/**
* !!!!!!!!!!!!!!!!!!!!!!!!!!
* NOTE: This file is auto generated. Do not edit this file manually.
*/

import {localize, localizeIntl, getOperationTypeByCode, getRentElementStateByCode, getCustomFieldTypeByCode} from "./localizationUtils";

export {localize, localizeIntl, getOperationTypeByCode, getRentElementStateByCode, getCustomFieldTypeByCode};

export enum LocalizationEnum {

    /** Отмена */
    ASPECT__GLOBAL__CANCEL = 'aspect.global.cancel',

    /** Добавить */
    ASPECT__GLOBAL__ADD = 'aspect.global.add',

    /** Создать */
    ASPECT__GLOBAL__CREATE = 'aspect.global.create',

    /** Отправить */
    ASPECT__GLOBAL__SEND = 'aspect.global.send',

    /** Сохранить */
    ASPECT__GLOBAL__SAVE = 'aspect.global.save',

    /** OK */
    ASPECT__GLOBAL__OK = 'aspect.global.ok',

    /** Да */
    ASPECT__GLOBAL__YES = 'aspect.global.yes',

    /** Нет */
    ASPECT__GLOBAL__NO = 'aspect.global.no',

    /** Последняя активность */
    ASPECT__GLOBAL__LAST_ACTIVITY = 'aspect.global.lastActivity',

    /** Последняя операция */
    ASPECT__GLOBAL__LAST_OPERATION = 'aspect.global.lastOperation',

    /** Электронная почта */
    ASPECT__GLOBAL__EMAIL = 'aspect.global.email',

    /** Номер телефона */
    ASPECT__GLOBAL__PHONE_NUMBER = 'aspect.global.phoneNumber',

    /** Автор последнего изменения */
    ASPECT__GLOBAL__LAST_UPDATE_AUTHOR = 'aspect.global.lastUpdateAuthor',

    /** Фамилия */
    ASPECT__GLOBAL__LASTNAME = 'aspect.global.lastname',

    /** Имя */
    ASPECT__GLOBAL__FIRSTNAME = 'aspect.global.firstname',

    /** Отчество */
    ASPECT__GLOBAL__SECONDNAME = 'aspect.global.secondname',

    /** Статистика */
    ASPECT__GLOBAL__STATS = 'aspect.global.stats',

    /** Всего */
    ASPECT__GLOBAL__TOTAL_NUMBER = 'aspect.global.totalNumber',

    /** Итого */
    ASPECT__GLOBAL__IN_TOTAL = 'aspect.global.inTotal',

    /** Все */
    ASPECT__GLOBAL__ANY = 'aspect.global.any',

    /** Еще */
    ASPECT__GLOBAL__MORE = 'aspect.global.more',

    /** Ошибка */
    ASPECT__GLOBAL__ERROR = 'aspect.global.error',

    /** из */
    ASPECT__GLOBAL__SUBSET_TERM_OF = 'aspect.global.subsetTermOf',

    /** Женский */
    ASPECT__GLOBAL__SEX__FEMALE = 'aspect.global.sex.female',

    /** Мужской */
    ASPECT__GLOBAL__SEX__MALE = 'aspect.global.sex.male',

    /** Пример */
    ASPECT__GLOBAL__EXAMPLE = 'aspect.global.example',

    /** Ф.И.О. */
    ASPECT__GLOBAL__PERSON_FULL_NAME = 'aspect.global.personFullName',

    /** Включены */
    ASPECT__GLOBAL__TURNED_ON__PLURAL = 'aspect.global.turnedOn.plural',

    /** Отключены */
    ASPECT__GLOBAL__TURNED_OFF__PLURAL = 'aspect.global.turnedOff.plural',

    /** от */
    ASPECT__GLOBAL__FROM_AS_START_OF_THE_NUMBERS_INTERVAL = 'aspect.global.fromAsStartOfTheNumbersInterval',

    /** до */
    ASPECT__GLOBAL__FROM_AS_END_OF_THE_NUMBERS_INTERVAL = 'aspect.global.fromAsEndOfTheNumbersInterval',

    /** По умолчанию */
    ASPECT__GLOBAL__FORM__NOTIFICATION_SCHEME__DEFAULT = 'aspect.global.form.notificationScheme.default',

    /** Показатели аренды */
    ASPECT__GLOBAL__RENT_INDICATORS = 'aspect.global.rentIndicators',

    /** Занято */
    ASPECT__GLOBAL__OCCUPIED = 'aspect.global.occupied',

    /** Инвентарь */
    ASPECT__GLOBAL__INVENTORY = 'aspect.global.inventory',

    /** Состояние */
    ASPECT__GLOBAL__CONDITION = 'aspect.global.condition',

    /** Пустое значение */
    ASPECT__GLOBAL__EMPTY = 'aspect.global.empty',

    /** Номенклатура */
    ASPECT__GLOBAL__NOMENCLATURE = 'aspect.global.nomenclature',

    /** Дополнительно */
    ASPECT__GLOBAL__EXTRA_FEATURES = 'aspect.global.extraFeatures',

    /** Показатели */
    ASPECT__GLOBAL__INDICATORS = 'aspect.global.indicators',

    /** Архив */
    ASPECT__GLOBAL__ARCHIVE = 'aspect.global.archive',

    /** {value, plural, =0 {файлов} one {файл} few {файла} other {файлов}} */
    ASPECT__GLOBAL__FILE = 'aspect.global.file',

    /** Скопировать ссылку */
    ASPECT__GLOBAL__COPY_LINK = 'aspect.global.copyLink',

    /** Ссылка скопирована */
    ASPECT__GLOBAL__POPUP_NOTIFICATIONS__LINK_COPIED = 'aspect.global.popupNotifications.linkCopied',

    /** Dashboard */
    ASPECT__MAIN_MENU__DASHBOARD = 'aspect.mainMenu.dashboard',

    /** Календарь */
    ASPECT__MAIN_MENU__CALENDAR = 'aspect.mainMenu.calendar',

    /** Проекты */
    ASPECT__MAIN_MENU__PROJECTS = 'aspect.mainMenu.projects',

    /** Контрагенты */
    ASPECT__MAIN_MENU__COUNTERPARTIES = 'aspect.mainMenu.counterparties',

    /** Продукты */
    ASPECT__MAIN_MENU__PRODUCTS = 'aspect.mainMenu.products',

    /** Новая операция */
    ASPECT__MAIN_MENU__CREATE_OPERATION = 'aspect.mainMenu.createOperation',

    /** Форма операции */
    ASPECT__MAIN_MENU__OPERATION_FORM = 'aspect.mainMenu.operationForm',

    /** Настройки */
    ASPECT__MAIN_MENU__SETTINGS = 'aspect.mainMenu.settings',

    /** Поддержка */
    ASPECT__MAIN_MENU__SUPPORT = 'aspect.mainMenu.support',

    /** Запросы поддержки */
    ASPECT__MAIN_MENU__SUPPORT__REQUESTS = 'aspect.mainMenu.support.requests',

    /** База знаний */
    ASPECT__MAIN_MENU__KB = 'aspect.mainMenu.kb',

    /** Уведомления */
    ASPECT__MAIN_MENU__NOTIFICATIONS = 'aspect.mainMenu.notifications',

    /** Категории */
    ASPECT__MAIN_MENU__CATEGORIES = 'aspect.mainMenu.categories',

    /** Операции */
    ASPECT__MAIN_MENU__OPERATIONS = 'aspect.mainMenu.operations',

    /** Профиль */
    ASPECT__MAIN_MENU__PROFILE = 'aspect.mainMenu.profile',

    /** Инвентарь */
    ASPECT__MAIN_MENU__INVENTORY = 'aspect.mainMenu.inventory',

    /** Персонал */
    ASPECT__MAIN_MENU__CREW = 'aspect.mainMenu.crew',

    /** Наборы */
    ASPECT__MAIN_MENU__KITS = 'aspect.mainMenu.kits',

    /** Статистика */
    ASPECT__MAIN_MENU__ANALYTICS = 'aspect.mainMenu.analytics',

    /** Поставки */
    ASPECT__MAIN_MENU__SUBRENT = 'aspect.mainMenu.subrent',

    /** Поставки для субаренды */
    ASPECT__MAIN_MENU__SUBRENT__RENT = 'aspect.mainMenu.subrent.rent',

    /** Аренда */
    ASPECT__MAIN_MENU__RENT = 'aspect.mainMenu.rent',

    /** Выйти */
    ASPECT__CURRENT_USER_PROFILE_POPUP__SIGN_OUT = 'aspect.currentUserProfilePopup.signOut',

    /** Сменить компанию */
    ASPECT__CURRENT_USER_PROFILE_POPUP__CHANGE_BUSINESS_ACCOUNT = 'aspect.currentUserProfilePopup.changeBusinessAccount',

    /** Добавить компанию */
    ASPECT__CURRENT_USER_PROFILE_POPUP__ADD_BUSINESS_ACCOUNT = 'aspect.currentUserProfilePopup.addBusinessAccount',

    /** Компании */
    ASPECT__CURRENT_USER_PROFILE_POPUP__BUSINESS_ACCOUNTS_OF = 'aspect.currentUserProfilePopup.businessAccountsOf',

    /** Продукт */
    ASPECT__ENTITY_TYPE__PRODUCT = 'aspect.entityType.product',

    /** Арендатор */
    ASPECT__ENTITY_TYPE__RENTER = 'aspect.entityType.renter',

    /** Поставка */
    ASPECT__ENTITY_TYPE__SUBRENT = 'aspect.entityType.subrent',

    /** Проект */
    ASPECT__ENTITY_TYPE__PROJECT = 'aspect.entityType.project',

    /** Операция */
    ASPECT__ENTITY_TYPE__OPERATION = 'aspect.entityType.operation',

    /** Операции */
    ASPECT__ENTITY_TYPE__OPERATIONS = 'aspect.entityType.operations',

    /** Обязательство */
    ASPECT__ENTITY_TYPE__ELEMENT = 'aspect.entityType.element',

    /** Обязательствa */
    ASPECT__ENTITY_TYPE__ELEMENTS = 'aspect.entityType.elements',

    /** Компания */
    ASPECT__ENTITY_TYPE__BUSINESS_ACCOUNT = 'aspect.entityType.businessAccount',

    /** Вариант */
    ASPECT__ENTITY_TYPE__VARIANT = 'aspect.entityType.variant',

    /** Экземпляр */
    ASPECT__ENTITY_TYPE__INSTANCE = 'aspect.entityType.instance',

    /** Экземпляры */
    ASPECT__ENTITY_TYPE__INSTANCES = 'aspect.entityType.instances',

    /** Набор */
    ASPECT__ENTITY_TYPE__KIT = 'aspect.entityType.kit',

    /** Шаблон */
    ASPECT__ENTITY_TYPE__TEMPLATE = 'aspect.entityType.template',

    /** Шаблоны */
    ASPECT__ENTITY_TYPE__TEMPLATES = 'aspect.entityType.templates',

    /** Контрагент */
    ASPECT__ENTITY_TYPE__COUNTERPARTY = 'aspect.entityType.counterparty',

    /** Контрагенты */
    ASPECT__ENTITY_TYPE__COUNTERPARTIES = 'aspect.entityType.counterparties',

    /** Шаблон документа */
    ASPECT__ENTITY_TYPE__DOCUMENT_TEMPLATE = 'aspect.entityType.documentTemplate',

    /** Стандартный шаблон документа */
    ASPECT__ENTITY_TYPE__COMMON_DOCUMENT_TEMPLATE = 'aspect.entityType.commonDocumentTemplate',

    /** Конфигурация отчёта */
    ASPECT__ENTITY_TYPE__REPORT_CONFIGURATION = 'aspect.entityType.reportConfiguration',

    /** Отображение отчета */
    ASPECT__ENTITY_TYPE__REPORT_VISUALIZATION = 'aspect.entityType.reportVisualization',

    /** Экземпляр отчёта */
    ASPECT__ENTITY_TYPE__REPORT_INSTANCE = 'aspect.entityType.reportInstance',

    /** Платёж */
    ASPECT__ENTITY_TYPE__PAYMENT = 'aspect.entityType.payment',

    /** Пользователь */
    ASPECT__ENTITY_TYPE__USER = 'aspect.entityType.user',

    /** Системная опция */
    ASPECT__ENTITY_TYPE__SYSTEM_OPTION = 'aspect.entityType.systemOption',

    /** Категория */
    ASPECT__ENTITY_TYPE__CATEGORY = 'aspect.entityType.category',

    /** Настраиваемое поле */
    ASPECT__ENTITY_TYPE__CUSTOM_FIELD = 'aspect.entityType.customField',

    /** Контакт */
    ASPECT__ENTITY_TYPE__CONTACT = 'aspect.entityType.contact',

    /** Локация */
    ASPECT__ENTITY_TYPE__LOCATION = 'aspect.entityType.location',

    /** Транспорт */
    ASPECT__ENTITY_TYPE__VEHICLE = 'aspect.entityType.vehicle',

    /** Движение инвентаря */
    ASPECT__ENTITY_TYPE__INVENTORY_MOVEMENT = 'aspect.entityType.inventoryMovement',

    /** Ценовая схема */
    ASPECT__ENTITY_TYPE__PRICING_SCHEME = 'aspect.entityType.pricingScheme',

    /** Перевозка */
    ASPECT__ENTITY_TYPE__TRANSPORTATION = 'aspect.entityType.transportation',

    /** Затрата */
    ASPECT__ENTITY_TYPE__EXPENSE = 'aspect.entityType.expense',

    /** Работы */
    ASPECT__ENTITY_TYPE__WORK_PLANNINGS = 'aspect.entityType.workPlannings',

    /** Вложение */
    ASPECT__ENTITY_TYPE__ATTACHMENT = 'aspect.entityType.attachment',

    /** Профессия */
    ASPECT__ENTITY_TYPE__PROFESSION = 'aspect.entityType.profession',

    /** Проектный работник */
    ASPECT__ENTITY_TYPE__CREW_MEMBER = 'aspect.entityType.crewMember',

    /** {value, plural, =0 {Лет} one {Год} few {Года} other {Лет}} */
    ASPECT__TIME__YEAR = 'aspect.time.year',

    /** {value, plural, =0 {Месяцев} one {Месяц} few {Месяца} other {Месяцев}} */
    ASPECT__TIME__MONTH = 'aspect.time.month',

    /** {value, plural, =0 {Недель} one {Неделя} few {Недели} other {Недель}} */
    ASPECT__TIME__WEEK = 'aspect.time.week',

    /** {value, plural, =0 {Дней} one {День} few {Дня} other {Дней}} */
    ASPECT__TIME__DAY = 'aspect.time.day',

    /** {value, plural, =0 {Часов} one {Час} few {Часа} other {Часов}} */
    ASPECT__TIME__HOUR = 'aspect.time.hour',

    /** {value, plural, =0 {Минут} one {Минута} few {Минуты} other {Минут}} */
    ASPECT__TIME__MINUTE = 'aspect.time.minute',

    /** Сегодня */
    ASPECT__TIME__TODAY = 'aspect.time.today',

    /** Вчера */
    ASPECT__TIME__YESTERDAY = 'aspect.time.yesterday',

    /** Завтра */
    ASPECT__TIME__TOMORROW = 'aspect.time.tomorrow',

    /** Роли */
    PAGE__SECURITY__ROLES = 'page.security.roles',

    /** Агент */
    PAGE__SECURITY__ROLES__AGENT = 'page.security.roles.agent',

    /** Администратор */
    PAGE__SECURITY__ROLES__ADMIN = 'page.security.roles.admin',

    /** Склад */
    ASPECT__RENT_BUSINESS__WAREHOUSE = 'aspect.rentBusiness.warehouse',

    /** На обслуживании */
    ASPECT__RENT_BUSINESS__UNDER_MAINTENANCE = 'aspect.rentBusiness.underMaintenance',

    /** Период аренды */
    ASPECT__RENT_BUSINESS__RENT_PERIOD = 'aspect.rentBusiness.rentPeriod',

    /** О системе */
    ASPECT__FOOTER__ABOUT = 'aspect.footer.about',

    /** Политика конфиденциальности */
    ASPECT__FOOTER__PRIVACY_POLICY = 'aspect.footer.privacyPolicy',

    /** {total, plural, =0 {Ничего не найдено} =1 {<span style="font-weight: 500">1</span> из <span style="font-weight: 500">1</span>} other {<span style="font-weight: 500">{from}</span>-<span style="font-weight: 500">{to}</span> из #}} */
    ASPECT__GRID__ROWS_PER_PAGE_DASHBOARD = 'aspect.grid.rowsPerPageDashboard',

    /** {total, plural, =0 {Ничего не найдено} =1 {Отображается <span style="font-weight: 500">1</span> из <span style="font-weight: 500">1</span>} other {Отображаются <span style="font-weight: 500">{from}</span>-<span style="font-weight: 500">{to}</span> из #}} */
    ASPECT__GRID__ROWS_PER_PAGE = 'aspect.grid.rowsPerPage',

    /** {total, plural, =0 {Ничего не найдено} =1 {<span style="font-weight: 500">1</span> из <span style="font-weight: 500">1</span>} other {<span style="font-weight: 500">{from}</span>-<span style="font-weight: 500">{to}</span> из #}} */
    ASPECT__GRID__ROWS_PER_PAGE_WITHOUT_OTHER = 'aspect.grid.rowsPerPageWithoutOther',

    /** Ничего не найдено */
    ASPECT__GRID__EMPTY = 'aspect.grid.empty',

    /** записей */
    ASPECT__GRID__RECORDS_IN_RECORDS_COUNT = 'aspect.grid.recordsInRecordsCount',

    /** Очистить */
    ASPECT__GRID__CLEAR_SELECTED = 'aspect.grid.clearSelected',

    /** Выбрано */
    ASPECT__GRID__SELECTED_COUNT = 'aspect.grid.selectedCount',

    /** Для поиска по архиву переключите режим */
    ASPECT__GRID__TO_SEARCH_IN_ARCHIVE_SWITCH_THE_MODE = 'aspect.grid.toSearchInArchiveSwitchTheMode',

    /** Показать */
    ASPECT__GRID__SHOW_PER_PAGE_COUNT_PRE_LABEL = 'aspect.grid.showPerPageCountPreLabel',

    /** Номера записей, показанных на странице (через дефис), а также общее число записей попавших в выборку после применения выбранных фильтров */
    ASPECT__GRID__TOOLTIP__022 = 'aspect.grid.tooltip.022',

    /** Время */
    ASPECT__GRID__COLUMN__TIME = 'aspect.grid.column.time',

    /** Кол-во */
    ASPECT__GRID__COLUMN__QUANTITY = 'aspect.grid.column.quantity',

    /** Наименование */
    ASPECT__GRID__COLUMN__NAME = 'aspect.grid.column.name',

    /** Статус */
    ASPECT__GRID__COLUMN__STATUS = 'aspect.grid.column.status',

    /** Скидка */
    ASPECT__GRID__COLUMN__DISCOUNT = 'aspect.grid.column.discount',

    /** Бронь */
    ASPECT__GRID__COLUMN__BOOKED = 'aspect.grid.column.booked',

    /** Аренда */
    ASPECT__GRID__COLUMN__RENT = 'aspect.grid.column.rent',

    /** Ответственный */
    ASPECT__GRID__COLUMN__RESPONSIBLE = 'aspect.grid.column.responsible',

    /** Активность */
    ASPECT__GRID__COLUMN__ACTIVITY = 'aspect.grid.column.activity',

    /** Арендатор */
    ASPECT__GRID__COLUMN__RENTER = 'aspect.grid.column.renter',

    /** Начало */
    ASPECT__GRID__COLUMN__BEGIN = 'aspect.grid.column.begin',

    /** Нехватка */
    ASPECT__GRID__COLUMN__SHORTAGE = 'aspect.grid.column.shortage',

    /** Просрочка */
    ASPECT__GRID__COLUMN__DELAY = 'aspect.grid.column.delay',

    /** Артикул */
    ASPECT__GRID__COLUMN__EXTERNAL_CODE = 'aspect.grid.column.externalCode',

    /** Обслуживание */
    ASPECT__GRID__COLUMN__MAINTENANCE = 'aspect.grid.column.maintenance',

    /** Цена смены */
    ASPECT__GRID__COLUMN__PRICE_PER_SHIFT = 'aspect.grid.column.pricePerShift',

    /** Смены к оплате */
    ASPECT__GRID__COLUMN__SHIFT_COUNT = 'aspect.grid.column.shiftCount',

    /** Проект */
    ASPECT__GRID__COLUMN__PROJECT = 'aspect.grid.column.project',

    /** Сумма */
    ASPECT__GRID__COLUMN__SUM = 'aspect.grid.column.sum',

    /** Итоговая сумма */
    ASPECT__GRID__COLUMN__FINAL_TOTAL_PRICE = 'aspect.grid.column.finalTotalPrice',

    /** Автор */
    ASPECT__GRID__COLUMN__AUTHOR = 'aspect.grid.column.author',

    /** Продукт */
    ASPECT__GRID__COLUMN__PRODUCT = 'aspect.grid.column.product',

    /** Вариант */
    ASPECT__GRID__COLUMN__VARIANT = 'aspect.grid.column.variant',

    /** Завершение */
    ASPECT__GRID__COLUMN__END_DATE = 'aspect.grid.column.endDate',

    /** Статус до */
    ASPECT__GRID__COLUMN__STATE_CODE_BEFORE = 'aspect.grid.column.stateCodeBefore',

    /** Цена экз./см. */
    ASPECT__GRID__COLUMN__PRICE_INSTANCE_PER_SHIFT = 'aspect.grid.column.priceInstancePerShift',

    /** Действия */
    ASPECT__GRID__COLUMN__ACTIONS = 'aspect.grid.column.actions',

    /** Стоимость экз./см. */
    ASPECT__GRID__COLUMN__COST_INSTANCE_PER_SHIFT = 'aspect.grid.column.costInstancePerShift',

    /** Номер */
    ASPECT__GRID__COLUMN__NUMBER = 'aspect.grid.column.number',

    /** Изобр. */
    ASPECT__GRID__COLUMN__IMAGE = 'aspect.grid.column.image',

    /** Стоимость */
    ASPECT__GRID__COLUMN__TOTAL_COST = 'aspect.grid.column.totalCost',

    /** Долг */
    ASPECT__GRID__COLUMN__DEBT = 'aspect.grid.column.debt',

    /** День недели */
    ASPECT__GRID__COLUMN__DAY_OF_WEEK = 'aspect.grid.column.dayOfWeek',

    /** Следующая страница */
    ASPECT__GRID__TOOLTIP__TO_NEXT_PAGE = 'aspect.grid.tooltip.toNextPage',

    /** К первой странице */
    ASPECT__GRID__TOOLTIP__TO_THE_FIRST_PAGE = 'aspect.grid.tooltip.toTheFirstPage',

    /** Предыдущая страница */
    ASPECT__GRID__TOOLTIP__TO_PREVIOUS_PAGE = 'aspect.grid.tooltip.toPreviousPage',

    /** К последней странице */
    ASPECT__GRID__TOOLTIP__TO_THE_LAST_PAGE = 'aspect.grid.tooltip.toTheLastPage',

    /** Все колонки */
    ASPECT__EXCEL_EXPORT__SELECT_OPTION__ALL_COLUMNS = 'aspect.excelExport.selectOption.allColumns',

    /** Все страницы */
    ASPECT__EXCEL_EXPORT__SELECT_OPTION__ALL_PAGES = 'aspect.excelExport.selectOption.allPages',

    /** Компоненты наборов */
    ASPECT__EXCEL_EXPORT__SELECT_OPTION__INCLUDE_COMPONENTS = 'aspect.excelExport.selectOption.includeComponents',

    /** Экспорт в Excel */
    ASPECT__EXCEL_EXPORT__POPUP_LABEL = 'aspect.excelExport.popupLabel',

    /** Экспортировать */
    ASPECT__EXCEL_EXPORT__POPUP_ACTION = 'aspect.excelExport.popupAction',

    /** Обязательства проекта */
    ASPECT__EXCEL_EXPORT__EXCEL_FILE_NAME_PROJECT_ELEMENTS = 'aspect.excelExport.excelFileNameProjectElements',

    /** Обязательства операции */
    ASPECT__EXCEL_EXPORT__EXCEL_FILE_NAME_OPERATION_ELEMENTS = 'aspect.excelExport.excelFileNameOperationElements',

    /** Экспорт обязательств из календаря */
    ASPECT__EXCEL_EXPORT__DEFAULT_EXCEL_EXPORT_FILE_NAME = 'aspect.excelExport.defaultExcelExportFileName',

    /** Экспорт в эксель выполнить не удалось */
    ASPECT__EXCEL_EXPORT__EXPORT_TO_EXCEL_FAILED = 'aspect.excelExport.exportToExcelFailed',

    /** Действия */
    ASPECT__GRID__POPUP__TITLE__ACTIONS = 'aspect.grid.popup.title.actions',

    /** Количество */
    ASPECT__GRID__POPUP__TITLE__AMOUNT = 'aspect.grid.popup.title.amount',

    /** Прервать редактирование? */
    ASPECT__MODAL__ABORT_EDIT = 'aspect.modal.abortEdit',

    /** Прервать создание? */
    ASPECT__MODAL__ABORT_CREATION = 'aspect.modal.abortCreation',

    /** {value, plural, =0 {проектов} one {проект} few {проекта} other {проектов}} */
    ASPECT__PLURAL__PROJECT = 'aspect.plural.project',

    /** {value, plural, =0 {проектах} one {проекте} few {проектах} other {проектах}} */
    ASPECT__PLURAL__IN_PROJECT = 'aspect.plural.inProject',

    /** {value, plural, =0 {работ} one {работ} few {работ} other {работ}} */
    ASPECT__PLURAL__FOR_WORK = 'aspect.plural.forWork',

    /** {value, plural, =0 {обязательств} one {обязательство} few {обязательства} other {обязательств}} */
    ASPECT__PLURAL__ELEMENT = 'aspect.plural.element',

    /** {value, plural, =0 {активных} one {активное} few {активных} other {активных}} */
    ASPECT__PLURAL__ACTIVE_NEUTER_GENDER = 'aspect.plural.activeNeuterGender',

    /** {value, plural, =0 {активных} one {активный} few {активных} other {активных}} */
    ASPECT__PLURAL__ACTIVE_MALE_GENDER = 'aspect.plural.activeMaleGender',

    /** {value, plural, =0 {активных} one {активного} few {активных} other {активных}} */
    ASPECT__PLURAL__FOR_ACTIVE = 'aspect.plural.forActive',

    /** {value, plural, =0 {обязательств} one {обязательства} few {обязательств} other {обязательств}} */
    ASPECT__PLURAL__FOR_ELEMENT = 'aspect.plural.forElement',

    /** {value, plural, =0 {операций} one {операция} few {операции} other {операций}} */
    ASPECT__PLURAL__OPERATION = 'aspect.plural.operation',

    /** {value, plural, =0 {экземпляров} one {экземпляр} few {экземпляра} other {экземпляров}} */
    ASPECT__PLURAL__INSTANCE = 'aspect.plural.instance',

    /** {value, plural, =0 {экземпляров добавлено} one {экземпляр добавлен} few {экземпляра добавлено} other {экземпляров добавлено}} */
    ASPECT__PLURAL__INSTANCE_ADDED = 'aspect.plural.instanceAdded',

    /** {value, plural, =0 {номенклатур добавлено} one {номенклатура добавлена} few {номенклатуры добавлено} other {номенклатур добавлено}} */
    ASPECT__PLURAL__NOMENCLATURE_ADDED = 'aspect.plural.nomenclatureAdded',

    /** {value, plural, =0 {смен} one {смена} few {смены} other {смен}} */
    ASPECT__PLURAL__SHIFT = 'aspect.plural.shift',

    /** {value, plural, =0 {смен} one {смены} few {смен} other {смен}} */
    ASPECT__PLURAL__FROM_SHIFTS = 'aspect.plural.fromShifts',

    /** {value, plural, =0 {анонимных экз.} one {анонимный экз.} few {анонимных экз.} other {анонимных экз.}} */
    ASPECT__PLURAL__ANONYMOUS_INSTANCE = 'aspect.plural.anonymousInstance',

    /** {value, plural, =0 {анонимных} one {анонимный} few {анонимных} other {анонимных}} */
    ASPECT__PLURAL__ANONYMOUS = 'aspect.plural.anonymous',

    /** {value, plural, =0 {фильтров} one {фильтр} few {фильтра} other {фильтров}} */
    ASPECT__PLURAL__FILTER = 'aspect.plural.filter',

    /** {value, plural, =0 {полей} one {поле} few {поля} other {полей}} */
    ASPECT__PLURAL__FIELD = 'aspect.plural.field',

    /** {value, plural, =0 {подкатегорий} one {подкатегорий} few {подкатегории} other {подкатегорий}} */
    ASPECT__PLURAL__SUBCATEGORY = 'aspect.plural.subcategory',

    /** {value, plural, =0 {контрагентов} one {контрагент} few {контрагента} other {контрагентов}} */
    ASPECT__PLURAL__COUNTERPARTY = 'aspect.plural.counterparty',

    /** {value, plural, =0 {вложений} one {вложение} few {вложения} other {вложений}} */
    ASPECT__PLURAL__ATTACHMENT = 'aspect.plural.attachment',

    /** {value, plural, =0 {контактов} one {контакт} few {контакта} other {контактов}} */
    ASPECT__PLURAL__CONTACT = 'aspect.plural.contact',

    /** {value, plural, =0 {назначенных работников} one {назначенный работник} few {назначенных работника} other {назначенных работников}} */
    ASPECT__PLURAL__CREW_MEMBER = 'aspect.plural.crewMember',

    /** Это действие также удалит её связи с <b>{value}</b> {value, plural, =0 {проектных работников} one {проектным работником} other {проектными работниками}} и сотрёт ставки, установленные для них по этой специальности. */
    ASPECT__PLURAL__DELETE_PROFESSION = 'aspect.plural.deleteProfession',

    /** {value, plural, =0 {файлов не будет загружено} one {файл не будет загружен} few {файла не будет загружено} other {файлов не будет загружено}} */
    ASPECT__PLURAL__FILE_WILL_NOT_BE_UPLOADED_MESSAGE = 'aspect.plural.fileWillNotBeUploadedMessage',

    /** {value, plural, =0 {Ошибок} one {Ошибка} few {Ошибки} other {Ошибок}} сканирования */
    ASPECT__PLURAL__SCAN_ERROR = 'aspect.plural.scanError',

    /** {value, plural, =0 {продуктов} one {продукт} few {продукта} other {продуктов}} */
    ASPECT__PLURAL__PRODUCT = 'aspect.plural.product',

    /** {value, plural, =0 {наборов} one {набор} few {набора} other {наборов}} */
    ASPECT__PLURAL__KIT = 'aspect.plural.kit',

    /** Не указан */
    ASPECT__DATA_PRESENCE__NOT_SPECIFIED__M = 'aspect.dataPresence.notSpecified.m',

    /** Не указана */
    ASPECT__DATA_PRESENCE__NOT_SPECIFIED__F = 'aspect.dataPresence.notSpecified.f',

    /** Не указано */
    ASPECT__DATA_PRESENCE__NOT_SPECIFIED__N = 'aspect.dataPresence.notSpecified.n',

    /** Не указаны */
    ASPECT__DATA_PRESENCE__NOT_SPECIFIED__MANY = 'aspect.dataPresence.notSpecified.many',

    /** Загрузка... */
    ASPECT__DATA_PRESENCE__DATA_LOADING = 'aspect.dataPresence.dataLoading',

    /** Ничего не найдено */
    ASPECT__DATA_PRESENCE__DATA_NOT_FOUND = 'aspect.dataPresence.dataNotFound',

    /** Ошибка загрузки */
    ASPECT__DATA_PRESENCE__DATA_LOADING_ERROR = 'aspect.dataPresence.dataLoadingError',

    /** Отсутствует */
    ASPECT__DATA_PRESENCE__ABSENT = 'aspect.dataPresence.absent',

    /** Изображение отсутствует */
    ASPECT__DATA_PRESENCE__NO_IMAGE = 'aspect.dataPresence.noImage',

    /** Не назначен */
    ASPECT__DATA_PRESENCE__NOT_ASSIGNED = 'aspect.dataPresence.notAssigned',

    /** Не задано */
    ASPECT__DATA_PRESENCE__NOT_SET = 'aspect.dataPresence.notSet',

    /** Поле обязательно для заполнения */
    ASPECT__DATA_PRESENCE__FIELD_IS_REQUIRED = 'aspect.dataPresence.fieldIsRequired',

    /** Не производилось */
    ASPECT__DATA_PRESENCE__NEVER_OCCURED = 'aspect.dataPresence.neverOccured',

    /** Категории продуктов отсутствуют */
    ASPECT__DATA_PRESENCE__NO_PRODUCT_CATEGORIES_EXIST = 'aspect.dataPresence.noProductCategoriesExist',

    /** Категории контрагентов отсутствуют */
    ASPECT__DATA_PRESENCE__NO_RENTER_CATEGORIES_EXIST = 'aspect.dataPresence.noRenterCategoriesExist',

    /** Не начисляется */
    ASPECT__DATA_PRESENCE__NOT_CHARGED = 'aspect.dataPresence.notCharged',

    /** Не удалось загрузить данные */
    ASPECT__DATA_PRESENCE__POPUP_NOTIFICATION__FAILED_TO_LOAD_DATA = 'aspect.dataPresence.popupNotification.failedToLoadData',

    /** Просрочки */
    ASPECT__COUNTER__CURRENT_DELAY_CHILD_ENTITIES_COUNT = 'aspect.counter.currentDelayChildEntitiesCount',

    /** Сумма просрочек */
    ASPECT__COUNTER__CURRENT_DELAY_SUM = 'aspect.counter.currentDelaySum',

    /** Нехватки */
    ASPECT__COUNTER__CURRENT_SHORTAGE_CHILD_ENTITIES_COUNT = 'aspect.counter.currentShortageChildEntitiesCount',

    /** Сумма нехваток */
    ASPECT__COUNTER__CURRENT_SHORTAGE_SUM = 'aspect.counter.currentShortageSum',

    /** В брони */
    ASPECT__COUNTER__CURRENT_BOOKED_SUM = 'aspect.counter.currentBookedSum',

    /** В аренде */
    ASPECT__COUNTER__CURRENT_RENTED_SUM = 'aspect.counter.currentRentedSum',

    /** Завершено аренды */
    ASPECT__COUNTER__TOTAL_RETURNED_SUM = 'aspect.counter.totalReturnedSum',

    /** Последняя активность */
    ASPECT__COUNTER__LAST_ACTIVITY_DATE = 'aspect.counter.lastActivityDate',

    /** Обязательств */
    ASPECT__COUNTER__ELEMENT_COUNT = 'aspect.counter.elementCount',

    /** Последний возврат */
    ASPECT__COUNTER__LAST_RETURN_DATE = 'aspect.counter.lastReturnDate',

    /** Просрочки */
    ASPECT__PROBLEM__DELAYS = 'aspect.problem.delays',

    /** Нехватки */
    ASPECT__PROBLEM__SHORTAGES = 'aspect.problem.shortages',

    /** Просрочка */
    ASPECT__PROBLEM__DELAY = 'aspect.problem.delay',

    /** Нехватка */
    ASPECT__PROBLEM__SHORTAGE = 'aspect.problem.shortage',

    /** Нехватка по брони */
    ASPECT__PROBLEM__TOOLTIP__BOOK_SHORTAGE = 'aspect.problem.tooltip.bookShortage',

    /** Просрочка поставки субаренды */
    ASPECT__PROBLEM__TOOLTIP__SUBRENT_SHIPMENT_DELAY = 'aspect.problem.tooltip.subrentShipmentDelay',

    /** Просрочка возврата */
    ASPECT__PROBLEM__TOOLTIP__RETURN_DELAY = 'aspect.problem.tooltip.returnDelay',

    /** Просрочка выдачи */
    ASPECT__PROBLEM__TOOLTIP__GIVEAWAY_DELAY = 'aspect.problem.tooltip.giveawayDelay',

    /** Просрочка возрата субаренды поставщику */
    ASPECT__PROBLEM__TOOLTIP__SUBRENT_RETURN_TO_SHIPPER_DELAY = 'aspect.problem.tooltip.subrentReturnToShipperDelay',

    /** Нехватка по заявке */
    ASPECT__PROBLEM__TOOLTIP__ORDER_SHORTAGE = 'aspect.problem.tooltip.orderShortage',

    /** Активировать */
    ASPECT__ACTION__ACTIVATE = 'aspect.action.activate',

    /** Заблокировать */
    ASPECT__ACTION__BLOCK = 'aspect.action.block',

    /** Разблокировать */
    ASPECT__ACTION__UNLOCK = 'aspect.action.unlock',

    /** В архив */
    ASPECT__ACTION__TO_ARCHIVE = 'aspect.action.toArchive',

    /** Из архива */
    ASPECT__ACTION__FROM_ARCHIVE = 'aspect.action.fromArchive',

    /** Создать */
    ASPECT__ACTION__CREATE = 'aspect.action.create',

    /** Создать контрагента */
    ASPECT__ACTION__CREATE_RENTER = 'aspect.action.createRenter',

    /** Создать проект */
    ASPECT__ACTION__CREATE_PROJECT = 'aspect.action.createProject',

    /** Редактировать */
    ASPECT__ACTION__EDIT = 'aspect.action.edit',

    /** Комментировать */
    ASPECT__ACTION__COMMENT = 'aspect.action.comment',

    /** Удалить */
    ASPECT__ACTION__DELETE = 'aspect.action.delete',

    /** Создать на основе */
    ASPECT__ACTION__DUPLICATE = 'aspect.action.duplicate',

    /** Отменить */
    ASPECT__ACTION__CANCEL = 'aspect.action.cancel',

    /** На обслуживание */
    ASPECT__INSTANCE_TRANSITIONS__SEND_UNDER_MAINTENANCE = 'aspect.instanceTransitions.sendUnderMaintenance',

    /** Списать */
    ASPECT__INSTANCE_TRANSITIONS__DECOMISSION = 'aspect.instanceTransitions.decomission',

    /** Отменить */
    ASPECT__EXPENSE_TRANSITIONS__CANCEL = 'aspect.expenseTransitions.cancel',

    /** Учесть */
    ASPECT__EXPENSE_TRANSITIONS__TAKE_INTO_ACCOUNT = 'aspect.expenseTransitions.takeIntoAccount',

    /** Продлить */
    ASPECT__RENT_OPERATION__ACTION__PROLONG = 'aspect.rentOperation.action.PROLONG',

    /** Вернуть */
    ASPECT__RENT_OPERATION__ACTION__RETURN = 'aspect.rentOperation.action.RETURN',

    /** Вернуть с дефектом */
    ASPECT__RENT_OPERATION__ACTION__RETURN_BROKEN = 'aspect.rentOperation.action.RETURN_BROKEN',

    /** Списать */
    ASPECT__RENT_OPERATION__ACTION__LOST_NO_RETURN = 'aspect.rentOperation.action.LOST_NO_RETURN',

    /** Корректировать */
    ASPECT__RENT_OPERATION__ACTION__CORRECT = 'aspect.rentOperation.action.CORRECT',

    /** Выдать */
    ASPECT__RENT_OPERATION__ACTION__GIVE_AWAY = 'aspect.rentOperation.action.GIVE_AWAY',

    /** Отменить */
    ASPECT__RENT_OPERATION__ACTION__CANCEL = 'aspect.rentOperation.action.CANCEL',

    /** Забронировать */
    ASPECT__RENT_OPERATION__ACTION__BOOK = 'aspect.rentOperation.action.BOOK',

    /** Принять заявку */
    ASPECT__RENT_OPERATION__ACTION__ORDER = 'aspect.rentOperation.action.ORDER',

    /** Рассчитать черновик */
    ASPECT__RENT_OPERATION__ACTION__DRAFT = 'aspect.rentOperation.action.DRAFT',

    /** Черновик СА */
    ASPECT__RENT_OPERATION__ACTION__SUBRENT_DRAFT = 'aspect.rentOperation.action.SUBRENT_DRAFT',

    /** Забронировать СА */
    ASPECT__RENT_OPERATION__ACTION__SUBRENT_BOOK_SHIPMENT = 'aspect.rentOperation.action.SUBRENT_BOOK_SHIPMENT',

    /** Принять поставку */
    ASPECT__RENT_OPERATION__ACTION__SUBRENT_ACCEPT_SHIPMENT = 'aspect.rentOperation.action.SUBRENT_ACCEPT_SHIPMENT',

    /** Вернуть поставщику */
    ASPECT__RENT_OPERATION__ACTION__SUBRENT_RETURN_TO_SHIPPER = 'aspect.rentOperation.action.SUBRENT_RETURN_TO_SHIPPER',

    /** Отменить СА */
    ASPECT__RENT_OPERATION__ACTION__SUBRENT_CANCEL = 'aspect.rentOperation.action.SUBRENT_CANCEL',

    /** Продлить СА */
    ASPECT__RENT_OPERATION__ACTION__SUBRENT_PROLONG = 'aspect.rentOperation.action.SUBRENT_PROLONG',

    /** Заявка */
    ASPECT__RENT_OPERATION__TYPE__ORDER = 'aspect.rentOperation.type.ORDER',

    /** Бронирование */
    ASPECT__RENT_OPERATION__TYPE__BOOK = 'aspect.rentOperation.type.BOOK',

    /** Выдача */
    ASPECT__RENT_OPERATION__TYPE__GIVE_AWAY = 'aspect.rentOperation.type.GIVE_AWAY',

    /** Продление */
    ASPECT__RENT_OPERATION__TYPE__PROLONG = 'aspect.rentOperation.type.PROLONG',

    /** Возврат */
    ASPECT__RENT_OPERATION__TYPE__RETURN = 'aspect.rentOperation.type.RETURN',

    /** Возврат с дефектом */
    ASPECT__RENT_OPERATION__TYPE__RETURN_BROKEN = 'aspect.rentOperation.type.RETURN_BROKEN',

    /** Списание */
    ASPECT__RENT_OPERATION__TYPE__LOST_NO_RETURN = 'aspect.rentOperation.type.LOST_NO_RETURN',

    /** Отмена */
    ASPECT__RENT_OPERATION__TYPE__CANCEL = 'aspect.rentOperation.type.CANCEL',

    /** Корректировка */
    ASPECT__RENT_OPERATION__TYPE__CORRECT = 'aspect.rentOperation.type.CORRECT',

    /** Черновик */
    ASPECT__RENT_OPERATION__TYPE__DRAFT = 'aspect.rentOperation.type.DRAFT',

    /** Авт. правило */
    ASPECT__RENT_OPERATION__TYPE__AUTOMATIC_RULE = 'aspect.rentOperation.type.AUTOMATIC_RULE',

    /** Черновик СА */
    ASPECT__RENT_OPERATION__TYPE__SUBRENT_DRAFT = 'aspect.rentOperation.type.SUBRENT_DRAFT',

    /** Бронирование СА */
    ASPECT__RENT_OPERATION__TYPE__SUBRENT_BOOK_SHIPMENT = 'aspect.rentOperation.type.SUBRENT_BOOK_SHIPMENT',

    /** Прием поставки */
    ASPECT__RENT_OPERATION__TYPE__SUBRENT_ACCEPT_SHIPMENT = 'aspect.rentOperation.type.SUBRENT_ACCEPT_SHIPMENT',

    /** Возврат поставщику */
    ASPECT__RENT_OPERATION__TYPE__SUBRENT_RETURN_TO_SHIPPER = 'aspect.rentOperation.type.SUBRENT_RETURN_TO_SHIPPER',

    /** Отмена СА */
    ASPECT__RENT_OPERATION__TYPE__SUBRENT_CANCEL = 'aspect.rentOperation.type.SUBRENT_CANCEL',

    /** Продление СА */
    ASPECT__RENT_OPERATION__TYPE__SUBRENT_PROLONG = 'aspect.rentOperation.type.SUBRENT_PROLONG',

    /** Российский рубль */
    ASPECT__CURRENCIES__RU = 'aspect.currencies.ru',

    /** Доллар США */
    ASPECT__CURRENCIES__US = 'aspect.currencies.us',

    /** Евро */
    ASPECT__CURRENCIES__EU = 'aspect.currencies.eu',

    /** Армянский Драм */
    ASPECT__CURRENCIES__AMD = 'aspect.currencies.AMD',

    /** Грузинский Лари */
    ASPECT__CURRENCIES__GEL = 'aspect.currencies.GEL',

    /** Белорусский Рубль */
    ASPECT__CURRENCIES__BYR = 'aspect.currencies.BYR',

    /** Казахстанcкий Тенге */
    ASPECT__CURRENCIES__KZT = 'aspect.currencies.KZT',

    /** Киргизский Сом */
    ASPECT__CURRENCIES__KGS = 'aspect.currencies.KGS',

    /** Узбекистанский Сум */
    ASPECT__CURRENCIES__UZS = 'aspect.currencies.UZS',

    /** Айзербайджанский Манат */
    ASPECT__CURRENCIES__AZN = 'aspect.currencies.AZN',

    /** Тайский Бат */
    ASPECT__CURRENCIES__THB = 'aspect.currencies.THB',

    /** Индонезийская Рупия */
    ASPECT__CURRENCIES__IDR = 'aspect.currencies.IDR',

    /** ОАЭ Дирхам */
    ASPECT__CURRENCIES__AED = 'aspect.currencies.AED',

    /** Таджикский Сомони */
    ASPECT__CURRENCIES__TJS = 'aspect.currencies.TJS',

    /** Не выбрано */
    ASPECT__FILTERS__PLACEHOLDER__NOT_SELECTED__NEUTRAL_GENDER = 'aspect.filters.placeholder.notSelected.neutralGender',

    /** Не выбран */
    ASPECT__FILTERS__PLACEHOLDER__NOT_SELECTED__MALE_GENDER = 'aspect.filters.placeholder.notSelected.maleGender',

    /** Не выбрана */
    ASPECT__FILTERS__PLACEHOLDER__NOT_SELECTED__FEMALE_GENDER = 'aspect.filters.placeholder.notSelected.femaleGender',

    /** Любая */
    ASPECT__FILTERS__PLACEHOLDER__ANY__FAMALE_GENDER = 'aspect.filters.placeholder.any.famaleGender',

    /** Любой */
    ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER = 'aspect.filters.placeholder.any.maleGender',

    /** Показать умный фильтр */
    ASPECT__FILTERS__ADVANCED_FILTERS__SHOW_BUTTON = 'aspect.filters.advancedFilters.showButton',

    /** Скрыть умный фильтр */
    ASPECT__FILTERS__ADVANCED_FILTERS__HIDE_BUTTON = 'aspect.filters.advancedFilters.hideButton',

    /** Поиск */
    ASPECT__FILTERS__SEARCH_FIELD_PLACEHOLDER = 'aspect.filters.searchFieldPlaceholder',

    /** Тип */
    ASPECT__FILTERS__TYPE = 'aspect.filters.type',

    /** Статус */
    ASPECT__FILTERS__STATUS = 'aspect.filters.status',

    /** Арендатор */
    ASPECT__FILTERS__RENTER = 'aspect.filters.renter',

    /** Проект */
    ASPECT__FILTERS__PROJECT = 'aspect.filters.project',

    /** Скрыть неактивные */
    ASPECT__FILTERS__HIDE_INACTIVE = 'aspect.filters.hideInactive',

    /** Скрыть архив */
    ASPECT__FILTERS__HIDE_ARCHIVE = 'aspect.filters.hideArchive',

    /** Наличие проблемы */
    ASPECT__FILTERS__PROBLEM = 'aspect.filters.problem',

    /** Отсутствие проблемы */
    ASPECT__FILTERS__PROBLEM__VALUE__WITHOUT_PROBLEM = 'aspect.filters.problem.value.withoutProblem',

    /** Просрочка */
    ASPECT__FILTERS__PROBLEM__VALUE__WITH_DELAY = 'aspect.filters.problem.value.withDelay',

    /** Нехватка */
    ASPECT__FILTERS__PROBLEM__VALUE__WITH_SHORTAGE = 'aspect.filters.problem.value.withShortage',

    /** Нехватка экземпляра */
    ASPECT__FILTERS__PROBLEM__VALUE__WITH_INSTANCE_SHORTAGE = 'aspect.filters.problem.value.withInstanceShortage',

    /** Любой вид проблемы */
    ASPECT__FILTERS__PROBLEM__VALUE__WITH_ANY_PROBLEM = 'aspect.filters.problem.value.withAnyProblem',

    /** Начало с */
    ASPECT__FILTERS__START_DATE_FROM = 'aspect.filters.startDateFrom',

    /** Завершение с */
    ASPECT__FILTERS__END_DATE_FROM = 'aspect.filters.endDateFrom',

    /** по */
    ASPECT__FILTERS__UPPER_DATE_LIMIT_LABEL = 'aspect.filters.upperDateLimitLabel',

    /** Создана c */
    ASPECT__FILTERS__CREATION_DATE_TITLE_PART = 'aspect.filters.creationDateTitlePart',

    /** Продукт */
    ASPECT__FILTERS__PRODUCT = 'aspect.filters.product',

    /** Любой */
    ASPECT__FILTERS__PRODUCT__PLACEHOLDER = 'aspect.filters.product.placeholder',

    /** Сортировка по */
    ASPECT__FILTERS__SORT_BY = 'aspect.filters.sortBy',

    /** Арендаторам */
    ASPECT__FILTERS__SORT_BY_RENTER = 'aspect.filters.sortByRenter',

    /** Проектам */
    ASPECT__FILTERS__SORT_BY_PROJECT = 'aspect.filters.sortByProject',

    /** Продуктам */
    ASPECT__FILTERS__SORT_BY_PRODUCT = 'aspect.filters.sortByProduct',

    /** Статусу */
    ASPECT__FILTERS__SORT_BY_STATE = 'aspect.filters.sortByState',

    /** Последняя активность с */
    ASPECT__FILTERS__LAST_ACTIVITY_DATE = 'aspect.filters.lastActivityDate',

    /** При нажатии открывает и прячет блок с дополнительными продвинутыми фильтрами */
    ASPECT__FILTERS__TOOLTIP__A2 = 'aspect.filters.tooltip.a2',

    /** Создать новые */
    ASPECT__CATEGORY_TREE_FILTER__CREATE_NEW_CATEGORIES_LINK = 'aspect.categoryTreeFilter.createNewCategoriesLink',

    /** Автор */
    ASPECT__FILTERS__OPERATIONS__AUTHOR_FIELD = 'aspect.filters.operations.authorField',

    /** Любой */
    ASPECT__FILTERS__OPERATIONS__AUTHOR_FIELD__PLACEHOLDER = 'aspect.filters.operations.authorField.placeholder',

    /** Цена за смену */
    ASPECT__FILTERS__PRODUCTS__PRICE_PER_SHIFT = 'aspect.filters.products.pricePerShift',

    /** Выберите сотрудника */
    ASPECT__FORMS__PLACEHOLDER__SELECT_EMPLOYEE = 'aspect.forms.placeholder.selectEmployee',

    /** Выберите категории */
    ASPECT__FORMS__PLACEHOLDER__SELECT_CATEGORY = 'aspect.forms.placeholder.selectCategory',

    /** Выберите арендатора */
    ASPECT__FORMS__PLACEHOLDER__SELECT_RENTER = 'aspect.forms.placeholder.selectRenter',

    /** Изображение в формате PNG или JPEG */
    ASPECT__FORMS__IMAGE_FORMAT_RESCTRICTIONS_STRING = 'aspect.forms.imageFormatResctrictionsString',

    /** Изображения в формате PNG или JPEG */
    ASPECT__FORMS__IMAGES_FORMAT_RESCTRICTIONS_STRING = 'aspect.forms.imagesFormatResctrictionsString',

    /** Скачать файл */
    ASPECT__FORMS__DOWNLOAD_FILE = 'aspect.forms.downloadFile',

    /** Загрузить файл */
    ASPECT__FORMS__UPLOAD_FILE = 'aspect.forms.uploadFile',

    /** Загрузить файлы */
    ASPECT__FORMS__UPLOAD_FILES = 'aspect.forms.uploadFiles',

    /** Файл */
    ASPECT__FORMS__PROBLEM__FILE_NOT_UPLOADED_BEGINNING = 'aspect.forms.problem.fileNotUploadedBeginning',

    /** не загружен */
    ASPECT__FORMS__PROBLEM__FILE_NOT_UPLOADED_ENDING = 'aspect.forms.problem.fileNotUploadedEnding',

    /** Выберите страны */
    ASPECT__FORMS__PLACEHOLDER__SELECT_COUNTRIES = 'aspect.forms.placeholder.selectCountries',

    /** Выберите категории */
    ASPECT__FORMS__PLACEHOLDER__SELECT_CATEGORIES = 'aspect.forms.placeholder.selectCategories',

    /** Основное изображение */
    ASPECT__FORMS__MAIN_IMAGE = 'aspect.forms.mainImage',

    /** Дополнительные изображения */
    ASPECT__FORMS__EXTRA_IMAGES = 'aspect.forms.extraImages',

    /** Длина поля не должна быть меньше */
    ASPECT__FORMS__VALIDATION__FIELD_LENGTH_MINIMUM = 'aspect.forms.validation.fieldLengthMinimum',

    /** Длина поля должна быть */
    ASPECT__FORMS__VALIDATION__FIELD_LENGTH_MUST_BE_EXACTLY = 'aspect.forms.validation.fieldLengthMustBeExactly',

    /** символов */
    ASPECT__FORMS__VALIDATION__SYMBOLS = 'aspect.forms.validation.symbols',

    /** Поле должно соответствовать шаблону */
    ASPECT__FORMS__VALIDATION__FIELD_MUST_MATCH_THE_PATTERN = 'aspect.forms.validation.fieldMustMatchThePattern',

    /** Длина поля не должна превышать */
    ASPECT__FORMS__VALIDATION__FIELD_LENGTH_MAXIMUM = 'aspect.forms.validation.fieldLengthMaximum',

    /** с учетом скрытых тегов форматирования */
    ASPECT__FORMS__VALIDATION__INCLUDING_FORMATTING_TAGS = 'aspect.forms.validation.includingFormattingTags',

    /** Новый */
    ASPECT__STATE_CODE__PRODUCT__NEW = 'aspect.stateCode.product.NEW',

    /** Активный */
    ASPECT__STATE_CODE__PRODUCT__ACTIVE = 'aspect.stateCode.product.ACTIVE',

    /** Недоступен */
    ASPECT__STATE_CODE__PRODUCT__NOTAVAILABLE = 'aspect.stateCode.product.NOTAVAILABLE',

    /** Новый */
    ASPECT__STATE_CODE__RENTER__NEW = 'aspect.stateCode.renter.NEW',

    /** Активный */
    ASPECT__STATE_CODE__RENTER__ACTIVE = 'aspect.stateCode.renter.ACTIVE',

    /** Заблокирован */
    ASPECT__STATE_CODE__RENTER__BLOCKED = 'aspect.stateCode.renter.BLOCKED',

    /** Новый */
    ASPECT__STATE_CODE__PROJECT__NEW = 'aspect.stateCode.project.NEW',

    /** В работе */
    ASPECT__STATE_CODE__PROJECT__INPROGRESS = 'aspect.stateCode.project.INPROGRESS',

    /** Завершен */
    ASPECT__STATE_CODE__PROJECT__FINISHED = 'aspect.stateCode.project.FINISHED',

    /** Новая */
    ASPECT__STATE_CODE__SHIPPING__NEW = 'aspect.stateCode.shipping.NEW',

    /** В работе */
    ASPECT__STATE_CODE__SHIPPING__INPROGRESS = 'aspect.stateCode.shipping.INPROGRESS',

    /** Завершена */
    ASPECT__STATE_CODE__SHIPPING__FINISHED = 'aspect.stateCode.shipping.FINISHED',

    /** В заявке */
    ASPECT__STATE_CODE__ELEMENT__ORDERED = 'aspect.stateCode.element.ORDERED',

    /** Бронь */
    ASPECT__STATE_CODE__ELEMENT__BOOKED = 'aspect.stateCode.element.BOOKED',

    /** В аренде */
    ASPECT__STATE_CODE__ELEMENT__RENT = 'aspect.stateCode.element.RENT',

    /** Возвращено */
    ASPECT__STATE_CODE__ELEMENT__RETURNED = 'aspect.stateCode.element.RETURNED',

    /** Повреждено */
    ASPECT__STATE_CODE__ELEMENT__RETURNED_BROKEN = 'aspect.stateCode.element.RETURNED_BROKEN',

    /** Утрачено */
    ASPECT__STATE_CODE__ELEMENT__LOST_DURING_RENT = 'aspect.stateCode.element.LOST_DURING_RENT',

    /** Отменено */
    ASPECT__STATE_CODE__ELEMENT__CANCELED = 'aspect.stateCode.element.CANCELED',

    /** Разный */
    ASPECT__STATE_CODE__ELEMENT__OTHER = 'aspect.stateCode.element.OTHER',

    /** Черновик */
    ASPECT__STATE_CODE__ELEMENT__DRAFT = 'aspect.stateCode.element.DRAFT',

    /** Черновик СА */
    ASPECT__STATE_CODE__ELEMENT__SUBRENT_DRAFT = 'aspect.stateCode.element.SUBRENT_DRAFT',

    /** Бронь СА */
    ASPECT__STATE_CODE__ELEMENT__SUBRENT_SHIPMENT_BOOKED = 'aspect.stateCode.element.SUBRENT_SHIPMENT_BOOKED',

    /** Субаренда */
    ASPECT__STATE_CODE__ELEMENT__SUBRENT = 'aspect.stateCode.element.SUBRENT',

    /** Завершено СА */
    ASPECT__STATE_CODE__ELEMENT__SUBRENT_RETURNED_TO_SHIPPER = 'aspect.stateCode.element.SUBRENT_RETURNED_TO_SHIPPER',

    /** Отменено  СА */
    ASPECT__STATE_CODE__ELEMENT__SUBRENT_CANCELED = 'aspect.stateCode.element.SUBRENT_CANCELED',

    /** Новый */
    ASPECT__STATE_CODE__USER__NEW = 'aspect.stateCode.user.NEW',

    /** Приглашен */
    ASPECT__STATE_CODE__USER__INVITED = 'aspect.stateCode.user.INVITED',

    /** Активный */
    ASPECT__STATE_CODE__USER__ACTIVE = 'aspect.stateCode.user.ACTIVE',

    /** Заблокирован */
    ASPECT__STATE_CODE__USER__BLOCKED = 'aspect.stateCode.user.BLOCKED',

    /** Не учтен */
    ASPECT__STATE_CODE__INSTANCE__NOT_IN_STOCK = 'aspect.stateCode.instance.notInStock',

    /** Активный */
    ASPECT__STATE_CODE__INSTANCE__ACTIVE = 'aspect.stateCode.instance.active',

    /** На обслуживании */
    ASPECT__STATE_CODE__INSTANCE__UNDERMAINTENANCE = 'aspect.stateCode.instance.undermaintenance',

    /** Списан */
    ASPECT__STATE_CODE__INSTANCE__DECOMMISSIONED = 'aspect.stateCode.instance.decommissioned',

    /** Не учтен */
    ASPECT__STATE_CODE__INSTANCE__UNACCOUNTED = 'aspect.stateCode.instance.unaccounted',

    /** Учтен */
    ASPECT__STATE_CODE__INSTANCE__ACCOUNTED = 'aspect.stateCode.instance.accounted',

    /** Арендная активность */
    ASPECT__NOTIFICATION_MESSAGE__TYPE_CODE__RENTACTIVITY = 'aspect.notificationMessage.typeCode.RENTACTIVITY',

    /** Таймауты */
    ASPECT__NOTIFICATION_MESSAGE__TYPE_CODE__TIMEOUTNOTICE = 'aspect.notificationMessage.typeCode.TIMEOUTNOTICE',

    /** Предупреждение */
    ASPECT__NOTIFICATION_MESSAGE__TYPE_CODE__WARNING = 'aspect.notificationMessage.typeCode.WARNING',

    /** Проблема */
    ASPECT__NOTIFICATION_MESSAGE__TYPE_CODE__PROBLEM = 'aspect.notificationMessage.typeCode.PROBLEM',

    /** Назначение */
    ASPECT__NOTIFICATION_MESSAGE__TYPE_CODE__ASSIGNMENT = 'aspect.notificationMessage.typeCode.ASSIGNMENT',

    /** Назначение на работы */
    ASPECT__NOTIFICATION_MESSAGE__TYPE_CODE__ASSIGNMENTTOWORK = 'aspect.notificationMessage.typeCode.ASSIGNMENTTOWORK',

    /** Новый платеж с витрины */
    ASPECT__NOTIFICATION_MESSAGE__TYPE_CODE__NEWPAYMENTFROMSTOREFRONT = 'aspect.notificationMessage.typeCode.NEWPAYMENTFROMSTOREFRONT',

    /** Ошибка соединения */
    ASPECT__POPUP_NOTIFICATIONS__CONNECTION_ERROR = 'aspect.popupNotifications.connectionError',

    /** Нет подключения к интернету или сервер недоступен */
    ASPECT__POPUP_NOTIFICATIONS__NO_INTERNET_OR_SERVER_UNAVAILABLE = 'aspect.popupNotifications.noInternetOrServerUnavailable',

    /** Внутренняя ошибка сервера */
    ASPECT__POPUP_NOTIFICATIONS__INTERNAL_SERVER_ERROR = 'aspect.popupNotifications.internalServerError',

    /** Ошибка валидации */
    ASPECT__POPUP_NOTIFICATIONS__VALIDATION_ERROR = 'aspect.popupNotifications.validationError',

    /** Возможно, внесенные изменения не сохранятся */
    ASPECT__POPUP_NOTIFICATIONS__BROWSER_TAB_CLOSING_CHANGES_MAY_NOT_SAVE = 'aspect.popupNotifications.browserTabClosingChangesMayNotSave',

    /** Попробуйте повторить запрос */
    ASPECT__POPUP_NOTIFICATIONS__TRY_TO_REPEAT_YOUR_REQUEST_AGAIN = 'aspect.popupNotifications.tryToRepeatYourRequestAgain',

    /** Краткое наименование (полное наименование отсутствует) */
    ASPECT__GLOBAL__TOOLTIP__SHORT_NAME_FULL_NAME_DOES_NOT_EXISTS = 'aspect.global.tooltip.shortNameFullNameDoesNotExists',

    /** Чекбокс */
    ASPECT__CUSTOM_FIELD__BOOLEAN = 'aspect.customField.BOOLEAN',

    /** Целое число */
    ASPECT__CUSTOM_FIELD__LONG = 'aspect.customField.LONG',

    /** Число */
    ASPECT__CUSTOM_FIELD__DOUBLE = 'aspect.customField.DOUBLE',

    /** Строка */
    ASPECT__CUSTOM_FIELD__STRING = 'aspect.customField.STRING',

    /** Дата */
    ASPECT__CUSTOM_FIELD__DATE = 'aspect.customField.DATE',

    /** Список чекбоксов */
    ASPECT__CUSTOM_FIELD__MULTIPLE__BOOLEAN = 'aspect.customField.multiple.BOOLEAN',

    /** Список целых чисел */
    ASPECT__CUSTOM_FIELD__MULTIPLE__LONG = 'aspect.customField.multiple.LONG',

    /** Список чисел */
    ASPECT__CUSTOM_FIELD__MULTIPLE__DOUBLE = 'aspect.customField.multiple.DOUBLE',

    /** Список строк */
    ASPECT__CUSTOM_FIELD__MULTIPLE__STRING = 'aspect.customField.multiple.STRING',

    /** Список дат */
    ASPECT__CUSTOM_FIELD__MULTIPLE__DATE = 'aspect.customField.multiple.DATE',

    /** Кол-во экземпляров */
    ASPECT__RENT_INDICATORS__INSTANCE_COUNT = 'aspect.rentIndicators.instanceCount',

    /** Сумма до скидки */
    ASPECT__RENT_INDICATORS__PRICE_BEFORE_DISCOUNT = 'aspect.rentIndicators.priceBeforeDiscount',

    /** Скидка */
    ASPECT__RENT_INDICATORS__DISCOUNT = 'aspect.rentIndicators.discount',

    /** Сумма до налога */
    ASPECT__RENT_INDICATORS__COST_BEFORE_TAXES = 'aspect.rentIndicators.costBeforeTaxes',

    /** Налог */
    ASPECT__RENT_INDICATORS__TAX_RATE = 'aspect.rentIndicators.taxRate',

    /** Итого */
    ASPECT__RENT_INDICATORS__FINAL_TOTAL_PRICE = 'aspect.rentIndicators.finalTotalPrice',

    /** Описание */
    ASPECT__FIELDS__COMMON__DESCRIPTION = 'aspect.fields.common.description',

    /** Комментарий */
    ASPECT__FIELDS__COMMON__COMMENT = 'aspect.fields.common.comment',

    /** Тип */
    ASPECT__FIELDS__COMMON__TYPE = 'aspect.fields.common.type',

    /** Не указано */
    ASPECT__FIELDS__COMMON__UNSPECIFIED = 'aspect.fields.common.unspecified',

    /** Пол */
    ASPECT__FIELDS__COMMON__GENDER = 'aspect.fields.common.gender',

    /** Краткое наименование */
    ASPECT__FIELDS__COMMON__SHORT_TITLE = 'aspect.fields.common.shortTitle',

    /** Полное наименование */
    ASPECT__FIELDS__COMMON__FULL_NAME = 'aspect.fields.common.fullName',

    /** Код */
    ASPECT__FIELDS__COMMON__CODE = 'aspect.fields.common.code',

    /** Скидка */
    ASPECT__FIELDS__COMMON__DISCOUNT = 'aspect.fields.common.discount',

    /** Ответственный */
    ASPECT__FIELDS__COMMON__ASSIGNEE = 'aspect.fields.common.assignee',

    /** Категории */
    ASPECT__FIELDS__COMMON__CATEGORIES = 'aspect.fields.common.categories',

    /** Изображение */
    ASPECT__FIELDS__COMMON__IMAGE = 'aspect.fields.common.image',

    /** Дата создания */
    ASPECT__FIELDS__COMMON__CREATION_DATE = 'aspect.fields.common.creationDate',

    /** Автор создания */
    ASPECT__FIELDS__COMMON__AUTHOR_CREATION = 'aspect.fields.common.authorCreation',

    /** Дата последнего изменения */
    ASPECT__FIELDS__COMMON__LAST_UPDATE_DATE = 'aspect.fields.common.lastUpdateDate',

    /** Автор последнего изменения */
    ASPECT__FIELDS__COMMON__LAST_UPDATE_AUTHOR = 'aspect.fields.common.lastUpdateAuthor',

    /** Скидка по умолчанию */
    ASPECT__FIELDS__COMMON__DEFAULT_DISCOUNT = 'aspect.fields.common.defaultDiscount',

    /** Контактное лицо */
    ASPECT__FIELDS__COMMON__CONTACT_PERSON = 'aspect.fields.common.contactPerson',

    /** Должность */
    ASPECT__FIELDS__COMMON__POSITION = 'aspect.fields.common.position',

    /** Мнемонический код */
    ASPECT__FIELDS__COMMON__MNEMO_KEY = 'aspect.fields.common.mnemoKey',

    /** Валюта */
    ASPECT__FIELDS__COMMON__CURRENCY = 'aspect.fields.common.currency',

    /** Цена за смену */
    ASPECT__FIELDS__COMMON__PRICE_PER_SHIFT = 'aspect.fields.common.pricePerShift',

    /** ИП */
    ASPECT__FIELDS__LEGAL_ATTRIBUTES__INDIVIDUAL_ENTREPRENEUR_SHORT = 'aspect.fields.legalAttributes.individualEntrepreneurShort',

    /** Юр. лицо */
    ASPECT__FIELDS__LEGAL_ATTRIBUTES__LEGAL_ENTITY_SHORT = 'aspect.fields.legalAttributes.legalEntityShort',

    /** Физ. лицо */
    ASPECT__FIELDS__LEGAL_ATTRIBUTES__PHYSICAL_ENTITY_SHORT = 'aspect.fields.legalAttributes.physicalEntityShort',

    /** ИНН */
    ASPECT__FIELDS__LEGAL_ATTRIBUTES__INN = 'aspect.fields.legalAttributes.inn',

    /** КПП */
    ASPECT__FIELDS__LEGAL_ATTRIBUTES__KPP = 'aspect.fields.legalAttributes.kpp',

    /** ОГРН */
    ASPECT__FIELDS__LEGAL_ATTRIBUTES__OGRN = 'aspect.fields.legalAttributes.ogrn',

    /** ИННИП */
    ASPECT__FIELDS__LEGAL_ATTRIBUTES__INNIP = 'aspect.fields.legalAttributes.innip',

    /** ОГРНИП */
    ASPECT__FIELDS__LEGAL_ATTRIBUTES__OGRNIP = 'aspect.fields.legalAttributes.ogrnip',

    /** Адрес нахождения */
    ASPECT__FIELDS__LEGAL_ATTRIBUTES__PHYSICAL_ADDRESS = 'aspect.fields.legalAttributes.physicalAddress',

    /** Дата рождения */
    ASPECT__FIELDS__LEGAL_ATTRIBUTES__BIRTH_DATE = 'aspect.fields.legalAttributes.birthDate',

    /** Юридический адрес */
    ASPECT__FIELDS__LEGAL_ATTRIBUTES__LEGAL_ADDRESS = 'aspect.fields.legalAttributes.legalAddress',

    /** Физическое лицо */
    ASPECT__FIELDS__LEGAL_ATTRIBUTES__NATURAL_PERSON = 'aspect.fields.legalAttributes.naturalPerson',

    /** Индивидуальный предприниматель */
    ASPECT__FIELDS__LEGAL_ATTRIBUTES__INDIVIDUAL_ENTREPRENEUR = 'aspect.fields.legalAttributes.individualEntrepreneur',

    /** Юридическое лицо */
    ASPECT__FIELDS__LEGAL_ATTRIBUTES__LEGAL_ENTITY = 'aspect.fields.legalAttributes.legalEntity',

    /** Серия и номер паспорта */
    ASPECT__FIELDS__LEGAL_ATTRIBUTES__PASSPORT_NUMBER = 'aspect.fields.legalAttributes.passportNumber',

    /** Стоимость проектов */
    ASPECT__FIELDS__RENTER__PROJECTS_COST = 'aspect.fields.renter.projectsCost',

    /** Дата начала */
    ASPECT__FIELDS__PROJECT__START_DATE = 'aspect.fields.project.startDate',

    /** Ожидаемая дата завершения */
    ASPECT__FIELDS__PROJECT__TARGET_FINISH_DATE = 'aspect.fields.project.targetFinishDate',

    /** Фактическая дата завершения */
    ASPECT__FIELDS__PROJECT__REAL_FINISH_DATE = 'aspect.fields.project.realFinishDate',

    /** Дата начала */
    ASPECT__FIELDS__PROJECT__START_DATE_PROJECT = 'aspect.fields.project.startDateProject',

    /** Страна(ы) производители */
    ASPECT__FIELDS__PRODUCT__COUNTRIES_OF_PRODUCTION = 'aspect.fields.product.countriesOfProduction',

    /** Общее количество */
    ASPECT__FIELDS__PRODUCT__TOTAL_AMOUNT = 'aspect.fields.product.totalAmount',

    /** Компания производитель */
    ASPECT__FIELDS__PRODUCT__COMPANY_OF_PRODUCTION = 'aspect.fields.product.companyOfProduction',

    /** Дата последней аренды */
    ASPECT__FIELDS__PRODUCT__LAST_RENT_END_DATE = 'aspect.fields.product.lastRentEndDate',

    /** Наименование */
    ASPECT__FIELDS__INSTANCE__CODE_OR_NAME = 'aspect.fields.instance.codeOrName',

    /** Статус учета */
    ASPECT__FIELDS__INSTANCE__INVENTORY_STATE = 'aspect.fields.instance.inventoryState',

    /** Доступность */
    ASPECT__FIELDS__INSTANCE__AVAILABILITY = 'aspect.fields.instance.availability',

    /** Последнее обслуживание */
    ASPECT__FIELDS__INSTANCE__LAST_MAINENANCE = 'aspect.fields.instance.lastMainenance',

    /** Производится сейчас */
    ASPECT__FIELDS__INSTANCE__IN_PROGRESS_NOW = 'aspect.fields.instance.inProgressNow',

    /** Официальный инвентарный номер */
    ASPECT__FIELDS__INSTANCE__INVENTORY_NUMBER = 'aspect.fields.instance.inventoryNumber',

    /** Раздел в разработке */
    PAGE__ERROR__UNDER_DEVELOPMENT__SHORT_MESSAGE = 'page.error.underDevelopment.shortMessage',

    /** Доступ запрещен */
    PAGE__ERROR__FORBIDDEN__SHORT_MESSAGE = 'page.error.forbidden.shortMessage',

    /** У вас недостаточно прав для просмотра данной страницы. */
    PAGE__ERROR__FORBIDDEN__FULL_MESSAGE = 'page.error.forbidden.fullMessage',

    /** Раздел не найден */
    PAGE__ERROR__NOT_FOUND__SHORT_MESSAGE = 'page.error.notFound.shortMessage',

    /** Статус */
    PAGE__DASHBOARD__TABS__STATUS = 'page.dashboard.tabs.status',

    /** Аналитика */
    PAGE__DASHBOARD__TABS__ANALYTICS = 'page.dashboard.tabs.analytics',

    /** Выдачи */
    PAGE__DASHBOARD__GIVE_AWAYS = 'page.dashboard.giveAways',

    /** Возвраты */
    PAGE__DASHBOARD__RETURNS = 'page.dashboard.returns',

    /** Сегодня и ранее */
    PAGE__DASHBOARD__DATE_PICKER_OPTION_TODAY_OR_EARLIER = 'page.dashboard.datePickerOptionTodayOrEarlier',

    /** Информация о всех просрочках по аренде в компании (просрочки возрата арендаторами и просрочки выдачи арендаторам) */
    PAGE__DASHBOARD__TOOLTIP__WIDGET__DELAYED = 'page.dashboard.tooltip.widget.delayed',

    /** Информация о всех нехватках по аренде в компании (нехватки по брони и нехватки по заявкам) */
    PAGE__DASHBOARD__TOOLTIP__WIDGET__SHORTAGES = 'page.dashboard.tooltip.widget.shortages',

    /** Информация о всех обязательствах в статусе "Бронь" */
    PAGE__DASHBOARD__TOOLTIP__WIDGET__BOOKED = 'page.dashboard.tooltip.widget.booked',

    /** Информация о всех обязательствах в статусе "В заявке" */
    PAGE__DASHBOARD__TOOLTIP__WIDGET__ORDERED = 'page.dashboard.tooltip.widget.ordered',

    /** Информация о всех обязательствах, которые находятся в статусе "В аренде" */
    PAGE__DASHBOARD__TOOLTIP__WIDGET__RENTED = 'page.dashboard.tooltip.widget.rented',

    /** Информация о продуктах, которые находятся на обслуживании */
    PAGE__DASHBOARD__TOOLTIP__WIDGET__UNDER_MAINTENANCE = 'page.dashboard.tooltip.widget.underMaintenance',

    /** Фильтр по просрочке выдачи, на кнопке пишется общее число обязательств в брони с данной просрочкой */
    PAGE__DASHBOARD__TOOLTIP__WIDGET__006 = 'page.dashboard.tooltip.widget.006',

    /** Фильтр по нехватки в брони, на кнопке пишется общее число обязательств с данный типом нехватки */
    PAGE__DASHBOARD__TOOLTIP__WIDGET__007 = 'page.dashboard.tooltip.widget.007',

    /** Отображаемый в списках период, чтобы попасть в результат поиска выдача или возврат должны попасть в данный интервал. Неделя и месяц берутся как 7 и 30 суток от текущего момента соотстветственно */
    PAGE__DASHBOARD__TOOLTIP__WIDGET__020 = 'page.dashboard.tooltip.widget.020',

    /** Уточните параметры поиска и попробуйте снова! */
    PAGE__CALENDAR__EMPTY_LIST_MESSAGE = 'page.calendar.emptyListMessage',

    /** Для поиска по неактивным обязательствам переключите режим */
    PAGE__CALENDAR__EMPTY_LIST_MESSAGE_SHOW_INACTIVE = 'page.calendar.emptyListMessageShowInactive',

    /** Отображаются */
    PAGE__CALENDAR__ROWS_PER_PAGE = 'page.calendar.rowsPerPage',

    /** Все время */
    PAGE__CALENDAR__ANY_TIME = 'page.calendar.anyTime',

    /** Разные (только наборы) */
    PAGE__CALENDAR__TAB_LABEL_MIXED_STATES = 'page.calendar.tabLabelMixedStates',

    /** Черновики */
    PAGE__CALENDAR__TAB_LABEL_DRAFTS = 'page.calendar.tabLabelDrafts',

    /** Вид отображения: */
    PAGE__CALENDAR__POPUP_LABEL_RENDER_TYPE = 'page.calendar.popupLabelRenderType',

    /** Нормальный */
    PAGE__CALENDAR__RENDER_TYPE_OPTION_NORMAL = 'page.calendar.renderTypeOptionNormal',

    /** Компактный */
    PAGE__CALENDAR__RENDER_TYPE_OPTION_COMPACT = 'page.calendar.renderTypeOptionCompact',

    /** Показать все обязательства */
    PAGE__CALENDAR__SHOW_ALL_ELEMENTS_BUTTON = 'page.calendar.showAllElementsButton',

    /** Началу аренды */
    PAGE__CALENDAR__GROUPING_OPTION__RENT_PERIOD_START = 'page.calendar.groupingOption.rentPeriodStart',

    /** Окончанию аренды */
    PAGE__CALENDAR__GROUPING_OPTION__RENT_PERIOD_END = 'page.calendar.groupingOption.rentPeriodEnd',

    /** Выбор интервала времени для отображения */
    PAGE__CALENDAR__TOOLTIP__A0 = 'page.calendar.tooltip.a0',

    /** Фильтр по активным статусам обязательств: "В заявке", "Бронь" и "В аренде" */
    PAGE__CALENDAR__TOOLTIP__A3 = 'page.calendar.tooltip.a3',

    /** Фильтр по наличию проблемы или предупрежения на обязательстве */
    PAGE__CALENDAR__TOOLTIP__A4 = 'page.calendar.tooltip.a4',

    /** Фильтр по статусу обязательства. Если в наборе у обязательств разные статусы, в выдачу попадет только его часть с нужным статусом (исключение - статус "Разный", при его выборе производится поиск только наборов в данном статусе со всем содержимым) */
    PAGE__CALENDAR__TOOLTIP__A5 = 'page.calendar.tooltip.a5',

    /** Текстовый поиск по наименованию продукта, набора, варианта и артикулу */
    PAGE__CALENDAR__TOOLTIP__A6 = 'page.calendar.tooltip.a6',

    /** Фильтр по контрагенту */
    PAGE__CALENDAR__TOOLTIP__A7 = 'page.calendar.tooltip.a7',

    /** Фильтр по проекту */
    PAGE__CALENDAR__TOOLTIP__A8 = 'page.calendar.tooltip.a8',

    /** Фильтр по поставке */
    PAGE__CALENDAR__TOOLTIP__A9 = 'page.calendar.tooltip.a9',

    /** Фильтр по операции */
    PAGE__CALENDAR__TOOLTIP__A10 = 'page.calendar.tooltip.a10',

    /** Фильтр по набору */
    PAGE__CALENDAR__TOOLTIP__A11 = 'page.calendar.tooltip.a11',

    /** Фильтр по продукту */
    PAGE__CALENDAR__TOOLTIP__A12 = 'page.calendar.tooltip.a12',

    /** Фильтр по варианту */
    PAGE__CALENDAR__TOOLTIP__A13 = 'page.calendar.tooltip.a13',

    /** Фильтр по экземпляру */
    PAGE__CALENDAR__TOOLTIP__A14 = 'page.calendar.tooltip.a14',

    /** По поставщику */
    PAGE__SHIPPINGS__FIELD_VALUE_BY_SUPPLIER = 'page.shippings.fieldValueBySupplier',

    /** Отправить поставку в архив? */
    PAGE__SHIPPINGS__MODAL__QUESTIONS__SEND_TO_ARCHIVE = 'page.shippings.modal.questions.sendToArchive',

    /** Завершить поставку? */
    PAGE__SHIPPINGS__MODAL__QUESTIONS__FINISH = 'page.shippings.modal.questions.finish',

    /** Возобновить поставку? */
    PAGE__SHIPPINGS__MODAL__QUESTIONS__RESUME = 'page.shippings.modal.questions.resume',

    /** Стартовать поставку? */
    PAGE__SHIPPINGS__MODAL__QUESTIONS__START = 'page.shippings.modal.questions.start',

    /** Сумма оплаты */
    PAGE__SHIPPINGS__MODAL__PAYMENT__PAYMENT_SUM = 'page.shippings.modal.payment.paymentSum',

    /** Долг перед поставщиком после оплаты */
    PAGE__SHIPPINGS__MODAL__PAYMENT__DEBT_AFTER_SUM = 'page.shippings.modal.payment.debtAfterSum',

    /** Оплата поставки */
    PAGE__SHIPPINGS__MODAL__PAYMENT__LABEL = 'page.shippings.modal.payment.label',

    /** Стартовать */
    PAGE__SHIPPINGS__ACTIONS__START = 'page.shippings.actions.start',

    /** Завершить */
    PAGE__SHIPPINGS__ACTIONS__FINISH = 'page.shippings.actions.finish',

    /** Возобновить */
    PAGE__SHIPPINGS__ACTIONS__RESUME = 'page.shippings.actions.resume',

    /** Поставка создана */
    PAGE__SHIPPINGS__POPUP_NOTIFICATIONS__CREATED = 'page.shippings.popupNotifications.created',

    /** Поставка изменена */
    PAGE__SHIPPINGS__POPUP_NOTIFICATIONS__SHIPMENT_UPDATED = 'page.shippings.popupNotifications.shipmentUpdated',

    /** Поставки изменены */
    PAGE__SHIPPINGS__POPUP_NOTIFICATIONS__SHIPMENTS_UPDATED = 'page.shippings.popupNotifications.shipmentsUpdated',

    /** Поставка отправлена в архив */
    PAGE__SHIPPINGS__POPUP_NOTIFICATIONS__ARCHIVED = 'page.shippings.popupNotifications.archived',

    /** Поставка возвращена из архива */
    PAGE__SHIPPINGS__POPUP_NOTIFICATIONS__UNARCHIVED = 'page.shippings.popupNotifications.unarchived',

    /** Не удалось загрузить список поставок */
    PAGE__SHIPPINGS__POPUP_NOTIFICATIONS__GET_LIST_FAILED = 'page.shippings.popupNotifications.getListFailed',

    /** Статус поставки изменен */
    PAGE__SHIPPINGS__POPUP_NOTIFICATIONS__SHIPMENT_STATE_UPDATED = 'page.shippings.popupNotifications.shipmentStateUpdated',

    /** Статус поставок изменен */
    PAGE__SHIPPINGS__POPUP_NOTIFICATIONS__SHIPMENTS_STATE_UPDATED = 'page.shippings.popupNotifications.shipmentsStateUpdated',

    /** Не удалось обновить поставку */
    PAGE__SHIPPINGS__POPUP_NOTIFICATIONS__UPDATE_FAILED = 'page.shippings.popupNotifications.updateFailed',

    /** Оплата по поставке выполнена успешно */
    PAGE__SHIPPINGS__POPUP_NOTIFICATIONS__PAYMENT_ACCEPTED = 'page.shippings.popupNotifications.paymentAccepted',

    /** Ожидаемое завершение */
    PAGE__SHIPPINGS__LIST__COLUMN__TARGET_FINISH_DATE = 'page.shippings.list.column.targetFinishDate',

    /** Фактическое завершение */
    PAGE__SHIPPINGS__LIST__COLUMN__REAL_FINISH_DATE = 'page.shippings.list.column.realFinishDate',

    /** Стоимость */
    PAGE__SHIPPINGS__CARD__FIELD__COST = 'page.shippings.card.field.cost',

    /** Оплачено */
    PAGE__SHIPPINGS__CARD__PAYMENTS_ACCEPTED_SUM = 'page.shippings.card.paymentsAcceptedSum',

    /** Долг перед поставщиком */
    PAGE__SHIPPINGS__CARD__DEBT_SUM = 'page.shippings.card.debtSum',

    /** Дата начала должна быть раньше даты завершения */
    PAGE__SHIPPINGS__FORM__START_DATE_MUST_BE_EARLIER = 'page.shippings.form.startDateMustBeEarlier',

    /** Дата завершения должна быть позже даты начала */
    PAGE__SHIPPINGS__FORM__END_DATE_MUST_BE_LATER = 'page.shippings.form.endDateMustBeLater',

    /** Создание новой поставки */
    PAGE__SHIPPINGS__FORM__FORM_TITLE__CREATE = 'page.shippings.form.formTitle.create',

    /** Редактирование поставки */
    PAGE__SHIPPINGS__FORM__FORM_TITLE__EDIT = 'page.shippings.form.formTitle.edit',

    /** Шаблон */
    PAGE__SHIPPINGS__FORM__FIELD__TEMPLATE = 'page.shippings.form.field.template',

    /** Проекты */
    PAGE__PROJECTS__SUPER_PAGE_TITLE = 'page.projects.superPageTitle',

    /** Проекты */
    PAGE__PROJECTS__SUBSECTION_NAME_PROJECT = 'page.projects.subsectionNameProject',

    /** По арендатору */
    PAGE__PROJECTS__FIELD_VALUE_BY_RENTER = 'page.projects.fieldValueByRenter',

    /** Отправить проект в архив? */
    PAGE__PROJECTS__MODAL__QUESTIONS__SEND_TO_ARCHIVE = 'page.projects.modal.questions.sendToArchive',

    /** Завершить проект? */
    PAGE__PROJECTS__MODAL__QUESTIONS__FINISH = 'page.projects.modal.questions.finish',

    /** Возобновить проект? */
    PAGE__PROJECTS__MODAL__QUESTIONS__RESUME = 'page.projects.modal.questions.resume',

    /** Стартовать проект? */
    PAGE__PROJECTS__MODAL__QUESTIONS__START = 'page.projects.modal.questions.start',

    /** Сумма оплаты */
    PAGE__PROJECTS__MODAL__PAYMENT__PAYMENT_SUM = 'page.projects.modal.payment.paymentSum',

    /** Долг после оплаты */
    PAGE__PROJECTS__MODAL__PAYMENT__DEBT_AFTER_SUM = 'page.projects.modal.payment.debtAfterSum',

    /** Прием оплаты по проекту */
    PAGE__PROJECTS__MODAL__PAYMENT__LABEL = 'page.projects.modal.payment.label',

    /** Принять оплату */
    PAGE__PROJECTS__ACTIONS__ACCEPT_PAYMENT = 'page.projects.actions.acceptPayment',

    /** Стартовать */
    PAGE__PROJECTS__ACTIONS__START = 'page.projects.actions.start',

    /** Завершить */
    PAGE__PROJECTS__ACTIONS__FINISH = 'page.projects.actions.finish',

    /** Возобновить */
    PAGE__PROJECTS__ACTIONS__RESUME = 'page.projects.actions.resume',

    /** Проект создан */
    PAGE__PROJECTS__POPUP_NOTIFICATIONS__CREATED = 'page.projects.popupNotifications.created',

    /** Проект изменен */
    PAGE__PROJECTS__POPUP_NOTIFICATIONS__PROJECT_UPDATED = 'page.projects.popupNotifications.projectUpdated',

    /** Проекты изменены */
    PAGE__PROJECTS__POPUP_NOTIFICATIONS__PROJECTS_UPDATED = 'page.projects.popupNotifications.projectsUpdated',

    /** Проект отправлен в архив */
    PAGE__PROJECTS__POPUP_NOTIFICATIONS__ARCHIVED = 'page.projects.popupNotifications.archived',

    /** Проект возвращен из архива */
    PAGE__PROJECTS__POPUP_NOTIFICATIONS__UNARCHIVED = 'page.projects.popupNotifications.unarchived',

    /** Не удалось загрузить список проектов */
    PAGE__PROJECTS__POPUP_NOTIFICATIONS__GET_LIST_FAILED = 'page.projects.popupNotifications.getListFailed',

    /** Статус проекта изменен */
    PAGE__PROJECTS__POPUP_NOTIFICATIONS__PROJECT_STATE_UPDATED = 'page.projects.popupNotifications.projectStateUpdated',

    /** Статус проектов изменен */
    PAGE__PROJECTS__POPUP_NOTIFICATIONS__PROJECTS_STATE_UPDATED = 'page.projects.popupNotifications.projectsStateUpdated',

    /** Не удалось обновить проект */
    PAGE__PROJECTS__POPUP_NOTIFICATIONS__UPDATE_FAILED = 'page.projects.popupNotifications.updateFailed',

    /** Оплата по проекту выполнена успешно */
    PAGE__PROJECTS__POPUP_NOTIFICATIONS__PAYMENT_ACCEPTED = 'page.projects.popupNotifications.paymentAccepted',

    /** Ожидаемое завершение */
    PAGE__PROJECTS__SUPER_PAGE_TITLE__COLUMN__TARGET_FINISH_DATE = 'page.projects.superPageTitle.column.targetFinishDate',

    /** Фактическое завершение */
    PAGE__PROJECTS__SUPER_PAGE_TITLE__COLUMN__REAL_FINISH_DATE = 'page.projects.superPageTitle.column.realFinishDate',

    /** Ожидаемая дата завершения проекта */
    PAGE__PROJECTS__CARD__FIELD__TARGET_FINISH_DATE__LONG_TITLE = 'page.projects.card.field.targetFinishDate.longTitle',

    /** Черновая стоимость до налогов */
    PAGE__PROJECTS__CARD__FIELD__DRAFT_COST_BEFORE_TAXES = 'page.projects.card.field.draftCostBeforeTaxes',

    /** Стоимость до налогов */
    PAGE__PROJECTS__CARD__FIELD__COST_BEFORE_TAXES = 'page.projects.card.field.costBeforeTaxes',

    /** Черновая стоимость после налогов */
    PAGE__PROJECTS__CARD__FIELD__DRAFTCOST_AFTER_TAXES = 'page.projects.card.field.draftcostAfterTaxes',

    /** Стоимость после налогов */
    PAGE__PROJECTS__CARD__FIELD__COST_AFTER_TAXES = 'page.projects.card.field.costAfterTaxes',

    /** Оплачено */
    PAGE__PROJECTS__CARD__PAYMENTS_ACCEPTED_SUM = 'page.projects.card.paymentsAcceptedSum',

    /** Долг */
    PAGE__PROJECTS__CARD__DEBT_SUM = 'page.projects.card.debtSum',

    /** Налог */
    PAGE__PROJECTS__CARD__TAXES_SUM = 'page.projects.card.taxesSum',

    /** Полное наименование проекта уже использовано */
    PAGE__PROJECTS__FORM__FULL_NAME_ALREADY_USED = 'page.projects.form.fullNameAlreadyUsed',

    /** Краткое наименование проекта уже использовано */
    PAGE__PROJECTS__FORM__SHORT_NAME_ALREADY_USED = 'page.projects.form.shortNameAlreadyUsed',

    /** Дата начала не должна быть позже даты окончания */
    PAGE__PROJECTS__FORM__START_DATE_MUST_BE_EARLIER = 'page.projects.form.startDateMustBeEarlier',

    /** Дата окончания не должна быть раньше даты начала */
    PAGE__PROJECTS__FORM__END_DATE_MUST_BE_LATER = 'page.projects.form.endDateMustBeLater',

    /** Создание нового проекта */
    PAGE__PROJECTS__FORM__FORM_TITLE__CREATE = 'page.projects.form.formTitle.create',

    /** Редактирование проекта */
    PAGE__PROJECTS__FORM__FORM_TITLE__EDIT = 'page.projects.form.formTitle.edit',

    /** Шаблон */
    PAGE__PROJECTS__FORM__FIELD__TEMPLATE = 'page.projects.form.field.template',

    /** Налог (в процентах) */
    PAGE__PROJECTS__FORM__FIELD__TAX_PERCENT = 'page.projects.form.field.taxPercent',

    /** Краткое наименование уже используется в текущем арендаторе */
    PAGE__PROJECTS__FORM__VALIDATION__SHORT_NAME_USED_IN_RENTER = 'page.projects.form.validation.shortNameUsedInRenter',

    /** Полное наименование уже используется в текущем арендаторе */
    PAGE__PROJECTS__FORM__VALIDATION__FULL_NAME_USED_IN_RENTER = 'page.projects.form.validation.fullNameUsedInRenter',

    /** Создать шаблон */
    PAGE__TEMPLATES__MODAL__CREATE = 'page.templates.modal.create',

    /** Проект по шаблону */
    PAGE__TEMPLATES__MODAL__CREATE_PROJECT_FROM_TEMPLATE = 'page.templates.modal.createProjectFromTemplate',

    /** Отправить шаблон в архив? */
    PAGE__TEMPLATES__MODAL__QUESTIONS__SEND_TO_ARCHIVE = 'page.templates.modal.questions.sendToArchive',

    /** Шаблон создан */
    PAGE__TEMPLATES__POPUP_NOTIFICATIONS__CREATED = 'page.templates.popupNotifications.created',

    /** Шаблон изменен */
    PAGE__TEMPLATES__POPUP_NOTIFICATIONS__TEMPLATE_UPDATED = 'page.templates.popupNotifications.templateUpdated',

    /** Шаблоны изменены */
    PAGE__TEMPLATES__POPUP_NOTIFICATIONS__TEMPLATES_UPDATED = 'page.templates.popupNotifications.templatesUpdated',

    /** Шаблон отправлен в архив */
    PAGE__TEMPLATES__POPUP_NOTIFICATIONS__ARCHIVED = 'page.templates.popupNotifications.archived',

    /** Шаблон возвращен из архива */
    PAGE__TEMPLATES__POPUP_NOTIFICATIONS__UNARCHIVED = 'page.templates.popupNotifications.unarchived',

    /** Не удалось загрузить список шаблонов */
    PAGE__TEMPLATES__POPUP_NOTIFICATIONS__FAILED_TO_LOAD_TEMPLATE_LIST = 'page.templates.popupNotifications.failedToLoadTemplateList',

    /** Редактирование шаблона */
    PAGE__TEMPLATES__FORM__UPDATE_FORM_LABEL = 'page.templates.form.updateFormLabel',

    /** Создание нового шаблона */
    PAGE__TEMPLATES__FORM__CREATION_FORM_LABEL = 'page.templates.form.creationFormLabel',

    /** Полное наименование шаблона уже использовано */
    PAGE__TEMPLATES__FORM__VALIDATION__FULL_NAME_ALREADY_USED = 'page.templates.form.validation.fullNameAlreadyUsed',

    /** Краткое наименование шаблона уже использовано */
    PAGE__TEMPLATES__FORM__VALIDATION__SHORT_NAME_ALREADY_USED = 'page.templates.form.validation.shortNameAlreadyUsed',

    /** Настроить поля */
    PAGE__PRODUCTS__CUSTOMIZE_FIELDS_BUTTON = 'page.products.customizeFieldsButton',

    /** Выберите поле */
    PAGE__PRODUCTS__SELECT_FIELD_BUTTON = 'page.products.selectFieldButton',

    /** Название группы */
    PAGE__PRODUCTS__GROUP_NAME = 'page.products.groupName',

    /** Характеристики отсутствуют */
    PAGE__PRODUCTS__FEATURE_LIST_IS_EMPTY = 'page.products.featureListIsEmpty',

    /** Список характеристик */
    PAGE__PRODUCTS__FEATURE_LIST = 'page.products.featureList',

    /** Закупочная стоимость */
    PAGE__PRODUCTS__PURCHASE_PRICE = 'page.products.purchasePrice',

    /** Описание состояния */
    PAGE__PRODUCTS__CONDITION_DESCRIPTION = 'page.products.conditionDescription',

    /** Настройка полей */
    PAGE__PRODUCTS__CUSTOM_FIELDS_SETUP = 'page.products.customFieldsSetup',

    /** Создание новой группы */
    PAGE__PRODUCTS__GROUP_CREATION_MODAL = 'page.products.groupCreationModal',

    /** Создать группу */
    PAGE__PRODUCTS__CREATE_GROUP = 'page.products.createGroup',

    /** Приложенные файлы */
    PAGE__PRODUCTS__ATTACHED_FILES = 'page.products.attachedFiles',

    /** Основные */
    PAGE__PRODUCTS__MAIN_ONES_LABEL = 'page.products.mainOnesLabel',

    /** Группа с таким названием уже существует */
    PAGE__PRODUCTS__GROUP_NAME_ALREADY_EXISTS = 'page.products.groupNameAlreadyExists',

    /** Характеристики продукта */
    PAGE__PRODUCTS__PRODUCT_FEATURES = 'page.products.productFeatures',

    /** Счетные экземпляры */
    PAGE__PRODUCTS__INSTANCE_TRACKING_TYPE__PRODUCT_BULK = 'page.products.instanceTrackingType.productBulk',

    /** Именованные экземпляры */
    PAGE__PRODUCTS__INSTANCE_TRACKING_TYPE__PRODUCT_TRACKED = 'page.products.instanceTrackingType.productTracked',

    /** Счетные экземпляры, разбитые на варианты */
    PAGE__PRODUCTS__INSTANCE_TRACKING_TYPE__PRODUCT_VARIANT_BULK = 'page.products.instanceTrackingType.productVariantBulk',

    /** Именованные экземпляры, разбитые на варианты */
    PAGE__PRODUCTS__INSTANCE_TRACKING_TYPE__PRODUCT_VARIANT_TRACKED = 'page.products.instanceTrackingType.productVariantTracked',

    /** Создать продукт */
    PAGE__PRODUCTS__ACTIONS__CREATE = 'page.products.actions.create',

    /** Задать количество */
    PAGE__PRODUCTS__ACTIONS__SET_AMOUNT = 'page.products.actions.setAmount',

    /** Поля отсутствуют */
    PAGE__PRODUCTS__POPUP_NOTIFICATIONS__LIST_PLACEHOLDER_NO_FIELDS_EXIST = 'page.products.popupNotifications.listPlaceholderNoFieldsExist',

    /** Требуется добавить настраиваемые поля на продукт */
    PAGE__PRODUCTS__POPUP_NOTIFICATIONS__LIST_PLACEHOLDER_ADD_CUSTOM_FIELDS = 'page.products.popupNotifications.listPlaceholderAddCustomFields',

    /** Название группы уже использовано */
    PAGE__PRODUCTS__POPUP_NOTIFICATIONS__VALIDATION__GROUP_NAME_ALREADY_EXISTS = 'page.products.popupNotifications.validation.groupNameAlreadyExists',

    /** Отправить продукт в архив? */
    PAGE__PRODUCTS__MODAL__QUESTIONS__ARCHIVE = 'page.products.modal.questions.archive',

    /** Активировать продукт? */
    PAGE__PRODUCTS__MODAL__QUESTIONS__ACTIVATE = 'page.products.modal.questions.activate',

    /** Заблокировать продукт? */
    PAGE__PRODUCTS__MODAL__QUESTIONS__BLOCK = 'page.products.modal.questions.block',

    /** Разблокировать продукт? */
    PAGE__PRODUCTS__MODAL__QUESTIONS__UNBLOCK = 'page.products.modal.questions.unblock',

    /** Пустые группы будут удалены. Продолжить? */
    PAGE__PRODUCTS__MODAL__QUESTIONS__DELETE_EMPTY_GROUPS = 'page.products.modal.questions.deleteEmptyGroups',

    /** Продукт создан */
    PAGE__PRODUCTS__POPUP_NOTIFICATIONS__CREATED = 'page.products.popupNotifications.created',

    /** Продукт изменен */
    PAGE__PRODUCTS__POPUP_NOTIFICATIONS__UPDATED = 'page.products.popupNotifications.updated',

    /** Продукт отправлен в архив */
    PAGE__PRODUCTS__POPUP_NOTIFICATIONS__ARCHIVED = 'page.products.popupNotifications.archived',

    /** Продукт возвращен из архива */
    PAGE__PRODUCTS__POPUP_NOTIFICATIONS__UNARCHIVED = 'page.products.popupNotifications.unarchived',

    /** Не удалось загрузить список продуктов */
    PAGE__PRODUCTS__POPUP_NOTIFICATIONS__GET_LIST_FAILED = 'page.products.popupNotifications.getListFailed',

    /** Статус продукта изменен */
    PAGE__PRODUCTS__POPUP_NOTIFICATIONS__PRODUCT_STATE_UPDATED = 'page.products.popupNotifications.productStateUpdated',

    /** Статус продуктов изменен */
    PAGE__PRODUCTS__POPUP_NOTIFICATIONS__PRODUCTS_STATE_UPDATED = 'page.products.popupNotifications.productsStateUpdated',

    /** Поля обновить не удалось */
    PAGE__PRODUCTS__POPUP_NOTIFICATIONS__CUSTOM_FIELDS_SETUP_UPDATE_FAILED = 'page.products.popupNotifications.customFieldsSetupUpdateFailed',

    /** Поля успешно обновлены */
    PAGE__PRODUCTS__POPUP_NOTIFICATIONS__CUSTOM_FIELDS_SETUP_UPDATED = 'page.products.popupNotifications.customFieldsSetupUpdated',

    /** Список продуктов */
    PAGE__PRODUCTS__TAB__PRODUCT_LIST = 'page.products.tab.productList',

    /** Основные характеристики */
    PAGE__PRODUCTS__CARD__BLOCK__MAIN_FEATURES = 'page.products.card.block.mainFeatures',

    /** Размеры (ШxВxГ) */
    PAGE__PRODUCTS__CARD__DIMENSIONS = 'page.products.card.dimensions',

    /** Вычисляемые показатели */
    PAGE__PRODUCTS__CARD__BLOCK__INDICATORS = 'page.products.card.block.Indicators',

    /** Доступен */
    PAGE__PRODUCTS__CARD__AVAILABLE_MALE_COUNT = 'page.products.card.availableMaleCount',

    /** Доступно */
    PAGE__PRODUCTS__CARD__AVAILABLE_NEUTER_COUNT = 'page.products.card.availableNeuterCount',

    /** Занят */
    PAGE__PRODUCTS__CARD__NOT_AVAILABLE_COUNT = 'page.products.card.notAvailableCount',

    /** Варианты */
    PAGE__PRODUCTS__CARD__VARIANTS = 'page.products.card.variants',

    /** Субаренда */
    PAGE__PRODUCTS__CARD__SUBRENT = 'page.products.card.subrent',

    /** Добавить характеристики */
    PAGE__PRODUCTS__CARD__ADD_FEATURES = 'page.products.card.addFeatures',

    /** Смотреть характеристики */
    PAGE__PRODUCTS__CARD__SHOW_FEATURES = 'page.products.card.showFeatures',

    /** Cмотреть все характеристики */
    PAGE__PRODUCTS__CARD__SHOW_ALL_FEATURES = 'page.products.card.showAllFeatures',

    /** Смотреть все */
    PAGE__PRODUCTS__CARD__SHOW_ALL = 'page.products.card.showAll',

    /** Полное наименование продукта уже использовано */
    PAGE__PRODUCTS__FORM__VALIDATION__FULL_NAME_ALREADY_USED = 'page.products.form.validation.fullNameAlreadyUsed',

    /** Краткое наименование продукта уже использовано */
    PAGE__PRODUCTS__FORM__VALIDATION__SHORT_NAME_ALREADY_USED = 'page.products.form.validation.shortNameAlreadyUsed',

    /** Артикул продукта уже использован */
    PAGE__PRODUCTS__FORM__VALIDATION__EXTERNAL_CODE_ALREADY_USED = 'page.products.form.validation.externalCodeAlreadyUsed',

    /** Общее число экземпляров */
    PAGE__PRODUCTS__FORM__FIELD__TOTAL_AMOUNT__LONG_TITLE = 'page.products.form.field.totalAmount.longTitle',

    /** Число экземпляров на обслуживании */
    PAGE__PRODUCTS__FORM__FIELD__UNDER_MAINTENANCE_AMOUNT__LONG_TITLE = 'page.products.form.field.underMaintenanceAmount.longTitle',

    /** Число экземпляров на обслуживании не должно быть больше общего числа экземпляров */
    PAGE__PRODUCTS__FORM__VALIDATION__UNDER_MAINTENANCE_MORE_THAN_TOTAL = 'page.products.form.validation.underMaintenanceMoreThanTotal',

    /** Редактирование продукта */
    PAGE__PRODUCTS__FORM__FORM_TITLE__EDIT = 'page.products.form.formTitle.edit',

    /** Создание продукта */
    PAGE__PRODUCTS__FORM__FORM_TITLE__CREATE = 'page.products.form.formTitle.create',

    /** Изменение количества экземпляров */
    PAGE__PRODUCTS__FORM__AMOUNT_EDITION_FORM_LABEL = 'page.products.form.amountEditionFormLabel',

    /** Наименование компании производителя */
    PAGE__PRODUCTS__FORM__FIELD__COMPANY_OF_PRODUCTION__LONG_TITLE = 'page.products.form.field.companyOfProduction.longTitle',

    /** Цена должна быть ниже 100 млн */
    PAGE__PRODUCTS__FORM__VALIDATION__PRICE_TOO_HIGH = 'page.products.form.validation.priceTooHigh',

    /** Дополнительные характеристики */
    PAGE__PRODUCTS__FORM__BLOCK__EXTRA_FEAUTURES = 'page.products.form.block.extraFeautures',

    /** Состоит из вариантов */
    PAGE__PRODUCTS__FORM__INCLUDES_VARIATIONS = 'page.products.form.includesVariations',

    /** Отслеживание индивидуальных экземпляров */
    PAGE__PRODUCTS__FORM__INDIVIDUAL_INSTANCE_TRACKING = 'page.products.form.individualInstanceTracking',

    /** Компоненты */
    PAGE__KITS__TAB__COMPONENTS = 'page.kits.tab.components',

    /** Создать набор */
    PAGE__ACTIONS__CREATE_KIT = 'page.actions.createKit',

    /** Настроить компоненты */
    PAGE__ACTIONS__CUSTOMIZE_COMPONENTS = 'page.actions.customizeComponents',

    /** Добавить компонент */
    PAGE__ACTIONS__ADD_COMPONENT = 'page.actions.addComponent',

    /** Разблокировать набор? */
    PAGE__MODAL__QUESTIONS__UNBLOCK = 'page.modal.questions.unblock',

    /** Заблокировать набор? */
    PAGE__MODAL__QUESTIONS__BLOCK = 'page.modal.questions.block',

    /** Отправить набор в архив? */
    PAGE__MODAL__QUESTIONS__SEND_TO_ARCHIVE = 'page.modal.questions.sendToArchive',

    /** Вы уверены что хотите удалить компонент из набора? */
    PAGE__MODAL__QUESTIONS__REMOVE_COMPONENT = 'page.modal.questions.removeComponent',

    /** Статус набора изменен */
    PAGE__KITS__POPUP_NOTIFICATIONS__STATE_CHANGED = 'page.kits.popupNotifications.stateChanged',

    /** Не удалось загрузить список наборов */
    PAGE__KITS__POPUP_NOTIFICATIONS__FAILED_TO_GET_KIT_LIST = 'page.kits.popupNotifications.failedToGetKitList',

    /** Статусы наборов изменены */
    PAGE__KITS__POPUP_NOTIFICATIONS__KIT_STATES_CHANGED = 'page.kits.popupNotifications.kitStatesChanged',

    /** Набор создан */
    PAGE__KITS__POPUP_NOTIFICATIONS__KIT_CREATED = 'page.kits.popupNotifications.kitCreated',

    /** Не удалось сохранить изменение в наборе т.к. он был изменен пока вы работали с ним */
    PAGE__KITS__POPUP_NOTIFICATIONS__KIT_SAVE_VERSION_CONFLICT = 'page.kits.popupNotifications.kitSaveVersionConflict',

    /** Не удалось изменить набор */
    PAGE__KITS__POPUP_NOTIFICATIONS__UPDATE_FAILED = 'page.kits.popupNotifications.updateFailed',

    /** Список компонентов набора изменен */
    PAGE__KITS__POPUP_NOTIFICATIONS__KIT_MEMBERS_UPDATED = 'page.kits.popupNotifications.kitMembersUpdated',

    /** Защита от перезаписи изменений. Набор был изменен другим пользователем или в другой вкладке во время редактирования */
    PAGE__KITS__POPUP_NOTIFICATIONS__UPDATE_VERSION_CONFLICT = 'page.kits.popupNotifications.updateVersionConflict',

    /** Набор изменен */
    PAGE__KITS__POPUP_NOTIFICATIONS__KIT_UPDATED = 'page.kits.popupNotifications.kitUpdated',

    /** Наборы изменены */
    PAGE__KITS__POPUP_NOTIFICATIONS__KITS_UPDATES = 'page.kits.popupNotifications.kitsUpdates',

    /** Набор отправлен в архив */
    PAGE__KITS__POPUP_NOTIFICATIONS__KIT_ARCHIVED = 'page.kits.popupNotifications.kitArchived',

    /** Набор возвращен из архива */
    PAGE__KITS__POPUP_NOTIFICATIONS__KIT_UNARCHIVED = 'page.kits.popupNotifications.kitUnarchived',

    /** Редактирование набора */
    PAGE__KITS__FORM__FORM_TITLE__EDIT = 'page.kits.form.formTitle.edit',

    /** Создание набора */
    PAGE__KITS__FORM__FORM_TITLE__CREATE = 'page.kits.form.formTitle.create',

    /** Краткое наименование набора уже использовано */
    PAGE__KITS__FORM__VALIDATIONS__SHORT_NAME_ALREADY_USED = 'page.kits.form.validations.shortNameAlreadyUsed',

    /** Полное наименование набора уже использовано */
    PAGE__KITS__FORM__VALIDATIONS__FULL_NAME_ALREADY_USED = 'page.kits.form.validations.fullNameAlreadyUsed',

    /** Имеет основной компонент */
    PAGE__KITS__FORM__HAS_MAIN_MEMBER = 'page.kits.form.hasMainMember',

    /** Компонентов */
    PAGE__KITS__DESCRIPTION_TAB__NUMBER_OF_COMPONENTS = 'page.kits.descriptionTab.numberOfComponents',

    /** Настройка компонентов */
    PAGE__KITS__COMPONENTS_TAB__LABEL__COMPONENTS_CUSTOMIZATION = 'page.kits.componentsTab.label.componentsCustomization',

    /** Список компонентов */
    PAGE__KITS__COMPONENTS_TAB__LABEL__COMPONENTS_LIST = 'page.kits.componentsTab.label.componentsList',

    /** Выберите номенклатуру */
    PAGE__KITS__COMPONENTS_TAB__SELECT_NOMENCLATURE = 'page.kits.componentsTab.selectNomenclature',

    /** Компоненты отсутствуют */
    PAGE__KITS__NO_COMPONENTS_EXIST = 'page.kits.noComponentsExist',

    /** Требуется добавить компоненты в набор */
    PAGE__KITS__NEED_TO_ADD_COMPONENTS = 'page.kits.needToAddComponents',

    /** Основной */
    PAGE__KITS__IS_MAIN_MEMBER = 'page.kits.isMainMember',

    /** Статус продукта */
    PAGE__KITS__PRODUCT_STATE = 'page.kits.productState',

    /** Цена в наборе экз./см. */
    PAGE__KITS__PRICE_WHEN_IN_KIT = 'page.kits.priceWhenInKit',

    /** Суммарная цена в наборе за см. */
    PAGE__KITS__TOTAL_PRICE_WHEN_IN_KIT = 'page.kits.totalPriceWhenInKit',

    /** Порядок */
    PAGE__KITS__ORDER = 'page.kits.order',

    /** Активировать контрагента */
    PAGE__RENTERS__ACTIONS__ACTIVATE = 'page.renters.actions.activate',

    /** Заблокировать контрагента */
    PAGE__RENTERS__ACTIONS__BLOCK = 'page.renters.actions.block',

    /** Разблокировать контрагента */
    PAGE__RENTERS__ACTIONS__UNBLOCK = 'page.renters.actions.unblock',

    /** Отправить контрагента в архив? */
    PAGE__RENTERS__MODAL__QUESTIONS__SEND_TO_ARCHIVE = 'page.renters.modal.questions.sendToArchive',

    /** Активировать контрагента? */
    PAGE__RENTERS__MODAL__QUESTIONS__ACTIVATE = 'page.renters.modal.questions.activate',

    /** Заблокировать контрагента? */
    PAGE__RENTERS__MODAL__QUESTIONS__BLOCK = 'page.renters.modal.questions.block',

    /** Разблокировать контрагента? */
    PAGE__RENTERS__MODAL__QUESTIONS__RESUME = 'page.renters.modal.questions.resume',

    /** Контрагент отправлен в архив */
    PAGE__RENTERS__POPUP_NOTIFICATIONS__ARCHIVED = 'page.renters.popupNotifications.archived',

    /** Контрагент возвращен из архива */
    PAGE__RENTERS__POPUP_NOTIFICATIONS__UNARCHIVED = 'page.renters.popupNotifications.unarchived',

    /** Контрагент создан */
    PAGE__RENTERS__POPUP_NOTIFICATIONS__CREATED = 'page.renters.popupNotifications.created',

    /** Не удалось загрузить список контрагентов */
    PAGE__RENTERS__POPUP_NOTIFICATIONS__GET_LIST_FAILED = 'page.renters.popupNotifications.getListFailed',

    /** Состояние контрагента изменено */
    PAGE__RENTERS__POPUP_NOTIFICATIONS__RENTER_UPDATED = 'page.renters.popupNotifications.renterUpdated',

    /** Состояние контрагентов изменено */
    PAGE__RENTERS__POPUP_NOTIFICATIONS__RENTERS_UPDATED = 'page.renters.popupNotifications.rentersUpdated',

    /** Статус контрагента изменен */
    PAGE__RENTERS__POPUP_NOTIFICATIONS__RENTER_STATE_UPDATED = 'page.renters.popupNotifications.renterStateUpdated',

    /** Статус контрагентов изменен */
    PAGE__RENTERS__POPUP_NOTIFICATIONS__RENTERS_STATE_UPDATED = 'page.renters.popupNotifications.rentersStateUpdated',

    /** Нельзя создать проект в заблокированном контрагенте */
    PAGE__RENTERS__POPUP_NOTIFICATIONS__RENTERS_CREATE_PROJECT_IMPOSSIBLE = 'page.renters.popupNotifications.rentersCreateProjectImpossible',

    /** Не удалось изменить контрагента */
    PAGE__RENTERS__POPUP_NOTIFICATIONS__FAILED_TO_UPDATE_RENTER = 'page.renters.popupNotifications.failedToUpdateRenter',

    /** Не удалось создать контрагента */
    PAGE__RENTERS__POPUP_NOTIFICATIONS__FAILED_TO_CREATE_RENTER = 'page.renters.popupNotifications.failedToCreateRenter',

    /** Контрагенты */
    PAGE__RENTERS__LIST__TABS__COUNTERPARTIES = 'page.renters.list.tabs.counterparties',

    /** Оплачено */
    PAGE__RENTERS__CARD__PAYED_AMOUNT = 'page.renters.card.payedAmount',

    /** Юридический адрес и адрес нахождения */
    PAGE__RENTERS__CARD__LEGAL_ADDRESS_RESIDENCE = 'page.renters.card.legalAddressResidence',

    /** Полное наименование контрагента уже использовано */
    PAGE__RENTERS__CARD__FULL_NAME_ALREADY_USED = 'page.renters.card.fullNameAlreadyUsed',

    /** Краткое наименование контрагента уже использовано */
    PAGE__RENTERS__CARD__SHORT_NAME_ALREADY_USE = 'page.renters.card.shortNameAlreadyUse',

    /** Стоимость проектов до налогов */
    PAGE__RENTERS__CARD__PROJECTS_BEFORE_TAXES_COST = 'page.renters.card.projectsBeforeTaxesCost',

    /** Налоги по проектам */
    PAGE__RENTERS__CARD__PROJECTS_TAXES = 'page.renters.card.projectsTaxes',

    /** Стоимость проектов после налогов */
    PAGE__RENTERS__CARD__PROJECT_AFTER_TAXES_COST = 'page.renters.card.projectAfterTaxesCost',

    /** Поле должно содержать только символы от A до Z и от А до Я. Допустимая длина поля от 1 до 4 символов */
    PAGE__RENTERS__FORM__ONLY_CHAPTER = 'page.renters.form.onlyChapter',

    /** Мнемонический код контрагента уже использован */
    PAGE__RENTERS__FORM__MNEMONIC_CODE_ALREADY_USED = 'page.renters.form.mnemonicCodeAlreadyUsed',

    /** Поле должно содержать только цифры. Допустимая длина поля 12 символов */
    PAGE__RENTERS__FORM__VALIATION_MESSAGE_INNIP = 'page.renters.form.valiationMessageINNIP',

    /** Поле должно содержать только цифры. Допустимая длина поля 15 символов */
    PAGE__RENTERS__FORM__VALIDATION_MESSAGE_OGRNIP = 'page.renters.form.validationMessageOGRNIP',

    /** Поле должно содержать только цифры. Допустимая длина поля 10 символов */
    PAGE__RENTERS__FORM__VALIATION_MESSAGE_PASPORT = 'page.renters.form.valiationMessagePasport',

    /** Поле должно содержать только цифры. Допустимая длина поля 10 символов */
    PAGE__RENTERS__FORM__VALIATION_MESSAGE_INN = 'page.renters.form.valiationMessageINN',

    /** Поле должно соответствовать формату 0000ZZ000. Допустимая длина поля 9 символов */
    PAGE__RENTERS__FORM__VALIDATION_MESSAGE_KPP = 'page.renters.form.validationMessageKPP',

    /** Поле должно содержать только цифры. Допустимая длина поля 13 символов */
    PAGE__RENTERS__FORM__VALIDATION_MESSAGE_OGRN = 'page.renters.form.validationMessageOGRN',

    /** Поле должно содержать валидный Email адрес */
    PAGE__RENTERS__FORM__VALIDATION_MESSAGE_EMAIL = 'page.renters.form.validationMessageEmail',

    /** Редактирование контрагента */
    PAGE__RENTERS__FORM__FORM_TITLE__EDIT = 'page.renters.form.formTitle.edit',

    /** Создание нового контрагента */
    PAGE__RENTERS__FORM__FORM_TITLE__CREATE = 'page.renters.form.formTitle.create',

    /** Компания */
    PAGE__RENTERS__FORM__BLOCK__COMPANY = 'page.renters.form.block.company',

    /** Итоговая скидка */
    PAGE__OPERATIONS__TOTAL_DISCOUNT = 'page.operations.totalDiscount',

    /** Отправить операцию в архив? */
    PAGE__OPERATIONS__MODAL__QUESTIONS__SEND_TO_ARCHIVE = 'page.operations.modal.questions.sendToArchive',

    /** Сбросить текущую операцию? */
    PAGE__OPERATIONS__MODAL__QUESTIONS__CANCEL = 'page.operations.modal.questions.cancel',

    /** Операция отправлена в архив */
    PAGE__OPERATIONS__POPUP_NOTIFICATIONS__ARCHIVED = 'page.operations.popupNotifications.archived',

    /** Операция возвращена из архива */
    PAGE__OPERATIONS__POPUP_NOTIFICATIONS__UNARCHIVED = 'page.operations.popupNotifications.unarchived',

    /** Не удалось загрузить список операций */
    PAGE__OPERATIONS__POPUP_NOTIFICATIONS__GET_LIST_FAILED = 'page.operations.popupNotifications.getListFailed',

    /** Состояние операции изменено */
    PAGE__OPERATIONS__POPUP_NOTIFICATIONS__OPERATION_UPDATED = 'page.operations.popupNotifications.operationUpdated',

    /** Состояние операций изменено */
    PAGE__OPERATIONS__POPUP_NOTIFICATIONS__OPERATIONS_UPDATED = 'page.operations.popupNotifications.operationsUpdated',

    /** Комментарий изменен */
    PAGE__OPERATIONS__POPUP_NOTIFICATIONS__COMMENT_UPDATED = 'page.operations.popupNotifications.commentUpdated',

    /** Создана */
    PAGE__OPERATIONS__LIST__GRID__COLUMN__CREATION_DATE = 'page.operations.list.grid.column.creationDate',

    /** Активных обяз. */
    PAGE__OPERATIONS__LIST__GRID__COLUMN__ACTIVE_ELEMENTS_COUNT = 'page.operations.list.grid.column.activeElementsCount',

    /** Экземпляров */
    PAGE__OPERATIONS__LIST__GRID__COLUMN__INSTANCE_COUNT = 'page.operations.list.grid.column.instanceCount',

    /** Активных экз. */
    PAGE__OPERATIONS__LIST__GRID__COLUMN__ACTIVE_INSTANCES_COUNT = 'page.operations.list.grid.column.activeInstancesCount',

    /** Список операций */
    PAGE__OPERATIONS__LIST__TAB__OPERATION_LIST = 'page.operations.list.tab.operationList',

    /** Скрыть автоматические */
    PAGE__OPERATIONS__LIST__HIDE_AUTOMATIC = 'page.operations.list.hideAutomatic',

    /** Количество экземпляров в обязательстве */
    PAGE__OPERATIONS__POPUP__TOOLTIP__001 = 'page.operations.popup.tooltip.001',

    /** Текущий статус обязательства */
    PAGE__OPERATIONS__POPUP__TOOLTIP__003 = 'page.operations.popup.tooltip.003',

    /** Целевой период аренды от выдачи до возврата */
    PAGE__OPERATIONS__POPUP__TOOLTIP__005 = 'page.operations.popup.tooltip.005',

    /** Уникальный идентификатор (номер) обязательства */
    PAGE__OPERATIONS__POPUP__TOOLTIP__006 = 'page.operations.popup.tooltip.006',

    /** Количество смен, которые учитываются в рассчете стоимости обязательства */
    PAGE__OPERATIONS__POPUP__TOOLTIP__008 = 'page.operations.popup.tooltip.008',

    /** Цена смены экземляра. Берется из номенклатуры (варианта или продукта) или из компонента набора. Определяется на момент создания обязательства и далее не изменяется (исключение - обязательства в шаблонах) */
    PAGE__OPERATIONS__POPUP__TOOLTIP__009 = 'page.operations.popup.tooltip.009',

    /** Скидка, которая применяется к цене номенклатуры, для определения стоимости смены экземпляра. По умолчанию берется из проекта, может быть изменена в рамках операции */
    PAGE__OPERATIONS__POPUP__TOOLTIP__010 = 'page.operations.popup.tooltip.010',

    /** Список операций, произведенных над данным обязательством в обратном хронологическом порядке */
    PAGE__OPERATIONS__POPUP__TOOLTIP__011 = 'page.operations.popup.tooltip.011',

    /** Стоимость смены экземпляра, равна цене за вычетом скидки */
    PAGE__OPERATIONS__POPUP__TOOLTIP__012 = 'page.operations.popup.tooltip.012',

    /** Сумма скидки за все смены и по всем экземплярам */
    PAGE__OPERATIONS__POPUP__TOOLTIP__013 = 'page.operations.popup.tooltip.013',

    /** Сумма стоимости за все смены и по всем экземплярам */
    PAGE__OPERATIONS__POPUP__TOOLTIP__014 = 'page.operations.popup.tooltip.014',

    /** Время просрочки. Отсчитывается от целевого момента выдачи для заявки или бронирования или от целевого момента возврата для аренды */
    PAGE__OPERATIONS__POPUP__TOOLTIP__016 = 'page.operations.popup.tooltip.016',

    /** Количество экземляров которые требуется сделать доступными для аренды в целевой период, чтобы обязательство стало выполнимым */
    PAGE__OPERATIONS__POPUP__TOOLTIP__017 = 'page.operations.popup.tooltip.017',

    /** Список отслеживаемых экземляров обязетельства. Красным отмечены те, по которым обнаружена нехватка, с учетом доступности конкретного экземпляра */
    PAGE__OPERATIONS__POPUP__TOOLTIP__018 = 'page.operations.popup.tooltip.018',

    /** Перейти к проекту */
    PAGE__OPERATIONS__POPUP__TOOLTIP__GO_TO_PROJECT = 'page.operations.popup.tooltip.goToProject',

    /** Перейти к шаблону */
    PAGE__OPERATIONS__POPUP__TOOLTIP__GO_TO_PROJECT_TEMPLATE = 'page.operations.popup.tooltip.goToProjectTemplate',

    /** Перейти к арендатору */
    PAGE__OPERATIONS__POPUP__TOOLTIP__GO_TO_RENTER = 'page.operations.popup.tooltip.goToRenter',

    /** Перейти к поставщику */
    PAGE__OPERATIONS__POPUP__TOOLTIP__GO_TO_SUPPLIER = 'page.operations.popup.tooltip.goToSupplier',

    /** Перейти в календарь */
    PAGE__OPERATIONS__POPUP__TOOLTIP__GO_TO_CALENDAR = 'page.operations.popup.tooltip.goToCalendar',

    /** Редактировать */
    PAGE__OPERATIONS__POPUP__TOOLTIP__EDIT = 'page.operations.popup.tooltip.edit',

    /** Закрыть */
    PAGE__OPERATIONS__POPUP__TOOLTIP__CLOSE = 'page.operations.popup.tooltip.close',

    /** Перейти к поставке */
    PAGE__OPERATIONS__POPUP__TOOLTIP__GO_TO_SHIPPING = 'page.operations.popup.tooltip.goToShipping',

    /** История */
    PAGE__OPERATIONS__CARD__TAB__HISTORY = 'page.operations.card.tab.history',

    /** Целевой статус */
    PAGE__OPERATIONS__CARD__TARGET_ELEMENTS_STATE = 'page.operations.card.targetElementsState',

    /** Активных обязательств */
    PAGE__OPERATIONS__CARD__ACTIVE_ELEMENTS_COUNT = 'page.operations.card.activeElementsCount',

    /** Всего обязательств */
    PAGE__OPERATIONS__CARD__ELEMENTS_TOTAL_AMOUNT = 'page.operations.card.elementsTotalAmount',

    /** Параметры создания */
    PAGE__OPERATIONS__CARD__RENT_TERMS_ON_CREATION = 'page.operations.card.rentTermsOnCreation',

    /** Начало аренды */
    PAGE__OPERATIONS__CARD__RENT_PERIOD_START_DATE = 'page.operations.card.rentPeriodStartDate',

    /** Окончание аренды */
    PAGE__OPERATIONS__CARD__RENT_PERIOD_END_DATE = 'page.operations.card.rentPeriodEndDate',

    /** Сумма до скидок */
    PAGE__OPERATIONS__CARD__TOTAL_PRICE_BEFORE_DISCOUNT = 'page.operations.card.totalPriceBeforeDiscount',

    /** Итоговая скидка */
    PAGE__OPERATIONS__CARD__TOTAL_DISCOUNT = 'page.operations.card.totalDiscount',

    /** Состав при создании */
    PAGE__OPERATIONS__CARD__COMPOSITION_ON_CREATION = 'page.operations.card.compositionOnCreation',

    /** Общие параметры при создании */
    PAGE__OPERATIONS__CARD__COMMON_RENT_TERMS_ON_CREATION = 'page.operations.card.commonRentTermsOnCreation',

    /** Смены к оплате */
    PAGE__OPERATIONS__CARD__SHIFT_COUNT = 'page.operations.card.shiftCount',

    /** Редактирование комментария */
    PAGE__OPERATIONS__FORM__FORM_TITLE__COMMENT = 'page.operations.form.formTitle.comment',

    /** Поиск на складе */
    PAGE__NEW_OPERATION__TAB__WAREHOUSE_SEARCH = 'page.newOperation.tab.warehouseSearch',

    /** Текущий состав */
    PAGE__NEW_OPERATION__TAB__CURRENT_COMPOSITION = 'page.newOperation.tab.currentComposition',

    /** Экземпляров */
    PAGE__NEW_OPERATION__INDICATOR__AMOUNT_OF_INSTANCES = 'page.newOperation.indicator.amountOfInstances',

    /** Невозможно применить операцию: состав операции пуст. */
    PAGE__NEW_OPERATION__TOOLTIP__DISABLED_SUBMIT_BUTTON_ON_EMPTY_COMPOSITION = 'page.newOperation.tooltip.disabledSubmitButtonOnEmptyComposition',

    /** Сбросить операцию */
    PAGE__NEW_OPERATION__ACTIONS__DISCARD_OPERATION = 'page.newOperation.actions.discardOperation',

    /** Добавить в состав */
    PAGE__NEW_OPERATION__ACTIONS__ADD_TO_THE_COMPOSITION = 'page.newOperation.actions.addToTheComposition',

    /** Убрать из операции */
    PAGE__NEW_OPERATION__ACTIONS__REMOVE_FROM_OPERATION = 'page.newOperation.actions.removeFromOperation',

    /** доступных */
    PAGE__MODAL__ACTIONS__OF_XAVAILABLE_LABEL_END = 'page.modal.actions.ofXAvailableLabelEnd',

    /** Предупреждение */
    PAGE__NEW_OPERATION__MODAL__WARNING_ON_SUBMIT__MODAL_TITLE = 'page.newOperation.modal.warningOnSubmit.modalTitle',

    /** Операция приведет к нехватке по заявкам */
    PAGE__NEW_OPERATION__MODAL__WARNING_ON_SUBMIT__OPERATION_CAUSE_REQUEST_SHORTAGE = 'page.newOperation.modal.warningOnSubmit.operationCauseRequestShortage',

    /** Проблема */
    PAGE__NEW_OPERATION__MODAL__PROBLEM_ON_SUBMIT__MODAL_TITLE = 'page.newOperation.modal.problemOnSubmit.modalTitle',

    /** Недостаточно доступных экземпляров для */
    PAGE__NEW_OPERATION__MODAL__PROBLEM_ON_SUBMIT__NOT_ENOUGH_INSTANCES_FOR = 'page.newOperation.modal.problemOnSubmit.notEnoughInstancesFor',

    /** продления */
    PAGE__NEW_OPERATION__MODAL__PROBLEM_ON_SUBMIT__NOT_ENOUGH_FOR_PROLONGATION = 'page.newOperation.modal.problemOnSubmit.notEnoughForProlongation',

    /** выдачи */
    PAGE__NEW_OPERATION__MODAL__PROBLEM_ON_SUBMIT__NOT_ENOUGH_FOR_GIVE_AWAY = 'page.newOperation.modal.problemOnSubmit.notEnoughForGiveAway',

    /** бронирования */
    PAGE__NEW_OPERATION__MODAL__PROBLEM_ON_SUBMIT__NOT_ENOUGH_FOR_BOOKING = 'page.newOperation.modal.problemOnSubmit.notEnoughForBooking',

    /** Применить операцию */
    PAGE__NEW_OPERATION__MODAL__PROBLEM_ON_SUBMIT__BUTTON__SUBMIT_OPERATION = 'page.newOperation.modal.problemOnSubmit.button.submitOperation',

    /** Операция создана */
    PAGE__NEW_OPERATION__POPUP_NOTIFICATIONS__CREATED = 'page.newOperation.popupNotifications.created',

    /** Отрицательная доступность по продукту в целевой период аренды */
    PAGE__NEW_OPERATION__POPUP_NOTIFICATIONS__CREATION_FAILED_DUE_SHORTAGE = 'page.newOperation.popupNotifications.creationFailedDueShortage',

    /** Ошибка создания операции */
    PAGE__NEW_OPERATION__POPUP_NOTIFICATIONS__CREATION_FAILED = 'page.newOperation.popupNotifications.creationFailed',

    /** {value, plural, =0 {доступные на складе} one {доступного на складе} few {доступных на складе} other {доступных на складе}} */
    PAGE__NEW_OPERATION__EDIT_ELEMENT_POPUP__AMOUNT_AVAILABLE = 'page.newOperation.editElementPopup.amountAvailable',

    /** {value, plural, =0 {экземпляров обязательств} one {экземпляра обязательства} few {экземпляров обязательств} other {экземпляров обязательств}} */
    PAGE__NEW_OPERATION__EDIT_ELEMENT_POPUP__INSTANCES_AMOUNT_IN_ELEMENT = 'page.newOperation.editElementPopup.instancesAmountInElement',

    /** Создание новой операции */
    PAGE__NEW_OPERATION__FAST_CREATION_FORM__FORM_TITLE = 'page.newOperation.fastCreationForm.formTitle',

    /** Новая операция */
    PAGE__NEW_OPERATION__FAST_CREATION_FORM__PAGE_TITLE = 'page.newOperation.fastCreationForm.pageTitle',

    /** В заявках */
    PAGE__NEW_OPERATION__TAB__WAREHOUSE__GRID__COLUMN__REQUESTED_COUNT = 'page.newOperation.tab.warehouse.grid.column.requestedCount',

    /** Добавлено */
    PAGE__NEW_OPERATION__TAB__WAREHOUSE__GRID__COLUMN__ADDED_COUNT = 'page.newOperation.tab.warehouse.grid.column.addedCount',

    /** Искать доступные в период */
    PAGE__NEW_OPERATION__TAB__WAREHOUSE__FILTER__AVAILABLE_ON_DATE_INTERVAL = 'page.newOperation.tab.warehouse.filter.availableOnDateInterval',

    /** Цена за единицу */
    PAGE__NEW_OPERATION__TAB__WAREHOUSE__FILTER__UNIT_PRICE = 'page.newOperation.tab.warehouse.filter.unitPrice',

    /** Параметры по умолчанию */
    PAGE__NEW_OPERATION__TAB__COMPOSITION__DEFAULT_RENT_TERMS = 'page.newOperation.tab.composition.defaultRentTerms',

    /** Операция пуста */
    PAGE__NEW_OPERATION__TAB__COMPOSITION__GRID__EMPTY_COMPOSITION_TITLE = 'page.newOperation.tab.composition.grid.emptyCompositionTitle',

    /** Добавьте продукты в операцию во вкладке "Поиск на складе" */
    PAGE__NEW_OPERATION__TAB__COMPOSITION__GRID__EMPTY_COMPOSITION_SUBTITLE_WAREHOUSE_AVAILABLE = 'page.newOperation.tab.composition.grid.emptyCompositionSubtitleWarehouseAvailable',

    /** Все обязательства были убраны из операции, создайте новую */
    PAGE__NEW_OPERATION__TAB__COMPOSITION__GRID__EMPTY_COMPOSITION_SUBTITLE_WAREHOUSE_UNAVAILABLE = 'page.newOperation.tab.composition.grid.emptyCompositionSubtitleWarehouseUnavailable',

    /** Не удалось загрузить список обязательств */
    PAGE__ELEMENTS__POPUP_NOTIFICATIONS__GET_LIST_FAILED = 'page.elements.popupNotifications.getListFailed',

    /** Обязательства успешно перенесены в проект */
    PAGE__ELEMENTS__POPUP_NOTIFICATIONS__ELEMENTS_MOVED_TO_PROJECT = 'page.elements.popupNotifications.elementsMovedToProject',

    /** Перенос обязательств в проект выполнить не удалось */
    PAGE__ELEMENTS__POPUP_NOTIFICATIONS__FAILED_TO_MOVE_ELEMENTS_TO_PROJECT = 'page.elements.popupNotifications.failedToMoveElementsToProject',

    /** Перенести в проект */
    PAGE__ELEMENTS__ACTIONS__MOVE_TO_PROJECT = 'page.elements.actions.moveToProject',

    /** Перенести */
    PAGE__ELEMENTS__ACTIONS__MOVE = 'page.elements.actions.move',

    /** Повторить */
    PAGE__ELEMENTS__ACTIONS__RETRY_AFTER_ERROR = 'page.elements.actions.retryAfterError',

    /** Выберите проект */
    PAGE__ELEMENTS__POPUP_NOTIFICATIONS__PLACEHOLDER__SELECT_PROJECT = 'page.elements.popupNotifications.placeholder.selectProject',

    /** Количество */
    PAGE__ELEMENTS__POPUP_CARD__AMOUNT = 'page.elements.popupCard.amount',

    /** Номер */
    PAGE__ELEMENTS__POPUP_CARD__RENT_ELEMENT_NUMBER = 'page.elements.popupCard.rentElementNumber',

    /** Текущая операция является последней произведенной над данным обязательством (актуальной) */
    PAGE__ELEMENTS__TOOLTIP__CURRENT_OPERATION = 'page.elements.tooltip.currentOperation',

    /** Родительская категория */
    PAGE__CATEGORIES__PARENT_CATEGORY = 'page.categories.parentCategory',

    /** Наименование категории */
    PAGE__CATEGORIES__CATEGORY_NAME = 'page.categories.categoryName',

    /** Вы действительно хотите удалить категорию? */
    PAGE__CATEGORIES__MODAL__QUESTIONS__DELETE = 'page.categories.modal.questions.delete',

    /** Удалить связи с */
    PAGE__CATEGORIES__MODAL__CHECKBOX__DELETE_LINKS = 'page.categories.modal.checkbox.deleteLinks',

    /** Удалить подкатегории */
    PAGE__CATEGORIES__MODAL__CHECKBOX__DELETE_SUBCATEGORIES = 'page.categories.modal.checkbox.deleteSubcategories',

    /** контрагентами */
    PAGE__CATEGORIES__MODAL__WITH_COUNT_RENTERS_LABEL_ENDING = 'page.categories.modal.withCountRentersLabelEnding',

    /** продуктами и наборами */
    PAGE__CATEGORIES__MODAL__WITH_COUNT_PRODUCTS_LABEL_ENDING = 'page.categories.modal.withCountProductsLabelEnding',

    /** Категория не удалена */
    PAGE__CATEGORIES__POPUP_NOTIFICATIONS__DELETION_FAILED = 'page.categories.popupNotifications.deletionFailed',

    /** Категория удалена */
    PAGE__CATEGORIES__POPUP_NOTIFICATIONS__DELETED = 'page.categories.popupNotifications.deleted',

    /** Категория не изменена */
    PAGE__CATEGORIES__POPUP_NOTIFICATIONS__UPDATE_FAILED = 'page.categories.popupNotifications.updateFailed',

    /** Категория изменена */
    PAGE__CATEGORIES__POPUP_NOTIFICATIONS__UPDATED = 'page.categories.popupNotifications.updated',

    /** Категория не добавлена */
    PAGE__CATEGORIES__POPUP_NOTIFICATIONS__CREATION_FAILED = 'page.categories.popupNotifications.creationFailed',

    /** Категория добавлена */
    PAGE__CATEGORIES__POPUP_NOTIFICATIONS__CREATED = 'page.categories.popupNotifications.created',

    /** Создать подкатегорию */
    PAGE__CATEGORIES__ACTIONS__CREATE_SUBCATEGORY = 'page.categories.actions.createSubcategory',

    /** Создать категорию */
    PAGE__CATEGORIES__ACTIONS__CREATE_CATEGORY = 'page.categories.actions.createCategory',

    /** Поиск по дереву категорий */
    PAGE__CATEGORIES__BLOCK_LIST__CATEGORY_TREE_SEARCH = 'page.categories.blockList.categoryTreeSearch',

    /** Создайте новые корневые категории */
    PAGE__CATEGORIES__BLOCK_LIST__PLACEHOLDER__CREATE_NEW_ROOT_CATEGORIES = 'page.categories.blockList.placeholder.createNewRootCategories',

    /** Продуктов и наборов */
    PAGE__CATEGORIES__BLOCK_CARD__COUNTER__PRODUCTS = 'page.categories.blockCard.counter.products',

    /** Контрагентов */
    PAGE__CATEGORIES__BLOCK_CARD__COUNTER__RENTERS = 'page.categories.blockCard.counter.renters',

    /** Подкатегорий */
    PAGE__CATEGORIES__BLOCK_CARD__COUNTER__SUBCATEGORIES = 'page.categories.blockCard.counter.subcategories',

    /** Продуктов в подкатегориях */
    PAGE__CATEGORIES__BLOCK_CARD__COUNTER__PRODUCTS_IN_SUBCATEGORIES = 'page.categories.blockCard.counter.productsInSubcategories',

    /** Контрагентов в подкатегориях */
    PAGE__CATEGORIES__BLOCK_CARD__COUNTER__RENTERS_IN_SUBCATEGORIES = 'page.categories.blockCard.counter.rentersInSubcategories',

    /** Заголовок страницы */
    PAGE__SETTINGS__PAGE_TITLE = 'page.settings.pageTitle',

    /** Основные */
    PAGE__SETTINGS__SECTION_TREE__BASIC = 'page.settings.sectionTree.basic',

    /** Пользователи */
    PAGE__SETTINGS__SECTION_TREE__USERS = 'page.settings.sectionTree.users',

    /** Профиль компании */
    PAGE__SETTINGS__SECTION_TREE__BA_PROFILE = 'page.settings.sectionTree.baProfile',

    /** Описание */
    PAGE__SETTINGS__SECTION_TREE__BA_PROFILE__SUBSECTION__DESCRIPTION = 'page.settings.sectionTree.baProfile.subsection.description',

    /** Контактное лицо */
    PAGE__SETTINGS__SECTION_TREE__BA_PROFILE__SUBSECTION__CONTACT_PERSON = 'page.settings.sectionTree.baProfile.subsection.contactPerson',

    /** Поля продуктов */
    PAGE__SETTINGS__SECTION_TREE__CUSTOM_FIELDS = 'page.settings.sectionTree.customFields',

    /** Ценовые схемы */
    PAGE__SETTINGS__SECTION_TREE__PRICING_SCHEMES = 'page.settings.sectionTree.pricingSchemes',

    /** Подключаемые модули */
    PAGE__SETTINGS__SECTION_TREE__MODULES = 'page.settings.sectionTree.modules',

    /** Основные настройки */
    PAGE__BASIC__BASIC_SETTINGS = 'page.basic.basicSettings',

    /** Резерв времени перед выдачей */
    PAGE__BASIC__TIME_GAP_BEFORE_GIVAWAY = 'page.basic.TimeGapBeforeGivaway',

    /** Резерв времени после выдачи */
    PAGE__BASIC__TIME_GAP_AFTER_RETURN = 'page.basic.TimeGapAfterReturn',

    /** Редактирование основных настроек */
    PAGE__BASIC__EDITING_BASE_SETTINGS = 'page.basic.EditingBaseSettings',

    /** Основные настройки изменить не удалось */
    PAGE__BASIC__MAIN_SETTINGS_COULD_NOT_BE_CHANGED = 'page.basic.MainSettingsCouldNotBeChanged',

    /** Основные настройки изменены */
    PAGE__BASIC__BASIC_SETTINGS_CHANGED = 'page.basic.BasicSettingsChanged',

    /** Не удалось загрузить основные настройки */
    PAGE__BASIC__NOTIFICATIONS__FAILED_TO_GET_BASIC_SETTINGS = 'page.basic.notifications.failedToGetBasicSettings',

    /** Основная страна работы, используется для предзаполнения страны в реквизитах компании и контрагентов */
    PAGE__BASIC__LEGAL_COUNTRY__HELP = 'page.basic.legalCountry.help',

    /** Используется только для отображения знака и наименования валюты, не имеет привязки к реальным курсам */
    PAGE__BASIC__BASE_CURRENCY__HELP = 'page.basic.baseCurrency.help',

    /** Смещение времени относительно UTC. Например, смещение для Москвы +3:00. Позволяет корректно отображать время при составлении писем с уведомлениями сотрудников, в остальных случаях используется часовой пояс компьютера пользователя */
    PAGE__BASIC__TIMEZONE_OFFSET__HELP = 'page.basic.timezoneOffset.help',

    /** Используется для пересчета числа смен при изменении периода аренды */
    PAGE__BASIC__SHIFT_LENGTH__HELP = 'page.basic.shiftLength.help',

    /** Алгоритм округления числа смен. Используется для определения того, сколько смен соответствует периоду времени. Смены округляются к целому числу или шагу ценовой схемы позиции */
    PAGE__BASIC__SHIFT_COUNT_ROUNDING__HELP = 'page.basic.shiftCountRounding.help',

    /** Используется для резервирования дополнительного времени между обязательствами аренды на осуществление физического возврата и выдачи. При создании и редактировании обязательств дополнительно проверяется доступность в этом периоде до начала и после завершения аренды */
    PAGE__BASIC__REQUIRED_TIME_INDENT_BETWEEN_ELEMENTS__HELP = 'page.basic.requiredTimeIndentBetweenElements.help',

    /** Если запрещены, то система не даст создать операцию в проекте (а также поставке и шаблоне), если другой пользователь уже работает там с формой операции */
    PAGE__BASIC__ALLOW_CONCURRENT_OPERATIONS__HELP = 'page.basic.allowConcurrentOperations.help',

    /** Если включено, то в стоимость проекта и поставки дополнительно включаются обязательства в статусе Бронь и Бронь СА соответственно, а также при расчете долга арендатора учитываются заказы в статусе Бронь. */
    PAGE__BASIC__INCLUDE_BOOKED_ELEMENTS_IN_PROJECT_SUM__HELP = 'page.basic.includeBookedElementsInProjectSum.help',

    /** Налог, который по умолчанию устанавливается на проект при его создании. Помогает быстрее заполнять форму создания проекта */
    PAGE__BASIC__DEFAULT_TAX_RATE__HELP = 'page.basic.defaultTaxRate.help',

    /** Алгоритм получения суммы налога на основе стоимости проекта. При выборе "От стоимости до налогов" налог берется напрямую, как процент стоимости. При выборе "От полной стоимости после налога", подбирается такой налог, который составлял бы нужный процент от итоговой стоимости (суммы стоимости до налогов и налога) */
    PAGE__BASIC__TAX_BASE_TYPE__HELP = 'page.basic.taxBaseType.help',

    /** Токен, используемый для получения доступа к АПИ для взаимодействия со стороны модуля витрины вашего интернет магазина. При обновлении токена, доступ по старому токену не предоставляется */
    PAGE__BASIC__STOREFRONT_ACCESS_TOKEN__HELP = 'page.basic.storefrontAccessToken.help',

    /** Если включено, то в стоимость проекта дополнительно включаются работы в статусе Запланированы. Иначе включаются только работы в статусах В работе и Завершены */
    PAGE__BASIC__INCLUDE_PLANNED_WORK_PLANNINGS_IN_PROJECT_SUM__HELP = 'page.basic.includePlannedWorkPlanningsInProjectSum.help',

    /** Требования к заполнению данных договора. Пока они не выполнены, проект нельзя перевести в статус "В работе", а заказ в статус "В аренде". Проекты уже находящиеся в статусе "В работе", не соответствующие требованиям, автоматически переводятся в статус "Согласование" */
    PAGE__BASIC__CONTRACT_VALIDATION_TYPE__HELP = 'page.basic.contractValidationType.help',

    /** Настройка задает значение по умолчанию для переключателя учета налога при создании новых затрат. Влияет только на то в каком положении будет переключатель "Облагается налогом" при открытии формы создания. */
    PAGE__BASIC__INCLUDE_TAX_IN_EXTERNAL_EXPENSE_SUM__HELP = 'page.basic.includeTaxInExternalExpenseSum.help',

    /** Токен, используемый для выгрузки календарных данных в формате iCal. При обновлении токена, доступ по старому токену не предоставляется */
    PAGE__BASIC__CALENDAR_ACCESS_TOKEN__HELP = 'page.basic.calendarAccessToken.help',

    /** Список пользователей */
    PAGE__SETTINGS__USERS__SECTION_TITLE = 'page.settings.users.sectionTitle',

    /** Роль */
    PAGE__SETTINGS__USERS__FILTER__ROLE = 'page.settings.users.filter.role',

    /** Редактирование ролей */
    PAGE__POPUP__EDIT_ROLES__POPUP_LABEL = 'page.popup.editRoles.popupLabel',

    /** Отменить приглашение? */
    PAGE__MODAL__QUESTION__CANCEL_INVITATION = 'page.modal.question.cancelInvitation',

    /** Лишить пользователя всех ролей в компании? После этого он перестанет быть членом данной компании. */
    PAGE__MODAL__QUESTION__REMOVE_USER = 'page.modal.question.removeUser',

    /** Приглашение пользователя в компанию */
    PAGE__MODAL__MODAL_TITLE = 'page.modal.modalTitle',

    /** Не удалось отправить приглашение пользователя */
    PAGE__POPUP_NOTIFICATIONS__INVITATION_SENDING_FAILED = 'page.popupNotifications.invitationSendingFailed',

    /** Приглашение отправлено */
    PAGE__POPUP_NOTIFICATIONS__INVITATION_SENT = 'page.popupNotifications.invitationSent',

    /** Приглашение отменено */
    PAGE__POPUP_NOTIFICATIONS__INVITATION_CANCELED = 'page.popupNotifications.invitationCanceled',

    /** Не удалось загрузить список ролей пользователя */
    PAGE__POPUP_NOTIFICATIONS__GET_LIST_FAILED = 'page.popupNotifications.getListFailed',

    /** Не удалось изменить роли пользователя */
    PAGE__POPUP_NOTIFICATIONS__ROLES_UPDATE_FAILED = 'page.popupNotifications.rolesUpdateFailed',

    /** Роли пользователя изменены */
    PAGE__POPUP_NOTIFICATIONS__ROLES_UPDATED = 'page.popupNotifications.rolesUpdated',

    /** Пригласить пользователя */
    PAGE__ACTIONS__INVITE_USER = 'page.actions.inviteUser',

    /** Выберите роли */
    PAGE__FORM__INVITATION__PLACEHOLDER__CHOOSE_ROLES = 'page.form.invitation.placeholder.chooseRoles',

    /** Права пользователя */
    PAGE__FORM__INVITATION__USER_AUTHORITIES = 'page.form.invitation.userAuthorities',

    /** Полное официальное наименование */
    PAGE__SETTINGS__BA_PROFILE__FIELD__FULL_NAME__LONG_TITLE = 'page.settings.baProfile.field.fullName.longTitle',

    /** Данные компании изменены */
    PAGE__POPUP_NOTIFICATIONS__UPDATED = 'page.popupNotifications.updated',

    /** Не удалось изменить данные компании */
    PAGE__POPUP_NOTIFICATIONS__UPDATE_FAILED = 'page.popupNotifications.updateFailed',

    /** Редактирование компании */
    PAGE__FORM__UPDATE__FORM_TITLE = 'page.form.update.formTitle',

    /** Отключить */
    PAGE__SETTINGS__MODULES__DISABLE_BUTTON_LABEL = 'page.settings.modules.disableButtonLabel',

    /** Включить */
    PAGE__SETTINGS__MODULES__ENABLE_BUTTON_LABEL = 'page.settings.modules.enableButtonLabel',

    /** Подключен */
    PAGE__SETTINGS__MODULES__ENABLED_STATUS = 'page.settings.modules.enabledStatus',

    /** Отключен */
    PAGE__SETTINGS__MODULES__DISABLED_STATUS = 'page.settings.modules.disabledStatus',

    /** Недоступен в тарифе */
    PAGE__SETTINGS__MODULES__UNAVAILABLED_STATUS = 'page.settings.modules.unavailabledStatus',

    /** Модуль логистики */
    PAGE__SETTINGS__MODULES__LOGISTICS_MODULE_NAME = 'page.settings.modules.logisticsModuleName',

    /** Модуль логистики позволяет контролировать географию проектов и все аспекты транспортировки между объектами. Учитывается парк транспорта, перевозки и база локаций. Есть возможность генерировать транспортную накладную. */
    PAGE__SETTINGS__MODULES__LOGISTICS_MODULE_DESCRIPTION = 'page.settings.modules.logisticsModuleDescription',

    /** Модуль персонала */
    PAGE__SETTINGS__MODULES__CREW_MODULE_NAME = 'page.settings.modules.crewModuleName',

    /** Модуль персонала позволяет автоматизировать планирование и расчет работ, выполняемых сотрудниками на проекте. Ведите базу работников, указывайте их ставки и специальности, а затем назначайте сотрудников на вакансии быстро и без ошибок в соответствии с их графиком и навыками. */
    PAGE__SETTINGS__MODULES__CREW_MODULE_DESCRIPTION = 'page.settings.modules.crewModuleDescription',

    /** Модуль статистики */
    PAGE__SETTINGS__MODULES__STATISTICS_MODULE_NAME = 'page.settings.modules.statisticsModuleName',

    /** Модуль статистики позволяет строить аналитические отчеты и видеть общую картину работы компании. Отчеты можно изучать как диаграммы и графики, либо скачивать в форматах Excel и PDF. Дополнительно вы можете заказать собственный отчет по своим требованиям, он будет доступен только вашей компании. */
    PAGE__SETTINGS__MODULES__STATISTICS_MODULE_DESCRIPTION = 'page.settings.modules.statisticsModuleDescription',

    /** Модуль субаренды */
    PAGE__SETTINGS__MODULES__SUBRENT_MODULE_NAME = 'page.settings.modules.subrentModuleName',

    /** Модуль субаренды позволяет автоматизировать процесс поставок товаров для последующей сдачи в субаренду. Доступен учет сделок с поставщиками, долги и взаимозачет. Как и в случае аренды учитывается статус каждой позиции - от бронирования до возврата поставщику. */
    PAGE__SETTINGS__MODULES__SUBRENT_MODULE_DESCRIPTION = 'page.settings.modules.subrentModuleDescription',

    /** Модуль затрат */
    PAGE__SETTINGS__MODULES__EXPENSES_MODULE_NAME = 'page.settings.modules.expensesModuleName',

    /** Модуль затрат позволяет вести учет произвольных затрат по проекту. Например, расходов на такси или гостиницу в выездном проекте, другой пример - учет страховки и расходников. Доступна внешняя стоимость (для клиента) и внутренняя (себестоимость). */
    PAGE__SETTINGS__MODULES__EXPENSES_MODULE_DESCRIPTION = 'page.settings.modules.expensesModuleDescription',

    /** Модуль смет */
    PAGE__SETTINGS__MODULES__OFFERS_MODULE_NAME = 'page.settings.modules.offersModuleName',

    /** Модуль смет позволяет внутри основного проекта разрабатывать сметы, согласовывать их с клиентом, после чего устанавливать стоимость проекта на основе одной или нескольких смет. */
    PAGE__SETTINGS__MODULES__OFFERS_MODULE_DESCRIPTION = 'page.settings.modules.offersModuleDescription',

    /** Модуль заказов */
    PAGE__SETTINGS__MODULES__ORDERS_MODULE_NAME = 'page.settings.modules.ordersModuleName',

    /** Модуль позволяет работать с обычными разовыми заказами клиентов в максимально простом и быстром режиме, не создавая сложные проекты и операции. */
    PAGE__SETTINGS__MODULES__ORDERS_MODULE_DESCRIPTION = 'page.settings.modules.ordersModuleDescription',

    /** Модуль проектов */
    PAGE__SETTINGS__MODULES__PROJECTS_MODULE_NAME = 'page.settings.modules.projectsModuleName',

    /** Модуль позволяет вести сложные длительные проекты аренды с частичными выдачами, возвратами и другими операциями. При включении соответствующих модулей, в проекте можно вести несколько смет, учитывать затраты и планировать работы. */
    PAGE__SETTINGS__MODULES__PROJECTS_MODULE_DESCRIPTION = 'page.settings.modules.projectsModuleDescription',

    /** Вы уверены, что хотите подключить модуль? */
    PAGE__SETTINGS__MODULES__ENABLE_MODULE_WARNING = 'page.settings.modules.enableModuleWarning',

    /** Вы уверены, что хотите отключить модуль? */
    PAGE__SETTINGS__MODULES__DISABLE_MODULE_WARNING = 'page.settings.modules.disableModuleWarning',

    /** Модуль подключен */
    PAGE__SETTINGS__MODULES__ENABLE_MODULE_SUCCESS_MESSAGE = 'page.settings.modules.enableModuleSuccessMessage',

    /** Не удалось подключить модуль */
    PAGE__SETTINGS__MODULES__ENABLE_MODULE_ERROR_MESSAGE = 'page.settings.modules.enableModuleErrorMessage',

    /** Модуль отключен */
    PAGE__SETTINGS__MODULES__DISABLE_MODULE_SUCCESS_MESSAGE = 'page.settings.modules.disableModuleSuccessMessage',

    /** Не удалось отключить модуль */
    PAGE__SETTINGS__MODULES__DISABLE_MODULE_ERROR_MESSAGE = 'page.settings.modules.disableModuleErrorMessage',

    /** Использованы {from} из {to} всего доступных полей данного типа */
    PAGE__SETTINGS__FIELDS__TOOLTIP__FIELD_TYPE_COMPANY_RESTRICTION = 'page.settings.fields.tooltip.fieldTypeCompanyRestriction',

    /** Краткое наименование поля уже использовано */
    PAGE__SETTINGS__FIELDS__TOOLTIP__VALIDATIONS__SHORT_NAME_ALREADY_USED = 'page.settings.fields.tooltip.validations.shortNameAlreadyUsed',

    /** Редактирование настроек */
    PAGE__SETTINGS__EXTRA__EDITING_LABEL = 'page.settings.extra.editingLabel',

    /** Настройки изменены */
    PAGE__SETTINGS__EXTRA__POPUP_NOTIFICATIONS__SETTINGS_UPDATED = 'page.settings.extra.popupNotifications.settingsUpdated',

    /** Назначение ответственного */
    PAGE__USER_PROFILE__EVENT_TYPE__ASSIGNEMENT = 'page.userProfile.eventType.assignement',

    /** Потеря или поломка */
    PAGE__USER_PROFILE__EVENT_TYPE__LOST_OR_BROKEN = 'page.userProfile.eventType.lostOrBroken',

    /** Новый заказ с витрины */
    PAGE__USER_PROFILE__EVENT_TYPE__NEW_ORDER_FROM_STOREFRONT = 'page.userProfile.eventType.newOrderFromStorefront',

    /** Мой профиль */
    PAGE__USER_PROFILE__PAGE_TITLE__CURRENT_USER_PROFILE = 'page.userProfile.pageTitle.currentUserProfile',

    /** Профиль пользователя */
    PAGE__USER_PROFILE__PAGE_TILTE__OTHER_USER_PROFILE = 'page.userProfile.pageTilte.otherUserProfile',

    /** Отмена заказа с витрины */
    PAGE__USER_PROFILE__EVENT_TYPE__ORDER_FROM_STOREFRONT_CANCELED = 'page.userProfile.eventType.orderFromStorefrontCanceled',

    /** Вы уверены, что хотите перестать получать любые уведомления от RentRabbit? */
    PAGE__USER_PROFILE__MODAL__QUESTION__TURN_OFF_ALL_NOTIFICATIONS = 'page.userProfile.modal.question.turnOffAllNotifications',

    /** Не удалось загрузить профиль пользователя */
    PAGE__USER_PROFILE__POPUP_NOTIFICATIONS__GET_FAILED = 'page.userProfile.popupNotifications.getFailed',

    /** Настройки уведомлений изменены */
    PAGE__USER_PROFILE__POPUP_NOTIFICATIONS__CHANGED = 'page.userProfile.popupNotifications.changed',

    /** После смены email адреса на новый, вход будет невозможен до его подтверждения. Вы уверены, что хотите продолжить? */
    PAGE__USER_PROFILE__POPUP_NOTIFICATIONS__COMFIRM_CHANGE_EMAIL = 'page.userProfile.popupNotifications.comfirmChangeEmail',

    /** Логин */
    PAGE__USER_PROFILE__TAB__DESCRIPTION__FIELD__LOGIN = 'page.userProfile.tab.description.field.login',

    /** Роли */
    PAGE__USER_PROFILE__TAB__DESCRIPTION__FIELD__ROLES = 'page.userProfile.tab.description.field.roles',

    /** Локаль */
    PAGE__USER_PROFILE__TAB__DESCRIPTION__FIELD__LOCALE = 'page.userProfile.tab.description.field.locale',

    /** Только назначенные мне */
    PAGE__USER_PROFILE__TAB__NOTIFICATIONS__PROJECT_SELECTOR__OPTION__ASSIGNED_TO_ME = 'page.userProfile.tab.notifications.projectSelector.option.assignedToMe',

    /** События */
    PAGE__USER_PROFILE__TAB__NOTIFICATIONS__EVENT_TYPES = 'page.userProfile.tab.notifications.eventTypes',

    /** Уведомления о собственных действиях */
    PAGE__USER_PROFILE__TAB__NOTIFICATIONS__NOTIFICATIONS_ABOUT_OWN_ACTIONS = 'page.userProfile.tab.notifications.notificationsAboutOwnActions',

    /** Значения по умолчанию */
    PAGE__USER_PROFILE__TAB__NOTIFICATIONS__BLOCK__DEFAULT_SETTINGS = 'page.userProfile.tab.notifications.block.defaultSettings',

    /** Общие настройки */
    PAGE__USER_PROFILE__TAB__NOTIFICATIONS__BLOCK__GLOBAL_SETTINGS = 'page.userProfile.tab.notifications.block.globalSettings',

    /** Уведомления */
    PAGE__USER_PROFILE__TAB__NOTIFICATIONS__NOTIFICATIONS = 'page.userProfile.tab.notifications.notifications',

    /** Настройки уведомлений */
    PAGE__USER_PROFILE__FORM__NOTIFICATION_SCHEME__FORM_TILTE = 'page.userProfile.form.notificationScheme.formTilte',

    /** Поле не заполненно */
    PAGE__USER_PROFILE__FORM__NOTIFICATION_SCHEME__VALIDATION__FIELD_IS_EMPTY = 'page.userProfile.form.notificationScheme.validation.fieldIsEmpty',

    /** Отправлять уведомления */
    PAGE__USER_PROFILE__FORM__NOTIFICATION_SCHEME__SEND_NOTOFICATIONS = 'page.userProfile.form.notificationScheme.sendNotofications',

    /** Назначенные мне */
    PAGE__USER_PROFILE__FORM__NOTIFICATION_SCHEME__ASSIGNED_TO_ME = 'page.userProfile.form.notificationScheme.assignedToMe',

    /** Укажите типы событий по которым хотите получать уведомления */
    PAGE__USER_PROFILE__FORM__NOTIFICATION_SCHEME__PLACEHOLDER__CHOOSE_TARGET_EVENT_TYPES = 'page.userProfile.form.notificationScheme.placeholder.chooseTargetEventTypes',

    /** Собственные действия */
    PAGE__USER_PROFILE__FORM__NOTIFICATION_SCHEME__OWN_ACTIONS = 'page.userProfile.form.notificationScheme.ownActions',

    /** Настройки уведомлений по компаниям */
    PAGE__USER_PROFILE__FORM__NOTIFICATION_SCHEME__NOTIFICATIONS_SETTINGS_BY_BUSINESS_ACCOUNT = 'page.userProfile.form.notificationScheme.notificationsSettingsByBusinessAccount',

    /** Продолжить */
    PAGE__INVITATIONS__PROCEED_BUTTON = 'page.invitations.proceedButton',

    /** Вам назначены роли в компании */
    PAGE__INVITATIONS__INVITATION_TEXT_ROLES_GRANTED = 'page.invitations.invitationTextRolesGranted',

    /** Пользователь RentRabbit отправил вам приглашение */
    PAGE__INVITATIONS__INVITATION_MAIN_TEXT = 'page.invitations.invitationMainText',

    /** Принять */
    PAGE__INVITATIONS__ACCEPT_BUTTON = 'page.invitations.acceptButton',

    /** Отказаться */
    PAGE__INVITATIONS__DECLINE_BUTTON = 'page.invitations.declineButton',

    /** Приглашение */
    PAGE__INVITATIONS__MODAL_TITLE = 'page.invitations.modalTitle',

    /** Приглашение устарело */
    PAGE__INVITATIONS__POPUP_NOTIFICATIONS__INVITATION_OUT_OF_DATE = 'page.invitations.popupNotifications.invitationOutOfDate',

    /** Приглашение принято */
    PAGE__INVITATIONS__POPUP_NOTIFICATIONS__INVITATION_ACCEPTED = 'page.invitations.popupNotifications.invitationAccepted',

    /** Центр поддержки */
    PAGE__HELP__HELP_CENTER = 'page.help.helpCenter',

    /** Запросы поддержки */
    PAGE__HELP__SUPPORT_REQUESTS = 'page.help.supportRequests',

    /** Список экземпляров */
    PAGE__INSTANCES__INSTANCE_LIST = 'page.instances.instanceList',

    /** Создать экземпляр */
    PAGE__INSTANCES__CREATE_INSTANCE = 'page.instances.createInstance',

    /** Скрыть списанные */
    PAGE__INSTANCES__HIDE_DECOMISSIONED = 'page.instances.hideDecomissioned',

    /** Имя или код уже использован в текущем продукте */
    PAGE__MODAL__VALIATION_MESSAGE_NAME_OR_CODE = 'page.modal.valiationMessageNameOrCode',

    /** Не удалось создать экземпляр продукта */
    PAGE__INSTANCES__POPUP_NOTIFICATIONS__CREATION_FAILED = 'page.instances.popupNotifications.creationFailed',

    /** Экземпляр продукта создан */
    PAGE__INSTANCES__POPUP_NOTIFICATIONS__CREATED = 'page.instances.popupNotifications.created',

    /** Не удалось изменить экземпляр продукта */
    PAGE__INSTANCES__POPUP_NOTIFICATIONS__UPDATE_FAILED = 'page.instances.popupNotifications.updateFailed',

    /** Экземпляр продукта изменен */
    PAGE__INSTANCES__POPUP_NOTIFICATIONS__UPDATED = 'page.instances.popupNotifications.updated',

    /** Ошибка получения списка экземпляров продукта */
    PAGE__INSTANCES__POPUP_NOTIFICATIONS__GET_LIST_FAILED = 'page.instances.popupNotifications.getListFailed',

    /** Hаименование уже использовано в текущем продукте */
    PAGE__INSTANCES__POPUP_NOTIFICATIONS__CODE_OR_NAME_USED_IN_PRODUCT = 'page.instances.popupNotifications.codeOrNameUsedInProduct',

    /** Официальный инвентарный номер уже использован в текущей компании */
    PAGE__INSTANCES__POPUP_NOTIFICATIONS__INVENTORY_NUMBER_USED_IN_PRODUCT = 'page.instances.popupNotifications.inventoryNumberUsedInProduct',

    /** Текущая доступность */
    PAGE__CARD_POPUP__CURRENT_AVAILABILITY = 'page.cardPopup.currentAvailability',

    /** Балансовая стоимость */
    PAGE__CARD_POPUP__CARRYING_VALUE = 'page.cardPopup.carryingValue',

    /** Редактирование экземпляра */
    PAGE__FORM__FORM_TITLE__EDIT = 'page.form.formTitle.edit',

    /** Создание нового экземпляра */
    PAGE__FORM__FORM_TITLE__CREATE = 'page.form.formTitle.create',

    /** Список вариантов */
    PAGE__VARIANTS__VARIANT_LIST = 'page.variants.variantList',

    /** Создать вариант */
    PAGE__VARIANTS__CREATE_VARIANT = 'page.variants.createVariant',

    /** Добавить поле */
    PAGE__VARIANTS__ADD_CUSTOM_FIELD = 'page.variants.addCustomField',

    /** Отвязать поле от продукта в целом и привязать к вариантам по отдельности? */
    PAGE__MODAL__UNLINK_FIELD_FROM_PRODUCT_AND_LINK_TO_EACH_VARIANT = 'page.modal.unlinkFieldFromProductAndLinkToEachVariant',

    /** Удалить поле? */
    PAGE__MODAL__REMOVE_FIELD = 'page.modal.removeField',

    /** Вариант продукта изменен */
    PAGE__VARIANTS__POPUP_NOTIFICATIONS__VARIANT_UPDATED = 'page.variants.popupNotifications.variantUpdated',

    /** Варианты продукта изменены */
    PAGE__VARIANTS__POPUP_NOTIFICATIONS__VARIANTS_UPDATED = 'page.variants.popupNotifications.variantsUpdated',

    /** Ошибка получения списка вариантов продукта */
    PAGE__VARIANTS__POPUP_NOTIFICATIONS__GET_LIST_FAILED = 'page.variants.popupNotifications.getListFailed',

    /** Вариант продукта создан */
    PAGE__VARIANTS__POPUP_NOTIFICATIONS__CREATED = 'page.variants.popupNotifications.created',

    /** По продукту */
    PAGE__VARIANTS__CARD_POPUP__FIELD_VALUE_BY_PRODUCT = 'page.variants.cardPopup.fieldValueByProduct',

    /** Характеристики */
    PAGE__VARIANTS__CARD_POPUP__FEATURES = 'page.variants.cardPopup.features',

    /** Редактирование варианта */
    PAGE__VARIANTS__FORM__FORM_TITLE__EDIT = 'page.variants.form.formTitle.edit',

    /** Создание нового варианта */
    PAGE__VARIANTS__FORM__FORM_TITLE__CREATE = 'page.variants.form.formTitle.create',

    /** Наименование варианта уже использовано в продукте */
    PAGE__VARIANTS__FORM__VALIDATIONS__NAME_ALREADY_USED = 'page.variants.form.validations.nameAlreadyUsed',

    /** Артикул уже использован */
    PAGE__VARIANTS__FORM__VALIDATIONS__EXTERNAL_CODE_ALREADY_USED = 'page.variants.form.validations.externalCodeAlreadyUsed',

    /** Характеристики варианта */
    PAGE__VARIANTS__FORM__VARIANT_FEATURES = 'page.variants.form.variantFeatures',

    /** Регистрация компании */
    PAGE__COMPANY_REGISTRATION__LABEL = 'page.companyRegistration.label',

    /** Название компании */
    PAGE__COMPANY_REGISTRATION__FIELD__COMPANY_NAME = 'page.companyRegistration.field.companyName',

    /** Отрасль */
    PAGE__COMPANY_REGISTRATION__FIELD__INDUSTRY = 'page.companyRegistration.field.industry',

    /** Демо данные */
    PAGE__COMPANY_REGISTRATION__FIELD__DEMO_DATA = 'page.companyRegistration.field.demoData',

    /** Заполнить демо данными */
    PAGE__COMPANY_REGISTRATION__FIELD_OPTION__FILL_IN_THE_DEMO_DATA = 'page.companyRegistration.fieldOption.fillInTheDemoData',

    /** Оставить пустым */
    PAGE__COMPANY_REGISTRATION__FIELD_OPTION__DO_NOT_FILL_IN = 'page.companyRegistration.fieldOption.doNotFillIn',

    /** Заполните, пожалуйста, все поля */
    PAGE__COMPANY_REGISTRATION__PLEASE_FILL_IN_ALL_THE_FIELDS = 'page.companyRegistration.pleaseFillInAllTheFields',

    /** Продолжить */
    PAGE__COMPANY_REGISTRATION__BUTTON__CONTINUE = 'page.companyRegistration.button.continue',

    /** Не удалось зарегистрировать компанию */
    PAGE__COMPANY_REGISTRATION__POPUP_NOTIFICATIONS__FAILED_TO_REGISTER_BUSINESS_ACCOUNT = 'page.companyRegistration.popupNotifications.failedToRegisterBusinessAccount',

    /** Все права защищены */
    PAGE__COMPANY_REGISTRATION__ALL_RIGHTS_RESERVED = 'page.companyRegistration.allRightsReserved',

    /** Некорректное название */
    PAGE__COMPANY_REGISTRATION__VALIDATIONS__INVALID_COMPANY_NAME = 'page.companyRegistration.validations.invalidCompanyName',

    /** Нажимая на кнопку, Вы соглашаетесь с */
    PAGE__COMPANY_REGISTRATION__LEASE_AGREEMENT_LEGAL_STRING__START = 'page.companyRegistration.leaseAgreementLegalString.start',

    /** условиями оферты */
    PAGE__COMPANY_REGISTRATION__LEASE_AGREEMENT_LEGAL_STRING__END = 'page.companyRegistration.leaseAgreementLegalString.end',

    /** Аудио и видео техника */
    PAGE__COMPANY_REGISTRATION__INDUSTRY_FIELD_OPTION__AV = 'page.companyRegistration.industryFieldOption.av',

    /** Спортивный инвентарь */
    PAGE__COMPANY_REGISTRATION__INDUSTRY_FIELD_OPTION__SPORT_INVENTORY = 'page.companyRegistration.industryFieldOption.sportInventory',

    /** Одежда и аксессуары */
    PAGE__COMPANY_REGISTRATION__INDUSTRY_FIELD_OPTION__CLOTHES = 'page.companyRegistration.industryFieldOption.clothes',

    /** Реквизит */
    PAGE__COMPANY_REGISTRATION__INDUSTRY_FIELD_OPTION__PROPS = 'page.companyRegistration.industryFieldOption.props',

    /** Организация ивентов */
    PAGE__COMPANY_REGISTRATION__INDUSTRY_FIELD_OPTION__EVENTS = 'page.companyRegistration.industryFieldOption.events',

    /** Людские ресурсы */
    PAGE__COMPANY_REGISTRATION__INDUSTRY_FIELD_OPTION__HR = 'page.companyRegistration.industryFieldOption.hr',

    /** Строительная техника */
    PAGE__COMPANY_REGISTRATION__INDUSTRY_FIELD_OPTION__CONSTRUCTION = 'page.companyRegistration.industryFieldOption.construction',

    /** Прокат транспорта */
    PAGE__COMPANY_REGISTRATION__INDUSTRY_FIELD_OPTION__TRANSPORT = 'page.companyRegistration.industryFieldOption.transport',

    /** Другая отрасль */
    PAGE__COMPANY_REGISTRATION__INDUSTRY_FIELD_OPTION__OTHER = 'page.companyRegistration.industryFieldOption.other',

    /** <p><strong>Создать дубликат</strong>  </p>undefined<p>Добавить продукты со склада и сформировать по ним новое обязательство с аналогичными условиями аренды</p> */
    PAGE__NEW_OPERATION__ELEMENTS__ACTION__COPY__TOOLTIP = 'page.newOperation.elements.action.copy.tooltip',

    /** <p><strong>Редактировать</strong>  </p>undefined<p>Уточнить условия аренды и количество экземпляров обязательства</p> */
    PAGE__NEW_OPERATION__ELEMENTS__ACTION__EDIT__TOOLTIP = 'page.newOperation.elements.action.edit.tooltip',

    /** <p><strong>Удалить</strong>  </p>undefined<p>Убрать обязательство из операции</p> */
    PAGE__NEW_OPERATION__ELEMENTS__ACTION__DELETE__TOOLTIP = 'page.newOperation.elements.action.delete.tooltip',

    /** <p><strong>Поместить в набор</strong>  </p>undefined<p>Поместить обязательства из состава операции в текущий набор</p> */
    PAGE__NEW_OPERATION__ELEMENTS__ACTION__MOVE_ELEMENT_TO_KIT__TOOLTIP = 'page.newOperation.elements.action.moveElementToKit.tooltip',

    /** <p><strong>Общий параметр</strong>  </p>undefined<p>Задано единое значение параметра аренды для всех обязательств данной операции</p> */
    PAGE__NEW_OPERATION__USE_COMMON_PARAMS__TOOLTIP = 'page.newOperation.useCommonParams.tooltip',

    /** <p><strong>Индивидуальные параметры</strong>  </p>undefined<p>Заданы различные значения параметра аренды для обязательств данной операции</p> */
    PAGE__NEW_OPERATION__USE_INDIVIDUAL_PARAMS__TOOLTIP = 'page.newOperation.useIndividualParams.tooltip',

    /** <p><strong>Индивидуальные параметры</strong>  </p>undefined<p>Заданы различные значения параметра аренды обязательств данной операции</p> */
    PAGE__HISTORY_ELEMENT__USE_INDIVIDUAL_PARAMS__TOOLTIP = 'page.historyElement.useIndividualParams.tooltip',

    /** Вид */
    PAGE__SETTINGS__CUSTOM_FIELDS__FIELD__ORIGIN = 'page.settings.customFields.field.origin',

    /** Тип */
    PAGE__SETTINGS__CUSTOM_FIELDS__FIELD__TYPE_CODE = 'page.settings.customFields.field.typeCode',

    /** Выберите тип */
    PAGE__SETTINGS__CUSTOM_FIELDS__FIELD__TYPE_CODE__PLACEHOLDER = 'page.settings.customFields.field.typeCode.placeholder',

    /** Список значений */
    PAGE__SETTINGS__CUSTOM_FIELDS__FIELD__MULTIPLE_VALUES = 'page.settings.customFields.field.multipleValues',

    /** Число продуктов */
    PAGE__SETTINGS__CUSTOM_FIELDS__FIELD__ENTITIES_LINKED_NUMBER = 'page.settings.customFields.field.entitiesLinkedNumber',

    /** Стандартное */
    PAGE__SETTINGS__CUSTOM_FIELDS__STANDARD_TYPE = 'page.settings.customFields.standardType',

    /** Пользовательское */
    PAGE__SETTINGS__CUSTOM_FIELDS__NOT_STANDARD_TYPE = 'page.settings.customFields.notStandardType',

    /** Скрыть стандартные поля */
    PAGE__SETTINGS__CUSTOM_FIELDS__HIDE_STANDARD_FIELDS = 'page.settings.customFields.hideStandardFields',

    /** Создать поле */
    PAGE__SETTINGS__CUSTOM_FIELDS__ACTION__CREATE = 'page.settings.customFields.action.create',

    /** <p>Создать поле</p> */
    PAGE__SETTINGS__CUSTOM_FIELDS__ACTION__CREATE__TOOLTIP = 'page.settings.customFields.action.create.tooltip',

    /** <p>Редактировать</p> */
    PAGE__SETTINGS__CUSTOM_FIELDS__ACTION__EDIT__TOOLTIP = 'page.settings.customFields.action.edit.tooltip',

    /** <p>Удалить</p> */
    PAGE__SETTINGS__CUSTOM_FIELDS__ACTION__DELETE__TOOLTIP = 'page.settings.customFields.action.delete.tooltip',

    /** Создание пользовательского поля */
    PAGE__SETTINGS__CUSTOM_FIELDS__MODAL__CREATE__TITLE = 'page.settings.customFields.modal.create.title',

    /** Редактирование пользовательского поля */
    PAGE__SETTINGS__CUSTOM_FIELDS__MODAL__EDIT__TITLE = 'page.settings.customFields.modal.edit.title',

    /** Не удалось загрузить список полей */
    PAGE__SETTINGS__CUSTOM_FIELDS__LOAD_ENTITIES_ERROR_MESSAGE = 'page.settings.customFields.loadEntitiesErrorMessage',

    /** Поле успешно создано */
    PAGE__SETTINGS__CUSTOM_FIELDS__CREATE_SUCCESS_MESSAGE = 'page.settings.customFields.createSuccessMessage',

    /** Поле успешно изменено */
    PAGE__SETTINGS__CUSTOM_FIELDS__UPDATE_SUCCESS_MESSAGE = 'page.settings.customFields.updateSuccessMessage',

    /** Поле удалено */
    PAGE__SETTINGS__CUSTOM_FIELDS__DELETE_SUCCESS_MESSAGE = 'page.settings.customFields.deleteSuccessMessage',

    /** Поле не удалено */
    PAGE__SETTINGS__CUSTOM_FIELDS__DELETE_ERROR_MESSAGE = 'page.settings.customFields.deleteErrorMessage',

    /** Вы действительно хотите удалить поле? */
    PAGE__SETTINGS__CUSTOM_FIELDS__DELETE_CONFIRM_MESSAGE = 'page.settings.customFields.deleteConfirmMessage',

    /** Прервать редактирование? */
    PAGE__SETTINGS__CUSTOM_FIELDS__EDIT_CANCEL_CONFIRM_MESSAGE = 'page.settings.customFields.editCancelConfirmMessage',

    /** Вы действительно хотите удалить ценовую схему? */
    PAGE__SETTINGS__PRICING_SCHEMES__DELETE_CONFIRM_MESSAGE = 'page.settings.pricingSchemes.deleteConfirmMessage',

    /** Роли */
    USERS_BA__ROLES = 'usersBA.Roles',

    /** Агент */
    USERS_BA__ROLES__AGENT = 'usersBA.Roles.Agent',

    /** Администратор */
    USERS_BA__ROLES__ADMIN = 'usersBA.Roles.Admin',

    /** Выбрать роль */
    USERS_BA__ROLES__PLACEHOLDER = 'usersBA.Roles.Placeholder'
}

import { useMemo } from 'react';
import { NomenclatureRecordCustom } from '../../../api/products.api.types';
import { CustomFieldDataTypeCodeEnum, KitRecord, NomenclatureEntityTypeCodeEnum } from '../../../../../../../server';

export const booleanMap = {
    true: '1',
    false: '0',
};

export const valueSorter = (a, b) => {
    if (typeof a === 'string' && typeof b === 'string') {
        return a.localeCompare(b);
    } else if (typeof a === 'number' && typeof b === 'number') {
        return a - b;
    } else {
        return -1;
    }
};

export function useCustomFieldValues(
    selectedRecords: (NomenclatureRecordCustom | KitRecord)[],
    customField: { id: number; type: CustomFieldDataTypeCodeEnum },
    values: string[]
) {
    const hasVariants = selectedRecords.some(
        (r) => 'nomenclatureEntityTypeCode' in r && r.nomenclatureEntityTypeCode === NomenclatureEntityTypeCodeEnum.VARIANT
    );

    const notEqual = useMemo(
        () =>
            selectedRecords.filter((record) => {
                let isNumber = false;
                const value = record.customFieldValues?.find((v) => v.customFieldId === customField.id);

                if (!value || value.values?.length !== values.length) return true;
                if (customField.type === CustomFieldDataTypeCodeEnum.DOUBLE || customField.type === CustomFieldDataTypeCodeEnum.LONG) {
                    isNumber = true;
                }

                const oldValues = [...(value.values ?? [])].map((v) => (isNumber ? +v : v));
                const newValues = [...values].map((v) => (isNumber ? +v : v));

                if (oldValues.length === 1) {
                    if (customField.type === CustomFieldDataTypeCodeEnum.BOOLEAN) {
                        return oldValues[0] !== booleanMap[newValues[0]];
                    } else return oldValues[0] !== newValues[0];
                }
                const sortedRecordValues = [...oldValues].sort(valueSorter);
                const sortedNewValues = [...newValues].sort(valueSorter);

                let equal = true;
                for (let i = 0; i < sortedRecordValues.length; i++) {
                    if (sortedRecordValues[i] !== sortedNewValues[i]) {
                        equal = false;
                        break;
                    }
                }

                return !equal;
            }),
        [customField.id, customField.type, selectedRecords, values]
    );

    const notEmpty = useMemo(
        () =>
            selectedRecords.filter(
                (record) =>
                    record.customFieldIds?.includes(customField.id) &&
                    !!record.customFieldValues?.find((v) => v.customFieldId === customField.id)?.values?.length
            ),
        [customField.id, selectedRecords]
    );

    const notEqualLength = useMemo(() => {
        const notEqualIds = notEqual.map((i) => ('productId' in i ? i.productId : i.id));
        return new Set(notEqualIds).size;
    }, [notEqual]);

    const notEmptyLength = useMemo(() => {
        const notEmptyIds = notEmpty.map((i) => ('productId' in i ? i.productId : i.id));
        return new Set(notEmptyIds).size;
    }, [notEmpty]);

    return { notEmpty, notEmptyLength, notEqual, notEqualLength, hasVariants };
}

import React, { FC, memo, useEffect, useMemo, useRef, useState } from 'react';
import { Card , Icon} from 'antd';
import Spin from '../../../../../components/spin/spin';
import { CalendarFilters } from '../../components/CalendarFilters/CalendarFilters';
import { usePageURLParams } from '../../../../../core/hooks/urlParams/usePageURLParams';
import {
    SummaryScheduleCalendarFilters,
    summaryScheduleCalendarFilters,
    summaryScheduleCalendarFiltersDescription
} from './filters/summaryScheduleCalendarFilters';
import { useFiltersChanges } from './hooks/useFiltersChanges';
import { useCalendarQueryParams } from '../../components/Calendar/hooks/useCalendarQueryParams';
import Timeline, { TimelineHeaders } from 'react-calendar-timeline';
import { CalendarHeader } from '../../components/CalendarHeader/CalendarHeader';
import { CalendarMarker } from '../../components/CalendarMarker/CalendarMarker';
import { useDateHeaderUnits } from '../../components/Calendar/hooks/useDateHeaderUnits';
import { useIntervalClick } from '../../components/Calendar/hooks/useIntervalClick';
import {
    CALENDAR_LINE_HEIGHT_RATIO,
    CALENDAR_SIDEBAR_WIDTH_MEDIUM,
    CalendarDisplayType,
    CalendarVisibleItemsLimit,
} from '../../components/Calendar/utils/data/calendarConstants';
import { CalendarLineUtils } from '../../components/CalendarItemRenderer/utils/calendarLineUtils';
import 'react-calendar-timeline/lib/Timeline.css';
import '../../components/Calendar/Calendar.less';
import '../../components/Calendar/CalendarLib.less';
import { CalendarItemRenderer } from '../../components/CalendarItemRenderer/CalendarItemRenderer';
import { SummaryScheduleCalendarItemRenderer } from './components/ItemRenderer/summaryScheduleCalendarItemRenderer';
import { TimeRangeContext } from '../../components/CalendarItemRenderer/context/TimeRangeContext';
import { WrappedFormUtils } from 'antd/es/form/Form';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../shared/reducers/system.reducer';
import { SummaryScheduleCalendarGroupRenderer } from './components/groupRenderer/summaryScheduleCalendarGroupRenderer';
import { useDisableMouseScrollEffect } from '../../components/Calendar/hooks/useDisableMouseScrollEffect';
import classNames from 'classnames';
import { useCalendarData } from './hooks/useCalendarData';
import { CalendarPageTabsEnum } from '../../../../../shared/constants/tabEnums';
import { SummaryScheduleCalendarHeader } from './components/header/summaryScheduleCalendarHeader';
import { loadCategories } from '../../../../../shared/reducers/entities.reducer';
import { CategoryTypeCodeEnum } from '../../../../../server';
import { useListSummaryScheduleQuery } from './api/summaryScheduleCalendarApi';
import { summaryScheduleCalendarSortData } from './data/sortData';
import { getNomenclatureCollapsedKey } from './data/processCalendarData';
import { defaultScreenLeft, defaultScreenRight } from '../../components/Calendar/utils/data/calendarBoundaries';
import './summaryScheduleCalendar.less';
import { SummaryScheduleCalendarFooter } from './components/footer/summaryScheduleCalendarFooter';
import { useFixedTableHeader } from '../../components/Calendar/hooks/useFixedTableHeader';
import {IconAngleDown, IconAngleUp} from "../../../../../components/icons";
import {CalendarBottomNavigation} from "../../components/СalendarBottomNavigation/СalendarBottomNavigation";
import {ItemsNotFoundBlock} from "../../../../../components/itemsNotFoundBlock/ItemsNotFoundBlock";
import {
    useHorizontalLineClassNamesForScheduleCalendarGroup
} from "../../components/Calendar/hooks/useClassNames";

export const summaryScheduleCalendarPageName = CalendarPageTabsEnum.SUMMARY_SCHEDULE;
const sidebarWidth = CALENDAR_SIDEBAR_WIDTH_MEDIUM;

export const SummaryScheduleCalendar: FC = memo(() => {
    const dispatch = useAppDispatch();
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const scrollRef = useRef<HTMLDivElement>();
    const headerRef = useRef<HTMLDivElement>();
    const filtersFormRef = useRef<WrappedFormUtils>();
    const [visibleItemsLimit, setVisibleItemsLimit] = useState<CalendarVisibleItemsLimit>(CalendarVisibleItemsLimit.FIRST_LIMIT);
    const [collapsedNomenclatures, setCollapsedNomenclatures] = useState<string[]>([]);
    const [openedKits, setOpenedKits] = useState<string[]>([]);
    const [allCollapsed, setAllCollapsed] = useState(true);
    const { fixed, headerRect } = useFixedTableHeader(headerRef, scrollRef);

    useEffect(() => {
        dispatch(loadCategories(businessAccountId, CategoryTypeCodeEnum.PRODUCT));
    }, []);

    const initialValues:Partial<SummaryScheduleCalendarFilters> = useMemo(()=>({
        group: 'name'
    }), []);

    const pageParamsObject = usePageURLParams({
        paramsDescription: summaryScheduleCalendarFiltersDescription,
        pageName: summaryScheduleCalendarPageName,
        initialValues
    });
    const { pageParams, updatePageParams } = pageParamsObject;

    const queryParams = useCalendarQueryParams({
        ...pageParams,
    });

    useEffect(() => {
        setVisibleItemsLimit(CalendarVisibleItemsLimit.FIRST_LIMIT);
    }, [pageParams]);

    const _screenLeft = pageParams.screenLeft || defaultScreenLeft();
    const _screenRight = pageParams.screenRight || defaultScreenRight();

    const { data: { entities, totalCount } = {}, isFetching } = useListSummaryScheduleQuery(
        {
            businessAccountId,
            params: queryParams,
            limit: visibleItemsLimit,
            from: _screenLeft,
            until: _screenRight,
        },
        {
            refetchOnReconnect: true,
            refetchOnMountOrArgChange: true,
        }
    );

    const [displayType, setDisplayType] = useState<CalendarDisplayType>(pageParams.displayType ?? CalendarDisplayType.COMPACT);

    const {
        deferredItems: data,
        visibleTimeStart,
        visibleTimeEnd,
    } = useCalendarData(
        entities,
        _screenLeft,
        _screenRight,
        isFetching,
        displayType,
        pageParams.group,
        pageParams.includeRentElements,
        collapsedNomenclatures,
        openedKits
    );

    const lineHeight = CalendarLineUtils.map.lineHeightFromDisplayType[displayType];
    const onIntervalClick = useIntervalClick(updatePageParams);
    const { primaryDateHeaderUnit, secondaryDateHeaderUnit } = useDateHeaderUnits(visibleTimeStart, visibleTimeEnd);

    const timeRangeContextValue = useMemo(
        () => ({
            visibleTimeStart,
            visibleTimeEnd,
        }),
        [visibleTimeEnd, visibleTimeStart]
    );

    useDisableMouseScrollEffect(scrollRef);

    const _totalCount = totalCount || 0;
    const _visibleCount = visibleItemsLimit > _totalCount ? _totalCount : visibleItemsLimit;

    useEffect(() => {
        if (!isFetching) {
            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 50);
        }
    }, [isFetching]);

    const horizontalLineClassNamesForGroup = useHorizontalLineClassNamesForScheduleCalendarGroup(data.items);

    return (
        <Spin wrapperClassName={'clickable-spin'} spinning={isFetching} delay={0}>
            <Card>
                <div className={classNames('calendar-filters-container')}>
                    <CalendarFilters
                        pageName={summaryScheduleCalendarPageName}
                        pageParamsDescription={summaryScheduleCalendarFiltersDescription}
                        pageParamsObject={pageParamsObject}
                        filtersData={summaryScheduleCalendarFilters}
                        useFiltersChanges={useFiltersChanges}
                        filtersFormRef={filtersFormRef}
                        hiddenFieldsForCount={['includeRentElements']}
                        resetCallback={() => {}}
                    />
                </div>
                {fixed && <div style={{ height: headerRect?.height }} />}
                <div
                    className={classNames('calendar-timeline', 'accessibility', {
                        'not-found': false, //!accessibilityEntitySelected,,
                        compact: displayType === CalendarDisplayType.COMPACT,
                    })}
                >
                    <div style={{position: 'absolute', top: 3, userSelect: 'none'}}>
                        <div>Инвентарь</div>
                        <a onClick={()=>{
                            setAllCollapsed(!allCollapsed);
                            if(allCollapsed) {
                                const allgroups = data.groups.filter(group=>group.collapsible).map(group=>getNomenclatureCollapsedKey(group));
                                setCollapsedNomenclatures(allgroups);
                            }else {
                                setCollapsedNomenclatures([]);
                            }
                        }} style={{marginTop: 10, marginLeft: 4}}>
                            <Icon component={allCollapsed ? IconAngleDown : IconAngleUp} style={{fontSize: 20, marginRight: 12}} />
                            <span className={'summaryScheduleCalendar-sidebar-group-collapse-all'}>{allCollapsed ? 'Раскрыть все' : 'Свернуть все'}</span>
                        </a>
                    </div>
                    <TimeRangeContext.Provider value={timeRangeContextValue}>
                        <SummaryScheduleCalendarHeader
                            isLoading={isFetching}
                            pageParamsObject={pageParamsObject as any}
                            sortData={summaryScheduleCalendarSortData}
                            sortByValue={pageParams.group}
                            displayType={displayType}
                            setDisplayType={setDisplayType}
                            visibleCount={_visibleCount}
                            totalCount={_totalCount}
                            sidebarWidth={sidebarWidth}
                        />
                        <Timeline
                            canMove={false}
                            canResize={false}
                            groups={data.groups}
                            itemHeightRatio={CALENDAR_LINE_HEIGHT_RATIO}
                            items={data.items}
                            lineHeight={lineHeight}
                            sidebarWidth={sidebarWidth}
                            horizontalLineClassNamesForGroup={horizontalLineClassNamesForGroup}
                            scrollRef={(el) => (scrollRef.current = el)}
                            headerRef={(el) => (headerRef.current = el)}
                            visibleTimeStart={visibleTimeStart}
                            visibleTimeEnd={visibleTimeEnd}
                            groupRenderer={({ group }) => {
                                return (
                                    <SummaryScheduleCalendarGroupRenderer
                                        screenLeft={visibleTimeStart}
                                        screenRight={visibleTimeEnd}
                                        group={group}
                                        onCollapse={(collapsed) => {
                                            const key = getNomenclatureCollapsedKey(group);
                                            if (!collapsed) {
                                                setCollapsedNomenclatures([...collapsedNomenclatures, key]);
                                            } else {
                                                setCollapsedNomenclatures(collapsedNomenclatures.filter((k) => k !== key));
                                            }
                                        }}
                                    />
                                );
                            }}
                            itemRenderer={
                            CalendarItemRenderer({
                                itemRenderer: SummaryScheduleCalendarItemRenderer,
                                displayType,
                                onPopoverVisibleChange: () => {},
                                onItemClick: (id, context) => {
                                    const _id = ''+id;
                                    if (!openedKits.includes(_id)) {
                                        setOpenedKits([...openedKits, _id]);
                                    } else {
                                        setOpenedKits(openedKits.filter((k)=>k!==_id));
                                    }},
                                groupsData: {},
                                sortByValue: undefined,
                            })
                        }
                        >
                            <TimelineHeaders className={fixed ? 'fixed-header' : undefined}>
                                <CalendarHeader.Primary
                                    unit={primaryDateHeaderUnit}
                                    onIntervalClick={onIntervalClick}
                                    allNothing={!visibleTimeEnd || !visibleTimeStart}
                                />
                                <CalendarHeader.Secondary
                                    primaryUnit={primaryDateHeaderUnit}
                                    unit={secondaryDateHeaderUnit}
                                    onIntervalClick={onIntervalClick}
                                    allNothing={!visibleTimeEnd || !visibleTimeStart}
                                    sidebarWidth={sidebarWidth}
                                />
                            </TimelineHeaders>
                            {data.items?.length && <CalendarMarker />}
                        </Timeline>
                    </TimeRangeContext.Provider>
                    {_totalCount > 0 && entities && entities.length < _totalCount && (
                        <SummaryScheduleCalendarFooter
                            totalCount={_totalCount}
                            onClick={() => {
                                setVisibleItemsLimit(CalendarVisibleItemsLimit.SECOND_LIMIT);
                            }}
                            isFetching={isFetching}
                        />
                    )}
                    {data.items.length === 0 ? <ItemsNotFoundBlock style={{ marginTop: 56, marginBottom: 20 }} entityType={'element'} /> : null}
                </div>
            </Card>
            <CalendarBottomNavigation
                capture={pageParams.capture}
                pageName={summaryScheduleCalendarPageName}
                pageParams={pageParams}
                positionTop={false}
                screenLeft={_screenLeft}
                screenRight={_screenRight}
            />
        </Spin>
    );
});

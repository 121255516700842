import {Input} from "antd";
import React, {useCallback} from "react";
import {InputProps} from "antd/lib/input/Input";

interface StringInputProps extends InputProps {

}

export const StringInputFC = (props: StringInputProps) => {

    const onBlur: InputProps['onBlur'] = useCallback((e) => {
        const input = e.currentTarget;
        const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value")?.set;
        if (nativeInputValueSetter) nativeInputValueSetter.call(input, input.value.trim());
        const inputEvent = new Event('input', {bubbles: true});
        input.dispatchEvent(inputEvent);
    }, []);

    return <Input onInput={(a) => {
    }}  {...props} onBlur={onBlur}/>
};

export class StringInput extends React.PureComponent<StringInputProps> {
    render() {
        return <StringInputFC {...this.props} />;
    }
}

import { IRouterItem } from '../../../config/routes';
import UserProfileDescription from './user-profile-description';
import UserProfileNotification from './notifications/user-profile-notifications';
import NotFoundModule from '../settings/notFound/notFoundModule';
import UserProfileInterface from './interface/user-profile-interface';

export const userProfileRoutes: Array<IRouterItem> = [
    {
        menuKey: 'description',
        title: 'Описание',
        path: '/:businessAccountId/userProfile/description',
        component: UserProfileDescription,
        sortOrder: 0,
    },
    {
        menuKey: 'notifications',
        title: 'Настройки уведомлений',
        path: '/:businessAccountId/userProfile/notifications',
        component: UserProfileNotification,
        sortOrder: 0,
    },
    {
        menuKey: 'interface',
        title: 'Настройки интерфейса',
        path: '/:businessAccountId/userProfile/interface',
        component: UserProfileInterface,
        sortOrder: 0,
    },
    {
        menuKey: 'notfound',
        path: `/:businessAccountId/userProfile/`,
        component: NotFoundModule,
        sortOrder: 0,
    },
];

import React, { ReactNode } from 'react';
import { FormItemType, IFormField, IFormProps } from './DynamicForm';
import { Checkbox, DatePicker, Form, Icon, Input, Radio, Select, Switch, Tooltip, TreeSelect } from 'antd';
import { SearchInput } from '../search/search.component';
import ReactDOM from 'react-dom';
import { SliderRange } from '../slider/SliderRange/SliderRange';
import { CustomSelect } from '../index';
import UploadImage from '../upload/uploadImage';
import { API_SERVER_BASE_PATH } from '../../config/config';
import { getBusinessAccountId } from '../../../index';
import moment from 'moment';
import getValue from 'get-value';
import { FiltersDateTimeRangePicker } from '../datePicker/FiltersDateTimeRangePicker';
import { IntlShape } from 'react-intl';
import { getFormattedMessageComponent, localize } from '../../localization/localizationUtils';
import { LocalizationEnum } from '../../localization';
import { IconClose, IconPlus } from '../icons';
import { HelpTooltip } from '../helpTooltip/HelpTooltip';
import { RichTextEditor } from '../richTextEditor/RichTextEditor';
import { CustomRangePicker } from '../datePicker/CustomRangePicker';
import { DatePicker1 } from '../datePicker/DatePicker1';
import { DateTimePicker } from '../dateTimePicker/dateTimePicker';
import { GetFieldDecoratorOptions } from 'antd/lib/form/Form';
import { NumberInput, NumberInputDiscount, NumberInputMoney, NumberInputTax } from '../numberInputs';
import { FiltersDateRangePicker } from '../datePicker/FiltersDateRangePicker';
import { RangePicker } from '../v2/calendar/rangePicker/rangePicker';
import { Checkbox as RRCheckbox } from '../v2/checkbox/checkbox';
import { StringInput } from '../stringInput/stringInput';
import AddressInput from '../addressInput/addressInput';

export const maxLengthValidationRule = (max: number): { max: number; message: ReactNode } => ({
    max,
    message: (
        <>
            {localize(LocalizationEnum.ASPECT__FORMS__VALIDATION__FIELD_LENGTH_MAXIMUM)} {max}{' '}
            {localize(LocalizationEnum.ASPECT__FORMS__VALIDATION__SYMBOLS)}
        </>
    ),
});

export const createFormItemChilds = (type: any, values, props: IFormProps, intl?: IntlShape) => {
    let options;

    if (typeof values === 'function') values = values(props.form);

    if (values && values.length) {
        options = [];
        for (let i = 0; i < values.length; ++i) {
            const { name } = values[i];
            options.push(
                React.createElement(
                    type,
                    {
                        className: !name && !values[i].value ? 'rr-select-dropdown-menu-item-seperator' : undefined,
                        key: '' + i,
                        value: values[i].value,
                        disabled: values[i].disabled === true,
                        search: name,
                    },
                    values[i].icon ? (
                        <>
                            {values[i].icon} {name}
                        </>
                    ) : (
                        name
                    )
                )
            );
        }
    }
    return options;
};

export const normFile = (e) => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
        return e;
    }
    return e && e.fileList;
};

const validateField = (fieldName, value, validationFunction, cb, props: IFormProps) => {
    if (validationFunction) {
        validationFunction(
            fieldName,
            value,
            (err) => {
                if (!props.viewMode) {
                    cb(err);
                }
            },
            props.form
        );
    }
};

export const createFormItem = (item: IFormField, index: number, props: IFormProps, context) => {
    const { getFieldDecorator, getFieldValue } = props.form;
    let componentProps: { [k: string]: any } = {};
    const formItemRules: { [k: string]: any }[] = [];
    const formItemOptions: GetFieldDecoratorOptions = { rules: formItemRules, validateFirst: true };
    let componentClass;
    let component;
    let componentChildClass;
    let componentChildContent;
    let customErrorMessage;

    // disabled - для всех компонентов
    if (item.disabled !== undefined) {
        if ((typeof item.disabled === 'boolean' && item.disabled) || (typeof item.disabled === 'function' && item.disabled(getFieldValue)))
            componentProps.disabled = true;
    }

    // visible - для всех компонентов
    if (item.visible !== undefined) {
        if (
            (typeof item.visible === 'boolean' && !item.visible) ||
            (typeof item.visible === 'function' && !item.visible(getFieldValue, props.form, props.currentValues))
        )
            return null;
    }

    if (item.placeholder !== undefined) {
        const intl = props.intl;

        if (intl) {
            if (Array.isArray(item.placeholder)) {
                componentProps.placeholder = item.placeholder.map((item) =>
                    /^[0-9a-zA-Z._-]+$/.test(item.placeholder) ? intl.formatMessage({ id: item }) : item
                );
            } else {
                componentProps.placeholder = /^[0-9a-zA-Z._-]+$/.test(item.placeholder)
                    ? intl.formatMessage({ id: item.placeholder })
                    : item.placeholder;
            }
        } else {
            componentProps.placeholder = item.placeholder;
        }
    }

    if (item.style !== undefined) componentProps.style = item.style;
    if (item.className !== undefined) componentProps.className = item.className;

    formItemOptions.normalize = item.normalize;

    if (!props.viewMode) {
        if (item.type === FormItemType.String) {
            componentClass = StringInput; // Input
        } else if (item.type === FormItemType.Address) {
            componentClass = AddressInput;
        } else if (item.type === FormItemType.Search) {
            componentClass = SearchInput;
            componentProps.allowClear = true;
        } else if (item.type === FormItemType.Label) {
            componentClass = 'span';
            if (props && props.initialValues && props.initialValues[item.id] !== undefined) {
                componentChildContent = props.initialValues[item.id];
            }
            componentProps.className = componentProps.className ? componentProps.className + ' h5' : 'h5';
        } else if (item.type === FormItemType.Password) componentClass = Input.Password;
        else if (item.type === FormItemType.Text) {
            componentClass = Input.TextArea;
            componentProps.autoSize = { minRows: 4, maxRows: 10 };
        } else if (item.type === FormItemType.RichText) {
            componentClass = RichTextEditor;
        } else if (item.type === FormItemType.Hidden) {
            componentClass = 'input';
            componentProps.type = 'hidden';
        } else if (item.type === FormItemType.Component) {
            componentClass = item.component;
            componentProps.fetchEntityErrorCallback = props.fetchEntityErrorCallback;
            componentProps.formLabel = item.label;
            componentProps.intl = props.intl;
            componentProps.getPopupContainer = () => ReactDOM.findDOMNode(context);
        } else if (item.type === FormItemType.Switch) {
            componentClass = Switch;
            formItemOptions.valuePropName = 'checked';
        } else if (item.type === FormItemType.Checkbox) {
            componentClass = RRCheckbox;
            formItemOptions.valuePropName = 'checked';
        } else if (item.type === FormItemType.Date) {
            componentClass = DatePicker1;
            componentProps.getCalendarContainer = () => ReactDOM.findDOMNode(context);
        } else if (item.type === FormItemType.DateTime) {
            componentClass = DateTimePicker;
            componentProps.getCalendarContainer = () => ReactDOM.findDOMNode(context);
        } else if (item.type === FormItemType.Discount) {
            componentClass = NumberInputDiscount;
        } else if (item.type === FormItemType.Tax) {
            componentClass = NumberInputTax;
        } else if (
            item.type === FormItemType.Number ||
            item.type === FormItemType.Money ||
            item.type === FormItemType.Float ||
            item.type === FormItemType.Integer
        ) {
            if (item.type === FormItemType.Money) {
                componentClass = NumberInputMoney;
                componentProps.flexible = true;
            } else {
                componentClass = NumberInput;
            }

            if (item.step) {
                componentProps.step = item.step;
            }

            if (item.type === FormItemType.Number || item.type === FormItemType.Money) {
                componentProps.precision = 2;
            } else if (item.type === FormItemType.Integer) {
                componentProps.precision = 0;
            }

            if (item.minLength !== undefined) {
                if (typeof item.minLength === 'function') {
                    componentProps.min = item.minLength(props.form);
                } else componentProps.min = item.minLength;
            }
            if (item.maxLength !== undefined) {
                if (typeof item.maxLength === 'function') {
                    componentProps.max = item.maxLength(props.form);
                } else componentProps.max = item.maxLength;
            }
        } else if (item.type === FormItemType.SliderRange) {
            componentClass = SliderRange;
            if (item.step) componentProps.step = item.step;
            if (item.minLength !== undefined) componentProps.min = item.minLength;
            if (item.maxLength !== undefined) componentProps.max = item.maxLength;
        } else if (item.type === FormItemType.RangePicker) {
            componentClass = DatePicker.RangePicker;
        } else if (item.type === FormItemType.DateRangePicker) {
            componentClass = RangePicker;
            componentProps.showTime = true;
            componentProps.getPopupContainer = () => ReactDOM.findDOMNode(context);
            componentProps.allowClear = true;
        } else if (item.type === FormItemType.Select) {
            componentClass = CustomSelect;
            componentChildClass = Select.Option;
            componentProps.allowClear = true;
            componentProps.defaultActiveFirstOption = false;
            componentProps.optionFilterProp = 'search';
            componentProps.filterOption = true;
            componentProps.getPopupContainer = () => ReactDOM.findDOMNode(context);
        } else if (item.type === FormItemType.MultiSelect) {
            componentClass = CustomSelect;
            componentChildClass = Select.Option;
            componentProps.mode = 'multiple';
            componentProps.allowClear = true;
            componentProps.defaultActiveFirstOption = false;
            componentProps.optionFilterProp = 'search';
            componentProps.filterOption = true;
            componentProps.getPopupContainer = () => ReactDOM.findDOMNode(context);
        } else if (item.type === FormItemType.RadioGroup) {
            componentClass = Radio.Group;
            componentChildClass = Radio;
        } else if (item.type === FormItemType.CheckboxGroup) {
            componentClass = Checkbox.Group;
            componentChildClass = Checkbox;
        } else if (item.type === FormItemType.TreeSelect) {
            componentClass = TreeSelect;
            if (item.values) componentProps.treeData = item.values;
            componentProps.showLine = true;
        } else if (item.type === FormItemType.TreeMultiSelect) {
            componentClass = TreeSelect;
            if (item.values) componentProps.treeData = item.values;
            componentProps.multiple = true;
            componentProps.showLine = true;
            componentProps.showCheckedStrategy = TreeSelect.SHOW_CHILD;
            componentProps.treeIcon = true;
            componentProps.showSearch = true;
            componentProps.treeCheckable = true;
            componentProps.getPopupContainer = () => ReactDOM.findDOMNode(context);
        } else if (item.type === FormItemType.UploadImage) {
            componentClass = UploadImage;
            componentProps.action = `${API_SERVER_BASE_PATH}/businessAccounts/${getBusinessAccountId()}/images/`;

            formItemOptions.valuePropName = 'fileList';
            formItemOptions.getValueFromEvent = normFile;

            if (item['imagesMaxCount'] !== undefined) {
                componentProps.maxCount = item['imagesMaxCount'];
            }

            if (item['multiple'] !== undefined) {
                componentProps.multiple = item['multiple'];
            }

            formItemOptions?.rules?.push({
                validator: (rule, value, callback) => {
                    let errors;
                    if (value && value.length) {
                        errors = value
                            .filter((item) => item.error)
                            .map((item) => (
                                <div key={item.uid}>
                                    {localize(LocalizationEnum.ASPECT__FORMS__PROBLEM__FILE_NOT_UPLOADED_BEGINNING)} {item.name}{' '}
                                    {localize(LocalizationEnum.ASPECT__FORMS__PROBLEM__FILE_NOT_UPLOADED_ENDING)}
                                    {item && item.response && item.response.message ? '. ' + item.response.message : ''}
                                </div>
                            ));
                    }
                    callback(errors);
                },
            });
        } else return null;
    } else {
        componentClass = 'span';

        if (item.defaultValue) {
            let value = item.defaultValue;

            if (item.type === FormItemType.Date) {
                value = moment(item.defaultValue).format('D MMMM YYYY');
            } else if (item.type === FormItemType.DateTime) {
                value = moment(item.defaultValue).format('D MMMM YYYY HH:mm');
            } else if (item.type === FormItemType.Switch || item.type === FormItemType.Checkbox) {
                if (value === true || value === 'true') value = localize(LocalizationEnum.ASPECT__GLOBAL__YES);
                else if (value === false || value === 'false') value = localize(LocalizationEnum.ASPECT__GLOBAL__NO);
            }

            // TODO Заменить на класс, вместо стилей
            componentChildContent = (
                <span
                    style={{
                        fontSize: 18,
                        fontWeight: 'bold',
                        display: 'block',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {value}
                </span>
            );
        }
    }

    if (item.onChange) {
        componentProps.onChange = (e) => {
            if (item.onChange) {
                let value = e && e.target ? e.target.value : e;
                item.onChange(value, props.form);
            }
        };
    }

    if (item.componentProps) {
        let compProps;
        if (typeof item.componentProps === 'function') {
            compProps = item.componentProps(props.form, props.customParams);
        } else compProps = item.componentProps;

        componentProps = { ...componentProps, ...compProps };
    }

    component = React.createElement(
        componentClass,
        componentProps,
        componentChildClass ? createFormItemChilds(componentChildClass, item.values, props, props.intl) : componentChildContent || undefined
    );

    ////////////////////////////////////
    // Валидация
    if (!props.viewMode && (item.required === true || (typeof item.required === 'function' && item.required(getFieldValue)))) {
        formItemRules.push({
            required: true,
            //whitespace: true,
            message: item.requiredMessage || localize(LocalizationEnum.ASPECT__DATA_PRESENCE__FIELD_IS_REQUIRED),
        });
    }

    if (
        item.minLength !== undefined &&
        item.disabled !== true &&
        (item.type === FormItemType.String ||
            item.type === FormItemType.Text ||
            (item.type === FormItemType.Hidden && typeof item.defaultValue !== 'number' && item.required))
    ) {
        formItemRules.push({
            min: item.minLength,
            message: (
                <>
                    {localize(LocalizationEnum.ASPECT__FORMS__VALIDATION__FIELD_LENGTH_MINIMUM)} {item.minLength}{' '}
                    {localize(LocalizationEnum.ASPECT__FORMS__VALIDATION__SYMBOLS)}
                </>
            ),
        });
    }

    if (
        item.maxLength !== undefined &&
        item.disabled !== true &&
        (item.type === FormItemType.String ||
            item.type === FormItemType.Text ||
            (item.type === FormItemType.Hidden && typeof item.defaultValue !== 'number'))
    ) {
        // TODO Нормальное сообщение об ошибке
        formItemRules.push({
            max: item.maxLength,
            message: (
                <>
                    {localize(LocalizationEnum.ASPECT__FORMS__VALIDATION__FIELD_LENGTH_MAXIMUM)} {item.maxLength}{' '}
                    {localize(LocalizationEnum.ASPECT__FORMS__VALIDATION__SYMBOLS)}
                </>
            ),
        });
    }

    if (
        item.len !== undefined &&
        item.disabled !== true &&
        (item.type === FormItemType.String || item.type === FormItemType.Text || item.type === FormItemType.Hidden)
    ) {
        // TODO Нормальное сообщение об ошибке
        formItemRules.push({
            len: item.len,
            message: (
                <>
                    {localize(LocalizationEnum.ASPECT__FORMS__VALIDATION__FIELD_LENGTH_MUST_BE_EXACTLY)} {item.len}{' '}
                    {localize(LocalizationEnum.ASPECT__FORMS__VALIDATION__SYMBOLS)}
                </>
            ),
        });
    }

    if (
        item.pattern !== undefined &&
        item.disabled !== true &&
        (item.type === FormItemType.String || item.type === FormItemType.Text || item.type === FormItemType.Hidden)
    ) {
        // TODO Нормальное сообщение об ошибке
        formItemRules.push({
            pattern: item.pattern,
            message: (
                <>
                    {localize(LocalizationEnum.ASPECT__FORMS__VALIDATION__FIELD_MUST_MATCH_THE_PATTERN)} {item.pattern}
                </>
            ),
        });
    }

    ////////////////////////////////////
    // Значение по умолчанию
    let initialValue = typeof item.defaultValue === 'function' ? item.defaultValue(props.form) : item.defaultValue;

    if (props && props.initialValues) {
        let value = getValue(props.initialValues, item.id.replace(/\[(\d+)\]/, '.$1'));
        if (value !== undefined && value !== null) initialValue = item.getInitialValue ? item.getInitialValue(value) : value;
    }

    if (initialValue !== undefined && initialValue !== null) {
        if (item.type === FormItemType.Date || item.type === FormItemType.DateTime) {
            initialValue = initialValue ? moment(initialValue) : initialValue;
        } else if (item.component === FiltersDateTimeRangePicker) {
            initialValue = [
                initialValue[0] ? moment(new Date(+initialValue[0])) : undefined,
                initialValue[1] ? moment(new Date(+initialValue[1])) : undefined,
            ];
        }
        formItemOptions.initialValue = initialValue;
    }

    // Это для формы с фильтрами
    if (props.onChange) {
        formItemOptions.rules?.push({
            validator: (rule, value, callback) => {
                callback();
                if (props.onChange) props.onChange(props.form.getFieldsValue());
            },
        });
    }

    if (item.validationRules) {
        formItemOptions.rules = [...(formItemOptions.rules ?? []), ...item.validationRules];
    }

    if (item.validationFunction) {
        formItemOptions.rules?.push({
            validator: (rule, value, callback) => {
                try {
                    if (props.validateInitialValues) {
                        validateField(item.id, value, item.validationFunction, callback, props);
                    } else {
                        let dontValidateInitialValue = true;
                        if (
                            (typeof item.validateInitialValue === 'boolean' && item.validateInitialValue) ||
                            (typeof item.validateInitialValue === 'function' && item.validateInitialValue(props.form))
                        )
                            dontValidateInitialValue = false;

                        if (
                            dontValidateInitialValue &&
                            (initialValue === value || ('' + initialValue).toLowerCase() === ('' + value).toLowerCase())
                        )
                            callback();
                        else {
                            validateField(item.id, value, item.validationFunction, callback, props);
                        }
                    }
                } catch (err) {
                    callback(err);
                }
            },
        });
    }

    if (item.onChange) {
        formItemOptions.rules?.push({
            validator: (rule, value, callback) => {
                callback();
            },
        });
    }

    // Это для формы с фильтрами
    if (props.onChange) {
        formItemOptions.rules?.push({
            validator: (rule, value, callback) => {
                callback();
                if (props.onChange) props.onChange(props.form);
            },
        });
    }

    if (item.type === FormItemType.Hidden) {
        return <React.Fragment key={item.id}>{getFieldDecorator(item.id, formItemOptions)(component)}</React.Fragment>;
    }

    let isValidating = item.validationFunction && props.form.isFieldValidating(item.id);

    if (item.multipleValues) {
        let re = /\[(\d+)\]/g;
        let result = ('' + item.id).match(re);
        let index = 0;
        let fieldName = '';

        if (result) {
            index = +result[result.length - 1].toString().replace('[', '').replace(']', '');
            fieldName = item.id.substring(0, item.id.length - result[result.length - 1].length);
        }

        let allFields = props.data.map((g) => g.fields).flat();
        let fields = allFields.filter((f) => f.id.indexOf(fieldName) === 0);
        let fieldsCount = fields.length;
        let fieldIndex = fields.findIndex((f) => f.id === item.id);

        return (
            <Form.Item
                className={'rr-form-multiple-filed'}
                hasFeedback={componentClass === Input && isValidating}
                help={customErrorMessage || undefined}
                validateStatus={isValidating ? 'validating' : customErrorMessage ? 'error' : undefined}
                extra={item.infoMessage}
                label={fieldIndex === 0 ? item.label : undefined}
                colon={false}
                key={`${item.id}__${index}`}
            >
                <div>
                    {getFieldDecorator(`${item.id}__${index}`, formItemOptions)(component)}
                    {fieldsCount > 1 ? (
                        <Icon
                            className={'rr-form-delete-icon'}
                            component={IconClose}
                            onClick={() => {
                                if (props.removeField) props.removeField(fieldName, index);
                            }}
                        />
                    ) : null}
                    {fieldIndex === fieldsCount - 1 ? (
                        <Icon
                            className={'rr-form-add-icon'}
                            component={IconPlus}
                            onClick={() => {
                                if (props.addField) props.addField(fieldName, index);
                            }}
                        />
                    ) : null}
                </div>
            </Form.Item>
        );
    }

    let isFieldNeedHighlight = false;
    if (props.highlightChangedFields) {
        if (props.defaultValues && props.currentValues) {
            // По новому
            if (props.form.getFieldValue(item.id) === undefined && !props.form.isFieldTouched(item.id)) {
                if ('' + props.defaultValues[item.id] !== '' + props.currentValues[item.id]) isFieldNeedHighlight = true;
            } else {
                if (
                    typeof props.form.getFieldValue(item.id) === 'string' &&
                    !props.defaultValues[item.id] === !props.currentValues[item.id]
                ) {
                    isFieldNeedHighlight = false;
                } else if ('' + props.defaultValues[item.id] !== '' + props.currentValues[item.id]) {
                    isFieldNeedHighlight = true;
                }
            }
        } else {
            // По старому
            if ('' + initialValue !== '' + props.form.getFieldValue(item.id) && props.form.getFieldValue(item.id) !== undefined)
                isFieldNeedHighlight = true;
        }
    }

    const formItem = (
        <Form.Item
            key={item.id}
            id={item.id}
            labelCol={item.labelCol}
            wrapperCol={item.wrapperCol}
            className={
                (isFieldNeedHighlight
                    ? item.component !== FiltersDateTimeRangePicker && item.component !== FiltersDateRangePicker
                        ? 'rr-filters-selected-filter'
                        : 'rr-filters-selected-filter1'
                    : 'rr-filters-not-selected-filter') + (item.customFormItemClassName ? ' ' + item.customFormItemClassName : '')
            }
            hasFeedback={componentClass === Input && isValidating}
            help={customErrorMessage || undefined}
            validateStatus={isValidating ? 'validating' : customErrorMessage ? 'error' : undefined}
            extra={item.infoMessage}
            label={
                item.label ? (
                    <Tooltip mouseEnterDelay={0.6} placement="right" title={item.tooltip}>
                        {getFormattedMessageComponent(item.label) /*props.intl ? props.intl.formatMessage({id:item.label}) : item.label*/}
                    </Tooltip>
                ) : undefined
            }
            colon={false}
        >
            {getFieldDecorator(item.id, formItemOptions)(component)}
        </Form.Item>
    );

    return item.helpTooltipLocalizationEnum ? <HelpTooltip content={item.helpTooltipLocalizationEnum}>{formItem}</HelpTooltip> : formItem;
};

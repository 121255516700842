import { CSSProperties } from 'react';

export const getStylesForBtnTime = (isBtnTime: boolean, currentDelayedBookedElementsCount: number) => {
    const styles: CSSProperties = {
        margin: '0px 5px',
        padding: '0px 10px',
        display: 'flex',
        alignItems: 'center',
    };

    if (isBtnTime) {
        styles['border'] = '1px solid #FC8C2F';
        styles['backgroundColor'] = '#FC8C2F';
    } else {
        styles['backgroundColor'] = '#fff';
        styles['border'] = '1px solid #FC8C2F';
        styles['color'] = '#FC8C2F';
    }

    if (currentDelayedBookedElementsCount === 0 && !isBtnTime) {
        delete styles['backgroundColor'];
        delete styles['color'];
    }

    if (currentDelayedBookedElementsCount) {
        styles['borderColor'] = '#FC8C2F';
    } else {
        styles['borderColor'] = '#d9d9d9';
    }

    return styles;
};

import React from 'react';
import { FormFieldsGroup, FormItemType, SelectItem } from '../../../../../components/dynamicForm/DynamicForm';
import { EntityRemoteSelect } from '../../../../../components/select/EntityRemoteSelect';
import debounce from 'lodash/debounce';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { validateField } from '../../../../../shared/util/validateField';
import {
    ContractValidationTypeCodeEnum,
    CounterpartyActivityTypeCodeEnum,
    ProjectStateCodeEnum,
    RenterStateCodeEnum,
    serverApi,
    TypeOfPricingCodeEnum,
    UserRecord,
} from '../../../../../server';
import { getBusinessAccountId, getStoreState } from '../../../../../../index';
import { LocalizationEnum, localize } from '../../../../../localization';
import { isDefined, printUserName } from '../../../../../shared/util/utils';
import { Dot } from '../../../../../components/dot/dot';
import { getLabeledValueNumberKey } from '../../../../../shared/util/utils5';
import { canUpdateFinancialData, canViewFinancialData } from '../../../../../shared/util/permissionUtils';
import { createHiddenField } from '../../../../../components/modalForm/utils';
import { DefaultTaxContent } from '../../../settings/businessAccountPreferences/businessAccountPreferencesEditModalFieldsData';
import { defaultTaxCalculationTypeField } from '../../../settings/businessAccountPreferences/businessAccountPreferencesEditModalFields';
import {
    CounterpartySelectAndCreate,
    CounterpartySelectAndCreateProps,
} from '../../../../../components/v2/select/counterpartySelect/counterpartySelectAndCreate';
import { LocationSelectAndCreate } from '../../../../../components/v2/select/locationSelect/locationSelectAndCreate';
import {
    DatePickerWithManualInput,
    DatePickerWithManualInputProps,
} from '../../../../../components/datePicker/DatePickerWithManualInput/DatePickerWithManualInput';
import {
    datePickerFormValidatorFunction,
    validateDatePickerFormValue,
} from '../../../../../components/datePicker/DatePickerWithManualInput/helpers/formValidatorFunction';
import {
    contractValidationTypeSelector,
    offersModuleEnabledSelector,
} from '../../../../../shared/reducers/businessAccountPreferences.reducer';
import { canUpdateFinancialDataSelector } from '../../../../../shared/reducers/permissions.reducer';
import { RangePickerProps } from '../../../../../components/v2/calendar/rangePicker/rangePicker';
import { CopyProjectParamsBlock } from '../components/copyProjectParamsBlock/copyProjectParamsBlock';
import { Moment } from 'moment';
import { Select } from 'antd';
import UserDetailedOption from '../../../../../components/v2/detailedSelect/UserDetailedOption';

let responsible;

export const projectContractFields = [
    {
        label: 'Номер договора',
        id: 'projectContract.contractNumber',
        maxLength: 30,
        type: FormItemType.String,
    },
    {
        label: 'Дата договора',
        id: 'projectContract.contractDate',
        type: FormItemType.Component,
        component: DatePickerWithManualInput,
        componentProps: {
            suppressedModes: ['decade'] as DatePickerWithManualInputProps['suppressedModes'],
            defaultPickerMode: 'day' as DatePickerWithManualInputProps['defaultPickerMode'],
        },
        validationFunction: debounce((fieldName: string, value: any, cb: Function, form) => {
            datePickerFormValidatorFunction(fieldName, value, cb);
        }, 500),
        onChange: (value, form) => {
            setTimeout(() => {
                form.validateFields(['projectContract.contractExpirationDate']);
            }, 100);
        },
    },
    {
        label: 'Дата окончания действия договора',
        id: 'projectContract.contractExpirationDate',
        type: FormItemType.Component,
        component: DatePickerWithManualInput,
        componentProps: {
            suppressedModes: ['decade'] as DatePickerWithManualInputProps['suppressedModes'],
            defaultPickerMode: 'day' as DatePickerWithManualInputProps['defaultPickerMode'],
        },
        validationFunction: debounce((fieldName: string, value: Moment, cb: Function, form: WrappedFormUtils) => {
            const contractDate: Moment | undefined = form.getFieldValue('projectContract.contractDate');
            if (value) {
                const dateIsValid = validateDatePickerFormValue(value);
                if (!dateIsValid) cb('Некорректный формат даты');
                else {
                    if (contractDate) {
                        if (value.valueOf() <= contractDate.valueOf()) cb('Дата окончания действия должна быть позже даты договора');
                        else cb();
                    } else {
                        cb();
                    }
                }
            } else cb();
        }, 500),
    },
];

// dynamic form fields
export const formFields: FormFieldsGroup[] = [
    {
        fields: [createHiddenField('fromTemplate'), createHiddenField('copyMode')],
    },
    {
        fields: [
            {
                label: LocalizationEnum.PAGE__PROJECTS__FORM__FIELD__TEMPLATE,
                id: 'template',
                type: FormItemType.String,
                disabled: true,
            },
        ],
        visible: (form) => {
            return form.getFieldValue('fromTemplate') === true;
        },
    },
    {
        fields: [
            {
                id: 'copyParams',
                type: FormItemType.Component,
                component: CopyProjectParamsBlock,
            },
        ],
        visible: (form) => {
            return form.getFieldValue('copyMode') === true;
        },
    },
    {
        fields: [
            createHiddenField('id'),
            createHiddenField('renterStateCode'),
            createHiddenField('projectEdit'),
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__SHORT_TITLE,
                id: 'shortName',
                type: FormItemType.String,
                required: true,
                maxLength: 50,
                validationFunction: debounce((fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
                    const renterId = getLabeledValueNumberKey(form.getFieldValue('renterId'));

                    if (renterId) {
                        const ignoreCurrentId = form.getFieldValue('projectEdit') ? form.getFieldValue('id') : undefined;

                        validateField(
                            'project',
                            fieldName,
                            value,
                            renterId,
                            ignoreCurrentId,
                            'Краткое наименование уже используется в текущем арендаторе',
                            cb
                        );
                    } else cb();
                }, 500),
            },
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__FULL_NAME,
                id: 'fullName',
                type: FormItemType.String,
                required: false,
                maxLength: 255,
                validationFunction: debounce((fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
                    let renterId = getLabeledValueNumberKey(form.getFieldValue('renterId'));
                    if (renterId) {
                        const ignoreCurrentId = form.getFieldValue('projectEdit') ? form.getFieldValue('id') : undefined;
                        validateField(
                            'project',
                            fieldName,
                            value,
                            renterId,
                            ignoreCurrentId,
                            'Полное наименование уже используется в текущем арендаторе',
                            cb
                        );
                    } else cb();
                }, 500),
            },
            {
                label: LocalizationEnum.ASPECT__ENTITY_TYPE__RENTER,
                id: 'renterId',
                type: FormItemType.Component,
                required: true,
                component: CounterpartySelectAndCreate,
                componentProps: (form: WrappedFormUtils): CounterpartySelectAndCreateProps => {
                    let stateCodes: RenterStateCodeEnum[] = [
                        RenterStateCodeEnum.NEW,
                        RenterStateCodeEnum.ACTIVE,
                        RenterStateCodeEnum.BLOCKED,
                    ];
                    if (form.getFieldValue('stateCode') === ProjectStateCodeEnum.INPROGRESS) stateCodes = [RenterStateCodeEnum.ACTIVE];
                    else if (form.getFieldValue('stateCode') === ProjectStateCodeEnum.FINISHED)
                        stateCodes = [RenterStateCodeEnum.ACTIVE, RenterStateCodeEnum.BLOCKED];

                    return {
                        counterpartyType: 'renter',
                        filters: {
                            stateCode: stateCodes, //[RenterStateCodeEnum.NEW, RenterStateCodeEnum.ACTIVE],
                            activityTypeCode: [
                                CounterpartyActivityTypeCodeEnum.RENTERONLY,
                                CounterpartyActivityTypeCodeEnum.RENTERANDSUPPLIER,
                            ],
                            archive: false,
                        },
                        getOptionProps: (record) => {
                            const isNewStatus = record.stateCode === RenterStateCodeEnum.NEW;
                            const stateCode = form.getFieldValue('stateCode');
                            const disabled =
                                isNewStatus &&
                                (stateCode === ProjectStateCodeEnum.INPROGRESS || stateCode === ProjectStateCodeEnum.FINISHED);

                            return {
                                disabled,
                                label: (
                                    <>
                                        {isNewStatus && <Dot className={`rr-status-bg-NEW`} style={{ marginRight: 10 }} />}
                                        {record.shortName}
                                    </>
                                ),
                            };
                        },
                        onDataChange: async (data) => {
                            const value = data?.[0];
                            let renterId = value?.id;

                            form.setFieldsValue({ renterStateCode: value?.stateCode });

                            if (value?.stateCode === RenterStateCodeEnum.NEW) {
                                form.setFieldsValue({ stateCode: ProjectStateCodeEnum.DRAFT });
                            }

                            if (renterId) {
                                let renter = await serverApi.getRenterById(getBusinessAccountId(), renterId);

                                await form.setFieldsValue({ renterDefaultDiscount: renter.data.defaultDiscount });

                                if (value) {
                                    if (form.getFieldValue('useRenterDefaultDiscount')) {
                                        await form.setFieldsValue({ defaultDiscount: form.getFieldValue('renterDefaultDiscount') });
                                    }

                                    if (!form.isFieldValidating('shortName') && !form.isFieldValidating('fullName')) {
                                        form.validateFields(['shortName', 'fullName']);
                                    }

                                    if (form.getFieldValue('useAssignee')) {
                                        if (renter.data.assigneeFullName) {
                                            await form.setFieldsValue({
                                                assigneeId: {
                                                    key: renter.data.id,
                                                    label: renter.data.assigneeFullName
                                                        ? renter.data.assigneeFullName.lastname +
                                                          (renter.data.assigneeFullName.firstname
                                                              ? ' ' + renter.data.assigneeFullName.firstname
                                                              : '')
                                                        : '',
                                                },
                                            });
                                        } else {
                                            await form.setFieldsValue({
                                                assigneeId: undefined,
                                            });
                                        }
                                    }

                                    await form.setFieldsValue({
                                        contactsFIO: renter.data.contacts[0] ? (
                                            (renter.data.contacts[0].fullName.lastname ? renter.data.contacts[0].fullName.lastname : '') +
                                            ' ' +
                                            (renter.data.contacts[0].fullName.firstname ? renter.data.contacts[0].fullName.firstname : '') +
                                            ' ' +
                                            (renter.data.contacts[0].fullName.secondname ? renter.data.contacts[0].fullName.secondname : '')
                                        ) : (
                                            <>{localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_ASSIGNED)}</>
                                        ),
                                    });
                                    responsible = renter.data.contacts[0] ? (
                                        (renter.data.contacts[0].fullName.lastname ? renter.data.contacts[0].fullName.lastname : '') +
                                        ' ' +
                                        (renter.data.contacts[0].fullName.firstname ? renter.data.contacts[0].fullName.firstname : '') +
                                        ' ' +
                                        (renter.data.contacts[0].fullName.secondname ? renter.data.contacts[0].fullName.secondname : '')
                                    ) : (
                                        <>{localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_ASSIGNED)}</>
                                    );
                                }
                            } else {
                                if (form.getFieldValue('useAssignee')) {
                                    await form.setFieldsValue({
                                        assigneeId: undefined,
                                    });
                                }
                            }
                        },
                    };
                },
                placeholder: localize(LocalizationEnum.ASPECT__FORMS__PLACEHOLDER__SELECT_RENTER),
            },
            {
                label: 'Локация',
                id: 'locationId',
                type: FormItemType.Component,
                component: LocationSelectAndCreate,
                placeholder: 'Выберите локацию',
                visible: (getFieldValue) => {
                    return getStoreState().businessAccountPreferences.preferences?.modules.logisticsModuleEnabled === true;
                },
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__STATUS,
                placeholder: localize(LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER),
                id: 'stateCode',
                type: FormItemType.Select,
                defaultValue: ProjectStateCodeEnum.INPROGRESS,
                required: true,
                values: (form: WrappedFormUtils) => {
                    let values: SelectItem[] = [];
                    let renterStateCode: string = form.getFieldValue('renterStateCode');

                    if (!form.getFieldValue('id')) {
                        // Это создание
                        const contractValidationType = contractValidationTypeSelector(getStoreState());
                        if (
                            contractValidationType === ContractValidationTypeCodeEnum.ONLYFILE ||
                            contractValidationType === ContractValidationTypeCodeEnum.ALLFIELDS
                        ) {
                            values = [
                                {
                                    name: 'Черновик',
                                    value: ProjectStateCodeEnum.DRAFT,
                                    disabled: false,
                                },
                                {
                                    name: 'Согласование',
                                    value: ProjectStateCodeEnum.COORDINATION,
                                    disabled: false,
                                },
                            ];
                        } else {
                            values = [
                                {
                                    name: 'Черновик',
                                    value: ProjectStateCodeEnum.DRAFT,
                                    disabled: false,
                                },
                                {
                                    name: 'Согласование',
                                    value: ProjectStateCodeEnum.COORDINATION,
                                    disabled: false,
                                },
                                {
                                    name: localize(LocalizationEnum.ASPECT__STATE_CODE__PROJECT__INPROGRESS),
                                    value: ProjectStateCodeEnum.INPROGRESS,
                                    disabled: renterStateCode === RenterStateCodeEnum.NEW,
                                },
                            ];
                        }
                    } else if (form.getFieldValue('id')) {
                        // Это редактирование
                        values = [
                            {
                                name: 'Черновик',
                                value: ProjectStateCodeEnum.DRAFT,
                                disabled: true,
                            },
                            {
                                name: 'Согласование',
                                value: ProjectStateCodeEnum.COORDINATION,
                                disabled: true,
                            },
                            {
                                name: localize(LocalizationEnum.ASPECT__STATE_CODE__PROJECT__INPROGRESS),
                                value: ProjectStateCodeEnum.INPROGRESS,
                                disabled: true,
                            },
                            {
                                name: localize(LocalizationEnum.ASPECT__STATE_CODE__PROJECT__FINISHED),
                                value: ProjectStateCodeEnum.FINISHED,
                                disabled: true,
                            },
                            {
                                name: 'Отменен',
                                value: ProjectStateCodeEnum.CANCELED,
                                disabled: true,
                            },
                        ];
                    }

                    return values.map((item) => ({
                        ...item,
                        name: (
                            <>
                                <Dot className={`rr-status-bg-` + item.value} style={{ marginRight: 10 }} /> {item.name}
                            </>
                        ),
                    }));
                },
                disabled: (getFieldValue) => !!getFieldValue('createFromOperationForm') || !!getFieldValue('id'),
            },
            {
                label: 'Период работ',
                id: 'dates',
                type: FormItemType.DateRangePicker,
                componentProps: (): RangePickerProps => ({
                    placeholder: ['Начало', 'Завершение'],
                    style: { width: 360 },
                    pricingScheme: undefined,
                }),
            },
            {
                label: 'Тип ценообразования',
                id: 'typeOfPricing',
                type: FormItemType.RadioGroup,
                defaultValue: TypeOfPricingCodeEnum.STRAIGHT,
                values: [
                    {
                        name: 'Прямое',
                        value: TypeOfPricingCodeEnum.STRAIGHT,
                    },
                    {
                        name: 'По сметам, включенным в стоимость',
                        value: TypeOfPricingCodeEnum.FROMOFFERS,
                    },
                ],
                visible: (getFieldValue) => {
                    const state = getStoreState();
                    const offersModuleEnabled = offersModuleEnabledSelector(state);
                    const canUpdateFinancialData = canUpdateFinancialDataSelector(state);
                    return Boolean(offersModuleEnabled && canUpdateFinancialData);
                },
            },
        ],
    },
    {
        title: localize(LocalizationEnum.ASPECT__FIELDS__COMMON__ASSIGNEE),
        fields: [
            createHiddenField('renterDefaultAssignee'),
            createHiddenField('defaultAssignee'),
            createHiddenField('_defaultAssignee'),
            {
                label: LocalizationEnum.PAGE__PROJECTS__FIELD_VALUE_BY_RENTER,
                id: 'useAssignee',
                type: FormItemType.Switch,
                defaultValue: true,
                labelCol: { span: 5 },
                wrapperCol: { span: 12 },
                onChange: async (value: any, form: WrappedFormUtils) => {
                    if (value) {
                        if (form.getFieldValue('renterDefaultAssignee')) {
                            form.setFieldsValue({ assigneeId: form.getFieldValue('renterDefaultAssignee') });
                        } else {
                            if (form.getFieldValue('renterId')) {
                                let renterId = getLabeledValueNumberKey(form.getFieldValue('renterId'));
                                if (renterId) {
                                    let renter = await serverApi.getRenterById(getBusinessAccountId(), renterId);
                                    if (renter.data.assigneeFullName) {
                                        form.setFieldsValue({
                                            assigneeId: {
                                                key: renter.data.id,
                                                label: printUserName(renter.data.assigneeFullName),
                                            },
                                        });
                                    } else {
                                        form.setFieldsValue({ assigneeId: undefined });
                                    }
                                }
                            } else {
                                form.setFieldsValue({ assigneeId: undefined });
                            }
                        }
                    } else {
                        if (form.getFieldValue('defaultAssignee')) {
                            form.setFieldsValue({ assigneeId: form.getFieldValue('defaultAssignee') });
                        }
                    }
                },
            },
            {
                id: 'assigneeId',
                type: FormItemType.Component,
                component: EntityRemoteSelect,
                disabled: (getFieldValue) => getFieldValue('useAssignee') === true,
                componentProps: {
                    className: 'rr-select-detailed',
                    operationName: 'listUsersSimple',
                    nameField: 'shortName',
                    renderer: (option: { id: number; name: string; data: UserRecord }) => {
                        return (
                            <Select.Option key={option.id}>
                                <UserDetailedOption item={option.data} getKey={(item) => item.id} />
                            </Select.Option>
                        );
                    },
                },
                placeholder: localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_ASSIGNED),
            },
        ],
    },
    {
        title: localize(LocalizationEnum.ASPECT__FIELDS__COMMON__DEFAULT_DISCOUNT),
        visible: (form) => {
            const p = getStoreState().permissions.permissions;
            return canViewFinancialData(p);
        },
        fields: [
            createHiddenField('renterDefaultDiscount'),
            createHiddenField('_defaultDiscount'),
            {
                label: LocalizationEnum.PAGE__PROJECTS__FIELD_VALUE_BY_RENTER,
                id: 'useRenterDefaultDiscount',
                type: FormItemType.Switch,
                defaultValue: true,
                labelCol: { span: 5 },
                wrapperCol: { span: 12 },
                onChange: (value: any, form: WrappedFormUtils) => {
                    if (value) {
                        form.setFieldsValue({ _defaultDiscount: form.getFieldValue('defaultDiscount') });
                        form.setFieldsValue({ defaultDiscount: form.getFieldValue('renterDefaultDiscount') || 0 });
                    } else {
                        form.setFieldsValue({ defaultDiscount: form.getFieldValue('_defaultDiscount') });
                    }
                },
                disabled: (getFieldValue) => {
                    const p = getStoreState().permissions.permissions;
                    return canViewFinancialData(p) && !canUpdateFinancialData(p);
                },
            },
            {
                id: 'defaultDiscount',
                type: FormItemType.Discount,
                defaultValue: 0,
                required: true,
                disabled: (getFieldValue) => {
                    const p = getStoreState().permissions.permissions;
                    return (canViewFinancialData(p) && !canUpdateFinancialData(p)) || getFieldValue('useRenterDefaultDiscount') === true;
                },
                onChange: (value: any, form: WrappedFormUtils) => {
                    form.setFieldsValue({ _defaultDiscount: value });
                },
            },
        ],
    },
    {
        helpMessage: DefaultTaxContent,
        fields: [
            {
                label: LocalizationEnum.PAGE__PROJECTS__FORM__FIELD__TAX_PERCENT,
                id: 'taxRate',
                type: FormItemType.Tax,
                defaultValue: 0,
                required: true,
                disabled: (getFieldValue) => {
                    const p = getStoreState().permissions.permissions;
                    return canViewFinancialData(p) && !canUpdateFinancialData(p);
                },
                visible: (getFieldValue) => {
                    const p = getStoreState().permissions.permissions;
                    return canViewFinancialData(p);
                },
            },
            defaultTaxCalculationTypeField as any, // TODO
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__DESCRIPTION,
                id: 'description',
                type: FormItemType.RichText,
                validationRules: [
                    {
                        max: 5000,
                        message: (
                            <>
                                {localize(LocalizationEnum.ASPECT__FORMS__VALIDATION__FIELD_LENGTH_MAXIMUM)} 5000{' '}
                                {localize(LocalizationEnum.ASPECT__FORMS__VALIDATION__INCLUDING_FORMATTING_TAGS)}
                            </>
                        ),
                    },
                ],
            },
        ],
    },
    {
        title: localize(LocalizationEnum.ASPECT__FIELDS__COMMON__CONTACT_PERSON),
        fields: [
            createHiddenField('hasOwnContacts'),
            {
                label: LocalizationEnum.PAGE__PROJECTS__FIELD_VALUE_BY_RENTER,
                id: 'useHasOwnContacts',
                type: FormItemType.Switch,
                labelCol: { span: 5 },
                wrapperCol: { span: 12 },
                defaultValue: true,
                onChange: async (value: any, form: WrappedFormUtils) => {
                    await form.setFieldsValue({ hasOwnContacts: !value });
                    if (responsible) {
                        await form.setFieldsValue({ contactsFIO: responsible });
                    }
                },
            },
            {
                label: LocalizationEnum.ASPECT__GLOBAL__LASTNAME,
                id: 'contacts[0].fullName.lastname',
                type: FormItemType.String,
                maxLength: 60,
                required: true,
                visible: (getFieldValue) => !!getFieldValue('hasOwnContacts'),
            },
            {
                label: LocalizationEnum.ASPECT__GLOBAL__FIRSTNAME,
                id: 'contacts[0].fullName.firstname',
                type: FormItemType.String,
                maxLength: 60,
                required: true,
                visible: (getFieldValue) => !!getFieldValue('hasOwnContacts'),
            },
            {
                label: LocalizationEnum.ASPECT__GLOBAL__SECONDNAME,
                id: 'contacts[0].fullName.secondname',
                type: FormItemType.String,
                maxLength: 60,
                // required: true,
                visible: (getFieldValue) => !!getFieldValue('hasOwnContacts'),
            },
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__POSITION,
                id: 'contacts[0].position',
                type: FormItemType.String,
                maxLength: 60,
                // required: true,
                visible: (getFieldValue) => !!getFieldValue('hasOwnContacts'),
            },
            {
                label: LocalizationEnum.ASPECT__GLOBAL__PHONE_NUMBER,
                id: 'contacts[0].phoneNumber',
                type: FormItemType.String,
                maxLength: 25,
                // required: true,
                visible: (getFieldValue) => !!getFieldValue('hasOwnContacts'),
            },
            {
                label: LocalizationEnum.ASPECT__GLOBAL__EMAIL,
                id: 'contacts[0].email',
                type: FormItemType.String,
                maxLength: 60,
                validationRules: [
                    {
                        // TODO Нормальное сообщение об ошибке
                        pattern: '^.+@.+\\..{2,}$',
                        message: localize(LocalizationEnum.PAGE__RENTERS__FORM__VALIDATION_MESSAGE_EMAIL),
                    },
                ],
                visible: (getFieldValue) => !!getFieldValue('hasOwnContacts'),
            },
            {
                label: LocalizationEnum.ASPECT__GLOBAL__PERSON_FULL_NAME,
                id: 'contactsFIO',
                maxLength: 60,
                disabled: true,
                type: FormItemType.String,
                visible: (getFieldValue) => {
                    return !getFieldValue('hasOwnContacts');
                },
            },
        ],
    },

    {
        fields: projectContractFields /*[
            {
                label: 'Номер договора',
                id: 'projectContract.contractNumber',
                maxLength: 30,
                type: FormItemType.String,
            },
            {
                label: 'Дата договора',
                id: 'projectContract.contractDate',
                type: FormItemType.Component,
                component: DatePickerWithManualInput,
                componentProps: {
                    suppressedModes: ['decade'] as DatePickerWithManualInputProps['suppressedModes'],
                    defaultPickerMode: 'day' as DatePickerWithManualInputProps['defaultPickerMode'],
                },
                validationFunction: debounce((fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
                    datePickerFormValidatorFunction(fieldName, value, cb);
                }, 500),
                onChange: (value, form)=>{
                    setTimeout(()=>{
                        form.validateFields(['projectContract.contractExpirationDate']);
                    }, 100)
                }
            },
            {
                label: 'Дата окончания действия договора',
                id: 'projectContract.contractExpirationDate',
                type: FormItemType.Component,
                component: DatePickerWithManualInput,
                componentProps: {
                    suppressedModes: ['decade'] as DatePickerWithManualInputProps['suppressedModes'],
                    defaultPickerMode: 'day' as DatePickerWithManualInputProps['defaultPickerMode'],
                },
                validationFunction: debounce((fieldName: string, value: Moment, cb: Function, form: WrappedFormUtils) => {
                    const contractDate:Moment|undefined = form.getFieldValue('projectContract.contractDate');
                    if(value){
                        const dateIsValid = validateDatePickerFormValue(value);
                        if(!dateIsValid) cb('Некорректный формат даты');
                        else{
                            if(contractDate){
                                if(value.valueOf() <= contractDate.valueOf()) cb('Дата окончания действия должна быть позже даты договора');
                                else cb();
                            }else{
                                cb();
                            }
                        }
                    }else cb();
                }, 500)
            },
        ],*/,
    },

    {
        fields: [
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__COMMENT,
                id: 'comment',
                type: FormItemType.Text,
                maxLength: 2000,
            },
        ],
    },
];

import React, { FC } from 'react';
import { EditableField, EditableFieldProps } from '../EditableField';
import { FormatPercents } from '../../../formatPercents/formatPercents';

interface EditablePercentsFieldProps extends Pick<EditableFieldProps, 'currentValue' | 'baseValue' | 'onChange'> {}

const minValue = -Number.MAX_SAFE_INTEGER;
const maxValue = 100;

export const EditablePercentsField: FC<EditablePercentsFieldProps> = (props) => {
    const { currentValue, baseValue, onChange } = props;

    return (
        <EditableField
            currentValue={currentValue}
            baseValue={baseValue}
            minValue={minValue}
            maxValue={maxValue}
            formatStyle={'percents'}
            onChange={onChange}
            valueFormatter={(value) => <FormatPercents value={value} precision={2} />}
        />
    );
};

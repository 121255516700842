import React, { CSSProperties, FC, useContext } from 'react';
import { LineIconCircle } from '../../../LineIconCircle/LineIconCircle';
import { StringUtils } from '../../../../../../../../../../../../../core/utils/stringUtils';
import { IconDollyFlatbedSolid } from '../../../../../../../../../../../../../components/icons';
import { Icon } from 'antd';
import { CalendarColors } from '../../../../../../../../utils/calendarColors';
import { DisplayTypeItemContext, ElementItemContext } from '../../../../../../../../context/CalendarItemContext';
import { CalendarItem, ElementsCalendarItem } from '../../../../../../../../../Calendar/types/items';
import { RentElementRecord } from '../../../../../../../../../../../../../server';
import { TimeRangeContext } from '../../../../../../../../context/TimeRangeContext';
import classNames from 'classnames';
import { CalendarDisplayType } from '../../../../../../../../../Calendar/utils/data/calendarConstants';

interface LineInnerContentProps {
    backgroundColor: string | undefined;
    type: 'forward' | 'backward';
    hasTail?: boolean;
}

export const ElementsLineInnerContent: FC<LineInnerContentProps> = (props) => {
    const { type, hasTail, backgroundColor } = props;
    const { item, itemContext } = useContext(ElementItemContext);
    const { visibleTimeStart } = useContext(TimeRangeContext);
    const displayType = useContext(DisplayTypeItemContext);
    const { instanceCount, elementType, isDraft, variantName, isKitChild, start_time, stateCode, summaryScheduleData } = item;

    const isTailBackground = type === 'backward' && hasTail;

    const iconColor = isTailBackground
        ? '#fff'
        : isDraft || elementType === 'subrent'
        ? CalendarColors.staticColors.TEXT_COLOR
        : type === 'forward'
        ? '#fff'
        : CalendarColors.staticColors.TEXT_COLOR;

    const borderColor: CSSProperties['borderColor'] =
        elementType === 'subrent'
            ? CalendarColors.elementsStateCodeMap[stateCode]
            : isDraft
            ? CalendarColors.staticColors.TEXT_COLOR
            : undefined;
    const borderStyle: CSSProperties['borderStyle'] = isDraft ? 'dashed' : 'solid';

    const isRecordKitCheck = (
        item: CalendarItem<ElementsCalendarItem>
    ): item is CalendarItem<ElementsCalendarItem> & {
        record: RentElementRecord;
    } => Boolean(item.kitId);
    const isRecordKit = isRecordKitCheck(item);

    const { nestedRentElements = [], rentElementsKitMemberCount = 0 } = isRecordKit ? item.record : {};

    const itemWidth = itemContext.dimensions.width;

    const isStartAfterLeftBoundary = +start_time > visibleTimeStart;

    return (
        <>
            <LineIconCircle
                backgroundColor={backgroundColor}
                itemWidth={itemWidth}
                isDraft={isDraft}
                isStatic={isTailBackground || elementType === 'subrent'}
                style={{
                    borderColor,
                    borderStyle,
                    borderWidth: isDraft ? 1 : 2,
                    visibility: isStartAfterLeftBoundary ? 'visible' : 'hidden',
                }}
            />
            {
                summaryScheduleData?.projectName ? (
                    summaryScheduleData.kitName ? (
                        <span className={'line-main-block-content'}>
                            {summaryScheduleData.instanceCount}&nbsp;в&nbsp;&nbsp;
                            <Icon
                                className={classNames('calendar-item-kit-icon', {
                                    compact: displayType === CalendarDisplayType.COMPACT,
                                })}
                                component={IconDollyFlatbedSolid}
                                style={{color: iconColor}}
                            />
                            {StringUtils.deleteSpaces(summaryScheduleData.kitName)}<span
                                style={{fontWeight: 400}}>&nbsp;на&nbsp;</span>{summaryScheduleData?.projectName}
                        </span>
                        ) :
                        (
                            <span className={'line-main-block-content'}>{summaryScheduleData.instanceCount}<span
                                style={{fontWeight: 400}}>&nbsp;на&nbsp;</span>{summaryScheduleData?.projectName}</span>
                        )
                ) : (
                    <span className={'line-main-block-content'}>
                {isRecordKit ? (
                    <Icon
                        className={classNames('calendar-item-kit-icon', {
                            compact: displayType === CalendarDisplayType.COMPACT,
                        })}
                        component={IconDollyFlatbedSolid}
                        style={{color: iconColor}}
                    />
                ) : (
                    <>{instanceCount} x </>
                )}
                        {isKitChild && item.record.mainKitMember ? '★ ' : ''}
                        {StringUtils.deleteSpaces(itemContext.title)}
                        {variantName ? <span style={{fontWeight: 400}}>: {variantName}</span> : ''}
                        {isRecordKit &&
                            nestedRentElements?.length &&
                            rentElementsKitMemberCount &&
                            nestedRentElements?.length < rentElementsKitMemberCount && (
                                <span style={{fontWeight: 400, marginLeft: 6}}>
                            ({nestedRentElements?.length} из {rentElementsKitMemberCount})
                        </span>
                            )}
            </span>
                )
            }
        </>
    );
};

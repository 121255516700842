import {useEffect, useMemo, useState} from 'react';
import {processCalendarData} from "../data/processCalendarData";
import {SummaryScheduleDataNomenclature} from "../api/summaryScheduleCalendarApi";
import {CalendarDisplayType} from "../../../components/Calendar/utils/data/calendarConstants";
import {SummaryScheduleCalendarSortValue} from "../data/sortData";

export const useCalendarData = (
    itemsData: SummaryScheduleDataNomenclature[] | undefined,
    screenLeft: number,
    screenRight: number,
    isLoading: boolean,
    displayType:CalendarDisplayType,
    sortBy:SummaryScheduleCalendarSortValue | undefined,
    includeRentElements: boolean | undefined,
    collapsed:string[],
    openedKits:string[]
) => {
    const d = useMemo(()=>{
        return processCalendarData({
            data: itemsData || [],
            sortBy: sortBy,
            includeRentElements: includeRentElements,
            displayType,
            screenLeft: screenLeft,
            screenRight: screenRight,
            collapsed,
            openedKits
        });
    }, [itemsData, sortBy, includeRentElements, displayType, screenLeft, screenRight, collapsed, openedKits]);

    const [visibleTimeStart, setVisibleTimeStart] = useState(screenLeft);
    const [visibleTimeEnd, setVisibleTimeEnd] = useState(screenRight);
    const [deferredItems, setDeferredItems] = useState(d);

    useEffect(() => {
        if (!isLoading) {
            setVisibleTimeStart(screenLeft);
            setVisibleTimeEnd(screenRight);
            setDeferredItems(d);
        }
    }, [isLoading, d, screenLeft, screenRight]);

    return useMemo(
        () => ({
            deferredItems,
            visibleTimeStart,
            visibleTimeEnd,
        }),
        [deferredItems, visibleTimeEnd, visibleTimeStart]
    );
};

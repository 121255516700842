import { FAILURE, REQUEST, SUCCESS } from '../../../../../shared/reducers/action-type.util';
import { ProjectRecord, ProjectRecordList, serverApi, ServerError, WorkflowDirective } from '../../../../../server';
import { showNotification } from '../../../../../components/notification/showNotification';
import { IRootState } from '../../../../../shared/reducers';
import { getServerError, getStateFromPath2, test1 } from '../../../../../shared/util/utils';
import { LOCATION_CHANGE } from 'connected-react-router';
import { LocalizationEnum, localize, localizeIntl } from '../../../../../localization';
import { MoneyUtils } from '../../../../../core/utils/moneyUtils';
import { getAvailableGridSortById } from '../../../../../shared/util/getAvailableGridSortById';
import templatesColumns from '../../templates/templatesListColumns';
import { gridDataChangedSignal } from '../../../../../components/controllers/scroll/SelectScrollController/SelectScrollController';

export const ACTION_TYPES = {
    LOAD_ENTITIES: 'templates/LOAD_ENTITIES',
    RESET: 'templates/RESET',
    CHANGE_ARCHIVE: 'templates/CHANGE_ARCHIVE',
};

export const initialParamsState = {
    search: undefined as string | undefined,
    sortBy: undefined as string | undefined,
    sortOrder: undefined as 'ASC' | 'DESC' | undefined,
    page: 1,
    limit: 20,
    hideArchive: false as boolean | undefined,
    startDate: [undefined, undefined] as (number | undefined)[] | undefined,
    rentElementsSum: undefined as number[] | undefined,
};

const initialState = {
    loading: false,
    loadingError: undefined as undefined | ServerError,
    entities: null as Array<ProjectRecord> | null,
    filteredCount: 0,
    smartFiltersOpened: false,
    params: {
        ...initialParamsState,
    },
};

export type TemplatesState = Readonly<typeof initialState>;

export default (state: TemplatesState = initialState, action): TemplatesState => {
    switch (action.type) {
        case LOCATION_CHANGE:
            let pathName =
                action.payload && action.payload.location && action.payload.location.pathname ? action.payload.location.pathname : '';
            let params = getStateFromPath2(action.payload.location.search);

            if (
                /\/\d+\/projects\/templates\/?/.test(pathName) ||
                (/\/\d+\/crm\/counterparties\/(\d+)/.test(pathName) && params.tab === 'templates')
            ) {
                let arr = /\/\d+\/crm\/counterparties\/(\d+)/.exec(pathName);
                if (arr && arr[1]) {
                    params.renterId = +arr[1];
                }

                return {
                    ...state,
                    params: {
                        ...state.params,
                        search: params.search !== undefined ? '' + params.search : initialParamsState.search,
                        sortBy: params.sortBy !== undefined ? params.sortBy : initialParamsState.sortBy,
                        sortOrder: params.sortOrder !== undefined ? params.sortOrder : initialParamsState.sortOrder,
                        page: params.page !== undefined ? +params.page : initialParamsState.page,
                        limit: params.limit !== undefined ? +params.limit : initialParamsState.limit,
                        hideArchive: params.hideArchive !== undefined ? params.hideArchive : initialParamsState.hideArchive,
                        rentElementsSum: test1(params.rentElementsSum, initialParamsState.rentElementsSum)?.map((price) => price),
                    },
                };
            }
            return state;

        case REQUEST(ACTION_TYPES.CHANGE_ARCHIVE):
            return {
                ...state,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.CHANGE_ARCHIVE):
            return {
                ...state,
                loading: false,
            };
        case SUCCESS(ACTION_TYPES.CHANGE_ARCHIVE):
            return {
                ...state,
                loading: !false,
            };
        case REQUEST(ACTION_TYPES.LOAD_ENTITIES):
            return {
                ...state,
                loadingError: undefined,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.LOAD_ENTITIES):
            console.dir(action.payload);
            return {
                ...state,
                loadingError: getServerError(action.payload),
                loading: false,
            };
        case SUCCESS(ACTION_TYPES.LOAD_ENTITIES):
            gridDataChangedSignal();

            return {
                ...state,
                loading: false,
                entities: (action.payload.data as ProjectRecordList).records,
                filteredCount: (action.payload.data as ProjectRecordList).listAttributes.filteredCount,
            };
        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export const loadTemplates = (intl, businessAccountId: number) => {
    return (dispatch, getState) => {
        let state: TemplatesState = (getState() as IRootState).templates;
        let { params } = state;

        let filters: string[] = [];
        if (params.hideArchive === true) filters.push('archive;EQ;false');
        if (params.startDate !== undefined && params.startDate[0] !== undefined) filters.push('startDate;GE;' + params.startDate[0]);
        if (params.startDate !== undefined && params.startDate[1] !== undefined) filters.push('startDate;LE;' + params.startDate[1]);
        if (params.rentElementsSum) {
            if (params.rentElementsSum[0] !== undefined)
                filters.push('rentElementsSum;GE;' + MoneyUtils.toCents(params.rentElementsSum[0]));
            if (params.rentElementsSum[1] !== undefined)
                filters.push('rentElementsSum;LE;' + MoneyUtils.toCents(params.rentElementsSum[1]));
        }
        const sortBy = getAvailableGridSortById(params.sortBy, templatesColumns, undefined, initialParamsState.sortBy);

        return dispatch({
            type: ACTION_TYPES.LOAD_ENTITIES,
            payload: serverApi.listTemplates(
                businessAccountId,
                params.limit,
                (params.page - 1) * params.limit,
                undefined,
                sortBy,
                params.sortOrder,
                params.search,
                { query: { filters: filters } }
            ),
        }).then((result) => {
            if (result instanceof Error) {
                showNotification('error', localize(LocalizationEnum.PAGE__TEMPLATES__POPUP_NOTIFICATIONS__FAILED_TO_LOAD_TEMPLATE_LIST));
            }
        });
    };
};

export const setArchiveState = (intl, businessAccountId: number, directives: Array<WorkflowDirective>) => {
    return (dispatch) => {
        return dispatch({
            type: ACTION_TYPES.CHANGE_ARCHIVE,
            payload: serverApi.transitTemplateWorkflowBulk(businessAccountId, { directives: directives }),
        }).then((result) => {
            if (result instanceof Error) {
                showNotification('error', localizeIntl(intl, LocalizationEnum.ASPECT__GLOBAL__ERROR));
            } else {
                showNotification(
                    'success',
                    directives.length === 1
                        ? localizeIntl(intl, LocalizationEnum.PAGE__TEMPLATES__POPUP_NOTIFICATIONS__TEMPLATE_UPDATED)
                        : localizeIntl(intl, LocalizationEnum.PAGE__TEMPLATES__POPUP_NOTIFICATIONS__TEMPLATES_UPDATED)
                );
                setTimeout(() => dispatch(loadTemplates(intl, businessAccountId)), 1500);
            }
        });
    };
};

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});

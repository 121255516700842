import { CSSProperties } from 'react';

export const getStylesForSubrent = (isBtnSubrent: boolean, currentSubrentShipmentDelay: number, right?: boolean) => {
    let stylesForSubrent: CSSProperties = {
        margin: '0px 5px',
        padding: '0px 10px',
        display: 'flex',
        alignItems: 'center',
    };

    const color = right === true ? '#FC8C2F' : '#F4516c';

    if (isBtnSubrent) {
        stylesForSubrent['border'] = `1px solid ${color}`;
        stylesForSubrent['backgroundColor'] = color;
    } else {
        stylesForSubrent['backgroundColor'] = '#fff';
        stylesForSubrent['color'] = color;
    }

    if (currentSubrentShipmentDelay === 0 && !isBtnSubrent) {
        delete stylesForSubrent['backgroundColor'];
        delete stylesForSubrent['color'];
    }

    if (currentSubrentShipmentDelay) {
        stylesForSubrent['borderColor'] = color;
    } else {
        stylesForSubrent['borderColor'] = '#d9d9d9';
    }

    return stylesForSubrent;
};

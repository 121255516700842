import React, { useState } from 'react';
import { SimpleTable, SimpleTableColumn } from '../v2/simpleTable/SimpleTable';
import { Input } from 'antd';
import { SystemIcon, SystemIconType } from '../v2/systemIcon/systemIcon';
import { ContactSelectAndCreate } from '../v2/select/contactSelect/contactSelectAndCreate';
import { isDefined } from '../../shared/util/utils';
import './contactsEditor.less';
import _ from 'lodash';
import { IDetailedSelectColumn } from '../v2/detailedSelect/DetailedSelect';

interface ContactsEditorProps {
    value: ContactData[];
    onChange: (value: ContactData[]) => void;
    getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
    selectColumns?: IDetailedSelectColumn[];
}

interface ContactData {
    position: string;
    contactId?: number;
}

export const ContactsEditorFC = ({ value, onChange, getPopupContainer, selectColumns }: ContactsEditorProps) => {
    const [contacts, setContacts] = useState<ContactData[]>(value);

    const editContact = (index: number, contact: Partial<ContactData>) => {
        const updatedContacts = [...contacts];

        const existingContact = updatedContacts[index];
        if (existingContact) {
            updatedContacts[index] = { ...existingContact, ...contact };
            if (!_.isEqual(contacts, updatedContacts)) {
                setContacts(updatedContacts);
                onChange(updatedContacts);
            }
        }
    };

    const removeContact = (index: number) => {
        const updatedContacts = [...contacts];
        updatedContacts.splice(index, 1);

        setContacts(updatedContacts);
        onChange(updatedContacts);
    };

    const addContact = () => {
        const newContact = {
            contactId: undefined,
            position: '',
        };
        const updatedContacts = contacts ? [...contacts, newContact] : [newContact];

        setContacts(updatedContacts);
        onChange(updatedContacts);
    };

    const columns: SimpleTableColumn<ContactData>[] = [
        {
            id: 'contactId',
            width: 200,
            style: { paddingRight: 30 },
            render: (value, row, index) => {
                return (
                    <div>
                        <ContactSelectAndCreate
                            onChange={(value) => {
                                if (isDefined(value)) editContact(index, { contactId: +value });
                            }}
                            onDataChange={(data) => {
                                const contactRecord = data?.[0];
                                const contact = contacts[index];
                                const position = !contact.position ? contactRecord?.primaryPosition : contact.position;
                                editContact(index, { contactId: contactRecord?.id, position });
                            }}
                            style={{ width: '100%' }}
                            value={row.contactId}
                            getPopupContainer={getPopupContainer}
                            filters={{ hideArchive: true }}
                            columns={selectColumns}
                            allowClear={false}
                        />
                    </div>
                );
            },
            header: 'Контакт',
        },
        {
            id: 'position',
            width: 100,
            style: { paddingRight: 20 },
            render: (value, row, index) => {
                return (
                    <Input
                        value={row.position}
                        onChange={(e) => {
                            editContact(index, { position: e.target.value });
                        }}
                        onBlur={(e) => {
                            editContact(index, { position: e.target.value });
                        }}
                        placeholder={row.position}
                    />
                );
            },
            header: 'Должность',
        },
        {
            id: 'contactId',
            width: 32,
            minWidth: 32,
            maxWidth: 32,
            style: { lineHeight: 1 },
            render: (value, row, index) => {
                return (
                    <SystemIcon
                        type={SystemIconType.cancel}
                        onClick={(e) => {
                            removeContact(index);
                        }}
                    />
                );
            },
        },
    ];

    return (
        <div className={'rr-edit-contacts-form'}>
            <SimpleTable className={'contacts-editor-table'} columns={columns} data={contacts} />
            {contacts.length === 0 && <div style={{ color: '#aaaaaa', fontSize: 18 }}>Контакты не указаны</div>}
            <a onClick={addContact} style={{ color: '#343f9a', textDecoration: 'underline' }}>
                Добавить строку
            </a>
        </div>
    );
};

// Без этого не работает прокрутка при валидации в форме
export class ContactsEditor extends React.Component<ContactsEditorProps> {
    render() {
        return <ContactsEditorFC {...this.props} />;
    }
}

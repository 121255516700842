import React, { FC, memo, useCallback, useRef, useState } from 'react';
import { Button, Icon, Popover } from 'antd';
import classNames from 'classnames';
import { useChosenIds } from './hooks/useChosenIds';
import { useCustomFieldsStorageValue } from '../../hooks/customFields/useCustomFieldsStorageValue';
import { LabeledValue } from 'antd/es/select';
import { useAppDispatch } from '../../../../../../store/hooks';
import { NomenclatureRecordCustom } from '../../api/products.api.types';
import { EntityTypeCodeEnum } from '../../../../../../server';
import { ArrayUtils } from '../../../../../../core/utils/arrayUtils';
import { changeCustomFieldsBindings } from '../../../../settings/customFields/reducers/customFields.reducer';
import { productsApi } from '../../api/products.api';
import { CustomFieldSelect } from '../../../../../../components/v2/select/customFieldSelect/customFieldSelect';
import { WarningsList } from '../../../../../../components/text/WarningsList/WarningsList';
import { IconLinkSolid, IconStreamSolid, IconUnlinkSolid } from '../../../../../../components/icons';
import AdditionalEntityActions from '../../../../../../components/additionalEntityActions/additionalEntityActions';
import { LocalizationEnum, localize } from '../../../../../../localization';

interface ProductAdditionalEntityActionForCustomFieldsProps {
    selectedRecords: NomenclatureRecordCustom[];
    clearSelection: () => void;
}

export const ProductAdditionalEntityActionForCustomFields: FC<ProductAdditionalEntityActionForCustomFieldsProps> = memo((props) => {
    const { selectedRecords, clearSelection } = props;
    const dispatch = useAppDispatch();

    const [selectedCustomIdsFromStorage, setSelectedStorageKey] = useCustomFieldsStorageValue(EntityTypeCodeEnum.PRODUCT);
    const initialCustomIds = useRef<LabeledValue | LabeledValue[] | undefined>(selectedCustomIdsFromStorage);
    const [selectedCustomFieldIds, setSelectedCustomFieldIds] = useState<number[]>([]);
    const { assignIds, assignEntitiesIds, detachIds, detachEntitiesIds } = useChosenIds(selectedRecords, selectedCustomFieldIds ?? []);

    const changeCategoryBindingCallback = (customFieldsIds: number[], entityIds: number[], createLinks: boolean) => {
        if (customFieldsIds.length === 0) return;

        const entityProductIds = ArrayUtils.removeDuplicates(
            entityIds.map((entityId) => selectedRecords.find((record) => record.productId === entityId)!.productId)
        );

        clearSelection();
        dispatch(
            changeCustomFieldsBindings({
                createLinks,
                customFieldBulkLinkObj: {
                    entityTypeCode: EntityTypeCodeEnum.PRODUCT,
                    customFieldsIds,
                    entityIds: entityProductIds,
                },
                successCallback: () => dispatch(productsApi.util?.invalidateTags(['ProductsList', 'ProductsAvailableFiltersList'])),
            })
        );
    };

    const onCustomFieldsChange = useCallback((value: number[]) => {
        setSelectedCustomFieldIds(value);
    }, []);

    const onCustomFieldsChangeLabeled = useCallback(
        (value: LabeledValue[] | LabeledValue | undefined) => {
            setSelectedStorageKey(value);
        },
        [setSelectedStorageKey]
    );

    const selectedRecordsHasVariant = selectedRecords.some((record) => record.variantId != null);

    return (
        <Popover
            overlayClassName={classNames('grid-additional-actions-popover', 'rr-grid-actions-popover-nested')}
            autoAdjustOverflow
            placement="bottomLeft"
            trigger="click"
            align={{ offset: [12, 0] }}
            content={
                <>
                    <div className={'rr-grid-actions-popover-header1'}></div>
                    <div className={'rr-grid-actions-popover-content'}>
                        <div className={'category-select-block'}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <span>Настраиваемые поля</span>
                            </div>
                            <CustomFieldSelect
                                openOnMount={initialCustomIds.current === undefined}
                                multiple
                                placeholder={'Выберите поля'}
                                defaultValue={initialCustomIds.current}
                                onChange={(value) => onCustomFieldsChange(value as number[])}
                                onChangeLabeled={onCustomFieldsChangeLabeled}
                            />
                            {selectedRecordsHasVariant && (
                                <div
                                    style={{
                                        paddingTop: 8,
                                    }}
                                >
                                    <WarningsList
                                        warnings={[
                                            'Привязка и отвязка полей производится для продукта в целом, не только выбранных вариантов',
                                        ]}
                                    />
                                </div>
                            )}
                        </div>
                        <div className={'category-action-block'}>
                            <Button
                                className={'action green'}
                                block
                                disabled={assignEntitiesIds.length === 0}
                                onClick={() => changeCategoryBindingCallback(assignIds, assignEntitiesIds, true)}
                            >
                                <Icon className={'icon green'} component={IconLinkSolid} />
                                <span>Привязать поля</span>
                                {assignIds.length > 0 ? (
                                    <span className={'categories-count'}>
                                        ({assignEntitiesIds.length}{' '}
                                        {localize(LocalizationEnum.ASPECT__PLURAL__PRODUCT, undefined, {
                                            value: assignEntitiesIds.length,
                                        })}
                                        )
                                    </span>
                                ) : undefined}
                            </Button>
                            <Button
                                className={'action red'}
                                block
                                disabled={detachEntitiesIds.length === 0}
                                onClick={() => changeCategoryBindingCallback(detachIds, detachEntitiesIds, false)}
                            >
                                <Icon className={'icon red'} component={IconUnlinkSolid} />
                                <span>Отвязать поля</span>
                                {detachIds.length > 0 ? (
                                    <span className={'categories-count'}>
                                        ({detachEntitiesIds.length}{' '}
                                        {localize(LocalizationEnum.ASPECT__PLURAL__PRODUCT, undefined, {
                                            value: detachEntitiesIds.length,
                                        })}
                                        )
                                    </span>
                                ) : undefined}
                            </Button>
                        </div>
                    </div>
                </>
            }
        >
            <Button className={'grid-additional-actions-button'} block>
                <Icon component={IconStreamSolid} className={'change-custom-fields-button'} />
                <AdditionalEntityActions content={<></>}>
                    <span>Привязка полей характеристик</span>
                </AdditionalEntityActions>
            </Button>
        </Popover>
    );
});

import { ComponentProps, FC, ReactNode, useCallback, useRef, useState } from 'react';
import { useMouseDownEffect } from '../../../core/hooks/listeners/useMouseDownEffect';
import { useKeydownEffect } from '../../../core/hooks/listeners/useKeydownEffect';
import { InlineNumberInput } from '../../inlineInputs/InlineNumberInput/soft/InlineNumberInput';
import classNames from 'classnames';
import { SliderRangeFormatStyle } from '../../slider/SliderRange/SliderRange';
import './EditableField.less';

export interface EditableFieldProps {
    currentValue: number | undefined;
    baseValue: number;
    minValue: number;
    maxValue: number;
    step?: number;
    formatStyle: SliderRangeFormatStyle;
    onChange: (value: number) => void;
    valueFormatter?: (value: number) => ReactNode;
    labelProps?: ComponentProps<'span'>;
}

export const EditableField: FC<EditableFieldProps> = (props) => {
    const { currentValue, baseValue, onChange, minValue, maxValue, step, formatStyle, valueFormatter, labelProps } = props;
    const value = currentValue != null ? currentValue : baseValue;

    const containerRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const [isInputOpen, setIsInputOpen] = useState(false);

    const getBoundedValue = useCallback((value: number) => Math.max(minValue, Math.min(maxValue, value)), [maxValue, minValue]);

    const onMouseDown = useCallback(
        (event: MouseEvent) => {
            if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                const inputValue = inputRef.current?.value;
                if (inputValue) {
                    onChange(getBoundedValue(Number(inputValue)));
                }
                setIsInputOpen(false);
            }
        },
        [getBoundedValue, onChange]
    );

    const onKeyPress = useCallback(
        (event: globalThis.KeyboardEvent) => {
            if (event.key === 'Enter') {
                if (isInputOpen) {
                    const inputValue = inputRef.current?.value;
                    if (inputValue) {
                        onChange(getBoundedValue(Number(inputValue)));
                    }
                    setIsInputOpen(false);
                }
            }

            if (event.key === 'Escape') {
                if (isInputOpen) {
                    setIsInputOpen(false);
                }
            }
        },
        [isInputOpen, onChange]
    );

    useMouseDownEffect(onMouseDown);
    useKeydownEffect(onKeyPress);

    return isInputOpen ? (
        <InlineNumberInput
            inputProps={{
                ref: inputRef,
                formatStyle,
                defaultValue: value,
                min: minValue,
                max: maxValue,
                step,
            }}
            containerProps={{
                ref: containerRef,
            }}
            focusOnMount
        />
    ) : (
        <span
            onClick={() => setIsInputOpen(true)}
            {...labelProps}
            className={classNames(
                'editable-field',
                {
                    active: currentValue != null,
                    disabled: currentValue == null,
                },
                labelProps?.className
            )}
        >
            {valueFormatter ? valueFormatter(value) : value}
        </span>
    );
};

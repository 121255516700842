import {
    addInstanceToExistedOperationElement,
    addNewOperationElementFromEquipment, addNewOperationElementFromEquipment1,
    InstancePropertiesEntityRecordCustom,
    OperationElement,
    OperationFormState,
    TimeTable,
    updateAnyTimetables,
} from '../reducers/operationForm.reducer';
import {
    InstanceRecord,
    InstanceRecordList,
    InstanceTrackingTypeCodeEnum,
    KitMemberObjRead,
    NomenclatureEntityTypeCodeEnum,
    NomenclatureRecord,
    NomenclatureRecordList,
    OperationTypeCodeEnum, PricingSchemeExternalRepresentationObj,
    ProjectStateCodeEnum,
    RentStateCodeEnum,
    TimetableTypeCodeEnum,
} from '../../../../server/api';
import {LocalizationEnum, localize} from '../../../../localization';
import {
    IconBorderStyleSolid,
    IconCheckSquare,
    IconEdit,
    IconEraser,
    IconHandStopO,
    IconHourglassStart,
    IconMagicSolid,
    IconReply,
    IconRobotSolid,
    IconStickyNoteO,
    IconTimesCircle,
    IconWrench,
} from '../../../../components/icons';
import {getShiftCountFromDates, isDefined} from '../../../../shared/util/utils';
import moment, {Moment} from 'moment';
import {getStore, getStoreState} from '../../../../../index';
import {RentElementsGridItem} from '../../../../types';
import React, {ComponentType} from 'react';
import {AxiosResponse} from 'axios';
import {serverApi} from '../../../../server';
import {showNotification} from '../../../../components/notification/showNotification';
import {
    getElementEndDate,
    getElementStartDate,
    loadNomenclaturesOnIntervalsIfNeed,
    NomenclatureOnInterval,
    rentElementToNomenclatureBaseObj,
} from './nomenclaturesUtils';

/**
 * Утилитный метод создает новый объект для добавления в операцию, на основе входящих данных
 * @param productRecord
 * @param rentPeriodStartDate
 * @param rentPeriodEndDate
 * @param shiftCount
 * @param discount
 * @param instanceCount
 * @param rentPeriodChanged
 * @param shiftCountChanged
 */
export const createNewOperationElement = (data: {
    productRecord: InstancePropertiesEntityRecordCustom;
    rentPeriodStartDate: Date;
    rentPeriodEndDate: Date;
    shiftCount;
    discount;
    instanceCount;
    rentPeriodChanged;
    shiftCountChanged;
    discountChanged;
    instanceIds?: Array<number>;
    subRows?: OperationElement[];
    anonymousInstanceCount: number | undefined;
}): OperationElement => {
    let el: OperationElement = {
        id: 0,
        businessVersion: 0,
        productId: data.productRecord.productId,

        productShortName: data.productRecord.productShortName,
        productMainImage: data.productRecord.mainImage,
        stateCode: undefined,
        rentPeriodStartDate: data.rentPeriodStartDate,
        rentPeriodEndDate: data.rentPeriodEndDate,
        shiftCount: data.shiftCount,
        calendarShiftCount: data.shiftCount,
        discount: data.discount,
        // Доступно 100/1000
        instanceCount: data.instanceCount,
        instanceCountOriginal: data.instanceCount,
        finalTotalPrice: 0,

        effectivePrice: data.productRecord.pricePerShift,

        //stockInstanceCountX: data.productRecord.stockInstanceCount,
        availableInstanceCount: data.productRecord.availableInstanceCount,

        discountChanged: data.discountChanged,
        rentPeriodChanged: data.rentPeriodChanged, // false
        shiftCountChanged: data.shiftCountChanged, //false,
        disabled: false,

        instanceIds: data.instanceIds,
        variantId: data.productRecord.variantId,
        variantName: data.productRecord.variantName,
        instanceTrackingTypeCode: data.productRecord.instanceTrackingTypeCode,
        kitId: data.productRecord.kitId || undefined,
        //subRows: data.subRows,
        mainImage: data.productRecord.mainImage,

        anonymousInstanceCount: data.anonymousInstanceCount || 0,
        anonymousInstanceCountOriginal: data.anonymousInstanceCount,

        leftoverInstanceCount: 0,
        leftoverAnonymousInstanceCount: 0,
        keepLeftover: undefined,

        warnings: [],
        problems: [],

        variantIdOriginal: data.productRecord.variantId,
        shiftLengthInMinutes: data.productRecord.shiftLengthInMinutes,
        hasOwnShiftLength: data.productRecord.productHasOwnShiftLength,
        requiredTimeIndentBetweenElementsInMinutes: data.productRecord.requiredTimeIndentBetweenElementsInMinutes,
        productHasOwnRequiredTimeIndentBetweenElements: data.productRecord.productHasOwnRequiredTimeIndentBetweenElements,
        externalCode: data.productRecord.externalCode,

        pricingSchemeId: data.productRecord.pricingScheme,
    };
    return el;
};

export const createNewOperationElementFromKitMemember = (data: {
    kitRecord: KitMemberObjRead;
    //productRecord: InstancePropertiesEntityRecordCustom;
    rentPeriodStartDate: Date;
    rentPeriodEndDate: Date;
    shiftCount;
    discount;
    instanceCount;
    rentPeriodChanged;
    shiftCountChanged;
    discountChanged;
    instanceIds?: Array<number>;
    anonymousInstanceCount?: number;
    shiftLengthInMinutes?: number;
    hasOwnShiftLength?: boolean;
    requiredTimeIndentBetweenElementsInMinutes?: number;
    productHasOwnRequiredTimeIndentBetweenElements?: boolean;
    pricingSchemeId?: PricingSchemeExternalRepresentationObj | null;
}): OperationElement => {
    let el: OperationElement = {
        id: -1,
        businessVersion: 0,
        productId: data.kitRecord.productId,

        productShortName: data.kitRecord.productShortName,
        productMainImage: data.kitRecord.image,
        stateCode: undefined,
        rentPeriodStartDate: data.rentPeriodStartDate,
        rentPeriodEndDate: data.rentPeriodEndDate,
        shiftCount: data.shiftCount,
        calendarShiftCount: data.shiftCount,
        discount: data.discount,
        // Доступно 100/1000
        instanceCount: data.instanceCount,
        instanceCountOriginal: data.instanceCount,
        finalTotalPrice: 0,

        effectivePrice: data.kitRecord.finalInstancePrice,

        //stockInstanceCountX: 0, //data.kitRecord.stockInstanceCount, // TODO
        availableInstanceCount: 0, //data.kitRecord.availableInstanceCount, // TODO

        discountChanged: data.discountChanged,
        rentPeriodChanged: data.rentPeriodChanged, // false
        shiftCountChanged: data.shiftCountChanged, //false,
        disabled: false,

        instanceIds: data.instanceIds,
        variantId: data.kitRecord.variantId,
        variantName: data.kitRecord.variantName,
        instanceTrackingTypeCode: data.kitRecord.instanceTrackingTypeCode,
        //kitId: data.kitRecord.kitId || undefined,
        mainImage: data.kitRecord.image,

        anonymousInstanceCount: data.anonymousInstanceCount || 0,
        anonymousInstanceCountOriginal: data.anonymousInstanceCount,

        leftoverInstanceCount: 0,
        leftoverAnonymousInstanceCount: 0,
        keepLeftover: false,

        warnings: [],
        problems: [],
        mainKitMember: data.kitRecord.main,

        variantIdOriginal: data.kitRecord.variantId,
        shiftLengthInMinutes: data.shiftLengthInMinutes, // TODO !!!
        hasOwnShiftLength: data.hasOwnShiftLength,
        requiredTimeIndentBetweenElementsInMinutes: data.requiredTimeIndentBetweenElementsInMinutes,
        productHasOwnRequiredTimeIndentBetweenElements: data.productHasOwnRequiredTimeIndentBetweenElements,
        externalCode: data.kitRecord.externalCode,
        kitMemberId: data.kitRecord.id,
        pricingSchemeId: data.pricingSchemeId
    };
    return el;
};

/**
 *
 * @param type
 * @param fullStatusName
 */
export const getOperationStatusTypeData = (
    type?: OperationTypeCodeEnum,
    fullStatusName = true
): { name: React.ReactElement; iconComponent: ComponentType } => {
    let iconComponent, name;

    if (type === OperationTypeCodeEnum.BOOK) {
        name = localize(LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__BOOK);
        iconComponent = IconCheckSquare;
    } else if (type === OperationTypeCodeEnum.CANCEL) {
        name = localize(LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__CANCEL);
        iconComponent = IconEraser;
    } else if (type === OperationTypeCodeEnum.CORRECT) {
        name = localize(LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__CORRECT);
        iconComponent = IconMagicSolid;
    } else if (type === OperationTypeCodeEnum.LOSTNORETURN) {
        name = localize(LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__LOST_NO_RETURN);
        iconComponent = IconTimesCircle;
    } else if (type === OperationTypeCodeEnum.ORDER) {
        name = localize(LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__ORDER);
        iconComponent = IconStickyNoteO;
    } else if (type === OperationTypeCodeEnum.PROLONG) {
        name = localize(LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__PROLONG);
        iconComponent = IconHourglassStart;
    } else if (type === OperationTypeCodeEnum.RENT) {
        name = localize(LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__GIVE_AWAY);
        iconComponent = IconHandStopO;
    } else if (type === OperationTypeCodeEnum.RETURN) {
        name = localize(LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__RETURN);
        iconComponent = IconReply;
    } else if (type === OperationTypeCodeEnum.RETURNBROKEN) {
        name = localize(LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__RETURN_BROKEN);
        iconComponent = IconWrench;
    } else if (type === OperationTypeCodeEnum.DRAFT) {
        name = localize(LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__DRAFT);
        iconComponent = IconBorderStyleSolid;
    } else if (type === OperationTypeCodeEnum.AUTOMATICRULE) {
        name = fullStatusName ? 'Автоматическое правило' : 'Авт. правило';
        iconComponent = IconRobotSolid;
    } else if (type === OperationTypeCodeEnum.SUBRENTACCEPTSHIPMENT) {
        name = fullStatusName
            ? localize(LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__SUBRENT_ACCEPT_SHIPMENT)
            : localize(LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__SUBRENT_ACCEPT_SHIPMENT);
        iconComponent = IconHandStopO;
    } else if (type === OperationTypeCodeEnum.SUBRENTBOOKSHIPMENT) {
        name = fullStatusName ? localize(LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__SUBRENT_BOOK_SHIPMENT) : 'Бронир. СА';
        iconComponent = IconCheckSquare;
    } else if (type === OperationTypeCodeEnum.SUBRENTRETURNTOSHIPPER) {
        name = fullStatusName ? localize(LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__SUBRENT_RETURN_TO_SHIPPER) : 'Возвр. поставщ.';
        iconComponent = IconReply;
    } else if (type === OperationTypeCodeEnum.SUBRENTCANCEL) {
        name = fullStatusName
            ? localize(LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__SUBRENT_CANCEL)
            : localize(LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__SUBRENT_CANCEL);
        iconComponent = IconEraser;
    } else if (type === OperationTypeCodeEnum.SUBRENTDRAFT) {
        name = fullStatusName
            ? localize(LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__SUBRENT_DRAFT)
            : localize(LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__SUBRENT_DRAFT);
        iconComponent = IconBorderStyleSolid;
    } else if (type === OperationTypeCodeEnum.SUBRENTPROLONG) {
        name = fullStatusName
            ? localize(LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__SUBRENT_PROLONG)
            : localize(LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__SUBRENT_PROLONG);
        iconComponent = IconHourglassStart;
    } else if (type === OperationTypeCodeEnum.EDIT) {
        name = 'Правка';
        iconComponent = IconEdit;
    }
    return { name: name, iconComponent: iconComponent };
};

export const getOperationStatusTypeLocalizationKey = (type: OperationTypeCodeEnum): LocalizationEnum|string|undefined => {
    if (type === OperationTypeCodeEnum.BOOK) {
        return LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__BOOK;
    } else if (type === OperationTypeCodeEnum.CANCEL) {
        return LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__CANCEL;
    } else if (type === OperationTypeCodeEnum.CORRECT) {
        return LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__CORRECT;
    } else if (type === OperationTypeCodeEnum.LOSTNORETURN) {
        return LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__LOST_NO_RETURN;
    } else if (type === OperationTypeCodeEnum.ORDER) {
        return LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__ORDER;
    } else if (type === OperationTypeCodeEnum.PROLONG) {
        return LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__PROLONG;
    } else if (type === OperationTypeCodeEnum.RENT) {
        return LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__GIVE_AWAY;
    } else if (type === OperationTypeCodeEnum.RETURN) {
        return LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__RETURN;
    } else if (type === OperationTypeCodeEnum.RETURNBROKEN) {
        return LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__RETURN_BROKEN;
    } else if (type === OperationTypeCodeEnum.DRAFT) {
        return LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__DRAFT;
    } else if (type === OperationTypeCodeEnum.AUTOMATICRULE) {
        return 'Автоматическое правило';
    } else if (type === OperationTypeCodeEnum.SUBRENTACCEPTSHIPMENT) {
        return LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__SUBRENT_ACCEPT_SHIPMENT;
    } else if (type === OperationTypeCodeEnum.SUBRENTBOOKSHIPMENT) {
        return LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__SUBRENT_BOOK_SHIPMENT;
    } else if (type === OperationTypeCodeEnum.SUBRENTRETURNTOSHIPPER) {
        return LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__SUBRENT_RETURN_TO_SHIPPER;
    } else if (type === OperationTypeCodeEnum.SUBRENTCANCEL) {
        return LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__SUBRENT_CANCEL;
    } else if (type === OperationTypeCodeEnum.SUBRENTDRAFT) {
        return LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__SUBRENT_DRAFT;
    } else if (type === OperationTypeCodeEnum.SUBRENTPROLONG) {
        return LocalizationEnum.ASPECT__RENT_OPERATION__TYPE__SUBRENT_PROLONG;
    } else if (type === OperationTypeCodeEnum.EDIT) {
        return 'Правка';
    }
};

/**
 * Метод сохраняет или обновляет карты для номенклатур (Наборы, Продукты, Варианты, Экземпляры)
 * @param records
 * @param timeTables
 */
/*export const saveOrUpdateTimetables = (
    records: (NomenclatureRecord | InstanceRecord)[],
    timeTables: TimeTable[],
    code: OperationTypeCodeEnum | RentStateCodeEnum | undefined
) => {
    console.log('saveOrUpdateTimetables', code);

    const newTimeTable = (record: NomenclatureRecord | InstanceRecord): TimeTable => {
        return {
            list: record.stackMapList,
            timetableVersion: record.timetableVersion,
            available1: ProductUtils.getIntervalsFromTimetableList(record.stackMapList, TimetableTypeCodeEnum.AVAILABLE),
            orderAvailable: ProductUtils.getIntervalsFromTimetableList(record.stackMapList, TimetableTypeCodeEnum.ORDERAVAILABLE),
            stock: ProductUtils.getIntervalsFromTimetableList(record.stackMapList, TimetableTypeCodeEnum.STOCK),
            order: ProductUtils.getIntervalsFromTimetableList(record.stackMapList, TimetableTypeCodeEnum.ORDER),
            shiftLengthInMinutes: ('shiftLengthInMinutes' in record) ? record.shiftLengthInMinutes : undefined,
            hasOwnShiftLength: ('productHasOwnShiftLength' in record) ? record.productHasOwnShiftLength : undefined,
            requiredTimeIndentBetweenElementsInMinutes: ('requiredTimeIndentBetweenElementsInMinutes' in record) ? record.requiredTimeIndentBetweenElementsInMinutes : undefined,
            productHasOwnRequiredTimeIndentBetweenElements: ('productHasOwnRequiredTimeIndentBetweenElements' in record) ? record.productHasOwnRequiredTimeIndentBetweenElements : undefined,
            subrentSupply: ('subrentSupply' in record) ? record.subrentSupply : undefined,
            pricingScheme: ('pricingScheme' in record) ? record.pricingScheme : undefined,
            nomenclature: record
        };
    };
    //TODO

    records.forEach((record) => {
        let timeTable: TimeTable | undefined;

        if ('kitId' in record && isDefined(record.kitId)) {
            // Это набор
            timeTable = timeTables.find((item) => item.kitId === record.kitId);
            if (!timeTable) {
                timeTables.push({
                    id: record.kitId,
                    type: NomenclatureEntityTypeCodeEnum.KIT,
                    kitId: record.kitId,
                    ...newTimeTable(record),
                });
            }
        } else if ('id' in record && isDefined(record.id)) {
            // Это экземпляр
            timeTable = timeTables.find((item) => item.instanceId === record.id);
            if (!timeTable) {
                timeTables.push({
                    id: record.id,
                    type: NomenclatureEntityTypeCodeEnum.INSTANCE,
                    productId: record.productId,
                    variantId: record.variantId || undefined,
                    instanceId: record.id,
                    ...newTimeTable(record),
                });
            }
        } else if ('productId' in record && isDefined(record.productId)) {
            // Это продукт с вариантом или без
            timeTable = timeTables.find(
                (item) =>
                    !isDefined(item.instanceId) &&
                    item.productId === record.productId &&
                    ((!isDefined(record.variantId) && !isDefined(item.variantId)) || item.variantId === record.variantId)
            ); //(item.productId === record.productId && (!isDefined(record.variantId) || (item.variantId === record.variantId))));
            if (!timeTable) {
                let id:number;
                let type:NomenclatureEntityTypeCodeEnum;
                if(record.productId && record.variantId){
                    id = record.variantId;
                    type = NomenclatureEntityTypeCodeEnum.VARIANT;

                }else{
                    id = record.productId;
                    type = NomenclatureEntityTypeCodeEnum.PRODUCT;
                }
                timeTables.push({
                    id: id,
                    type: type,
                    productId: record.productId,
                    variantId: record.variantId || undefined,
                    ...newTimeTable(record),
                });
            }
        }

        if (timeTable) {
            timeTable.shiftLengthInMinutes = 'shiftLengthInMinutes' in record ? record.shiftLengthInMinutes : undefined;
            timeTable.hasOwnShiftLength = 'productHasOwnShiftLength' in record ? record.productHasOwnShiftLength : undefined;
            if (timeTable.timetableVersion !== record.timetableVersion) {
                timeTable.list = record.stackMapList;
                timeTable.timetableVersion = record.timetableVersion;
            }
            timeTable.pricingScheme = 'pricingScheme' in record ? record.pricingScheme : undefined;
        }
    });
};*/

/**
 * Ищем карту состояний по параметрам
 * @param timeTables
 * @param params
 */
export const findTimeTable = (
    timeTables: TimeTable[],
    params: { kitId?: number; productId?: number; variantId?: number; instanceId?: number }
) => {
    let timeTable: TimeTable | undefined;

    if (isDefined(params.kitId)) {
        // Ищем набор
        timeTable = timeTables.find((item) => item.kitId === params.kitId);
    } else if (isDefined(params.instanceId)) {
        // Ищем экземпляр
        timeTable = timeTables.find((item) => item.instanceId === params.instanceId);
    } else if (isDefined(params.productId)) {
        // Ищем продукт
        if (!isDefined(params.variantId) && !isDefined(params.instanceId)) {
            // только продукт
            timeTable = timeTables.find(
                (item) => item.productId === params.productId && !isDefined(item.variantId) && !isDefined(item.instanceId)
            );
        } else if (isDefined(params.variantId) && !isDefined(params.instanceId)) {
            timeTable = timeTables.find(
                (item) => item.productId === params.productId && item.variantId === params.variantId && !isDefined(item.instanceId)
            );
        } else {
            timeTable = timeTables.find(
                (item) =>
                    item.productId === params.productId && item.variantId === params.variantId && item.instanceId === params.instanceId
            );
        }
    }
    //console.log('findTimeTable', timeTable, params);
    return timeTable;
};

export const getLeftoverInstances = (oldInstances: number[] | undefined, newInstances: number[] | undefined) => {
    let leftoverInstances: number[] = [];
    oldInstances?.forEach((id) => {
        if (!newInstances?.includes(id)) leftoverInstances.push(id);
    });
    return leftoverInstances;
};

export enum OperationErrorCodeEnum {
    PROBLEM = 'PROBLEM',
    WARNING = 'WARNING',
}

/**
 *
 * @param operationTypeCode
 */
export const operationWithShortage = (operationTypeCode?: OperationTypeCodeEnum): boolean => {
    return (
        operationTypeCode === OperationTypeCodeEnum.ORDER ||
        operationTypeCode === OperationTypeCodeEnum.DRAFT ||
        operationTypeCode === OperationTypeCodeEnum.CORRECT ||
        operationTypeCode === OperationTypeCodeEnum.RETURN ||
        operationTypeCode === OperationTypeCodeEnum.RETURNBROKEN ||
        operationTypeCode === OperationTypeCodeEnum.LOSTNORETURN ||
        operationTypeCode === OperationTypeCodeEnum.CANCEL ||
        operationTypeCode === OperationTypeCodeEnum.SUBRENTACCEPTSHIPMENT ||
        operationTypeCode === OperationTypeCodeEnum.SUBRENTBOOKSHIPMENT ||
        operationTypeCode === OperationTypeCodeEnum.SUBRENTDRAFT ||
        operationTypeCode === OperationTypeCodeEnum.SUBRENTCANCEL ||
        operationTypeCode === OperationTypeCodeEnum.SUBRENTPROLONG ||
        operationTypeCode === OperationTypeCodeEnum.SUBRENTRETURNTOSHIPPER
    );
};

/**
 * Может ли быть нехватка для конкретного типа операции. Проблема или Предупреждение.
 * @param code
 * @param correction
 */
export const canBeErrorWhenShortage = (
    code: OperationTypeCodeEnum | undefined,
    correction: RentStateCodeEnum | undefined,
    elementStateCode: RentStateCodeEnum | undefined // TODO EDIT !!!
): OperationErrorCodeEnum | undefined => {
    let problem: OperationErrorCodeEnum | undefined;
    // TODO Тут видимо нужно добавить будет субаренду
    if (code) {
        if ([OperationTypeCodeEnum.BOOK, OperationTypeCodeEnum.RENT, OperationTypeCodeEnum.PROLONG].includes(code)) {
            // Бронь, Выдача, Продление
            // Проблема
            problem = OperationErrorCodeEnum.PROBLEM;
        } else if ([OperationTypeCodeEnum.ORDER, OperationTypeCodeEnum.DRAFT].includes(code)) {
            // Предупреждение
            problem = OperationErrorCodeEnum.WARNING;
        } else if ([OperationTypeCodeEnum.CORRECT].includes(code) && correction !== RentStateCodeEnum.CANCELED) {
            // Предупреждение
            problem = OperationErrorCodeEnum.WARNING;
        } else if(code === OperationTypeCodeEnum.EDIT && elementStateCode){ // TODO EDIT !!!
            if([RentStateCodeEnum.RENT, RentStateCodeEnum.BOOKED].includes(elementStateCode)){
                problem = OperationErrorCodeEnum.PROBLEM;
            }else if([RentStateCodeEnum.ORDERED, RentStateCodeEnum.DRAFT].includes(elementStateCode)){
                problem = OperationErrorCodeEnum.WARNING;
            }
        }
    }
    return problem;
};

/**
 * Может ли смен быть больше за период чем физических
 * @param code
 * @param correction
 */
export const canBeErrorWhenShiftCountMoreThanPeriod = (
    code: OperationTypeCodeEnum | undefined,
    correction: RentStateCodeEnum | undefined
): OperationErrorCodeEnum | undefined => {
    let problem: OperationErrorCodeEnum | undefined;
    if (code) {
        problem = OperationErrorCodeEnum.WARNING;
        if (code === OperationTypeCodeEnum.CANCEL || code === OperationTypeCodeEnum.SUBRENTCANCEL) {
            problem = undefined;
        } else if (
            code === OperationTypeCodeEnum.CORRECT &&
            (correction === RentStateCodeEnum.CANCELED || correction === RentStateCodeEnum.SUBRENTCANCELED)
        ) {
            problem = undefined;
        }
    }
    return problem;
};

/**
 * Можем ли добавлять новые продукты в операцию
 * @param operationTypeCode
 */
export const canAddNewProductsToOperation = (operationTypeCode: OperationTypeCodeEnum | undefined): boolean => {
    return (
        !!operationTypeCode &&
        [
            OperationTypeCodeEnum.DRAFT,
            OperationTypeCodeEnum.RENT,
            OperationTypeCodeEnum.BOOK,
            OperationTypeCodeEnum.ORDER,
            OperationTypeCodeEnum.CORRECT,
            OperationTypeCodeEnum.EDIT,

            OperationTypeCodeEnum.SUBRENTACCEPTSHIPMENT,
            OperationTypeCodeEnum.SUBRENTDRAFT,
            OperationTypeCodeEnum.SUBRENTBOOKSHIPMENT,
            //OperationTypeCodeEnum.SUBRENTCANCEL,
            //OperationTypeCodeEnum.SUBRENTRETURNTOSHIPPER
        ].includes(operationTypeCode)
    );
};

export const canAddNewProductsToOperation2 = (state:OperationFormState, element?:OperationElement): boolean => {
    if(state.typeCode !== OperationTypeCodeEnum.EDIT){
        // Просто операция
        return !!state.typeCode &&
            [
                OperationTypeCodeEnum.DRAFT,
                OperationTypeCodeEnum.RENT,
                OperationTypeCodeEnum.BOOK,
                OperationTypeCodeEnum.ORDER,
                OperationTypeCodeEnum.CORRECT,
                //OperationTypeCodeEnum.EDIT,
                OperationTypeCodeEnum.SUBRENTACCEPTSHIPMENT,
                OperationTypeCodeEnum.SUBRENTDRAFT,
                OperationTypeCodeEnum.SUBRENTBOOKSHIPMENT
            ].includes(state.typeCode);
    }else{
        if(!state.isSimpleOrder){
            //  Аналогично для операции правки в обычном проекте, если статус Аренда, Возвращено (+2 возвращенных статуса) или Субаренда и Возвращено поставщику - нельзя выбирать новые экземпляры и увеличивать их число.
            // Редактирование
            return !!element?.stateCodeNew && ![RentStateCodeEnum.RENT, RentStateCodeEnum.RETURNED, RentStateCodeEnum.RETURNEDBROKEN, RentStateCodeEnum.LOSTDURINGRENT, RentStateCodeEnum.SUBRENT, RentStateCodeEnum.SUBRENTRETURNEDTOSHIPPER].includes(element.stateCodeNew);
        }else{
            // Простой заказ: редактирование или создание
            // 19 В уже выданном в аренду и завершенном проекте нельзя добавлять новые экземпляры и увеличивать их число, система это не пропустит.
            return !!state.projectStateCode && ![ProjectStateCodeEnum.RENTED, ProjectStateCodeEnum.FINISHED].includes(state.projectStateCode);
        }
    }
};

/**
 *
 * @param operationTypeCode
 */
export const canChangeKeepLeftover = (operationTypeCode: OperationTypeCodeEnum | undefined): boolean => {
    let canChangeKeepLeftover =
        operationTypeCode !== OperationTypeCodeEnum.SUBRENTRETURNTOSHIPPER &&
        operationTypeCode !== OperationTypeCodeEnum.RETURN &&
        operationTypeCode !== OperationTypeCodeEnum.RETURNBROKEN &&
        operationTypeCode !== OperationTypeCodeEnum.LOSTNORETURN &&
        operationTypeCode !== OperationTypeCodeEnum.PROLONG &&
        operationTypeCode !== OperationTypeCodeEnum.SUBRENTPROLONG;
    return canChangeKeepLeftover;
};

/**
 * Можем ли редактировать параметры по умолчанию (В составе операции)
 * @param operationTypeCode
 */
export const canEditDefaultOperationParams = (operationTypeCode: OperationTypeCodeEnum | undefined): boolean => {
    return !!operationTypeCode && [OperationTypeCodeEnum.CANCEL, OperationTypeCodeEnum.SUBRENTCANCEL].includes(operationTypeCode);
};

/**
 * Можем ли редактировать параметры элемента операции
 * @param operationTypeCode
 */
export const canEditOperationElementParams = (operationTypeCode: OperationTypeCodeEnum | undefined): boolean => {
    return !!operationTypeCode && [OperationTypeCodeEnum.CANCEL, OperationTypeCodeEnum.SUBRENTCANCEL].includes(operationTypeCode);
};

/**
 * Можем ли создавать элементы в операции на основе
 * @param operationTypeCode
 */
export const canCopyElementInOperation = (operationTypeCode: OperationTypeCodeEnum | undefined): boolean => {
    return (
        !!operationTypeCode &&
        [
            OperationTypeCodeEnum.RENT,
            OperationTypeCodeEnum.BOOK,
            OperationTypeCodeEnum.ORDER,
            OperationTypeCodeEnum.DRAFT,
            OperationTypeCodeEnum.CORRECT,

            OperationTypeCodeEnum.SUBRENTDRAFT,
            OperationTypeCodeEnum.SUBRENTBOOKSHIPMENT,
            OperationTypeCodeEnum.SUBRENTACCEPTSHIPMENT,
        ].includes(operationTypeCode)
    );
};

/**
 *
 * @param code
 * @param elements
 * @param startDate
 * @param endDate
 */
// export const canActualizeOperationDates = (code: OperationTypeCodeEnum | RentStateCodeEnum | undefined, elements: OperationElement[], currentServerTimeMsec:number) => {
//     let canActualize = true;
//     elements.forEach((element) => {
//         if (isRentOperation(code) || code === OperationTypeCodeEnum.DRAFT) {
//             if (currentServerTimeMsec > element.rentPeriodEndDate.getTime()) canActualize = false;
//         } else if (isReturnOperation(code)) {
//             if (currentServerTimeMsec < element.rentPeriodStartDate.getTime()) canActualize = false;
//         }
//     });
//     return canActualize;
// };

/**
 * Можем ли увеличить кол-во экземпляров для элемента
 * @param code
 */
export const canAddInstancesToExistedElement = (code: OperationTypeCodeEnum | RentStateCodeEnum | undefined) => {
    return (
        isRentOperation(code) ||
        isBookOperation(code) ||
        isOrderOperation(code) ||
        code === OperationTypeCodeEnum.DRAFT ||
        code === OperationTypeCodeEnum.SUBRENTDRAFT ||
        code === OperationTypeCodeEnum.SUBRENTBOOKSHIPMENT ||
        code === OperationTypeCodeEnum.SUBRENTACCEPTSHIPMENT ||
        code === RentStateCodeEnum.ORDERED || // Корректировка:
        code === RentStateCodeEnum.BOOKED || // Корректировка:
        code === RentStateCodeEnum.RENT || // Корректировка:
        code === RentStateCodeEnum.RETURNED || // Корректировка:
        code === RentStateCodeEnum.RETURNEDBROKEN || // Корректировка:
        code === RentStateCodeEnum.LOSTDURINGRENT || // Корректировка:
        code === RentStateCodeEnum.CANCELED || // Корректировка:
        code === RentStateCodeEnum.DRAFT || // Корректировка:
        code === RentStateCodeEnum.SUBRENTSHIPMENTBOOKED || // Корректировка: Бронь СА
        code === RentStateCodeEnum.SUBRENT || // Корректировка: Субаренда
        code === RentStateCodeEnum.SUBRENTRETURNEDTOSHIPPER || // Корректировка: Завершено СА
        code === RentStateCodeEnum.SUBRENTCANCELED || // Корректировка: Отмена СА
        code === RentStateCodeEnum.SUBRENTDRAFT // Корректировка: Черновик СА
    );
};

/**
 * Можем ли создать операцию с анонимными экземплярами
 * @param code
 */
export const canCreateOperationWithAnonymousInstances = (code: OperationTypeCodeEnum | RentStateCodeEnum | undefined) => {
    return (
        code === OperationTypeCodeEnum.DRAFT ||
        isBookOperation(code) ||
        isOrderOperation(code) ||
        code === RentStateCodeEnum.CANCELED ||
        code === OperationTypeCodeEnum.CANCEL ||
        code === RentStateCodeEnum.DRAFT ||
        code === OperationTypeCodeEnum.SUBRENTBOOKSHIPMENT ||
        code === OperationTypeCodeEnum.SUBRENTCANCEL ||
        code === OperationTypeCodeEnum.SUBRENTDRAFT ||
        code === RentStateCodeEnum.SUBRENTSHIPMENTBOOKED ||
        code === RentStateCodeEnum.SUBRENTCANCELED ||
        code === RentStateCodeEnum.SUBRENTDRAFT
    );
};

/**
 * Можем ли сменить вариант у экземпляра
 * @param code
 */
export const canChangeVariantOnElement = (code: OperationTypeCodeEnum | RentStateCodeEnum | undefined) => {
    return (
        code === OperationTypeCodeEnum.ORDER ||
        code === OperationTypeCodeEnum.BOOK ||
        code === OperationTypeCodeEnum.RENT ||
        code === OperationTypeCodeEnum.DRAFT ||
        code === OperationTypeCodeEnum.SUBRENTBOOKSHIPMENT ||
        code === OperationTypeCodeEnum.SUBRENTACCEPTSHIPMENT ||
        code === OperationTypeCodeEnum.SUBRENTDRAFT ||
        code === OperationTypeCodeEnum.CORRECT
    );
};

/**
 *
 * @param operationTypeCode
 * @param correction
 */
export const isSubrentOperation = (
    operationTypeCode: OperationTypeCodeEnum | undefined,
    correction: RentStateCodeEnum | undefined
): boolean => {
    return (
        (!!operationTypeCode &&
            [
                OperationTypeCodeEnum.SUBRENTDRAFT,
                OperationTypeCodeEnum.SUBRENTCANCEL,
                OperationTypeCodeEnum.SUBRENTRETURNTOSHIPPER,
                OperationTypeCodeEnum.SUBRENTBOOKSHIPMENT,
                OperationTypeCodeEnum.SUBRENTACCEPTSHIPMENT,
                OperationTypeCodeEnum.SUBRENTPROLONG,

                RentStateCodeEnum.SUBRENTSHIPMENTBOOKED,
                RentStateCodeEnum.SUBRENTRETURNEDTOSHIPPER,
                RentStateCodeEnum.SUBRENTDRAFT,
                RentStateCodeEnum.SUBRENTCANCELED,
                RentStateCodeEnum.SUBRENT,
            ].includes(operationTypeCode)) ||
        (operationTypeCode === OperationTypeCodeEnum.CORRECT &&
            !!correction &&
            [
                RentStateCodeEnum.SUBRENTSHIPMENTBOOKED,
                RentStateCodeEnum.SUBRENTRETURNEDTOSHIPPER,
                RentStateCodeEnum.SUBRENTDRAFT,
                RentStateCodeEnum.SUBRENTCANCELED,
                RentStateCodeEnum.SUBRENT,
            ].includes(correction))
    );
};

// TODO EDIT !!!
export const isSubrentElementOnEditOperation = (operationTypeCode:OperationTypeCodeEnum|undefined, stateCode:RentStateCodeEnum|undefined) => {
    return operationTypeCode === OperationTypeCodeEnum.EDIT && stateCode && [
        RentStateCodeEnum.SUBRENTSHIPMENTBOOKED,
        RentStateCodeEnum.SUBRENTRETURNEDTOSHIPPER,
        RentStateCodeEnum.SUBRENTDRAFT,
        RentStateCodeEnum.SUBRENTCANCELED,
        RentStateCodeEnum.SUBRENT,
    ].includes(stateCode);
};

/**
 *
 * @param code
 */
export const isRentOperation = (code: OperationTypeCodeEnum | RentStateCodeEnum | undefined) => {
    return code === OperationTypeCodeEnum.RENT || code === RentStateCodeEnum.RENT;
};

export const isRentOrSubrentOperation = (code: OperationTypeCodeEnum | RentStateCodeEnum | undefined) => {
    return isRentOperation(code) || code === OperationTypeCodeEnum.SUBRENTACCEPTSHIPMENT || code === RentStateCodeEnum.SUBRENT;
};

/**
 * Операция ХХ или нет
 * @param code
 */
export const isBookOperation = (code: OperationTypeCodeEnum | RentStateCodeEnum | undefined) => {
    return code === OperationTypeCodeEnum.BOOK || code === RentStateCodeEnum.BOOKED;
};

/**
 * Операция ХХ или нет
 * @param code
 */
export const isOrderOperation = (code: OperationTypeCodeEnum | RentStateCodeEnum | undefined) => {
    return code === OperationTypeCodeEnum.ORDER || code === RentStateCodeEnum.ORDERED;
};

// TODO EDIT !!!
export const isOrderElementOnEditOperation = (operationTypeCode:OperationTypeCodeEnum|undefined, stateCode:RentStateCodeEnum|undefined) => {
    return operationTypeCode === OperationTypeCodeEnum.EDIT && stateCode === RentStateCodeEnum.ORDERED;
};

/**
 * Оперция возврата или нет
 * @param code
 */
export const isReturnOperation = (code: OperationTypeCodeEnum | RentStateCodeEnum | undefined) => {
    return (
        code === OperationTypeCodeEnum.RETURN ||
        code === OperationTypeCodeEnum.RETURNBROKEN ||
        code === OperationTypeCodeEnum.LOSTNORETURN ||
        code === RentStateCodeEnum.RETURNED ||
        code === RentStateCodeEnum.LOSTDURINGRENT ||
        code === RentStateCodeEnum.RETURNEDBROKEN
    );
};

export const isReturnOrSubrentReturnOperation = (code: OperationTypeCodeEnum | RentStateCodeEnum | undefined) => {
    return (
        isReturnOperation(code) ||
        code === OperationTypeCodeEnum.SUBRENTRETURNTOSHIPPER ||
        code === RentStateCodeEnum.SUBRENTRETURNEDTOSHIPPER
    );
};

export const operationElementCanBeCancelled = (code: OperationTypeCodeEnum | undefined, entity: OperationElement) => {
    let arr = [
        RentStateCodeEnum.DRAFT,
        RentStateCodeEnum.ORDERED,
        RentStateCodeEnum.BOOKED,
        RentStateCodeEnum.CANCELED,
        RentStateCodeEnum.SUBRENTDRAFT,
        RentStateCodeEnum.SUBRENTSHIPMENTBOOKED,
        RentStateCodeEnum.SUBRENTCANCELED,
    ];
    return code === OperationTypeCodeEnum.CORRECT || (entity.stateCode && arr.includes(entity.stateCode));
};

export const getKeepLeftoverByElementStateCode = (stateCode: RentStateCodeEnum | undefined): boolean => {
    return true; //stateCode === RentStateCodeEnum.RENT || stateCode === RentStateCodeEnum.SUBRENT;
};

export const is_OperationTimeError = (
    startDateMsec: Date | Moment | number | undefined,
    endDateMsec: Date | Moment | number | undefined
) => {
    let datesError = false;
    if (moment(startDateMsec).valueOf() >= moment(endDateMsec).valueOf()) {
        datesError = true;
    }
    return datesError;
};

export const isOperationTimeError = (
    startDateMsec: Date | Moment | number | undefined,
    endDateMsec: Date | Moment | number | undefined
) => {
    let datesError = false;
    const state = getStoreState();
    const operationTypeCode = state.operationForm.typeCode;
    const operationTypeCorrectionCode = state.operationForm.targetStateCode;
    const operationStartTime = state.operationForm.operationStartTime;

    if (
        operationTypeCode === OperationTypeCodeEnum.RENT ||
        operationTypeCode === OperationTypeCodeEnum.PROLONG ||
        operationTypeCode === OperationTypeCodeEnum.SUBRENTACCEPTSHIPMENT ||
        operationTypeCode === OperationTypeCodeEnum.SUBRENTPROLONG ||
        operationTypeCorrectionCode === RentStateCodeEnum.RENT ||
        operationTypeCorrectionCode === RentStateCodeEnum.SUBRENT
    ) {
        if (moment(startDateMsec).valueOf() > moment(operationStartTime).valueOf()) {
            datesError = true;
        }
    }

    // #1128 3.7. Для любого типа операции обязательство в целевом статусе
    // возвращено/возвр. с дефектом/списано/возвращено поставщику не может иметь окончание периода в будущем;
    if (
        operationTypeCode === OperationTypeCodeEnum.LOSTNORETURN ||
        operationTypeCode === OperationTypeCodeEnum.RETURNBROKEN ||
        operationTypeCode === OperationTypeCodeEnum.RETURN ||
        operationTypeCode === OperationTypeCodeEnum.SUBRENTRETURNTOSHIPPER ||
        operationTypeCorrectionCode === RentStateCodeEnum.RETURNED ||
        operationTypeCorrectionCode === RentStateCodeEnum.RETURNEDBROKEN ||
        operationTypeCorrectionCode === RentStateCodeEnum.LOSTDURINGRENT ||
        operationTypeCorrectionCode === RentStateCodeEnum.SUBRENTRETURNEDTOSHIPPER
    ) {
        if (moment(endDateMsec).valueOf() > moment(operationStartTime).valueOf()) {
            datesError = true;
        }
    }

    if (moment(startDateMsec).valueOf() >= moment(endDateMsec).valueOf()) {
        datesError = true;
    }

    return datesError;
};

export const addElementToElementsFromScan = async (
    elementToAdd: RentElementsGridItem,
    instanceId: number | undefined,
    succesCb?: () => void,
    scanner?: boolean
) => {
    if (elementToAdd.kitId) {
        addKitToElementsFromScan(elementToAdd, undefined, succesCb, scanner);
        return;
    }

    const nomenclatures: NomenclatureOnInterval[] = [];

    const entities = getStoreState().operationForm.elements.entities;

    let item = elementToAdd;

    let instanceCount = 1;
    let anonymousInstanceCount = 0;

    let itemStartDate = moment(elementToAdd.rentTerms.rentPeriodStartDate);
    let itemEndDate = moment(elementToAdd.rentTerms.rentPeriodEndDate);
    let itemShiftCount = elementToAdd.rentTerms.shiftCount;
    const pricingScheme = elementToAdd.effectivePricingScheme;

    if (getStoreState().operationForm.actualizeDateMode) {
        if (isRentOrSubrentOperation(getStoreState().operationForm.targetStateCode || getStoreState().operationForm.typeCode))
            itemStartDate = moment(getStoreState().operationForm.operationStartTime).clone();
        else if (isReturnOrSubrentReturnOperation(getStoreState().operationForm.targetStateCode || getStoreState().operationForm.typeCode))
            itemEndDate = moment(getStoreState().operationForm.operationStartTime).clone();
    }
    if (getStoreState().operationForm.autoRecalculateShiftsCountMode) {
        itemShiftCount = getShiftCountFromDates(
            pricingScheme,
            itemStartDate,
            itemEndDate,
            getStoreState().operationForm.shiftLengthInMinutes,
            getStoreState().operationForm.shiftCountRoundingType
        );
    }

    let code = getStoreState().operationForm.typeCode;

    let partOfKit;
    partOfKit = false;

    if (getStoreState().operationForm.projectTemplate) {
        itemStartDate = moment(getStoreState().operationForm.operationStartTime);
        itemEndDate = moment(itemStartDate.valueOf() + 1000);
    }

    let instanceIds: number[] | undefined; // = instanceId ? [instanceId] : undefined;
    // Если есть instanceId, то это после сканирования вызов
    if (instanceId) instanceIds = [instanceId];
    else {
        // Тут на плюс в гриде, для счетного или нет
        if (
            elementToAdd.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED ||
            elementToAdd.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED
        ) {
            if (elementToAdd.anonymousInstanceCount > 0) {
                anonymousInstanceCount = 1;
            } else {
                if (!scanner && elementToAdd.instanceIds && elementToAdd.instanceIds.length) {
                    //instanceIds = [elementToAdd.instanceIds[0]];
                    instanceId = elementToAdd.instanceIds[0];
                    instanceIds = [instanceId];
                } else anonymousInstanceCount = 1;
            }
        }
    }

    let elements: OperationElement[] = [];
    if (item.parent && !entities.find((el) => item.parent && el.id === item.parent.id)) {
        let itemParentStartDate = moment(item.parent.rentTerms.rentPeriodStartDate);
        let itemParentEndDate = moment(item.parent.rentTerms.rentPeriodEndDate);
        let itemParentShiftCount = item.parent.rentTerms.shiftCount;
        let itemParentPricingScheme = item.parent.effectivePricingScheme;

        if (getStoreState().operationForm.actualizeDateMode) {
            if (isRentOperation(getStoreState().operationForm.typeCode))
                itemParentStartDate = moment(getStoreState().operationForm.operationStartTime).clone();
            else if (isReturnOperation(getStoreState().operationForm.typeCode))
                itemParentEndDate = moment(getStoreState().operationForm.operationStartTime).clone();
        }

        if (getStoreState().operationForm.projectTemplate) {
            itemParentStartDate = moment(getStoreState().operationForm.operationStartTime);
            itemParentEndDate = moment(itemParentStartDate.valueOf() + 1000);
        }

        if (getStoreState().operationForm.autoRecalculateShiftsCountMode) {
            itemParentShiftCount = getShiftCountFromDates(
                itemParentPricingScheme,
                itemParentStartDate,
                itemParentEndDate,
                getStoreState().operationForm.shiftLengthInMinutes,
                getStoreState().operationForm.shiftCountRoundingType
            );
        }

        let parentElement: OperationElement = {
            id: item.parent.id,
            numberInActivityFrame: item.parent.numberInActivityFrame,
            businessVersion: item.parent.businessVersion,
            productId: item.parent.productId,
            productShortName: item.parent.nomenclatureShortName,
            productMainImage: item.parent.image,
            stateCode: item.parent.stateCode,
            rentPeriodStartDate: itemParentStartDate.toDate(),
            rentPeriodEndDate: itemParentEndDate.toDate(),
            shiftCount: itemParentShiftCount,
            calendarShiftCount: itemParentShiftCount,
            discount: item.parent.rentTerms.discount,
            instanceCount: item.parent.instanceCount,
            instanceCountOriginal: item.parent.instanceCount,
            finalTotalPrice: item.parent.finalTotalPrice,
            effectivePrice: item.parent.effectivePricingScheme ? item.parent.basePriceAtTheTimeOfCreation : item.parent.effectivePrice,
            pricingSchemeId: item.parent.effectivePricingScheme,
            //stockInstanceCountX: 0,
            availableInstanceCount: 0,

            discountChanged: true,
            rentPeriodChanged: true,
            shiftCountChanged: true,
            disabled: code === OperationTypeCodeEnum.CANCEL,

            //instanceIds: instanceIds,
            //instanceIdsOriginal: instanceIds ? [...instanceIds] : undefined,
            //variantId: item.variantId,
            //variantName: item.variantName,
            //instanceTrackingTypeCode: item.instanceTrackingTypeCode,

            rentPeriodStartDateOriginal: moment(
                getStoreState().operationForm.projectTemplate ? itemParentStartDate : item.parent.rentTerms.rentPeriodStartDate
            ).toDate(),
            rentPeriodEndDateOriginal: moment(
                getStoreState().operationForm.projectTemplate ? itemParentEndDate : item.parent.rentTerms.rentPeriodEndDate
            ).toDate(),

            mainImage: item.parent.image,
            parentId: item.parent.rentElementsKitId,
            kitId: item.parent.kitId,
            warnings: [],
            problems: [],
            //partOfKit,
            anonymousInstanceCount: anonymousInstanceCount, //item.anonymousInstanceCount,
            //anonymousInstanceCountOriginal: data.anonymousInstanceCount

            leftoverInstanceCount: 0,
            leftoverAnonymousInstanceCount: 0,
            keepLeftover: getKeepLeftoverByElementStateCode(item.parent.stateCode),

            projectId: item.parent.projectId,
            templateId: item.parent.templateId,
            renterId: item.parent.renterId,

            //variantIdOriginal: item.variantId,
            externalCode: item.parent.externalCode,

            availableTransitionCodes: item.parent.availableTransitionCodes || undefined,
        };
        elements.push(parentElement);

        nomenclatures.push({
            ...rentElementToNomenclatureBaseObj(item.parent),
            from: itemParentStartDate.valueOf(),
            until: itemParentEndDate.valueOf(),
        });
    }

    let element: OperationElement = {
        id: item.id,
        numberInActivityFrame: item.numberInActivityFrame,
        businessVersion: item.businessVersion,
        productId: item.productId,
        productShortName: item.nomenclatureShortName,
        productMainImage: item.image,
        stateCode: item.stateCode,
        rentPeriodStartDate: moment(itemStartDate).toDate(),
        rentPeriodEndDate: moment(itemEndDate).toDate(),
        shiftCount: itemShiftCount,
        calendarShiftCount: itemShiftCount,
        discount: item.rentTerms.discount,
        instanceCount: instanceCount !== undefined ? instanceCount : item.instanceCount,
        instanceCountOriginal: item.instanceCount, //instanceCount !== undefined ? instanceCount : item.instanceCount,
        finalTotalPrice: item.finalTotalPrice,
        effectivePrice: item.effectivePricingScheme ? item.basePriceAtTheTimeOfCreation : item.effectivePrice,
        //stockInstanceCountX: 0,
        availableInstanceCount: 0,

        discountChanged: true,
        rentPeriodChanged: true,
        shiftCountChanged: true,
        disabled: code === OperationTypeCodeEnum.CANCEL,

        instanceIds: instanceIds,
        instanceIdsOriginal: item.instanceIds?.map((id) => id), //instanceIds ? [...instanceIds] : undefined,
        variantId: item.variantId,
        variantName: item.variantName,
        instanceTrackingTypeCode: item.instanceTrackingTypeCode,

        rentPeriodStartDateOriginal: moment(itemStartDate).toDate(),
        rentPeriodEndDateOriginal: moment(itemEndDate).toDate(),

        mainImage: item.image,
        parentId: item.rentElementsKitId,
        kitId: item.kitId,
        warnings: [],
        problems: [],
        partOfKit,
        anonymousInstanceCount: anonymousInstanceCount, //item.anonymousInstanceCount,
        anonymousInstanceCountOriginal: item.anonymousInstanceCount,

        leftoverInstanceCount: 0,
        leftoverAnonymousInstanceCount: 0,
        keepLeftover: getKeepLeftoverByElementStateCode(item.stateCode),

        projectId: item.projectId,
        templateId: item.templateId,
        renterId: item.renterId,

        variantIdOriginal: item.variantId,
        externalCode: item.externalCode,

        availableTransitionCodes: item.availableTransitionCodes || undefined,

        pricingSchemeId: item.effectivePricingScheme,
    };
    elements.push(element);

    nomenclatures.push({ ...rentElementToNomenclatureBaseObj(item), from: itemStartDate.valueOf(), until: itemEndDate.valueOf() });
    if (instanceId){
        nomenclatures.push({
            id: instanceId,
            type: NomenclatureEntityTypeCodeEnum.INSTANCE,
            from: itemStartDate.valueOf(),
            until: itemEndDate.valueOf(),
        });
        __data.loadingInstanceIds.push(instanceId);
    }

    try {
        // let records = await sss(elementToAdd, instanceId);
        // if(item.parent){
        //     const r = await loadKitTimetableIfNotFound({
        //         kitId: item.parent.kitId,
        //         startDate: item.parent.rentTerms.rentPeriodStartDate,
        //         endDate: item.parent.rentTerms.rentPeriodEndDate
        //     });
        //     if(r) records.push(...r);
        // }
        // getStore().dispatch(addNewOperationElementFromEquipment(elements, records));

        //
        getStore().dispatch(addNewOperationElementFromEquipment(elements, []));
        const data = await loadNomenclaturesOnIntervalsIfNeed(nomenclatures);
        getStore().dispatch(updateAnyTimetables(data.timetables, data.elementsDelayedReturnDates));
        getStore().dispatch(addNewOperationElementFromEquipment1(elements, []));
        if (succesCb) succesCb();
    } catch (err) {
        showNotification('error', 'Ошибка');
    } finally {
        __data.loadingInstanceIds = __data.loadingInstanceIds.filter((id) => id !== instanceId);
    }
};

export const addKitToElementsFromScan = async (
    elementToAdd: RentElementsGridItem,
    instanceId: number | undefined,
    succesCb?: () => void,
    scanner?: boolean
) => {
    let anonymousInstanceCount = 0;
    let code = getStoreState().operationForm.typeCode;
    let elements: OperationElement[] = [];
    const nomenclatures: NomenclatureOnInterval[] = [];

    ///
    // Создаем элемент набора.
    if (elementToAdd) {
        //nomKit.push(elementToAdd);
        nomenclatures.push({
            ...rentElementToNomenclatureBaseObj(elementToAdd),
            from: moment(elementToAdd.rentTerms.rentPeriodStartDate).valueOf(),
            until: moment(elementToAdd.rentTerms.rentPeriodEndDate).valueOf(),
        });

        let itemParentStartDate = moment(elementToAdd.rentTerms.rentPeriodStartDate);
        let itemParentEndDate = moment(elementToAdd.rentTerms.rentPeriodEndDate);
        let itemParentShiftCount = elementToAdd.rentTerms.shiftCount;
        let itemParentPricingScheme = elementToAdd.effectivePricingScheme;

        if (getStoreState().operationForm.actualizeDateMode) {
            if (isRentOrSubrentOperation(getStoreState().operationForm.targetStateCode || getStoreState().operationForm.typeCode))
                itemParentStartDate = moment(getStoreState().operationForm.operationStartTime).clone();
            else if (
                isReturnOrSubrentReturnOperation(getStoreState().operationForm.targetStateCode || getStoreState().operationForm.typeCode)
            )
                itemParentEndDate = moment(getStoreState().operationForm.operationStartTime).clone();
        }
        if (getStoreState().operationForm.autoRecalculateShiftsCountMode) {
            itemParentShiftCount = getShiftCountFromDates(
                itemParentPricingScheme,
                itemParentStartDate,
                itemParentEndDate,
                getStoreState().operationForm.shiftLengthInMinutes,
                getStoreState().operationForm.shiftCountRoundingType
            );
        }

        let parentElement: OperationElement = {
            id: elementToAdd.id,
            numberInActivityFrame: elementToAdd.numberInActivityFrame,
            businessVersion: elementToAdd.businessVersion,
            productId: elementToAdd.productId,
            productShortName: elementToAdd.nomenclatureShortName,
            productMainImage: elementToAdd.image,
            stateCode: elementToAdd.stateCode,
            rentPeriodStartDate: itemParentStartDate.toDate(),
            rentPeriodEndDate: itemParentEndDate.toDate(),
            shiftCount: itemParentShiftCount,
            calendarShiftCount: itemParentShiftCount,
            discount: elementToAdd.rentTerms.discount,
            instanceCount: elementToAdd.instanceCount,
            instanceCountOriginal: elementToAdd.instanceCount,
            finalTotalPrice: elementToAdd.finalTotalPrice,
            effectivePrice: elementToAdd.effectivePrice,
            //stockInstanceCountX: 0,
            availableInstanceCount: 0,

            discountChanged: true,
            rentPeriodChanged: true,
            shiftCountChanged: true,
            disabled: code === OperationTypeCodeEnum.CANCEL,

            //instanceIds: instanceIds,
            //instanceIdsOriginal: instanceIds ? [...instanceIds] : undefined,
            //variantId: item.variantId,
            //variantName: item.variantName,
            //instanceTrackingTypeCode: item.instanceTrackingTypeCode,

            rentPeriodStartDateOriginal: moment(elementToAdd.rentTerms.rentPeriodStartDate).toDate(),
            rentPeriodEndDateOriginal: moment(elementToAdd.rentTerms.rentPeriodEndDate).toDate(),

            mainImage: elementToAdd.image,
            parentId: elementToAdd.rentElementsKitId,
            kitId: elementToAdd.kitId,
            warnings: [],
            problems: [],
            //partOfKit,
            anonymousInstanceCount: anonymousInstanceCount, //item.anonymousInstanceCount,
            //anonymousInstanceCountOriginal: data.anonymousInstanceCount

            leftoverInstanceCount: 0,
            leftoverAnonymousInstanceCount: 0,
            keepLeftover: getKeepLeftoverByElementStateCode(elementToAdd.stateCode),

            projectId: elementToAdd.projectId,
            templateId: elementToAdd.templateId,
            renterId: elementToAdd.renterId,

            //variantIdOriginal: item.variantId,
            externalCode: elementToAdd.externalCode,

            availableTransitionCodes: elementToAdd.availableTransitionCodes || undefined,
        };
        elements.push(parentElement);
    }

    // Создаем его детей
    if (elementToAdd.subRows) {
        elementToAdd.subRows.forEach((item) => {
            //nom.push(item);
            nomenclatures.push({
                ...rentElementToNomenclatureBaseObj(item),
                from: moment(item.rentTerms.rentPeriodStartDate).valueOf(),
                until: moment(item.rentTerms.rentPeriodEndDate).valueOf(),
            });

            let newMode = scanner ? true : false;
            let instanceIds = newMode ? [] : item.instanceIds;
            let anonymousInstanceCount = newMode
                ? item.anonymousInstanceCount + (item.instanceIds || []).length
                : item.anonymousInstanceCount;

            let itemStartDate = moment(item.rentTerms.rentPeriodStartDate);
            let itemEndDate = moment(item.rentTerms.rentPeriodEndDate);
            let itemShiftCount = item.rentTerms.shiftCount;
            let itemPricingScheme = item.effectivePricingScheme;

            if (getStoreState().operationForm.actualizeDateMode) {
                if (isRentOrSubrentOperation(getStoreState().operationForm.targetStateCode || getStoreState().operationForm.typeCode))
                    itemStartDate = moment(getStoreState().operationForm.operationStartTime).clone();
                else if (isReturnOrSubrentReturnOperation(getStoreState().operationForm.targetStateCode || getStoreState().operationForm.typeCode))
                    itemEndDate = moment(getStoreState().operationForm.operationStartTime).clone();
            }
            if (getStoreState().operationForm.autoRecalculateShiftsCountMode) {
                itemShiftCount = getShiftCountFromDates(
                    itemPricingScheme,
                    itemStartDate,
                    itemEndDate,
                    getStoreState().operationForm.shiftLengthInMinutes,
                    getStoreState().operationForm.shiftCountRoundingType
                );
            }

            let anonymousInstanceCountOriginal = item.anonymousInstanceCount;

            if (
                item.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTBULK ||
                item.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.BULK
            ) {
                anonymousInstanceCount = 0;
                anonymousInstanceCountOriginal = 0;
            }

            //if(instanceIds) instancesToLoad.push(...instanceIds);

            instanceIds?.forEach((id) => {
                nomenclatures.push({
                    id,
                    type: NomenclatureEntityTypeCodeEnum.INSTANCE,
                    from: moment(item.rentTerms.rentPeriodStartDate).valueOf(),
                    until: moment(item.rentTerms.rentPeriodEndDate).valueOf(),
                });
            });

            let element: OperationElement = {
                id: item.id,
                numberInActivityFrame: item.numberInActivityFrame,
                businessVersion: item.businessVersion,
                productId: item.productId,
                productShortName: item.nomenclatureShortName,
                productMainImage: item.image,
                stateCode: item.stateCode,
                rentPeriodStartDate: moment(itemStartDate).toDate(),
                rentPeriodEndDate: moment(itemEndDate).toDate(),
                shiftCount: itemShiftCount,
                calendarShiftCount: itemShiftCount,
                discount: item.rentTerms.discount,
                instanceCount: item.instanceCount,
                instanceCountOriginal: item.instanceCount, //instanceCount !== undefined ? instanceCount : item.instanceCount,
                finalTotalPrice: item.finalTotalPrice,
                effectivePrice: item.effectivePrice,
                //stockInstanceCountX: 0,
                availableInstanceCount: 0,

                discountChanged: true,
                rentPeriodChanged: true,
                shiftCountChanged: true,
                disabled: code === OperationTypeCodeEnum.CANCEL,

                instanceIds: instanceIds,
                instanceIdsOriginal: item.instanceIds?.map((id) => id), //instanceIds ? [...instanceIds] : undefined,
                variantId: item.variantId,
                variantName: item.variantName,
                instanceTrackingTypeCode: item.instanceTrackingTypeCode,

                rentPeriodStartDateOriginal: moment(itemStartDate).toDate(),
                rentPeriodEndDateOriginal: moment(itemEndDate).toDate(),

                mainImage: item.image,
                parentId: item.rentElementsKitId,
                kitId: item.kitId,
                warnings: [],
                problems: [],
                partOfKit: false, // TODO
                anonymousInstanceCount: anonymousInstanceCount,
                anonymousInstanceCountOriginal: anonymousInstanceCountOriginal,

                leftoverInstanceCount: 0,
                leftoverAnonymousInstanceCount: 0,
                keepLeftover: getKeepLeftoverByElementStateCode(item.stateCode),

                projectId: item.projectId,
                templateId: item.templateId,
                renterId: item.renterId,

                variantIdOriginal: item.variantId,
                externalCode: item.externalCode,

                availableTransitionCodes: item.availableTransitionCodes || undefined,
                pricingSchemeId: item.effectivePricingScheme
            };
            elements.push(element);
        });
    }

    try {
        // let records = await OperationFormUtils.loadNomenclaturesById(nom);
        // let recordsKit = await OperationFormUtils.loadNomenclaturesById(nomKit);
        //
        // const state = getStore().getState();
        // let rentPeriodStartDate = state.operationForm.rentPeriodStartDate || new Date();
        // let rentPeriodEndDate = state.operationForm.rentPeriodEndDate || new Date();
        //
        // let recordsInstances = await loadInstancesTimetables({instanceIds: instancesToLoad, startDate: rentPeriodStartDate, endDate: rentPeriodEndDate});
        //
        // getStore().dispatch(addNewOperationElementFromEquipment(elements, [...records || [], ...recordsKit || [], ...recordsInstances || []]));

        const data = await loadNomenclaturesOnIntervalsIfNeed(nomenclatures);
        getStore().dispatch(updateAnyTimetables(data.timetables, data.elementsDelayedReturnDates));
        getStore().dispatch(addNewOperationElementFromEquipment(elements, []));
        getStore().dispatch(addNewOperationElementFromEquipment1(elements, []));
        if (succesCb) succesCb();
    } catch (err) {
        showNotification('error', 'Ошибка');
    }
};

const __data = {
    loadingInstanceIds: [] as number[]
};

// Добавляет +1 к существующему
export const addElementToElementsFromScan1 = async (
    elementToAdd: RentElementsGridItem,
    instanceId?: number | undefined,
    succesCb?: () => void,
    insteadOfAnonymous?: boolean,
    scanner?: boolean
) => {
    if (elementToAdd.kitId) {
        addKitToElementsFromScan(elementToAdd, instanceId, succesCb, scanner);
        return;
    }

    let elements = getStoreState().operationForm.elements.entities;
    let addedEl = elements.find((el) => el.id === elementToAdd.id);
    let _instanceId: number | undefined = undefined;
    if (instanceId) _instanceId = instanceId;
    else if (elementToAdd.instanceIds && addedEl) {
        const availableInstances = elementToAdd.instanceIds.filter((id)=>!__data.loadingInstanceIds.includes(id));
        _instanceId = availableInstances.find((id) => !(addedEl?.instanceIds || [])?.includes(id));
        if (scanner) _instanceId = undefined;
    }
    if (addedEl && addedEl.anonymousInstanceCount < elementToAdd.anonymousInstanceCount && !instanceId) _instanceId = undefined;
    try {
        const nomenclatures: NomenclatureOnInterval[] = [];
        if (addedEl) {
            const from = getElementStartDate(addedEl);
            const until = getElementEndDate(addedEl);
            if (_instanceId){
                const n = { id: _instanceId, type: NomenclatureEntityTypeCodeEnum.INSTANCE, from, until };
                nomenclatures.push(n);
                __data.loadingInstanceIds.push(_instanceId);
            }
            //nomenclatures.push({ ...rentElementToNomenclatureBaseObj(addedEl), from, until });
        }

        const data = await loadNomenclaturesOnIntervalsIfNeed(nomenclatures);
        getStore().dispatch(updateAnyTimetables(data.timetables, data.elementsDelayedReturnDates));
        if (addedEl) {
            getStore().dispatch(addInstanceToExistedOperationElement(addedEl, _instanceId, insteadOfAnonymous));
        }
        if (succesCb) succesCb();
    } catch (err) {
        console.log(err);
        showNotification('error', 'Ошибка');
    } finally {
        __data.loadingInstanceIds = __data.loadingInstanceIds.filter((id) => id !== _instanceId);
    }
};

/*
export const loadInstanceTimetableIfNotFound = async (params: {
    instanceId: number;
    productId: number;
    variantId: number | undefined;
    startDate: Date;
    endDate: Date;
}) => {
    const { instanceId, productId, variantId, startDate, endDate } = params;

    let rentPeriodStartDate = moment(startDate).toDate();
    let rentPeriodEndDate = moment(endDate).toDate();

    let instanceTT = findTimeTable(getStoreState().operationForm.timeTables, {
        instanceId: instanceId,
        productId: productId,
        variantId: variantId,
    });

    if (!instanceTT) {
        const request_instanceTT = await serverApi.listInstancesOnInterval(
            getStoreState().system.businessAccountId,
            rentPeriodStartDate.getTime() - (getStoreState().operationForm.projectTemplate ? 0 : 365 * 1 * 24 * 60 * 60 * 1000),
            rentPeriodEndDate.getTime() + (getStoreState().operationForm.projectTemplate ? 0 : 365 * 2 * 24 * 60 * 60 * 1000),
            {
                possibleDelayedRentElementIds: undefined,
                listFilters: [`id;IN;${instanceId}`],
            },
            1,
            0,
            undefined,
            undefined,
            undefined,
            getStoreState().operationForm.projectTemplate
                ? [TimetableTypeCodeEnum.STOCK]
                : [
                      TimetableTypeCodeEnum.ORDERAVAILABLE,
                      TimetableTypeCodeEnum.AVAILABLE,
                      TimetableTypeCodeEnum.STOCK,
                      TimetableTypeCodeEnum.ORDER,
                  ]
        );
        if (request_instanceTT.data.records) {
            return request_instanceTT.data.records[0];
        }
    }
};
*/


/*
export const loadInstancesTimetables = async (params: {instanceIds:number[]; startDate:Date; endDate:Date}) => {
    const {instanceIds, startDate, endDate} = params;

    if(instanceIds.length > 0){
        let rentPeriodStartDate = moment(startDate).toDate();
        let rentPeriodEndDate = moment(endDate).toDate();

        const instancesToLoad:number[] = [];
        instanceIds.forEach(id=>{
            if(!instancesToLoad.includes(id)) instancesToLoad.push(id);
        })

        const request_instanceTT = await serverApi.listInstancesOnInterval(
            getStoreState().system.businessAccountId,
            rentPeriodStartDate.getTime() - (getStoreState().operationForm.projectTemplate ? 0 : (365 * 1 * 24 * 60 * 60 * 1000)),
            rentPeriodEndDate.getTime() + (getStoreState().operationForm.projectTemplate ? 0 : (365 * 2 * 24 * 60 * 60 * 1000)),
            {
                possibleDelayedRentElementIds: undefined,
                listFilters: [`id;IN;${instancesToLoad.join(';')}`]
            },
            undefined,
            0,
            undefined,
            undefined,
            undefined,
            getStoreState().operationForm.projectTemplate ? [TimetableTypeCodeEnum.STOCK] : [TimetableTypeCodeEnum.ORDERAVAILABLE, TimetableTypeCodeEnum.AVAILABLE, TimetableTypeCodeEnum.STOCK, TimetableTypeCodeEnum.ORDER]
        );
        if(request_instanceTT.data.records){
            return request_instanceTT.data.records;
        }
    }else{
        return [];
    }
};

const loadKitTimetableIfNotFound = async(params: {kitId:number|undefined; startDate:Date; endDate:Date}) => {
    const {kitId, startDate, endDate} = params;
    let rentPeriodStartDate = moment(startDate).toDate();
    let rentPeriodEndDate = moment(endDate).toDate();

    if(getStoreState().operationForm.projectTemplate){
        rentPeriodStartDate = moment(getStoreState().operationForm.operationStartTime).toDate();
        rentPeriodEndDate = moment(rentPeriodStartDate.valueOf() + 1000).toDate();
    }

    let productTT = findTimeTable(getStoreState().operationForm.timeTables, {
        kitId
    });
    let records:(NomenclatureRecord | InstanceRecord)[]|undefined = [];
    let productsResponseTT:AxiosResponse<NomenclatureRecordList>|undefined;
    if(!productTT){
        let filters = [`kitId;IN;${kitId}`];
        //filters.push('identifiesRentElementAvailability;EQ;true');
        productsResponseTT = await serverApi.listNomenclatureOnInterval(
            getStoreState().system.businessAccountId,
            rentPeriodStartDate.getTime() - (getStoreState().operationForm.projectTemplate ? 0 : (365 * 1 * 24 * 60 * 60 * 1000)),
            rentPeriodEndDate.getTime() + (getStoreState().operationForm.projectTemplate ? 0 : (365 * 2 * 24 * 60 * 60 * 1000)),
            {
                possibleDelayedRentElementIds: undefined,
                listFilters: filters
            },
            1,
            undefined,
            undefined,
            undefined,
            undefined,
            getStoreState().operationForm.projectTemplate ? [TimetableTypeCodeEnum.STOCK] : [TimetableTypeCodeEnum.ORDERAVAILABLE, TimetableTypeCodeEnum.AVAILABLE, TimetableTypeCodeEnum.STOCK, TimetableTypeCodeEnum.ORDER]
        );
        if(productsResponseTT.data.records) records.push(...productsResponseTT.data.records);
    }
    return records;
};
*/

// const sss = async (elementToAdd: RentElementsGridItem, instanceId?: number) => {
//     let rentPeriodStartDate = moment(elementToAdd.rentTerms.rentPeriodStartDate).toDate();
//     let rentPeriodEndDate = moment(elementToAdd.rentTerms.rentPeriodEndDate).toDate();
//
//     if (getStoreState().operationForm.projectTemplate) {
//         rentPeriodStartDate = moment(getStoreState().operationForm.operationStartTime).toDate();
//         rentPeriodEndDate = moment(rentPeriodStartDate.valueOf() + 1000).toDate();
//     }
//
//     let instanceTT = findTimeTable(getStoreState().operationForm.timeTables, {
//         instanceId: instanceId,
//         productId: elementToAdd.productId,
//         variantId: elementToAdd.variantId,
//     });
//
//     let productTT = findTimeTable(getStoreState().operationForm.timeTables, {
//         productId: elementToAdd.productId,
//         variantId: elementToAdd.variantId,
//     });
//
//     let request_instanceTT: AxiosResponse<InstanceRecordList> | undefined;
//     let records: (NomenclatureRecord | InstanceRecord)[] | undefined = [];
//
//     if (!instanceTT /* || instanceTT.timetableVersion !== res.data.instance?.timetableVersion*/) {
//         if (instanceId) {
//             request_instanceTT = await serverApi.listInstancesOnInterval(
//                 getStoreState().system.businessAccountId,
//                 rentPeriodStartDate.getTime() - (getStoreState().operationForm.projectTemplate ? 0 : 365 * 1 * 24 * 60 * 60 * 1000),
//                 rentPeriodEndDate.getTime() + (getStoreState().operationForm.projectTemplate ? 0 : 365 * 2 * 24 * 60 * 60 * 1000),
//                 {
//                     possibleDelayedRentElementIds: undefined,
//                     listFilters: [`id;IN;${instanceId}`],
//                 },
//                 1,
//                 0,
//                 undefined,
//                 undefined,
//                 undefined,
//                 getStoreState().operationForm.projectTemplate
//                     ? [TimetableTypeCodeEnum.STOCK]
//                     : [
//                           TimetableTypeCodeEnum.ORDERAVAILABLE,
//                           TimetableTypeCodeEnum.AVAILABLE,
//                           TimetableTypeCodeEnum.STOCK,
//                           TimetableTypeCodeEnum.ORDER,
//                       ]
//             );
//             if (request_instanceTT.data.records) records.push(...request_instanceTT.data.records);
//         }
//     }
//
//     let productsResponseTT: AxiosResponse<NomenclatureRecordList> | undefined;
//     if (!productTT /* || productTT.timetableVersion !== res.data.product?.timetableVersion*/) {
//         let filters = [`productId;IN;${elementToAdd.productId}`];
//         if (elementToAdd.variantId) filters.push(`variantId;IN;${elementToAdd.variantId}`);
//         //filters.push('identifiesRentElementAvailability;EQ;true');
//
//         productsResponseTT = await serverApi.listNomenclatureOnInterval(
//             getStoreState().system.businessAccountId,
//             rentPeriodStartDate.getTime() - (getStoreState().operationForm.projectTemplate ? 0 : 365 * 1 * 24 * 60 * 60 * 1000),
//             rentPeriodEndDate.getTime() + (getStoreState().operationForm.projectTemplate ? 0 : 365 * 2 * 24 * 60 * 60 * 1000),
//             {
//                 possibleDelayedRentElementIds: undefined,
//                 listFilters: filters,
//             },
//             1,
//             undefined,
//             undefined,
//             undefined,
//             undefined,
//             getStoreState().operationForm.projectTemplate
//                 ? [TimetableTypeCodeEnum.STOCK]
//                 : [
//                       TimetableTypeCodeEnum.ORDERAVAILABLE,
//                       TimetableTypeCodeEnum.AVAILABLE,
//                       TimetableTypeCodeEnum.STOCK,
//                       TimetableTypeCodeEnum.ORDER,
//                   ]
//         );
//         if (productsResponseTT.data.records) records.push(...productsResponseTT.data.records);
//     }
//
//     return records;
//
//     //console.log('EquipmentAddedBlock', productTT, instanceTT, res.data);
//
//     // let addedElement = elements.find((item: OperationElement) => {
//     //     return (
//     //         !isDefined(item.kitId) &&
//     //         item.id < 0 &&
//     //         //!item.parentId &&
//     //         item.productId === instanceEntity.productId &&
//     //         item.variantId === instanceEntity.variantId &&
//     //         //item.discount === instanceEntity.discount &&
//     //         //item.shiftCount === instanceEntity.shiftCount &&
//     //         item.rentPeriodStartDate.toString() === rentPeriodStartDate.toString() &&
//     //         item.rentPeriodEndDate.toString() === rentPeriodEndDate.toString() &&
//     //         item.instanceIds && item.instanceIds.includes(instanceEntity.id)
//     //     );
//     // });
//     //
//     // if(addedElement){
//     //     showNotification('error', 'Экземпляр уже добавлен');
//     // }else{
//     //     await this.props.addInstanceFromScanner(res.data.barcodeEntityObj.id, res.data.barcodeEntityObj.entityInfoRead as InstanceInfoRead, records, productsResponseTT.data.records[0]);
//     //     let key = 'uniq_' + this.instancesScannedKeyIndex;
//     //     this.instancesScanned++;
//     //     showNotification('success', this.instancesScanned + ' ' + localizeIntl(this.props.intl, LocalizationEnum.ASPECT__PLURAL__INSTANCE_ADDED, undefined, {value: this.instancesScanned}), undefined, key, ()=>{
//     //         this.instancesScannedKeyIndex++;
//     //         this.instancesScanned = 0;
//     //     }, 10);
//     // }
// };

/**
 * Добавление набора из вкладки "Выбор существующих"
 */
export const prepareDataForAddingKitToElements = async (elementToAdd: RentElementsGridItem, scanner?: boolean) => {
    let anonymousInstanceCount = 0;
    let code = getStoreState().operationForm.typeCode;
    let elements: OperationElement[] = [];
    let nom: any[] = [];
    let nomKit: any[] = [];
    const nomenclatures: NomenclatureOnInterval[] = [];

    // Создаем элемент набора.
    if (elementToAdd) {
        nomKit.push(elementToAdd);

        let itemParentStartDate = moment(elementToAdd.rentTerms.rentPeriodStartDate);
        let itemParentEndDate = moment(elementToAdd.rentTerms.rentPeriodEndDate);
        let itemParentShiftCount = elementToAdd.rentTerms.shiftCount;
        let itemParentPricingScheme = elementToAdd.effectivePricingScheme;

        if (getStoreState().operationForm.actualizeDateMode) {
            if (isRentOrSubrentOperation(getStoreState().operationForm.targetStateCode || getStoreState().operationForm.typeCode))
                itemParentStartDate = moment(getStoreState().operationForm.operationStartTime).clone();
            else if (
                isReturnOrSubrentReturnOperation(getStoreState().operationForm.targetStateCode || getStoreState().operationForm.typeCode)
            )
                itemParentEndDate = moment(getStoreState().operationForm.operationStartTime).clone();
        }
        if (getStoreState().operationForm.autoRecalculateShiftsCountMode) {
            itemParentShiftCount = getShiftCountFromDates(
                itemParentPricingScheme,
                itemParentStartDate,
                itemParentEndDate,
                getStoreState().operationForm.shiftLengthInMinutes,
                getStoreState().operationForm.shiftCountRoundingType
            );
        }

        let parentElement: OperationElement = {
            id: elementToAdd.id,
            numberInActivityFrame: elementToAdd.numberInActivityFrame,
            businessVersion: elementToAdd.businessVersion,
            productId: elementToAdd.productId,
            productShortName: elementToAdd.nomenclatureShortName,
            productMainImage: elementToAdd.image,
            stateCode: elementToAdd.stateCode,
            rentPeriodStartDate: itemParentStartDate.toDate(),
            rentPeriodEndDate: itemParentEndDate.toDate(),
            shiftCount: itemParentShiftCount,
            calendarShiftCount: itemParentShiftCount,
            discount: elementToAdd.rentTerms.discount,
            instanceCount: elementToAdd.instanceCount,
            instanceCountOriginal: elementToAdd.instanceCount,
            finalTotalPrice: elementToAdd.finalTotalPrice,
            effectivePrice: elementToAdd.effectivePrice,
            //stockInstanceCountX: 0,
            availableInstanceCount: 0,

            discountChanged: true,
            rentPeriodChanged: true,
            shiftCountChanged: true,
            disabled: code === OperationTypeCodeEnum.CANCEL,

            //instanceIds: instanceIds,
            //instanceIdsOriginal: instanceIds ? [...instanceIds] : undefined,
            //variantId: item.variantId,
            //variantName: item.variantName,
            //instanceTrackingTypeCode: item.instanceTrackingTypeCode,

            rentPeriodStartDateOriginal: moment(elementToAdd.rentTerms.rentPeriodStartDate).toDate(),
            rentPeriodEndDateOriginal: moment(elementToAdd.rentTerms.rentPeriodEndDate).toDate(),

            mainImage: elementToAdd.image,
            parentId: elementToAdd.rentElementsKitId,
            kitId: elementToAdd.kitId,
            warnings: [],
            problems: [],
            //partOfKit,
            anonymousInstanceCount: anonymousInstanceCount, //item.anonymousInstanceCount,
            //anonymousInstanceCountOriginal: data.anonymousInstanceCount

            leftoverInstanceCount: 0,
            leftoverAnonymousInstanceCount: 0,
            keepLeftover: getKeepLeftoverByElementStateCode(elementToAdd.stateCode),

            projectId: elementToAdd.projectId,
            templateId: elementToAdd.templateId,
            renterId: elementToAdd.renterId,

            //variantIdOriginal: item.variantId,
            externalCode: elementToAdd.externalCode,

            availableTransitionCodes: elementToAdd.availableTransitionCodes || undefined,
        };
        elements.push(parentElement);

        if (elementToAdd.kitId) {
            const from = Math.min(moment(itemParentStartDate).valueOf(), moment(elementToAdd.rentTerms.rentPeriodStartDate).valueOf());
            const until = Math.max(moment(itemParentEndDate).valueOf(), moment(elementToAdd.rentTerms.rentPeriodEndDate).valueOf());
            nomenclatures.push({ id: elementToAdd.kitId, type: NomenclatureEntityTypeCodeEnum.KIT, from, until });
        }
    }

    // Создаем его детей

    if (elementToAdd.subRows) {
        elementToAdd.subRows.forEach((item) => {
            nom.push(item);

            let newMode = scanner ? true : false;
            let instanceIds = newMode ? [] : item.instanceIds;
            let anonymousInstanceCount = newMode
                ? item.anonymousInstanceCount + (item.instanceIds || []).length
                : item.anonymousInstanceCount;

            let itemStartDate = moment(item.rentTerms.rentPeriodStartDate);
            let itemEndDate = moment(item.rentTerms.rentPeriodEndDate);
            let itemShiftCount = item.rentTerms.shiftCount;
            let itemPricingScheme = item.effectivePricingScheme;

            if (getStoreState().operationForm.actualizeDateMode) {
                if (isRentOperation(getStoreState().operationForm.typeCode))
                    itemStartDate = moment(getStoreState().operationForm.operationStartTime).clone();
                else if (isReturnOperation(getStoreState().operationForm.typeCode))
                    itemEndDate = moment(getStoreState().operationForm.operationStartTime).clone();
            }
            if (getStoreState().operationForm.autoRecalculateShiftsCountMode) {
                itemShiftCount = getShiftCountFromDates(
                    itemPricingScheme,
                    itemStartDate,
                    itemEndDate,
                    getStoreState().operationForm.shiftLengthInMinutes,
                    getStoreState().operationForm.shiftCountRoundingType
                );
            }

            let anonymousInstanceCountOriginal = item.anonymousInstanceCount;

            if (
                item.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTBULK ||
                item.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.BULK
            ) {
                anonymousInstanceCount = 0;
                anonymousInstanceCountOriginal = 0;
            }

            let element: OperationElement = {
                id: item.id,
                numberInActivityFrame: item.numberInActivityFrame,
                businessVersion: item.businessVersion,
                productId: item.productId,
                productShortName: item.nomenclatureShortName,
                productMainImage: item.image,
                stateCode: item.stateCode,
                rentPeriodStartDate: moment(itemStartDate).toDate(),
                rentPeriodEndDate: moment(itemEndDate).toDate(),
                shiftCount: itemShiftCount,
                calendarShiftCount: itemShiftCount,
                discount: item.rentTerms.discount,
                instanceCount: item.instanceCount,
                instanceCountOriginal: item.instanceCount, //instanceCount !== undefined ? instanceCount : item.instanceCount,
                finalTotalPrice: item.finalTotalPrice,
                effectivePrice: item.effectivePrice,
                //stockInstanceCountX: 0,
                availableInstanceCount: 0,

                discountChanged: true,
                rentPeriodChanged: true,
                shiftCountChanged: true,
                disabled: code === OperationTypeCodeEnum.CANCEL,

                instanceIds: instanceIds,
                instanceIdsOriginal: item.instanceIds?.map((id) => id), //instanceIds ? [...instanceIds] : undefined,
                variantId: item.variantId,
                variantName: item.variantName,
                instanceTrackingTypeCode: item.instanceTrackingTypeCode,

                rentPeriodStartDateOriginal: moment(itemStartDate).toDate(),
                rentPeriodEndDateOriginal: moment(itemEndDate).toDate(),

                mainImage: item.image,
                parentId: item.rentElementsKitId,
                kitId: item.kitId,
                warnings: [],
                problems: [],
                partOfKit: false, // TODO
                anonymousInstanceCount: anonymousInstanceCount,
                anonymousInstanceCountOriginal: anonymousInstanceCountOriginal,

                leftoverInstanceCount: 0,
                leftoverAnonymousInstanceCount: 0,
                keepLeftover: getKeepLeftoverByElementStateCode(item.stateCode),

                projectId: item.projectId,
                templateId: item.templateId,
                renterId: item.renterId,

                variantIdOriginal: item.variantId,
                externalCode: item.externalCode,

                availableTransitionCodes: item.availableTransitionCodes || undefined,
            };
            elements.push(element);

            const id = item.variantId || item.productId;
            const type = item.variantId ? NomenclatureEntityTypeCodeEnum.VARIANT : NomenclatureEntityTypeCodeEnum.PRODUCT;
            const from = Math.min(moment(itemStartDate).valueOf());
            const until = Math.max(moment(itemEndDate).valueOf());
            nomenclatures.push({ id, type, from, until });

            if (item.instanceIds) {
                item.instanceIds.forEach((id) => {
                    nomenclatures.push({ id, type: NomenclatureEntityTypeCodeEnum.INSTANCE, from, until });
                });
            }

            if (instanceIds) {
                instanceIds.forEach((id) => {
                    nomenclatures.push({ id, type: NomenclatureEntityTypeCodeEnum.INSTANCE, from, until });
                });
            }
        });
    }

    try {
        //let records = await OperationFormUtils.loadNomenclaturesById(nom);
        //let recordsKit = await OperationFormUtils.loadNomenclaturesById(nomKit);

        const data = await loadNomenclaturesOnIntervalsIfNeed(nomenclatures);
        getStore().dispatch(updateAnyTimetables(data.timetables, data.elementsDelayedReturnDates));

        return {
            elements: elements,
            //records: []//[...records || [], ...recordsKit || []]
        };
        //getStore().dispatch(addNewOperationElementFromEquipment(elements, [...records || [], ...recordsKit || []]));
        //if(succesCb) succesCb();
    } catch (err) {
        throw err;
        //showNotification('error', 'Ошибка');
    }
};

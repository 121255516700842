import moment from 'moment';
import { isDefined } from './utils';
import { OperationElement, TimeTable } from '../../modules/main/operationForm/reducers/operationForm.reducer';
import { InstanceTrackingTypeCodeEnum, RentElementInfoWrite, TimetableList, TimetableTypeCodeEnum } from '../../server/api';
import { formatDate } from './formatDate';
import { LOG_NOMENCLATURE_STACKMAPS } from '../../config/config';
import {RentElementsGridItem} from "../../types";

export class ProductUtils {
    static printElementIntervals = (data: [number, number, number][]) => {
        let arr: { count: number; from: string; until: string }[] = [];
        data.forEach((item) =>
            arr.push({
                count: item[0],
                from: formatDate(moment(item[1]), 'DD.MM.YYYY HH:mm:ss'),
                until: formatDate(moment(item[2]), 'DD.MM.YYYY HH:mm:ss'),
            })
        );
        return arr;
    };

    static printProductStackMap = (data: [number, number][]) => {
        let arr: { count: number; date: string }[] = [];
        data.forEach((item) =>
            arr.push({
                count: item[0],
                date: formatDate(moment(item[1]), 'DD.MM.YYYY HH:mm:ss'),
            })
        );
        return arr;
    };

    /**
     *
     * @param mapString
     */
    static parseProductStackMap = (mapString: string): [number, number][] => {
        let arr = atob(mapString)
            .replace(/;$/, '')
            .split(';')
            .map((value) => value.split(',').map((value) => +value));
        if (arr[0][1] === undefined && arr[1][0] !== undefined) arr[0].unshift(arr[1][0]);
        arr.sort((a, b) => (a[1] > b[1] ? 1 : a[1] < b[1] ? -1 : 0));
        if (arr && arr[0] && arr[0][0] > 0) {
            // въебем ноль в начало этого дня
            let d = moment(arr[0][1]);
            let d0 = d.clone().millisecond(0).second(0).minute(0).hour(0);
            if (d.valueOf() > d0.valueOf()) {
                arr.unshift([0, d0.valueOf()]);
            }
        }
        return arr as [number, number][];
    };

    /**
     *
     * @param map
     */
    static productStackMapToString = (map: [number, number][]): string => {
        let res = map.map((item, index) => (index === 0 ? item[1] : item.join(','))).join(';') + ';';
        return btoa(res);
    };

    /**
     *
     * @param intervals
     * @param from
     * @param until
     */
    static getMinimumValueInInterval = (intervals: [number, number][], from: number, until: number): number => {
        if (intervals.length === 0) return 0;
        let min = Number.MAX_SAFE_INTEGER;
        let fromIndex = intervals.findIndex((item) => item[1] > from);
        if (fromIndex === -1) fromIndex = 0;

        let toIndex = intervals.findIndex((item) => item[1] >= until);
        if (toIndex === -1) toIndex = intervals.length - 1;

        for (let i = fromIndex; i <= toIndex; ++i) {
            min = Math.min(min, intervals[i][0]);
        }
        return min;
    };

    /**
     *
     * @param intervals
     * @param from
     * @param until
     */
    static getMaximumValueInInterval = (intervals: [number, number][], from: number, until: number): number => {
        if (intervals.length === 0) return 0;
        let max = Number.MIN_SAFE_INTEGER;
        let fromIndex = intervals.findIndex((item) => item[1] > from);
        if (fromIndex === -1) fromIndex = 0;

        let toIndex = intervals.findIndex((item) => item[1] >= until);
        if (toIndex === -1) toIndex = intervals.length - 1;

        for (let i = fromIndex; i <= toIndex; ++i) {
            max = Math.max(max, intervals[i][0]);
        }
        return max;
    };

    /**
     *
     * @param intervals
     * @param elementIntervals
     */
    static getNewIntervals = (
        intervals1: [number, number][],
        elementIntervals: ([number, number, number, string] | [number, number, number])[],
        message: any | undefined = undefined,
        comment: string | undefined = undefined
    ): [number, number][] => {
        // Копируем массив
        let intervals: [number, number][] = intervals1.map((interval) => [...interval]);

        if(intervals && intervals.length > 0){
            elementIntervals.forEach((element) => {
                let elementStartDate = moment(element[1]);
                let elementEndDate = moment(element[2]);

                let startIndex = intervals.findIndex((interval) => {
                    return interval[1] >= elementStartDate.valueOf();
                });

                if (startIndex === -1) {
                    // Нужно добавить в начало
                    startIndex = 0;
                }

                if (intervals[startIndex][1] > elementStartDate.valueOf()) {
                    // Вставить перед ним обязательство
                    intervals.splice(startIndex, 0, [intervals[startIndex][0], elementStartDate.valueOf()]);
                }

                let endIndex = intervals.findIndex((interval) => {
                    return interval[1] >= elementEndDate.valueOf();
                });

                if (intervals[endIndex] !== undefined && intervals[endIndex][1] > elementEndDate.valueOf()) {
                    // Добавить новое обязательство
                    intervals.splice(endIndex, 0, [intervals[endIndex][0], elementEndDate.valueOf()]);
                }

                intervals.forEach((interval, index) => {
                    if (index > startIndex && index <= endIndex) {
                        interval[0] = interval[0] - element[0];
                    }
                });
            });
        }
        //console.log(`%c ProductUtils.getNewIntervals`, 'color: #1170f1', ProductUtils.printProductStackMap(intervals1), ProductUtils.printElementIntervals(elementIntervals), ProductUtils.printProductStackMap(intervals));
        //console.log(`%cProductUtils.getNewIntervals ${message}`, 'color: #1170f1', ProductUtils.printElementIntervals(elementIntervals), comment);
        return intervals;
    };

    /**
     * Метод ищет в списке элементов операции все подходящие, в зависимости от типа элемента
     *
     * @param elements
     * @param productId
     * @param variantId
     * @param instanceId
     */
    static getElementIntervals = (
        elements: Array<OperationElement>,
        productId: number,
        variantId?: number,
        instanceId?: number,
        excludeElementIds?: number[],
        filter?: (element: OperationElement) => boolean
    ): [number, number, number][] => {
        let intervals: [number, number, number][] = elements
            .filter((element) => {
                return filter ? filter(element) : true;
            })
            .filter((element) => {
                return excludeElementIds ? !excludeElementIds.includes(element.id) : true;
            })
            .filter((element) => {
                // Это старая тема, работала раньше!!!
                if (element.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.BULK) {
                    // Ищем только по продукту
                    return element.productId === productId;
                } else if (element.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTBULK) {
                    // Ищем по продукту и варианту
                    return element.productId === productId && element.variantId === variantId;
                } else if (element.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED) {
                    // Ищем по продукту и экземпляру
                    if (isDefined(instanceId))
                        return element.productId === productId && element.instanceIds && element.instanceIds.includes(instanceId);
                    else return element.productId === productId;
                } else if (element.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED) {
                    // Ищем по продукту, варианту и экземпляру
                    if (isDefined(instanceId))
                        return (
                            element.productId === productId &&
                            element.variantId === variantId &&
                            element.instanceIds &&
                            element.instanceIds.includes(instanceId)
                        );
                    else return element.productId === productId && element.variantId === variantId;
                } else {
                    return false;
                }
            })
            .map((element) => [
                isDefined(instanceId) ? 1 : element.instanceCount,
                //element.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.BULK || element.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTBULK ? element.instanceCount : 1,
                element.rentPeriodStartDate.getTime(),
                element.rentPeriodEndDate.getTime(),
            ]);
        //console.log(`%c ProductUtils.getElementIntervals productId:${productId} variantId:${variantId} instanceId:${instanceId} intervals: excludeElementIds:${excludeElementIds}`, 'color: #1170f1', ProductUtils.printElementIntervals(intervals));
        return intervals;
    };

    static createRentElementInfoUpdate = (element: OperationElement, editOperation: boolean, withDates: boolean): RentElementInfoWrite => {
        const effectivePrice = element.pricePerShift || 0;
        return {
            id: element.id >= 0 ? element.id : undefined,
            instanceCount: element.instanceCount,
            instanceIds: element.instanceIds && element.instanceIds.length ? element.instanceIds : undefined,
            rentTerms: {
                discount: element.discount,
                shiftCount: element.shiftCount || 0,
                rentPeriodStartDate: withDates ? element.rentPeriodStartDate : new Date(0),
                rentPeriodEndDate: withDates ? element.rentPeriodEndDate : new Date(1),
            },
            anonymousInstanceCount:
                element.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.BULK ||
                element.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTBULK
                    ? element.instanceCount
                    : element.anonymousInstanceCount,
            businessVersion: element.businessVersion,
            variantId: element.variantId,
            effectivePrice: effectivePrice,
            effectivePricingSchemeId: isDefined(element.pricingSchemeId) ? element.pricingSchemeId.id : undefined,
            keepLeftover: editOperation ? false : element.keepLeftover,
            targetStateCodeOnEditing: editOperation ? element.stateCodeNew : undefined
        };
    };

    static createRentElementInfoCreate = (element: OperationElement, editOperation: boolean, withDates: boolean): RentElementInfoWrite => {
        const effectivePrice = element.pricePerShift || 0;
        return {
            instanceCount: element.instanceCount,
            rentTerms: {
                discount: element.discount,
                shiftCount: element.shiftCount || 0,
                rentPeriodStartDate: withDates ? element.rentPeriodStartDate : new Date(0),
                rentPeriodEndDate: withDates ? element.rentPeriodEndDate : new Date(1),
            },
            instanceIds: element.instanceIds && element.instanceIds.length ? element.instanceIds : undefined,
            anonymousInstanceCount:
                element.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.BULK ||
                element.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTBULK
                    ? element.instanceCount
                    : element.anonymousInstanceCount,
            effectivePrice: effectivePrice,
            effectivePricingSchemeId: isDefined(element.pricingSchemeId) ? element.pricingSchemeId.id : undefined,
            productId: element.productId,
            variantId: element.variantId,
            mainKitMember: element.mainKitMember ? true : false,
            keepLeftover: element.keepLeftover,
            kitMemberId: element.kitMemberId,
            targetStateCodeOnEditing: editOperation ? element.stateCodeNew : undefined
        };
    };

    static createRentElementInfoUpdate1 = (element: RentElementsGridItem): RentElementInfoWrite => {
        return {
            id: element.id,
            businessVersion: element.businessVersion,
            instanceCount: element.instanceCount,
            rentTerms: {
                discount: element.rentTerms.discount,
                shiftCount: element.rentTerms.shiftCount || 0,
                rentPeriodStartDate: element.rentTerms.rentPeriodStartDate,
                rentPeriodEndDate: element.rentTerms.rentPeriodEndDate,
            },
            instanceIds: element.instanceIds && element.instanceIds.length ? element.instanceIds : undefined,
            anonymousInstanceCount:
                element.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.BULK ||
                element.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTBULK
                    ? element.instanceCount
                    : element.anonymousInstanceCount,
            effectivePrice: element.effectivePrice,
            effectivePricingSchemeId: element.effectivePricingScheme?.id,
            productId: element.productId,
            variantId: element.variantId,
            mainKitMember: element.mainKitMember ? true : false,
            keepLeftover: false
        };
    };

    static getIntervalsFromProductStackMapList2 = (
        timeTables: TimeTable[],
        kitId: number | undefined | null,
        productId: number | undefined | null,
        variantId: number | undefined | null,
        instanceId: number | undefined | null,
        typeCode: TimetableTypeCodeEnum
    ): [number, number][] | undefined => {
        // На вход массив со всеми картами для всех продуктов.
        const map = timeTables
            ?.find((item) => {
                if (kitId && item.kitId === kitId) return true;
                else if (productId && item.productId === productId) {
                    if (!isDefined(variantId) && !isDefined(instanceId) && !isDefined(item.variantId) && !isDefined(item.instanceId))
                        return true;
                    else if (
                        isDefined(variantId) &&
                        !isDefined(instanceId) &&
                        isDefined(item.variantId) &&
                        !isDefined(item.instanceId) &&
                        variantId === item.variantId
                    )
                        return true;
                    else if (
                        isDefined(variantId) &&
                        isDefined(instanceId) &&
                        isDefined(item.variantId) &&
                        isDefined(item.instanceId) &&
                        variantId === item.variantId &&
                        instanceId === item.instanceId
                    )
                        return true;
                    else if (
                        !isDefined(variantId) &&
                        isDefined(instanceId) &&
                        !isDefined(item.variantId) &&
                        isDefined(item.instanceId) &&
                        instanceId === item.instanceId
                    )
                        return true;
                }
                return false;
            })
            ?.list?.maps.find((item) => item.typeCode === typeCode);

        if (map) {
            let intervals = ProductUtils.parseProductStackMap(map.mapString);
            //console.log(`%c ProductUtils.getIntervalsFromProductStackMapList productId:${productId} variantId:${variantId} typeCode:${typeCode} intervals:`, 'color: #11aff1', ProductUtils.printProductStackMap(intervals));
            return intervals;
        } else {
            return undefined;
        }
    };

    static getIntervalsFromTimetableList = (list: TimetableList | undefined, typeCode: TimetableTypeCodeEnum) => {
        let map = list?.maps.find((item) => item.typeCode === typeCode);
        let intervals: [number, number][] = [];
        if (map) intervals = ProductUtils.parseProductStackMap(map.mapString);
        //else console.error('error', typeCode);
        return intervals;
    };

    static findElements = (
        elements: OperationElement[],
        params: { kitId?: number; productId?: number; variantId?: number },
        excludeElementId?: number
    ) => {
        let elems: OperationElement[] = [];

        elements.forEach((element: OperationElement) => {
            if (!excludeElementId || element.id !== excludeElementId) {
                if (isDefined(params.kitId)) {
                    // Ищем набор
                    if (element.kitId === params.kitId) elems.push(element);
                } else if (isDefined(params.productId)) {
                    // Ищем продукт
                    if (!isDefined(params.variantId)) {
                        // только продукт
                        if (element.productId === params.productId && !isDefined(element.variantId)) elems.push(element);
                    } else if (isDefined(params.variantId)) {
                        if (element.productId === params.productId && element.variantId === params.variantId) elems.push(element);
                    }
                }
            }
        });
        //return elems;

        let iii: any[] = [];
        elems.forEach((el) => {
            let requiredIndent = (el.requiredTimeIndentBetweenElementsInMinutes || 0) * 60 * 1000;
            iii.push(
                [el.instanceCount, el.rentPeriodStartDate.getTime() - requiredIndent / 2, el.rentPeriodStartDate.getTime()],
                [el.instanceCount, el.rentPeriodEndDate.getTime(), el.rentPeriodEndDate.getTime() + requiredIndent / 2]
            );
        });
        //console.log('EEE', iii);
        return iii;
    };

    static findInstancesInElements = (elements: OperationElement[], instanceId: number, excludeElementId?: number) => {
        let intervals: any[] = [];

        elements.forEach((element: OperationElement) => {
            if (!excludeElementId || element.id !== excludeElementId) {
                if (element.instanceIds) {
                    element.instanceIds.forEach((id) => {
                        if (instanceId === id) {
                            let requiredIndent = ((element.requiredTimeIndentBetweenElementsInMinutes || 0) * 60 * 1000) / 2;
                            intervals.push(
                                [1, element.rentPeriodStartDate.getTime() - requiredIndent, element.rentPeriodStartDate.getTime()],
                                [1, element.rentPeriodEndDate.getTime(), element.rentPeriodEndDate.getTime() + requiredIndent]
                            );
                        }
                    });
                }
            }
        });
        return intervals;
    };

    logIntervals = (intervals1: [number, number][], elementIntervals: [number, number, number][]) => {};
}

/**
 *
 */
export const logStackMap = (
    params: { productId?: number; kitId?: number; variantId?: number; instanceId?: number; map?: any; intervals?: any; comment?: string },
    line?: true
) => {
    if (LOG_NOMENCLATURE_STACKMAPS) {
        let arr: any = [];
        let str = `%clogStackMap${params.productId ? ' productId:' + params.productId : ''}${params.kitId ? ' kitId:' + params.kitId : ''}${
            params.variantId ? ' variantId:' + params.variantId : ''
        }${params.instanceId ? ' instanceId:' + params.instanceId : ''}`;

        if (line) console.log(`${str} --------------------------------`, 'color: #1170f1');
        arr.push(`${str}`, 'color: #1170f1');

        if (params.intervals) arr.push('intervals:', ProductUtils.printElementIntervals(params.intervals));
        if (params.map) arr.push('map:', ProductUtils.printProductStackMap(params.map));
        if (params.comment) arr.push(params.comment);
        console.log(...arr);
    }
};

import { IRootState } from '../../../../shared/reducers';
import { createShipment, resetError, updateEntity } from './reducers/subrent.reducer';
import { goBack, push } from 'connected-react-router';
import { connect } from 'react-redux';
import {
    ContactPersonAPIObj,
    ContractValidationTypeCodeEnum,
    PersonFullName,
    ProjectStateCodeEnum,
    RenterInfoRead,
    RenterStateCodeEnum,
    SubrentInfoCreate,
    SubrentInfoRead,
    SubrentInfoUpdate,
    SubrentStateCodeEnum,
} from '../../../../server/api';
import { showConfirm } from '../../../../components/confirm/showConfirm';
import { ModalFormNEW, ModalFormNEWProps } from '../../../../components/modalForm/ModalFormNEW';
import { FormFieldsGroup } from '../../../../components/dynamicForm/DynamicForm';
import { formFields } from './subrentShippingCreateModalFields';
import { printUserName } from '../../../../shared/util/utils';
import { LocalizationEnum, localizeIntl } from '../../../../localization';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { AxiosResponse } from 'axios';
import { showNotification } from '../../../../components/notification/showNotification';
import { logEvent } from '../../../../analytics/analytics';
import moment from 'moment';
import { updateModalFilters } from '../../../../shared/reducers/userSettings/userSettings.reducer';
import { contractValidationTypeSelector } from '../../../../shared/reducers/businessAccountPreferences.reducer';

interface IProps extends ModalFormNEWProps, WrappedComponentProps {
    formFields?: FormFieldsGroup[];
    successCallback?: (data: SubrentInfoRead) => void;
    renterId?: string;
    copyMode?: boolean;
    validateAfterCreate?: boolean;
    renterObj?: RenterInfoRead | null;
    copyModeProject?: boolean;
    createFromOperationForm?: boolean;
}

interface IContacts extends PersonFullName, ContactPersonAPIObj {}

const setCopyParams = (newData: SubrentInfoCreate) => {
    const copyParams: any | undefined = newData['copyParams'];
    newData.copyRentElements = copyParams?.copyRentElements || false;
    newData.saveTrackedInstances = copyParams?.saveTrackedInstances || false;
    delete newData['copyParams'];
};

class _SubrentShippingCreateModal extends ModalFormNEW<IProps & StateProps & DispatchProps> {
    static defaultProps = {
        formFields: formFields,
    };

    onOk = (data: any) => {
        console.log(data);

        if (data['dates']) {
            if (data['dates'][0]) data.startDate = data['dates'][0];
            if (data['dates'][1]) data.targetFinishDate = data['dates'][1];
            delete data['dates'];
        }
        if (!data.startDate) data.startDate = null;
        if (!data.targetFinishDate) data.targetFinishDate = null;

        delete data['createFromOperationForm'];

        data.subrentContract = {};

        if (this.props.initialValues) {
            if (!this.props.copyMode) {
                // Редактирование
                let newData: any = { ...this.props.initialValues, ...data } as SubrentInfoUpdate;

                const useAssignee: any = newData;
                if (newData.assigneeId) newData.assigneeId = newData.assigneeId['key'];

                if (useAssignee.useAssignee) {
                    newData.hasOwnAssignee = false;
                } else {
                    newData.hasOwnAssignee = true;
                }

                this.contactNotEmptyString(newData);

                if (newData.description === '') delete newData.description;
                if (newData.comment === '') delete newData.comment;
                if (newData.fullName === '') delete newData.fullName;
                delete newData['useAssignee'];
                delete newData['defaultAssignee'];

                // if (typeof newData.supplierId === 'object') {
                //     newData.supplierId = +newData.supplierId.key;
                // }

                this.props.updateEntity(this.props.intl, this.props.businessAccountId, newData.id || 0, newData);
            } else {
                // Копирование
                let newData: SubrentInfoCreate = data as SubrentInfoCreate;
                if (newData.assigneeId) newData.assigneeId = newData.assigneeId['key'];
                //if (newData.supplierId) newData.supplierId = newData.supplierId['key'];

                newData.hasOwnAssignee = !newData['useAssignee'];

                if (newData.description === '') delete newData.description;
                if (newData.comment === '') delete newData.comment;
                if (newData.fullName === '') delete newData.fullName;
                delete newData['useAssignee'];
                delete newData['defaultAssignee'];
                delete newData['useHasOwnContacts'];
                newData.hasOwnContacts = newData.hasOwnContacts === undefined || newData.hasOwnContacts === false ? false : true;

                if (this.props.copyModeProject) {
                    //newData.baseTemplateId  = this.props.initialValues.id;
                    delete newData['template'];
                    delete newData['fromTemplate'];
                }

                setCopyParams(newData);
                newData.sourceSubrentId = this.props.initialValues.id;

                let request = this.props.createProject(
                    this.props.intl,
                    this.props.businessAccountId,
                    newData,
                    this.props.successCallback
                ) as any;
                request.then((result: any) => {
                    if (result instanceof Error) {
                        // Сущность не создана
                    } else {
                        //
                        let res: AxiosResponse<SubrentInfoRead> = result.action.payload;
                        showNotification(
                            'success',
                            localizeIntl(this.props.intl, LocalizationEnum.PAGE__SHIPPINGS__POPUP_NOTIFICATIONS__CREATED)
                        );
                        this.props.push(`/${this.props.businessAccountId}/subrent/shippings/${res.data.id}?tab=description`);
                        if (this.props.successCallback) this.props.successCallback(res.data);
                        logEvent({
                            type: 'create project',
                            data: {
                                'entity id': data.id,
                            },
                        });
                    }
                });
            }
        } else {
            // Создание
            let newData = data as SubrentInfoCreate;

            if (!newData.stateCode) data.stateCode = SubrentStateCodeEnum.INPROGRESS;

            if (newData.assigneeId) newData.assigneeId = newData.assigneeId['key'];
            //if (newData.supplierId) newData.supplierId = newData.supplierId['key'];

            newData.hasOwnAssignee = !newData['useAssignee'];

            newData.hasOwnContacts = newData.hasOwnContacts === undefined || newData.hasOwnContacts === false ? false : true;

            if (newData.description === '') delete newData.description;
            if (newData.comment === '') delete newData.comment;
            if (newData.fullName === '') delete newData.fullName;

            delete newData['defaultAssignee'];
            delete newData['fromTemplate'];
            delete newData['projectEdit'];
            delete newData['renterDefaultAssignee'];
            delete newData['useAssignee'];
            delete newData['useHasOwnContacts'];
            delete newData['_defaultAssignee'];

            setCopyParams(newData);

            let request = this.props.createProject(
                this.props.intl,
                this.props.businessAccountId,
                newData,
                this.props.successCallback
            ) as any;
            request.then((result: any) => {
                if (result instanceof Error) {
                    // Сущность не создана
                } else {
                    let res: AxiosResponse<SubrentInfoRead> = result.action.payload;
                    showNotification('success', 'Поставка создана');

                    if (this.props.successCallback) this.props.successCallback(res.data);
                    logEvent({
                        type: 'create project',
                        data: {
                            'entity id': data.id,
                        },
                    });
                }
            });
        }
    };

    contactNotEmptyString = (newData) => {
        const contact: IContacts = newData ? newData.contacts![0].fullName : undefined;

        if (contact) {
            if (contact.secondname === '') {
                contact.secondname = undefined;
            } else if (contact.position === '') {
                contact.position = undefined;
            } else if (contact.phoneNumber === '') {
                contact.phoneNumber = undefined;
            } else if (contact.email === '') {
                contact.email = undefined;
            }
        }
    };

    getInitialValues = () => {
        let initialValues: any;

        if (this.props.initialValues) {
            initialValues = {
                ...this.props.initialValues,
                __fullName: this.props.initialValues.fullName,
                __shortName: this.props.initialValues.shortName,
                dates:
                    this.props.initialValues.startDate || this.props.initialValues.targetFinishDate
                        ? [
                              this.props.initialValues.startDate ? moment(this.props.initialValues.startDate) : undefined,
                              this.props.initialValues.targetFinishDate ? moment(this.props.initialValues.targetFinishDate) : undefined,
                          ]
                        : undefined,
            };

            if (this.props.copyModeProject) {
                initialValues.fromTemplate = true;
                initialValues.template = initialValues.shortName;
            } else {
                initialValues.projectEdit = true;
            }

            if (this.props.copyMode) {
                initialValues.id = undefined;

                initialValues.copyMode = true;

                initialValues.stateCode =
                    this.props.renterObj?.stateCode === RenterStateCodeEnum.NEW
                        ? SubrentStateCodeEnum.DRAFT
                        : SubrentStateCodeEnum.INPROGRESS;
            }

            if (this.props.copyMode) {
                if (initialValues.supplierStateCode === RenterStateCodeEnum.BLOCKED) {
                    initialValues.supplierId = undefined;
                    initialValues.stateCode = undefined;
                } else if (initialValues.supplierStateCode === RenterStateCodeEnum.NEW) {
                    initialValues.stateCode = SubrentStateCodeEnum.DRAFT;
                } else if (initialValues.supplierStateCode === RenterStateCodeEnum.ACTIVE) {
                    initialValues.stateCode = SubrentStateCodeEnum.INPROGRESS;
                }

                initialValues.copyParams = {
                    copyRentElements: true,
                };
            }

            // if (initialValues.supplierId) {
            //     initialValues.supplierId = {
            //         key: '' + initialValues.supplierId,
            //         label: initialValues.supplierShortName,
            //     };
            // }

            if (initialValues.assigneeId) {
                initialValues.defaultAssignee = {
                    key: '' + initialValues.assigneeId,
                    label: printUserName(initialValues.assigneeFullName),
                };

                if (this.props.initialValues.assigneeFullName) {
                    initialValues.assigneeId = {
                        key: '' + initialValues.assigneeId,
                        label: printUserName(initialValues.assigneeFullName),
                    };
                }

                if (this.props.initialValues.defaultAssignee) {
                    initialValues.assigneeId = {
                        key: '' + initialValues.assigneeId,
                        label: printUserName(initialValues.assigneeFullName),
                    };
                }
            }

            if (initialValues.hasOwnAssignee) {
                initialValues.useAssignee = false;
            } else {
                initialValues.useAssignee = true;
            }

            if (initialValues.hasOwnContacts) {
                initialValues.useHasOwnContacts = false;
            } else {
                initialValues.useHasOwnContacts = true;
            }

            if (initialValues.supplierContacts && initialValues.supplierContacts[0]) {
                initialValues.contactsFIO =
                    (initialValues.supplierContacts[0].fullName.lastname ? initialValues.supplierContacts[0].fullName.lastname : '') +
                    ' ' +
                    (initialValues.supplierContacts[0].fullName.firstname ? initialValues.supplierContacts[0].fullName.firstname : '') +
                    ' ' +
                    (initialValues.supplierContacts[0].fullName.secondname ? initialValues.supplierContacts[0].fullName.secondname : '');
            } else {
                initialValues.contactsFIO = localizeIntl(this.props.intl, LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_ASSIGNED) as string;
            }

            if (this.props.initialValues.renterAssigneeFullName) {
                initialValues.renterDefaultAssignee = {
                    key: '' + this.props.initialValues.renterAssigneeId,
                    label:
                        this.props.initialValues.renterAssigneeFullName.lastname +
                        ' ' +
                        this.props.initialValues.renterAssigneeFullName.firstname,
                };
            }
        } else {
            if (this.props.renterId) {
                initialValues = {
                    supplierId: this.props.renterId,
                    renterStateCode: this.props.renterObj?.stateCode,
                    stateCode:
                        this.props.renterObj?.stateCode === RenterStateCodeEnum.NEW
                            ? SubrentStateCodeEnum.DRAFT
                            : SubrentStateCodeEnum.INPROGRESS,

                    taxBaseType: this.props.businessAccountPreferences?.taxBaseType,
                    taxRate: this.props.businessAccountPreferences?.defaultTaxRate,
                };

                if (this.props.renterObj) {
                    if (initialValues.defaultAssignee) {
                        initialValues.defaultAssignee = {
                            key: '' + this.props.renterObj.assigneeId,
                            label: printUserName(this.props.renterObj.assigneeFullName),
                        };
                    }
                    if (initialValues.assigneeId) {
                        initialValues.assigneeId = {
                            key: '' + this.props.renterObj.assigneeId,
                            label: printUserName(this.props.renterObj.assigneeFullName),
                        };
                    }

                    if (this.props.renterObj.contacts && this.props.renterObj.contacts[0]) {
                        initialValues.contactsFIO = printUserName(this.props.renterObj.contacts[0].fullName);
                    } else {
                        initialValues.contactsFIO = localizeIntl(
                            this.props.intl,
                            LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_ASSIGNED
                        ) as string;
                    }

                    initialValues.hasOwnAssignee = true;
                }
            }
        }

        if (this.props.createFromOperationForm) {
            if (!initialValues) initialValues = {};
            initialValues.createFromOperationForm = this.props.createFromOperationForm;
        }

        if (
            this.props.contractValidationType &&
            [ContractValidationTypeCodeEnum.ONLYFILE, ContractValidationTypeCodeEnum.ALLFIELDS].includes(this.props.contractValidationType)
        ) {
            if (!initialValues) initialValues = {};
            initialValues.stateCode = ProjectStateCodeEnum.DRAFT;
        }

        return initialValues;
    };

    onCancel = async (isFieldsTouched: Boolean) => {
        let yes =
            !isFieldsTouched ||
            (await showConfirm(
                this.props.intl,
                this.props.initialValues
                    ? localizeIntl(this.props.intl, LocalizationEnum.ASPECT__MODAL__ABORT_EDIT)
                    : localizeIntl(this.props.intl, LocalizationEnum.ASPECT__MODAL__ABORT_CREATION)
            ));
        if (yes) {
            // this.props.goBack();

            if (this.props.initialValues) {
                if (this.props.copyModeProject === false || this.props.copyModeProject === undefined) {
                    this.props.push(`/${this.props.businessAccountId}/subrent/shippings/${this.props.initialValues?.id}/?tab=description`);
                } else {
                    this.props.goBack();
                }
            } else {
                this.props.goBack();
            }
            this.props.resetError();
        }
    };
}

const mapStateToProps = (storeState: IRootState) => ({
    updating: storeState.subrent.updating,
    updatingError: storeState.subrent.updatingError,
    modalFilters: storeState.userSettings.modalFilters,
    businessAccountPreferences: storeState.businessAccountPreferences.preferences,
    contractValidationType: contractValidationTypeSelector(storeState),
});

const mapDispatchToProps = {
    createProject: createShipment,
    updateEntity,
    goBack,
    resetError,
    push,
    updateModalFilters,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const SubrentShippingCreateModal = connect(mapStateToProps, mapDispatchToProps)(injectIntl(_SubrentShippingCreateModal));

import React from 'react';
import { BaseSelect, BaseSelectBaseProps, BaseSelectValue } from '../baseSelect';
import { serverApi, UserRecord } from '../../../../server';
import { AxiosResponse } from 'axios';
import { useAppSelector } from '../../../../store/hooks';

export interface UserSelectProps extends BaseSelectBaseProps<UserRecord> {}

const UserSelectFC = ({ ...props }: UserSelectProps) => {
    const businessAccountId = useAppSelector((state) => state.system.businessAccountId);

    const getEntitiesById = async (id: BaseSelectValue) => {
        const ids = Array.isArray(id) ? id : [id];
        const response = (await Promise.all(
            ids.map((id) => serverApi.getUserById(businessAccountId, +id))
        )) as unknown as AxiosResponse<UserRecord>[];
        return response.map((item) => item.data);
    };

    const getEntitiesList = async (startFrom: number, limit: number) => {
        const response = await serverApi.listUsersSimple(businessAccountId, limit, startFrom);
        return response.data.records;
    };

    return (
        <BaseSelect
            {...props}
            fetchRecordsById={getEntitiesById}
            fetchRecords={getEntitiesList}
            getRecordId={(record) => record.id}
            getOptionProps={(record) => ({
                label: record.id,
                children: record.shortName,
            })}
            showSearch={false} // TODO Тут поиск отключен т.к. нет этого на сервере
        />
    );
};

export class UserSelect extends React.Component<UserSelectProps> {
    render() {
        return <UserSelectFC {...this.props} />;
    }
}

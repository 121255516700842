import React, { useRef, useState } from 'react';
import { ItemsNotFoundBlock } from '../../../../../components/itemsNotFoundBlock/ItemsNotFoundBlock';
import Timeline, { TimelineHeaders } from 'react-calendar-timeline';
import { useDateHeaderUnits } from './hooks/useDateHeaderUnits';
import { CalendarHeader } from '../CalendarHeader/CalendarHeader';
import { CalendarMarker } from '../CalendarMarker/CalendarMarker';
import { useIntervalClick } from './hooks/useIntervalClick';
import { useGroups } from './hooks/useGroups';
import { typedMemo } from '../../../../../core/hooks/useMemo';
import { CalendarItemRenderer } from '../CalendarItemRenderer/CalendarItemRenderer';
import { TimeRangeContext } from '../CalendarItemRenderer/context/TimeRangeContext';
import { CALENDAR_LINE_HEIGHT_RATIO, CalendarDisplayType } from './utils/data/calendarConstants';
import { CalendarLineUtils } from '../CalendarItemRenderer/utils/calendarLineUtils';
import { useOnCalendarItemChanges } from './hooks/useOnCalendarItemChanges';
import { CalendarHeaderContainer } from './components/Header/HeaderContainer/CalendarHeaderContainer';
import { ElementsCount } from './components/Header/ElementsCount/ElementsCount';
import { CalendarFooter } from './components/Footer/CalendarFooter';
import { useHorizontalLineClassNamesForGroup } from './hooks/useClassNames';
import { useDeferredItems } from './hooks/useDeferredItems';
import 'react-calendar-timeline/lib/Timeline.css';
import './Calendar.less';
import './CalendarLib.less';
import { ICalendarItem, useCalendarItems } from './hooks/useCalendarItems';
import { useDisableMouseScrollEffect } from './hooks/useDisableMouseScrollEffect';
import { CalendarId, CalendarItemType } from './types/items';
import { CalendarProps } from './types/props';
import { useFixedTableHeader } from './hooks/useFixedTableHeader';

const CalendarComponent = <
    Record extends ICalendarItem,
    Params extends object,
    SortValue extends string,
    ICalendarItemType extends CalendarItemType
>(
    props: CalendarProps<Record, Params, SortValue, ICalendarItemType>
) => {
    const {
        calendarType,
        recordList,
        processItemFunction,
        sortGroupMap,
        screenLeft,
        screenRight,
        isLoading,
        pageParamsObject,
        sortData,
        visibleItemsLimit,
        setVisibleItemsLimit,
        groupsData,
        onClick,
        itemRenderer,
        headerButtons: HeaderButtonsComponent,
    } = props;
    const { pageParams, updatePageParams } = pageParamsObject;

    const [sortByValue, setSortByValue] = useState<SortValue | undefined>(pageParams.group);
    const [displayType, setDisplayType] = useState<CalendarDisplayType>(pageParams.displayType ?? CalendarDisplayType.NORMAL);
    const [isPopoverVisible, setIsPopoverVisible] = useState(false);
    const [collapsedKitsId, setCollapsedKitsId] = useState<CalendarId[]>([]);
    const [collapsedGroupsId, setCollapsedGroupsId] = useState<CalendarId[]>([]);
    const scrollRef = useRef<HTMLDivElement>();
    const headerRef = useRef<HTMLDivElement>();
    const selectedIndexRef = useRef<number | undefined>();
    const { fixed, headerRect } = useFixedTableHeader(headerRef, scrollRef);

    const filteredCount = recordList?.listAttributes.filteredCount ?? 0;

    const { items, visibleTimeStart, visibleTimeEnd } = useCalendarItems(
        recordList?.records,
        processItemFunction,
        pageParams.screenLeft ?? screenLeft,
        pageParams.screenRight ?? screenRight,
        isLoading,
        collapsedKitsId,
        collapsedGroupsId,
        sortGroupMap,
        pageParams.group
    );

    const { primaryDateHeaderUnit, secondaryDateHeaderUnit } = useDateHeaderUnits(visibleTimeStart, visibleTimeEnd);

    const onIntervalClick = useIntervalClick(updatePageParams);

    const lineHeight = CalendarLineUtils.map.lineHeightFromDisplayType[displayType];
    const sidebarWidth = 0;

    const { onItemClick, onPopoverVisibleChange } = useOnCalendarItemChanges({
        items,
        calendarType,
        selectedIndexRef,
        scrollRef,
        setIsPopoverVisible,
        setCollapsedKitsId,
        setCollapsedGroupsId,
        onClick,
        pageParams,
        isLoading,
    });

    const horizontalLineClassNamesForGroup = useHorizontalLineClassNamesForGroup(items);

    const deferredItems = useDeferredItems(items, isLoading);
    const groups = useGroups(deferredItems, displayType);

    useDisableMouseScrollEffect(scrollRef);

    return (
        <div className={'calendar-timeline'} style={{ opacity: isPopoverVisible ? 0.5 : 1 }}>
            {fixed && <div style={{ height: headerRect?.height }} />}
            {
                <CalendarHeaderContainer>
                    <ElementsCount items={items} filteredCount={filteredCount} />
                    <div className={'actions-block'}>
                        <HeaderButtonsComponent
                            isLoading={isLoading}
                            pageParamsObject={pageParamsObject}
                            sortData={sortData}
                            sortByValue={sortByValue}
                            setSortByValue={setSortByValue}
                            displayType={displayType}
                            setDisplayType={setDisplayType}
                        />
                    </div>
                </CalendarHeaderContainer>
            }
            {
                <TimeRangeContext.Provider
                    value={{
                        visibleTimeStart,
                        visibleTimeEnd,
                    }}
                >
                    <Timeline
                        canMove={false}
                        canResize={false}
                        groups={groups}
                        horizontalLineClassNamesForGroup={horizontalLineClassNamesForGroup}
                        itemHeightRatio={CALENDAR_LINE_HEIGHT_RATIO}
                        items={deferredItems}
                        lineHeight={lineHeight}
                        scrollRef={(el) => (scrollRef.current = el)}
                        headerRef={(el) => (headerRef.current = el)}
                        sidebarWidth={sidebarWidth}
                        visibleTimeStart={visibleTimeStart}
                        visibleTimeEnd={visibleTimeEnd}
                        itemRenderer={CalendarItemRenderer({
                            itemRenderer,
                            displayType,
                            onPopoverVisibleChange,
                            onItemClick,
                            groupsData,
                            sortByValue,
                        })}
                    >
                        <TimelineHeaders className={fixed ? 'fixed-header' : undefined}>
                            <CalendarHeader.Primary
                                unit={primaryDateHeaderUnit}
                                onIntervalClick={onIntervalClick}
                                allNothing={!visibleTimeEnd || !visibleTimeStart}
                            />
                            <CalendarHeader.Secondary
                                primaryUnit={primaryDateHeaderUnit}
                                unit={secondaryDateHeaderUnit}
                                onIntervalClick={onIntervalClick}
                                allNothing={!visibleTimeEnd || !visibleTimeStart}
                                sidebarWidth={sidebarWidth}
                            />
                        </TimelineHeaders>
                        {items?.length && <CalendarMarker />}
                    </Timeline>
                </TimeRangeContext.Provider>
            }
            {
                <CalendarFooter
                    filteredCount={filteredCount}
                    visibleItemsLimit={visibleItemsLimit}
                    setVisibleItemsLimit={setVisibleItemsLimit}
                />
            }
            {items.length === 0 ? <ItemsNotFoundBlock style={{ marginTop: 56, marginBottom: 20 }} entityType={'element'} /> : null}
        </div>
    );
};

export const Calendar = typedMemo(CalendarComponent);

import React, { createRef, ReactNode, RefObject } from 'react';
import { Checkbox, Col, Icon, Pagination, Popover, Radio, Row, Select } from 'antd';
import { IconExclamationCircleSolid, IconSitemap, IconSliders } from '../icons';
import { CustomSelect } from '../select/CustomSelect';
import { Table } from './Table3';
import RoundButton from '../button/roundButton';
import './Grid.less';
import { LocalizationEnum, localize } from '../../localization';
import { ItemsNotFoundBlock } from '../itemsNotFoundBlock/ItemsNotFoundBlock';
import { EntityActionType, EntityType } from '../../../index';
import { HelpTooltip } from '../helpTooltip/HelpTooltip';
import {
    getGridCategoriesStorageData,
    getGridStorageData,
    GridStorageData,
    setGridCategoriesStorageData,
    setGridStorageDataFilters,
} from './utils';
import DynamicFilters from '../dynamicFilters/DynamicFilters';
import { FormFieldsGroup } from '../dynamicForm/DynamicForm';
import uniq from 'lodash/uniq';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { IRootState } from '../../shared/reducers';
import { setGridViewMode, updateGridSettings } from '../../shared/reducers/userSettings/userSettings.reducer';
import { ActionTypeEnum } from '../../core/types/ActionTypeEnum';
import { getGridAnchorClassName, SelectScrollController } from '../controllers/scroll/SelectScrollController/SelectScrollController';
import { EmptyCategoriesProps } from '../emptyCategories/EmptyCategories';
import { getFilteredParams, getPathFromState, isDefined } from '../../shared/util/utils';
import { replace } from 'connected-react-router';
import { TableColumn } from './Table';
import { DndProps } from './grid/ReactTableBody';
import { AntTreeNodeSelectedEvent } from 'antd/lib/tree';
import { CheckboxDragController } from '../controllers/drag/CheckboxDragController/CheckboxDragController';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { CustomFieldObjRead } from '../../server';
import { ConditionalLink } from '../lib/ConditionalLink/ConditionalLink';
import { canUpdateSystemOptions } from '../../shared/util/permissionUtils';
import { GridName } from './utils/types';
import { CustomFieldsUtils } from '../../core/utils/customFieldsUtils';
import { CustomFieldActionType } from '../../modules/main/settings/customFields/customFieldsModuleData';
import { DEFAULT_ON_PAGE_SELECT_VALUES } from '../../config/constants';

interface GridState {
    currentPage: number;
    pageSize: number;
    selectedItems: string[];
    categoriesFilterCollapsed?: boolean;
    hiddenColumns: string[];
}

export interface GridProps {
    columns: Array<TableColumn<any>>; // Описание колонок таблицы
    customFieldMarkers?: CustomFieldObjRead[];
    excludeColumns: Array<string>;
    data: any[] | null; // Данные с сервера, массив любых объектов
    pageSize: number; // Обязательств на странице
    total: number; // Всего обязательств
    filtered: number; // Количество отфильтрованных обязательств
    currentPage: number; // Текущая страница
    actionsBlock?: React.ReactNode; // Кнопки действий
    onSortedChange(id: string, desc: boolean): void; // Калбэк при сортировке
    onPageChanged(page: number): void; // Калбэк при смене страницы
    onPageSizeChanged(size: number): void; // Калбэк при смене количества обязательств на странице
    onSelectionChanged(value: string[]): void;
    onRowAction?(entity: any, action: EntityActionType | ActionTypeEnum | CustomFieldActionType, instanceCount?: number): void;
    onRowEditAction?(entity: any, action: EntityActionType, instanceCount?: number): void;
    onRowClick?(entity: any): void;
    onCellAction?(dataIndex: string, record: Record<string, any>): void;
    categoriesFilter?: boolean;
    onColumnRemove?(id?: string): void;
    indexFieldName?: string;
    actionButtons?: React.ReactNode[];
    entityType: EntityType;
    rowPopoverComponent?: any;
    rowPopoverComponentProps?: any;
    selectable?: boolean;
    hidePageSizeSelect?: boolean;
    defaultSortDesc?: boolean;
    defaultSorted?: string;
    hideArchive?: boolean;
    dynamicColumns?: boolean;
    gridName: GridName;
    tableRowId: string;
    filtersGetFiltersFormRef?: (ref: any) => void;
    filtersData?: FormFieldsGroup[];
    filtersInitialValues?: { [k: string]: any };
    filtersOnChange?: (data: any) => void;
    filtersOnHideCustomValue?: (key: string) => void;
    filtersDefaultValues?: { [k: string]: any };
    filtersCurrentValues?: { [k: string]: any };
    filtersCustomParams?: { [k: string]: any };
    filtersResetFiltersCb?: () => void;
    filtersClassName?: string;
    categoriesOnSelect?: (selectedKeys: string[] | undefined) => void;
    categoriesOnExpand?: (keys) => void;
    filtersExcludeFields?: undefined | string[];
    filtersRightBlockExtraContent?: ReactNode;
    categoriesComponent?: any;
    categoriesFilterOffsetBottom?: number;
    categoriesSelectedKeys?: string[];
    categoriesType?: EmptyCategoriesProps['categoriesType'];
    filtersDontResetFilters?: string[];
    onPageSelect: number[];
    bottomPaginationRightExtraContent?: ReactNode;
    bottomPaginationLeftExtraContent?: ReactNode;
    generateEstimateCb?: (a: any) => void;
    exportBlock?: ReactNode;
    getUniqId?(data: any): string;
    alwaysRedraw?: boolean;
    fetchEntityErrorCallback?: (entityId: number) => void;
    dndProps?: DndProps;
    sortingDisabled?: boolean;
    convertSortBy?: (id: string) => string;
}

export class _Grid extends React.PureComponent<GridProps & StateProps & DispatchProps, GridState> {
    private tableRef;
    private checkboxesPopoverRef: RefObject<Popover>;
    private isCheckboxesOpened: boolean = false;
    static defaultProps;

    constructor(props) {
        super(props);

        this.checkboxesPopoverRef = createRef();

        const gridData = this.props.gridSettings;
        const urlParams = Object.fromEntries(new URLSearchParams(this.props.location.search).entries());

        const hiddenColumns = this.processHiddenColumns();

        this.state = {
            currentPage: this.props.currentPage,
            pageSize: this.props.pageSize,
            selectedItems: [],
            hiddenColumns,
            categoriesFilterCollapsed:
                urlParams.categoryIds != null ? false : gridData.hideCategories !== undefined ? gridData.hideCategories : false,
        };
    }

    componentDidUpdate(prevProps: Readonly<GridProps>, prevState: Readonly<GridState>, snapshot?: any): void {
        if (prevState.categoriesFilterCollapsed !== this.state.categoriesFilterCollapsed) {
            // let gridData = getGridStorageData(this.props.gridName);
            // gridData.hideCategories = this.state.categoriesFilterCollapsed;
            // setGridStorageData(this.props.gridName, gridData);
            // TODO сохранение настроек
            const gridData = { ...this.props.gridSettings, hideCategories: this.state.categoriesFilterCollapsed };
            this.props.updateGridSettings({ [this.props.gridName]: gridData });
        }

        if (
            prevProps.data !== this.props.data &&
            this.props.data &&
            this.props.data.length === 0 &&
            isDefined(this.props.filtered) &&
            this.props.filtered > 0
        ) {
            const page = Math.ceil(this.props.filtered / this.props.pageSize);
            this.props.onPageChanged(page);
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.pageSize !== state.pageSize || props.currentPage !== state.currentPage) {
            return {
                ...state,
                pageSize: +props.pageSize, // TODO
                currentPage: +props.currentPage, // TODO
            };
        }
        return null;
    }

    processHiddenColumns = (): string[] => {
        const hiddenColumns: string[] = [];

        const gridData = this.props.gridSettings;
        if (!gridData.columns) gridData.columns = {};

        this.props.columns.forEach((column) => {
            if (
                column.dontHide !== true &&
                column.dataIndex &&
                column.defaultHidden === true &&
                gridData.columns &&
                gridData.columns[column.dataIndex as string] === undefined
            ) {
                gridData.columns[column.dataIndex as string] = { hidden: true };
            }
        });

        for (let k in gridData.columns) {
            if (gridData.columns[k].hidden) hiddenColumns.push(k);
        }

        return hiddenColumns;
    };

    onCurrentPageChanged = (page, pageSize) => {
        this.setState({ currentPage: page });

        if (this.props.onPageChanged) {
            this.props.onPageChanged(page);
        }
    };

    onPageSizeChanged = (value, element) => {
        this.setState({ pageSize: value });
        if (this.props.onPageSizeChanged) this.props.onPageSizeChanged(value);
        // let gridData = getGridStorageData(this.props.gridName);
        // gridData.params.limit = value;
        // setGridStorageData(this.props.gridName, gridData);

        // TODO сохранение настроек
        const gridData = { ...this.props.gridSettings };
        gridData.params = { ...gridData.params, limit: value };
        this.props.updateGridSettings({ [this.props.gridName]: gridData });
    };

    renderPaginationInfo(marginLeft: number = 10, dashboardFooter = false) {
        let { currentPage, pageSize } = this.state;
        let { filtered } = this.props;

        let from = (currentPage - 1) * pageSize + 1;
        if (from > filtered) from = filtered;
        let to = currentPage * pageSize;
        if (to > filtered) to = filtered;

        return (
            <HelpTooltip content={LocalizationEnum.ASPECT__GRID__TOOLTIP__022}>
                <span style={{ whiteSpace: 'nowrap', marginLeft: marginLeft }}>
                    {(this.props.gridName === 'dashboard-elements-booked' || this.props.gridName === 'dashboard-elements-rent') &&
                    dashboardFooter
                        ? localize(
                              LocalizationEnum.ASPECT__GRID__ROWS_PER_PAGE_WITHOUT_OTHER,
                              'span',
                              { from: from, to: to, total: filtered },
                              true
                          )
                        : localize(LocalizationEnum.ASPECT__GRID__ROWS_PER_PAGE, 'span', { from: from, to: to, total: filtered }, true)}
                </span>
            </HelpTooltip>
        );
    }

    private onSortChanged = (id, desc) => {
        if (this.props.onSortedChange) this.props.onSortedChange(id, desc);
        // let gridData = getGridStorageData(this.props.gridName);
        // gridData.params.sortBy = id;
        // gridData.params.sortOrder = desc ? 'DESC' : 'ASC';
        // setGridStorageData(this.props.gridName, gridData);
        // TODO сохранение настроек
        const gridData = { ...this.props.gridSettings };
        gridData.params = {
            ...gridData.params,
            sortBy: this.props.convertSortBy?.(id) || id,
            sortOrder: desc ? 'DESC' : 'ASC',
        };
        this.props.updateGridSettings({ [this.props.gridName]: gridData });
    };

    private onSelectionChanged = (value) => {
        if (this.props.onSelectionChanged) this.props.onSelectionChanged(value);
        this.setState({
            selectedItems: value,
        });
    };

    onCancelButtonClick = () => {
        this.clearSelection();
    };

    clearSelection = () => {
        if (this.tableRef && this.tableRef.clearSelection) {
            this.tableRef.clearSelection();
        }
    };

    renderFooter = (showPages, withCategories: Boolean) => {
        return this.props.data && this.props.data.length > 0 ? (
            <div className={'rr-grid-footer'} style={withCategories ? { paddingLeft: 24 } : {}}>
                <div className={'rr-grid-footer-left'}>
                    {this.props.bottomPaginationLeftExtraContent && <div>{this.props.bottomPaginationLeftExtraContent}</div>}
                    <div>
                        <SelectScrollController gridName={this.props.gridName}>
                            {({ onPageChange }) => (
                                <Pagination
                                    defaultCurrent={this.state.currentPage}
                                    current={this.state.currentPage}
                                    pageSize={this.state.pageSize}
                                    total={this.props.filtered}
                                    hideOnSinglePage={true}
                                    onChange={(page: number, pageSize) => {
                                        onPageChange();
                                        this.onCurrentPageChanged(page, pageSize);
                                    }}
                                />
                            )}
                        </SelectScrollController>
                    </div>
                </div>
                <div className={'rr-grid-footer-right'}>
                    {showPages ? (
                        <>
                            <span>{localize(LocalizationEnum.ASPECT__GRID__SHOW_PER_PAGE_COUNT_PRE_LABEL)}</span>
                            <CustomSelect
                                allowClear={false}
                                defaultValue={this.state.pageSize}
                                value={this.state.pageSize}
                                className={'rr-select-gray rr-select-round'}
                                onChange={this.onPageSizeChanged}
                            >
                                {this.props.onPageSelect.map((v) => {
                                    return (
                                        <Select.Option value={v} key={v}>
                                            {v} {localize(LocalizationEnum.ASPECT__GRID__RECORDS_IN_RECORDS_COUNT)}
                                        </Select.Option>
                                    );
                                })}
                            </CustomSelect>
                        </>
                    ) : null}
                    {this.renderPaginationInfo(0, true)}
                    {this.props.bottomPaginationRightExtraContent}
                </div>
            </div>
        ) : null;
    };

    setRowProps = (state, rowInfo, column) => {
        if (!rowInfo) return;
        let props = {};

        if (rowInfo.original && (rowInfo.original.archive || rowInfo.original.cancelled)) {
            props['className'] = (props['className'] ? props['className'] + ' ' : '') + 'rr-grid-archive-row';
        }

        if (rowInfo.original && rowInfo.original.disabled) {
            props['className'] = (props['className'] ? props['className'] + ' ' : '') + 'rr-grid-disabled-row';
        }

        if (rowInfo.original && rowInfo.original.__error && !rowInfo.original.isCancelled) {
            props['className'] = (props['className'] ? props['className'] + ' ' : '') + 'rr-grid-error-row';
        }

        if (rowInfo.original && rowInfo.original.__warn && !rowInfo.original.isCancelled) {
            props['className'] = (props['className'] ? props['className'] + ' ' : '') + 'rr-grid-warning-row';
        }

        if (rowInfo.original && rowInfo.original.isCancelled) {
            props['className'] = (props['className'] ? props['className'] + ' ' : '') + 'rr-grid-cancelled-row';
        }

        return props;
    };

    renderCheckboxes = () => {
        const {
            businessAccountId,
            customFieldMarkers,
            excludeColumns,
            gridName,
            gridSettings,
            gridViewMode,
            permissions,
            setGridViewMode,
            updateGridSettings,
        } = this.props;

        const columns = this.props.columns.filter(
            (item) =>
                (!item.dataIndex ||
                    excludeColumns.indexOf(item.dataIndex as string) === -1 ||
                    (excludeColumns.indexOf(item.dataIndex as string) !== -1 && item.showInMenuWhenExcluded)) &&
                item.dataIndex !== 'problemSeverity' &&
                (customFieldMarkers == null ||
                    !Boolean(
                        customFieldMarkers.find((marker) => CustomFieldsUtils.addPrefix(marker.customFieldIndexKey) === item.dataIndex)
                    ))
        );

        return (
            <>
                <div className="rr-calendar-popup-header">Системные поля:</div>
                <CheckboxDragController className={'popover-checkboxes'}>
                    {({ onMouseEnter, refSetter }) => (
                        <>
                            {columns.map((column, index) => {
                                if (!column.dataIndex) return null;

                                let isExcluded = excludeColumns.indexOf(column.dataIndex as string) !== -1 && column.showInMenuWhenExcluded;
                                let checked = column.dontHide || !this.state.hiddenColumns.includes(column.dataIndex as string);
                                if (isExcluded) checked = false;
                                const disabled = column.dontHide || isExcluded;

                                const dataIndex = column.dataIndex as string;

                                const onChange = (event: CheckboxChangeEvent) => {
                                    const { checked } = event.target;
                                    const columns = gridSettings?.columns ? { ...gridSettings.columns } : {};
                                    if (!columns[dataIndex]) columns[dataIndex] = {};

                                    columns[dataIndex].hidden = checked ? undefined : true;
                                    updateGridSettings({
                                        [gridName]: { ...gridSettings, columns },
                                    });

                                    const hiddenColumns = [...this.state.hiddenColumns];

                                    if (!checked) {
                                        // Показываем
                                        if (!hiddenColumns.includes(dataIndex)) {
                                            hiddenColumns.push(dataIndex);
                                        }
                                    } else {
                                        // Прячем
                                        if (hiddenColumns.includes(dataIndex)) {
                                            hiddenColumns.splice(hiddenColumns.indexOf(dataIndex), 1);
                                        }
                                    }
                                    this.setState({ ...this.state, hiddenColumns });
                                };

                                return (
                                    <Checkbox
                                        ref={refSetter(index)}
                                        key={index}
                                        disabled={disabled}
                                        checked={checked}
                                        onChange={onChange}
                                        onMouseEnter={() => onMouseEnter(index)}
                                    >
                                        {column.dataIndex === 'problemSeverity'
                                            ? localize(LocalizationEnum.ASPECT__NOTIFICATION_MESSAGE__TYPE_CODE__PROBLEM)
                                            : column.title}
                                    </Checkbox>
                                );
                            })}
                        </>
                    )}
                </CheckboxDragController>
                {customFieldMarkers?.length && (
                    <>
                        <div className={classNames('rr-calendar-popup-header', 'custom-fields-text-title')} style={{ marginTop: 12 }}>
                            Настраиваемые поля:
                        </div>
                        {customFieldMarkers.length > 0 ? (
                            <CheckboxDragController>
                                {({ onMouseEnter, refSetter }) => (
                                    <>
                                        {customFieldMarkers?.map((marker, index) => {
                                            const dataIndex = CustomFieldsUtils.addPrefix(marker.customFieldIndexKey as string);
                                            const customColumn = this.props.columns.find((column) => column.dataIndex === dataIndex);
                                            if (customColumn == null) return null;

                                            const checked = !this.state.hiddenColumns.includes(customColumn.dataIndex as string);

                                            const onChange = (event: CheckboxChangeEvent) => {
                                                const { checked } = event.target;
                                                const columns = gridSettings.columns ? { ...gridSettings.columns } : {};

                                                if (!columns[dataIndex]) columns[dataIndex] = {};

                                                columns[dataIndex].hidden = checked ? undefined : true;
                                                updateGridSettings({
                                                    [gridName]: { ...gridSettings, columns },
                                                });

                                                const hiddenColumns = [...this.state.hiddenColumns];

                                                if (!checked) {
                                                    // Показываем
                                                    if (!hiddenColumns.includes(dataIndex)) {
                                                        hiddenColumns.push(dataIndex);
                                                    }
                                                } else {
                                                    // Прячем
                                                    if (hiddenColumns.includes(dataIndex)) {
                                                        hiddenColumns.splice(hiddenColumns.indexOf(dataIndex), 1);
                                                    }
                                                }
                                                this.setState({ ...this.state, hiddenColumns });
                                            };

                                            return (
                                                <Checkbox
                                                    ref={refSetter(index)}
                                                    key={index}
                                                    disabled={false}
                                                    checked={checked}
                                                    onChange={onChange}
                                                    onMouseEnter={() => onMouseEnter(index)}
                                                >
                                                    {marker.name}
                                                </Checkbox>
                                            );
                                        })}
                                    </>
                                )}
                            </CheckboxDragController>
                        ) : (
                            <span className={'checkboxes-popover-text'} style={{}}>
                                Настраиваемых полей нет, добавьте в{' '}
                                <ConditionalLink
                                    condition={canUpdateSystemOptions(permissions)}
                                    to={`/${businessAccountId}/settings/customFields`}
                                >
                                    настройках компании
                                </ConditionalLink>
                            </span>
                        )}
                    </>
                )}
                <div className="rr-calendar-popup-header" style={{ marginTop: 12 }}>
                    {localize(LocalizationEnum.PAGE__CALENDAR__POPUP_LABEL_RENDER_TYPE)}
                </div>
                <Radio.Group
                    onChange={(e) => {
                        setGridViewMode(e.target.value);
                    }}
                    value={gridViewMode}
                >
                    <Radio value={'normal'} style={{ display: 'block' }}>
                        {localize(LocalizationEnum.PAGE__CALENDAR__RENDER_TYPE_OPTION_NORMAL)}
                    </Radio>
                    <Radio value={'compact'} style={{ display: 'block', marginTop: 8 }}>
                        {localize(LocalizationEnum.PAGE__CALENDAR__RENDER_TYPE_OPTION_COMPACT)}
                    </Radio>
                </Radio.Group>
            </>
        );
    };

    onOtherFiltersCollapse = (collapsed) => {
        // TODO сохранение настроек
        const gridData = { ...this.props.gridSettings };
        gridData.hideFilters = collapsed;
        this.props.updateGridSettings({ [this.props.gridName]: gridData });
    };

    getChildrenKey = (arr, newArr) => {
        arr.forEach((item) => {
            newArr.push(item);
            this.getChildrenKey(item.props.children, newArr);
        });
        return newArr;
    };

    onCategoriesTreeChanged = (selectedKeys: string[] | undefined, event: React.MouseEvent | AntTreeNodeSelectedEvent | undefined) => {
        const newSelectedKeys: string[] = [...(selectedKeys ?? [])];

        let data: string[] = [];

        if (event) {
            const ctrlKeyPressed = event.nativeEvent.ctrlKey || event.nativeEvent.metaKey;

            if (!ctrlKeyPressed) {
                if ('node' in event && event.node.props.dataRef) {
                    data = [event.node.props.dataRef.key.toString()];
                } else if (
                    selectedKeys?.includes('-1') ||
                    (!selectedKeys?.includes('-1') && this.props.categoriesSelectedKeys?.includes('-1'))
                ) {
                    data = ['-1'];
                }
            } else {
                if (
                    'node' in event &&
                    event.node.props.dataRef &&
                    !this.props.categoriesSelectedKeys?.includes(event.node.props.dataRef.key.toString()) &&
                    !this.props.categoriesSelectedKeys?.includes('-1')
                ) {
                    // Добавление
                    data = [...(this.props.categoriesSelectedKeys ?? []), ...newSelectedKeys];
                } else {
                    // Удаление
                    data = [...newSelectedKeys];
                }
            }
            data = uniq(data);
        }

        if (this.props.categoriesOnSelect) this.props.categoriesOnSelect(data);
        setGridCategoriesStorageData(this.props.gridName, { selectedIds: data });
    };

    categoriesOnReset = () => {
        this.onCategoriesTreeChanged(undefined, undefined);
    };

    public getVisibleColumns = (): string[] => {
        let columns = [...this.props.columns];
        let visibleColumns = columns.map((column) => column.dataIndex);

        this.state.hiddenColumns.forEach((name) => {
            let cIndex = visibleColumns.findIndex((column) => column === name);
            if (cIndex >= 0) {
                visibleColumns.splice(cIndex, 1);
            }
        });

        return visibleColumns as string[];
    };

    fetchEntityErrorCallback = (id: number) => {
        const { filteredParams, deletedCount } = getFilteredParams(this.props.filtersCurrentValues, id);

        if (deletedCount > 0) {
            setGridStorageDataFilters(this.props.gridName, filteredParams);
            this.props.replace(getPathFromState(this.props.location.pathname, '', filteredParams));
        }
    };

    render() {
        let showPages = !this.props.hidePageSizeSelect;

        let columns = [...this.props.columns];

        this.state.hiddenColumns.forEach((name) => {
            let isAllElementsProcessed = false;

            while (!isAllElementsProcessed) {
                const columnIndex = columns.findIndex((column) => column.dataIndex === name);
                if (columnIndex >= 0) {
                    columns.splice(columnIndex, 1);
                } else {
                    isAllElementsProcessed = true;
                }
            }
        });

        let gridData = getGridStorageData(this.props.gridName);
        let gridCategoriesData = getGridCategoriesStorageData(this.props.gridName);
        let defaultExpandedKeys = gridCategoriesData.expandedIds;

        return (
            <div
                className={classNames(
                    'rr-grid',
                    this.props.categoriesFilter && this.state.categoriesFilterCollapsed ? ' rr-grid-with-collapsed-categories' : '',
                    this.props.gridViewMode === 'compact' && 'rr-grid-compact-view'
                )}
            >
                {this.props.filtersData ? (
                    <div className={this.props.filtersClassName}>
                        <DynamicFilters
                            key={this.props.filtersData?.[1]?.fields.length}
                            gridName={this.props.gridName}
                            otherFiltersCollapsed={gridData.hideFilters}
                            onOtherFiltersCollapse={this.onOtherFiltersCollapse}
                            resetFiltersCb={this.props.filtersResetFiltersCb}
                            categoriesFilterSelected={!!this.props.categoriesSelectedKeys}
                            currentValues={this.props.filtersCurrentValues}
                            defaultValues={this.props.filtersDefaultValues}
                            wrappedComponentRef={this.props.filtersGetFiltersFormRef}
                            onChange={this.props.filtersOnChange}
                            data={this.props.filtersData}
                            initialValues={this.props.filtersInitialValues}
                            customParams={this.props.filtersCustomParams}
                            excludeFields={this.props.filtersExcludeFields}
                            canResetFilters={true}
                            dontResetFilters={this.props.filtersDontResetFilters}
                            fetchEntityErrorCallback={this.props.fetchEntityErrorCallback ?? this.fetchEntityErrorCallback}
                            customFieldMarkers={this.props.customFieldMarkers}
                            onHideCustomValue={this.props.filtersOnHideCustomValue}
                            rightBlockExtraContent={this.props.filtersRightBlockExtraContent}
                        />
                    </div>
                ) : null}

                {this.state.selectedItems.length > 0 ? (
                    <Row className={'rr-grid-actions'}>
                        <Col>
                            <span style={{ whiteSpace: 'nowrap' }}>
                                <RoundButton colorScheme={'danger'} onClick={this.onCancelButtonClick} icon={'close'}>
                                    {localize(LocalizationEnum.ASPECT__GRID__CLEAR_SELECTED, 'span')}
                                </RoundButton>
                                <span style={{ marginLeft: 20 }}>
                                    {localize(LocalizationEnum.ASPECT__GRID__SELECTED_COUNT)}: {this.state.selectedItems.length}
                                </span>
                            </span>
                            {this.props.actionButtons ? this.props.actionButtons.map((item) => item) : null}
                        </Col>
                    </Row>
                ) : null}

                {this.props.data ? (
                    <Row className={`rr-grid-header`}>
                        <Col span={20} className={'rr-grid-header-left'}>
                            {this.state.categoriesFilterCollapsed && this.props.categoriesFilter ? (
                                <Icon
                                    className={'rr-grid-categories-icon'}
                                    component={IconSitemap}
                                    onClick={() => {
                                        this.setState({ categoriesFilterCollapsed: !this.state.categoriesFilterCollapsed });
                                    }}
                                />
                            ) : null}
                            {this.state.pageSize >= 50 && (
                                <Row
                                    style={{
                                        marginRight: 21,
                                    }}
                                >
                                    <Pagination
                                        defaultCurrent={this.state.currentPage}
                                        current={this.state.currentPage}
                                        pageSize={this.state.pageSize}
                                        total={this.props.filtered}
                                        hideOnSinglePage={true}
                                        onChange={(page: number, pageSize) => {
                                            this.onCurrentPageChanged(page, pageSize);
                                        }}
                                    />
                                </Row>
                            )}
                            {this.renderPaginationInfo(0)}
                            {this.props.sortingDisabled && (
                                <>
                                    <Icon
                                        component={IconExclamationCircleSolid}
                                        style={{ marginLeft: 25, marginRight: 5, color: '#06B0F1' }}
                                    />
                                    Порядок строк сохранен до очистки выбранных
                                </>
                            )}
                        </Col>
                        <Col span={4} className={'rr-grid-header-right'}>
                            {this.props.exportBlock}
                            <Popover
                                ref={this.checkboxesPopoverRef}
                                trigger={'click'}
                                placement="bottomRight"
                                arrowPointAtCenter
                                overlayClassName={'rr-grid-columns-control-popover-overlay'}
                                content={<div className={'rr-grid-columns-control-popover-content'}>{this.renderCheckboxes()}</div>}
                                onVisibleChange={(visible) => {
                                    if (visible && !this.isCheckboxesOpened) {
                                        setTimeout(() => {
                                            this.isCheckboxesOpened = true;
                                            const textTitleElement = document.querySelector('.custom-fields-text-title');
                                            const checkboxesElement = document.querySelector('.popover-checkboxes');
                                            const checkboxesTextElement = document.querySelector('.checkboxes-popover-text');

                                            if (textTitleElement && checkboxesElement && checkboxesTextElement) {
                                                const textTitleElementWidth = textTitleElement.clientWidth;
                                                const checkboxesElementWidth = checkboxesElement.clientWidth;
                                                (checkboxesTextElement as HTMLDivElement).style.width = `${Math.max(
                                                    textTitleElementWidth,
                                                    checkboxesElementWidth
                                                )}px`;
                                                this.checkboxesPopoverRef.current?.forceUpdate();
                                            }
                                        });
                                    }
                                }}
                            >
                                <Icon className={'rr-grid-settings-icon'} component={IconSliders} />
                            </Popover>
                        </Col>
                    </Row>
                ) : null}

                <Row type={'flex'} className={getGridAnchorClassName(this.props.gridName)}>
                    {this.props.categoriesFilter && !this.state.categoriesFilterCollapsed ? (
                        <Col
                            span={6}
                            xl={5}
                            xxl={4}
                            style={{
                                overflow: 'auto',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            {React.createElement(this.props.categoriesComponent, {
                                selectedKeys: this.props.categoriesSelectedKeys,
                                categoriesType: this.props.categoriesType,
                                onSelect: this.onCategoriesTreeChanged,
                                onReset: this.categoriesOnReset,
                                onCollapse: () => {
                                    this.setState({ categoriesFilterCollapsed: !this.state.categoriesFilterCollapsed });
                                },
                                defaultExpandedKeys: defaultExpandedKeys,
                                onExpand: (keys) => {
                                    let gridData = getGridCategoriesStorageData(this.props.gridName);
                                    gridData.expandedIds = keys;
                                    setGridCategoriesStorageData(this.props.gridName, gridData);
                                    if (this.props.categoriesOnExpand) this.props.categoriesOnExpand(keys);
                                },
                                offsetBottom: this.props.categoriesFilterOffsetBottom,
                                gridName: this.props.gridName,
                            })}
                        </Col>
                    ) : null}
                    <Col
                        span={this.props.categoriesFilter && !this.state.categoriesFilterCollapsed ? 18 : 24}
                        xl={this.props.categoriesFilter && !this.state.categoriesFilterCollapsed ? 19 : 24}
                        xxl={this.props.categoriesFilter && !this.state.categoriesFilterCollapsed ? 20 : 24}
                    >
                        <Table
                            ref={(ref) => (this.tableRef = ref)}
                            columns={columns}
                            excludeColumns={this.props.excludeColumns}
                            data={this.props.data}
                            selectable={this.props.selectable === undefined ? true : this.props.selectable}
                            onSortedChange={this.onSortChanged}
                            onSelectionChanged={this.onSelectionChanged}
                            indexFieldName={this.props.indexFieldName}
                            getRowProps={this.setRowProps}
                            onRowAction={this.props.onRowAction}
                            onRowEditAction={this.props.onRowEditAction}
                            entityType={this.props.entityType}
                            rowPopoverComponent={this.props.rowPopoverComponent}
                            rowPopoverComponentProps={this.props.rowPopoverComponentProps}
                            defaultSortDesc={this.props.defaultSortDesc}
                            defaultSorted={this.props.defaultSorted}
                            onColumnRemove={this.props.onColumnRemove}
                            name={this.props.gridName}
                            rowId={this.props.tableRowId}
                            getUniqId={this.props.getUniqId}
                            alwaysRedraw={!!this.props.alwaysRedraw}
                            page={this.props.currentPage}
                            onPage={this.props.pageSize}
                            selectWidth={40}
                            onRowClick={this.props.onRowClick}
                            onCellAction={this.props.onCellAction}
                            dndProps={this.props.dndProps}
                            sortingDisabled={this.props.sortingDisabled}
                        />
                        {this.props.data && this.props.data.length === 0 ? (
                            <ItemsNotFoundBlock
                                entityType={this.props.entityType === 'element' ? this.props.entityType : undefined}
                                hideArchive={this.props.hideArchive}
                            />
                        ) : null}

                        {this.props.data && this.props.data.length % 2 === 1 ? <div className={'rr-grid-table-separator'}></div> : null}

                        {this.props.categoriesFilter ? this.renderFooter(showPages, true) : null}
                    </Col>
                </Row>
                {!this.props.categoriesFilter ? this.renderFooter(showPages, false) : null}
            </div>
        );
    }
}

_Grid.defaultProps = {
    currentPage: 1,
    pageSize: 20,
    total: 0,
    filtered: -1,
    onPageChanged: undefined,
    onSortedChange: undefined,
    onPageSizeChanged: undefined,
    onSelectionChanged: undefined,
    indexFieldName: 'key',
    excludeColumns: [],
    hidePageSizeSelect: false,
    dynamicColumns: true,
    tableRowId: 'id',
    onPageSelect: DEFAULT_ON_PAGE_SELECT_VALUES,
    alwaysRedraw: false,
};

const mapStateToProps = (storeState: IRootState, ownProps: GridProps) => ({
    permissions: storeState.permissions.permissions,
    businessAccountId: storeState.system.businessAccountId,
    gridViewMode: storeState.userSettings.gridViewMode || 'normal',
    gridSettings: storeState.userSettings.gridSettings[ownProps.gridName] || ({} as GridStorageData),
    location: storeState.router.location,
});

const mapDispatchToProps = { setGridViewMode, updateGridSettings, replace };
type DispatchProps = typeof mapDispatchToProps;

type StateProps = ReturnType<typeof mapStateToProps>;

export const Grid = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(_Grid) as any;

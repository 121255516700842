import React from 'react';
import { BaseSelect, BaseSelectBaseProps, BaseSelectProps, BaseSelectValue, selectValueToNumber } from '../baseSelect';
import { ContactRecord } from '../../../../server';
import { useLazyListContactsQuery } from '../../../../modules/main/renters/tabs/contacts/api/contactsApi';
import { useAppSelector } from '../../../../store/hooks';
import { useBaseSelectFetchRecordsBulk } from '../baseSelectUtils';

export interface ContactSelectProps extends BaseSelectBaseProps<ContactRecord> {
    filters?: Filters;
    getOptionProps?: BaseSelectProps<ContactRecord>['getOptionProps'];
}

interface Filters {
    hideArchive?: boolean;
}

const sortBy: string = 'lastUpdateDate';
const sortOrder = 'DESC';

export const ContactSelectFC = ({ ...props }: ContactSelectProps) => {
    const businessAccountId = useAppSelector((state) => state.system.businessAccountId);
    const [fetchContacts] = useLazyListContactsQuery();
    const fetchContactsBulk = useBaseSelectFetchRecordsBulk<ContactRecord>(
        'contact',
        async (ids) => {
            const response = await fetchContacts(
                {
                    businessAccountId,
                    params: { limit: undefined, search: undefined, page: 1, sortBy, sortOrder, ids: ids.map((id) => +id) },
                },
                false
            );
            return response.data?.entitiesData.records ?? [];
        },
        (record) => record.id
    );

    const fetchRecordsById = async (id: BaseSelectValue) => {
        const ids = (Array.isArray(id) ? id : [id]).map((id) => selectValueToNumber(id));
        try {
            return await fetchContactsBulk(ids);
        } catch (err) {
            throw err;
        }
    };

    const fetchRecords = async (startFrom: number, limit: number, search?: string) => {
        const response = await fetchContacts(
            {
                businessAccountId,
                params: { limit, search, page: startFrom / limit + 1, sortBy, sortOrder, hideArchive: props.filters?.hideArchive },
            },
            false
        );
        if (response.error) throw response.error;
        return response.data?.entitiesData.records ?? [];
    };

    return (
        <BaseSelect
            {...props}
            fetchRecordsById={fetchRecordsById}
            fetchRecords={fetchRecords}
            getRecordId={(record) => record.id}
            dropdownMatchSelectWidth={true}
            getOptionProps={
                props.getOptionProps ||
                ((item) => ({
                    label: item.personFullName,
                    children: item.personFullName,
                }))
            }
        />
    );
};

export class ContactSelect extends React.PureComponent<ContactSelectProps> {
    render() {
        return <ContactSelectFC {...this.props} />;
    }
}

import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import { ProductCompositionStaticGrid } from './components/grid/StaticGrid/ProductCompositionStaticGrid';
import { KitIndicatorsBlock } from '../../kit-indicators-block';
import { KitInfoRead } from '../../../../../../server';
import { canViewFinancialDataSelector } from '../../../../../../shared/reducers/permissions.reducer';
import { useAppSelector } from '../../../../../../store/hooks';
import { useGridStatistics } from './hooks/useGridStatistics';
import './ProductComposition.less';
import { ProductCompositionEditGrid } from './components/grid/EditGrid/ProductCompositionEditGrid';
import { useGridData } from './hooks/useGridData';
import { ProductCompositionColumnsObj } from './columns/productCompositionColumns.types';

interface ProductCompositionProps {
    kit: KitInfoRead;
}

export const PRODUCT_COMPOSITION_GRID_NAME = 'kit-composition';

export const ProductComposition: FC<ProductCompositionProps> = memo((props) => {
    const { kit } = props;
    const [isEdit, setIsEdit] = useState(false);
    const { gridData } = useGridData(kit);
    const [editData, setEditData] = useState<ProductCompositionColumnsObj[]>(gridData);

    useEffect(() => {
        if (!isEdit) {
            setEditData(gridData);
        }
    }, [gridData, isEdit]);

    const canViewFinancialData = useAppSelector(canViewFinancialDataSelector);

    const gridStatistics = useGridStatistics(kit);

    const editStat = useMemo(() => {
        const instanceCount = editData.reduce((acc, it) => acc + it.instanceCount, 0);
        const price = editData.reduce((acc, it) => acc + it.pricePerShift * it.instanceCount, 0);
        const finalTotalPrice = editData.reduce((acc, it) => acc + it.finalTotalPrice, 0);
        const discount = price - finalTotalPrice;
        const discountPercent = finalTotalPrice === 0 || price - finalTotalPrice === 0 ? 0 : 100 - (finalTotalPrice / price) * 100;

        return { instanceCount, price, finalTotalPrice, discount, discountPercent };
    }, [editData]);

    const excludeColumns = kit.mainMemberOptionEnabled ? [] : ['main'];
    if (!canViewFinancialData) {
        excludeColumns.push('pricePerShift', 'discount', 'finalInstancePrice', 'finalTotalPrice');
    }

    return (
        <>
            <div className="grid-container">
                {isEdit ? (
                    <ProductCompositionEditGrid editData={editData} setEditData={setEditData} state={kit.stateCode} setIsEdit={setIsEdit} excludeColumns={excludeColumns} />
                ) : (
                    <ProductCompositionStaticGrid kit={kit} setIsEdit={setIsEdit} excludeColumns={excludeColumns} />
                )}
                <KitIndicatorsBlock
                    instanceCount={isEdit ? editStat.instanceCount : gridStatistics.instanceCount}
                    price={isEdit ? editStat.price : gridStatistics.price}
                    discount={isEdit ? editStat.discount : gridStatistics.discount}
                    discountPercent={isEdit ? editStat.discountPercent : gridStatistics.discountPercent}
                    finalTotalPrice={isEdit ? editStat.finalTotalPrice : gridStatistics.finalTotalPrice}
                />
            </div>
        </>
    );
});

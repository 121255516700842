import { WrappedFormUtils } from 'antd/lib/form/Form';
import { FormFieldsGroup, FormItemType } from '../../../../../components/dynamicForm/DynamicForm';
import { EntityRemoteSelect } from '../../../../../components/select/EntityRemoteSelect';
import { LocalizationEnum, localize } from '../../../../../localization';
import { InstanceStateCodeEnum } from '../../../../../server';
import { getStoreState } from '../../../../../..';
import { canViewFinancialData } from '../../../../../shared/util/permissionUtils';
import {
    ProductVariantSelectAndCreate,
    ProductVariantSelectAndCreateProps,
} from '../../../../../components/v2/select/productVariantSelect/productVariantSelectAndCreate';
import { createHiddenField } from '../../../../../components/modalForm/utils';
import { debounce } from 'lodash';

export const formFields: FormFieldsGroup[] = [
    {
        fields: [
            {
                label: 'Количество создаваемых экземпляров',
                id: 'addedCount' as const,
                type: FormItemType.Integer,
                minLength: 1,
                required: true,
            },
            {
                label: 'Номер первого создаваемого экземпляра',
                id: 'startNumber' as const,
                type: FormItemType.Integer,
                minLength: 1,
                required: true,
            },
            {
                label: 'Префикс для заполнения уникальных полей',
                id: 'prefix' as const,
                type: FormItemType.String,
                maxLength: 27,
                required: true,
                validationFunction: debounce((_: string, value: string, cb: Function, form: WrappedFormUtils) => {
                    const barcodeChecked = form.getFieldValue('params').includes('setBarcode');
                    const matches = /^[0-9a-zA-Z-_]+$/.test(value);
                    cb(!barcodeChecked ? undefined : matches ? undefined : 'Поле должно содержать только символы: A-Z, a-z, 0-9, - и _');
                }, 500),
            },
            {
                label: 'Параметры',
                id: 'params',
                type: FormItemType.CheckboxGroup,
                values: () => {
                    return [
                        {
                            name: 'Наименование',
                            value: 'name',
                            disabled: true,
                        },
                        {
                            name: 'Штриховой код',
                            value: 'setBarcode',
                        },
                        {
                            name: 'Инвентарный номер',
                            value: 'setInventoryNumber',
                        },
                    ];
                },
                className: 'rr-checkbox-group-vertical',
            },
        ],
    },
    {
        title: 'Общие значения',
        fields: [
            createHiddenField('productId'),
            createHiddenField('variants'),
            {
                label: LocalizationEnum.ASPECT__ENTITY_TYPE__VARIANT,
                id: 'selectedVariantId' as const,
                type: FormItemType.Component,
                required: true,
                component: ProductVariantSelectAndCreate,
                componentProps: (form: WrappedFormUtils): ProductVariantSelectAndCreateProps => ({
                    productId: form.getFieldValue('productId'),
                    onDataChange: (data) => {
                        if (data && data[0]) {
                            form.setFieldsValue({ variantId: data[0].businessVersion });
                        }
                    },
                }),
                visible: (getFieldValue) => getFieldValue('variants'),
            },
            {
                label: 'Продавец',
                id: 'seller' as const,
                type: FormItemType.Component,
                required: !true,
                component: EntityRemoteSelect,
                componentProps: (param) => {
                    return {
                        operationName: 'listRenters',
                        nameField: 'shortName',
                        filters: ['archive;EQ;false'],
                    };
                },
                placeholder: 'Выберите продавца',
                visible: (getFieldValue) => {
                    const stateCode: InstanceStateCodeEnum | undefined = getFieldValue('stateCode');
                    return stateCode !== InstanceStateCodeEnum.SUPPLIERACTIVE && stateCode !== InstanceStateCodeEnum.SUPPLIERDECOMMISSIONED;
                },
            },
            {
                label: LocalizationEnum.PAGE__CARD_POPUP__CARRYING_VALUE,
                id: 'carryingValue' as const,
                type: FormItemType.Money,
                step: 50,
                minLength: 0,
                validationFunction: (fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
                    cb(value >= 10000000000 ? localize(LocalizationEnum.PAGE__PRODUCTS__FORM__VALIDATION__PRICE_TOO_HIGH) : undefined);
                },
                visible: (form) => {
                    const p = getStoreState().permissions.permissions;
                    return canViewFinancialData(p);
                },
            },
            {
                label: LocalizationEnum.PAGE__PRODUCTS__PURCHASE_PRICE,
                id: 'purchaseCost' as const,
                type: FormItemType.Money,
                step: 50,
                minLength: 0,
                defaultValue: undefined,
                validationFunction: (fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
                    cb(value >= 10000000000 ? localize(LocalizationEnum.PAGE__PRODUCTS__FORM__VALIDATION__PRICE_TOO_HIGH) : undefined);
                },
                visible: () => {
                    const p = getStoreState().permissions.permissions;
                    return canViewFinancialData(p);
                },
            },
            {
                label: LocalizationEnum.PAGE__PRODUCTS__CONDITION_DESCRIPTION,
                id: 'stateDescription' as const,
                type: FormItemType.Text,
                maxLength: 2000,
            },
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__COMMENT,
                id: 'comment' as const,
                type: FormItemType.Text,
                maxLength: 2000,
            },
        ],
    },
];

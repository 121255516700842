import { Card, Col, Icon, Row } from 'antd';
import { RoundButton } from '../../../../components';
import { IconTrash } from '../../../../components/icons';
import { useAppSelector } from '../../../../store/hooks';
import { IRootState } from '../../../../shared/reducers';
import { showConfirm } from '../../../../components/confirm/showConfirm';
import { getIntl } from '../../../../App';
import { showNotification } from '../../../../components/notification/showNotification';

const UserProfileInterface = () => {
    const login = useAppSelector((state: IRootState) => state.oidc.user?.profile.sub);
    const intl = getIntl();

    const resetHandler = async () => {
        if (!login) {
            showNotification('error', 'Не удалось определить пользователя');
            return;
        }

        const yes = await showConfirm(intl, 'Вы уверены, что хотите сбросить все сохраненные настройки интерфейса?');

        if (yes) {
            const keys = Object.keys(localStorage);

            keys.forEach((key) => {
                if (key.includes(login)) {
                    localStorage.removeItem(key);
                }
            });
            window.location.reload();
        }
    };

    return (
        <>
            <Card
                className={'rr-card'}
                bodyStyle={{ display: 'none' }}
                style={{ borderBottom: '1px solid #cccccc' }}
                title={<span className="card-title">Настройки интерфейса</span>}
                extra={<div style={{ height: 40 }} />}
            />

            <Card bodyStyle={{ padding: '0px', flexGrow: 1 }}>
                <Row>
                    <Col className={'rr-form-item user-profile-col'} style={{ padding: '32px' }}>
                        <div className={'rr-label'}>Сбросить все сохраненные в текущем браузере настройки</div>
                        <RoundButton colorScheme={'DELETE'} onClick={resetHandler} style={{ marginTop: 8 }}>
                            <Icon component={IconTrash} />
                            Сбросить настройки
                        </RoundButton>
                    </Col>
                </Row>
            </Card>
        </>
    );
};
export default UserProfileInterface;

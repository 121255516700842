import React, {ReactNode} from 'react';
import { BaseSelect, BaseSelectBaseProps, BaseSelectValue } from '../baseSelect';
import {PaymentMethodRecord, PricingSchemeRecord, serverApi} from '../../../../server';
import { useAppSelector } from '../../../../store/hooks';
import { DeepKeys } from '@tanstack/react-table';
import { businessAccountIdSelector } from '../../../../shared/reducers/system.reducer';
import {Icon} from "antd";
import {PricingSchemeMaps} from "../../../../core/utils/pricingSchemeUtils";
import {IconClose, IconTags} from "../../../icons";

export interface PricingSchemeSelectProps extends BaseSelectBaseProps<PricingSchemeRecord> {
    forKit?: boolean;
}

const sortBy: DeepKeys<PaymentMethodRecord> = 'lastUpdateDate';
const sortOrder = 'DESC';

const PricingSchemeSelectFC = ({ ...props }: PricingSchemeSelectProps) => {
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    const getEntitiesById = async (id: BaseSelectValue) => {
        const _id = +(Array.isArray(id) ? id[0] : id);
        const response = await serverApi.getPricingSchemeById(businessAccountId, _id);
        const data = response.data;

        return [{
            id: data.id,
            businessVersion: data.businessVersion,
            businessAccountId: data.businessAccountId,
            name: data.name,
            typeCode: data.typeCode,
            steps: data.steps,
            linkedProductCount: data.linkedProductCount,
            linkedKitCount: data.linkedKitCount,
            linkedProfessionsCount: data.linkedProfessionCount,
            lastUpdateDate: data.lastUpdateDate,
            lastUpdateAuthorId: data.lastUpdateAuthorId,
            lastUpdateAuthorFullName: data.lastUpdateAuthorFullName
        }];
    };

    const getEntitiesList = async (startFrom: number, limit: number, search?: string) => {
        const response = await serverApi.listPricingSchemes(businessAccountId, search, limit, startFrom, sortBy, sortOrder, undefined);
        return response.data.records;
    };

    const renderItem = (record:PricingSchemeRecord) => {
        let icon:ReactNode;
        let label:string|undefined;
        if(props.forKit && record.id === -1){
            icon = <Icon component={IconTags} style={{color: '#aaaaaa', fontSize: 21, marginRight: 5}} />;
            label = 'Ценовые схемы по продуктам';
        }else if(props.forKit && record.id === -2){
            icon = <Icon component={IconClose} style={{color: '#F12143', fontSize: 21, marginRight: 5}} />;
            label = 'Без ценовых схем';
        }else{
            icon = <Icon component={PricingSchemeMaps.pricingSchemeTypeCodeToIconMap[record.typeCode]} className={'price-scheme-icon'}/>;
            label = record.name;
        }
        return (
            <div style={{display: 'flex', alignItems: 'center'}}>
                {icon}
                <span>{label}</span>
            </div>
        );
    }

    return (
        <BaseSelect
            {...props}
            fetchRecordsById={getEntitiesById}
            fetchRecords={getEntitiesList}
            getRecordId={(record) => record.id}
            getOptionProps={(record) => ({
                label: renderItem(record),
                children: (
                    renderItem(record)
                ),
                style: props.forKit && record.id === -2 ? {borderBottom: '1px solid #CCCCCC'} : undefined
            })}
            showSearch={true}
            extraData={
                props.forKit ? [
                    {id: -1, label: ''},
                    {id: -2, label: ''}
                ] : undefined
            }
        />
    );
};

export class PricingSchemeSelect extends React.Component<PricingSchemeSelectProps> {
    render() {
        return <PricingSchemeSelectFC {...this.props} />;
    }
}

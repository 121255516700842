import { FAILURE, REQUEST, SUCCESS } from '../../../../../shared/reducers/action-type.util';
import { serverApi, ServerError, SubrentRecord, SubrentRecordList, WorkflowDirective } from '../../../../../server';
import { showNotification } from '../../../../../components/notification/showNotification';
import { IRootState } from '../../../../../shared/reducers';
import { getDatesFromParamsString, getServerError, getStateFromPath2, test1 } from '../../../../../shared/util/utils';
import { LOCATION_CHANGE } from 'connected-react-router';
import { LocalizationEnum, localizeIntl } from '../../../../../localization';
import { gridDataChangedSignal } from '../../../../../components/controllers/scroll/SelectScrollController/SelectScrollController';
import { getSubrentsSortBy } from '../utils/getSubrentsSortBy';
import { getSubrentsFilters } from '../utils/getSubrentsFilters';

export const ACTION_TYPES = {
    LOAD_ENTITIES: 'subrents/LOAD_ENTITIES',
    RESET: 'subrents/RESET',
    RESET_ENTITIES: 'subrents/RESET_ENTITIES',
    CHANGE_ARCHIVE: 'subrents/CHANGE_ARCHIVE',
    CHANGE_STATUS: 'subrents/CHANGE_STATUS',
};

export const subrentsInitialParamsState = {
    search: undefined as string | undefined,
    sortBy: undefined as string | undefined,
    sortOrder: undefined as 'ASC' | 'DESC' | undefined,
    status: undefined as string | undefined,
    page: 1,
    limit: 20,
    hideArchive: /*false*/ undefined as boolean | undefined,
    shortage: undefined as boolean | undefined,
    delay: undefined as boolean | undefined,
    supplierId: undefined as string | undefined,
    startDate: [undefined, undefined] as (number | undefined)[] | undefined,
    problem: undefined as string | undefined,
    assigneeId: undefined as string | undefined,
    debtToSupplierSum: undefined as number[] | undefined,
};

const initialState = {
    loading: false,
    loadingError: undefined as undefined | ServerError,
    entities: null as Array<SubrentRecord> | null,
    filteredCount: 0,
    smartFiltersOpened: false,
    params: {
        ...subrentsInitialParamsState,
    },
};

export type SubrentsState = Readonly<typeof initialState>;

export default (state: SubrentsState = initialState, action): SubrentsState => {
    switch (action.type) {
        case LOCATION_CHANGE:
            let pathName =
                action.payload && action.payload.location && action.payload.location.pathname ? action.payload.location.pathname : '';
            let params = getStateFromPath2(action.payload.location.search);

            if (
                /\/\d+\/subrent\/shippings\/?/.test(pathName) ||
                (/\/\d+\/crm\/counterparties\/(\d+)/.test(pathName) && params.tab === 'shippings')
            ) {
                let arr = /\/\d+\/crm\/counterparties\/(\d+)/.exec(pathName);
                if (arr && arr[1]) {
                    params.supplierId = +arr[1];
                }

                return {
                    ...state,
                    params: {
                        ...state.params,
                        search: params.search !== undefined ? '' + params.search : subrentsInitialParamsState.search,
                        sortBy: params.sortBy !== undefined ? params.sortBy : subrentsInitialParamsState.sortBy,
                        sortOrder: params.sortOrder !== undefined ? params.sortOrder : subrentsInitialParamsState.sortOrder,
                        status: params.status !== undefined ? params.status : subrentsInitialParamsState.status,
                        page: params.page !== undefined ? +params.page : subrentsInitialParamsState.page,
                        limit: params.limit !== undefined ? +params.limit : subrentsInitialParamsState.limit,
                        hideArchive: params.hideArchive !== undefined ? params.hideArchive : subrentsInitialParamsState.hideArchive,
                        shortage: params.shortage !== undefined ? params.shortage : subrentsInitialParamsState.shortage,
                        delay: params.delay !== undefined ? params.delay : subrentsInitialParamsState.delay,
                        supplierId: params.supplierId !== undefined ? params.supplierId : subrentsInitialParamsState.supplierId,
                        startDate: getDatesFromParamsString(params.startDate, subrentsInitialParamsState.startDate), //params.startDate !== undefined ? params.startDate : initialParamsState.startDate,
                        problem: params.problem !== undefined ? '' + params.problem : subrentsInitialParamsState.problem,
                        assigneeId: params.assigneeId !== undefined ? params.assigneeId : subrentsInitialParamsState.assigneeId,
                        debtToSupplierSum: test1(params.debtToSupplierSum, subrentsInitialParamsState.debtToSupplierSum)?.map((price) => price),
                    },
                };
            }
            return state;
        case REQUEST(ACTION_TYPES.CHANGE_ARCHIVE):
            return {
                ...state,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.CHANGE_ARCHIVE):
            return {
                ...state,
                loading: false,
            };
        case SUCCESS(ACTION_TYPES.CHANGE_ARCHIVE):
            return {
                ...state,
                loading: !false,
            };
        case REQUEST(ACTION_TYPES.CHANGE_STATUS):
            return {
                ...state,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.CHANGE_STATUS):
            return {
                ...state,
                loading: false,
            };
        case SUCCESS(ACTION_TYPES.CHANGE_STATUS):
            return {
                ...state,
                loading: !false,
            };
        case REQUEST(ACTION_TYPES.LOAD_ENTITIES):
            return {
                ...state,
                loadingError: undefined,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.LOAD_ENTITIES):
            console.dir(action.payload);
            return {
                ...state,
                loadingError: getServerError(action.payload),
                loading: false,
            };
        case SUCCESS(ACTION_TYPES.LOAD_ENTITIES):
            gridDataChangedSignal();

            return {
                ...state,
                loading: false,
                entities: (action.payload.data as SubrentRecordList).records,
                filteredCount: (action.payload.data as SubrentRecordList).listAttributes.filteredCount,
            };
        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };
        case ACTION_TYPES.RESET_ENTITIES:
            return {
                ...state,
                entities: null,
            };
        default:
            return state;
    }
};

// Actions

export const loadSubrents = (intl, businessAccountId: number) => {
    return (dispatch, getState) => {
        let state: SubrentsState = (getState() as IRootState).subrents;
        let { params } = state;

        const filters: string[] = getSubrentsFilters(params);

        const sortBy = getSubrentsSortBy(params);

        return dispatch({
            type: ACTION_TYPES.LOAD_ENTITIES,
            payload: serverApi.listSubrents(
                businessAccountId,
                params.limit,
                (params.page - 1) * params.limit,
                undefined,
                sortBy,
                params.sortOrder,
                params.search,
                { query: { filters: filters } }
            ),
        }).then((result) => {
            if (result instanceof Error) {
                showNotification('error', localizeIntl(intl, LocalizationEnum.PAGE__SHIPPINGS__POPUP_NOTIFICATIONS__GET_LIST_FAILED));
            } else {
            }
        });
    };
};

export const setArchiveState = (intl, businessAccountId: number, directives: Array<WorkflowDirective>) => {
    return (dispatch) => {
        return dispatch({
            type: ACTION_TYPES.CHANGE_ARCHIVE,
            payload: serverApi.transitSubrentWorkflowBulk(businessAccountId, { directives: directives }),
        }).then((result) => {
            if (result instanceof Error) {
                // В архив не изменен
                // TODO Очень информативная ошибка
                showNotification('error', localizeIntl(intl, LocalizationEnum.ASPECT__GLOBAL__ERROR));
            } else {
                // В архив изменен
                showNotification(
                    'success',
                    localizeIntl(
                        intl,
                        directives.length > 1
                            ? LocalizationEnum.PAGE__SHIPPINGS__POPUP_NOTIFICATIONS__SHIPMENTS_UPDATED
                            : LocalizationEnum.PAGE__SHIPPINGS__POPUP_NOTIFICATIONS__SHIPMENT_UPDATED
                    )
                );
                dispatch(loadSubrents(intl, businessAccountId));
            }
        });
    };
};

export const setStatus = (intl, businessAccountId: number, directives: Array<WorkflowDirective>) => {
    return (dispatch) => {
        return dispatch({
            type: ACTION_TYPES.CHANGE_STATUS,
            payload: serverApi.transitSubrentWorkflowBulk(businessAccountId, { directives: directives }),
        }).then((result) => {
            if (result instanceof Error) {
                // Статус поставки не изменен
                // TODO Очень информативная ошибка
                showNotification('error', localizeIntl(intl, LocalizationEnum.ASPECT__GLOBAL__ERROR));
            } else {
                // Статус поставки изменен
                showNotification(
                    'success',
                    localizeIntl(
                        intl,
                        directives.length > 1
                            ? LocalizationEnum.PAGE__SHIPPINGS__POPUP_NOTIFICATIONS__SHIPMENTS_STATE_UPDATED
                            : LocalizationEnum.PAGE__SHIPPINGS__POPUP_NOTIFICATIONS__SHIPMENT_STATE_UPDATED
                    )
                );
                dispatch(loadSubrents(intl, businessAccountId));
            }
        });
    };
};

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});

export const resetEntities = () => ({
    type: ACTION_TYPES.RESET_ENTITIES,
});

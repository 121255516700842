import { Select } from 'antd';
import classNames from 'classnames';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { BaseSelectOptionProps } from '../select/baseSelect';
import DetailedOption from './DetailedOption';

export interface IDetailedSelectColumn {
    key: string;
    width?: number | string;
    secondary?: boolean;
    render?: (value: unknown) => string | number;
    className?: string;
}

interface IDetailedSelectProps<T extends {}> {
    id: string;
    placeholder?: string;
    items: T[];
    allowClear?: boolean;
    getKey: (item: T) => string | number;
    columns: IDetailedSelectColumn[];
    value: number[];
    onChange: (value: number[]) => void;
    details?: {
        clickHandler: (id: number | undefined) => void;
        text: string;
        getKey?: (item: T) => number | undefined;
    };
    multiple?: boolean;
    className?: string;
    loading?: boolean;
    selectedId?: number;
    onSearch?: (value: string) => void;
    getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
    getOptionProps?: (record: T) => BaseSelectOptionProps;
    hideArchive?: boolean;
    getOptionPrefix?: (record: T) => ReactNode | null;
    disabled?: boolean;
}

const DetailedSelect = <T extends {}>({
    id,
    placeholder,
    items,
    allowClear,
    getKey,
    columns,
    value,
    onChange,
    multiple,
    details,
    loading,
    className,
    selectedId,
    onSearch,
    getPopupContainer,
    getOptionProps,
    hideArchive,
    getOptionPrefix,
    disabled,
}: IDetailedSelectProps<T>) => {
    const [selectOpen, setSelectOpen] = useState(!!selectedId);
    const dropDownRef = useRef<HTMLDivElement>(null);
    const selectRef = useRef<Select<number[]>>(null);

    const clickHandler = useCallback(
        (e: MouseEvent) => {
            if (
                !(
                    dropDownRef.current &&
                    (e.composedPath().includes(dropDownRef.current) || e.composedPath().includes(selectRef.current?.['rcSelect'].rootRef))
                )
            ) {
                setSelectOpen(selectedId ? true : false);
            }
        },
        [selectedId]
    );

    const dropdownVisibleChangeHandler = useCallback(
        (open) => {
            setSelectOpen(selectedId ? true : open);
        },
        [selectedId]
    );
    const dropdownRender = useCallback((menu: ReactNode) => <div ref={dropDownRef}>{menu}</div>, []);

    useEffect(() => {
        document.addEventListener('click', clickHandler);

        return () => document.removeEventListener('click', clickHandler);
    }, [clickHandler]);

    return (
        <Select
            id={id}
            ref={selectRef}
            filterOption={false}
            optionFilterProp="children"
            showArrow={true}
            mode={multiple ? 'multiple' : 'default'}
            placeholder={placeholder}
            className={classNames('rr-select rr-select-detailed', className)}
            onChange={onChange}
            allowClear={allowClear}
            value={value}
            loading={loading}
            open={selectOpen}
            showSearch={onSearch ? true : undefined}
            onSearch={onSearch}
            dropdownClassName={'rr-select-dropdown rr-select-detailed-dropdown'}
            onDropdownVisibleChange={dropdownVisibleChangeHandler}
            dropdownMatchSelectWidth={false}
            dropdownRender={dropdownRender}
            getPopupContainer={getPopupContainer}
            defaultActiveFirstOption={false}
            disabled={disabled}
        >
            {items.map((item) => {
                const optionProps = getOptionProps?.(item);
                return (
                    <Select.Option
                        style={{ display: hideArchive && 'archive' in item && item.archive ? 'none' : undefined }}
                        key={getKey(item)}
                        value={getKey(item)}
                        {...(optionProps ?? {})}
                    >
                        <DetailedOption item={item} columns={columns} getKey={getKey} details={details} getOptionPrefix={getOptionPrefix} />
                    </Select.Option>
                );
            })}
        </Select>
    );
};
export default DetailedSelect;

import React, {ReactNode} from 'react';
import {connect} from 'react-redux';
import {IRootState} from '../../../../shared/reducers';
import {injectIntl, WrappedComponentProps} from 'react-intl';
import {Button, Icon, Modal, Tooltip} from 'antd';
import {IconExclamationCircleSolid, IconExclamationSolid} from '../../../../components/icons';
import {LocalizationEnum, localize} from '../../../../localization';
import {OperationElement} from '../reducers/operationForm.reducer';
import {Link} from 'react-router-dom';
import {linkToCalendar} from '../../../../shared/util/createLinkPath';
import {getShiftCountFromDates} from '../../../../shared/util/utils';
import moment from 'moment';
import {formatShiftCount} from '../../../../shared/util/utils4';
import {CalendarPageTabsEnum} from "../../../../shared/constants/tabEnums";
import './OperationProblemModal.less';

export interface ErrorItem {
    message?: ReactNode;
    elements?: OperationElement[];
    type?: OperationProblemModalType;
    tooltip?: any;
    problemField?: 'instanceCount' | 'shiftCount' | 'instanceIds' | 'endDate' | 'startDate';
}

interface IOperationProblemModalProps extends StateProps, DispatchProps, WrappedComponentProps {
    type?: OperationProblemModalType;
    isCorrection?: boolean;
    visible?: boolean;
    onOk?: (e: React.MouseEvent<HTMLElement>) => void;
    onCancel?: (e: React.MouseEvent<HTMLElement>) => void;
    errors?: ErrorItem[];
    okButtonDisabledMessage?: ReactNode;
}

export enum OperationProblemModalType {
    ERROR = 'error',
    WARNING = 'warning',
}

class Component extends React.PureComponent<IOperationProblemModalProps> {

    constructor(props) {
        super(props);
    }

    render() {
        console.log('OperationProblemModal render()');
        const props = this.props;
        let color = props.type === OperationProblemModalType.ERROR ? '#f8516c' : '#ff9924';

        const printErrorsBlock = (error: ErrorItem, lastElement: boolean) => {
            return (
                <div style={{ marginBottom: lastElement ? 0 : 28 }}>
                    <div style={{ fontSize: 18, fontWeight: 'bold', marginBottom: 5 }}>{error.message}</div>

                    {/*{error.elements ? (*/}
                    {/*    <div*/}
                    {/*        style={{*/}
                    {/*            marginBottom: 5,*/}
                    {/*            fontSize: 15,*/}
                    {/*            paddingLeft: 20,*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        {localize(LocalizationEnum.ASPECT__GLOBAL__NOMENCLATURE)}:*/}
                    {/*    </div>*/}
                    {/*) : null}*/}

                    {error.elements
                        ? error.elements.map((item, index) => {
                              if (error.problemField === 'instanceCount') {
                                  let startDate = item.rentPeriodStartDate.getTime();
                                  let endDate = item.rentPeriodEndDate.getTime();

                                  let count = !props.projectTemplate
                                      ? -(item.unavailableInstanceCount || 0)
                                      : -(item.unavailableInstanceCount || 0); //(item.stockInstanceCount - item.instanceCount);
                                  // Показываем экземпляры
                                  return (
                                      <div key={index} style={{ paddingLeft: 20 }}>
                                          <Link
                                              className={'product-link'}
                                              to={`/${props.businessAccountId}/inventory/products/${item.productId}`}
                                          >
                                              <span style={{ fontWeight: 'bold', marginBottom: 4 }}>
                                                  {item.productShortName}
                                              </span>
                                              {item.variantId ? (
                                                  <span style={{ marginBottom: 4 }}>{`: ${item.variantName}`}</span>
                                              ) : null}
                                          </Link>
                                          <span style={{ fontWeight: 'bold', marginLeft: 12, color: color }}>{count}</span>
                                          <Link
                                              style={{ marginLeft: 4, color: color, textDecoration: 'underline' }}
                                              to={linkToCalendar({
                                                  productId: item.productId,
                                                  variantId: item.variantId,
                                                  screenLeft: startDate,
                                                  screenRight: endDate,
                                              }, CalendarPageTabsEnum.ACCESSIBILITY_MAP)}
                                          >
                                              {localize(LocalizationEnum.ASPECT__PLURAL__INSTANCE, undefined, { value: count })}
                                          </Link>
                                      </div>
                                  );
                              } else if (error.problemField === 'shiftCount') {
                                  let shiftCount =
                                      (item.shiftCount || 0) -
                                      getShiftCountFromDates(
                                          item.pricingSchemeId || undefined,
                                          moment(item.rentPeriodStartDate),
                                          moment(item.rentPeriodEndDate),
                                          item.shiftLengthInMinutes,
                                          props.shiftCountRoundingType
                                      );

                                  return (
                                      <div key={index} style={{ paddingLeft: 20 }}>
                                          <Link
                                              className={'product-link'}
                                              to={`/${props.businessAccountId}/inventory/products/${item.productId}`}
                                          >
                                              <span style={{ fontWeight: 'bold', marginBottom: 4 }}>
                                                  {item.productShortName}
                                              </span>
                                              {item.variantId ? (
                                                  <span style={{ marginBottom: 4 }}>{`: ${item.variantName}`}</span>
                                              ) : null}
                                          </Link>
                                          <span style={{ fontWeight: 'bold', marginLeft: 12, color: color }}>
                                              {shiftCount > 0 ? '+' : ''}
                                              {formatShiftCount(shiftCount)}
                                          </span>
                                          <span style={{ marginLeft: 4, color: color }}>
                                              {localize(LocalizationEnum.ASPECT__PLURAL__SHIFT, undefined, { value: shiftCount })}
                                          </span>
                                      </div>
                                  );
                              } else if (error.problemField === 'instanceIds') {
                                  let anonymous = item.instanceCount - (item.instanceIds ? item.instanceIds.length : 0);

                                  return (
                                      <div key={index} style={{ paddingLeft: 20 }}>
                                          <Link
                                              className={'product-link'}
                                              to={`/${props.businessAccountId}/inventory/products/${item.productId}`}
                                          >
                                              <span style={{fontWeight: 'bold', marginBottom: 4 }}>
                                                  {item.productShortName}
                                              </span>
                                              {item.variantId ? (
                                                  <span style={{marginBottom: 4 }}>{`: ${item.variantName}`}</span>
                                              ) : null}
                                          </Link>
                                          <span style={{fontWeight: 'bold', marginLeft: 12, color: color }}>
                                              {anonymous}?
                                          </span>
                                          <span style={{ marginLeft: 4, color: color }}>
                                              {localize(LocalizationEnum.ASPECT__PLURAL__ANONYMOUS_INSTANCE, undefined, {
                                                  value: anonymous,
                                              })}
                                          </span>
                                      </div>
                                  );
                              } else if (error.problemField === 'startDate' || error.problemField === 'endDate') {
                                  return (
                                      <div key={index} style={{ paddingLeft: 20 }}>
                                          <Link
                                              className={'product-link'}
                                              to={`/${props.businessAccountId}/inventory/products/${item.productId}`}
                                          >
                                              <span style={{fontWeight: 'bold', marginBottom: 4 }}>
                                                  {item.productShortName}
                                              </span>
                                              {item.variantId ? (
                                                  <span style={{marginBottom: 4 }}>{`: ${item.variantName}`}</span>
                                              ) : null}
                                          </Link>
                                      </div>
                                  );
                              }
                          })
                        : null}
                </div>
            );
        };

        return (
            <Modal
                visible={props.visible}
                className={'rr-operation-problem-modal'}
                width={560}
                title={
                    <>
                        {props.type === OperationProblemModalType.ERROR ? (
                            <Icon component={IconExclamationSolid} style={{ color: color }} />
                        ) : (
                            <Icon component={IconExclamationCircleSolid} style={{ color: color }} />
                        )}
                        <span>
                            {props.type === OperationProblemModalType.ERROR
                                ? localize(LocalizationEnum.ASPECT__NOTIFICATION_MESSAGE__TYPE_CODE__PROBLEM)
                                : localize(LocalizationEnum.ASPECT__NOTIFICATION_MESSAGE__TYPE_CODE__WARNING)}
                        </span>
                    </>
                }
                onCancel={props.onCancel}
                footer={
                    <>
                        <Button type={'primary'} onClick={props.onCancel}>
                            {localize(LocalizationEnum.ASPECT__GLOBAL__CANCEL)}
                        </Button>
                        {props.type === OperationProblemModalType.WARNING /*|| props.isCorrection*/ ? (
                            <Tooltip title={props.okButtonDisabledMessage}>
                                <Button className={!!props.okButtonDisabledMessage ? 'rr-operation-problem-modal-okButton-disbaled' : undefined} disabled={!!props.okButtonDisabledMessage} type="link" style={{ color: color }} onClick={props.onOk}>
                                    {localize(LocalizationEnum.PAGE__NEW_OPERATION__MODAL__PROBLEM_ON_SUBMIT__BUTTON__SUBMIT_OPERATION)}
                                </Button>
                            </Tooltip>
                        ) : null}
                    </>
                }
            >
                <>
                    {props.errors?.map((error, index, arr) => {
                        return printErrorsBlock(error, index === arr.length);
                    })}
                </>
            </Modal>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => {
    return {
        businessAccountId: storeState.system.businessAccountId,
        projectTemplate: storeState.operationForm.projectTemplate,
        shiftCountRoundingType: storeState.businessAccountPreferences.preferences?.shiftCountRoundingType,
    };
};

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const OperationProblemModal = connect(mapStateToProps, mapDispatchToProps)(injectIntl(Component));

import { useState } from 'react';
import { useScrollEffect } from '../../../../../../core/hooks/listeners/useScrollEffect';

export const useVisibleOnScroll = (height: number) => {
    const [visible, setVisible] = useState(false);

    useScrollEffect(() => {
        setVisible(window.scrollY >= height);
    });

    return visible;
};

import { FormFieldsGroup, FormItemType, IFormField } from '../../../../../../components/dynamicForm/DynamicForm';
import { FormUtils, TypedFormField, WrappedFormUtilsTyped } from '../../../../../../core/utils/formUtils';
import { ContactLinkObjWrite, LocationInfoCreate } from '../../../../../../server';
import { LocalizationEnum } from '../../../../../../localization';
import debounce from 'lodash/debounce';
import { validateField } from '../../../../../../shared/util/validateField';
import { ContactsEditor } from '../../../../../../components/contactsEditor/contactsEditor';
import { createHiddenField } from '../../../../../../components/modalForm/utils';

export const shortNameField = {
    label: LocalizationEnum.ASPECT__GRID__COLUMN__NAME,
    id: 'shortName' as const,
    type: FormItemType.String,
    required: true,
    maxLength: 60,
    validationFunction: debounce((fieldName: string, value: any, cb: Function, form: WrappedFormUtilsTyped<FormValues>) => {
        const ignoreCurrentId = form.getFieldValue('isEdit') ? (form.getFieldValue('id') as number) : undefined;
        validateField('location', fieldName, value, undefined, ignoreCurrentId, 'Наименование уже используется', cb);
    }, 500),
} satisfies IFormField;

export const addressField = {
    label: 'Адрес',
    id: 'address' as const,
    type: FormItemType.Address,
    required: true,
    maxLength: 500,
} satisfies IFormField;

interface FormValues extends LocationInfoCreate {
    isEdit?: boolean;
}

export const formFields: FormFieldsGroup[] = FormUtils.typedFormFields<FormValues>([
    {
        fields: [createHiddenField('id') as TypedFormField<FormValues>, createHiddenField('isEdit') as TypedFormField<FormValues>],
    },
    {
        fields: [shortNameField, addressField],
    },
    {
        title: 'Координаты',
        fields: [
            {
                label: 'Широта',
                id: 'latitude',
                type: FormItemType.String,
                defaultValue: '',
                validationRules: [
                    {
                        pattern: '^-?([0-8]?[0-9]|90)(\\.[0-9]{1,10})?$',
                        message: 'Невалидное значение',
                    },
                ],
            },
            {
                label: 'Долгота',
                id: 'longitude',
                type: FormItemType.String,
                defaultValue: '',
                validationRules: [
                    {
                        pattern: '^-?([0-9]{1,2}|1[0-7][0-9]|180)(\\.[0-9]{1,10})?$',
                        message: 'Невалидное значение',
                    },
                ],
            },
        ],
    },
    {
        fields: [
            {
                id: 'contacts',
                type: FormItemType.Component,
                component: ContactsEditor,
                componentProps: {
                    selectColumns: [
                        { key: 'personFullName', width: 240 },
                        { key: 'phoneNumber', width: 150 },
                        { key: 'email', width: 200, secondary: true },
                    ],
                },
                validationRules: [
                    {
                        validator: (rule, value: ContactLinkObjWrite[] | undefined, cb) => {
                            if (value && value.some((item) => item.contactId === undefined && item.position)) cb('Контакт не выбран');
                            else cb();
                        },
                    },
                ],
            },
        ],
    },
    {
        fields: [
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__COMMENT,
                id: 'comment',
                type: FormItemType.Text,
                maxLength: 2000,
            },
        ],
    },
]);

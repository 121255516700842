import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ElementsCalendarFilters } from '../filters/elementsCalendarFilters.types';
import { serverApi } from '../../../../../../server';
import { getCurrentTimezoneUTCOffset } from '../../../../../../utils/timeUtils/getCurrentTimezoneUTCOffset';
import { showNotification } from '../../../../../../components/notification/showNotification';
import { LocalizationEnum, localizeIntl } from '../../../../../../localization';
import { downloadDataFromExcel } from '../../../../../../shared/util/downloadDataFromExcel';
import { ElementsCalendarApiUtils } from '../api/elementsCalendar.api.utils';
import { defaultScreenLeft, defaultScreenRight } from '../../../components/Calendar/utils/data/calendarBoundaries';
import { CalendarBoundaries } from '../../../components/Calendar/types/params';
import moment from "moment";

export interface ElementsCalendarState extends CalendarBoundaries {}

const initialState: ElementsCalendarState = {
    screenLeft: defaultScreenLeft(),
    screenRight: defaultScreenRight(),
};

const elementsCalendarSlice = createSlice({
    name: 'elementsCalendar',
    initialState,
    reducers: {
        setCalendarBoundaries: (
            state,
            action: PayloadAction<{
                screenLeft?: number;
                screenRight?: number;
            }>
        ) => {
            const { screenLeft, screenRight } = action.payload;

            if (screenLeft) state.screenLeft = screenLeft;
            if (screenRight) state.screenRight = screenRight;
        },
    },
});

export const { setCalendarBoundaries } = elementsCalendarSlice.actions;
export default elementsCalendarSlice.reducer;

export const exportExcel = async (
    intl,
    businessAccountId: number,
    params: ElementsCalendarFilters,
    expandKits: boolean,
    subrentModuleEnabled: boolean
) => {
    let filters: string[] = ElementsCalendarApiUtils.getFilters(params, subrentModuleEnabled);
    let sortBy = ElementsCalendarApiUtils.getSortBy(params.group);
    let sortOrd: 'ASC' | 'DESC' | undefined;
    if (sortBy !== 'lastActivityDate') sortOrd = 'ASC';
    else sortOrd = 'DESC';

    console.log('PPP', params);

    serverApi
        .exportBusinessAccountElementsList(
            businessAccountId,
            ['All'],
            expandKits,
            getCurrentTimezoneUTCOffset(),
            1000,
            0,
            undefined,
            sortBy,
            sortOrd,
            params.search,
            params.capture !== 'all' ? moment(params.screenLeft ?? defaultScreenLeft()).utc().toDate() : undefined,
            { query: { filters: filters }, responseType: 'blob' }
        )
        .then((result) => {
            if (result instanceof Error) {
                showNotification('error', localizeIntl(intl, LocalizationEnum.ASPECT__EXCEL_EXPORT__EXPORT_TO_EXCEL_FAILED));
            } else {
                const fileName = localizeIntl(intl, LocalizationEnum.ASPECT__EXCEL_EXPORT__DEFAULT_EXCEL_EXPORT_FILE_NAME) as string;
                downloadDataFromExcel(result.data, fileName);
            }
        });
};

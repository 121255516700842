import { FormFieldsGroup, FormItemType, SelectItem } from '../../../../../../../components/dynamicForm/DynamicForm';
import { FormUtils, TypedFormField } from '../../../../../../../core/utils/formUtils';
import { CrewMemberInfoRead, CrewMemberStateCodeEnum, ProfessionStateCodeEnum } from '../../../../../../../server';
import { LocalizationEnum, localize } from '../../../../../../../localization';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { Dot } from '../../../../../../../components/dot/dot';
import React from 'react';
import {
    ContactSelectAndCreate,
    ContactSelectAndCreateProps,
} from '../../../../../../../components/v2/select/contactSelect/contactSelectAndCreate';
import { getStoreState } from '../../../../../../../../index';
import { canUpdateFinancialData, canViewFinancialData } from '../../../../../../../shared/util/permissionUtils';
import { ProfessionSelectProps } from '../../../../../../../components/v2/select/professionSelect/ProfessionSelect';
import { createHiddenField } from '../../../../../../../components/modalForm/utils';
import { ProfessionSelectAndCreate } from '../../../../../../../components/v2/select/professionSelect/ProfessionSelectAndCreate';
import { IconHardHatSolid } from '../../../../../../../components/icons';
import { Icon } from 'antd';

export const crewMemberModalFields: FormFieldsGroup[] = FormUtils.typedFormFields<CrewMemberInfoRead>([
    {
        fields: [
            createHiddenField('isEdit') as TypedFormField<CrewMemberInfoRead>,
            {
                label: 'Контакт проектного работника',
                id: 'contact',
                type: FormItemType.Component,
                component: ContactSelectAndCreate,
                required: true,
                componentProps: (): ContactSelectAndCreateProps => ({
                    filters: { hideArchive: true },
                    getOptionPrefix: (record) =>
                        record.isCrewMember ? <Icon component={IconHardHatSolid} style={{ marginRight: 4 }} /> : null,
                    getOptionProps: (record) => {
                        const isRecordBlocked = Boolean(record.isCrewMember);

                        return {
                            disabled: isRecordBlocked,
                        };
                    },
                }),
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__STATUS,
                placeholder: localize(LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER),
                id: 'stateCode',
                type: FormItemType.Select,
                defaultValue: CrewMemberStateCodeEnum.ACTIVE,
                required: true,
                disabled: (getFieldValue) => {
                    return Boolean(getFieldValue('isEdit'));
                },
                values: (form) => {
                    const values: SelectItem[] = [
                        {
                            name: localize(LocalizationEnum.ASPECT__STATE_CODE__PRODUCT__ACTIVE),
                            value: CrewMemberStateCodeEnum.ACTIVE,
                        },
                        {
                            name: localize(LocalizationEnum.ASPECT__STATE_CODE__RENTER__BLOCKED),
                            value: CrewMemberStateCodeEnum.BLOCKED,
                        },
                    ];

                    return values.map((item) => ({
                        ...item,
                        name: (
                            <>
                                <Dot className={`rr-status-bg-` + item.value} style={{ marginRight: 10 }} /> {item.name}
                            </>
                        ),
                    }));
                },
            },
            {
                label: 'Специальности',
                id: 'professions',
                type: FormItemType.Component,
                component: ProfessionSelectAndCreate,
                componentProps: (form: WrappedFormUtils): ProfessionSelectProps => ({
                    multiple: true,
                    showSearch: true,
                    getOptionProps: (record) => {
                        const isRecordBlocked = record.stateCode === ProfessionStateCodeEnum.BLOCKED;
                        const statusType = record.stateCode;

                        return {
                            disabled: isRecordBlocked,
                            label: (
                                <>
                                    {isRecordBlocked && <Dot className={`rr-status-bg-${statusType ?? ''}`} style={{ marginRight: 10 }} />}
                                    {record.shortName}
                                </>
                            ),
                        };
                    },
                }),
            },
        ],
    },
    {
        visible: () => {
            const p = getStoreState().permissions.permissions;
            return canViewFinancialData(p) && canUpdateFinancialData(p);
        },
        fields: [
            {
                label: 'Базовая ставка за смену',
                id: 'defaultRates.ratePerShift',
                type: FormItemType.Money,
                step: 50,
                required: true,
                minLength: 0,
                defaultValue: 0,
                validationFunction: (fieldName: string, value: any, cb: Function, form) => {
                    cb(value >= 10000000000 ? localize(LocalizationEnum.PAGE__PRODUCTS__FORM__VALIDATION__PRICE_TOO_HIGH) : undefined);
                },
                disabled: () => {
                    const p = getStoreState().permissions.permissions;
                    return canViewFinancialData(p) && !canUpdateFinancialData(p);
                },
            },
            {
                label: 'Базовая ставка за час',
                id: 'defaultRates.ratePerHour',
                type: FormItemType.Money,
                step: 50,
                required: true,
                minLength: 0,
                defaultValue: 0,
                validationFunction: (fieldName: string, value: any, cb: Function, form) => {
                    cb(value >= 10000000000 ? localize(LocalizationEnum.PAGE__PRODUCTS__FORM__VALIDATION__PRICE_TOO_HIGH) : undefined);
                },
                disabled: () => {
                    const p = getStoreState().permissions.permissions;
                    return canViewFinancialData(p) && !canUpdateFinancialData(p);
                },
            },
        ],
    },
    {
        fields: [
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__DESCRIPTION,
                id: 'description',
                type: FormItemType.RichText,
                validationRules: [
                    {
                        max: 5000,
                        message: (
                            <>
                                {localize(LocalizationEnum.ASPECT__FORMS__VALIDATION__FIELD_LENGTH_MAXIMUM)} 5000{' '}
                                {localize(LocalizationEnum.ASPECT__FORMS__VALIDATION__INCLUDING_FORMATTING_TAGS)}
                            </>
                        ),
                    },
                ],
            },
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__COMMENT,
                id: 'comment',
                type: FormItemType.Text,
                maxLength: 2000,
            },
        ],
    },
]);

import { injectIntl, WrappedComponentProps } from 'react-intl';
import { ModalFormNEW, ModalFormNEWProps } from '../../../../../components/modalForm/ModalFormNEW';
import { IRootState } from '../../../../../shared/reducers';
import { updateModalFilters } from '../../../../../shared/reducers/userSettings/userSettings.reducer';
import { connect, ConnectedProps } from 'react-redux';
import { goBack } from 'connected-react-router';
import { formFields } from './add-prouct-instance-bulk-fields';
import { createInstancesBulk, resetError, resetInstance } from '../reducers/product-instanсe.reducer';
import { InstanceInfoCreateBulk, InstanceTrackingTypeCodeEnum } from '../../../../../server';
import { showConfirm } from '../../../../../components/confirm/showConfirm';
import { LocalizationEnum, localizeIntl } from '../../../../../localization';
import { getStateFromPath2 } from '../../../../../shared/util/utils';

interface InstanceBulkModalProps extends ModalFormNEWProps, WrappedComponentProps {
    variant?: any;
}

export class _AddProductInstanceBulkModal extends ModalFormNEW<InstanceBulkModalProps & StoreProps> {
    static defaultProps = {
        formFields,
    };

    onOk = (data) => {
        if ('errors' in data || !this.props.entity) return;

        const createInfo: InstanceInfoCreateBulk = {
            addedCount: data.addedCount,
            startNumber: data.startNumber,
            prefix: data.prefix,
        };

        if (data.carryingValue) {
            createInfo.carryingValue = data.carryingValue;
        }

        if (data.purchaseCost) {
            createInfo.purchaseCost = data.purchaseCost;
        }

        if (data.seller && data.seller.data?.id) {
            createInfo.sellerId = data.seller.data.id;
        }

        if (data.comment) {
            createInfo.comment = data.comment;
        }

        if (data.stateDescription) {
            createInfo.stateDescription = data.stateDescription;
        }

        if (data.selectedVariantId) {
            createInfo.variantId = data.selectedVariantId;
        }

        const setBarcode = (data.params as string[]).includes('setBarcode');
        const setInventoryNumber = (data.params as string[]).includes('setInventoryNumber');

        this.props.createInstancesBulk(
            this.props.intl,
            this.props.businessAccountId,
            this.props.entity.id,
            createInfo,
            setInventoryNumber,
            setBarcode
        );
    };

    onCancel = async (isFieldsTouched: boolean) => {
        let yes =
            !isFieldsTouched ||
            (await showConfirm(this.props.intl, localizeIntl(this.props.intl, LocalizationEnum.ASPECT__MODAL__ABORT_EDIT)));
        if (yes) {
            this.props.goBack();
            this.props.resetError();
            this.props.resetInstance();
        }
    };

    getInitialValues = () => {
        const defaultValues = {
            productId: this.props.entity!.id,
            instanceTrackingTypeCode: this.props.entity!.instanceTrackingTypeCode,
            instanceEdit: this.props.editMode,
            params: ['name'],
        };

        const variants =
            this.props.entity!.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTBULK ||
            this.props.entity!.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED;

        const initialValues = {
            variantId: { key: undefined, label: undefined },
            listVariants: getStateFromPath2(this.props.location.search).variantId
                ? getStateFromPath2(this.props.location.search).variantId
                : this.props.variant,
            variants,
        };

        return {
            ...defaultValues,
            ...initialValues,
        };
    };
}

const mapStateToProps = (storeState: IRootState) => ({
    entity: storeState.product.entity,
    businessAccountId: storeState.system.businessAccountId,
    updating: storeState.changeInstanceStatus.updating,
    updatingError: storeState.productInstance.updatingError,
    modalFilters: storeState.userSettings.modalFilters,
    listVariants: storeState.conditionProductInstance.entity,
    location: storeState.router.location,
});

const mapDispatchToProps = {
    updateModalFilters,
    goBack,
    createInstancesBulk,
    resetError,
    resetInstance,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type StoreProps = ConnectedProps<typeof connector>;

export const AddProductInstanceBulkModal = connector(injectIntl(_AddProductInstanceBulkModal));

import {ColumnTypes, TableColumn} from '../../../../../components/grid/Table';
import {Button, Icon, Popover} from 'antd';
import {
    IconArrowCircleDownSolid,
    IconArrowCircleUpSolid,
    IconClose,
    IconControlPointDuplicateBlack,
    IconEdit,
    IconEllipsisHSolid,
    IconReply,
} from '../../../../../components/icons';
import {Link} from 'react-router-dom';
import React from 'react';
import {formatDate} from '../../../../../shared/util/formatDate';
import {OperationElementCreatePopover} from '../elementCreatePopover/OperationElementCreatePopover';
import {
    cancelOperationElement,
    InstancePropertiesEntityRecordCustom,
    moveElementFromKit,
    OperationElement,
    removeInstances, removeRentElement,
    removeTrackedInstances,
    setDiscountForElement,
    setElementIsNowEditing,
    setKeepLeftover,
    setPriceForElement,
    setShiftCountForElement,
} from '../../reducers/operationForm.reducer';
import {EntityType, getBusinessAccountId, getStore, getStoreState} from '../../../../../../index';
import {ElementsElementPopover} from '../../../elements/elements-element-popover';
import {InstanceTrackingTypeCodeEnum, OperationTypeCodeEnum, ProjectStateCodeEnum, RentStateCodeEnum} from '../../../../../server/api';
import {HelpTooltip} from '../../../../../components/helpTooltip/HelpTooltip';
import {LocalizationEnum, localize} from '../../../../../localization';
import {getActionsColumnWidth, MAX_WIDTH} from '../../../../../config/constants';
import {tableCellMoneyRenderer} from '../../../../../components/grid/renderers/tableCellMoneyRenderer';
import {OperationFormKitRemovePopover} from './OperationFormKitRemovePopover';
import moment from 'moment';
import {getShiftCountFromDates, isDefined} from '../../../../../shared/util/utils';
import {ProductVariantPopover} from "../../../inventory/variants/components/productVariantPopover/productVariantPopover";
import {VariantSelectPopover} from '../components/variantSelectPopover/variantSelectPopover';
import {KitAddElementPopover} from '../kit-add-element-popover/KitAddElementPopover';
import {
    canAddNewProductsToOperation2,
    canChangeKeepLeftover,
    canChangeVariantOnElement,
    canCopyElementInOperation,
    canEditOperationElementParams,
} from '../../utils/utils';
import {GridInstanceCountBlock} from '../../components/gridInstanceCountBlock/GridInstanceCountBlock';
import {OperationFormUtils} from '../../utils/operationFormUtils';
import {OperationElementCancelToggleButton} from '../../components/operationElementCancelToggleButton/OperationElementCancelToggleButton';
import {PricingSchemePricePopover} from '../../../settings/pricingSchemes/pricingSchemePricePopover';
import {InlineNumberInput} from '../../../../../components/inlineInputs/InlineNumberInput/solid/InlineNumberInput';
import {InlinePeriodPicker} from './InlinePeriodPicker';
import {tableCellPercentsRenderer} from '../../../../../components/grid/renderers/tableCellPercentsRenderer';
import {tableCellShiftCountRenderer} from '../../../../../components/grid/renderers/tableCellShiftCountRenderer';
import {GridColumnCreator} from '../../../../../components/grid/gridColumnCreator';
import {PricingSchemeMaps} from '../../../../../core/utils/pricingSchemeUtils';
import {RentElementsGridItem} from "../../../../../types";
import {tableCellStatusRenderer} from "../../../../../components/grid/renderers/tableCellStatusRenderer";

const getDiscountForKit = (elements: OperationElement[], price: number) => {
    let pricePerShift = 0;
    let finalPricePerShift = 0;
    elements.forEach((row: OperationElement) => {
        pricePerShift += Math.round((row.pricePerShift || 0) * row.instanceCount * (row.shiftCount || 0));
        finalPricePerShift += Math.round((row.finalPricePerShift || 0) * row.instanceCount * (row.shiftCount || 0));
    });
    return pricePerShift === 0 ? 0 : (100 - finalPricePerShift * 100 / pricePerShift);
};

const removeIcon = () => (
    <span>
        <HelpTooltip placement={'topRight'} content={LocalizationEnum.PAGE__NEW_OPERATION__ELEMENTS__ACTION__DELETE__TOOLTIP}>
            <Icon className={'rr-grid-table-remove-icon'} style={{ cursor: 'pointer' }} component={IconClose} />
        </HelpTooltip>
    </span>
);

const removeIconDisabled = () => (
    <span>
        <HelpTooltip placement={'topRight'} content={LocalizationEnum.PAGE__NEW_OPERATION__ELEMENTS__ACTION__DELETE__TOOLTIP}>
            <Icon className={'rr-grid-table-remove-icon'} style={{ opacity: 0.4, cursor: 'default' }} component={IconClose} />
        </HelpTooltip>
    </span>
);

const editIcon = () => (
    <span>
        <HelpTooltip placement={'topRight'} content={LocalizationEnum.PAGE__NEW_OPERATION__ELEMENTS__ACTION__EDIT__TOOLTIP}>
            <Icon className={'rr-grid-table-edit-icon'} style={{ cursor: 'pointer' }} component={IconEdit} />
        </HelpTooltip>
    </span>
);

// GRID COLUMNS
export const operationFormElementsListColumns: TableColumn<OperationElement>[] = [
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__NUMBER),
        dataIndex: 'id',
        type: ColumnTypes.CustomRender,
        minWidth: 90,
        maxWidth: MAX_WIDTH(90),
        resizable: false,
        render: (value: any, rowData: OperationElement) => {
            return rowData && rowData.id > -1 ? (
                <ElementsElementPopover record={rowData} hideActionsButton={true}>
                    <span
                        className={'rr-grid-id'}
                        style={{
                            fontWeight: 700,
                            cursor: rowData.kitId ? undefined : 'pointer',
                        }}
                    >
                        #{rowData.numberInActivityFrame}
                    </span>
                </ElementsElementPopover>
            ) : (
                <>
                    <span className={'rr-dot'} style={{ backgroundColor: '#34bfa3' }}></span>
                    <span style={{ color: '#34bfa3', fontWeight: 600 }}> Новое</span>
                </>
            );
        },
    },
    GridColumnCreator.createImageColumn({ dataIndex: 'mainImage' }),
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__NAME), //localize(LocalizationEnum.ASPECT__GRID__COLUMN__PRODUCT),
        dataIndex: 'productShortName',
        type: ColumnTypes.CustomRender,
        ...GridColumnCreator.getColumnSizesForEntity('product'),
        resizable: true,
        render: (value: any, rowData: OperationElement) =>
            rowData ? (
                <div
                    className={'rr-grid-shortName-cell'}
                    style={{ paddingLeft: 10 + (rowData.parentId ? 22 : 0) }}
                    title={rowData.productShortName}
                >
                    <Link
                        className={rowData.id > -1 ? undefined : 'new-value'}
                        to={`/${getBusinessAccountId()}/inventory/${rowData.kitId ? 'kits' : 'products'}/${
                            rowData.kitId ? rowData.kitId : rowData.productId
                        }`}
                    >
                        {rowData.mainKitMember ? '★ ' : null}
                        {rowData.productShortName}
                        {/*rowData.partOfKit ?
                    <span style={{fontWeight: 400}}> (Часть)</span> : null*/}
                    </Link>
                </div>
            ) : null,
        dontHide: true,
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__VARIANT),
        dataIndex: 'variantName',
        type: ColumnTypes.CustomRender,
        ...GridColumnCreator.getColumnSizesForEntity('variant'),
        resizable: true,
        render: (value: any, rowData: OperationElement) => {
            const operationTypeCode = getStoreState().operationForm.typeCode;
            const canChangeVariant = rowData.variantId && canChangeVariantOnElement(operationTypeCode);

            return rowData && rowData.productId && rowData.variantId ? (
                <div style={{ textAlign: 'left' }}>
                    <span className={'rr-operationForm-elementsList-variant'}>
                        {
                            <ProductVariantPopover
                                productId={rowData.productId}
                                variantId={rowData.variantId}
                            >
                                <span className={'app-link-underline'}>{rowData.variantName}</span>
                            </ProductVariantPopover>
                        }
                        {canChangeVariant && <VariantSelectPopover element={rowData}></VariantSelectPopover>}
                    </span>
                </div>
            ) : null;
        },
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__QUANTITY),
        dataIndex: 'instanceCount',
        type: ColumnTypes.CustomRender,
        minWidth: 160,
        resizable: false,
        sortable: true,
        className: 'rr-grid-table-cell-fix',
        render: (value: any, rowData: OperationElement) => {
            let anonymousInstanceCount = 0;
            let hasError = rowData.problems && rowData.problems.includes('instanceCount');
            let hasWarning = !hasError && rowData.warnings && rowData.warnings.includes('instanceCount');
            const state = getStoreState();
            const typeCode = state.operationForm.typeCode;
            let instanceCount = 0;
            let unavailableInstanceCount = 0;

            let plusButtonDisabled = false;
            let minusButtonDisabled = false;

            if (!canAddNewProductsToOperation2(state.operationForm, rowData) && ((rowData.leftoverInstanceCount === 0) || (rowData.leftoverInstanceCount !== 0 && rowData.instanceCountOriginal === rowData.instanceCount))) {
                plusButtonDisabled = true;
            }

            if (!rowData['subRows']) {
                if (
                    rowData.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED ||
                    rowData.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED
                )
                    anonymousInstanceCount = rowData.anonymousInstanceCount !== undefined ? rowData.anonymousInstanceCount : 0;
                instanceCount = rowData.instanceCount;
                unavailableInstanceCount = rowData.unavailableInstanceCount || 0;
            } else {
                (rowData['subRows'] as OperationElement[]).forEach((element) => {
                    if (
                        element.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED ||
                        element.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED
                    )
                        anonymousInstanceCount += element.anonymousInstanceCount !== undefined ? element.anonymousInstanceCount : 0;
                    instanceCount = 1; //+= element.instanceCount;
                    unavailableInstanceCount += element.unavailableInstanceCount || 0;
                });
            }

            if (
                rowData.instanceTrackingTypeCode == InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED ||
                rowData.instanceTrackingTypeCode == InstanceTrackingTypeCodeEnum.INSTANCETRACKED
            ) {
                //anonymousInstanceCount = 0;
                //unavailableInstanceCount = 0;
            }

            // Для несчетных не выводим кол-во анонимных
            if (
                rowData.instanceTrackingTypeCode == InstanceTrackingTypeCodeEnum.BULK ||
                rowData.instanceTrackingTypeCode == InstanceTrackingTypeCodeEnum.VARIANTBULK
            ) {
                anonymousInstanceCount = 0;
            }

            /*
            return rowData ? (
                <div
                    className={'rr-grid-table-cell-fix-container' + (hasError ? ' rr-grid-table-error-cell' : (hasWarning ? ' rr-grid-table-warning-cell' : ''))}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div className={rowData.id > -1 ? 'main-value' : 'new-value'} style={{fontWeight: 600}}>
                            {
                                instanceCount
                            }
                            {
                                (unavailableInstanceCount && unavailableInstanceCount > 0 ? ` (-${unavailableInstanceCount})` : '')
                            }
                            {
                                anonymousInstanceCount > 0 ? ` (${anonymousInstanceCount}?)` : null
                            }
                        </div>
                    </div>
                </div>
            ) : null;
            */

            const addInstance = () => {
                OperationFormUtils.addItemFromOperationElementTab(rowData);
            };

            const removeInstance = () => {
                if (rowData.keepLeftover || (instanceCount > 1 && !rowData.keepLeftover)) {
                    OperationFormUtils.removeItemFromElements(rowData);
                } else {
                    if (rowData.canBeCancelled) {
                        getStore().dispatch(cancelOperationElement(rowData.id, true));
                    } else {
                        OperationFormUtils.removeItemFromElements(rowData);
                    }
                }
            };

            // TODO EDIT !!!
            if(typeCode === OperationTypeCodeEnum.EDIT){
                if(instanceCount <= 1 && rowData.id > 0) minusButtonDisabled = true;
                if(rowData.stateCodeNew && [RentStateCodeEnum.RENT, RentStateCodeEnum.RETURNED, RentStateCodeEnum.RETURNEDBROKEN, RentStateCodeEnum.LOSTDURINGRENT, RentStateCodeEnum.SUBRENT, RentStateCodeEnum.SUBRENTRETURNEDTOSHIPPER].includes(rowData.stateCodeNew)){
                    minusButtonDisabled = true;
                }
            }

            return rowData ? (
                <div
                    className={
                        'rr-grid-table-cell-fix-container' +
                        (!rowData.isCancelled
                            ? hasError
                                ? ' rr-grid-table-error-cell'
                                : hasWarning
                                ? ' rr-grid-table-warning-cell'
                                : ''
                            : '')
                    }
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className={rowData.id > -1 ? 'main-value' : 'new-value'} style={{ fontWeight: 600 }}>
                            <GridInstanceCountBlock
                                hideControls={rowData.isCancelled || !!rowData.kitId}
                                instanceCount={instanceCount}
                                unavailableInstanceCount={unavailableInstanceCount}
                                anonymousInstanceCount={anonymousInstanceCount}
                                addInstance={!plusButtonDisabled ? addInstance : undefined}
                                removeInstance={!minusButtonDisabled ? removeInstance : undefined}
                            />
                        </div>
                    </div>
                </div>
            ) : null;
        },
    },
    {
        title: 'Кол-во чужих',
        dataIndex: 'subrentedInstanceCount',
        type: ColumnTypes.CustomRender,
        minWidth: 100,
        //maxWidth: MAX_WIDTH(100),
        resizable: false,
        defaultHidden: true,
        render: (value: any, rowData: OperationElement) => {
            if (value) return value;
            else return '';
        },
    },
    {
        title: 'Остаток',
        dataIndex: 'leftoverInstanceCount',
        type: ColumnTypes.CustomRender,
        minWidth: 120,
        //maxWidth: MAX_WIDTH(120),
        resizable: false,
        render: (value: any, rowData: OperationElement) => {
            if (rowData.id < 0 || rowData.kitId) {
                return;
            } else {
                let leftoverInstanceCount = rowData.leftoverInstanceCount;
                let leftoverAnonymousInstanceCount = rowData.leftoverAnonymousInstanceCount;
                let withLeftover = !!rowData.keepLeftover;
                const typeCode = getStoreState().operationForm.typeCode;
                let _canChangeKeepLeftover = canChangeKeepLeftover(typeCode);
                if (rowData.isCancelled) _canChangeKeepLeftover = false;

                return (
                    <>
                        {leftoverInstanceCount > 0 ? (
                            withLeftover ? (
                                <span
                                    className={'rr-operationForm-elementsList-leftover'}
                                    style={{
                                        fontWeight: 700,
                                        color: '#343f9a',
                                        paddingLeft: _canChangeKeepLeftover ? 26 : 0,
                                    }}
                                >
                                    {leftoverInstanceCount}
                                    {leftoverAnonymousInstanceCount > 0 ? (
                                        <span style={{ fontWeight: 500, opacity: 0.5 }}>{`(${leftoverAnonymousInstanceCount}?)`}</span>
                                    ) : (
                                        ''
                                    )}
                                    {_canChangeKeepLeftover && (
                                        <Icon
                                            className={'rr-operationForm-elementsList-leftover--removeIcon'}
                                            component={IconClose}
                                            onClick={() => {
                                                getStore().dispatch(setKeepLeftover(rowData.id, false));
                                            }}
                                        />
                                    )}
                                </span>
                            ) : (
                                <span
                                    className={'rr-operationForm-elementsList-leftover'}
                                    style={{
                                        fontWeight: 700,
                                        color: '#a0a0a0',
                                        textDecoration: 'line-through',
                                        paddingLeft: _canChangeKeepLeftover ? 26 : 0,
                                    }}
                                >
                                    &nbsp;{leftoverInstanceCount}&nbsp;
                                    {leftoverAnonymousInstanceCount > 0 ? (
                                        <span style={{ fontWeight: 500, opacity: 0.5 }}>{`(${leftoverAnonymousInstanceCount}?)`}</span>
                                    ) : (
                                        ''
                                    )}
                                    {_canChangeKeepLeftover && (
                                        <Icon
                                            className={'rr-operationForm-elementsList-leftover--leaveIcon'}
                                            component={IconReply}
                                            onClick={() => {
                                                getStore().dispatch(setKeepLeftover(rowData.id, true));
                                            }}
                                        />
                                    )}
                                </span>
                            )
                        ) : (
                            <span style={{ color: '#aaaaaa' }}>Нет</span>
                        )}
                    </>
                );
            }
        },
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__EXTERNAL_CODE),
        dataIndex: 'externalCode',
        type: ColumnTypes.CustomRender,
        minWidth: 110,
        //maxWidth: MAX_WIDTH(110),
        resizable: true,
        render: (value: any, rowData: InstancePropertiesEntityRecordCustom) => {
            const businessAccountId = getStoreState().system.businessAccountId;
            return rowData.externalCode ? (
                <div className={'rr-grid-text-cell-center'}>
                    <Link to={`/${businessAccountId}/inventory/products/${rowData.productId}`} style={{ color: 'unset' }}>
                        {rowData.externalCode}
                    </Link>
                </div>
            ) : null;
        },
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__PRICE_INSTANCE_PER_SHIFT),
        dataIndex: 'effectivePrice',
        type: ColumnTypes.CustomRender,
        className: 'money',
        minWidth: 160,
        //maxWidth: MAX_WIDTH(140),
        resizable: true,
        render: (value: any, rowData: OperationElement) => {
            if (rowData) {
                let price = rowData.pricePerShift || 0;
                if (rowData.kitId && rowData['subRows']) {
                    price = 0;
                    rowData['subRows'].forEach((row: OperationElement) => {
                        price += (row.pricePerShift||0) * row.instanceCount;
                    });
                }
                let pricingScheme = !rowData.kitId ? rowData.pricingSchemeId : undefined;
                const editDisabled =
                    !!rowData.kitId ||
                    (rowData.canBeCancelled && rowData.isCancelled === true) ||
                    canEditOperationElementParams(getStoreState().operationForm.typeCode);
                return (
                    <div style={{display: 'flex', alignItems: 'center', columnGap: 2, justifyContent: 'right'}}>
                        {pricingScheme ? (
                            <PricingSchemePricePopover
                                data={{
                                    id: pricingScheme.id,
                                    typeCode: pricingScheme.typeCode,
                                    label: pricingScheme.name || '',
                                    price: rowData.effectivePrice,
                                    steps: pricingScheme.steps || [],
                                    shiftCount: rowData.shiftCount,
                                }}
                            >
                                <Icon
                                    component={PricingSchemeMaps.pricingSchemeTypeCodeToIconMap[pricingScheme.typeCode]}
                                    className={'rr-grid-pricingScheme-icon'}
                                />
                            </PricingSchemePricePopover>
                        ) : null}
                        <InlineNumberInput
                            type={'money'}
                            min={0}
                            step={50}
                            canBeEdit={!editDisabled}
                            value={price}
                            onEditing={(isEditing) => {
                                console.log('OOO onEditing');
                                getStore().dispatch(setElementIsNowEditing(isEditing));
                            }}
                            onChange={(value) => {
                                console.log('OOO onChange');
                                getStore().dispatch(setPriceForElement(rowData.id, value));
                            }}
                        >
                            <>{tableCellMoneyRenderer(price, rowData)}</>
                        </InlineNumberInput>
                    </div>
                );
            }
            return null;
        },
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__STATUS),
        dataIndex: 'stateCodeNew',
        type: ColumnTypes.CustomRender,
        minWidth: 130,
        maxWidth: MAX_WIDTH(130),
        resizable: false,
        render: (value: any, rowData: RentElementsGridItem, onAction, entityType: EntityType) => {
            return value ? (
                tableCellStatusRenderer(value, rowData, entityType)
            ) : (
                <span style={{ color: '#575962', opacity: 0.4 }}>{localize(LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__OTHER)}</span>
            );
        },
    },
    {
        title: 'Начало',
        dataIndex: 'rentPeriodStartDate',
        type: ColumnTypes.CustomRender,
        minWidth: 120,
        //maxWidth: MAX_WIDTH(120),
        resizable: false,
        className: 'rr-grid-table-cell-fix',
        render: (value: any, rowData: OperationElement) => {
            let hasError = rowData.problems && rowData.problems.includes('startDate');
            let changed = rowData.rentPeriodStartDateChanged;

            const editDisabled =
                !!rowData.kitId ||
                (rowData.canBeCancelled && rowData.isCancelled === true) ||
                canEditOperationElementParams(getStoreState().operationForm.typeCode);
            return (
                <div
                    style={{ color: changed ? undefined : '#aaa' }}
                    className={
                        'rr-grid-table-cell-fix-container' +
                        (changed ? ' rr-grid-table-modified-cell' : '') +
                        (hasError ? ' rr-grid-table-error-cell' : '')
                    }
                >
                    <InlinePeriodPicker
                        element={rowData}
                        onEditing={(isEditing) => {
                            getStore().dispatch(setElementIsNowEditing(isEditing));
                        }}
                        canBeEdit={!editDisabled}
                        value={[rowData.rentPeriodStartDate, rowData.rentPeriodEndDate]}
                    >
                        <span>
                            {formatDate(rowData.rentPeriodStartDate, 'DD.MM.YY')} {formatDate(rowData.rentPeriodStartDate, 'HH:mm')}
                        </span>
                    </InlinePeriodPicker>
                </div>
            );
        },
    },
    {
        title: 'Завершение',
        dataIndex: 'rentPeriodEndDate',
        type: ColumnTypes.CustomRender,
        minWidth: 130,
        //maxWidth: MAX_WIDTH(130),
        resizable: false,
        className: 'rr-grid-table-cell-fix',
        render: (value: any, rowData: OperationElement) => {
            let hasError = rowData.problems && rowData.problems.includes('endDate');
            let changed = rowData.rentPeriodEndDateChanged;

            const editDisabled =
                !!rowData.kitId ||
                (rowData.canBeCancelled && rowData.isCancelled === true) ||
                canEditOperationElementParams(getStoreState().operationForm.typeCode);
            return (
                <div
                    style={{ color: changed ? undefined : '#aaa' }}
                    className={
                        'rr-grid-table-cell-fix-container' +
                        (changed ? ' rr-grid-table-modified-cell' : '') +
                        (hasError ? ' rr-grid-table-error-cell' : '')
                    }
                >
                    <InlinePeriodPicker
                        element={rowData}
                        onEditing={(isEditing) => {
                            getStore().dispatch(setElementIsNowEditing(isEditing));
                        }}
                        canBeEdit={!editDisabled}
                        value={[rowData.rentPeriodStartDate, rowData.rentPeriodEndDate]}
                    >
                        <span>
                            {formatDate(rowData.rentPeriodEndDate, 'DD.MM.YY')} {formatDate(rowData.rentPeriodEndDate, 'HH:mm')}
                        </span>
                    </InlinePeriodPicker>
                </div>
            );
        },
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__SHIFT_COUNT),
        dataIndex: 'shiftCount',
        type: ColumnTypes.CustomRender,
        minWidth: 110,
        //maxWidth: MAX_WIDTH(110),
        resizable: false,
        className: 'rr-grid-table-cell-fix shiftCount',
        render: (value: any, rowData: OperationElement) => {
            let hasDateError = rowData.problems && (rowData.problems.includes('endDate') || rowData.problems.includes('startDate'));
            let hasWarning = !hasDateError && rowData.warnings && rowData.warnings.includes('shiftCount');
            if (value < 0) value = 0;
            let val: any = tableCellShiftCountRenderer(value);
            if (
                !rowData['subRows'] &&
                !hasDateError &&
                !getStoreState().operationForm.projectTemplate &&
                (rowData.shiftCount || 0) -
                    getShiftCountFromDates(
                        rowData.pricingSchemeId || undefined,
                        moment(rowData.rentPeriodStartDate),
                        moment(rowData.rentPeriodEndDate),
                        rowData.shiftLengthInMinutes,
                        getStoreState().businessAccountPreferences.preferences?.shiftCountRoundingType
                    ) >
                    0
            ) {
                const diff =
                    (rowData.shiftCount || 0) -
                    getShiftCountFromDates(
                        rowData.pricingSchemeId || undefined,
                        moment(rowData.rentPeriodStartDate),
                        moment(rowData.rentPeriodEndDate),
                        rowData.shiftLengthInMinutes,
                        getStoreState().businessAccountPreferences.preferences?.shiftCountRoundingType
                    );
                val = (
                    <>
                        <span
                            style={
                                !rowData.shiftCountChanged
                                    ? {
                                          fontWeight: 400,
                                          color: '#e3e2e2',
                                      }
                                    : undefined
                            }
                        >
                            {val}
                        </span>
                        <span> (+{Math.round(diff * 10) / 10})</span>
                    </>
                );
            }
            let changed = false;
            if (rowData['subRows']) {
                let min = Number.MAX_SAFE_INTEGER;
                let max = 0;
                (rowData['subRows'] as OperationElement[]).forEach((item) => {
                    min = Math.min(min, (item.shiftCount || 0) < 0 ? 0 : item.shiftCount || 0);
                    max = Math.max(max, (item.shiftCount || 0) < 0 ? 0 : item.shiftCount || 0);
                    if (item.shiftCountChanged) changed = true;
                });
                if (min !== max)
                    val = (
                        <>
                            {tableCellShiftCountRenderer(min)}-{tableCellShiftCountRenderer(max)}
                        </>
                    );
                else val = tableCellShiftCountRenderer(min);
            } else {
                changed = rowData.shiftCountChanged;
            }
            const editDisabled =
                !!rowData.kitId ||
                (rowData.canBeCancelled && rowData.isCancelled === true) ||
                canEditOperationElementParams(getStoreState().operationForm.typeCode);
            return rowData ? (
                <div
                    style={{ color: changed ? undefined : '#aaa' }}
                    className={
                        'rr-grid-table-cell-fix-container' +
                        (changed ? ' rr-grid-table-modified-cell' : ' ') +
                        (hasWarning ? ' rr-grid-table-warning-cell' : '')
                    }
                >
                    <div>
                        <InlineNumberInput
                            canBeEdit={!editDisabled}
                            min={0}
                            step={1}
                            value={value}
                            onEditing={(isEditing) => {
                                getStore().dispatch(setElementIsNowEditing(isEditing));
                            }}
                            onChange={(value) => {
                                getStore().dispatch(setShiftCountForElement(rowData.id, value));
                            }}
                        >
                            {val}
                        </InlineNumberInput>
                    </div>
                </div>
            ) : null;
        },
    },
    {
        title: 'Календарные смены',
        dataIndex: 'calendarShiftCount',
        type: ColumnTypes.CustomRender,
        minWidth: 140,
        //maxWidth: MAX_WIDTH(140),
        resizable: false,
        defaultHidden: true,
        className: 'rr-grid-table-cell-fix shiftCount',
        render: (value: any, rowData: OperationElement) => {
            let changed = false;
            value = rowData.calendarShiftCount < 0 ? 0 : rowData.calendarShiftCount;
            let val: any = tableCellShiftCountRenderer(value);
            if (rowData.shiftCount !== rowData.calendarShiftCount) changed = true;

            if (rowData['subRows']) {
                //
                let val1;
                let smin = Number.MAX_SAFE_INTEGER;
                let smax = 0;
                (rowData['subRows'] as OperationElement[]).forEach((item) => {
                    smin = Math.min(smin, (item.shiftCount || 0) < 0 ? 0 : item.shiftCount || 0);
                    smax = Math.max(smax, (item.shiftCount || 0) < 0 ? 0 : item.shiftCount || 0);
                });
                if (smin !== smax)
                    val1 = (
                        <>
                            {tableCellShiftCountRenderer(smin)}-{tableCellShiftCountRenderer(smax)}
                        </>
                    );
                else val1 = tableCellShiftCountRenderer(smin);

                //
                let min = Number.MAX_SAFE_INTEGER;
                let max = 0;
                (rowData['subRows'] as OperationElement[]).forEach((item) => {
                    min = Math.min(min, item.calendarShiftCount < 0 ? 0 : item.calendarShiftCount);
                    max = Math.max(max, item.calendarShiftCount < 0 ? 0 : item.calendarShiftCount);
                });
                if (min !== max)
                    val = (
                        <>
                            {tableCellShiftCountRenderer(min)}-{tableCellShiftCountRenderer(max)}
                        </>
                    );
                else val = tableCellShiftCountRenderer(min);
                changed = val !== val1;
            }

            return rowData ? (
                <div style={{ color: changed ? undefined : '#CCC' }} className={'rr-grid-table-cell-fix-container'}>
                    <div>{val}</div>
                </div>
            ) : null;
        },
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__DISCOUNT),
        dataIndex: 'discount',
        type: ColumnTypes.CustomRender,
        minWidth: 110,
        //maxWidth: MAX_WIDTH(110),
        resizable: false,
        className: 'rr-grid-table-cell-fix',
        render: (value: any, rowData: OperationElement) => {
            let changed = false;
            if (rowData['subRows']) {
                (rowData['subRows'] as OperationElement[]).forEach((item) => {
                    if (item.discountChanged) changed = true;
                });
                value = getDiscountForKit(rowData['subRows'] as OperationElement[], rowData.finalTotalPrice);
            } else {
                changed = rowData.discountChanged;
            }

            const editDisabled =
                !!rowData.kitId ||
                (rowData.canBeCancelled && rowData.isCancelled === true) ||
                canEditOperationElementParams(getStoreState().operationForm.typeCode);

            return rowData ? (
                <div
                    style={{ color: changed ? undefined : '#aaa' }}
                    className={'rr-grid-table-cell-fix-container' + (changed ? ' rr-grid-table-modified-cell' : '')}
                >
                    <div>
                        <InlineNumberInput
                            canBeEdit={!editDisabled}
                            max={100}
                            step={1}
                            value={value}
                            onEditing={(isEditing) => {
                                getStore().dispatch(setElementIsNowEditing(isEditing));
                            }}
                            onChange={(value) => {
                                getStore().dispatch(setDiscountForElement(rowData.id, value));
                            }}
                        >
                            {tableCellPercentsRenderer(value)}
                        </InlineNumberInput>
                    </div>
                </div>
            ) : null;
        },
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__COST_INSTANCE_PER_SHIFT),
        dataIndex: 'finalPricePerShift',
        type: ColumnTypes.CustomRender,
        className: 'money',
        minWidth: 120,
        //maxWidth: MAX_WIDTH(120),
        resizable: false,
        render: (value: any, rowData: OperationElement) => {
            let price = rowData.finalPricePerShift;
            return tableCellMoneyRenderer(price, rowData);
        },
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__FINAL_TOTAL_PRICE),
        dataIndex: 'finalTotalPrice',
        type: ColumnTypes.Money,
        className: 'money',
        minWidth: 140,
        //maxWidth: MAX_WIDTH(140),
        resizable: false,
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__ACTIONS),
        type: ColumnTypes.CustomRender,
        width: getActionsColumnWidth(4),
        maxWidth: getActionsColumnWidth(4),
        minWidth: getActionsColumnWidth(4),
        resizable: false,
        sortable: false,
        className: 'actions',
        headerClassName: 'actions',
        render: (value: any, rowData: OperationElement) => {
            if (!rowData) return null;
            const operationTypeCode = getStoreState().operationForm.typeCode;
            const isSimpleOrder = getStoreState().operationForm.isSimpleOrder;
            const operationProjectStateCode = getStoreState().operationForm.projectStateCode;
            const isInstanceTracked =
                rowData.instanceTrackingTypeCode &&
                [InstanceTrackingTypeCodeEnum.INSTANCETRACKED, InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED].includes(
                    rowData.instanceTrackingTypeCode
                );
            const removeDisabled = operationTypeCode !== OperationTypeCodeEnum.CANCEL && (rowData.disabled || rowData.instanceCount < 1);
            const canCopy = canCopyElementInOperation(operationTypeCode);
            const canMoveFromKit = !!rowData.parentId;

            if(isSimpleOrder && (operationProjectStateCode === ProjectStateCodeEnum.CANCELED || operationProjectStateCode === ProjectStateCodeEnum.RENTED || operationProjectStateCode === ProjectStateCodeEnum.FINISHED)) return null;

            // if(operationTypeCode === OperationTypeCodeEnum.EDIT && !isSimpleOrder){
            //     return !rowData.kitId ? (
            //         <div className={'rr-grid-table-actions-group'}>
            //             <div>
            //                 {/* Редактирование */}
            //                 <OperationElementCreatePopover
            //                     elementRecord={rowData}
            //                     mode={'edit'}
            //                     instanceCount={rowData.instanceCount}
            //                     availableInstanceCount={rowData.availableInstanceCount}
            //                 >
            //                     {editIcon}
            //                 </OperationElementCreatePopover>
            //             </div>
            //         </div>
            //     ) : null;
            // }

            {
                /* Удаление набора */
            }
            if (rowData && rowData.kitId) {
                return (
                    <div className={'rr-grid-table-actions-group'}>
                        <div></div>
                        <div></div>
                        <div>
                            {
                                isSimpleOrder && rowData.id > 0 ? null : (
                                    <OperationFormKitRemovePopover disabled={removeDisabled} id={rowData.id}>
                                        {operationTypeCode !== OperationTypeCodeEnum.CANCEL && rowData.disabled ? removeIconDisabled : removeIcon}
                                    </OperationFormKitRemovePopover>
                                )
                            }
                        </div>

                        <div>
                            <Popover
                                arrowPointAtCenter={true}
                                overlayClassName={'rr-actions-popover'}
                                placement="bottomRight"
                                trigger={'click'}
                                overlayStyle={{ width: 300 }}
                                content={
                                    <>
                                        <div className={'rr-grid-actions-popover-header1'}></div>
                                        <div className={'rr-grid-actions-popover-content'}>
                                            <KitAddElementPopover element={rowData}>
                                                <Button className={'ant-btn-page-actions'}>
                                                    <Icon
                                                        className={'rr-grid-table-move-from-to-kit-icon'}
                                                        style={{ cursor: 'pointer' }}
                                                        component={IconArrowCircleDownSolid}
                                                    />
                                                    Поместить в набор
                                                </Button>
                                            </KitAddElementPopover>
                                        </div>
                                    </>
                                }
                            >
                                <span>
                                    <Icon
                                        className={'rr-grid-table-edit-icon'}
                                        style={rowData.disabled ? { cursor: 'default', fontSize: 30, opacity: 0.4 } : { cursor: 'pointer', fontSize: 30 }}
                                        component={IconEllipsisHSolid}
                                    />
                                </span>
                            </Popover>
                        </div>
                    </div>
                );
            }

            return rowData ? (
                <div className={'rr-grid-table-actions-group'}>
                    {rowData.canBeCancelled && rowData.isCancelled === true ? <div></div> : null}
                    {rowData.canBeCancelled && rowData.isCancelled === true ? <div></div> : null}
                    <div>
                        {/* Отмена */}
                        {rowData.canBeCancelled && isDefined(rowData.isCancelled) && (
                            <OperationElementCancelToggleButton
                                onChange={(cancelled: boolean) => {
                                    if (rowData) getStore().dispatch(cancelOperationElement(rowData.id, cancelled));
                                }}
                                cancelled={rowData.isCancelled}
                            />
                        )}
                    </div>
                    {rowData.canBeCancelled && rowData.isCancelled === true ? null : (
                        <div>
                            {/* Редактирование */}
                            <OperationElementCreatePopover
                                elementRecord={rowData}
                                mode={'edit'}
                                instanceCount={rowData.instanceCount}
                                availableInstanceCount={rowData.availableInstanceCount}
                            >
                                {editIcon}
                            </OperationElementCreatePopover>
                        </div>
                    )}
                    {!(rowData.canBeCancelled && rowData.isCancelled === true) ? (
                        <div>
                            {/* Удаление */}
                            {
                                isSimpleOrder && rowData.id > 0 ? null : (
                                    <span>
                                        <HelpTooltip
                                            placement={'topRight'}
                                            content={LocalizationEnum.PAGE__NEW_OPERATION__ELEMENTS__ACTION__DELETE__TOOLTIP}
                                        >
                                            <Icon
                                                onClick={() => {
                                                    if (!removeDisabled) {
                                                        const selectedIds = getStoreState().operationForm.elements.selectedIds;//filters?.selectedIds;
                                                        if(operationTypeCode === OperationTypeCodeEnum.EDIT && selectedIds && selectedIds.length > 0){
                                                            getStore().dispatch(removeRentElement(rowData.id));
                                                            return;
                                                        }
                                                        if (isInstanceTracked) {
                                                            let max = rowData.instanceCount;
                                                            let amount = max;
                                                            let instanceCountToDelete = amount;
                                                            let anonymousCount = rowData.anonymousInstanceCount || 0;
                                                            let anonymousToDelete =
                                                                anonymousCount > instanceCountToDelete ? instanceCountToDelete : anonymousCount;
                                                            let instancesToDelete: number[] = [];

                                                            if (rowData.instanceIds && anonymousToDelete < instanceCountToDelete) {
                                                                let instances = [...rowData.instanceIds];
                                                                instances = instances.splice(0, instanceCountToDelete - anonymousToDelete);
                                                                instancesToDelete = instances;
                                                            }
                                                            getStore().dispatch(
                                                                removeTrackedInstances(rowData.id, instancesToDelete, anonymousToDelete)
                                                            );
                                                        } else {
                                                            getStore().dispatch(removeInstances(rowData.id, rowData.instanceCount));
                                                        }
                                                    }
                                                }}
                                                className={'rr-grid-table-remove-icon'}
                                                style={
                                                    removeDisabled
                                                        ? {
                                                            opacity: 0.4,
                                                            cursor: 'default',
                                                        }
                                                        : { cursor: 'pointer' }
                                                }
                                                component={IconClose}
                                            />
                                        </HelpTooltip>
                                    </span>
                                )
                            }
                        </div>
                    ) : null}
                    <div>
                        {(canCopy || canMoveFromKit) && (
                            <Popover
                                arrowPointAtCenter={true}
                                overlayClassName={'rr-actions-popover'}
                                placement="bottomRight"
                                trigger={'click'}
                                overlayStyle={{ width: 300 }}
                                content={
                                    <>
                                        <div className={'rr-grid-actions-popover-header1'}></div>
                                        <div className={'rr-grid-actions-popover-content'}>
                                            {canMoveFromKit && (
                                                <Button
                                                    className={'ant-btn-page-actions'}
                                                    onClick={(e) => {
                                                        if (rowData.parentId) getStore().dispatch(moveElementFromKit(0, rowData.id));
                                                    }}
                                                >
                                                    <Icon
                                                        component={IconArrowCircleUpSolid}
                                                        className={'rr-grid-table-move-from-to-kit-icon'}
                                                    />
                                                    Вынести вне набора
                                                </Button>
                                            )}

                                            {canCopy && (
                                                <OperationElementCreatePopover
                                                    elementRecord={rowData}
                                                    disabled={rowData.disabled}
                                                    mode={'copy'}
                                                    instanceCount={rowData.instanceCount}
                                                    availableInstanceCount={rowData.availableInstanceCount}
                                                >
                                                    {() => (
                                                        <Button className={'ant-btn-page-actions'}>
                                                            <Icon
                                                                component={IconControlPointDuplicateBlack}
                                                                className={'rr-grid-table-plus-icon'}
                                                            />
                                                            Создать на основе
                                                        </Button>
                                                    )}
                                                </OperationElementCreatePopover>
                                            )}
                                        </div>
                                    </>
                                }
                            >
                                {rowData.disabled ? (
                                    <span>
                                        <Icon
                                            className={'rr-grid-table-edit-icon'}
                                            style={{ cursor: 'default', fontSize: 30, opacity: 0.4 }}
                                            component={IconEllipsisHSolid}
                                        />
                                    </span>
                                ) : (
                                    <span>
                                        <Icon
                                            className={'rr-grid-table-edit-icon'}
                                            style={{ cursor: 'pointer', fontSize: 30 }}
                                            component={IconEllipsisHSolid}
                                        />
                                    </span>
                                )}
                            </Popover>
                        )}
                    </div>
                </div>
            ) : null;
        },
    },
];

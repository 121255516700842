import React, { RefObject } from 'react';
import { TreeSelect } from 'antd';
import { ProductCategory, RenterCategory } from '../../shared/reducers/entities.reducer';
import {
    findCategoryRecursively,
    mapProductsCategoriesToTreeNodeNormal,
} from '../../modules/main/inventory/components/ProductCategoriesSelect/ProductCategoriesSelect';
import { TreeNode } from 'antd/es/tree-select';
import './baseCategoriesSelect.less';
import { TreeSelectProps } from 'antd/lib/tree-select';
import ReactDOM from 'react-dom';
import { TreeNodeValue } from 'antd/lib/tree-select/interface';
import classNames from 'classnames';

export interface BaseCategoriesSelectProps extends TreeSelectProps<string | number | Array<any>> {
    onChange?: (value: any, label: any, extra: any) => void;
    value?: string | number | Array<any>;
    getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
    categories: ProductCategory[] | RenterCategory[];
    multiple?: boolean;
    elementId?: number;
    id?: string;
    isHighlightValue?: boolean;
    initiallyOpen?: boolean;
}

interface BaseCategoriesSelectState {
    open: boolean;
}

export class BaseCategoriesSelect extends React.PureComponent<BaseCategoriesSelectProps, BaseCategoriesSelectState> {
    treeSelectRef: RefObject<TreeSelect<TreeNodeValue>>;

    constructor(props) {
        super(props);
        this.treeSelectRef = React.createRef();
        this.state = {
            open: false,
        };
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyDown);
        if (this.props.initiallyOpen) {
            setTimeout(() => {
                this.setState({ open: true });
            }, 250);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    handleKeyDown = (event: globalThis.KeyboardEvent) => {
        if (event.key === 'Enter') {
            this.setState({
                open: false,
            });
        }
    };

    focusInput() {
        const treeNode = ReactDOM.findDOMNode(this.treeSelectRef?.current);
        if (treeNode instanceof Element) {
            const inputNode = treeNode?.querySelector('input');
            if (inputNode) inputNode.focus();
        }
    }

    render() {
        const {
            onChange,
            value,
            id,
            getPopupContainer,
            categories,
            multiple,
            elementId,
            isHighlightValue = true,
            ...treeSelectProps
        } = this.props;

        const treeData = mapProductsCategoriesToTreeNodeNormal(categories, isHighlightValue ? value?.[0] : undefined);

        return (
            <TreeSelect
                ref={this.treeSelectRef}
                getPopupContainer={getPopupContainer}
                showSearch
                treeData={treeData}
                filterTreeNode={(search, item: TreeNode) => {
                    const category = findCategoryRecursively(categories, Number(item.key));
                    if (category == null) return false;
                    return category.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
                }}
                style={{ width: '100%' }}
                value={value}
                allowClear
                treeDefaultExpandAll
                multiple={multiple !== false}
                onChange={onChange}
                onSelect={() => this.focusInput()}
                open={this.state.open}
                onDropdownVisibleChange={(visible) =>
                    this.setState({
                        open: visible,
                    })
                }
                {...treeSelectProps}
                className={classNames('rr-base-categories-select', treeSelectProps.className)}
                dropdownClassName={classNames('rr-base-categories-select_dropdown', treeSelectProps.dropdownClassName)}
            />
        );
    }
}

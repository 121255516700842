import {FC, useContext, useState} from 'react';
import classNames from 'classnames';
import {AccessibilityMapItemContext} from '../../../../../../../../context/CalendarItemContext';
import {CalendarColors} from '../../../../../../../../utils/calendarColors';
import {Popover} from 'antd';
import {
    AccessibilityMapLocalizationMap
} from '../../../../../../../../../../tabs/accessibilityMap/data/localizationData';
import './AccessibilityMapContent.less';
import {PopoverDate} from './components/PopoverDate/PopoverDate';
import {TimeRangeContext} from '../../../../../../../../context/TimeRangeContext';
import {CalendarDisplayType} from "../../../../../../../../../Calendar/utils/data/calendarConstants";

interface AccessibilityMapContentProps {
    displayType:CalendarDisplayType;
}

export const AccessibilityMapContent: FC<AccessibilityMapContentProps> = ({displayType}) => {
    const { item } = useContext(AccessibilityMapItemContext);
    const { visibleTimeStart, visibleTimeEnd } = useContext(TimeRangeContext);
    const { typeCode, value, startTime, endTime } = item;
    const [visible, setVisible] = useState(false);

    const [backgroundMoreZeroColor, backgroundZeroColor, backgroundLessZeroColor] =
        CalendarColors.accessibilityStateCodeBackgroundMap[typeCode];
    const borderColor = CalendarColors.getAccessibilityStateCodeBorderMap(typeCode, value);
    const textColor = CalendarColors.getAccessibilityStateCodeColorMap(typeCode, value);

    const backgroundColor = value > 0 ? backgroundMoreZeroColor : value < 0 ? backgroundLessZeroColor : backgroundZeroColor;

    const itemBorder = `1px solid ${borderColor}`;

    const getPaddingLeft = (): number => {
        if (startTime - visibleTimeStart < 2) return displayType === CalendarDisplayType.NORMAL ? 14 : 10;
        return displayType === CalendarDisplayType.NORMAL ? 12 : 8;
    };

    return (
        <Popover
            visible={visible}
            onVisibleChange={(visible) => setVisible(visible)}
            placement={'top'}
            arrowPointAtCenter
            overlayClassName={'accessibility-item-popover'}
            content={
                <div className={'container'}>
                    <span className={'title'}>
                        <div
                            className={'circle'}
                            style={{
                                backgroundColor,
                                border: `1px solid ${borderColor ?? 'transparent'}`,
                            }}
                        />
                        <span>
                            {value} {AccessibilityMapLocalizationMap[typeCode]}
                        </span>
                    </span>
                    <div className={'dates'}>
                        <PopoverDate time={startTime < visibleTimeStart ? null : startTime} />
                        <span className={'arrow'}>⟶</span>
                        <PopoverDate time={endTime > visibleTimeEnd ? null : endTime} />
                    </div>
                </div>
            }
        >
            <div
                className={classNames('line-main-block-container')}
                onTouchEnd={() => setVisible(true)}
                style={{
                    color: textColor ?? '#fff',
                    background: backgroundColor,
                    border: borderColor ? itemBorder : undefined,
                }}
            >
                <span
                    className={'accessibility-item'}
                    style={{
                        paddingLeft: getPaddingLeft(),
                    }}
                >
                    {value}
                </span>
            </div>
        </Popover>
    );
};

import React, { CSSProperties } from 'react';
import { OperationTypeCodeEnum } from '../../server/api';
import { Icon } from 'antd';
import { getOperationStatusTypeData } from '../../modules/main/operationForm/utils/utils';

interface IProps {
    code: OperationTypeCodeEnum;
    style?: CSSProperties;
    isPopover?: boolean;
    status?: string;
}

export const OperationType = (props: IProps) => {
    let iconComponent,
        label,
        typeData = getOperationStatusTypeData(props.code, false);

    label = typeData.name;
    iconComponent = typeData.iconComponent;

    return (
        <span style={props.style} className="popover-link-hover">
            {iconComponent ? (
                <Icon
                    component={iconComponent}
                    className={'rr-operation-status-color-' + props.code}
                    style={{ fontSize: 20, position: 'relative', top: '-1px', marginRight: 5, verticalAlign: 'middle' }}
                />
            ) : null}
            {props.isPopover && (props.code === 'AUTOMATIC_RULE' || props.code === 'CORRECT') ? (
                <div className={`rr-dot rr-operation-status-point rr-status-bg-${props.status}`}></div>
            ) : null}
            {label}
        </span>
    );
};

import React, {FC, memo, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import { useAppSelector } from '../../../../../store/hooks';
import { useIntl } from 'react-intl';
import { businessAccountIdSelector } from '../../../../../shared/reducers/system.reducer';
import { useListRentElementsQuery } from './api/elementsCalendar.api';
import { ElementsCalendarFiltersDescription } from './filters/elementsCalendarFilters.description';
import { useFiltersChanges } from './hooks/useFiltersChanges';
import { useCalendarQueryParams } from '../../components/Calendar/hooks/useCalendarQueryParams';
import {CalendarDisplayType, CalendarVisibleItemsLimit} from '../../components/Calendar/utils/data/calendarConstants';
import { elementsCalendarScreenLeft, elementsCalendarScreenRight } from './reducers/elementsCalendar.reducer.selectors';
import { EntityCalendar } from '../../components/EntityCalendar/EntityCalendar';
import { elementsCalendarFilters } from './filters/elementsCalendarFilters';
import { WrappedFormUtils } from 'antd/es/form/Form';
import { serverApi } from '../../../../../server';
import { ElementsCalendarFilters } from './filters/elementsCalendarFilters.types';
import { usePageURLParams } from '../../../../../core/hooks/urlParams/usePageURLParams';
import { ElementsCalendarItemRenderer } from './components/ItemRenderer/ElementsCalendarItemRenderer';
import { CalendarPageTabsEnum } from '../../../../../shared/constants/tabEnums';
import { elementsCalendarSortData, elementsCalendarSortValueToItemKey } from './utils/data/sortData';
import { elementsCalendarGroups } from './utils/data/groupsData';
import { processElementsCalendarItem } from './utils/processing/processElementsCalendarItem';
import { ElementsCalendarHeaderButtons } from './components/HeaderButtons/ElementsCalendarHeaderButtons';

export const elementsCalendarPageName = CalendarPageTabsEnum.ELEMENTS;

interface ElementsCalendarProps {}

export const ElementsCalendar: FC<ElementsCalendarProps> = memo(() => {
    const intl = useIntl();
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const screenLeft = useAppSelector(elementsCalendarScreenLeft);
    const screenRight = useAppSelector(elementsCalendarScreenRight);
    const [visibleItemsLimit, setVisibleItemsLimit] = useState<CalendarVisibleItemsLimit>(CalendarVisibleItemsLimit.FIRST_LIMIT);
    const filtersFormRef = useRef<WrappedFormUtils>();

    const manuallyParams = useCallback((params: ElementsCalendarFilters): Partial<ElementsCalendarFilters> => {
        if (params.productId == null)
            return {
                productInstanceTrackingTypeCode: undefined,
            };

        return {
            productInstanceTrackingTypeCode: filtersFormRef.current?.getFieldValue('productInstanceTrackingTypeCode'),
        };
    }, []);

    const pageParamsObject = usePageURLParams({
        paramsDescription: ElementsCalendarFiltersDescription,
        pageName: elementsCalendarPageName,
        manuallyParams,
        initialValues: {
            group: 'project',
            displayType: CalendarDisplayType.COMPACT
        }
    });
    const { pageParams } = pageParamsObject;

    const queryParams = useCalendarQueryParams({
        ...pageParams,
    });

    const { data: rentElementsList, isFetching } = useListRentElementsQuery(
        {
            businessAccountId,
            intl,
            params: queryParams,
            visibleItemsLimit,
        },
        {
            refetchOnReconnect: true,
            refetchOnMountOrArgChange: true,
        }
    );

    const setVisibleInstance = useCallback(
        async (productId: number) => {
            if (productId) {
                await serverApi.getProductById(businessAccountId, productId).then((data) => {
                    filtersFormRef.current?.setFieldsValue({
                        productInstanceTrackingTypeCode: data?.data?.instanceTrackingTypeCode,
                    });
                });
            }
        },
        [businessAccountId]
    );

    useEffect(() => {
        if (pageParams.productId) setVisibleInstance(pageParams.productId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <EntityCalendar
            calendarType={'elements'}
            filtersData={elementsCalendarFilters}
            filtersFormRef={filtersFormRef}
            groupsData={elementsCalendarGroups}
            isLoading={isFetching}
            pageName={elementsCalendarPageName}
            pageParamsDescription={ElementsCalendarFiltersDescription}
            pageParamsObject={pageParamsObject}
            processItemFunction={processElementsCalendarItem}
            recordList={rentElementsList}
            screenLeft={screenLeft}
            screenRight={screenRight}
            setVisibleItemsLimit={setVisibleItemsLimit}
            sortData={elementsCalendarSortData}
            sortGroupMap={elementsCalendarSortValueToItemKey}
            useFiltersChanges={useFiltersChanges}
            visibleItemsLimit={visibleItemsLimit}
            itemRenderer={ElementsCalendarItemRenderer}
            headerButtons={ElementsCalendarHeaderButtons}
        />
    );
});

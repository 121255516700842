import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../../store/rtqQueryApi';
import { CustomFieldInfoRead, serverApi } from '../../../../server';
import { AxiosError } from 'axios';

const CUSTOM_FIELDS_TAG = 'CUSTOM_FIELDS_TAG' as const;

export const customFieldsApi = createApi({
    reducerPath: 'customFieldsApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: [CUSTOM_FIELDS_TAG],
    endpoints: (builder) => ({
        listCustomFields: builder.query({
            queryFn: async (params) => {
                try {
                    const response = await serverApi.listCustomFields(params.businessAccountId);
                    return { data: response.data };
                } catch (e) {
                    return { error: e as AxiosError };
                }
            },
        }),
        getCustomFieldById: builder.query<CustomFieldInfoRead, { businessAccountId: number; id: number }>({
            queryFn: async (params) => {
                try {
                    const response = await serverApi.getCustomFieldById(params.businessAccountId, params.id);
                    return { data: response.data };
                } catch (e) {
                    return { error: e as AxiosError };
                }
            },
        }),
    }),
});

export const { useGetCustomFieldByIdQuery } = customFieldsApi;

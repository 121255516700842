import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../../../store/rtqQueryApi';
import { serverApi } from '../../../../../server';
import { AxiosError } from 'axios';
import { ListUsersSimpleArgs, ListUsersSimpleData } from './types';

export const usersApi = createApi({
    reducerPath: 'usersApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['UsersList', 'User'],
    endpoints: (builder) => ({
        listUsersSimple: builder.query<ListUsersSimpleData, ListUsersSimpleArgs>({
            queryFn: async ({ businessAccountId, params }: ListUsersSimpleArgs) => {
                const startFrom = ((params?.page ?? 1) - 1) * (params?.limit ?? 1);

                try {
                    const { data: users } = await serverApi.listUsersSimple(businessAccountId, params.limit, startFrom);
                    return {
                        data: {
                            entitiesData: users,
                        },
                    };
                } catch (e) {
                    return {
                        error: e as AxiosError,
                    };
                }
            },
            providesTags: ['UsersList'],
        }),
    }),
});

export const { useListUsersSimpleQuery, useLazyListUsersSimpleQuery } = usersApi;

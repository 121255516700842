import React, {CSSProperties, FC, MouseEvent, ReactNode, useCallback, useEffect, useState} from 'react';
import {Icon} from "antd";
import moment, {Moment} from "moment";
import classNames from "classnames";
import {IconCalendar, IconClose} from "../../../icons";
import {RangePickerProps as RangePickerComponentProps} from "antd/lib/date-picker/interface";
import {Picker} from "./components/picker/picker";
import {Calendar} from "./components/calendar/calendar";
import {CustomRangeCalendarDateItem} from "../../../datePicker/customRangeCalendarDateItem";
import {PricingSchemeExternalRepresentationObj, TimetableTypeCodeEnum} from "../../../../server";
import {RangeCalendar} from "./components/rangeCalendar/rangeCalendar";
import {RangeCalendarFooterProps} from "./components/rangeCalendar/rangeCalendarFooter";
import {useAppDispatch, useAppSelector} from "../../../../store/hooks";
import {getShiftCountFromDates} from "../../../../shared/util/utils";
import {setRecalculateShiftCount, updateRecalculateShiftsCount} from "../../../../shared/reducers/userSettings/userSettings.reducer";
import {generateArrayOfNumbers} from "../../../../shared/util/utils2";
import './rangePicker.less';
import {
    canSetEndDateToTargetDate,
    canSetEndDateTimeToTargetDateTime,
    canSetStartDateToTargetDate,
    canSetStartTimeToTargetDateTime
} from "../../../../utils/timeUtils/timeUtils";

export interface RangePickerProps {
    value?: [Moment, Moment];
    onChange?: (dates: [Moment, Moment]|[]|undefined, recalculateShiftCount?: boolean)=>void;
    disabled?: boolean;
    selectionDisabled?: boolean;
    placeholder?: RangePickerComponentProps['placeholder'];
    allowClear?: RangePickerComponentProps['allowClear'];
    getPopupContainer?: RangePickerComponentProps['getCalendarContainer'];
    showTime?: boolean;
    intervals?: [number, number][];
    instanceCount?: number;
    startDateLabel?: ReactNode;
    endDateLabel?: ReactNode;
    shiftCount?: number;
    typeCode?: TimetableTypeCodeEnum;
    dontShowBadges?: boolean;
    instance?: boolean;
    style?: CSSProperties;
    className?: string; // TODO В редактировании элемента, какие-то классы навешиваются, надо проверить
    requiredTimeIndentProps?: RangeCalendarFooterProps['requiredTimeIndentProps'];
    recalculateShiftCountBlockProps?: RangeCalendarFooterProps['recalculateShiftCountBlockProps'];
    getTodayDate?: ()=>Moment;
    storeKey?: string;
    pricingScheme: undefined | PricingSchemeExternalRepresentationObj;
}

const mainClassName = `rr-range-picker`;
const mainDisabledClassName = `${mainClassName}-disabled`;
const mainRangeSelectedClassName = `${mainClassName}-range-selected`;
const mainAllowClearClassName = `${mainClassName}-allow-clear`;
const inputClassName = `${mainClassName}-input`;
const separatorClassName = `${mainClassName}-separator`;
const calendarIconClassName = `${mainClassName}-calendar-icon`;
const clearIconClassName = `${mainClassName}-clear-icon`;

const formatStringWithoutTime = `DD.MM.YY`;
const formatStringWithTime = `${formatStringWithoutTime} - HH:mm`;

export const RangePicker:FC<RangePickerProps> = (props) => {

    const dispatch = useAppDispatch();

    const recalculateShiftCount = useAppSelector((storeState) => props.storeKey
        ? storeState.userSettings.recalculateShiftsCount[props.storeKey]
        : storeState.userSettings.operationFormRecalculateShiftCount);

    const shiftCountRoundingType = useAppSelector((storeState) => storeState.businessAccountPreferences.preferences?.shiftCountRoundingType);

    const [rangeCalendarOpen, setRangeCalendarOpen] = useState(false);
    const [startDateCalendarOpen, setStartDateCalendarOpen] = useState(false);
    const [endDateCalendarOpen, setEndDateCalendarOpen] = useState(false);
    const isRangeSelected = Boolean(props.value && props.value[0] && props.value[1]);
    const [s1, setS1] = useState(false);

    const pickerOnClick = useCallback((e:MouseEvent<HTMLElement>)=>{
        if(!props.disabled){
            setRangeCalendarOpen(true);
            setStartDateCalendarOpen(false);
            setEndDateCalendarOpen(false);
        }
    }, [props.disabled, setStartDateCalendarOpen, setRangeCalendarOpen, setEndDateCalendarOpen]);

    const startDateInputOnClick = useCallback((e:MouseEvent<HTMLElement>)=>{
        e.stopPropagation();
        setRangeCalendarOpen(!isRangeSelected);
        setStartDateCalendarOpen(isRangeSelected);
        setEndDateCalendarOpen(false);
    }, [isRangeSelected, setStartDateCalendarOpen, setRangeCalendarOpen, setEndDateCalendarOpen]);

    const endDateInputOnClick = useCallback((e:MouseEvent<HTMLElement>)=>{
        e.stopPropagation();
        setRangeCalendarOpen(!isRangeSelected);
        setStartDateCalendarOpen(false);
        setEndDateCalendarOpen(isRangeSelected);
    }, [isRangeSelected, setStartDateCalendarOpen, setRangeCalendarOpen, setEndDateCalendarOpen]);

    const formatDate = useCallback((v:Moment) => {
        return (v ? v.format(props.showTime ? formatStringWithTime : formatStringWithoutTime) : '');
    }, [props.showTime]);

    const startDatePlaceHolder = props.placeholder?.[0] || 'Начальная дата';
    const endDatePlaceHolder = props.placeholder?.[1] || 'Конечная дата';

    const startDateValue = props.value?.[0]?.startOf('minute');
    const endDateValue = props.value?.[1].startOf('minute');

    const startDateInputValue = startDateValue ? formatDate(startDateValue) : '';
    const endDateInputValue = endDateValue ? formatDate(endDateValue) : '';

    const [v, setV] = useState(0);

    const todayDate = props.getTodayDate ? props.getTodayDate().startOf('minute') : moment().startOf('minute');
    const startDateTodayButtonDisabled = !canSetStartDateToTargetDate(todayDate, startDateValue, endDateValue);//!rangeSelected || (endDateValue && todayDate.valueOf() >= endDateValue.valueOf());
    const endDateTodayButtonDisabled = !canSetEndDateToTargetDate(todayDate, startDateValue, endDateValue);//!rangeSelected || (startDateValue && todayDate.valueOf() <= startDateValue.valueOf());

    const startDateTodayButtonDisabled1 = !canSetStartTimeToTargetDateTime(todayDate, startDateValue, endDateValue);
    const endDateTodayButtonDisabled1 = !canSetEndDateTimeToTargetDateTime(todayDate, startDateValue, endDateValue);

    const onChange = (value?: [Moment, Moment], recalculateShiftCount?: boolean)=>{
        props.onChange?.(value, recalculateShiftCount);
        if (recalculateShiftCount && value && value[0] && value[1]) {
            let newShiftCount =
                props.recalculateShiftCountBlockProps?.shiftLengthInMin !== undefined
                    ? getShiftCountFromDates(props.pricingScheme, value[0], value[1], props.recalculateShiftCountBlockProps?.shiftLengthInMin, shiftCountRoundingType)
                    : 0;
            props.recalculateShiftCountBlockProps?.onShiftCountChange?.(newShiftCount);
        }
    };

    const onClearIconClick = useCallback((e:MouseEvent<HTMLElement>)=>{
        e.stopPropagation();
        onChange(undefined, recalculateShiftCount);
    }, [onChange, recalculateShiftCount]);

    useEffect(()=>{
        setV(v+1);
    }, [props.intervals?.toString(), setV]);

    const renderDate = (current: moment.Moment, today: moment.Moment): React.ReactNode => {
        return (
            <CustomRangeCalendarDateItem
                current={current}
                today={today}
                typeCode={props.typeCode || TimetableTypeCodeEnum.AVAILABLE}
                dontShowBadges={props.dontShowBadges}
                instance={props.instance}
                instanceCount={props.instanceCount}
                intervals={props.intervals}
                v={v}
            />
        );
    };

    const newShiftCount =
        startDateValue && endDateValue && props.recalculateShiftCountBlockProps?.shiftLengthInMin !== undefined
            ? getShiftCountFromDates(props.pricingScheme, startDateValue, endDateValue, props.recalculateShiftCountBlockProps?.shiftLengthInMin, shiftCountRoundingType)
            : 0;

    const shiftCount = props.shiftCount;

    const _recalculateShiftCountBlockProps = props.recalculateShiftCountBlockProps && {
        ...props.recalculateShiftCountBlockProps,
        recalculateShiftCount: recalculateShiftCount,
        recalculateShiftCountOnChange: async (checked)=>{
            if (props.storeKey)
                await dispatch(updateRecalculateShiftsCount({
                    [props.storeKey]: checked,
                }));
            else await dispatch(setRecalculateShiftCount(checked));
                onChange(props.value, checked);
        },
        fromShiftCount: shiftCount,
        toShiftCount: newShiftCount
    };

    const onStartDateTodayButtonClick = !startDateTodayButtonDisabled ? ()=>{
        if(endDateValue){
            const startDate = props.getTodayDate ? props.getTodayDate().startOf('minute') : moment().startOf('minute');
            onChange?.([startDate, endDateValue], recalculateShiftCount);
        }
    } : undefined;

    const onEndDateTodayButtonClick = !endDateTodayButtonDisabled ? ()=>{
        if(startDateValue){
            const endDate:Moment = props.getTodayDate ? props.getTodayDate().startOf('minute') : moment().startOf('minute');
            onChange?.([startDateValue, endDate], recalculateShiftCount);
        }
    } : undefined;

    const onStartDateTodayButtonClick1 = !startDateTodayButtonDisabled1 ? ()=>{
        if(startDateValue && endDateValue){
            const curDate = props.getTodayDate ? props.getTodayDate().startOf('minute') : moment().startOf('minute');
            const startDate = startDateValue?.clone().hours(curDate.hours()).minutes(curDate.minutes());
            onChange?.([startDate, endDateValue], recalculateShiftCount);
        }
    } : null;

    const onEndDateTodayButtonClick1 = !endDateTodayButtonDisabled1 ? ()=>{
        if(startDateValue && endDateValue){
            const curDate = props.getTodayDate ? props.getTodayDate().startOf('minute') : moment().startOf('minute');
            const endDate = endDateValue?.clone().hours(curDate.hours()).minutes(curDate.minutes());
            onChange?.([startDateValue, endDate], recalculateShiftCount);
        }
    } : null;

    const rangeCalendar = (
        <RangeCalendar
            value={props.value || [undefined, undefined]}
            onChange={(value)=>{
                onChange(value, recalculateShiftCount);
            }}
            renderDate={renderDate}
            onOk={()=>{
                setRangeCalendarOpen(false);
            }}
            showTime={props.showTime}
            requiredTimeIndentProps={props.requiredTimeIndentProps}
            recalculateShiftCountBlockProps={_recalculateShiftCountBlockProps}
            onStartDateTodayButtonClick={onStartDateTodayButtonClick}
            onEndDateTodayButtonClick={onEndDateTodayButtonClick}
            onStartDateTodayButtonClick1={onStartDateTodayButtonClick1}
            onEndDateTodayButtonClick1={onEndDateTodayButtonClick1}
            selectionDisabled={props.selectionDisabled}
        />
    );

    let disabledStartHours:number[]|undefined;
    let disabledStartMinutes:number[]|undefined;
    let disabledEndHours:number[]|undefined;
    let disabledEndMinutes:number[]|undefined;

    if(startDateValue && endDateValue){
        if(startDateValue.clone().startOf('day').valueOf() === endDateValue.clone().startOf('day').valueOf()){
            disabledStartHours = generateArrayOfNumbers(endDateValue.hours()+1, 24);
            if(startDateValue.hours() === endDateValue.hours()) disabledStartMinutes = generateArrayOfNumbers(endDateValue.minutes()-1, 60);

            disabledEndHours = generateArrayOfNumbers(0, startDateValue.hours());
            if(startDateValue.hours() === endDateValue.hours()) disabledEndMinutes = generateArrayOfNumbers(0, startDateValue.minutes()+1);
        }
    }

    const leftCalendar = <Calendar
        onChange={(value)=>{
            let newStartDate = value;
            if(endDateValue && newStartDate.valueOf() >= endDateValue.valueOf()){
                newStartDate = endDateValue.clone().subtract(1, 'm');
            }
            onChange([newStartDate, endDateValue as any], recalculateShiftCount);
        }}
        value={startDateValue}
        renderDate={renderDate}
        onOk={()=>{
            setStartDateCalendarOpen(false);
        }}
        onTodayButtonClick={onStartDateTodayButtonClick}
        onTodayTimeButtonClick={onStartDateTodayButtonClick1}
        recalculateShiftCountBlockProps={_recalculateShiftCountBlockProps}
        disabledDate={(current)=>{
            if(startDateValue && endDateValue){
                const d = current.clone().hours(startDateValue.hours()).minutes(startDateValue.minutes()).seconds(startDateValue.seconds()).milliseconds(startDateValue.milliseconds());
                return d.valueOf() >= endDateValue.valueOf();
            }
            return true;
        }}
        disabledHours={disabledStartHours}
        disabledMinutes={disabledStartMinutes}
        selectionDisabled={props.selectionDisabled}
    />;

    const rightCalendar = <Calendar
        onChange={(value)=>{
            let newEndDate = value;
            if(startDateValue && newEndDate.valueOf() <= startDateValue.valueOf()){
                newEndDate = startDateValue.clone().add(1, 'm');
            }
            onChange([startDateValue as any, newEndDate], recalculateShiftCount);
        }}
        value={endDateValue}
        renderDate={renderDate}
        onOk={()=>{
            setEndDateCalendarOpen(false);
        }}
        onTodayButtonClick={onEndDateTodayButtonClick}
        onTodayTimeButtonClick={onEndDateTodayButtonClick1}
        recalculateShiftCountBlockProps={_recalculateShiftCountBlockProps}
        disabledDate={(current)=>{
            if(startDateValue && endDateValue){
                const d = current.clone().hours(endDateValue.hours()).minutes(endDateValue.minutes()).seconds(endDateValue.seconds()).milliseconds(endDateValue.milliseconds());
                return d.valueOf() <= startDateValue.valueOf();
            }
            return true;
        }}
        disabledHours={disabledEndHours}
        disabledMinutes={disabledEndMinutes}
        selectionDisabled={props.selectionDisabled}
    />;

    return (
        <Picker
            align={{ points: ['bc', 'tc'], offset: [0, -6] }}
            calendar={rangeCalendar}
            open={rangeCalendarOpen}
            onOpenChange={(open)=>{
                if(!open) setRangeCalendarOpen(false);
            }}
            getPopupContainer={props.getPopupContainer}
        >
            <div style={props.style} className={classNames(mainClassName, props.disabled && mainDisabledClassName, 'ant-input', props.disabled && 'ant-input-disabled', s1 && 'rr-range-picker-hover', isRangeSelected && mainRangeSelectedClassName, props.allowClear && mainAllowClearClassName, props.className)} onClick={pickerOnClick}>
                <input style={{display: 'inline-block', width: 1, border: 0, height: 36, opacity: 0}}/>
                <div className={'rr-range-picker-bg'}></div>
                <div className={'rr-range-picker-content'}>

                    <div style={{flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <div style={props.startDateLabel ? undefined : {pointerEvents: 'auto'}}>
                            <Picker
                                align={{ points: ['bc', 'tc'], offset: [0, -17] }}
                                calendar={leftCalendar}
                                open={startDateCalendarOpen}
                                onOpenChange={(open)=>{
                                    if(!open) setStartDateCalendarOpen(false);
                                }}
                                getPopupContainer={props.getPopupContainer}
                            >
                                {
                                    props.startDateLabel ? (
                                        <div style={{ display: 'inline-block' }}>
                                            {props.startDateLabel}
                                        </div>
                                    ) : (
                                        <input
                                            disabled={props.disabled}
                                            placeholder={startDatePlaceHolder}
                                            className={inputClassName}
                                            onClick={startDateInputOnClick}
                                            value={startDateInputValue}
                                            readOnly={true}
                                        />
                                    )
                                }
                            </Picker>
                        </div>
                    </div>
                    <div>
                        <span className={separatorClassName}>→</span>
                    </div>
                    <div style={{flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <div style={props.endDateLabel ? undefined : {pointerEvents: 'auto'}}>
                            <Picker
                                align={{ points: ['bc', 'tc'], offset: [0, -17] }}
                                calendar={rightCalendar}
                                open={endDateCalendarOpen}
                                onOpenChange={(open)=>{
                                    if(!open) setEndDateCalendarOpen(false);
                                }}
                                getPopupContainer={props.getPopupContainer}
                            >
                                {
                                    props.endDateLabel ? (
                                        <div style={{ display: 'inline-block' }}>
                                            {props.endDateLabel}
                                        </div>
                                    ) : (
                                        <input
                                            disabled={props.disabled}
                                            placeholder={endDatePlaceHolder}
                                            className={inputClassName}
                                            onClick={endDateInputOnClick}
                                            value={endDateInputValue}
                                            readOnly={true}
                                        />
                                    )
                                }
                            </Picker>
                        </div>
                    </div>
                    <div style={{justifyContent: 'end'}}>
                        <span className={`${mainClassName}-icons`} style={{pointerEvents: 'auto'}} onMouseEnter={()=>{setS1(true)}} onMouseLeave={()=>{setS1(false)}}>
                            <Icon className={calendarIconClassName} component={IconCalendar} />
                            {props.allowClear && <Icon className={clearIconClassName} component={IconClose} onClick={onClearIconClick} />}
                        </span>
                    </div>
                </div>
            </div>
        </Picker>
    );
};

import React from 'react';
import {listElements,} from './reducers/elements.reducer';
import {
    removeConcurrentOperation,
    resetOperation,
    setElementsListParams,
    startNewOperation,
} from '../operationForm/reducers/operationForm.reducer';
import {connect} from 'react-redux';
import {IRootState} from '../../../shared/reducers';
import {push, replace} from 'connected-react-router';
import {getStringServerProblem,} from '../../../shared/util/utils';
import {ProjectInfoRead, RentActivityFrameTypeCodeEnum,} from '../../../server';
import {createNewSimpleOperation} from './create-operation-util';
import {showConfirm} from '../../../components/confirm/showConfirm';
import {showNotification} from '../../../components/notification/showNotification';
import {localizeIntl} from '../../../localization/localizationUtils';
import {LocalizationEnum} from '../../../localization';
import {injectIntl, WrappedComponentProps} from 'react-intl';
import {convertRentElementRecordToRentElementsGridItem, RentElementsGridItem} from '../../../types';
import {listConcurrentOperationsAndShowConfirm} from '../operationForm/listConcurrentOperationsAndShowConfirm';
import {sortByForEditOperationElements} from "../operationForm/reducers/operationForm.reducer.utils";
import {EditButton} from "../../../components/buttons/editButton/editButton";

export interface ElementsListProps extends StateProps, DispatchProps, WrappedComponentProps {
    parentId: number;
    parentType: 'project' | 'operation' | 'projectTemplate' | 'shipping';
    order?: ProjectInfoRead;
}

class Component extends React.Component<ElementsListProps> {

    getActivityFrameData = () => {
        let activityFrameType: RentActivityFrameTypeCodeEnum | undefined;
        let activityFrameId: number | undefined;
        if (this.props.parentType === 'operation') {
            activityFrameId = this.props.operation?.projectId || this.props.operation?.subrentId || this.props.operation?.templateId;
            if (this.props.operation?.projectId) activityFrameType = RentActivityFrameTypeCodeEnum.PROJECT;
            else if (this.props.operation?.subrentId) activityFrameType = RentActivityFrameTypeCodeEnum.SUBRENT;
            else if (this.props.operation?.templateId) activityFrameType = RentActivityFrameTypeCodeEnum.TEMPLATE;
        } else if (this.props.parentType === 'project') {
            activityFrameId = this.props.parentId;
            activityFrameType = RentActivityFrameTypeCodeEnum.PROJECT;
        } else if (this.props.parentType === 'shipping') {
            activityFrameId = this.props.parentId;
            activityFrameType = RentActivityFrameTypeCodeEnum.SUBRENT;
        } else if (this.props.parentType === 'projectTemplate') {
            activityFrameId = this.props.parentId;
            activityFrameType = RentActivityFrameTypeCodeEnum.TEMPLATE;
        }

        return {
            activityFrameId,
            activityFrameType,
        };
    };

    renderOperationEditButton = (selectedItems: RentElementsGridItem[]|undefined, canEdit: boolean, forFilters = true) => {
        return (
            <EditButton
                disabled={!canEdit}
                style={forFilters ? { height: 38, lineHeight: '38px'} : undefined}
                onClick={async () => {
                    let canCreate = true;
                    // Спросить
                    if (this.props.operationFormMnemoKey) {
                        canCreate = await showConfirm(
                            this.props.intl,
                            localizeIntl(this.props.intl, LocalizationEnum.PAGE__OPERATIONS__MODAL__QUESTIONS__CANCEL)
                        );
                    }

                    if (canCreate) {
                        try {
                            const { activityFrameId, activityFrameType } = this.getActivityFrameData();

                            if (activityFrameId && activityFrameType) {
                                canCreate = await listConcurrentOperationsAndShowConfirm(
                                    this.props.intl,
                                    this.props.businessAccountId,
                                    activityFrameId,
                                    activityFrameType
                                );
                            }
                        } catch (e) {
                            showNotification('error', 'Ошибка');
                            return;
                        }
                    }

                    if(canCreate){
                        const selectedIds = selectedItems?.map((item)=>item.id);
                        //this.setState({ loading: true });
                        try{
                            // Тут нужно все все все загрузить без пагинации
                            const sortBy = this.props.pageParams.sortBy === 'problemSeverity' ? 'problemsAndWarnings.severity' : this.props.pageParams.sortBy;

                            const filters:string[] = [];
                            //if (this.props.categoryIds !== undefined) filters.push('categoryIds;IN;' + this.props.categoryIds);
                            if (this.props.pageParams.problem) filters.push(getStringServerProblem(this.props.pageParams.problem));

                            const data = await listElements({
                                parentEntityType: this.props.parentType,
                                businessAccountId: this.props.businessAccountId,
                                limit: undefined,
                                startFrom: 0,
                                sortBy: sortBy,
                                sortOrder: this.props.pageParams.sortOrder,
                                search: this.props.pageParams.search,
                                excludeAggregations: true,
                                excludeRecords: false,
                                projectId: this.props.simpleOrderElementsPageParams.projectId || this.props.order?.id || 0,
                                filters: filters.length ? filters : undefined
                            });
                            const entities: RentElementsGridItem[] = data.records.map((item) => {
                                return convertRentElementRecordToRentElementsGridItem(item);
                            });
                            let allElements:RentElementsGridItem[] = [];
                            entities.forEach((e)=>{
                                allElements.push(e);
                                if(e.subRows){
                                    e.subRows.forEach(sr=>{
                                        allElements.push(sr);
                                    })
                                }
                            });

                            let startDate = this.props.pageParams.startDate;
                            let endDate = this.props.pageParams.endDate;
                            if(!startDate?.[0] && !startDate?.[1]) startDate = [undefined, undefined];
                            if(!endDate?.[0] && !endDate?.[1]) endDate = [undefined, undefined];

                            const categoriesFilter = this.props.pageParams.categoryIds ? ('' + this.props.pageParams.categoryIds).split(';').map(id=>+id) : undefined;

                            await createNewSimpleOperation({
                                selectedItems: allElements,
                                businessAccountId: this.props.businessAccountId,
                                projectTemplate: this.props.projectTemplate,
                                filters: {
                                    typeCode: this.props.pageParams.typeCode,
                                    tags: this.props.pageParams.tags,
                                    hide: this.props.pageParams.hide,
                                    problem: this.props.pageParams.problem,
                                    startDate: startDate,
                                    endDate: endDate,
                                    discount: this.props.pageParams.discount,
                                    shiftCount: this.props.pageParams.shiftCount,
                                    finalTotalPrice: this.props.pageParams.finalTotalPrice,
                                    categoryIds: categoriesFilter,
                                    //selectedIds: selectedIds,
                                },
                                order: this.props.order,
                                selectedIds: selectedIds,
                                availableFilters: {}//this.availableFilters
                            });

                            // this.props.setElementsListParams({
                            //     page: 1,
                            //     sortOrder: this.props.pageParams.sortOrder,
                            //     sortBy: this.props.pageParams.sortBy && sortByForEditOperationElements(this.props.pageParams.sortBy as any),
                            //     limit: this.props.pageParams.limit
                            // });
                            //this.props.push(getPathFromState(this.props.location.pathname, this.props.location.search, { page: 1 }));
                            //this.clearSelection();
                            //this.filtersForm?.setFieldsValue({selectedIds: selectedIds});
                        }catch(error){
                            //console.log('EEE', error);
                            showNotification('error', 'Ошибка');
                        }
                        //this.setState({ loading: false });
                    }
                }}
            />
        );
    }

    render() {
        return this.renderOperationEditButton(undefined, true);
    }
}

const mapStateToProps = (storeState: IRootState) => {
    return {
        operation: storeState.operation.entity,
        pageParams: storeState.elements.params,
        simpleOrderElementsPageParams: storeState.simpleOrderElements.params,
        businessAccountId: storeState.system.businessAccountId,
        operationFormMnemoKey: storeState.operationForm.mnemoKey,
        operationTypeCode: storeState.operationForm.typeCode,
        currentOperationUUID: storeState.operationForm.currentOperationUUID,
        projectTemplate: storeState.operationForm.projectTemplate,
    };
};

const mapDispatchToProps = {
    push,
    setElementsListParams
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const EditOperationButton = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    injectIntl(Component, { forwardRef: true })
);

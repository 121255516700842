import { HeaderTitle, HeaderTitleLink } from '../../../components/pageHeader/components/headerTitle/headerTitle';
import React, { FC } from 'react';
import {
    linkToCounterpartiesPage,
    linkToCounterpartyPage,
    linkToCounterpartyProjectsPage,
    linkToCounterpartyShippingsPage,
} from '../renters/data/tabs';
import { LocalizationEnum, localize } from '../../../localization';
import { SubrentPageTabsEnum } from '../../../shared/constants/tabEnums';
import { projectsPageUrlRoute } from '../projects/projectsPage';
import { OperationInfoRead } from '../../../server';
import { useAppSelector } from '../../../store/hooks';
import { businessAccountIdSelector } from '../../../shared/reducers/system.reducer';
import { Icon } from 'antd';
import { subrentPageUrlRoute } from '../subrent/subrentPage';
import moment from 'moment';
import { Status } from '../../../components/status/status';
import { getOperationStatusTypeData } from '../operationForm/utils/utils';

interface OperationPageHeaderTitleProps {
    operation?: OperationInfoRead;
}

export const OperationPageHeaderTitle: FC<OperationPageHeaderTitleProps> = ({ operation: entity }) => {
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    if (!entity) return null;

    const operation = getOperationStatusTypeData(entity.typeCode, true);

    let links: HeaderTitleLink[] | undefined;

    const linkToCounterparties = linkToCounterpartiesPage({ businessAccountId });
    const linkToCounterparty = linkToCounterpartyPage({ businessAccountId, counterpartyId: entity.counterpartyId });

    if (entity.projectId && !entity.projectIsOffer) {
        let linkToProjects: HeaderTitleLink;

        if (entity.projectIsSimpleOrder) {
            linkToProjects = {
                name: 'Заказы',
                link: linkToCounterpartyProjectsPage({ businessAccountId, counterpartyId: entity.counterpartyId }),
            };
        } else {
            linkToProjects = {
                name: localize(LocalizationEnum.ASPECT__MAIN_MENU__PROJECTS),
                link: linkToCounterpartyProjectsPage({ businessAccountId, counterpartyId: entity.counterpartyId }),
            };
        }

        links = [
            { name: localize(LocalizationEnum.ASPECT__ENTITY_TYPE__COUNTERPARTIES), link: linkToCounterparties },
            { name: entity.counterpartyShortName, link: linkToCounterparty, bold: true },
            linkToProjects,
            {
                name: entity.projectShortName || '',
                link: `/${businessAccountId}/${projectsPageUrlRoute}/production/${entity.projectId}`,
                bold: true,
            },
            { name: 'Операции', link: `/${businessAccountId}/${projectsPageUrlRoute}/production/${entity.projectId}?tab=operations` },
        ];
    } else if (entity.projectId && entity.projectIsOffer) {
        links = [
            { name: localize(LocalizationEnum.ASPECT__ENTITY_TYPE__COUNTERPARTIES), link: linkToCounterparties },
            { name: entity.counterpartyShortName, link: linkToCounterparty, bold: true },
            {
                name: localize(LocalizationEnum.ASPECT__MAIN_MENU__PROJECTS),
                link: linkToCounterpartyProjectsPage({ businessAccountId, counterpartyId: entity.counterpartyId }),
            },
            {
                name: entity.mainProjectShortName!,
                link: `/${businessAccountId}/${projectsPageUrlRoute}/production/${entity.mainProjectId}`,
                bold: true,
            },
            { name: 'Сметы', link: `/${businessAccountId}/projects/production/${entity.mainProjectId}?tab=offers` },
            { name: entity.projectShortName!, link: `/${businessAccountId}/projects/offers/${entity.projectId}`, bold: true },
            { name: 'Операции', link: `/${businessAccountId}/projects/offers/${entity.projectId}?tab=operations` },
        ];
    } else if (entity.subrentId) {
        links = [
            { name: localize(LocalizationEnum.ASPECT__ENTITY_TYPE__COUNTERPARTIES), link: linkToCounterparties },
            { name: entity.counterpartyShortName, link: linkToCounterparty, bold: true },
            { name: 'Поставки', link: linkToCounterpartyShippingsPage({ businessAccountId, counterpartyId: entity.counterpartyId }) },
            {
                name: entity.subrentShortName!,
                link: `/${businessAccountId}/${subrentPageUrlRoute}/${SubrentPageTabsEnum.SHIPPINGS}/${entity.subrentId}`,
                bold: true,
            },
            {
                name: 'Операции',
                link: `/${businessAccountId}/${subrentPageUrlRoute}/${SubrentPageTabsEnum.SHIPPINGS}/${entity.subrentId}?tab=operations`,
            },
        ];
    } else if (entity.templateId) {
        links = [
            { name: 'Аренда', link: `/${businessAccountId}/projects` },
            { name: 'Шаблоны', link: `/${businessAccountId}/projects/templates` },
            { name: entity.templateShortName!, link: `/${businessAccountId}/projects/templates/${entity.templateId}`, bold: true },
            { name: 'Операции', link: `/${businessAccountId}/projects/templates/${entity.templateId}?tab=operations` },
        ];
    }

    return (
        <HeaderTitle
            icon={<Icon component={operation.iconComponent} className={'rr-operation-status-color-' + entity.typeCode} />}
            title={
                <span>
                    {operation.name} <span style={{ fontWeight: 500 }}>{moment(entity.creationDate).format('DD MMMM YYYY HH:mm')}</span>
                </span>
            }
            archive={entity.archive}
            links={links}
            badge={<Status entityType={'operation'} code={entity.targetStateCode} />}
        />
    );
};

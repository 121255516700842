/* eslint-disable */
// @ts-nocheck
/**
 * RentRabbit Api Combined: Internal and Public
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.35.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * Абстрактная сущность с метаинформацией
 * @export
 * @interface AbstractEntityInfo
 */
export interface AbstractEntityInfo {
    /**
     * Идентификатор
     * @type {number}
     * @memberof AbstractEntityInfo
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof AbstractEntityInfo
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof AbstractEntityInfo
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof AbstractEntityInfo
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof AbstractEntityInfo
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof AbstractEntityInfo
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof AbstractEntityInfo
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof AbstractEntityInfo
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof AbstractEntityInfo
     */
    comment?: string;
}
/**
 * Абстрактная запись о сущности (Списочное представление)
 * @export
 * @interface AbstractEntityRecord
 */
export interface AbstractEntityRecord {
    /**
     * Идентификатор
     * @type {number}
     * @memberof AbstractEntityRecord
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof AbstractEntityRecord
     */
    businessVersion: number;
}
/**
 * Абстрактный список записей
 * @export
 * @interface AbstractEntityRecordList
 */
export interface AbstractEntityRecordList {
    /**
     * 
     * @type {ListAttributesInfo}
     * @memberof AbstractEntityRecordList
     */
    listAttributes: ListAttributesInfo;
    /**
     * 
     * @type {object}
     * @memberof AbstractEntityRecordList
     */
    records: object;
}
/**
 * Ссылка на рамку активности - проект, поставку или шаблон проекта
 * @export
 * @interface ActivityFrameObj
 */
export interface ActivityFrameObj {
    /**
     * Идентификатор рамки активности
     * @type {number}
     * @memberof ActivityFrameObj
     */
    id: number;
    /**
     * 
     * @type {RentActivityFrameTypeCodeEnum}
     * @memberof ActivityFrameObj
     */
    type: RentActivityFrameTypeCodeEnum;
}
/**
 * Короткий и полный адрес
 * @export
 * @interface AddressSuggestion
 */
export interface AddressSuggestion {
    /**
     * Короткий адрес для предложений
     * @type {string}
     * @memberof AddressSuggestion
     */
    shortValue: string;
    /**
     * Полный адрес с индексом и районом
     * @type {string}
     * @memberof AddressSuggestion
     */
    fullValue: string;
}
/**
 * Бизнес-тип документа
 * @export
 * @enum {string}
 */
export enum AttachmentDocumentTypeCodeEnum {
    CONTRACT = 'CONTRACT'
}

/**
 * Вложение: абстрактное общее состояние для всех операций
 * @export
 * @interface AttachmentInfoAbstractCommons
 */
export interface AttachmentInfoAbstractCommons {
    /**
     * Идентификатор
     * @type {number}
     * @memberof AttachmentInfoAbstractCommons
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof AttachmentInfoAbstractCommons
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof AttachmentInfoAbstractCommons
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof AttachmentInfoAbstractCommons
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof AttachmentInfoAbstractCommons
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof AttachmentInfoAbstractCommons
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof AttachmentInfoAbstractCommons
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof AttachmentInfoAbstractCommons
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof AttachmentInfoAbstractCommons
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof AttachmentInfoAbstractCommons
     */
    businessAccountId?: number;
    /**
     * Содержит финансовую информацию
     * @type {boolean}
     * @memberof AttachmentInfoAbstractCommons
     */
    financial: boolean;
}
/**
 * 
 * @export
 * @interface AttachmentInfoAbstractCommonsAllOf
 */
export interface AttachmentInfoAbstractCommonsAllOf {
    /**
     * Содержит финансовую информацию
     * @type {boolean}
     * @memberof AttachmentInfoAbstractCommonsAllOf
     */
    financial: boolean;
}
/**
 * Вложение: состояние изменения
 * @export
 * @interface AttachmentInfoCreate
 */
export interface AttachmentInfoCreate {
    /**
     * Идентификатор
     * @type {number}
     * @memberof AttachmentInfoCreate
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof AttachmentInfoCreate
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof AttachmentInfoCreate
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof AttachmentInfoCreate
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof AttachmentInfoCreate
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof AttachmentInfoCreate
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof AttachmentInfoCreate
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof AttachmentInfoCreate
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof AttachmentInfoCreate
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof AttachmentInfoCreate
     */
    businessAccountId?: number;
    /**
     * Содержит финансовую информацию
     * @type {boolean}
     * @memberof AttachmentInfoCreate
     */
    financial: boolean;
    /**
     * 
     * @type {AttachmentParentEntityTypeCodeEnum}
     * @memberof AttachmentInfoCreate
     */
    parentEntityTypeCode?: AttachmentParentEntityTypeCodeEnum;
    /**
     * Идентификатор родительской сущности
     * @type {number}
     * @memberof AttachmentInfoCreate
     */
    parentEntityId: number;
    /**
     * 
     * @type {AttachmentDocumentTypeCodeEnum}
     * @memberof AttachmentInfoCreate
     */
    documentType?: AttachmentDocumentTypeCodeEnum;
}
/**
 * 
 * @export
 * @interface AttachmentInfoCreateAllOf
 */
export interface AttachmentInfoCreateAllOf {
    /**
     * 
     * @type {AttachmentParentEntityTypeCodeEnum}
     * @memberof AttachmentInfoCreateAllOf
     */
    parentEntityTypeCode?: AttachmentParentEntityTypeCodeEnum;
    /**
     * Идентификатор родительской сущности
     * @type {number}
     * @memberof AttachmentInfoCreateAllOf
     */
    parentEntityId: number;
    /**
     * 
     * @type {AttachmentDocumentTypeCodeEnum}
     * @memberof AttachmentInfoCreateAllOf
     */
    documentType?: AttachmentDocumentTypeCodeEnum;
}
/**
 * Вложение: состояние чтения
 * @export
 * @interface AttachmentInfoRead
 */
export interface AttachmentInfoRead {
    /**
     * Идентификатор
     * @type {number}
     * @memberof AttachmentInfoRead
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof AttachmentInfoRead
     */
    businessVersion: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof AttachmentInfoRead
     */
    creationAuthorId: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof AttachmentInfoRead
     */
    creationAuthorFullName: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof AttachmentInfoRead
     */
    creationDate: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof AttachmentInfoRead
     */
    lastUpdateAuthorId: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof AttachmentInfoRead
     */
    lastUpdateAuthorFullName: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof AttachmentInfoRead
     */
    lastUpdateDate: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof AttachmentInfoRead
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof AttachmentInfoRead
     */
    businessAccountId: number;
    /**
     * Содержит финансовую информацию
     * @type {boolean}
     * @memberof AttachmentInfoRead
     */
    financial: boolean;
    /**
     * Название файла (с расширением)
     * @type {string}
     * @memberof AttachmentInfoRead
     */
    fileName: string;
    /**
     * 
     * @type {FileExtensionTypeCodeEnum}
     * @memberof AttachmentInfoRead
     */
    fileExtensionTypeCode: FileExtensionTypeCodeEnum;
    /**
     * 
     * @type {FileTypeCodeEnum}
     * @memberof AttachmentInfoRead
     */
    fileTypeCode?: FileTypeCodeEnum;
    /**
     * 
     * @type {AttachmentDocumentTypeCodeEnum}
     * @memberof AttachmentInfoRead
     */
    documentType?: AttachmentDocumentTypeCodeEnum;
    /**
     * Размер файла в байтах
     * @type {number}
     * @memberof AttachmentInfoRead
     */
    fileSize: number;
    /**
     * Относительный путь URL для скачивания файла
     * @type {string}
     * @memberof AttachmentInfoRead
     */
    relativeDownloadURL: string;
    /**
     * 
     * @type {AttachmentParentEntityTypeCodeEnum}
     * @memberof AttachmentInfoRead
     */
    parentEntityTypeCode?: AttachmentParentEntityTypeCodeEnum;
    /**
     * Идентификатор родительской сущности
     * @type {number}
     * @memberof AttachmentInfoRead
     */
    parentEntityId: number;
}
/**
 * 
 * @export
 * @interface AttachmentInfoReadAllOf
 */
export interface AttachmentInfoReadAllOf {
    /**
     * Название файла (с расширением)
     * @type {string}
     * @memberof AttachmentInfoReadAllOf
     */
    fileName: string;
    /**
     * 
     * @type {FileExtensionTypeCodeEnum}
     * @memberof AttachmentInfoReadAllOf
     */
    fileExtensionTypeCode: FileExtensionTypeCodeEnum;
    /**
     * 
     * @type {FileTypeCodeEnum}
     * @memberof AttachmentInfoReadAllOf
     */
    fileTypeCode?: FileTypeCodeEnum;
    /**
     * 
     * @type {AttachmentDocumentTypeCodeEnum}
     * @memberof AttachmentInfoReadAllOf
     */
    documentType?: AttachmentDocumentTypeCodeEnum;
    /**
     * Размер файла в байтах
     * @type {number}
     * @memberof AttachmentInfoReadAllOf
     */
    fileSize: number;
    /**
     * Относительный путь URL для скачивания файла
     * @type {string}
     * @memberof AttachmentInfoReadAllOf
     */
    relativeDownloadURL: string;
    /**
     * 
     * @type {AttachmentParentEntityTypeCodeEnum}
     * @memberof AttachmentInfoReadAllOf
     */
    parentEntityTypeCode?: AttachmentParentEntityTypeCodeEnum;
    /**
     * Идентификатор родительской сущности
     * @type {number}
     * @memberof AttachmentInfoReadAllOf
     */
    parentEntityId: number;
}
/**
 * Вложение: состояние изменения
 * @export
 * @interface AttachmentInfoUpdate
 */
export interface AttachmentInfoUpdate {
    /**
     * Идентификатор
     * @type {number}
     * @memberof AttachmentInfoUpdate
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof AttachmentInfoUpdate
     */
    businessVersion: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof AttachmentInfoUpdate
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof AttachmentInfoUpdate
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof AttachmentInfoUpdate
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof AttachmentInfoUpdate
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof AttachmentInfoUpdate
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof AttachmentInfoUpdate
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof AttachmentInfoUpdate
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof AttachmentInfoUpdate
     */
    businessAccountId?: number;
    /**
     * Содержит финансовую информацию
     * @type {boolean}
     * @memberof AttachmentInfoUpdate
     */
    financial: boolean;
    /**
     * Название файла (с расширением)
     * @type {string}
     * @memberof AttachmentInfoUpdate
     */
    fileName: string;
}
/**
 * 
 * @export
 * @interface AttachmentInfoUpdateAllOf
 */
export interface AttachmentInfoUpdateAllOf {
    /**
     * Название файла (с расширением)
     * @type {string}
     * @memberof AttachmentInfoUpdateAllOf
     */
    fileName: string;
}
/**
 * Тип сущности, к которой относится вложение
 * @export
 * @enum {string}
 */
export enum AttachmentParentEntityTypeCodeEnum {
    VEHICLE = 'VEHICLE',
    LOCATION = 'LOCATION',
    PRODUCT = 'PRODUCT',
    RENTER = 'RENTER',
    PROJECT = 'PROJECT',
    SUBRENT = 'SUBRENT',
    PAYMENT = 'PAYMENT',
    CONTACT = 'CONTACT',
    TRANSPORTATION = 'TRANSPORTATION',
    INVENTORYMOVEMENT = 'INVENTORY_MOVEMENT',
    PROFESSION = 'PROFESSION',
    CREWMEMBER = 'CREW_MEMBER',
    EXPENSE = 'EXPENSE'
}

/**
 * Списочное представление вложения
 * @export
 * @interface AttachmentRecord
 */
export interface AttachmentRecord {
    /**
     * Идентификатор
     * @type {number}
     * @memberof AttachmentRecord
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof AttachmentRecord
     */
    businessVersion: number;
    /**
     * Ссылка: Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof AttachmentRecord
     */
    businessAccountId: number;
    /**
     * Название файла (с расширением)
     * @type {string}
     * @memberof AttachmentRecord
     */
    fileName: string;
    /**
     * Содержит финансовую информацию
     * @type {boolean}
     * @memberof AttachmentRecord
     */
    financial: boolean;
    /**
     * 
     * @type {FileExtensionTypeCodeEnum}
     * @memberof AttachmentRecord
     */
    fileExtensionTypeCode: FileExtensionTypeCodeEnum;
    /**
     * 
     * @type {FileTypeCodeEnum}
     * @memberof AttachmentRecord
     */
    fileTypeCode?: FileTypeCodeEnum;
    /**
     * Размер файла в байтах
     * @type {number}
     * @memberof AttachmentRecord
     */
    fileSize: number;
    /**
     * Относительный путь URL для скачивания файла
     * @type {string}
     * @memberof AttachmentRecord
     */
    relativeDownloadURL: string;
    /**
     * 
     * @type {AttachmentParentEntityTypeCodeEnum}
     * @memberof AttachmentRecord
     */
    parentEntityTypeCode?: AttachmentParentEntityTypeCodeEnum;
    /**
     * Идентификатор родительской сущности
     * @type {number}
     * @memberof AttachmentRecord
     */
    parentEntityId: number;
    /**
     * 
     * @type {AttachmentDocumentTypeCodeEnum}
     * @memberof AttachmentRecord
     */
    documentType?: AttachmentDocumentTypeCodeEnum;
}
/**
 * 
 * @export
 * @interface AttachmentRecordAllOf
 */
export interface AttachmentRecordAllOf {
    /**
     * Название файла (с расширением)
     * @type {string}
     * @memberof AttachmentRecordAllOf
     */
    fileName: string;
    /**
     * Содержит финансовую информацию
     * @type {boolean}
     * @memberof AttachmentRecordAllOf
     */
    financial: boolean;
    /**
     * 
     * @type {FileExtensionTypeCodeEnum}
     * @memberof AttachmentRecordAllOf
     */
    fileExtensionTypeCode: FileExtensionTypeCodeEnum;
    /**
     * 
     * @type {FileTypeCodeEnum}
     * @memberof AttachmentRecordAllOf
     */
    fileTypeCode?: FileTypeCodeEnum;
    /**
     * Размер файла в байтах
     * @type {number}
     * @memberof AttachmentRecordAllOf
     */
    fileSize: number;
    /**
     * Относительный путь URL для скачивания файла
     * @type {string}
     * @memberof AttachmentRecordAllOf
     */
    relativeDownloadURL: string;
    /**
     * 
     * @type {AttachmentParentEntityTypeCodeEnum}
     * @memberof AttachmentRecordAllOf
     */
    parentEntityTypeCode?: AttachmentParentEntityTypeCodeEnum;
    /**
     * Идентификатор родительской сущности
     * @type {number}
     * @memberof AttachmentRecordAllOf
     */
    parentEntityId: number;
    /**
     * 
     * @type {AttachmentDocumentTypeCodeEnum}
     * @memberof AttachmentRecordAllOf
     */
    documentType?: AttachmentDocumentTypeCodeEnum;
}
/**
 * Список записей вложений
 * @export
 * @interface AttachmentRecordList
 */
export interface AttachmentRecordList {
    /**
     * 
     * @type {ListAttributesInfo}
     * @memberof AttachmentRecordList
     */
    listAttributes: ListAttributesInfo;
    /**
     * 
     * @type {Array<AttachmentRecord>}
     * @memberof AttachmentRecordList
     */
    records: Array<AttachmentRecord>;
}
/**
 * 
 * @export
 * @interface AttachmentRecordListAllOf
 */
export interface AttachmentRecordListAllOf {
    /**
     * 
     * @type {Array<AttachmentRecord>}
     * @memberof AttachmentRecordListAllOf
     */
    records: Array<AttachmentRecord>;
}
/**
 * Доступные значений для фильтра
 * @export
 * @interface AvailableFilterValuesObj
 */
export interface AvailableFilterValuesObj {
    /**
     * Наименование поля
     * @type {string}
     * @memberof AvailableFilterValuesObj
     */
    fieldName: string;
    /**
     * 
     * @type {AvailableFilterValuesObjTypeCodeEnum}
     * @memberof AvailableFilterValuesObj
     */
    type: AvailableFilterValuesObjTypeCodeEnum;
    /**
     * Минимальное целочисленное значение, при пустом списке - null
     * @type {number}
     * @memberof AvailableFilterValuesObj
     */
    minLong?: number;
    /**
     * Максимальное целочисленное значение, при пустом списке - null
     * @type {number}
     * @memberof AvailableFilterValuesObj
     */
    maxLong?: number;
    /**
     * Список возможных строковых значений
     * @type {Array<string>}
     * @memberof AvailableFilterValuesObj
     */
    strings?: Array<string>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum AvailableFilterValuesObjTypeCodeEnum {
    MINMAX = 'MIN_MAX',
    MIN = 'MIN',
    MAX = 'MAX',
    STRINGS = 'STRINGS'
}

/**
 * Список доступных значений для множества фильтров
 * @export
 * @interface AvailableFiltersValuesListObj
 */
export interface AvailableFiltersValuesListObj {
    /**
     * 
     * @type {Array<AvailableFilterValuesObj>}
     * @memberof AvailableFiltersValuesListObj
     */
    filters: Array<AvailableFilterValuesObj>;
}
/**
 * Список доступных значений для множества фильтров
 * @export
 * @interface AvailableFiltersValuesWithCustomFieldsListObj
 */
export interface AvailableFiltersValuesWithCustomFieldsListObj {
    /**
     * 
     * @type {AvailableFiltersValuesListObj}
     * @memberof AvailableFiltersValuesWithCustomFieldsListObj
     */
    availableFiltersValues: AvailableFiltersValuesListObj;
    /**
     * 
     * @type {Array<CustomFieldObjRead>}
     * @memberof AvailableFiltersValuesWithCustomFieldsListObj
     */
    customFieldMarkers: Array<CustomFieldObjRead>;
}
/**
 * Type of axis. This information not for generation, but for users. For proper setup of settings!
 * @export
 * @enum {string}
 */
export enum AxisTypeEnum {
    CATEGORYAXIS = 'CATEGORY_AXIS',
    CATEGORYDATEAXIS = 'CATEGORY_DATE_AXIS',
    DATEAXIS = 'DATE_AXIS',
    GAPLESSDATEAXIS = 'GAPLESS_DATE_AXIS',
    VALUEAXIS = 'VALUE_AXIS',
    DURATIONAXIS = 'DURATION_AXIS'
}

/**
 * Запись о пользователе БА(в том числе его роли в целевом БА)
 * @export
 * @interface BAUserRecord
 */
export interface BAUserRecord {
    /**
     * Идентификатор
     * @type {number}
     * @memberof BAUserRecord
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof BAUserRecord
     */
    businessVersion: number;
    /**
     * Является ли спец. пользователем - системой
     * @type {boolean}
     * @memberof BAUserRecord
     */
    specialSystemUser: boolean;
    /**
     * Наименование/логин пользователя
     * @type {string}
     * @memberof BAUserRecord
     */
    username: string;
    /**
     * Электронная почта - основной внешний идентификатор пользователя, уникальный
     * @type {string}
     * @memberof BAUserRecord
     */
    email: string;
    /**
     * 
     * @type {PersonFullName}
     * @memberof BAUserRecord
     */
    fullName: PersonFullName;
    /**
     * Короткое ФИО - фамилия и инициалы
     * @type {string}
     * @memberof BAUserRecord
     */
    shortName: string;
    /**
     * 
     * @type {UserStateCodeEnum}
     * @memberof BAUserRecord
     */
    stateCode: UserStateCodeEnum;
    /**
     * Подготовленный номер телефона
     * @type {string}
     * @memberof BAUserRecord
     */
    phoneNumber?: string;
    /**
     * Идентификатор контакта в конкретном БА
     * @type {number}
     * @memberof BAUserRecord
     */
    contactId?: number;
    /**
     * Роли в целевом бизнес аккаунте
     * @type {Array<UserBARoleCodeEnum>}
     * @memberof BAUserRecord
     */
    baRoles: Array<UserBARoleCodeEnum>;
}
/**
 * 
 * @export
 * @interface BAUserRecordAllOf
 */
export interface BAUserRecordAllOf {
    /**
     * Роли в целевом бизнес аккаунте
     * @type {Array<UserBARoleCodeEnum>}
     * @memberof BAUserRecordAllOf
     */
    baRoles: Array<UserBARoleCodeEnum>;
}
/**
 * Список записей о пользователях в БА(в том числе их роли в целевом БА)
 * @export
 * @interface BAUserRecordList
 */
export interface BAUserRecordList {
    /**
     * 
     * @type {ListAttributesInfo}
     * @memberof BAUserRecordList
     */
    listAttributes: ListAttributesInfo;
    /**
     * 
     * @type {Array<BAUserRecord>}
     * @memberof BAUserRecordList
     */
    records: Array<BAUserRecord>;
}
/**
 * 
 * @export
 * @interface BAUserRecordListAllOf
 */
export interface BAUserRecordListAllOf {
    /**
     * 
     * @type {Array<BAUserRecord>}
     * @memberof BAUserRecordListAllOf
     */
    records: Array<BAUserRecord>;
}
/**
 * Инофрмация о сущности с штриховым кодом
 * @export
 * @interface BarcodeEntityObj
 */
export interface BarcodeEntityObj {
    /**
     * Идентификатор
     * @type {number}
     * @memberof BarcodeEntityObj
     */
    id: number;
    /**
     * Тип сущности
     * @type {string}
     * @memberof BarcodeEntityObj
     */
    entityTypeCode: BarcodeEntityObjEntityTypeCodeEnum;
    /**
     * Идентификатор родительской сущности
     * @type {number}
     * @memberof BarcodeEntityObj
     */
    parentId?: number;
    /**
     * 
     * @type {BusinessAccountAbstractEntityInfo}
     * @memberof BarcodeEntityObj
     */
    entityInfoRead?: BusinessAccountAbstractEntityInfo;
}

/**
    * @export
    * @enum {string}
    */
export enum BarcodeEntityObjEntityTypeCodeEnum {
    KIT = 'KIT',
    PRODUCT = 'PRODUCT',
    VARIANT = 'VARIANT',
    INSTANCE = 'INSTANCE'
}

/**
 * Инофрмация о сущности с штриховым кодом дополненная информацией по номерклатуре для формы операции
 * @export
 * @interface BarcodeNomenclatureInOperationFormObj
 */
export interface BarcodeNomenclatureInOperationFormObj {
    /**
     * 
     * @type {BarcodeEntityObj}
     * @memberof BarcodeNomenclatureInOperationFormObj
     */
    barcodeEntityObj: BarcodeEntityObj;
    /**
     * 
     * @type {NomenclatureIdAndTimetableVersionObj}
     * @memberof BarcodeNomenclatureInOperationFormObj
     */
    kit?: NomenclatureIdAndTimetableVersionObj;
    /**
     * 
     * @type {NomenclatureIdAndTimetableVersionObj}
     * @memberof BarcodeNomenclatureInOperationFormObj
     */
    product?: NomenclatureIdAndTimetableVersionObj;
    /**
     * 
     * @type {NomenclatureIdAndTimetableVersionObj}
     * @memberof BarcodeNomenclatureInOperationFormObj
     */
    variant?: NomenclatureIdAndTimetableVersionObj;
    /**
     * 
     * @type {NomenclatureIdAndTimetableVersionObj}
     * @memberof BarcodeNomenclatureInOperationFormObj
     */
    instance?: NomenclatureIdAndTimetableVersionObj;
}
/**
 * Код типа отображения штрихового кода, используется только в этикетках
 * @export
 * @enum {string}
 */
export enum BarcodeTypeCodeEnum {
    CODE128 = 'CODE_128',
    QRCODE = 'QR_CODE',
    DATAMATRIX = 'DATA_MATRIX'
}

/**
 * Финансовые поля сущности ProjectRecord типа BASE, которые могут быть выгружены в список Excel
 * @export
 * @enum {string}
 */
export enum BaseProjectFinancialFieldsTypeCode {
    CostOfRent = 'costOfRent',
    CostOfWork = 'costOfWork',
    CostOfExpenses = 'costOfExpenses',
    SumToBePaidAfterTaxes = 'sumToBePaidAfterTaxes',
    DebtSum = 'debtSum',
    CurrentOrderedSum = 'currentOrderedSum',
    CurrentBookedSum = 'currentBookedSum',
    CurrentRentSum = 'currentRentSum',
    DefaultDiscount = 'defaultDiscount'
}

/**
 * Не финансовые поля сущности ProjectRecord типа BASE, которые могут быть выгружены в список Excel
 * @export
 * @enum {string}
 */
export enum BaseProjectNotFinancialFieldsTypeCode {
    ShortName = 'shortName',
    StateCode = 'stateCode',
    SourceCode = 'sourceCode',
    AssigneeShortName = 'assigneeShortName',
    LastActivityDate = 'lastActivityDate',
    RenterShortName = 'renterShortName',
    LocationName = 'locationName',
    TagsString = 'tagsString',
    StartDate = 'startDate',
    TargetFinishDate = 'targetFinishDate',
    RealFinishDate = 'realFinishDate',
    ContactPersonShortName = 'contactPersonShortName'
}

/**
 * Тип кузова
 * @export
 * @enum {string}
 */
export enum BodyTypeCodeEnum {
    SIDEOPEN = 'SIDE_OPEN',
    SIDEAWNING = 'SIDE_AWNING',
    BOOTH = 'BOOTH',
    ISOTHERMALBOOTH = 'ISOTHERMAL_BOOTH'
}

/**
 * Порядок категорий
 * @export
 * @interface BulkCategoryUpdateOrderItem
 */
export interface BulkCategoryUpdateOrderItem {
    /**
     * 
     * @type {number}
     * @memberof BulkCategoryUpdateOrderItem
     */
    id: number;
    /**
     * 
     * @type {Array<BulkCategoryUpdateOrderItem>}
     * @memberof BulkCategoryUpdateOrderItem
     */
    children?: Array<BulkCategoryUpdateOrderItem>;
}
/**
 * Массовое обновление порядка категорий
 * @export
 * @interface BulkUpdateCategoriesOrder
 */
export interface BulkUpdateCategoriesOrder {
    /**
     * 
     * @type {Array<BulkCategoryUpdateOrderItem>}
     * @memberof BulkUpdateCategoriesOrder
     */
    rootCategories: Array<BulkCategoryUpdateOrderItem>;
}
/**
 * Бизнес аккаунт : абстрактное общее состояние для всех операций
 * @export
 * @interface BusinessAccountAbstractCommons
 */
export interface BusinessAccountAbstractCommons {
    /**
     * Идентификатор
     * @type {number}
     * @memberof BusinessAccountAbstractCommons
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof BusinessAccountAbstractCommons
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof BusinessAccountAbstractCommons
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof BusinessAccountAbstractCommons
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof BusinessAccountAbstractCommons
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof BusinessAccountAbstractCommons
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof BusinessAccountAbstractCommons
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof BusinessAccountAbstractCommons
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof BusinessAccountAbstractCommons
     */
    comment?: string;
    /**
     * 
     * @type {BusinessAccountTypeCodeEnum}
     * @memberof BusinessAccountAbstractCommons
     */
    typeCode: BusinessAccountTypeCodeEnum;
    /**
     * 
     * @type {TariffCodeEnum}
     * @memberof BusinessAccountAbstractCommons
     */
    tariff: TariffCodeEnum;
    /**
     * Дата и время до которой продлен легальный триальный период
     * @type {Date}
     * @memberof BusinessAccountAbstractCommons
     */
    trialPeriodProlongedUntil?: Date;
}
/**
 * 
 * @export
 * @interface BusinessAccountAbstractCommonsAllOf
 */
export interface BusinessAccountAbstractCommonsAllOf {
    /**
     * 
     * @type {BusinessAccountTypeCodeEnum}
     * @memberof BusinessAccountAbstractCommonsAllOf
     */
    typeCode: BusinessAccountTypeCodeEnum;
    /**
     * 
     * @type {TariffCodeEnum}
     * @memberof BusinessAccountAbstractCommonsAllOf
     */
    tariff: TariffCodeEnum;
    /**
     * Дата и время до которой продлен легальный триальный период
     * @type {Date}
     * @memberof BusinessAccountAbstractCommonsAllOf
     */
    trialPeriodProlongedUntil?: Date;
}
/**
 * Абстрактная сущность принадлежащая бизнес аккаунту с метаинформацией
 * @export
 * @interface BusinessAccountAbstractEntityInfo
 */
export interface BusinessAccountAbstractEntityInfo {
    /**
     * Идентификатор
     * @type {number}
     * @memberof BusinessAccountAbstractEntityInfo
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof BusinessAccountAbstractEntityInfo
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof BusinessAccountAbstractEntityInfo
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof BusinessAccountAbstractEntityInfo
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof BusinessAccountAbstractEntityInfo
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof BusinessAccountAbstractEntityInfo
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof BusinessAccountAbstractEntityInfo
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof BusinessAccountAbstractEntityInfo
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof BusinessAccountAbstractEntityInfo
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof BusinessAccountAbstractEntityInfo
     */
    businessAccountId?: number;
}
/**
 * 
 * @export
 * @interface BusinessAccountAbstractEntityInfoAllOf
 */
export interface BusinessAccountAbstractEntityInfoAllOf {
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof BusinessAccountAbstractEntityInfoAllOf
     */
    businessAccountId?: number;
}
/**
 * Абстрактная запись о сущности (Списочное представление)
 * @export
 * @interface BusinessAccountAbstractEntityRecord
 */
export interface BusinessAccountAbstractEntityRecord {
    /**
     * Идентификатор
     * @type {number}
     * @memberof BusinessAccountAbstractEntityRecord
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof BusinessAccountAbstractEntityRecord
     */
    businessVersion: number;
    /**
     * Ссылка: Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof BusinessAccountAbstractEntityRecord
     */
    businessAccountId: number;
}
/**
 * 
 * @export
 * @interface BusinessAccountAbstractEntityRecordAllOf
 */
export interface BusinessAccountAbstractEntityRecordAllOf {
    /**
     * Ссылка: Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof BusinessAccountAbstractEntityRecordAllOf
     */
    businessAccountId: number;
}
/**
 * 
 * @export
 * @interface BusinessAccountContactsAbstractInfo
 */
export interface BusinessAccountContactsAbstractInfo {
    /**
     * Идентификатор
     * @type {number}
     * @memberof BusinessAccountContactsAbstractInfo
     */
    id?: number;
    /**
     * Бизнес версия сущности Бизнес Аккаунт
     * @type {number}
     * @memberof BusinessAccountContactsAbstractInfo
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof BusinessAccountContactsAbstractInfo
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof BusinessAccountContactsAbstractInfo
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof BusinessAccountContactsAbstractInfo
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof BusinessAccountContactsAbstractInfo
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof BusinessAccountContactsAbstractInfo
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof BusinessAccountContactsAbstractInfo
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof BusinessAccountContactsAbstractInfo
     */
    comment?: string;
    /**
     * Ид Бизнес Аккаунта
     * @type {number}
     * @memberof BusinessAccountContactsAbstractInfo
     */
    businessAccountId?: number;
    /**
     * Информация об изображении аватара
     * @type {ImageObj}
     * @memberof BusinessAccountContactsAbstractInfo
     */
    image?: ImageObj;
    /**
     * Полное официальное наименование
     * @type {string}
     * @memberof BusinessAccountContactsAbstractInfo
     */
    fullName?: string;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof BusinessAccountContactsAbstractInfo
     */
    shortName?: string;
    /**
     * Описание
     * @type {string}
     * @memberof BusinessAccountContactsAbstractInfo
     */
    description?: string;
    /**
     * 
     * @type {ContactPersonAPIObj}
     * @memberof BusinessAccountContactsAbstractInfo
     */
    contact?: ContactPersonAPIObj;
}
/**
 * Контактная информация бизнес аккаунт: состояние при изменении контактной информации
 * @export
 * @interface BusinessAccountContactsAbstractInfoAllOf
 */
export interface BusinessAccountContactsAbstractInfoAllOf {
    /**
     * Ид Бизнес Аккаунта
     * @type {number}
     * @memberof BusinessAccountContactsAbstractInfoAllOf
     */
    businessAccountId?: number;
    /**
     * Бизнес версия сущности Бизнес Аккаунт
     * @type {number}
     * @memberof BusinessAccountContactsAbstractInfoAllOf
     */
    businessVersion?: number;
    /**
     * Информация об изображении аватара
     * @type {ImageObj}
     * @memberof BusinessAccountContactsAbstractInfoAllOf
     */
    image?: ImageObj;
    /**
     * Полное официальное наименование
     * @type {string}
     * @memberof BusinessAccountContactsAbstractInfoAllOf
     */
    fullName?: string;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof BusinessAccountContactsAbstractInfoAllOf
     */
    shortName?: string;
    /**
     * Описание
     * @type {string}
     * @memberof BusinessAccountContactsAbstractInfoAllOf
     */
    description?: string;
    /**
     * 
     * @type {ContactPersonAPIObj}
     * @memberof BusinessAccountContactsAbstractInfoAllOf
     */
    contact?: ContactPersonAPIObj;
}
/**
 * 
 * @export
 * @interface BusinessAccountContactsInfoRead
 */
export interface BusinessAccountContactsInfoRead extends BusinessAccountContactsAbstractInfo {
}
/**
 * 
 * @export
 * @interface BusinessAccountContactsInfoUpdate
 */
export interface BusinessAccountContactsInfoUpdate extends BusinessAccountContactsAbstractInfo {
}
/**
 * Данные для дешборда оператора БА (часть данных, информация о проблемах берется обычным способом как индикаторы меню)
 * @export
 * @interface BusinessAccountDashboardDataObj
 */
export interface BusinessAccountDashboardDataObj {
    /**
     * Количество продуктов на обслуживании
     * @type {number}
     * @memberof BusinessAccountDashboardDataObj
     */
    maintenanceProductsCount: number;
    /**
     * Количество экземпляров на обслуживании
     * @type {number}
     * @memberof BusinessAccountDashboardDataObj
     */
    maintenanceInstancesCount: number;
    /**
     * 
     * @type {IndicatorWithInstancesObj}
     * @memberof BusinessAccountDashboardDataObj
     */
    currentOrdered: IndicatorWithInstancesObj;
    /**
     * 
     * @type {IndicatorWithInstancesObj}
     * @memberof BusinessAccountDashboardDataObj
     */
    currentBooked: IndicatorWithInstancesObj;
    /**
     * 
     * @type {IndicatorWithInstancesObj}
     * @memberof BusinessAccountDashboardDataObj
     */
    currentRent?: IndicatorWithInstancesObj;
    /**
     * 
     * @type {IndicatorWithInstancesObj}
     * @memberof BusinessAccountDashboardDataObj
     */
    currentSubrentShipmentBooked: IndicatorWithInstancesObj;
    /**
     * 
     * @type {IndicatorWithInstancesObj}
     * @memberof BusinessAccountDashboardDataObj
     */
    currentSubrent?: IndicatorWithInstancesObj;
    /**
     * Количество просроченных забронированных элементов
     * @type {number}
     * @memberof BusinessAccountDashboardDataObj
     */
    currentBookDelayElementsCount: number;
    /**
     * 
     * @type {ProblemAndWarningRentIndicatorsObj}
     * @memberof BusinessAccountDashboardDataObj
     */
    problemsAndWarnings: ProblemAndWarningRentIndicatorsObj;
    /**
     * Текущая сумма долга, коп.
     * @type {number}
     * @memberof BusinessAccountDashboardDataObj
     */
    currentRentDebtSum: number;
    /**
     * Количество проектов по которым имеется долг
     * @type {number}
     * @memberof BusinessAccountDashboardDataObj
     */
    currentRentDebtProjectsCount: number;
    /**
     * Количество арендаторов имеющих долг
     * @type {number}
     * @memberof BusinessAccountDashboardDataObj
     */
    currentRentDebtRentersCount: number;
    /**
     * Текущая сумма долга, коп.
     * @type {number}
     * @memberof BusinessAccountDashboardDataObj
     */
    currentSubrentDebtSum: number;
    /**
     * Количество поставок по которым имеется долг
     * @type {number}
     * @memberof BusinessAccountDashboardDataObj
     */
    currentSubrentDebtSubrentsCount: number;
    /**
     * Количество поставщиков перед которыми имеется долг
     * @type {number}
     * @memberof BusinessAccountDashboardDataObj
     */
    currentSubrentDebtSuppliersCount: number;
}
/**
 * Индикаторы бизнес аккаунта в целом
 * @export
 * @interface BusinessAccountIndicatorsObj
 */
export interface BusinessAccountIndicatorsObj {
    /**
     * 
     * @type {BusinessAccountWarningIndicatorsObj}
     * @memberof BusinessAccountIndicatorsObj
     */
    currentShortagesOrDelays: BusinessAccountWarningIndicatorsObj;
    /**
     * 
     * @type {BusinessAccountWarningIndicatorsObj}
     * @memberof BusinessAccountIndicatorsObj
     */
    currentShortages: BusinessAccountWarningIndicatorsObj;
    /**
     * 
     * @type {BusinessAccountWarningIndicatorsObj}
     * @memberof BusinessAccountIndicatorsObj
     */
    currentDelays: BusinessAccountWarningIndicatorsObj;
}
/**
 * Бизнес аккаунт: состояние при чтении
 * @export
 * @interface BusinessAccountInfoAbstractWriteCommons
 */
export interface BusinessAccountInfoAbstractWriteCommons extends BusinessAccountAbstractCommons {
}
/**
 * Бизнес аккаунт: состояние при создании
 * @export
 * @interface BusinessAccountInfoCreate
 */
export interface BusinessAccountInfoCreate extends BusinessAccountInfoAbstractWriteCommons {
}
/**
 * Бизнес аккаунт: состояние при чтении
 * @export
 * @interface BusinessAccountInfoRead
 */
export interface BusinessAccountInfoRead {
    /**
     * Идентификатор
     * @type {number}
     * @memberof BusinessAccountInfoRead
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof BusinessAccountInfoRead
     */
    businessVersion: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof BusinessAccountInfoRead
     */
    creationAuthorId: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof BusinessAccountInfoRead
     */
    creationAuthorFullName: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof BusinessAccountInfoRead
     */
    creationDate: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof BusinessAccountInfoRead
     */
    lastUpdateAuthorId: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof BusinessAccountInfoRead
     */
    lastUpdateAuthorFullName: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof BusinessAccountInfoRead
     */
    lastUpdateDate: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof BusinessAccountInfoRead
     */
    comment?: string;
    /**
     * 
     * @type {BusinessAccountTypeCodeEnum}
     * @memberof BusinessAccountInfoRead
     */
    typeCode: BusinessAccountTypeCodeEnum;
    /**
     * 
     * @type {TariffCodeEnum}
     * @memberof BusinessAccountInfoRead
     */
    tariff: TariffCodeEnum;
    /**
     * Дата и время до которой продлен легальный триальный период
     * @type {Date}
     * @memberof BusinessAccountInfoRead
     */
    trialPeriodProlongedUntil?: Date;
    /**
     * 
     * @type {PermissonListObj}
     * @memberof BusinessAccountInfoRead
     */
    currentUserPermissions: PermissonListObj;
    /**
     * 
     * @type {BusinessAccountStateCodeEnum}
     * @memberof BusinessAccountInfoRead
     */
    stateCode: BusinessAccountStateCodeEnum;
    /**
     * Коды доступных переходов для данного бизнес аккаунта
     * @type {Array<BusinessAccountTransitionCodeEnum>}
     * @memberof BusinessAccountInfoRead
     */
    availableTransitionCodes?: Array<BusinessAccountTransitionCodeEnum>;
    /**
     * Маркер нахождения в архиве
     * @type {boolean}
     * @memberof BusinessAccountInfoRead
     */
    archive: boolean;
    /**
     * Может ли быть отправлен в архив (может, когда не является активным)
     * @type {boolean}
     * @memberof BusinessAccountInfoRead
     */
    archivable: boolean;
    /**
     * 
     * @type {BusinessAccountRoleUserCountersObj}
     * @memberof BusinessAccountInfoRead
     */
    allRolesUserCounters: BusinessAccountRoleUserCountersObj;
    /**
     * 
     * @type {BusinessAccountRoleUserCountersObj}
     * @memberof BusinessAccountInfoRead
     */
    managerRolesUserCounters: BusinessAccountRoleUserCountersObj;
    /**
     * 
     * @type {BusinessAccountModulesObj}
     * @memberof BusinessAccountInfoRead
     */
    modules: BusinessAccountModulesObj;
    /**
     * Коды доступных модулей, которые можно активировать для данного бизнес аккаунта
     * @type {Array<BusinessAccountModuleCodeEnum>}
     * @memberof BusinessAccountInfoRead
     */
    availableModules: Array<BusinessAccountModuleCodeEnum>;
    /**
     * 
     * @type {Array<LegalDetailsInfoRead>}
     * @memberof BusinessAccountInfoRead
     */
    legalDetails: Array<LegalDetailsInfoRead>;
}
/**
 * 
 * @export
 * @interface BusinessAccountInfoReadAllOf
 */
export interface BusinessAccountInfoReadAllOf {
    /**
     * 
     * @type {PermissonListObj}
     * @memberof BusinessAccountInfoReadAllOf
     */
    currentUserPermissions: PermissonListObj;
    /**
     * 
     * @type {BusinessAccountStateCodeEnum}
     * @memberof BusinessAccountInfoReadAllOf
     */
    stateCode: BusinessAccountStateCodeEnum;
    /**
     * Коды доступных переходов для данного бизнес аккаунта
     * @type {Array<BusinessAccountTransitionCodeEnum>}
     * @memberof BusinessAccountInfoReadAllOf
     */
    availableTransitionCodes?: Array<BusinessAccountTransitionCodeEnum>;
    /**
     * Маркер нахождения в архиве
     * @type {boolean}
     * @memberof BusinessAccountInfoReadAllOf
     */
    archive: boolean;
    /**
     * Может ли быть отправлен в архив (может, когда не является активным)
     * @type {boolean}
     * @memberof BusinessAccountInfoReadAllOf
     */
    archivable: boolean;
    /**
     * 
     * @type {BusinessAccountRoleUserCountersObj}
     * @memberof BusinessAccountInfoReadAllOf
     */
    allRolesUserCounters: BusinessAccountRoleUserCountersObj;
    /**
     * 
     * @type {BusinessAccountRoleUserCountersObj}
     * @memberof BusinessAccountInfoReadAllOf
     */
    managerRolesUserCounters: BusinessAccountRoleUserCountersObj;
    /**
     * 
     * @type {BusinessAccountModulesObj}
     * @memberof BusinessAccountInfoReadAllOf
     */
    modules: BusinessAccountModulesObj;
    /**
     * Коды доступных модулей, которые можно активировать для данного бизнес аккаунта
     * @type {Array<BusinessAccountModuleCodeEnum>}
     * @memberof BusinessAccountInfoReadAllOf
     */
    availableModules: Array<BusinessAccountModuleCodeEnum>;
    /**
     * 
     * @type {TariffCodeEnum}
     * @memberof BusinessAccountInfoReadAllOf
     */
    tariff: TariffCodeEnum;
    /**
     * 
     * @type {Array<LegalDetailsInfoRead>}
     * @memberof BusinessAccountInfoReadAllOf
     */
    legalDetails: Array<LegalDetailsInfoRead>;
}
/**
 * Бизнес аккаунт: состояние при изменении
 * @export
 * @interface BusinessAccountInfoUpdate
 */
export interface BusinessAccountInfoUpdate {
    /**
     * Идентификатор
     * @type {number}
     * @memberof BusinessAccountInfoUpdate
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof BusinessAccountInfoUpdate
     */
    businessVersion: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof BusinessAccountInfoUpdate
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof BusinessAccountInfoUpdate
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof BusinessAccountInfoUpdate
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof BusinessAccountInfoUpdate
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof BusinessAccountInfoUpdate
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof BusinessAccountInfoUpdate
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof BusinessAccountInfoUpdate
     */
    comment?: string;
    /**
     * 
     * @type {BusinessAccountTypeCodeEnum}
     * @memberof BusinessAccountInfoUpdate
     */
    typeCode: BusinessAccountTypeCodeEnum;
    /**
     * 
     * @type {TariffCodeEnum}
     * @memberof BusinessAccountInfoUpdate
     */
    tariff: TariffCodeEnum;
    /**
     * Дата и время до которой продлен легальный триальный период
     * @type {Date}
     * @memberof BusinessAccountInfoUpdate
     */
    trialPeriodProlongedUntil?: Date;
    /**
     * Максимальное число пользователей в компании
     * @type {number}
     * @memberof BusinessAccountInfoUpdate
     */
    allRolesUserCountLimit: number;
    /**
     * Максимальное число с менеджерскими ролями в компании
     * @type {number}
     * @memberof BusinessAccountInfoUpdate
     */
    managerRolesUserCountLimit: number;
}
/**
 * 
 * @export
 * @interface BusinessAccountInfoUpdateAllOf
 */
export interface BusinessAccountInfoUpdateAllOf {
    /**
     * Максимальное число пользователей в компании
     * @type {number}
     * @memberof BusinessAccountInfoUpdateAllOf
     */
    allRolesUserCountLimit: number;
    /**
     * Максимальное число с менеджерскими ролями в компании
     * @type {number}
     * @memberof BusinessAccountInfoUpdateAllOf
     */
    managerRolesUserCountLimit: number;
}
/**
 * Код модуля бизнес аккаунта
 * @export
 * @enum {string}
 */
export enum BusinessAccountModuleCodeEnum {
    CREW = 'CREW',
    SUBRENT = 'SUBRENT',
    LOGISTICS = 'LOGISTICS',
    STATISTICS = 'STATISTICS',
    EXPENSES = 'EXPENSES',
    OFFERS = 'OFFERS',
    PROJECTS = 'PROJECTS',
    ORDERS = 'ORDERS'
}

/**
 * Объект с информацией о включении или отключении модуля
 * @export
 * @interface BusinessAccountModuleEnableOrDisableObj
 */
export interface BusinessAccountModuleEnableOrDisableObj {
    /**
     * 
     * @type {BusinessAccountModuleCodeEnum}
     * @memberof BusinessAccountModuleEnableOrDisableObj
     */
    moduleCode: BusinessAccountModuleCodeEnum;
    /**
     * Подключить или отключить модуль, если подключить true, если отключить false
     * @type {boolean}
     * @memberof BusinessAccountModuleEnableOrDisableObj
     */
    enable: boolean;
    /**
     * Бизнес версия объекта BusinessAccountPreferences
     * @type {number}
     * @memberof BusinessAccountModuleEnableOrDisableObj
     */
    businessVersion: number;
}
/**
 * Список объектов с информацией о включении или отключении модулей
 * @export
 * @interface BusinessAccountModuleEnableOrDisableObjList
 */
export interface BusinessAccountModuleEnableOrDisableObjList {
    /**
     * 
     * @type {Array<BusinessAccountModuleEnableOrDisableObj>}
     * @memberof BusinessAccountModuleEnableOrDisableObjList
     */
    records?: Array<BusinessAccountModuleEnableOrDisableObj>;
}
/**
 * Объект с информацией о подключеных и отключеных модулях бизнес аккаунта
 * @export
 * @interface BusinessAccountModulesObj
 */
export interface BusinessAccountModulesObj {
    /**
     * Модуль персонала подключен
     * @type {boolean}
     * @memberof BusinessAccountModulesObj
     */
    crewModuleEnabled: boolean;
    /**
     * Модуль субаренды подключен
     * @type {boolean}
     * @memberof BusinessAccountModulesObj
     */
    subrentModuleEnabled: boolean;
    /**
     * Модуль логистики подключен
     * @type {boolean}
     * @memberof BusinessAccountModulesObj
     */
    logisticsModuleEnabled: boolean;
    /**
     * Модуль статистики подключен
     * @type {boolean}
     * @memberof BusinessAccountModulesObj
     */
    statisticsModuleEnabled: boolean;
    /**
     * Модуль затрат подключен
     * @type {boolean}
     * @memberof BusinessAccountModulesObj
     */
    expensesModuleEnabled: boolean;
    /**
     * Модуль коммерческих предложений подключен
     * @type {boolean}
     * @memberof BusinessAccountModulesObj
     */
    offerModuleEnabled: boolean;
    /**
     * Модуль проектом подключен
     * @type {boolean}
     * @memberof BusinessAccountModulesObj
     */
    projectsModuleEnabled?: boolean;
    /**
     * Модуль заказов подключен
     * @type {boolean}
     * @memberof BusinessAccountModulesObj
     */
    ordersModuleEnabled?: boolean;
}
/**
 * Права в бизнес аккаунте
 * @export
 * @enum {string}
 */
export enum BusinessAccountPermissionCodeEnum {
    USEAPI = 'BA__USE_API',
    CREATEENTITIES = 'BA__CREATE_ENTITIES',
    VIEWENTITIES = 'BA__VIEW_ENTITIES',
    UPDATEENTITIES = 'BA__UPDATE_ENTITIES',
    WORKFLOWTRANSITENTITIES = 'BA__WORKFLOW_TRANSIT_ENTITIES',
    VIEWFINANCIALDATA = 'BA__VIEW_FINANCIAL_DATA',
    UPDATEFINANCIALDATA = 'BA__UPDATE_FINANCIAL_DATA',
    VIEWSYSTEMOPTIONS = 'BA__VIEW_SYSTEM_OPTIONS',
    UPDATESYSTEMOPTIONS = 'BA__UPDATE_SYSTEM_OPTIONS',
    VIEWBUSINESSACCOUNT = 'BA__VIEW_BUSINESS_ACCOUNT',
    VIEWUSERS = 'BA__VIEW_USERS',
    CREATECORRECTIONOPERATION = 'BA__CREATE_CORRECTION_OPERATION',
    MANAGEUSERS = 'BA__MANAGE_USERS'
}

/**
 * Основные настройки бизнес аккаунта: общее абстрактное состояние
 * @export
 * @interface BusinessAccountPreferencesAbstractInfo
 */
export interface BusinessAccountPreferencesAbstractInfo {
    /**
     * Идентификатор
     * @type {number}
     * @memberof BusinessAccountPreferencesAbstractInfo
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof BusinessAccountPreferencesAbstractInfo
     */
    businessVersion: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof BusinessAccountPreferencesAbstractInfo
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof BusinessAccountPreferencesAbstractInfo
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof BusinessAccountPreferencesAbstractInfo
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof BusinessAccountPreferencesAbstractInfo
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof BusinessAccountPreferencesAbstractInfo
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof BusinessAccountPreferencesAbstractInfo
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof BusinessAccountPreferencesAbstractInfo
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof BusinessAccountPreferencesAbstractInfo
     */
    businessAccountId?: number;
    /**
     * 
     * @type {CurrencyCodeEnum}
     * @memberof BusinessAccountPreferencesAbstractInfo
     */
    baseCurrency: CurrencyCodeEnum;
    /**
     * Длительность смены, мин
     * @type {number}
     * @memberof BusinessAccountPreferencesAbstractInfo
     */
    shiftLengthInMinutes: number;
    /**
     * 
     * @type {ShiftCountRoundingTypeCodeEnum}
     * @memberof BusinessAccountPreferencesAbstractInfo
     */
    shiftCountRoundingType: ShiftCountRoundingTypeCodeEnum;
    /**
     * Обязательный запас времени между обязательствами, мин
     * @type {number}
     * @memberof BusinessAccountPreferencesAbstractInfo
     */
    requiredTimeIndentBetweenElementsInMinutes: number;
    /**
     * Разрешить работу с параллельными операциями в одной рамке активности
     * @type {boolean}
     * @memberof BusinessAccountPreferencesAbstractInfo
     */
    allowConcurrentOperations: boolean;
    /**
     * Включать ли обязательства бронирования в стоимость проекта
     * @type {boolean}
     * @memberof BusinessAccountPreferencesAbstractInfo
     */
    includeBookedElementsInProjectSum: boolean;
    /**
     * Включать ли запланированные работы в сумму проекта
     * @type {boolean}
     * @memberof BusinessAccountPreferencesAbstractInfo
     */
    includePlannedWorkPlanningsInProjectSum: boolean;
    /**
     * Включать ли обязательства бронирования в стоимость проекта
     * @type {number}
     * @memberof BusinessAccountPreferencesAbstractInfo
     */
    defaultTaxRate?: number;
    /**
     * 
     * @type {TaxBaseTypeCodeEnum}
     * @memberof BusinessAccountPreferencesAbstractInfo
     */
    taxBaseType: TaxBaseTypeCodeEnum;
    /**
     * Ключ доступа к витрине
     * @type {string}
     * @memberof BusinessAccountPreferencesAbstractInfo
     */
    storefrontAccessToken?: string;
    /**
     * Ключ доступа к календарю
     * @type {string}
     * @memberof BusinessAccountPreferencesAbstractInfo
     */
    calendarAccessToken?: string;
    /**
     * Часовой пояс компании, сдвиг относительно UTC
     * @type {number}
     * @memberof BusinessAccountPreferencesAbstractInfo
     */
    timezoneOffsetUTC?: number;
    /**
     * 
     * @type {PaymentMethodRequirementTypeCodeEnum}
     * @memberof BusinessAccountPreferencesAbstractInfo
     */
    paymentMethodRequirementType: PaymentMethodRequirementTypeCodeEnum;
    /**
     * 
     * @type {LegalCountryCodeEnum}
     * @memberof BusinessAccountPreferencesAbstractInfo
     */
    legalCountry?: LegalCountryCodeEnum;
    /**
     * 
     * @type {ContractValidationTypeCodeEnum}
     * @memberof BusinessAccountPreferencesAbstractInfo
     */
    contractValidationType: ContractValidationTypeCodeEnum;
    /**
     * Флаг включения налога в внешнюю стоимость затраты
     * @type {boolean}
     * @memberof BusinessAccountPreferencesAbstractInfo
     */
    includeTaxInExternalExpenseSum: boolean;
}
/**
 * 
 * @export
 * @interface BusinessAccountPreferencesAbstractInfoAllOf
 */
export interface BusinessAccountPreferencesAbstractInfoAllOf {
    /**
     * 
     * @type {CurrencyCodeEnum}
     * @memberof BusinessAccountPreferencesAbstractInfoAllOf
     */
    baseCurrency: CurrencyCodeEnum;
    /**
     * Длительность смены, мин
     * @type {number}
     * @memberof BusinessAccountPreferencesAbstractInfoAllOf
     */
    shiftLengthInMinutes: number;
    /**
     * 
     * @type {ShiftCountRoundingTypeCodeEnum}
     * @memberof BusinessAccountPreferencesAbstractInfoAllOf
     */
    shiftCountRoundingType: ShiftCountRoundingTypeCodeEnum;
    /**
     * Обязательный запас времени между обязательствами, мин
     * @type {number}
     * @memberof BusinessAccountPreferencesAbstractInfoAllOf
     */
    requiredTimeIndentBetweenElementsInMinutes: number;
    /**
     * Разрешить работу с параллельными операциями в одной рамке активности
     * @type {boolean}
     * @memberof BusinessAccountPreferencesAbstractInfoAllOf
     */
    allowConcurrentOperations: boolean;
    /**
     * Включать ли обязательства бронирования в стоимость проекта
     * @type {boolean}
     * @memberof BusinessAccountPreferencesAbstractInfoAllOf
     */
    includeBookedElementsInProjectSum: boolean;
    /**
     * Включать ли запланированные работы в сумму проекта
     * @type {boolean}
     * @memberof BusinessAccountPreferencesAbstractInfoAllOf
     */
    includePlannedWorkPlanningsInProjectSum: boolean;
    /**
     * Включать ли обязательства бронирования в стоимость проекта
     * @type {number}
     * @memberof BusinessAccountPreferencesAbstractInfoAllOf
     */
    defaultTaxRate?: number;
    /**
     * 
     * @type {TaxBaseTypeCodeEnum}
     * @memberof BusinessAccountPreferencesAbstractInfoAllOf
     */
    taxBaseType: TaxBaseTypeCodeEnum;
    /**
     * Ключ доступа к витрине
     * @type {string}
     * @memberof BusinessAccountPreferencesAbstractInfoAllOf
     */
    storefrontAccessToken?: string;
    /**
     * Ключ доступа к календарю
     * @type {string}
     * @memberof BusinessAccountPreferencesAbstractInfoAllOf
     */
    calendarAccessToken?: string;
    /**
     * Часовой пояс компании, сдвиг относительно UTC
     * @type {number}
     * @memberof BusinessAccountPreferencesAbstractInfoAllOf
     */
    timezoneOffsetUTC?: number;
    /**
     * 
     * @type {PaymentMethodRequirementTypeCodeEnum}
     * @memberof BusinessAccountPreferencesAbstractInfoAllOf
     */
    paymentMethodRequirementType: PaymentMethodRequirementTypeCodeEnum;
    /**
     * 
     * @type {LegalCountryCodeEnum}
     * @memberof BusinessAccountPreferencesAbstractInfoAllOf
     */
    legalCountry?: LegalCountryCodeEnum;
    /**
     * 
     * @type {ContractValidationTypeCodeEnum}
     * @memberof BusinessAccountPreferencesAbstractInfoAllOf
     */
    contractValidationType: ContractValidationTypeCodeEnum;
    /**
     * Флаг включения налога в внешнюю стоимость затраты
     * @type {boolean}
     * @memberof BusinessAccountPreferencesAbstractInfoAllOf
     */
    includeTaxInExternalExpenseSum: boolean;
}
/**
 * Основные настройки бизнес аккаунта: состояние чтения
 * @export
 * @interface BusinessAccountPreferencesInfoRead
 */
export interface BusinessAccountPreferencesInfoRead {
    /**
     * Идентификатор
     * @type {number}
     * @memberof BusinessAccountPreferencesInfoRead
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof BusinessAccountPreferencesInfoRead
     */
    businessVersion: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof BusinessAccountPreferencesInfoRead
     */
    creationAuthorId: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof BusinessAccountPreferencesInfoRead
     */
    creationAuthorFullName: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof BusinessAccountPreferencesInfoRead
     */
    creationDate: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof BusinessAccountPreferencesInfoRead
     */
    lastUpdateAuthorId: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof BusinessAccountPreferencesInfoRead
     */
    lastUpdateAuthorFullName: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof BusinessAccountPreferencesInfoRead
     */
    lastUpdateDate: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof BusinessAccountPreferencesInfoRead
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof BusinessAccountPreferencesInfoRead
     */
    businessAccountId: number;
    /**
     * 
     * @type {CurrencyCodeEnum}
     * @memberof BusinessAccountPreferencesInfoRead
     */
    baseCurrency: CurrencyCodeEnum;
    /**
     * Длительность смены, мин
     * @type {number}
     * @memberof BusinessAccountPreferencesInfoRead
     */
    shiftLengthInMinutes: number;
    /**
     * 
     * @type {ShiftCountRoundingTypeCodeEnum}
     * @memberof BusinessAccountPreferencesInfoRead
     */
    shiftCountRoundingType: ShiftCountRoundingTypeCodeEnum;
    /**
     * Обязательный запас времени между обязательствами, мин
     * @type {number}
     * @memberof BusinessAccountPreferencesInfoRead
     */
    requiredTimeIndentBetweenElementsInMinutes: number;
    /**
     * Разрешить работу с параллельными операциями в одной рамке активности
     * @type {boolean}
     * @memberof BusinessAccountPreferencesInfoRead
     */
    allowConcurrentOperations: boolean;
    /**
     * Включать ли обязательства бронирования в стоимость проекта
     * @type {boolean}
     * @memberof BusinessAccountPreferencesInfoRead
     */
    includeBookedElementsInProjectSum: boolean;
    /**
     * Включать ли запланированные работы в сумму проекта
     * @type {boolean}
     * @memberof BusinessAccountPreferencesInfoRead
     */
    includePlannedWorkPlanningsInProjectSum: boolean;
    /**
     * Включать ли обязательства бронирования в стоимость проекта
     * @type {number}
     * @memberof BusinessAccountPreferencesInfoRead
     */
    defaultTaxRate?: number;
    /**
     * 
     * @type {TaxBaseTypeCodeEnum}
     * @memberof BusinessAccountPreferencesInfoRead
     */
    taxBaseType: TaxBaseTypeCodeEnum;
    /**
     * Ключ доступа к витрине
     * @type {string}
     * @memberof BusinessAccountPreferencesInfoRead
     */
    storefrontAccessToken?: string;
    /**
     * Ключ доступа к календарю
     * @type {string}
     * @memberof BusinessAccountPreferencesInfoRead
     */
    calendarAccessToken?: string;
    /**
     * Часовой пояс компании, сдвиг относительно UTC
     * @type {number}
     * @memberof BusinessAccountPreferencesInfoRead
     */
    timezoneOffsetUTC?: number;
    /**
     * 
     * @type {PaymentMethodRequirementTypeCodeEnum}
     * @memberof BusinessAccountPreferencesInfoRead
     */
    paymentMethodRequirementType: PaymentMethodRequirementTypeCodeEnum;
    /**
     * 
     * @type {LegalCountryCodeEnum}
     * @memberof BusinessAccountPreferencesInfoRead
     */
    legalCountry: LegalCountryCodeEnum;
    /**
     * 
     * @type {ContractValidationTypeCodeEnum}
     * @memberof BusinessAccountPreferencesInfoRead
     */
    contractValidationType: ContractValidationTypeCodeEnum;
    /**
     * Флаг включения налога в внешнюю стоимость затраты
     * @type {boolean}
     * @memberof BusinessAccountPreferencesInfoRead
     */
    includeTaxInExternalExpenseSum: boolean;
    /**
     * 
     * @type {BusinessAccountModulesObj}
     * @memberof BusinessAccountPreferencesInfoRead
     */
    modules: BusinessAccountModulesObj;
    /**
     * 
     * @type {DefaultDocumentTemplateObjRead}
     * @memberof BusinessAccountPreferencesInfoRead
     */
    defaultBadgeTemplate?: DefaultDocumentTemplateObjRead;
    /**
     * 
     * @type {string}
     * @memberof BusinessAccountPreferencesInfoRead
     */
    billNumberPostfix?: string;
}
/**
 * 
 * @export
 * @interface BusinessAccountPreferencesInfoReadAllOf
 */
export interface BusinessAccountPreferencesInfoReadAllOf {
    /**
     * 
     * @type {BusinessAccountModulesObj}
     * @memberof BusinessAccountPreferencesInfoReadAllOf
     */
    modules: BusinessAccountModulesObj;
    /**
     * 
     * @type {DefaultDocumentTemplateObjRead}
     * @memberof BusinessAccountPreferencesInfoReadAllOf
     */
    defaultBadgeTemplate?: DefaultDocumentTemplateObjRead;
    /**
     * 
     * @type {string}
     * @memberof BusinessAccountPreferencesInfoReadAllOf
     */
    billNumberPostfix?: string;
}
/**
 * Основные настройки бизнес аккаунта: состояние изменения
 * @export
 * @interface BusinessAccountPreferencesInfoUpdate
 */
export interface BusinessAccountPreferencesInfoUpdate {
    /**
     * Идентификатор
     * @type {number}
     * @memberof BusinessAccountPreferencesInfoUpdate
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof BusinessAccountPreferencesInfoUpdate
     */
    businessVersion: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof BusinessAccountPreferencesInfoUpdate
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof BusinessAccountPreferencesInfoUpdate
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof BusinessAccountPreferencesInfoUpdate
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof BusinessAccountPreferencesInfoUpdate
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof BusinessAccountPreferencesInfoUpdate
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof BusinessAccountPreferencesInfoUpdate
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof BusinessAccountPreferencesInfoUpdate
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof BusinessAccountPreferencesInfoUpdate
     */
    businessAccountId?: number;
    /**
     * 
     * @type {CurrencyCodeEnum}
     * @memberof BusinessAccountPreferencesInfoUpdate
     */
    baseCurrency: CurrencyCodeEnum;
    /**
     * Длительность смены, мин
     * @type {number}
     * @memberof BusinessAccountPreferencesInfoUpdate
     */
    shiftLengthInMinutes: number;
    /**
     * 
     * @type {ShiftCountRoundingTypeCodeEnum}
     * @memberof BusinessAccountPreferencesInfoUpdate
     */
    shiftCountRoundingType: ShiftCountRoundingTypeCodeEnum;
    /**
     * Обязательный запас времени между обязательствами, мин
     * @type {number}
     * @memberof BusinessAccountPreferencesInfoUpdate
     */
    requiredTimeIndentBetweenElementsInMinutes: number;
    /**
     * Разрешить работу с параллельными операциями в одной рамке активности
     * @type {boolean}
     * @memberof BusinessAccountPreferencesInfoUpdate
     */
    allowConcurrentOperations: boolean;
    /**
     * Включать ли обязательства бронирования в стоимость проекта
     * @type {boolean}
     * @memberof BusinessAccountPreferencesInfoUpdate
     */
    includeBookedElementsInProjectSum: boolean;
    /**
     * Включать ли запланированные работы в сумму проекта
     * @type {boolean}
     * @memberof BusinessAccountPreferencesInfoUpdate
     */
    includePlannedWorkPlanningsInProjectSum: boolean;
    /**
     * Включать ли обязательства бронирования в стоимость проекта
     * @type {number}
     * @memberof BusinessAccountPreferencesInfoUpdate
     */
    defaultTaxRate?: number;
    /**
     * 
     * @type {TaxBaseTypeCodeEnum}
     * @memberof BusinessAccountPreferencesInfoUpdate
     */
    taxBaseType: TaxBaseTypeCodeEnum;
    /**
     * Ключ доступа к витрине
     * @type {string}
     * @memberof BusinessAccountPreferencesInfoUpdate
     */
    storefrontAccessToken?: string;
    /**
     * Ключ доступа к календарю
     * @type {string}
     * @memberof BusinessAccountPreferencesInfoUpdate
     */
    calendarAccessToken?: string;
    /**
     * Часовой пояс компании, сдвиг относительно UTC
     * @type {number}
     * @memberof BusinessAccountPreferencesInfoUpdate
     */
    timezoneOffsetUTC?: number;
    /**
     * 
     * @type {PaymentMethodRequirementTypeCodeEnum}
     * @memberof BusinessAccountPreferencesInfoUpdate
     */
    paymentMethodRequirementType: PaymentMethodRequirementTypeCodeEnum;
    /**
     * 
     * @type {LegalCountryCodeEnum}
     * @memberof BusinessAccountPreferencesInfoUpdate
     */
    legalCountry?: LegalCountryCodeEnum;
    /**
     * 
     * @type {ContractValidationTypeCodeEnum}
     * @memberof BusinessAccountPreferencesInfoUpdate
     */
    contractValidationType: ContractValidationTypeCodeEnum;
    /**
     * Флаг включения налога в внешнюю стоимость затраты
     * @type {boolean}
     * @memberof BusinessAccountPreferencesInfoUpdate
     */
    includeTaxInExternalExpenseSum: boolean;
    /**
     * 
     * @type {DefaultDocumentTemplateObjWrite}
     * @memberof BusinessAccountPreferencesInfoUpdate
     */
    defaultBadgeTemplate?: DefaultDocumentTemplateObjWrite;
    /**
     * 
     * @type {string}
     * @memberof BusinessAccountPreferencesInfoUpdate
     */
    billNumberPostfix?: string;
}
/**
 * 
 * @export
 * @interface BusinessAccountPreferencesInfoUpdateAllOf
 */
export interface BusinessAccountPreferencesInfoUpdateAllOf {
    /**
     * 
     * @type {DefaultDocumentTemplateObjWrite}
     * @memberof BusinessAccountPreferencesInfoUpdateAllOf
     */
    defaultBadgeTemplate?: DefaultDocumentTemplateObjWrite;
    /**
     * 
     * @type {string}
     * @memberof BusinessAccountPreferencesInfoUpdateAllOf
     */
    billNumberPostfix?: string;
}
/**
 * Списочное представление бизнес аккаунта
 * @export
 * @interface BusinessAccountRecord
 */
export interface BusinessAccountRecord {
    /**
     * Идентификатор
     * @type {number}
     * @memberof BusinessAccountRecord
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof BusinessAccountRecord
     */
    businessVersion: number;
    /**
     * Информация об изображении аватара
     * @type {ImageObj}
     * @memberof BusinessAccountRecord
     */
    image?: ImageObj;
    /**
     * Короткое наименование, берется из контактов
     * @type {string}
     * @memberof BusinessAccountRecord
     */
    shortName: string;
    /**
     * 
     * @type {BusinessAccountTypeCodeEnum}
     * @memberof BusinessAccountRecord
     */
    typeCode: BusinessAccountTypeCodeEnum;
    /**
     * Дата и время до которой продлен легальный триальный период
     * @type {Date}
     * @memberof BusinessAccountRecord
     */
    trialPeriodProlongedUntil?: Date;
    /**
     * 
     * @type {RentIndustryCodeEnum}
     * @memberof BusinessAccountRecord
     */
    rentIndustryCode: RentIndustryCodeEnum;
    /**
     * Максимальное число пользователей в компании
     * @type {number}
     * @memberof BusinessAccountRecord
     */
    allRolesUserCountLimit: number;
    /**
     * Максимальное число с менеджерскими ролями в компании
     * @type {number}
     * @memberof BusinessAccountRecord
     */
    managerRolesUserCountLimit: number;
    /**
     * 
     * @type {TariffCodeEnum}
     * @memberof BusinessAccountRecord
     */
    tariff: TariffCodeEnum;
    /**
     * 
     * @type {BusinessAccountStateCodeEnum}
     * @memberof BusinessAccountRecord
     */
    stateCode: BusinessAccountStateCodeEnum;
    /**
     * Маркер нахождения в архиве
     * @type {boolean}
     * @memberof BusinessAccountRecord
     */
    archive: boolean;
    /**
     * Может ли быть отправлен в архив (может, когда не является активным)
     * @type {boolean}
     * @memberof BusinessAccountRecord
     */
    archivable: boolean;
    /**
     * Коды доступных переходов для данного бизнес аккаунта
     * @type {Array<BusinessAccountTransitionCodeEnum>}
     * @memberof BusinessAccountRecord
     */
    availableTransitionCodes?: Array<BusinessAccountTransitionCodeEnum>;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof BusinessAccountRecord
     */
    creationDate: Date;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof BusinessAccountRecord
     */
    creationAuthorId: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof BusinessAccountRecord
     */
    creationAuthorFullName: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof BusinessAccountRecord
     */
    lastUpdateDate: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof BusinessAccountRecord
     */
    lastUpdateAuthorId: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof BusinessAccountRecord
     */
    lastUpdateAuthorFullName: PersonFullName;
}
/**
 * 
 * @export
 * @interface BusinessAccountRecordAllOf
 */
export interface BusinessAccountRecordAllOf {
    /**
     * Информация об изображении аватара
     * @type {ImageObj}
     * @memberof BusinessAccountRecordAllOf
     */
    image?: ImageObj;
    /**
     * Короткое наименование, берется из контактов
     * @type {string}
     * @memberof BusinessAccountRecordAllOf
     */
    shortName: string;
    /**
     * 
     * @type {BusinessAccountTypeCodeEnum}
     * @memberof BusinessAccountRecordAllOf
     */
    typeCode: BusinessAccountTypeCodeEnum;
    /**
     * Дата и время до которой продлен легальный триальный период
     * @type {Date}
     * @memberof BusinessAccountRecordAllOf
     */
    trialPeriodProlongedUntil?: Date;
    /**
     * 
     * @type {RentIndustryCodeEnum}
     * @memberof BusinessAccountRecordAllOf
     */
    rentIndustryCode: RentIndustryCodeEnum;
    /**
     * Максимальное число пользователей в компании
     * @type {number}
     * @memberof BusinessAccountRecordAllOf
     */
    allRolesUserCountLimit: number;
    /**
     * Максимальное число с менеджерскими ролями в компании
     * @type {number}
     * @memberof BusinessAccountRecordAllOf
     */
    managerRolesUserCountLimit: number;
    /**
     * 
     * @type {TariffCodeEnum}
     * @memberof BusinessAccountRecordAllOf
     */
    tariff: TariffCodeEnum;
    /**
     * 
     * @type {BusinessAccountStateCodeEnum}
     * @memberof BusinessAccountRecordAllOf
     */
    stateCode: BusinessAccountStateCodeEnum;
    /**
     * Маркер нахождения в архиве
     * @type {boolean}
     * @memberof BusinessAccountRecordAllOf
     */
    archive: boolean;
    /**
     * Может ли быть отправлен в архив (может, когда не является активным)
     * @type {boolean}
     * @memberof BusinessAccountRecordAllOf
     */
    archivable: boolean;
    /**
     * Коды доступных переходов для данного бизнес аккаунта
     * @type {Array<BusinessAccountTransitionCodeEnum>}
     * @memberof BusinessAccountRecordAllOf
     */
    availableTransitionCodes?: Array<BusinessAccountTransitionCodeEnum>;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof BusinessAccountRecordAllOf
     */
    creationDate: Date;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof BusinessAccountRecordAllOf
     */
    creationAuthorId: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof BusinessAccountRecordAllOf
     */
    creationAuthorFullName: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof BusinessAccountRecordAllOf
     */
    lastUpdateDate: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof BusinessAccountRecordAllOf
     */
    lastUpdateAuthorId: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof BusinessAccountRecordAllOf
     */
    lastUpdateAuthorFullName: PersonFullName;
}
/**
 * Список записей бизнес аккаунтов
 * @export
 * @interface BusinessAccountRecordList
 */
export interface BusinessAccountRecordList {
    /**
     * 
     * @type {ListAttributesInfo}
     * @memberof BusinessAccountRecordList
     */
    listAttributes: ListAttributesInfo;
    /**
     * 
     * @type {Array<BusinessAccountRecord>}
     * @memberof BusinessAccountRecordList
     */
    records: Array<BusinessAccountRecord>;
}
/**
 * 
 * @export
 * @interface BusinessAccountRecordListAllOf
 */
export interface BusinessAccountRecordListAllOf {
    /**
     * 
     * @type {Array<BusinessAccountRecord>}
     * @memberof BusinessAccountRecordListAllOf
     */
    records: Array<BusinessAccountRecord>;
}
/**
 * Объект с информацией о регистрации нового бизнес аккаунта
 * @export
 * @interface BusinessAccountRegistrationObj
 */
export interface BusinessAccountRegistrationObj {
    /**
     * Короткое наименование, передается и на короткое и на полное наименование БА
     * @type {string}
     * @memberof BusinessAccountRegistrationObj
     */
    shortName: string;
    /**
     * 
     * @type {RentIndustryCodeEnum}
     * @memberof BusinessAccountRegistrationObj
     */
    rentIndustryCode: RentIndustryCodeEnum;
    /**
     * 
     * @type {CountryCodeEnum}
     * @memberof BusinessAccountRegistrationObj
     */
    countryCode: CountryCodeEnum;
    /**
     * Заполнять ли тестовыми данными, используется только при триальном(демонстрационном) типе БА
     * @type {boolean}
     * @memberof BusinessAccountRegistrationObj
     */
    fillWithDemoContent: boolean;
    /**
     * UTM данные
     * @type {string}
     * @memberof BusinessAccountRegistrationObj
     */
    uTMDataString?: string;
    /**
     * Номер телефона
     * @type {string}
     * @memberof BusinessAccountRegistrationObj
     */
    phoneNumber: string;
    /**
     * Промокод
     * @type {string}
     * @memberof BusinessAccountRegistrationObj
     */
    promoCode?: string;
}
/**
 * Роли в бизнесс аккаунте
 * @export
 * @enum {string}
 */
export enum BusinessAccountRoleCodeEnum {
    ADMIN = 'RR_BA__ADMIN',
    AGENT = 'RR_BA__AGENT',
    STOREKEEPER = 'RR_BA__STOREKEEPER'
}

/**
 * 
 * @export
 * @interface BusinessAccountRoleUserCountersObj
 */
export interface BusinessAccountRoleUserCountersObj {
    /**
     * Ограничение на максимальное число пользователей с ролью в компании
     * @type {number}
     * @memberof BusinessAccountRoleUserCountersObj
     */
    userCountLimit: number;
    /**
     * Число пользователей с ролью в компании
     * @type {number}
     * @memberof BusinessAccountRoleUserCountersObj
     */
    userCount: number;
    /**
     * Число активных приглашений на роль в компании
     * @type {number}
     * @memberof BusinessAccountRoleUserCountersObj
     */
    activeInvitationCount: number;
}
/**
 * Код статуса бизнес аккаунта
 * @export
 * @enum {string}
 */
export enum BusinessAccountStateCodeEnum {
    ACTIVE = 'ACTIVE',
    BLOCKED = 'BLOCKED'
}

/**
 * Код перехода между статусами бизнес аккаунта
 * @export
 * @enum {string}
 */
export enum BusinessAccountTransitionCodeEnum {
    ACTIVATE = 'ACTIVATE',
    BLOCK = 'BLOCK'
}

/**
 * Код типа бизнес аккаунта
 * @export
 * @enum {string}
 */
export enum BusinessAccountTypeCodeEnum {
    DEMOTEMPLATE = 'DEMO_TEMPLATE',
    SANDBOX = 'SANDBOX',
    TRIAL = 'TRIAL',
    PRODUCTION = 'PRODUCTION'
}

/**
 * 
 * @export
 * @interface BusinessAccountWarningIndicatorsObj
 */
export interface BusinessAccountWarningIndicatorsObj {
    /**
     * Количество продуктов, по которым есть проблема или предупреждение
     * @type {number}
     * @memberof BusinessAccountWarningIndicatorsObj
     */
    currentProductsCount: number;
    /**
     * Количество арендаторов, по которым есть проблема или предупреждение
     * @type {number}
     * @memberof BusinessAccountWarningIndicatorsObj
     */
    currentRentersCount: number;
    /**
     * Количество проектов, по которым есть проблема или предупреждение
     * @type {number}
     * @memberof BusinessAccountWarningIndicatorsObj
     */
    currentProjectsCount: number;
    /**
     * Есть ли проблема по аренде (любому статусу аренды)
     * @type {boolean}
     * @memberof BusinessAccountWarningIndicatorsObj
     */
    rentProblem: boolean;
    /**
     * Количество поставок, по которым есть проблема или предупреждение
     * @type {number}
     * @memberof BusinessAccountWarningIndicatorsObj
     */
    currentSubrentsCount: number;
    /**
     * Есть ли проблема по субаренде (любому статусу субаренды)
     * @type {boolean}
     * @memberof BusinessAccountWarningIndicatorsObj
     */
    subrentProblem: boolean;
    /**
     * Количество элементов аренды, по которым есть проблема
     * @type {number}
     * @memberof BusinessAccountWarningIndicatorsObj
     */
    currentRentElementsCount: number;
}
/**
 * Объект для явной передачи бизнес версии
 * @export
 * @interface BusinessVersionObj
 */
export interface BusinessVersionObj {
    /**
     * Бизнес версия
     * @type {number}
     * @memberof BusinessVersionObj
     */
    businessVersion: number;
}
/**
 * Расчет корзины
 * @export
 * @interface CalculatedCart
 */
export interface CalculatedCart {
    /**
     * Дата и время начала аренды
     * @type {Date}
     * @memberof CalculatedCart
     */
    rentPeriodStartDate: Date;
    /**
     * Дата и время окончания аренды
     * @type {Date}
     * @memberof CalculatedCart
     */
    rentPeriodEndDate: Date;
    /**
     * Позиции корзины - аренда товаров и заказ услуг
     * @type {Array<CalculatedCartPosition>}
     * @memberof CalculatedCart
     */
    positionsCalculated: Array<CalculatedCartPosition>;
    /**
     * Итоговая суммарная стоимость всех позиций в корзине
     * @type {number}
     * @memberof CalculatedCart
     */
    totalCostSum: number;
    /**
     * Персональная скидка арендатора по данному заказу
     * @type {number}
     * @memberof CalculatedCart
     */
    personalDiscount?: number;
}
/**
 * Расчет позиции в корзине
 * @export
 * @interface CalculatedCartPosition
 */
export interface CalculatedCartPosition {
    /**
     * Идентификатор торгового предложения
     * @type {number}
     * @memberof CalculatedCartPosition
     */
    offerId: number;
    /**
     * Краткое наименование номенклатуры
     * @type {string}
     * @memberof CalculatedCartPosition
     */
    nomenclatureShortName: string;
    /**
     * 
     * @type {OfferNomenclatureEntityTypeCodeEnum}
     * @memberof CalculatedCartPosition
     */
    entityTypeCodeEnum: OfferNomenclatureEntityTypeCodeEnum;
    /**
     * Число экз. товара либо работников, оказывающих услугу
     * @type {number}
     * @memberof CalculatedCartPosition
     */
    instanceCount: number;
    /**
     * Число доступных экз. товара либо работников, оказывающих услугу
     * @type {number}
     * @memberof CalculatedCartPosition
     */
    availableInstanceCount?: number;
    /**
     * Итоговая стоимость позиции
     * @type {number}
     * @memberof CalculatedCartPosition
     */
    totalCost: number;
    /**
     * Дополнительная скидка по ценовой схеме в процентах
     * @type {number}
     * @memberof CalculatedCartPosition
     */
    extraDiscountByPricingScheme?: number;
}
/**
 * Данные обязательства отменяемого через механизм быстрой отмены
 * @export
 * @interface CanceledRentElementObj
 */
export interface CanceledRentElementObj {
    /**
     * Идентификатор
     * @type {number}
     * @memberof CanceledRentElementObj
     */
    id: number;
    /**
     * Версия
     * @type {number}
     * @memberof CanceledRentElementObj
     */
    businessVersion: number;
    /**
     * Набор обязательств, к которому принадлежит данное обязательство
     * @type {number}
     * @memberof CanceledRentElementObj
     */
    rentElementsKitId?: number;
}
/**
 * Список данных обязательств отменяемых через механизм быстрок отмены
 * @export
 * @interface CanceledRentElementObjList
 */
export interface CanceledRentElementObjList {
    /**
     * 
     * @type {Array<CanceledRentElementObj>}
     * @memberof CanceledRentElementObjList
     */
    entities: Array<CanceledRentElementObj>;
}
/**
 * Тип сущности поддерживающей категории
 * @export
 * @enum {string}
 */
export enum CategorisedEntityCodeEnum {
    RENTER = 'RENTER',
    PRODUCT = 'PRODUCT',
    KIT = 'KIT'
}

/**
 * Код типа сущности поддерживающей категории
 * @export
 * @enum {string}
 */
export enum CategorizedEntityTypeCodeEnum {
    KIT = 'KIT',
    PRODUCT = 'PRODUCT',
    RENTER = 'RENTER'
}

/**
 * Объект описывающий массовое назначение или отвязку категории
 * @export
 * @interface CategoryBulkLinkObj
 */
export interface CategoryBulkLinkObj {
    /**
     * Идентификатор категории
     * @type {number}
     * @memberof CategoryBulkLinkObj
     */
    categoryId: number;
    /**
     * 
     * @type {CategorisedEntityCodeEnum}
     * @memberof CategoryBulkLinkObj
     */
    entityTypeCode: CategorisedEntityCodeEnum;
    /**
     * Идентификаторы сущностей
     * @type {Array<number>}
     * @memberof CategoryBulkLinkObj
     */
    entityIds: Array<number>;
}
/**
 * Множество деревьев категорий
 * @export
 * @interface CategoryForest
 */
export interface CategoryForest {
    /**
     * 
     * @type {Array<CategoryTreeNode>}
     * @memberof CategoryForest
     */
    results: Array<CategoryTreeNode>;
}
/**
 * Категория: абстрактное общее состояние для всех операций
 * @export
 * @interface CategoryInfoAbstractCommons
 */
export interface CategoryInfoAbstractCommons {
    /**
     * Идентификатор
     * @type {number}
     * @memberof CategoryInfoAbstractCommons
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof CategoryInfoAbstractCommons
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof CategoryInfoAbstractCommons
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof CategoryInfoAbstractCommons
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof CategoryInfoAbstractCommons
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof CategoryInfoAbstractCommons
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof CategoryInfoAbstractCommons
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof CategoryInfoAbstractCommons
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof CategoryInfoAbstractCommons
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof CategoryInfoAbstractCommons
     */
    businessAccountId?: number;
    /**
     * Порядок в родительской категории (или среди корневых при ее отсутствии)
     * @type {number}
     * @memberof CategoryInfoAbstractCommons
     */
    siblingOrder: number;
    /**
     * Ссылка: идентификатор родительской категории
     * @type {number}
     * @memberof CategoryInfoAbstractCommons
     */
    parentCategoryId?: number;
    /**
     * Наименование
     * @type {string}
     * @memberof CategoryInfoAbstractCommons
     */
    name?: string;
    /**
     * 
     * @type {CategoryTypeCodeEnum}
     * @memberof CategoryInfoAbstractCommons
     */
    typeCode?: CategoryTypeCodeEnum;
}
/**
 * 
 * @export
 * @interface CategoryInfoAbstractCommonsAllOf
 */
export interface CategoryInfoAbstractCommonsAllOf {
    /**
     * Порядок в родительской категории (или среди корневых при ее отсутствии)
     * @type {number}
     * @memberof CategoryInfoAbstractCommonsAllOf
     */
    siblingOrder: number;
    /**
     * Ссылка: идентификатор родительской категории
     * @type {number}
     * @memberof CategoryInfoAbstractCommonsAllOf
     */
    parentCategoryId?: number;
    /**
     * Наименование
     * @type {string}
     * @memberof CategoryInfoAbstractCommonsAllOf
     */
    name?: string;
    /**
     * 
     * @type {CategoryTypeCodeEnum}
     * @memberof CategoryInfoAbstractCommonsAllOf
     */
    typeCode?: CategoryTypeCodeEnum;
}
/**
 * Категория : абстрактное состояние при записи
 * @export
 * @interface CategoryInfoAbstractWriteCommons
 */
export interface CategoryInfoAbstractWriteCommons extends CategoryInfoAbstractCommons {
}
/**
 * Категория : состояние при создании
 * @export
 * @interface CategoryInfoCreate
 */
export interface CategoryInfoCreate extends CategoryInfoAbstractWriteCommons {
}
/**
 * Категория : состояние при чтении
 * @export
 * @interface CategoryInfoRead
 */
export interface CategoryInfoRead {
    /**
     * Идентификатор
     * @type {number}
     * @memberof CategoryInfoRead
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof CategoryInfoRead
     */
    businessVersion: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof CategoryInfoRead
     */
    creationAuthorId: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof CategoryInfoRead
     */
    creationAuthorFullName: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof CategoryInfoRead
     */
    creationDate: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof CategoryInfoRead
     */
    lastUpdateAuthorId: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof CategoryInfoRead
     */
    lastUpdateAuthorFullName: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof CategoryInfoRead
     */
    lastUpdateDate: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof CategoryInfoRead
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof CategoryInfoRead
     */
    businessAccountId: number;
    /**
     * Порядок в родительской категории (или среди корневых при ее отсутствии)
     * @type {number}
     * @memberof CategoryInfoRead
     */
    siblingOrder: number;
    /**
     * Ссылка: идентификатор родительской категории
     * @type {number}
     * @memberof CategoryInfoRead
     */
    parentCategoryId?: number;
    /**
     * Наименование
     * @type {string}
     * @memberof CategoryInfoRead
     */
    name: string;
    /**
     * 
     * @type {CategoryTypeCodeEnum}
     * @memberof CategoryInfoRead
     */
    typeCode: CategoryTypeCodeEnum;
    /**
     * Выч. по ссылке: наименование родительской категории
     * @type {string}
     * @memberof CategoryInfoRead
     */
    parentCategoryName?: string;
    /**
     * Количество подкатегорий
     * @type {number}
     * @memberof CategoryInfoRead
     */
    subcategoriesCount: number;
    /**
     * Количество связанных объектов (продуктов или арендаторов)
     * @type {number}
     * @memberof CategoryInfoRead
     */
    directRelatedObjectsCount: number;
    /**
     * Количество объектов, связанных через сабкатегории (продуктов или арендаторов)
     * @type {number}
     * @memberof CategoryInfoRead
     */
    transitiveRelatedObjectsCount: number;
}
/**
 * 
 * @export
 * @interface CategoryInfoReadAllOf
 */
export interface CategoryInfoReadAllOf {
    /**
     * Выч. по ссылке: наименование родительской категории
     * @type {string}
     * @memberof CategoryInfoReadAllOf
     */
    parentCategoryName?: string;
    /**
     * Количество подкатегорий
     * @type {number}
     * @memberof CategoryInfoReadAllOf
     */
    subcategoriesCount: number;
    /**
     * Количество связанных объектов (продуктов или арендаторов)
     * @type {number}
     * @memberof CategoryInfoReadAllOf
     */
    directRelatedObjectsCount: number;
    /**
     * Количество объектов, связанных через сабкатегории (продуктов или арендаторов)
     * @type {number}
     * @memberof CategoryInfoReadAllOf
     */
    transitiveRelatedObjectsCount: number;
}
/**
 * Категория : состояние при изменении
 * @export
 * @interface CategoryInfoUpdate
 */
export interface CategoryInfoUpdate extends CategoryInfoAbstractWriteCommons {
}
/**
 * Списочное представление категории
 * @export
 * @interface CategoryRecord
 */
export interface CategoryRecord {
    /**
     * Идентификатор
     * @type {number}
     * @memberof CategoryRecord
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof CategoryRecord
     */
    businessVersion: number;
    /**
     * Ссылка: Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof CategoryRecord
     */
    businessAccountId: number;
    /**
     * Ссылка: идентификатор родительской категории
     * @type {number}
     * @memberof CategoryRecord
     */
    parentCategoryId?: number;
    /**
     * Наименование
     * @type {string}
     * @memberof CategoryRecord
     */
    name: string;
}
/**
 * 
 * @export
 * @interface CategoryRecordAllOf
 */
export interface CategoryRecordAllOf {
    /**
     * Ссылка: идентификатор родительской категории
     * @type {number}
     * @memberof CategoryRecordAllOf
     */
    parentCategoryId?: number;
    /**
     * Наименование
     * @type {string}
     * @memberof CategoryRecordAllOf
     */
    name: string;
}
/**
 * Список записей категорий
 * @export
 * @interface CategoryRecordList
 */
export interface CategoryRecordList {
    /**
     * 
     * @type {ListAttributesInfo}
     * @memberof CategoryRecordList
     */
    listAttributes: ListAttributesInfo;
    /**
     * Записи категорий
     * @type {Array<CategoryRecord>}
     * @memberof CategoryRecordList
     */
    records: Array<CategoryRecord>;
    /**
     * 
     * @type {CategoryTypeCodeEnum}
     * @memberof CategoryRecordList
     */
    typeCode: CategoryTypeCodeEnum;
}
/**
 * 
 * @export
 * @interface CategoryRecordListAllOf
 */
export interface CategoryRecordListAllOf {
    /**
     * 
     * @type {CategoryTypeCodeEnum}
     * @memberof CategoryRecordListAllOf
     */
    typeCode: CategoryTypeCodeEnum;
    /**
     * Записи категорий
     * @type {Array<CategoryRecord>}
     * @memberof CategoryRecordListAllOf
     */
    records: Array<CategoryRecord>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum CategorySortOrderEnum {
    NAME = 'NAME',
    SIBLINGORDER = 'SIBLING_ORDER'
}

/**
 * Древовидное представление множества категорий
 * @export
 * @interface CategoryTreeNode
 */
export interface CategoryTreeNode {
    /**
     * Идентификатор
     * @type {number}
     * @memberof CategoryTreeNode
     */
    id: number;
    /**
     * Список подкатегорий
     * @type {Array<CategoryTreeNode>}
     * @memberof CategoryTreeNode
     */
    subcategories?: Array<CategoryTreeNode>;
    /**
     * Порядок в родительской категории (или среди корневых при ее отсутствии)
     * @type {number}
     * @memberof CategoryTreeNode
     */
    siblingOrder: number;
    /**
     * Наименование
     * @type {string}
     * @memberof CategoryTreeNode
     */
    name: string;
}
/**
 * Тип категории, выбор из перечисления
 * @export
 * @enum {string}
 */
export enum CategoryTypeCodeEnum {
    RENTER = 'RENTER',
    PRODUCT = 'PRODUCT'
}

/**
 * Корзина для оформления заказа
 * @export
 * @interface ClientCart
 */
export interface ClientCart {
    /**
     * Дата и время начала аренды
     * @type {Date}
     * @memberof ClientCart
     */
    rentPeriodStartDate: Date;
    /**
     * Дата и время окончания аренды
     * @type {Date}
     * @memberof ClientCart
     */
    rentPeriodEndDate: Date;
    /**
     * Позиции корзины
     * @type {Array<ClientCartPosition>}
     * @memberof ClientCart
     */
    positions?: Array<ClientCartPosition>;
}
/**
 * Позиция в корзине для оформления заказа
 * @export
 * @interface ClientCartPosition
 */
export interface ClientCartPosition {
    /**
     * Идентификатор торгового предложения
     * @type {number}
     * @memberof ClientCartPosition
     */
    offerId: number;
    /**
     * 
     * @type {OfferNomenclatureEntityTypeCodeEnum}
     * @memberof ClientCartPosition
     */
    entityType?: OfferNomenclatureEntityTypeCodeEnum;
    /**
     * Число экземпляров
     * @type {number}
     * @memberof ClientCartPosition
     */
    instanceCount: number;
}
/**
 * Данные для изменения профиля клиентского пользователя
 * @export
 * @interface ClientUserInfoUpdate
 */
export interface ClientUserInfoUpdate {
    /**
     * Имя
     * @type {string}
     * @memberof ClientUserInfoUpdate
     */
    firstName: string;
    /**
     * Фамилия
     * @type {string}
     * @memberof ClientUserInfoUpdate
     */
    lastName: string;
    /**
     * Электронная почта
     * @type {string}
     * @memberof ClientUserInfoUpdate
     */
    email: string;
    /**
     * Номер телефона
     * @type {string}
     * @memberof ClientUserInfoUpdate
     */
    phoneNumber?: string;
}
/**
 * 
 * @export
 * @interface ComplexModificationRule
 */
export interface ComplexModificationRule {
    /**
     * 
     * @type {string}
     * @memberof ComplexModificationRule
     */
    commonRule?: string;
    /**
     * 
     * @type {string}
     * @memberof ComplexModificationRule
     */
    customPattern?: string;
    /**
     * 
     * @type {string}
     * @memberof ComplexModificationRule
     */
    dataType?: ComplexModificationRuleDataTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ComplexModificationRuleDataTypeEnum {
    MONEYVALUE = 'MONEY_VALUE',
    TIMESTAMPVALUE = 'TIMESTAMP_VALUE',
    INTEGERVALUE = 'INTEGER_VALUE',
    FLOATINGPOINTVALUE = 'FLOATING_POINT_VALUE'
}

/**
 * Описание операции создаваемой в интерфейсе в текущий момент. Служит для борьбы с конфликтами при изменении обязательств
 * @export
 * @interface ConcurrentOperationObj
 */
export interface ConcurrentOperationObj {
    /**
     * Временный идентификатор, получаемый при регистрации создаваемой операции на сервере. Передается и обязателен при чтении, а также при обновлении состояния зарегистрированной операции
     * @type {string}
     * @memberof ConcurrentOperationObj
     */
    uuid?: string;
    /**
     * 
     * @type {ActivityFrameObj}
     * @memberof ConcurrentOperationObj
     */
    activityFrame: ActivityFrameObj;
    /**
     * 
     * @type {OperationTypeCodeEnum}
     * @memberof ConcurrentOperationObj
     */
    operationType: OperationTypeCodeEnum;
    /**
     * 
     * @type {RentStateCodeEnum}
     * @memberof ConcurrentOperationObj
     */
    targetStateCode?: RentStateCodeEnum;
    /**
     * Дата и время открытия формы операции
     * @type {Date}
     * @memberof ConcurrentOperationObj
     */
    progressStartedDate: Date;
    /**
     * Ссылка: идентификатор автора создания - передается и обязателен только при чтении
     * @type {number}
     * @memberof ConcurrentOperationObj
     */
    authorId?: number;
    /**
     * Выч. по ссылке: короткое ФИО автора создания - передается и обязателен только при чтении
     * @type {string}
     * @memberof ConcurrentOperationObj
     */
    authorShortName?: string;
}
/**
 * Список создаваемых в данный момент операций
 * @export
 * @interface ConcurrentOperationObjList
 */
export interface ConcurrentOperationObjList {
    /**
     * 
     * @type {ListAttributesInfo}
     * @memberof ConcurrentOperationObjList
     */
    listAttributes: ListAttributesInfo;
    /**
     * 
     * @type {Array<ConcurrentOperationObj>}
     * @memberof ConcurrentOperationObjList
     */
    records: Array<ConcurrentOperationObj>;
    /**
     * UUID текущей операции
     * @type {string}
     * @memberof ConcurrentOperationObjList
     */
    currentOperationUUID?: string;
}
/**
 * 
 * @export
 * @interface ConcurrentOperationObjListAllOf
 */
export interface ConcurrentOperationObjListAllOf {
    /**
     * UUID текущей операции
     * @type {string}
     * @memberof ConcurrentOperationObjListAllOf
     */
    currentOperationUUID?: string;
    /**
     * 
     * @type {Array<ConcurrentOperationObj>}
     * @memberof ConcurrentOperationObjListAllOf
     */
    records: Array<ConcurrentOperationObj>;
}
/**
 * Информация для назначения контакта на сущность
 * @export
 * @interface ContactAssignInfo
 */
export interface ContactAssignInfo {
    /**
     * Идентификатор контакта
     * @type {number}
     * @memberof ContactAssignInfo
     */
    contactId: number;
    /**
     * Идентификатор связанной сущности
     * @type {number}
     * @memberof ContactAssignInfo
     */
    assignedToEntityId?: number;
    /**
     * 
     * @type {ContactLinkTypeCodeEnum}
     * @memberof ContactAssignInfo
     */
    linkType: ContactLinkTypeCodeEnum;
    /**
     * Должность контакта применительно к связанной сущности
     * @type {string}
     * @memberof ContactAssignInfo
     */
    position?: string;
    /**
     * Комментарий
     * @type {string}
     * @memberof ContactAssignInfo
     */
    comment?: string;
    /**
     * 
     * @type {ContactTypeCodeEnum}
     * @memberof ContactAssignInfo
     */
    contactType?: ContactTypeCodeEnum;
    /**
     * Является ли контакт основным
     * @type {boolean}
     * @memberof ContactAssignInfo
     */
    isMainContact: boolean;
}
/**
 * Контакт: абстрактное общее состояние для всех операций
 * @export
 * @interface ContactInfoAbstractCommons
 */
export interface ContactInfoAbstractCommons {
    /**
     * Идентификатор
     * @type {number}
     * @memberof ContactInfoAbstractCommons
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof ContactInfoAbstractCommons
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof ContactInfoAbstractCommons
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof ContactInfoAbstractCommons
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof ContactInfoAbstractCommons
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof ContactInfoAbstractCommons
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof ContactInfoAbstractCommons
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof ContactInfoAbstractCommons
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof ContactInfoAbstractCommons
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof ContactInfoAbstractCommons
     */
    businessAccountId?: number;
    /**
     * Имя
     * @type {string}
     * @memberof ContactInfoAbstractCommons
     */
    firstName: string;
    /**
     * Отчество
     * @type {string}
     * @memberof ContactInfoAbstractCommons
     */
    secondName?: string;
    /**
     * Фамилия
     * @type {string}
     * @memberof ContactInfoAbstractCommons
     */
    lastName: string;
    /**
     * Номер телефона
     * @type {string}
     * @memberof ContactInfoAbstractCommons
     */
    phoneNumber?: string;
    /**
     * Адрес электронной почты
     * @type {string}
     * @memberof ContactInfoAbstractCommons
     */
    email?: string;
    /**
     * Основная должность (базовая, может перезаписываться при назначении контакта на другие сущности)
     * @type {string}
     * @memberof ContactInfoAbstractCommons
     */
    primaryPosition?: string;
    /**
     * Номер паспорта (полный номер с серией)
     * @type {string}
     * @memberof ContactInfoAbstractCommons
     */
    passport?: string;
    /**
     * 
     * @type {ImageObj}
     * @memberof ContactInfoAbstractCommons
     */
    mainImage?: ImageObj;
    /**
     * Флаг нахождения в архиве
     * @type {boolean}
     * @memberof ContactInfoAbstractCommons
     */
    archive: boolean;
    /**
     * Ссылки на соцсети
     * @type {Array<SocialNetworkLinkAbstractInfo>}
     * @memberof ContactInfoAbstractCommons
     */
    socialNetworks?: Array<SocialNetworkLinkAbstractInfo>;
}
/**
 * 
 * @export
 * @interface ContactInfoAbstractCommonsAllOf
 */
export interface ContactInfoAbstractCommonsAllOf {
    /**
     * Имя
     * @type {string}
     * @memberof ContactInfoAbstractCommonsAllOf
     */
    firstName: string;
    /**
     * Отчество
     * @type {string}
     * @memberof ContactInfoAbstractCommonsAllOf
     */
    secondName?: string;
    /**
     * Фамилия
     * @type {string}
     * @memberof ContactInfoAbstractCommonsAllOf
     */
    lastName: string;
    /**
     * Номер телефона
     * @type {string}
     * @memberof ContactInfoAbstractCommonsAllOf
     */
    phoneNumber?: string;
    /**
     * Адрес электронной почты
     * @type {string}
     * @memberof ContactInfoAbstractCommonsAllOf
     */
    email?: string;
    /**
     * Основная должность (базовая, может перезаписываться при назначении контакта на другие сущности)
     * @type {string}
     * @memberof ContactInfoAbstractCommonsAllOf
     */
    primaryPosition?: string;
    /**
     * Номер паспорта (полный номер с серией)
     * @type {string}
     * @memberof ContactInfoAbstractCommonsAllOf
     */
    passport?: string;
    /**
     * 
     * @type {ImageObj}
     * @memberof ContactInfoAbstractCommonsAllOf
     */
    mainImage?: ImageObj;
    /**
     * Флаг нахождения в архиве
     * @type {boolean}
     * @memberof ContactInfoAbstractCommonsAllOf
     */
    archive: boolean;
    /**
     * Ссылки на соцсети
     * @type {Array<SocialNetworkLinkAbstractInfo>}
     * @memberof ContactInfoAbstractCommonsAllOf
     */
    socialNetworks?: Array<SocialNetworkLinkAbstractInfo>;
}
/**
 * 
 * @export
 * @interface ContactInfoCheck
 */
export interface ContactInfoCheck {
    /**
     * 
     * @type {number}
     * @memberof ContactInfoCheck
     */
    updatedId?: number;
    /**
     * 
     * @type {string}
     * @memberof ContactInfoCheck
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInfoCheck
     */
    phone?: string;
}
/**
 * Контакт: состояние при создании
 * @export
 * @interface ContactInfoCreate
 */
export interface ContactInfoCreate extends ContactInfoAbstractCommons {
}
/**
 * Контакт: состояние чтения
 * @export
 * @interface ContactInfoRead
 */
export interface ContactInfoRead {
    /**
     * Идентификатор
     * @type {number}
     * @memberof ContactInfoRead
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof ContactInfoRead
     */
    businessVersion: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof ContactInfoRead
     */
    creationAuthorId: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof ContactInfoRead
     */
    creationAuthorFullName: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof ContactInfoRead
     */
    creationDate: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof ContactInfoRead
     */
    lastUpdateAuthorId: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof ContactInfoRead
     */
    lastUpdateAuthorFullName: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof ContactInfoRead
     */
    lastUpdateDate: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof ContactInfoRead
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof ContactInfoRead
     */
    businessAccountId: number;
    /**
     * Имя
     * @type {string}
     * @memberof ContactInfoRead
     */
    firstName: string;
    /**
     * Отчество
     * @type {string}
     * @memberof ContactInfoRead
     */
    secondName?: string;
    /**
     * Фамилия
     * @type {string}
     * @memberof ContactInfoRead
     */
    lastName: string;
    /**
     * Номер телефона
     * @type {string}
     * @memberof ContactInfoRead
     */
    phoneNumber?: string;
    /**
     * Адрес электронной почты
     * @type {string}
     * @memberof ContactInfoRead
     */
    email?: string;
    /**
     * Основная должность (базовая, может перезаписываться при назначении контакта на другие сущности)
     * @type {string}
     * @memberof ContactInfoRead
     */
    primaryPosition?: string;
    /**
     * Номер паспорта (полный номер с серией)
     * @type {string}
     * @memberof ContactInfoRead
     */
    passport?: string;
    /**
     * 
     * @type {ImageObj}
     * @memberof ContactInfoRead
     */
    mainImage?: ImageObj;
    /**
     * Флаг нахождения в архиве
     * @type {boolean}
     * @memberof ContactInfoRead
     */
    archive: boolean;
    /**
     * Ссылки на соцсети
     * @type {Array<SocialNetworkLinkAbstractInfo>}
     * @memberof ContactInfoRead
     */
    socialNetworks?: Array<SocialNetworkLinkAbstractInfo>;
    /**
     * ФИО - фамилия и инициалы
     * @type {string}
     * @memberof ContactInfoRead
     */
    personShortName: string;
    /**
     * URL изображения, если есть
     * @type {string}
     * @memberof ContactInfoRead
     */
    directImageURL?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ContactInfoRead
     */
    isBusinessAccountRRUser: boolean;
    /**
     * 
     * @type {number}
     * @memberof ContactInfoRead
     */
    userId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ContactInfoRead
     */
    isCrewMember: boolean;
    /**
     * 
     * @type {number}
     * @memberof ContactInfoRead
     */
    crewMemberId?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ContactInfoRead
     */
    crewMemberProfessions?: Array<string>;
    /**
     * Роли в целевом бизнес аккаунте
     * @type {Array<UserBARoleCodeEnum>}
     * @memberof ContactInfoRead
     */
    baRoles?: Array<UserBARoleCodeEnum>;
    /**
     * 
     * @type {AttachmentRecordList}
     * @memberof ContactInfoRead
     */
    attachments?: AttachmentRecordList;
    /**
     * Число входящий ссылок (кол-во сущностей которые ссылаются на данный контакт)
     * @type {number}
     * @memberof ContactInfoRead
     */
    referenceCount: number;
    /**
     * Может ли быть отправлен в архив (может, когда не в архиве)
     * @type {boolean}
     * @memberof ContactInfoRead
     */
    archivable: boolean;
    /**
     * Может ли быть удален
     * @type {boolean}
     * @memberof ContactInfoRead
     */
    deleteable: boolean;
}
/**
 * 
 * @export
 * @interface ContactInfoReadAllOf
 */
export interface ContactInfoReadAllOf {
    /**
     * ФИО - фамилия и инициалы
     * @type {string}
     * @memberof ContactInfoReadAllOf
     */
    personShortName: string;
    /**
     * URL изображения, если есть
     * @type {string}
     * @memberof ContactInfoReadAllOf
     */
    directImageURL?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ContactInfoReadAllOf
     */
    isBusinessAccountRRUser: boolean;
    /**
     * 
     * @type {number}
     * @memberof ContactInfoReadAllOf
     */
    userId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ContactInfoReadAllOf
     */
    isCrewMember: boolean;
    /**
     * 
     * @type {number}
     * @memberof ContactInfoReadAllOf
     */
    crewMemberId?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ContactInfoReadAllOf
     */
    crewMemberProfessions?: Array<string>;
    /**
     * Роли в целевом бизнес аккаунте
     * @type {Array<UserBARoleCodeEnum>}
     * @memberof ContactInfoReadAllOf
     */
    baRoles?: Array<UserBARoleCodeEnum>;
    /**
     * 
     * @type {AttachmentRecordList}
     * @memberof ContactInfoReadAllOf
     */
    attachments?: AttachmentRecordList;
    /**
     * Число входящий ссылок (кол-во сущностей которые ссылаются на данный контакт)
     * @type {number}
     * @memberof ContactInfoReadAllOf
     */
    referenceCount: number;
    /**
     * Может ли быть отправлен в архив (может, когда не в архиве)
     * @type {boolean}
     * @memberof ContactInfoReadAllOf
     */
    archivable: boolean;
    /**
     * Может ли быть удален
     * @type {boolean}
     * @memberof ContactInfoReadAllOf
     */
    deleteable: boolean;
}
/**
 * Контакт: состояние при редактировании
 * @export
 * @interface ContactInfoUpdate
 */
export interface ContactInfoUpdate extends ContactInfoAbstractCommons {
}
/**
 * Контакт сущности - состояние чтения
 * @export
 * @interface ContactLinkObjRead
 */
export interface ContactLinkObjRead {
    /**
     * 
     * @type {string}
     * @memberof ContactLinkObjRead
     */
    position?: string;
    /**
     * 
     * @type {number}
     * @memberof ContactLinkObjRead
     */
    contactId: number;
    /**
     * 
     * @type {boolean}
     * @memberof ContactLinkObjRead
     */
    isMainContact: boolean;
    /**
     * ФИО - инициалы и фамилия
     * @type {string}
     * @memberof ContactLinkObjRead
     */
    shortName: string;
    /**
     * 
     * @type {ImageObj}
     * @memberof ContactLinkObjRead
     */
    image?: ImageObj;
}
/**
 * 
 * @export
 * @interface ContactLinkObjReadAllOf
 */
export interface ContactLinkObjReadAllOf {
    /**
     * ФИО - инициалы и фамилия
     * @type {string}
     * @memberof ContactLinkObjReadAllOf
     */
    shortName: string;
    /**
     * 
     * @type {boolean}
     * @memberof ContactLinkObjReadAllOf
     */
    isMainContact: boolean;
    /**
     * 
     * @type {ImageObj}
     * @memberof ContactLinkObjReadAllOf
     */
    image?: ImageObj;
}
/**
 * Контакт сущности - состояние записи
 * @export
 * @interface ContactLinkObjWrite
 */
export interface ContactLinkObjWrite {
    /**
     * 
     * @type {string}
     * @memberof ContactLinkObjWrite
     */
    position?: string;
    /**
     * 
     * @type {number}
     * @memberof ContactLinkObjWrite
     */
    contactId: number;
    /**
     * 
     * @type {boolean}
     * @memberof ContactLinkObjWrite
     */
    isMainContact?: boolean;
}
/**
 * Тип сущности, к которой относится контакт
 * @export
 * @enum {string}
 */
export enum ContactLinkTypeCodeEnum {
    RENTER = 'RENTER',
    PROJECT = 'PROJECT',
    SUBRENT = 'SUBRENT',
    LOCATION = 'LOCATION',
    TRANSPORTVEHICLE = 'TRANSPORT_VEHICLE',
    TRANSPORTATION = 'TRANSPORTATION'
}

/**
 * Все поля сущности ContactRecord, которые могут быть выгружены в список Excel. Финансовых полей нет
 * @export
 * @enum {string}
 */
export enum ContactNotFinancialFieldsTypeCode {
    PersonShortName = 'personShortName',
    IsCrewMember = 'isCrewMember',
    PrimaryPosition = 'primaryPosition',
    PhoneNumber = 'phoneNumber',
    Email = 'email',
    AttachmentCount = 'attachmentCount',
    ReferenceCount = 'referenceCount',
    LastUpdateAuthorShortName = 'lastUpdateAuthorShortName',
    LastUpdateDate = 'lastUpdateDate'
}

/**
 * 
 * @export
 * @interface ContactPersonAPIObj
 */
export interface ContactPersonAPIObj {
    /**
     * 
     * @type {PersonFullName}
     * @memberof ContactPersonAPIObj
     */
    fullName: PersonFullName;
    /**
     * Номер телефона
     * @type {string}
     * @memberof ContactPersonAPIObj
     */
    phoneNumber?: string;
    /**
     * Электронная почта
     * @type {string}
     * @memberof ContactPersonAPIObj
     */
    email?: string;
    /**
     * Должность
     * @type {string}
     * @memberof ContactPersonAPIObj
     */
    position?: string;
}
/**
 * Контакт: списочное предствление
 * @export
 * @interface ContactRecord
 */
export interface ContactRecord {
    /**
     * Идентификатор
     * @type {number}
     * @memberof ContactRecord
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof ContactRecord
     */
    businessVersion: number;
    /**
     * Ссылка: Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof ContactRecord
     */
    businessAccountId: number;
    /**
     * URL изображения, если есть
     * @type {string}
     * @memberof ContactRecord
     */
    directImageURL?: string;
    /**
     * 
     * @type {ImageObj}
     * @memberof ContactRecord
     */
    mainImage?: ImageObj;
    /**
     * ФИО - фамилия и инициалы
     * @type {string}
     * @memberof ContactRecord
     */
    personShortName: string;
    /**
     * ФИО полностью
     * @type {string}
     * @memberof ContactRecord
     */
    personFullName?: string;
    /**
     * Находится ли в архиве
     * @type {boolean}
     * @memberof ContactRecord
     */
    archive: boolean;
    /**
     * Может ли быть отправлен в архив (может, когда не в архиве)
     * @type {boolean}
     * @memberof ContactRecord
     */
    archivable: boolean;
    /**
     * Может ли быть удален
     * @type {boolean}
     * @memberof ContactRecord
     */
    deleteable?: boolean;
    /**
     * Основная должность (базовая, может перезаписываться при назначении контакта на другие сущности)
     * @type {string}
     * @memberof ContactRecord
     */
    primaryPosition?: string;
    /**
     * Номер паспорта (полный номер с серией)
     * @type {string}
     * @memberof ContactRecord
     */
    passport?: string;
    /**
     * Подготовленный номер телефона
     * @type {string}
     * @memberof ContactRecord
     */
    phoneNumber?: string;
    /**
     * Число вложений
     * @type {number}
     * @memberof ContactRecord
     */
    attachmentCount?: number;
    /**
     * Число входящий ссылок (кол-во сущностей которые ссылаются на данный контакт)
     * @type {number}
     * @memberof ContactRecord
     */
    referenceCount?: number;
    /**
     * Адрес электронной почты
     * @type {string}
     * @memberof ContactRecord
     */
    email?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ContactRecord
     */
    isBusinessAccountRRUser: boolean;
    /**
     * 
     * @type {number}
     * @memberof ContactRecord
     */
    userId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ContactRecord
     */
    isCrewMember: boolean;
    /**
     * 
     * @type {number}
     * @memberof ContactRecord
     */
    crewMemberId?: number;
    /**
     * Ссылка: идентификатор автора посл. изменения
     * @type {number}
     * @memberof ContactRecord
     */
    lastUpdateAuthorId: number;
    /**
     * Выч. по ссылке: короткое ФИО автора посл. изменения
     * @type {string}
     * @memberof ContactRecord
     */
    lastUpdateAuthorShortName: string;
    /**
     * Дата и время посл. изменения
     * @type {Date}
     * @memberof ContactRecord
     */
    lastUpdateDate: Date;
}
/**
 * 
 * @export
 * @interface ContactRecordAllOf
 */
export interface ContactRecordAllOf {
    /**
     * URL изображения, если есть
     * @type {string}
     * @memberof ContactRecordAllOf
     */
    directImageURL?: string;
    /**
     * 
     * @type {ImageObj}
     * @memberof ContactRecordAllOf
     */
    mainImage?: ImageObj;
    /**
     * ФИО - фамилия и инициалы
     * @type {string}
     * @memberof ContactRecordAllOf
     */
    personShortName: string;
    /**
     * ФИО полностью
     * @type {string}
     * @memberof ContactRecordAllOf
     */
    personFullName?: string;
    /**
     * Находится ли в архиве
     * @type {boolean}
     * @memberof ContactRecordAllOf
     */
    archive: boolean;
    /**
     * Может ли быть отправлен в архив (может, когда не в архиве)
     * @type {boolean}
     * @memberof ContactRecordAllOf
     */
    archivable: boolean;
    /**
     * Может ли быть удален
     * @type {boolean}
     * @memberof ContactRecordAllOf
     */
    deleteable?: boolean;
    /**
     * Основная должность (базовая, может перезаписываться при назначении контакта на другие сущности)
     * @type {string}
     * @memberof ContactRecordAllOf
     */
    primaryPosition?: string;
    /**
     * Номер паспорта (полный номер с серией)
     * @type {string}
     * @memberof ContactRecordAllOf
     */
    passport?: string;
    /**
     * Подготовленный номер телефона
     * @type {string}
     * @memberof ContactRecordAllOf
     */
    phoneNumber?: string;
    /**
     * Число вложений
     * @type {number}
     * @memberof ContactRecordAllOf
     */
    attachmentCount?: number;
    /**
     * Число входящий ссылок (кол-во сущностей которые ссылаются на данный контакт)
     * @type {number}
     * @memberof ContactRecordAllOf
     */
    referenceCount?: number;
    /**
     * Адрес электронной почты
     * @type {string}
     * @memberof ContactRecordAllOf
     */
    email?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ContactRecordAllOf
     */
    isBusinessAccountRRUser: boolean;
    /**
     * 
     * @type {number}
     * @memberof ContactRecordAllOf
     */
    userId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ContactRecordAllOf
     */
    isCrewMember: boolean;
    /**
     * 
     * @type {number}
     * @memberof ContactRecordAllOf
     */
    crewMemberId?: number;
    /**
     * Ссылка: идентификатор автора посл. изменения
     * @type {number}
     * @memberof ContactRecordAllOf
     */
    lastUpdateAuthorId: number;
    /**
     * Выч. по ссылке: короткое ФИО автора посл. изменения
     * @type {string}
     * @memberof ContactRecordAllOf
     */
    lastUpdateAuthorShortName: string;
    /**
     * Дата и время посл. изменения
     * @type {Date}
     * @memberof ContactRecordAllOf
     */
    lastUpdateDate: Date;
}
/**
 * Список записей контактов
 * @export
 * @interface ContactRecordList
 */
export interface ContactRecordList {
    /**
     * 
     * @type {ListAttributesInfo}
     * @memberof ContactRecordList
     */
    listAttributes: ListAttributesInfo;
    /**
     * 
     * @type {Array<ContactRecord>}
     * @memberof ContactRecordList
     */
    records: Array<ContactRecord>;
}
/**
 * 
 * @export
 * @interface ContactRecordListAllOf
 */
export interface ContactRecordListAllOf {
    /**
     * 
     * @type {Array<ContactRecord>}
     * @memberof ContactRecordListAllOf
     */
    records: Array<ContactRecord>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ContactTypeCodeEnum {
    DRIVER = 'DRIVER',
    RECEIVER = 'RECEIVER',
    RECIPIENT = 'RECIPIENT'
}

/**
 * Сущность для чтения данных о договоре
 * @export
 * @interface ContractObjRead
 */
export interface ContractObjRead {
    /**
     * Номер договора
     * @type {string}
     * @memberof ContractObjRead
     */
    contractNumber?: string;
    /**
     * Дата договора
     * @type {string}
     * @memberof ContractObjRead
     */
    contractDate?: string;
    /**
     * Дата окончания действия договора
     * @type {string}
     * @memberof ContractObjRead
     */
    contractExpirationDate?: string;
    /**
     * Признак корректности и не истечения даты договора
     * @type {boolean}
     * @memberof ContractObjRead
     */
    isContractExpirationDateValid?: boolean;
    /**
     * Признак наличия файла договора
     * @type {boolean}
     * @memberof ContractObjRead
     */
    isContractFileAvailable?: boolean;
}
/**
 * Сущность для записи данных о договоре
 * @export
 * @interface ContractObjWrite
 */
export interface ContractObjWrite {
    /**
     * Номер договора
     * @type {string}
     * @memberof ContractObjWrite
     */
    contractNumber?: string;
    /**
     * Дата договора
     * @type {string}
     * @memberof ContractObjWrite
     */
    contractDate?: string;
    /**
     * Дата окончания действия договора
     * @type {string}
     * @memberof ContractObjWrite
     */
    contractExpirationDate?: string;
}
/**
 * Код типа валидации контрактов
 * @export
 * @enum {string}
 */
export enum ContractValidationTypeCodeEnum {
    NOVALIDATION = 'NO_VALIDATION',
    ONLYFILE = 'ONLY_FILE',
    ALLFIELDS = 'ALL_FIELDS'
}

/**
 * Код юридического типа арендатора, выбор из перечисления
 * @export
 * @enum {string}
 */
export enum CounterpartyActivityTypeCodeEnum {
    RENTERONLY = 'RENTER_ONLY',
    SUPPLIERONLY = 'SUPPLIER_ONLY',
    RENTERANDSUPPLIER = 'RENTER_AND_SUPPLIER'
}

/**
 * Коды стран ISO 3166-1 alpha-2
 * @export
 * @enum {string}
 */
export enum CountryCodeEnum {
    AU = 'AU',
    AT = 'AT',
    AZ = 'AZ',
    AX = 'AX',
    AL = 'AL',
    DZ = 'DZ',
    VI = 'VI',
    AS = 'AS',
    AI = 'AI',
    AO = 'AO',
    AD = 'AD',
    AQ = 'AQ',
    AG = 'AG',
    AR = 'AR',
    AM = 'AM',
    AW = 'AW',
    AF = 'AF',
    BS = 'BS',
    BD = 'BD',
    BB = 'BB',
    BH = 'BH',
    BZ = 'BZ',
    BY = 'BY',
    BE = 'BE',
    BJ = 'BJ',
    BM = 'BM',
    BG = 'BG',
    BO = 'BO',
    BQ = 'BQ',
    BA = 'BA',
    BW = 'BW',
    BR = 'BR',
    IO = 'IO',
    VG = 'VG',
    BN = 'BN',
    BF = 'BF',
    BI = 'BI',
    BT = 'BT',
    VU = 'VU',
    VA = 'VA',
    GB = 'GB',
    HU = 'HU',
    VE = 'VE',
    UM = 'UM',
    TL = 'TL',
    VN = 'VN',
    GA = 'GA',
    HT = 'HT',
    GY = 'GY',
    GM = 'GM',
    GH = 'GH',
    GP = 'GP',
    GT = 'GT',
    GF = 'GF',
    GN = 'GN',
    GW = 'GW',
    DE = 'DE',
    GG = 'GG',
    GI = 'GI',
    HN = 'HN',
    HK = 'HK',
    GD = 'GD',
    GL = 'GL',
    GR = 'GR',
    GE = 'GE',
    GU = 'GU',
    DK = 'DK',
    JE = 'JE',
    DJ = 'DJ',
    DM = 'DM',
    DO = 'DO',
    CD = 'CD',
    EU = 'EU',
    EG = 'EG',
    ZM = 'ZM',
    EH = 'EH',
    ZW = 'ZW',
    IL = 'IL',
    IN = 'IN',
    ID = 'ID',
    JO = 'JO',
    IQ = 'IQ',
    IR = 'IR',
    IE = 'IE',
    IS = 'IS',
    ES = 'ES',
    IT = 'IT',
    YE = 'YE',
    CV = 'CV',
    KZ = 'KZ',
    KY = 'KY',
    KH = 'KH',
    CM = 'CM',
    CA = 'CA',
    QA = 'QA',
    KE = 'KE',
    CY = 'CY',
    KG = 'KG',
    KI = 'KI',
    TW = 'TW',
    KP = 'KP',
    CN = 'CN',
    CC = 'CC',
    CO = 'CO',
    KM = 'KM',
    CR = 'CR',
    CI = 'CI',
    CU = 'CU',
    KW = 'KW',
    CW = 'CW',
    LA = 'LA',
    LV = 'LV',
    LS = 'LS',
    LR = 'LR',
    LB = 'LB',
    LY = 'LY',
    LT = 'LT',
    LI = 'LI',
    LU = 'LU',
    MU = 'MU',
    MR = 'MR',
    MG = 'MG',
    YT = 'YT',
    MO = 'MO',
    MK = 'MK',
    MW = 'MW',
    MY = 'MY',
    ML = 'ML',
    MV = 'MV',
    MT = 'MT',
    MA = 'MA',
    MQ = 'MQ',
    MH = 'MH',
    MX = 'MX',
    FM = 'FM',
    MZ = 'MZ',
    MD = 'MD',
    MC = 'MC',
    MN = 'MN',
    MS = 'MS',
    MM = 'MM',
    NA = 'NA',
    NR = 'NR',
    NP = 'NP',
    NE = 'NE',
    NG = 'NG',
    NL = 'NL',
    NI = 'NI',
    NU = 'NU',
    NZ = 'NZ',
    NC = 'NC',
    NO = 'NO',
    AE = 'AE',
    OM = 'OM',
    BV = 'BV',
    IM = 'IM',
    CK = 'CK',
    NF = 'NF',
    CX = 'CX',
    PN = 'PN',
    SH = 'SH',
    PK = 'PK',
    PW = 'PW',
    PS = 'PS',
    PA = 'PA',
    PG = 'PG',
    PY = 'PY',
    PE = 'PE',
    PL = 'PL',
    PT = 'PT',
    PR = 'PR',
    CG = 'CG',
    KR = 'KR',
    RE = 'RE',
    RU = 'RU',
    RW = 'RW',
    RO = 'RO',
    SV = 'SV',
    WS = 'WS',
    SM = 'SM',
    ST = 'ST',
    SA = 'SA',
    SZ = 'SZ',
    MP = 'MP',
    SC = 'SC',
    BL = 'BL',
    MF = 'MF',
    PM = 'PM',
    SN = 'SN',
    VC = 'VC',
    KN = 'KN',
    LC = 'LC',
    RS = 'RS',
    SG = 'SG',
    SX = 'SX',
    SY = 'SY',
    SK = 'SK',
    SI = 'SI',
    SB = 'SB',
    SO = 'SO',
    SD = 'SD',
    SR = 'SR',
    US = 'US',
    SL = 'SL',
    TJ = 'TJ',
    TH = 'TH',
    TZ = 'TZ',
    TC = 'TC',
    TG = 'TG',
    TK = 'TK',
    TO = 'TO',
    TT = 'TT',
    TV = 'TV',
    TN = 'TN',
    TM = 'TM',
    TR = 'TR',
    UG = 'UG',
    UZ = 'UZ',
    UA = 'UA',
    WF = 'WF',
    UY = 'UY',
    FO = 'FO',
    FJ = 'FJ',
    PH = 'PH',
    FI = 'FI',
    FK = 'FK',
    FR = 'FR',
    PF = 'PF',
    TF = 'TF',
    HM = 'HM',
    HR = 'HR',
    CF = 'CF',
    TD = 'TD',
    ME = 'ME',
    CZ = 'CZ',
    CL = 'CL',
    CH = 'CH',
    SE = 'SE',
    SJ = 'SJ',
    LK = 'LK',
    EC = 'EC',
    GQ = 'GQ',
    ER = 'ER',
    EE = 'EE',
    ET = 'ET',
    ZA = 'ZA',
    GS = 'GS',
    SS = 'SS',
    JM = 'JM',
    JP = 'JP'
}

/**
 * Информация о всех реквизитах по стране
 * @export
 * @interface CountryLegalDetailsFieldsObjRead
 */
export interface CountryLegalDetailsFieldsObjRead {
    /**
     * 
     * @type {LegalSubjectTypeCodeEnum}
     * @memberof CountryLegalDetailsFieldsObjRead
     */
    subject: LegalSubjectTypeCodeEnum;
    /**
     * 
     * @type {Array<LegalDetailsFieldRecord>}
     * @memberof CountryLegalDetailsFieldsObjRead
     */
    fields: Array<LegalDetailsFieldRecord>;
}
/**
 * 
 * @export
 * @interface CreatedEntityInfo
 */
export interface CreatedEntityInfo {
    /**
     * Идентификатор созданой сущности
     * @type {number}
     * @memberof CreatedEntityInfo
     */
    id: number;
}
/**
 * Списочное представление системной настройки
 * @export
 * @interface CreatedImageInfo
 */
export interface CreatedImageInfo {
    /**
     * Идентификатор созданой сущности
     * @type {number}
     * @memberof CreatedImageInfo
     */
    id: number;
    /**
     * Внешний идентификатор
     * @type {string}
     * @memberof CreatedImageInfo
     */
    externalId: string;
}
/**
 * 
 * @export
 * @interface CreatedImageInfoAllOf
 */
export interface CreatedImageInfoAllOf {
    /**
     * Внешний идентификатор
     * @type {string}
     * @memberof CreatedImageInfoAllOf
     */
    externalId: string;
}
/**
 * 
 * @export
 * @interface CrewMemberAbstractInfoCommons
 */
export interface CrewMemberAbstractInfoCommons {
    /**
     * Идентификатор
     * @type {number}
     * @memberof CrewMemberAbstractInfoCommons
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof CrewMemberAbstractInfoCommons
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof CrewMemberAbstractInfoCommons
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof CrewMemberAbstractInfoCommons
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof CrewMemberAbstractInfoCommons
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof CrewMemberAbstractInfoCommons
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof CrewMemberAbstractInfoCommons
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof CrewMemberAbstractInfoCommons
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof CrewMemberAbstractInfoCommons
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof CrewMemberAbstractInfoCommons
     */
    businessAccountId?: number;
    /**
     * Описание
     * @type {string}
     * @memberof CrewMemberAbstractInfoCommons
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface CrewMemberAbstractInfoCommonsAllOf
 */
export interface CrewMemberAbstractInfoCommonsAllOf {
    /**
     * Описание
     * @type {string}
     * @memberof CrewMemberAbstractInfoCommonsAllOf
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface CrewMemberAbstractInfoWriteCommons
 */
export interface CrewMemberAbstractInfoWriteCommons {
    /**
     * Идентификатор
     * @type {number}
     * @memberof CrewMemberAbstractInfoWriteCommons
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof CrewMemberAbstractInfoWriteCommons
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof CrewMemberAbstractInfoWriteCommons
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof CrewMemberAbstractInfoWriteCommons
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof CrewMemberAbstractInfoWriteCommons
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof CrewMemberAbstractInfoWriteCommons
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof CrewMemberAbstractInfoWriteCommons
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof CrewMemberAbstractInfoWriteCommons
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof CrewMemberAbstractInfoWriteCommons
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof CrewMemberAbstractInfoWriteCommons
     */
    businessAccountId?: number;
    /**
     * Описание
     * @type {string}
     * @memberof CrewMemberAbstractInfoWriteCommons
     */
    description?: string;
    /**
     * Идентификатор контакта
     * @type {number}
     * @memberof CrewMemberAbstractInfoWriteCommons
     */
    contactId: number;
    /**
     * 
     * @type {Array<ProfessionAssignmentObjWrite>}
     * @memberof CrewMemberAbstractInfoWriteCommons
     */
    professions?: Array<ProfessionAssignmentObjWrite>;
    /**
     * 
     * @type {CrewMemberRatesObj}
     * @memberof CrewMemberAbstractInfoWriteCommons
     */
    defaultRates: CrewMemberRatesObj;
}
/**
 * 
 * @export
 * @interface CrewMemberAbstractInfoWriteCommonsAllOf
 */
export interface CrewMemberAbstractInfoWriteCommonsAllOf {
    /**
     * Идентификатор контакта
     * @type {number}
     * @memberof CrewMemberAbstractInfoWriteCommonsAllOf
     */
    contactId: number;
    /**
     * 
     * @type {Array<ProfessionAssignmentObjWrite>}
     * @memberof CrewMemberAbstractInfoWriteCommonsAllOf
     */
    professions?: Array<ProfessionAssignmentObjWrite>;
    /**
     * 
     * @type {CrewMemberRatesObj}
     * @memberof CrewMemberAbstractInfoWriteCommonsAllOf
     */
    defaultRates: CrewMemberRatesObj;
}
/**
 * Финансовые поля сущности CrewMemberRecord, которые могут быть выгружены в список Excel
 * @export
 * @enum {string}
 */
export enum CrewMemberFinancialFieldsTypeCode {
    RatePerShift = 'ratePerShift',
    RatePerHour = 'ratePerHour'
}

/**
 * 
 * @export
 * @interface CrewMemberInfoCreate
 */
export interface CrewMemberInfoCreate {
    /**
     * Идентификатор
     * @type {number}
     * @memberof CrewMemberInfoCreate
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof CrewMemberInfoCreate
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof CrewMemberInfoCreate
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof CrewMemberInfoCreate
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof CrewMemberInfoCreate
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof CrewMemberInfoCreate
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof CrewMemberInfoCreate
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof CrewMemberInfoCreate
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof CrewMemberInfoCreate
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof CrewMemberInfoCreate
     */
    businessAccountId?: number;
    /**
     * Описание
     * @type {string}
     * @memberof CrewMemberInfoCreate
     */
    description?: string;
    /**
     * Идентификатор контакта
     * @type {number}
     * @memberof CrewMemberInfoCreate
     */
    contactId: number;
    /**
     * 
     * @type {Array<ProfessionAssignmentObjWrite>}
     * @memberof CrewMemberInfoCreate
     */
    professions?: Array<ProfessionAssignmentObjWrite>;
    /**
     * 
     * @type {CrewMemberRatesObj}
     * @memberof CrewMemberInfoCreate
     */
    defaultRates: CrewMemberRatesObj;
    /**
     * 
     * @type {CrewMemberStateCodeEnum}
     * @memberof CrewMemberInfoCreate
     */
    stateCode: CrewMemberStateCodeEnum;
}
/**
 * 
 * @export
 * @interface CrewMemberInfoCreateAllOf
 */
export interface CrewMemberInfoCreateAllOf {
    /**
     * 
     * @type {CrewMemberStateCodeEnum}
     * @memberof CrewMemberInfoCreateAllOf
     */
    stateCode: CrewMemberStateCodeEnum;
}
/**
 * 
 * @export
 * @interface CrewMemberInfoRead
 */
export interface CrewMemberInfoRead {
    /**
     * Идентификатор
     * @type {number}
     * @memberof CrewMemberInfoRead
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof CrewMemberInfoRead
     */
    businessVersion: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof CrewMemberInfoRead
     */
    creationAuthorId: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof CrewMemberInfoRead
     */
    creationAuthorFullName: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof CrewMemberInfoRead
     */
    creationDate: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof CrewMemberInfoRead
     */
    lastUpdateAuthorId: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof CrewMemberInfoRead
     */
    lastUpdateAuthorFullName: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof CrewMemberInfoRead
     */
    lastUpdateDate: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof CrewMemberInfoRead
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof CrewMemberInfoRead
     */
    businessAccountId: number;
    /**
     * Описание
     * @type {string}
     * @memberof CrewMemberInfoRead
     */
    description?: string;
    /**
     * 
     * @type {CrewMemberStateCodeEnum}
     * @memberof CrewMemberInfoRead
     */
    stateCode?: CrewMemberStateCodeEnum;
    /**
     * 
     * @type {ContactRecord}
     * @memberof CrewMemberInfoRead
     */
    contact: ContactRecord;
    /**
     * Признак нахождения в архиве
     * @type {boolean}
     * @memberof CrewMemberInfoRead
     */
    archive: boolean;
    /**
     * Признак возможности отправки в архив
     * @type {boolean}
     * @memberof CrewMemberInfoRead
     */
    archivable: boolean;
    /**
     * Признак возможности удаления
     * @type {boolean}
     * @memberof CrewMemberInfoRead
     */
    deleteable: boolean;
    /**
     * Доступные переходы между статусами
     * @type {Array<CrewMemberTransitionCodeEnum>}
     * @memberof CrewMemberInfoRead
     */
    availableTransitionCodes?: Array<CrewMemberTransitionCodeEnum>;
    /**
     * 
     * @type {AttachmentRecordList}
     * @memberof CrewMemberInfoRead
     */
    attachments?: AttachmentRecordList;
    /**
     * 
     * @type {CrewMemberRatesObj}
     * @memberof CrewMemberInfoRead
     */
    defaultRates: CrewMemberRatesObj;
    /**
     * 
     * @type {Array<ProfessionAssignmentObjRead>}
     * @memberof CrewMemberInfoRead
     */
    professions?: Array<ProfessionAssignmentObjRead>;
}
/**
 * 
 * @export
 * @interface CrewMemberInfoReadAllOf
 */
export interface CrewMemberInfoReadAllOf {
    /**
     * 
     * @type {CrewMemberStateCodeEnum}
     * @memberof CrewMemberInfoReadAllOf
     */
    stateCode?: CrewMemberStateCodeEnum;
    /**
     * 
     * @type {ContactRecord}
     * @memberof CrewMemberInfoReadAllOf
     */
    contact: ContactRecord;
    /**
     * Признак нахождения в архиве
     * @type {boolean}
     * @memberof CrewMemberInfoReadAllOf
     */
    archive: boolean;
    /**
     * Признак возможности отправки в архив
     * @type {boolean}
     * @memberof CrewMemberInfoReadAllOf
     */
    archivable: boolean;
    /**
     * Признак возможности удаления
     * @type {boolean}
     * @memberof CrewMemberInfoReadAllOf
     */
    deleteable: boolean;
    /**
     * Доступные переходы между статусами
     * @type {Array<CrewMemberTransitionCodeEnum>}
     * @memberof CrewMemberInfoReadAllOf
     */
    availableTransitionCodes?: Array<CrewMemberTransitionCodeEnum>;
    /**
     * 
     * @type {AttachmentRecordList}
     * @memberof CrewMemberInfoReadAllOf
     */
    attachments?: AttachmentRecordList;
    /**
     * 
     * @type {CrewMemberRatesObj}
     * @memberof CrewMemberInfoReadAllOf
     */
    defaultRates: CrewMemberRatesObj;
    /**
     * 
     * @type {Array<ProfessionAssignmentObjRead>}
     * @memberof CrewMemberInfoReadAllOf
     */
    professions?: Array<ProfessionAssignmentObjRead>;
}
/**
 * 
 * @export
 * @interface CrewMemberInfoUpdate
 */
export interface CrewMemberInfoUpdate extends CrewMemberAbstractInfoWriteCommons {
}
/**
 * Не финансовые поля сущности CrewMemberRecord, которые могут быть выгружены в список Excel
 * @export
 * @enum {string}
 */
export enum CrewMemberNotFinancialFieldsTypeCode {
    PersonShortName = 'personShortName',
    StateCode = 'stateCode',
    ProfessionCount = 'professionCount',
    AttachmentsCount = 'attachmentsCount',
    LastUpdateDate = 'lastUpdateDate'
}

/**
 * 
 * @export
 * @interface CrewMemberRatesObj
 */
export interface CrewMemberRatesObj {
    /**
     * Ставка за смену
     * @type {number}
     * @memberof CrewMemberRatesObj
     */
    ratePerShift: number;
    /**
     * Ставка за час
     * @type {number}
     * @memberof CrewMemberRatesObj
     */
    ratePerHour: number;
    /**
     * Ставка за час переработки
     * @type {number}
     * @memberof CrewMemberRatesObj
     */
    overtimeRatePerHour?: number;
}
/**
 * 
 * @export
 * @interface CrewMemberRecord
 */
export interface CrewMemberRecord {
    /**
     * Идентификатор
     * @type {number}
     * @memberof CrewMemberRecord
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof CrewMemberRecord
     */
    businessVersion: number;
    /**
     * Ссылка: Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof CrewMemberRecord
     */
    businessAccountId: number;
    /**
     * 
     * @type {CrewMemberStateCodeEnum}
     * @memberof CrewMemberRecord
     */
    stateCode: CrewMemberStateCodeEnum;
    /**
     * Признак нахождения в архиве
     * @type {boolean}
     * @memberof CrewMemberRecord
     */
    archive?: boolean;
    /**
     * Признак возможности отправки в архив
     * @type {boolean}
     * @memberof CrewMemberRecord
     */
    archivable?: boolean;
    /**
     * Признак возможности удаления
     * @type {boolean}
     * @memberof CrewMemberRecord
     */
    deleteable?: boolean;
    /**
     * Доступные переходы между статусами
     * @type {Array<CrewMemberTransitionCodeEnum>}
     * @memberof CrewMemberRecord
     */
    availableTransitionCodes?: Array<CrewMemberTransitionCodeEnum>;
    /**
     * Идентификаторы профессий сотрудника
     * @type {Array<number>}
     * @memberof CrewMemberRecord
     */
    professionIds?: Array<number>;
    /**
     * Количество профессий сотрудника
     * @type {number}
     * @memberof CrewMemberRecord
     */
    professionCount: number;
    /**
     * 
     * @type {ImageObj}
     * @memberof CrewMemberRecord
     */
    contactImage?: ImageObj;
    /**
     * ФИО с инициалами
     * @type {string}
     * @memberof CrewMemberRecord
     */
    personShortName?: string;
    /**
     * Подготовленный номер телефона
     * @type {string}
     * @memberof CrewMemberRecord
     */
    phoneNumber?: string;
    /**
     * Адрес электронной почты
     * @type {string}
     * @memberof CrewMemberRecord
     */
    email?: string;
    /**
     * Идентификатор контакта сотрудника
     * @type {number}
     * @memberof CrewMemberRecord
     */
    contactId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CrewMemberRecord
     */
    contactIsBusinessAccountRRUser?: boolean;
    /**
     * Количество вложений
     * @type {number}
     * @memberof CrewMemberRecord
     */
    attachmentsCount?: number;
    /**
     * Дата последней активности
     * @type {Date}
     * @memberof CrewMemberRecord
     */
    lastActivityDate?: Date;
    /**
     * 
     * @type {CrewMemberRatesObj}
     * @memberof CrewMemberRecord
     */
    defaultRates: CrewMemberRatesObj;
    /**
     * Идентификатор автора последнего изменения
     * @type {number}
     * @memberof CrewMemberRecord
     */
    lastUpdateAuthorId?: number;
    /**
     * Фио с инициалами автора последнего изменения
     * @type {string}
     * @memberof CrewMemberRecord
     */
    lastUpdateAuthorShortName?: string;
    /**
     * Дата последнего изменения
     * @type {Date}
     * @memberof CrewMemberRecord
     */
    lastUpdateDate?: Date;
}
/**
 * 
 * @export
 * @interface CrewMemberRecordAllOf
 */
export interface CrewMemberRecordAllOf {
    /**
     * 
     * @type {CrewMemberStateCodeEnum}
     * @memberof CrewMemberRecordAllOf
     */
    stateCode: CrewMemberStateCodeEnum;
    /**
     * Признак нахождения в архиве
     * @type {boolean}
     * @memberof CrewMemberRecordAllOf
     */
    archive?: boolean;
    /**
     * Признак возможности отправки в архив
     * @type {boolean}
     * @memberof CrewMemberRecordAllOf
     */
    archivable?: boolean;
    /**
     * Признак возможности удаления
     * @type {boolean}
     * @memberof CrewMemberRecordAllOf
     */
    deleteable?: boolean;
    /**
     * Доступные переходы между статусами
     * @type {Array<CrewMemberTransitionCodeEnum>}
     * @memberof CrewMemberRecordAllOf
     */
    availableTransitionCodes?: Array<CrewMemberTransitionCodeEnum>;
    /**
     * Идентификаторы профессий сотрудника
     * @type {Array<number>}
     * @memberof CrewMemberRecordAllOf
     */
    professionIds?: Array<number>;
    /**
     * Количество профессий сотрудника
     * @type {number}
     * @memberof CrewMemberRecordAllOf
     */
    professionCount: number;
    /**
     * 
     * @type {ImageObj}
     * @memberof CrewMemberRecordAllOf
     */
    contactImage?: ImageObj;
    /**
     * ФИО с инициалами
     * @type {string}
     * @memberof CrewMemberRecordAllOf
     */
    personShortName?: string;
    /**
     * Подготовленный номер телефона
     * @type {string}
     * @memberof CrewMemberRecordAllOf
     */
    phoneNumber?: string;
    /**
     * Адрес электронной почты
     * @type {string}
     * @memberof CrewMemberRecordAllOf
     */
    email?: string;
    /**
     * Идентификатор контакта сотрудника
     * @type {number}
     * @memberof CrewMemberRecordAllOf
     */
    contactId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CrewMemberRecordAllOf
     */
    contactIsBusinessAccountRRUser?: boolean;
    /**
     * Количество вложений
     * @type {number}
     * @memberof CrewMemberRecordAllOf
     */
    attachmentsCount?: number;
    /**
     * Дата последней активности
     * @type {Date}
     * @memberof CrewMemberRecordAllOf
     */
    lastActivityDate?: Date;
    /**
     * 
     * @type {CrewMemberRatesObj}
     * @memberof CrewMemberRecordAllOf
     */
    defaultRates: CrewMemberRatesObj;
    /**
     * Идентификатор автора последнего изменения
     * @type {number}
     * @memberof CrewMemberRecordAllOf
     */
    lastUpdateAuthorId?: number;
    /**
     * Фио с инициалами автора последнего изменения
     * @type {string}
     * @memberof CrewMemberRecordAllOf
     */
    lastUpdateAuthorShortName?: string;
    /**
     * Дата последнего изменения
     * @type {Date}
     * @memberof CrewMemberRecordAllOf
     */
    lastUpdateDate?: Date;
}
/**
 * Список записей контактов
 * @export
 * @interface CrewMemberRecordList
 */
export interface CrewMemberRecordList {
    /**
     * 
     * @type {ListAttributesInfo}
     * @memberof CrewMemberRecordList
     */
    listAttributes: ListAttributesInfo;
    /**
     * 
     * @type {Array<CrewMemberRecord>}
     * @memberof CrewMemberRecordList
     */
    records: Array<CrewMemberRecord>;
}
/**
 * 
 * @export
 * @interface CrewMemberRecordListAllOf
 */
export interface CrewMemberRecordListAllOf {
    /**
     * 
     * @type {Array<CrewMemberRecord>}
     * @memberof CrewMemberRecordListAllOf
     */
    records: Array<CrewMemberRecord>;
}
/**
 * 
 * @export
 * @interface CrewMemberSimpleObj
 */
export interface CrewMemberSimpleObj {
    /**
     * 
     * @type {number}
     * @memberof CrewMemberSimpleObj
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CrewMemberSimpleObj
     */
    personFIO: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum CrewMemberStateCodeEnum {
    ACTIVE = 'ACTIVE',
    BLOCKED = 'BLOCKED'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum CrewMemberTransitionCodeEnum {
    ACTIVATE = 'ACTIVATE',
    BLOCK = 'BLOCK'
}

/**
 * Поддерживаемые коды валют (подмножество ISO_4217)
 * @export
 * @enum {string}
 */
export enum CurrencyCodeEnum {
    USD = 'USD',
    EUR = 'EUR',
    RUB = 'RUB',
    AMD = 'AMD',
    GEL = 'GEL',
    BYR = 'BYR',
    KZT = 'KZT',
    KGS = 'KGS',
    UZS = 'UZS',
    AZN = 'AZN',
    THB = 'THB',
    IDR = 'IDR',
    AED = 'AED',
    TJS = 'TJS'
}

/**
 * Абстрактное общее состояние пользовательского поля
 * @export
 * @interface CustomFieldAbstractCommons
 */
export interface CustomFieldAbstractCommons {
    /**
     * Идентификатор
     * @type {number}
     * @memberof CustomFieldAbstractCommons
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof CustomFieldAbstractCommons
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof CustomFieldAbstractCommons
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof CustomFieldAbstractCommons
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof CustomFieldAbstractCommons
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof CustomFieldAbstractCommons
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof CustomFieldAbstractCommons
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof CustomFieldAbstractCommons
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof CustomFieldAbstractCommons
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof CustomFieldAbstractCommons
     */
    businessAccountId?: number;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof CustomFieldAbstractCommons
     */
    shortName?: string;
    /**
     * Содержит финансовые данные
     * @type {boolean}
     * @memberof CustomFieldAbstractCommons
     */
    financial?: boolean;
    /**
     * Является внутренним, не отображается на витрине
     * @type {boolean}
     * @memberof CustomFieldAbstractCommons
     */
    internal?: boolean;
    /**
     * 
     * @type {CustomFieldProcessingTypeCodeEnum}
     * @memberof CustomFieldAbstractCommons
     */
    processingType?: CustomFieldProcessingTypeCodeEnum;
    /**
     * Множественные значения
     * @type {boolean}
     * @memberof CustomFieldAbstractCommons
     */
    multipleValues?: boolean;
}
/**
 * 
 * @export
 * @interface CustomFieldAbstractCommonsAllOf
 */
export interface CustomFieldAbstractCommonsAllOf {
    /**
     * Короткое наименование
     * @type {string}
     * @memberof CustomFieldAbstractCommonsAllOf
     */
    shortName?: string;
    /**
     * Содержит финансовые данные
     * @type {boolean}
     * @memberof CustomFieldAbstractCommonsAllOf
     */
    financial?: boolean;
    /**
     * Является внутренним, не отображается на витрине
     * @type {boolean}
     * @memberof CustomFieldAbstractCommonsAllOf
     */
    internal?: boolean;
    /**
     * 
     * @type {CustomFieldProcessingTypeCodeEnum}
     * @memberof CustomFieldAbstractCommonsAllOf
     */
    processingType?: CustomFieldProcessingTypeCodeEnum;
    /**
     * Множественные значения
     * @type {boolean}
     * @memberof CustomFieldAbstractCommonsAllOf
     */
    multipleValues?: boolean;
}
/**
 * Объект описывающий массовое назначение или отвязку пользовательских полей
 * @export
 * @interface CustomFieldBulkLinkObj
 */
export interface CustomFieldBulkLinkObj {
    /**
     * Идентификаторы пользовательских полей
     * @type {Array<number>}
     * @memberof CustomFieldBulkLinkObj
     */
    customFieldsIds: Array<number>;
    /**
     * 
     * @type {EntityTypeCodeEnum}
     * @memberof CustomFieldBulkLinkObj
     */
    entityTypeCode: EntityTypeCodeEnum;
    /**
     * Идентификаторы сущностей
     * @type {Array<number>}
     * @memberof CustomFieldBulkLinkObj
     */
    entityIds: Array<number>;
    /**
     * Значения в строковом представлении
     * @type {Array<string>}
     * @memberof CustomFieldBulkLinkObj
     */
    values?: Array<string>;
}
/**
 * Поддерживаемые типы пользовательских полей
 * @export
 * @enum {string}
 */
export enum CustomFieldDataTypeCodeEnum {
    BOOLEAN = 'BOOLEAN',
    LONG = 'LONG',
    DOUBLE = 'DOUBLE',
    STRING = 'STRING',
    DATE = 'DATE',
    ENTITY = 'ENTITY'
}

/**
 * Группа полей - состояние чтения
 * @export
 * @interface CustomFieldGroupObjRead
 */
export interface CustomFieldGroupObjRead {
    /**
     * Полное наименование группы
     * @type {string}
     * @memberof CustomFieldGroupObjRead
     */
    name?: string;
    /**
     * Маркер того, что группа является основной
     * @type {boolean}
     * @memberof CustomFieldGroupObjRead
     */
    main: boolean;
    /**
     * Поля со значениями
     * @type {Array<CustomFieldWithValueObjRead>}
     * @memberof CustomFieldGroupObjRead
     */
    fields: Array<CustomFieldWithValueObjRead>;
}
/**
 * Группа полей - состояние создания или изменения
 * @export
 * @interface CustomFieldGroupObjWrite
 */
export interface CustomFieldGroupObjWrite {
    /**
     * Полное наименование группы
     * @type {string}
     * @memberof CustomFieldGroupObjWrite
     */
    name?: string;
    /**
     * Маркер того, что группа является основной
     * @type {boolean}
     * @memberof CustomFieldGroupObjWrite
     */
    main: boolean;
    /**
     * Поля со значениями
     * @type {Array<CustomFieldValueObjWrite>}
     * @memberof CustomFieldGroupObjWrite
     */
    fields: Array<CustomFieldValueObjWrite>;
}
/**
 * Пользовательское поле: абстрактное состояние записи
 * @export
 * @interface CustomFieldInfoAbstractWriteCommons
 */
export interface CustomFieldInfoAbstractWriteCommons extends CustomFieldAbstractCommons {
}
/**
 * Пользовательское поле - состояние создания
 * @export
 * @interface CustomFieldInfoCreate
 */
export interface CustomFieldInfoCreate {
    /**
     * Идентификатор
     * @type {number}
     * @memberof CustomFieldInfoCreate
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof CustomFieldInfoCreate
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof CustomFieldInfoCreate
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof CustomFieldInfoCreate
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof CustomFieldInfoCreate
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof CustomFieldInfoCreate
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof CustomFieldInfoCreate
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof CustomFieldInfoCreate
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof CustomFieldInfoCreate
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof CustomFieldInfoCreate
     */
    businessAccountId?: number;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof CustomFieldInfoCreate
     */
    shortName: string;
    /**
     * Содержит финансовые данные
     * @type {boolean}
     * @memberof CustomFieldInfoCreate
     */
    financial: boolean;
    /**
     * Является внутренним, не отображается на витрине
     * @type {boolean}
     * @memberof CustomFieldInfoCreate
     */
    internal: boolean;
    /**
     * 
     * @type {CustomFieldProcessingTypeCodeEnum}
     * @memberof CustomFieldInfoCreate
     */
    processingType: CustomFieldProcessingTypeCodeEnum;
    /**
     * Множественные значения
     * @type {boolean}
     * @memberof CustomFieldInfoCreate
     */
    multipleValues: boolean;
    /**
     * 
     * @type {CustomFieldDataTypeCodeEnum}
     * @memberof CustomFieldInfoCreate
     */
    typeCode: CustomFieldDataTypeCodeEnum;
    /**
     * Является стандартным полем из пула RentRabbit
     * @type {boolean}
     * @memberof CustomFieldInfoCreate
     */
    standard?: boolean;
}
/**
 * 
 * @export
 * @interface CustomFieldInfoCreateAllOf
 */
export interface CustomFieldInfoCreateAllOf {
    /**
     * 
     * @type {CustomFieldDataTypeCodeEnum}
     * @memberof CustomFieldInfoCreateAllOf
     */
    typeCode: CustomFieldDataTypeCodeEnum;
    /**
     * Является стандартным полем из пула RentRabbit
     * @type {boolean}
     * @memberof CustomFieldInfoCreateAllOf
     */
    standard?: boolean;
}
/**
 * Пользовательское поле - состояние чтения
 * @export
 * @interface CustomFieldInfoRead
 */
export interface CustomFieldInfoRead {
    /**
     * Идентификатор
     * @type {number}
     * @memberof CustomFieldInfoRead
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof CustomFieldInfoRead
     */
    businessVersion: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof CustomFieldInfoRead
     */
    creationAuthorId: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof CustomFieldInfoRead
     */
    creationAuthorFullName: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof CustomFieldInfoRead
     */
    creationDate: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof CustomFieldInfoRead
     */
    lastUpdateAuthorId: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof CustomFieldInfoRead
     */
    lastUpdateAuthorFullName: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof CustomFieldInfoRead
     */
    lastUpdateDate: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof CustomFieldInfoRead
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof CustomFieldInfoRead
     */
    businessAccountId: number;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof CustomFieldInfoRead
     */
    shortName: string;
    /**
     * Содержит финансовые данные
     * @type {boolean}
     * @memberof CustomFieldInfoRead
     */
    financial?: boolean;
    /**
     * Является внутренним, не отображается на витрине
     * @type {boolean}
     * @memberof CustomFieldInfoRead
     */
    internal?: boolean;
    /**
     * 
     * @type {CustomFieldProcessingTypeCodeEnum}
     * @memberof CustomFieldInfoRead
     */
    processingType: CustomFieldProcessingTypeCodeEnum;
    /**
     * Множественные значения
     * @type {boolean}
     * @memberof CustomFieldInfoRead
     */
    multipleValues: boolean;
    /**
     * 
     * @type {CustomFieldDataTypeCodeEnum}
     * @memberof CustomFieldInfoRead
     */
    typeCode: CustomFieldDataTypeCodeEnum;
    /**
     * Количество сущностей использующих поле
     * @type {number}
     * @memberof CustomFieldInfoRead
     */
    entitiesLinkedNumber: number;
    /**
     * Является стандартным полем из пула RentRabbit
     * @type {boolean}
     * @memberof CustomFieldInfoRead
     */
    standard: boolean;
}
/**
 * 
 * @export
 * @interface CustomFieldInfoReadAllOf
 */
export interface CustomFieldInfoReadAllOf {
    /**
     * 
     * @type {CustomFieldDataTypeCodeEnum}
     * @memberof CustomFieldInfoReadAllOf
     */
    typeCode: CustomFieldDataTypeCodeEnum;
    /**
     * Количество сущностей использующих поле
     * @type {number}
     * @memberof CustomFieldInfoReadAllOf
     */
    entitiesLinkedNumber: number;
    /**
     * Является стандартным полем из пула RentRabbit
     * @type {boolean}
     * @memberof CustomFieldInfoReadAllOf
     */
    standard: boolean;
}
/**
 * Пользовательское поле - состояние изменения
 * @export
 * @interface CustomFieldInfoUpdate
 */
export interface CustomFieldInfoUpdate extends CustomFieldInfoAbstractWriteCommons {
}
/**
 * Объект настаиваемого поля для отображения колонок
 * @export
 * @interface CustomFieldObjRead
 */
export interface CustomFieldObjRead {
    /**
     * Идентификатор поля
     * @type {number}
     * @memberof CustomFieldObjRead
     */
    customFieldId: number;
    /**
     * Полное наименование поля
     * @type {string}
     * @memberof CustomFieldObjRead
     */
    name: string;
    /**
     * Является стандартным
     * @type {boolean}
     * @memberof CustomFieldObjRead
     */
    standard: boolean;
    /**
     * Является финансовым
     * @type {boolean}
     * @memberof CustomFieldObjRead
     */
    financial: boolean;
    /**
     * 
     * @type {CustomFieldDataTypeCodeEnum}
     * @memberof CustomFieldObjRead
     */
    typeCode: CustomFieldDataTypeCodeEnum;
    /**
     * Множественные значения
     * @type {boolean}
     * @memberof CustomFieldObjRead
     */
    multipleValues: boolean;
    /**
     * Комментарий поля
     * @type {string}
     * @memberof CustomFieldObjRead
     */
    comment?: string;
    /**
     * Строка ключа по которому индексируется настраиваемое поле
     * @type {string}
     * @memberof CustomFieldObjRead
     */
    customFieldIndexKey: string;
    /**
     * 
     * @type {CustomFieldProcessingTypeCodeEnum}
     * @memberof CustomFieldObjRead
     */
    processingType: CustomFieldProcessingTypeCodeEnum;
}
/**
 * Поддерживаемые типы ссылок на внешние сущности, которые определяют дополнительную логику
 * @export
 * @enum {string}
 */
export enum CustomFieldProcessingTypeCodeEnum {
    USERLINK = 'USER_LINK',
    CONTACTLINK = 'CONTACT_LINK',
    SUBRENTLINK = 'SUBRENT_LINK',
    PROJECTLINK = 'PROJECT_LINK',
    RENTERLINK = 'RENTER_LINK',
    LONGSUM = 'LONG_SUM',
    MONEYSUM = 'MONEY_SUM'
}

/**
 * Списочное представление пользовательского поля
 * @export
 * @interface CustomFieldRecord
 */
export interface CustomFieldRecord {
    /**
     * Идентификатор
     * @type {number}
     * @memberof CustomFieldRecord
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof CustomFieldRecord
     */
    businessVersion: number;
    /**
     * Ссылка: Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof CustomFieldRecord
     */
    businessAccountId: number;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof CustomFieldRecord
     */
    shortName: string;
    /**
     * 
     * @type {CustomFieldDataTypeCodeEnum}
     * @memberof CustomFieldRecord
     */
    typeCode: CustomFieldDataTypeCodeEnum;
    /**
     * Множественные значения
     * @type {boolean}
     * @memberof CustomFieldRecord
     */
    multipleValues: boolean;
    /**
     * Количество сущностей использующих поле
     * @type {number}
     * @memberof CustomFieldRecord
     */
    entitiesLinkedNumber: number;
    /**
     * Является стандартным полем из пула RentRabbit
     * @type {boolean}
     * @memberof CustomFieldRecord
     */
    standard: boolean;
    /**
     * Содержит финансовые данные
     * @type {boolean}
     * @memberof CustomFieldRecord
     */
    financial: boolean;
    /**
     * Является внутренним, не отображается на витрине
     * @type {boolean}
     * @memberof CustomFieldRecord
     */
    internal: boolean;
    /**
     * Комментарий
     * @type {string}
     * @memberof CustomFieldRecord
     */
    comment?: string;
    /**
     * 
     * @type {CustomFieldProcessingTypeCodeEnum}
     * @memberof CustomFieldRecord
     */
    processingType: CustomFieldProcessingTypeCodeEnum;
}
/**
 * 
 * @export
 * @interface CustomFieldRecordAllOf
 */
export interface CustomFieldRecordAllOf {
    /**
     * Короткое наименование
     * @type {string}
     * @memberof CustomFieldRecordAllOf
     */
    shortName: string;
    /**
     * 
     * @type {CustomFieldDataTypeCodeEnum}
     * @memberof CustomFieldRecordAllOf
     */
    typeCode: CustomFieldDataTypeCodeEnum;
    /**
     * Множественные значения
     * @type {boolean}
     * @memberof CustomFieldRecordAllOf
     */
    multipleValues: boolean;
    /**
     * Количество сущностей использующих поле
     * @type {number}
     * @memberof CustomFieldRecordAllOf
     */
    entitiesLinkedNumber: number;
    /**
     * Является стандартным полем из пула RentRabbit
     * @type {boolean}
     * @memberof CustomFieldRecordAllOf
     */
    standard: boolean;
    /**
     * Содержит финансовые данные
     * @type {boolean}
     * @memberof CustomFieldRecordAllOf
     */
    financial: boolean;
    /**
     * Является внутренним, не отображается на витрине
     * @type {boolean}
     * @memberof CustomFieldRecordAllOf
     */
    internal: boolean;
    /**
     * Комментарий
     * @type {string}
     * @memberof CustomFieldRecordAllOf
     */
    comment?: string;
    /**
     * 
     * @type {CustomFieldProcessingTypeCodeEnum}
     * @memberof CustomFieldRecordAllOf
     */
    processingType: CustomFieldProcessingTypeCodeEnum;
}
/**
 * Список записей о пользовательских полях
 * @export
 * @interface CustomFieldRecordList
 */
export interface CustomFieldRecordList {
    /**
     * 
     * @type {ListAttributesInfo}
     * @memberof CustomFieldRecordList
     */
    listAttributes: ListAttributesInfo;
    /**
     * 
     * @type {Array<CustomFieldRecord>}
     * @memberof CustomFieldRecordList
     */
    records: Array<CustomFieldRecord>;
}
/**
 * 
 * @export
 * @interface CustomFieldRecordListAllOf
 */
export interface CustomFieldRecordListAllOf {
    /**
     * 
     * @type {Array<CustomFieldRecord>}
     * @memberof CustomFieldRecordListAllOf
     */
    records: Array<CustomFieldRecord>;
}
/**
 * Значение поля - состояние чтения
 * @export
 * @interface CustomFieldValueObjRecord
 */
export interface CustomFieldValueObjRecord {
    /**
     * Идентификатор настраиваемого поля, используется для актов и смет
     * @type {number}
     * @memberof CustomFieldValueObjRecord
     */
    customFieldId?: number;
    /**
     * Наименование настраиваемого поля, используется для актов и смет
     * @type {string}
     * @memberof CustomFieldValueObjRecord
     */
    customFieldName?: string;
    /**
     * Строка ключа по которому индексируется настраиваемое поле
     * @type {string}
     * @memberof CustomFieldValueObjRecord
     */
    customFieldIndexKey: string;
    /**
     * Значения в строковом представлении
     * @type {Array<string>}
     * @memberof CustomFieldValueObjRecord
     */
    values?: Array<string>;
    /**
     * Пользовательское поле со ссылкой на другой объект
     * @type {Array<SimpleEntityRecord>}
     * @memberof CustomFieldValueObjRecord
     */
    linkValues?: Array<SimpleEntityRecord>;
}
/**
 * Значение поля - состояние записи
 * @export
 * @interface CustomFieldValueObjWrite
 */
export interface CustomFieldValueObjWrite {
    /**
     * Идентификатор поля
     * @type {number}
     * @memberof CustomFieldValueObjWrite
     */
    customFieldId: number;
    /**
     * Значения в строковом представлении
     * @type {Array<string>}
     * @memberof CustomFieldValueObjWrite
     */
    values?: Array<string>;
}
/**
 * Список значений поля - состояний записи
 * @export
 * @interface CustomFieldValueObjWriteList
 */
export interface CustomFieldValueObjWriteList {
    /**
     * Значения поля - состояния записи
     * @type {Array<CustomFieldValueObjWrite>}
     * @memberof CustomFieldValueObjWriteList
     */
    objects: Array<CustomFieldValueObjWrite>;
}
/**
 * 
 * @export
 * @interface CustomFieldWithValueObjRead
 */
export interface CustomFieldWithValueObjRead {
    /**
     * Идентификатор поля
     * @type {number}
     * @memberof CustomFieldWithValueObjRead
     */
    customFieldId: number;
    /**
     * Полное наименование поля
     * @type {string}
     * @memberof CustomFieldWithValueObjRead
     */
    name: string;
    /**
     * Является стандартным
     * @type {boolean}
     * @memberof CustomFieldWithValueObjRead
     */
    standard: boolean;
    /**
     * Является финансовым
     * @type {boolean}
     * @memberof CustomFieldWithValueObjRead
     */
    financial: boolean;
    /**
     * 
     * @type {CustomFieldDataTypeCodeEnum}
     * @memberof CustomFieldWithValueObjRead
     */
    typeCode: CustomFieldDataTypeCodeEnum;
    /**
     * Множественные значения
     * @type {boolean}
     * @memberof CustomFieldWithValueObjRead
     */
    multipleValues: boolean;
    /**
     * Комментарий поля
     * @type {string}
     * @memberof CustomFieldWithValueObjRead
     */
    comment?: string;
    /**
     * Строка ключа по которому индексируется настраиваемое поле
     * @type {string}
     * @memberof CustomFieldWithValueObjRead
     */
    customFieldIndexKey: string;
    /**
     * 
     * @type {CustomFieldProcessingTypeCodeEnum}
     * @memberof CustomFieldWithValueObjRead
     */
    processingType: CustomFieldProcessingTypeCodeEnum;
    /**
     * Значения в строковом представлении
     * @type {Array<string>}
     * @memberof CustomFieldWithValueObjRead
     */
    values?: Array<string>;
    /**
     * Пользовательское поле со ссылкой на другой объект
     * @type {Array<SimpleEntityRecord>}
     * @memberof CustomFieldWithValueObjRead
     */
    linkValues?: Array<SimpleEntityRecord>;
}
/**
 * Значение поля - состояние чтения
 * @export
 * @interface CustomFieldWithValueObjReadAllOf
 */
export interface CustomFieldWithValueObjReadAllOf {
    /**
     * Значения в строковом представлении
     * @type {Array<string>}
     * @memberof CustomFieldWithValueObjReadAllOf
     */
    values?: Array<string>;
    /**
     * Пользовательское поле со ссылкой на другой объект
     * @type {Array<SimpleEntityRecord>}
     * @memberof CustomFieldWithValueObjReadAllOf
     */
    linkValues?: Array<SimpleEntityRecord>;
}
/**
 * Общее состояние всех пользовательских полей по типу сущности. Для продукта также доступны настраиваемые поля вариантов. Состояние чтения
 * @export
 * @interface CustomFieldsGlobalObjRead
 */
export interface CustomFieldsGlobalObjRead {
    /**
     * Все кастомные поля данного продукта со значениями сгруппированные и упорядоченные
     * @type {Array<CustomFieldGroupObjRead>}
     * @memberof CustomFieldsGlobalObjRead
     */
    customFieldGroups: Array<CustomFieldGroupObjRead>;
    /**
     * Набор настраиваемых полей для всех вариантов продукта
     * @type {Array<CustomFieldObjRead>}
     * @memberof CustomFieldsGlobalObjRead
     */
    variantsCustomFieldMarkers?: Array<CustomFieldObjRead>;
}
/**
 * Общее состояние всех пользовательских полей по типу сущности. Состояние записи
 * @export
 * @interface CustomFieldsGlobalObjWrite
 */
export interface CustomFieldsGlobalObjWrite {
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof CustomFieldsGlobalObjWrite
     */
    businessVersion: number;
    /**
     * Все кастомные поля данного продукта со значениями сгруппированные и упорядоченные
     * @type {Array<CustomFieldGroupObjWrite>}
     * @memberof CustomFieldsGlobalObjWrite
     */
    customFieldGroups: Array<CustomFieldGroupObjWrite>;
}
/**
 * Ограничение пользовательских полей определенного типа
 * @export
 * @interface CustomFieldsLimitObj
 */
export interface CustomFieldsLimitObj {
    /**
     * 
     * @type {CustomFieldDataTypeCodeEnum}
     * @memberof CustomFieldsLimitObj
     */
    typeCode: CustomFieldDataTypeCodeEnum;
    /**
     * 
     * @type {number}
     * @memberof CustomFieldsLimitObj
     */
    currentlyUsed: number;
    /**
     * 
     * @type {number}
     * @memberof CustomFieldsLimitObj
     */
    maxAllowed: number;
}
/**
 * Список ограничений пользовательских полей различных типов
 * @export
 * @interface CustomFieldsLimitObjList
 */
export interface CustomFieldsLimitObjList {
    /**
     * 
     * @type {Array<CustomFieldsLimitObj>}
     * @memberof CustomFieldsLimitObjList
     */
    limits?: Array<CustomFieldsLimitObj>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum DataTypeEnum {
    Int = 'int',
    Long = 'long',
    Float = 'float',
    Double = 'double',
    String = 'string',
    Array = 'array',
    LocalDate = 'local_date',
    DateWithTime = 'date_with_time',
    Date = 'date',
    Time = 'time',
    Timeinterval = 'timeinterval'
}

/**
 * Объект дефолтного шаблона со свойствами для чтения
 * @export
 * @interface DefaultDocumentTemplateObjRead
 */
export interface DefaultDocumentTemplateObjRead {
    /**
     * 
     * @type {number}
     * @memberof DefaultDocumentTemplateObjRead
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DefaultDocumentTemplateObjRead
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DefaultDocumentTemplateObjRead
     */
    isCommon?: boolean;
}
/**
 * Объект дефолтного шаблона со свойствами для записи
 * @export
 * @interface DefaultDocumentTemplateObjWrite
 */
export interface DefaultDocumentTemplateObjWrite {
    /**
     * 
     * @type {number}
     * @memberof DefaultDocumentTemplateObjWrite
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DefaultDocumentTemplateObjWrite
     */
    isCommon?: boolean;
}
/**
 * Конфигурация отчета: детализированное состояние чтения, со списком параметров для выполнения
 * @export
 * @interface DetailedReportConfigurationInfoRead
 */
export interface DetailedReportConfigurationInfoRead {
    /**
     * Идентификатор
     * @type {number}
     * @memberof DetailedReportConfigurationInfoRead
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof DetailedReportConfigurationInfoRead
     */
    businessVersion: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof DetailedReportConfigurationInfoRead
     */
    creationAuthorId: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof DetailedReportConfigurationInfoRead
     */
    creationAuthorFullName: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof DetailedReportConfigurationInfoRead
     */
    creationDate: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof DetailedReportConfigurationInfoRead
     */
    lastUpdateAuthorId: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof DetailedReportConfigurationInfoRead
     */
    lastUpdateAuthorFullName: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof DetailedReportConfigurationInfoRead
     */
    lastUpdateDate: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof DetailedReportConfigurationInfoRead
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedReportConfigurationInfoRead
     */
    localizationSettings?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedReportConfigurationInfoRead
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedReportConfigurationInfoRead
     */
    description?: string;
    /**
     * 
     * @type {ReportTypeCodeEnum}
     * @memberof DetailedReportConfigurationInfoRead
     */
    reportType: ReportTypeCodeEnum;
    /**
     * Содаржит финансовую информацию
     * @type {boolean}
     * @memberof DetailedReportConfigurationInfoRead
     */
    financial: boolean;
    /**
     * 
     * @type {number}
     * @memberof DetailedReportConfigurationInfoRead
     */
    businessAccountId?: number;
    /**
     * 
     * @type {Array<ReportExcelVisualizationInfoRead>}
     * @memberof DetailedReportConfigurationInfoRead
     */
    views?: Array<ReportExcelVisualizationInfoRead>;
    /**
     * 
     * @type {Array<ReportInputArgumentModel>}
     * @memberof DetailedReportConfigurationInfoRead
     */
    variables?: Array<ReportInputArgumentModel>;
}
/**
 * 
 * @export
 * @interface DetailedReportConfigurationInfoReadAllOf
 */
export interface DetailedReportConfigurationInfoReadAllOf {
    /**
     * 
     * @type {Array<ReportInputArgumentModel>}
     * @memberof DetailedReportConfigurationInfoReadAllOf
     */
    variables?: Array<ReportInputArgumentModel>;
}
/**
 * Код режима очистки кеша шаблонов документов
 * @export
 * @enum {string}
 */
export enum DocumentTemplateCacheClearModeCodeEnum {
    COMMON = 'COMMON',
    ALL = 'ALL',
    BUSINESSACCOUNTS = 'BUSINESS_ACCOUNTS',
    DOCUMENTTEMPLATE = 'DOCUMENT_TEMPLATE'
}

/**
 * Шаблон документа: абстрактное общее состояние для всех операций
 * @export
 * @interface DocumentTemplateInfoAbstractCommons
 */
export interface DocumentTemplateInfoAbstractCommons {
    /**
     * Идентификатор
     * @type {number}
     * @memberof DocumentTemplateInfoAbstractCommons
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof DocumentTemplateInfoAbstractCommons
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof DocumentTemplateInfoAbstractCommons
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof DocumentTemplateInfoAbstractCommons
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof DocumentTemplateInfoAbstractCommons
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof DocumentTemplateInfoAbstractCommons
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof DocumentTemplateInfoAbstractCommons
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof DocumentTemplateInfoAbstractCommons
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof DocumentTemplateInfoAbstractCommons
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof DocumentTemplateInfoAbstractCommons
     */
    businessAccountId?: number;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof DocumentTemplateInfoAbstractCommons
     */
    shortName: string;
    /**
     * 
     * @type {DocumentTypeCodeEnum}
     * @memberof DocumentTemplateInfoAbstractCommons
     */
    documentType: DocumentTypeCodeEnum;
    /**
     * 
     * @type {BarcodeTypeCodeEnum}
     * @memberof DocumentTemplateInfoAbstractCommons
     */
    barcodeType?: BarcodeTypeCodeEnum;
    /**
     * Содаржит финансовую информацию
     * @type {boolean}
     * @memberof DocumentTemplateInfoAbstractCommons
     */
    financial: boolean;
    /**
     * Скрыт в текущем БА
     * @type {boolean}
     * @memberof DocumentTemplateInfoAbstractCommons
     */
    hidden?: boolean;
}
/**
 * 
 * @export
 * @interface DocumentTemplateInfoAbstractCommonsAllOf
 */
export interface DocumentTemplateInfoAbstractCommonsAllOf {
    /**
     * Короткое наименование
     * @type {string}
     * @memberof DocumentTemplateInfoAbstractCommonsAllOf
     */
    shortName: string;
    /**
     * 
     * @type {DocumentTypeCodeEnum}
     * @memberof DocumentTemplateInfoAbstractCommonsAllOf
     */
    documentType: DocumentTypeCodeEnum;
    /**
     * 
     * @type {BarcodeTypeCodeEnum}
     * @memberof DocumentTemplateInfoAbstractCommonsAllOf
     */
    barcodeType?: BarcodeTypeCodeEnum;
    /**
     * Содаржит финансовую информацию
     * @type {boolean}
     * @memberof DocumentTemplateInfoAbstractCommonsAllOf
     */
    financial: boolean;
    /**
     * Скрыт в текущем БА
     * @type {boolean}
     * @memberof DocumentTemplateInfoAbstractCommonsAllOf
     */
    hidden?: boolean;
}
/**
 * Шаблон документа: состояние при создании
 * @export
 * @interface DocumentTemplateInfoCreate
 */
export interface DocumentTemplateInfoCreate extends DocumentTemplateInfoAbstractCommons {
}
/**
 * Шаблон документа: состояние чтения
 * @export
 * @interface DocumentTemplateInfoRead
 */
export interface DocumentTemplateInfoRead {
    /**
     * Идентификатор
     * @type {number}
     * @memberof DocumentTemplateInfoRead
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof DocumentTemplateInfoRead
     */
    businessVersion: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof DocumentTemplateInfoRead
     */
    creationAuthorId: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof DocumentTemplateInfoRead
     */
    creationAuthorFullName: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof DocumentTemplateInfoRead
     */
    creationDate: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof DocumentTemplateInfoRead
     */
    lastUpdateAuthorId: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof DocumentTemplateInfoRead
     */
    lastUpdateAuthorFullName: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof DocumentTemplateInfoRead
     */
    lastUpdateDate: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof DocumentTemplateInfoRead
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof DocumentTemplateInfoRead
     */
    businessAccountId: number;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof DocumentTemplateInfoRead
     */
    shortName: string;
    /**
     * 
     * @type {DocumentTypeCodeEnum}
     * @memberof DocumentTemplateInfoRead
     */
    documentType: DocumentTypeCodeEnum;
    /**
     * 
     * @type {BarcodeTypeCodeEnum}
     * @memberof DocumentTemplateInfoRead
     */
    barcodeType?: BarcodeTypeCodeEnum;
    /**
     * Содаржит финансовую информацию
     * @type {boolean}
     * @memberof DocumentTemplateInfoRead
     */
    financial: boolean;
    /**
     * Скрыт в текущем БА
     * @type {boolean}
     * @memberof DocumentTemplateInfoRead
     */
    hidden?: boolean;
    /**
     * Если указан как дефолтный в ба, то false
     * @type {boolean}
     * @memberof DocumentTemplateInfoRead
     */
    isDeleteable: boolean;
}
/**
 * 
 * @export
 * @interface DocumentTemplateInfoReadAllOf
 */
export interface DocumentTemplateInfoReadAllOf {
    /**
     * Если указан как дефолтный в ба, то false
     * @type {boolean}
     * @memberof DocumentTemplateInfoReadAllOf
     */
    isDeleteable: boolean;
}
/**
 * Шаблон документа: состояние обновления
 * @export
 * @interface DocumentTemplateInfoUpdate
 */
export interface DocumentTemplateInfoUpdate extends DocumentTemplateInfoAbstractCommons {
}
/**
 * Списочное представление шаблона документа
 * @export
 * @interface DocumentTemplateRecord
 */
export interface DocumentTemplateRecord {
    /**
     * Идентификатор
     * @type {number}
     * @memberof DocumentTemplateRecord
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof DocumentTemplateRecord
     */
    businessVersion: number;
    /**
     * Ссылка: Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof DocumentTemplateRecord
     */
    businessAccountId: number;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof DocumentTemplateRecord
     */
    shortName: string;
    /**
     * 
     * @type {DocumentTypeCodeEnum}
     * @memberof DocumentTemplateRecord
     */
    documentType: DocumentTypeCodeEnum;
    /**
     * Содаржит финансовую информацию
     * @type {boolean}
     * @memberof DocumentTemplateRecord
     */
    financial: boolean;
    /**
     * Скрыт в текущем БА
     * @type {boolean}
     * @memberof DocumentTemplateRecord
     */
    hidden?: boolean;
    /**
     * Явлается стандартным
     * @type {boolean}
     * @memberof DocumentTemplateRecord
     */
    common: boolean;
    /**
     * Дата последнего изменения
     * @type {Date}
     * @memberof DocumentTemplateRecord
     */
    lastUpdateDate: Date;
    /**
     * Если указан как дефолтный в ба, то false
     * @type {boolean}
     * @memberof DocumentTemplateRecord
     */
    isDeleteable: boolean;
}
/**
 * 
 * @export
 * @interface DocumentTemplateRecordAllOf
 */
export interface DocumentTemplateRecordAllOf {
    /**
     * Короткое наименование
     * @type {string}
     * @memberof DocumentTemplateRecordAllOf
     */
    shortName: string;
    /**
     * 
     * @type {DocumentTypeCodeEnum}
     * @memberof DocumentTemplateRecordAllOf
     */
    documentType: DocumentTypeCodeEnum;
    /**
     * Содаржит финансовую информацию
     * @type {boolean}
     * @memberof DocumentTemplateRecordAllOf
     */
    financial: boolean;
    /**
     * Скрыт в текущем БА
     * @type {boolean}
     * @memberof DocumentTemplateRecordAllOf
     */
    hidden?: boolean;
    /**
     * Явлается стандартным
     * @type {boolean}
     * @memberof DocumentTemplateRecordAllOf
     */
    common: boolean;
    /**
     * Дата последнего изменения
     * @type {Date}
     * @memberof DocumentTemplateRecordAllOf
     */
    lastUpdateDate: Date;
    /**
     * Если указан как дефолтный в ба, то false
     * @type {boolean}
     * @memberof DocumentTemplateRecordAllOf
     */
    isDeleteable: boolean;
}
/**
 * Список записей шаблонов документов
 * @export
 * @interface DocumentTemplateRecordList
 */
export interface DocumentTemplateRecordList {
    /**
     * 
     * @type {ListAttributesInfo}
     * @memberof DocumentTemplateRecordList
     */
    listAttributes: ListAttributesInfo;
    /**
     * 
     * @type {Array<DocumentTemplateRecord>}
     * @memberof DocumentTemplateRecordList
     */
    records: Array<DocumentTemplateRecord>;
}
/**
 * 
 * @export
 * @interface DocumentTemplateRecordListAllOf
 */
export interface DocumentTemplateRecordListAllOf {
    /**
     * 
     * @type {Array<DocumentTemplateRecord>}
     * @memberof DocumentTemplateRecordListAllOf
     */
    records: Array<DocumentTemplateRecord>;
}
/**
 * Код типа документа
 * @export
 * @enum {string}
 */
export enum DocumentTypeCodeEnum {
    ESTIMATE = 'ESTIMATE',
    PRICELIST = 'PRICE_LIST',
    CONTRACT = 'CONTRACT',
    ACT = 'ACT',
    INVENTORYLIST = 'INVENTORY_LIST',
    BILLOFLADING = 'BILL_OF_LADING',
    BILL = 'BILL',
    BADGE = 'BADGE'
}

/**
 * Все поля сущности EntityLifecycleEventRecord, которые могут быть выгружены в список Excel. Финансовых полей нет
 * @export
 * @enum {string}
 */
export enum EntityLifecycleEventNotFinancialFieldsTypeCode {
    EntityTypeCode = 'entityTypeCode',
    EntityName = 'entityName',
    EntityLifecycleEventTypeCode = 'entityLifecycleEventTypeCode',
    ActivityDate = 'activityDate',
    ActivityAuthorShortName = 'activityAuthorShortName'
}

/**
 * Событие изменения сущности: списочное представление
 * @export
 * @interface EntityLifecycleEventRecord
 */
export interface EntityLifecycleEventRecord {
    /**
     * Идентификатор
     * @type {number}
     * @memberof EntityLifecycleEventRecord
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof EntityLifecycleEventRecord
     */
    businessVersion: number;
    /**
     * Ссылка: Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof EntityLifecycleEventRecord
     */
    businessAccountId: number;
    /**
     * Время, когда сущность была изменена
     * @type {Date}
     * @memberof EntityLifecycleEventRecord
     */
    activityDate?: Date;
    /**
     * Id автора, который изменил сущность
     * @type {number}
     * @memberof EntityLifecycleEventRecord
     */
    activityAuthorId?: number;
    /**
     * Id автора, который изменил сущность
     * @type {number}
     * @memberof EntityLifecycleEventRecord
     */
    entityId?: number;
    /**
     * ФИО - фамилия и инициалы
     * @type {string}
     * @memberof EntityLifecycleEventRecord
     */
    activityAuthorShortName?: string;
    /**
     * Описание изменения
     * @type {string}
     * @memberof EntityLifecycleEventRecord
     */
    description?: string;
    /**
     * Имя сущности, которую изменили
     * @type {string}
     * @memberof EntityLifecycleEventRecord
     */
    entityName?: string;
    /**
     * 
     * @type {EntityLifecycleEventTypeCode}
     * @memberof EntityLifecycleEventRecord
     */
    entityLifecycleEventTypeCode?: EntityLifecycleEventTypeCode;
    /**
     * 
     * @type {EntityTypeCodeEnum}
     * @memberof EntityLifecycleEventRecord
     */
    entityTypeCode?: EntityTypeCodeEnum;
    /**
     * Удаленная сущность
     * @type {boolean}
     * @memberof EntityLifecycleEventRecord
     */
    deletedEntity?: boolean;
}
/**
 * 
 * @export
 * @interface EntityLifecycleEventRecordAllOf
 */
export interface EntityLifecycleEventRecordAllOf {
    /**
     * Время, когда сущность была изменена
     * @type {Date}
     * @memberof EntityLifecycleEventRecordAllOf
     */
    activityDate?: Date;
    /**
     * Id автора, который изменил сущность
     * @type {number}
     * @memberof EntityLifecycleEventRecordAllOf
     */
    activityAuthorId?: number;
    /**
     * Id автора, который изменил сущность
     * @type {number}
     * @memberof EntityLifecycleEventRecordAllOf
     */
    entityId?: number;
    /**
     * ФИО - фамилия и инициалы
     * @type {string}
     * @memberof EntityLifecycleEventRecordAllOf
     */
    activityAuthorShortName?: string;
    /**
     * Описание изменения
     * @type {string}
     * @memberof EntityLifecycleEventRecordAllOf
     */
    description?: string;
    /**
     * Имя сущности, которую изменили
     * @type {string}
     * @memberof EntityLifecycleEventRecordAllOf
     */
    entityName?: string;
    /**
     * 
     * @type {EntityLifecycleEventTypeCode}
     * @memberof EntityLifecycleEventRecordAllOf
     */
    entityLifecycleEventTypeCode?: EntityLifecycleEventTypeCode;
    /**
     * 
     * @type {EntityTypeCodeEnum}
     * @memberof EntityLifecycleEventRecordAllOf
     */
    entityTypeCode?: EntityTypeCodeEnum;
    /**
     * Удаленная сущность
     * @type {boolean}
     * @memberof EntityLifecycleEventRecordAllOf
     */
    deletedEntity?: boolean;
}
/**
 * Список записей событий изменения сущности
 * @export
 * @interface EntityLifecycleEventRecordList
 */
export interface EntityLifecycleEventRecordList {
    /**
     * 
     * @type {ListAttributesInfo}
     * @memberof EntityLifecycleEventRecordList
     */
    listAttributes: ListAttributesInfo;
    /**
     * 
     * @type {Array<EntityLifecycleEventRecord>}
     * @memberof EntityLifecycleEventRecordList
     */
    records: Array<EntityLifecycleEventRecord>;
}
/**
 * 
 * @export
 * @interface EntityLifecycleEventRecordListAllOf
 */
export interface EntityLifecycleEventRecordListAllOf {
    /**
     * 
     * @type {Array<EntityLifecycleEventRecord>}
     * @memberof EntityLifecycleEventRecordListAllOf
     */
    records: Array<EntityLifecycleEventRecord>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum EntityLifecycleEventTypeCode {
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE',
    WORKFLOWTRANSITION = 'WORKFLOW_TRANSITION'
}

/**
 * Код типа сущности
 * @export
 * @enum {string}
 */
export enum EntityTypeCodeEnum {
    RENTER = 'RENTER',
    PROJECT = 'PROJECT',
    TEMPLATE = 'TEMPLATE',
    SUBRENT = 'SUBRENT',
    OPERATION = 'OPERATION',
    RENTELEMENT = 'RENT_ELEMENT',
    DOCUMENTTEMPLATE = 'DOCUMENT_TEMPLATE',
    COMMONDOCUMENTTEMPLATE = 'COMMON_DOCUMENT_TEMPLATE',
    REPORTCONFIGURATION = 'REPORT_CONFIGURATION',
    REPORTVISUALIZATION = 'REPORT_VISUALIZATION',
    REPORTINSTANCE = 'REPORT_INSTANCE',
    KIT = 'KIT',
    PRODUCT = 'PRODUCT',
    VARIANT = 'VARIANT',
    INSTANCE = 'INSTANCE',
    PAYMENT = 'PAYMENT',
    USER = 'USER',
    SYSTEMOPTION = 'SYSTEM_OPTION',
    BUSINESSACCOUNT = 'BUSINESS_ACCOUNT',
    CATEGORY = 'CATEGORY',
    CUSTOMFIELD = 'CUSTOM_FIELD',
    CONTACT = 'CONTACT',
    LOCATION = 'LOCATION',
    VEHICLE = 'VEHICLE',
    INVENTORYMOVEMENT = 'INVENTORY_MOVEMENT',
    PRICINGSCHEME = 'PRICING_SCHEME',
    TRANSPORTATION = 'TRANSPORTATION',
    EXPENSE = 'EXPENSE',
    WORKPLANNINGS = 'WORK_PLANNINGS',
    ATTACHMENT = 'ATTACHMENT',
    PROFESSION = 'PROFESSION',
    CREWMEMBER = 'CREW_MEMBER',
    PAYMENTMETHOD = 'PAYMENT_METHOD',
    LEGALDETAILS = 'LEGAL_DETAILS'
}

/**
 * 
 * @export
 * @interface ErrorInfo
 */
export interface ErrorInfo {
    /**
     * Код ошибки из справочника ошибок RentRabbit
     * @type {string}
     * @memberof ErrorInfo
     */
    code: string;
    /**
     * Наименование ошибки
     * @type {string}
     * @memberof ErrorInfo
     */
    title: string;
    /**
     * Дата и время ошибки
     * @type {Date}
     * @memberof ErrorInfo
     */
    timestamp: Date;
    /**
     * Сообщение ошибки
     * @type {string}
     * @memberof ErrorInfo
     */
    message?: string;
    /**
     * Стектрейс ошибки
     * @type {string}
     * @memberof ErrorInfo
     */
    stacktrace?: string;
    /**
     * Детализированное сообщение об ошибке
     * @type {string}
     * @memberof ErrorInfo
     */
    detailedMessage?: string;
}
/**
 * 
 * @export
 * @interface ExcelExportAllColumnsInfo
 */
export interface ExcelExportAllColumnsInfo {
    /**
     * 
     * @type {RenterNotFinancialFieldsTypeCode}
     * @memberof ExcelExportAllColumnsInfo
     */
    renterNotFinancialFieldsTypeCode?: RenterNotFinancialFieldsTypeCode;
    /**
     * 
     * @type {RenterFinancialFieldsTypeCode}
     * @memberof ExcelExportAllColumnsInfo
     */
    renterFinancialFieldsTypeCode?: RenterFinancialFieldsTypeCode;
    /**
     * 
     * @type {BaseProjectNotFinancialFieldsTypeCode}
     * @memberof ExcelExportAllColumnsInfo
     */
    baseProjectNotFinancialFieldsTypeCode?: BaseProjectNotFinancialFieldsTypeCode;
    /**
     * 
     * @type {BaseProjectFinancialFieldsTypeCode}
     * @memberof ExcelExportAllColumnsInfo
     */
    baseProjectFinancialFieldsTypeCode?: BaseProjectFinancialFieldsTypeCode;
    /**
     * 
     * @type {OfferProjectNotFinancialFieldsTypeCode}
     * @memberof ExcelExportAllColumnsInfo
     */
    offerProjectNotFinancialFieldsTypeCode?: OfferProjectNotFinancialFieldsTypeCode;
    /**
     * 
     * @type {OfferProjectFinancialFieldsTypeCode}
     * @memberof ExcelExportAllColumnsInfo
     */
    offerProjectFinancialFieldsTypeCode?: OfferProjectFinancialFieldsTypeCode;
    /**
     * 
     * @type {TemplateNotFinancialFieldsTypeCode}
     * @memberof ExcelExportAllColumnsInfo
     */
    templateNotFinancialFieldsTypeCode?: TemplateNotFinancialFieldsTypeCode;
    /**
     * 
     * @type {TemplateFinancialFieldsTypeCode}
     * @memberof ExcelExportAllColumnsInfo
     */
    templateFinancialFieldsTypeCode?: TemplateFinancialFieldsTypeCode;
    /**
     * 
     * @type {SubrentNotFinancialFieldsTypeCode}
     * @memberof ExcelExportAllColumnsInfo
     */
    subrentNotFinancialFieldsTypeCode?: SubrentNotFinancialFieldsTypeCode;
    /**
     * 
     * @type {SubrentFinancialFieldsTypeCode}
     * @memberof ExcelExportAllColumnsInfo
     */
    subrentFinancialFieldsTypeCode?: SubrentFinancialFieldsTypeCode;
    /**
     * 
     * @type {PaymentFinancialFieldsTypeCode}
     * @memberof ExcelExportAllColumnsInfo
     */
    paymentFinancialFieldsTypeCode?: PaymentFinancialFieldsTypeCode;
    /**
     * 
     * @type {WorkPlanningsNotFinancialFieldsTypeCode}
     * @memberof ExcelExportAllColumnsInfo
     */
    workPlanningsNotFinancialFieldsTypeCode?: WorkPlanningsNotFinancialFieldsTypeCode;
    /**
     * 
     * @type {WorkPlanningsFinancialFieldsTypeCode}
     * @memberof ExcelExportAllColumnsInfo
     */
    workPlanningsFinancialFieldsTypeCode?: WorkPlanningsFinancialFieldsTypeCode;
    /**
     * 
     * @type {ExpenseFinancialFieldsTypeCode}
     * @memberof ExcelExportAllColumnsInfo
     */
    expenseFinancialFieldsTypeCode?: ExpenseFinancialFieldsTypeCode;
    /**
     * 
     * @type {TransportationNotFinancialFieldsTypeCode}
     * @memberof ExcelExportAllColumnsInfo
     */
    transportationNotFinancialFieldsTypeCode?: TransportationNotFinancialFieldsTypeCode;
    /**
     * 
     * @type {LocationNotFinancialFieldsTypeCode}
     * @memberof ExcelExportAllColumnsInfo
     */
    locationNotFinancialFieldsTypeCode?: LocationNotFinancialFieldsTypeCode;
    /**
     * 
     * @type {VehicleNotFinancialFieldsTypeCode}
     * @memberof ExcelExportAllColumnsInfo
     */
    vehicleNotFinancialFieldsTypeCode?: VehicleNotFinancialFieldsTypeCode;
    /**
     * 
     * @type {ProfessionNotFinancialFieldsTypeCode}
     * @memberof ExcelExportAllColumnsInfo
     */
    professionNotFinancialFieldsTypeCode?: ProfessionNotFinancialFieldsTypeCode;
    /**
     * 
     * @type {ProfessionFinancialFieldsTypeCode}
     * @memberof ExcelExportAllColumnsInfo
     */
    professionFinancialFieldsTypeCode?: ProfessionFinancialFieldsTypeCode;
    /**
     * 
     * @type {CrewMemberNotFinancialFieldsTypeCode}
     * @memberof ExcelExportAllColumnsInfo
     */
    crewMemberNotFinancialFieldsTypeCode?: CrewMemberNotFinancialFieldsTypeCode;
    /**
     * 
     * @type {CrewMemberFinancialFieldsTypeCode}
     * @memberof ExcelExportAllColumnsInfo
     */
    crewMemberFinancialFieldsTypeCode?: CrewMemberFinancialFieldsTypeCode;
    /**
     * 
     * @type {ContactNotFinancialFieldsTypeCode}
     * @memberof ExcelExportAllColumnsInfo
     */
    contactFinancialFieldsTypeCode?: ContactNotFinancialFieldsTypeCode;
    /**
     * 
     * @type {OperationNotFinancialFieldsTypeCode}
     * @memberof ExcelExportAllColumnsInfo
     */
    operationNotFinancialFieldsTypeCode?: OperationNotFinancialFieldsTypeCode;
    /**
     * 
     * @type {OperationFinancialFieldsTypeCode}
     * @memberof ExcelExportAllColumnsInfo
     */
    operationFinancialFieldsTypeCode?: OperationFinancialFieldsTypeCode;
    /**
     * 
     * @type {InventoryMovementNotFinancialFieldsTypeCode}
     * @memberof ExcelExportAllColumnsInfo
     */
    inventoryMovementNotFinancialFieldsTypeCode?: InventoryMovementNotFinancialFieldsTypeCode;
    /**
     * 
     * @type {InventoryMovementFinancialFieldsTypeCode}
     * @memberof ExcelExportAllColumnsInfo
     */
    inventoryMovementFinancialFieldsTypeCode?: InventoryMovementFinancialFieldsTypeCode;
    /**
     * 
     * @type {EntityLifecycleEventNotFinancialFieldsTypeCode}
     * @memberof ExcelExportAllColumnsInfo
     */
    entityLifecycleEventNotFinancialFieldsTypeCode?: EntityLifecycleEventNotFinancialFieldsTypeCode;
    /**
     * 
     * @type {NomenclatureNotFinancialFieldsTypeCode}
     * @memberof ExcelExportAllColumnsInfo
     */
    nomenclatureNotFinancialFieldsTypeCode?: NomenclatureNotFinancialFieldsTypeCode;
    /**
     * 
     * @type {NomenclatureFinancialFieldsTypeCode}
     * @memberof ExcelExportAllColumnsInfo
     */
    nomenclatureFinancialFieldsTypeCode?: NomenclatureFinancialFieldsTypeCode;
    /**
     * 
     * @type {VariantNotFinancialFieldsTypeCode}
     * @memberof ExcelExportAllColumnsInfo
     */
    variantNotFinancialFieldsTypeCode?: VariantNotFinancialFieldsTypeCode;
    /**
     * 
     * @type {VariantFinancialFieldsTypeCode}
     * @memberof ExcelExportAllColumnsInfo
     */
    variantFinancialFieldsTypeCode?: VariantFinancialFieldsTypeCode;
    /**
     * 
     * @type {InstanceNotFinancialFieldsTypeCode}
     * @memberof ExcelExportAllColumnsInfo
     */
    instanceNotFinancialFieldsTypeCode?: InstanceNotFinancialFieldsTypeCode;
    /**
     * 
     * @type {KitNotFinancialFieldsTypeCode}
     * @memberof ExcelExportAllColumnsInfo
     */
    kitNotFinancialFieldsTypeCode?: KitNotFinancialFieldsTypeCode;
    /**
     * 
     * @type {KitFinancialFieldsTypeCode}
     * @memberof ExcelExportAllColumnsInfo
     */
    kitFinancialFieldsTypeCode?: KitFinancialFieldsTypeCode;
}
/**
 * 
 * @export
 * @interface ExcelExportGenerationInfo
 */
export interface ExcelExportGenerationInfo {
    /**
     * 
     * @type {Array<string>}
     * @memberof ExcelExportGenerationInfo
     */
    columns: Array<string>;
    /**
     * 
     * @type {ExcelExportTemplateTypeCodeEnum}
     * @memberof ExcelExportGenerationInfo
     */
    exportTemplateType: ExcelExportTemplateTypeCodeEnum;
    /**
     * 
     * @type {ExcelExportAllColumnsInfo}
     * @memberof ExcelExportGenerationInfo
     */
    excelExportAllColumnsInfo?: ExcelExportAllColumnsInfo;
}
/**
 * Код типа шаблона для экспорта
 * @export
 * @enum {string}
 */
export enum ExcelExportTemplateTypeCodeEnum {
    BASEPROJECTTEMPLATE = 'BASE_PROJECT_TEMPLATE',
    OFFERPROJECTTEMPLATE = 'OFFER_PROJECT_TEMPLATE',
    TEMPLATETEMPLATE = 'TEMPLATE_TEMPLATE',
    SUBRENTTEMPLATE = 'SUBRENT_TEMPLATE',
    PAYMENTTEMPLATE = 'PAYMENT_TEMPLATE',
    WORKPLANNINGSTEMPLATE = 'WORK_PLANNINGS_TEMPLATE',
    EXPENSETEMPLATE = 'EXPENSE_TEMPLATE',
    RENTERTEMPLATE = 'RENTER_TEMPLATE',
    TRANSPORTATIONTEMPLATE = 'TRANSPORTATION_TEMPLATE',
    LOCATIONTEMPLATE = 'LOCATION_TEMPLATE',
    VEHICLETEMPLATE = 'VEHICLE_TEMPLATE',
    PROFESSIONTEMPLATE = 'PROFESSION_TEMPLATE',
    CREWMEMBERTEMPLATE = 'CREW_MEMBER_TEMPLATE',
    CONTACTTEMPLATE = 'CONTACT_TEMPLATE',
    OPERATIONTEMPLATE = 'OPERATION_TEMPLATE',
    INVENTORYMOVEMENTTEMPLATE = 'INVENTORY_MOVEMENT_TEMPLATE',
    ENTITYLIFECYCLEEVENTTEMPLATE = 'ENTITY_LIFECYCLE_EVENT_TEMPLATE',
    NOMENCLATURETEMPLATE = 'NOMENCLATURE_TEMPLATE',
    VARIANTTEMPLATE = 'VARIANT_TEMPLATE',
    INSTANCETEMPLATE = 'INSTANCE_TEMPLATE',
    KITTEMPLATE = 'KIT_TEMPLATE'
}

/**
 * 
 * @export
 * @interface ExcelReportGenerationInfo
 */
export interface ExcelReportGenerationInfo {
    /**
     * 
     * @type {ReportInputParameters}
     * @memberof ExcelReportGenerationInfo
     */
    params: ReportInputParameters;
    /**
     * 
     * @type {number}
     * @memberof ExcelReportGenerationInfo
     */
    timeOffsetUtc?: number;
}
/**
 * Все поля сущности ExpenseRecord, которые могут быть выгружены в список Excel. Не финансовых полей нет, так как платежи целиком являются финансовой сущностью
 * @export
 * @enum {string}
 */
export enum ExpenseFinancialFieldsTypeCode {
    ProjectShortName = 'projectShortName',
    NumberInProject = 'numberInProject',
    Name = 'name',
    Date = 'date',
    InternalFinalTotalPrice = 'internalFinalTotalPrice',
    ExternalFinalTotalPrice = 'externalFinalTotalPrice',
    IncludeTaxInExternalExpenseSum = 'includeTaxInExternalExpenseSum',
    State = 'state',
    LastUpdateAuthorShortName = 'lastUpdateAuthorShortName',
    LastUpdateDate = 'lastUpdateDate'
}

/**
 * Абстрактное общее состояние сущности затраты
 * @export
 * @interface ExpenseInfoAbstractCommons
 */
export interface ExpenseInfoAbstractCommons {
    /**
     * Идентификатор
     * @type {number}
     * @memberof ExpenseInfoAbstractCommons
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof ExpenseInfoAbstractCommons
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof ExpenseInfoAbstractCommons
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof ExpenseInfoAbstractCommons
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof ExpenseInfoAbstractCommons
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof ExpenseInfoAbstractCommons
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof ExpenseInfoAbstractCommons
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof ExpenseInfoAbstractCommons
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof ExpenseInfoAbstractCommons
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof ExpenseInfoAbstractCommons
     */
    businessAccountId?: number;
    /**
     * Название
     * @type {string}
     * @memberof ExpenseInfoAbstractCommons
     */
    name: string;
    /**
     * Дата затраты
     * @type {Date}
     * @memberof ExpenseInfoAbstractCommons
     */
    date: Date;
    /**
     * Внутренняя стоимость, которая будет учитываться в издержках
     * @type {number}
     * @memberof ExpenseInfoAbstractCommons
     */
    internalFinalTotalPrice: number;
    /**
     * Внешняя стоимость, которая будет предъявлена арендатору
     * @type {number}
     * @memberof ExpenseInfoAbstractCommons
     */
    externalFinalTotalPrice: number;
    /**
     * Ссылка на проект
     * @type {number}
     * @memberof ExpenseInfoAbstractCommons
     */
    projectId: number;
    /**
     * Флаг включения налога в внешнюю стоимость затраты
     * @type {boolean}
     * @memberof ExpenseInfoAbstractCommons
     */
    includeTaxInExternalExpenseSum: boolean;
}
/**
 * 
 * @export
 * @interface ExpenseInfoAbstractCommonsAllOf
 */
export interface ExpenseInfoAbstractCommonsAllOf {
    /**
     * Название
     * @type {string}
     * @memberof ExpenseInfoAbstractCommonsAllOf
     */
    name: string;
    /**
     * Дата затраты
     * @type {Date}
     * @memberof ExpenseInfoAbstractCommonsAllOf
     */
    date: Date;
    /**
     * Внутренняя стоимость, которая будет учитываться в издержках
     * @type {number}
     * @memberof ExpenseInfoAbstractCommonsAllOf
     */
    internalFinalTotalPrice: number;
    /**
     * Внешняя стоимость, которая будет предъявлена арендатору
     * @type {number}
     * @memberof ExpenseInfoAbstractCommonsAllOf
     */
    externalFinalTotalPrice: number;
    /**
     * Ссылка на проект
     * @type {number}
     * @memberof ExpenseInfoAbstractCommonsAllOf
     */
    projectId: number;
    /**
     * Флаг включения налога в внешнюю стоимость затраты
     * @type {boolean}
     * @memberof ExpenseInfoAbstractCommonsAllOf
     */
    includeTaxInExternalExpenseSum: boolean;
}
/**
 * Состояние сущности затраты для создания
 * @export
 * @interface ExpenseInfoCreate
 */
export interface ExpenseInfoCreate extends ExpenseInfoAbstractCommons {
    /**
     * 
     * @type {ExpenseStateCodeEnum}
     * @memberof ExpenseInfoCreate
     */
    state: ExpenseStateCodeEnum;
}
/**
 * Состояние сущности затраты для чтения
 * @export
 * @interface ExpenseInfoRead
 */
export interface ExpenseInfoRead {
    /**
     * Идентификатор
     * @type {number}
     * @memberof ExpenseInfoRead
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof ExpenseInfoRead
     */
    businessVersion: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof ExpenseInfoRead
     */
    creationAuthorId: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof ExpenseInfoRead
     */
    creationAuthorFullName: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof ExpenseInfoRead
     */
    creationDate: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof ExpenseInfoRead
     */
    lastUpdateAuthorId: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof ExpenseInfoRead
     */
    lastUpdateAuthorFullName: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof ExpenseInfoRead
     */
    lastUpdateDate: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof ExpenseInfoRead
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof ExpenseInfoRead
     */
    businessAccountId: number;
    /**
     * Название
     * @type {string}
     * @memberof ExpenseInfoRead
     */
    name: string;
    /**
     * Дата затраты
     * @type {Date}
     * @memberof ExpenseInfoRead
     */
    date: Date;
    /**
     * Внутренняя стоимость, которая будет учитываться в издержках
     * @type {number}
     * @memberof ExpenseInfoRead
     */
    internalFinalTotalPrice: number;
    /**
     * Внешняя стоимость, которая будет предъявлена арендатору
     * @type {number}
     * @memberof ExpenseInfoRead
     */
    externalFinalTotalPrice: number;
    /**
     * Ссылка на проект
     * @type {number}
     * @memberof ExpenseInfoRead
     */
    projectId: number;
    /**
     * Флаг включения налога в внешнюю стоимость затраты
     * @type {boolean}
     * @memberof ExpenseInfoRead
     */
    includeTaxInExternalExpenseSum: boolean;
    /**
     * 
     * @type {AttachmentRecordList}
     * @memberof ExpenseInfoRead
     */
    attachments?: AttachmentRecordList;
    /**
     * Внешняя стоимость, которая будет предъявлена арендатору с учетом налогов
     * @type {number}
     * @memberof ExpenseInfoRead
     */
    externalFinalTotalPriceAfterTaxes: number;
    /**
     * Ссылка: идентификатор арендатора
     * @type {number}
     * @memberof ExpenseInfoRead
     */
    renterId: number;
    /**
     * Выч. по ссылке: Короткое наименование арендатора
     * @type {string}
     * @memberof ExpenseInfoRead
     */
    renterShortName: string;
    /**
     * Выч. по ссылке: Короткое наименование проекта
     * @type {string}
     * @memberof ExpenseInfoRead
     */
    projectShortName: string;
    /**
     * Коды доступных переходов для данной затраты
     * @type {Array<ExpenseTransitionCodeEnum>}
     * @memberof ExpenseInfoRead
     */
    availableTransitionCodes: Array<ExpenseTransitionCodeEnum>;
    /**
     * Номер в проекте(локальный)
     * @type {number}
     * @memberof ExpenseInfoRead
     */
    numberInProject: number;
    /**
     * 
     * @type {ExpenseStateCodeEnum}
     * @memberof ExpenseInfoRead
     */
    state: ExpenseStateCodeEnum;
    /**
     * Состояние проекта
     * @type {boolean}
     * @memberof ExpenseInfoRead
     */
    projectIsOffer: boolean;
}
/**
 * 
 * @export
 * @interface ExpenseInfoReadAllOf
 */
export interface ExpenseInfoReadAllOf {
    /**
     * 
     * @type {AttachmentRecordList}
     * @memberof ExpenseInfoReadAllOf
     */
    attachments?: AttachmentRecordList;
    /**
     * Внешняя стоимость, которая будет предъявлена арендатору с учетом налогов
     * @type {number}
     * @memberof ExpenseInfoReadAllOf
     */
    externalFinalTotalPriceAfterTaxes: number;
    /**
     * Ссылка: идентификатор арендатора
     * @type {number}
     * @memberof ExpenseInfoReadAllOf
     */
    renterId: number;
    /**
     * Выч. по ссылке: Короткое наименование арендатора
     * @type {string}
     * @memberof ExpenseInfoReadAllOf
     */
    renterShortName: string;
    /**
     * Выч. по ссылке: Короткое наименование проекта
     * @type {string}
     * @memberof ExpenseInfoReadAllOf
     */
    projectShortName: string;
    /**
     * Коды доступных переходов для данной затраты
     * @type {Array<ExpenseTransitionCodeEnum>}
     * @memberof ExpenseInfoReadAllOf
     */
    availableTransitionCodes: Array<ExpenseTransitionCodeEnum>;
    /**
     * Номер в проекте(локальный)
     * @type {number}
     * @memberof ExpenseInfoReadAllOf
     */
    numberInProject: number;
    /**
     * 
     * @type {ExpenseStateCodeEnum}
     * @memberof ExpenseInfoReadAllOf
     */
    state: ExpenseStateCodeEnum;
    /**
     * Состояние проекта
     * @type {boolean}
     * @memberof ExpenseInfoReadAllOf
     */
    projectIsOffer: boolean;
}
/**
 * Состояние сущности затраты для обновления
 * @export
 * @interface ExpenseInfoUpdate
 */
export interface ExpenseInfoUpdate extends ExpenseInfoAbstractCommons {
}
/**
 * Описание агрегаций затрат
 * @export
 * @interface ExpenseListAggregations
 */
export interface ExpenseListAggregations {
    /**
     * Количество затрат
     * @type {number}
     * @memberof ExpenseListAggregations
     */
    count?: number;
    /**
     * Сумма внутренних затрат
     * @type {number}
     * @memberof ExpenseListAggregations
     */
    internalExpensesSum?: number;
    /**
     * Сумма внешних затрат
     * @type {number}
     * @memberof ExpenseListAggregations
     */
    externalExpensesSum?: number;
    /**
     * Сумма внешних затрат после вычета налога
     * @type {number}
     * @memberof ExpenseListAggregations
     */
    externalExpensesSumAfterTax?: number;
}
/**
 * Списочное представление затраты
 * @export
 * @interface ExpenseRecord
 */
export interface ExpenseRecord {
    /**
     * Идентификатор
     * @type {number}
     * @memberof ExpenseRecord
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof ExpenseRecord
     */
    businessVersion: number;
    /**
     * Ссылка: Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof ExpenseRecord
     */
    businessAccountId: number;
    /**
     * Номер в проекте(локальный)
     * @type {number}
     * @memberof ExpenseRecord
     */
    numberInProject: number;
    /**
     * Название
     * @type {string}
     * @memberof ExpenseRecord
     */
    name: string;
    /**
     * Дата затраты
     * @type {Date}
     * @memberof ExpenseRecord
     */
    date: Date;
    /**
     * Внутренняя стоимость, которая будет учитываться в издержках
     * @type {number}
     * @memberof ExpenseRecord
     */
    internalFinalTotalPrice: number;
    /**
     * Внешняя стоимость, которая будет предъявлена арендатору
     * @type {number}
     * @memberof ExpenseRecord
     */
    externalFinalTotalPrice: number;
    /**
     * Внешняя стоимость, которая будет предъявлена арендатору с учетом налогов
     * @type {number}
     * @memberof ExpenseRecord
     */
    externalFinalTotalPriceAfterTaxes: number;
    /**
     * Флаг включения налога в внешнюю стоимость затраты
     * @type {boolean}
     * @memberof ExpenseRecord
     */
    includeTaxInExternalExpenseSum: boolean;
    /**
     * 
     * @type {ExpenseStateCodeEnum}
     * @memberof ExpenseRecord
     */
    state: ExpenseStateCodeEnum;
    /**
     * Коды доступных переходов для данной затраты
     * @type {Array<ExpenseTransitionCodeEnum>}
     * @memberof ExpenseRecord
     */
    availableTransitionCodes?: Array<ExpenseTransitionCodeEnum>;
    /**
     * Ссылка на проект
     * @type {number}
     * @memberof ExpenseRecord
     */
    projectId: number;
    /**
     * Короткое наименование проекта
     * @type {string}
     * @memberof ExpenseRecord
     */
    projectShortName: string;
    /**
     * Ссылка: идентификатор арендатора
     * @type {number}
     * @memberof ExpenseRecord
     */
    renterId: number;
    /**
     * Выч. по ссылке: Короткое наименование арендатора
     * @type {string}
     * @memberof ExpenseRecord
     */
    renterShortName: string;
    /**
     * Идентификатор автора последнего изменения записи
     * @type {number}
     * @memberof ExpenseRecord
     */
    lastUpdateAuthorId: number;
    /**
     * Дата последнего изменения записи
     * @type {Date}
     * @memberof ExpenseRecord
     */
    lastUpdateDate: Date;
    /**
     * Выч. по ссылке: короткое ФИО автора посл. изменения
     * @type {string}
     * @memberof ExpenseRecord
     */
    lastUpdateAuthorShortName: string;
    /**
     * Состояние проекта
     * @type {boolean}
     * @memberof ExpenseRecord
     */
    projectIsOffer: boolean;
    /**
     * 
     * @type {number}
     * @memberof ExpenseRecord
     */
    attachmentsCount?: number;
}
/**
 * 
 * @export
 * @interface ExpenseRecordAllOf
 */
export interface ExpenseRecordAllOf {
    /**
     * Номер в проекте(локальный)
     * @type {number}
     * @memberof ExpenseRecordAllOf
     */
    numberInProject: number;
    /**
     * Название
     * @type {string}
     * @memberof ExpenseRecordAllOf
     */
    name: string;
    /**
     * Дата затраты
     * @type {Date}
     * @memberof ExpenseRecordAllOf
     */
    date: Date;
    /**
     * Внутренняя стоимость, которая будет учитываться в издержках
     * @type {number}
     * @memberof ExpenseRecordAllOf
     */
    internalFinalTotalPrice: number;
    /**
     * Внешняя стоимость, которая будет предъявлена арендатору
     * @type {number}
     * @memberof ExpenseRecordAllOf
     */
    externalFinalTotalPrice: number;
    /**
     * Внешняя стоимость, которая будет предъявлена арендатору с учетом налогов
     * @type {number}
     * @memberof ExpenseRecordAllOf
     */
    externalFinalTotalPriceAfterTaxes: number;
    /**
     * Флаг включения налога в внешнюю стоимость затраты
     * @type {boolean}
     * @memberof ExpenseRecordAllOf
     */
    includeTaxInExternalExpenseSum: boolean;
    /**
     * 
     * @type {ExpenseStateCodeEnum}
     * @memberof ExpenseRecordAllOf
     */
    state: ExpenseStateCodeEnum;
    /**
     * Коды доступных переходов для данной затраты
     * @type {Array<ExpenseTransitionCodeEnum>}
     * @memberof ExpenseRecordAllOf
     */
    availableTransitionCodes?: Array<ExpenseTransitionCodeEnum>;
    /**
     * Ссылка на проект
     * @type {number}
     * @memberof ExpenseRecordAllOf
     */
    projectId: number;
    /**
     * Короткое наименование проекта
     * @type {string}
     * @memberof ExpenseRecordAllOf
     */
    projectShortName: string;
    /**
     * Ссылка: идентификатор арендатора
     * @type {number}
     * @memberof ExpenseRecordAllOf
     */
    renterId: number;
    /**
     * Выч. по ссылке: Короткое наименование арендатора
     * @type {string}
     * @memberof ExpenseRecordAllOf
     */
    renterShortName: string;
    /**
     * Идентификатор автора последнего изменения записи
     * @type {number}
     * @memberof ExpenseRecordAllOf
     */
    lastUpdateAuthorId: number;
    /**
     * Дата последнего изменения записи
     * @type {Date}
     * @memberof ExpenseRecordAllOf
     */
    lastUpdateDate: Date;
    /**
     * Выч. по ссылке: короткое ФИО автора посл. изменения
     * @type {string}
     * @memberof ExpenseRecordAllOf
     */
    lastUpdateAuthorShortName: string;
    /**
     * Состояние проекта
     * @type {boolean}
     * @memberof ExpenseRecordAllOf
     */
    projectIsOffer: boolean;
    /**
     * 
     * @type {number}
     * @memberof ExpenseRecordAllOf
     */
    attachmentsCount?: number;
}
/**
 * Список записей о затратах
 * @export
 * @interface ExpenseRecordList
 */
export interface ExpenseRecordList {
    /**
     * 
     * @type {ListAttributesInfo}
     * @memberof ExpenseRecordList
     */
    listAttributes: ListAttributesInfo;
    /**
     * 
     * @type {Array<ExpenseRecord>}
     * @memberof ExpenseRecordList
     */
    records: Array<ExpenseRecord>;
    /**
     * 
     * @type {ExpenseListAggregations}
     * @memberof ExpenseRecordList
     */
    aggregations?: ExpenseListAggregations;
}
/**
 * 
 * @export
 * @interface ExpenseRecordListAllOf
 */
export interface ExpenseRecordListAllOf {
    /**
     * 
     * @type {Array<ExpenseRecord>}
     * @memberof ExpenseRecordListAllOf
     */
    records: Array<ExpenseRecord>;
    /**
     * 
     * @type {ExpenseListAggregations}
     * @memberof ExpenseRecordListAllOf
     */
    aggregations?: ExpenseListAggregations;
}
/**
 * Код состояния сущности затраты
 * @export
 * @enum {string}
 */
export enum ExpenseStateCodeEnum {
    TAKENINTOACCOUNT = 'TAKEN_INTO_ACCOUNT',
    DRAFT = 'DRAFT',
    CANCELED = 'CANCELED'
}

/**
 * Возможные коды для переходов затрат
 * @export
 * @enum {string}
 */
export enum ExpenseTransitionCodeEnum {
    TAKEINTOACCOUNT = 'TAKE_INTO_ACCOUNT',
    TODRAFT = 'TO_DRAFT',
    CANCEL = 'CANCEL'
}

/**
 * Конфигурация шаблона для экспорта: абстрактное состояние с наименованием и описанием
 * @export
 * @interface ExportTemplateAbstractCommons
 */
export interface ExportTemplateAbstractCommons {
    /**
     * Идентификатор
     * @type {number}
     * @memberof ExportTemplateAbstractCommons
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof ExportTemplateAbstractCommons
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof ExportTemplateAbstractCommons
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof ExportTemplateAbstractCommons
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof ExportTemplateAbstractCommons
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof ExportTemplateAbstractCommons
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof ExportTemplateAbstractCommons
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof ExportTemplateAbstractCommons
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof ExportTemplateAbstractCommons
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof ExportTemplateAbstractCommons
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ExportTemplateAbstractCommons
     */
    description?: string;
    /**
     * Содержит финансовую информацию
     * @type {boolean}
     * @memberof ExportTemplateAbstractCommons
     */
    financial?: boolean;
    /**
     * 
     * @type {ExcelExportTemplateTypeCodeEnum}
     * @memberof ExportTemplateAbstractCommons
     */
    exportTemplateType?: ExcelExportTemplateTypeCodeEnum;
}
/**
 * 
 * @export
 * @interface ExportTemplateAbstractCommonsAllOf
 */
export interface ExportTemplateAbstractCommonsAllOf {
    /**
     * 
     * @type {string}
     * @memberof ExportTemplateAbstractCommonsAllOf
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ExportTemplateAbstractCommonsAllOf
     */
    description?: string;
    /**
     * Содержит финансовую информацию
     * @type {boolean}
     * @memberof ExportTemplateAbstractCommonsAllOf
     */
    financial?: boolean;
    /**
     * 
     * @type {ExcelExportTemplateTypeCodeEnum}
     * @memberof ExportTemplateAbstractCommonsAllOf
     */
    exportTemplateType?: ExcelExportTemplateTypeCodeEnum;
}
/**
 * Конфигурация шаблона для экспорта: состояние при создании
 * @export
 * @interface ExportTemplateInfoCreate
 */
export interface ExportTemplateInfoCreate extends ExportTemplateAbstractCommons {
}
/**
 * Конфигурация шаблона для экспорта: состояние чтения
 * @export
 * @interface ExportTemplateInfoRead
 */
export interface ExportTemplateInfoRead extends ExportTemplateAbstractCommons {
}
/**
 * Конфигурация шаблона для экспорта: состояние изменения
 * @export
 * @interface ExportTemplateInfoUpdate
 */
export interface ExportTemplateInfoUpdate extends ExportTemplateAbstractCommons {
}
/**
 * Конфигурация шаблона для экспорта: состояние списочного представления
 * @export
 * @interface ExportTemplateRecord
 */
export interface ExportTemplateRecord {
    /**
     * Идентификатор
     * @type {number}
     * @memberof ExportTemplateRecord
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof ExportTemplateRecord
     */
    businessVersion: number;
    /**
     * 
     * @type {string}
     * @memberof ExportTemplateRecord
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ExportTemplateRecord
     */
    description?: string;
    /**
     * Содержит финансовую информацию
     * @type {boolean}
     * @memberof ExportTemplateRecord
     */
    financial: boolean;
    /**
     * 
     * @type {ExcelExportTemplateTypeCodeEnum}
     * @memberof ExportTemplateRecord
     */
    exportTemplateType: ExcelExportTemplateTypeCodeEnum;
}
/**
 * 
 * @export
 * @interface ExportTemplateRecordAllOf
 */
export interface ExportTemplateRecordAllOf {
    /**
     * 
     * @type {string}
     * @memberof ExportTemplateRecordAllOf
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ExportTemplateRecordAllOf
     */
    description?: string;
    /**
     * Содержит финансовую информацию
     * @type {boolean}
     * @memberof ExportTemplateRecordAllOf
     */
    financial: boolean;
    /**
     * 
     * @type {ExcelExportTemplateTypeCodeEnum}
     * @memberof ExportTemplateRecordAllOf
     */
    exportTemplateType: ExcelExportTemplateTypeCodeEnum;
}
/**
 * Список шаблонов для экспорта
 * @export
 * @interface ExportTemplateRecordList
 */
export interface ExportTemplateRecordList {
    /**
     * 
     * @type {ListAttributesInfo}
     * @memberof ExportTemplateRecordList
     */
    listAttributes: ListAttributesInfo;
    /**
     * 
     * @type {Array<ExportTemplateRecord>}
     * @memberof ExportTemplateRecordList
     */
    records: Array<ExportTemplateRecord>;
}
/**
 * 
 * @export
 * @interface ExportTemplateRecordListAllOf
 */
export interface ExportTemplateRecordListAllOf {
    /**
     * 
     * @type {Array<ExportTemplateRecord>}
     * @memberof ExportTemplateRecordListAllOf
     */
    records: Array<ExportTemplateRecord>;
}
/**
 * Код тип фактора образования цены аренды
 * @export
 * @enum {string}
 */
export enum FactorTypeCodeEnum {
    HOUR = 'HOUR',
    DAY = 'DAY',
    SHIFT = 'SHIFT'
}

/**
 * Код типа расширения файла
 * @export
 * @enum {string}
 */
export enum FileExtensionTypeCodeEnum {
    XLSX = 'XLSX',
    DOCX = 'DOCX',
    TXT = 'TXT',
    JPEG = 'JPEG',
    PNG = 'PNG',
    BMP = 'BMP',
    PSD = 'PSD',
    SVG = 'SVG',
    AI = 'AI',
    MP3 = 'MP3',
    WMA = 'WMA',
    AVI = 'AVI',
    MOV = 'MOV',
    MP4 = 'MP4',
    GIF = 'GIF',
    ZIP = 'ZIP',
    RAR = 'RAR',
    _7Z = '_7Z',
    PPTX = 'PPTX',
    PDF = 'PDF',
    OTHER = 'OTHER'
}

/**
 * Код типа файла
 * @export
 * @enum {string}
 */
export enum FileTypeCodeEnum {
    TEXTDOCUMENT = 'TEXT_DOCUMENT',
    IMAGE = 'IMAGE',
    AUDIO = 'AUDIO',
    VIDEO = 'VIDEO',
    ARCHIVE = 'ARCHIVE',
    PRESENTATION = 'PRESENTATION',
    WORD = 'WORD',
    EXCEL = 'EXCEL',
    PDFTYPE = 'PDF_TYPE',
    OTHERTYPE = 'OTHER_TYPE'
}

/**
 * Глобальные права
 * @export
 * @enum {string}
 */
export enum GlobalPermissionCodeEnum {
    USEAPI = 'GLOBAL__USE_API',
    CREATEENTITIES = 'GLOBAL__CREATE_ENTITIES',
    VIEWENTITIES = 'GLOBAL__VIEW_ENTITIES',
    UPDATEENTITIES = 'GLOBAL__UPDATE_ENTITIES',
    WORKFLOWTRANSITENTITIES = 'GLOBAL__WORKFLOW_TRANSIT_ENTITIES',
    VIEWFINANCIALDATA = 'GLOBAL__VIEW_FINANCIAL_DATA',
    UPDATEFINANCIALDATA = 'GLOBAL__UPDATE_FINANCIAL_DATA',
    VIEWSYSTEMOPTIONS = 'GLOBAL__VIEW_SYSTEM_OPTIONS',
    UPDATESYSTEMOPTIONS = 'GLOBAL__UPDATE_SYSTEM_OPTIONS',
    VIEWBUSINESSACCOUNT = 'GLOBAL__VIEW_BUSINESS_ACCOUNT',
    CREATEBUSINESSACCOUNT = 'GLOBAL__CREATE_BUSINESS_ACCOUNT',
    UPDATEBUSINESSACCOUNT = 'GLOBAL__UPDATE_BUSINESS_ACCOUNT',
    DELETEBUSINESSACCOUNT = 'GLOBAL__DELETE_BUSINESS_ACCOUNT',
    WORKFLOWTRANSITBUSINESSACCOUNTS = 'GLOBAL__WORKFLOW_TRANSIT_BUSINESS_ACCOUNTS',
    VIEWUSERS = 'GLOBAL__VIEW_USERS',
    CREATECORRECTIONOPERATION = 'GLOBAL__CREATE_CORRECTION_OPERATION',
    MANAGEUSERS = 'GLOBAL__MANAGE_USERS'
}

/**
 * Глобальные роли
 * @export
 * @enum {string}
 */
export enum GlobalRoleCodeEnum {
    ADMIN = 'RR_GLOBAL__ADMIN',
    AGENT = 'RR_GLOBAL__AGENT',
    STOREKEEPER = 'RR_GLOBAL__STOREKEEPER'
}

/**
 * Description of whole grid
 * @export
 * @interface Grid
 */
export interface Grid {
    /**
     * Count of columns
     * @type {number}
     * @memberof Grid
     */
    cols: number;
    /**
     * Array of desciptions of cells
     * @type {Array<GridCell>}
     * @memberof Grid
     */
    cells: Array<GridCell>;
}
/**
 * Description of grid cell
 * @export
 * @interface GridCell
 */
export interface GridCell {
    /**
     * Grid cell unique identifier
     * @type {number}
     * @memberof GridCell
     */
    identifier: number;
    /**
     * 
     * @type {GridInfo}
     * @memberof GridCell
     */
    grid: GridInfo;
}
/**
 * Description of grid cell
 * @export
 * @interface GridInfo
 */
export interface GridInfo {
    /**
     * X axis position
     * @type {number}
     * @memberof GridInfo
     */
    x: number;
    /**
     * Y axis position
     * @type {number}
     * @memberof GridInfo
     */
    y: number;
    /**
     * Colspan info
     * @type {number}
     * @memberof GridInfo
     */
    colspan?: number;
    /**
     * Rowspan info
     * @type {number}
     * @memberof GridInfo
     */
    rowspan?: number;
    /**
     * Maximum height
     * @type {number}
     * @memberof GridInfo
     */
    maxH?: number;
    /**
     * Maximum width
     * @type {number}
     * @memberof GridInfo
     */
    maxW?: number;
    /**
     * Minimum height
     * @type {number}
     * @memberof GridInfo
     */
    minH?: number;
    /**
     * Minimum width
     * @type {number}
     * @memberof GridInfo
     */
    minW?: number;
}
/**
 * 
 * @export
 * @interface GroupedRentElement
 */
export interface GroupedRentElement {
    /**
     * 
     * @type {EntityTypeCodeEnum}
     * @memberof GroupedRentElement
     */
    entityType?: EntityTypeCodeEnum;
    /**
     * 
     * @type {number}
     * @memberof GroupedRentElement
     */
    entityId?: number;
    /**
     * 
     * @type {string}
     * @memberof GroupedRentElement
     */
    entityName?: string;
    /**
     * 
     * @type {Array<GroupedRentElement>}
     * @memberof GroupedRentElement
     */
    variants?: Array<GroupedRentElement>;
    /**
     * 
     * @type {Array<RentElementRecord>}
     * @memberof GroupedRentElement
     */
    anonymousRentElements?: Array<RentElementRecord>;
    /**
     * 
     * @type {Array<InstanceRentElement>}
     * @memberof GroupedRentElement
     */
    instances?: Array<InstanceRentElement>;
}
/**
 * Сгруппированные по дате и рамке активности обязательства
 * @export
 * @interface GroupedRentElementRecord
 */
export interface GroupedRentElementRecord {
    /**
     * Дата и время выдачи или возврата в зависимости от типа группировки
     * @type {Date}
     * @memberof GroupedRentElementRecord
     */
    date: Date;
    /**
     * Идентификатор контрагента
     * @type {number}
     * @memberof GroupedRentElementRecord
     */
    counterpartyId: number;
    /**
     * Короткое наименование контрагента
     * @type {string}
     * @memberof GroupedRentElementRecord
     */
    counterpartyShortName: string;
    /**
     * Идентификатор проекта или поставки в зависимости от типа группировки
     * @type {number}
     * @memberof GroupedRentElementRecord
     */
    activityFrameId: number;
    /**
     * Короткое наименование проекта или поставки в зависимости от типа группировки
     * @type {string}
     * @memberof GroupedRentElementRecord
     */
    activityFrameShortName: string;
    /**
     * Число обязательств (каждый компонент набора за единицу)
     * @type {number}
     * @memberof GroupedRentElementRecord
     */
    rentElementCount: number;
    /**
     * Число экземпляров
     * @type {number}
     * @memberof GroupedRentElementRecord
     */
    instanceCount: number;
    /**
     * Сумма в коп.
     * @type {number}
     * @memberof GroupedRentElementRecord
     */
    finalTotalSum?: number;
    /**
     * 
     * @type {ProblemAndWarningRentIndicatorsRecordObj}
     * @memberof GroupedRentElementRecord
     */
    problemsAndWarnings: ProblemAndWarningRentIndicatorsRecordObj;
}
/**
 * Список cгруппированных по дате и рамке активности обязательств
 * @export
 * @interface GroupedRentElementRecordList
 */
export interface GroupedRentElementRecordList {
    /**
     * 
     * @type {ListAttributesInfo}
     * @memberof GroupedRentElementRecordList
     */
    listAttributes: ListAttributesInfo;
    /**
     * 
     * @type {Array<GroupedRentElementRecord>}
     * @memberof GroupedRentElementRecordList
     */
    records: Array<GroupedRentElementRecord>;
}
/**
 * 
 * @export
 * @interface GroupedRentElementRecordListAllOf
 */
export interface GroupedRentElementRecordListAllOf {
    /**
     * 
     * @type {Array<GroupedRentElementRecord>}
     * @memberof GroupedRentElementRecordListAllOf
     */
    records: Array<GroupedRentElementRecord>;
}
/**
 * Абстрактная пара, идентификатор и дата
 * @export
 * @interface IdDatePairObj
 */
export interface IdDatePairObj {
    /**
     * Идентификатор
     * @type {number}
     * @memberof IdDatePairObj
     */
    id: number;
    /**
     * Дата и время последней операции
     * @type {Date}
     * @memberof IdDatePairObj
     */
    date: Date;
}
/**
 * Информация об изображении
 * @export
 * @interface ImageObj
 */
export interface ImageObj {
    /**
     * Идентификатор изображения
     * @type {number}
     * @memberof ImageObj
     */
    id: number;
    /**
     * Ссылка изображения
     * @type {string}
     * @memberof ImageObj
     */
    externalId?: string;
}
/**
 * Объект, описывающий индикатор аренды состоящий из суммы и числа обязательств
 * @export
 * @interface IndicatorObj
 */
export interface IndicatorObj {
    /**
     * Сумма в коп.
     * @type {number}
     * @memberof IndicatorObj
     */
    sum: number;
    /**
     * Число обязательств
     * @type {number}
     * @memberof IndicatorObj
     */
    elementsCount: number;
}
/**
 * Объект описывающий индикатор аренды состоящий из суммы, числа обязательств и числа экземпляров
 * @export
 * @interface IndicatorWithInstancesObj
 */
export interface IndicatorWithInstancesObj {
    /**
     * Сумма в коп.
     * @type {number}
     * @memberof IndicatorWithInstancesObj
     */
    sum: number;
    /**
     * Число обязательств
     * @type {number}
     * @memberof IndicatorWithInstancesObj
     */
    elementsCount: number;
    /**
     * Число экземпляров
     * @type {number}
     * @memberof IndicatorWithInstancesObj
     */
    instnacesCount?: number;
}
/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {ContactInfoCreate}
     * @memberof InlineObject
     */
    contactInfoCreate?: ContactInfoCreate;
    /**
     * 
     * @type {Array<any>}
     * @memberof InlineObject
     */
    attachmentInfoCreates?: Array<any>;
}
/**
 * 
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
    /**
     * 
     * @type {any}
     * @memberof InlineObject1
     */
    file: any;
}
/**
 * 
 * @export
 * @interface InlineObject10
 */
export interface InlineObject10 {
    /**
     * 
     * @type {any}
     * @memberof InlineObject10
     */
    content: any;
}
/**
 * 
 * @export
 * @interface InlineObject11
 */
export interface InlineObject11 {
    /**
     * 
     * @type {any}
     * @memberof InlineObject11
     */
    file: any;
    /**
     * 
     * @type {DocumentTemplateInfoCreate}
     * @memberof InlineObject11
     */
    data: DocumentTemplateInfoCreate;
}
/**
 * 
 * @export
 * @interface InlineObject12
 */
export interface InlineObject12 {
    /**
     * 
     * @type {any}
     * @memberof InlineObject12
     */
    file: any;
    /**
     * Если true, изменение контента шаблона игнорируется
     * @type {boolean}
     * @memberof InlineObject12
     */
    ignoreFile: boolean;
    /**
     * 
     * @type {DocumentTemplateInfoUpdate}
     * @memberof InlineObject12
     */
    data: DocumentTemplateInfoUpdate;
}
/**
 * 
 * @export
 * @interface InlineObject2
 */
export interface InlineObject2 {
    /**
     * 
     * @type {AttachmentInfoCreate}
     * @memberof InlineObject2
     */
    attachmentInfoCreate: AttachmentInfoCreate;
    /**
     * 
     * @type {any}
     * @memberof InlineObject2
     */
    file: any;
}
/**
 * 
 * @export
 * @interface InlineObject3
 */
export interface InlineObject3 {
    /**
     * 
     * @type {any}
     * @memberof InlineObject3
     */
    file: any;
    /**
     * 
     * @type {DocumentTemplateInfoCreate}
     * @memberof InlineObject3
     */
    data: DocumentTemplateInfoCreate;
}
/**
 * 
 * @export
 * @interface InlineObject4
 */
export interface InlineObject4 {
    /**
     * 
     * @type {any}
     * @memberof InlineObject4
     */
    file: any;
    /**
     * Если true, изменение контента шаблона игнорируется
     * @type {boolean}
     * @memberof InlineObject4
     */
    ignoreFile: boolean;
    /**
     * 
     * @type {DocumentTemplateInfoUpdate}
     * @memberof InlineObject4
     */
    data: DocumentTemplateInfoUpdate;
}
/**
 * 
 * @export
 * @interface InlineObject5
 */
export interface InlineObject5 {
    /**
     * 
     * @type {InventoryMovementInfoCreate}
     * @memberof InlineObject5
     */
    inventoryMovementInfoCreate?: InventoryMovementInfoCreate;
    /**
     * 
     * @type {Array<any>}
     * @memberof InlineObject5
     */
    attachmentInfoCreates?: Array<any>;
}
/**
 * 
 * @export
 * @interface InlineObject6
 */
export interface InlineObject6 {
    /**
     * 
     * @type {CrewMemberInfoCreate}
     * @memberof InlineObject6
     */
    contactInfoCreate?: CrewMemberInfoCreate;
    /**
     * 
     * @type {Array<any>}
     * @memberof InlineObject6
     */
    attachmentInfoCreates?: Array<any>;
}
/**
 * 
 * @export
 * @interface InlineObject7
 */
export interface InlineObject7 {
    /**
     * 
     * @type {LocationInfoCreate}
     * @memberof InlineObject7
     */
    contactInfoCreate?: LocationInfoCreate;
    /**
     * 
     * @type {Array<any>}
     * @memberof InlineObject7
     */
    attachmentInfoCreates?: Array<any>;
}
/**
 * 
 * @export
 * @interface InlineObject8
 */
export interface InlineObject8 {
    /**
     * 
     * @type {TransportationInfoCreate}
     * @memberof InlineObject8
     */
    contactInfoCreate?: TransportationInfoCreate;
    /**
     * 
     * @type {Array<any>}
     * @memberof InlineObject8
     */
    attachmentInfoCreates?: Array<any>;
}
/**
 * 
 * @export
 * @interface InlineObject9
 */
export interface InlineObject9 {
    /**
     * 
     * @type {VehicleInfoCreate}
     * @memberof InlineObject9
     */
    contactInfoCreate?: VehicleInfoCreate;
    /**
     * 
     * @type {Array<any>}
     * @memberof InlineObject9
     */
    attachmentInfoCreates?: Array<any>;
}
/**
 * 
 * @export
 * @interface InstanceIdsWithNamesOrCodes
 */
export interface InstanceIdsWithNamesOrCodes {
    /**
     * 
     * @type {number}
     * @memberof InstanceIdsWithNamesOrCodes
     */
    instanceId?: number;
    /**
     * 
     * @type {string}
     * @memberof InstanceIdsWithNamesOrCodes
     */
    instanceNameOrCode?: string;
}
/**
 * Экземпляр: абстрактное общее состояние для всех операций
 * @export
 * @interface InstanceInfoAbstractCommons
 */
export interface InstanceInfoAbstractCommons {
    /**
     * Идентификатор
     * @type {number}
     * @memberof InstanceInfoAbstractCommons
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof InstanceInfoAbstractCommons
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof InstanceInfoAbstractCommons
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof InstanceInfoAbstractCommons
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof InstanceInfoAbstractCommons
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof InstanceInfoAbstractCommons
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof InstanceInfoAbstractCommons
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof InstanceInfoAbstractCommons
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof InstanceInfoAbstractCommons
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof InstanceInfoAbstractCommons
     */
    businessAccountId?: number;
    /**
     * Наименование
     * @type {string}
     * @memberof InstanceInfoAbstractCommons
     */
    nameOrCode: string;
    /**
     * Версия продукта. Для защиты от перезаписи свойств продукта - stockInstanceCount и underMaintenanceInstanceCount, которые получаются суммированием по экземплярам (в случае наличия вариантов, сначала суммируются по вариантам)
     * @type {number}
     * @memberof InstanceInfoAbstractCommons
     */
    productBusinessVersion?: number;
    /**
     * Версия варианта. Обязательна если тип отслеживания экземпляров variantBased, иначе не нужна. Для защиты от перезаписи свойств варианта - stockInstanceCount и underMaintenanceInstanceCount, которые получаются суммированием по экземплярам
     * @type {number}
     * @memberof InstanceInfoAbstractCommons
     */
    variantBusinessVersion?: number;
    /**
     * Штриховой код
     * @type {string}
     * @memberof InstanceInfoAbstractCommons
     */
    barcode?: string;
    /**
     * Инвентарный номер
     * @type {string}
     * @memberof InstanceInfoAbstractCommons
     */
    inventoryNumber?: string;
    /**
     * Закупочная стоимость, коп.
     * @type {number}
     * @memberof InstanceInfoAbstractCommons
     */
    purchaseCost?: number;
    /**
     * Балансовая стоимость, коп.
     * @type {number}
     * @memberof InstanceInfoAbstractCommons
     */
    carryingValue?: number;
    /**
     * Описание состояния
     * @type {string}
     * @memberof InstanceInfoAbstractCommons
     */
    stateDescription?: string;
    /**
     * Идентификатор продавца
     * @type {number}
     * @memberof InstanceInfoAbstractCommons
     */
    sellerId?: number;
}
/**
 * 
 * @export
 * @interface InstanceInfoAbstractCommonsAllOf
 */
export interface InstanceInfoAbstractCommonsAllOf {
    /**
     * Наименование
     * @type {string}
     * @memberof InstanceInfoAbstractCommonsAllOf
     */
    nameOrCode: string;
    /**
     * Версия продукта. Для защиты от перезаписи свойств продукта - stockInstanceCount и underMaintenanceInstanceCount, которые получаются суммированием по экземплярам (в случае наличия вариантов, сначала суммируются по вариантам)
     * @type {number}
     * @memberof InstanceInfoAbstractCommonsAllOf
     */
    productBusinessVersion?: number;
    /**
     * Версия варианта. Обязательна если тип отслеживания экземпляров variantBased, иначе не нужна. Для защиты от перезаписи свойств варианта - stockInstanceCount и underMaintenanceInstanceCount, которые получаются суммированием по экземплярам
     * @type {number}
     * @memberof InstanceInfoAbstractCommonsAllOf
     */
    variantBusinessVersion?: number;
    /**
     * Штриховой код
     * @type {string}
     * @memberof InstanceInfoAbstractCommonsAllOf
     */
    barcode?: string;
    /**
     * Инвентарный номер
     * @type {string}
     * @memberof InstanceInfoAbstractCommonsAllOf
     */
    inventoryNumber?: string;
    /**
     * Закупочная стоимость, коп.
     * @type {number}
     * @memberof InstanceInfoAbstractCommonsAllOf
     */
    purchaseCost?: number;
    /**
     * Балансовая стоимость, коп.
     * @type {number}
     * @memberof InstanceInfoAbstractCommonsAllOf
     */
    carryingValue?: number;
    /**
     * Описание состояния
     * @type {string}
     * @memberof InstanceInfoAbstractCommonsAllOf
     */
    stateDescription?: string;
    /**
     * Идентификатор продавца
     * @type {number}
     * @memberof InstanceInfoAbstractCommonsAllOf
     */
    sellerId?: number;
}
/**
 * Экземпляр: абстрактное общее состояние при создании и изменении
 * @export
 * @interface InstanceInfoAbstractWriteCommons
 */
export interface InstanceInfoAbstractWriteCommons extends InstanceInfoAbstractCommons {
}
/**
 * Экземпляр: состояние при создании
 * @export
 * @interface InstanceInfoCreate
 */
export interface InstanceInfoCreate {
    /**
     * Идентификатор
     * @type {number}
     * @memberof InstanceInfoCreate
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof InstanceInfoCreate
     */
    businessVersion: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof InstanceInfoCreate
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof InstanceInfoCreate
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof InstanceInfoCreate
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof InstanceInfoCreate
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof InstanceInfoCreate
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof InstanceInfoCreate
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof InstanceInfoCreate
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof InstanceInfoCreate
     */
    businessAccountId?: number;
    /**
     * Наименование
     * @type {string}
     * @memberof InstanceInfoCreate
     */
    nameOrCode: string;
    /**
     * Версия продукта. Для защиты от перезаписи свойств продукта - stockInstanceCount и underMaintenanceInstanceCount, которые получаются суммированием по экземплярам (в случае наличия вариантов, сначала суммируются по вариантам)
     * @type {number}
     * @memberof InstanceInfoCreate
     */
    productBusinessVersion: number;
    /**
     * Версия варианта. Обязательна если тип отслеживания экземпляров variantBased, иначе не нужна. Для защиты от перезаписи свойств варианта - stockInstanceCount и underMaintenanceInstanceCount, которые получаются суммированием по экземплярам
     * @type {number}
     * @memberof InstanceInfoCreate
     */
    variantBusinessVersion?: number;
    /**
     * Штриховой код
     * @type {string}
     * @memberof InstanceInfoCreate
     */
    barcode?: string;
    /**
     * Инвентарный номер
     * @type {string}
     * @memberof InstanceInfoCreate
     */
    inventoryNumber?: string;
    /**
     * Закупочная стоимость, коп.
     * @type {number}
     * @memberof InstanceInfoCreate
     */
    purchaseCost?: number;
    /**
     * Балансовая стоимость, коп.
     * @type {number}
     * @memberof InstanceInfoCreate
     */
    carryingValue?: number;
    /**
     * Описание состояния
     * @type {string}
     * @memberof InstanceInfoCreate
     */
    stateDescription?: string;
    /**
     * Идентификатор продавца
     * @type {number}
     * @memberof InstanceInfoCreate
     */
    sellerId?: number;
    /**
     * 
     * @type {InstanceStateCodeEnum}
     * @memberof InstanceInfoCreate
     */
    stateCode: InstanceStateCodeEnum;
    /**
     * Идентификатор поставщика, обязателен когда статус SUPPLIER_OWNED
     * @type {number}
     * @memberof InstanceInfoCreate
     */
    supplierId?: number;
}
/**
 * 
 * @export
 * @interface InstanceInfoCreateAllOf
 */
export interface InstanceInfoCreateAllOf {
    /**
     * 
     * @type {InstanceStateCodeEnum}
     * @memberof InstanceInfoCreateAllOf
     */
    stateCode: InstanceStateCodeEnum;
    /**
     * Идентификатор поставщика, обязателен когда статус SUPPLIER_OWNED
     * @type {number}
     * @memberof InstanceInfoCreateAllOf
     */
    supplierId?: number;
}
/**
 * Экземпляр: состояние при создании
 * @export
 * @interface InstanceInfoCreateBulk
 */
export interface InstanceInfoCreateBulk {
    /**
     * Идентификатор поставщика, обязателен когда статус SUPPLIER_OWNED
     * @type {string}
     * @memberof InstanceInfoCreateBulk
     */
    prefix: string;
    /**
     * Число добавляемых экземпляров
     * @type {number}
     * @memberof InstanceInfoCreateBulk
     */
    addedCount: number;
    /**
     * Номер экземпляра, с которого начинаем добавлять
     * @type {number}
     * @memberof InstanceInfoCreateBulk
     */
    startNumber: number;
    /**
     * Идентификатор варианта
     * @type {number}
     * @memberof InstanceInfoCreateBulk
     */
    variantId?: number;
    /**
     * Закупочная стоимость, коп.
     * @type {number}
     * @memberof InstanceInfoCreateBulk
     */
    purchaseCost?: number;
    /**
     * Идентификатор продавца
     * @type {number}
     * @memberof InstanceInfoCreateBulk
     */
    sellerId?: number;
    /**
     * 
     * @type {string}
     * @memberof InstanceInfoCreateBulk
     */
    stateDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof InstanceInfoCreateBulk
     */
    comment?: string;
    /**
     * Балансовая стоимость, коп.
     * @type {number}
     * @memberof InstanceInfoCreateBulk
     */
    carryingValue?: number;
}
/**
 * Вариант: состояние при чтении
 * @export
 * @interface InstanceInfoRead
 */
export interface InstanceInfoRead {
    /**
     * Идентификатор
     * @type {number}
     * @memberof InstanceInfoRead
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof InstanceInfoRead
     */
    businessVersion: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof InstanceInfoRead
     */
    creationAuthorId: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof InstanceInfoRead
     */
    creationAuthorFullName: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof InstanceInfoRead
     */
    creationDate: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof InstanceInfoRead
     */
    lastUpdateAuthorId: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof InstanceInfoRead
     */
    lastUpdateAuthorFullName: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof InstanceInfoRead
     */
    lastUpdateDate: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof InstanceInfoRead
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof InstanceInfoRead
     */
    businessAccountId: number;
    /**
     * Наименование
     * @type {string}
     * @memberof InstanceInfoRead
     */
    nameOrCode: string;
    /**
     * Версия продукта. Для защиты от перезаписи свойств продукта - stockInstanceCount и underMaintenanceInstanceCount, которые получаются суммированием по экземплярам (в случае наличия вариантов, сначала суммируются по вариантам)
     * @type {number}
     * @memberof InstanceInfoRead
     */
    productBusinessVersion: number;
    /**
     * Версия варианта. Обязательна если тип отслеживания экземпляров variantBased, иначе не нужна. Для защиты от перезаписи свойств варианта - stockInstanceCount и underMaintenanceInstanceCount, которые получаются суммированием по экземплярам
     * @type {number}
     * @memberof InstanceInfoRead
     */
    variantBusinessVersion?: number;
    /**
     * Штриховой код
     * @type {string}
     * @memberof InstanceInfoRead
     */
    barcode?: string;
    /**
     * Инвентарный номер
     * @type {string}
     * @memberof InstanceInfoRead
     */
    inventoryNumber?: string;
    /**
     * Закупочная стоимость, коп.
     * @type {number}
     * @memberof InstanceInfoRead
     */
    purchaseCost?: number;
    /**
     * Балансовая стоимость, коп.
     * @type {number}
     * @memberof InstanceInfoRead
     */
    carryingValue?: number;
    /**
     * Описание состояния
     * @type {string}
     * @memberof InstanceInfoRead
     */
    stateDescription?: string;
    /**
     * Идентификатор продавца
     * @type {number}
     * @memberof InstanceInfoRead
     */
    sellerId?: number;
    /**
     * Возможно удалить данную сущность
     * @type {boolean}
     * @memberof InstanceInfoRead
     */
    deleteable: boolean;
    /**
     * Состоит из вариантов - задается только при создании, не изменяется
     * @type {boolean}
     * @memberof InstanceInfoRead
     */
    variantBased: boolean;
    /**
     * Идентификатор продукта
     * @type {number}
     * @memberof InstanceInfoRead
     */
    productId: number;
    /**
     * Краткое наименование продукта
     * @type {string}
     * @memberof InstanceInfoRead
     */
    productShortName: string;
    /**
     * Идентификатор варианта
     * @type {number}
     * @memberof InstanceInfoRead
     */
    variantId?: number;
    /**
     * Наименование варианта
     * @type {string}
     * @memberof InstanceInfoRead
     */
    variantName?: string;
    /**
     * Идентификатор поставщика
     * @type {number}
     * @memberof InstanceInfoRead
     */
    supplierId?: number;
    /**
     * Краткое наименование поставщика
     * @type {string}
     * @memberof InstanceInfoRead
     */
    supplierShortName?: string;
    /**
     * 
     * @type {InstanceStateCodeEnum}
     * @memberof InstanceInfoRead
     */
    stateCode: InstanceStateCodeEnum;
    /**
     * Коды доступных переходов для данного экземпляра
     * @type {Array<InstanceTransitionCodeEnum>}
     * @memberof InstanceInfoRead
     */
    availableTransitionCodes?: Array<InstanceTransitionCodeEnum>;
    /**
     * Дата окончания последнего обслуживания
     * @type {Date}
     * @memberof InstanceInfoRead
     */
    lastMaintenanceEndDate?: Date;
    /**
     * Краткое наименование продавца
     * @type {string}
     * @memberof InstanceInfoRead
     */
    sellerShortName?: string;
    /**
     * 
     * @type {RentIndicatorsObj}
     * @memberof InstanceInfoRead
     */
    rentIndicators: RentIndicatorsObj;
    /**
     * 
     * @type {TimetableStackCountersObj}
     * @memberof InstanceInfoRead
     */
    timetableStackCounters: TimetableStackCountersObj;
    /**
     * 
     * @type {WarehousePresenceCountersObj}
     * @memberof InstanceInfoRead
     */
    warehousePresenceCounters: WarehousePresenceCountersObj;
}
/**
 * 
 * @export
 * @interface InstanceInfoReadAllOf
 */
export interface InstanceInfoReadAllOf {
    /**
     * Возможно удалить данную сущность
     * @type {boolean}
     * @memberof InstanceInfoReadAllOf
     */
    deleteable: boolean;
    /**
     * Состоит из вариантов - задается только при создании, не изменяется
     * @type {boolean}
     * @memberof InstanceInfoReadAllOf
     */
    variantBased: boolean;
    /**
     * Идентификатор продукта
     * @type {number}
     * @memberof InstanceInfoReadAllOf
     */
    productId: number;
    /**
     * Краткое наименование продукта
     * @type {string}
     * @memberof InstanceInfoReadAllOf
     */
    productShortName: string;
    /**
     * Идентификатор варианта
     * @type {number}
     * @memberof InstanceInfoReadAllOf
     */
    variantId?: number;
    /**
     * Наименование варианта
     * @type {string}
     * @memberof InstanceInfoReadAllOf
     */
    variantName?: string;
    /**
     * Идентификатор поставщика
     * @type {number}
     * @memberof InstanceInfoReadAllOf
     */
    supplierId?: number;
    /**
     * Краткое наименование поставщика
     * @type {string}
     * @memberof InstanceInfoReadAllOf
     */
    supplierShortName?: string;
    /**
     * 
     * @type {InstanceStateCodeEnum}
     * @memberof InstanceInfoReadAllOf
     */
    stateCode: InstanceStateCodeEnum;
    /**
     * Коды доступных переходов для данного экземпляра
     * @type {Array<InstanceTransitionCodeEnum>}
     * @memberof InstanceInfoReadAllOf
     */
    availableTransitionCodes?: Array<InstanceTransitionCodeEnum>;
    /**
     * Дата окончания последнего обслуживания
     * @type {Date}
     * @memberof InstanceInfoReadAllOf
     */
    lastMaintenanceEndDate?: Date;
    /**
     * Краткое наименование продавца
     * @type {string}
     * @memberof InstanceInfoReadAllOf
     */
    sellerShortName?: string;
    /**
     * 
     * @type {RentIndicatorsObj}
     * @memberof InstanceInfoReadAllOf
     */
    rentIndicators: RentIndicatorsObj;
    /**
     * 
     * @type {TimetableStackCountersObj}
     * @memberof InstanceInfoReadAllOf
     */
    timetableStackCounters: TimetableStackCountersObj;
    /**
     * 
     * @type {WarehousePresenceCountersObj}
     * @memberof InstanceInfoReadAllOf
     */
    warehousePresenceCounters: WarehousePresenceCountersObj;
}
/**
 * Экземпляр: состояние при изменении
 * @export
 * @interface InstanceInfoUpdate
 */
export interface InstanceInfoUpdate extends InstanceInfoAbstractWriteCommons {
}
/**
 * Все поля сущности InstanceRecord, которые могут быть выгружены в список Excel. Финансовых полей нет
 * @export
 * @enum {string}
 */
export enum InstanceNotFinancialFieldsTypeCode {
    NameOrCode = 'nameOrCode',
    Barcode = 'barcode',
    InventoryNumber = 'inventoryNumber',
    VariantName = 'variantName',
    StateCode = 'stateCode',
    SubrentedInstanceCount = 'subrentedInstanceCount',
    RentedInstanceCount = 'rentedInstanceCount',
    WarehouseInstanceCount = 'warehouseInstanceCount',
    SupplierShortName = 'supplierShortName',
    SellerShortName = 'sellerShortName',
    LastMaintenanceEndDate = 'lastMaintenanceEndDate',
    LastActivityDate = 'lastActivityDate'
}

/**
 * Списочное представление экземпляра
 * @export
 * @interface InstanceRecord
 */
export interface InstanceRecord {
    /**
     * Идентификатор
     * @type {number}
     * @memberof InstanceRecord
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof InstanceRecord
     */
    businessVersion: number;
    /**
     * Ссылка: Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof InstanceRecord
     */
    businessAccountId: number;
    /**
     * Наименование
     * @type {string}
     * @memberof InstanceRecord
     */
    nameOrCode: string;
    /**
     * Штриховой код
     * @type {string}
     * @memberof InstanceRecord
     */
    barcode?: string;
    /**
     * Официальный инвентарный номер
     * @type {string}
     * @memberof InstanceRecord
     */
    inventoryNumber?: string;
    /**
     * Возможно удалить данную сущность
     * @type {boolean}
     * @memberof InstanceRecord
     */
    deleteable: boolean;
    /**
     * Состоит из вариантов - задается только при создании, не изменяется
     * @type {boolean}
     * @memberof InstanceRecord
     */
    variantBased: boolean;
    /**
     * Идентификатор продукта
     * @type {number}
     * @memberof InstanceRecord
     */
    productId: number;
    /**
     * Короткое наименование продукта
     * @type {string}
     * @memberof InstanceRecord
     */
    productShortName: string;
    /**
     * Идентификатор варианта
     * @type {number}
     * @memberof InstanceRecord
     */
    variantId?: number;
    /**
     * Наименование варианта
     * @type {string}
     * @memberof InstanceRecord
     */
    variantName?: string;
    /**
     * Идентификатор поставщика
     * @type {number}
     * @memberof InstanceRecord
     */
    supplierId?: number;
    /**
     * Краткое наименование поставщика
     * @type {string}
     * @memberof InstanceRecord
     */
    supplierShortName?: string;
    /**
     * 
     * @type {ProblemAndWarningRentIndicatorsRecordObj}
     * @memberof InstanceRecord
     */
    problemsAndWarnings: ProblemAndWarningRentIndicatorsRecordObj;
    /**
     * 
     * @type {InstanceStateCodeEnum}
     * @memberof InstanceRecord
     */
    stateCode: InstanceStateCodeEnum;
    /**
     * Чужой экземпляр (поставляется в компанию по субаренде)
     * @type {boolean}
     * @memberof InstanceRecord
     */
    subrentSupply: boolean;
    /**
     * Коды доступных переходов для данного экземпляра
     * @type {Array<InstanceTransitionCodeEnum>}
     * @memberof InstanceRecord
     */
    availableTransitionCodes?: Array<InstanceTransitionCodeEnum>;
    /**
     * Дата окончания последнего обслуживания
     * @type {Date}
     * @memberof InstanceRecord
     */
    lastMaintenanceEndDate?: Date;
    /**
     * Идентификатор продавца
     * @type {number}
     * @memberof InstanceRecord
     */
    sellerId?: number;
    /**
     * Краткое наименование продавца
     * @type {string}
     * @memberof InstanceRecord
     */
    sellerShortName?: string;
    /**
     * Версия карты состояний
     * @type {number}
     * @memberof InstanceRecord
     */
    timetableVersion: number;
    /**
     * 
     * @type {number}
     * @memberof InstanceRecord
     */
    attachmentsCount?: number;
    /**
     * Карта состояний
     * @type {TimetableList}
     * @memberof InstanceRecord
     */
    stackMapList?: TimetableList;
    /**
     * 
     * @type {WarehousePresenceCountersObj}
     * @memberof InstanceRecord
     */
    warehousePresenceCounters: WarehousePresenceCountersObj;
    /**
     * Дата и время последней активности (измение сущности либо операция над связанными обязательствами)
     * @type {Date}
     * @memberof InstanceRecord
     */
    lastActivityDate: Date;
}
/**
 * 
 * @export
 * @interface InstanceRecordAllOf
 */
export interface InstanceRecordAllOf {
    /**
     * Наименование
     * @type {string}
     * @memberof InstanceRecordAllOf
     */
    nameOrCode: string;
    /**
     * Штриховой код
     * @type {string}
     * @memberof InstanceRecordAllOf
     */
    barcode?: string;
    /**
     * Официальный инвентарный номер
     * @type {string}
     * @memberof InstanceRecordAllOf
     */
    inventoryNumber?: string;
    /**
     * Возможно удалить данную сущность
     * @type {boolean}
     * @memberof InstanceRecordAllOf
     */
    deleteable: boolean;
    /**
     * Состоит из вариантов - задается только при создании, не изменяется
     * @type {boolean}
     * @memberof InstanceRecordAllOf
     */
    variantBased: boolean;
    /**
     * Идентификатор продукта
     * @type {number}
     * @memberof InstanceRecordAllOf
     */
    productId: number;
    /**
     * Короткое наименование продукта
     * @type {string}
     * @memberof InstanceRecordAllOf
     */
    productShortName: string;
    /**
     * Идентификатор варианта
     * @type {number}
     * @memberof InstanceRecordAllOf
     */
    variantId?: number;
    /**
     * Наименование варианта
     * @type {string}
     * @memberof InstanceRecordAllOf
     */
    variantName?: string;
    /**
     * Идентификатор поставщика
     * @type {number}
     * @memberof InstanceRecordAllOf
     */
    supplierId?: number;
    /**
     * Краткое наименование поставщика
     * @type {string}
     * @memberof InstanceRecordAllOf
     */
    supplierShortName?: string;
    /**
     * 
     * @type {ProblemAndWarningRentIndicatorsRecordObj}
     * @memberof InstanceRecordAllOf
     */
    problemsAndWarnings: ProblemAndWarningRentIndicatorsRecordObj;
    /**
     * 
     * @type {InstanceStateCodeEnum}
     * @memberof InstanceRecordAllOf
     */
    stateCode: InstanceStateCodeEnum;
    /**
     * Чужой экземпляр (поставляется в компанию по субаренде)
     * @type {boolean}
     * @memberof InstanceRecordAllOf
     */
    subrentSupply: boolean;
    /**
     * Коды доступных переходов для данного экземпляра
     * @type {Array<InstanceTransitionCodeEnum>}
     * @memberof InstanceRecordAllOf
     */
    availableTransitionCodes?: Array<InstanceTransitionCodeEnum>;
    /**
     * Дата окончания последнего обслуживания
     * @type {Date}
     * @memberof InstanceRecordAllOf
     */
    lastMaintenanceEndDate?: Date;
    /**
     * Идентификатор продавца
     * @type {number}
     * @memberof InstanceRecordAllOf
     */
    sellerId?: number;
    /**
     * Краткое наименование продавца
     * @type {string}
     * @memberof InstanceRecordAllOf
     */
    sellerShortName?: string;
    /**
     * Версия карты состояний
     * @type {number}
     * @memberof InstanceRecordAllOf
     */
    timetableVersion: number;
    /**
     * 
     * @type {number}
     * @memberof InstanceRecordAllOf
     */
    attachmentsCount?: number;
    /**
     * Карта состояний
     * @type {TimetableList}
     * @memberof InstanceRecordAllOf
     */
    stackMapList?: TimetableList;
    /**
     * 
     * @type {WarehousePresenceCountersObj}
     * @memberof InstanceRecordAllOf
     */
    warehousePresenceCounters: WarehousePresenceCountersObj;
    /**
     * Дата и время последней активности (измение сущности либо операция над связанными обязательствами)
     * @type {Date}
     * @memberof InstanceRecordAllOf
     */
    lastActivityDate: Date;
}
/**
 * Список записей экземпляров
 * @export
 * @interface InstanceRecordList
 */
export interface InstanceRecordList {
    /**
     * 
     * @type {ListAttributesInfo}
     * @memberof InstanceRecordList
     */
    listAttributes: ListAttributesInfo;
    /**
     * 
     * @type {Array<InstanceRecord>}
     * @memberof InstanceRecordList
     */
    records: Array<InstanceRecord>;
    /**
     * 
     * @type {Array<IdDatePairObj>}
     * @memberof InstanceRecordList
     */
    elementsDelayedReturnDates?: Array<IdDatePairObj>;
}
/**
 * 
 * @export
 * @interface InstanceRecordListAllOf
 */
export interface InstanceRecordListAllOf {
    /**
     * 
     * @type {Array<IdDatePairObj>}
     * @memberof InstanceRecordListAllOf
     */
    elementsDelayedReturnDates?: Array<IdDatePairObj>;
    /**
     * 
     * @type {Array<InstanceRecord>}
     * @memberof InstanceRecordListAllOf
     */
    records: Array<InstanceRecord>;
}
/**
 * 
 * @export
 * @interface InstanceRentElement
 */
export interface InstanceRentElement {
    /**
     * 
     * @type {EntityTypeCodeEnum}
     * @memberof InstanceRentElement
     */
    entityType?: EntityTypeCodeEnum;
    /**
     * 
     * @type {number}
     * @memberof InstanceRentElement
     */
    entityId?: number;
    /**
     * 
     * @type {string}
     * @memberof InstanceRentElement
     */
    entityName?: string;
    /**
     * 
     * @type {Array<RentElementRecord>}
     * @memberof InstanceRentElement
     */
    rentElements?: Array<RentElementRecord>;
}
/**
 * Код складского статуса экземпляра)
 * @export
 * @enum {string}
 */
export enum InstanceStateCodeEnum {
    NEW = 'NEW',
    ACTIVE = 'ACTIVE',
    UNDERMAINTENANCE = 'UNDER_MAINTENANCE',
    DECOMMISSIONED = 'DECOMMISSIONED',
    SUPPLIERACTIVE = 'SUPPLIER_ACTIVE',
    SUPPLIERDECOMMISSIONED = 'SUPPLIER_DECOMMISSIONED'
}

/**
 * Код типа учета продукта
 * @export
 * @enum {string}
 */
export enum InstanceTrackingTypeCodeEnum {
    BULK = 'PRODUCT_BULK',
    VARIANTBULK = 'PRODUCT_VARIANT_BULK',
    INSTANCETRACKED = 'PRODUCT_INSTANCE_TRACKED',
    VARIANTINSTANCETRACKED = 'PRODUCT_VARIANT_INSTANCE_TRACKED'
}

/**
 * Возможные коды для переходов экземпляра
 * @export
 * @enum {string}
 */
export enum InstanceTransitionCodeEnum {
    ACTIVATE = 'ACTIVATE',
    SENDUNDERMAINTENANCE = 'SEND_UNDER_MAINTENANCE',
    DECOMMISSION = 'DECOMMISSION',
    SUPPLIERACTIVATE = 'SUPPLIER_ACTIVATE',
    SUPPLIERDECOMMISSION = 'SUPPLIER_DECOMMISSION'
}

/**
 * Информация о экземпляре и его существующих обязательствах
 * @export
 * @interface InstanceWithRentElements
 */
export interface InstanceWithRentElements {
    /**
     * 
     * @type {InstanceRecord}
     * @memberof InstanceWithRentElements
     */
    info: InstanceRecord;
    /**
     * 
     * @type {Array<RentElementRecord>}
     * @memberof InstanceWithRentElements
     */
    rentElements?: Array<RentElementRecord>;
}
/**
 * 
 * @export
 * @interface InterfaceLocation
 */
export interface InterfaceLocation {
    /**
     * 
     * @type {InterfaceLocationEnumCode}
     * @memberof InterfaceLocation
     */
    interfaceLocationCode: InterfaceLocationEnumCode;
    /**
     * 
     * @type {Array<number>}
     * @memberof InterfaceLocation
     */
    ru: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof InterfaceLocation
     */
    en: Array<number>;
}
/**
 * Поддерживаемые локации в интрефейсе
 * @export
 * @enum {string}
 */
export enum InterfaceLocationEnumCode {
    INVENTORY = 'BA_INVENTORY',
    INVENTORYPRODUCT = 'BA_INVENTORY_PRODUCT',
    SYSTEMOPTIONSPAYMENTMETHODS = 'BA_SYSTEM_OPTIONS_PAYMENT_METHODS',
    ORDERSORDERCARD = 'BA_ORDERS_ORDER_CARD',
    RENTOFFERSOFFERCARD = 'BA_RENT_OFFERS_OFFER_CARD',
    CALENDARAVAILABILITYSCHEDULE = 'BA_CALENDAR_AVAILABILITY_SCHEDULE'
}

/**
 * 
 * @export
 * @interface InventoryMovementAbstractCommons
 */
export interface InventoryMovementAbstractCommons {
    /**
     * Идентификатор
     * @type {number}
     * @memberof InventoryMovementAbstractCommons
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof InventoryMovementAbstractCommons
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof InventoryMovementAbstractCommons
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof InventoryMovementAbstractCommons
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof InventoryMovementAbstractCommons
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof InventoryMovementAbstractCommons
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof InventoryMovementAbstractCommons
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof InventoryMovementAbstractCommons
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof InventoryMovementAbstractCommons
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof InventoryMovementAbstractCommons
     */
    businessAccountId?: number;
    /**
     * Идентификатор связанного контрагента
     * @type {number}
     * @memberof InventoryMovementAbstractCommons
     */
    counterpartyId?: number;
    /**
     * Дата события движения инвентаря
     * @type {Date}
     * @memberof InventoryMovementAbstractCommons
     */
    eventDate: Date;
    /**
     * Идентификатор связанного продукта
     * @type {number}
     * @memberof InventoryMovementAbstractCommons
     */
    productId: number;
    /**
     * Идентификатор связанного варианта
     * @type {number}
     * @memberof InventoryMovementAbstractCommons
     */
    variantId?: number;
    /**
     * Идентификаторы связанных экземпляров
     * @type {Array<number>}
     * @memberof InventoryMovementAbstractCommons
     */
    instanceIds?: Array<number>;
    /**
     * Идентификатор связанного проекта
     * @type {number}
     * @memberof InventoryMovementAbstractCommons
     */
    projectId?: number;
    /**
     * Идентификатор связанного обязательства
     * @type {number}
     * @memberof InventoryMovementAbstractCommons
     */
    rentElementId?: number;
    /**
     * Описание
     * @type {string}
     * @memberof InventoryMovementAbstractCommons
     */
    description?: string;
    /**
     * Флаг отмечающий выведено ли из строя оборудование
     * @type {boolean}
     * @memberof InventoryMovementAbstractCommons
     */
    outOfOrder?: boolean;
    /**
     * Флаг отмечающий ответственность арендатора
     * @type {boolean}
     * @memberof InventoryMovementAbstractCommons
     */
    causedByRenter?: boolean;
    /**
     * Цена экземпляра
     * @type {number}
     * @memberof InventoryMovementAbstractCommons
     */
    pricePerInstance?: number;
    /**
     * Количество экземпляров
     * @type {number}
     * @memberof InventoryMovementAbstractCommons
     */
    instanceCount: number;
    /**
     * Итоговая сумма
     * @type {number}
     * @memberof InventoryMovementAbstractCommons
     */
    totalSum?: number;
    /**
     * 
     * @type {InventoryMovementEventTypeCodeEnum}
     * @memberof InventoryMovementAbstractCommons
     */
    eventType: InventoryMovementEventTypeCodeEnum;
}
/**
 * 
 * @export
 * @interface InventoryMovementAbstractCommonsAllOf
 */
export interface InventoryMovementAbstractCommonsAllOf {
    /**
     * Идентификатор связанного контрагента
     * @type {number}
     * @memberof InventoryMovementAbstractCommonsAllOf
     */
    counterpartyId?: number;
    /**
     * Дата события движения инвентаря
     * @type {Date}
     * @memberof InventoryMovementAbstractCommonsAllOf
     */
    eventDate: Date;
    /**
     * Идентификатор связанного продукта
     * @type {number}
     * @memberof InventoryMovementAbstractCommonsAllOf
     */
    productId: number;
    /**
     * Идентификатор связанного варианта
     * @type {number}
     * @memberof InventoryMovementAbstractCommonsAllOf
     */
    variantId?: number;
    /**
     * Идентификаторы связанных экземпляров
     * @type {Array<number>}
     * @memberof InventoryMovementAbstractCommonsAllOf
     */
    instanceIds?: Array<number>;
    /**
     * Идентификатор связанного проекта
     * @type {number}
     * @memberof InventoryMovementAbstractCommonsAllOf
     */
    projectId?: number;
    /**
     * Идентификатор связанного обязательства
     * @type {number}
     * @memberof InventoryMovementAbstractCommonsAllOf
     */
    rentElementId?: number;
    /**
     * Описание
     * @type {string}
     * @memberof InventoryMovementAbstractCommonsAllOf
     */
    description?: string;
    /**
     * Флаг отмечающий выведено ли из строя оборудование
     * @type {boolean}
     * @memberof InventoryMovementAbstractCommonsAllOf
     */
    outOfOrder?: boolean;
    /**
     * Флаг отмечающий ответственность арендатора
     * @type {boolean}
     * @memberof InventoryMovementAbstractCommonsAllOf
     */
    causedByRenter?: boolean;
    /**
     * Цена экземпляра
     * @type {number}
     * @memberof InventoryMovementAbstractCommonsAllOf
     */
    pricePerInstance?: number;
    /**
     * Количество экземпляров
     * @type {number}
     * @memberof InventoryMovementAbstractCommonsAllOf
     */
    instanceCount: number;
    /**
     * Итоговая сумма
     * @type {number}
     * @memberof InventoryMovementAbstractCommonsAllOf
     */
    totalSum?: number;
    /**
     * 
     * @type {InventoryMovementEventTypeCodeEnum}
     * @memberof InventoryMovementAbstractCommonsAllOf
     */
    eventType: InventoryMovementEventTypeCodeEnum;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum InventoryMovementEventTypeCodeEnum {
    STOCKTAKING = 'STOCKTAKING',
    ACQUISITION = 'ACQUISITION',
    DAMAGE = 'DAMAGE',
    LOSS = 'LOSS',
    MAINTENANCE = 'MAINTENANCE',
    MAINTENANCECOMPLETION = 'MAINTENANCE_COMPLETION',
    WRITEOFF = 'WRITE_OFF',
    SALE = 'SALE'
}

/**
 * Финансовые поля сущности InventoryMovementRecord, которые могут быть выгружены в список Excel
 * @export
 * @enum {string}
 */
export enum InventoryMovementFinancialFieldsTypeCode {
    PricePerInstance = 'pricePerInstance',
    TotalSum = 'totalSum'
}

/**
 * 
 * @export
 * @interface InventoryMovementInfoCreate
 */
export interface InventoryMovementInfoCreate extends InventoryMovementAbstractCommons {
}
/**
 * 
 * @export
 * @interface InventoryMovementInfoRead
 */
export interface InventoryMovementInfoRead {
    /**
     * Идентификатор
     * @type {number}
     * @memberof InventoryMovementInfoRead
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof InventoryMovementInfoRead
     */
    businessVersion: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof InventoryMovementInfoRead
     */
    creationAuthorId: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof InventoryMovementInfoRead
     */
    creationAuthorFullName: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof InventoryMovementInfoRead
     */
    creationDate: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof InventoryMovementInfoRead
     */
    lastUpdateAuthorId: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof InventoryMovementInfoRead
     */
    lastUpdateAuthorFullName: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof InventoryMovementInfoRead
     */
    lastUpdateDate: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof InventoryMovementInfoRead
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof InventoryMovementInfoRead
     */
    businessAccountId: number;
    /**
     * Идентификатор связанного контрагента
     * @type {number}
     * @memberof InventoryMovementInfoRead
     */
    counterpartyId?: number;
    /**
     * Дата события движения инвентаря
     * @type {Date}
     * @memberof InventoryMovementInfoRead
     */
    eventDate: Date;
    /**
     * Идентификатор связанного продукта
     * @type {number}
     * @memberof InventoryMovementInfoRead
     */
    productId: number;
    /**
     * Идентификатор связанного варианта
     * @type {number}
     * @memberof InventoryMovementInfoRead
     */
    variantId?: number;
    /**
     * Идентификаторы связанных экземпляров
     * @type {Array<number>}
     * @memberof InventoryMovementInfoRead
     */
    instanceIds?: Array<number>;
    /**
     * Идентификатор связанного проекта
     * @type {number}
     * @memberof InventoryMovementInfoRead
     */
    projectId?: number;
    /**
     * Идентификатор связанного обязательства
     * @type {number}
     * @memberof InventoryMovementInfoRead
     */
    rentElementId?: number;
    /**
     * Описание
     * @type {string}
     * @memberof InventoryMovementInfoRead
     */
    description?: string;
    /**
     * Флаг отмечающий выведено ли из строя оборудование
     * @type {boolean}
     * @memberof InventoryMovementInfoRead
     */
    outOfOrder?: boolean;
    /**
     * Флаг отмечающий ответственность арендатора
     * @type {boolean}
     * @memberof InventoryMovementInfoRead
     */
    causedByRenter?: boolean;
    /**
     * Цена экземпляра
     * @type {number}
     * @memberof InventoryMovementInfoRead
     */
    pricePerInstance?: number;
    /**
     * Количество экземпляров
     * @type {number}
     * @memberof InventoryMovementInfoRead
     */
    instanceCount: number;
    /**
     * Итоговая сумма
     * @type {number}
     * @memberof InventoryMovementInfoRead
     */
    totalSum?: number;
    /**
     * 
     * @type {InventoryMovementEventTypeCodeEnum}
     * @memberof InventoryMovementInfoRead
     */
    eventType: InventoryMovementEventTypeCodeEnum;
    /**
     * 
     * @type {AttachmentRecordList}
     * @memberof InventoryMovementInfoRead
     */
    attachments?: AttachmentRecordList;
    /**
     * 
     * @type {string}
     * @memberof InventoryMovementInfoRead
     */
    productShortName: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryMovementInfoRead
     */
    variantName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InventoryMovementInfoRead
     */
    instanceNameOrCodes?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof InventoryMovementInfoRead
     */
    counterpartyShortName?: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryMovementInfoRead
     */
    projectShortName?: string;
    /**
     * Номер связанного обязательства в рамке активности
     * @type {number}
     * @memberof InventoryMovementInfoRead
     */
    rentElementNumberInActivityFrame?: number;
    /**
     * Список записей экземпляров (используется только для продуктов с отслеживаемыми экземплярами)
     * @type {Array<InstanceRecord>}
     * @memberof InventoryMovementInfoRead
     */
    instances?: Array<InstanceRecord>;
}
/**
 * 
 * @export
 * @interface InventoryMovementInfoReadAllOf
 */
export interface InventoryMovementInfoReadAllOf {
    /**
     * 
     * @type {AttachmentRecordList}
     * @memberof InventoryMovementInfoReadAllOf
     */
    attachments?: AttachmentRecordList;
    /**
     * 
     * @type {string}
     * @memberof InventoryMovementInfoReadAllOf
     */
    productShortName: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryMovementInfoReadAllOf
     */
    variantName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InventoryMovementInfoReadAllOf
     */
    instanceNameOrCodes?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof InventoryMovementInfoReadAllOf
     */
    counterpartyShortName?: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryMovementInfoReadAllOf
     */
    projectShortName?: string;
    /**
     * Номер связанного обязательства в рамке активности
     * @type {number}
     * @memberof InventoryMovementInfoReadAllOf
     */
    rentElementNumberInActivityFrame?: number;
    /**
     * Список записей экземпляров (используется только для продуктов с отслеживаемыми экземплярами)
     * @type {Array<InstanceRecord>}
     * @memberof InventoryMovementInfoReadAllOf
     */
    instances?: Array<InstanceRecord>;
}
/**
 * 
 * @export
 * @interface InventoryMovementInfoUpdate
 */
export interface InventoryMovementInfoUpdate extends InventoryMovementAbstractCommons {
}
/**
 * Не финансовые поля сущности InventoryMovementRecord, которые могут быть выгружены в список Excel
 * @export
 * @enum {string}
 */
export enum InventoryMovementNotFinancialFieldsTypeCode {
    ProductShortName = 'productShortName',
    VariantName = 'variantName',
    InstanceNameOrCode = 'instanceNameOrCode',
    EventType = 'eventType',
    InstanceCount = 'instanceCount',
    AttachmentsCount = 'attachmentsCount',
    CounterpartyShortName = 'counterpartyShortName',
    ProjectShortName = 'projectShortName',
    LastUpdateAuthorShortName = 'lastUpdateAuthorShortName',
    EventDate = 'eventDate'
}

/**
 * Списочное представление движений инвентаря
 * @export
 * @interface InventoryMovementRecord
 */
export interface InventoryMovementRecord {
    /**
     * Идентификатор
     * @type {number}
     * @memberof InventoryMovementRecord
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof InventoryMovementRecord
     */
    businessVersion: number;
    /**
     * Ссылка: Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof InventoryMovementRecord
     */
    businessAccountId: number;
    /**
     * 
     * @type {number}
     * @memberof InventoryMovementRecord
     */
    productId: number;
    /**
     * 
     * @type {string}
     * @memberof InventoryMovementRecord
     */
    productShortName?: string;
    /**
     * 
     * @type {number}
     * @memberof InventoryMovementRecord
     */
    variantId?: number;
    /**
     * 
     * @type {string}
     * @memberof InventoryMovementRecord
     */
    variantName?: string;
    /**
     * 
     * @type {Array<InstanceIdsWithNamesOrCodes>}
     * @memberof InventoryMovementRecord
     */
    instances?: Array<InstanceIdsWithNamesOrCodes>;
    /**
     * 
     * @type {InventoryMovementEventTypeCodeEnum}
     * @memberof InventoryMovementRecord
     */
    eventType: InventoryMovementEventTypeCodeEnum;
    /**
     * 
     * @type {Date}
     * @memberof InventoryMovementRecord
     */
    eventDate: Date;
    /**
     * 
     * @type {number}
     * @memberof InventoryMovementRecord
     */
    lastUpdateAuthorId: number;
    /**
     * 
     * @type {string}
     * @memberof InventoryMovementRecord
     */
    lastUpdateAuthorShortName: string;
    /**
     * 
     * @type {boolean}
     * @memberof InventoryMovementRecord
     */
    outOfOrder?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InventoryMovementRecord
     */
    causedByRenter?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InventoryMovementRecord
     */
    counterpartyId?: number;
    /**
     * 
     * @type {string}
     * @memberof InventoryMovementRecord
     */
    counterpartyShortName?: string;
    /**
     * 
     * @type {number}
     * @memberof InventoryMovementRecord
     */
    projectId?: number;
    /**
     * Идентификатор связанного обязательства
     * @type {number}
     * @memberof InventoryMovementRecord
     */
    rentElementId?: number;
    /**
     * Номер связанного обязательства в рамке активности
     * @type {number}
     * @memberof InventoryMovementRecord
     */
    rentElementNumberInActivityFrame?: number;
    /**
     * 
     * @type {string}
     * @memberof InventoryMovementRecord
     */
    projectShortName?: string;
    /**
     * 
     * @type {number}
     * @memberof InventoryMovementRecord
     */
    pricePerInstance?: number;
    /**
     * 
     * @type {number}
     * @memberof InventoryMovementRecord
     */
    instanceCount: number;
    /**
     * 
     * @type {number}
     * @memberof InventoryMovementRecord
     */
    totalSum?: number;
    /**
     * 
     * @type {number}
     * @memberof InventoryMovementRecord
     */
    attachmentsCount?: number;
}
/**
 * 
 * @export
 * @interface InventoryMovementRecordAllOf
 */
export interface InventoryMovementRecordAllOf {
    /**
     * 
     * @type {number}
     * @memberof InventoryMovementRecordAllOf
     */
    productId: number;
    /**
     * 
     * @type {string}
     * @memberof InventoryMovementRecordAllOf
     */
    productShortName?: string;
    /**
     * 
     * @type {number}
     * @memberof InventoryMovementRecordAllOf
     */
    variantId?: number;
    /**
     * 
     * @type {string}
     * @memberof InventoryMovementRecordAllOf
     */
    variantName?: string;
    /**
     * 
     * @type {Array<InstanceIdsWithNamesOrCodes>}
     * @memberof InventoryMovementRecordAllOf
     */
    instances?: Array<InstanceIdsWithNamesOrCodes>;
    /**
     * 
     * @type {InventoryMovementEventTypeCodeEnum}
     * @memberof InventoryMovementRecordAllOf
     */
    eventType: InventoryMovementEventTypeCodeEnum;
    /**
     * 
     * @type {Date}
     * @memberof InventoryMovementRecordAllOf
     */
    eventDate: Date;
    /**
     * 
     * @type {number}
     * @memberof InventoryMovementRecordAllOf
     */
    lastUpdateAuthorId: number;
    /**
     * 
     * @type {string}
     * @memberof InventoryMovementRecordAllOf
     */
    lastUpdateAuthorShortName: string;
    /**
     * 
     * @type {boolean}
     * @memberof InventoryMovementRecordAllOf
     */
    outOfOrder?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InventoryMovementRecordAllOf
     */
    causedByRenter?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InventoryMovementRecordAllOf
     */
    counterpartyId?: number;
    /**
     * 
     * @type {string}
     * @memberof InventoryMovementRecordAllOf
     */
    counterpartyShortName?: string;
    /**
     * 
     * @type {number}
     * @memberof InventoryMovementRecordAllOf
     */
    projectId?: number;
    /**
     * Идентификатор связанного обязательства
     * @type {number}
     * @memberof InventoryMovementRecordAllOf
     */
    rentElementId?: number;
    /**
     * Номер связанного обязательства в рамке активности
     * @type {number}
     * @memberof InventoryMovementRecordAllOf
     */
    rentElementNumberInActivityFrame?: number;
    /**
     * 
     * @type {string}
     * @memberof InventoryMovementRecordAllOf
     */
    projectShortName?: string;
    /**
     * 
     * @type {number}
     * @memberof InventoryMovementRecordAllOf
     */
    pricePerInstance?: number;
    /**
     * 
     * @type {number}
     * @memberof InventoryMovementRecordAllOf
     */
    instanceCount: number;
    /**
     * 
     * @type {number}
     * @memberof InventoryMovementRecordAllOf
     */
    totalSum?: number;
    /**
     * 
     * @type {number}
     * @memberof InventoryMovementRecordAllOf
     */
    attachmentsCount?: number;
}
/**
 * Список записей движений инвентаря
 * @export
 * @interface InventoryMovementRecordList
 */
export interface InventoryMovementRecordList {
    /**
     * 
     * @type {ListAttributesInfo}
     * @memberof InventoryMovementRecordList
     */
    listAttributes: ListAttributesInfo;
    /**
     * 
     * @type {Array<InventoryMovementRecord>}
     * @memberof InventoryMovementRecordList
     */
    records: Array<InventoryMovementRecord>;
}
/**
 * 
 * @export
 * @interface InventoryMovementRecordListAllOf
 */
export interface InventoryMovementRecordListAllOf {
    /**
     * 
     * @type {Array<InventoryMovementRecord>}
     * @memberof InventoryMovementRecordListAllOf
     */
    records: Array<InventoryMovementRecord>;
}
/**
 * Финансовые поля сущности KitRecord, которые могут быть выгружены в список Excel
 * @export
 * @enum {string}
 */
export enum KitFinancialFieldsTypeCode {
    TotalDiscount = 'totalDiscount',
    FinalTotalPrice = 'finalTotalPrice'
}

/**
 * Набор: абстрактное общее состояние для всех операций
 * @export
 * @interface KitInfoAbstractCommons
 */
export interface KitInfoAbstractCommons {
    /**
     * Идентификатор
     * @type {number}
     * @memberof KitInfoAbstractCommons
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof KitInfoAbstractCommons
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof KitInfoAbstractCommons
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof KitInfoAbstractCommons
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof KitInfoAbstractCommons
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof KitInfoAbstractCommons
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof KitInfoAbstractCommons
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof KitInfoAbstractCommons
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof KitInfoAbstractCommons
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof KitInfoAbstractCommons
     */
    businessAccountId?: number;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof KitInfoAbstractCommons
     */
    shortName: string;
    /**
     * Полное наименование
     * @type {string}
     * @memberof KitInfoAbstractCommons
     */
    fullName?: string;
    /**
     * Артикул
     * @type {string}
     * @memberof KitInfoAbstractCommons
     */
    externalCode?: string;
    /**
     * Штриховой код
     * @type {string}
     * @memberof KitInfoAbstractCommons
     */
    barcode?: string;
    /**
     * Информация о главном изображении
     * @type {ImageObj}
     * @memberof KitInfoAbstractCommons
     */
    mainImage?: ImageObj;
    /**
     * Информация об изображениях помимо главного, порядок соответствует требуемому порядку отображения
     * @type {Array<ImageObj>}
     * @memberof KitInfoAbstractCommons
     */
    images?: Array<ImageObj>;
    /**
     * Включена ли возможность выбора основного компанента для данного набора
     * @type {boolean}
     * @memberof KitInfoAbstractCommons
     */
    mainMemberOptionEnabled?: boolean;
    /**
     * Описание
     * @type {string}
     * @memberof KitInfoAbstractCommons
     */
    description?: string;
    /**
     * 
     * @type {PricingSchemeModeCodeEnum}
     * @memberof KitInfoAbstractCommons
     */
    pricingSchemeMode?: PricingSchemeModeCodeEnum;
}
/**
 * 
 * @export
 * @interface KitInfoAbstractCommonsAllOf
 */
export interface KitInfoAbstractCommonsAllOf {
    /**
     * Короткое наименование
     * @type {string}
     * @memberof KitInfoAbstractCommonsAllOf
     */
    shortName: string;
    /**
     * Полное наименование
     * @type {string}
     * @memberof KitInfoAbstractCommonsAllOf
     */
    fullName?: string;
    /**
     * Артикул
     * @type {string}
     * @memberof KitInfoAbstractCommonsAllOf
     */
    externalCode?: string;
    /**
     * Штриховой код
     * @type {string}
     * @memberof KitInfoAbstractCommonsAllOf
     */
    barcode?: string;
    /**
     * Информация о главном изображении
     * @type {ImageObj}
     * @memberof KitInfoAbstractCommonsAllOf
     */
    mainImage?: ImageObj;
    /**
     * Информация об изображениях помимо главного, порядок соответствует требуемому порядку отображения
     * @type {Array<ImageObj>}
     * @memberof KitInfoAbstractCommonsAllOf
     */
    images?: Array<ImageObj>;
    /**
     * Включена ли возможность выбора основного компанента для данного набора
     * @type {boolean}
     * @memberof KitInfoAbstractCommonsAllOf
     */
    mainMemberOptionEnabled?: boolean;
    /**
     * Описание
     * @type {string}
     * @memberof KitInfoAbstractCommonsAllOf
     */
    description?: string;
    /**
     * 
     * @type {PricingSchemeModeCodeEnum}
     * @memberof KitInfoAbstractCommonsAllOf
     */
    pricingSchemeMode?: PricingSchemeModeCodeEnum;
}
/**
 * Набор: абстрактное общее состояние при создании и изменении
 * @export
 * @interface KitInfoAbstractWriteCommons
 */
export interface KitInfoAbstractWriteCommons {
    /**
     * Идентификатор
     * @type {number}
     * @memberof KitInfoAbstractWriteCommons
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof KitInfoAbstractWriteCommons
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof KitInfoAbstractWriteCommons
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof KitInfoAbstractWriteCommons
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof KitInfoAbstractWriteCommons
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof KitInfoAbstractWriteCommons
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof KitInfoAbstractWriteCommons
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof KitInfoAbstractWriteCommons
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof KitInfoAbstractWriteCommons
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof KitInfoAbstractWriteCommons
     */
    businessAccountId?: number;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof KitInfoAbstractWriteCommons
     */
    shortName: string;
    /**
     * Полное наименование
     * @type {string}
     * @memberof KitInfoAbstractWriteCommons
     */
    fullName?: string;
    /**
     * Артикул
     * @type {string}
     * @memberof KitInfoAbstractWriteCommons
     */
    externalCode?: string;
    /**
     * Штриховой код
     * @type {string}
     * @memberof KitInfoAbstractWriteCommons
     */
    barcode?: string;
    /**
     * Информация о главном изображении
     * @type {ImageObj}
     * @memberof KitInfoAbstractWriteCommons
     */
    mainImage?: ImageObj;
    /**
     * Информация об изображениях помимо главного, порядок соответствует требуемому порядку отображения
     * @type {Array<ImageObj>}
     * @memberof KitInfoAbstractWriteCommons
     */
    images?: Array<ImageObj>;
    /**
     * Включена ли возможность выбора основного компанента для данного набора
     * @type {boolean}
     * @memberof KitInfoAbstractWriteCommons
     */
    mainMemberOptionEnabled: boolean;
    /**
     * Описание
     * @type {string}
     * @memberof KitInfoAbstractWriteCommons
     */
    description?: string;
    /**
     * 
     * @type {PricingSchemeModeCodeEnum}
     * @memberof KitInfoAbstractWriteCommons
     */
    pricingSchemeMode?: PricingSchemeModeCodeEnum;
    /**
     * Идентификаторы категорий
     * @type {Array<number>}
     * @memberof KitInfoAbstractWriteCommons
     */
    categoryIds?: Array<number>;
    /**
     * Все кастомные поля данного продукта со значениями сгруппированные и упорядоченные
     * @type {Array<CustomFieldGroupObjWrite>}
     * @memberof KitInfoAbstractWriteCommons
     */
    customFieldGroups?: Array<CustomFieldGroupObjWrite>;
    /**
     * 
     * @type {Array<KitMemberObjWrite>}
     * @memberof KitInfoAbstractWriteCommons
     */
    members: Array<KitMemberObjWrite>;
    /**
     * Идентификатор ценовой схемы
     * @type {number}
     * @memberof KitInfoAbstractWriteCommons
     */
    pricingSchemeId?: number;
}
/**
 * 
 * @export
 * @interface KitInfoAbstractWriteCommonsAllOf
 */
export interface KitInfoAbstractWriteCommonsAllOf {
    /**
     * Идентификаторы категорий
     * @type {Array<number>}
     * @memberof KitInfoAbstractWriteCommonsAllOf
     */
    categoryIds?: Array<number>;
    /**
     * Все кастомные поля данного продукта со значениями сгруппированные и упорядоченные
     * @type {Array<CustomFieldGroupObjWrite>}
     * @memberof KitInfoAbstractWriteCommonsAllOf
     */
    customFieldGroups?: Array<CustomFieldGroupObjWrite>;
    /**
     * 
     * @type {Array<KitMemberObjWrite>}
     * @memberof KitInfoAbstractWriteCommonsAllOf
     */
    members: Array<KitMemberObjWrite>;
    /**
     * Идентификатор ценовой схемы
     * @type {number}
     * @memberof KitInfoAbstractWriteCommonsAllOf
     */
    pricingSchemeId?: number;
}
/**
 * Набор: состояние при создании
 * @export
 * @interface KitInfoCreate
 */
export interface KitInfoCreate {
    /**
     * Идентификатор
     * @type {number}
     * @memberof KitInfoCreate
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof KitInfoCreate
     */
    businessVersion: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof KitInfoCreate
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof KitInfoCreate
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof KitInfoCreate
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof KitInfoCreate
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof KitInfoCreate
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof KitInfoCreate
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof KitInfoCreate
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof KitInfoCreate
     */
    businessAccountId?: number;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof KitInfoCreate
     */
    shortName: string;
    /**
     * Полное наименование
     * @type {string}
     * @memberof KitInfoCreate
     */
    fullName?: string;
    /**
     * Артикул
     * @type {string}
     * @memberof KitInfoCreate
     */
    externalCode?: string;
    /**
     * Штриховой код
     * @type {string}
     * @memberof KitInfoCreate
     */
    barcode?: string;
    /**
     * Информация о главном изображении
     * @type {ImageObj}
     * @memberof KitInfoCreate
     */
    mainImage?: ImageObj;
    /**
     * Информация об изображениях помимо главного, порядок соответствует требуемому порядку отображения
     * @type {Array<ImageObj>}
     * @memberof KitInfoCreate
     */
    images?: Array<ImageObj>;
    /**
     * Включена ли возможность выбора основного компанента для данного набора
     * @type {boolean}
     * @memberof KitInfoCreate
     */
    mainMemberOptionEnabled: boolean;
    /**
     * Описание
     * @type {string}
     * @memberof KitInfoCreate
     */
    description?: string;
    /**
     * 
     * @type {PricingSchemeModeCodeEnum}
     * @memberof KitInfoCreate
     */
    pricingSchemeMode?: PricingSchemeModeCodeEnum;
    /**
     * Идентификаторы категорий
     * @type {Array<number>}
     * @memberof KitInfoCreate
     */
    categoryIds?: Array<number>;
    /**
     * Все кастомные поля данного продукта со значениями сгруппированные и упорядоченные
     * @type {Array<CustomFieldGroupObjWrite>}
     * @memberof KitInfoCreate
     */
    customFieldGroups?: Array<CustomFieldGroupObjWrite>;
    /**
     * 
     * @type {Array<KitMemberObjWrite>}
     * @memberof KitInfoCreate
     */
    members: Array<KitMemberObjWrite>;
    /**
     * Идентификатор ценовой схемы
     * @type {number}
     * @memberof KitInfoCreate
     */
    pricingSchemeId?: number;
    /**
     * 
     * @type {NomenclatureStateCodeEnum}
     * @memberof KitInfoCreate
     */
    stateCode: NomenclatureStateCodeEnum;
}
/**
 * 
 * @export
 * @interface KitInfoCreateAllOf
 */
export interface KitInfoCreateAllOf {
    /**
     * 
     * @type {NomenclatureStateCodeEnum}
     * @memberof KitInfoCreateAllOf
     */
    stateCode: NomenclatureStateCodeEnum;
}
/**
 * Набор: состояние при чтении
 * @export
 * @interface KitInfoRead
 */
export interface KitInfoRead {
    /**
     * Идентификатор
     * @type {number}
     * @memberof KitInfoRead
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof KitInfoRead
     */
    businessVersion: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof KitInfoRead
     */
    creationAuthorId: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof KitInfoRead
     */
    creationAuthorFullName: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof KitInfoRead
     */
    creationDate: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof KitInfoRead
     */
    lastUpdateAuthorId: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof KitInfoRead
     */
    lastUpdateAuthorFullName: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof KitInfoRead
     */
    lastUpdateDate: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof KitInfoRead
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof KitInfoRead
     */
    businessAccountId: number;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof KitInfoRead
     */
    shortName: string;
    /**
     * Полное наименование
     * @type {string}
     * @memberof KitInfoRead
     */
    fullName: string;
    /**
     * Артикул
     * @type {string}
     * @memberof KitInfoRead
     */
    externalCode?: string;
    /**
     * Штриховой код
     * @type {string}
     * @memberof KitInfoRead
     */
    barcode?: string;
    /**
     * Информация о главном изображении
     * @type {ImageObj}
     * @memberof KitInfoRead
     */
    mainImage?: ImageObj;
    /**
     * Информация об изображениях помимо главного, порядок соответствует требуемому порядку отображения
     * @type {Array<ImageObj>}
     * @memberof KitInfoRead
     */
    images?: Array<ImageObj>;
    /**
     * Включена ли возможность выбора основного компанента для данного набора
     * @type {boolean}
     * @memberof KitInfoRead
     */
    mainMemberOptionEnabled?: boolean;
    /**
     * Описание
     * @type {string}
     * @memberof KitInfoRead
     */
    description?: string;
    /**
     * 
     * @type {PricingSchemeModeCodeEnum}
     * @memberof KitInfoRead
     */
    pricingSchemeMode?: PricingSchemeModeCodeEnum;
    /**
     * Возможно удалить данную сущность
     * @type {boolean}
     * @memberof KitInfoRead
     */
    deleteable: boolean;
    /**
     * Итоговая скидка в процентах
     * @type {number}
     * @memberof KitInfoRead
     */
    totalDiscount: number;
    /**
     * Итоговая суммарная стоимость, коп
     * @type {number}
     * @memberof KitInfoRead
     */
    finalTotalPrice: number;
    /**
     * Количество наборов, которое можно собрать из текущего наличия компанентов в инвентаре
     * @type {number}
     * @memberof KitInfoRead
     */
    stockCount: number;
    /**
     * Количество компанентов
     * @type {number}
     * @memberof KitInfoRead
     */
    membersCount: number;
    /**
     * Количество экземпляров
     * @type {number}
     * @memberof KitInfoRead
     */
    instancesCount: number;
    /**
     * Категории (продуктовые)
     * @type {Array<SimpleEntityRecord>}
     * @memberof KitInfoRead
     */
    categories?: Array<SimpleEntityRecord>;
    /**
     * 
     * @type {NomenclatureStateCodeEnum}
     * @memberof KitInfoRead
     */
    stateCode: NomenclatureStateCodeEnum;
    /**
     * Коды доступных переходов для данного продукта и пользователя
     * @type {Array<SimpleTransitionCodeEnum>}
     * @memberof KitInfoRead
     */
    availableTransitionCodes?: Array<SimpleTransitionCodeEnum>;
    /**
     * Находится в архиве
     * @type {boolean}
     * @memberof KitInfoRead
     */
    archive: boolean;
    /**
     * Может ли быть отправлен в архив (может, когда не является активным)
     * @type {boolean}
     * @memberof KitInfoRead
     */
    archivable: boolean;
    /**
     * 
     * @type {Array<KitMemberObjRead>}
     * @memberof KitInfoRead
     */
    members: Array<KitMemberObjRead>;
    /**
     * 
     * @type {RentIndicatorsObj}
     * @memberof KitInfoRead
     */
    rentIndicators: RentIndicatorsObj;
    /**
     * Все кастомные поля данного продукта со значениями сгруппированные и упорядоченные
     * @type {Array<CustomFieldGroupObjRead>}
     * @memberof KitInfoRead
     */
    customFieldGroups?: Array<CustomFieldGroupObjRead>;
    /**
     * Число доступных для аренды наборов. на момент запроса, в bulk запросах не передается
     * @type {number}
     * @memberof KitInfoRead
     */
    availableKitsCount?: number;
    /**
     * 
     * @type {PricingSchemeExternalRepresentationObj}
     * @memberof KitInfoRead
     */
    pricingScheme?: PricingSchemeExternalRepresentationObj;
}
/**
 * 
 * @export
 * @interface KitInfoReadAllOf
 */
export interface KitInfoReadAllOf {
    /**
     * Возможно удалить данную сущность
     * @type {boolean}
     * @memberof KitInfoReadAllOf
     */
    deleteable: boolean;
    /**
     * Итоговая скидка в процентах
     * @type {number}
     * @memberof KitInfoReadAllOf
     */
    totalDiscount: number;
    /**
     * Итоговая суммарная стоимость, коп
     * @type {number}
     * @memberof KitInfoReadAllOf
     */
    finalTotalPrice: number;
    /**
     * Количество наборов, которое можно собрать из текущего наличия компанентов в инвентаре
     * @type {number}
     * @memberof KitInfoReadAllOf
     */
    stockCount: number;
    /**
     * Количество компанентов
     * @type {number}
     * @memberof KitInfoReadAllOf
     */
    membersCount: number;
    /**
     * Количество экземпляров
     * @type {number}
     * @memberof KitInfoReadAllOf
     */
    instancesCount: number;
    /**
     * Категории (продуктовые)
     * @type {Array<SimpleEntityRecord>}
     * @memberof KitInfoReadAllOf
     */
    categories?: Array<SimpleEntityRecord>;
    /**
     * 
     * @type {NomenclatureStateCodeEnum}
     * @memberof KitInfoReadAllOf
     */
    stateCode: NomenclatureStateCodeEnum;
    /**
     * Коды доступных переходов для данного продукта и пользователя
     * @type {Array<SimpleTransitionCodeEnum>}
     * @memberof KitInfoReadAllOf
     */
    availableTransitionCodes?: Array<SimpleTransitionCodeEnum>;
    /**
     * Находится в архиве
     * @type {boolean}
     * @memberof KitInfoReadAllOf
     */
    archive: boolean;
    /**
     * Может ли быть отправлен в архив (может, когда не является активным)
     * @type {boolean}
     * @memberof KitInfoReadAllOf
     */
    archivable: boolean;
    /**
     * 
     * @type {Array<KitMemberObjRead>}
     * @memberof KitInfoReadAllOf
     */
    members: Array<KitMemberObjRead>;
    /**
     * 
     * @type {RentIndicatorsObj}
     * @memberof KitInfoReadAllOf
     */
    rentIndicators: RentIndicatorsObj;
    /**
     * Все кастомные поля данного продукта со значениями сгруппированные и упорядоченные
     * @type {Array<CustomFieldGroupObjRead>}
     * @memberof KitInfoReadAllOf
     */
    customFieldGroups?: Array<CustomFieldGroupObjRead>;
    /**
     * Число доступных для аренды наборов. на момент запроса, в bulk запросах не передается
     * @type {number}
     * @memberof KitInfoReadAllOf
     */
    availableKitsCount?: number;
    /**
     * 
     * @type {PricingSchemeExternalRepresentationObj}
     * @memberof KitInfoReadAllOf
     */
    pricingScheme?: PricingSchemeExternalRepresentationObj;
}
/**
 * Набор: состояние при изменении
 * @export
 * @interface KitInfoUpdate
 */
export interface KitInfoUpdate extends KitInfoAbstractWriteCommons {
}
/**
 * Компонент набора состояние чтения
 * @export
 * @interface KitMemberObjRead
 */
export interface KitMemberObjRead {
    /**
     * Идентификатор компонента набора
     * @type {number}
     * @memberof KitMemberObjRead
     */
    id: number;
    /**
     * Является главным компонентом набора
     * @type {boolean}
     * @memberof KitMemberObjRead
     */
    main: boolean;
    /**
     * Количество экземпляров
     * @type {number}
     * @memberof KitMemberObjRead
     */
    instanceCount: number;
    /**
     * Идентификатор продукта
     * @type {number}
     * @memberof KitMemberObjRead
     */
    productId: number;
    /**
     * Идентификатор варианта
     * @type {number}
     * @memberof KitMemberObjRead
     */
    variantId?: number;
    /**
     * Тип сущности на которую ссылается - Вариант продукта если true, Продукт если false
     * @type {boolean}
     * @memberof KitMemberObjRead
     */
    linksProductVariant: boolean;
    /**
     * Скидка в процентах
     * @type {number}
     * @memberof KitMemberObjRead
     */
    discount: number;
    /**
     * Обязательно в наборе
     * @type {boolean}
     * @memberof KitMemberObjRead
     */
    requiredInKit: boolean;
    /**
     * 
     * @type {ImageObj}
     * @memberof KitMemberObjRead
     */
    image?: ImageObj;
    /**
     * 
     * @type {InstanceTrackingTypeCodeEnum}
     * @memberof KitMemberObjRead
     */
    instanceTrackingTypeCode: InstanceTrackingTypeCodeEnum;
    /**
     * Порядок в наборе
     * @type {number}
     * @memberof KitMemberObjRead
     */
    memberOrder: number;
    /**
     * 
     * @type {NomenclatureStateCodeEnum}
     * @memberof KitMemberObjRead
     */
    productStateCode: NomenclatureStateCodeEnum;
    /**
     * Короткое наименование продукта
     * @type {string}
     * @memberof KitMemberObjRead
     */
    productShortName: string;
    /**
     * Артикул
     * @type {string}
     * @memberof KitMemberObjRead
     */
    externalCode: string;
    /**
     * Наименование варианта
     * @type {string}
     * @memberof KitMemberObjRead
     */
    variantName?: string;
    /**
     * Цена экз./см.
     * @type {number}
     * @memberof KitMemberObjRead
     */
    pricePerShift: number;
    /**
     * 
     * @type {PricingSchemeExternalRepresentationObj}
     * @memberof KitMemberObjRead
     */
    pricingScheme?: PricingSchemeExternalRepresentationObj;
    /**
     * Итоговая стоимость за экземпляр, коп.
     * @type {number}
     * @memberof KitMemberObjRead
     */
    finalInstancePrice: number;
    /**
     * Итоговая суммарная стоимость, коп.
     * @type {number}
     * @memberof KitMemberObjRead
     */
    finalTotalPrice: number;
}
/**
 * 
 * @export
 * @interface KitMemberObjReadAllOf
 */
export interface KitMemberObjReadAllOf {
    /**
     * 
     * @type {ImageObj}
     * @memberof KitMemberObjReadAllOf
     */
    image?: ImageObj;
    /**
     * 
     * @type {InstanceTrackingTypeCodeEnum}
     * @memberof KitMemberObjReadAllOf
     */
    instanceTrackingTypeCode: InstanceTrackingTypeCodeEnum;
    /**
     * Порядок в наборе
     * @type {number}
     * @memberof KitMemberObjReadAllOf
     */
    memberOrder: number;
    /**
     * 
     * @type {NomenclatureStateCodeEnum}
     * @memberof KitMemberObjReadAllOf
     */
    productStateCode: NomenclatureStateCodeEnum;
    /**
     * Короткое наименование продукта
     * @type {string}
     * @memberof KitMemberObjReadAllOf
     */
    productShortName: string;
    /**
     * Артикул
     * @type {string}
     * @memberof KitMemberObjReadAllOf
     */
    externalCode: string;
    /**
     * Наименование варианта
     * @type {string}
     * @memberof KitMemberObjReadAllOf
     */
    variantName?: string;
    /**
     * Цена экз./см.
     * @type {number}
     * @memberof KitMemberObjReadAllOf
     */
    pricePerShift: number;
    /**
     * 
     * @type {PricingSchemeExternalRepresentationObj}
     * @memberof KitMemberObjReadAllOf
     */
    pricingScheme?: PricingSchemeExternalRepresentationObj;
    /**
     * Итоговая стоимость за экземпляр, коп.
     * @type {number}
     * @memberof KitMemberObjReadAllOf
     */
    finalInstancePrice: number;
    /**
     * Итоговая суммарная стоимость, коп.
     * @type {number}
     * @memberof KitMemberObjReadAllOf
     */
    finalTotalPrice: number;
}
/**
 * Компанент набора состояние записи
 * @export
 * @interface KitMemberObjWrite
 */
export interface KitMemberObjWrite {
    /**
     * Идентификатор компонента набора
     * @type {number}
     * @memberof KitMemberObjWrite
     */
    id?: number;
    /**
     * Является главным компонентом набора
     * @type {boolean}
     * @memberof KitMemberObjWrite
     */
    main: boolean;
    /**
     * Количество экземпляров
     * @type {number}
     * @memberof KitMemberObjWrite
     */
    instanceCount: number;
    /**
     * Идентификатор продукта
     * @type {number}
     * @memberof KitMemberObjWrite
     */
    productId: number;
    /**
     * Идентификатор варианта
     * @type {number}
     * @memberof KitMemberObjWrite
     */
    variantId?: number;
    /**
     * Тип сущности на которую ссылается - Вариант продукта если true, Продукт если false
     * @type {boolean}
     * @memberof KitMemberObjWrite
     */
    linksProductVariant: boolean;
    /**
     * Скидка в процентах
     * @type {number}
     * @memberof KitMemberObjWrite
     */
    discount: number;
    /**
     * Обязательно в наборе
     * @type {boolean}
     * @memberof KitMemberObjWrite
     */
    requiredInKit: boolean;
}
/**
 * Не финансовые поля сущности KitRecord, которые могут быть выгружены в список Excel
 * @export
 * @enum {string}
 */
export enum KitNotFinancialFieldsTypeCode {
    ShortName = 'shortName',
    ExternalCode = 'externalCode',
    StateCode = 'stateCode',
    MembersCount = 'membersCount',
    InstancesCount = 'instancesCount',
    LastActivityDate = 'lastActivityDate'
}

/**
 * Списочное представление набора
 * @export
 * @interface KitRecord
 */
export interface KitRecord {
    /**
     * Идентификатор
     * @type {number}
     * @memberof KitRecord
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof KitRecord
     */
    businessVersion: number;
    /**
     * Ссылка: Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof KitRecord
     */
    businessAccountId: number;
    /**
     * Информация о главном изображении
     * @type {ImageObj}
     * @memberof KitRecord
     */
    mainImage?: ImageObj;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof KitRecord
     */
    shortName: string;
    /**
     * Артикул
     * @type {string}
     * @memberof KitRecord
     */
    externalCode?: string;
    /**
     * Идентификатор основной категории
     * @type {number}
     * @memberof KitRecord
     */
    mainCategoryId?: number;
    /**
     * Наименование основной категории
     * @type {string}
     * @memberof KitRecord
     */
    mainCategoryName?: string;
    /**
     * Итоговая скидка в процентах
     * @type {number}
     * @memberof KitRecord
     */
    totalDiscount: number;
    /**
     * Итоговая суммарная стоимость, коп
     * @type {number}
     * @memberof KitRecord
     */
    finalTotalPrice: number;
    /**
     * Идентификаторы продуктов - компонентов набора
     * @type {Array<number>}
     * @memberof KitRecord
     */
    productIds?: Array<number>;
    /**
     * Идентификаторы вариантов - компонентов набора
     * @type {Array<number>}
     * @memberof KitRecord
     */
    variantIds?: Array<number>;
    /**
     * Количество компанентов
     * @type {number}
     * @memberof KitRecord
     */
    membersCount: number;
    /**
     * Количество экземпляров
     * @type {number}
     * @memberof KitRecord
     */
    instancesCount: number;
    /**
     * Дата и время последней активности (измение сущности либо операция над связанными обязательствами)
     * @type {Date}
     * @memberof KitRecord
     */
    lastActivityDate: Date;
    /**
     * 
     * @type {NomenclatureStateCodeEnum}
     * @memberof KitRecord
     */
    stateCode?: NomenclatureStateCodeEnum;
    /**
     * Коды доступных переходов для данного продукта и пользователя
     * @type {Array<SimpleTransitionCodeEnum>}
     * @memberof KitRecord
     */
    availableTransitionCodes?: Array<SimpleTransitionCodeEnum>;
    /**
     * Находится в архиве
     * @type {boolean}
     * @memberof KitRecord
     */
    archive: boolean;
    /**
     * Может ли быть отправлен в архив (может, когда не является активным)
     * @type {boolean}
     * @memberof KitRecord
     */
    archivable: boolean;
    /**
     * Возможно удалить данную сущность
     * @type {boolean}
     * @memberof KitRecord
     */
    deleteable: boolean;
    /**
     * Идентификаторы категорий
     * @type {Array<number>}
     * @memberof KitRecord
     */
    categoryIds: Array<number>;
    /**
     * 
     * @type {Array<CustomFieldValueObjRecord>}
     * @memberof KitRecord
     */
    customFieldValues?: Array<CustomFieldValueObjRecord>;
    /**
     * Идентификаторы пользовательских полей набора
     * @type {Array<number>}
     * @memberof KitRecord
     */
    customFieldIds?: Array<number>;
    /**
     * 
     * @type {ProblemAndWarningRentIndicatorsRecordObj}
     * @memberof KitRecord
     */
    problemsAndWarnings: ProblemAndWarningRentIndicatorsRecordObj;
    /**
     * 
     * @type {PricingSchemeExternalRepresentationObj}
     * @memberof KitRecord
     */
    pricingScheme?: PricingSchemeExternalRepresentationObj;
    /**
     * 
     * @type {PricingSchemeModeCodeEnum}
     * @memberof KitRecord
     */
    pricingSchemeMode?: PricingSchemeModeCodeEnum;
}
/**
 * 
 * @export
 * @interface KitRecordAllOf
 */
export interface KitRecordAllOf {
    /**
     * Информация о главном изображении
     * @type {ImageObj}
     * @memberof KitRecordAllOf
     */
    mainImage?: ImageObj;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof KitRecordAllOf
     */
    shortName: string;
    /**
     * Артикул
     * @type {string}
     * @memberof KitRecordAllOf
     */
    externalCode?: string;
    /**
     * Идентификатор основной категории
     * @type {number}
     * @memberof KitRecordAllOf
     */
    mainCategoryId?: number;
    /**
     * Наименование основной категории
     * @type {string}
     * @memberof KitRecordAllOf
     */
    mainCategoryName?: string;
    /**
     * Итоговая скидка в процентах
     * @type {number}
     * @memberof KitRecordAllOf
     */
    totalDiscount: number;
    /**
     * Итоговая суммарная стоимость, коп
     * @type {number}
     * @memberof KitRecordAllOf
     */
    finalTotalPrice: number;
    /**
     * Идентификаторы продуктов - компонентов набора
     * @type {Array<number>}
     * @memberof KitRecordAllOf
     */
    productIds?: Array<number>;
    /**
     * Идентификаторы вариантов - компонентов набора
     * @type {Array<number>}
     * @memberof KitRecordAllOf
     */
    variantIds?: Array<number>;
    /**
     * Количество компанентов
     * @type {number}
     * @memberof KitRecordAllOf
     */
    membersCount: number;
    /**
     * Количество экземпляров
     * @type {number}
     * @memberof KitRecordAllOf
     */
    instancesCount: number;
    /**
     * Дата и время последней активности (измение сущности либо операция над связанными обязательствами)
     * @type {Date}
     * @memberof KitRecordAllOf
     */
    lastActivityDate: Date;
    /**
     * 
     * @type {NomenclatureStateCodeEnum}
     * @memberof KitRecordAllOf
     */
    stateCode?: NomenclatureStateCodeEnum;
    /**
     * Коды доступных переходов для данного продукта и пользователя
     * @type {Array<SimpleTransitionCodeEnum>}
     * @memberof KitRecordAllOf
     */
    availableTransitionCodes?: Array<SimpleTransitionCodeEnum>;
    /**
     * Находится в архиве
     * @type {boolean}
     * @memberof KitRecordAllOf
     */
    archive: boolean;
    /**
     * Может ли быть отправлен в архив (может, когда не является активным)
     * @type {boolean}
     * @memberof KitRecordAllOf
     */
    archivable: boolean;
    /**
     * Возможно удалить данную сущность
     * @type {boolean}
     * @memberof KitRecordAllOf
     */
    deleteable: boolean;
    /**
     * Идентификаторы категорий
     * @type {Array<number>}
     * @memberof KitRecordAllOf
     */
    categoryIds: Array<number>;
    /**
     * 
     * @type {Array<CustomFieldValueObjRecord>}
     * @memberof KitRecordAllOf
     */
    customFieldValues?: Array<CustomFieldValueObjRecord>;
    /**
     * Идентификаторы пользовательских полей набора
     * @type {Array<number>}
     * @memberof KitRecordAllOf
     */
    customFieldIds?: Array<number>;
    /**
     * 
     * @type {ProblemAndWarningRentIndicatorsRecordObj}
     * @memberof KitRecordAllOf
     */
    problemsAndWarnings: ProblemAndWarningRentIndicatorsRecordObj;
    /**
     * 
     * @type {PricingSchemeExternalRepresentationObj}
     * @memberof KitRecordAllOf
     */
    pricingScheme?: PricingSchemeExternalRepresentationObj;
    /**
     * 
     * @type {PricingSchemeModeCodeEnum}
     * @memberof KitRecordAllOf
     */
    pricingSchemeMode?: PricingSchemeModeCodeEnum;
}
/**
 * Список записей наборов
 * @export
 * @interface KitRecordList
 */
export interface KitRecordList {
    /**
     * 
     * @type {ListAttributesInfo}
     * @memberof KitRecordList
     */
    listAttributes: ListAttributesInfo;
    /**
     * 
     * @type {Array<KitRecord>}
     * @memberof KitRecordList
     */
    records: Array<KitRecord>;
}
/**
 * 
 * @export
 * @interface KitRecordListAllOf
 */
export interface KitRecordListAllOf {
    /**
     * 
     * @type {Array<KitRecord>}
     * @memberof KitRecordListAllOf
     */
    records: Array<KitRecord>;
}
/**
 * Ссылка на статью базы знаний с названием в текущей локали
 * @export
 * @interface KnowledgeBaseArticleLinkObj
 */
export interface KnowledgeBaseArticleLinkObj {
    /**
     * Наименование статьи в текущей локали
     * @type {string}
     * @memberof KnowledgeBaseArticleLinkObj
     */
    name: string;
    /**
     * Идентификатор статьи
     * @type {number}
     * @memberof KnowledgeBaseArticleLinkObj
     */
    articleId: number;
}
/**
 * Список ссылок на статьи базы знаний с названиями в текущей локали
 * @export
 * @interface KnowledgeBaseArticleLinkObjList
 */
export interface KnowledgeBaseArticleLinkObjList {
    /**
     * 
     * @type {Array<KnowledgeBaseArticleLinkObj>}
     * @memberof KnowledgeBaseArticleLinkObjList
     */
    records: Array<KnowledgeBaseArticleLinkObj>;
}
/**
 * 
 * @export
 * @interface KnowledgeBaseJson
 */
export interface KnowledgeBaseJson {
    /**
     * 
     * @type {LanguageArticles}
     * @memberof KnowledgeBaseJson
     */
    articles: LanguageArticles;
    /**
     * 
     * @type {Array<InterfaceLocation>}
     * @memberof KnowledgeBaseJson
     */
    interfaceLocationArticleLinks: Array<InterfaceLocation>;
}
/**
 * 
 * @export
 * @interface LanguageArticles
 */
export interface LanguageArticles {
    /**
     * 
     * @type {Array<LanguageSpecificArticlesItem>}
     * @memberof LanguageArticles
     */
    ru: Array<LanguageSpecificArticlesItem>;
    /**
     * 
     * @type {Array<LanguageSpecificArticlesItem>}
     * @memberof LanguageArticles
     */
    en: Array<LanguageSpecificArticlesItem>;
}
/**
 * 
 * @export
 * @interface LanguageSpecificArticlesItem
 */
export interface LanguageSpecificArticlesItem {
    /**
     * 
     * @type {number}
     * @memberof LanguageSpecificArticlesItem
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof LanguageSpecificArticlesItem
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof LanguageSpecificArticlesItem
     */
    hidden?: boolean;
}
/**
 * Код страны в юридическом контексте. Подмножество ISO 3166-1 alpha-2 + специальное значение OTHER
 * @export
 * @enum {string}
 */
export enum LegalCountryCodeEnum {
    RU = 'RU',
    BY = 'BY',
    KZ = 'KZ',
    OTHER = 'OTHER'
}

/**
 * Информация о реквизитах: состояние для записи
 * @export
 * @interface LegalDetailsFieldInfoWrite
 */
export interface LegalDetailsFieldInfoWrite {
    /**
     * 
     * @type {LegalDetailsFieldTypeCodeEnum}
     * @memberof LegalDetailsFieldInfoWrite
     */
    name: LegalDetailsFieldTypeCodeEnum;
    /**
     * Значение реквизита
     * @type {string}
     * @memberof LegalDetailsFieldInfoWrite
     */
    value: string;
}
/**
 * Информация о полях реквизитов при получении общей схемы
 * @export
 * @interface LegalDetailsFieldRecord
 */
export interface LegalDetailsFieldRecord {
    /**
     * 
     * @type {LegalDetailsFieldTypeCodeEnum}
     * @memberof LegalDetailsFieldRecord
     */
    name: LegalDetailsFieldTypeCodeEnum;
    /**
     * Является финансовым
     * @type {boolean}
     * @memberof LegalDetailsFieldRecord
     */
    financial: boolean;
    /**
     * Регулярное выражение для реквизита
     * @type {string}
     * @memberof LegalDetailsFieldRecord
     */
    regex?: string;
    /**
     * Маска для реквизита
     * @type {string}
     * @memberof LegalDetailsFieldRecord
     */
    mask?: string;
    /**
     * Максимальная длина значения реквизита (для тех реквизитов, у которых просто ограничение на длину)
     * @type {number}
     * @memberof LegalDetailsFieldRecord
     */
    maxLength?: number;
}
/**
 * Поля реквизитов
 * @export
 * @enum {string}
 */
export enum LegalDetailsFieldTypeCodeEnum {
    IBAN = 'IBAN',
    SWIFTBIC = 'SWIFT_BIC',
    PHYSICALADDRESS = 'PHYSICAL_ADDRESS',
    BANKNAME = 'BANK_NAME',
    LEGALADDRESS = 'LEGAL_ADDRESS',
    BIRTHDATE = 'BIRTH_DATE',
    SEX = 'SEX',
    CITIZENSHIP = 'CITIZENSHIP',
    IDENTITYDOCUMENTNUMBER = 'IDENTITY_DOCUMENT_NUMBER',
    IDENTITYDOCUMENTEXPIRATIONDATE = 'IDENTITY_DOCUMENT_EXPIRATION_DATE',
    IDENTITYDOCUMENTISSUERNAME = 'IDENTITY_DOCUMENT_ISSUER_NAME',
    IDENTITYDOCUMENTISSUEDATE = 'IDENTITY_DOCUMENT_ISSUE_DATE',
    BIC = 'BIC',
    CORRESPONDENTACCOUNTNUMBER = 'CORRESPONDENT_ACCOUNT_NUMBER',
    PAYMENTACCOUNTNUMBER = 'PAYMENT_ACCOUNT_NUMBER',
    INN = 'INN',
    KPP = 'KPP',
    OGRN = 'OGRN',
    REPRESENTEDBYDOCUMENTSTRING = 'REPRESENTED_BY_DOCUMENT_STRING',
    PASSPORTNUMBER = 'PASSPORT_NUMBER',
    PASSPORTISSUERCODE = 'PASSPORT_ISSUER_CODE',
    INNIP = 'INNIP',
    OGRNIP = 'OGRNIP',
    BYUNP = 'BY_UNP',
    BYIDENTIFICATIONPASSPORTNUMBER = 'BY_IDENTIFICATION_PASSPORT_NUMBER',
    BYPASSPORTBOOKNUMBER = 'BY_PASSPORT_BOOK_NUMBER',
    KZIIN = 'KZ_IIN',
    KZBIN = 'KZ_BIN',
    KZPASSPORTNUMBER = 'KZ_PASSPORT_NUMBER',
    KZCERTIFICATENUMBER = 'KZ_CERTIFICATE_NUMBER'
}

/**
 * Информация о реквизитах: состояние для чтения
 * @export
 * @interface LegalDetailsFieldValuesRecord
 */
export interface LegalDetailsFieldValuesRecord {
    /**
     * 
     * @type {LegalDetailsFieldTypeCodeEnum}
     * @memberof LegalDetailsFieldValuesRecord
     */
    name: LegalDetailsFieldTypeCodeEnum;
    /**
     * Значение реквизита
     * @type {string}
     * @memberof LegalDetailsFieldValuesRecord
     */
    value: string;
}
/**
 * Объект субъектов и реквизитов: абстрактное общее состояние для всех операций
 * @export
 * @interface LegalDetailsInfoAbstractCommons
 */
export interface LegalDetailsInfoAbstractCommons {
    /**
     * Идентификатор
     * @type {number}
     * @memberof LegalDetailsInfoAbstractCommons
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof LegalDetailsInfoAbstractCommons
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof LegalDetailsInfoAbstractCommons
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof LegalDetailsInfoAbstractCommons
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof LegalDetailsInfoAbstractCommons
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof LegalDetailsInfoAbstractCommons
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof LegalDetailsInfoAbstractCommons
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof LegalDetailsInfoAbstractCommons
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof LegalDetailsInfoAbstractCommons
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof LegalDetailsInfoAbstractCommons
     */
    businessAccountId?: number;
    /**
     * Полное наименование
     * @type {string}
     * @memberof LegalDetailsInfoAbstractCommons
     */
    fullName?: string;
    /**
     * 
     * @type {LegalCountryCodeEnum}
     * @memberof LegalDetailsInfoAbstractCommons
     */
    country: LegalCountryCodeEnum;
    /**
     * 
     * @type {LegalSubjectTypeCodeEnum}
     * @memberof LegalDetailsInfoAbstractCommons
     */
    subjectType: LegalSubjectTypeCodeEnum;
    /**
     * ID ссылочной сущности
     * @type {number}
     * @memberof LegalDetailsInfoAbstractCommons
     */
    referenceEntityId: number;
    /**
     * 
     * @type {EntityTypeCodeEnum}
     * @memberof LegalDetailsInfoAbstractCommons
     */
    entityType: EntityTypeCodeEnum;
}
/**
 * 
 * @export
 * @interface LegalDetailsInfoAbstractCommonsAllOf
 */
export interface LegalDetailsInfoAbstractCommonsAllOf {
    /**
     * Полное наименование
     * @type {string}
     * @memberof LegalDetailsInfoAbstractCommonsAllOf
     */
    fullName?: string;
    /**
     * 
     * @type {LegalCountryCodeEnum}
     * @memberof LegalDetailsInfoAbstractCommonsAllOf
     */
    country: LegalCountryCodeEnum;
    /**
     * 
     * @type {LegalSubjectTypeCodeEnum}
     * @memberof LegalDetailsInfoAbstractCommonsAllOf
     */
    subjectType: LegalSubjectTypeCodeEnum;
    /**
     * ID ссылочной сущности
     * @type {number}
     * @memberof LegalDetailsInfoAbstractCommonsAllOf
     */
    referenceEntityId: number;
    /**
     * 
     * @type {EntityTypeCodeEnum}
     * @memberof LegalDetailsInfoAbstractCommonsAllOf
     */
    entityType: EntityTypeCodeEnum;
}
/**
 * Объект субъектов и реквизитов: состояние для чтения
 * @export
 * @interface LegalDetailsInfoRead
 */
export interface LegalDetailsInfoRead {
    /**
     * Идентификатор
     * @type {number}
     * @memberof LegalDetailsInfoRead
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof LegalDetailsInfoRead
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof LegalDetailsInfoRead
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof LegalDetailsInfoRead
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof LegalDetailsInfoRead
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof LegalDetailsInfoRead
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof LegalDetailsInfoRead
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof LegalDetailsInfoRead
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof LegalDetailsInfoRead
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof LegalDetailsInfoRead
     */
    businessAccountId?: number;
    /**
     * Полное наименование
     * @type {string}
     * @memberof LegalDetailsInfoRead
     */
    fullName?: string;
    /**
     * 
     * @type {LegalCountryCodeEnum}
     * @memberof LegalDetailsInfoRead
     */
    country: LegalCountryCodeEnum;
    /**
     * 
     * @type {LegalSubjectTypeCodeEnum}
     * @memberof LegalDetailsInfoRead
     */
    subjectType: LegalSubjectTypeCodeEnum;
    /**
     * ID ссылочной сущности
     * @type {number}
     * @memberof LegalDetailsInfoRead
     */
    referenceEntityId: number;
    /**
     * 
     * @type {EntityTypeCodeEnum}
     * @memberof LegalDetailsInfoRead
     */
    entityType: EntityTypeCodeEnum;
    /**
     * 
     * @type {Array<LegalDetailsFieldValuesRecord>}
     * @memberof LegalDetailsInfoRead
     */
    fields: Array<LegalDetailsFieldValuesRecord>;
}
/**
 * 
 * @export
 * @interface LegalDetailsInfoReadAllOf
 */
export interface LegalDetailsInfoReadAllOf {
    /**
     * 
     * @type {Array<LegalDetailsFieldValuesRecord>}
     * @memberof LegalDetailsInfoReadAllOf
     */
    fields: Array<LegalDetailsFieldValuesRecord>;
}
/**
 * Объект субъектов и реквизитов: состояние для записи (создание и обновление)
 * @export
 * @interface LegalDetailsInfoWrite
 */
export interface LegalDetailsInfoWrite {
    /**
     * Идентификатор
     * @type {number}
     * @memberof LegalDetailsInfoWrite
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof LegalDetailsInfoWrite
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof LegalDetailsInfoWrite
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof LegalDetailsInfoWrite
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof LegalDetailsInfoWrite
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof LegalDetailsInfoWrite
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof LegalDetailsInfoWrite
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof LegalDetailsInfoWrite
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof LegalDetailsInfoWrite
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof LegalDetailsInfoWrite
     */
    businessAccountId?: number;
    /**
     * Полное наименование
     * @type {string}
     * @memberof LegalDetailsInfoWrite
     */
    fullName?: string;
    /**
     * 
     * @type {LegalCountryCodeEnum}
     * @memberof LegalDetailsInfoWrite
     */
    country: LegalCountryCodeEnum;
    /**
     * 
     * @type {LegalSubjectTypeCodeEnum}
     * @memberof LegalDetailsInfoWrite
     */
    subjectType: LegalSubjectTypeCodeEnum;
    /**
     * ID ссылочной сущности
     * @type {number}
     * @memberof LegalDetailsInfoWrite
     */
    referenceEntityId: number;
    /**
     * 
     * @type {EntityTypeCodeEnum}
     * @memberof LegalDetailsInfoWrite
     */
    entityType: EntityTypeCodeEnum;
    /**
     * 
     * @type {Array<LegalDetailsFieldInfoWrite>}
     * @memberof LegalDetailsInfoWrite
     */
    fields: Array<LegalDetailsFieldInfoWrite>;
}
/**
 * 
 * @export
 * @interface LegalDetailsInfoWriteAllOf
 */
export interface LegalDetailsInfoWriteAllOf {
    /**
     * 
     * @type {Array<LegalDetailsFieldInfoWrite>}
     * @memberof LegalDetailsInfoWriteAllOf
     */
    fields: Array<LegalDetailsFieldInfoWrite>;
}
/**
 * Списочное представление объекта субъекта и реквизитов
 * @export
 * @interface LegalDetailsRecord
 */
export interface LegalDetailsRecord extends BusinessAccountAbstractEntityRecord {
}
/**
 * Список записей объектов субъекта и реквизитов
 * @export
 * @interface LegalDetailsRecordList
 */
export interface LegalDetailsRecordList {
    /**
     * 
     * @type {ListAttributesInfo}
     * @memberof LegalDetailsRecordList
     */
    listAttributes: ListAttributesInfo;
    /**
     * 
     * @type {Array<LegalDetailsRecord>}
     * @memberof LegalDetailsRecordList
     */
    records: Array<LegalDetailsRecord>;
}
/**
 * 
 * @export
 * @interface LegalDetailsRecordListAllOf
 */
export interface LegalDetailsRecordListAllOf {
    /**
     * 
     * @type {Array<LegalDetailsRecord>}
     * @memberof LegalDetailsRecordListAllOf
     */
    records: Array<LegalDetailsRecord>;
}
/**
 * Код типа юридического субъекта
 * @export
 * @enum {string}
 */
export enum LegalSubjectTypeCodeEnum {
    RULEGALENTITY = 'RU_LEGAL_ENTITY',
    RUNATURALPERSON = 'RU_NATURAL_PERSON',
    RUINDIVIDUALENTREPRENEUR = 'RU_INDIVIDUAL_ENTREPRENEUR',
    BYLEGALENTITY = 'BY_LEGAL_ENTITY',
    BYNATURALPERSON = 'BY_NATURAL_PERSON',
    BYINDIVIDUALENTREPRENEUR = 'BY_INDIVIDUAL_ENTREPRENEUR',
    KZLEGALENTITY = 'KZ_LEGAL_ENTITY',
    KZNATURALPERSON = 'KZ_NATURAL_PERSON',
    KZINDIVIDUALENTREPRENEUR = 'KZ_INDIVIDUAL_ENTREPRENEUR',
    INTERNATIONALSWIFTLEGALENTITY = 'INTERNATIONAL_SWIFT_LEGAL_ENTITY',
    INTERNATIONALSWIFTNATURALPERSON = 'INTERNATIONAL_SWIFT_NATURAL_PERSON'
}

/**
 * 
 * @export
 * @interface ListAttributesInfo
 */
export interface ListAttributesInfo {
    /**
     * Сдвиг (постраничка)
     * @type {number}
     * @memberof ListAttributesInfo
     */
    startFrom?: number;
    /**
     * Ограничение количества возвращаемых результатов (постраничка)
     * @type {number}
     * @memberof ListAttributesInfo
     */
    limit?: number;
    /**
     * Количество прошедших фильтрацию
     * @type {number}
     * @memberof ListAttributesInfo
     */
    filteredCount: number;
}
/**
 * Параметры запроса на получение списков номенклатуры со стеками доступности для передачи в теле запроса
 * @export
 * @interface ListOnIntervalRequestObject
 */
export interface ListOnIntervalRequestObject {
    /**
     * 
     * @type {Array<number>}
     * @memberof ListOnIntervalRequestObject
     */
    possibleDelayedRentElementIds?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListOnIntervalRequestObject
     */
    listFilters?: Array<string>;
}
/**
 * Тип погрузки
 * @export
 * @enum {string}
 */
export enum LoadingTypeCodeEnum {
    REAR = 'REAR',
    REARHYDROBOARD = 'REAR_HYDROBOARD',
    SIDE = 'SIDE',
    TOP = 'TOP'
}

/**
 * Код локали
 * @export
 * @enum {string}
 */
export enum LocaleCodeEnum {
    RU = 'RU',
    EN = 'EN'
}

/**
 * 
 * @export
 * @interface LocationInfoAbstractCommons
 */
export interface LocationInfoAbstractCommons {
    /**
     * Идентификатор
     * @type {number}
     * @memberof LocationInfoAbstractCommons
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof LocationInfoAbstractCommons
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof LocationInfoAbstractCommons
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof LocationInfoAbstractCommons
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof LocationInfoAbstractCommons
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof LocationInfoAbstractCommons
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof LocationInfoAbstractCommons
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof LocationInfoAbstractCommons
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof LocationInfoAbstractCommons
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof LocationInfoAbstractCommons
     */
    businessAccountId?: number;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof LocationInfoAbstractCommons
     */
    shortName: string;
    /**
     * Адрес
     * @type {string}
     * @memberof LocationInfoAbstractCommons
     */
    address: string;
    /**
     * Широта
     * @type {number}
     * @memberof LocationInfoAbstractCommons
     */
    longitude?: number;
    /**
     * Долгота
     * @type {number}
     * @memberof LocationInfoAbstractCommons
     */
    latitude?: number;
    /**
     * Описание
     * @type {string}
     * @memberof LocationInfoAbstractCommons
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface LocationInfoAbstractCommonsAllOf
 */
export interface LocationInfoAbstractCommonsAllOf {
    /**
     * Короткое наименование
     * @type {string}
     * @memberof LocationInfoAbstractCommonsAllOf
     */
    shortName: string;
    /**
     * Адрес
     * @type {string}
     * @memberof LocationInfoAbstractCommonsAllOf
     */
    address: string;
    /**
     * Широта
     * @type {number}
     * @memberof LocationInfoAbstractCommonsAllOf
     */
    longitude?: number;
    /**
     * Долгота
     * @type {number}
     * @memberof LocationInfoAbstractCommonsAllOf
     */
    latitude?: number;
    /**
     * Описание
     * @type {string}
     * @memberof LocationInfoAbstractCommonsAllOf
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface LocationInfoAbstractWriteCommons
 */
export interface LocationInfoAbstractWriteCommons {
    /**
     * Идентификатор
     * @type {number}
     * @memberof LocationInfoAbstractWriteCommons
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof LocationInfoAbstractWriteCommons
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof LocationInfoAbstractWriteCommons
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof LocationInfoAbstractWriteCommons
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof LocationInfoAbstractWriteCommons
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof LocationInfoAbstractWriteCommons
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof LocationInfoAbstractWriteCommons
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof LocationInfoAbstractWriteCommons
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof LocationInfoAbstractWriteCommons
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof LocationInfoAbstractWriteCommons
     */
    businessAccountId?: number;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof LocationInfoAbstractWriteCommons
     */
    shortName: string;
    /**
     * Адрес
     * @type {string}
     * @memberof LocationInfoAbstractWriteCommons
     */
    address: string;
    /**
     * Широта
     * @type {number}
     * @memberof LocationInfoAbstractWriteCommons
     */
    longitude?: number;
    /**
     * Долгота
     * @type {number}
     * @memberof LocationInfoAbstractWriteCommons
     */
    latitude?: number;
    /**
     * Описание
     * @type {string}
     * @memberof LocationInfoAbstractWriteCommons
     */
    description?: string;
    /**
     * Контакты локации
     * @type {Array<ContactLinkObjWrite>}
     * @memberof LocationInfoAbstractWriteCommons
     */
    contacts?: Array<ContactLinkObjWrite>;
}
/**
 * 
 * @export
 * @interface LocationInfoAbstractWriteCommonsAllOf
 */
export interface LocationInfoAbstractWriteCommonsAllOf {
    /**
     * Контакты локации
     * @type {Array<ContactLinkObjWrite>}
     * @memberof LocationInfoAbstractWriteCommonsAllOf
     */
    contacts?: Array<ContactLinkObjWrite>;
}
/**
 * 
 * @export
 * @interface LocationInfoCheck
 */
export interface LocationInfoCheck {
    /**
     * 
     * @type {number}
     * @memberof LocationInfoCheck
     */
    updatedId?: number;
    /**
     * 
     * @type {string}
     * @memberof LocationInfoCheck
     */
    shortName?: string;
}
/**
 * 
 * @export
 * @interface LocationInfoCreate
 */
export interface LocationInfoCreate extends LocationInfoAbstractWriteCommons {
}
/**
 * 
 * @export
 * @interface LocationInfoRead
 */
export interface LocationInfoRead {
    /**
     * Идентификатор
     * @type {number}
     * @memberof LocationInfoRead
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof LocationInfoRead
     */
    businessVersion: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof LocationInfoRead
     */
    creationAuthorId: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof LocationInfoRead
     */
    creationAuthorFullName: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof LocationInfoRead
     */
    creationDate: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof LocationInfoRead
     */
    lastUpdateAuthorId: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof LocationInfoRead
     */
    lastUpdateAuthorFullName: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof LocationInfoRead
     */
    lastUpdateDate: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof LocationInfoRead
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof LocationInfoRead
     */
    businessAccountId: number;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof LocationInfoRead
     */
    shortName: string;
    /**
     * Адрес
     * @type {string}
     * @memberof LocationInfoRead
     */
    address: string;
    /**
     * Широта
     * @type {number}
     * @memberof LocationInfoRead
     */
    longitude?: number;
    /**
     * Долгота
     * @type {number}
     * @memberof LocationInfoRead
     */
    latitude?: number;
    /**
     * Описание
     * @type {string}
     * @memberof LocationInfoRead
     */
    description?: string;
    /**
     * Признак нахождения в архиве
     * @type {boolean}
     * @memberof LocationInfoRead
     */
    archive: boolean;
    /**
     * Может ли быть отправлена в архив (может, когда не в архиве)
     * @type {boolean}
     * @memberof LocationInfoRead
     */
    archivable: boolean;
    /**
     * Может ли быть удалена (может, когда нет входящих ссылок)
     * @type {boolean}
     * @memberof LocationInfoRead
     */
    deleteable: boolean;
    /**
     * Контакты локации
     * @type {Array<ContactLinkObjRead>}
     * @memberof LocationInfoRead
     */
    contacts?: Array<ContactLinkObjRead>;
    /**
     * 
     * @type {AttachmentRecordList}
     * @memberof LocationInfoRead
     */
    attachments?: AttachmentRecordList;
    /**
     * Число перевозок
     * @type {number}
     * @memberof LocationInfoRead
     */
    transportationCount: number;
    /**
     * Число проектов
     * @type {number}
     * @memberof LocationInfoRead
     */
    projectCount: number;
}
/**
 * 
 * @export
 * @interface LocationInfoReadAllOf
 */
export interface LocationInfoReadAllOf {
    /**
     * Признак нахождения в архиве
     * @type {boolean}
     * @memberof LocationInfoReadAllOf
     */
    archive: boolean;
    /**
     * Может ли быть отправлена в архив (может, когда не в архиве)
     * @type {boolean}
     * @memberof LocationInfoReadAllOf
     */
    archivable: boolean;
    /**
     * Может ли быть удалена (может, когда нет входящих ссылок)
     * @type {boolean}
     * @memberof LocationInfoReadAllOf
     */
    deleteable: boolean;
    /**
     * Контакты локации
     * @type {Array<ContactLinkObjRead>}
     * @memberof LocationInfoReadAllOf
     */
    contacts?: Array<ContactLinkObjRead>;
    /**
     * 
     * @type {AttachmentRecordList}
     * @memberof LocationInfoReadAllOf
     */
    attachments?: AttachmentRecordList;
    /**
     * Число перевозок
     * @type {number}
     * @memberof LocationInfoReadAllOf
     */
    transportationCount: number;
    /**
     * Число проектов
     * @type {number}
     * @memberof LocationInfoReadAllOf
     */
    projectCount: number;
}
/**
 * 
 * @export
 * @interface LocationInfoUpdate
 */
export interface LocationInfoUpdate extends LocationInfoAbstractWriteCommons {
}
/**
 * Все поля сущности LocationRecord, которые могут быть выгружены в список Excel. Финансовых полей нет
 * @export
 * @enum {string}
 */
export enum LocationNotFinancialFieldsTypeCode {
    ShortName = 'shortName',
    Address = 'address',
    TransportationCount = 'transportationCount',
    ProjectCount = 'projectCount',
    AttachmentCount = 'attachmentCount',
    LastUpdateAuthorShortName = 'lastUpdateAuthorShortName',
    LastUpdateDate = 'lastUpdateDate'
}

/**
 * 
 * @export
 * @interface LocationRecord
 */
export interface LocationRecord {
    /**
     * Идентификатор
     * @type {number}
     * @memberof LocationRecord
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof LocationRecord
     */
    businessVersion: number;
    /**
     * Ссылка: Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof LocationRecord
     */
    businessAccountId: number;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof LocationRecord
     */
    shortName: string;
    /**
     * Адрес
     * @type {string}
     * @memberof LocationRecord
     */
    address: string;
    /**
     * Долгота
     * @type {number}
     * @memberof LocationRecord
     */
    longitude?: number;
    /**
     * Широта
     * @type {number}
     * @memberof LocationRecord
     */
    latitude?: number;
    /**
     * Признак нахождения в архиве
     * @type {boolean}
     * @memberof LocationRecord
     */
    archive: boolean;
    /**
     * Может ли быть отправлена в архив (может, когда не в архиве)
     * @type {boolean}
     * @memberof LocationRecord
     */
    archivable: boolean;
    /**
     * Может ли быть удалена (может, когда нет входящих ссылок)
     * @type {boolean}
     * @memberof LocationRecord
     */
    deleteable: boolean;
    /**
     * Число вложений
     * @type {number}
     * @memberof LocationRecord
     */
    attachmentCount: number;
    /**
     * Число перевозок
     * @type {number}
     * @memberof LocationRecord
     */
    transportationCount: number;
    /**
     * Число проектов
     * @type {number}
     * @memberof LocationRecord
     */
    projectCount: number;
    /**
     * Ссылка: идентификатор автора посл. изменения
     * @type {number}
     * @memberof LocationRecord
     */
    lastUpdateAuthorId: number;
    /**
     * Выч. по ссылке: короткое ФИО автора посл. изменения
     * @type {string}
     * @memberof LocationRecord
     */
    lastUpdateAuthorShortName: string;
    /**
     * Дата и время посл. изменения
     * @type {Date}
     * @memberof LocationRecord
     */
    lastUpdateDate: Date;
}
/**
 * 
 * @export
 * @interface LocationRecordAllOf
 */
export interface LocationRecordAllOf {
    /**
     * Короткое наименование
     * @type {string}
     * @memberof LocationRecordAllOf
     */
    shortName: string;
    /**
     * Адрес
     * @type {string}
     * @memberof LocationRecordAllOf
     */
    address: string;
    /**
     * Долгота
     * @type {number}
     * @memberof LocationRecordAllOf
     */
    longitude?: number;
    /**
     * Широта
     * @type {number}
     * @memberof LocationRecordAllOf
     */
    latitude?: number;
    /**
     * Признак нахождения в архиве
     * @type {boolean}
     * @memberof LocationRecordAllOf
     */
    archive: boolean;
    /**
     * Может ли быть отправлена в архив (может, когда не в архиве)
     * @type {boolean}
     * @memberof LocationRecordAllOf
     */
    archivable: boolean;
    /**
     * Может ли быть удалена (может, когда нет входящих ссылок)
     * @type {boolean}
     * @memberof LocationRecordAllOf
     */
    deleteable: boolean;
    /**
     * Число вложений
     * @type {number}
     * @memberof LocationRecordAllOf
     */
    attachmentCount: number;
    /**
     * Число перевозок
     * @type {number}
     * @memberof LocationRecordAllOf
     */
    transportationCount: number;
    /**
     * Число проектов
     * @type {number}
     * @memberof LocationRecordAllOf
     */
    projectCount: number;
    /**
     * Ссылка: идентификатор автора посл. изменения
     * @type {number}
     * @memberof LocationRecordAllOf
     */
    lastUpdateAuthorId: number;
    /**
     * Выч. по ссылке: короткое ФИО автора посл. изменения
     * @type {string}
     * @memberof LocationRecordAllOf
     */
    lastUpdateAuthorShortName: string;
    /**
     * Дата и время посл. изменения
     * @type {Date}
     * @memberof LocationRecordAllOf
     */
    lastUpdateDate: Date;
}
/**
 * 
 * @export
 * @interface LocationRecordList
 */
export interface LocationRecordList {
    /**
     * 
     * @type {ListAttributesInfo}
     * @memberof LocationRecordList
     */
    listAttributes: ListAttributesInfo;
    /**
     * 
     * @type {Array<LocationRecord>}
     * @memberof LocationRecordList
     */
    records: Array<LocationRecord>;
}
/**
 * 
 * @export
 * @interface LocationRecordListAllOf
 */
export interface LocationRecordListAllOf {
    /**
     * 
     * @type {Array<LocationRecord>}
     * @memberof LocationRecordListAllOf
     */
    records: Array<LocationRecord>;
}
/**
 * 
 * @export
 * @interface MigrationStats
 */
export interface MigrationStats {
    /**
     * 
     * @type {number}
     * @memberof MigrationStats
     */
    createdProducts?: number;
    /**
     * 
     * @type {number}
     * @memberof MigrationStats
     */
    createdVariants?: number;
    /**
     * 
     * @type {number}
     * @memberof MigrationStats
     */
    createdInstances?: number;
    /**
     * 
     * @type {number}
     * @memberof MigrationStats
     */
    createdRenters?: number;
    /**
     * 
     * @type {number}
     * @memberof MigrationStats
     */
    createdLegalDetails?: number;
    /**
     * 
     * @type {number}
     * @memberof MigrationStats
     */
    createdProjects?: number;
    /**
     * 
     * @type {number}
     * @memberof MigrationStats
     */
    updatedProducts?: number;
    /**
     * 
     * @type {number}
     * @memberof MigrationStats
     */
    updatedVariants?: number;
    /**
     * 
     * @type {number}
     * @memberof MigrationStats
     */
    updatedInstances?: number;
    /**
     * 
     * @type {number}
     * @memberof MigrationStats
     */
    updatedRenters?: number;
    /**
     * 
     * @type {number}
     * @memberof MigrationStats
     */
    updatedProjects?: number;
}
/**
 * Списочное представление элемента аренды
 * @export
 * @interface NestedRentElementRecord
 */
export interface NestedRentElementRecord {
    /**
     * 
     * @type {Array<string>}
     * @memberof NestedRentElementRecord
     */
    tags?: Array<string>;
    /**
     * Общая строка тегов, объединенных через запятую
     * @type {string}
     * @memberof NestedRentElementRecord
     */
    tagsString?: string;
    /**
     * 
     * @type {NomenclatureEntityTypeCodeEnum}
     * @memberof NestedRentElementRecord
     */
    nomenclatureEntityTypeCode: NomenclatureEntityTypeCodeEnum;
    /**
     * 
     * @type {InstanceTrackingTypeCodeEnum}
     * @memberof NestedRentElementRecord
     */
    instanceTrackingTypeCode: InstanceTrackingTypeCodeEnum;
    /**
     * Идентификаторы экземпляров
     * @type {Array<number>}
     * @memberof NestedRentElementRecord
     */
    instanceIds?: Array<number>;
    /**
     * Коды экземпляров
     * @type {Array<string>}
     * @memberof NestedRentElementRecord
     */
    instanceNameOrCodes?: Array<string>;
    /**
     * Число субарендованых экземпляров (только для обязательств аренды по номенклатуре с именованными экземплярами)
     * @type {number}
     * @memberof NestedRentElementRecord
     */
    subrentedInstanceCount: number;
    /**
     * Выч: краткое наименование номенклатуры
     * @type {string}
     * @memberof NestedRentElementRecord
     */
    nomenclatureShortName: string;
    /**
     * Выч: полное наименование номенклатуры
     * @type {string}
     * @memberof NestedRentElementRecord
     */
    nomenclatureFullName: string;
    /**
     * Номер в рамке активности
     * @type {number}
     * @memberof NestedRentElementRecord
     */
    numberInActivityFrame?: number;
    /**
     * Ссылка: идентификатор продукта
     * @type {number}
     * @memberof NestedRentElementRecord
     */
    productId: number;
    /**
     * Идентификатор варианта
     * @type {number}
     * @memberof NestedRentElementRecord
     */
    variantId?: number;
    /**
     * Выч: наименование варианта
     * @type {string}
     * @memberof NestedRentElementRecord
     */
    variantName?: string;
    /**
     * Артикул (продукта или варианта)
     * @type {string}
     * @memberof NestedRentElementRecord
     */
    externalCode?: string;
    /**
     * Выч: информация об изображении
     * @type {ImageObj}
     * @memberof NestedRentElementRecord
     */
    image?: ImageObj;
    /**
     * Ссылка: идентификатор автора последнего изменения (автора операции)
     * @type {number}
     * @memberof NestedRentElementRecord
     */
    lastUpdateAuthorId: number;
    /**
     * Ссылки: идентификаторы операций над элементом
     * @type {Array<number>}
     * @memberof NestedRentElementRecord
     */
    operationIds: Array<number>;
    /**
     * Ссылка: идентификатор последней операции
     * @type {number}
     * @memberof NestedRentElementRecord
     */
    lastOperationId: number;
    /**
     * 
     * @type {RentTermsObj}
     * @memberof NestedRentElementRecord
     */
    rentTerms: RentTermsObj;
    /**
     * 
     * @type {ShiftsObj}
     * @memberof NestedRentElementRecord
     */
    calendarShifts: ShiftsObj;
    /**
     * 
     * @type {ShiftsObj}
     * @memberof NestedRentElementRecord
     */
    subrentWorkedShifts?: ShiftsObj;
    /**
     * Базовая цена за штуку, коп. Берется из каталожной цены на момент создания
     * @type {number}
     * @memberof NestedRentElementRecord
     */
    basePriceAtTheTimeOfCreation: number;
    /**
     * 
     * @type {PricingSchemeExternalRepresentationObj}
     * @memberof NestedRentElementRecord
     */
    effectivePricingScheme?: PricingSchemeExternalRepresentationObj;
    /**
     * Цена за штуку, коп.
     * @type {number}
     * @memberof NestedRentElementRecord
     */
    effectivePrice: number;
    /**
     * Количество экземпляров
     * @type {number}
     * @memberof NestedRentElementRecord
     */
    instanceCount: number;
    /**
     * Число анонимных экземпляров (не счетных, а отслеживаемых, но анонимных)
     * @type {number}
     * @memberof NestedRentElementRecord
     */
    anonymousInstanceCount: number;
    /**
     * Суммарная стоимость до вычета скидки, коп.
     * @type {number}
     * @memberof NestedRentElementRecord
     */
    totalPriceBeforeDiscount: number;
    /**
     * Итоговая стоимость за экземпляр, коп.
     * @type {number}
     * @memberof NestedRentElementRecord
     */
    finalInstancePrice: number;
    /**
     * Итоговая суммарная стоимость, коп. (Считается на основе округленной finalInstancePrice)
     * @type {number}
     * @memberof NestedRentElementRecord
     */
    finalTotalPrice: number;
    /**
     * Идентификатор основной категории
     * @type {number}
     * @memberof NestedRentElementRecord
     */
    mainCategoryId?: number;
    /**
     * Наименование основной категории
     * @type {string}
     * @memberof NestedRentElementRecord
     */
    mainCategoryName?: string;
    /**
     * Идентификаторы категорий
     * @type {Array<number>}
     * @memberof NestedRentElementRecord
     */
    categoryIds?: Array<number>;
    /**
     * 
     * @type {RentStateCodeEnum}
     * @memberof NestedRentElementRecord
     */
    stateCode: RentStateCodeEnum;
    /**
     * 
     * @type {RentStateCodeEnum}
     * @memberof NestedRentElementRecord
     */
    stateCodeToReturnBeforeCancel?: RentStateCodeEnum;
    /**
     * Доступен ли возврат в статус до отменены в правке
     * @type {boolean}
     * @memberof NestedRentElementRecord
     */
    stateCodeToReturnBeforeCancelAvailableWithoutCorrection?: boolean;
    /**
     * Коды резолюций
     * @type {Array<RentElementResolutionCodeEnum>}
     * @memberof NestedRentElementRecord
     */
    resolutionCodes?: Array<RentElementResolutionCodeEnum>;
    /**
     * Активный статус, либо, в случае набора, хотя бы одно вложенное обязательство в активном статусе
     * @type {boolean}
     * @memberof NestedRentElementRecord
     */
    active: boolean;
    /**
     * 
     * @type {ProblemAndWarningRentIndicatorsRecordObj}
     * @memberof NestedRentElementRecord
     */
    problemsAndWarnings: ProblemAndWarningRentIndicatorsRecordObj;
    /**
     * Количество недостающих экземпляров (нехватка)
     * @type {number}
     * @memberof NestedRentElementRecord
     */
    shortageInstanceCount: number;
    /**
     * Список идентификаторов экземпляров по которым нехватка именно на текущем обязательстве
     * @type {Array<number>}
     * @memberof NestedRentElementRecord
     */
    instanceWithCurrentElementShortageIds?: Array<number>;
    /**
     * Коды доступных переходов для данного обазательства и пользователя (корректировка на передается, обрабатывается на клиенте)
     * @type {Array<OperationTypeCodeEnum>}
     * @memberof NestedRentElementRecord
     */
    availableTransitionCodes?: Array<OperationTypeCodeEnum>;
    /**
     * Коды доступных целевых статусов корректировки
     * @type {Array<RentStateCodeEnum>}
     * @memberof NestedRentElementRecord
     */
    availableTargetCorrectionStates?: Array<RentStateCodeEnum>;
    /**
     * Дата и время последней активности (в данном случае только дата последней операции)
     * @type {Date}
     * @memberof NestedRentElementRecord
     */
    lastActivityDate: Date;
    /**
     * Базовая закупочная стоимость, коп.
     * @type {number}
     * @memberof NestedRentElementRecord
     */
    basePurchaseCost?: number;
    /**
     * Сумма закупочных стоимостей, коп.
     * @type {number}
     * @memberof NestedRentElementRecord
     */
    purchaseCostSum?: number;
    /**
     * 
     * @type {Array<CustomFieldValueObjRecord>}
     * @memberof NestedRentElementRecord
     */
    customFieldValues?: Array<CustomFieldValueObjRecord>;
    /**
     * Идентификаторы пользовательских полей обязательств
     * @type {Array<number>}
     * @memberof NestedRentElementRecord
     */
    customFieldIds?: Array<number>;
    /**
     * Идентификатор элемента
     * @type {number}
     * @memberof NestedRentElementRecord
     */
    id: number;
    /**
     * Бизнес версия
     * @type {number}
     * @memberof NestedRentElementRecord
     */
    businessVersion: number;
    /**
     * Является ли основным компанентом набора
     * @type {boolean}
     * @memberof NestedRentElementRecord
     */
    mainKitMember: boolean;
}
/**
 * 
 * @export
 * @interface NestedRentElementRecordAllOf
 */
export interface NestedRentElementRecordAllOf {
    /**
     * Идентификатор элемента
     * @type {number}
     * @memberof NestedRentElementRecordAllOf
     */
    id: number;
    /**
     * Бизнес версия
     * @type {number}
     * @memberof NestedRentElementRecordAllOf
     */
    businessVersion: number;
    /**
     * Является ли основным компанентом набора
     * @type {boolean}
     * @memberof NestedRentElementRecordAllOf
     */
    mainKitMember: boolean;
}
/**
 * Список пар версий и идентификаторов сущностей
 * @export
 * @interface NomenclatureEntityList
 */
export interface NomenclatureEntityList {
    /**
     * 
     * @type {Array<NomenclatureEntityObj>}
     * @memberof NomenclatureEntityList
     */
    entities: Array<NomenclatureEntityObj>;
}
/**
 * Тип, версия и идентификатор сущности
 * @export
 * @interface NomenclatureEntityObj
 */
export interface NomenclatureEntityObj {
    /**
     * Идентификатор
     * @type {number}
     * @memberof NomenclatureEntityObj
     */
    id: number;
    /**
     * Версия
     * @type {number}
     * @memberof NomenclatureEntityObj
     */
    businessVersion: number;
    /**
     * 
     * @type {NomenclatureEntityTypeCodeEnum}
     * @memberof NomenclatureEntityObj
     */
    nomenclatureEntityTypeCode: NomenclatureEntityTypeCodeEnum;
}
/**
 * Код типа номенклатуры
 * @export
 * @enum {string}
 */
export enum NomenclatureEntityTypeCodeEnum {
    KIT = 'KIT',
    PRODUCT = 'PRODUCT',
    VARIANT = 'VARIANT',
    INSTANCE = 'INSTANCE'
}

/**
 * Финансовые поля сущности NomenclatureRecord, которые могут быть выгружены в список Excel
 * @export
 * @enum {string}
 */
export enum NomenclatureFinancialFieldsTypeCode {
    PricePerShift = 'pricePerShift'
}

/**
 * 
 * @export
 * @interface NomenclatureIdAndTimetableVersionObj
 */
export interface NomenclatureIdAndTimetableVersionObj {
    /**
     * Идентификатор
     * @type {number}
     * @memberof NomenclatureIdAndTimetableVersionObj
     */
    id: number;
    /**
     * Версия календарей стеков состяния
     * @type {number}
     * @memberof NomenclatureIdAndTimetableVersionObj
     */
    timetableVersion: number;
}
/**
 * Не финансовые поля сущности NomenclatureRecord, которые могут быть выгружены в список Excel
 * @export
 * @enum {string}
 */
export enum NomenclatureNotFinancialFieldsTypeCode {
    ProductShortName = 'productShortName',
    VariantName = 'variantName',
    ExternalCode = 'externalCode',
    StateCode = 'stateCode',
    StockInstanceCount = 'stockInstanceCount',
    UnderMaintenanceInstanceCount = 'underMaintenanceInstanceCount',
    SubrentedInstanceCount = 'subrentedInstanceCount',
    RentedInstanceCount = 'rentedInstanceCount',
    WarehouseInstanceCount = 'warehouseInstanceCount',
    LastActivityDate = 'lastActivityDate'
}

/**
 * Списочное представление типа экземпляра - продукта или варианта
 * @export
 * @interface NomenclatureRecord
 */
export interface NomenclatureRecord {
    /**
     * Для отметки выбранных строк в спике номенклатуры, генерируется при сохранении в ES
     * @type {string}
     * @memberof NomenclatureRecord
     */
    uuid?: string;
    /**
     * Используется для определения нехватки обязательства - является либо продуктом без вариантов, либо вариантом, (спец. маркер т.к. комбинации параметров не поддерживаются в простых документах ES)
     * @type {boolean}
     * @memberof NomenclatureRecord
     */
    identifiesRentElementAvailability: boolean;
    /**
     * Показывать в списке номенклатуры при добавлении к составу операции из инвентаря
     * @type {boolean}
     * @memberof NomenclatureRecord
     */
    visible: boolean;
    /**
     * Показывает, что может быть добавлено в набор (либо продукт без вариантов либо вариант продукта)
     * @type {boolean}
     * @memberof NomenclatureRecord
     */
    addableToKit: boolean;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof NomenclatureRecord
     */
    businessVersion: number;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof NomenclatureRecord
     */
    businessAccountId: number;
    /**
     * Идентификатор набора
     * @type {number}
     * @memberof NomenclatureRecord
     */
    kitId?: number;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof NomenclatureRecord
     */
    productId: number;
    /**
     * Идентификатор варианта, если не variantBased - null
     * @type {number}
     * @memberof NomenclatureRecord
     */
    variantId?: number;
    /**
     * 
     * @type {NomenclatureEntityTypeCodeEnum}
     * @memberof NomenclatureRecord
     */
    nomenclatureEntityTypeCode: NomenclatureEntityTypeCodeEnum;
    /**
     * 
     * @type {InstanceTrackingTypeCodeEnum}
     * @memberof NomenclatureRecord
     */
    instanceTrackingTypeCode?: InstanceTrackingTypeCodeEnum;
    /**
     * Количество вариантов которые относятся к этой записи, если isVariant = false, иначе null
     * @type {number}
     * @memberof NomenclatureRecord
     */
    childVariantCount?: number;
    /**
     * 
     * @type {NomenclatureStateCodeEnum}
     * @memberof NomenclatureRecord
     */
    stateCode: NomenclatureStateCodeEnum;
    /**
     * Коды доступных переходов для данного продукта и пользователя
     * @type {Array<SimpleTransitionCodeEnum>}
     * @memberof NomenclatureRecord
     */
    availableTransitionCodes?: Array<SimpleTransitionCodeEnum>;
    /**
     * Находится ли в архиве
     * @type {boolean}
     * @memberof NomenclatureRecord
     */
    archive: boolean;
    /**
     * Может ли быть отправлен в архив (может, когда не является активным)
     * @type {boolean}
     * @memberof NomenclatureRecord
     */
    archivable?: boolean;
    /**
     * Возможно удалить данную сущность
     * @type {boolean}
     * @memberof NomenclatureRecord
     */
    deleteable: boolean;
    /**
     * Информация о главном изображении
     * @type {ImageObj}
     * @memberof NomenclatureRecord
     */
    mainImage?: ImageObj;
    /**
     * Короткое наименование продукта
     * @type {string}
     * @memberof NomenclatureRecord
     */
    productShortName: string;
    /**
     * Наименование варианта
     * @type {string}
     * @memberof NomenclatureRecord
     */
    variantName?: string;
    /**
     * Артикул
     * @type {string}
     * @memberof NomenclatureRecord
     */
    externalCode?: string;
    /**
     * Цена за смену, коп.
     * @type {number}
     * @memberof NomenclatureRecord
     */
    pricePerShift: number;
    /**
     * 
     * @type {PricingSchemeExternalRepresentationObj}
     * @memberof NomenclatureRecord
     */
    pricingScheme?: PricingSchemeExternalRepresentationObj;
    /**
     * 
     * @type {PricingSchemeModeCodeEnum}
     * @memberof NomenclatureRecord
     */
    pricingSchemeMode?: PricingSchemeModeCodeEnum;
    /**
     * Обязательный запас времени между обязательствами, мин. Для наборов не передается
     * @type {number}
     * @memberof NomenclatureRecord
     */
    requiredTimeIndentBetweenElementsInMinutes?: number;
    /**
     * Маркер, указывающий, что продукт имеет собственный обязательный запас времени между обязательствами
     * @type {boolean}
     * @memberof NomenclatureRecord
     */
    productHasOwnRequiredTimeIndentBetweenElements?: boolean;
    /**
     * Длительность смены, мин. Для наборов не передается
     * @type {number}
     * @memberof NomenclatureRecord
     */
    shiftLengthInMinutes?: number;
    /**
     * Маркер, указывающий, что продукт имеет собственную длительность смены, а не наследует значение по умолчанию для компании
     * @type {boolean}
     * @memberof NomenclatureRecord
     */
    productHasOwnShiftLength?: boolean;
    /**
     * Идентификаторы категорий
     * @type {Array<number>}
     * @memberof NomenclatureRecord
     */
    categoryIds: Array<number>;
    /**
     * Идентификатор основной категории
     * @type {number}
     * @memberof NomenclatureRecord
     */
    mainCategoryId?: number;
    /**
     * Наименование основной категории
     * @type {string}
     * @memberof NomenclatureRecord
     */
    mainCategoryName?: string;
    /**
     * 
     * @type {ProblemAndWarningRentIndicatorsRecordObj}
     * @memberof NomenclatureRecord
     */
    problemsAndWarnings: ProblemAndWarningRentIndicatorsRecordObj;
    /**
     * Версия карты состояний
     * @type {number}
     * @memberof NomenclatureRecord
     */
    timetableVersion: number;
    /**
     * Карта состояний
     * @type {TimetableList}
     * @memberof NomenclatureRecord
     */
    stackMapList: TimetableList;
    /**
     * Дата и время первой доступности в инвентаре
     * @type {Date}
     * @memberof NomenclatureRecord
     */
    firstInventoryDate?: Date;
    /**
     * 
     * @type {Array<CustomFieldValueObjRecord>}
     * @memberof NomenclatureRecord
     */
    customFieldValues: Array<CustomFieldValueObjRecord>;
    /**
     * Коды экземпляров
     * @type {Array<number>}
     * @memberof NomenclatureRecord
     */
    instanceIds?: Array<number>;
    /**
     * 
     * @type {WarehousePresenceCountersObj}
     * @memberof NomenclatureRecord
     */
    warehousePresenceCounters: WarehousePresenceCountersObj;
    /**
     * Дата и время последней активности (изменение сущности либо операция над связанными обязательствами)
     * @type {Date}
     * @memberof NomenclatureRecord
     */
    lastActivityDate: Date;
    /**
     * Идентификаторы пользовательских полей продукта - у варианта и продукта
     * @type {Array<number>}
     * @memberof NomenclatureRecord
     */
    customFieldIds: Array<number>;
}
/**
 * Список записей представления типа экземпляра - продукта или варианта
 * @export
 * @interface NomenclatureRecordList
 */
export interface NomenclatureRecordList {
    /**
     * 
     * @type {ListAttributesInfo}
     * @memberof NomenclatureRecordList
     */
    listAttributes: ListAttributesInfo;
    /**
     * 
     * @type {Array<NomenclatureRecord>}
     * @memberof NomenclatureRecordList
     */
    records: Array<NomenclatureRecord>;
    /**
     * 
     * @type {Array<IdDatePairObj>}
     * @memberof NomenclatureRecordList
     */
    elementsDelayedReturnDates?: Array<IdDatePairObj>;
}
/**
 * 
 * @export
 * @interface NomenclatureRecordListAllOf
 */
export interface NomenclatureRecordListAllOf {
    /**
     * 
     * @type {Array<IdDatePairObj>}
     * @memberof NomenclatureRecordListAllOf
     */
    elementsDelayedReturnDates?: Array<IdDatePairObj>;
    /**
     * 
     * @type {Array<NomenclatureRecord>}
     * @memberof NomenclatureRecordListAllOf
     */
    records: Array<NomenclatureRecord>;
}
/**
 * Код статуса номенклатуры
 * @export
 * @enum {string}
 */
export enum NomenclatureStateCodeEnum {
    NEW = 'NEW',
    ACTIVE = 'ACTIVE',
    NOTAVAILABLE = 'NOT_AVAILABLE'
}

/**
 * Информация о номенклатуре и ее существующих экземплярах или обязательствах
 * @export
 * @interface NomenclatureWithInstancesAndRentElements
 */
export interface NomenclatureWithInstancesAndRentElements {
    /**
     * 
     * @type {NomenclatureRecord}
     * @memberof NomenclatureWithInstancesAndRentElements
     */
    info: NomenclatureRecord;
    /**
     * 
     * @type {Array<InstanceWithRentElements>}
     * @memberof NomenclatureWithInstancesAndRentElements
     */
    instances?: Array<InstanceWithRentElements>;
    /**
     * 
     * @type {Array<RentElementRecord>}
     * @memberof NomenclatureWithInstancesAndRentElements
     */
    rentElements?: Array<RentElementRecord>;
}
/**
 * 
 * @export
 * @interface NomenclatureWithInstancesAndRentElementsList
 */
export interface NomenclatureWithInstancesAndRentElementsList {
    /**
     * 
     * @type {ListAttributesInfo}
     * @memberof NomenclatureWithInstancesAndRentElementsList
     */
    listAttributes: ListAttributesInfo;
    /**
     * 
     * @type {Array<NomenclatureWithInstancesAndRentElements>}
     * @memberof NomenclatureWithInstancesAndRentElementsList
     */
    entities: Array<NomenclatureWithInstancesAndRentElements>;
}
/**
 * Директива жизненного цикла номенклатуры - изменение статуса и архивации сущности
 * @export
 * @interface NomenclatureWorkflowDirective
 */
export interface NomenclatureWorkflowDirective {
    /**
     * Идетификатор сущности
     * @type {number}
     * @memberof NomenclatureWorkflowDirective
     */
    id: number;
    /**
     * Версия
     * @type {number}
     * @memberof NomenclatureWorkflowDirective
     */
    businessVersion: number;
    /**
     * Новое значение маркера нахождения в архиве
     * @type {boolean}
     * @memberof NomenclatureWorkflowDirective
     */
    archive?: boolean;
    /**
     * Код перехода между статусами согласно ЖЦ
     * @type {string}
     * @memberof NomenclatureWorkflowDirective
     */
    transitionCode?: string;
    /**
     * 
     * @type {NomenclatureEntityTypeCodeEnum}
     * @memberof NomenclatureWorkflowDirective
     */
    nomenclatureEntityTypeCode: NomenclatureEntityTypeCodeEnum;
}
/**
 * 
 * @export
 * @interface NomenclatureWorkflowDirectiveAllOf
 */
export interface NomenclatureWorkflowDirectiveAllOf {
    /**
     * 
     * @type {NomenclatureEntityTypeCodeEnum}
     * @memberof NomenclatureWorkflowDirectiveAllOf
     */
    nomenclatureEntityTypeCode: NomenclatureEntityTypeCodeEnum;
    /**
     * Новое значение маркера нахождения в архиве
     * @type {boolean}
     * @memberof NomenclatureWorkflowDirectiveAllOf
     */
    archive?: boolean;
    /**
     * Код перехода между статусами согласно ЖЦ
     * @type {string}
     * @memberof NomenclatureWorkflowDirectiveAllOf
     */
    transitionCode?: string;
}
/**
 * Множество директив жизненного цикла номенклатуры
 * @export
 * @interface NomenclatureWorkflowDirectiveBulk
 */
export interface NomenclatureWorkflowDirectiveBulk {
    /**
     * 
     * @type {Array<NomenclatureWorkflowDirective>}
     * @memberof NomenclatureWorkflowDirectiveBulk
     */
    directives: Array<NomenclatureWorkflowDirective>;
}
/**
 * Настройки уведомлений (в одном БА или пресет по умолчанию для применения в разных БА)
 * @export
 * @interface NotificationCustomizationObj
 */
export interface NotificationCustomizationObj {
    /**
     * Идентификатор БА, к которому относится настройка, null - если это настройка схемы по умолчанию
     * @type {number}
     * @memberof NotificationCustomizationObj
     */
    businessAccountId?: number;
    /**
     * Краткое наименование бизнес аккаунта
     * @type {string}
     * @memberof NotificationCustomizationObj
     */
    businessAccountShortName?: string;
    /**
     * Уведомления включены
     * @type {boolean}
     * @memberof NotificationCustomizationObj
     */
    enabled: boolean;
    /**
     * Фильтр по проектам
     * @type {string}
     * @memberof NotificationCustomizationObj
     */
    projectFilter: NotificationCustomizationObjProjectFilterEnum;
    /**
     * Фильтр по типам событий
     * @type {Array<string>}
     * @memberof NotificationCustomizationObj
     */
    eventFilter: Array<NotificationCustomizationObjEventFilterEnum>;
}

/**
    * @export
    * @enum {string}
    */
export enum NotificationCustomizationObjProjectFilterEnum {
    ALL = 'ALL',
    ASSIGNED = 'ASSIGNED'
}
/**
    * @export
    * @enum {string}
    */
export enum NotificationCustomizationObjEventFilterEnum {
    ASSIGNMENT = 'ASSIGNMENT',
    WARNING = 'WARNING',
    PROBLEM = 'PROBLEM',
    RENTACTIVITY = 'RENT_ACTIVITY',
    RENTCORRECTION = 'RENT_CORRECTION',
    RENTLOSTORBROKEN = 'RENT_LOST_OR_BROKEN',
    NEWORDERFROMSTOREFRONT = 'NEW_ORDER_FROM_STOREFRONT',
    NEWPAYMENTFROMSTOREFRONT = 'NEW_PAYMENT_FROM_STOREFRONT',
    ORDERFROMSTOREFRONTCANCELED = 'ORDER_FROM_STOREFRONT_CANCELED',
    ASSIGNMENTTOWORK = 'ASSIGNMENT_TO_WORK'
}

/**
 * Схема настроек уведомлений пользователя
 * @export
 * @interface NotificationSchemaInfoAbstractCommons
 */
export interface NotificationSchemaInfoAbstractCommons {
    /**
     * Идентификатор
     * @type {number}
     * @memberof NotificationSchemaInfoAbstractCommons
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof NotificationSchemaInfoAbstractCommons
     */
    businessVersion: number;
    /**
     * Уведомления глобально включены
     * @type {boolean}
     * @memberof NotificationSchemaInfoAbstractCommons
     */
    globalEnabled: boolean;
    /**
     * Уведомления о собственных действиях глобально включены
     * @type {boolean}
     * @memberof NotificationSchemaInfoAbstractCommons
     */
    globalShowOwnActions: boolean;
    /**
     * Настройки уведомлений применяемые в бизнес аккаунтах по умолчанию
     * @type {NotificationCustomizationObj}
     * @memberof NotificationSchemaInfoAbstractCommons
     */
    defaultCustomization?: NotificationCustomizationObj;
    /**
     * Список настроек уведомлений применяемых в бизнес аккаунтах
     * @type {Array<NotificationCustomizationObj>}
     * @memberof NotificationSchemaInfoAbstractCommons
     */
    baCustomizations: Array<NotificationCustomizationObj>;
}
/**
 * Схема настроек уведомлений пользователя состояние чтения
 * @export
 * @interface NotificationSchemaInfoRead
 */
export interface NotificationSchemaInfoRead {
    /**
     * Идентификатор
     * @type {number}
     * @memberof NotificationSchemaInfoRead
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof NotificationSchemaInfoRead
     */
    businessVersion: number;
    /**
     * Уведомления глобально включены
     * @type {boolean}
     * @memberof NotificationSchemaInfoRead
     */
    globalEnabled: boolean;
    /**
     * Уведомления о собственных действиях глобально включены
     * @type {boolean}
     * @memberof NotificationSchemaInfoRead
     */
    globalShowOwnActions: boolean;
    /**
     * Настройки уведомлений применяемые в бизнес аккаунтах по умолчанию
     * @type {NotificationCustomizationObj}
     * @memberof NotificationSchemaInfoRead
     */
    defaultCustomization?: NotificationCustomizationObj;
    /**
     * Список настроек уведомлений применяемых в бизнес аккаунтах
     * @type {Array<NotificationCustomizationObj>}
     * @memberof NotificationSchemaInfoRead
     */
    baCustomizations: Array<NotificationCustomizationObj>;
    /**
     * Идентификатор пользователя
     * @type {number}
     * @memberof NotificationSchemaInfoRead
     */
    userId: number;
}
/**
 * 
 * @export
 * @interface NotificationSchemaInfoReadAllOf
 */
export interface NotificationSchemaInfoReadAllOf {
    /**
     * Идентификатор пользователя
     * @type {number}
     * @memberof NotificationSchemaInfoReadAllOf
     */
    userId: number;
}
/**
 * 
 * @export
 * @interface NotificationSchemaInfoUpdate
 */
export interface NotificationSchemaInfoUpdate extends NotificationSchemaInfoAbstractCommons {
}
/**
 * Данные по доступности, рассчитанному фактору и полной стоимости торгового предложения на основе периода
 * @export
 * @interface OfferByIntervalCalculation
 */
export interface OfferByIntervalCalculation {
    /**
     * Рассчитанный по периоду фактор
     * @type {number}
     * @memberof OfferByIntervalCalculation
     */
    factor: number;
    /**
     * Цена за все экземпляры по рассчитаному фактору с учетом ценовых схем
     * @type {number}
     * @memberof OfferByIntervalCalculation
     */
    finalTotalPrice: number;
    /**
     * Количество доступных экземпляров в целевой период
     * @type {number}
     * @memberof OfferByIntervalCalculation
     */
    availableInstanceCount: number;
}
/**
 * 
 * @export
 * @interface OfferCategoryObj
 */
export interface OfferCategoryObj {
    /**
     * Идентификатор
     * @type {number}
     * @memberof OfferCategoryObj
     */
    id: number;
    /**
     * Наименование
     * @type {string}
     * @memberof OfferCategoryObj
     */
    name: string;
}
/**
 * Значение поля торгового предложения - состояние чтения
 * @export
 * @interface OfferCustomFieldValueObjRead
 */
export interface OfferCustomFieldValueObjRead {
    /**
     * Идентификатор
     * @type {number}
     * @memberof OfferCustomFieldValueObjRead
     */
    id: number;
    /**
     * Полное наименование поля
     * @type {string}
     * @memberof OfferCustomFieldValueObjRead
     */
    name: string;
    /**
     * Признак отображения в каталоге
     * @type {boolean}
     * @memberof OfferCustomFieldValueObjRead
     */
    showInCatalog: boolean;
    /**
     * 
     * @type {CustomFieldDataTypeCodeEnum}
     * @memberof OfferCustomFieldValueObjRead
     */
    typeCode: CustomFieldDataTypeCodeEnum;
    /**
     * Значения в строковом представлении
     * @type {Array<string>}
     * @memberof OfferCustomFieldValueObjRead
     */
    values?: Array<string>;
}
/**
 * Компонент набора торгового предложения
 * @export
 * @interface OfferKitMemberObjRead
 */
export interface OfferKitMemberObjRead {
    /**
     * 
     * @type {ImageObj}
     * @memberof OfferKitMemberObjRead
     */
    image?: ImageObj;
    /**
     * Порядок в наборе
     * @type {number}
     * @memberof OfferKitMemberObjRead
     */
    memberOrder: number;
    /**
     * Количество экземпляров
     * @type {number}
     * @memberof OfferKitMemberObjRead
     */
    instanceCount: number;
    /**
     * Идентификатор продукта
     * @type {number}
     * @memberof OfferKitMemberObjRead
     */
    productId: number;
    /**
     * Короткое наименование продукта
     * @type {string}
     * @memberof OfferKitMemberObjRead
     */
    productShortName: string;
    /**
     * Тип сущности на которую ссылается - Вариант продукта если true, Продукт если false
     * @type {boolean}
     * @memberof OfferKitMemberObjRead
     */
    linksProductVariant: boolean;
    /**
     * Идентификатор варианта
     * @type {number}
     * @memberof OfferKitMemberObjRead
     */
    variantId?: number;
    /**
     * Наименование варианта
     * @type {string}
     * @memberof OfferKitMemberObjRead
     */
    variantName?: string;
}
/**
 * Код типа номенклатуры включая специальность
 * @export
 * @enum {string}
 */
export enum OfferNomenclatureEntityTypeCodeEnum {
    KIT = 'KIT',
    PRODUCT = 'PRODUCT',
    VARIANT = 'VARIANT',
    PROFESSION = 'PROFESSION'
}

/**
 * Объект описания ценовой схемы торгового предложения
 * @export
 * @interface OfferPricingSchemeObj
 */
export interface OfferPricingSchemeObj {
    /**
     * 
     * @type {PricingSchemeTypeCodeEnum}
     * @memberof OfferPricingSchemeObj
     */
    typeCode?: PricingSchemeTypeCodeEnum;
    /**
     * Шаги ступенчатой ценовой схемы
     * @type {Array<OfferPricingSchemeStepObj>}
     * @memberof OfferPricingSchemeObj
     */
    steps: Array<OfferPricingSchemeStepObj>;
}
/**
 * Шаг ценовой схемы - ступенчатой или многоуровневой
 * @export
 * @interface OfferPricingSchemeStepObj
 */
export interface OfferPricingSchemeStepObj {
    /**
     * Количество смен после которого применяется правило
     * @type {number}
     * @memberof OfferPricingSchemeStepObj
     */
    effectsAfterShiftCount: number;
    /**
     * Скидка относительно базовой цены
     * @type {number}
     * @memberof OfferPricingSchemeStepObj
     */
    extraDiscount: number;
}
/**
 * Финансовые поля сущности ProjectRecord типа OFFER, которые могут быть выгружены в список Excel
 * @export
 * @enum {string}
 */
export enum OfferProjectFinancialFieldsTypeCode {
    CostOfRent = 'costOfRent',
    CostOfWork = 'costOfWork',
    CostOfExpenses = 'costOfExpenses',
    SumToBePaidAfterTaxes = 'sumToBePaidAfterTaxes',
    DefaultDiscount = 'defaultDiscount',
    PartOfThePrice = 'partOfThePrice'
}

/**
 * Не финансовые поля сущности ProjectRecord типа OFFER, которые могут быть выгружены в список Excel
 * @export
 * @enum {string}
 */
export enum OfferProjectNotFinancialFieldsTypeCode {
    ShortName = 'shortName',
    StateCode = 'stateCode',
    MainProjectShortName = 'mainProjectShortName',
    AssigneeShortName = 'assigneeShortName',
    LastActivityDate = 'lastActivityDate',
    RenterShortName = 'renterShortName',
    LocationName = 'locationName',
    TagsString = 'tagsString',
    StartDate = 'startDate',
    TargetFinishDate = 'targetFinishDate',
    ContactPersonShortName = 'contactPersonShortName'
}

/**
 * Списочное представление торгового предложения
 * @export
 * @interface OfferRecord
 */
export interface OfferRecord {
    /**
     * Идентификатор торгового предложения
     * @type {number}
     * @memberof OfferRecord
     */
    id: number;
    /**
     * 
     * @type {OfferNomenclatureEntityTypeCodeEnum}
     * @memberof OfferRecord
     */
    nomenclatureEntityTypeCode: OfferNomenclatureEntityTypeCodeEnum;
    /**
     * Является профессией
     * @type {boolean}
     * @memberof OfferRecord
     */
    profession?: boolean;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof OfferRecord
     */
    shortName: string;
    /**
     * Полное наименование
     * @type {string}
     * @memberof OfferRecord
     */
    fullName: string;
    /**
     * Описание
     * @type {string}
     * @memberof OfferRecord
     */
    description?: string;
    /**
     * Информация о главном изображении
     * @type {ImageObj}
     * @memberof OfferRecord
     */
    mainImage?: ImageObj;
    /**
     * Информация об изображениях помимо главного, порядок соответствует требуемому порядку отображения
     * @type {Array<ImageObj>}
     * @memberof OfferRecord
     */
    extraImages?: Array<ImageObj>;
    /**
     * Артикул
     * @type {string}
     * @memberof OfferRecord
     */
    externalCode?: string;
    /**
     * 
     * @type {FactorTypeCodeEnum}
     * @memberof OfferRecord
     */
    factorTypeCode: FactorTypeCodeEnum;
    /**
     * Длина смены в минутах (заполняется, если factorTypeCode = SHIFT)
     * @type {number}
     * @memberof OfferRecord
     */
    shiftLengthInMinutes?: number;
    /**
     * Цена единицу фактора, например, за час или за день
     * @type {number}
     * @memberof OfferRecord
     */
    pricePerFactor: number;
    /**
     * 
     * @type {OfferPricingSchemeObj}
     * @memberof OfferRecord
     */
    pricingScheme?: OfferPricingSchemeObj;
    /**
     * Скидка, отображаемая в витрине в маркетинговых целях
     * @type {number}
     * @memberof OfferRecord
     */
    storefrontDiscount: number;
    /**
     * Цена единицу фактора, кокой бы она была до применения скидки, отображаемой в маркетинговых целях
     * @type {number}
     * @memberof OfferRecord
     */
    pricePerFactorBeforeDiscount: number;
    /**
     * 
     * @type {OfferCategoryObj}
     * @memberof OfferRecord
     */
    mainCategory?: OfferCategoryObj;
    /**
     * Все категории (включая основную)
     * @type {Array<OfferCategoryObj>}
     * @memberof OfferRecord
     */
    categories?: Array<OfferCategoryObj>;
    /**
     * Характеристики (заполненные пользовательские и системные настраиваемые поля)
     * @type {Array<OfferCustomFieldValueObjRead>}
     * @memberof OfferRecord
     */
    customFields?: Array<OfferCustomFieldValueObjRead>;
    /**
     * Характеристики (заполненные пользовательские и системные настраиваемые поля)
     * @type {Array<OfferKitMemberObjRead>}
     * @memberof OfferRecord
     */
    kitMembers?: Array<OfferKitMemberObjRead>;
    /**
     * Дата и время последнего изменения торгового предложения
     * @type {Date}
     * @memberof OfferRecord
     */
    lastOfferChangeDate: Date;
}
/**
 * Список торговых предложений
 * @export
 * @interface OfferRecordList
 */
export interface OfferRecordList {
    /**
     * 
     * @type {Array<OfferRecord>}
     * @memberof OfferRecordList
     */
    records: Array<OfferRecord>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum OnExistingEntityFound {
    UPDATE = 'UPDATE',
    SKIP = 'SKIP',
    ERROR = 'ERROR'
}

/**
 * Информация которая передается в запросе получения возможных типов операции для определения возможностей по смене ее типа
 * @export
 * @interface OperationAvailableStatesRequestObj
 */
export interface OperationAvailableStatesRequestObj {
    /**
     * Идентификатор рамки активности
     * @type {number}
     * @memberof OperationAvailableStatesRequestObj
     */
    activityFrameId: number;
    /**
     * 
     * @type {RentActivityFrameTypeCodeEnum}
     * @memberof OperationAvailableStatesRequestObj
     */
    activityFrameType: RentActivityFrameTypeCodeEnum;
    /**
     * Список идентификаторов наборов
     * @type {Array<number>}
     * @memberof OperationAvailableStatesRequestObj
     */
    kitIds?: Array<number>;
    /**
     * Список идентификаторов продуктов
     * @type {Array<number>}
     * @memberof OperationAvailableStatesRequestObj
     */
    productIds?: Array<number>;
    /**
     * Список статусов
     * @type {Array<RentStateCodeEnum>}
     * @memberof OperationAvailableStatesRequestObj
     */
    rentStateCodes?: Array<RentStateCodeEnum>;
    /**
     * Были добавлены новые экземпляры - именованные или счетные
     * @type {boolean}
     * @memberof OperationAvailableStatesRequestObj
     */
    createdNewInstances?: boolean;
    /**
     * Был изменен вариант продукта
     * @type {boolean}
     * @memberof OperationAvailableStatesRequestObj
     */
    variantChanged?: boolean;
    /**
     * Обязательство, которое не может быть отменено без корректировки было отменено
     * @type {boolean}
     * @memberof OperationAvailableStatesRequestObj
     */
    rentElementCanceledByCorrection?: boolean;
    /**
     * Убран остаток обязательства
     * @type {boolean}
     * @memberof OperationAvailableStatesRequestObj
     */
    leftoverCleared?: boolean;
}
/**
 * Доступные типы операций и целевые статусы корректировки для смены из формы операции
 * @export
 * @interface OperationAvailableStatesResponseObj
 */
export interface OperationAvailableStatesResponseObj {
    /**
     * Список доступных типов операций
     * @type {Array<OperationTypeCodeEnum>}
     * @memberof OperationAvailableStatesResponseObj
     */
    operationTypeCodes?: Array<OperationTypeCodeEnum>;
    /**
     * Список доступных целевых статусов корректировки
     * @type {Array<RentStateCodeEnum>}
     * @memberof OperationAvailableStatesResponseObj
     */
    correctionTargetStateCodes?: Array<RentStateCodeEnum>;
}
/**
 * Условия аренды операции по умолчанию
 * @export
 * @interface OperationCommonRentTermsObj
 */
export interface OperationCommonRentTermsObj {
    /**
     * Скидка
     * @type {number}
     * @memberof OperationCommonRentTermsObj
     */
    discount: number;
    /**
     * Число смен (необязательное!)
     * @type {number}
     * @memberof OperationCommonRentTermsObj
     */
    shiftCount?: number;
    /**
     * Дата начала периода аренды
     * @type {Date}
     * @memberof OperationCommonRentTermsObj
     */
    rentPeriodStartDate: Date;
    /**
     * Дата завершения периода аренды
     * @type {Date}
     * @memberof OperationCommonRentTermsObj
     */
    rentPeriodEndDate: Date;
}
/**
 * Состав операции - набор аудитов с предшественниками и общих параметров аренды
 * @export
 * @interface OperationCompositionObj
 */
export interface OperationCompositionObj {
    /**
     * 
     * @type {RentAuditWithPredecessorRecordList}
     * @memberof OperationCompositionObj
     */
    auditList?: RentAuditWithPredecessorRecordList;
    /**
     * 
     * @type {OperationCommonRentTermsObj}
     * @memberof OperationCompositionObj
     */
    commonRentTerms: OperationCommonRentTermsObj;
}
/**
 * Финансовые поля сущности OperationRecord, которые могут быть выгружены в список Excel
 * @export
 * @enum {string}
 */
export enum OperationFinancialFieldsTypeCode {
    TotalDiscount = 'totalDiscount',
    FinalTotalPrice = 'finalTotalPrice'
}

/**
 * Вычисляемые показатели операции
 * @export
 * @interface OperationIndicatorsObj
 */
export interface OperationIndicatorsObj {
    /**
     * Общее число элементов
     * @type {number}
     * @memberof OperationIndicatorsObj
     */
    totalElementsCount: number;
    /**
     * Число активных элементов
     * @type {number}
     * @memberof OperationIndicatorsObj
     */
    activeElementsCount: number;
    /**
     * Общее число экземпляров
     * @type {number}
     * @memberof OperationIndicatorsObj
     */
    totalInstancesCount: number;
    /**
     * Число активных экземпляров
     * @type {number}
     * @memberof OperationIndicatorsObj
     */
    activeInstancesCount: number;
    /**
     * Суммарная стоимость до скидки, коп
     * @type {number}
     * @memberof OperationIndicatorsObj
     */
    totalPriceBeforeDiscount: number;
    /**
     * Итоговая суммарная стоимость, коп
     * @type {number}
     * @memberof OperationIndicatorsObj
     */
    finalTotalPrice: number;
    /**
     * Итоговая скидка в процентах
     * @type {number}
     * @memberof OperationIndicatorsObj
     */
    totalDiscount: number;
    /**
     * Процент скидки минимальный
     * @type {number}
     * @memberof OperationIndicatorsObj
     */
    discountMin: number;
    /**
     * Процент скидки максимальный
     * @type {number}
     * @memberof OperationIndicatorsObj
     */
    discountMax: number;
    /**
     * Минимальное число смен
     * @type {number}
     * @memberof OperationIndicatorsObj
     */
    shiftCountMin: number;
    /**
     * Максимальное число смен
     * @type {number}
     * @memberof OperationIndicatorsObj
     */
    shiftCountMax: number;
    /**
     * Минимальная дата начала периода аренды
     * @type {Date}
     * @memberof OperationIndicatorsObj
     */
    rentPeriodStartDateMin: Date;
    /**
     * Максимальная дата завершения периода аренды
     * @type {Date}
     * @memberof OperationIndicatorsObj
     */
    rentPeriodEndDateMax: Date;
}
/**
 * Абстрактное общее состояние операции
 * @export
 * @interface OperationInfoAbstractCommons
 */
export interface OperationInfoAbstractCommons extends BusinessAccountAbstractEntityInfo {
}
/**
 * Операция: абстрактное состояние при записи
 * @export
 * @interface OperationInfoAbstractWriteCommons
 */
export interface OperationInfoAbstractWriteCommons extends OperationInfoAbstractCommons {
}
/**
 * Операция: состояние при создании
 * @export
 * @interface OperationInfoCreate
 */
export interface OperationInfoCreate {
    /**
     * Идентификатор
     * @type {number}
     * @memberof OperationInfoCreate
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof OperationInfoCreate
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof OperationInfoCreate
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof OperationInfoCreate
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof OperationInfoCreate
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof OperationInfoCreate
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof OperationInfoCreate
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof OperationInfoCreate
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof OperationInfoCreate
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof OperationInfoCreate
     */
    businessAccountId?: number;
    /**
     * 
     * @type {string}
     * @memberof OperationInfoCreate
     */
    concurrentOperationUUID?: string;
    /**
     * 
     * @type {OperationCommonRentTermsObj}
     * @memberof OperationInfoCreate
     */
    commonRentTerms?: OperationCommonRentTermsObj;
    /**
     * 
     * @type {CanceledRentElementObjList}
     * @memberof OperationInfoCreate
     */
    canceledRentElements?: CanceledRentElementObjList;
    /**
     * Список наборов элементов
     * @type {Array<RentElementsKitObjWrite>}
     * @memberof OperationInfoCreate
     */
    rentElementsKits?: Array<RentElementsKitObjWrite>;
    /**
     * Список элементов
     * @type {Array<RentElementInfoWrite>}
     * @memberof OperationInfoCreate
     */
    rentElements?: Array<RentElementInfoWrite>;
    /**
     * Ссылка: идентификатор проекта (обязательно, если не указаны шаблон и поставка)
     * @type {number}
     * @memberof OperationInfoCreate
     */
    projectId?: number;
    /**
     * Ссылка: идентификатор проставки (обязательно, если не указаны шаблон и проект)
     * @type {number}
     * @memberof OperationInfoCreate
     */
    subrentId?: number;
    /**
     * Ссылка: идентификатор шаблона (обязательно, если не указаны проект и поставка)
     * @type {number}
     * @memberof OperationInfoCreate
     */
    templateId?: number;
    /**
     * 
     * @type {OperationTypeCodeEnum}
     * @memberof OperationInfoCreate
     */
    typeCode: OperationTypeCodeEnum;
    /**
     * 
     * @type {RentStateCodeEnum}
     * @memberof OperationInfoCreate
     */
    targetStateCode?: RentStateCodeEnum;
    /**
     * 
     * @type {OperationOriginCodeEnum}
     * @memberof OperationInfoCreate
     */
    originCode?: OperationOriginCodeEnum;
}
/**
 * 
 * @export
 * @interface OperationInfoCreateAllOf
 */
export interface OperationInfoCreateAllOf {
    /**
     * 
     * @type {string}
     * @memberof OperationInfoCreateAllOf
     */
    concurrentOperationUUID?: string;
    /**
     * 
     * @type {OperationCommonRentTermsObj}
     * @memberof OperationInfoCreateAllOf
     */
    commonRentTerms?: OperationCommonRentTermsObj;
    /**
     * 
     * @type {CanceledRentElementObjList}
     * @memberof OperationInfoCreateAllOf
     */
    canceledRentElements?: CanceledRentElementObjList;
    /**
     * Список наборов элементов
     * @type {Array<RentElementsKitObjWrite>}
     * @memberof OperationInfoCreateAllOf
     */
    rentElementsKits?: Array<RentElementsKitObjWrite>;
    /**
     * Список элементов
     * @type {Array<RentElementInfoWrite>}
     * @memberof OperationInfoCreateAllOf
     */
    rentElements?: Array<RentElementInfoWrite>;
    /**
     * Ссылка: идентификатор проекта (обязательно, если не указаны шаблон и поставка)
     * @type {number}
     * @memberof OperationInfoCreateAllOf
     */
    projectId?: number;
    /**
     * Ссылка: идентификатор проставки (обязательно, если не указаны шаблон и проект)
     * @type {number}
     * @memberof OperationInfoCreateAllOf
     */
    subrentId?: number;
    /**
     * Ссылка: идентификатор шаблона (обязательно, если не указаны проект и поставка)
     * @type {number}
     * @memberof OperationInfoCreateAllOf
     */
    templateId?: number;
    /**
     * 
     * @type {OperationTypeCodeEnum}
     * @memberof OperationInfoCreateAllOf
     */
    typeCode: OperationTypeCodeEnum;
    /**
     * 
     * @type {RentStateCodeEnum}
     * @memberof OperationInfoCreateAllOf
     */
    targetStateCode?: RentStateCodeEnum;
    /**
     * 
     * @type {OperationOriginCodeEnum}
     * @memberof OperationInfoCreateAllOf
     */
    originCode?: OperationOriginCodeEnum;
}
/**
 * Операция: состояние чтения
 * @export
 * @interface OperationInfoRead
 */
export interface OperationInfoRead {
    /**
     * Идентификатор
     * @type {number}
     * @memberof OperationInfoRead
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof OperationInfoRead
     */
    businessVersion: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof OperationInfoRead
     */
    creationAuthorId: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof OperationInfoRead
     */
    creationAuthorFullName: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof OperationInfoRead
     */
    creationDate: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof OperationInfoRead
     */
    lastUpdateAuthorId: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof OperationInfoRead
     */
    lastUpdateAuthorFullName: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof OperationInfoRead
     */
    lastUpdateDate: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof OperationInfoRead
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof OperationInfoRead
     */
    businessAccountId: number;
    /**
     * Ссылка: идентификатор проекта (обязательно, если не указаны поставка и шаблон)
     * @type {number}
     * @memberof OperationInfoRead
     */
    projectId?: number;
    /**
     * Выч: короткое наименование проекта (обязательно, если не указаны поставка и шаблон)
     * @type {string}
     * @memberof OperationInfoRead
     */
    projectShortName?: string;
    /**
     * 
     * @type {ProjectStateCodeEnum}
     * @memberof OperationInfoRead
     */
    projectStateCode?: ProjectStateCodeEnum;
    /**
     * Ссылка: идентификатор поставки (обязательно, если не указан проект и шаблон)
     * @type {number}
     * @memberof OperationInfoRead
     */
    subrentId?: number;
    /**
     * Выч: короткое наименование поставки (обязательно, если не указан проект и шаблон)
     * @type {string}
     * @memberof OperationInfoRead
     */
    subrentShortName?: string;
    /**
     * 
     * @type {SubrentStateCodeEnum}
     * @memberof OperationInfoRead
     */
    subrentStateCode?: SubrentStateCodeEnum;
    /**
     * Ссылка: идентификатор шаблона (обязательно, если не указан поставка и  проект)
     * @type {number}
     * @memberof OperationInfoRead
     */
    templateId?: number;
    /**
     * Выч: короткое наименование шаблона  (обязательно, если не указан поставка и  проект)
     * @type {string}
     * @memberof OperationInfoRead
     */
    templateShortName?: string;
    /**
     * Ссылка: идентификатор контрагента
     * @type {number}
     * @memberof OperationInfoRead
     */
    counterpartyId: number;
    /**
     * Выч: короткое наименование контрагента
     * @type {string}
     * @memberof OperationInfoRead
     */
    counterpartyShortName: string;
    /**
     * Ссылка: идентификатор основного проекта (заполняется только если операция относится к КП (OFFER))
     * @type {number}
     * @memberof OperationInfoRead
     */
    mainProjectId?: number;
    /**
     * Ссылка: короткое наименование основного проекта (заполняется только если операция относится к КП (OFFER))
     * @type {string}
     * @memberof OperationInfoRead
     */
    mainProjectShortName?: string;
    /**
     * Мнемонический код вида *КОД_ПРОЕКТА*-*МНЕМОКОД_ТИПА_ОПЕРАЦИИ**ПОРЯДКОВЫЙ_НОМЕР_ОПЕРАЦИИ_В_ПРОЕКТЕ*
     * @type {string}
     * @memberof OperationInfoRead
     */
    mnemoKey: string;
    /**
     * 
     * @type {OperationTypeCodeEnum}
     * @memberof OperationInfoRead
     */
    typeCode: OperationTypeCodeEnum;
    /**
     * 
     * @type {RentStateCodeEnum}
     * @memberof OperationInfoRead
     */
    targetStateCode?: RentStateCodeEnum;
    /**
     * 
     * @type {OperationOriginCodeEnum}
     * @memberof OperationInfoRead
     */
    originCode: OperationOriginCodeEnum;
    /**
     * Маркер нахождения в архиве
     * @type {boolean}
     * @memberof OperationInfoRead
     */
    archive: boolean;
    /**
     * Возможность отправить в архив (для единообразия, можно всегда, когда уже не в архиве)
     * @type {boolean}
     * @memberof OperationInfoRead
     */
    archivable: boolean;
    /**
     * Маркер того, что операция была создана автоматически
     * @type {boolean}
     * @memberof OperationInfoRead
     */
    automaticallyCreated: boolean;
    /**
     * 
     * @type {RentIndicatorsObj}
     * @memberof OperationInfoRead
     */
    rentIndicators: RentIndicatorsObj;
    /**
     * 
     * @type {OperationIndicatorsObj}
     * @memberof OperationInfoRead
     */
    operationIndicators: OperationIndicatorsObj;
    /**
     * Проект является КП. Обязательно если операция относится к проекту или КП
     * @type {boolean}
     * @memberof OperationInfoRead
     */
    projectIsOffer?: boolean;
    /**
     * Проект является простым заказом. Обязательно если операция относится к проекту или КП
     * @type {boolean}
     * @memberof OperationInfoRead
     */
    projectIsSimpleOrder?: boolean;
}
/**
 * 
 * @export
 * @interface OperationInfoReadAllOf
 */
export interface OperationInfoReadAllOf {
    /**
     * Ссылка: идентификатор проекта (обязательно, если не указаны поставка и шаблон)
     * @type {number}
     * @memberof OperationInfoReadAllOf
     */
    projectId?: number;
    /**
     * Выч: короткое наименование проекта (обязательно, если не указаны поставка и шаблон)
     * @type {string}
     * @memberof OperationInfoReadAllOf
     */
    projectShortName?: string;
    /**
     * 
     * @type {ProjectStateCodeEnum}
     * @memberof OperationInfoReadAllOf
     */
    projectStateCode?: ProjectStateCodeEnum;
    /**
     * Ссылка: идентификатор поставки (обязательно, если не указан проект и шаблон)
     * @type {number}
     * @memberof OperationInfoReadAllOf
     */
    subrentId?: number;
    /**
     * Выч: короткое наименование поставки (обязательно, если не указан проект и шаблон)
     * @type {string}
     * @memberof OperationInfoReadAllOf
     */
    subrentShortName?: string;
    /**
     * 
     * @type {SubrentStateCodeEnum}
     * @memberof OperationInfoReadAllOf
     */
    subrentStateCode?: SubrentStateCodeEnum;
    /**
     * Ссылка: идентификатор шаблона (обязательно, если не указан поставка и  проект)
     * @type {number}
     * @memberof OperationInfoReadAllOf
     */
    templateId?: number;
    /**
     * Выч: короткое наименование шаблона  (обязательно, если не указан поставка и  проект)
     * @type {string}
     * @memberof OperationInfoReadAllOf
     */
    templateShortName?: string;
    /**
     * Ссылка: идентификатор контрагента
     * @type {number}
     * @memberof OperationInfoReadAllOf
     */
    counterpartyId: number;
    /**
     * Выч: короткое наименование контрагента
     * @type {string}
     * @memberof OperationInfoReadAllOf
     */
    counterpartyShortName: string;
    /**
     * Ссылка: идентификатор основного проекта (заполняется только если операция относится к КП (OFFER))
     * @type {number}
     * @memberof OperationInfoReadAllOf
     */
    mainProjectId?: number;
    /**
     * Ссылка: короткое наименование основного проекта (заполняется только если операция относится к КП (OFFER))
     * @type {string}
     * @memberof OperationInfoReadAllOf
     */
    mainProjectShortName?: string;
    /**
     * Мнемонический код вида *КОД_ПРОЕКТА*-*МНЕМОКОД_ТИПА_ОПЕРАЦИИ**ПОРЯДКОВЫЙ_НОМЕР_ОПЕРАЦИИ_В_ПРОЕКТЕ*
     * @type {string}
     * @memberof OperationInfoReadAllOf
     */
    mnemoKey: string;
    /**
     * 
     * @type {OperationTypeCodeEnum}
     * @memberof OperationInfoReadAllOf
     */
    typeCode: OperationTypeCodeEnum;
    /**
     * 
     * @type {RentStateCodeEnum}
     * @memberof OperationInfoReadAllOf
     */
    targetStateCode?: RentStateCodeEnum;
    /**
     * 
     * @type {OperationOriginCodeEnum}
     * @memberof OperationInfoReadAllOf
     */
    originCode: OperationOriginCodeEnum;
    /**
     * Маркер нахождения в архиве
     * @type {boolean}
     * @memberof OperationInfoReadAllOf
     */
    archive: boolean;
    /**
     * Возможность отправить в архив (для единообразия, можно всегда, когда уже не в архиве)
     * @type {boolean}
     * @memberof OperationInfoReadAllOf
     */
    archivable: boolean;
    /**
     * Маркер того, что операция была создана автоматически
     * @type {boolean}
     * @memberof OperationInfoReadAllOf
     */
    automaticallyCreated: boolean;
    /**
     * 
     * @type {RentIndicatorsObj}
     * @memberof OperationInfoReadAllOf
     */
    rentIndicators: RentIndicatorsObj;
    /**
     * 
     * @type {OperationIndicatorsObj}
     * @memberof OperationInfoReadAllOf
     */
    operationIndicators: OperationIndicatorsObj;
    /**
     * Проект является КП. Обязательно если операция относится к проекту или КП
     * @type {boolean}
     * @memberof OperationInfoReadAllOf
     */
    projectIsOffer?: boolean;
    /**
     * Проект является простым заказом. Обязательно если операция относится к проекту или КП
     * @type {boolean}
     * @memberof OperationInfoReadAllOf
     */
    projectIsSimpleOrder?: boolean;
}
/**
 * Операция: состояние при изменении (изменяем только комментарий)
 * @export
 * @interface OperationInfoUpdate
 */
export interface OperationInfoUpdate extends OperationInfoAbstractWriteCommons {
}
/**
 * Не финансовые поля сущности OperationRecord, которые могут быть выгружены в список Excel
 * @export
 * @enum {string}
 */
export enum OperationNotFinancialFieldsTypeCode {
    MnemoKey = 'mnemoKey',
    TypeCode = 'typeCode',
    TargetStateCode = 'targetStateCode',
    TotalElementsCount = 'totalElementsCount',
    ActiveElementsCount = 'activeElementsCount',
    TotalInstancesCount = 'totalInstancesCount',
    ActiveInstancesCount = 'activeInstancesCount',
    CounterpartyShortName = 'counterpartyShortName',
    ProjectShortName = 'projectShortName',
    SubrentShortName = 'subrentShortName',
    CreationAuthorShortName = 'creationAuthorShortName',
    CreationDate = 'creationDate'
}

/**
 * Код источника операции, выбор из перечисления
 * @export
 * @enum {string}
 */
export enum OperationOriginCodeEnum {
    FORM = 'FORM',
    QUICK = 'QUICK',
    AUTO = 'AUTO'
}

/**
 * Списочное представление операции
 * @export
 * @interface OperationRecord
 */
export interface OperationRecord {
    /**
     * Идентификатор
     * @type {number}
     * @memberof OperationRecord
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof OperationRecord
     */
    businessVersion: number;
    /**
     * Ссылка: Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof OperationRecord
     */
    businessAccountId: number;
    /**
     * Ссылка: идентификатор контрагента
     * @type {number}
     * @memberof OperationRecord
     */
    counterpartyId: number;
    /**
     * Выч: короткое наименование контрагента
     * @type {string}
     * @memberof OperationRecord
     */
    counterpartyShortName: string;
    /**
     * Ссылка: идентификатор проекта (обязательно, если не указан поставка и шаблон)
     * @type {number}
     * @memberof OperationRecord
     */
    projectId?: number;
    /**
     * Выч: короткое наименование проекта (обязательно, если не указан поставка и шаблон)
     * @type {string}
     * @memberof OperationRecord
     */
    projectShortName?: string;
    /**
     * Ссылка: идентификатор поставки (обязательно, если не указан проект и шаблон)
     * @type {number}
     * @memberof OperationRecord
     */
    subrentId?: number;
    /**
     * Выч: короткое наименование поставки (обязательно, если не указан проект и шаблон)
     * @type {string}
     * @memberof OperationRecord
     */
    subrentShortName?: string;
    /**
     * Ссылка: идентификатор шаблона (обязательно, если не указан проект и поставка)
     * @type {number}
     * @memberof OperationRecord
     */
    templateId?: number;
    /**
     * Выч: короткое наименование шаблона  (обязательно, если не указан проект и поставка)
     * @type {string}
     * @memberof OperationRecord
     */
    templateShortName?: string;
    /**
     * Мнемонический код вида *КОД_ПРОЕКТА*-*МНЕМОКОД_ТИПА_ОПЕРАЦИИ**ПОРЯДКОВЫЙ_НОМЕР_ОПЕРАЦИИ_В_ПРОЕКТЕ*
     * @type {string}
     * @memberof OperationRecord
     */
    mnemoKey: string;
    /**
     * 
     * @type {OperationTypeCodeEnum}
     * @memberof OperationRecord
     */
    typeCode: OperationTypeCodeEnum;
    /**
     * 
     * @type {RentStateCodeEnum}
     * @memberof OperationRecord
     */
    targetStateCode?: RentStateCodeEnum;
    /**
     * 
     * @type {OperationOriginCodeEnum}
     * @memberof OperationRecord
     */
    originCode?: OperationOriginCodeEnum;
    /**
     * Маркер нахождения в архиве
     * @type {boolean}
     * @memberof OperationRecord
     */
    archive: boolean;
    /**
     * Возможность отправить в архив (для единообразия, можно всегда, когда уже не в архиве)
     * @type {boolean}
     * @memberof OperationRecord
     */
    archivable: boolean;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof OperationRecord
     */
    creationAuthorId: number;
    /**
     * Выч. по ссылке: короткое ФИО автора создания
     * @type {string}
     * @memberof OperationRecord
     */
    creationAuthorShortName: string;
    /**
     * 
     * @type {OperationIndicatorsObj}
     * @memberof OperationRecord
     */
    operationIndicators: OperationIndicatorsObj;
    /**
     * 
     * @type {ProblemAndWarningRentIndicatorsRecordObj}
     * @memberof OperationRecord
     */
    problemsAndWarnings: ProblemAndWarningRentIndicatorsRecordObj;
    /**
     * Дата и время создания операции
     * @type {Date}
     * @memberof OperationRecord
     */
    creationDate: Date;
    /**
     * Дата и время последней активности (измение сущности либо операция над связанными обязательствами)
     * @type {Date}
     * @memberof OperationRecord
     */
    lastActivityDate: Date;
    /**
     * Проект является КП. Обязательно если операция относится к проекту или КП
     * @type {boolean}
     * @memberof OperationRecord
     */
    projectIsOffer?: boolean;
    /**
     * Проект является простым заказом. Обязательно если операция относится к проекту или КП
     * @type {boolean}
     * @memberof OperationRecord
     */
    projectIsSimpleOrder?: boolean;
}
/**
 * 
 * @export
 * @interface OperationRecordAllOf
 */
export interface OperationRecordAllOf {
    /**
     * Ссылка: идентификатор контрагента
     * @type {number}
     * @memberof OperationRecordAllOf
     */
    counterpartyId: number;
    /**
     * Выч: короткое наименование контрагента
     * @type {string}
     * @memberof OperationRecordAllOf
     */
    counterpartyShortName: string;
    /**
     * Ссылка: идентификатор проекта (обязательно, если не указан поставка и шаблон)
     * @type {number}
     * @memberof OperationRecordAllOf
     */
    projectId?: number;
    /**
     * Выч: короткое наименование проекта (обязательно, если не указан поставка и шаблон)
     * @type {string}
     * @memberof OperationRecordAllOf
     */
    projectShortName?: string;
    /**
     * Ссылка: идентификатор поставки (обязательно, если не указан проект и шаблон)
     * @type {number}
     * @memberof OperationRecordAllOf
     */
    subrentId?: number;
    /**
     * Выч: короткое наименование поставки (обязательно, если не указан проект и шаблон)
     * @type {string}
     * @memberof OperationRecordAllOf
     */
    subrentShortName?: string;
    /**
     * Ссылка: идентификатор шаблона (обязательно, если не указан проект и поставка)
     * @type {number}
     * @memberof OperationRecordAllOf
     */
    templateId?: number;
    /**
     * Выч: короткое наименование шаблона  (обязательно, если не указан проект и поставка)
     * @type {string}
     * @memberof OperationRecordAllOf
     */
    templateShortName?: string;
    /**
     * Мнемонический код вида *КОД_ПРОЕКТА*-*МНЕМОКОД_ТИПА_ОПЕРАЦИИ**ПОРЯДКОВЫЙ_НОМЕР_ОПЕРАЦИИ_В_ПРОЕКТЕ*
     * @type {string}
     * @memberof OperationRecordAllOf
     */
    mnemoKey: string;
    /**
     * 
     * @type {OperationTypeCodeEnum}
     * @memberof OperationRecordAllOf
     */
    typeCode: OperationTypeCodeEnum;
    /**
     * 
     * @type {RentStateCodeEnum}
     * @memberof OperationRecordAllOf
     */
    targetStateCode?: RentStateCodeEnum;
    /**
     * 
     * @type {OperationOriginCodeEnum}
     * @memberof OperationRecordAllOf
     */
    originCode?: OperationOriginCodeEnum;
    /**
     * Маркер нахождения в архиве
     * @type {boolean}
     * @memberof OperationRecordAllOf
     */
    archive: boolean;
    /**
     * Возможность отправить в архив (для единообразия, можно всегда, когда уже не в архиве)
     * @type {boolean}
     * @memberof OperationRecordAllOf
     */
    archivable: boolean;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof OperationRecordAllOf
     */
    creationAuthorId: number;
    /**
     * Выч. по ссылке: короткое ФИО автора создания
     * @type {string}
     * @memberof OperationRecordAllOf
     */
    creationAuthorShortName: string;
    /**
     * 
     * @type {OperationIndicatorsObj}
     * @memberof OperationRecordAllOf
     */
    operationIndicators: OperationIndicatorsObj;
    /**
     * 
     * @type {ProblemAndWarningRentIndicatorsRecordObj}
     * @memberof OperationRecordAllOf
     */
    problemsAndWarnings: ProblemAndWarningRentIndicatorsRecordObj;
    /**
     * Дата и время создания операции
     * @type {Date}
     * @memberof OperationRecordAllOf
     */
    creationDate: Date;
    /**
     * Дата и время последней активности (измение сущности либо операция над связанными обязательствами)
     * @type {Date}
     * @memberof OperationRecordAllOf
     */
    lastActivityDate: Date;
    /**
     * Проект является КП. Обязательно если операция относится к проекту или КП
     * @type {boolean}
     * @memberof OperationRecordAllOf
     */
    projectIsOffer?: boolean;
    /**
     * Проект является простым заказом. Обязательно если операция относится к проекту или КП
     * @type {boolean}
     * @memberof OperationRecordAllOf
     */
    projectIsSimpleOrder?: boolean;
}
/**
 * Список записей операций
 * @export
 * @interface OperationRecordList
 */
export interface OperationRecordList {
    /**
     * 
     * @type {ListAttributesInfo}
     * @memberof OperationRecordList
     */
    listAttributes: ListAttributesInfo;
    /**
     * 
     * @type {Array<OperationRecord>}
     * @memberof OperationRecordList
     */
    records: Array<OperationRecord>;
}
/**
 * 
 * @export
 * @interface OperationRecordListAllOf
 */
export interface OperationRecordListAllOf {
    /**
     * 
     * @type {Array<OperationRecord>}
     * @memberof OperationRecordListAllOf
     */
    records: Array<OperationRecord>;
}
/**
 * Код типа операции, выбор из перечисления
 * @export
 * @enum {string}
 */
export enum OperationTypeCodeEnum {
    CORRECT = 'CORRECT',
    AUTOMATICRULE = 'AUTOMATIC_RULE',
    EDIT = 'EDIT',
    DRAFT = 'DRAFT',
    ORDER = 'ORDER',
    BOOK = 'BOOK',
    RENT = 'RENT',
    PROLONG = 'PROLONG',
    RETURN = 'RETURN',
    RETURNBROKEN = 'RETURN_BROKEN',
    LOSTNORETURN = 'LOST_NO_RETURN',
    CANCEL = 'CANCEL',
    SUBRENTDRAFT = 'SUBRENT_DRAFT',
    SUBRENTBOOKSHIPMENT = 'SUBRENT_BOOK_SHIPMENT',
    SUBRENTACCEPTSHIPMENT = 'SUBRENT_ACCEPT_SHIPMENT',
    SUBRENTPROLONG = 'SUBRENT_PROLONG',
    SUBRENTRETURNTOSHIPPER = 'SUBRENT_RETURN_TO_SHIPPER',
    SUBRENTCANCEL = 'SUBRENT_CANCEL'
}

/**
 * Данные клиента, оставившего заказ
 * @export
 * @interface OrderClientInfo
 */
export interface OrderClientInfo {
    /**
     * Имя
     * @type {string}
     * @memberof OrderClientInfo
     */
    firstName: string;
    /**
     * Фамилия
     * @type {string}
     * @memberof OrderClientInfo
     */
    lastName: string;
    /**
     * Номер телефона
     * @type {string}
     * @memberof OrderClientInfo
     */
    phoneNumber?: string;
    /**
     * Пароль клиента, сгенерированный при оформлении быстрого заказа
     * @type {string}
     * @memberof OrderClientInfo
     */
    generatedPassword?: string;
    /**
     * Электронная почта
     * @type {string}
     * @memberof OrderClientInfo
     */
    email: string;
}
/**
 * 
 * @export
 * @interface OrderCustomFieldValue
 */
export interface OrderCustomFieldValue {
    /**
     * 
     * @type {string}
     * @memberof OrderCustomFieldValue
     */
    fieldName: string;
    /**
     * 
     * @type {string}
     * @memberof OrderCustomFieldValue
     */
    fieldValue: string;
}
/**
 * 
 * @export
 * @interface OrderExtraInfo
 */
export interface OrderExtraInfo {
    /**
     * Комментарий
     * @type {string}
     * @memberof OrderExtraInfo
     */
    comment?: string;
    /**
     * 
     * @type {Array<OrderCustomFieldValue>}
     * @memberof OrderExtraInfo
     */
    customFieldValues?: Array<OrderCustomFieldValue>;
}
/**
 * Информация для создания заказа
 * @export
 * @interface OrderInfoCreate
 */
export interface OrderInfoCreate {
    /**
     * 
     * @type {ClientCart}
     * @memberof OrderInfoCreate
     */
    clientCart?: ClientCart;
    /**
     * 
     * @type {OrderClientInfo}
     * @memberof OrderInfoCreate
     */
    clientInfo?: OrderClientInfo;
    /**
     * 
     * @type {OrderExtraInfo}
     * @memberof OrderInfoCreate
     */
    extraInfo?: OrderExtraInfo;
}
/**
 * Полная информация о заказе
 * @export
 * @interface OrderInfoRead
 */
export interface OrderInfoRead {
    /**
     * Человекочитаемый номер заказа
     * @type {string}
     * @memberof OrderInfoRead
     */
    orderNumber: string;
    /**
     * Идентификатор заказа
     * @type {number}
     * @memberof OrderInfoRead
     */
    orderId?: number;
    /**
     * Дата и время создания заказа
     * @type {Date}
     * @memberof OrderInfoRead
     */
    creationDate?: Date;
    /**
     * 
     * @type {CalculatedCart}
     * @memberof OrderInfoRead
     */
    calculatedCart?: CalculatedCart;
    /**
     * 
     * @type {OrderClientInfo}
     * @memberof OrderInfoRead
     */
    clientInfo?: OrderClientInfo;
    /**
     * 
     * @type {OrderExtraInfo}
     * @memberof OrderInfoRead
     */
    extraInfo?: OrderExtraInfo;
}
/**
 * Списочное представление информации о заказе
 * @export
 * @interface OrderRecord
 */
export interface OrderRecord {
    /**
     * Уникальный идентификатор клиента в системе SSO
     * @type {string}
     * @memberof OrderRecord
     */
    clientUserSsoId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderRecord
     */
    orderNumber: string;
    /**
     * Идентификатор заказа
     * @type {number}
     * @memberof OrderRecord
     */
    orderId?: number;
    /**
     * Дата и время создания заказа
     * @type {Date}
     * @memberof OrderRecord
     */
    creationDate: Date;
    /**
     * 
     * @type {CalculatedCart}
     * @memberof OrderRecord
     */
    calculatedCart: CalculatedCart;
    /**
     * Описание
     * @type {string}
     * @memberof OrderRecord
     */
    description?: string;
    /**
     * 
     * @type {OrderStateCodeEnum}
     * @memberof OrderRecord
     */
    stateCode: OrderStateCodeEnum;
    /**
     * Сумма успешно принятой оплаты по заказу
     * @type {number}
     * @memberof OrderRecord
     */
    payedSum: number;
    /**
     * Список успешно проведенных платежей по заказу
     * @type {Array<PublicPaymentRecord>}
     * @memberof OrderRecord
     */
    payments?: Array<PublicPaymentRecord>;
}
/**
 * Список заказов
 * @export
 * @interface OrderRecordList
 */
export interface OrderRecordList {
    /**
     * 
     * @type {Array<OrderRecord>}
     * @memberof OrderRecordList
     */
    records: Array<OrderRecord>;
}
/**
 * Код статуса заказа
 * @export
 * @enum {string}
 */
export enum OrderStateCodeEnum {
    NEW = 'NEW',
    INPROGRESS = 'IN_PROGRESS',
    FINISHED = 'FINISHED',
    CANCELED = 'CANCELED'
}

/**
 * Все поля сущности PaymentRecord, которые могут быть выгружены в список Excel. Не финансовых полей нет, так как платежи целиком являются финансовой сущностью
 * @export
 * @enum {string}
 */
export enum PaymentFinancialFieldsTypeCode {
    NumberInBusinessAccount = 'numberInBusinessAccount',
    CounterpartyShortName = 'counterpartyShortName',
    RentActivityFrameShortName = 'rentActivityFrameShortName',
    Date = 'date',
    StateCode = 'stateCode',
    SourceCode = 'sourceCode',
    PaymentMethodName = 'paymentMethodName',
    LastUpdateAuthorShortName = 'lastUpdateAuthorShortName',
    LastUpdateDate = 'lastUpdateDate',
    BalanceChangeAmount = 'balanceChangeAmount'
}

/**
 * Платеж: состояние при создании
 * @export
 * @interface PaymentInfoCreate
 */
export interface PaymentInfoCreate {
    /**
     * Идентификатор
     * @type {number}
     * @memberof PaymentInfoCreate
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof PaymentInfoCreate
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof PaymentInfoCreate
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof PaymentInfoCreate
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof PaymentInfoCreate
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof PaymentInfoCreate
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof PaymentInfoCreate
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof PaymentInfoCreate
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof PaymentInfoCreate
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof PaymentInfoCreate
     */
    businessAccountId?: number;
    /**
     * Сумма платежа, всегда неотрицательная (направление платежа не влияет)
     * @type {number}
     * @memberof PaymentInfoCreate
     */
    amount: number;
    /**
     * Является ли входящим
     * @type {boolean}
     * @memberof PaymentInfoCreate
     */
    incoming: boolean;
    /**
     * Ссылка: идентификатор проекта (обязательно, если не указана поставка)
     * @type {number}
     * @memberof PaymentInfoCreate
     */
    projectId?: number;
    /**
     * Ссылка: идентификатор поставки (обязательно, если не указан проект)
     * @type {number}
     * @memberof PaymentInfoCreate
     */
    subrentId?: number;
    /**
     * Дополнительная информация об оплате
     * @type {string}
     * @memberof PaymentInfoCreate
     */
    acquiringInfo?: string;
    /**
     * Внешний идентификатор транзакции
     * @type {string}
     * @memberof PaymentInfoCreate
     */
    externalTransactionId?: string;
    /**
     * Дата платежа
     * @type {Date}
     * @memberof PaymentInfoCreate
     */
    date: Date;
    /**
     * 
     * @type {PaymentStateCodeEnum}
     * @memberof PaymentInfoCreate
     */
    stateCode: PaymentStateCodeEnum;
    /**
     * Ссылка: способ оплаты
     * @type {number}
     * @memberof PaymentInfoCreate
     */
    paymentMethodId?: number;
}
/**
 * 
 * @export
 * @interface PaymentInfoCreateAllOf
 */
export interface PaymentInfoCreateAllOf {
    /**
     * Сумма платежа, всегда неотрицательная (направление платежа не влияет)
     * @type {number}
     * @memberof PaymentInfoCreateAllOf
     */
    amount: number;
    /**
     * Является ли входящим
     * @type {boolean}
     * @memberof PaymentInfoCreateAllOf
     */
    incoming: boolean;
    /**
     * Ссылка: идентификатор проекта (обязательно, если не указана поставка)
     * @type {number}
     * @memberof PaymentInfoCreateAllOf
     */
    projectId?: number;
    /**
     * Ссылка: идентификатор поставки (обязательно, если не указан проект)
     * @type {number}
     * @memberof PaymentInfoCreateAllOf
     */
    subrentId?: number;
    /**
     * Дополнительная информация об оплате
     * @type {string}
     * @memberof PaymentInfoCreateAllOf
     */
    acquiringInfo?: string;
    /**
     * Внешний идентификатор транзакции
     * @type {string}
     * @memberof PaymentInfoCreateAllOf
     */
    externalTransactionId?: string;
    /**
     * Дата платежа
     * @type {Date}
     * @memberof PaymentInfoCreateAllOf
     */
    date: Date;
    /**
     * 
     * @type {PaymentStateCodeEnum}
     * @memberof PaymentInfoCreateAllOf
     */
    stateCode: PaymentStateCodeEnum;
    /**
     * Ссылка: способ оплаты
     * @type {number}
     * @memberof PaymentInfoCreateAllOf
     */
    paymentMethodId?: number;
}
/**
 * Платеж: cостояние при чтении
 * @export
 * @interface PaymentInfoRead
 */
export interface PaymentInfoRead {
    /**
     * Идентификатор
     * @type {number}
     * @memberof PaymentInfoRead
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof PaymentInfoRead
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof PaymentInfoRead
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof PaymentInfoRead
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof PaymentInfoRead
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof PaymentInfoRead
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof PaymentInfoRead
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof PaymentInfoRead
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof PaymentInfoRead
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof PaymentInfoRead
     */
    businessAccountId?: number;
    /**
     * Номер платежа в бизнес аккаунте
     * @type {number}
     * @memberof PaymentInfoRead
     */
    numberInBusinessAccount: number;
    /**
     * 
     * @type {AttachmentRecordList}
     * @memberof PaymentInfoRead
     */
    attachments?: AttachmentRecordList;
    /**
     * Сумма платежа, всегда неотрицательная (направление платежа не влияет)
     * @type {number}
     * @memberof PaymentInfoRead
     */
    amount: number;
    /**
     * Является ли входящим
     * @type {boolean}
     * @memberof PaymentInfoRead
     */
    incoming?: boolean;
    /**
     * Изменение баланса компании в результате платежа, для входящих положительная, для исходящих отрицательная, для нулевой суммы направление может быть любым
     * @type {number}
     * @memberof PaymentInfoRead
     */
    balanceChangeAmount?: number;
    /**
     * Ссылка: идентификатор контрагента
     * @type {number}
     * @memberof PaymentInfoRead
     */
    counterpartyId: number;
    /**
     * Ссылка: идентификатор проекта (обязательно, если не указана поставка)
     * @type {number}
     * @memberof PaymentInfoRead
     */
    projectId?: number;
    /**
     * Ссылка: идентификатор поставки (обязательно, если не указан проект)
     * @type {number}
     * @memberof PaymentInfoRead
     */
    subrentId?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentInfoRead
     */
    acquiringInfo?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentInfoRead
     */
    externalTransactionId?: string;
    /**
     * 
     * @type {PaymentSourceCodeEnum}
     * @memberof PaymentInfoRead
     */
    sourceCode?: PaymentSourceCodeEnum;
    /**
     * Дата платежа
     * @type {Date}
     * @memberof PaymentInfoRead
     */
    date: Date;
    /**
     * 
     * @type {PaymentStateCodeEnum}
     * @memberof PaymentInfoRead
     */
    stateCode: PaymentStateCodeEnum;
    /**
     * Коды доступных переходов для данного продукта и пользователя
     * @type {Array<PaymentTransitionCodeEnum>}
     * @memberof PaymentInfoRead
     */
    availableTransitionCodes?: Array<PaymentTransitionCodeEnum>;
    /**
     * 
     * @type {PaymentTypeCodeEnum}
     * @memberof PaymentInfoRead
     */
    typeCode: PaymentTypeCodeEnum;
    /**
     * Ссылка: способ оплаты
     * @type {number}
     * @memberof PaymentInfoRead
     */
    paymentMethodId?: number;
    /**
     * Наименование способа оплаты
     * @type {string}
     * @memberof PaymentInfoRead
     */
    paymentMethodName?: string;
    /**
     * Выч: короткое наименование контрагента
     * @type {string}
     * @memberof PaymentInfoRead
     */
    counterpartyShortName: string;
    /**
     * Является платежом по проекту, а не по поставке
     * @type {boolean}
     * @memberof PaymentInfoRead
     */
    projectPayment?: boolean;
    /**
     * Выч: короткое наименование рамки активности
     * @type {string}
     * @memberof PaymentInfoRead
     */
    rentActivityFrameShortName?: string;
}
/**
 * 
 * @export
 * @interface PaymentInfoReadAllOf
 */
export interface PaymentInfoReadAllOf {
    /**
     * Номер платежа в бизнес аккаунте
     * @type {number}
     * @memberof PaymentInfoReadAllOf
     */
    numberInBusinessAccount: number;
    /**
     * 
     * @type {AttachmentRecordList}
     * @memberof PaymentInfoReadAllOf
     */
    attachments?: AttachmentRecordList;
    /**
     * Сумма платежа, всегда неотрицательная (направление платежа не влияет)
     * @type {number}
     * @memberof PaymentInfoReadAllOf
     */
    amount: number;
    /**
     * Является ли входящим
     * @type {boolean}
     * @memberof PaymentInfoReadAllOf
     */
    incoming?: boolean;
    /**
     * Изменение баланса компании в результате платежа, для входящих положительная, для исходящих отрицательная, для нулевой суммы направление может быть любым
     * @type {number}
     * @memberof PaymentInfoReadAllOf
     */
    balanceChangeAmount?: number;
    /**
     * Ссылка: идентификатор контрагента
     * @type {number}
     * @memberof PaymentInfoReadAllOf
     */
    counterpartyId: number;
    /**
     * Ссылка: идентификатор проекта (обязательно, если не указана поставка)
     * @type {number}
     * @memberof PaymentInfoReadAllOf
     */
    projectId?: number;
    /**
     * Ссылка: идентификатор поставки (обязательно, если не указан проект)
     * @type {number}
     * @memberof PaymentInfoReadAllOf
     */
    subrentId?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentInfoReadAllOf
     */
    acquiringInfo?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentInfoReadAllOf
     */
    externalTransactionId?: string;
    /**
     * 
     * @type {PaymentSourceCodeEnum}
     * @memberof PaymentInfoReadAllOf
     */
    sourceCode?: PaymentSourceCodeEnum;
    /**
     * Дата платежа
     * @type {Date}
     * @memberof PaymentInfoReadAllOf
     */
    date: Date;
    /**
     * 
     * @type {PaymentStateCodeEnum}
     * @memberof PaymentInfoReadAllOf
     */
    stateCode: PaymentStateCodeEnum;
    /**
     * Коды доступных переходов для данного продукта и пользователя
     * @type {Array<PaymentTransitionCodeEnum>}
     * @memberof PaymentInfoReadAllOf
     */
    availableTransitionCodes?: Array<PaymentTransitionCodeEnum>;
    /**
     * 
     * @type {PaymentTypeCodeEnum}
     * @memberof PaymentInfoReadAllOf
     */
    typeCode: PaymentTypeCodeEnum;
    /**
     * Ссылка: способ оплаты
     * @type {number}
     * @memberof PaymentInfoReadAllOf
     */
    paymentMethodId?: number;
    /**
     * Наименование способа оплаты
     * @type {string}
     * @memberof PaymentInfoReadAllOf
     */
    paymentMethodName?: string;
    /**
     * Выч: короткое наименование контрагента
     * @type {string}
     * @memberof PaymentInfoReadAllOf
     */
    counterpartyShortName: string;
    /**
     * Является платежом по проекту, а не по поставке
     * @type {boolean}
     * @memberof PaymentInfoReadAllOf
     */
    projectPayment?: boolean;
    /**
     * Выч: короткое наименование рамки активности
     * @type {string}
     * @memberof PaymentInfoReadAllOf
     */
    rentActivityFrameShortName?: string;
}
/**
 * Платеж: состояние при изменении
 * @export
 * @interface PaymentInfoUpdate
 */
export interface PaymentInfoUpdate {
    /**
     * Идентификатор
     * @type {number}
     * @memberof PaymentInfoUpdate
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof PaymentInfoUpdate
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof PaymentInfoUpdate
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof PaymentInfoUpdate
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof PaymentInfoUpdate
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof PaymentInfoUpdate
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof PaymentInfoUpdate
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof PaymentInfoUpdate
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof PaymentInfoUpdate
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof PaymentInfoUpdate
     */
    businessAccountId?: number;
    /**
     * Сумма платежа, всегда неотрицательная (направление платежа не влияет)
     * @type {number}
     * @memberof PaymentInfoUpdate
     */
    amount: number;
    /**
     * Является ли входящим
     * @type {boolean}
     * @memberof PaymentInfoUpdate
     */
    incoming: boolean;
    /**
     * 
     * @type {string}
     * @memberof PaymentInfoUpdate
     */
    acquiringInfo?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentInfoUpdate
     */
    externalTransactionId?: string;
    /**
     * Дата платежа
     * @type {Date}
     * @memberof PaymentInfoUpdate
     */
    date: Date;
    /**
     * Ссылка: способ оплаты
     * @type {number}
     * @memberof PaymentInfoUpdate
     */
    paymentMethodId?: number;
}
/**
 * 
 * @export
 * @interface PaymentInfoUpdateAllOf
 */
export interface PaymentInfoUpdateAllOf {
    /**
     * Сумма платежа, всегда неотрицательная (направление платежа не влияет)
     * @type {number}
     * @memberof PaymentInfoUpdateAllOf
     */
    amount: number;
    /**
     * Является ли входящим
     * @type {boolean}
     * @memberof PaymentInfoUpdateAllOf
     */
    incoming: boolean;
    /**
     * 
     * @type {string}
     * @memberof PaymentInfoUpdateAllOf
     */
    acquiringInfo?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentInfoUpdateAllOf
     */
    externalTransactionId?: string;
    /**
     * Дата платежа
     * @type {Date}
     * @memberof PaymentInfoUpdateAllOf
     */
    date: Date;
    /**
     * Ссылка: способ оплаты
     * @type {number}
     * @memberof PaymentInfoUpdateAllOf
     */
    paymentMethodId?: number;
}
/**
 * Способ оплаты: абстрактное общее состояние для всех операций
 * @export
 * @interface PaymentMethodInfoAbstractCommons
 */
export interface PaymentMethodInfoAbstractCommons {
    /**
     * Идентификатор
     * @type {number}
     * @memberof PaymentMethodInfoAbstractCommons
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof PaymentMethodInfoAbstractCommons
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof PaymentMethodInfoAbstractCommons
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof PaymentMethodInfoAbstractCommons
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof PaymentMethodInfoAbstractCommons
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof PaymentMethodInfoAbstractCommons
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof PaymentMethodInfoAbstractCommons
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof PaymentMethodInfoAbstractCommons
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof PaymentMethodInfoAbstractCommons
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof PaymentMethodInfoAbstractCommons
     */
    businessAccountId?: number;
    /**
     * Название способа оплаты
     * @type {string}
     * @memberof PaymentMethodInfoAbstractCommons
     */
    name: string;
    /**
     * Флаг для определения, является ли способом оплаты по умолчанию
     * @type {boolean}
     * @memberof PaymentMethodInfoAbstractCommons
     */
    isDefault: boolean;
}
/**
 * 
 * @export
 * @interface PaymentMethodInfoAbstractCommonsAllOf
 */
export interface PaymentMethodInfoAbstractCommonsAllOf {
    /**
     * Название способа оплаты
     * @type {string}
     * @memberof PaymentMethodInfoAbstractCommonsAllOf
     */
    name: string;
    /**
     * Флаг для определения, является ли способом оплаты по умолчанию
     * @type {boolean}
     * @memberof PaymentMethodInfoAbstractCommonsAllOf
     */
    isDefault: boolean;
}
/**
 * Способ оплаты: состояние при создании
 * @export
 * @interface PaymentMethodInfoCreate
 */
export interface PaymentMethodInfoCreate extends PaymentMethodInfoAbstractCommons {
}
/**
 * Способ оплаты: состояние при чтении
 * @export
 * @interface PaymentMethodInfoRead
 */
export interface PaymentMethodInfoRead {
    /**
     * Идентификатор
     * @type {number}
     * @memberof PaymentMethodInfoRead
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof PaymentMethodInfoRead
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof PaymentMethodInfoRead
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof PaymentMethodInfoRead
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof PaymentMethodInfoRead
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof PaymentMethodInfoRead
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof PaymentMethodInfoRead
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof PaymentMethodInfoRead
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof PaymentMethodInfoRead
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof PaymentMethodInfoRead
     */
    businessAccountId?: number;
    /**
     * Название способа оплаты
     * @type {string}
     * @memberof PaymentMethodInfoRead
     */
    name: string;
    /**
     * Флаг для определения, является ли способом оплаты по умолчанию
     * @type {boolean}
     * @memberof PaymentMethodInfoRead
     */
    isDefault: boolean;
    /**
     * Флаг для архива
     * @type {boolean}
     * @memberof PaymentMethodInfoRead
     */
    archive: boolean;
    /**
     * Может ли быть отправлен в архив (может, когда не в архиве и не является методом по умолчанию)
     * @type {boolean}
     * @memberof PaymentMethodInfoRead
     */
    archivable: boolean;
    /**
     * Флаг для определения, можно ли удалить сущность
     * @type {boolean}
     * @memberof PaymentMethodInfoRead
     */
    deleteable: boolean;
    /**
     * Количество связанных сущностей
     * @type {number}
     * @memberof PaymentMethodInfoRead
     */
    cachedPaymentsLinkedNumber: number;
}
/**
 * 
 * @export
 * @interface PaymentMethodInfoReadAllOf
 */
export interface PaymentMethodInfoReadAllOf {
    /**
     * Флаг для архива
     * @type {boolean}
     * @memberof PaymentMethodInfoReadAllOf
     */
    archive: boolean;
    /**
     * Может ли быть отправлен в архив (может, когда не в архиве и не является методом по умолчанию)
     * @type {boolean}
     * @memberof PaymentMethodInfoReadAllOf
     */
    archivable: boolean;
    /**
     * Флаг для определения, можно ли удалить сущность
     * @type {boolean}
     * @memberof PaymentMethodInfoReadAllOf
     */
    deleteable: boolean;
    /**
     * Количество связанных сущностей
     * @type {number}
     * @memberof PaymentMethodInfoReadAllOf
     */
    cachedPaymentsLinkedNumber: number;
}
/**
 * Способ оплаты: состояние при изменении
 * @export
 * @interface PaymentMethodInfoUpdate
 */
export interface PaymentMethodInfoUpdate extends PaymentMethodInfoAbstractCommons {
}
/**
 * Списочное представление способов оплаты
 * @export
 * @interface PaymentMethodRecord
 */
export interface PaymentMethodRecord {
    /**
     * Идентификатор
     * @type {number}
     * @memberof PaymentMethodRecord
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof PaymentMethodRecord
     */
    businessVersion: number;
    /**
     * Ссылка: Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof PaymentMethodRecord
     */
    businessAccountId: number;
    /**
     * Название способа оплаты
     * @type {string}
     * @memberof PaymentMethodRecord
     */
    name: string;
    /**
     * Флаг для определения, является ли способом оплаты по умолчанию
     * @type {boolean}
     * @memberof PaymentMethodRecord
     */
    isDefault: boolean;
    /**
     * Флаг для архива
     * @type {boolean}
     * @memberof PaymentMethodRecord
     */
    archive: boolean;
    /**
     * Может ли быть отправлен в архив (может, когда не в архиве и не является методом по умолчанию)
     * @type {boolean}
     * @memberof PaymentMethodRecord
     */
    archivable: boolean;
    /**
     * Флаг для определения, можно ли удалить сущность
     * @type {boolean}
     * @memberof PaymentMethodRecord
     */
    deleteable: boolean;
    /**
     * Количество связанных сущностей
     * @type {number}
     * @memberof PaymentMethodRecord
     */
    cachedPaymentsLinkedNumber: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof PaymentMethodRecord
     */
    lastUpdateAuthorId?: number;
    /**
     * Выч. по ссылке: короткое ФИО автора создания
     * @type {string}
     * @memberof PaymentMethodRecord
     */
    lastUpdateAuthorShortName?: string;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof PaymentMethodRecord
     */
    lastUpdateDate?: Date;
}
/**
 * 
 * @export
 * @interface PaymentMethodRecordAllOf
 */
export interface PaymentMethodRecordAllOf {
    /**
     * Название способа оплаты
     * @type {string}
     * @memberof PaymentMethodRecordAllOf
     */
    name: string;
    /**
     * Флаг для определения, является ли способом оплаты по умолчанию
     * @type {boolean}
     * @memberof PaymentMethodRecordAllOf
     */
    isDefault: boolean;
    /**
     * Флаг для архива
     * @type {boolean}
     * @memberof PaymentMethodRecordAllOf
     */
    archive: boolean;
    /**
     * Может ли быть отправлен в архив (может, когда не в архиве и не является методом по умолчанию)
     * @type {boolean}
     * @memberof PaymentMethodRecordAllOf
     */
    archivable: boolean;
    /**
     * Флаг для определения, можно ли удалить сущность
     * @type {boolean}
     * @memberof PaymentMethodRecordAllOf
     */
    deleteable: boolean;
    /**
     * Количество связанных сущностей
     * @type {number}
     * @memberof PaymentMethodRecordAllOf
     */
    cachedPaymentsLinkedNumber: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof PaymentMethodRecordAllOf
     */
    lastUpdateAuthorId?: number;
    /**
     * Выч. по ссылке: короткое ФИО автора создания
     * @type {string}
     * @memberof PaymentMethodRecordAllOf
     */
    lastUpdateAuthorShortName?: string;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof PaymentMethodRecordAllOf
     */
    lastUpdateDate?: Date;
}
/**
 * Список платежей
 * @export
 * @interface PaymentMethodRecordList
 */
export interface PaymentMethodRecordList {
    /**
     * 
     * @type {ListAttributesInfo}
     * @memberof PaymentMethodRecordList
     */
    listAttributes: ListAttributesInfo;
    /**
     * 
     * @type {Array<PaymentMethodRecord>}
     * @memberof PaymentMethodRecordList
     */
    records: Array<PaymentMethodRecord>;
}
/**
 * 
 * @export
 * @interface PaymentMethodRecordListAllOf
 */
export interface PaymentMethodRecordListAllOf {
    /**
     * 
     * @type {Array<PaymentMethodRecord>}
     * @memberof PaymentMethodRecordListAllOf
     */
    records: Array<PaymentMethodRecord>;
}
/**
 * Обязательность способа оплаты
 * @export
 * @enum {string}
 */
export enum PaymentMethodRequirementTypeCodeEnum {
    OPTIONAL = 'OPTIONAL',
    REQUIRED = 'REQUIRED',
    REQUIREDINSTATUSDONE = 'REQUIRED_IN_STATUS_DONE'
}

/**
 * Списочное представление платежа
 * @export
 * @interface PaymentRecord
 */
export interface PaymentRecord {
    /**
     * Идентификатор
     * @type {number}
     * @memberof PaymentRecord
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof PaymentRecord
     */
    businessVersion: number;
    /**
     * Ссылка: Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof PaymentRecord
     */
    businessAccountId: number;
    /**
     * Номер платежа в бизнес аккаунте
     * @type {number}
     * @memberof PaymentRecord
     */
    numberInBusinessAccount: number;
    /**
     * Сумма платежа, всегда неотрицательная (направление платежа не влияет)
     * @type {number}
     * @memberof PaymentRecord
     */
    amount: number;
    /**
     * Является ли входящим
     * @type {boolean}
     * @memberof PaymentRecord
     */
    incoming: boolean;
    /**
     * Изменение баланса компании в результате платежа, для входящих положительная, для исходящих отрицательная, для нулевой суммы направление может быть любым
     * @type {number}
     * @memberof PaymentRecord
     */
    balanceChangeAmount: number;
    /**
     * Ссылка: идентификатор контрагента
     * @type {number}
     * @memberof PaymentRecord
     */
    counterpartyId: number;
    /**
     * Выч: короткое наименование контрагента
     * @type {string}
     * @memberof PaymentRecord
     */
    counterpartyShortName: string;
    /**
     * Является платежом по проекту, а не по поставке
     * @type {boolean}
     * @memberof PaymentRecord
     */
    projectPayment?: boolean;
    /**
     * Ссылка: идентификатор рамки активности
     * @type {number}
     * @memberof PaymentRecord
     */
    rentActivityFrameId?: number;
    /**
     * Выч: короткое наименование рамки активности
     * @type {string}
     * @memberof PaymentRecord
     */
    rentActivityFrameShortName?: string;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof PaymentRecord
     */
    lastUpdateAuthorId?: number;
    /**
     * Выч. по ссылке: короткое ФИО автора создания
     * @type {string}
     * @memberof PaymentRecord
     */
    lastUpdateAuthorShortName?: string;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof PaymentRecord
     */
    lastUpdateDate?: Date;
    /**
     * 
     * @type {PaymentSourceCodeEnum}
     * @memberof PaymentRecord
     */
    sourceCode: PaymentSourceCodeEnum;
    /**
     * Дата платежа
     * @type {Date}
     * @memberof PaymentRecord
     */
    date: Date;
    /**
     * 
     * @type {PaymentStateCodeEnum}
     * @memberof PaymentRecord
     */
    stateCode: PaymentStateCodeEnum;
    /**
     * Коды доступных переходов для данного продукта и пользователя
     * @type {Array<PaymentTransitionCodeEnum>}
     * @memberof PaymentRecord
     */
    availableTransitionCodes?: Array<PaymentTransitionCodeEnum>;
    /**
     * 
     * @type {PaymentTypeCodeEnum}
     * @memberof PaymentRecord
     */
    typeCode: PaymentTypeCodeEnum;
    /**
     * Ссылка: способ оплаты
     * @type {number}
     * @memberof PaymentRecord
     */
    paymentMethodId?: number;
    /**
     * Наименование способа оплаты
     * @type {string}
     * @memberof PaymentRecord
     */
    paymentMethodName?: string;
}
/**
 * 
 * @export
 * @interface PaymentRecordAllOf
 */
export interface PaymentRecordAllOf {
    /**
     * Номер платежа в бизнес аккаунте
     * @type {number}
     * @memberof PaymentRecordAllOf
     */
    numberInBusinessAccount: number;
    /**
     * Сумма платежа, всегда неотрицательная (направление платежа не влияет)
     * @type {number}
     * @memberof PaymentRecordAllOf
     */
    amount: number;
    /**
     * Является ли входящим
     * @type {boolean}
     * @memberof PaymentRecordAllOf
     */
    incoming: boolean;
    /**
     * Изменение баланса компании в результате платежа, для входящих положительная, для исходящих отрицательная, для нулевой суммы направление может быть любым
     * @type {number}
     * @memberof PaymentRecordAllOf
     */
    balanceChangeAmount: number;
    /**
     * Ссылка: идентификатор контрагента
     * @type {number}
     * @memberof PaymentRecordAllOf
     */
    counterpartyId: number;
    /**
     * Выч: короткое наименование контрагента
     * @type {string}
     * @memberof PaymentRecordAllOf
     */
    counterpartyShortName: string;
    /**
     * Является платежом по проекту, а не по поставке
     * @type {boolean}
     * @memberof PaymentRecordAllOf
     */
    projectPayment?: boolean;
    /**
     * Ссылка: идентификатор рамки активности
     * @type {number}
     * @memberof PaymentRecordAllOf
     */
    rentActivityFrameId?: number;
    /**
     * Выч: короткое наименование рамки активности
     * @type {string}
     * @memberof PaymentRecordAllOf
     */
    rentActivityFrameShortName?: string;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof PaymentRecordAllOf
     */
    lastUpdateAuthorId?: number;
    /**
     * Выч. по ссылке: короткое ФИО автора создания
     * @type {string}
     * @memberof PaymentRecordAllOf
     */
    lastUpdateAuthorShortName?: string;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof PaymentRecordAllOf
     */
    lastUpdateDate?: Date;
    /**
     * 
     * @type {PaymentSourceCodeEnum}
     * @memberof PaymentRecordAllOf
     */
    sourceCode: PaymentSourceCodeEnum;
    /**
     * Дата платежа
     * @type {Date}
     * @memberof PaymentRecordAllOf
     */
    date: Date;
    /**
     * 
     * @type {PaymentStateCodeEnum}
     * @memberof PaymentRecordAllOf
     */
    stateCode: PaymentStateCodeEnum;
    /**
     * Коды доступных переходов для данного продукта и пользователя
     * @type {Array<PaymentTransitionCodeEnum>}
     * @memberof PaymentRecordAllOf
     */
    availableTransitionCodes?: Array<PaymentTransitionCodeEnum>;
    /**
     * 
     * @type {PaymentTypeCodeEnum}
     * @memberof PaymentRecordAllOf
     */
    typeCode: PaymentTypeCodeEnum;
    /**
     * Ссылка: способ оплаты
     * @type {number}
     * @memberof PaymentRecordAllOf
     */
    paymentMethodId?: number;
    /**
     * Наименование способа оплаты
     * @type {string}
     * @memberof PaymentRecordAllOf
     */
    paymentMethodName?: string;
}
/**
 * Список платежей
 * @export
 * @interface PaymentRecordList
 */
export interface PaymentRecordList {
    /**
     * 
     * @type {ListAttributesInfo}
     * @memberof PaymentRecordList
     */
    listAttributes: ListAttributesInfo;
    /**
     * 
     * @type {Array<PaymentRecord>}
     * @memberof PaymentRecordList
     */
    records: Array<PaymentRecord>;
}
/**
 * 
 * @export
 * @interface PaymentRecordListAllOf
 */
export interface PaymentRecordListAllOf {
    /**
     * 
     * @type {Array<PaymentRecord>}
     * @memberof PaymentRecordListAllOf
     */
    records: Array<PaymentRecord>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum PaymentSourceCodeEnum {
    MANAGERINTERFACE = 'MANAGER_INTERFACE',
    STOREFRONT = 'STOREFRONT'
}

/**
 * Статус платежа
 * @export
 * @enum {string}
 */
export enum PaymentStateCodeEnum {
    PLANNED = 'PLANNED',
    DONE = 'DONE',
    DELETED = 'DELETED'
}

/**
 * Статус платежа при создании
 * @export
 * @enum {string}
 */
export enum PaymentStateCodeInfoCreate {
    PLANNED = 'PLANNED',
    DONE = 'DONE'
}

/**
 * Переходы между статусами платежа
 * @export
 * @enum {string}
 */
export enum PaymentTransitionCodeEnum {
    MAKE = 'MAKE',
    DELETE = 'DELETE',
    RECOVER = 'RECOVER'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum PaymentTypeCodeEnum {
    PAYMENT = 'PAYMENT',
    RETURN = 'RETURN'
}

/**
 * Список прав доступа
 * @export
 * @interface PermissonListObj
 */
export interface PermissonListObj {
    /**
     * 
     * @type {Array<string>}
     * @memberof PermissonListObj
     */
    permissions: Array<string>;
}
/**
 * ФИО
 * @export
 * @interface PersonFullName
 */
export interface PersonFullName {
    /**
     * Имя
     * @type {string}
     * @memberof PersonFullName
     */
    firstname: string;
    /**
     * Отчество
     * @type {string}
     * @memberof PersonFullName
     */
    secondname?: string;
    /**
     * Фамилия
     * @type {string}
     * @memberof PersonFullName
     */
    lastname: string;
}
/**
 * Шаг ступенчатой ценовой схемы
 * @export
 * @interface PricingLadderStepObj
 */
export interface PricingLadderStepObj {
    /**
     * Количество смен после которого применяется правило
     * @type {number}
     * @memberof PricingLadderStepObj
     */
    effectsAfterShiftCount: number;
    /**
     * Скидка относительно базовой цены
     * @type {number}
     * @memberof PricingLadderStepObj
     */
    extraDiscount: number;
}
/**
 * Абстрактное общее состояние ценовой схемы
 * @export
 * @interface PricingSchemeAbstractCommons
 */
export interface PricingSchemeAbstractCommons {
    /**
     * Идентификатор
     * @type {number}
     * @memberof PricingSchemeAbstractCommons
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof PricingSchemeAbstractCommons
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof PricingSchemeAbstractCommons
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof PricingSchemeAbstractCommons
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof PricingSchemeAbstractCommons
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof PricingSchemeAbstractCommons
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof PricingSchemeAbstractCommons
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof PricingSchemeAbstractCommons
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof PricingSchemeAbstractCommons
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof PricingSchemeAbstractCommons
     */
    businessAccountId?: number;
    /**
     * Наименование
     * @type {string}
     * @memberof PricingSchemeAbstractCommons
     */
    name?: string;
    /**
     * 
     * @type {PricingSchemeTypeCodeEnum}
     * @memberof PricingSchemeAbstractCommons
     */
    typeCode?: PricingSchemeTypeCodeEnum;
    /**
     * 
     * @type {Array<PricingLadderStepObj>}
     * @memberof PricingSchemeAbstractCommons
     */
    steps?: Array<PricingLadderStepObj>;
}
/**
 * 
 * @export
 * @interface PricingSchemeAbstractCommonsAllOf
 */
export interface PricingSchemeAbstractCommonsAllOf {
    /**
     * Наименование
     * @type {string}
     * @memberof PricingSchemeAbstractCommonsAllOf
     */
    name?: string;
    /**
     * 
     * @type {PricingSchemeTypeCodeEnum}
     * @memberof PricingSchemeAbstractCommonsAllOf
     */
    typeCode?: PricingSchemeTypeCodeEnum;
    /**
     * 
     * @type {Array<PricingLadderStepObj>}
     * @memberof PricingSchemeAbstractCommonsAllOf
     */
    steps?: Array<PricingLadderStepObj>;
}
/**
 * 
 * @export
 * @interface PricingSchemeExternalRepresentationObj
 */
export interface PricingSchemeExternalRepresentationObj {
    /**
     * Идентификатор ценовой схемы
     * @type {number}
     * @memberof PricingSchemeExternalRepresentationObj
     */
    id: number;
    /**
     * Наименование
     * @type {string}
     * @memberof PricingSchemeExternalRepresentationObj
     */
    name: string;
    /**
     * 
     * @type {PricingSchemeTypeCodeEnum}
     * @memberof PricingSchemeExternalRepresentationObj
     */
    typeCode: PricingSchemeTypeCodeEnum;
    /**
     * 
     * @type {Array<PricingLadderStepObj>}
     * @memberof PricingSchemeExternalRepresentationObj
     */
    steps?: Array<PricingLadderStepObj>;
}
/**
 * Ценовая схема - состояние создания
 * @export
 * @interface PricingSchemeInfoCreate
 */
export interface PricingSchemeInfoCreate extends PricingSchemeAbstractCommons {
}
/**
 * Ценовая схема - состояние чтения
 * @export
 * @interface PricingSchemeInfoRead
 */
export interface PricingSchemeInfoRead {
    /**
     * Идентификатор
     * @type {number}
     * @memberof PricingSchemeInfoRead
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof PricingSchemeInfoRead
     */
    businessVersion: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof PricingSchemeInfoRead
     */
    creationAuthorId: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof PricingSchemeInfoRead
     */
    creationAuthorFullName: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof PricingSchemeInfoRead
     */
    creationDate: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof PricingSchemeInfoRead
     */
    lastUpdateAuthorId: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof PricingSchemeInfoRead
     */
    lastUpdateAuthorFullName: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof PricingSchemeInfoRead
     */
    lastUpdateDate: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof PricingSchemeInfoRead
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof PricingSchemeInfoRead
     */
    businessAccountId: number;
    /**
     * Наименование
     * @type {string}
     * @memberof PricingSchemeInfoRead
     */
    name: string;
    /**
     * 
     * @type {PricingSchemeTypeCodeEnum}
     * @memberof PricingSchemeInfoRead
     */
    typeCode: PricingSchemeTypeCodeEnum;
    /**
     * 
     * @type {Array<PricingLadderStepObj>}
     * @memberof PricingSchemeInfoRead
     */
    steps?: Array<PricingLadderStepObj>;
    /**
     * Число продуктов, использующих ценовую схему
     * @type {number}
     * @memberof PricingSchemeInfoRead
     */
    linkedProductCount: number;
    /**
     * Число наборов, использующих ценовую схему
     * @type {number}
     * @memberof PricingSchemeInfoRead
     */
    linkedKitCount: number;
    /**
     * Число обязательств и наборов обязательств, использующих данную ценовую схему
     * @type {number}
     * @memberof PricingSchemeInfoRead
     */
    linkedRentElementsAndKitsCount: number;
    /**
     * Число проектов на обязательств которых используется данная ценовая схема
     * @type {number}
     * @memberof PricingSchemeInfoRead
     */
    projectWithLinkedRentElementsOrKitsCount: number;
    /**
     * Число специальностей, использующих ценовую схему
     * @type {number}
     * @memberof PricingSchemeInfoRead
     */
    linkedProfessionCount: number;
    /**
     * Число работ, использующих данную ценовую схему
     * @type {number}
     * @memberof PricingSchemeInfoRead
     */
    linkedWorkPlanningsCount: number;
    /**
     * Число проектов на работах по которым используется данная ценовая схема
     * @type {number}
     * @memberof PricingSchemeInfoRead
     */
    projectWithLinkedWorkPlanningCount?: number;
}
/**
 * 
 * @export
 * @interface PricingSchemeInfoReadAllOf
 */
export interface PricingSchemeInfoReadAllOf {
    /**
     * Число продуктов, использующих ценовую схему
     * @type {number}
     * @memberof PricingSchemeInfoReadAllOf
     */
    linkedProductCount: number;
    /**
     * Число наборов, использующих ценовую схему
     * @type {number}
     * @memberof PricingSchemeInfoReadAllOf
     */
    linkedKitCount: number;
    /**
     * Число обязательств и наборов обязательств, использующих данную ценовую схему
     * @type {number}
     * @memberof PricingSchemeInfoReadAllOf
     */
    linkedRentElementsAndKitsCount: number;
    /**
     * Число проектов на обязательств которых используется данная ценовая схема
     * @type {number}
     * @memberof PricingSchemeInfoReadAllOf
     */
    projectWithLinkedRentElementsOrKitsCount: number;
    /**
     * Число специальностей, использующих ценовую схему
     * @type {number}
     * @memberof PricingSchemeInfoReadAllOf
     */
    linkedProfessionCount: number;
    /**
     * Число работ, использующих данную ценовую схему
     * @type {number}
     * @memberof PricingSchemeInfoReadAllOf
     */
    linkedWorkPlanningsCount: number;
    /**
     * Число проектов на работах по которым используется данная ценовая схема
     * @type {number}
     * @memberof PricingSchemeInfoReadAllOf
     */
    projectWithLinkedWorkPlanningCount?: number;
}
/**
 * Ценовая схема - состояние изменения
 * @export
 * @interface PricingSchemeInfoUpdate
 */
export interface PricingSchemeInfoUpdate extends PricingSchemeAbstractCommons {
}
/**
 * Режим ценовой схемы
 * @export
 * @enum {string}
 */
export enum PricingSchemeModeCodeEnum {
    WITHMEMBERPRICINGSCHEMES = 'WITH_MEMBER_PRICING_SCHEMES',
    DISABLED = 'DISABLED',
    KITPRICINGSCHEME = 'KIT_PRICING_SCHEME'
}

/**
 * Списочное представление пользовательского поля
 * @export
 * @interface PricingSchemeRecord
 */
export interface PricingSchemeRecord {
    /**
     * Идентификатор
     * @type {number}
     * @memberof PricingSchemeRecord
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof PricingSchemeRecord
     */
    businessVersion: number;
    /**
     * Ссылка: Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof PricingSchemeRecord
     */
    businessAccountId: number;
    /**
     * Наименование
     * @type {string}
     * @memberof PricingSchemeRecord
     */
    name: string;
    /**
     * 
     * @type {PricingSchemeTypeCodeEnum}
     * @memberof PricingSchemeRecord
     */
    typeCode: PricingSchemeTypeCodeEnum;
    /**
     * 
     * @type {Array<PricingLadderStepObj>}
     * @memberof PricingSchemeRecord
     */
    steps?: Array<PricingLadderStepObj>;
    /**
     * Число продуктов, использующих ценовую схему
     * @type {number}
     * @memberof PricingSchemeRecord
     */
    linkedProductCount: number;
    /**
     * Число наборов, использующих ценовую схему
     * @type {number}
     * @memberof PricingSchemeRecord
     */
    linkedKitCount: number;
    /**
     * Число профессий, использующих ценовую схему
     * @type {number}
     * @memberof PricingSchemeRecord
     */
    linkedProfessionsCount: number;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof PricingSchemeRecord
     */
    lastUpdateDate: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof PricingSchemeRecord
     */
    lastUpdateAuthorId: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof PricingSchemeRecord
     */
    lastUpdateAuthorFullName: PersonFullName;
}
/**
 * 
 * @export
 * @interface PricingSchemeRecordAllOf
 */
export interface PricingSchemeRecordAllOf {
    /**
     * Наименование
     * @type {string}
     * @memberof PricingSchemeRecordAllOf
     */
    name: string;
    /**
     * 
     * @type {PricingSchemeTypeCodeEnum}
     * @memberof PricingSchemeRecordAllOf
     */
    typeCode: PricingSchemeTypeCodeEnum;
    /**
     * 
     * @type {Array<PricingLadderStepObj>}
     * @memberof PricingSchemeRecordAllOf
     */
    steps?: Array<PricingLadderStepObj>;
    /**
     * Число продуктов, использующих ценовую схему
     * @type {number}
     * @memberof PricingSchemeRecordAllOf
     */
    linkedProductCount: number;
    /**
     * Число наборов, использующих ценовую схему
     * @type {number}
     * @memberof PricingSchemeRecordAllOf
     */
    linkedKitCount: number;
    /**
     * Число профессий, использующих ценовую схему
     * @type {number}
     * @memberof PricingSchemeRecordAllOf
     */
    linkedProfessionsCount: number;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof PricingSchemeRecordAllOf
     */
    lastUpdateDate: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof PricingSchemeRecordAllOf
     */
    lastUpdateAuthorId: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof PricingSchemeRecordAllOf
     */
    lastUpdateAuthorFullName: PersonFullName;
}
/**
 * Список записей о ценовых схемах
 * @export
 * @interface PricingSchemeRecordList
 */
export interface PricingSchemeRecordList {
    /**
     * 
     * @type {ListAttributesInfo}
     * @memberof PricingSchemeRecordList
     */
    listAttributes: ListAttributesInfo;
    /**
     * 
     * @type {Array<PricingSchemeRecord>}
     * @memberof PricingSchemeRecordList
     */
    records: Array<PricingSchemeRecord>;
}
/**
 * 
 * @export
 * @interface PricingSchemeRecordListAllOf
 */
export interface PricingSchemeRecordListAllOf {
    /**
     * 
     * @type {Array<PricingSchemeRecord>}
     * @memberof PricingSchemeRecordListAllOf
     */
    records: Array<PricingSchemeRecord>;
}
/**
 * Код типа ценовой схемы
 * @export
 * @enum {string}
 */
export enum PricingSchemeTypeCodeEnum {
    LADDER = 'LADDER',
    MULTILEVEL = 'MULTI_LEVEL'
}

/**
 * Вычисляемые поля сущностей, объединяющих множество элементов
 * @export
 * @interface ProblemAndWarningRentIndicatorsObj
 */
export interface ProblemAndWarningRentIndicatorsObj {
    /**
     * Наличие любых просрочек
     * @type {boolean}
     * @memberof ProblemAndWarningRentIndicatorsObj
     */
    anyDelay: boolean;
    /**
     * Наличие любых нехваток
     * @type {boolean}
     * @memberof ProblemAndWarningRentIndicatorsObj
     */
    anyShortage: boolean;
    /**
     * 
     * @type {IndicatorObj}
     * @memberof ProblemAndWarningRentIndicatorsObj
     */
    orderShortage: IndicatorObj;
    /**
     * 
     * @type {IndicatorObj}
     * @memberof ProblemAndWarningRentIndicatorsObj
     */
    bookShortage: IndicatorObj;
    /**
     * 
     * @type {IndicatorObj}
     * @memberof ProblemAndWarningRentIndicatorsObj
     */
    giveawayDelay: IndicatorObj;
    /**
     * 
     * @type {IndicatorObj}
     * @memberof ProblemAndWarningRentIndicatorsObj
     */
    returnDelay: IndicatorObj;
    /**
     * 
     * @type {IndicatorObj}
     * @memberof ProblemAndWarningRentIndicatorsObj
     */
    subrentShipmentDelay: IndicatorObj;
    /**
     * 
     * @type {IndicatorObj}
     * @memberof ProblemAndWarningRentIndicatorsObj
     */
    subrentReturnToShipperDelay: IndicatorObj;
}
/**
 * Вычисляемые поля сущностей, объединяющих множество элементов
 * @export
 * @interface ProblemAndWarningRentIndicatorsRecordObj
 */
export interface ProblemAndWarningRentIndicatorsRecordObj {
    /**
     * Серьезность проблемы или предупреждения
     * @type {number}
     * @memberof ProblemAndWarningRentIndicatorsRecordObj
     */
    severity: number;
    /**
     * Наличие любых просрочек
     * @type {boolean}
     * @memberof ProblemAndWarningRentIndicatorsRecordObj
     */
    anyDelay: boolean;
    /**
     * Наличие любых нехваток
     * @type {boolean}
     * @memberof ProblemAndWarningRentIndicatorsRecordObj
     */
    anyShortage: boolean;
    /**
     * Наличие нехваток в заявках
     * @type {boolean}
     * @memberof ProblemAndWarningRentIndicatorsRecordObj
     */
    orderShortage: boolean;
    /**
     * Наличие нехваток по бронированиям
     * @type {boolean}
     * @memberof ProblemAndWarningRentIndicatorsRecordObj
     */
    bookShortage: boolean;
    /**
     * Наличие просрочек выдачи
     * @type {boolean}
     * @memberof ProblemAndWarningRentIndicatorsRecordObj
     */
    giveawayDelay: boolean;
    /**
     * Наличие просрочек возврата
     * @type {boolean}
     * @memberof ProblemAndWarningRentIndicatorsRecordObj
     */
    returnDelay: boolean;
    /**
     * Наличие просрочек поставки по субаренде
     * @type {boolean}
     * @memberof ProblemAndWarningRentIndicatorsRecordObj
     */
    subrentShipmentDelay: boolean;
    /**
     * Наличие просрочек возврата поставщику по субаренде
     * @type {boolean}
     * @memberof ProblemAndWarningRentIndicatorsRecordObj
     */
    subrentReturnToShipperDelay: boolean;
}
/**
 * Описание полей проблемных позиций. Варианта может не быть
 * @export
 * @interface ProblemRentElementField
 */
export interface ProblemRentElementField {
    /**
     * 
     * @type {string}
     * @memberof ProblemRentElementField
     */
    kitShortName: string;
    /**
     * 
     * @type {number}
     * @memberof ProblemRentElementField
     */
    kitId: number;
    /**
     * 
     * @type {string}
     * @memberof ProblemRentElementField
     */
    productShortName: string;
    /**
     * 
     * @type {number}
     * @memberof ProblemRentElementField
     */
    productId: number;
    /**
     * 
     * @type {string}
     * @memberof ProblemRentElementField
     */
    variantShortName?: string;
    /**
     * 
     * @type {number}
     * @memberof ProblemRentElementField
     */
    variantId?: number;
    /**
     * 
     * @type {number}
     * @memberof ProblemRentElementField
     */
    countInstance: number;
}
/**
 * Проблемные позиции, которые обнаружились при пересчете или количество измененных позиций
 * @export
 * @interface ProblematicRentElementInRecalculateOrNumberOfChanges
 */
export interface ProblematicRentElementInRecalculateOrNumberOfChanges {
    /**
     * 
     * @type {number}
     * @memberof ProblematicRentElementInRecalculateOrNumberOfChanges
     */
    successfulUpdateCount?: number;
    /**
     * 
     * @type {Array<ProblemRentElementField>}
     * @memberof ProblematicRentElementInRecalculateOrNumberOfChanges
     */
    fields?: Array<ProblemRentElementField>;
}
/**
 * Продукт: абстрактное общее состояние для всех операций
 * @export
 * @interface ProductInfoAbstractCommons
 */
export interface ProductInfoAbstractCommons {
    /**
     * Идентификатор
     * @type {number}
     * @memberof ProductInfoAbstractCommons
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof ProductInfoAbstractCommons
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof ProductInfoAbstractCommons
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof ProductInfoAbstractCommons
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof ProductInfoAbstractCommons
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof ProductInfoAbstractCommons
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof ProductInfoAbstractCommons
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof ProductInfoAbstractCommons
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof ProductInfoAbstractCommons
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof ProductInfoAbstractCommons
     */
    businessAccountId?: number;
    /**
     * Информация о главном изображении
     * @type {ImageObj}
     * @memberof ProductInfoAbstractCommons
     */
    mainImage?: ImageObj;
    /**
     * Информация об изображениях помимо главного, порядок соответствует требуемому порядку отображения
     * @type {Array<ImageObj>}
     * @memberof ProductInfoAbstractCommons
     */
    images?: Array<ImageObj>;
    /**
     * Полное официальное наименование
     * @type {string}
     * @memberof ProductInfoAbstractCommons
     */
    fullName?: string;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof ProductInfoAbstractCommons
     */
    shortName?: string;
    /**
     * Артикул
     * @type {string}
     * @memberof ProductInfoAbstractCommons
     */
    externalCode?: string;
    /**
     * Штриховой код
     * @type {string}
     * @memberof ProductInfoAbstractCommons
     */
    barcode?: string;
    /**
     * 
     * @type {Array<RentPriceForTimeMeasureObj>}
     * @memberof ProductInfoAbstractCommons
     */
    prices?: Array<RentPriceForTimeMeasureObj>;
    /**
     * Цена за смену, коп
     * @type {number}
     * @memberof ProductInfoAbstractCommons
     */
    pricePerShift?: number;
    /**
     * Базовая закупочная стоимость, коп.
     * @type {number}
     * @memberof ProductInfoAbstractCommons
     */
    basePurchaseCost?: number;
    /**
     * Страна(ы) производители
     * @type {Array<string>}
     * @memberof ProductInfoAbstractCommons
     */
    producerCountries?: Array<string>;
    /**
     * Описание
     * @type {string}
     * @memberof ProductInfoAbstractCommons
     */
    description?: string;
    /**
     * Обязательный запас времени между обязательствами, мин
     * @type {number}
     * @memberof ProductInfoAbstractCommons
     */
    requiredTimeIndentBetweenElementsInMinutes?: number;
    /**
     * Маркер, указывающий, что продукт имеет собственный обязательный запас времени между обязательствами, а не наследует значение по умолчанию для компании
     * @type {boolean}
     * @memberof ProductInfoAbstractCommons
     */
    hasOwnRequiredTimeIndentBetweenElements?: boolean;
    /**
     * Длительность смены, мин
     * @type {number}
     * @memberof ProductInfoAbstractCommons
     */
    shiftLengthInMinutes?: number;
    /**
     * Маркер, указывающий, что продукт имеет собственную длительность смены, а не наследует значение по умолчанию для компании
     * @type {boolean}
     * @memberof ProductInfoAbstractCommons
     */
    hasOwnShiftLength?: boolean;
}
/**
 * 
 * @export
 * @interface ProductInfoAbstractCommonsAllOf
 */
export interface ProductInfoAbstractCommonsAllOf {
    /**
     * Информация о главном изображении
     * @type {ImageObj}
     * @memberof ProductInfoAbstractCommonsAllOf
     */
    mainImage?: ImageObj;
    /**
     * Информация об изображениях помимо главного, порядок соответствует требуемому порядку отображения
     * @type {Array<ImageObj>}
     * @memberof ProductInfoAbstractCommonsAllOf
     */
    images?: Array<ImageObj>;
    /**
     * Полное официальное наименование
     * @type {string}
     * @memberof ProductInfoAbstractCommonsAllOf
     */
    fullName?: string;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof ProductInfoAbstractCommonsAllOf
     */
    shortName?: string;
    /**
     * Артикул
     * @type {string}
     * @memberof ProductInfoAbstractCommonsAllOf
     */
    externalCode?: string;
    /**
     * Штриховой код
     * @type {string}
     * @memberof ProductInfoAbstractCommonsAllOf
     */
    barcode?: string;
    /**
     * 
     * @type {Array<RentPriceForTimeMeasureObj>}
     * @memberof ProductInfoAbstractCommonsAllOf
     */
    prices?: Array<RentPriceForTimeMeasureObj>;
    /**
     * Цена за смену, коп
     * @type {number}
     * @memberof ProductInfoAbstractCommonsAllOf
     */
    pricePerShift?: number;
    /**
     * Базовая закупочная стоимость, коп.
     * @type {number}
     * @memberof ProductInfoAbstractCommonsAllOf
     */
    basePurchaseCost?: number;
    /**
     * Страна(ы) производители
     * @type {Array<string>}
     * @memberof ProductInfoAbstractCommonsAllOf
     */
    producerCountries?: Array<string>;
    /**
     * Описание
     * @type {string}
     * @memberof ProductInfoAbstractCommonsAllOf
     */
    description?: string;
    /**
     * Обязательный запас времени между обязательствами, мин
     * @type {number}
     * @memberof ProductInfoAbstractCommonsAllOf
     */
    requiredTimeIndentBetweenElementsInMinutes?: number;
    /**
     * Маркер, указывающий, что продукт имеет собственный обязательный запас времени между обязательствами, а не наследует значение по умолчанию для компании
     * @type {boolean}
     * @memberof ProductInfoAbstractCommonsAllOf
     */
    hasOwnRequiredTimeIndentBetweenElements?: boolean;
    /**
     * Длительность смены, мин
     * @type {number}
     * @memberof ProductInfoAbstractCommonsAllOf
     */
    shiftLengthInMinutes?: number;
    /**
     * Маркер, указывающий, что продукт имеет собственную длительность смены, а не наследует значение по умолчанию для компании
     * @type {boolean}
     * @memberof ProductInfoAbstractCommonsAllOf
     */
    hasOwnShiftLength?: boolean;
}
/**
 * Продукт : абстрактное состояние при записи
 * @export
 * @interface ProductInfoAbstractWriteCommons
 */
export interface ProductInfoAbstractWriteCommons {
    /**
     * Идентификатор
     * @type {number}
     * @memberof ProductInfoAbstractWriteCommons
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof ProductInfoAbstractWriteCommons
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof ProductInfoAbstractWriteCommons
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof ProductInfoAbstractWriteCommons
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof ProductInfoAbstractWriteCommons
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof ProductInfoAbstractWriteCommons
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof ProductInfoAbstractWriteCommons
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof ProductInfoAbstractWriteCommons
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof ProductInfoAbstractWriteCommons
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof ProductInfoAbstractWriteCommons
     */
    businessAccountId?: number;
    /**
     * Информация о главном изображении
     * @type {ImageObj}
     * @memberof ProductInfoAbstractWriteCommons
     */
    mainImage?: ImageObj;
    /**
     * Информация об изображениях помимо главного, порядок соответствует требуемому порядку отображения
     * @type {Array<ImageObj>}
     * @memberof ProductInfoAbstractWriteCommons
     */
    images?: Array<ImageObj>;
    /**
     * Полное официальное наименование
     * @type {string}
     * @memberof ProductInfoAbstractWriteCommons
     */
    fullName?: string;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof ProductInfoAbstractWriteCommons
     */
    shortName: string;
    /**
     * Артикул
     * @type {string}
     * @memberof ProductInfoAbstractWriteCommons
     */
    externalCode?: string;
    /**
     * Штриховой код
     * @type {string}
     * @memberof ProductInfoAbstractWriteCommons
     */
    barcode?: string;
    /**
     * 
     * @type {Array<RentPriceForTimeMeasureObj>}
     * @memberof ProductInfoAbstractWriteCommons
     */
    prices?: Array<RentPriceForTimeMeasureObj>;
    /**
     * Цена за смену, коп
     * @type {number}
     * @memberof ProductInfoAbstractWriteCommons
     */
    pricePerShift: number;
    /**
     * Базовая закупочная стоимость, коп.
     * @type {number}
     * @memberof ProductInfoAbstractWriteCommons
     */
    basePurchaseCost?: number;
    /**
     * Страна(ы) производители
     * @type {Array<string>}
     * @memberof ProductInfoAbstractWriteCommons
     */
    producerCountries?: Array<string>;
    /**
     * Описание
     * @type {string}
     * @memberof ProductInfoAbstractWriteCommons
     */
    description?: string;
    /**
     * Обязательный запас времени между обязательствами, мин
     * @type {number}
     * @memberof ProductInfoAbstractWriteCommons
     */
    requiredTimeIndentBetweenElementsInMinutes?: number;
    /**
     * Маркер, указывающий, что продукт имеет собственный обязательный запас времени между обязательствами, а не наследует значение по умолчанию для компании
     * @type {boolean}
     * @memberof ProductInfoAbstractWriteCommons
     */
    hasOwnRequiredTimeIndentBetweenElements: boolean;
    /**
     * Длительность смены, мин
     * @type {number}
     * @memberof ProductInfoAbstractWriteCommons
     */
    shiftLengthInMinutes?: number;
    /**
     * Маркер, указывающий, что продукт имеет собственную длительность смены, а не наследует значение по умолчанию для компании
     * @type {boolean}
     * @memberof ProductInfoAbstractWriteCommons
     */
    hasOwnShiftLength: boolean;
    /**
     * Общее число экземпляров на момент запроса
     * @type {number}
     * @memberof ProductInfoAbstractWriteCommons
     */
    stockInstanceCount: number;
    /**
     * Число экземпляров на обслуживании на момент запроса
     * @type {number}
     * @memberof ProductInfoAbstractWriteCommons
     */
    underMaintenanceInstanceCount: number;
    /**
     * 
     * @type {InstanceTrackingTypeCodeEnum}
     * @memberof ProductInfoAbstractWriteCommons
     */
    instanceTrackingTypeCode: InstanceTrackingTypeCodeEnum;
    /**
     * Ссылки: идентификаторы категорий
     * @type {Array<number>}
     * @memberof ProductInfoAbstractWriteCommons
     */
    categoryIds?: Array<number>;
    /**
     * Все кастомные поля данного продукта со значениями сгруппированные и упорядоченные
     * @type {Array<CustomFieldGroupObjWrite>}
     * @memberof ProductInfoAbstractWriteCommons
     */
    customFieldGroups?: Array<CustomFieldGroupObjWrite>;
    /**
     * Идентификатор ценовой схемы
     * @type {number}
     * @memberof ProductInfoAbstractWriteCommons
     */
    pricingSchemeId?: number;
}
/**
 * 
 * @export
 * @interface ProductInfoAbstractWriteCommonsAllOf
 */
export interface ProductInfoAbstractWriteCommonsAllOf {
    /**
     * Общее число экземпляров на момент запроса
     * @type {number}
     * @memberof ProductInfoAbstractWriteCommonsAllOf
     */
    stockInstanceCount: number;
    /**
     * Число экземпляров на обслуживании на момент запроса
     * @type {number}
     * @memberof ProductInfoAbstractWriteCommonsAllOf
     */
    underMaintenanceInstanceCount: number;
    /**
     * 
     * @type {InstanceTrackingTypeCodeEnum}
     * @memberof ProductInfoAbstractWriteCommonsAllOf
     */
    instanceTrackingTypeCode: InstanceTrackingTypeCodeEnum;
    /**
     * Ссылки: идентификаторы категорий
     * @type {Array<number>}
     * @memberof ProductInfoAbstractWriteCommonsAllOf
     */
    categoryIds?: Array<number>;
    /**
     * Все кастомные поля данного продукта со значениями сгруппированные и упорядоченные
     * @type {Array<CustomFieldGroupObjWrite>}
     * @memberof ProductInfoAbstractWriteCommonsAllOf
     */
    customFieldGroups?: Array<CustomFieldGroupObjWrite>;
    /**
     * Идентификатор ценовой схемы
     * @type {number}
     * @memberof ProductInfoAbstractWriteCommonsAllOf
     */
    pricingSchemeId?: number;
}
/**
 * Продукт : состояние при создании
 * @export
 * @interface ProductInfoCreate
 */
export interface ProductInfoCreate {
    /**
     * Идентификатор
     * @type {number}
     * @memberof ProductInfoCreate
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof ProductInfoCreate
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof ProductInfoCreate
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof ProductInfoCreate
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof ProductInfoCreate
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof ProductInfoCreate
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof ProductInfoCreate
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof ProductInfoCreate
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof ProductInfoCreate
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof ProductInfoCreate
     */
    businessAccountId?: number;
    /**
     * Информация о главном изображении
     * @type {ImageObj}
     * @memberof ProductInfoCreate
     */
    mainImage?: ImageObj;
    /**
     * Информация об изображениях помимо главного, порядок соответствует требуемому порядку отображения
     * @type {Array<ImageObj>}
     * @memberof ProductInfoCreate
     */
    images?: Array<ImageObj>;
    /**
     * Полное официальное наименование
     * @type {string}
     * @memberof ProductInfoCreate
     */
    fullName?: string;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof ProductInfoCreate
     */
    shortName: string;
    /**
     * Артикул
     * @type {string}
     * @memberof ProductInfoCreate
     */
    externalCode: string;
    /**
     * Штриховой код
     * @type {string}
     * @memberof ProductInfoCreate
     */
    barcode?: string;
    /**
     * 
     * @type {Array<RentPriceForTimeMeasureObj>}
     * @memberof ProductInfoCreate
     */
    prices?: Array<RentPriceForTimeMeasureObj>;
    /**
     * Цена за смену, коп
     * @type {number}
     * @memberof ProductInfoCreate
     */
    pricePerShift: number;
    /**
     * Базовая закупочная стоимость, коп.
     * @type {number}
     * @memberof ProductInfoCreate
     */
    basePurchaseCost?: number;
    /**
     * Страна(ы) производители
     * @type {Array<string>}
     * @memberof ProductInfoCreate
     */
    producerCountries?: Array<string>;
    /**
     * Описание
     * @type {string}
     * @memberof ProductInfoCreate
     */
    description?: string;
    /**
     * Обязательный запас времени между обязательствами, мин
     * @type {number}
     * @memberof ProductInfoCreate
     */
    requiredTimeIndentBetweenElementsInMinutes?: number;
    /**
     * Маркер, указывающий, что продукт имеет собственный обязательный запас времени между обязательствами, а не наследует значение по умолчанию для компании
     * @type {boolean}
     * @memberof ProductInfoCreate
     */
    hasOwnRequiredTimeIndentBetweenElements: boolean;
    /**
     * Длительность смены, мин
     * @type {number}
     * @memberof ProductInfoCreate
     */
    shiftLengthInMinutes?: number;
    /**
     * Маркер, указывающий, что продукт имеет собственную длительность смены, а не наследует значение по умолчанию для компании
     * @type {boolean}
     * @memberof ProductInfoCreate
     */
    hasOwnShiftLength: boolean;
    /**
     * Общее число экземпляров на момент запроса
     * @type {number}
     * @memberof ProductInfoCreate
     */
    stockInstanceCount: number;
    /**
     * Число экземпляров на обслуживании на момент запроса
     * @type {number}
     * @memberof ProductInfoCreate
     */
    underMaintenanceInstanceCount: number;
    /**
     * 
     * @type {InstanceTrackingTypeCodeEnum}
     * @memberof ProductInfoCreate
     */
    instanceTrackingTypeCode: InstanceTrackingTypeCodeEnum;
    /**
     * Ссылки: идентификаторы категорий
     * @type {Array<number>}
     * @memberof ProductInfoCreate
     */
    categoryIds?: Array<number>;
    /**
     * Все кастомные поля данного продукта со значениями сгруппированные и упорядоченные
     * @type {Array<CustomFieldGroupObjWrite>}
     * @memberof ProductInfoCreate
     */
    customFieldGroups?: Array<CustomFieldGroupObjWrite>;
    /**
     * Идентификатор ценовой схемы
     * @type {number}
     * @memberof ProductInfoCreate
     */
    pricingSchemeId?: number;
    /**
     * 
     * @type {NomenclatureStateCodeEnum}
     * @memberof ProductInfoCreate
     */
    stateCode: NomenclatureStateCodeEnum;
}
/**
 * 
 * @export
 * @interface ProductInfoCreateAllOf
 */
export interface ProductInfoCreateAllOf {
    /**
     * 
     * @type {NomenclatureStateCodeEnum}
     * @memberof ProductInfoCreateAllOf
     */
    stateCode: NomenclatureStateCodeEnum;
}
/**
 * Продукт: состояние при чтении
 * @export
 * @interface ProductInfoRead
 */
export interface ProductInfoRead {
    /**
     * Идентификатор
     * @type {number}
     * @memberof ProductInfoRead
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof ProductInfoRead
     */
    businessVersion: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof ProductInfoRead
     */
    creationAuthorId: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof ProductInfoRead
     */
    creationAuthorFullName: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof ProductInfoRead
     */
    creationDate: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof ProductInfoRead
     */
    lastUpdateAuthorId: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof ProductInfoRead
     */
    lastUpdateAuthorFullName: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof ProductInfoRead
     */
    lastUpdateDate: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof ProductInfoRead
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof ProductInfoRead
     */
    businessAccountId: number;
    /**
     * Информация о главном изображении
     * @type {ImageObj}
     * @memberof ProductInfoRead
     */
    mainImage?: ImageObj;
    /**
     * Информация об изображениях помимо главного, порядок соответствует требуемому порядку отображения
     * @type {Array<ImageObj>}
     * @memberof ProductInfoRead
     */
    images?: Array<ImageObj>;
    /**
     * Полное официальное наименование
     * @type {string}
     * @memberof ProductInfoRead
     */
    fullName: string;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof ProductInfoRead
     */
    shortName: string;
    /**
     * Артикул
     * @type {string}
     * @memberof ProductInfoRead
     */
    externalCode?: string;
    /**
     * Штриховой код
     * @type {string}
     * @memberof ProductInfoRead
     */
    barcode?: string;
    /**
     * 
     * @type {Array<RentPriceForTimeMeasureObj>}
     * @memberof ProductInfoRead
     */
    prices?: Array<RentPriceForTimeMeasureObj>;
    /**
     * Цена за смену, коп
     * @type {number}
     * @memberof ProductInfoRead
     */
    pricePerShift: number;
    /**
     * Базовая закупочная стоимость, коп.
     * @type {number}
     * @memberof ProductInfoRead
     */
    basePurchaseCost?: number;
    /**
     * Страна(ы) производители
     * @type {Array<string>}
     * @memberof ProductInfoRead
     */
    producerCountries?: Array<string>;
    /**
     * Описание
     * @type {string}
     * @memberof ProductInfoRead
     */
    description?: string;
    /**
     * Обязательный запас времени между обязательствами, мин
     * @type {number}
     * @memberof ProductInfoRead
     */
    requiredTimeIndentBetweenElementsInMinutes?: number;
    /**
     * Маркер, указывающий, что продукт имеет собственный обязательный запас времени между обязательствами, а не наследует значение по умолчанию для компании
     * @type {boolean}
     * @memberof ProductInfoRead
     */
    hasOwnRequiredTimeIndentBetweenElements?: boolean;
    /**
     * Длительность смены, мин
     * @type {number}
     * @memberof ProductInfoRead
     */
    shiftLengthInMinutes: number;
    /**
     * Маркер, указывающий, что продукт имеет собственную длительность смены, а не наследует значение по умолчанию для компании
     * @type {boolean}
     * @memberof ProductInfoRead
     */
    hasOwnShiftLength: boolean;
    /**
     * 
     * @type {AttachmentRecordList}
     * @memberof ProductInfoRead
     */
    attachments?: AttachmentRecordList;
    /**
     * Возможно удалить данную сущность
     * @type {boolean}
     * @memberof ProductInfoRead
     */
    deleteable: boolean;
    /**
     * Существую ли какие-либо обязательства по продукту, ограничивает изменение типа отслеживания экземпляров
     * @type {boolean}
     * @memberof ProductInfoRead
     */
    hasAnyRentElements: boolean;
    /**
     * Количество вариантов которые относятся к этому продукту
     * @type {number}
     * @memberof ProductInfoRead
     */
    childVariantCount?: number;
    /**
     * Количество именованных экземпляров, которые относятся к этому продукту
     * @type {number}
     * @memberof ProductInfoRead
     */
    childInstanceCount?: number;
    /**
     * Количество наборов в которых состоит продукт
     * @type {number}
     * @memberof ProductInfoRead
     */
    kitsCount?: number;
    /**
     * Количество активных наборов, в которых состоит продукт
     * @type {number}
     * @memberof ProductInfoRead
     */
    activeKitsCount?: number;
    /**
     * 
     * @type {InstanceTrackingTypeCodeEnum}
     * @memberof ProductInfoRead
     */
    instanceTrackingTypeCode: InstanceTrackingTypeCodeEnum;
    /**
     * 
     * @type {NomenclatureStateCodeEnum}
     * @memberof ProductInfoRead
     */
    stateCode: NomenclatureStateCodeEnum;
    /**
     * Коды доступных переходов для данного продукта и пользователя
     * @type {Array<SimpleTransitionCodeEnum>}
     * @memberof ProductInfoRead
     */
    availableTransitionCodes?: Array<SimpleTransitionCodeEnum>;
    /**
     * Маркер нахождения в архиве
     * @type {boolean}
     * @memberof ProductInfoRead
     */
    archive: boolean;
    /**
     * Может ли быть отправлен в архив (может, когда не является активным)
     * @type {boolean}
     * @memberof ProductInfoRead
     */
    archivable: boolean;
    /**
     * 
     * @type {PricingSchemeExternalRepresentationObj}
     * @memberof ProductInfoRead
     */
    pricingScheme?: PricingSchemeExternalRepresentationObj;
    /**
     * Прямые категории продукта (используется только при чтении)
     * @type {Array<CategoryRecord>}
     * @memberof ProductInfoRead
     */
    categories?: Array<CategoryRecord>;
    /**
     * Все кастомные поля данного продукта со значениями сгруппированные и упорядоченные
     * @type {Array<CustomFieldGroupObjRead>}
     * @memberof ProductInfoRead
     */
    customFieldGroups?: Array<CustomFieldGroupObjRead>;
    /**
     * Набор настраиваемых полей для всех вариантов продукта
     * @type {Array<CustomFieldObjRead>}
     * @memberof ProductInfoRead
     */
    variantsCustomFieldMarkers?: Array<CustomFieldObjRead>;
    /**
     * 
     * @type {RentIndicatorsObj}
     * @memberof ProductInfoRead
     */
    rentIndicators: RentIndicatorsObj;
    /**
     * 
     * @type {TimetableStackCountersObj}
     * @memberof ProductInfoRead
     */
    timetableStackCounters: TimetableStackCountersObj;
    /**
     * 
     * @type {WarehousePresenceCountersObj}
     * @memberof ProductInfoRead
     */
    warehousePresenceCounters: WarehousePresenceCountersObj;
}
/**
 * 
 * @export
 * @interface ProductInfoReadAllOf
 */
export interface ProductInfoReadAllOf {
    /**
     * 
     * @type {AttachmentRecordList}
     * @memberof ProductInfoReadAllOf
     */
    attachments?: AttachmentRecordList;
    /**
     * Возможно удалить данную сущность
     * @type {boolean}
     * @memberof ProductInfoReadAllOf
     */
    deleteable: boolean;
    /**
     * Существую ли какие-либо обязательства по продукту, ограничивает изменение типа отслеживания экземпляров
     * @type {boolean}
     * @memberof ProductInfoReadAllOf
     */
    hasAnyRentElements: boolean;
    /**
     * Количество вариантов которые относятся к этому продукту
     * @type {number}
     * @memberof ProductInfoReadAllOf
     */
    childVariantCount?: number;
    /**
     * Количество именованных экземпляров, которые относятся к этому продукту
     * @type {number}
     * @memberof ProductInfoReadAllOf
     */
    childInstanceCount?: number;
    /**
     * Количество наборов в которых состоит продукт
     * @type {number}
     * @memberof ProductInfoReadAllOf
     */
    kitsCount?: number;
    /**
     * Количество активных наборов, в которых состоит продукт
     * @type {number}
     * @memberof ProductInfoReadAllOf
     */
    activeKitsCount?: number;
    /**
     * 
     * @type {InstanceTrackingTypeCodeEnum}
     * @memberof ProductInfoReadAllOf
     */
    instanceTrackingTypeCode: InstanceTrackingTypeCodeEnum;
    /**
     * 
     * @type {NomenclatureStateCodeEnum}
     * @memberof ProductInfoReadAllOf
     */
    stateCode: NomenclatureStateCodeEnum;
    /**
     * Коды доступных переходов для данного продукта и пользователя
     * @type {Array<SimpleTransitionCodeEnum>}
     * @memberof ProductInfoReadAllOf
     */
    availableTransitionCodes?: Array<SimpleTransitionCodeEnum>;
    /**
     * Маркер нахождения в архиве
     * @type {boolean}
     * @memberof ProductInfoReadAllOf
     */
    archive: boolean;
    /**
     * Может ли быть отправлен в архив (может, когда не является активным)
     * @type {boolean}
     * @memberof ProductInfoReadAllOf
     */
    archivable: boolean;
    /**
     * 
     * @type {PricingSchemeExternalRepresentationObj}
     * @memberof ProductInfoReadAllOf
     */
    pricingScheme?: PricingSchemeExternalRepresentationObj;
    /**
     * Прямые категории продукта (используется только при чтении)
     * @type {Array<CategoryRecord>}
     * @memberof ProductInfoReadAllOf
     */
    categories?: Array<CategoryRecord>;
    /**
     * Все кастомные поля данного продукта со значениями сгруппированные и упорядоченные
     * @type {Array<CustomFieldGroupObjRead>}
     * @memberof ProductInfoReadAllOf
     */
    customFieldGroups?: Array<CustomFieldGroupObjRead>;
    /**
     * Набор настраиваемых полей для всех вариантов продукта
     * @type {Array<CustomFieldObjRead>}
     * @memberof ProductInfoReadAllOf
     */
    variantsCustomFieldMarkers?: Array<CustomFieldObjRead>;
    /**
     * 
     * @type {RentIndicatorsObj}
     * @memberof ProductInfoReadAllOf
     */
    rentIndicators: RentIndicatorsObj;
    /**
     * 
     * @type {TimetableStackCountersObj}
     * @memberof ProductInfoReadAllOf
     */
    timetableStackCounters: TimetableStackCountersObj;
    /**
     * 
     * @type {WarehousePresenceCountersObj}
     * @memberof ProductInfoReadAllOf
     */
    warehousePresenceCounters: WarehousePresenceCountersObj;
}
/**
 * Продукт : состояние при изменении
 * @export
 * @interface ProductInfoUpdate
 */
export interface ProductInfoUpdate extends ProductInfoAbstractWriteCommons {
}
/**
 * Списочное представление продукта
 * @export
 * @interface ProductRecord
 */
export interface ProductRecord {
    /**
     * Идентификатор
     * @type {number}
     * @memberof ProductRecord
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof ProductRecord
     */
    businessVersion: number;
    /**
     * Ссылка: Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof ProductRecord
     */
    businessAccountId: number;
    /**
     * Информация о главном изображении
     * @type {ImageObj}
     * @memberof ProductRecord
     */
    mainImage?: ImageObj;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof ProductRecord
     */
    shortName: string;
    /**
     * Артикул
     * @type {string}
     * @memberof ProductRecord
     */
    externalCode?: string;
    /**
     * Идентификатор основной категории
     * @type {number}
     * @memberof ProductRecord
     */
    mainCategoryId?: number;
    /**
     * Наименование основной категории
     * @type {string}
     * @memberof ProductRecord
     */
    mainCategoryName?: string;
    /**
     * 
     * @type {NomenclatureStateCodeEnum}
     * @memberof ProductRecord
     */
    stateCode: NomenclatureStateCodeEnum;
    /**
     * Коды доступных переходов для данного продукта и пользователя
     * @type {Array<SimpleTransitionCodeEnum>}
     * @memberof ProductRecord
     */
    availableTransitionCodes?: Array<SimpleTransitionCodeEnum>;
    /**
     * Находится в архиве
     * @type {boolean}
     * @memberof ProductRecord
     */
    archive: boolean;
    /**
     * Может ли быть отправлен в архив (может, когда не является активным)
     * @type {boolean}
     * @memberof ProductRecord
     */
    archivable: boolean;
    /**
     * Возможно удалить данную сущность
     * @type {boolean}
     * @memberof ProductRecord
     */
    deleteable: boolean;
    /**
     * Идентификаторы именованных экземпляров
     * @type {Array<number>}
     * @memberof ProductRecord
     */
    instanceIds?: Array<number>;
    /**
     * Цена за смену, коп.
     * @type {number}
     * @memberof ProductRecord
     */
    pricePerShift: number;
    /**
     * Идентификатор ценовой схемы
     * @type {number}
     * @memberof ProductRecord
     */
    pricingSchemeId?: number;
    /**
     * Идентификаторы категорий
     * @type {Array<number>}
     * @memberof ProductRecord
     */
    categoryIds: Array<number>;
    /**
     * 
     * @type {InstanceTrackingTypeCodeEnum}
     * @memberof ProductRecord
     */
    instanceTrackingTypeCode: InstanceTrackingTypeCodeEnum;
    /**
     * 
     * @type {ProblemAndWarningRentIndicatorsRecordObj}
     * @memberof ProductRecord
     */
    problemsAndWarnings: ProblemAndWarningRentIndicatorsRecordObj;
    /**
     * Версия карты состояний
     * @type {number}
     * @memberof ProductRecord
     */
    timetableVersion: number;
    /**
     * 
     * @type {WarehousePresenceCountersObj}
     * @memberof ProductRecord
     */
    warehousePresenceCounters: WarehousePresenceCountersObj;
    /**
     * Дата и время последней активности (измение сущности либо операция над связанными обязательствами)
     * @type {Date}
     * @memberof ProductRecord
     */
    lastActivityDate: Date;
}
/**
 * 
 * @export
 * @interface ProductRecordAllOf
 */
export interface ProductRecordAllOf {
    /**
     * Информация о главном изображении
     * @type {ImageObj}
     * @memberof ProductRecordAllOf
     */
    mainImage?: ImageObj;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof ProductRecordAllOf
     */
    shortName: string;
    /**
     * Артикул
     * @type {string}
     * @memberof ProductRecordAllOf
     */
    externalCode?: string;
    /**
     * Идентификатор основной категории
     * @type {number}
     * @memberof ProductRecordAllOf
     */
    mainCategoryId?: number;
    /**
     * Наименование основной категории
     * @type {string}
     * @memberof ProductRecordAllOf
     */
    mainCategoryName?: string;
    /**
     * 
     * @type {NomenclatureStateCodeEnum}
     * @memberof ProductRecordAllOf
     */
    stateCode: NomenclatureStateCodeEnum;
    /**
     * Коды доступных переходов для данного продукта и пользователя
     * @type {Array<SimpleTransitionCodeEnum>}
     * @memberof ProductRecordAllOf
     */
    availableTransitionCodes?: Array<SimpleTransitionCodeEnum>;
    /**
     * Находится в архиве
     * @type {boolean}
     * @memberof ProductRecordAllOf
     */
    archive: boolean;
    /**
     * Может ли быть отправлен в архив (может, когда не является активным)
     * @type {boolean}
     * @memberof ProductRecordAllOf
     */
    archivable: boolean;
    /**
     * Возможно удалить данную сущность
     * @type {boolean}
     * @memberof ProductRecordAllOf
     */
    deleteable: boolean;
    /**
     * Идентификаторы именованных экземпляров
     * @type {Array<number>}
     * @memberof ProductRecordAllOf
     */
    instanceIds?: Array<number>;
    /**
     * Цена за смену, коп.
     * @type {number}
     * @memberof ProductRecordAllOf
     */
    pricePerShift: number;
    /**
     * Идентификатор ценовой схемы
     * @type {number}
     * @memberof ProductRecordAllOf
     */
    pricingSchemeId?: number;
    /**
     * Идентификаторы категорий
     * @type {Array<number>}
     * @memberof ProductRecordAllOf
     */
    categoryIds: Array<number>;
    /**
     * 
     * @type {InstanceTrackingTypeCodeEnum}
     * @memberof ProductRecordAllOf
     */
    instanceTrackingTypeCode: InstanceTrackingTypeCodeEnum;
    /**
     * 
     * @type {ProblemAndWarningRentIndicatorsRecordObj}
     * @memberof ProductRecordAllOf
     */
    problemsAndWarnings: ProblemAndWarningRentIndicatorsRecordObj;
    /**
     * Версия карты состояний
     * @type {number}
     * @memberof ProductRecordAllOf
     */
    timetableVersion: number;
    /**
     * 
     * @type {WarehousePresenceCountersObj}
     * @memberof ProductRecordAllOf
     */
    warehousePresenceCounters: WarehousePresenceCountersObj;
    /**
     * Дата и время последней активности (измение сущности либо операция над связанными обязательствами)
     * @type {Date}
     * @memberof ProductRecordAllOf
     */
    lastActivityDate: Date;
}
/**
 * Список записей продуктов
 * @export
 * @interface ProductRecordList
 */
export interface ProductRecordList {
    /**
     * 
     * @type {ListAttributesInfo}
     * @memberof ProductRecordList
     */
    listAttributes: ListAttributesInfo;
    /**
     * 
     * @type {Array<ProductRecord>}
     * @memberof ProductRecordList
     */
    records: Array<ProductRecord>;
}
/**
 * 
 * @export
 * @interface ProductRecordListAllOf
 */
export interface ProductRecordListAllOf {
    /**
     * 
     * @type {Array<ProductRecord>}
     * @memberof ProductRecordListAllOf
     */
    records: Array<ProductRecord>;
}
/**
 * Список записей контактов
 * @export
 * @interface ProfessionAssignmentObjRead
 */
export interface ProfessionAssignmentObjRead {
    /**
     * Идентификатор профессии
     * @type {number}
     * @memberof ProfessionAssignmentObjRead
     */
    professionId: number;
    /**
     * 
     * @type {CrewMemberRatesObj}
     * @memberof ProfessionAssignmentObjRead
     */
    rate?: CrewMemberRatesObj;
    /**
     * Краткое наименование профессии
     * @type {string}
     * @memberof ProfessionAssignmentObjRead
     */
    professionShortName: string;
    /**
     * Порядковый номер
     * @type {number}
     * @memberof ProfessionAssignmentObjRead
     */
    ordinal?: number;
}
/**
 * 
 * @export
 * @interface ProfessionAssignmentObjReadAllOf
 */
export interface ProfessionAssignmentObjReadAllOf {
    /**
     * Краткое наименование профессии
     * @type {string}
     * @memberof ProfessionAssignmentObjReadAllOf
     */
    professionShortName: string;
    /**
     * Порядковый номер
     * @type {number}
     * @memberof ProfessionAssignmentObjReadAllOf
     */
    ordinal?: number;
}
/**
 * 
 * @export
 * @interface ProfessionAssignmentObjWrite
 */
export interface ProfessionAssignmentObjWrite {
    /**
     * Идентификатор профессии
     * @type {number}
     * @memberof ProfessionAssignmentObjWrite
     */
    professionId: number;
    /**
     * 
     * @type {CrewMemberRatesObj}
     * @memberof ProfessionAssignmentObjWrite
     */
    rate?: CrewMemberRatesObj;
}
/**
 * Финансовые поля сущности ProfessionRecord, которые могут быть выгружены в список Excel
 * @export
 * @enum {string}
 */
export enum ProfessionFinancialFieldsTypeCode {
    PricePerShift = 'pricePerShift'
}

/**
 * Продукт: абстрактное общее состояние для всех операций
 * @export
 * @interface ProfessionInfoAbstractCommons
 */
export interface ProfessionInfoAbstractCommons {
    /**
     * Идентификатор
     * @type {number}
     * @memberof ProfessionInfoAbstractCommons
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof ProfessionInfoAbstractCommons
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof ProfessionInfoAbstractCommons
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof ProfessionInfoAbstractCommons
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof ProfessionInfoAbstractCommons
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof ProfessionInfoAbstractCommons
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof ProfessionInfoAbstractCommons
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof ProfessionInfoAbstractCommons
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof ProfessionInfoAbstractCommons
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof ProfessionInfoAbstractCommons
     */
    businessAccountId?: number;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof ProfessionInfoAbstractCommons
     */
    shortName: string;
    /**
     * Полное официальное наименование
     * @type {string}
     * @memberof ProfessionInfoAbstractCommons
     */
    fullName?: string;
    /**
     * Артикул
     * @type {string}
     * @memberof ProfessionInfoAbstractCommons
     */
    externalCode?: string;
    /**
     * Информация о главном изображении
     * @type {ImageObj}
     * @memberof ProfessionInfoAbstractCommons
     */
    mainImage?: ImageObj;
    /**
     * Цена за смену, коп
     * @type {number}
     * @memberof ProfessionInfoAbstractCommons
     */
    pricePerShift: number;
    /**
     * Описание
     * @type {string}
     * @memberof ProfessionInfoAbstractCommons
     */
    description?: string;
    /**
     * Длительность смены, мин
     * @type {number}
     * @memberof ProfessionInfoAbstractCommons
     */
    shiftLengthInMinutes?: number;
    /**
     * Маркер, указывающий, что продукт имеет собственную длительность смены, а не наследует значение по умолчанию для компании
     * @type {boolean}
     * @memberof ProfessionInfoAbstractCommons
     */
    hasOwnShiftLength: boolean;
}
/**
 * 
 * @export
 * @interface ProfessionInfoAbstractCommonsAllOf
 */
export interface ProfessionInfoAbstractCommonsAllOf {
    /**
     * Короткое наименование
     * @type {string}
     * @memberof ProfessionInfoAbstractCommonsAllOf
     */
    shortName: string;
    /**
     * Полное официальное наименование
     * @type {string}
     * @memberof ProfessionInfoAbstractCommonsAllOf
     */
    fullName?: string;
    /**
     * Артикул
     * @type {string}
     * @memberof ProfessionInfoAbstractCommonsAllOf
     */
    externalCode?: string;
    /**
     * Информация о главном изображении
     * @type {ImageObj}
     * @memberof ProfessionInfoAbstractCommonsAllOf
     */
    mainImage?: ImageObj;
    /**
     * Цена за смену, коп
     * @type {number}
     * @memberof ProfessionInfoAbstractCommonsAllOf
     */
    pricePerShift: number;
    /**
     * Описание
     * @type {string}
     * @memberof ProfessionInfoAbstractCommonsAllOf
     */
    description?: string;
    /**
     * Длительность смены, мин
     * @type {number}
     * @memberof ProfessionInfoAbstractCommonsAllOf
     */
    shiftLengthInMinutes?: number;
    /**
     * Маркер, указывающий, что продукт имеет собственную длительность смены, а не наследует значение по умолчанию для компании
     * @type {boolean}
     * @memberof ProfessionInfoAbstractCommonsAllOf
     */
    hasOwnShiftLength: boolean;
}
/**
 * 
 * @export
 * @interface ProfessionInfoAbstractWriteCommons
 */
export interface ProfessionInfoAbstractWriteCommons {
    /**
     * Идентификатор
     * @type {number}
     * @memberof ProfessionInfoAbstractWriteCommons
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof ProfessionInfoAbstractWriteCommons
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof ProfessionInfoAbstractWriteCommons
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof ProfessionInfoAbstractWriteCommons
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof ProfessionInfoAbstractWriteCommons
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof ProfessionInfoAbstractWriteCommons
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof ProfessionInfoAbstractWriteCommons
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof ProfessionInfoAbstractWriteCommons
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof ProfessionInfoAbstractWriteCommons
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof ProfessionInfoAbstractWriteCommons
     */
    businessAccountId?: number;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof ProfessionInfoAbstractWriteCommons
     */
    shortName: string;
    /**
     * Полное официальное наименование
     * @type {string}
     * @memberof ProfessionInfoAbstractWriteCommons
     */
    fullName?: string;
    /**
     * Артикул
     * @type {string}
     * @memberof ProfessionInfoAbstractWriteCommons
     */
    externalCode?: string;
    /**
     * Информация о главном изображении
     * @type {ImageObj}
     * @memberof ProfessionInfoAbstractWriteCommons
     */
    mainImage?: ImageObj;
    /**
     * Цена за смену, коп
     * @type {number}
     * @memberof ProfessionInfoAbstractWriteCommons
     */
    pricePerShift: number;
    /**
     * Описание
     * @type {string}
     * @memberof ProfessionInfoAbstractWriteCommons
     */
    description?: string;
    /**
     * Длительность смены, мин
     * @type {number}
     * @memberof ProfessionInfoAbstractWriteCommons
     */
    shiftLengthInMinutes?: number;
    /**
     * Маркер, указывающий, что продукт имеет собственную длительность смены, а не наследует значение по умолчанию для компании
     * @type {boolean}
     * @memberof ProfessionInfoAbstractWriteCommons
     */
    hasOwnShiftLength: boolean;
    /**
     * Идентификатор ценовой схемы
     * @type {number}
     * @memberof ProfessionInfoAbstractWriteCommons
     */
    pricingSchemeId?: number;
}
/**
 * 
 * @export
 * @interface ProfessionInfoAbstractWriteCommonsAllOf
 */
export interface ProfessionInfoAbstractWriteCommonsAllOf {
    /**
     * Идентификатор ценовой схемы
     * @type {number}
     * @memberof ProfessionInfoAbstractWriteCommonsAllOf
     */
    pricingSchemeId?: number;
}
/**
 * Профессия: состояние при создании
 * @export
 * @interface ProfessionInfoCreate
 */
export interface ProfessionInfoCreate {
    /**
     * Идентификатор
     * @type {number}
     * @memberof ProfessionInfoCreate
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof ProfessionInfoCreate
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof ProfessionInfoCreate
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof ProfessionInfoCreate
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof ProfessionInfoCreate
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof ProfessionInfoCreate
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof ProfessionInfoCreate
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof ProfessionInfoCreate
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof ProfessionInfoCreate
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof ProfessionInfoCreate
     */
    businessAccountId?: number;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof ProfessionInfoCreate
     */
    shortName: string;
    /**
     * Полное официальное наименование
     * @type {string}
     * @memberof ProfessionInfoCreate
     */
    fullName?: string;
    /**
     * Артикул
     * @type {string}
     * @memberof ProfessionInfoCreate
     */
    externalCode?: string;
    /**
     * Информация о главном изображении
     * @type {ImageObj}
     * @memberof ProfessionInfoCreate
     */
    mainImage?: ImageObj;
    /**
     * Цена за смену, коп
     * @type {number}
     * @memberof ProfessionInfoCreate
     */
    pricePerShift: number;
    /**
     * Описание
     * @type {string}
     * @memberof ProfessionInfoCreate
     */
    description?: string;
    /**
     * Длительность смены, мин
     * @type {number}
     * @memberof ProfessionInfoCreate
     */
    shiftLengthInMinutes?: number;
    /**
     * Маркер, указывающий, что продукт имеет собственную длительность смены, а не наследует значение по умолчанию для компании
     * @type {boolean}
     * @memberof ProfessionInfoCreate
     */
    hasOwnShiftLength: boolean;
    /**
     * Идентификатор ценовой схемы
     * @type {number}
     * @memberof ProfessionInfoCreate
     */
    pricingSchemeId?: number;
    /**
     * 
     * @type {ProfessionStateCodeEnum}
     * @memberof ProfessionInfoCreate
     */
    stateCode: ProfessionStateCodeEnum;
}
/**
 * 
 * @export
 * @interface ProfessionInfoCreateAllOf
 */
export interface ProfessionInfoCreateAllOf {
    /**
     * 
     * @type {ProfessionStateCodeEnum}
     * @memberof ProfessionInfoCreateAllOf
     */
    stateCode: ProfessionStateCodeEnum;
}
/**
 * Профессия: состояние при чтении
 * @export
 * @interface ProfessionInfoRead
 */
export interface ProfessionInfoRead {
    /**
     * Идентификатор
     * @type {number}
     * @memberof ProfessionInfoRead
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof ProfessionInfoRead
     */
    businessVersion: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof ProfessionInfoRead
     */
    creationAuthorId: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof ProfessionInfoRead
     */
    creationAuthorFullName: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof ProfessionInfoRead
     */
    creationDate: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof ProfessionInfoRead
     */
    lastUpdateAuthorId: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof ProfessionInfoRead
     */
    lastUpdateAuthorFullName: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof ProfessionInfoRead
     */
    lastUpdateDate: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof ProfessionInfoRead
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof ProfessionInfoRead
     */
    businessAccountId: number;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof ProfessionInfoRead
     */
    shortName: string;
    /**
     * Полное официальное наименование
     * @type {string}
     * @memberof ProfessionInfoRead
     */
    fullName?: string;
    /**
     * Артикул
     * @type {string}
     * @memberof ProfessionInfoRead
     */
    externalCode?: string;
    /**
     * Информация о главном изображении
     * @type {ImageObj}
     * @memberof ProfessionInfoRead
     */
    mainImage?: ImageObj;
    /**
     * Цена за смену, коп
     * @type {number}
     * @memberof ProfessionInfoRead
     */
    pricePerShift: number;
    /**
     * Описание
     * @type {string}
     * @memberof ProfessionInfoRead
     */
    description?: string;
    /**
     * Длительность смены, мин
     * @type {number}
     * @memberof ProfessionInfoRead
     */
    shiftLengthInMinutes: number;
    /**
     * Маркер, указывающий, что продукт имеет собственную длительность смены, а не наследует значение по умолчанию для компании
     * @type {boolean}
     * @memberof ProfessionInfoRead
     */
    hasOwnShiftLength: boolean;
    /**
     * 
     * @type {AttachmentRecordList}
     * @memberof ProfessionInfoRead
     */
    attachments?: AttachmentRecordList;
    /**
     * Возможно удалить данную сущность
     * @type {boolean}
     * @memberof ProfessionInfoRead
     */
    deleteable: boolean;
    /**
     * 
     * @type {ProfessionStateCodeEnum}
     * @memberof ProfessionInfoRead
     */
    stateCode: ProfessionStateCodeEnum;
    /**
     * Коды доступных переходов для данного продукта и пользователя
     * @type {Array<SimpleTransitionCodeEnum>}
     * @memberof ProfessionInfoRead
     */
    availableTransitionCodes?: Array<SimpleTransitionCodeEnum>;
    /**
     * Маркер нахождения в архиве
     * @type {boolean}
     * @memberof ProfessionInfoRead
     */
    archive: boolean;
    /**
     * Может ли быть отправлен в архив (может, когда не является активным)
     * @type {boolean}
     * @memberof ProfessionInfoRead
     */
    archivable: boolean;
    /**
     * Версия карты состояний
     * @type {number}
     * @memberof ProfessionInfoRead
     */
    timetableVersion: number;
    /**
     * 
     * @type {PricingSchemeExternalRepresentationObj}
     * @memberof ProfessionInfoRead
     */
    pricingScheme?: PricingSchemeExternalRepresentationObj;
    /**
     * Количество сотрудников компании с данной профессией в активном статусе
     * @type {number}
     * @memberof ProfessionInfoRead
     */
    ownActiveCrewMemberCount: number;
}
/**
 * 
 * @export
 * @interface ProfessionInfoReadAllOf
 */
export interface ProfessionInfoReadAllOf {
    /**
     * 
     * @type {AttachmentRecordList}
     * @memberof ProfessionInfoReadAllOf
     */
    attachments?: AttachmentRecordList;
    /**
     * Возможно удалить данную сущность
     * @type {boolean}
     * @memberof ProfessionInfoReadAllOf
     */
    deleteable: boolean;
    /**
     * 
     * @type {ProfessionStateCodeEnum}
     * @memberof ProfessionInfoReadAllOf
     */
    stateCode: ProfessionStateCodeEnum;
    /**
     * Коды доступных переходов для данного продукта и пользователя
     * @type {Array<SimpleTransitionCodeEnum>}
     * @memberof ProfessionInfoReadAllOf
     */
    availableTransitionCodes?: Array<SimpleTransitionCodeEnum>;
    /**
     * Маркер нахождения в архиве
     * @type {boolean}
     * @memberof ProfessionInfoReadAllOf
     */
    archive: boolean;
    /**
     * Может ли быть отправлен в архив (может, когда не является активным)
     * @type {boolean}
     * @memberof ProfessionInfoReadAllOf
     */
    archivable: boolean;
    /**
     * Версия карты состояний
     * @type {number}
     * @memberof ProfessionInfoReadAllOf
     */
    timetableVersion: number;
    /**
     * 
     * @type {PricingSchemeExternalRepresentationObj}
     * @memberof ProfessionInfoReadAllOf
     */
    pricingScheme?: PricingSchemeExternalRepresentationObj;
    /**
     * Количество сотрудников компании с данной профессией в активном статусе
     * @type {number}
     * @memberof ProfessionInfoReadAllOf
     */
    ownActiveCrewMemberCount: number;
}
/**
 * Профессия: состояние при обновлении
 * @export
 * @interface ProfessionInfoUpdate
 */
export interface ProfessionInfoUpdate extends ProfessionInfoAbstractWriteCommons {
}
/**
 * Не финансовые поля сущности ProfessionRecord, которые могут быть выгружены в список Excel
 * @export
 * @enum {string}
 */
export enum ProfessionNotFinancialFieldsTypeCode {
    ShortName = 'shortName',
    ExternalCode = 'externalCode',
    StateCode = 'stateCode',
    OwnActiveCrewMemberCount = 'ownActiveCrewMemberCount',
    LastActivityDate = 'lastActivityDate'
}

/**
 * Профессия: запись списочного представления
 * @export
 * @interface ProfessionRecord
 */
export interface ProfessionRecord {
    /**
     * Идентификатор
     * @type {number}
     * @memberof ProfessionRecord
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof ProfessionRecord
     */
    businessVersion: number;
    /**
     * Ссылка: Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof ProfessionRecord
     */
    businessAccountId: number;
    /**
     * 
     * @type {PricingSchemeExternalRepresentationObj}
     * @memberof ProfessionRecord
     */
    pricingScheme?: PricingSchemeExternalRepresentationObj;
    /**
     * Информация о главном изображении
     * @type {ImageObj}
     * @memberof ProfessionRecord
     */
    mainImage?: ImageObj;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof ProfessionRecord
     */
    shortName: string;
    /**
     * Артикул
     * @type {string}
     * @memberof ProfessionRecord
     */
    externalCode?: string;
    /**
     * 
     * @type {ProfessionStateCodeEnum}
     * @memberof ProfessionRecord
     */
    stateCode: ProfessionStateCodeEnum;
    /**
     * Коды доступных переходов для данного продукта и пользователя
     * @type {Array<SimpleTransitionCodeEnum>}
     * @memberof ProfessionRecord
     */
    availableTransitionCodes?: Array<SimpleTransitionCodeEnum>;
    /**
     * Находится в архиве
     * @type {boolean}
     * @memberof ProfessionRecord
     */
    archive: boolean;
    /**
     * Может ли быть отправлен в архив (может, когда не является активным)
     * @type {boolean}
     * @memberof ProfessionRecord
     */
    archivable: boolean;
    /**
     * Возможно удалить данную сущность
     * @type {boolean}
     * @memberof ProfessionRecord
     */
    deleteable: boolean;
    /**
     * Цена за смену, коп.
     * @type {number}
     * @memberof ProfessionRecord
     */
    pricePerShift: number;
    /**
     * Версия карты состояний
     * @type {number}
     * @memberof ProfessionRecord
     */
    timetableVersion: number;
    /**
     * Количество сотрудников компании с данной профессией в активном статусе
     * @type {number}
     * @memberof ProfessionRecord
     */
    ownActiveCrewMemberCount: number;
    /**
     * Длительность смены, мин. Если null - берется длина смены по компании
     * @type {number}
     * @memberof ProfessionRecord
     */
    shiftLengthInMinutes?: number;
    /**
     * Дата и время последней активности (измение сущности либо операция над связанными обязательствами)
     * @type {Date}
     * @memberof ProfessionRecord
     */
    lastActivityDate: Date;
}
/**
 * 
 * @export
 * @interface ProfessionRecordAllOf
 */
export interface ProfessionRecordAllOf {
    /**
     * 
     * @type {PricingSchemeExternalRepresentationObj}
     * @memberof ProfessionRecordAllOf
     */
    pricingScheme?: PricingSchemeExternalRepresentationObj;
    /**
     * Информация о главном изображении
     * @type {ImageObj}
     * @memberof ProfessionRecordAllOf
     */
    mainImage?: ImageObj;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof ProfessionRecordAllOf
     */
    shortName: string;
    /**
     * Артикул
     * @type {string}
     * @memberof ProfessionRecordAllOf
     */
    externalCode?: string;
    /**
     * 
     * @type {ProfessionStateCodeEnum}
     * @memberof ProfessionRecordAllOf
     */
    stateCode: ProfessionStateCodeEnum;
    /**
     * Коды доступных переходов для данного продукта и пользователя
     * @type {Array<SimpleTransitionCodeEnum>}
     * @memberof ProfessionRecordAllOf
     */
    availableTransitionCodes?: Array<SimpleTransitionCodeEnum>;
    /**
     * Находится в архиве
     * @type {boolean}
     * @memberof ProfessionRecordAllOf
     */
    archive: boolean;
    /**
     * Может ли быть отправлен в архив (может, когда не является активным)
     * @type {boolean}
     * @memberof ProfessionRecordAllOf
     */
    archivable: boolean;
    /**
     * Возможно удалить данную сущность
     * @type {boolean}
     * @memberof ProfessionRecordAllOf
     */
    deleteable: boolean;
    /**
     * Цена за смену, коп.
     * @type {number}
     * @memberof ProfessionRecordAllOf
     */
    pricePerShift: number;
    /**
     * Версия карты состояний
     * @type {number}
     * @memberof ProfessionRecordAllOf
     */
    timetableVersion: number;
    /**
     * Количество сотрудников компании с данной профессией в активном статусе
     * @type {number}
     * @memberof ProfessionRecordAllOf
     */
    ownActiveCrewMemberCount: number;
    /**
     * Длительность смены, мин. Если null - берется длина смены по компании
     * @type {number}
     * @memberof ProfessionRecordAllOf
     */
    shiftLengthInMinutes?: number;
    /**
     * Дата и время последней активности (измение сущности либо операция над связанными обязательствами)
     * @type {Date}
     * @memberof ProfessionRecordAllOf
     */
    lastActivityDate: Date;
}
/**
 * Список записей профессий
 * @export
 * @interface ProfessionRecordList
 */
export interface ProfessionRecordList {
    /**
     * 
     * @type {ListAttributesInfo}
     * @memberof ProfessionRecordList
     */
    listAttributes: ListAttributesInfo;
    /**
     * 
     * @type {Array<ProfessionRecord>}
     * @memberof ProfessionRecordList
     */
    records: Array<ProfessionRecord>;
}
/**
 * 
 * @export
 * @interface ProfessionRecordListAllOf
 */
export interface ProfessionRecordListAllOf {
    /**
     * 
     * @type {Array<ProfessionRecord>}
     * @memberof ProfessionRecordListAllOf
     */
    records: Array<ProfessionRecord>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ProfessionStateCodeEnum {
    ACTIVE = 'ACTIVE',
    BLOCKED = 'BLOCKED'
}

/**
 * Вычисляемые показатели проекта
 * @export
 * @interface ProjectIndicatorsObj
 */
export interface ProjectIndicatorsObj {
    /**
     * Стоимость всех элементов нового проекта (только черновиков т.к. другие не допустимо создавать), в коп.
     * @type {number}
     * @memberof ProjectIndicatorsObj
     */
    newProjectElementsSum?: number;
}
/**
 * Проект: абстрактное общее состояние для всех операций
 * @export
 * @interface ProjectInfoAbstractCommons
 */
export interface ProjectInfoAbstractCommons {
    /**
     * Идентификатор
     * @type {number}
     * @memberof ProjectInfoAbstractCommons
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof ProjectInfoAbstractCommons
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof ProjectInfoAbstractCommons
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof ProjectInfoAbstractCommons
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof ProjectInfoAbstractCommons
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof ProjectInfoAbstractCommons
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof ProjectInfoAbstractCommons
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof ProjectInfoAbstractCommons
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof ProjectInfoAbstractCommons
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof ProjectInfoAbstractCommons
     */
    businessAccountId?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectInfoAbstractCommons
     */
    tags?: Array<string>;
    /**
     * Ссылка: идентификатор арендатора
     * @type {number}
     * @memberof ProjectInfoAbstractCommons
     */
    renterId: number;
    /**
     * Ссылка: идентификатор пользователя - сотрудника абонента, ответственного за проект
     * @type {number}
     * @memberof ProjectInfoAbstractCommons
     */
    assigneeId?: number;
    /**
     * Выч по ссылке: ФИО ответственного пользователя
     * @type {PersonFullName}
     * @memberof ProjectInfoAbstractCommons
     */
    assigneeFullName?: PersonFullName;
    /**
     * Маркер, указывающий, что проект имеет собственного ответственного, а не наследует его от арендатора
     * @type {boolean}
     * @memberof ProjectInfoAbstractCommons
     */
    hasOwnAssignee?: boolean;
    /**
     * Полное официальное наименование
     * @type {string}
     * @memberof ProjectInfoAbstractCommons
     */
    fullName?: string;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof ProjectInfoAbstractCommons
     */
    shortName: string;
    /**
     * Применяемый процент скидки по умолчанию - по арендатору или нет
     * @type {number}
     * @memberof ProjectInfoAbstractCommons
     */
    defaultDiscount?: number;
    /**
     * Маркер, указывающий, что проект имеет собственную скидку по умолчанию, а не наследует от арендатора
     * @type {boolean}
     * @memberof ProjectInfoAbstractCommons
     */
    hasOwnDefaultDiscount?: boolean;
    /**
     * Применяемый процент налога - применяется к суммарной стоимости проекта
     * @type {number}
     * @memberof ProjectInfoAbstractCommons
     */
    taxRate?: number;
    /**
     * 
     * @type {TaxBaseTypeCodeEnum}
     * @memberof ProjectInfoAbstractCommons
     */
    taxBaseType?: TaxBaseTypeCodeEnum;
    /**
     * Дата начала работ по проекту (по договору)
     * @type {Date}
     * @memberof ProjectInfoAbstractCommons
     */
    startDate?: Date;
    /**
     * Ожидаемая дата завершения проекта (по договору)
     * @type {Date}
     * @memberof ProjectInfoAbstractCommons
     */
    targetFinishDate?: Date;
    /**
     * Идентификатор локации
     * @type {number}
     * @memberof ProjectInfoAbstractCommons
     */
    locationId?: number;
    /**
     * Описание
     * @type {string}
     * @memberof ProjectInfoAbstractCommons
     */
    description?: string;
    /**
     * 
     * @type {Array<ContactPersonAPIObj>}
     * @memberof ProjectInfoAbstractCommons
     */
    contacts?: Array<ContactPersonAPIObj>;
    /**
     * Маркер, указывающий, что проект имеет собственные контакты
     * @type {boolean}
     * @memberof ProjectInfoAbstractCommons
     */
    hasOwnContacts?: boolean;
    /**
     * 
     * @type {ProjectTypeCodeEnum}
     * @memberof ProjectInfoAbstractCommons
     */
    projectType: ProjectTypeCodeEnum;
    /**
     * Маркер, указывающий, что проект является частью стоимости другого проекта. Используется только для КП (OFFER)
     * @type {boolean}
     * @memberof ProjectInfoAbstractCommons
     */
    partOfThePrice?: boolean;
    /**
     * Ссылка: идентификатор основного проекта. Используется только для КП (OFFER)
     * @type {number}
     * @memberof ProjectInfoAbstractCommons
     */
    mainProjectId?: number;
    /**
     * 
     * @type {TypeOfPricingCodeEnum}
     * @memberof ProjectInfoAbstractCommons
     */
    typeOfPricing?: TypeOfPricingCodeEnum;
    /**
     * Число неотмененных КП
     * @type {number}
     * @memberof ProjectInfoAbstractCommons
     */
    totalNotCanceledOffersCount?: number;
    /**
     * Число КП включенных в стоимость
     * @type {number}
     * @memberof ProjectInfoAbstractCommons
     */
    totalPartOfPriceOffersCount?: number;
}
/**
 * 
 * @export
 * @interface ProjectInfoAbstractCommonsAllOf
 */
export interface ProjectInfoAbstractCommonsAllOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectInfoAbstractCommonsAllOf
     */
    tags?: Array<string>;
    /**
     * Ссылка: идентификатор арендатора
     * @type {number}
     * @memberof ProjectInfoAbstractCommonsAllOf
     */
    renterId: number;
    /**
     * Ссылка: идентификатор пользователя - сотрудника абонента, ответственного за проект
     * @type {number}
     * @memberof ProjectInfoAbstractCommonsAllOf
     */
    assigneeId?: number;
    /**
     * Выч по ссылке: ФИО ответственного пользователя
     * @type {PersonFullName}
     * @memberof ProjectInfoAbstractCommonsAllOf
     */
    assigneeFullName?: PersonFullName;
    /**
     * Маркер, указывающий, что проект имеет собственного ответственного, а не наследует его от арендатора
     * @type {boolean}
     * @memberof ProjectInfoAbstractCommonsAllOf
     */
    hasOwnAssignee?: boolean;
    /**
     * Полное официальное наименование
     * @type {string}
     * @memberof ProjectInfoAbstractCommonsAllOf
     */
    fullName?: string;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof ProjectInfoAbstractCommonsAllOf
     */
    shortName: string;
    /**
     * Применяемый процент скидки по умолчанию - по арендатору или нет
     * @type {number}
     * @memberof ProjectInfoAbstractCommonsAllOf
     */
    defaultDiscount?: number;
    /**
     * Маркер, указывающий, что проект имеет собственную скидку по умолчанию, а не наследует от арендатора
     * @type {boolean}
     * @memberof ProjectInfoAbstractCommonsAllOf
     */
    hasOwnDefaultDiscount?: boolean;
    /**
     * Применяемый процент налога - применяется к суммарной стоимости проекта
     * @type {number}
     * @memberof ProjectInfoAbstractCommonsAllOf
     */
    taxRate?: number;
    /**
     * 
     * @type {TaxBaseTypeCodeEnum}
     * @memberof ProjectInfoAbstractCommonsAllOf
     */
    taxBaseType?: TaxBaseTypeCodeEnum;
    /**
     * Дата начала работ по проекту (по договору)
     * @type {Date}
     * @memberof ProjectInfoAbstractCommonsAllOf
     */
    startDate?: Date;
    /**
     * Ожидаемая дата завершения проекта (по договору)
     * @type {Date}
     * @memberof ProjectInfoAbstractCommonsAllOf
     */
    targetFinishDate?: Date;
    /**
     * Идентификатор локации
     * @type {number}
     * @memberof ProjectInfoAbstractCommonsAllOf
     */
    locationId?: number;
    /**
     * Описание
     * @type {string}
     * @memberof ProjectInfoAbstractCommonsAllOf
     */
    description?: string;
    /**
     * 
     * @type {Array<ContactPersonAPIObj>}
     * @memberof ProjectInfoAbstractCommonsAllOf
     */
    contacts?: Array<ContactPersonAPIObj>;
    /**
     * Маркер, указывающий, что проект имеет собственные контакты
     * @type {boolean}
     * @memberof ProjectInfoAbstractCommonsAllOf
     */
    hasOwnContacts?: boolean;
    /**
     * 
     * @type {ProjectTypeCodeEnum}
     * @memberof ProjectInfoAbstractCommonsAllOf
     */
    projectType: ProjectTypeCodeEnum;
    /**
     * Маркер, указывающий, что проект является частью стоимости другого проекта. Используется только для КП (OFFER)
     * @type {boolean}
     * @memberof ProjectInfoAbstractCommonsAllOf
     */
    partOfThePrice?: boolean;
    /**
     * Ссылка: идентификатор основного проекта. Используется только для КП (OFFER)
     * @type {number}
     * @memberof ProjectInfoAbstractCommonsAllOf
     */
    mainProjectId?: number;
    /**
     * 
     * @type {TypeOfPricingCodeEnum}
     * @memberof ProjectInfoAbstractCommonsAllOf
     */
    typeOfPricing?: TypeOfPricingCodeEnum;
    /**
     * Число неотмененных КП
     * @type {number}
     * @memberof ProjectInfoAbstractCommonsAllOf
     */
    totalNotCanceledOffersCount?: number;
    /**
     * Число КП включенных в стоимость
     * @type {number}
     * @memberof ProjectInfoAbstractCommonsAllOf
     */
    totalPartOfPriceOffersCount?: number;
}
/**
 * Проект: абстрактное состояние при записи
 * @export
 * @interface ProjectInfoAbstractWriteCommons
 */
export interface ProjectInfoAbstractWriteCommons {
    /**
     * Идентификатор
     * @type {number}
     * @memberof ProjectInfoAbstractWriteCommons
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof ProjectInfoAbstractWriteCommons
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof ProjectInfoAbstractWriteCommons
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof ProjectInfoAbstractWriteCommons
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof ProjectInfoAbstractWriteCommons
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof ProjectInfoAbstractWriteCommons
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof ProjectInfoAbstractWriteCommons
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof ProjectInfoAbstractWriteCommons
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof ProjectInfoAbstractWriteCommons
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof ProjectInfoAbstractWriteCommons
     */
    businessAccountId?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectInfoAbstractWriteCommons
     */
    tags?: Array<string>;
    /**
     * Ссылка: идентификатор арендатора
     * @type {number}
     * @memberof ProjectInfoAbstractWriteCommons
     */
    renterId: number;
    /**
     * Ссылка: идентификатор пользователя - сотрудника абонента, ответственного за проект
     * @type {number}
     * @memberof ProjectInfoAbstractWriteCommons
     */
    assigneeId?: number;
    /**
     * Выч по ссылке: ФИО ответственного пользователя
     * @type {PersonFullName}
     * @memberof ProjectInfoAbstractWriteCommons
     */
    assigneeFullName?: PersonFullName;
    /**
     * Маркер, указывающий, что проект имеет собственного ответственного, а не наследует его от арендатора
     * @type {boolean}
     * @memberof ProjectInfoAbstractWriteCommons
     */
    hasOwnAssignee?: boolean;
    /**
     * Полное официальное наименование
     * @type {string}
     * @memberof ProjectInfoAbstractWriteCommons
     */
    fullName?: string;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof ProjectInfoAbstractWriteCommons
     */
    shortName: string;
    /**
     * Применяемый процент скидки по умолчанию - по арендатору или нет
     * @type {number}
     * @memberof ProjectInfoAbstractWriteCommons
     */
    defaultDiscount?: number;
    /**
     * Маркер, указывающий, что проект имеет собственную скидку по умолчанию, а не наследует от арендатора
     * @type {boolean}
     * @memberof ProjectInfoAbstractWriteCommons
     */
    hasOwnDefaultDiscount?: boolean;
    /**
     * Применяемый процент налога - применяется к суммарной стоимости проекта
     * @type {number}
     * @memberof ProjectInfoAbstractWriteCommons
     */
    taxRate?: number;
    /**
     * 
     * @type {TaxBaseTypeCodeEnum}
     * @memberof ProjectInfoAbstractWriteCommons
     */
    taxBaseType?: TaxBaseTypeCodeEnum;
    /**
     * Дата начала работ по проекту (по договору)
     * @type {Date}
     * @memberof ProjectInfoAbstractWriteCommons
     */
    startDate?: Date;
    /**
     * Ожидаемая дата завершения проекта (по договору)
     * @type {Date}
     * @memberof ProjectInfoAbstractWriteCommons
     */
    targetFinishDate?: Date;
    /**
     * Идентификатор локации
     * @type {number}
     * @memberof ProjectInfoAbstractWriteCommons
     */
    locationId?: number;
    /**
     * Описание
     * @type {string}
     * @memberof ProjectInfoAbstractWriteCommons
     */
    description?: string;
    /**
     * 
     * @type {Array<ContactPersonAPIObj>}
     * @memberof ProjectInfoAbstractWriteCommons
     */
    contacts?: Array<ContactPersonAPIObj>;
    /**
     * Маркер, указывающий, что проект имеет собственные контакты
     * @type {boolean}
     * @memberof ProjectInfoAbstractWriteCommons
     */
    hasOwnContacts?: boolean;
    /**
     * 
     * @type {ProjectTypeCodeEnum}
     * @memberof ProjectInfoAbstractWriteCommons
     */
    projectType: ProjectTypeCodeEnum;
    /**
     * Маркер, указывающий, что проект является частью стоимости другого проекта. Используется только для КП (OFFER)
     * @type {boolean}
     * @memberof ProjectInfoAbstractWriteCommons
     */
    partOfThePrice?: boolean;
    /**
     * Ссылка: идентификатор основного проекта. Используется только для КП (OFFER)
     * @type {number}
     * @memberof ProjectInfoAbstractWriteCommons
     */
    mainProjectId?: number;
    /**
     * 
     * @type {TypeOfPricingCodeEnum}
     * @memberof ProjectInfoAbstractWriteCommons
     */
    typeOfPricing?: TypeOfPricingCodeEnum;
    /**
     * Число неотмененных КП
     * @type {number}
     * @memberof ProjectInfoAbstractWriteCommons
     */
    totalNotCanceledOffersCount?: number;
    /**
     * Число КП включенных в стоимость
     * @type {number}
     * @memberof ProjectInfoAbstractWriteCommons
     */
    totalPartOfPriceOffersCount?: number;
    /**
     * 
     * @type {ContractObjWrite}
     * @memberof ProjectInfoAbstractWriteCommons
     */
    projectContract: ContractObjWrite;
    /**
     * Контакты проекта
     * @type {Array<ContactLinkObjWrite>}
     * @memberof ProjectInfoAbstractWriteCommons
     */
    contactLinks?: Array<ContactLinkObjWrite>;
}
/**
 * 
 * @export
 * @interface ProjectInfoAbstractWriteCommonsAllOf
 */
export interface ProjectInfoAbstractWriteCommonsAllOf {
    /**
     * 
     * @type {ContractObjWrite}
     * @memberof ProjectInfoAbstractWriteCommonsAllOf
     */
    projectContract: ContractObjWrite;
    /**
     * Контакты проекта
     * @type {Array<ContactLinkObjWrite>}
     * @memberof ProjectInfoAbstractWriteCommonsAllOf
     */
    contactLinks?: Array<ContactLinkObjWrite>;
}
/**
 * Проект: состояние при создании
 * @export
 * @interface ProjectInfoCreate
 */
export interface ProjectInfoCreate {
    /**
     * Идентификатор
     * @type {number}
     * @memberof ProjectInfoCreate
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof ProjectInfoCreate
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof ProjectInfoCreate
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof ProjectInfoCreate
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof ProjectInfoCreate
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof ProjectInfoCreate
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof ProjectInfoCreate
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof ProjectInfoCreate
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof ProjectInfoCreate
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof ProjectInfoCreate
     */
    businessAccountId?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectInfoCreate
     */
    tags?: Array<string>;
    /**
     * Ссылка: идентификатор арендатора
     * @type {number}
     * @memberof ProjectInfoCreate
     */
    renterId: number;
    /**
     * Ссылка: идентификатор пользователя - сотрудника абонента, ответственного за проект
     * @type {number}
     * @memberof ProjectInfoCreate
     */
    assigneeId?: number;
    /**
     * Выч по ссылке: ФИО ответственного пользователя
     * @type {PersonFullName}
     * @memberof ProjectInfoCreate
     */
    assigneeFullName?: PersonFullName;
    /**
     * Маркер, указывающий, что проект имеет собственного ответственного, а не наследует его от арендатора
     * @type {boolean}
     * @memberof ProjectInfoCreate
     */
    hasOwnAssignee?: boolean;
    /**
     * Полное официальное наименование
     * @type {string}
     * @memberof ProjectInfoCreate
     */
    fullName?: string;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof ProjectInfoCreate
     */
    shortName: string;
    /**
     * Применяемый процент скидки по умолчанию - по арендатору или нет
     * @type {number}
     * @memberof ProjectInfoCreate
     */
    defaultDiscount?: number;
    /**
     * Маркер, указывающий, что проект имеет собственную скидку по умолчанию, а не наследует от арендатора
     * @type {boolean}
     * @memberof ProjectInfoCreate
     */
    hasOwnDefaultDiscount?: boolean;
    /**
     * Применяемый процент налога - применяется к суммарной стоимости проекта
     * @type {number}
     * @memberof ProjectInfoCreate
     */
    taxRate?: number;
    /**
     * 
     * @type {TaxBaseTypeCodeEnum}
     * @memberof ProjectInfoCreate
     */
    taxBaseType?: TaxBaseTypeCodeEnum;
    /**
     * Дата начала работ по проекту (по договору)
     * @type {Date}
     * @memberof ProjectInfoCreate
     */
    startDate?: Date;
    /**
     * Ожидаемая дата завершения проекта (по договору)
     * @type {Date}
     * @memberof ProjectInfoCreate
     */
    targetFinishDate?: Date;
    /**
     * Идентификатор локации
     * @type {number}
     * @memberof ProjectInfoCreate
     */
    locationId?: number;
    /**
     * Описание
     * @type {string}
     * @memberof ProjectInfoCreate
     */
    description?: string;
    /**
     * 
     * @type {Array<ContactPersonAPIObj>}
     * @memberof ProjectInfoCreate
     */
    contacts?: Array<ContactPersonAPIObj>;
    /**
     * Маркер, указывающий, что проект имеет собственные контакты
     * @type {boolean}
     * @memberof ProjectInfoCreate
     */
    hasOwnContacts?: boolean;
    /**
     * 
     * @type {ProjectTypeCodeEnum}
     * @memberof ProjectInfoCreate
     */
    projectType: ProjectTypeCodeEnum;
    /**
     * Маркер, указывающий, что проект является частью стоимости другого проекта (используется только для OFFER)
     * @type {boolean}
     * @memberof ProjectInfoCreate
     */
    partOfThePrice?: boolean;
    /**
     * Ссылка: идентификатор основного проекта. Используется только для КП (OFFER)
     * @type {number}
     * @memberof ProjectInfoCreate
     */
    mainProjectId?: number;
    /**
     * 
     * @type {TypeOfPricingCodeEnum}
     * @memberof ProjectInfoCreate
     */
    typeOfPricing?: TypeOfPricingCodeEnum;
    /**
     * Число неотмененных КП
     * @type {number}
     * @memberof ProjectInfoCreate
     */
    totalNotCanceledOffersCount?: number;
    /**
     * Число КП включенных в стоимость
     * @type {number}
     * @memberof ProjectInfoCreate
     */
    totalPartOfPriceOffersCount?: number;
    /**
     * 
     * @type {ContractObjWrite}
     * @memberof ProjectInfoCreate
     */
    projectContract: ContractObjWrite;
    /**
     * Контакты проекта
     * @type {Array<ContactLinkObjWrite>}
     * @memberof ProjectInfoCreate
     */
    contactLinks?: Array<ContactLinkObjWrite>;
    /**
     * 
     * @type {ProjectStateCodeEnum}
     * @memberof ProjectInfoCreate
     */
    stateCode: ProjectStateCodeEnum;
    /**
     * Копировать неотмененные затраты
     * @type {boolean}
     * @memberof ProjectInfoCreate
     */
    copyExpenses: boolean;
    /**
     * Копировать неотмененные обязательства исходного проекта. Если статус целевого проекта Новый, все обязательства в нем будут созданы в статусе Черновик
     * @type {boolean}
     * @memberof ProjectInfoCreate
     */
    copyRentElements: boolean;
    /**
     * Копировать неотмененные работы
     * @type {boolean}
     * @memberof ProjectInfoCreate
     */
    copyWorkPlannings: boolean;
    /**
     * Признак сохранения работников при копировании работ во время создания на основе другого проекта
     * @type {boolean}
     * @memberof ProjectInfoCreate
     */
    saveCrewMembers: boolean;
    /**
     * Признак сохранения именованных экземпляров при копировании обязательств во время создания на основе другого проекта
     * @type {boolean}
     * @memberof ProjectInfoCreate
     */
    saveTrackedInstances: boolean;
    /**
     * Идентификатор проекта
     * @type {number}
     * @memberof ProjectInfoCreate
     */
    sourceProjectId?: number;
    /**
     * Идентификатор шаблона, на основании которого создается проект
     * @type {number}
     * @memberof ProjectInfoCreate
     */
    baseTemplateId?: number;
    /**
     * Маркер, указывающий, что проект является простым заказом
     * @type {boolean}
     * @memberof ProjectInfoCreate
     */
    isSimpleOrder: boolean;
}
/**
 * 
 * @export
 * @interface ProjectInfoCreateAllOf
 */
export interface ProjectInfoCreateAllOf {
    /**
     * 
     * @type {ProjectStateCodeEnum}
     * @memberof ProjectInfoCreateAllOf
     */
    stateCode: ProjectStateCodeEnum;
    /**
     * Копировать неотмененные затраты
     * @type {boolean}
     * @memberof ProjectInfoCreateAllOf
     */
    copyExpenses: boolean;
    /**
     * Копировать неотмененные обязательства исходного проекта. Если статус целевого проекта Новый, все обязательства в нем будут созданы в статусе Черновик
     * @type {boolean}
     * @memberof ProjectInfoCreateAllOf
     */
    copyRentElements: boolean;
    /**
     * Копировать неотмененные работы
     * @type {boolean}
     * @memberof ProjectInfoCreateAllOf
     */
    copyWorkPlannings: boolean;
    /**
     * Признак сохранения работников при копировании работ во время создания на основе другого проекта
     * @type {boolean}
     * @memberof ProjectInfoCreateAllOf
     */
    saveCrewMembers: boolean;
    /**
     * Признак сохранения именованных экземпляров при копировании обязательств во время создания на основе другого проекта
     * @type {boolean}
     * @memberof ProjectInfoCreateAllOf
     */
    saveTrackedInstances: boolean;
    /**
     * Идентификатор проекта
     * @type {number}
     * @memberof ProjectInfoCreateAllOf
     */
    sourceProjectId?: number;
    /**
     * Идентификатор шаблона, на основании которого создается проект
     * @type {number}
     * @memberof ProjectInfoCreateAllOf
     */
    baseTemplateId?: number;
    /**
     * Маркер, указывающий, что проект является частью стоимости другого проекта (используется только для OFFER)
     * @type {boolean}
     * @memberof ProjectInfoCreateAllOf
     */
    partOfThePrice?: boolean;
    /**
     * Маркер, указывающий, что проект является простым заказом
     * @type {boolean}
     * @memberof ProjectInfoCreateAllOf
     */
    isSimpleOrder: boolean;
}
/**
 * Проект: состояние чтения
 * @export
 * @interface ProjectInfoRead
 */
export interface ProjectInfoRead {
    /**
     * Идентификатор
     * @type {number}
     * @memberof ProjectInfoRead
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof ProjectInfoRead
     */
    businessVersion: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof ProjectInfoRead
     */
    creationAuthorId: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof ProjectInfoRead
     */
    creationAuthorFullName: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof ProjectInfoRead
     */
    creationDate: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof ProjectInfoRead
     */
    lastUpdateAuthorId: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof ProjectInfoRead
     */
    lastUpdateAuthorFullName: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof ProjectInfoRead
     */
    lastUpdateDate: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof ProjectInfoRead
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof ProjectInfoRead
     */
    businessAccountId: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectInfoRead
     */
    tags?: Array<string>;
    /**
     * Ссылка: идентификатор арендатора
     * @type {number}
     * @memberof ProjectInfoRead
     */
    renterId: number;
    /**
     * Ссылка: идентификатор пользователя - сотрудника абонента, ответственного за проект
     * @type {number}
     * @memberof ProjectInfoRead
     */
    assigneeId?: number;
    /**
     * Выч по ссылке: ФИО ответственного пользователя
     * @type {PersonFullName}
     * @memberof ProjectInfoRead
     */
    assigneeFullName?: PersonFullName;
    /**
     * Маркер, указывающий, что проект имеет собственного ответственного, а не наследует его от арендатора
     * @type {boolean}
     * @memberof ProjectInfoRead
     */
    hasOwnAssignee: boolean;
    /**
     * Полное официальное наименование
     * @type {string}
     * @memberof ProjectInfoRead
     */
    fullName?: string;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof ProjectInfoRead
     */
    shortName: string;
    /**
     * Применяемый процент скидки по умолчанию - по арендатору или нет
     * @type {number}
     * @memberof ProjectInfoRead
     */
    defaultDiscount: number;
    /**
     * Маркер, указывающий, что проект имеет собственную скидку по умолчанию, а не наследует от арендатора
     * @type {boolean}
     * @memberof ProjectInfoRead
     */
    hasOwnDefaultDiscount: boolean;
    /**
     * Применяемый процент налога - применяется к суммарной стоимости проекта
     * @type {number}
     * @memberof ProjectInfoRead
     */
    taxRate: number;
    /**
     * 
     * @type {TaxBaseTypeCodeEnum}
     * @memberof ProjectInfoRead
     */
    taxBaseType: TaxBaseTypeCodeEnum;
    /**
     * Дата начала работ по проекту (по договору)
     * @type {Date}
     * @memberof ProjectInfoRead
     */
    startDate?: Date;
    /**
     * Ожидаемая дата завершения проекта (по договору)
     * @type {Date}
     * @memberof ProjectInfoRead
     */
    targetFinishDate?: Date;
    /**
     * Идентификатор локации
     * @type {number}
     * @memberof ProjectInfoRead
     */
    locationId?: number;
    /**
     * Описание
     * @type {string}
     * @memberof ProjectInfoRead
     */
    description?: string;
    /**
     * 
     * @type {Array<ContactPersonAPIObj>}
     * @memberof ProjectInfoRead
     */
    contacts: Array<ContactPersonAPIObj>;
    /**
     * Маркер, указывающий, что проект имеет собственные контакты
     * @type {boolean}
     * @memberof ProjectInfoRead
     */
    hasOwnContacts: boolean;
    /**
     * 
     * @type {ProjectTypeCodeEnum}
     * @memberof ProjectInfoRead
     */
    projectType: ProjectTypeCodeEnum;
    /**
     * Маркер, указывающий, что проект является частью стоимости другого проекта. Используется только для КП (OFFER)
     * @type {boolean}
     * @memberof ProjectInfoRead
     */
    partOfThePrice?: boolean;
    /**
     * Ссылка: идентификатор основного проекта. Используется только для КП (OFFER)
     * @type {number}
     * @memberof ProjectInfoRead
     */
    mainProjectId?: number;
    /**
     * 
     * @type {TypeOfPricingCodeEnum}
     * @memberof ProjectInfoRead
     */
    typeOfPricing?: TypeOfPricingCodeEnum;
    /**
     * Число неотмененных КП
     * @type {number}
     * @memberof ProjectInfoRead
     */
    totalNotCanceledOffersCount?: number;
    /**
     * Число КП включенных в стоимость
     * @type {number}
     * @memberof ProjectInfoRead
     */
    totalPartOfPriceOffersCount?: number;
    /**
     * 
     * @type {AttachmentRecordList}
     * @memberof ProjectInfoRead
     */
    attachments?: AttachmentRecordList;
    /**
     * 
     * @type {ProjectSourceCodeEnum}
     * @memberof ProjectInfoRead
     */
    sourceCode: ProjectSourceCodeEnum;
    /**
     * 
     * @type {RentIndicatorsObj}
     * @memberof ProjectInfoRead
     */
    rentIndicators: RentIndicatorsObj;
    /**
     * 
     * @type {ProjectIndicatorsObj}
     * @memberof ProjectInfoRead
     */
    projectIndicators?: ProjectIndicatorsObj;
    /**
     * 
     * @type {ProjectStateCodeEnum}
     * @memberof ProjectInfoRead
     */
    stateCode: ProjectStateCodeEnum;
    /**
     * Коды доступных переходов для данного проекта и пользователя
     * @type {Array<ProjectTransitionCodeEnum>}
     * @memberof ProjectInfoRead
     */
    availableTransitionCodes?: Array<ProjectTransitionCodeEnum>;
    /**
     * Маркер нахождения в архиве
     * @type {boolean}
     * @memberof ProjectInfoRead
     */
    archive: boolean;
    /**
     * Может ли быть отправлен в архив (может, когда не является активным)
     * @type {boolean}
     * @memberof ProjectInfoRead
     */
    archivable: boolean;
    /**
     * Короткое наименование арендатора
     * @type {string}
     * @memberof ProjectInfoRead
     */
    renterShortName: string;
    /**
     * 
     * @type {RenterStateCodeEnum}
     * @memberof ProjectInfoRead
     */
    renterStateCode: RenterStateCodeEnum;
    /**
     * Фактическая дата завершения проекта (когда проект был переведен в статус завершен)
     * @type {Date}
     * @memberof ProjectInfoRead
     */
    realFinishDate?: Date;
    /**
     * Наименование локации
     * @type {string}
     * @memberof ProjectInfoRead
     */
    locationName?: string;
    /**
     * Сумма в коп. до налогов стоимости всех обязательств в статусах аренды, завершенных, а также, в зависимости от настроек аккаунта компании, бронирования
     * @type {number}
     * @memberof ProjectInfoRead
     */
    costOfRent: number;
    /**
     * Сумма в коп. до налогов стоимости всех работ
     * @type {number}
     * @memberof ProjectInfoRead
     */
    costOfWork: number;
    /**
     * Сумма в коп. до налогов внешней стоимости всех затрат
     * @type {number}
     * @memberof ProjectInfoRead
     */
    costOfExpenses: number;
    /**
     * Актуальная стоимость до налогов в коп.
     * @type {number}
     * @memberof ProjectInfoRead
     */
    actualSumBeforeTaxes: number;
    /**
     * Актуальная стоимость после налогов в коп.
     * @type {number}
     * @memberof ProjectInfoRead
     */
    actualSumAfterTaxes: number;
    /**
     * Стоимость кп до налогов в коп.
     * @type {number}
     * @memberof ProjectInfoRead
     */
    offersSumBeforeTaxes?: number;
    /**
     * Стоимость кп до после налогов в коп.
     * @type {number}
     * @memberof ProjectInfoRead
     */
    offersSumAfterTaxes?: number;
    /**
     * Сумма долга арендатора по проекту в коп.
     * @type {number}
     * @memberof ProjectInfoRead
     */
    debtSum: number;
    /**
     * Число платежей по проекту от арендатора
     * @type {number}
     * @memberof ProjectInfoRead
     */
    acceptedPaymentsCount: number;
    /**
     * Сумма подтвержденных полученых платежей по проекту от арендатора в коп.
     * @type {number}
     * @memberof ProjectInfoRead
     */
    acceptedPaymentsSum: number;
    /**
     * Процент скидки по умолчанию у арендатора
     * @type {number}
     * @memberof ProjectInfoRead
     */
    renterDefaultDiscount: number;
    /**
     * 
     * @type {Array<ContactPersonAPIObj>}
     * @memberof ProjectInfoRead
     */
    renterContacts: Array<ContactPersonAPIObj>;
    /**
     * 
     * @type {Array<ContactLinkObjRead>}
     * @memberof ProjectInfoRead
     */
    renterContactLinks?: Array<ContactLinkObjRead>;
    /**
     * Ссылка: идентификатор пользователя - сотрудника абонента, ответственного за арендатора проекта, важно когда отдельного ответственного на сам проект нет
     * @type {number}
     * @memberof ProjectInfoRead
     */
    renterAssigneeId?: number;
    /**
     * Выч по ссылке: ФИО ответственного пользователя арендатора данного проекта, важно когда отдельного ответственного на сам проект нет
     * @type {PersonFullName}
     * @memberof ProjectInfoRead
     */
    renterAssigneeFullName?: PersonFullName;
    /**
     * Сумма внутренних расходов по проекту в коп.
     * @type {number}
     * @memberof ProjectInfoRead
     */
    totalInternalExpensesSum: number;
    /**
     * Ссылка: короткое наименование основного проекта. Используется только для КП (OFFER)
     * @type {string}
     * @memberof ProjectInfoRead
     */
    mainProjectShortName?: string;
    /**
     * Маркер, указывающий, что проект является простым заказом
     * @type {boolean}
     * @memberof ProjectInfoRead
     */
    isSimpleOrder: boolean;
    /**
     * 
     * @type {ContractObjRead}
     * @memberof ProjectInfoRead
     */
    projectContract?: ContractObjRead;
    /**
     * Контакты проекта
     * @type {Array<ContactLinkObjRead>}
     * @memberof ProjectInfoRead
     */
    contactLinks?: Array<ContactLinkObjRead>;
}
/**
 * 
 * @export
 * @interface ProjectInfoReadAllOf
 */
export interface ProjectInfoReadAllOf {
    /**
     * 
     * @type {AttachmentRecordList}
     * @memberof ProjectInfoReadAllOf
     */
    attachments?: AttachmentRecordList;
    /**
     * 
     * @type {ProjectSourceCodeEnum}
     * @memberof ProjectInfoReadAllOf
     */
    sourceCode: ProjectSourceCodeEnum;
    /**
     * 
     * @type {RentIndicatorsObj}
     * @memberof ProjectInfoReadAllOf
     */
    rentIndicators: RentIndicatorsObj;
    /**
     * 
     * @type {ProjectIndicatorsObj}
     * @memberof ProjectInfoReadAllOf
     */
    projectIndicators?: ProjectIndicatorsObj;
    /**
     * 
     * @type {ProjectStateCodeEnum}
     * @memberof ProjectInfoReadAllOf
     */
    stateCode: ProjectStateCodeEnum;
    /**
     * Коды доступных переходов для данного проекта и пользователя
     * @type {Array<ProjectTransitionCodeEnum>}
     * @memberof ProjectInfoReadAllOf
     */
    availableTransitionCodes?: Array<ProjectTransitionCodeEnum>;
    /**
     * Маркер нахождения в архиве
     * @type {boolean}
     * @memberof ProjectInfoReadAllOf
     */
    archive: boolean;
    /**
     * Может ли быть отправлен в архив (может, когда не является активным)
     * @type {boolean}
     * @memberof ProjectInfoReadAllOf
     */
    archivable: boolean;
    /**
     * Короткое наименование арендатора
     * @type {string}
     * @memberof ProjectInfoReadAllOf
     */
    renterShortName: string;
    /**
     * 
     * @type {RenterStateCodeEnum}
     * @memberof ProjectInfoReadAllOf
     */
    renterStateCode: RenterStateCodeEnum;
    /**
     * Фактическая дата завершения проекта (когда проект был переведен в статус завершен)
     * @type {Date}
     * @memberof ProjectInfoReadAllOf
     */
    realFinishDate?: Date;
    /**
     * Наименование локации
     * @type {string}
     * @memberof ProjectInfoReadAllOf
     */
    locationName?: string;
    /**
     * Сумма в коп. до налогов стоимости всех обязательств в статусах аренды, завершенных, а также, в зависимости от настроек аккаунта компании, бронирования
     * @type {number}
     * @memberof ProjectInfoReadAllOf
     */
    costOfRent: number;
    /**
     * Сумма в коп. до налогов стоимости всех работ
     * @type {number}
     * @memberof ProjectInfoReadAllOf
     */
    costOfWork: number;
    /**
     * Сумма в коп. до налогов внешней стоимости всех затрат
     * @type {number}
     * @memberof ProjectInfoReadAllOf
     */
    costOfExpenses: number;
    /**
     * Актуальная стоимость до налогов в коп.
     * @type {number}
     * @memberof ProjectInfoReadAllOf
     */
    actualSumBeforeTaxes: number;
    /**
     * Актуальная стоимость после налогов в коп.
     * @type {number}
     * @memberof ProjectInfoReadAllOf
     */
    actualSumAfterTaxes: number;
    /**
     * Стоимость кп до налогов в коп.
     * @type {number}
     * @memberof ProjectInfoReadAllOf
     */
    offersSumBeforeTaxes?: number;
    /**
     * Стоимость кп до после налогов в коп.
     * @type {number}
     * @memberof ProjectInfoReadAllOf
     */
    offersSumAfterTaxes?: number;
    /**
     * Сумма долга арендатора по проекту в коп.
     * @type {number}
     * @memberof ProjectInfoReadAllOf
     */
    debtSum: number;
    /**
     * Число платежей по проекту от арендатора
     * @type {number}
     * @memberof ProjectInfoReadAllOf
     */
    acceptedPaymentsCount: number;
    /**
     * Сумма подтвержденных полученых платежей по проекту от арендатора в коп.
     * @type {number}
     * @memberof ProjectInfoReadAllOf
     */
    acceptedPaymentsSum: number;
    /**
     * Процент скидки по умолчанию у арендатора
     * @type {number}
     * @memberof ProjectInfoReadAllOf
     */
    renterDefaultDiscount: number;
    /**
     * 
     * @type {Array<ContactPersonAPIObj>}
     * @memberof ProjectInfoReadAllOf
     */
    renterContacts: Array<ContactPersonAPIObj>;
    /**
     * 
     * @type {Array<ContactLinkObjRead>}
     * @memberof ProjectInfoReadAllOf
     */
    renterContactLinks?: Array<ContactLinkObjRead>;
    /**
     * Ссылка: идентификатор пользователя - сотрудника абонента, ответственного за арендатора проекта, важно когда отдельного ответственного на сам проект нет
     * @type {number}
     * @memberof ProjectInfoReadAllOf
     */
    renterAssigneeId?: number;
    /**
     * Выч по ссылке: ФИО ответственного пользователя арендатора данного проекта, важно когда отдельного ответственного на сам проект нет
     * @type {PersonFullName}
     * @memberof ProjectInfoReadAllOf
     */
    renterAssigneeFullName?: PersonFullName;
    /**
     * Сумма внутренних расходов по проекту в коп.
     * @type {number}
     * @memberof ProjectInfoReadAllOf
     */
    totalInternalExpensesSum: number;
    /**
     * Ссылка: короткое наименование основного проекта. Используется только для КП (OFFER)
     * @type {string}
     * @memberof ProjectInfoReadAllOf
     */
    mainProjectShortName?: string;
    /**
     * Маркер, указывающий, что проект является простым заказом
     * @type {boolean}
     * @memberof ProjectInfoReadAllOf
     */
    isSimpleOrder: boolean;
    /**
     * 
     * @type {ContractObjRead}
     * @memberof ProjectInfoReadAllOf
     */
    projectContract?: ContractObjRead;
    /**
     * Контакты проекта
     * @type {Array<ContactLinkObjRead>}
     * @memberof ProjectInfoReadAllOf
     */
    contactLinks?: Array<ContactLinkObjRead>;
}
/**
 * Проект: состояние при изменении
 * @export
 * @interface ProjectInfoUpdate
 */
export interface ProjectInfoUpdate extends ProjectInfoAbstractWriteCommons {
}
/**
 * Списочное представление проекта
 * @export
 * @interface ProjectRecord
 */
export interface ProjectRecord {
    /**
     * Идентификатор
     * @type {number}
     * @memberof ProjectRecord
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof ProjectRecord
     */
    businessVersion: number;
    /**
     * Ссылка: Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof ProjectRecord
     */
    businessAccountId: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectRecord
     */
    tags?: Array<string>;
    /**
     * Общая строка тегов, объединенных через запятую
     * @type {string}
     * @memberof ProjectRecord
     */
    tagsString?: string;
    /**
     * 
     * @type {ProjectSourceCodeEnum}
     * @memberof ProjectRecord
     */
    sourceCode: ProjectSourceCodeEnum;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof ProjectRecord
     */
    shortName: string;
    /**
     * 
     * @type {ProjectStateCodeEnum}
     * @memberof ProjectRecord
     */
    stateCode: ProjectStateCodeEnum;
    /**
     * Коды доступных переходов для данного проекта и пользователя
     * @type {Array<ProjectTransitionCodeEnum>}
     * @memberof ProjectRecord
     */
    availableTransitionCodes?: Array<ProjectTransitionCodeEnum>;
    /**
     * Находится ли в архиве
     * @type {boolean}
     * @memberof ProjectRecord
     */
    archive: boolean;
    /**
     * Может ли быть отправлен в архив (может, когда не является активным)
     * @type {boolean}
     * @memberof ProjectRecord
     */
    archivable: boolean;
    /**
     * Ссылка: идентификатор арендатора
     * @type {number}
     * @memberof ProjectRecord
     */
    renterId: number;
    /**
     * Выч. по ссылке: Короткое наименование арендатора
     * @type {string}
     * @memberof ProjectRecord
     */
    renterShortName: string;
    /**
     * Процент скидки по умолчанию (из проекта если есть, или наследованный от арендатора)
     * @type {number}
     * @memberof ProjectRecord
     */
    defaultDiscount: number;
    /**
     * Маркер, указывающий, что проект имеет собственную скидку по умолчанию, а не наследует от арендатора
     * @type {boolean}
     * @memberof ProjectRecord
     */
    hasOwnDefaultDiscount: boolean;
    /**
     * Дата начала работ по проекту (по договору)
     * @type {Date}
     * @memberof ProjectRecord
     */
    startDate?: Date;
    /**
     * Ожидаемая дата завершения проекта
     * @type {Date}
     * @memberof ProjectRecord
     */
    targetFinishDate?: Date;
    /**
     * Дата завершения проекта (когда проект был переведен в статус завершен)
     * @type {Date}
     * @memberof ProjectRecord
     */
    realFinishDate?: Date;
    /**
     * Наименование локации
     * @type {string}
     * @memberof ProjectRecord
     */
    locationName?: string;
    /**
     * Идентификатор локации
     * @type {number}
     * @memberof ProjectRecord
     */
    locationId?: number;
    /**
     * Ссылка: идентификатор пользователя - сотрудника абонента, ответственного за проект
     * @type {number}
     * @memberof ProjectRecord
     */
    assigneeId?: number;
    /**
     * Выч. по ссылке: короткое ФИО ответственного
     * @type {string}
     * @memberof ProjectRecord
     */
    assigneeShortName?: string;
    /**
     * Маркер, указывающий, что проект имеет собственного ответственного, а не наследует его от арендатора
     * @type {boolean}
     * @memberof ProjectRecord
     */
    hasOwnAssignee: boolean;
    /**
     * Выч. по ссылке: короткое ФИО контактного лица проекта (если на проекте КЛ по арендатору, то КЛ арендатора)
     * @type {string}
     * @memberof ProjectRecord
     */
    contactPersonShortName?: string;
    /**
     * Маркер, указывающий, что проект имеет собственное контактное лицо, а не наследует его от арендатора
     * @type {boolean}
     * @memberof ProjectRecord
     */
    hasOwnContactPerson?: boolean;
    /**
     * 
     * @type {ProblemAndWarningRentIndicatorsRecordObj}
     * @memberof ProjectRecord
     */
    problemsAndWarnings: ProblemAndWarningRentIndicatorsRecordObj;
    /**
     * Стоимость всех обязательств в статусе \'В заявке\' в коп.
     * @type {number}
     * @memberof ProjectRecord
     */
    currentOrderedSum: number;
    /**
     * Сумма всех обязательств в статусе бронирование в коп.
     * @type {number}
     * @memberof ProjectRecord
     */
    currentBookedSum: number;
    /**
     * Сумма всех обязательств в статусе аренда в коп.
     * @type {number}
     * @memberof ProjectRecord
     */
    currentRentSum: number;
    /**
     * Сумма в коп. до налогов стоимости всех обязательств в статусах аренды, завершенных, а также, в зависимости от настроек аккаунта компании, бронирования
     * @type {number}
     * @memberof ProjectRecord
     */
    costOfRent: number;
    /**
     * Сумма в коп. до налогов стоимости всех работ
     * @type {number}
     * @memberof ProjectRecord
     */
    costOfWork: number;
    /**
     * Сумма в коп. до налогов внешней стоимости всех затрат
     * @type {number}
     * @memberof ProjectRecord
     */
    costOfExpenses: number;
    /**
     * Стоимость к оплате после налогов в коп.
     * @type {number}
     * @memberof ProjectRecord
     */
    sumToBePaidAfterTaxes: number;
    /**
     * Сумма долга арендатора по проекту в коп.
     * @type {number}
     * @memberof ProjectRecord
     */
    debtSum: number;
    /**
     * Дата и время последней активности (измение сущности либо операция над связанными обязательствами)
     * @type {Date}
     * @memberof ProjectRecord
     */
    lastActivityDate: Date;
    /**
     * Сумма внутренних расходов в коп.
     * @type {number}
     * @memberof ProjectRecord
     */
    totalInternalExpensesSum: number;
    /**
     * Сумма внешних расходов в коп.
     * @type {number}
     * @memberof ProjectRecord
     */
    totalExternalExpensesSum: number;
    /**
     * 
     * @type {ProjectTypeCodeEnum}
     * @memberof ProjectRecord
     */
    projectType: ProjectTypeCodeEnum;
    /**
     * Маркер, указывающий, что проект является частью стоимости другого проекта. Используется только для КП (OFFER)
     * @type {boolean}
     * @memberof ProjectRecord
     */
    partOfThePrice?: boolean;
    /**
     * 
     * @type {TypeOfPricingCodeEnum}
     * @memberof ProjectRecord
     */
    typeOfPricing?: TypeOfPricingCodeEnum;
    /**
     * Ссылка: идентификатор основного проекта. Используется только для КП (OFFER)
     * @type {number}
     * @memberof ProjectRecord
     */
    mainProjectId?: number;
    /**
     * Ссылка: короткое наименование основного проекта. Используется только для КП (OFFER)
     * @type {string}
     * @memberof ProjectRecord
     */
    mainProjectShortName?: string;
    /**
     * Маркер, указывающий, что проект является простым заказом
     * @type {boolean}
     * @memberof ProjectRecord
     */
    isSimpleOrder: boolean;
}
/**
 * 
 * @export
 * @interface ProjectRecordAllOf
 */
export interface ProjectRecordAllOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectRecordAllOf
     */
    tags?: Array<string>;
    /**
     * Общая строка тегов, объединенных через запятую
     * @type {string}
     * @memberof ProjectRecordAllOf
     */
    tagsString?: string;
    /**
     * 
     * @type {ProjectSourceCodeEnum}
     * @memberof ProjectRecordAllOf
     */
    sourceCode: ProjectSourceCodeEnum;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof ProjectRecordAllOf
     */
    shortName: string;
    /**
     * 
     * @type {ProjectStateCodeEnum}
     * @memberof ProjectRecordAllOf
     */
    stateCode: ProjectStateCodeEnum;
    /**
     * Коды доступных переходов для данного проекта и пользователя
     * @type {Array<ProjectTransitionCodeEnum>}
     * @memberof ProjectRecordAllOf
     */
    availableTransitionCodes?: Array<ProjectTransitionCodeEnum>;
    /**
     * Находится ли в архиве
     * @type {boolean}
     * @memberof ProjectRecordAllOf
     */
    archive: boolean;
    /**
     * Может ли быть отправлен в архив (может, когда не является активным)
     * @type {boolean}
     * @memberof ProjectRecordAllOf
     */
    archivable: boolean;
    /**
     * Ссылка: идентификатор арендатора
     * @type {number}
     * @memberof ProjectRecordAllOf
     */
    renterId: number;
    /**
     * Выч. по ссылке: Короткое наименование арендатора
     * @type {string}
     * @memberof ProjectRecordAllOf
     */
    renterShortName: string;
    /**
     * Процент скидки по умолчанию (из проекта если есть, или наследованный от арендатора)
     * @type {number}
     * @memberof ProjectRecordAllOf
     */
    defaultDiscount: number;
    /**
     * Маркер, указывающий, что проект имеет собственную скидку по умолчанию, а не наследует от арендатора
     * @type {boolean}
     * @memberof ProjectRecordAllOf
     */
    hasOwnDefaultDiscount: boolean;
    /**
     * Дата начала работ по проекту (по договору)
     * @type {Date}
     * @memberof ProjectRecordAllOf
     */
    startDate?: Date;
    /**
     * Ожидаемая дата завершения проекта
     * @type {Date}
     * @memberof ProjectRecordAllOf
     */
    targetFinishDate?: Date;
    /**
     * Дата завершения проекта (когда проект был переведен в статус завершен)
     * @type {Date}
     * @memberof ProjectRecordAllOf
     */
    realFinishDate?: Date;
    /**
     * Наименование локации
     * @type {string}
     * @memberof ProjectRecordAllOf
     */
    locationName?: string;
    /**
     * Идентификатор локации
     * @type {number}
     * @memberof ProjectRecordAllOf
     */
    locationId?: number;
    /**
     * Ссылка: идентификатор пользователя - сотрудника абонента, ответственного за проект
     * @type {number}
     * @memberof ProjectRecordAllOf
     */
    assigneeId?: number;
    /**
     * Выч. по ссылке: короткое ФИО ответственного
     * @type {string}
     * @memberof ProjectRecordAllOf
     */
    assigneeShortName?: string;
    /**
     * Маркер, указывающий, что проект имеет собственного ответственного, а не наследует его от арендатора
     * @type {boolean}
     * @memberof ProjectRecordAllOf
     */
    hasOwnAssignee: boolean;
    /**
     * Выч. по ссылке: короткое ФИО контактного лица проекта (если на проекте КЛ по арендатору, то КЛ арендатора)
     * @type {string}
     * @memberof ProjectRecordAllOf
     */
    contactPersonShortName?: string;
    /**
     * Маркер, указывающий, что проект имеет собственное контактное лицо, а не наследует его от арендатора
     * @type {boolean}
     * @memberof ProjectRecordAllOf
     */
    hasOwnContactPerson?: boolean;
    /**
     * 
     * @type {ProblemAndWarningRentIndicatorsRecordObj}
     * @memberof ProjectRecordAllOf
     */
    problemsAndWarnings: ProblemAndWarningRentIndicatorsRecordObj;
    /**
     * Стоимость всех обязательств в статусе \'В заявке\' в коп.
     * @type {number}
     * @memberof ProjectRecordAllOf
     */
    currentOrderedSum: number;
    /**
     * Сумма всех обязательств в статусе бронирование в коп.
     * @type {number}
     * @memberof ProjectRecordAllOf
     */
    currentBookedSum: number;
    /**
     * Сумма всех обязательств в статусе аренда в коп.
     * @type {number}
     * @memberof ProjectRecordAllOf
     */
    currentRentSum: number;
    /**
     * Сумма в коп. до налогов стоимости всех обязательств в статусах аренды, завершенных, а также, в зависимости от настроек аккаунта компании, бронирования
     * @type {number}
     * @memberof ProjectRecordAllOf
     */
    costOfRent: number;
    /**
     * Сумма в коп. до налогов стоимости всех работ
     * @type {number}
     * @memberof ProjectRecordAllOf
     */
    costOfWork: number;
    /**
     * Сумма в коп. до налогов внешней стоимости всех затрат
     * @type {number}
     * @memberof ProjectRecordAllOf
     */
    costOfExpenses: number;
    /**
     * Стоимость к оплате после налогов в коп.
     * @type {number}
     * @memberof ProjectRecordAllOf
     */
    sumToBePaidAfterTaxes: number;
    /**
     * Сумма долга арендатора по проекту в коп.
     * @type {number}
     * @memberof ProjectRecordAllOf
     */
    debtSum: number;
    /**
     * Дата и время последней активности (измение сущности либо операция над связанными обязательствами)
     * @type {Date}
     * @memberof ProjectRecordAllOf
     */
    lastActivityDate: Date;
    /**
     * Сумма внутренних расходов в коп.
     * @type {number}
     * @memberof ProjectRecordAllOf
     */
    totalInternalExpensesSum: number;
    /**
     * Сумма внешних расходов в коп.
     * @type {number}
     * @memberof ProjectRecordAllOf
     */
    totalExternalExpensesSum: number;
    /**
     * 
     * @type {ProjectTypeCodeEnum}
     * @memberof ProjectRecordAllOf
     */
    projectType: ProjectTypeCodeEnum;
    /**
     * Маркер, указывающий, что проект является частью стоимости другого проекта. Используется только для КП (OFFER)
     * @type {boolean}
     * @memberof ProjectRecordAllOf
     */
    partOfThePrice?: boolean;
    /**
     * 
     * @type {TypeOfPricingCodeEnum}
     * @memberof ProjectRecordAllOf
     */
    typeOfPricing?: TypeOfPricingCodeEnum;
    /**
     * Ссылка: идентификатор основного проекта. Используется только для КП (OFFER)
     * @type {number}
     * @memberof ProjectRecordAllOf
     */
    mainProjectId?: number;
    /**
     * Ссылка: короткое наименование основного проекта. Используется только для КП (OFFER)
     * @type {string}
     * @memberof ProjectRecordAllOf
     */
    mainProjectShortName?: string;
    /**
     * Маркер, указывающий, что проект является простым заказом
     * @type {boolean}
     * @memberof ProjectRecordAllOf
     */
    isSimpleOrder: boolean;
}
/**
 * Список записей проектов
 * @export
 * @interface ProjectRecordList
 */
export interface ProjectRecordList {
    /**
     * 
     * @type {ListAttributesInfo}
     * @memberof ProjectRecordList
     */
    listAttributes: ListAttributesInfo;
    /**
     * 
     * @type {Array<ProjectRecord>}
     * @memberof ProjectRecordList
     */
    records: Array<ProjectRecord>;
}
/**
 * 
 * @export
 * @interface ProjectRecordListAllOf
 */
export interface ProjectRecordListAllOf {
    /**
     * 
     * @type {Array<ProjectRecord>}
     * @memberof ProjectRecordListAllOf
     */
    records: Array<ProjectRecord>;
}
/**
 * Код источника проекта
 * @export
 * @enum {string}
 */
export enum ProjectSourceCodeEnum {
    INTERNAL = 'INTERNAL',
    STOREFRONT = 'STOREFRONT'
}

/**
 * Код статуса проекта
 * @export
 * @enum {string}
 */
export enum ProjectStateCodeEnum {
    DRAFT = 'DRAFT',
    INPROGRESS = 'IN_PROGRESS',
    APPROVED = 'APPROVED',
    FINISHED = 'FINISHED',
    CANCELED = 'CANCELED',
    BOOKED = 'BOOKED',
    RENTED = 'RENTED',
    COORDINATION = 'COORDINATION'
}

/**
 * Код перехода между статусами проекта
 * @export
 * @enum {string}
 */
export enum ProjectTransitionCodeEnum {
    ACTIVATE = 'ACTIVATE',
    FINISH = 'FINISH',
    CANCEL = 'CANCEL',
    APPROVE = 'APPROVE',
    RETURNTODRAFT = 'RETURN_TO_DRAFT',
    BOOK = 'BOOK',
    RENT = 'RENT',
    TOCOORDINATION = 'TO_COORDINATION'
}

/**
 * Код типа проекта
 * @export
 * @enum {string}
 */
export enum ProjectTypeCodeEnum {
    BASE = 'BASE',
    OFFER = 'OFFER'
}

/**
 * Информация о компании
 * @export
 * @interface PublicBusinessAccountInfo
 */
export interface PublicBusinessAccountInfo {
    /**
     * Активный статус
     * @type {boolean}
     * @memberof PublicBusinessAccountInfo
     */
    active: boolean;
}
/**
 * Платеж: состояние при создании
 * @export
 * @interface PublicPaymentInfoCreate
 */
export interface PublicPaymentInfoCreate {
    /**
     * Дополнительная информация об оплате
     * @type {string}
     * @memberof PublicPaymentInfoCreate
     */
    acquiringInfo?: string;
    /**
     * Внешний идентификатор транзакции
     * @type {string}
     * @memberof PublicPaymentInfoCreate
     */
    externalTransactionId: string;
    /**
     * Сумма платежа, всегда неотрицательная (направление - всегда входящий)
     * @type {number}
     * @memberof PublicPaymentInfoCreate
     */
    amount: number;
    /**
     * Идентификатор заказа
     * @type {number}
     * @memberof PublicPaymentInfoCreate
     */
    orderId: number;
}
/**
 * 
 * @export
 * @interface PublicPaymentRecord
 */
export interface PublicPaymentRecord {
    /**
     * Сумма платежа, всегда неотрицательная
     * @type {number}
     * @memberof PublicPaymentRecord
     */
    amount: number;
    /**
     * Направление платежа. Флаг отмечает, является платеж входящим для арендодателя (от клиента к арендодателю)
     * @type {boolean}
     * @memberof PublicPaymentRecord
     */
    incoming: boolean;
    /**
     * Дата платежа
     * @type {Date}
     * @memberof PublicPaymentRecord
     */
    date: Date;
}
/**
 * Списочное представление рамки арендной активности
 * @export
 * @interface RentActivityFrameRecord
 */
export interface RentActivityFrameRecord {
    /**
     * Идентификатор
     * @type {number}
     * @memberof RentActivityFrameRecord
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof RentActivityFrameRecord
     */
    businessVersion: number;
    /**
     * Ссылка: Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof RentActivityFrameRecord
     */
    businessAccountId: number;
    /**
     * 
     * @type {RentActivityFrameTypeCodeEnum}
     * @memberof RentActivityFrameRecord
     */
    rentActivityFrameTypeCode?: RentActivityFrameTypeCodeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof RentActivityFrameRecord
     */
    tags?: Array<string>;
    /**
     * Общая строка тегов, объединенных через запятую
     * @type {string}
     * @memberof RentActivityFrameRecord
     */
    tagsString?: string;
    /**
     * 
     * @type {ProjectSourceCodeEnum}
     * @memberof RentActivityFrameRecord
     */
    sourceCode: ProjectSourceCodeEnum;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof RentActivityFrameRecord
     */
    shortName: string;
    /**
     * 
     * @type {RentActivityFrameStateCodeEnum}
     * @memberof RentActivityFrameRecord
     */
    stateCode: RentActivityFrameStateCodeEnum;
    /**
     * Коды доступных переходов для данного проекта и пользователя
     * @type {Array<RentActivityFrameTransitionCodeEnum>}
     * @memberof RentActivityFrameRecord
     */
    availableTransitionCodes?: Array<RentActivityFrameTransitionCodeEnum>;
    /**
     * Находится ли в архиве
     * @type {boolean}
     * @memberof RentActivityFrameRecord
     */
    archive: boolean;
    /**
     * Может ли быть отправлен в архив (может, когда не является активным)
     * @type {boolean}
     * @memberof RentActivityFrameRecord
     */
    archivable: boolean;
    /**
     * Ссылка: идентификатор контрагента
     * @type {number}
     * @memberof RentActivityFrameRecord
     */
    counterpartyId: number;
    /**
     * Выч. по ссылке: Короткое наименование контрагента
     * @type {string}
     * @memberof RentActivityFrameRecord
     */
    counterpartyShortName: string;
    /**
     * Дата начала работ
     * @type {Date}
     * @memberof RentActivityFrameRecord
     */
    startDate?: Date;
    /**
     * Дата завершения работ
     * @type {Date}
     * @memberof RentActivityFrameRecord
     */
    finishDate?: Date;
    /**
     * Наименование локации
     * @type {string}
     * @memberof RentActivityFrameRecord
     */
    locationName?: string;
    /**
     * Идентификатор локации
     * @type {number}
     * @memberof RentActivityFrameRecord
     */
    locationId?: number;
    /**
     * Ссылка: идентификатор пользователя - сотрудника абонента, ответственного за проект
     * @type {number}
     * @memberof RentActivityFrameRecord
     */
    assigneeId?: number;
    /**
     * Выч. по ссылке: короткое ФИО ответственного
     * @type {string}
     * @memberof RentActivityFrameRecord
     */
    assigneeShortName?: string;
    /**
     * Маркер, указывающий, что проект имеет собственного ответственного, а не наследует его от арендатора
     * @type {boolean}
     * @memberof RentActivityFrameRecord
     */
    hasOwnAssignee: boolean;
    /**
     * 
     * @type {ProblemAndWarningRentIndicatorsRecordObj}
     * @memberof RentActivityFrameRecord
     */
    problemsAndWarnings: ProblemAndWarningRentIndicatorsRecordObj;
    /**
     * Сумма после налогов, если налоги указаны, в коп.
     * @type {number}
     * @memberof RentActivityFrameRecord
     */
    sumAfterTaxesIfTaxExists?: number;
    /**
     * Сумма долга арендатора по проекту в коп.
     * @type {number}
     * @memberof RentActivityFrameRecord
     */
    debtSum: number;
    /**
     * Дата и время последней активности (измение сущности либо операция над связанными обязательствами)
     * @type {Date}
     * @memberof RentActivityFrameRecord
     */
    lastActivityDate: Date;
}
/**
 * 
 * @export
 * @interface RentActivityFrameRecordAllOf
 */
export interface RentActivityFrameRecordAllOf {
    /**
     * 
     * @type {RentActivityFrameTypeCodeEnum}
     * @memberof RentActivityFrameRecordAllOf
     */
    rentActivityFrameTypeCode?: RentActivityFrameTypeCodeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof RentActivityFrameRecordAllOf
     */
    tags?: Array<string>;
    /**
     * Общая строка тегов, объединенных через запятую
     * @type {string}
     * @memberof RentActivityFrameRecordAllOf
     */
    tagsString?: string;
    /**
     * 
     * @type {ProjectSourceCodeEnum}
     * @memberof RentActivityFrameRecordAllOf
     */
    sourceCode: ProjectSourceCodeEnum;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof RentActivityFrameRecordAllOf
     */
    shortName: string;
    /**
     * 
     * @type {RentActivityFrameStateCodeEnum}
     * @memberof RentActivityFrameRecordAllOf
     */
    stateCode: RentActivityFrameStateCodeEnum;
    /**
     * Коды доступных переходов для данного проекта и пользователя
     * @type {Array<RentActivityFrameTransitionCodeEnum>}
     * @memberof RentActivityFrameRecordAllOf
     */
    availableTransitionCodes?: Array<RentActivityFrameTransitionCodeEnum>;
    /**
     * Находится ли в архиве
     * @type {boolean}
     * @memberof RentActivityFrameRecordAllOf
     */
    archive: boolean;
    /**
     * Может ли быть отправлен в архив (может, когда не является активным)
     * @type {boolean}
     * @memberof RentActivityFrameRecordAllOf
     */
    archivable: boolean;
    /**
     * Ссылка: идентификатор контрагента
     * @type {number}
     * @memberof RentActivityFrameRecordAllOf
     */
    counterpartyId: number;
    /**
     * Выч. по ссылке: Короткое наименование контрагента
     * @type {string}
     * @memberof RentActivityFrameRecordAllOf
     */
    counterpartyShortName: string;
    /**
     * Дата начала работ
     * @type {Date}
     * @memberof RentActivityFrameRecordAllOf
     */
    startDate?: Date;
    /**
     * Дата завершения работ
     * @type {Date}
     * @memberof RentActivityFrameRecordAllOf
     */
    finishDate?: Date;
    /**
     * Наименование локации
     * @type {string}
     * @memberof RentActivityFrameRecordAllOf
     */
    locationName?: string;
    /**
     * Идентификатор локации
     * @type {number}
     * @memberof RentActivityFrameRecordAllOf
     */
    locationId?: number;
    /**
     * Ссылка: идентификатор пользователя - сотрудника абонента, ответственного за проект
     * @type {number}
     * @memberof RentActivityFrameRecordAllOf
     */
    assigneeId?: number;
    /**
     * Выч. по ссылке: короткое ФИО ответственного
     * @type {string}
     * @memberof RentActivityFrameRecordAllOf
     */
    assigneeShortName?: string;
    /**
     * Маркер, указывающий, что проект имеет собственного ответственного, а не наследует его от арендатора
     * @type {boolean}
     * @memberof RentActivityFrameRecordAllOf
     */
    hasOwnAssignee: boolean;
    /**
     * 
     * @type {ProblemAndWarningRentIndicatorsRecordObj}
     * @memberof RentActivityFrameRecordAllOf
     */
    problemsAndWarnings: ProblemAndWarningRentIndicatorsRecordObj;
    /**
     * Сумма после налогов, если налоги указаны, в коп.
     * @type {number}
     * @memberof RentActivityFrameRecordAllOf
     */
    sumAfterTaxesIfTaxExists?: number;
    /**
     * Сумма долга арендатора по проекту в коп.
     * @type {number}
     * @memberof RentActivityFrameRecordAllOf
     */
    debtSum: number;
    /**
     * Дата и время последней активности (измение сущности либо операция над связанными обязательствами)
     * @type {Date}
     * @memberof RentActivityFrameRecordAllOf
     */
    lastActivityDate: Date;
}
/**
 * Список представлений рамки арендной активности
 * @export
 * @interface RentActivityFrameRecordList
 */
export interface RentActivityFrameRecordList {
    /**
     * 
     * @type {ListAttributesInfo}
     * @memberof RentActivityFrameRecordList
     */
    listAttributes: ListAttributesInfo;
    /**
     * 
     * @type {Array<RentActivityFrameRecord>}
     * @memberof RentActivityFrameRecordList
     */
    records: Array<RentActivityFrameRecord>;
}
/**
 * 
 * @export
 * @interface RentActivityFrameRecordListAllOf
 */
export interface RentActivityFrameRecordListAllOf {
    /**
     * 
     * @type {Array<RentActivityFrameRecord>}
     * @memberof RentActivityFrameRecordListAllOf
     */
    records: Array<RentActivityFrameRecord>;
}
/**
 * Код статуса
 * @export
 * @enum {string}
 */
export enum RentActivityFrameStateCodeEnum {
    DRAFT = 'DRAFT',
    COORDINATION = 'COORDINATION',
    INPROGRESS = 'IN_PROGRESS',
    FINISHED = 'FINISHED',
    CANCELED = 'CANCELED',
    RENTED = 'RENTED',
    BOOKED = 'BOOKED'
}

/**
 * Код перехода между статусами
 * @export
 * @enum {string}
 */
export enum RentActivityFrameTransitionCodeEnum {
    ACTIVATE = 'ACTIVATE',
    FINISH = 'FINISH',
    CANCEL = 'CANCEL',
    RETURNTODRAFT = 'RETURN_TO_DRAFT',
    RENT = 'RENT',
    BOOK = 'BOOK',
    TOCOORDINATION = 'TO_COORDINATION'
}

/**
 * Тип рамки активности
 * @export
 * @enum {string}
 */
export enum RentActivityFrameTypeCodeEnum {
    PROJECT = 'PROJECT',
    SUBRENT = 'SUBRENT',
    TEMPLATE = 'TEMPLATE'
}

/**
 * Аудит аренды: состояние чтения
 * @export
 * @interface RentAuditRecord
 */
export interface RentAuditRecord {
    /**
     * Идентификатор
     * @type {number}
     * @memberof RentAuditRecord
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof RentAuditRecord
     */
    businessVersion: number;
    /**
     * Ссылка: Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof RentAuditRecord
     */
    businessAccountId: number;
    /**
     * Идентификатор элемента аренды
     * @type {Array<number>}
     * @memberof RentAuditRecord
     */
    rentElementIds: Array<number>;
    /**
     * Применяемая цена за смену, коп.
     * @type {number}
     * @memberof RentAuditRecord
     */
    rentElementEffectivePrice: number;
    /**
     * Идентификатор операции
     * @type {number}
     * @memberof RentAuditRecord
     */
    operationId: number;
    /**
     * Мнемонический код вида *КОД_ПРОЕКТА*-*МНЕМОКОД_ТИПА_ОПЕРАЦИИ**ПОРЯДКОВЫЙ_НОМЕР_ОПЕРАЦИИ_В_ПРОЕКТЕ*
     * @type {string}
     * @memberof RentAuditRecord
     */
    operationMnemoKey: string;
    /**
     * 
     * @type {OperationTypeCodeEnum}
     * @memberof RentAuditRecord
     */
    operationTypeCode: OperationTypeCodeEnum;
    /**
     * Ссылка: идентификатор автора
     * @type {number}
     * @memberof RentAuditRecord
     */
    operationAuthorId: number;
    /**
     * Выч: короткое ФИО автора (Фамилия и инициалы)
     * @type {string}
     * @memberof RentAuditRecord
     */
    operationAuthorShortName: string;
    /**
     * Ссылка: идентификатор продукта
     * @type {number}
     * @memberof RentAuditRecord
     */
    productId: number;
    /**
     * Выч: короткое наименование продукта
     * @type {string}
     * @memberof RentAuditRecord
     */
    productShortName: string;
    /**
     * Выч: информация о главном изображении продукта
     * @type {ImageObj}
     * @memberof RentAuditRecord
     */
    productMainImage?: ImageObj;
    /**
     * Идентификатор варианта
     * @type {number}
     * @memberof RentAuditRecord
     */
    variantId?: number;
    /**
     * Наименование варианта
     * @type {string}
     * @memberof RentAuditRecord
     */
    variantName?: string;
    /**
     * Дата создания операции
     * @type {Date}
     * @memberof RentAuditRecord
     */
    operationCreationDate?: Date;
    /**
     * 
     * @type {RentStateCodeEnum}
     * @memberof RentAuditRecord
     */
    stateCode: RentStateCodeEnum;
    /**
     * Выч: маркер, были ли изменены параметры аренды текущего элемента
     * @type {boolean}
     * @memberof RentAuditRecord
     */
    rentTermsOrInstancesChanged: boolean;
    /**
     * 
     * @type {RentTermsObj}
     * @memberof RentAuditRecord
     */
    rentTerms: RentTermsObj;
    /**
     * 
     * @type {InstanceTrackingTypeCodeEnum}
     * @memberof RentAuditRecord
     */
    instanceTrackingTypeCode: InstanceTrackingTypeCodeEnum;
    /**
     * Общее число экземпляров
     * @type {number}
     * @memberof RentAuditRecord
     */
    instanceCount: number;
    /**
     * Число анонимных экземпляров (для счетных всегда равно общему числу экземляров)
     * @type {number}
     * @memberof RentAuditRecord
     */
    anonymousInstanceCount: number;
    /**
     * Список записей экземпляров (используется только для продуктов с отслеживаемыми экземплярами)
     * @type {Array<InstanceRecord>}
     * @memberof RentAuditRecord
     */
    instances?: Array<InstanceRecord>;
}
/**
 * 
 * @export
 * @interface RentAuditRecordAllOf
 */
export interface RentAuditRecordAllOf {
    /**
     * Идентификатор элемента аренды
     * @type {Array<number>}
     * @memberof RentAuditRecordAllOf
     */
    rentElementIds: Array<number>;
    /**
     * Применяемая цена за смену, коп.
     * @type {number}
     * @memberof RentAuditRecordAllOf
     */
    rentElementEffectivePrice: number;
    /**
     * Идентификатор операции
     * @type {number}
     * @memberof RentAuditRecordAllOf
     */
    operationId: number;
    /**
     * Мнемонический код вида *КОД_ПРОЕКТА*-*МНЕМОКОД_ТИПА_ОПЕРАЦИИ**ПОРЯДКОВЫЙ_НОМЕР_ОПЕРАЦИИ_В_ПРОЕКТЕ*
     * @type {string}
     * @memberof RentAuditRecordAllOf
     */
    operationMnemoKey: string;
    /**
     * 
     * @type {OperationTypeCodeEnum}
     * @memberof RentAuditRecordAllOf
     */
    operationTypeCode: OperationTypeCodeEnum;
    /**
     * Ссылка: идентификатор автора
     * @type {number}
     * @memberof RentAuditRecordAllOf
     */
    operationAuthorId: number;
    /**
     * Выч: короткое ФИО автора (Фамилия и инициалы)
     * @type {string}
     * @memberof RentAuditRecordAllOf
     */
    operationAuthorShortName: string;
    /**
     * Ссылка: идентификатор продукта
     * @type {number}
     * @memberof RentAuditRecordAllOf
     */
    productId: number;
    /**
     * Выч: короткое наименование продукта
     * @type {string}
     * @memberof RentAuditRecordAllOf
     */
    productShortName: string;
    /**
     * Выч: информация о главном изображении продукта
     * @type {ImageObj}
     * @memberof RentAuditRecordAllOf
     */
    productMainImage?: ImageObj;
    /**
     * Идентификатор варианта
     * @type {number}
     * @memberof RentAuditRecordAllOf
     */
    variantId?: number;
    /**
     * Наименование варианта
     * @type {string}
     * @memberof RentAuditRecordAllOf
     */
    variantName?: string;
    /**
     * Дата создания операции
     * @type {Date}
     * @memberof RentAuditRecordAllOf
     */
    operationCreationDate?: Date;
    /**
     * 
     * @type {RentStateCodeEnum}
     * @memberof RentAuditRecordAllOf
     */
    stateCode: RentStateCodeEnum;
    /**
     * Выч: маркер, были ли изменены параметры аренды текущего элемента
     * @type {boolean}
     * @memberof RentAuditRecordAllOf
     */
    rentTermsOrInstancesChanged: boolean;
    /**
     * 
     * @type {RentTermsObj}
     * @memberof RentAuditRecordAllOf
     */
    rentTerms: RentTermsObj;
    /**
     * 
     * @type {InstanceTrackingTypeCodeEnum}
     * @memberof RentAuditRecordAllOf
     */
    instanceTrackingTypeCode: InstanceTrackingTypeCodeEnum;
    /**
     * Общее число экземпляров
     * @type {number}
     * @memberof RentAuditRecordAllOf
     */
    instanceCount: number;
    /**
     * Число анонимных экземпляров (для счетных всегда равно общему числу экземляров)
     * @type {number}
     * @memberof RentAuditRecordAllOf
     */
    anonymousInstanceCount: number;
    /**
     * Список записей экземпляров (используется только для продуктов с отслеживаемыми экземплярами)
     * @type {Array<InstanceRecord>}
     * @memberof RentAuditRecordAllOf
     */
    instances?: Array<InstanceRecord>;
}
/**
 * Список аудитов аренды
 * @export
 * @interface RentAuditRecordList
 */
export interface RentAuditRecordList {
    /**
     * 
     * @type {Array<RentAuditRecord>}
     * @memberof RentAuditRecordList
     */
    records: Array<RentAuditRecord>;
}
/**
 * Запись аудита аренды и его предшественника
 * @export
 * @interface RentAuditWithPredecessorRecord
 */
export interface RentAuditWithPredecessorRecord {
    /**
     * 
     * @type {RentAuditRecord}
     * @memberof RentAuditWithPredecessorRecord
     */
    audit: RentAuditRecord;
    /**
     * 
     * @type {RentAuditRecord}
     * @memberof RentAuditWithPredecessorRecord
     */
    predecessor?: RentAuditRecord;
}
/**
 * Список аудитов аренды состава операции и предшествующих им аудитов
 * @export
 * @interface RentAuditWithPredecessorRecordList
 */
export interface RentAuditWithPredecessorRecordList {
    /**
     * 
     * @type {Array<RentAuditWithPredecessorRecord>}
     * @memberof RentAuditWithPredecessorRecordList
     */
    records: Array<RentAuditWithPredecessorRecord>;
}
/**
 * 
 * @export
 * @interface RentElementAbstractInfo
 */
export interface RentElementAbstractInfo {
    /**
     * Идентификатор
     * @type {number}
     * @memberof RentElementAbstractInfo
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof RentElementAbstractInfo
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof RentElementAbstractInfo
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof RentElementAbstractInfo
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof RentElementAbstractInfo
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof RentElementAbstractInfo
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof RentElementAbstractInfo
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof RentElementAbstractInfo
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof RentElementAbstractInfo
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof RentElementAbstractInfo
     */
    businessAccountId?: number;
    /**
     * Количество экземпляров
     * @type {number}
     * @memberof RentElementAbstractInfo
     */
    instanceCount: number;
    /**
     * Число анонимных экземпляров (не счетных, а отслеживаемых, но анонимных)
     * @type {number}
     * @memberof RentElementAbstractInfo
     */
    anonymousInstanceCount?: number;
    /**
     * 
     * @type {RentTermsObj}
     * @memberof RentElementAbstractInfo
     */
    rentTerms: RentTermsObj;
}
/**
 * 
 * @export
 * @interface RentElementAbstractInfoAllOf
 */
export interface RentElementAbstractInfoAllOf {
    /**
     * Количество экземпляров
     * @type {number}
     * @memberof RentElementAbstractInfoAllOf
     */
    instanceCount: number;
    /**
     * Число анонимных экземпляров (не счетных, а отслеживаемых, но анонимных)
     * @type {number}
     * @memberof RentElementAbstractInfoAllOf
     */
    anonymousInstanceCount?: number;
    /**
     * 
     * @type {RentTermsObj}
     * @memberof RentElementAbstractInfoAllOf
     */
    rentTerms: RentTermsObj;
}
/**
 * Элемент аренды: абстрактное состояние при записи
 * @export
 * @interface RentElementAbstractWriteCommons
 */
export interface RentElementAbstractWriteCommons {
    /**
     * Идентификатор
     * @type {number}
     * @memberof RentElementAbstractWriteCommons
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof RentElementAbstractWriteCommons
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof RentElementAbstractWriteCommons
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof RentElementAbstractWriteCommons
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof RentElementAbstractWriteCommons
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof RentElementAbstractWriteCommons
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof RentElementAbstractWriteCommons
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof RentElementAbstractWriteCommons
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof RentElementAbstractWriteCommons
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof RentElementAbstractWriteCommons
     */
    businessAccountId?: number;
    /**
     * Количество экземпляров
     * @type {number}
     * @memberof RentElementAbstractWriteCommons
     */
    instanceCount: number;
    /**
     * Число анонимных экземпляров (не счетных, а отслеживаемых, но анонимных)
     * @type {number}
     * @memberof RentElementAbstractWriteCommons
     */
    anonymousInstanceCount: number;
    /**
     * 
     * @type {RentTermsObj}
     * @memberof RentElementAbstractWriteCommons
     */
    rentTerms: RentTermsObj;
    /**
     * Список идентификаторов экземпляров (используется только для продуктов с отслеживанием экземпляров)
     * @type {Array<number>}
     * @memberof RentElementAbstractWriteCommons
     */
    instanceIds?: Array<number>;
    /**
     * Ссылка: идентификатор варианта
     * @type {number}
     * @memberof RentElementAbstractWriteCommons
     */
    variantId?: number;
}
/**
 * 
 * @export
 * @interface RentElementAbstractWriteCommonsAllOf
 */
export interface RentElementAbstractWriteCommonsAllOf {
    /**
     * Список идентификаторов экземпляров (используется только для продуктов с отслеживанием экземпляров)
     * @type {Array<number>}
     * @memberof RentElementAbstractWriteCommonsAllOf
     */
    instanceIds?: Array<number>;
    /**
     * Ссылка: идентификатор варианта
     * @type {number}
     * @memberof RentElementAbstractWriteCommonsAllOf
     */
    variantId?: number;
}
/**
 * Общие поля списочных представление элемента аренды и вложенных в набор элементов аренды
 * @export
 * @interface RentElementCommonFieldsObj
 */
export interface RentElementCommonFieldsObj {
    /**
     * 
     * @type {Array<string>}
     * @memberof RentElementCommonFieldsObj
     */
    tags?: Array<string>;
    /**
     * Общая строка тегов, объединенных через запятую
     * @type {string}
     * @memberof RentElementCommonFieldsObj
     */
    tagsString?: string;
    /**
     * 
     * @type {NomenclatureEntityTypeCodeEnum}
     * @memberof RentElementCommonFieldsObj
     */
    nomenclatureEntityTypeCode: NomenclatureEntityTypeCodeEnum;
    /**
     * 
     * @type {InstanceTrackingTypeCodeEnum}
     * @memberof RentElementCommonFieldsObj
     */
    instanceTrackingTypeCode: InstanceTrackingTypeCodeEnum;
    /**
     * Идентификаторы экземпляров
     * @type {Array<number>}
     * @memberof RentElementCommonFieldsObj
     */
    instanceIds?: Array<number>;
    /**
     * Коды экземпляров
     * @type {Array<string>}
     * @memberof RentElementCommonFieldsObj
     */
    instanceNameOrCodes?: Array<string>;
    /**
     * Число субарендованых экземпляров (только для обязательств аренды по номенклатуре с именованными экземплярами)
     * @type {number}
     * @memberof RentElementCommonFieldsObj
     */
    subrentedInstanceCount: number;
    /**
     * Выч: краткое наименование номенклатуры
     * @type {string}
     * @memberof RentElementCommonFieldsObj
     */
    nomenclatureShortName: string;
    /**
     * Выч: полное наименование номенклатуры
     * @type {string}
     * @memberof RentElementCommonFieldsObj
     */
    nomenclatureFullName: string;
    /**
     * Номер в рамке активности
     * @type {number}
     * @memberof RentElementCommonFieldsObj
     */
    numberInActivityFrame?: number;
    /**
     * Ссылка: идентификатор продукта
     * @type {number}
     * @memberof RentElementCommonFieldsObj
     */
    productId: number;
    /**
     * Идентификатор варианта
     * @type {number}
     * @memberof RentElementCommonFieldsObj
     */
    variantId?: number;
    /**
     * Выч: наименование варианта
     * @type {string}
     * @memberof RentElementCommonFieldsObj
     */
    variantName?: string;
    /**
     * Артикул (продукта или варианта)
     * @type {string}
     * @memberof RentElementCommonFieldsObj
     */
    externalCode?: string;
    /**
     * Выч: информация об изображении
     * @type {ImageObj}
     * @memberof RentElementCommonFieldsObj
     */
    image?: ImageObj;
    /**
     * Ссылка: идентификатор автора последнего изменения (автора операции)
     * @type {number}
     * @memberof RentElementCommonFieldsObj
     */
    lastUpdateAuthorId: number;
    /**
     * Ссылки: идентификаторы операций над элементом
     * @type {Array<number>}
     * @memberof RentElementCommonFieldsObj
     */
    operationIds: Array<number>;
    /**
     * Ссылка: идентификатор последней операции
     * @type {number}
     * @memberof RentElementCommonFieldsObj
     */
    lastOperationId: number;
    /**
     * 
     * @type {RentTermsObj}
     * @memberof RentElementCommonFieldsObj
     */
    rentTerms: RentTermsObj;
    /**
     * 
     * @type {ShiftsObj}
     * @memberof RentElementCommonFieldsObj
     */
    calendarShifts: ShiftsObj;
    /**
     * 
     * @type {ShiftsObj}
     * @memberof RentElementCommonFieldsObj
     */
    subrentWorkedShifts?: ShiftsObj;
    /**
     * Базовая цена за штуку, коп. Берется из каталожной цены на момент создания
     * @type {number}
     * @memberof RentElementCommonFieldsObj
     */
    basePriceAtTheTimeOfCreation: number;
    /**
     * 
     * @type {PricingSchemeExternalRepresentationObj}
     * @memberof RentElementCommonFieldsObj
     */
    effectivePricingScheme?: PricingSchemeExternalRepresentationObj;
    /**
     * Цена за штуку, коп.
     * @type {number}
     * @memberof RentElementCommonFieldsObj
     */
    effectivePrice: number;
    /**
     * Количество экземпляров
     * @type {number}
     * @memberof RentElementCommonFieldsObj
     */
    instanceCount: number;
    /**
     * Число анонимных экземпляров (не счетных, а отслеживаемых, но анонимных)
     * @type {number}
     * @memberof RentElementCommonFieldsObj
     */
    anonymousInstanceCount: number;
    /**
     * Суммарная стоимость до вычета скидки, коп.
     * @type {number}
     * @memberof RentElementCommonFieldsObj
     */
    totalPriceBeforeDiscount: number;
    /**
     * Итоговая стоимость за экземпляр, коп.
     * @type {number}
     * @memberof RentElementCommonFieldsObj
     */
    finalInstancePrice: number;
    /**
     * Итоговая суммарная стоимость, коп. (Считается на основе округленной finalInstancePrice)
     * @type {number}
     * @memberof RentElementCommonFieldsObj
     */
    finalTotalPrice: number;
    /**
     * Идентификатор основной категории
     * @type {number}
     * @memberof RentElementCommonFieldsObj
     */
    mainCategoryId?: number;
    /**
     * Наименование основной категории
     * @type {string}
     * @memberof RentElementCommonFieldsObj
     */
    mainCategoryName?: string;
    /**
     * Идентификаторы категорий
     * @type {Array<number>}
     * @memberof RentElementCommonFieldsObj
     */
    categoryIds?: Array<number>;
    /**
     * 
     * @type {RentStateCodeEnum}
     * @memberof RentElementCommonFieldsObj
     */
    stateCode: RentStateCodeEnum;
    /**
     * 
     * @type {RentStateCodeEnum}
     * @memberof RentElementCommonFieldsObj
     */
    stateCodeToReturnBeforeCancel?: RentStateCodeEnum;
    /**
     * Доступен ли возврат в статус до отменены в правке
     * @type {boolean}
     * @memberof RentElementCommonFieldsObj
     */
    stateCodeToReturnBeforeCancelAvailableWithoutCorrection?: boolean;
    /**
     * Коды резолюций
     * @type {Array<RentElementResolutionCodeEnum>}
     * @memberof RentElementCommonFieldsObj
     */
    resolutionCodes?: Array<RentElementResolutionCodeEnum>;
    /**
     * Активный статус, либо, в случае набора, хотя бы одно вложенное обязательство в активном статусе
     * @type {boolean}
     * @memberof RentElementCommonFieldsObj
     */
    active: boolean;
    /**
     * 
     * @type {ProblemAndWarningRentIndicatorsRecordObj}
     * @memberof RentElementCommonFieldsObj
     */
    problemsAndWarnings: ProblemAndWarningRentIndicatorsRecordObj;
    /**
     * Количество недостающих экземпляров (нехватка)
     * @type {number}
     * @memberof RentElementCommonFieldsObj
     */
    shortageInstanceCount: number;
    /**
     * Список идентификаторов экземпляров по которым нехватка именно на текущем обязательстве
     * @type {Array<number>}
     * @memberof RentElementCommonFieldsObj
     */
    instanceWithCurrentElementShortageIds?: Array<number>;
    /**
     * Коды доступных переходов для данного обазательства и пользователя (корректировка на передается, обрабатывается на клиенте)
     * @type {Array<OperationTypeCodeEnum>}
     * @memberof RentElementCommonFieldsObj
     */
    availableTransitionCodes?: Array<OperationTypeCodeEnum>;
    /**
     * Коды доступных целевых статусов корректировки
     * @type {Array<RentStateCodeEnum>}
     * @memberof RentElementCommonFieldsObj
     */
    availableTargetCorrectionStates?: Array<RentStateCodeEnum>;
    /**
     * Дата и время последней активности (в данном случае только дата последней операции)
     * @type {Date}
     * @memberof RentElementCommonFieldsObj
     */
    lastActivityDate: Date;
    /**
     * Базовая закупочная стоимость, коп.
     * @type {number}
     * @memberof RentElementCommonFieldsObj
     */
    basePurchaseCost?: number;
    /**
     * Сумма закупочных стоимостей, коп.
     * @type {number}
     * @memberof RentElementCommonFieldsObj
     */
    purchaseCostSum?: number;
    /**
     * 
     * @type {Array<CustomFieldValueObjRecord>}
     * @memberof RentElementCommonFieldsObj
     */
    customFieldValues?: Array<CustomFieldValueObjRecord>;
    /**
     * Идентификаторы пользовательских полей обязательств
     * @type {Array<number>}
     * @memberof RentElementCommonFieldsObj
     */
    customFieldIds?: Array<number>;
}
/**
 * Элемент аренды: состояние создания
 * @export
 * @interface RentElementInfoCreate
 */
export interface RentElementInfoCreate {
    /**
     * Идентификатор
     * @type {number}
     * @memberof RentElementInfoCreate
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof RentElementInfoCreate
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof RentElementInfoCreate
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof RentElementInfoCreate
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof RentElementInfoCreate
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof RentElementInfoCreate
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof RentElementInfoCreate
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof RentElementInfoCreate
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof RentElementInfoCreate
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof RentElementInfoCreate
     */
    businessAccountId?: number;
    /**
     * Количество экземпляров
     * @type {number}
     * @memberof RentElementInfoCreate
     */
    instanceCount: number;
    /**
     * Число анонимных экземпляров (не счетных, а отслеживаемых, но анонимных)
     * @type {number}
     * @memberof RentElementInfoCreate
     */
    anonymousInstanceCount: number;
    /**
     * 
     * @type {RentTermsObj}
     * @memberof RentElementInfoCreate
     */
    rentTerms: RentTermsObj;
    /**
     * Список идентификаторов экземпляров (используется только для продуктов с отслеживанием экземпляров)
     * @type {Array<number>}
     * @memberof RentElementInfoCreate
     */
    instanceIds?: Array<number>;
    /**
     * Ссылка: идентификатор варианта
     * @type {number}
     * @memberof RentElementInfoCreate
     */
    variantId?: number;
    /**
     * Ожидаемая применяемая цена за смену, если отличается от цены в продукте при применении - ошибка
     * @type {number}
     * @memberof RentElementInfoCreate
     */
    effectivePrice: number;
    /**
     * Является основным компанентом в наборе
     * @type {boolean}
     * @memberof RentElementInfoCreate
     */
    mainKitMember: boolean;
    /**
     * Ссылка: идентификатор продукта
     * @type {number}
     * @memberof RentElementInfoCreate
     */
    productId: number;
}
/**
 * 
 * @export
 * @interface RentElementInfoCreateAllOf
 */
export interface RentElementInfoCreateAllOf {
    /**
     * Ожидаемая применяемая цена за смену, если отличается от цены в продукте при применении - ошибка
     * @type {number}
     * @memberof RentElementInfoCreateAllOf
     */
    effectivePrice: number;
    /**
     * Является основным компанентом в наборе
     * @type {boolean}
     * @memberof RentElementInfoCreateAllOf
     */
    mainKitMember: boolean;
    /**
     * Ссылка: идентификатор продукта
     * @type {number}
     * @memberof RentElementInfoCreateAllOf
     */
    productId: number;
}
/**
 * Элемент аренды: состояние при чтении
 * @export
 * @interface RentElementInfoRead
 */
export interface RentElementInfoRead {
    /**
     * Идентификатор
     * @type {number}
     * @memberof RentElementInfoRead
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof RentElementInfoRead
     */
    businessVersion: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof RentElementInfoRead
     */
    creationAuthorId: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof RentElementInfoRead
     */
    creationAuthorFullName: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof RentElementInfoRead
     */
    creationDate: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof RentElementInfoRead
     */
    lastUpdateAuthorId: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof RentElementInfoRead
     */
    lastUpdateAuthorFullName: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof RentElementInfoRead
     */
    lastUpdateDate: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof RentElementInfoRead
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof RentElementInfoRead
     */
    businessAccountId: number;
    /**
     * Количество экземпляров
     * @type {number}
     * @memberof RentElementInfoRead
     */
    instanceCount: number;
    /**
     * Число анонимных экземпляров (не счетных, а отслеживаемых, но анонимных)
     * @type {number}
     * @memberof RentElementInfoRead
     */
    anonymousInstanceCount: number;
    /**
     * 
     * @type {RentTermsObj}
     * @memberof RentElementInfoRead
     */
    rentTerms: RentTermsObj;
    /**
     * 
     * @type {Array<string>}
     * @memberof RentElementInfoRead
     */
    tags?: Array<string>;
    /**
     * Общая строка тегов, объединенных через запятую
     * @type {string}
     * @memberof RentElementInfoRead
     */
    tagsString?: string;
    /**
     * 
     * @type {InstanceTrackingTypeCodeEnum}
     * @memberof RentElementInfoRead
     */
    instanceTrackingTypeCode: InstanceTrackingTypeCodeEnum;
    /**
     * Набор обязательств
     * @type {number}
     * @memberof RentElementInfoRead
     */
    rentElementsKitId?: number;
    /**
     * Ссылка: идентификатор набора
     * @type {number}
     * @memberof RentElementInfoRead
     */
    kitId?: number;
    /**
     * Выч: короткое наименование набора
     * @type {string}
     * @memberof RentElementInfoRead
     */
    kitShortName?: string;
    /**
     * Является основным компанентом в наборе
     * @type {boolean}
     * @memberof RentElementInfoRead
     */
    mainKitMember: boolean;
    /**
     * Ссылка: идентификатор продукта
     * @type {number}
     * @memberof RentElementInfoRead
     */
    productId: number;
    /**
     * Выч: короткое наименование продукта
     * @type {string}
     * @memberof RentElementInfoRead
     */
    productShortName: string;
    /**
     * Выч: идентификатор варианта
     * @type {number}
     * @memberof RentElementInfoRead
     */
    variantId?: number;
    /**
     * Выч: наименование варианта
     * @type {string}
     * @memberof RentElementInfoRead
     */
    variantName?: string;
    /**
     * Список записей экземпляров (используется только для продуктов с отслеживаемыми экземплярами)
     * @type {Array<InstanceRecord>}
     * @memberof RentElementInfoRead
     */
    instances?: Array<InstanceRecord>;
    /**
     * Список идентификаторов экземпляров по которым нехватка именно на текущем обязательстве
     * @type {Array<number>}
     * @memberof RentElementInfoRead
     */
    instanceWithCurrentElementShortageIds?: Array<number>;
    /**
     * Ссылка: идентификатор проекта (обязательно, если не указан шаблон и поставка)
     * @type {number}
     * @memberof RentElementInfoRead
     */
    projectId?: number;
    /**
     * Выч: короткое наименование проекта (обязательно, если не указан шаблон и поставка)
     * @type {string}
     * @memberof RentElementInfoRead
     */
    projectShortName?: string;
    /**
     * Ссылка: идентификатор поставки (обязательно, если не указан шаблон и проект)
     * @type {number}
     * @memberof RentElementInfoRead
     */
    subrentId?: number;
    /**
     * Выч: короткое наименование поставки (обязательно, если не указан шаблон и проект)
     * @type {string}
     * @memberof RentElementInfoRead
     */
    subrentShortName?: string;
    /**
     * Ссылка: идентификатор шаблона (обязательно, если не указан проект и поставка)
     * @type {number}
     * @memberof RentElementInfoRead
     */
    templateId?: number;
    /**
     * Выч: короткое наименование шаблона  (обязательно, если не указан проект и поставка)
     * @type {string}
     * @memberof RentElementInfoRead
     */
    templateShortName?: string;
    /**
     * Ссылка: идентификатор контрагента
     * @type {number}
     * @memberof RentElementInfoRead
     */
    counterpartyId: number;
    /**
     * Выч: короткое наименование контрагента
     * @type {string}
     * @memberof RentElementInfoRead
     */
    counterpartyShortName: string;
    /**
     * Выч: информация об изображении
     * @type {ImageObj}
     * @memberof RentElementInfoRead
     */
    image?: ImageObj;
    /**
     * 
     * @type {RentStateCodeEnum}
     * @memberof RentElementInfoRead
     */
    stateCode: RentStateCodeEnum;
    /**
     * Коды резолюций
     * @type {Array<RentElementResolutionCodeEnum>}
     * @memberof RentElementInfoRead
     */
    resolutionCodes?: Array<RentElementResolutionCodeEnum>;
    /**
     * Базовая цена за штуку, коп. Берется из каталожной цены на момент создания
     * @type {number}
     * @memberof RentElementInfoRead
     */
    basePriceAtTheTimeOfCreation: number;
    /**
     * Применяемая цена за смену, коп.
     * @type {number}
     * @memberof RentElementInfoRead
     */
    effectivePrice: number;
    /**
     * 
     * @type {PricingSchemeExternalRepresentationObj}
     * @memberof RentElementInfoRead
     */
    effectivePricingScheme?: PricingSchemeExternalRepresentationObj;
    /**
     * Коды доступных переходов для данного обазательства и пользователя (корректировка на передается, обрабатывается на клиенте). Только для обработки возможности корректировки в зависимости от прав
     * @type {Array<OperationTypeCodeEnum>}
     * @memberof RentElementInfoRead
     */
    availableTransitionCodes?: Array<OperationTypeCodeEnum>;
    /**
     * Коды доступных целевых статусов корректировки
     * @type {Array<RentStateCodeEnum>}
     * @memberof RentElementInfoRead
     */
    availableTargetCorrectionStates?: Array<RentStateCodeEnum>;
    /**
     * Количество недостающих экземпляров (нехватка)
     * @type {number}
     * @memberof RentElementInfoRead
     */
    shortageInstanceCount: number;
    /**
     * 
     * @type {RentAuditRecordList}
     * @memberof RentElementInfoRead
     */
    rentAuditRecordList: RentAuditRecordList;
    /**
     * 
     * @type {RentIndicatorsObj}
     * @memberof RentElementInfoRead
     */
    rentIndicators: RentIndicatorsObj;
    /**
     * 
     * @type {number}
     * @memberof RentElementInfoRead
     */
    calendarShiftCount: number;
    /**
     * 
     * @type {number}
     * @memberof RentElementInfoRead
     */
    subrentWorkedShiftCount?: number;
    /**
     * Проект является КП. Обязательно если операция относится к проекту или КП
     * @type {boolean}
     * @memberof RentElementInfoRead
     */
    projectIsOffer?: boolean;
    /**
     * Проект является простым заказом. Обязательно если операция относится к проекту или КП
     * @type {boolean}
     * @memberof RentElementInfoRead
     */
    projectIsSimpleOrder?: boolean;
}
/**
 * 
 * @export
 * @interface RentElementInfoReadAllOf
 */
export interface RentElementInfoReadAllOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof RentElementInfoReadAllOf
     */
    tags?: Array<string>;
    /**
     * Общая строка тегов, объединенных через запятую
     * @type {string}
     * @memberof RentElementInfoReadAllOf
     */
    tagsString?: string;
    /**
     * 
     * @type {InstanceTrackingTypeCodeEnum}
     * @memberof RentElementInfoReadAllOf
     */
    instanceTrackingTypeCode: InstanceTrackingTypeCodeEnum;
    /**
     * Набор обязательств
     * @type {number}
     * @memberof RentElementInfoReadAllOf
     */
    rentElementsKitId?: number;
    /**
     * Ссылка: идентификатор набора
     * @type {number}
     * @memberof RentElementInfoReadAllOf
     */
    kitId?: number;
    /**
     * Выч: короткое наименование набора
     * @type {string}
     * @memberof RentElementInfoReadAllOf
     */
    kitShortName?: string;
    /**
     * Является основным компанентом в наборе
     * @type {boolean}
     * @memberof RentElementInfoReadAllOf
     */
    mainKitMember: boolean;
    /**
     * Ссылка: идентификатор продукта
     * @type {number}
     * @memberof RentElementInfoReadAllOf
     */
    productId: number;
    /**
     * Выч: короткое наименование продукта
     * @type {string}
     * @memberof RentElementInfoReadAllOf
     */
    productShortName: string;
    /**
     * Выч: идентификатор варианта
     * @type {number}
     * @memberof RentElementInfoReadAllOf
     */
    variantId?: number;
    /**
     * Выч: наименование варианта
     * @type {string}
     * @memberof RentElementInfoReadAllOf
     */
    variantName?: string;
    /**
     * Список записей экземпляров (используется только для продуктов с отслеживаемыми экземплярами)
     * @type {Array<InstanceRecord>}
     * @memberof RentElementInfoReadAllOf
     */
    instances?: Array<InstanceRecord>;
    /**
     * Список идентификаторов экземпляров по которым нехватка именно на текущем обязательстве
     * @type {Array<number>}
     * @memberof RentElementInfoReadAllOf
     */
    instanceWithCurrentElementShortageIds?: Array<number>;
    /**
     * Ссылка: идентификатор проекта (обязательно, если не указан шаблон и поставка)
     * @type {number}
     * @memberof RentElementInfoReadAllOf
     */
    projectId?: number;
    /**
     * Выч: короткое наименование проекта (обязательно, если не указан шаблон и поставка)
     * @type {string}
     * @memberof RentElementInfoReadAllOf
     */
    projectShortName?: string;
    /**
     * Ссылка: идентификатор поставки (обязательно, если не указан шаблон и проект)
     * @type {number}
     * @memberof RentElementInfoReadAllOf
     */
    subrentId?: number;
    /**
     * Выч: короткое наименование поставки (обязательно, если не указан шаблон и проект)
     * @type {string}
     * @memberof RentElementInfoReadAllOf
     */
    subrentShortName?: string;
    /**
     * Ссылка: идентификатор шаблона (обязательно, если не указан проект и поставка)
     * @type {number}
     * @memberof RentElementInfoReadAllOf
     */
    templateId?: number;
    /**
     * Выч: короткое наименование шаблона  (обязательно, если не указан проект и поставка)
     * @type {string}
     * @memberof RentElementInfoReadAllOf
     */
    templateShortName?: string;
    /**
     * Ссылка: идентификатор контрагента
     * @type {number}
     * @memberof RentElementInfoReadAllOf
     */
    counterpartyId: number;
    /**
     * Выч: короткое наименование контрагента
     * @type {string}
     * @memberof RentElementInfoReadAllOf
     */
    counterpartyShortName: string;
    /**
     * Выч: информация об изображении
     * @type {ImageObj}
     * @memberof RentElementInfoReadAllOf
     */
    image?: ImageObj;
    /**
     * 
     * @type {RentStateCodeEnum}
     * @memberof RentElementInfoReadAllOf
     */
    stateCode: RentStateCodeEnum;
    /**
     * Коды резолюций
     * @type {Array<RentElementResolutionCodeEnum>}
     * @memberof RentElementInfoReadAllOf
     */
    resolutionCodes?: Array<RentElementResolutionCodeEnum>;
    /**
     * Базовая цена за штуку, коп. Берется из каталожной цены на момент создания
     * @type {number}
     * @memberof RentElementInfoReadAllOf
     */
    basePriceAtTheTimeOfCreation: number;
    /**
     * Применяемая цена за смену, коп.
     * @type {number}
     * @memberof RentElementInfoReadAllOf
     */
    effectivePrice: number;
    /**
     * 
     * @type {PricingSchemeExternalRepresentationObj}
     * @memberof RentElementInfoReadAllOf
     */
    effectivePricingScheme?: PricingSchemeExternalRepresentationObj;
    /**
     * Коды доступных переходов для данного обазательства и пользователя (корректировка на передается, обрабатывается на клиенте). Только для обработки возможности корректировки в зависимости от прав
     * @type {Array<OperationTypeCodeEnum>}
     * @memberof RentElementInfoReadAllOf
     */
    availableTransitionCodes?: Array<OperationTypeCodeEnum>;
    /**
     * Коды доступных целевых статусов корректировки
     * @type {Array<RentStateCodeEnum>}
     * @memberof RentElementInfoReadAllOf
     */
    availableTargetCorrectionStates?: Array<RentStateCodeEnum>;
    /**
     * Количество недостающих экземпляров (нехватка)
     * @type {number}
     * @memberof RentElementInfoReadAllOf
     */
    shortageInstanceCount: number;
    /**
     * 
     * @type {RentAuditRecordList}
     * @memberof RentElementInfoReadAllOf
     */
    rentAuditRecordList: RentAuditRecordList;
    /**
     * 
     * @type {RentIndicatorsObj}
     * @memberof RentElementInfoReadAllOf
     */
    rentIndicators: RentIndicatorsObj;
    /**
     * 
     * @type {number}
     * @memberof RentElementInfoReadAllOf
     */
    calendarShiftCount: number;
    /**
     * 
     * @type {number}
     * @memberof RentElementInfoReadAllOf
     */
    subrentWorkedShiftCount?: number;
    /**
     * Проект является КП. Обязательно если операция относится к проекту или КП
     * @type {boolean}
     * @memberof RentElementInfoReadAllOf
     */
    projectIsOffer?: boolean;
    /**
     * Проект является простым заказом. Обязательно если операция относится к проекту или КП
     * @type {boolean}
     * @memberof RentElementInfoReadAllOf
     */
    projectIsSimpleOrder?: boolean;
}
/**
 * Элемент аренды: состояние изменения
 * @export
 * @interface RentElementInfoUpdate
 */
export interface RentElementInfoUpdate extends RentElementAbstractWriteCommons {
}
/**
 * 
 * @export
 * @interface RentElementInfoWrite
 */
export interface RentElementInfoWrite {
    /**
     * Идентификатор
     * @type {number}
     * @memberof RentElementInfoWrite
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof RentElementInfoWrite
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof RentElementInfoWrite
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof RentElementInfoWrite
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof RentElementInfoWrite
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof RentElementInfoWrite
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof RentElementInfoWrite
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof RentElementInfoWrite
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof RentElementInfoWrite
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof RentElementInfoWrite
     */
    businessAccountId?: number;
    /**
     * Количество экземпляров
     * @type {number}
     * @memberof RentElementInfoWrite
     */
    instanceCount: number;
    /**
     * Число анонимных экземпляров (не счетных, а отслеживаемых, но анонимных)
     * @type {number}
     * @memberof RentElementInfoWrite
     */
    anonymousInstanceCount: number;
    /**
     * 
     * @type {RentTermsObj}
     * @memberof RentElementInfoWrite
     */
    rentTerms: RentTermsObj;
    /**
     * Список идентификаторов экземпляров (используется только для продуктов с отслеживанием экземпляров)
     * @type {Array<number>}
     * @memberof RentElementInfoWrite
     */
    instanceIds?: Array<number>;
    /**
     * Ссылка: идентификатор варианта
     * @type {number}
     * @memberof RentElementInfoWrite
     */
    variantId?: number;
    /**
     * 
     * @type {RentStateCodeEnum}
     * @memberof RentElementInfoWrite
     */
    targetStateCodeOnEditing?: RentStateCodeEnum;
    /**
     * Применяемая цена экз. за смену
     * @type {number}
     * @memberof RentElementInfoWrite
     */
    effectivePrice: number;
    /**
     * Идентификатор применяемой ценовой схемы
     * @type {number}
     * @memberof RentElementInfoWrite
     */
    effectivePricingSchemeId?: number;
    /**
     * Является основным компанентом в наборе (когда создание - обязателен)
     * @type {boolean}
     * @memberof RentElementInfoWrite
     */
    mainKitMember?: boolean;
    /**
     * Ссылка: идентификатор компонента набора, нужно для пересчета цен в шаблонах, при изменении скидки в компоненте набора
     * @type {number}
     * @memberof RentElementInfoWrite
     */
    kitMemberId?: number;
    /**
     * Ссылка: идентификатор продукта (только если создание)
     * @type {number}
     * @memberof RentElementInfoWrite
     */
    productId?: number;
    /**
     * Оставлять ли остаток от обязательства при неполном попадании экземпляров в операцию
     * @type {boolean}
     * @memberof RentElementInfoWrite
     */
    keepLeftover?: boolean;
}
/**
 * 
 * @export
 * @interface RentElementInfoWriteAllOf
 */
export interface RentElementInfoWriteAllOf {
    /**
     * 
     * @type {RentStateCodeEnum}
     * @memberof RentElementInfoWriteAllOf
     */
    targetStateCodeOnEditing?: RentStateCodeEnum;
    /**
     * Применяемая цена экз. за смену
     * @type {number}
     * @memberof RentElementInfoWriteAllOf
     */
    effectivePrice: number;
    /**
     * Идентификатор применяемой ценовой схемы
     * @type {number}
     * @memberof RentElementInfoWriteAllOf
     */
    effectivePricingSchemeId?: number;
    /**
     * Является основным компанентом в наборе (когда создание - обязателен)
     * @type {boolean}
     * @memberof RentElementInfoWriteAllOf
     */
    mainKitMember?: boolean;
    /**
     * Ссылка: идентификатор компонента набора, нужно для пересчета цен в шаблонах, при изменении скидки в компоненте набора
     * @type {number}
     * @memberof RentElementInfoWriteAllOf
     */
    kitMemberId?: number;
    /**
     * Ссылка: идентификатор продукта (только если создание)
     * @type {number}
     * @memberof RentElementInfoWriteAllOf
     */
    productId?: number;
    /**
     * Оставлять ли остаток от обязательства при неполном попадании экземпляров в операцию
     * @type {boolean}
     * @memberof RentElementInfoWriteAllOf
     */
    keepLeftover?: boolean;
}
/**
 * Агрегации по выборке обязательств
 * @export
 * @interface RentElementListAggregations
 */
export interface RentElementListAggregations {
    /**
     * Число обязательств (для наборов считаются как кол-во вложенных обязательств)
     * @type {number}
     * @memberof RentElementListAggregations
     */
    rentElementCount: number;
    /**
     * Число экземпляров (для наборов считаются как сумма экземпляров вложенных обязательств)
     * @type {number}
     * @memberof RentElementListAggregations
     */
    instanceCount: number;
    /**
     * Коды присутствующих в выборке статусов аренды (MIXED не включается в выборку)
     * @type {Array<RentStateCodeEnum>}
     * @memberof RentElementListAggregations
     */
    stateCodes?: Array<RentStateCodeEnum>;
    /**
     * Суммарная стоимость до вычета скидки и добавления налогов, коп.
     * @type {number}
     * @memberof RentElementListAggregations
     */
    sumBeforeDiscountAndTaxes: number;
    /**
     * Суммарная стоимость после вычета скидки, до добавления налогов, коп.
     * @type {number}
     * @memberof RentElementListAggregations
     */
    sumAfterDiscountBeforeTaxes: number;
    /**
     * Финальная суммарная стоимость после вычета скидки и добавления налогов, коп.
     * @type {number}
     * @memberof RentElementListAggregations
     */
    finalTotalSum: number;
}
/**
 * Списочное представление элемента аренды
 * @export
 * @interface RentElementRecord
 */
export interface RentElementRecord {
    /**
     * Идентификатор
     * @type {number}
     * @memberof RentElementRecord
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof RentElementRecord
     */
    businessVersion: number;
    /**
     * Ссылка: Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof RentElementRecord
     */
    businessAccountId: number;
    /**
     * Маркер того, что запись является набором обязательств
     * @type {boolean}
     * @memberof RentElementRecord
     */
    rentElementsKitMarker: boolean;
    /**
     * 
     * @type {RentElementCommonFieldsObj}
     * @memberof RentElementRecord
     */
    commonFields: RentElementCommonFieldsObj;
    /**
     * Вложенные обязательства (только для набора обязательств)
     * @type {Array<NestedRentElementRecord>}
     * @memberof RentElementRecord
     */
    nestedRentElements?: Array<NestedRentElementRecord>;
    /**
     * Ссылка: идентификатор набора (типа набора)
     * @type {number}
     * @memberof RentElementRecord
     */
    kitId?: number;
    /**
     * Идентификатор проекта поставки или шаблона, для сортировки календаря
     * @type {number}
     * @memberof RentElementRecord
     */
    activityFrameId: number;
    /**
     * Ссылка: идентификатор проекта (обязательно, если не указан шаблон и поставка)
     * @type {number}
     * @memberof RentElementRecord
     */
    projectId?: number;
    /**
     * Выч: короткое наименование проекта (обязательно, если не указан шаблон и поставка)
     * @type {string}
     * @memberof RentElementRecord
     */
    projectShortName?: string;
    /**
     * Выч: проект данного обязательства завершен (обязательно, если не указан шаблон и поставка). Только для определения возможных целевых статусов корректировки
     * @type {boolean}
     * @memberof RentElementRecord
     */
    projectFinished?: boolean;
    /**
     * Ссылка: идентификатор поставки (обязательно, если не указан шаблон и проект)
     * @type {number}
     * @memberof RentElementRecord
     */
    subrentId?: number;
    /**
     * Выч: короткое наименование поставки (обязательно, если не указан шаблон и проект)
     * @type {string}
     * @memberof RentElementRecord
     */
    subrentShortName?: string;
    /**
     * Выч: поставка данного обязательства завершена (обязательно, если не указан шаблон и проект). Только для определения возможных целевых статусов корректировки
     * @type {boolean}
     * @memberof RentElementRecord
     */
    subrentFinished?: boolean;
    /**
     * Ссылка: идентификатор шаблона (обязательно, если не указан проект)
     * @type {number}
     * @memberof RentElementRecord
     */
    templateId?: number;
    /**
     * Выч: короткое наименование шаблона  (обязательно, если не указан проект)
     * @type {string}
     * @memberof RentElementRecord
     */
    templateShortName?: string;
    /**
     * Ссылка: идентификатор арендатора
     * @type {number}
     * @memberof RentElementRecord
     */
    counterpartyId: number;
    /**
     * Выч: короткое наименование арендатора
     * @type {string}
     * @memberof RentElementRecord
     */
    counterpartyShortName: string;
    /**
     * Дата самого раннего завершения периода аренды (для сортировки возвратов в дешборде)
     * @type {Date}
     * @memberof RentElementRecord
     */
    rentPeriodEndDateMinimum: Date;
    /**
     * Количество компоненто в наборе обязательств (заполняется только когда запись описывает набор)
     * @type {number}
     * @memberof RentElementRecord
     */
    rentElementsKitMemberCount?: number;
    /**
     * Состояние проекта
     * @type {boolean}
     * @memberof RentElementRecord
     */
    projectIsOffer?: boolean;
    /**
     * Ценовые схемы компонентов в наборе обязательств (заполняется только когда запись описывает набор)
     * @type {Array<PricingSchemeExternalRepresentationObj>}
     * @memberof RentElementRecord
     */
    rentElementsKitEffectivePricingSchemes?: Array<PricingSchemeExternalRepresentationObj>;
}
/**
 * 
 * @export
 * @interface RentElementRecordAllOf
 */
export interface RentElementRecordAllOf {
    /**
     * Маркер того, что запись является набором обязательств
     * @type {boolean}
     * @memberof RentElementRecordAllOf
     */
    rentElementsKitMarker: boolean;
    /**
     * 
     * @type {RentElementCommonFieldsObj}
     * @memberof RentElementRecordAllOf
     */
    commonFields: RentElementCommonFieldsObj;
    /**
     * Вложенные обязательства (только для набора обязательств)
     * @type {Array<NestedRentElementRecord>}
     * @memberof RentElementRecordAllOf
     */
    nestedRentElements?: Array<NestedRentElementRecord>;
    /**
     * Ссылка: идентификатор набора (типа набора)
     * @type {number}
     * @memberof RentElementRecordAllOf
     */
    kitId?: number;
    /**
     * Идентификатор проекта поставки или шаблона, для сортировки календаря
     * @type {number}
     * @memberof RentElementRecordAllOf
     */
    activityFrameId: number;
    /**
     * Ссылка: идентификатор проекта (обязательно, если не указан шаблон и поставка)
     * @type {number}
     * @memberof RentElementRecordAllOf
     */
    projectId?: number;
    /**
     * Выч: короткое наименование проекта (обязательно, если не указан шаблон и поставка)
     * @type {string}
     * @memberof RentElementRecordAllOf
     */
    projectShortName?: string;
    /**
     * Выч: проект данного обязательства завершен (обязательно, если не указан шаблон и поставка). Только для определения возможных целевых статусов корректировки
     * @type {boolean}
     * @memberof RentElementRecordAllOf
     */
    projectFinished?: boolean;
    /**
     * Ссылка: идентификатор поставки (обязательно, если не указан шаблон и проект)
     * @type {number}
     * @memberof RentElementRecordAllOf
     */
    subrentId?: number;
    /**
     * Выч: короткое наименование поставки (обязательно, если не указан шаблон и проект)
     * @type {string}
     * @memberof RentElementRecordAllOf
     */
    subrentShortName?: string;
    /**
     * Выч: поставка данного обязательства завершена (обязательно, если не указан шаблон и проект). Только для определения возможных целевых статусов корректировки
     * @type {boolean}
     * @memberof RentElementRecordAllOf
     */
    subrentFinished?: boolean;
    /**
     * Ссылка: идентификатор шаблона (обязательно, если не указан проект)
     * @type {number}
     * @memberof RentElementRecordAllOf
     */
    templateId?: number;
    /**
     * Выч: короткое наименование шаблона  (обязательно, если не указан проект)
     * @type {string}
     * @memberof RentElementRecordAllOf
     */
    templateShortName?: string;
    /**
     * Ссылка: идентификатор арендатора
     * @type {number}
     * @memberof RentElementRecordAllOf
     */
    counterpartyId: number;
    /**
     * Выч: короткое наименование арендатора
     * @type {string}
     * @memberof RentElementRecordAllOf
     */
    counterpartyShortName: string;
    /**
     * Дата самого раннего завершения периода аренды (для сортировки возвратов в дешборде)
     * @type {Date}
     * @memberof RentElementRecordAllOf
     */
    rentPeriodEndDateMinimum: Date;
    /**
     * Количество компоненто в наборе обязательств (заполняется только когда запись описывает набор)
     * @type {number}
     * @memberof RentElementRecordAllOf
     */
    rentElementsKitMemberCount?: number;
    /**
     * Состояние проекта
     * @type {boolean}
     * @memberof RentElementRecordAllOf
     */
    projectIsOffer?: boolean;
    /**
     * Ценовые схемы компонентов в наборе обязательств (заполняется только когда запись описывает набор)
     * @type {Array<PricingSchemeExternalRepresentationObj>}
     * @memberof RentElementRecordAllOf
     */
    rentElementsKitEffectivePricingSchemes?: Array<PricingSchemeExternalRepresentationObj>;
}
/**
 * Список записей элементов аренды
 * @export
 * @interface RentElementRecordList
 */
export interface RentElementRecordList {
    /**
     * 
     * @type {ListAttributesInfo}
     * @memberof RentElementRecordList
     */
    listAttributes: ListAttributesInfo;
    /**
     * 
     * @type {Array<RentElementRecord>}
     * @memberof RentElementRecordList
     */
    records: Array<RentElementRecord>;
    /**
     * 
     * @type {RentElementListAggregations}
     * @memberof RentElementRecordList
     */
    aggregations?: RentElementListAggregations;
}
/**
 * 
 * @export
 * @interface RentElementRecordListAllOf
 */
export interface RentElementRecordListAllOf {
    /**
     * 
     * @type {Array<RentElementRecord>}
     * @memberof RentElementRecordListAllOf
     */
    records: Array<RentElementRecord>;
    /**
     * 
     * @type {RentElementListAggregations}
     * @memberof RentElementRecordListAllOf
     */
    aggregations?: RentElementListAggregations;
}
/**
 * Код резолюции элемента аренды, выбор из перечисления
 * @export
 * @enum {string}
 */
export enum RentElementResolutionCodeEnum {
    RETURNEDBROKEN = 'RETURNED_BROKEN',
    LOSTDURINGRENT = 'LOST_DURING_RENT'
}

/**
 * 
 * @export
 * @interface RentElementsKitObjWrite
 */
export interface RentElementsKitObjWrite {
    /**
     * Идентификатор (только если изменение)
     * @type {number}
     * @memberof RentElementsKitObjWrite
     */
    id?: number;
    /**
     * Бизнес версия (только если изменение)
     * @type {number}
     * @memberof RentElementsKitObjWrite
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор набора (типа набора)
     * @type {number}
     * @memberof RentElementsKitObjWrite
     */
    kitId: number;
    /**
     * Список создаваемых элементов
     * @type {Array<RentElementInfoWrite>}
     * @memberof RentElementsKitObjWrite
     */
    rentElements?: Array<RentElementInfoWrite>;
}
/**
 * Вычисляемые показатели аренды на момент запроса
 * @export
 * @interface RentIndicatorsObj
 */
export interface RentIndicatorsObj {
    /**
     * Дата и время окончания последней аренды
     * @type {Date}
     * @memberof RentIndicatorsObj
     */
    lastRentEndDate?: Date;
    /**
     * Дата и время окончания последней субаренды
     * @type {Date}
     * @memberof RentIndicatorsObj
     */
    lastSubrentEndDate?: Date;
    /**
     * Дата и время последней операции
     * @type {Date}
     * @memberof RentIndicatorsObj
     */
    lastOperationDate?: Date;
    /**
     * Дата и время последней активности
     * @type {Date}
     * @memberof RentIndicatorsObj
     */
    lastActivityDate?: Date;
    /**
     * 
     * @type {IndicatorObj}
     * @memberof RentIndicatorsObj
     */
    currentDraft: IndicatorObj;
    /**
     * 
     * @type {IndicatorObj}
     * @memberof RentIndicatorsObj
     */
    currentOrdered: IndicatorObj;
    /**
     * 
     * @type {IndicatorObj}
     * @memberof RentIndicatorsObj
     */
    currentBooked: IndicatorObj;
    /**
     * 
     * @type {IndicatorObj}
     * @memberof RentIndicatorsObj
     */
    currentRent: IndicatorObj;
    /**
     * 
     * @type {IndicatorObj}
     * @memberof RentIndicatorsObj
     */
    totalReturned: IndicatorObj;
    /**
     * 
     * @type {IndicatorObj}
     * @memberof RentIndicatorsObj
     */
    currentSubrentShipmentBooked: IndicatorObj;
    /**
     * 
     * @type {IndicatorObj}
     * @memberof RentIndicatorsObj
     */
    currentSubrent: IndicatorObj;
    /**
     * 
     * @type {IndicatorObj}
     * @memberof RentIndicatorsObj
     */
    totalSubrentReturnedToShipper: IndicatorObj;
    /**
     * 
     * @type {ProblemAndWarningRentIndicatorsObj}
     * @memberof RentIndicatorsObj
     */
    problemsAndWarnings: ProblemAndWarningRentIndicatorsObj;
}
/**
 * Код арендной отрасли
 * @export
 * @enum {string}
 */
export enum RentIndustryCodeEnum {
    AV = 'AV',
    SPORT = 'SPORT',
    CLOTHES = 'CLOTHES',
    PROPS = 'PROPS',
    EVENTS = 'EVENTS',
    HR = 'HR',
    CONSTRUCTION = 'CONSTRUCTION',
    TRANSPORT = 'TRANSPORT',
    OTHER = 'OTHER'
}

/**
 * Цена аренды за единицу времени
 * @export
 * @interface RentPriceForTimeMeasureObj
 */
export interface RentPriceForTimeMeasureObj {
    /**
     * Цена, коп.
     * @type {number}
     * @memberof RentPriceForTimeMeasureObj
     */
    price?: number;
    /**
     * Маркер, указывающий, что это собственная цена, не наследованная от родительской сущности
     * @type {boolean}
     * @memberof RentPriceForTimeMeasureObj
     */
    hasOwn?: boolean;
}
/**
 * Код статуса элемента аренды, выбор из перечисления
 * @export
 * @enum {string}
 */
export enum RentStateCodeEnum {
    ORDERED = 'ORDERED',
    BOOKED = 'BOOKED',
    RENT = 'RENT',
    RETURNED = 'RETURNED',
    RETURNEDBROKEN = 'RETURNED_BROKEN',
    LOSTDURINGRENT = 'LOST_DURING_RENT',
    CANCELED = 'CANCELED',
    DRAFT = 'DRAFT',
    SUBRENTSHIPMENTBOOKED = 'SUBRENT_SHIPMENT_BOOKED',
    SUBRENT = 'SUBRENT',
    SUBRENTRETURNEDTOSHIPPER = 'SUBRENT_RETURNED_TO_SHIPPER',
    SUBRENTCANCELED = 'SUBRENT_CANCELED',
    SUBRENTDRAFT = 'SUBRENT_DRAFT',
    NOTEXISTS = 'NOT_EXISTS',
    MIXED = 'MIXED'
}

/**
 * Условия аренды
 * @export
 * @interface RentTermsObj
 */
export interface RentTermsObj {
    /**
     * Скидка
     * @type {number}
     * @memberof RentTermsObj
     */
    discount: number;
    /**
     * 
     * @type {ShiftsObj}
     * @memberof RentTermsObj
     */
    shifts?: ShiftsObj;
    /**
     * Число смен (для наборов среднее арифм. либо по основному компаненту)
     * @type {number}
     * @memberof RentTermsObj
     */
    shiftCount: number;
    /**
     * Максимальное число смен (только для наборов)
     * @type {number}
     * @memberof RentTermsObj
     */
    shiftCountMax?: number;
    /**
     * Минимальное число смен (только для наборов)
     * @type {number}
     * @memberof RentTermsObj
     */
    shiftCountMin?: number;
    /**
     * Дата начала периода аренды (для набора - самая ранняя дата)
     * @type {Date}
     * @memberof RentTermsObj
     */
    rentPeriodStartDate: Date;
    /**
     * Дата завершения периода аренды (для набора - самая поздняя дата)
     * @type {Date}
     * @memberof RentTermsObj
     */
    rentPeriodEndDate: Date;
}
/**
 * Финансовые поля сущности RenterRecord, которые могут быть выгружены в список Excel
 * @export
 * @enum {string}
 */
export enum RenterFinancialFieldsTypeCode {
    AllProjectsSumAfterTaxes = 'allProjectsSumAfterTaxes',
    AllSubrentsSum = 'allSubrentsSum',
    DebtOfRenter = 'debtOfRenter',
    DebtToSupplier = 'debtToSupplier',
    CurrentBookedSum = 'currentBookedSum',
    CurrentRentSum = 'currentRentSum',
    DefaultDiscount = 'defaultDiscount'
}

/**
 * Арендатор: абстрактное общее состояние для всех операций
 * @export
 * @interface RenterInfoAbstractCommons
 */
export interface RenterInfoAbstractCommons {
    /**
     * Идентификатор
     * @type {number}
     * @memberof RenterInfoAbstractCommons
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof RenterInfoAbstractCommons
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof RenterInfoAbstractCommons
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof RenterInfoAbstractCommons
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof RenterInfoAbstractCommons
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof RenterInfoAbstractCommons
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof RenterInfoAbstractCommons
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof RenterInfoAbstractCommons
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof RenterInfoAbstractCommons
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof RenterInfoAbstractCommons
     */
    businessAccountId?: number;
    /**
     * Информация об изображении аватара
     * @type {ImageObj}
     * @memberof RenterInfoAbstractCommons
     */
    image?: ImageObj;
    /**
     * Ссылка: идентификатор пользователя - сотрудника абонента, ответственного за арендатора
     * @type {number}
     * @memberof RenterInfoAbstractCommons
     */
    assigneeId?: number;
    /**
     * Выч. по ссылке: ФИО ответственного пользователя
     * @type {PersonFullName}
     * @memberof RenterInfoAbstractCommons
     */
    assigneeFullName?: PersonFullName;
    /**
     * 
     * @type {Array<ContactPersonAPIObj>}
     * @memberof RenterInfoAbstractCommons
     */
    contacts: Array<ContactPersonAPIObj>;
    /**
     * Полное официальное наименование
     * @type {string}
     * @memberof RenterInfoAbstractCommons
     */
    fullName?: string;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof RenterInfoAbstractCommons
     */
    shortName: string;
    /**
     * 
     * @type {CounterpartyActivityTypeCodeEnum}
     * @memberof RenterInfoAbstractCommons
     */
    activityTypeCode: CounterpartyActivityTypeCodeEnum;
    /**
     * Скидка по умолчанию
     * @type {number}
     * @memberof RenterInfoAbstractCommons
     */
    defaultDiscount?: number;
    /**
     * Описание
     * @type {string}
     * @memberof RenterInfoAbstractCommons
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface RenterInfoAbstractCommonsAllOf
 */
export interface RenterInfoAbstractCommonsAllOf {
    /**
     * Информация об изображении аватара
     * @type {ImageObj}
     * @memberof RenterInfoAbstractCommonsAllOf
     */
    image?: ImageObj;
    /**
     * Ссылка: идентификатор пользователя - сотрудника абонента, ответственного за арендатора
     * @type {number}
     * @memberof RenterInfoAbstractCommonsAllOf
     */
    assigneeId?: number;
    /**
     * Выч. по ссылке: ФИО ответственного пользователя
     * @type {PersonFullName}
     * @memberof RenterInfoAbstractCommonsAllOf
     */
    assigneeFullName?: PersonFullName;
    /**
     * 
     * @type {Array<ContactPersonAPIObj>}
     * @memberof RenterInfoAbstractCommonsAllOf
     */
    contacts: Array<ContactPersonAPIObj>;
    /**
     * Полное официальное наименование
     * @type {string}
     * @memberof RenterInfoAbstractCommonsAllOf
     */
    fullName?: string;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof RenterInfoAbstractCommonsAllOf
     */
    shortName: string;
    /**
     * 
     * @type {CounterpartyActivityTypeCodeEnum}
     * @memberof RenterInfoAbstractCommonsAllOf
     */
    activityTypeCode: CounterpartyActivityTypeCodeEnum;
    /**
     * Скидка по умолчанию
     * @type {number}
     * @memberof RenterInfoAbstractCommonsAllOf
     */
    defaultDiscount?: number;
    /**
     * Описание
     * @type {string}
     * @memberof RenterInfoAbstractCommonsAllOf
     */
    description?: string;
}
/**
 * Арендатор : абстрактное общее состояние при записи
 * @export
 * @interface RenterInfoAbstractWriteCommons
 */
export interface RenterInfoAbstractWriteCommons {
    /**
     * Идентификатор
     * @type {number}
     * @memberof RenterInfoAbstractWriteCommons
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof RenterInfoAbstractWriteCommons
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof RenterInfoAbstractWriteCommons
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof RenterInfoAbstractWriteCommons
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof RenterInfoAbstractWriteCommons
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof RenterInfoAbstractWriteCommons
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof RenterInfoAbstractWriteCommons
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof RenterInfoAbstractWriteCommons
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof RenterInfoAbstractWriteCommons
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof RenterInfoAbstractWriteCommons
     */
    businessAccountId?: number;
    /**
     * Информация об изображении аватара
     * @type {ImageObj}
     * @memberof RenterInfoAbstractWriteCommons
     */
    image?: ImageObj;
    /**
     * Ссылка: идентификатор пользователя - сотрудника абонента, ответственного за арендатора
     * @type {number}
     * @memberof RenterInfoAbstractWriteCommons
     */
    assigneeId?: number;
    /**
     * Выч. по ссылке: ФИО ответственного пользователя
     * @type {PersonFullName}
     * @memberof RenterInfoAbstractWriteCommons
     */
    assigneeFullName?: PersonFullName;
    /**
     * 
     * @type {Array<ContactPersonAPIObj>}
     * @memberof RenterInfoAbstractWriteCommons
     */
    contacts: Array<ContactPersonAPIObj>;
    /**
     * Полное официальное наименование
     * @type {string}
     * @memberof RenterInfoAbstractWriteCommons
     */
    fullName?: string;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof RenterInfoAbstractWriteCommons
     */
    shortName: string;
    /**
     * 
     * @type {CounterpartyActivityTypeCodeEnum}
     * @memberof RenterInfoAbstractWriteCommons
     */
    activityTypeCode: CounterpartyActivityTypeCodeEnum;
    /**
     * Скидка по умолчанию
     * @type {number}
     * @memberof RenterInfoAbstractWriteCommons
     */
    defaultDiscount?: number;
    /**
     * Описание
     * @type {string}
     * @memberof RenterInfoAbstractWriteCommons
     */
    description?: string;
    /**
     * Ссылки: идентификаторы категорий арендатора
     * @type {Array<number>}
     * @memberof RenterInfoAbstractWriteCommons
     */
    categoryIds?: Array<number>;
    /**
     * Контакты арендатора
     * @type {Array<ContactLinkObjWrite>}
     * @memberof RenterInfoAbstractWriteCommons
     */
    contactLinks: Array<ContactLinkObjWrite>;
}
/**
 * 
 * @export
 * @interface RenterInfoAbstractWriteCommonsAllOf
 */
export interface RenterInfoAbstractWriteCommonsAllOf {
    /**
     * Ссылки: идентификаторы категорий арендатора
     * @type {Array<number>}
     * @memberof RenterInfoAbstractWriteCommonsAllOf
     */
    categoryIds?: Array<number>;
    /**
     * Контакты арендатора
     * @type {Array<ContactLinkObjWrite>}
     * @memberof RenterInfoAbstractWriteCommonsAllOf
     */
    contactLinks: Array<ContactLinkObjWrite>;
}
/**
 * Арендатор : состояние для создания
 * @export
 * @interface RenterInfoCreate
 */
export interface RenterInfoCreate {
    /**
     * Идентификатор
     * @type {number}
     * @memberof RenterInfoCreate
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof RenterInfoCreate
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof RenterInfoCreate
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof RenterInfoCreate
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof RenterInfoCreate
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof RenterInfoCreate
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof RenterInfoCreate
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof RenterInfoCreate
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof RenterInfoCreate
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof RenterInfoCreate
     */
    businessAccountId?: number;
    /**
     * Информация об изображении аватара
     * @type {ImageObj}
     * @memberof RenterInfoCreate
     */
    image?: ImageObj;
    /**
     * Ссылка: идентификатор пользователя - сотрудника абонента, ответственного за арендатора
     * @type {number}
     * @memberof RenterInfoCreate
     */
    assigneeId?: number;
    /**
     * Выч. по ссылке: ФИО ответственного пользователя
     * @type {PersonFullName}
     * @memberof RenterInfoCreate
     */
    assigneeFullName?: PersonFullName;
    /**
     * 
     * @type {Array<ContactPersonAPIObj>}
     * @memberof RenterInfoCreate
     */
    contacts: Array<ContactPersonAPIObj>;
    /**
     * Полное официальное наименование
     * @type {string}
     * @memberof RenterInfoCreate
     */
    fullName?: string;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof RenterInfoCreate
     */
    shortName: string;
    /**
     * 
     * @type {CounterpartyActivityTypeCodeEnum}
     * @memberof RenterInfoCreate
     */
    activityTypeCode: CounterpartyActivityTypeCodeEnum;
    /**
     * Скидка по умолчанию
     * @type {number}
     * @memberof RenterInfoCreate
     */
    defaultDiscount?: number;
    /**
     * Описание
     * @type {string}
     * @memberof RenterInfoCreate
     */
    description?: string;
    /**
     * Ссылки: идентификаторы категорий арендатора
     * @type {Array<number>}
     * @memberof RenterInfoCreate
     */
    categoryIds?: Array<number>;
    /**
     * Контакты арендатора
     * @type {Array<ContactLinkObjWrite>}
     * @memberof RenterInfoCreate
     */
    contactLinks: Array<ContactLinkObjWrite>;
    /**
     * 
     * @type {RenterStateCodeEnum}
     * @memberof RenterInfoCreate
     */
    stateCode: RenterStateCodeEnum;
}
/**
 * 
 * @export
 * @interface RenterInfoCreateAllOf
 */
export interface RenterInfoCreateAllOf {
    /**
     * 
     * @type {RenterStateCodeEnum}
     * @memberof RenterInfoCreateAllOf
     */
    stateCode: RenterStateCodeEnum;
}
/**
 * Арендатор : состояние при чтении
 * @export
 * @interface RenterInfoRead
 */
export interface RenterInfoRead {
    /**
     * Идентификатор
     * @type {number}
     * @memberof RenterInfoRead
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof RenterInfoRead
     */
    businessVersion: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof RenterInfoRead
     */
    creationAuthorId: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof RenterInfoRead
     */
    creationAuthorFullName: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof RenterInfoRead
     */
    creationDate: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof RenterInfoRead
     */
    lastUpdateAuthorId: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof RenterInfoRead
     */
    lastUpdateAuthorFullName: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof RenterInfoRead
     */
    lastUpdateDate: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof RenterInfoRead
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof RenterInfoRead
     */
    businessAccountId: number;
    /**
     * Информация об изображении аватара
     * @type {ImageObj}
     * @memberof RenterInfoRead
     */
    image?: ImageObj;
    /**
     * Ссылка: идентификатор пользователя - сотрудника абонента, ответственного за арендатора
     * @type {number}
     * @memberof RenterInfoRead
     */
    assigneeId?: number;
    /**
     * Выч. по ссылке: ФИО ответственного пользователя
     * @type {PersonFullName}
     * @memberof RenterInfoRead
     */
    assigneeFullName?: PersonFullName;
    /**
     * 
     * @type {Array<ContactPersonAPIObj>}
     * @memberof RenterInfoRead
     */
    contacts: Array<ContactPersonAPIObj>;
    /**
     * Полное официальное наименование
     * @type {string}
     * @memberof RenterInfoRead
     */
    fullName: string;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof RenterInfoRead
     */
    shortName: string;
    /**
     * 
     * @type {CounterpartyActivityTypeCodeEnum}
     * @memberof RenterInfoRead
     */
    activityTypeCode: CounterpartyActivityTypeCodeEnum;
    /**
     * Скидка по умолчанию
     * @type {number}
     * @memberof RenterInfoRead
     */
    defaultDiscount?: number;
    /**
     * Описание
     * @type {string}
     * @memberof RenterInfoRead
     */
    description?: string;
    /**
     * 
     * @type {AttachmentRecordList}
     * @memberof RenterInfoRead
     */
    attachments?: AttachmentRecordList;
    /**
     * 
     * @type {RenterSourceCodeEnum}
     * @memberof RenterInfoRead
     */
    sourceCode: RenterSourceCodeEnum;
    /**
     * Прямые категории арендатора (используется только при чтении)
     * @type {Array<CategoryRecord>}
     * @memberof RenterInfoRead
     */
    categories?: Array<CategoryRecord>;
    /**
     * 
     * @type {RentIndicatorsObj}
     * @memberof RenterInfoRead
     */
    rentIndicators: RentIndicatorsObj;
    /**
     * 
     * @type {RenterStateCodeEnum}
     * @memberof RenterInfoRead
     */
    stateCode: RenterStateCodeEnum;
    /**
     * Коды доступных переходов для данного арендатора и пользователя
     * @type {Array<SimpleTransitionCodeEnum>}
     * @memberof RenterInfoRead
     */
    availableTransitionCodes?: Array<SimpleTransitionCodeEnum>;
    /**
     * Маркер нахождения в архиве
     * @type {boolean}
     * @memberof RenterInfoRead
     */
    archive: boolean;
    /**
     * Может ли быть отправлен в архив (может, когда не является активным)
     * @type {boolean}
     * @memberof RenterInfoRead
     */
    archivable: boolean;
    /**
     * Минимальный применяемый процент налога к проектам арендатора
     * @type {number}
     * @memberof RenterInfoRead
     */
    projectsTaxRateMin: number;
    /**
     * Максимальный применяемый процент налога к проектам арендатора
     * @type {number}
     * @memberof RenterInfoRead
     */
    projectsTaxRateMax: number;
    /**
     * Сумма стоимости всех проектов до применения налога.
     * @type {number}
     * @memberof RenterInfoRead
     */
    allProjectsSumBeforeTaxes: number;
    /**
     * Сумма стоимости всех проектов после применения налога.
     * @type {number}
     * @memberof RenterInfoRead
     */
    allProjectsSumAfterTaxes: number;
    /**
     * Сумма стоимости всех поставок
     * @type {number}
     * @memberof RenterInfoRead
     */
    allSubrentsSum: number;
    /**
     * Сумма подтвержденных полученых платежей от арендатора по всем его проектам в коп.
     * @type {number}
     * @memberof RenterInfoRead
     */
    allProjectsAcceptedPaymentsSum: number;
    /**
     * Число платежей по проекту от арендатора
     * @type {number}
     * @memberof RenterInfoRead
     */
    allProjectsAcceptedPaymentsCount?: number;
    /**
     * Сумма произведенной оплаты поставщику по всем его поставкам в коп.
     * @type {number}
     * @memberof RenterInfoRead
     */
    allSubrentsPaymentsMadeSum: number;
    /**
     * Число платежей по субаренде поставщику
     * @type {number}
     * @memberof RenterInfoRead
     */
    allSubrentsPaymentsMadeCount?: number;
    /**
     * Сумма долга арендатора в коп.
     * @type {number}
     * @memberof RenterInfoRead
     */
    debtOfRenter: number;
    /**
     * Сумма долга перед поставщиком в коп.
     * @type {number}
     * @memberof RenterInfoRead
     */
    debtToSupplier: number;
    /**
     * Число проектов с долгом
     * @type {number}
     * @memberof RenterInfoRead
     */
    projectsWithDebtCount: number;
    /**
     * Число заказов с долгом
     * @type {number}
     * @memberof RenterInfoRead
     */
    ordersWithDebtCount: number;
    /**
     * Число заказов с долгом
     * @type {number}
     * @memberof RenterInfoRead
     */
    subrentsWithDebtCount: number;
    /**
     * Контакты арендатора
     * @type {Array<ContactLinkObjRead>}
     * @memberof RenterInfoRead
     */
    contactLinks?: Array<ContactLinkObjRead>;
    /**
     * Указывает, что у контрагента есть хотя бы один неотмененный проект
     * @type {boolean}
     * @memberof RenterInfoRead
     */
    hasProjects: boolean;
    /**
     * Указывает, что у контрагента есть хотя бы одна неотмененная поставка
     * @type {boolean}
     * @memberof RenterInfoRead
     */
    hasSubrents: boolean;
    /**
     * 
     * @type {LegalDetailsInfoRead}
     * @memberof RenterInfoRead
     */
    legalDetails: LegalDetailsInfoRead;
}
/**
 * 
 * @export
 * @interface RenterInfoReadAllOf
 */
export interface RenterInfoReadAllOf {
    /**
     * 
     * @type {AttachmentRecordList}
     * @memberof RenterInfoReadAllOf
     */
    attachments?: AttachmentRecordList;
    /**
     * 
     * @type {RenterSourceCodeEnum}
     * @memberof RenterInfoReadAllOf
     */
    sourceCode: RenterSourceCodeEnum;
    /**
     * Прямые категории арендатора (используется только при чтении)
     * @type {Array<CategoryRecord>}
     * @memberof RenterInfoReadAllOf
     */
    categories?: Array<CategoryRecord>;
    /**
     * 
     * @type {RentIndicatorsObj}
     * @memberof RenterInfoReadAllOf
     */
    rentIndicators: RentIndicatorsObj;
    /**
     * 
     * @type {RenterStateCodeEnum}
     * @memberof RenterInfoReadAllOf
     */
    stateCode: RenterStateCodeEnum;
    /**
     * Коды доступных переходов для данного арендатора и пользователя
     * @type {Array<SimpleTransitionCodeEnum>}
     * @memberof RenterInfoReadAllOf
     */
    availableTransitionCodes?: Array<SimpleTransitionCodeEnum>;
    /**
     * Маркер нахождения в архиве
     * @type {boolean}
     * @memberof RenterInfoReadAllOf
     */
    archive: boolean;
    /**
     * Может ли быть отправлен в архив (может, когда не является активным)
     * @type {boolean}
     * @memberof RenterInfoReadAllOf
     */
    archivable: boolean;
    /**
     * Минимальный применяемый процент налога к проектам арендатора
     * @type {number}
     * @memberof RenterInfoReadAllOf
     */
    projectsTaxRateMin: number;
    /**
     * Максимальный применяемый процент налога к проектам арендатора
     * @type {number}
     * @memberof RenterInfoReadAllOf
     */
    projectsTaxRateMax: number;
    /**
     * Сумма стоимости всех проектов до применения налога.
     * @type {number}
     * @memberof RenterInfoReadAllOf
     */
    allProjectsSumBeforeTaxes: number;
    /**
     * Сумма стоимости всех проектов после применения налога.
     * @type {number}
     * @memberof RenterInfoReadAllOf
     */
    allProjectsSumAfterTaxes: number;
    /**
     * Сумма стоимости всех поставок
     * @type {number}
     * @memberof RenterInfoReadAllOf
     */
    allSubrentsSum: number;
    /**
     * Сумма подтвержденных полученых платежей от арендатора по всем его проектам в коп.
     * @type {number}
     * @memberof RenterInfoReadAllOf
     */
    allProjectsAcceptedPaymentsSum: number;
    /**
     * Число платежей по проекту от арендатора
     * @type {number}
     * @memberof RenterInfoReadAllOf
     */
    allProjectsAcceptedPaymentsCount?: number;
    /**
     * Сумма произведенной оплаты поставщику по всем его поставкам в коп.
     * @type {number}
     * @memberof RenterInfoReadAllOf
     */
    allSubrentsPaymentsMadeSum: number;
    /**
     * Число платежей по субаренде поставщику
     * @type {number}
     * @memberof RenterInfoReadAllOf
     */
    allSubrentsPaymentsMadeCount?: number;
    /**
     * Сумма долга арендатора в коп.
     * @type {number}
     * @memberof RenterInfoReadAllOf
     */
    debtOfRenter: number;
    /**
     * Сумма долга перед поставщиком в коп.
     * @type {number}
     * @memberof RenterInfoReadAllOf
     */
    debtToSupplier: number;
    /**
     * Число проектов с долгом
     * @type {number}
     * @memberof RenterInfoReadAllOf
     */
    projectsWithDebtCount: number;
    /**
     * Число заказов с долгом
     * @type {number}
     * @memberof RenterInfoReadAllOf
     */
    ordersWithDebtCount: number;
    /**
     * Число заказов с долгом
     * @type {number}
     * @memberof RenterInfoReadAllOf
     */
    subrentsWithDebtCount: number;
    /**
     * Контакты арендатора
     * @type {Array<ContactLinkObjRead>}
     * @memberof RenterInfoReadAllOf
     */
    contactLinks?: Array<ContactLinkObjRead>;
    /**
     * Указывает, что у контрагента есть хотя бы один неотмененный проект
     * @type {boolean}
     * @memberof RenterInfoReadAllOf
     */
    hasProjects: boolean;
    /**
     * Указывает, что у контрагента есть хотя бы одна неотмененная поставка
     * @type {boolean}
     * @memberof RenterInfoReadAllOf
     */
    hasSubrents: boolean;
    /**
     * 
     * @type {LegalDetailsInfoRead}
     * @memberof RenterInfoReadAllOf
     */
    legalDetails: LegalDetailsInfoRead;
}
/**
 * Арендатор : состояние для изменения
 * @export
 * @interface RenterInfoUpdate
 */
export interface RenterInfoUpdate extends RenterInfoAbstractWriteCommons {
}
/**
 * Не финансовые поля сущности RenterRecord, которые могут быть выгружены в список Excel
 * @export
 * @enum {string}
 */
export enum RenterNotFinancialFieldsTypeCode {
    ShortName = 'shortName',
    StateCode = 'stateCode',
    ActivityTypeCode = 'activityTypeCode',
    SourceCode = 'sourceCode',
    PhoneNumber = 'phoneNumber',
    Country = 'country',
    TypeCode = 'typeCode',
    AssigneeShortName = 'assigneeShortName',
    LastActivityDate = 'lastActivityDate'
}

/**
 * Списочное представление арендатора
 * @export
 * @interface RenterRecord
 */
export interface RenterRecord {
    /**
     * Идентификатор
     * @type {number}
     * @memberof RenterRecord
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof RenterRecord
     */
    businessVersion: number;
    /**
     * Ссылка: Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof RenterRecord
     */
    businessAccountId: number;
    /**
     * 
     * @type {RenterSourceCodeEnum}
     * @memberof RenterRecord
     */
    sourceCode: RenterSourceCodeEnum;
    /**
     * Информация об изображении аватара
     * @type {ImageObj}
     * @memberof RenterRecord
     */
    image?: ImageObj;
    /**
     * Короткое наименование или сокращенное ФИО
     * @type {string}
     * @memberof RenterRecord
     */
    shortName: string;
    /**
     * 
     * @type {LegalCountryCodeEnum}
     * @memberof RenterRecord
     */
    country: LegalCountryCodeEnum;
    /**
     * 
     * @type {LegalSubjectTypeCodeEnum}
     * @memberof RenterRecord
     */
    typeCode: LegalSubjectTypeCodeEnum;
    /**
     * 
     * @type {CounterpartyActivityTypeCodeEnum}
     * @memberof RenterRecord
     */
    activityTypeCode: CounterpartyActivityTypeCodeEnum;
    /**
     * 
     * @type {RenterStateCodeEnum}
     * @memberof RenterRecord
     */
    stateCode: RenterStateCodeEnum;
    /**
     * Коды доступных переходов для данного арендатора и пользователя
     * @type {Array<SimpleTransitionCodeEnum>}
     * @memberof RenterRecord
     */
    availableTransitionCodes?: Array<SimpleTransitionCodeEnum>;
    /**
     * Может ли быть отправлен в архив (может, когда не является активным)
     * @type {boolean}
     * @memberof RenterRecord
     */
    archivable: boolean;
    /**
     * Находится ли в архиве
     * @type {boolean}
     * @memberof RenterRecord
     */
    archive: boolean;
    /**
     * Номер телефона контактного лица
     * @type {string}
     * @memberof RenterRecord
     */
    phoneNumber?: string;
    /**
     * Скидка по умолчанию
     * @type {number}
     * @memberof RenterRecord
     */
    defaultDiscount: number;
    /**
     * Ссылка: идентификатор пользователя - сотрудника абонента, ответственного за арендатора
     * @type {number}
     * @memberof RenterRecord
     */
    assigneeId?: number;
    /**
     * Выч. по ссылке: короткое ФИО ответственного пользователя
     * @type {string}
     * @memberof RenterRecord
     */
    assigneeShortName?: string;
    /**
     * Идентификаторы категорий
     * @type {Array<number>}
     * @memberof RenterRecord
     */
    categoryIds: Array<number>;
    /**
     * Идентификатор основной категории
     * @type {number}
     * @memberof RenterRecord
     */
    mainCategoryId?: number;
    /**
     * Наименование основной категории
     * @type {string}
     * @memberof RenterRecord
     */
    mainCategoryName?: string;
    /**
     * 
     * @type {ProblemAndWarningRentIndicatorsRecordObj}
     * @memberof RenterRecord
     */
    problemsAndWarnings: ProblemAndWarningRentIndicatorsRecordObj;
    /**
     * Стоимость всех обязательств в статусе \'В заявке\' в коп.
     * @type {number}
     * @memberof RenterRecord
     */
    currentOrderedSum: number;
    /**
     * Стоимость всех обязательств в статусе бронирование в коп.
     * @type {number}
     * @memberof RenterRecord
     */
    currentBookedSum: number;
    /**
     * Стоимость всех обязательств в статусе аренда на данный момент в коп.
     * @type {number}
     * @memberof RenterRecord
     */
    currentRentSum: number;
    /**
     * Сумма стоимости всех проектов после налогов в коп.
     * @type {number}
     * @memberof RenterRecord
     */
    allProjectsSumAfterTaxes: number;
    /**
     * Сумма стоимости всех поставок в коп.
     * @type {number}
     * @memberof RenterRecord
     */
    allSubrentsSum?: number;
    /**
     * Сумма долга арендатора в коп.
     * @type {number}
     * @memberof RenterRecord
     */
    debtOfRenter?: number;
    /**
     * Сумма долга перед поставщиком в коп.
     * @type {number}
     * @memberof RenterRecord
     */
    debtToSupplier?: number;
    /**
     * Дата и время последней активности (измение сущности либо операция над связанными обязательствами)
     * @type {Date}
     * @memberof RenterRecord
     */
    lastActivityDate: Date;
}
/**
 * 
 * @export
 * @interface RenterRecordAllOf
 */
export interface RenterRecordAllOf {
    /**
     * 
     * @type {RenterSourceCodeEnum}
     * @memberof RenterRecordAllOf
     */
    sourceCode: RenterSourceCodeEnum;
    /**
     * Информация об изображении аватара
     * @type {ImageObj}
     * @memberof RenterRecordAllOf
     */
    image?: ImageObj;
    /**
     * Короткое наименование или сокращенное ФИО
     * @type {string}
     * @memberof RenterRecordAllOf
     */
    shortName: string;
    /**
     * 
     * @type {LegalCountryCodeEnum}
     * @memberof RenterRecordAllOf
     */
    country: LegalCountryCodeEnum;
    /**
     * 
     * @type {LegalSubjectTypeCodeEnum}
     * @memberof RenterRecordAllOf
     */
    typeCode: LegalSubjectTypeCodeEnum;
    /**
     * 
     * @type {CounterpartyActivityTypeCodeEnum}
     * @memberof RenterRecordAllOf
     */
    activityTypeCode: CounterpartyActivityTypeCodeEnum;
    /**
     * 
     * @type {RenterStateCodeEnum}
     * @memberof RenterRecordAllOf
     */
    stateCode: RenterStateCodeEnum;
    /**
     * Коды доступных переходов для данного арендатора и пользователя
     * @type {Array<SimpleTransitionCodeEnum>}
     * @memberof RenterRecordAllOf
     */
    availableTransitionCodes?: Array<SimpleTransitionCodeEnum>;
    /**
     * Может ли быть отправлен в архив (может, когда не является активным)
     * @type {boolean}
     * @memberof RenterRecordAllOf
     */
    archivable: boolean;
    /**
     * Находится ли в архиве
     * @type {boolean}
     * @memberof RenterRecordAllOf
     */
    archive: boolean;
    /**
     * Номер телефона контактного лица
     * @type {string}
     * @memberof RenterRecordAllOf
     */
    phoneNumber?: string;
    /**
     * Скидка по умолчанию
     * @type {number}
     * @memberof RenterRecordAllOf
     */
    defaultDiscount: number;
    /**
     * Ссылка: идентификатор пользователя - сотрудника абонента, ответственного за арендатора
     * @type {number}
     * @memberof RenterRecordAllOf
     */
    assigneeId?: number;
    /**
     * Выч. по ссылке: короткое ФИО ответственного пользователя
     * @type {string}
     * @memberof RenterRecordAllOf
     */
    assigneeShortName?: string;
    /**
     * Идентификаторы категорий
     * @type {Array<number>}
     * @memberof RenterRecordAllOf
     */
    categoryIds: Array<number>;
    /**
     * Идентификатор основной категории
     * @type {number}
     * @memberof RenterRecordAllOf
     */
    mainCategoryId?: number;
    /**
     * Наименование основной категории
     * @type {string}
     * @memberof RenterRecordAllOf
     */
    mainCategoryName?: string;
    /**
     * 
     * @type {ProblemAndWarningRentIndicatorsRecordObj}
     * @memberof RenterRecordAllOf
     */
    problemsAndWarnings: ProblemAndWarningRentIndicatorsRecordObj;
    /**
     * Стоимость всех обязательств в статусе \'В заявке\' в коп.
     * @type {number}
     * @memberof RenterRecordAllOf
     */
    currentOrderedSum: number;
    /**
     * Стоимость всех обязательств в статусе бронирование в коп.
     * @type {number}
     * @memberof RenterRecordAllOf
     */
    currentBookedSum: number;
    /**
     * Стоимость всех обязательств в статусе аренда на данный момент в коп.
     * @type {number}
     * @memberof RenterRecordAllOf
     */
    currentRentSum: number;
    /**
     * Сумма стоимости всех проектов после налогов в коп.
     * @type {number}
     * @memberof RenterRecordAllOf
     */
    allProjectsSumAfterTaxes: number;
    /**
     * Сумма стоимости всех поставок в коп.
     * @type {number}
     * @memberof RenterRecordAllOf
     */
    allSubrentsSum?: number;
    /**
     * Сумма долга арендатора в коп.
     * @type {number}
     * @memberof RenterRecordAllOf
     */
    debtOfRenter?: number;
    /**
     * Сумма долга перед поставщиком в коп.
     * @type {number}
     * @memberof RenterRecordAllOf
     */
    debtToSupplier?: number;
    /**
     * Дата и время последней активности (измение сущности либо операция над связанными обязательствами)
     * @type {Date}
     * @memberof RenterRecordAllOf
     */
    lastActivityDate: Date;
}
/**
 * Список записей арендаторов
 * @export
 * @interface RenterRecordList
 */
export interface RenterRecordList {
    /**
     * 
     * @type {ListAttributesInfo}
     * @memberof RenterRecordList
     */
    listAttributes: ListAttributesInfo;
    /**
     * 
     * @type {Array<RenterRecord>}
     * @memberof RenterRecordList
     */
    records: Array<RenterRecord>;
}
/**
 * 
 * @export
 * @interface RenterRecordListAllOf
 */
export interface RenterRecordListAllOf {
    /**
     * 
     * @type {Array<RenterRecord>}
     * @memberof RenterRecordListAllOf
     */
    records: Array<RenterRecord>;
}
/**
 * Код источника контрагента
 * @export
 * @enum {string}
 */
export enum RenterSourceCodeEnum {
    INTERNAL = 'INTERNAL',
    STOREFRONT = 'STOREFRONT'
}

/**
 * Код статуса контрагента
 * @export
 * @enum {string}
 */
export enum RenterStateCodeEnum {
    NEW = 'NEW',
    ACTIVE = 'ACTIVE',
    BLOCKED = 'BLOCKED'
}

/**
 * Графическая визуализация отчета: базовое абстрактное состояние
 * @export
 * @interface ReportChartVisualizationAbstractCommons
 */
export interface ReportChartVisualizationAbstractCommons {
    /**
     * Идентификатор
     * @type {number}
     * @memberof ReportChartVisualizationAbstractCommons
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof ReportChartVisualizationAbstractCommons
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof ReportChartVisualizationAbstractCommons
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof ReportChartVisualizationAbstractCommons
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof ReportChartVisualizationAbstractCommons
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof ReportChartVisualizationAbstractCommons
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof ReportChartVisualizationAbstractCommons
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof ReportChartVisualizationAbstractCommons
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof ReportChartVisualizationAbstractCommons
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportChartVisualizationAbstractCommons
     */
    name: string;
    /**
     * 
     * @type {VisualizationTypeEnum}
     * @memberof ReportChartVisualizationAbstractCommons
     */
    visualizationType: VisualizationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ReportChartVisualizationAbstractCommons
     */
    settings?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportChartVisualizationAbstractCommons
     */
    source?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportChartVisualizationAbstractCommons
     */
    reportId?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportChartVisualizationAbstractCommons
     */
    description?: string;
    /**
     * 
     * @type {Array<ReportInputArgumentModel>}
     * @memberof ReportChartVisualizationAbstractCommons
     */
    parameters?: Array<ReportInputArgumentModel>;
    /**
     * 
     * @type {number}
     * @memberof ReportChartVisualizationAbstractCommons
     */
    defaultExcelVisualizationId?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportChartVisualizationAbstractCommons
     */
    preprocessor?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportChartVisualizationAbstractCommons
     */
    handlers?: string;
}
/**
 * 
 * @export
 * @interface ReportChartVisualizationAbstractCommonsAllOf
 */
export interface ReportChartVisualizationAbstractCommonsAllOf {
    /**
     * 
     * @type {string}
     * @memberof ReportChartVisualizationAbstractCommonsAllOf
     */
    name: string;
    /**
     * 
     * @type {VisualizationTypeEnum}
     * @memberof ReportChartVisualizationAbstractCommonsAllOf
     */
    visualizationType: VisualizationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ReportChartVisualizationAbstractCommonsAllOf
     */
    settings?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportChartVisualizationAbstractCommonsAllOf
     */
    source?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportChartVisualizationAbstractCommonsAllOf
     */
    reportId?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportChartVisualizationAbstractCommonsAllOf
     */
    description?: string;
    /**
     * 
     * @type {Array<ReportInputArgumentModel>}
     * @memberof ReportChartVisualizationAbstractCommonsAllOf
     */
    parameters?: Array<ReportInputArgumentModel>;
    /**
     * 
     * @type {number}
     * @memberof ReportChartVisualizationAbstractCommonsAllOf
     */
    defaultExcelVisualizationId?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportChartVisualizationAbstractCommonsAllOf
     */
    preprocessor?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportChartVisualizationAbstractCommonsAllOf
     */
    handlers?: string;
}
/**
 * Графическая визуализация отчета: состояние создания
 * @export
 * @interface ReportChartVisualizationInfoCreate
 */
export interface ReportChartVisualizationInfoCreate extends ReportChartVisualizationAbstractCommons {
}
/**
 * Графическая визуализация отчета: состояние чтения
 * @export
 * @interface ReportChartVisualizationInfoRead
 */
export interface ReportChartVisualizationInfoRead {
    /**
     * Идентификатор
     * @type {number}
     * @memberof ReportChartVisualizationInfoRead
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof ReportChartVisualizationInfoRead
     */
    businessVersion: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof ReportChartVisualizationInfoRead
     */
    creationAuthorId: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof ReportChartVisualizationInfoRead
     */
    creationAuthorFullName: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof ReportChartVisualizationInfoRead
     */
    creationDate: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof ReportChartVisualizationInfoRead
     */
    lastUpdateAuthorId: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof ReportChartVisualizationInfoRead
     */
    lastUpdateAuthorFullName: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof ReportChartVisualizationInfoRead
     */
    lastUpdateDate: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof ReportChartVisualizationInfoRead
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportChartVisualizationInfoRead
     */
    name: string;
    /**
     * 
     * @type {VisualizationTypeEnum}
     * @memberof ReportChartVisualizationInfoRead
     */
    visualizationType: VisualizationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ReportChartVisualizationInfoRead
     */
    settings?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportChartVisualizationInfoRead
     */
    source?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportChartVisualizationInfoRead
     */
    reportId?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportChartVisualizationInfoRead
     */
    description?: string;
    /**
     * 
     * @type {Array<ReportInputArgumentModel>}
     * @memberof ReportChartVisualizationInfoRead
     */
    parameters?: Array<ReportInputArgumentModel>;
    /**
     * 
     * @type {number}
     * @memberof ReportChartVisualizationInfoRead
     */
    defaultExcelVisualizationId?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportChartVisualizationInfoRead
     */
    preprocessor?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportChartVisualizationInfoRead
     */
    handlers?: string;
    /**
     * 
     * @type {ReportTypeCodeEnum}
     * @memberof ReportChartVisualizationInfoRead
     */
    reportType: ReportTypeCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof ReportChartVisualizationInfoRead
     */
    reportConfigurationName: string;
    /**
     * Содаржит финансовую информацию, значение берется из ReportConfiguration
     * @type {boolean}
     * @memberof ReportChartVisualizationInfoRead
     */
    financial: boolean;
}
/**
 * 
 * @export
 * @interface ReportChartVisualizationInfoReadAllOf
 */
export interface ReportChartVisualizationInfoReadAllOf {
    /**
     * 
     * @type {ReportTypeCodeEnum}
     * @memberof ReportChartVisualizationInfoReadAllOf
     */
    reportType: ReportTypeCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof ReportChartVisualizationInfoReadAllOf
     */
    reportConfigurationName: string;
    /**
     * Содаржит финансовую информацию, значение берется из ReportConfiguration
     * @type {boolean}
     * @memberof ReportChartVisualizationInfoReadAllOf
     */
    financial: boolean;
}
/**
 * Графическая визуализация отчета: состояние изменения
 * @export
 * @interface ReportChartVisualizationInfoUpdate
 */
export interface ReportChartVisualizationInfoUpdate {
    /**
     * Идентификатор
     * @type {number}
     * @memberof ReportChartVisualizationInfoUpdate
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof ReportChartVisualizationInfoUpdate
     */
    businessVersion: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof ReportChartVisualizationInfoUpdate
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof ReportChartVisualizationInfoUpdate
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof ReportChartVisualizationInfoUpdate
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof ReportChartVisualizationInfoUpdate
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof ReportChartVisualizationInfoUpdate
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof ReportChartVisualizationInfoUpdate
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof ReportChartVisualizationInfoUpdate
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportChartVisualizationInfoUpdate
     */
    name: string;
    /**
     * 
     * @type {VisualizationTypeEnum}
     * @memberof ReportChartVisualizationInfoUpdate
     */
    visualizationType: VisualizationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ReportChartVisualizationInfoUpdate
     */
    settings?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportChartVisualizationInfoUpdate
     */
    source?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportChartVisualizationInfoUpdate
     */
    reportId: number;
    /**
     * 
     * @type {string}
     * @memberof ReportChartVisualizationInfoUpdate
     */
    description?: string;
    /**
     * 
     * @type {Array<ReportInputArgumentModel>}
     * @memberof ReportChartVisualizationInfoUpdate
     */
    parameters?: Array<ReportInputArgumentModel>;
    /**
     * 
     * @type {number}
     * @memberof ReportChartVisualizationInfoUpdate
     */
    defaultExcelVisualizationId?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportChartVisualizationInfoUpdate
     */
    preprocessor?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportChartVisualizationInfoUpdate
     */
    handlers?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportChartVisualizationInfoUpdate
     */
    configuredVariableValues?: string;
}
/**
 * 
 * @export
 * @interface ReportChartVisualizationInfoUpdateAllOf
 */
export interface ReportChartVisualizationInfoUpdateAllOf {
    /**
     * 
     * @type {string}
     * @memberof ReportChartVisualizationInfoUpdateAllOf
     */
    configuredVariableValues?: string;
}
/**
 * Графическая визуализация отчета: состояние списочного представление
 * @export
 * @interface ReportChartVisualizationRecord
 */
export interface ReportChartVisualizationRecord {
    /**
     * Идентификатор
     * @type {number}
     * @memberof ReportChartVisualizationRecord
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof ReportChartVisualizationRecord
     */
    businessVersion: number;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof ReportChartVisualizationRecord
     */
    creationDate: Date;
    /**
     * 
     * @type {ReportTypeCodeEnum}
     * @memberof ReportChartVisualizationRecord
     */
    reportType: ReportTypeCodeEnum;
    /**
     * Содаржит финансовую информацию, значение берется из ReportConfiguration
     * @type {boolean}
     * @memberof ReportChartVisualizationRecord
     */
    financial: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReportChartVisualizationRecord
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ReportChartVisualizationRecord
     */
    reportConfigurationName?: string;
    /**
     * 
     * @type {VisualizationTypeEnum}
     * @memberof ReportChartVisualizationRecord
     */
    visualizationType: VisualizationTypeEnum;
}
/**
 * 
 * @export
 * @interface ReportChartVisualizationRecordAllOf
 */
export interface ReportChartVisualizationRecordAllOf {
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof ReportChartVisualizationRecordAllOf
     */
    creationDate: Date;
    /**
     * 
     * @type {ReportTypeCodeEnum}
     * @memberof ReportChartVisualizationRecordAllOf
     */
    reportType: ReportTypeCodeEnum;
    /**
     * Содаржит финансовую информацию, значение берется из ReportConfiguration
     * @type {boolean}
     * @memberof ReportChartVisualizationRecordAllOf
     */
    financial: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReportChartVisualizationRecordAllOf
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ReportChartVisualizationRecordAllOf
     */
    reportConfigurationName?: string;
    /**
     * 
     * @type {VisualizationTypeEnum}
     * @memberof ReportChartVisualizationRecordAllOf
     */
    visualizationType: VisualizationTypeEnum;
}
/**
 * Список записей графической визуализации отчета
 * @export
 * @interface ReportChartVisualizationRecordList
 */
export interface ReportChartVisualizationRecordList {
    /**
     * 
     * @type {ListAttributesInfo}
     * @memberof ReportChartVisualizationRecordList
     */
    listAttributes: ListAttributesInfo;
    /**
     * 
     * @type {Array<ReportChartVisualizationRecord>}
     * @memberof ReportChartVisualizationRecordList
     */
    records: Array<ReportChartVisualizationRecord>;
}
/**
 * 
 * @export
 * @interface ReportChartVisualizationRecordListAllOf
 */
export interface ReportChartVisualizationRecordListAllOf {
    /**
     * 
     * @type {Array<ReportChartVisualizationRecord>}
     * @memberof ReportChartVisualizationRecordListAllOf
     */
    records: Array<ReportChartVisualizationRecord>;
}
/**
 * Конфигурация отчета: абстрактное состояние с наименованием и описанием
 * @export
 * @interface ReportConfigurationAbstractCommons
 */
export interface ReportConfigurationAbstractCommons {
    /**
     * Идентификатор
     * @type {number}
     * @memberof ReportConfigurationAbstractCommons
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof ReportConfigurationAbstractCommons
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof ReportConfigurationAbstractCommons
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof ReportConfigurationAbstractCommons
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof ReportConfigurationAbstractCommons
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof ReportConfigurationAbstractCommons
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof ReportConfigurationAbstractCommons
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof ReportConfigurationAbstractCommons
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof ReportConfigurationAbstractCommons
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportConfigurationAbstractCommons
     */
    localizationSettings?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportConfigurationAbstractCommons
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ReportConfigurationAbstractCommons
     */
    description?: string;
    /**
     * 
     * @type {ReportTypeCodeEnum}
     * @memberof ReportConfigurationAbstractCommons
     */
    reportType: ReportTypeCodeEnum;
    /**
     * Содаржит финансовую информацию
     * @type {boolean}
     * @memberof ReportConfigurationAbstractCommons
     */
    financial?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ReportConfigurationAbstractCommons
     */
    businessAccountId?: number;
}
/**
 * 
 * @export
 * @interface ReportConfigurationAbstractCommonsAllOf
 */
export interface ReportConfigurationAbstractCommonsAllOf {
    /**
     * 
     * @type {string}
     * @memberof ReportConfigurationAbstractCommonsAllOf
     */
    localizationSettings?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportConfigurationAbstractCommonsAllOf
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ReportConfigurationAbstractCommonsAllOf
     */
    description?: string;
    /**
     * 
     * @type {ReportTypeCodeEnum}
     * @memberof ReportConfigurationAbstractCommonsAllOf
     */
    reportType: ReportTypeCodeEnum;
    /**
     * Содаржит финансовую информацию
     * @type {boolean}
     * @memberof ReportConfigurationAbstractCommonsAllOf
     */
    financial?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ReportConfigurationAbstractCommonsAllOf
     */
    businessAccountId?: number;
}
/**
 * Конфигурация отчета: состояние при создании
 * @export
 * @interface ReportConfigurationInfoCreate
 */
export interface ReportConfigurationInfoCreate extends ReportConfigurationAbstractCommons {
}
/**
 * Конфигурация отчета: состояние чтения
 * @export
 * @interface ReportConfigurationInfoRead
 */
export interface ReportConfigurationInfoRead {
    /**
     * Идентификатор
     * @type {number}
     * @memberof ReportConfigurationInfoRead
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof ReportConfigurationInfoRead
     */
    businessVersion: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof ReportConfigurationInfoRead
     */
    creationAuthorId: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof ReportConfigurationInfoRead
     */
    creationAuthorFullName: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof ReportConfigurationInfoRead
     */
    creationDate: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof ReportConfigurationInfoRead
     */
    lastUpdateAuthorId: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof ReportConfigurationInfoRead
     */
    lastUpdateAuthorFullName: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof ReportConfigurationInfoRead
     */
    lastUpdateDate: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof ReportConfigurationInfoRead
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportConfigurationInfoRead
     */
    localizationSettings?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportConfigurationInfoRead
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ReportConfigurationInfoRead
     */
    description?: string;
    /**
     * 
     * @type {ReportTypeCodeEnum}
     * @memberof ReportConfigurationInfoRead
     */
    reportType: ReportTypeCodeEnum;
    /**
     * Содаржит финансовую информацию
     * @type {boolean}
     * @memberof ReportConfigurationInfoRead
     */
    financial: boolean;
    /**
     * 
     * @type {number}
     * @memberof ReportConfigurationInfoRead
     */
    businessAccountId?: number;
    /**
     * 
     * @type {Array<ReportExcelVisualizationInfoRead>}
     * @memberof ReportConfigurationInfoRead
     */
    views?: Array<ReportExcelVisualizationInfoRead>;
}
/**
 * 
 * @export
 * @interface ReportConfigurationInfoReadAllOf
 */
export interface ReportConfigurationInfoReadAllOf {
    /**
     * 
     * @type {Array<ReportExcelVisualizationInfoRead>}
     * @memberof ReportConfigurationInfoReadAllOf
     */
    views?: Array<ReportExcelVisualizationInfoRead>;
}
/**
 * Конфигурация отчета: состояние изменения
 * @export
 * @interface ReportConfigurationInfoUpdate
 */
export interface ReportConfigurationInfoUpdate extends ReportConfigurationAbstractCommons {
}
/**
 * Конфигурация отчета: состояние списочного представления
 * @export
 * @interface ReportConfigurationRecord
 */
export interface ReportConfigurationRecord {
    /**
     * Идентификатор
     * @type {number}
     * @memberof ReportConfigurationRecord
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof ReportConfigurationRecord
     */
    businessVersion: number;
    /**
     * 
     * @type {string}
     * @memberof ReportConfigurationRecord
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ReportConfigurationRecord
     */
    description?: string;
    /**
     * 
     * @type {ReportTypeCodeEnum}
     * @memberof ReportConfigurationRecord
     */
    reportType: ReportTypeCodeEnum;
    /**
     * Содаржит финансовую информацию
     * @type {boolean}
     * @memberof ReportConfigurationRecord
     */
    financial: boolean;
    /**
     * 
     * @type {ReportExcelVisualizationRecordList}
     * @memberof ReportConfigurationRecord
     */
    views: ReportExcelVisualizationRecordList;
}
/**
 * 
 * @export
 * @interface ReportConfigurationRecordAllOf
 */
export interface ReportConfigurationRecordAllOf {
    /**
     * 
     * @type {string}
     * @memberof ReportConfigurationRecordAllOf
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ReportConfigurationRecordAllOf
     */
    description?: string;
    /**
     * 
     * @type {ReportTypeCodeEnum}
     * @memberof ReportConfigurationRecordAllOf
     */
    reportType: ReportTypeCodeEnum;
    /**
     * Содаржит финансовую информацию
     * @type {boolean}
     * @memberof ReportConfigurationRecordAllOf
     */
    financial: boolean;
    /**
     * 
     * @type {ReportExcelVisualizationRecordList}
     * @memberof ReportConfigurationRecordAllOf
     */
    views: ReportExcelVisualizationRecordList;
}
/**
 * Список представлений отчетов
 * @export
 * @interface ReportConfigurationRecordList
 */
export interface ReportConfigurationRecordList {
    /**
     * 
     * @type {ListAttributesInfo}
     * @memberof ReportConfigurationRecordList
     */
    listAttributes: ListAttributesInfo;
    /**
     * 
     * @type {Array<ReportConfigurationRecord>}
     * @memberof ReportConfigurationRecordList
     */
    records: Array<ReportConfigurationRecord>;
}
/**
 * 
 * @export
 * @interface ReportConfigurationRecordListAllOf
 */
export interface ReportConfigurationRecordListAllOf {
    /**
     * 
     * @type {Array<ReportConfigurationRecord>}
     * @memberof ReportConfigurationRecordListAllOf
     */
    records: Array<ReportConfigurationRecord>;
}
/**
 * Эксель визуализация отчета: базовая абстрактное состояние
 * @export
 * @interface ReportExcelVisualizationAbstractCommons
 */
export interface ReportExcelVisualizationAbstractCommons {
    /**
     * Идентификатор
     * @type {number}
     * @memberof ReportExcelVisualizationAbstractCommons
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof ReportExcelVisualizationAbstractCommons
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof ReportExcelVisualizationAbstractCommons
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof ReportExcelVisualizationAbstractCommons
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof ReportExcelVisualizationAbstractCommons
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof ReportExcelVisualizationAbstractCommons
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof ReportExcelVisualizationAbstractCommons
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof ReportExcelVisualizationAbstractCommons
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof ReportExcelVisualizationAbstractCommons
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportExcelVisualizationAbstractCommons
     */
    shortName?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportExcelVisualizationAbstractCommons
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportExcelVisualizationAbstractCommons
     */
    reportId?: number;
}
/**
 * 
 * @export
 * @interface ReportExcelVisualizationAbstractCommonsAllOf
 */
export interface ReportExcelVisualizationAbstractCommonsAllOf {
    /**
     * 
     * @type {string}
     * @memberof ReportExcelVisualizationAbstractCommonsAllOf
     */
    shortName?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportExcelVisualizationAbstractCommonsAllOf
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportExcelVisualizationAbstractCommonsAllOf
     */
    reportId?: number;
}
/**
 * Эксель визуализация отчета: состояние создания
 * @export
 * @interface ReportExcelVisualizationInfoCreate
 */
export interface ReportExcelVisualizationInfoCreate extends ReportExcelVisualizationAbstractCommons {
}
/**
 * Эксель визуализация отчета: состояние чтения
 * @export
 * @interface ReportExcelVisualizationInfoRead
 */
export interface ReportExcelVisualizationInfoRead extends ReportExcelVisualizationAbstractCommons {
}
/**
 * Эксель визуализация отчета: состояние изменения
 * @export
 * @interface ReportExcelVisualizationInfoUpdate
 */
export interface ReportExcelVisualizationInfoUpdate extends ReportExcelVisualizationAbstractCommons {
}
/**
 * Эксель визуализация отчета: списочное представление
 * @export
 * @interface ReportExcelVisualizationRecord
 */
export interface ReportExcelVisualizationRecord {
    /**
     * Идентификатор
     * @type {number}
     * @memberof ReportExcelVisualizationRecord
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof ReportExcelVisualizationRecord
     */
    businessVersion: number;
    /**
     * 
     * @type {string}
     * @memberof ReportExcelVisualizationRecord
     */
    shortName: string;
    /**
     * 
     * @type {string}
     * @memberof ReportExcelVisualizationRecord
     */
    description?: string;
    /**
     * 
     * @type {ReportTypeCodeEnum}
     * @memberof ReportExcelVisualizationRecord
     */
    reportType: ReportTypeCodeEnum;
}
/**
 * 
 * @export
 * @interface ReportExcelVisualizationRecordAllOf
 */
export interface ReportExcelVisualizationRecordAllOf {
    /**
     * 
     * @type {string}
     * @memberof ReportExcelVisualizationRecordAllOf
     */
    shortName: string;
    /**
     * 
     * @type {string}
     * @memberof ReportExcelVisualizationRecordAllOf
     */
    description?: string;
    /**
     * 
     * @type {ReportTypeCodeEnum}
     * @memberof ReportExcelVisualizationRecordAllOf
     */
    reportType: ReportTypeCodeEnum;
}
/**
 * Список записей эксель визуализации отчета
 * @export
 * @interface ReportExcelVisualizationRecordList
 */
export interface ReportExcelVisualizationRecordList {
    /**
     * 
     * @type {ListAttributesInfo}
     * @memberof ReportExcelVisualizationRecordList
     */
    listAttributes: ListAttributesInfo;
    /**
     * 
     * @type {Array<ReportExcelVisualizationRecord>}
     * @memberof ReportExcelVisualizationRecordList
     */
    records: Array<ReportExcelVisualizationRecord>;
}
/**
 * 
 * @export
 * @interface ReportExcelVisualizationRecordListAllOf
 */
export interface ReportExcelVisualizationRecordListAllOf {
    /**
     * 
     * @type {Array<ReportExcelVisualizationRecord>}
     * @memberof ReportExcelVisualizationRecordListAllOf
     */
    records: Array<ReportExcelVisualizationRecord>;
}
/**
 * Модель параметра отчета
 * @export
 * @interface ReportInputArgumentModel
 */
export interface ReportInputArgumentModel {
    /**
     * 
     * @type {string}
     * @memberof ReportInputArgumentModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ReportInputArgumentModel
     */
    alias: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReportInputArgumentModel
     */
    isReferenceToEntity: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReportInputArgumentModel
     */
    referencedTable?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportInputArgumentModel
     */
    referencedTableColumnName?: string;
    /**
     * 
     * @type {DataTypeEnum}
     * @memberof ReportInputArgumentModel
     */
    dataType: DataTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ReportInputArgumentModel
     */
    required: boolean;
    /**
     * 
     * @type {object}
     * @memberof ReportInputArgumentModel
     */
    placeholder?: object;
    /**
     * 
     * @type {Array<ReportInputArgumentModelOptions>}
     * @memberof ReportInputArgumentModel
     */
    options?: Array<ReportInputArgumentModelOptions>;
}
/**
 * 
 * @export
 * @interface ReportInputArgumentModelOptions
 */
export interface ReportInputArgumentModelOptions {
    /**
     * 
     * @type {string}
     * @memberof ReportInputArgumentModelOptions
     */
    alias: string;
    /**
     * 
     * @type {string}
     * @memberof ReportInputArgumentModelOptions
     */
    value: string;
}
/**
 * Значение параметра отчета
 * @export
 * @interface ReportInputParameter
 */
export interface ReportInputParameter {
    /**
     * 
     * @type {string}
     * @memberof ReportInputParameter
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ReportInputParameter
     */
    value: string;
}
/**
 * Список значений параметров отчета
 * @export
 * @interface ReportInputParameters
 */
export interface ReportInputParameters {
    /**
     * 
     * @type {Array<ReportInputParameter>}
     * @memberof ReportInputParameters
     */
    parameters?: Array<ReportInputParameter>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ReportTypeCodeEnum {
    COMMON = 'COMMON',
    CUSTOM = 'CUSTOM'
}

/**
 * Роли и права в заданном контексте
 * @export
 * @interface RolesAndPermissionsObj
 */
export interface RolesAndPermissionsObj {
    /**
     * Глобальные роли
     * @type {Array<GlobalRoleCodeEnum>}
     * @memberof RolesAndPermissionsObj
     */
    globalRoles: Array<GlobalRoleCodeEnum>;
    /**
     * Глобальные разрешения
     * @type {Array<GlobalPermissionCodeEnum>}
     * @memberof RolesAndPermissionsObj
     */
    globalPermissions: Array<GlobalPermissionCodeEnum>;
    /**
     * Роли назначенные непосредственно в данном бизнес аккаунте
     * @type {Array<BusinessAccountRoleCodeEnum>}
     * @memberof RolesAndPermissionsObj
     */
    businessAccountRoles?: Array<BusinessAccountRoleCodeEnum>;
    /**
     * Разрешения назначенные непосредственно в данном бизнес аккаунте
     * @type {Array<BusinessAccountPermissionCodeEnum>}
     * @memberof RolesAndPermissionsObj
     */
    businessAccountPermissions?: Array<BusinessAccountPermissionCodeEnum>;
}
/**
 * Правило огругления числа смен при рассчете их из длительности периода аренды
 * @export
 * @enum {string}
 */
export enum ShiftCountRoundingTypeCodeEnum {
    UP = 'UP',
    DOWN = 'DOWN',
    ROUNDHALFUP = 'ROUND_HALF_UP',
    FRACTIONSECONDSIGNROUNDHALFUP = 'FRACTION_SECOND_SIGN_ROUND_HALF_UP'
}

/**
 * Описание календарных смен (смен которые содержатся в периоде при рассчете по умолчанию)
 * @export
 * @interface ShiftsObj
 */
export interface ShiftsObj {
    /**
     * Число смен (для наборов среднее арифм. либо по основному компаненту)
     * @type {number}
     * @memberof ShiftsObj
     */
    shiftCount: number;
    /**
     * Максимальное число смен (только для наборов)
     * @type {number}
     * @memberof ShiftsObj
     */
    shiftCountMax: number;
    /**
     * Минимальное число смен (только для наборов)
     * @type {number}
     * @memberof ShiftsObj
     */
    shiftCountMin: number;
}
/**
 * 
 * @export
 * @interface SimpleCategoryObj
 */
export interface SimpleCategoryObj {
    /**
     * Идентификатор
     * @type {number}
     * @memberof SimpleCategoryObj
     */
    id: number;
    /**
     * Наименование
     * @type {string}
     * @memberof SimpleCategoryObj
     */
    name: string;
}
/**
 * Значение поля - состояние чтения
 * @export
 * @interface SimpleCustomFieldValueObjRead
 */
export interface SimpleCustomFieldValueObjRead {
    /**
     * Полное наименование поля
     * @type {string}
     * @memberof SimpleCustomFieldValueObjRead
     */
    name: string;
    /**
     * 
     * @type {CustomFieldDataTypeCodeEnum}
     * @memberof SimpleCustomFieldValueObjRead
     */
    typeCode: CustomFieldDataTypeCodeEnum;
    /**
     * Значения в строковом представлении
     * @type {Array<string>}
     * @memberof SimpleCustomFieldValueObjRead
     */
    values?: Array<string>;
}
/**
 * Простая запись для выпадающего списка сущностей или других целей
 * @export
 * @interface SimpleEntityRecord
 */
export interface SimpleEntityRecord {
    /**
     * Идентификатор
     * @type {number}
     * @memberof SimpleEntityRecord
     */
    id: number;
    /**
     * Наименование
     * @type {string}
     * @memberof SimpleEntityRecord
     */
    title: string;
}
/**
 * Список простых записей
 * @export
 * @interface SimpleEntityRecordList
 */
export interface SimpleEntityRecordList {
    /**
     * 
     * @type {ListAttributesInfo}
     * @memberof SimpleEntityRecordList
     */
    listAttributes: ListAttributesInfo;
    /**
     * 
     * @type {Array<SimpleEntityRecord>}
     * @memberof SimpleEntityRecordList
     */
    records: Array<SimpleEntityRecord>;
}
/**
 * Операция: состояние при создании
 * @export
 * @interface SimpleOrderInfoCreate
 */
export interface SimpleOrderInfoCreate {
    /**
     * 
     * @type {ProjectInfoCreate}
     * @memberof SimpleOrderInfoCreate
     */
    projectInfo: ProjectInfoCreate;
    /**
     * 
     * @type {OperationInfoCreate}
     * @memberof SimpleOrderInfoCreate
     */
    operationInfo: OperationInfoCreate;
}
/**
 * Операция: состояние при изменении
 * @export
 * @interface SimpleOrderInfoUpdate
 */
export interface SimpleOrderInfoUpdate {
    /**
     * 
     * @type {ProjectInfoUpdate}
     * @memberof SimpleOrderInfoUpdate
     */
    projectInfo: ProjectInfoUpdate;
    /**
     * 
     * @type {OperationInfoCreate}
     * @memberof SimpleOrderInfoUpdate
     */
    operationInfo: OperationInfoCreate;
}
/**
 * Возможные коды для простых переходов - активировать и заблокировать
 * @export
 * @enum {string}
 */
export enum SimpleTransitionCodeEnum {
    ACTIVATE = 'ACTIVATE',
    BLOCK = 'BLOCK'
}

/**
 * Дерево простых записей с уникальными кодами
 * @export
 * @interface SimpleTreeEntityRecord
 */
export interface SimpleTreeEntityRecord {
    /**
     * Идентификатор
     * @type {number}
     * @memberof SimpleTreeEntityRecord
     */
    id: number;
    /**
     * Наименование
     * @type {string}
     * @memberof SimpleTreeEntityRecord
     */
    title: string;
    /**
     * Уникальный латинский код
     * @type {string}
     * @memberof SimpleTreeEntityRecord
     */
    code: string;
    /**
     * 
     * @type {Array<SimpleTreeEntityRecord>}
     * @memberof SimpleTreeEntityRecord
     */
    children?: Array<SimpleTreeEntityRecord>;
}
/**
 * Список деревьев простых записей с уникальными кодами
 * @export
 * @interface SimpleTreeEntityRecordList
 */
export interface SimpleTreeEntityRecordList {
    /**
     * 
     * @type {ListAttributesInfo}
     * @memberof SimpleTreeEntityRecordList
     */
    listAttributes?: ListAttributesInfo;
    /**
     * 
     * @type {Array<SimpleTreeEntityRecord>}
     * @memberof SimpleTreeEntityRecordList
     */
    records: Array<SimpleTreeEntityRecord>;
}
/**
 * Информация о социальной сети
 * @export
 * @interface SocialNetworkLinkAbstractInfo
 */
export interface SocialNetworkLinkAbstractInfo {
    /**
     * 
     * @type {SocialNetworkTypeCodeEnum}
     * @memberof SocialNetworkLinkAbstractInfo
     */
    type: SocialNetworkTypeCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof SocialNetworkLinkAbstractInfo
     */
    value: string;
}
/**
 * Тип социальной сети
 * @export
 * @enum {string}
 */
export enum SocialNetworkTypeCodeEnum {
    FACEBOOK = 'FACEBOOK',
    VK = 'VK',
    INSTAGRAM = 'INSTAGRAM',
    TELEGRAM = 'TELEGRAM',
    INTERNETPAGE = 'INTERNET_PAGE'
}

/**
 * 
 * @export
 * @interface SsoClientOrganizationInfoRead
 */
export interface SsoClientOrganizationInfoRead {
    /**
     * Строковый идентификатор организации, например my_shop
     * @type {string}
     * @memberof SsoClientOrganizationInfoRead
     */
    clientId: string;
    /**
     * Секретная часть ключа для авторизации
     * @type {string}
     * @memberof SsoClientOrganizationInfoRead
     */
    secret: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof SsoClientOrganizationInfoRead
     */
    businessAccountId?: number;
}
/**
 * 
 * @export
 * @interface SsoClientOrganizationInfoWrite
 */
export interface SsoClientOrganizationInfoWrite {
    /**
     * Корневой адрес расположения витрины
     * @type {string}
     * @memberof SsoClientOrganizationInfoWrite
     */
    rootURL: string;
}
/**
 * 
 * @export
 * @interface StorefrontBusinessAccountInfoObj
 */
export interface StorefrontBusinessAccountInfoObj {
    /**
     * Короткое наименование
     * @type {string}
     * @memberof StorefrontBusinessAccountInfoObj
     */
    shortName: string;
    /**
     * Полное официальное наименование
     * @type {string}
     * @memberof StorefrontBusinessAccountInfoObj
     */
    fullName?: string;
    /**
     * Описание
     * @type {string}
     * @memberof StorefrontBusinessAccountInfoObj
     */
    description?: string;
    /**
     * Информация об изображении аватара
     * @type {ImageObj}
     * @memberof StorefrontBusinessAccountInfoObj
     */
    image?: ImageObj;
    /**
     * 
     * @type {ContactPersonAPIObj}
     * @memberof StorefrontBusinessAccountInfoObj
     */
    contact: ContactPersonAPIObj;
}
/**
 * Объект для чтения информации о витрине
 * @export
 * @interface StorefrontInfoRead
 */
export interface StorefrontInfoRead {
    /**
     * 
     * @type {FactorTypeCodeEnum}
     * @memberof StorefrontInfoRead
     */
    factorTypeCode: FactorTypeCodeEnum;
    /**
     * Длина смены в минутах по умолчанию для витрины (заполняется, если factorTypeCode = SHIFT)
     * @type {number}
     * @memberof StorefrontInfoRead
     */
    shiftLengthInMinutes?: number;
    /**
     * Минимальная длина интервала в минутах
     * @type {number}
     * @memberof StorefrontInfoRead
     */
    minimumIntervalLengthInMinutes?: number;
    /**
     * Максимальная длина интервала в минутах
     * @type {number}
     * @memberof StorefrontInfoRead
     */
    maximumIntervalLengthInMinutes?: number;
    /**
     * 
     * @type {CategoryForest}
     * @memberof StorefrontInfoRead
     */
    categoryForest?: CategoryForest;
    /**
     * 
     * @type {StorefrontBusinessAccountInfoObj}
     * @memberof StorefrontInfoRead
     */
    businessAccount?: StorefrontBusinessAccountInfoObj;
}
/**
 * Данные арендатора для витрины
 * @export
 * @interface StorefrontRenterInfoRead
 */
export interface StorefrontRenterInfoRead {
    /**
     * Скидка по умолчанию
     * @type {number}
     * @memberof StorefrontRenterInfoRead
     */
    defaultDiscount: number;
}
/**
 * Финансовые поля сущности SubrentRecord, которые могут быть выгружены в список Excel
 * @export
 * @enum {string}
 */
export enum SubrentFinancialFieldsTypeCode {
    CurrentSubrentShipmentBookedSum = 'currentSubrentShipmentBookedSum',
    DebtToSupplierSum = 'debtToSupplierSum',
    CurrentSubrentSum = 'currentSubrentSum',
    Sum = 'sum'
}

/**
 * Субаренда: абстрактное общее состояние для всех операций
 * @export
 * @interface SubrentInfoAbstractCommons
 */
export interface SubrentInfoAbstractCommons {
    /**
     * Идентификатор
     * @type {number}
     * @memberof SubrentInfoAbstractCommons
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof SubrentInfoAbstractCommons
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof SubrentInfoAbstractCommons
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof SubrentInfoAbstractCommons
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof SubrentInfoAbstractCommons
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof SubrentInfoAbstractCommons
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof SubrentInfoAbstractCommons
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof SubrentInfoAbstractCommons
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof SubrentInfoAbstractCommons
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof SubrentInfoAbstractCommons
     */
    businessAccountId?: number;
    /**
     * Ссылка: идентификатор поставщика
     * @type {number}
     * @memberof SubrentInfoAbstractCommons
     */
    supplierId: number;
    /**
     * Ссылка: идентификатор пользователя - сотрудника абонента, ответственного за субаренду
     * @type {number}
     * @memberof SubrentInfoAbstractCommons
     */
    assigneeId?: number;
    /**
     * Выч по ссылке: ФИО ответственного пользователя
     * @type {PersonFullName}
     * @memberof SubrentInfoAbstractCommons
     */
    assigneeFullName?: PersonFullName;
    /**
     * Маркер, указывающий, что субаренда имеет собственного ответственного, а не наследует его от поставщика
     * @type {boolean}
     * @memberof SubrentInfoAbstractCommons
     */
    hasOwnAssignee: boolean;
    /**
     * Полное официальное наименование
     * @type {string}
     * @memberof SubrentInfoAbstractCommons
     */
    fullName?: string;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof SubrentInfoAbstractCommons
     */
    shortName: string;
    /**
     * Дата начала работ по субаренде (по договору)
     * @type {Date}
     * @memberof SubrentInfoAbstractCommons
     */
    startDate?: Date;
    /**
     * Ожидаемая дата завершения субаренды (по договору)
     * @type {Date}
     * @memberof SubrentInfoAbstractCommons
     */
    targetFinishDate?: Date;
    /**
     * Описание
     * @type {string}
     * @memberof SubrentInfoAbstractCommons
     */
    description?: string;
    /**
     * 
     * @type {Array<ContactPersonAPIObj>}
     * @memberof SubrentInfoAbstractCommons
     */
    contacts?: Array<ContactPersonAPIObj>;
    /**
     * Маркер, указывающий, что субаренда имеет собственные контакты
     * @type {boolean}
     * @memberof SubrentInfoAbstractCommons
     */
    hasOwnContacts: boolean;
}
/**
 * 
 * @export
 * @interface SubrentInfoAbstractCommonsAllOf
 */
export interface SubrentInfoAbstractCommonsAllOf {
    /**
     * Ссылка: идентификатор поставщика
     * @type {number}
     * @memberof SubrentInfoAbstractCommonsAllOf
     */
    supplierId: number;
    /**
     * Ссылка: идентификатор пользователя - сотрудника абонента, ответственного за субаренду
     * @type {number}
     * @memberof SubrentInfoAbstractCommonsAllOf
     */
    assigneeId?: number;
    /**
     * Выч по ссылке: ФИО ответственного пользователя
     * @type {PersonFullName}
     * @memberof SubrentInfoAbstractCommonsAllOf
     */
    assigneeFullName?: PersonFullName;
    /**
     * Маркер, указывающий, что субаренда имеет собственного ответственного, а не наследует его от поставщика
     * @type {boolean}
     * @memberof SubrentInfoAbstractCommonsAllOf
     */
    hasOwnAssignee: boolean;
    /**
     * Полное официальное наименование
     * @type {string}
     * @memberof SubrentInfoAbstractCommonsAllOf
     */
    fullName?: string;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof SubrentInfoAbstractCommonsAllOf
     */
    shortName: string;
    /**
     * Дата начала работ по субаренде (по договору)
     * @type {Date}
     * @memberof SubrentInfoAbstractCommonsAllOf
     */
    startDate?: Date;
    /**
     * Ожидаемая дата завершения субаренды (по договору)
     * @type {Date}
     * @memberof SubrentInfoAbstractCommonsAllOf
     */
    targetFinishDate?: Date;
    /**
     * Описание
     * @type {string}
     * @memberof SubrentInfoAbstractCommonsAllOf
     */
    description?: string;
    /**
     * 
     * @type {Array<ContactPersonAPIObj>}
     * @memberof SubrentInfoAbstractCommonsAllOf
     */
    contacts?: Array<ContactPersonAPIObj>;
    /**
     * Маркер, указывающий, что субаренда имеет собственные контакты
     * @type {boolean}
     * @memberof SubrentInfoAbstractCommonsAllOf
     */
    hasOwnContacts: boolean;
}
/**
 * Субаренда: абстрактное состояние при записи
 * @export
 * @interface SubrentInfoAbstractWriteCommons
 */
export interface SubrentInfoAbstractWriteCommons {
    /**
     * Идентификатор
     * @type {number}
     * @memberof SubrentInfoAbstractWriteCommons
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof SubrentInfoAbstractWriteCommons
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof SubrentInfoAbstractWriteCommons
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof SubrentInfoAbstractWriteCommons
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof SubrentInfoAbstractWriteCommons
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof SubrentInfoAbstractWriteCommons
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof SubrentInfoAbstractWriteCommons
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof SubrentInfoAbstractWriteCommons
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof SubrentInfoAbstractWriteCommons
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof SubrentInfoAbstractWriteCommons
     */
    businessAccountId?: number;
    /**
     * Ссылка: идентификатор поставщика
     * @type {number}
     * @memberof SubrentInfoAbstractWriteCommons
     */
    supplierId: number;
    /**
     * Ссылка: идентификатор пользователя - сотрудника абонента, ответственного за субаренду
     * @type {number}
     * @memberof SubrentInfoAbstractWriteCommons
     */
    assigneeId?: number;
    /**
     * Выч по ссылке: ФИО ответственного пользователя
     * @type {PersonFullName}
     * @memberof SubrentInfoAbstractWriteCommons
     */
    assigneeFullName?: PersonFullName;
    /**
     * Маркер, указывающий, что субаренда имеет собственного ответственного, а не наследует его от поставщика
     * @type {boolean}
     * @memberof SubrentInfoAbstractWriteCommons
     */
    hasOwnAssignee: boolean;
    /**
     * Полное официальное наименование
     * @type {string}
     * @memberof SubrentInfoAbstractWriteCommons
     */
    fullName?: string;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof SubrentInfoAbstractWriteCommons
     */
    shortName: string;
    /**
     * Дата начала работ по субаренде (по договору)
     * @type {Date}
     * @memberof SubrentInfoAbstractWriteCommons
     */
    startDate?: Date;
    /**
     * Ожидаемая дата завершения субаренды (по договору)
     * @type {Date}
     * @memberof SubrentInfoAbstractWriteCommons
     */
    targetFinishDate?: Date;
    /**
     * Описание
     * @type {string}
     * @memberof SubrentInfoAbstractWriteCommons
     */
    description?: string;
    /**
     * 
     * @type {Array<ContactPersonAPIObj>}
     * @memberof SubrentInfoAbstractWriteCommons
     */
    contacts?: Array<ContactPersonAPIObj>;
    /**
     * Маркер, указывающий, что субаренда имеет собственные контакты
     * @type {boolean}
     * @memberof SubrentInfoAbstractWriteCommons
     */
    hasOwnContacts: boolean;
    /**
     * 
     * @type {ContractObjWrite}
     * @memberof SubrentInfoAbstractWriteCommons
     */
    subrentContract: ContractObjWrite;
    /**
     * Контакты поставки
     * @type {Array<ContactLinkObjWrite>}
     * @memberof SubrentInfoAbstractWriteCommons
     */
    contactLinks?: Array<ContactLinkObjWrite>;
}
/**
 * 
 * @export
 * @interface SubrentInfoAbstractWriteCommonsAllOf
 */
export interface SubrentInfoAbstractWriteCommonsAllOf {
    /**
     * 
     * @type {ContractObjWrite}
     * @memberof SubrentInfoAbstractWriteCommonsAllOf
     */
    subrentContract: ContractObjWrite;
    /**
     * Контакты поставки
     * @type {Array<ContactLinkObjWrite>}
     * @memberof SubrentInfoAbstractWriteCommonsAllOf
     */
    contactLinks?: Array<ContactLinkObjWrite>;
}
/**
 * Субаренда: состояние при создании
 * @export
 * @interface SubrentInfoCreate
 */
export interface SubrentInfoCreate {
    /**
     * Идентификатор
     * @type {number}
     * @memberof SubrentInfoCreate
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof SubrentInfoCreate
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof SubrentInfoCreate
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof SubrentInfoCreate
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof SubrentInfoCreate
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof SubrentInfoCreate
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof SubrentInfoCreate
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof SubrentInfoCreate
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof SubrentInfoCreate
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof SubrentInfoCreate
     */
    businessAccountId?: number;
    /**
     * Ссылка: идентификатор поставщика
     * @type {number}
     * @memberof SubrentInfoCreate
     */
    supplierId: number;
    /**
     * Ссылка: идентификатор пользователя - сотрудника абонента, ответственного за субаренду
     * @type {number}
     * @memberof SubrentInfoCreate
     */
    assigneeId?: number;
    /**
     * Выч по ссылке: ФИО ответственного пользователя
     * @type {PersonFullName}
     * @memberof SubrentInfoCreate
     */
    assigneeFullName?: PersonFullName;
    /**
     * Маркер, указывающий, что субаренда имеет собственного ответственного, а не наследует его от поставщика
     * @type {boolean}
     * @memberof SubrentInfoCreate
     */
    hasOwnAssignee: boolean;
    /**
     * Полное официальное наименование
     * @type {string}
     * @memberof SubrentInfoCreate
     */
    fullName?: string;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof SubrentInfoCreate
     */
    shortName: string;
    /**
     * Дата начала работ по субаренде (по договору)
     * @type {Date}
     * @memberof SubrentInfoCreate
     */
    startDate?: Date;
    /**
     * Ожидаемая дата завершения субаренды (по договору)
     * @type {Date}
     * @memberof SubrentInfoCreate
     */
    targetFinishDate?: Date;
    /**
     * Описание
     * @type {string}
     * @memberof SubrentInfoCreate
     */
    description?: string;
    /**
     * 
     * @type {Array<ContactPersonAPIObj>}
     * @memberof SubrentInfoCreate
     */
    contacts?: Array<ContactPersonAPIObj>;
    /**
     * Маркер, указывающий, что субаренда имеет собственные контакты
     * @type {boolean}
     * @memberof SubrentInfoCreate
     */
    hasOwnContacts: boolean;
    /**
     * 
     * @type {ContractObjWrite}
     * @memberof SubrentInfoCreate
     */
    subrentContract: ContractObjWrite;
    /**
     * Контакты поставки
     * @type {Array<ContactLinkObjWrite>}
     * @memberof SubrentInfoCreate
     */
    contactLinks?: Array<ContactLinkObjWrite>;
    /**
     * 
     * @type {SubrentStateCodeEnum}
     * @memberof SubrentInfoCreate
     */
    stateCode: SubrentStateCodeEnum;
    /**
     * Копировать неотмененные обязательства исходного проекта. Если статус целевой поставки Новый, все обязательства в нем будут созданы в статусе Черновик
     * @type {boolean}
     * @memberof SubrentInfoCreate
     */
    copyRentElements: boolean;
    /**
     * Признак сохранения именованных экземпляров при копировании обязательств во время создания на основе другой поставки
     * @type {boolean}
     * @memberof SubrentInfoCreate
     */
    saveTrackedInstances: boolean;
    /**
     * Идентификатор поставки
     * @type {number}
     * @memberof SubrentInfoCreate
     */
    sourceSubrentId?: number;
}
/**
 * 
 * @export
 * @interface SubrentInfoCreateAllOf
 */
export interface SubrentInfoCreateAllOf {
    /**
     * 
     * @type {SubrentStateCodeEnum}
     * @memberof SubrentInfoCreateAllOf
     */
    stateCode: SubrentStateCodeEnum;
    /**
     * Копировать неотмененные обязательства исходного проекта. Если статус целевой поставки Новый, все обязательства в нем будут созданы в статусе Черновик
     * @type {boolean}
     * @memberof SubrentInfoCreateAllOf
     */
    copyRentElements: boolean;
    /**
     * Признак сохранения именованных экземпляров при копировании обязательств во время создания на основе другой поставки
     * @type {boolean}
     * @memberof SubrentInfoCreateAllOf
     */
    saveTrackedInstances: boolean;
    /**
     * Идентификатор поставки
     * @type {number}
     * @memberof SubrentInfoCreateAllOf
     */
    sourceSubrentId?: number;
}
/**
 * Субаренда: состояние чтения
 * @export
 * @interface SubrentInfoRead
 */
export interface SubrentInfoRead {
    /**
     * Идентификатор
     * @type {number}
     * @memberof SubrentInfoRead
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof SubrentInfoRead
     */
    businessVersion: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof SubrentInfoRead
     */
    creationAuthorId: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof SubrentInfoRead
     */
    creationAuthorFullName: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof SubrentInfoRead
     */
    creationDate: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof SubrentInfoRead
     */
    lastUpdateAuthorId: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof SubrentInfoRead
     */
    lastUpdateAuthorFullName: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof SubrentInfoRead
     */
    lastUpdateDate: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof SubrentInfoRead
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof SubrentInfoRead
     */
    businessAccountId: number;
    /**
     * Ссылка: идентификатор поставщика
     * @type {number}
     * @memberof SubrentInfoRead
     */
    supplierId: number;
    /**
     * Ссылка: идентификатор пользователя - сотрудника абонента, ответственного за субаренду
     * @type {number}
     * @memberof SubrentInfoRead
     */
    assigneeId?: number;
    /**
     * Выч по ссылке: ФИО ответственного пользователя
     * @type {PersonFullName}
     * @memberof SubrentInfoRead
     */
    assigneeFullName?: PersonFullName;
    /**
     * Маркер, указывающий, что субаренда имеет собственного ответственного, а не наследует его от поставщика
     * @type {boolean}
     * @memberof SubrentInfoRead
     */
    hasOwnAssignee: boolean;
    /**
     * Полное официальное наименование
     * @type {string}
     * @memberof SubrentInfoRead
     */
    fullName?: string;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof SubrentInfoRead
     */
    shortName: string;
    /**
     * Дата начала работ по субаренде (по договору)
     * @type {Date}
     * @memberof SubrentInfoRead
     */
    startDate?: Date;
    /**
     * Ожидаемая дата завершения субаренды (по договору)
     * @type {Date}
     * @memberof SubrentInfoRead
     */
    targetFinishDate?: Date;
    /**
     * Описание
     * @type {string}
     * @memberof SubrentInfoRead
     */
    description?: string;
    /**
     * 
     * @type {Array<ContactPersonAPIObj>}
     * @memberof SubrentInfoRead
     */
    contacts: Array<ContactPersonAPIObj>;
    /**
     * Маркер, указывающий, что субаренда имеет собственные контакты
     * @type {boolean}
     * @memberof SubrentInfoRead
     */
    hasOwnContacts: boolean;
    /**
     * 
     * @type {AttachmentRecordList}
     * @memberof SubrentInfoRead
     */
    attachments?: AttachmentRecordList;
    /**
     * Мнемонический код вида *КОД_ПОСТАВЩИКА* + *SEQUENCE субаренд этого поставщика*
     * @type {string}
     * @memberof SubrentInfoRead
     */
    mnemoKey: string;
    /**
     * 
     * @type {RentIndicatorsObj}
     * @memberof SubrentInfoRead
     */
    rentIndicators: RentIndicatorsObj;
    /**
     * 
     * @type {SubrentStateCodeEnum}
     * @memberof SubrentInfoRead
     */
    stateCode: SubrentStateCodeEnum;
    /**
     * Коды доступных переходов для данной субаренды и пользователя
     * @type {Array<SubrentTransitionCodeEnum>}
     * @memberof SubrentInfoRead
     */
    availableTransitionCodes?: Array<SubrentTransitionCodeEnum>;
    /**
     * Маркер нахождения в архиве
     * @type {boolean}
     * @memberof SubrentInfoRead
     */
    archive: boolean;
    /**
     * Может ли быть отправлен в архив (может, когда не является активным)
     * @type {boolean}
     * @memberof SubrentInfoRead
     */
    archivable: boolean;
    /**
     * Короткое наименование поставщика
     * @type {string}
     * @memberof SubrentInfoRead
     */
    supplierShortName: string;
    /**
     * 
     * @type {RenterStateCodeEnum}
     * @memberof SubrentInfoRead
     */
    supplierStateCode: RenterStateCodeEnum;
    /**
     * Фактическая дата завершения субаренды (когда субаренда был переведена в статус завершена)
     * @type {Date}
     * @memberof SubrentInfoRead
     */
    realFinishDate?: Date;
    /**
     * Сумма всех обязательств в статусах бронирование субаренды, субаренда и завершенных, в коп.
     * @type {number}
     * @memberof SubrentInfoRead
     */
    sum: number;
    /**
     * Сумма долга перед поставщиком по субаренде в коп.
     * @type {number}
     * @memberof SubrentInfoRead
     */
    debtToSupplierSum: number;
    /**
     * Число платежей по субаренде поставщику
     * @type {number}
     * @memberof SubrentInfoRead
     */
    paymentsToSupplierCount?: number;
    /**
     * Сумма проведенных платежей по субаренде поставщику в коп.
     * @type {number}
     * @memberof SubrentInfoRead
     */
    payedToSupplierSum: number;
    /**
     * Процент скидки по умолчанию у поставщика
     * @type {number}
     * @memberof SubrentInfoRead
     */
    supplierDefaultDiscount: number;
    /**
     * 
     * @type {Array<ContactPersonAPIObj>}
     * @memberof SubrentInfoRead
     */
    supplierContacts: Array<ContactPersonAPIObj>;
    /**
     * 
     * @type {Array<ContactLinkObjRead>}
     * @memberof SubrentInfoRead
     */
    supplierContactLinks?: Array<ContactLinkObjRead>;
    /**
     * Ссылка: идентификатор пользователя - сотрудника абонента, ответственного за поставщика субаренды, важно когда отдельного ответственного на саму субаренду нет
     * @type {number}
     * @memberof SubrentInfoRead
     */
    supplierAssigneeId?: number;
    /**
     * Выч по ссылке: ФИО ответственного пользователя поставщика субаренды, важно когда отдельного ответственного на саму субаренду нет
     * @type {PersonFullName}
     * @memberof SubrentInfoRead
     */
    supplierAssigneeFullName?: PersonFullName;
    /**
     * 
     * @type {ContractObjRead}
     * @memberof SubrentInfoRead
     */
    subrentContract?: ContractObjRead;
    /**
     * Контакты поставки
     * @type {Array<ContactLinkObjRead>}
     * @memberof SubrentInfoRead
     */
    contactLinks?: Array<ContactLinkObjRead>;
}
/**
 * 
 * @export
 * @interface SubrentInfoReadAllOf
 */
export interface SubrentInfoReadAllOf {
    /**
     * 
     * @type {AttachmentRecordList}
     * @memberof SubrentInfoReadAllOf
     */
    attachments?: AttachmentRecordList;
    /**
     * Мнемонический код вида *КОД_ПОСТАВЩИКА* + *SEQUENCE субаренд этого поставщика*
     * @type {string}
     * @memberof SubrentInfoReadAllOf
     */
    mnemoKey: string;
    /**
     * 
     * @type {RentIndicatorsObj}
     * @memberof SubrentInfoReadAllOf
     */
    rentIndicators: RentIndicatorsObj;
    /**
     * 
     * @type {SubrentStateCodeEnum}
     * @memberof SubrentInfoReadAllOf
     */
    stateCode: SubrentStateCodeEnum;
    /**
     * Коды доступных переходов для данной субаренды и пользователя
     * @type {Array<SubrentTransitionCodeEnum>}
     * @memberof SubrentInfoReadAllOf
     */
    availableTransitionCodes?: Array<SubrentTransitionCodeEnum>;
    /**
     * Маркер нахождения в архиве
     * @type {boolean}
     * @memberof SubrentInfoReadAllOf
     */
    archive: boolean;
    /**
     * Может ли быть отправлен в архив (может, когда не является активным)
     * @type {boolean}
     * @memberof SubrentInfoReadAllOf
     */
    archivable: boolean;
    /**
     * Короткое наименование поставщика
     * @type {string}
     * @memberof SubrentInfoReadAllOf
     */
    supplierShortName: string;
    /**
     * 
     * @type {RenterStateCodeEnum}
     * @memberof SubrentInfoReadAllOf
     */
    supplierStateCode: RenterStateCodeEnum;
    /**
     * Фактическая дата завершения субаренды (когда субаренда был переведена в статус завершена)
     * @type {Date}
     * @memberof SubrentInfoReadAllOf
     */
    realFinishDate?: Date;
    /**
     * Сумма всех обязательств в статусах бронирование субаренды, субаренда и завершенных, в коп.
     * @type {number}
     * @memberof SubrentInfoReadAllOf
     */
    sum: number;
    /**
     * Сумма долга перед поставщиком по субаренде в коп.
     * @type {number}
     * @memberof SubrentInfoReadAllOf
     */
    debtToSupplierSum: number;
    /**
     * Число платежей по субаренде поставщику
     * @type {number}
     * @memberof SubrentInfoReadAllOf
     */
    paymentsToSupplierCount?: number;
    /**
     * Сумма проведенных платежей по субаренде поставщику в коп.
     * @type {number}
     * @memberof SubrentInfoReadAllOf
     */
    payedToSupplierSum: number;
    /**
     * Процент скидки по умолчанию у поставщика
     * @type {number}
     * @memberof SubrentInfoReadAllOf
     */
    supplierDefaultDiscount: number;
    /**
     * 
     * @type {Array<ContactPersonAPIObj>}
     * @memberof SubrentInfoReadAllOf
     */
    supplierContacts: Array<ContactPersonAPIObj>;
    /**
     * 
     * @type {Array<ContactLinkObjRead>}
     * @memberof SubrentInfoReadAllOf
     */
    supplierContactLinks?: Array<ContactLinkObjRead>;
    /**
     * Ссылка: идентификатор пользователя - сотрудника абонента, ответственного за поставщика субаренды, важно когда отдельного ответственного на саму субаренду нет
     * @type {number}
     * @memberof SubrentInfoReadAllOf
     */
    supplierAssigneeId?: number;
    /**
     * Выч по ссылке: ФИО ответственного пользователя поставщика субаренды, важно когда отдельного ответственного на саму субаренду нет
     * @type {PersonFullName}
     * @memberof SubrentInfoReadAllOf
     */
    supplierAssigneeFullName?: PersonFullName;
    /**
     * 
     * @type {ContractObjRead}
     * @memberof SubrentInfoReadAllOf
     */
    subrentContract?: ContractObjRead;
    /**
     * Контакты поставки
     * @type {Array<ContactLinkObjRead>}
     * @memberof SubrentInfoReadAllOf
     */
    contactLinks?: Array<ContactLinkObjRead>;
}
/**
 * Субаренда: состояние при изменении
 * @export
 * @interface SubrentInfoUpdate
 */
export interface SubrentInfoUpdate extends SubrentInfoAbstractWriteCommons {
}
/**
 * Не финансовые поля сущности SubrentRecord, которые могут быть выгружены в список Excel
 * @export
 * @enum {string}
 */
export enum SubrentNotFinancialFieldsTypeCode {
    ShortName = 'shortName',
    StateCode = 'stateCode',
    SupplierShortName = 'supplierShortName',
    AssigneeShortName = 'assigneeShortName',
    LastActivityDate = 'lastActivityDate',
    StartDate = 'startDate',
    TargetFinishDate = 'targetFinishDate',
    RealFinishDate = 'realFinishDate'
}

/**
 * Списочное представление субаренды
 * @export
 * @interface SubrentRecord
 */
export interface SubrentRecord {
    /**
     * Идентификатор
     * @type {number}
     * @memberof SubrentRecord
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof SubrentRecord
     */
    businessVersion: number;
    /**
     * Ссылка: Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof SubrentRecord
     */
    businessAccountId: number;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof SubrentRecord
     */
    shortName: string;
    /**
     * Мнемонический код вида *КОД_ПОСТАВЩИКА* + *SEQUENCE субаренд этого поставщика*
     * @type {string}
     * @memberof SubrentRecord
     */
    mnemoKey: string;
    /**
     * 
     * @type {SubrentStateCodeEnum}
     * @memberof SubrentRecord
     */
    stateCode: SubrentStateCodeEnum;
    /**
     * Коды доступных переходов для данной субаренды и пользователя
     * @type {Array<SubrentTransitionCodeEnum>}
     * @memberof SubrentRecord
     */
    availableTransitionCodes?: Array<SubrentTransitionCodeEnum>;
    /**
     * Находится ли в архиве
     * @type {boolean}
     * @memberof SubrentRecord
     */
    archive: boolean;
    /**
     * Может ли быть отправлена в архив (может, когда не является активным)
     * @type {boolean}
     * @memberof SubrentRecord
     */
    archivable: boolean;
    /**
     * Ссылка: идентификатор поставщика
     * @type {number}
     * @memberof SubrentRecord
     */
    supplierId: number;
    /**
     * Выч. по ссылке: Короткое наименование поставщика
     * @type {string}
     * @memberof SubrentRecord
     */
    supplierShortName: string;
    /**
     * Дата начала работ по субаренде (по договору)
     * @type {Date}
     * @memberof SubrentRecord
     */
    startDate?: Date;
    /**
     * Ожидаемая дата завершения субаренды
     * @type {Date}
     * @memberof SubrentRecord
     */
    targetFinishDate?: Date;
    /**
     * Дата завершения субаренды (когда субаренда былы переведена в статус завершена)
     * @type {Date}
     * @memberof SubrentRecord
     */
    realFinishDate?: Date;
    /**
     * Ссылка: идентификатор пользователя - сотрудника абонента, ответственного за субаренду
     * @type {number}
     * @memberof SubrentRecord
     */
    assigneeId?: number;
    /**
     * Выч. по ссылке: короткое ФИО ответственного
     * @type {string}
     * @memberof SubrentRecord
     */
    assigneeShortName?: string;
    /**
     * Маркер, указывающий, что субаренда имеет собственного ответственного, а не наследует его от поставщика
     * @type {boolean}
     * @memberof SubrentRecord
     */
    hasOwnAssignee: boolean;
    /**
     * Сумма всех обязательств в статусе бронирование субаренды в коп.
     * @type {number}
     * @memberof SubrentRecord
     */
    currentSubrentShipmentBookedSum: number;
    /**
     * Сумма всех обязательств в статусе субаренда в коп.
     * @type {number}
     * @memberof SubrentRecord
     */
    currentSubrentSum: number;
    /**
     * Сумма всех обязательств в статусах бронирование субаренды, субаренда и завершенных, в коп.
     * @type {number}
     * @memberof SubrentRecord
     */
    sum?: number;
    /**
     * Сумма долга перед поставщиком по субаренде в коп.
     * @type {number}
     * @memberof SubrentRecord
     */
    debtToSupplierSum?: number;
    /**
     * 
     * @type {ProblemAndWarningRentIndicatorsRecordObj}
     * @memberof SubrentRecord
     */
    problemsAndWarnings: ProblemAndWarningRentIndicatorsRecordObj;
    /**
     * Дата и время последней активности (измение сущности либо операция над связанными обязательствами)
     * @type {Date}
     * @memberof SubrentRecord
     */
    lastActivityDate: Date;
}
/**
 * 
 * @export
 * @interface SubrentRecordAllOf
 */
export interface SubrentRecordAllOf {
    /**
     * Короткое наименование
     * @type {string}
     * @memberof SubrentRecordAllOf
     */
    shortName: string;
    /**
     * Мнемонический код вида *КОД_ПОСТАВЩИКА* + *SEQUENCE субаренд этого поставщика*
     * @type {string}
     * @memberof SubrentRecordAllOf
     */
    mnemoKey: string;
    /**
     * 
     * @type {SubrentStateCodeEnum}
     * @memberof SubrentRecordAllOf
     */
    stateCode: SubrentStateCodeEnum;
    /**
     * Коды доступных переходов для данной субаренды и пользователя
     * @type {Array<SubrentTransitionCodeEnum>}
     * @memberof SubrentRecordAllOf
     */
    availableTransitionCodes?: Array<SubrentTransitionCodeEnum>;
    /**
     * Находится ли в архиве
     * @type {boolean}
     * @memberof SubrentRecordAllOf
     */
    archive: boolean;
    /**
     * Может ли быть отправлена в архив (может, когда не является активным)
     * @type {boolean}
     * @memberof SubrentRecordAllOf
     */
    archivable: boolean;
    /**
     * Ссылка: идентификатор поставщика
     * @type {number}
     * @memberof SubrentRecordAllOf
     */
    supplierId: number;
    /**
     * Выч. по ссылке: Короткое наименование поставщика
     * @type {string}
     * @memberof SubrentRecordAllOf
     */
    supplierShortName: string;
    /**
     * Дата начала работ по субаренде (по договору)
     * @type {Date}
     * @memberof SubrentRecordAllOf
     */
    startDate?: Date;
    /**
     * Ожидаемая дата завершения субаренды
     * @type {Date}
     * @memberof SubrentRecordAllOf
     */
    targetFinishDate?: Date;
    /**
     * Дата завершения субаренды (когда субаренда былы переведена в статус завершена)
     * @type {Date}
     * @memberof SubrentRecordAllOf
     */
    realFinishDate?: Date;
    /**
     * Ссылка: идентификатор пользователя - сотрудника абонента, ответственного за субаренду
     * @type {number}
     * @memberof SubrentRecordAllOf
     */
    assigneeId?: number;
    /**
     * Выч. по ссылке: короткое ФИО ответственного
     * @type {string}
     * @memberof SubrentRecordAllOf
     */
    assigneeShortName?: string;
    /**
     * Маркер, указывающий, что субаренда имеет собственного ответственного, а не наследует его от поставщика
     * @type {boolean}
     * @memberof SubrentRecordAllOf
     */
    hasOwnAssignee: boolean;
    /**
     * Сумма всех обязательств в статусе бронирование субаренды в коп.
     * @type {number}
     * @memberof SubrentRecordAllOf
     */
    currentSubrentShipmentBookedSum: number;
    /**
     * Сумма всех обязательств в статусе субаренда в коп.
     * @type {number}
     * @memberof SubrentRecordAllOf
     */
    currentSubrentSum: number;
    /**
     * Сумма всех обязательств в статусах бронирование субаренды, субаренда и завершенных, в коп.
     * @type {number}
     * @memberof SubrentRecordAllOf
     */
    sum?: number;
    /**
     * Сумма долга перед поставщиком по субаренде в коп.
     * @type {number}
     * @memberof SubrentRecordAllOf
     */
    debtToSupplierSum?: number;
    /**
     * 
     * @type {ProblemAndWarningRentIndicatorsRecordObj}
     * @memberof SubrentRecordAllOf
     */
    problemsAndWarnings: ProblemAndWarningRentIndicatorsRecordObj;
    /**
     * Дата и время последней активности (измение сущности либо операция над связанными обязательствами)
     * @type {Date}
     * @memberof SubrentRecordAllOf
     */
    lastActivityDate: Date;
}
/**
 * Список записей субаренд
 * @export
 * @interface SubrentRecordList
 */
export interface SubrentRecordList {
    /**
     * 
     * @type {ListAttributesInfo}
     * @memberof SubrentRecordList
     */
    listAttributes: ListAttributesInfo;
    /**
     * 
     * @type {Array<SubrentRecord>}
     * @memberof SubrentRecordList
     */
    records: Array<SubrentRecord>;
}
/**
 * 
 * @export
 * @interface SubrentRecordListAllOf
 */
export interface SubrentRecordListAllOf {
    /**
     * 
     * @type {Array<SubrentRecord>}
     * @memberof SubrentRecordListAllOf
     */
    records: Array<SubrentRecord>;
}
/**
 * Код статуса проекта
 * @export
 * @enum {string}
 */
export enum SubrentStateCodeEnum {
    DRAFT = 'DRAFT',
    INPROGRESS = 'IN_PROGRESS',
    CANCELED = 'CANCELED',
    FINISHED = 'FINISHED'
}

/**
 * Все возможные коды для простых переходов - активировать, отменить и заблокировать
 * @export
 * @enum {string}
 */
export enum SubrentTransitionCodeEnum {
    ACTIVATE = 'ACTIVATE',
    CANCEL = 'CANCEL',
    FINISH = 'FINISH',
    RETURNTODRAFT = 'RETURN_TO_DRAFT'
}

/**
 * Параметры запроса на получение списков номенклатуры и экземпляров со стеками доступности для передачи в теле запроса
 * @export
 * @interface SummaryScheduleListRequestObject
 */
export interface SummaryScheduleListRequestObject {
    /**
     * 
     * @type {Array<string>}
     * @memberof SummaryScheduleListRequestObject
     */
    nomenclatureListFilters?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SummaryScheduleListRequestObject
     */
    instanceListFilters?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SummaryScheduleListRequestObject
     */
    nomenclatureSearch?: string;
    /**
     * 
     * @type {string}
     * @memberof SummaryScheduleListRequestObject
     */
    instanceSearch?: string;
    /**
     * 
     * @type {string}
     * @memberof SummaryScheduleListRequestObject
     */
    nomenclatureSortBy?: string;
    /**
     * 
     * @type {string}
     * @memberof SummaryScheduleListRequestObject
     */
    instanceSortBy?: string;
    /**
     * 
     * @type {string}
     * @memberof SummaryScheduleListRequestObject
     */
    nomenclatureSortOrder?: SummaryScheduleListRequestObjectNomenclatureSortOrderEnum;
    /**
     * 
     * @type {string}
     * @memberof SummaryScheduleListRequestObject
     */
    instanceSortOrder?: SummaryScheduleListRequestObjectInstanceSortOrderEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SummaryScheduleListRequestObjectNomenclatureSortOrderEnum {
    ASC = 'ASC',
    DESC = 'DESC'
}
/**
    * @export
    * @enum {string}
    */
export enum SummaryScheduleListRequestObjectInstanceSortOrderEnum {
    ASC = 'ASC',
    DESC = 'DESC'
}

/**
 * Системная настройка: абстрактное общее состояние для всех операций
 * @export
 * @interface SystemOptionInfoAbstractCommons
 */
export interface SystemOptionInfoAbstractCommons {
    /**
     * Идентификатор
     * @type {number}
     * @memberof SystemOptionInfoAbstractCommons
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof SystemOptionInfoAbstractCommons
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof SystemOptionInfoAbstractCommons
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof SystemOptionInfoAbstractCommons
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof SystemOptionInfoAbstractCommons
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof SystemOptionInfoAbstractCommons
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof SystemOptionInfoAbstractCommons
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof SystemOptionInfoAbstractCommons
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof SystemOptionInfoAbstractCommons
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof SystemOptionInfoAbstractCommons
     */
    businessAccountId?: number;
    /**
     * Значение
     * @type {string}
     * @memberof SystemOptionInfoAbstractCommons
     */
    value?: string;
}
/**
 * 
 * @export
 * @interface SystemOptionInfoAbstractCommonsAllOf
 */
export interface SystemOptionInfoAbstractCommonsAllOf {
    /**
     * Значение
     * @type {string}
     * @memberof SystemOptionInfoAbstractCommonsAllOf
     */
    value?: string;
}
/**
 * Системная настройка
 * @export
 * @interface SystemOptionInfoRead
 */
export interface SystemOptionInfoRead {
    /**
     * Идентификатор
     * @type {number}
     * @memberof SystemOptionInfoRead
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof SystemOptionInfoRead
     */
    businessVersion: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof SystemOptionInfoRead
     */
    creationAuthorId: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof SystemOptionInfoRead
     */
    creationAuthorFullName: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof SystemOptionInfoRead
     */
    creationDate: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof SystemOptionInfoRead
     */
    lastUpdateAuthorId: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof SystemOptionInfoRead
     */
    lastUpdateAuthorFullName: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof SystemOptionInfoRead
     */
    lastUpdateDate: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof SystemOptionInfoRead
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof SystemOptionInfoRead
     */
    businessAccountId: number;
    /**
     * Значение
     * @type {string}
     * @memberof SystemOptionInfoRead
     */
    value: string;
    /**
     * Ссылка: идентификатор группы системных настроек
     * @type {number}
     * @memberof SystemOptionInfoRead
     */
    groupId: number;
    /**
     * Наименование
     * @type {string}
     * @memberof SystemOptionInfoRead
     */
    fullName: string;
    /**
     * Латинский код
     * @type {string}
     * @memberof SystemOptionInfoRead
     */
    code: string;
    /**
     * Описание
     * @type {string}
     * @memberof SystemOptionInfoRead
     */
    description: string;
    /**
     * Код типа
     * @type {string}
     * @memberof SystemOptionInfoRead
     */
    typeCode: SystemOptionInfoReadTypeCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SystemOptionInfoReadTypeCodeEnum {
    BOOLEAN = 'BOOLEAN',
    LONG = 'LONG',
    DOUBLE = 'DOUBLE',
    STRING = 'STRING',
    DATE = 'DATE',
    DATETIME = 'DATE_TIME'
}

/**
 * 
 * @export
 * @interface SystemOptionInfoReadAllOf
 */
export interface SystemOptionInfoReadAllOf {
    /**
     * Ссылка: идентификатор группы системных настроек
     * @type {number}
     * @memberof SystemOptionInfoReadAllOf
     */
    groupId: number;
    /**
     * Наименование
     * @type {string}
     * @memberof SystemOptionInfoReadAllOf
     */
    fullName: string;
    /**
     * Латинский код
     * @type {string}
     * @memberof SystemOptionInfoReadAllOf
     */
    code: string;
    /**
     * Описание
     * @type {string}
     * @memberof SystemOptionInfoReadAllOf
     */
    description: string;
    /**
     * Код типа
     * @type {string}
     * @memberof SystemOptionInfoReadAllOf
     */
    typeCode: SystemOptionInfoReadAllOfTypeCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SystemOptionInfoReadAllOfTypeCodeEnum {
    BOOLEAN = 'BOOLEAN',
    LONG = 'LONG',
    DOUBLE = 'DOUBLE',
    STRING = 'STRING',
    DATE = 'DATE',
    DATETIME = 'DATE_TIME'
}

/**
 * Системная настройка: состояние изменения
 * @export
 * @interface SystemOptionInfoUpdate
 */
export interface SystemOptionInfoUpdate extends SystemOptionInfoAbstractCommons {
}
/**
 * Системная настройка: массовое состояние изменения
 * @export
 * @interface SystemOptionInfoUpdateBulk
 */
export interface SystemOptionInfoUpdateBulk {
    /**
     * 
     * @type {Array<SystemOptionInfoUpdate>}
     * @memberof SystemOptionInfoUpdateBulk
     */
    systemOptions: Array<SystemOptionInfoUpdate>;
}
/**
 * Списочное представление системной настройки
 * @export
 * @interface SystemOptionRecord
 */
export interface SystemOptionRecord {
    /**
     * Идентификатор
     * @type {number}
     * @memberof SystemOptionRecord
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof SystemOptionRecord
     */
    businessVersion: number;
    /**
     * Ссылка: Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof SystemOptionRecord
     */
    businessAccountId: number;
    /**
     * Наименование
     * @type {string}
     * @memberof SystemOptionRecord
     */
    fullName: string;
    /**
     * Латинский код
     * @type {string}
     * @memberof SystemOptionRecord
     */
    code: string;
    /**
     * Код типа
     * @type {string}
     * @memberof SystemOptionRecord
     */
    typeCode: SystemOptionRecordTypeCodeEnum;
    /**
     * Обязательность
     * @type {boolean}
     * @memberof SystemOptionRecord
     */
    required: boolean;
    /**
     * Значение
     * @type {string}
     * @memberof SystemOptionRecord
     */
    value: string;
    /**
     * Описание
     * @type {string}
     * @memberof SystemOptionRecord
     */
    description?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum SystemOptionRecordTypeCodeEnum {
    BOOLEAN = 'BOOLEAN',
    LONG = 'LONG',
    DOUBLE = 'DOUBLE',
    STRING = 'STRING',
    DATE = 'DATE',
    DATETIME = 'DATE_TIME'
}

/**
 * 
 * @export
 * @interface SystemOptionRecordAllOf
 */
export interface SystemOptionRecordAllOf {
    /**
     * Наименование
     * @type {string}
     * @memberof SystemOptionRecordAllOf
     */
    fullName: string;
    /**
     * Латинский код
     * @type {string}
     * @memberof SystemOptionRecordAllOf
     */
    code: string;
    /**
     * Код типа
     * @type {string}
     * @memberof SystemOptionRecordAllOf
     */
    typeCode: SystemOptionRecordAllOfTypeCodeEnum;
    /**
     * Обязательность
     * @type {boolean}
     * @memberof SystemOptionRecordAllOf
     */
    required: boolean;
    /**
     * Значение
     * @type {string}
     * @memberof SystemOptionRecordAllOf
     */
    value: string;
    /**
     * Описание
     * @type {string}
     * @memberof SystemOptionRecordAllOf
     */
    description?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum SystemOptionRecordAllOfTypeCodeEnum {
    BOOLEAN = 'BOOLEAN',
    LONG = 'LONG',
    DOUBLE = 'DOUBLE',
    STRING = 'STRING',
    DATE = 'DATE',
    DATETIME = 'DATE_TIME'
}

/**
 * Списочное представление группы системных настроек
 * @export
 * @interface SystemOptionsGroupRecord
 */
export interface SystemOptionsGroupRecord {
    /**
     * Идентификатор
     * @type {number}
     * @memberof SystemOptionsGroupRecord
     */
    id: number;
    /**
     * Ссылка: идентификатор бизнес аккаунта
     * @type {number}
     * @memberof SystemOptionsGroupRecord
     */
    businessAccountId: number;
    /**
     * Наименование
     * @type {string}
     * @memberof SystemOptionsGroupRecord
     */
    fullName: string;
    /**
     * Латинский код
     * @type {string}
     * @memberof SystemOptionsGroupRecord
     */
    code: string;
    /**
     * Описание
     * @type {string}
     * @memberof SystemOptionsGroupRecord
     */
    description?: string;
    /**
     * Вложенные сущности: системные настройки, входящие в группу
     * @type {Array<SystemOptionRecord>}
     * @memberof SystemOptionsGroupRecord
     */
    systemOptions?: Array<SystemOptionRecord>;
}
/**
 * Список групп системных настроек
 * @export
 * @interface SystemOptionsGroupRecordList
 */
export interface SystemOptionsGroupRecordList {
    /**
     * 
     * @type {Array<SystemOptionsGroupRecord>}
     * @memberof SystemOptionsGroupRecordList
     */
    records: Array<SystemOptionsGroupRecord>;
}
/**
 * Группа системных настроек
 * @export
 * @interface SystemOptionsSectionReadInfo
 */
export interface SystemOptionsSectionReadInfo {
    /**
     * Идентификатор
     * @type {number}
     * @memberof SystemOptionsSectionReadInfo
     */
    id: number;
    /**
     * Ссылка: идентификатор бизнес аккаунта
     * @type {number}
     * @memberof SystemOptionsSectionReadInfo
     */
    businessAccountId: number;
    /**
     * Наименование
     * @type {string}
     * @memberof SystemOptionsSectionReadInfo
     */
    fullName: string;
    /**
     * Латинский код
     * @type {string}
     * @memberof SystemOptionsSectionReadInfo
     */
    code: string;
    /**
     * Описание
     * @type {string}
     * @memberof SystemOptionsSectionReadInfo
     */
    description?: string;
    /**
     * 
     * @type {SystemOptionsGroupRecordList}
     * @memberof SystemOptionsSectionReadInfo
     */
    systemOptionsGroups?: SystemOptionsGroupRecordList;
}
/**
 * Объект ссылки на таблицу
 * @export
 * @interface TableReference
 */
export interface TableReference {
    /**
     * 
     * @type {string}
     * @memberof TableReference
     */
    tableReference: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TagEntityTypeCode {
    PROJECT = 'PROJECT',
    RENTELEMENT = 'RENT_ELEMENT'
}

/**
 * Объект описывающий массовое назначение или отвязку категории
 * @export
 * @interface TagsBulkLinkObj
 */
export interface TagsBulkLinkObj {
    /**
     * 
     * @type {Array<string>}
     * @memberof TagsBulkLinkObj
     */
    tags: Array<string>;
    /**
     * 
     * @type {TagEntityTypeCode}
     * @memberof TagsBulkLinkObj
     */
    entityTypeCode: TagEntityTypeCode;
    /**
     * 
     * @type {Array<VersionedEntityObj>}
     * @memberof TagsBulkLinkObj
     */
    entities: Array<VersionedEntityObj>;
}
/**
 * Код тарифа бизнес аккаунта
 * @export
 * @enum {string}
 */
export enum TariffCodeEnum {
    TRIAL = 'TRIAL',
    START = 'START',
    ECONOMY = 'ECONOMY',
    BUSINESS = 'BUSINESS',
    ENTERPRISE = 'ENTERPRISE'
}

/**
 * Код типа используемой базы для вычисления налога
 * @export
 * @enum {string}
 */
export enum TaxBaseTypeCodeEnum {
    COSTBEFORETAX = 'COST_BEFORE_TAX',
    SUMAFTERTAX = 'SUM_AFTER_TAX'
}

/**
 * Финансовые поля сущности TemplateRecord, которые могут быть выгружены в список Excel
 * @export
 * @enum {string}
 */
export enum TemplateFinancialFieldsTypeCode {
    DefaultDiscount = 'defaultDiscount',
    RentElementsSum = 'rentElementsSum'
}

/**
 * Шаблон: абстрактное общее состояние для всех операций
 * @export
 * @interface TemplateInfoAbstractCommons
 */
export interface TemplateInfoAbstractCommons {
    /**
     * Идентификатор
     * @type {number}
     * @memberof TemplateInfoAbstractCommons
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof TemplateInfoAbstractCommons
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof TemplateInfoAbstractCommons
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof TemplateInfoAbstractCommons
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof TemplateInfoAbstractCommons
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof TemplateInfoAbstractCommons
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof TemplateInfoAbstractCommons
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof TemplateInfoAbstractCommons
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof TemplateInfoAbstractCommons
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof TemplateInfoAbstractCommons
     */
    businessAccountId?: number;
    /**
     * Полное официальное наименование
     * @type {string}
     * @memberof TemplateInfoAbstractCommons
     */
    fullName?: string;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof TemplateInfoAbstractCommons
     */
    shortName: string;
    /**
     * Применяемый процент скидки по умолчанию
     * @type {number}
     * @memberof TemplateInfoAbstractCommons
     */
    defaultDiscount?: number;
    /**
     * Дата начала работ
     * @type {Date}
     * @memberof TemplateInfoAbstractCommons
     */
    startDate?: Date;
    /**
     * Ожидаемая дата завершения
     * @type {Date}
     * @memberof TemplateInfoAbstractCommons
     */
    targetFinishDate?: Date;
    /**
     * Описание
     * @type {string}
     * @memberof TemplateInfoAbstractCommons
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface TemplateInfoAbstractCommonsAllOf
 */
export interface TemplateInfoAbstractCommonsAllOf {
    /**
     * Полное официальное наименование
     * @type {string}
     * @memberof TemplateInfoAbstractCommonsAllOf
     */
    fullName?: string;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof TemplateInfoAbstractCommonsAllOf
     */
    shortName: string;
    /**
     * Применяемый процент скидки по умолчанию
     * @type {number}
     * @memberof TemplateInfoAbstractCommonsAllOf
     */
    defaultDiscount?: number;
    /**
     * Дата начала работ
     * @type {Date}
     * @memberof TemplateInfoAbstractCommonsAllOf
     */
    startDate?: Date;
    /**
     * Ожидаемая дата завершения
     * @type {Date}
     * @memberof TemplateInfoAbstractCommonsAllOf
     */
    targetFinishDate?: Date;
    /**
     * Описание
     * @type {string}
     * @memberof TemplateInfoAbstractCommonsAllOf
     */
    description?: string;
}
/**
 * Шаблон: абстрактное состояние при записи
 * @export
 * @interface TemplateInfoAbstractWriteCommons
 */
export interface TemplateInfoAbstractWriteCommons extends TemplateInfoAbstractCommons {
}
/**
 * Шаблон: состояние при создании
 * @export
 * @interface TemplateInfoCreate
 */
export interface TemplateInfoCreate extends TemplateInfoAbstractWriteCommons {
}
/**
 * Шаблон: состояние чтения
 * @export
 * @interface TemplateInfoRead
 */
export interface TemplateInfoRead {
    /**
     * Идентификатор
     * @type {number}
     * @memberof TemplateInfoRead
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof TemplateInfoRead
     */
    businessVersion: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof TemplateInfoRead
     */
    creationAuthorId: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof TemplateInfoRead
     */
    creationAuthorFullName: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof TemplateInfoRead
     */
    creationDate: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof TemplateInfoRead
     */
    lastUpdateAuthorId: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof TemplateInfoRead
     */
    lastUpdateAuthorFullName: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof TemplateInfoRead
     */
    lastUpdateDate: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof TemplateInfoRead
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof TemplateInfoRead
     */
    businessAccountId: number;
    /**
     * Полное официальное наименование
     * @type {string}
     * @memberof TemplateInfoRead
     */
    fullName: string;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof TemplateInfoRead
     */
    shortName: string;
    /**
     * Применяемый процент скидки по умолчанию
     * @type {number}
     * @memberof TemplateInfoRead
     */
    defaultDiscount?: number;
    /**
     * Дата начала работ
     * @type {Date}
     * @memberof TemplateInfoRead
     */
    startDate?: Date;
    /**
     * Ожидаемая дата завершения
     * @type {Date}
     * @memberof TemplateInfoRead
     */
    targetFinishDate?: Date;
    /**
     * Описание
     * @type {string}
     * @memberof TemplateInfoRead
     */
    description?: string;
    /**
     * Маркер нахождения в архиве
     * @type {boolean}
     * @memberof TemplateInfoRead
     */
    archive: boolean;
    /**
     * Может ли быть отправлен в архив (может, когда не является активным)
     * @type {boolean}
     * @memberof TemplateInfoRead
     */
    archivable: boolean;
    /**
     * Сумма, коп.
     * @type {number}
     * @memberof TemplateInfoRead
     */
    rentElementsSum: number;
}
/**
 * 
 * @export
 * @interface TemplateInfoReadAllOf
 */
export interface TemplateInfoReadAllOf {
    /**
     * Маркер нахождения в архиве
     * @type {boolean}
     * @memberof TemplateInfoReadAllOf
     */
    archive: boolean;
    /**
     * Может ли быть отправлен в архив (может, когда не является активным)
     * @type {boolean}
     * @memberof TemplateInfoReadAllOf
     */
    archivable: boolean;
    /**
     * Сумма, коп.
     * @type {number}
     * @memberof TemplateInfoReadAllOf
     */
    rentElementsSum: number;
}
/**
 * Шаблон: состояние при изменении
 * @export
 * @interface TemplateInfoUpdate
 */
export interface TemplateInfoUpdate extends TemplateInfoAbstractWriteCommons {
}
/**
 * Не финансовые поля сущности TemplateRecord, которые могут быть выгружены в список Excel
 * @export
 * @enum {string}
 */
export enum TemplateNotFinancialFieldsTypeCode {
    ShortName = 'shortName',
    LastActivityDate = 'lastActivityDate'
}

/**
 * Списочное представление шаблона
 * @export
 * @interface TemplateRecord
 */
export interface TemplateRecord {
    /**
     * Идентификатор
     * @type {number}
     * @memberof TemplateRecord
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof TemplateRecord
     */
    businessVersion: number;
    /**
     * Ссылка: Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof TemplateRecord
     */
    businessAccountId: number;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof TemplateRecord
     */
    shortName: string;
    /**
     * Находится ли в архиве
     * @type {boolean}
     * @memberof TemplateRecord
     */
    archive: boolean;
    /**
     * Может ли быть отправлен в архив (может, когда не является активным)
     * @type {boolean}
     * @memberof TemplateRecord
     */
    archivable: boolean;
    /**
     * Сумма, коп.
     * @type {number}
     * @memberof TemplateRecord
     */
    rentElementsSum: number;
    /**
     * Процент скидки по умолчанию (из проекта если есть, или наследованный от арендатора)
     * @type {number}
     * @memberof TemplateRecord
     */
    defaultDiscount?: number;
    /**
     * Дата начала работ
     * @type {Date}
     * @memberof TemplateRecord
     */
    startDate: Date;
    /**
     * Ожидаемая дата завершения
     * @type {Date}
     * @memberof TemplateRecord
     */
    targetFinishDate?: Date;
    /**
     * Дата и время последней активности (измение сущности либо операция над связанными обязательствами)
     * @type {Date}
     * @memberof TemplateRecord
     */
    lastActivityDate: Date;
}
/**
 * 
 * @export
 * @interface TemplateRecordAllOf
 */
export interface TemplateRecordAllOf {
    /**
     * Короткое наименование
     * @type {string}
     * @memberof TemplateRecordAllOf
     */
    shortName: string;
    /**
     * Находится ли в архиве
     * @type {boolean}
     * @memberof TemplateRecordAllOf
     */
    archive: boolean;
    /**
     * Может ли быть отправлен в архив (может, когда не является активным)
     * @type {boolean}
     * @memberof TemplateRecordAllOf
     */
    archivable: boolean;
    /**
     * Сумма, коп.
     * @type {number}
     * @memberof TemplateRecordAllOf
     */
    rentElementsSum: number;
    /**
     * Процент скидки по умолчанию (из проекта если есть, или наследованный от арендатора)
     * @type {number}
     * @memberof TemplateRecordAllOf
     */
    defaultDiscount?: number;
    /**
     * Дата начала работ
     * @type {Date}
     * @memberof TemplateRecordAllOf
     */
    startDate: Date;
    /**
     * Ожидаемая дата завершения
     * @type {Date}
     * @memberof TemplateRecordAllOf
     */
    targetFinishDate?: Date;
    /**
     * Дата и время последней активности (измение сущности либо операция над связанными обязательствами)
     * @type {Date}
     * @memberof TemplateRecordAllOf
     */
    lastActivityDate: Date;
}
/**
 * Список записей шаблонов
 * @export
 * @interface TemplateRecordList
 */
export interface TemplateRecordList {
    /**
     * 
     * @type {ListAttributesInfo}
     * @memberof TemplateRecordList
     */
    listAttributes: ListAttributesInfo;
    /**
     * 
     * @type {Array<TemplateRecord>}
     * @memberof TemplateRecordList
     */
    records: Array<TemplateRecord>;
}
/**
 * 
 * @export
 * @interface TemplateRecordListAllOf
 */
export interface TemplateRecordListAllOf {
    /**
     * 
     * @type {Array<TemplateRecord>}
     * @memberof TemplateRecordListAllOf
     */
    records: Array<TemplateRecord>;
}
/**
 * Список, содержащий временные карты состояния продукта/варианта
 * @export
 * @interface TimetableList
 */
export interface TimetableList {
    /**
     * 
     * @type {Array<TimetableObj>}
     * @memberof TimetableList
     */
    maps: Array<TimetableObj>;
}
/**
 * Временная карта состояния продукта/варианта
 * @export
 * @interface TimetableObj
 */
export interface TimetableObj {
    /**
     * 
     * @type {TimetableTypeCodeEnum}
     * @memberof TimetableObj
     */
    typeCode: TimetableTypeCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof TimetableObj
     */
    mapString: string;
}
/**
 * Показатели календарей стеков состояния экземпляров в целевой момент
 * @export
 * @interface TimetableStackCountersObj
 */
export interface TimetableStackCountersObj {
    /**
     * Показатели клендаря стека субаренды экземпляров в целевой момент
     * @type {number}
     * @memberof TimetableStackCountersObj
     */
    subrentedInstanceCount: number;
    /**
     * Показатели клендаря стека занятости экземпляров в целевой момент
     * @type {number}
     * @memberof TimetableStackCountersObj
     */
    occupiedInstanceCount: number;
    /**
     * Показатели клендаря стека доступности экземпляров в целевой момент
     * @type {number}
     * @memberof TimetableStackCountersObj
     */
    availableInstanceCount: number;
}
/**
 * Код типа состояния продукта/варианта
 * @export
 * @enum {string}
 */
export enum TimetableTypeCodeEnum {
    ORDERAVAILABLE = 'ORDER_AVAILABLE',
    AVAILABLE = 'AVAILABLE',
    ORDER = 'ORDER',
    OCCUPIED = 'OCCUPIED',
    MAINTENANCE = 'MAINTENANCE',
    STOCK = 'STOCK',
    SUBRENTED = 'SUBRENTED'
}

/**
 * 
 * @export
 * @interface TransportationInfoAbstractCommons
 */
export interface TransportationInfoAbstractCommons {
    /**
     * Идентификатор
     * @type {number}
     * @memberof TransportationInfoAbstractCommons
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof TransportationInfoAbstractCommons
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof TransportationInfoAbstractCommons
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof TransportationInfoAbstractCommons
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof TransportationInfoAbstractCommons
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof TransportationInfoAbstractCommons
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof TransportationInfoAbstractCommons
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof TransportationInfoAbstractCommons
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof TransportationInfoAbstractCommons
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof TransportationInfoAbstractCommons
     */
    businessAccountId?: number;
    /**
     * Локальная дата транспортной накладной
     * @type {string}
     * @memberof TransportationInfoAbstractCommons
     */
    transportationDate?: string;
    /**
     * Локальная дата запроса (Заявки) (Поле транспортной накладной)
     * @type {string}
     * @memberof TransportationInfoAbstractCommons
     */
    requestDate?: string;
    /**
     * Груз
     * @type {string}
     * @memberof TransportationInfoAbstractCommons
     */
    cargo?: string;
    /**
     * Локальная дата и время погрузки
     * @type {Date}
     * @memberof TransportationInfoAbstractCommons
     */
    dateTimeOfLoading: Date;
    /**
     * Локальная дата и время разгрузки
     * @type {Date}
     * @memberof TransportationInfoAbstractCommons
     */
    dateTimeOfUnloading: Date;
}
/**
 * 
 * @export
 * @interface TransportationInfoAbstractCommonsAllOf
 */
export interface TransportationInfoAbstractCommonsAllOf {
    /**
     * Локальная дата транспортной накладной
     * @type {string}
     * @memberof TransportationInfoAbstractCommonsAllOf
     */
    transportationDate?: string;
    /**
     * Локальная дата запроса (Заявки) (Поле транспортной накладной)
     * @type {string}
     * @memberof TransportationInfoAbstractCommonsAllOf
     */
    requestDate?: string;
    /**
     * Груз
     * @type {string}
     * @memberof TransportationInfoAbstractCommonsAllOf
     */
    cargo?: string;
    /**
     * Локальная дата и время погрузки
     * @type {Date}
     * @memberof TransportationInfoAbstractCommonsAllOf
     */
    dateTimeOfLoading: Date;
    /**
     * Локальная дата и время разгрузки
     * @type {Date}
     * @memberof TransportationInfoAbstractCommonsAllOf
     */
    dateTimeOfUnloading: Date;
}
/**
 * 
 * @export
 * @interface TransportationInfoCreate
 */
export interface TransportationInfoCreate {
    /**
     * Идентификатор
     * @type {number}
     * @memberof TransportationInfoCreate
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof TransportationInfoCreate
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof TransportationInfoCreate
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof TransportationInfoCreate
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof TransportationInfoCreate
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof TransportationInfoCreate
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof TransportationInfoCreate
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof TransportationInfoCreate
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof TransportationInfoCreate
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof TransportationInfoCreate
     */
    businessAccountId?: number;
    /**
     * Локальная дата транспортной накладной
     * @type {string}
     * @memberof TransportationInfoCreate
     */
    transportationDate?: string;
    /**
     * Локальная дата запроса (Заявки) (Поле транспортной накладной)
     * @type {string}
     * @memberof TransportationInfoCreate
     */
    requestDate?: string;
    /**
     * Груз
     * @type {string}
     * @memberof TransportationInfoCreate
     */
    cargo?: string;
    /**
     * Локальная дата и время погрузки
     * @type {Date}
     * @memberof TransportationInfoCreate
     */
    dateTimeOfLoading: Date;
    /**
     * Локальная дата и время разгрузки
     * @type {Date}
     * @memberof TransportationInfoCreate
     */
    dateTimeOfUnloading: Date;
    /**
     * Грузоотправитель, контрагент
     * @type {number}
     * @memberof TransportationInfoCreate
     */
    shipperId?: number;
    /**
     * Грузополучатель, контрагент
     * @type {number}
     * @memberof TransportationInfoCreate
     */
    consigneeId?: number;
    /**
     * Адрес доставки, локация
     * @type {number}
     * @memberof TransportationInfoCreate
     */
    cargoDeliveryLocationId?: number;
    /**
     * Проект
     * @type {number}
     * @memberof TransportationInfoCreate
     */
    projectId?: number;
    /**
     * Автомобиль, транспортное средство
     * @type {number}
     * @memberof TransportationInfoCreate
     */
    carId?: number;
    /**
     * Прицеп, транспортное средство
     * @type {number}
     * @memberof TransportationInfoCreate
     */
    trailerId?: number;
    /**
     * Адрес места погрузки, локация
     * @type {number}
     * @memberof TransportationInfoCreate
     */
    loadingLocationId: number;
    /**
     * Адрес места разгрузки, локация
     * @type {number}
     * @memberof TransportationInfoCreate
     */
    unloadingLocationId: number;
    /**
     * Контакты водителей
     * @type {Array<number>}
     * @memberof TransportationInfoCreate
     */
    driverIds?: Array<number>;
    /**
     * Контакт грузополучателя
     * @type {number}
     * @memberof TransportationInfoCreate
     */
    recipientId?: number;
    /**
     * Контакт приемщика груза (для погрузки в машину)
     * @type {number}
     * @memberof TransportationInfoCreate
     */
    receiverId?: number;
    /**
     * 
     * @type {TransportationStateCodeEnum}
     * @memberof TransportationInfoCreate
     */
    stateCode: TransportationStateCodeEnum;
}
/**
 * 
 * @export
 * @interface TransportationInfoCreateAllOf
 */
export interface TransportationInfoCreateAllOf {
    /**
     * 
     * @type {TransportationStateCodeEnum}
     * @memberof TransportationInfoCreateAllOf
     */
    stateCode: TransportationStateCodeEnum;
}
/**
 * 
 * @export
 * @interface TransportationInfoRead
 */
export interface TransportationInfoRead {
    /**
     * Идентификатор
     * @type {number}
     * @memberof TransportationInfoRead
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof TransportationInfoRead
     */
    businessVersion: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof TransportationInfoRead
     */
    creationAuthorId: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof TransportationInfoRead
     */
    creationAuthorFullName: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof TransportationInfoRead
     */
    creationDate: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof TransportationInfoRead
     */
    lastUpdateAuthorId: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof TransportationInfoRead
     */
    lastUpdateAuthorFullName: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof TransportationInfoRead
     */
    lastUpdateDate: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof TransportationInfoRead
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof TransportationInfoRead
     */
    businessAccountId: number;
    /**
     * Локальная дата транспортной накладной
     * @type {string}
     * @memberof TransportationInfoRead
     */
    transportationDate?: string;
    /**
     * Локальная дата запроса (Заявки) (Поле транспортной накладной)
     * @type {string}
     * @memberof TransportationInfoRead
     */
    requestDate?: string;
    /**
     * Груз
     * @type {string}
     * @memberof TransportationInfoRead
     */
    cargo?: string;
    /**
     * Локальная дата и время погрузки
     * @type {Date}
     * @memberof TransportationInfoRead
     */
    dateTimeOfLoading: Date;
    /**
     * Локальная дата и время разгрузки
     * @type {Date}
     * @memberof TransportationInfoRead
     */
    dateTimeOfUnloading: Date;
    /**
     * Коды доступных переходов для данной перевозки
     * @type {Array<TransportationTransitionCodeEnum>}
     * @memberof TransportationInfoRead
     */
    availableTransitionCodes?: Array<TransportationTransitionCodeEnum>;
    /**
     * Номер
     * @type {number}
     * @memberof TransportationInfoRead
     */
    number: number;
    /**
     * 
     * @type {TransportationStateCodeEnum}
     * @memberof TransportationInfoRead
     */
    stateCode: TransportationStateCodeEnum;
    /**
     * 
     * @type {AttachmentRecordList}
     * @memberof TransportationInfoRead
     */
    attachments?: AttachmentRecordList;
    /**
     * 
     * @type {RenterRecord}
     * @memberof TransportationInfoRead
     */
    shipper?: RenterRecord;
    /**
     * 
     * @type {RenterRecord}
     * @memberof TransportationInfoRead
     */
    consignee?: RenterRecord;
    /**
     * 
     * @type {LocationRecord}
     * @memberof TransportationInfoRead
     */
    cargoDeliveryLocation?: LocationRecord;
    /**
     * 
     * @type {ProjectRecord}
     * @memberof TransportationInfoRead
     */
    project?: ProjectRecord;
    /**
     * 
     * @type {VehicleRecord}
     * @memberof TransportationInfoRead
     */
    car?: VehicleRecord;
    /**
     * 
     * @type {VehicleRecord}
     * @memberof TransportationInfoRead
     */
    trailer?: VehicleRecord;
    /**
     * 
     * @type {LocationRecord}
     * @memberof TransportationInfoRead
     */
    unloadingLocation: LocationRecord;
    /**
     * 
     * @type {LocationRecord}
     * @memberof TransportationInfoRead
     */
    loadingLocation: LocationRecord;
    /**
     * 
     * @type {ContactLinkObjRead}
     * @memberof TransportationInfoRead
     */
    receiver?: ContactLinkObjRead;
    /**
     * 
     * @type {ContactLinkObjRead}
     * @memberof TransportationInfoRead
     */
    recipient?: ContactLinkObjRead;
    /**
     * Контакты водителей
     * @type {Array<ContactLinkObjRead>}
     * @memberof TransportationInfoRead
     */
    drivers?: Array<ContactLinkObjRead>;
}
/**
 * 
 * @export
 * @interface TransportationInfoReadAllOf
 */
export interface TransportationInfoReadAllOf {
    /**
     * Коды доступных переходов для данной перевозки
     * @type {Array<TransportationTransitionCodeEnum>}
     * @memberof TransportationInfoReadAllOf
     */
    availableTransitionCodes?: Array<TransportationTransitionCodeEnum>;
    /**
     * Номер
     * @type {number}
     * @memberof TransportationInfoReadAllOf
     */
    number: number;
    /**
     * 
     * @type {TransportationStateCodeEnum}
     * @memberof TransportationInfoReadAllOf
     */
    stateCode: TransportationStateCodeEnum;
    /**
     * 
     * @type {AttachmentRecordList}
     * @memberof TransportationInfoReadAllOf
     */
    attachments?: AttachmentRecordList;
    /**
     * 
     * @type {RenterRecord}
     * @memberof TransportationInfoReadAllOf
     */
    shipper?: RenterRecord;
    /**
     * 
     * @type {RenterRecord}
     * @memberof TransportationInfoReadAllOf
     */
    consignee?: RenterRecord;
    /**
     * 
     * @type {LocationRecord}
     * @memberof TransportationInfoReadAllOf
     */
    cargoDeliveryLocation?: LocationRecord;
    /**
     * 
     * @type {ProjectRecord}
     * @memberof TransportationInfoReadAllOf
     */
    project?: ProjectRecord;
    /**
     * 
     * @type {VehicleRecord}
     * @memberof TransportationInfoReadAllOf
     */
    car?: VehicleRecord;
    /**
     * 
     * @type {VehicleRecord}
     * @memberof TransportationInfoReadAllOf
     */
    trailer?: VehicleRecord;
    /**
     * 
     * @type {LocationRecord}
     * @memberof TransportationInfoReadAllOf
     */
    unloadingLocation: LocationRecord;
    /**
     * 
     * @type {LocationRecord}
     * @memberof TransportationInfoReadAllOf
     */
    loadingLocation: LocationRecord;
    /**
     * 
     * @type {ContactLinkObjRead}
     * @memberof TransportationInfoReadAllOf
     */
    receiver?: ContactLinkObjRead;
    /**
     * 
     * @type {ContactLinkObjRead}
     * @memberof TransportationInfoReadAllOf
     */
    recipient?: ContactLinkObjRead;
    /**
     * Контакты водителей
     * @type {Array<ContactLinkObjRead>}
     * @memberof TransportationInfoReadAllOf
     */
    drivers?: Array<ContactLinkObjRead>;
}
/**
 * 
 * @export
 * @interface TransportationInfoUpdate
 */
export interface TransportationInfoUpdate extends TransportationInfoWriteCommons {
}
/**
 * 
 * @export
 * @interface TransportationInfoWriteCommons
 */
export interface TransportationInfoWriteCommons {
    /**
     * Идентификатор
     * @type {number}
     * @memberof TransportationInfoWriteCommons
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof TransportationInfoWriteCommons
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof TransportationInfoWriteCommons
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof TransportationInfoWriteCommons
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof TransportationInfoWriteCommons
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof TransportationInfoWriteCommons
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof TransportationInfoWriteCommons
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof TransportationInfoWriteCommons
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof TransportationInfoWriteCommons
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof TransportationInfoWriteCommons
     */
    businessAccountId?: number;
    /**
     * Локальная дата транспортной накладной
     * @type {string}
     * @memberof TransportationInfoWriteCommons
     */
    transportationDate?: string;
    /**
     * Локальная дата запроса (Заявки) (Поле транспортной накладной)
     * @type {string}
     * @memberof TransportationInfoWriteCommons
     */
    requestDate?: string;
    /**
     * Груз
     * @type {string}
     * @memberof TransportationInfoWriteCommons
     */
    cargo?: string;
    /**
     * Локальная дата и время погрузки
     * @type {Date}
     * @memberof TransportationInfoWriteCommons
     */
    dateTimeOfLoading: Date;
    /**
     * Локальная дата и время разгрузки
     * @type {Date}
     * @memberof TransportationInfoWriteCommons
     */
    dateTimeOfUnloading: Date;
    /**
     * Грузоотправитель, контрагент
     * @type {number}
     * @memberof TransportationInfoWriteCommons
     */
    shipperId?: number;
    /**
     * Грузополучатель, контрагент
     * @type {number}
     * @memberof TransportationInfoWriteCommons
     */
    consigneeId?: number;
    /**
     * Адрес доставки, локация
     * @type {number}
     * @memberof TransportationInfoWriteCommons
     */
    cargoDeliveryLocationId?: number;
    /**
     * Проект
     * @type {number}
     * @memberof TransportationInfoWriteCommons
     */
    projectId?: number;
    /**
     * Автомобиль, транспортное средство
     * @type {number}
     * @memberof TransportationInfoWriteCommons
     */
    carId?: number;
    /**
     * Прицеп, транспортное средство
     * @type {number}
     * @memberof TransportationInfoWriteCommons
     */
    trailerId?: number;
    /**
     * Адрес места погрузки, локация
     * @type {number}
     * @memberof TransportationInfoWriteCommons
     */
    loadingLocationId: number;
    /**
     * Адрес места разгрузки, локация
     * @type {number}
     * @memberof TransportationInfoWriteCommons
     */
    unloadingLocationId: number;
    /**
     * Контакты водителей
     * @type {Array<number>}
     * @memberof TransportationInfoWriteCommons
     */
    driverIds?: Array<number>;
    /**
     * Контакт грузополучателя
     * @type {number}
     * @memberof TransportationInfoWriteCommons
     */
    recipientId?: number;
    /**
     * Контакт приемщика груза (для погрузки в машину)
     * @type {number}
     * @memberof TransportationInfoWriteCommons
     */
    receiverId?: number;
}
/**
 * 
 * @export
 * @interface TransportationInfoWriteCommonsAllOf
 */
export interface TransportationInfoWriteCommonsAllOf {
    /**
     * Грузоотправитель, контрагент
     * @type {number}
     * @memberof TransportationInfoWriteCommonsAllOf
     */
    shipperId?: number;
    /**
     * Грузополучатель, контрагент
     * @type {number}
     * @memberof TransportationInfoWriteCommonsAllOf
     */
    consigneeId?: number;
    /**
     * Адрес доставки, локация
     * @type {number}
     * @memberof TransportationInfoWriteCommonsAllOf
     */
    cargoDeliveryLocationId?: number;
    /**
     * Проект
     * @type {number}
     * @memberof TransportationInfoWriteCommonsAllOf
     */
    projectId?: number;
    /**
     * Автомобиль, транспортное средство
     * @type {number}
     * @memberof TransportationInfoWriteCommonsAllOf
     */
    carId?: number;
    /**
     * Прицеп, транспортное средство
     * @type {number}
     * @memberof TransportationInfoWriteCommonsAllOf
     */
    trailerId?: number;
    /**
     * Адрес места погрузки, локация
     * @type {number}
     * @memberof TransportationInfoWriteCommonsAllOf
     */
    loadingLocationId: number;
    /**
     * Адрес места разгрузки, локация
     * @type {number}
     * @memberof TransportationInfoWriteCommonsAllOf
     */
    unloadingLocationId: number;
    /**
     * Контакты водителей
     * @type {Array<number>}
     * @memberof TransportationInfoWriteCommonsAllOf
     */
    driverIds?: Array<number>;
    /**
     * Контакт грузополучателя
     * @type {number}
     * @memberof TransportationInfoWriteCommonsAllOf
     */
    recipientId?: number;
    /**
     * Контакт приемщика груза (для погрузки в машину)
     * @type {number}
     * @memberof TransportationInfoWriteCommonsAllOf
     */
    receiverId?: number;
}
/**
 * Все поля сущности TransportationRecord, которые могут быть выгружены в список Excel. Финансовых полей нет
 * @export
 * @enum {string}
 */
export enum TransportationNotFinancialFieldsTypeCode {
    Number = 'number',
    ProjectName = 'projectName',
    StateCode = 'stateCode',
    DateTimeOfLoading = 'dateTimeOfLoading',
    ShipperName = 'shipperName',
    LoadingLocationName = 'loadingLocationName',
    DateTimeOfUnloading = 'dateTimeOfUnloading',
    ConsigneeName = 'consigneeName',
    UnloadingLocationName = 'unloadingLocationName',
    CarName = 'carName',
    TrailerName = 'trailerName',
    AttachmentCount = 'attachmentCount',
    LastUpdateAuthorShortName = 'lastUpdateAuthorShortName',
    LastUpdateDate = 'lastUpdateDate'
}

/**
 * 
 * @export
 * @interface TransportationRecord
 */
export interface TransportationRecord {
    /**
     * Идентификатор
     * @type {number}
     * @memberof TransportationRecord
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof TransportationRecord
     */
    businessVersion: number;
    /**
     * Ссылка: Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof TransportationRecord
     */
    businessAccountId: number;
    /**
     * Номер
     * @type {number}
     * @memberof TransportationRecord
     */
    number: number;
    /**
     * Контакты водителей
     * @type {Array<number>}
     * @memberof TransportationRecord
     */
    driverIds?: Array<number>;
    /**
     * Локальная дата транспортной накладной
     * @type {Date}
     * @memberof TransportationRecord
     */
    transportationDate?: Date;
    /**
     * 
     * @type {TransportationStateCodeEnum}
     * @memberof TransportationRecord
     */
    stateCode: TransportationStateCodeEnum;
    /**
     * Коды доступных переходов для данной перевозки
     * @type {Array<TransportationTransitionCodeEnum>}
     * @memberof TransportationRecord
     */
    availableTransitionCodes?: Array<TransportationTransitionCodeEnum>;
    /**
     * Идентификатор грузоотправителя (контрагент)
     * @type {number}
     * @memberof TransportationRecord
     */
    shipperId?: number;
    /**
     * Наименование грузоотправителя (контрагент)
     * @type {string}
     * @memberof TransportationRecord
     */
    shipperName?: string;
    /**
     * Идентификатор грузополучателя (контрагент)
     * @type {number}
     * @memberof TransportationRecord
     */
    consigneeId?: number;
    /**
     * Наименование грузополучателя (контрагент)
     * @type {string}
     * @memberof TransportationRecord
     */
    consigneeName?: string;
    /**
     * Идентификатор адреса доставки (локация)
     * @type {number}
     * @memberof TransportationRecord
     */
    cargoDeliveryLocationId?: number;
    /**
     * Наименование адреса доставки (локация)
     * @type {string}
     * @memberof TransportationRecord
     */
    cargoDeliveryLocationName?: string;
    /**
     * Идентификатор проекта
     * @type {number}
     * @memberof TransportationRecord
     */
    projectId?: number;
    /**
     * Наименование проекта
     * @type {string}
     * @memberof TransportationRecord
     */
    projectName?: string;
    /**
     * Груз
     * @type {string}
     * @memberof TransportationRecord
     */
    cargo?: string;
    /**
     * Идентификатор автомобиля (транспорт)
     * @type {number}
     * @memberof TransportationRecord
     */
    carId?: number;
    /**
     * Наименование автомобиля (транспорт)
     * @type {string}
     * @memberof TransportationRecord
     */
    carName?: string;
    /**
     * Идентификатор прицепа (транспорт)
     * @type {number}
     * @memberof TransportationRecord
     */
    trailerId?: number;
    /**
     * Наименование прицепа (транспорт)
     * @type {string}
     * @memberof TransportationRecord
     */
    trailerName?: string;
    /**
     * Идентификатор адреса места погрузки (локация)
     * @type {number}
     * @memberof TransportationRecord
     */
    loadingLocationId: number;
    /**
     * Наименование адреса места погрузки (локация)
     * @type {string}
     * @memberof TransportationRecord
     */
    loadingLocationName: string;
    /**
     * Локальная дата и время погрузки
     * @type {Date}
     * @memberof TransportationRecord
     */
    dateTimeOfLoading: Date;
    /**
     * Идентификатор адреса места разгрузки (локация)
     * @type {number}
     * @memberof TransportationRecord
     */
    unloadingLocationId: number;
    /**
     * Наименование адреса места разгрузки (локация)
     * @type {string}
     * @memberof TransportationRecord
     */
    unloadingLocationName: string;
    /**
     * Локальная дата и время разгрузки
     * @type {Date}
     * @memberof TransportationRecord
     */
    dateTimeOfUnloading: Date;
    /**
     * Ссылка: идентификатор автора посл. изменения
     * @type {number}
     * @memberof TransportationRecord
     */
    lastUpdateAuthorId: number;
    /**
     * Выч. по ссылке: короткое ФИО автора посл. изменения
     * @type {string}
     * @memberof TransportationRecord
     */
    lastUpdateAuthorShortName: string;
    /**
     * Дата и время посл. изменения
     * @type {Date}
     * @memberof TransportationRecord
     */
    lastUpdateDate: Date;
    /**
     * Число вложений
     * @type {number}
     * @memberof TransportationRecord
     */
    attachmentCount?: number;
    /**
     * Может ли быть удалена (может, когда нет входящих ссылок)
     * @type {boolean}
     * @memberof TransportationRecord
     */
    deleteable?: boolean;
}
/**
 * 
 * @export
 * @interface TransportationRecordAllOf
 */
export interface TransportationRecordAllOf {
    /**
     * Номер
     * @type {number}
     * @memberof TransportationRecordAllOf
     */
    number: number;
    /**
     * Контакты водителей
     * @type {Array<number>}
     * @memberof TransportationRecordAllOf
     */
    driverIds?: Array<number>;
    /**
     * Локальная дата транспортной накладной
     * @type {Date}
     * @memberof TransportationRecordAllOf
     */
    transportationDate?: Date;
    /**
     * 
     * @type {TransportationStateCodeEnum}
     * @memberof TransportationRecordAllOf
     */
    stateCode: TransportationStateCodeEnum;
    /**
     * Коды доступных переходов для данной перевозки
     * @type {Array<TransportationTransitionCodeEnum>}
     * @memberof TransportationRecordAllOf
     */
    availableTransitionCodes?: Array<TransportationTransitionCodeEnum>;
    /**
     * Идентификатор грузоотправителя (контрагент)
     * @type {number}
     * @memberof TransportationRecordAllOf
     */
    shipperId?: number;
    /**
     * Наименование грузоотправителя (контрагент)
     * @type {string}
     * @memberof TransportationRecordAllOf
     */
    shipperName?: string;
    /**
     * Идентификатор грузополучателя (контрагент)
     * @type {number}
     * @memberof TransportationRecordAllOf
     */
    consigneeId?: number;
    /**
     * Наименование грузополучателя (контрагент)
     * @type {string}
     * @memberof TransportationRecordAllOf
     */
    consigneeName?: string;
    /**
     * Идентификатор адреса доставки (локация)
     * @type {number}
     * @memberof TransportationRecordAllOf
     */
    cargoDeliveryLocationId?: number;
    /**
     * Наименование адреса доставки (локация)
     * @type {string}
     * @memberof TransportationRecordAllOf
     */
    cargoDeliveryLocationName?: string;
    /**
     * Идентификатор проекта
     * @type {number}
     * @memberof TransportationRecordAllOf
     */
    projectId?: number;
    /**
     * Наименование проекта
     * @type {string}
     * @memberof TransportationRecordAllOf
     */
    projectName?: string;
    /**
     * Груз
     * @type {string}
     * @memberof TransportationRecordAllOf
     */
    cargo?: string;
    /**
     * Идентификатор автомобиля (транспорт)
     * @type {number}
     * @memberof TransportationRecordAllOf
     */
    carId?: number;
    /**
     * Наименование автомобиля (транспорт)
     * @type {string}
     * @memberof TransportationRecordAllOf
     */
    carName?: string;
    /**
     * Идентификатор прицепа (транспорт)
     * @type {number}
     * @memberof TransportationRecordAllOf
     */
    trailerId?: number;
    /**
     * Наименование прицепа (транспорт)
     * @type {string}
     * @memberof TransportationRecordAllOf
     */
    trailerName?: string;
    /**
     * Идентификатор адреса места погрузки (локация)
     * @type {number}
     * @memberof TransportationRecordAllOf
     */
    loadingLocationId: number;
    /**
     * Наименование адреса места погрузки (локация)
     * @type {string}
     * @memberof TransportationRecordAllOf
     */
    loadingLocationName: string;
    /**
     * Локальная дата и время погрузки
     * @type {Date}
     * @memberof TransportationRecordAllOf
     */
    dateTimeOfLoading: Date;
    /**
     * Идентификатор адреса места разгрузки (локация)
     * @type {number}
     * @memberof TransportationRecordAllOf
     */
    unloadingLocationId: number;
    /**
     * Наименование адреса места разгрузки (локация)
     * @type {string}
     * @memberof TransportationRecordAllOf
     */
    unloadingLocationName: string;
    /**
     * Локальная дата и время разгрузки
     * @type {Date}
     * @memberof TransportationRecordAllOf
     */
    dateTimeOfUnloading: Date;
    /**
     * Ссылка: идентификатор автора посл. изменения
     * @type {number}
     * @memberof TransportationRecordAllOf
     */
    lastUpdateAuthorId: number;
    /**
     * Выч. по ссылке: короткое ФИО автора посл. изменения
     * @type {string}
     * @memberof TransportationRecordAllOf
     */
    lastUpdateAuthorShortName: string;
    /**
     * Дата и время посл. изменения
     * @type {Date}
     * @memberof TransportationRecordAllOf
     */
    lastUpdateDate: Date;
    /**
     * Число вложений
     * @type {number}
     * @memberof TransportationRecordAllOf
     */
    attachmentCount?: number;
    /**
     * Может ли быть удалена (может, когда нет входящих ссылок)
     * @type {boolean}
     * @memberof TransportationRecordAllOf
     */
    deleteable?: boolean;
}
/**
 * 
 * @export
 * @interface TransportationRecordList
 */
export interface TransportationRecordList {
    /**
     * 
     * @type {ListAttributesInfo}
     * @memberof TransportationRecordList
     */
    listAttributes: ListAttributesInfo;
    /**
     * 
     * @type {Array<TransportationRecord>}
     * @memberof TransportationRecordList
     */
    records: Array<TransportationRecord>;
}
/**
 * 
 * @export
 * @interface TransportationRecordListAllOf
 */
export interface TransportationRecordListAllOf {
    /**
     * 
     * @type {Array<TransportationRecord>}
     * @memberof TransportationRecordListAllOf
     */
    records: Array<TransportationRecord>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TransportationStateCodeEnum {
    PLANNED = 'PLANNED',
    INPROGRESS = 'IN_PROGRESS',
    FINISHED = 'FINISHED',
    CANCELED = 'CANCELED'
}

/**
 * Код перехода между статусами перевозки
 * @export
 * @enum {string}
 */
export enum TransportationTransitionCodeEnum {
    PLAN = 'PLAN',
    START = 'START',
    FINISH = 'FINISH',
    CANCEL = 'CANCEL'
}

/**
 * Код типа ценообразования
 * @export
 * @enum {string}
 */
export enum TypeOfPricingCodeEnum {
    STRAIGHT = 'STRAIGHT',
    FROMOFFERS = 'FROM_OFFERS'
}

/**
 * Объект для изменения связей сущностей на основе категорий
 * @export
 * @interface UpdateLinksByCategoryObj
 */
export interface UpdateLinksByCategoryObj {
    /**
     * 
     * @type {CategorizedEntityTypeCodeEnum}
     * @memberof UpdateLinksByCategoryObj
     */
    entityTypeCode: CategorizedEntityTypeCodeEnum;
    /**
     * Идентификаторы сущностей, которые мы привязываем или отвязываем от сущностей, разбитых на категории (например Пользовательские поля)
     * @type {Array<number>}
     * @memberof UpdateLinksByCategoryObj
     */
    entityToProcessIds: Array<number>;
    /**
     * Флаг, если false - привязываем, если true - отвязываем категории
     * @type {boolean}
     * @memberof UpdateLinksByCategoryObj
     */
    unlink: boolean;
    /**
     * Идентификаторы категорий, по которым нужно выбрать сущности для добавления связей (Например продукты; сущности ищутся в том числе по подкотегориям переданных категорий)
     * @type {Array<number>}
     * @memberof UpdateLinksByCategoryObj
     */
    categoryIds: Array<number>;
}
/**
 * 
 * @export
 * @interface UpdatePassword
 */
export interface UpdatePassword {
    /**
     * 
     * @type {string}
     * @memberof UpdatePassword
     */
    newPassword: string;
}
/**
 * 
 * @export
 * @interface UpdatedEntityInfo
 */
export interface UpdatedEntityInfo {
    /**
     * Новая версия измененной сущности
     * @type {number}
     * @memberof UpdatedEntityInfo
     */
    businessVersion: number;
}
/**
 * Роль пользователя в бизнес аккаунте
 * @export
 * @enum {string}
 */
export enum UserBARoleCodeEnum {
    STOREKEEPER = 'STOREKEEPER',
    AGENT = 'AGENT',
    ADMIN = 'ADMIN'
}

/**
 * Информация об изменении локальных ролей пользователя в бизнес аккаунте
 * @export
 * @interface UserBARolesUpdateObj
 */
export interface UserBARolesUpdateObj {
    /**
     * Бизнес версия пользователя RR
     * @type {number}
     * @memberof UserBARolesUpdateObj
     */
    businessVersion: number;
    /**
     * 
     * @type {UserBARoleCodeEnum}
     * @memberof UserBARolesUpdateObj
     */
    baRole?: UserBARoleCodeEnum;
}
/**
 * Информация о приглашении пользователя в RentRabbit и бизнес аккаунт
 * @export
 * @interface UserBaInvitationInfoRead
 */
export interface UserBaInvitationInfoRead {
    /**
     * Список целевых ролей в бизнес аккаунте
     * @type {Array<UserBARoleCodeEnum>}
     * @memberof UserBaInvitationInfoRead
     */
    baRoles: Array<UserBARoleCodeEnum>;
    /**
     * Идентификатор целевого бизнес аккаунта
     * @type {number}
     * @memberof UserBaInvitationInfoRead
     */
    businessAccountId: number;
    /**
     * Полное официальное наименование целевого бизнес аккаунта
     * @type {string}
     * @memberof UserBaInvitationInfoRead
     */
    businessAccountFullName: string;
}
/**
 * 
 * @export
 * @interface UserInfoRead
 */
export interface UserInfoRead {
    /**
     * Идентификатор
     * @type {number}
     * @memberof UserInfoRead
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof UserInfoRead
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof UserInfoRead
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof UserInfoRead
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof UserInfoRead
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof UserInfoRead
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof UserInfoRead
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof UserInfoRead
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof UserInfoRead
     */
    comment?: string;
    /**
     * Является ли спец. пользователем - системой
     * @type {boolean}
     * @memberof UserInfoRead
     */
    specialSystemUser: boolean;
    /**
     * Наименование/логин пользователя
     * @type {string}
     * @memberof UserInfoRead
     */
    username: string;
    /**
     * Электронная почта - основной внешний идентификатор пользователя, уникальный
     * @type {string}
     * @memberof UserInfoRead
     */
    email: string;
    /**
     * 
     * @type {PersonFullName}
     * @memberof UserInfoRead
     */
    fullName: PersonFullName;
    /**
     * Короткое ФИО - фамилия и инициалы
     * @type {string}
     * @memberof UserInfoRead
     */
    shortName: string;
    /**
     * Номер телефона
     * @type {string}
     * @memberof UserInfoRead
     */
    phoneNumber: string;
    /**
     * Локаль
     * @type {string}
     * @memberof UserInfoRead
     */
    locale: string;
    /**
     * Роли
     * @type {Array<string>}
     * @memberof UserInfoRead
     */
    roles: Array<string>;
}
/**
 * Пользователь
 * @export
 * @interface UserInfoReadAllOf
 */
export interface UserInfoReadAllOf {
    /**
     * Является ли спец. пользователем - системой
     * @type {boolean}
     * @memberof UserInfoReadAllOf
     */
    specialSystemUser: boolean;
    /**
     * Наименование/логин пользователя
     * @type {string}
     * @memberof UserInfoReadAllOf
     */
    username: string;
    /**
     * Электронная почта - основной внешний идентификатор пользователя, уникальный
     * @type {string}
     * @memberof UserInfoReadAllOf
     */
    email: string;
    /**
     * 
     * @type {PersonFullName}
     * @memberof UserInfoReadAllOf
     */
    fullName: PersonFullName;
    /**
     * Короткое ФИО - фамилия и инициалы
     * @type {string}
     * @memberof UserInfoReadAllOf
     */
    shortName: string;
    /**
     * Номер телефона
     * @type {string}
     * @memberof UserInfoReadAllOf
     */
    phoneNumber: string;
    /**
     * Локаль
     * @type {string}
     * @memberof UserInfoReadAllOf
     */
    locale: string;
    /**
     * Роли
     * @type {Array<string>}
     * @memberof UserInfoReadAllOf
     */
    roles: Array<string>;
}
/**
 * 
 * @export
 * @interface UserInfoUpdate
 */
export interface UserInfoUpdate {
    /**
     * Идентификатор
     * @type {number}
     * @memberof UserInfoUpdate
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof UserInfoUpdate
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof UserInfoUpdate
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof UserInfoUpdate
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof UserInfoUpdate
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof UserInfoUpdate
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof UserInfoUpdate
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof UserInfoUpdate
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof UserInfoUpdate
     */
    comment?: string;
    /**
     * Имя
     * @type {string}
     * @memberof UserInfoUpdate
     */
    firstName: string;
    /**
     * Фамилия
     * @type {string}
     * @memberof UserInfoUpdate
     */
    lastName: string;
    /**
     * Отчество
     * @type {string}
     * @memberof UserInfoUpdate
     */
    secondName?: string;
    /**
     * Электронная почта - основной внешний идентификатор пользователя, уникальный
     * @type {string}
     * @memberof UserInfoUpdate
     */
    email: string;
    /**
     * Номер телефона
     * @type {string}
     * @memberof UserInfoUpdate
     */
    phoneNumber?: string;
    /**
     * 
     * @type {LocaleCodeEnum}
     * @memberof UserInfoUpdate
     */
    locale: LocaleCodeEnum;
}
/**
 * Пользователь
 * @export
 * @interface UserInfoUpdateAllOf
 */
export interface UserInfoUpdateAllOf {
    /**
     * Имя
     * @type {string}
     * @memberof UserInfoUpdateAllOf
     */
    firstName: string;
    /**
     * Фамилия
     * @type {string}
     * @memberof UserInfoUpdateAllOf
     */
    lastName: string;
    /**
     * Отчество
     * @type {string}
     * @memberof UserInfoUpdateAllOf
     */
    secondName?: string;
    /**
     * Электронная почта - основной внешний идентификатор пользователя, уникальный
     * @type {string}
     * @memberof UserInfoUpdateAllOf
     */
    email: string;
    /**
     * Номер телефона
     * @type {string}
     * @memberof UserInfoUpdateAllOf
     */
    phoneNumber?: string;
    /**
     * 
     * @type {LocaleCodeEnum}
     * @memberof UserInfoUpdateAllOf
     */
    locale: LocaleCodeEnum;
}
/**
 * Информация об изменении локальных ролей пользователя в бизнес аккаунте
 * @export
 * @interface UserInviteToBaObj
 */
export interface UserInviteToBaObj {
    /**
     * Электронная почта
     * @type {string}
     * @memberof UserInviteToBaObj
     */
    email: string;
    /**
     * 
     * @type {UserBARoleCodeEnum}
     * @memberof UserInviteToBaObj
     */
    baRole?: UserBARoleCodeEnum;
}
/**
 * 
 * @export
 * @interface UserRecord
 */
export interface UserRecord {
    /**
     * Идентификатор
     * @type {number}
     * @memberof UserRecord
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof UserRecord
     */
    businessVersion: number;
    /**
     * Является ли спец. пользователем - системой
     * @type {boolean}
     * @memberof UserRecord
     */
    specialSystemUser: boolean;
    /**
     * Наименование/логин пользователя
     * @type {string}
     * @memberof UserRecord
     */
    username: string;
    /**
     * Электронная почта - основной внешний идентификатор пользователя, уникальный
     * @type {string}
     * @memberof UserRecord
     */
    email: string;
    /**
     * 
     * @type {PersonFullName}
     * @memberof UserRecord
     */
    fullName: PersonFullName;
    /**
     * Короткое ФИО - фамилия и инициалы
     * @type {string}
     * @memberof UserRecord
     */
    shortName: string;
    /**
     * 
     * @type {UserStateCodeEnum}
     * @memberof UserRecord
     */
    stateCode: UserStateCodeEnum;
    /**
     * Подготовленный номер телефона
     * @type {string}
     * @memberof UserRecord
     */
    phoneNumber?: string;
    /**
     * Идентификатор контакта в конкретном БА
     * @type {number}
     * @memberof UserRecord
     */
    contactId?: number;
}
/**
 * Запись пользователя
 * @export
 * @interface UserRecordAllOf
 */
export interface UserRecordAllOf {
    /**
     * Является ли спец. пользователем - системой
     * @type {boolean}
     * @memberof UserRecordAllOf
     */
    specialSystemUser: boolean;
    /**
     * Наименование/логин пользователя
     * @type {string}
     * @memberof UserRecordAllOf
     */
    username: string;
    /**
     * Электронная почта - основной внешний идентификатор пользователя, уникальный
     * @type {string}
     * @memberof UserRecordAllOf
     */
    email: string;
    /**
     * 
     * @type {PersonFullName}
     * @memberof UserRecordAllOf
     */
    fullName: PersonFullName;
    /**
     * Короткое ФИО - фамилия и инициалы
     * @type {string}
     * @memberof UserRecordAllOf
     */
    shortName: string;
    /**
     * 
     * @type {UserStateCodeEnum}
     * @memberof UserRecordAllOf
     */
    stateCode: UserStateCodeEnum;
    /**
     * Подготовленный номер телефона
     * @type {string}
     * @memberof UserRecordAllOf
     */
    phoneNumber?: string;
    /**
     * Идентификатор контакта в конкретном БА
     * @type {number}
     * @memberof UserRecordAllOf
     */
    contactId?: number;
}
/**
 * Список записей о пользователях
 * @export
 * @interface UserRecordList
 */
export interface UserRecordList {
    /**
     * 
     * @type {ListAttributesInfo}
     * @memberof UserRecordList
     */
    listAttributes: ListAttributesInfo;
    /**
     * 
     * @type {Array<UserRecord>}
     * @memberof UserRecordList
     */
    records: Array<UserRecord>;
}
/**
 * 
 * @export
 * @interface UserRecordListAllOf
 */
export interface UserRecordListAllOf {
    /**
     * 
     * @type {Array<UserRecord>}
     * @memberof UserRecordListAllOf
     */
    records: Array<UserRecord>;
}
/**
 * Статус пользователя
 * @export
 * @enum {string}
 */
export enum UserStateCodeEnum {
    INVITED = 'INVITED',
    ACTIVE = 'ACTIVE',
    BLOCKED = 'BLOCKED'
}

/**
 * Финансовые поля сущности VariantRecord, которые могут быть выгружены в список Excel
 * @export
 * @enum {string}
 */
export enum VariantFinancialFieldsTypeCode {
    PricePerShift = 'pricePerShift'
}

/**
 * Вариант: абстрактное общее состояние для всех операций
 * @export
 * @interface VariantInfoAbstractCommons
 */
export interface VariantInfoAbstractCommons {
    /**
     * Идентификатор
     * @type {number}
     * @memberof VariantInfoAbstractCommons
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof VariantInfoAbstractCommons
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof VariantInfoAbstractCommons
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof VariantInfoAbstractCommons
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof VariantInfoAbstractCommons
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof VariantInfoAbstractCommons
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof VariantInfoAbstractCommons
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof VariantInfoAbstractCommons
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof VariantInfoAbstractCommons
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof VariantInfoAbstractCommons
     */
    businessAccountId?: number;
    /**
     * Выч: информация об изображении
     * @type {ImageObj}
     * @memberof VariantInfoAbstractCommons
     */
    image?: ImageObj;
    /**
     * Наименование
     * @type {string}
     * @memberof VariantInfoAbstractCommons
     */
    name: string;
    /**
     * Артикул
     * @type {string}
     * @memberof VariantInfoAbstractCommons
     */
    externalCode?: string;
    /**
     * Штриховой код
     * @type {string}
     * @memberof VariantInfoAbstractCommons
     */
    barcode?: string;
    /**
     * Маркер, указывающий, что вариант имеет собственный отдельный артикул, а не наследует его от продукта
     * @type {boolean}
     * @memberof VariantInfoAbstractCommons
     */
    hasOwnExternalCode?: boolean;
    /**
     * 
     * @type {Array<RentPriceForTimeMeasureObj>}
     * @memberof VariantInfoAbstractCommons
     */
    prices?: Array<RentPriceForTimeMeasureObj>;
    /**
     * Цена за смену, коп.
     * @type {number}
     * @memberof VariantInfoAbstractCommons
     */
    pricePerShift?: number;
    /**
     * Маркер, указывающий, что вариант имеет собственную отдельную цену, а не наследует ее от продукта
     * @type {boolean}
     * @memberof VariantInfoAbstractCommons
     */
    hasOwnPricePerShift?: boolean;
    /**
     * Версия продукта. Для защиты от перезаписи свойств продукта - stockInstanceCount и underMaintenanceInstanceCount, которые получаются суммированием по вариантам (если продукт содержит варианты)
     * @type {number}
     * @memberof VariantInfoAbstractCommons
     */
    productBusinessVersion?: number;
}
/**
 * 
 * @export
 * @interface VariantInfoAbstractCommonsAllOf
 */
export interface VariantInfoAbstractCommonsAllOf {
    /**
     * Выч: информация об изображении
     * @type {ImageObj}
     * @memberof VariantInfoAbstractCommonsAllOf
     */
    image?: ImageObj;
    /**
     * Наименование
     * @type {string}
     * @memberof VariantInfoAbstractCommonsAllOf
     */
    name: string;
    /**
     * Артикул
     * @type {string}
     * @memberof VariantInfoAbstractCommonsAllOf
     */
    externalCode?: string;
    /**
     * Штриховой код
     * @type {string}
     * @memberof VariantInfoAbstractCommonsAllOf
     */
    barcode?: string;
    /**
     * Маркер, указывающий, что вариант имеет собственный отдельный артикул, а не наследует его от продукта
     * @type {boolean}
     * @memberof VariantInfoAbstractCommonsAllOf
     */
    hasOwnExternalCode?: boolean;
    /**
     * 
     * @type {Array<RentPriceForTimeMeasureObj>}
     * @memberof VariantInfoAbstractCommonsAllOf
     */
    prices?: Array<RentPriceForTimeMeasureObj>;
    /**
     * Цена за смену, коп.
     * @type {number}
     * @memberof VariantInfoAbstractCommonsAllOf
     */
    pricePerShift?: number;
    /**
     * Маркер, указывающий, что вариант имеет собственную отдельную цену, а не наследует ее от продукта
     * @type {boolean}
     * @memberof VariantInfoAbstractCommonsAllOf
     */
    hasOwnPricePerShift?: boolean;
    /**
     * Версия продукта. Для защиты от перезаписи свойств продукта - stockInstanceCount и underMaintenanceInstanceCount, которые получаются суммированием по вариантам (если продукт содержит варианты)
     * @type {number}
     * @memberof VariantInfoAbstractCommonsAllOf
     */
    productBusinessVersion?: number;
}
/**
 * Вариант: абстрактное общее состояние состояние при создании и изменении
 * @export
 * @interface VariantInfoAbstractWriteCommons
 */
export interface VariantInfoAbstractWriteCommons {
    /**
     * Идентификатор
     * @type {number}
     * @memberof VariantInfoAbstractWriteCommons
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof VariantInfoAbstractWriteCommons
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof VariantInfoAbstractWriteCommons
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof VariantInfoAbstractWriteCommons
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof VariantInfoAbstractWriteCommons
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof VariantInfoAbstractWriteCommons
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof VariantInfoAbstractWriteCommons
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof VariantInfoAbstractWriteCommons
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof VariantInfoAbstractWriteCommons
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof VariantInfoAbstractWriteCommons
     */
    businessAccountId?: number;
    /**
     * Выч: информация об изображении
     * @type {ImageObj}
     * @memberof VariantInfoAbstractWriteCommons
     */
    image?: ImageObj;
    /**
     * Наименование
     * @type {string}
     * @memberof VariantInfoAbstractWriteCommons
     */
    name: string;
    /**
     * Артикул
     * @type {string}
     * @memberof VariantInfoAbstractWriteCommons
     */
    externalCode?: string;
    /**
     * Штриховой код
     * @type {string}
     * @memberof VariantInfoAbstractWriteCommons
     */
    barcode?: string;
    /**
     * Маркер, указывающий, что вариант имеет собственный отдельный артикул, а не наследует его от продукта
     * @type {boolean}
     * @memberof VariantInfoAbstractWriteCommons
     */
    hasOwnExternalCode: boolean;
    /**
     * 
     * @type {Array<RentPriceForTimeMeasureObj>}
     * @memberof VariantInfoAbstractWriteCommons
     */
    prices?: Array<RentPriceForTimeMeasureObj>;
    /**
     * Цена за смену, коп.
     * @type {number}
     * @memberof VariantInfoAbstractWriteCommons
     */
    pricePerShift?: number;
    /**
     * Маркер, указывающий, что вариант имеет собственную отдельную цену, а не наследует ее от продукта
     * @type {boolean}
     * @memberof VariantInfoAbstractWriteCommons
     */
    hasOwnPricePerShift: boolean;
    /**
     * Версия продукта. Для защиты от перезаписи свойств продукта - stockInstanceCount и underMaintenanceInstanceCount, которые получаются суммированием по вариантам (если продукт содержит варианты)
     * @type {number}
     * @memberof VariantInfoAbstractWriteCommons
     */
    productBusinessVersion: number;
    /**
     * Настраиваемые поля со значениями
     * @type {Array<CustomFieldValueObjWrite>}
     * @memberof VariantInfoAbstractWriteCommons
     */
    customFields?: Array<CustomFieldValueObjWrite>;
    /**
     * Общее число экземпляров варианта на момент запроса
     * @type {number}
     * @memberof VariantInfoAbstractWriteCommons
     */
    stockInstanceCount: number;
    /**
     * Число экземпляров варианта на обслуживании на момент запроса
     * @type {number}
     * @memberof VariantInfoAbstractWriteCommons
     */
    underMaintenanceInstanceCount: number;
}
/**
 * 
 * @export
 * @interface VariantInfoAbstractWriteCommonsAllOf
 */
export interface VariantInfoAbstractWriteCommonsAllOf {
    /**
     * Настраиваемые поля со значениями
     * @type {Array<CustomFieldValueObjWrite>}
     * @memberof VariantInfoAbstractWriteCommonsAllOf
     */
    customFields?: Array<CustomFieldValueObjWrite>;
    /**
     * Общее число экземпляров варианта на момент запроса
     * @type {number}
     * @memberof VariantInfoAbstractWriteCommonsAllOf
     */
    stockInstanceCount: number;
    /**
     * Число экземпляров варианта на обслуживании на момент запроса
     * @type {number}
     * @memberof VariantInfoAbstractWriteCommonsAllOf
     */
    underMaintenanceInstanceCount: number;
}
/**
 * Вариант: состояние при создании
 * @export
 * @interface VariantInfoCreate
 */
export interface VariantInfoCreate extends VariantInfoAbstractWriteCommons {
}
/**
 * Вариант: состояние при чтении
 * @export
 * @interface VariantInfoRead
 */
export interface VariantInfoRead {
    /**
     * Идентификатор
     * @type {number}
     * @memberof VariantInfoRead
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof VariantInfoRead
     */
    businessVersion: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof VariantInfoRead
     */
    creationAuthorId: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof VariantInfoRead
     */
    creationAuthorFullName: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof VariantInfoRead
     */
    creationDate: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof VariantInfoRead
     */
    lastUpdateAuthorId: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof VariantInfoRead
     */
    lastUpdateAuthorFullName: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof VariantInfoRead
     */
    lastUpdateDate: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof VariantInfoRead
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof VariantInfoRead
     */
    businessAccountId: number;
    /**
     * Выч: информация об изображении
     * @type {ImageObj}
     * @memberof VariantInfoRead
     */
    image?: ImageObj;
    /**
     * Наименование
     * @type {string}
     * @memberof VariantInfoRead
     */
    name: string;
    /**
     * Артикул
     * @type {string}
     * @memberof VariantInfoRead
     */
    externalCode?: string;
    /**
     * Штриховой код
     * @type {string}
     * @memberof VariantInfoRead
     */
    barcode?: string;
    /**
     * Маркер, указывающий, что вариант имеет собственный отдельный артикул, а не наследует его от продукта
     * @type {boolean}
     * @memberof VariantInfoRead
     */
    hasOwnExternalCode: boolean;
    /**
     * 
     * @type {Array<RentPriceForTimeMeasureObj>}
     * @memberof VariantInfoRead
     */
    prices?: Array<RentPriceForTimeMeasureObj>;
    /**
     * Цена за смену, коп.
     * @type {number}
     * @memberof VariantInfoRead
     */
    pricePerShift?: number;
    /**
     * Маркер, указывающий, что вариант имеет собственную отдельную цену, а не наследует ее от продукта
     * @type {boolean}
     * @memberof VariantInfoRead
     */
    hasOwnPricePerShift: boolean;
    /**
     * Версия продукта. Для защиты от перезаписи свойств продукта - stockInstanceCount и underMaintenanceInstanceCount, которые получаются суммированием по вариантам (если продукт содержит варианты)
     * @type {number}
     * @memberof VariantInfoRead
     */
    productBusinessVersion: number;
    /**
     * Возможно удалить данную сущность
     * @type {boolean}
     * @memberof VariantInfoRead
     */
    deleteable: boolean;
    /**
     * Идентификатор продукта
     * @type {number}
     * @memberof VariantInfoRead
     */
    productId: number;
    /**
     * Короткое наименование продукта
     * @type {string}
     * @memberof VariantInfoRead
     */
    productShortName: string;
    /**
     * Находится в архиве
     * @type {boolean}
     * @memberof VariantInfoRead
     */
    archive: boolean;
    /**
     * Может ли быть отправлен в архив (может, когда экземпляры отслеживаемы и все списаны или stock = 0 когда экземпляры счетные)
     * @type {boolean}
     * @memberof VariantInfoRead
     */
    archivable: boolean;
    /**
     * 
     * @type {RentIndicatorsObj}
     * @memberof VariantInfoRead
     */
    rentIndicators: RentIndicatorsObj;
    /**
     * Являются ли экземпляры отслеживаемыми
     * @type {boolean}
     * @memberof VariantInfoRead
     */
    tracked: boolean;
    /**
     * Настраиваемые поля со значениями
     * @type {Array<CustomFieldWithValueObjRead>}
     * @memberof VariantInfoRead
     */
    customFields?: Array<CustomFieldWithValueObjRead>;
    /**
     * Применяемый обязательный запас времени до выдачи, мин. Берется по компании или продукту
     * @type {number}
     * @memberof VariantInfoRead
     */
    requiredTimeIndentBetweenElementsInMinutes: number;
    /**
     * Обязательный запас времени до выдачи, мин. Берется по компании или продукту
     * @type {number}
     * @memberof VariantInfoRead
     */
    shiftLengthInMinutes: number;
    /**
     * Маркер, указывающий, что продукт имеет собственную длительность смены, а не наследует значение по умолчанию для компании
     * @type {boolean}
     * @memberof VariantInfoRead
     */
    productHasOwnShiftLength: boolean;
    /**
     * Идентификатор ценовой схемы
     * @type {number}
     * @memberof VariantInfoRead
     */
    pricingSchemeId?: number;
    /**
     * 
     * @type {TimetableStackCountersObj}
     * @memberof VariantInfoRead
     */
    timetableStackCounters: TimetableStackCountersObj;
    /**
     * 
     * @type {WarehousePresenceCountersObj}
     * @memberof VariantInfoRead
     */
    warehousePresenceCounters: WarehousePresenceCountersObj;
}
/**
 * 
 * @export
 * @interface VariantInfoReadAllOf
 */
export interface VariantInfoReadAllOf {
    /**
     * Возможно удалить данную сущность
     * @type {boolean}
     * @memberof VariantInfoReadAllOf
     */
    deleteable: boolean;
    /**
     * Идентификатор продукта
     * @type {number}
     * @memberof VariantInfoReadAllOf
     */
    productId: number;
    /**
     * Короткое наименование продукта
     * @type {string}
     * @memberof VariantInfoReadAllOf
     */
    productShortName: string;
    /**
     * Находится в архиве
     * @type {boolean}
     * @memberof VariantInfoReadAllOf
     */
    archive: boolean;
    /**
     * Может ли быть отправлен в архив (может, когда экземпляры отслеживаемы и все списаны или stock = 0 когда экземпляры счетные)
     * @type {boolean}
     * @memberof VariantInfoReadAllOf
     */
    archivable: boolean;
    /**
     * 
     * @type {RentIndicatorsObj}
     * @memberof VariantInfoReadAllOf
     */
    rentIndicators: RentIndicatorsObj;
    /**
     * Являются ли экземпляры отслеживаемыми
     * @type {boolean}
     * @memberof VariantInfoReadAllOf
     */
    tracked: boolean;
    /**
     * Настраиваемые поля со значениями
     * @type {Array<CustomFieldWithValueObjRead>}
     * @memberof VariantInfoReadAllOf
     */
    customFields?: Array<CustomFieldWithValueObjRead>;
    /**
     * Применяемый обязательный запас времени до выдачи, мин. Берется по компании или продукту
     * @type {number}
     * @memberof VariantInfoReadAllOf
     */
    requiredTimeIndentBetweenElementsInMinutes: number;
    /**
     * Обязательный запас времени до выдачи, мин. Берется по компании или продукту
     * @type {number}
     * @memberof VariantInfoReadAllOf
     */
    shiftLengthInMinutes: number;
    /**
     * Маркер, указывающий, что продукт имеет собственную длительность смены, а не наследует значение по умолчанию для компании
     * @type {boolean}
     * @memberof VariantInfoReadAllOf
     */
    productHasOwnShiftLength: boolean;
    /**
     * Идентификатор ценовой схемы
     * @type {number}
     * @memberof VariantInfoReadAllOf
     */
    pricingSchemeId?: number;
    /**
     * 
     * @type {TimetableStackCountersObj}
     * @memberof VariantInfoReadAllOf
     */
    timetableStackCounters: TimetableStackCountersObj;
    /**
     * 
     * @type {WarehousePresenceCountersObj}
     * @memberof VariantInfoReadAllOf
     */
    warehousePresenceCounters: WarehousePresenceCountersObj;
}
/**
 * Вариант: состояние при изменении
 * @export
 * @interface VariantInfoUpdate
 */
export interface VariantInfoUpdate extends VariantInfoAbstractWriteCommons {
}
/**
 * Не финансовые поля сущности VariantRecord, которые могут быть выгружены в список Excel
 * @export
 * @enum {string}
 */
export enum VariantNotFinancialFieldsTypeCode {
    Name = 'name',
    Barcode = 'barcode',
    ExternalCode = 'externalCode',
    StockInstanceCount = 'stockInstanceCount',
    UnderMaintenanceInstanceCount = 'underMaintenanceInstanceCount',
    SubrentedInstanceCount = 'subrentedInstanceCount',
    RentedInstanceCount = 'rentedInstanceCount',
    WarehouseInstanceCount = 'warehouseInstanceCount',
    LastActivityDate = 'lastActivityDate'
}

/**
 * Списочное представление варианта
 * @export
 * @interface VariantRecord
 */
export interface VariantRecord {
    /**
     * Идентификатор
     * @type {number}
     * @memberof VariantRecord
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof VariantRecord
     */
    businessVersion: number;
    /**
     * Ссылка: Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof VariantRecord
     */
    businessAccountId: number;
    /**
     * Идентификатор продукта
     * @type {number}
     * @memberof VariantRecord
     */
    productId?: number;
    /**
     * Выч: информация об изображении
     * @type {ImageObj}
     * @memberof VariantRecord
     */
    image?: ImageObj;
    /**
     * Наименование
     * @type {string}
     * @memberof VariantRecord
     */
    name: string;
    /**
     * Артикул
     * @type {string}
     * @memberof VariantRecord
     */
    externalCode: string;
    /**
     * Маркер, указывающий, что вариант имеет собственный отдельный артикул, а не наследует его от продукта
     * @type {boolean}
     * @memberof VariantRecord
     */
    hasOwnExternalCode: boolean;
    /**
     * Штриховой код
     * @type {string}
     * @memberof VariantRecord
     */
    barcode?: string;
    /**
     * Фактическая цена за смену, коп., берется либо из продукта либо из варианта, в соответствии с флагом hasOwnPricePerShift
     * @type {number}
     * @memberof VariantRecord
     */
    pricePerShift: number;
    /**
     * Маркер, указывающий, что вариант имеет собственную отдельную цену, а не наследует ее от продукта
     * @type {boolean}
     * @memberof VariantRecord
     */
    hasOwnPricePerShift: boolean;
    /**
     * Идентификатор ценовой схемы
     * @type {number}
     * @memberof VariantRecord
     */
    pricingSchemeId?: number;
    /**
     * 
     * @type {ProblemAndWarningRentIndicatorsRecordObj}
     * @memberof VariantRecord
     */
    problemsAndWarnings: ProblemAndWarningRentIndicatorsRecordObj;
    /**
     * Находится в архиве
     * @type {boolean}
     * @memberof VariantRecord
     */
    archive: boolean;
    /**
     * Может ли быть отправлен в архив (может, когда экземпляры отслеживаемы и все списаны или stock = 0 когда экземпляры счетные)
     * @type {boolean}
     * @memberof VariantRecord
     */
    archivable: boolean;
    /**
     * Возможно удалить данную сущность
     * @type {boolean}
     * @memberof VariantRecord
     */
    deleteable: boolean;
    /**
     * Являются ли экземпляры отслеживаемыми
     * @type {boolean}
     * @memberof VariantRecord
     */
    tracked: boolean;
    /**
     * 
     * @type {Array<CustomFieldValueObjRecord>}
     * @memberof VariantRecord
     */
    customFieldValues?: Array<CustomFieldValueObjRecord>;
    /**
     * Версия карты состояний
     * @type {number}
     * @memberof VariantRecord
     */
    timetableVersion: number;
    /**
     * Общее число экземпляров на момент запроса
     * @type {number}
     * @memberof VariantRecord
     */
    stockInstanceCount: number;
    /**
     * Число экземпляров на обслуживании на момент запроса
     * @type {number}
     * @memberof VariantRecord
     */
    underMaintenanceInstanceCount: number;
    /**
     * 
     * @type {WarehousePresenceCountersObj}
     * @memberof VariantRecord
     */
    warehousePresenceCounters: WarehousePresenceCountersObj;
    /**
     * Дата и время последней активности (измение сущности либо операция над связанными обязательствами)
     * @type {Date}
     * @memberof VariantRecord
     */
    lastActivityDate: Date;
}
/**
 * 
 * @export
 * @interface VariantRecordAllOf
 */
export interface VariantRecordAllOf {
    /**
     * Идентификатор продукта
     * @type {number}
     * @memberof VariantRecordAllOf
     */
    productId?: number;
    /**
     * Выч: информация об изображении
     * @type {ImageObj}
     * @memberof VariantRecordAllOf
     */
    image?: ImageObj;
    /**
     * Наименование
     * @type {string}
     * @memberof VariantRecordAllOf
     */
    name: string;
    /**
     * Артикул
     * @type {string}
     * @memberof VariantRecordAllOf
     */
    externalCode: string;
    /**
     * Маркер, указывающий, что вариант имеет собственный отдельный артикул, а не наследует его от продукта
     * @type {boolean}
     * @memberof VariantRecordAllOf
     */
    hasOwnExternalCode: boolean;
    /**
     * Штриховой код
     * @type {string}
     * @memberof VariantRecordAllOf
     */
    barcode?: string;
    /**
     * Фактическая цена за смену, коп., берется либо из продукта либо из варианта, в соответствии с флагом hasOwnPricePerShift
     * @type {number}
     * @memberof VariantRecordAllOf
     */
    pricePerShift: number;
    /**
     * Маркер, указывающий, что вариант имеет собственную отдельную цену, а не наследует ее от продукта
     * @type {boolean}
     * @memberof VariantRecordAllOf
     */
    hasOwnPricePerShift: boolean;
    /**
     * Идентификатор ценовой схемы
     * @type {number}
     * @memberof VariantRecordAllOf
     */
    pricingSchemeId?: number;
    /**
     * 
     * @type {ProblemAndWarningRentIndicatorsRecordObj}
     * @memberof VariantRecordAllOf
     */
    problemsAndWarnings: ProblemAndWarningRentIndicatorsRecordObj;
    /**
     * Находится в архиве
     * @type {boolean}
     * @memberof VariantRecordAllOf
     */
    archive: boolean;
    /**
     * Может ли быть отправлен в архив (может, когда экземпляры отслеживаемы и все списаны или stock = 0 когда экземпляры счетные)
     * @type {boolean}
     * @memberof VariantRecordAllOf
     */
    archivable: boolean;
    /**
     * Возможно удалить данную сущность
     * @type {boolean}
     * @memberof VariantRecordAllOf
     */
    deleteable: boolean;
    /**
     * Являются ли экземпляры отслеживаемыми
     * @type {boolean}
     * @memberof VariantRecordAllOf
     */
    tracked: boolean;
    /**
     * 
     * @type {Array<CustomFieldValueObjRecord>}
     * @memberof VariantRecordAllOf
     */
    customFieldValues?: Array<CustomFieldValueObjRecord>;
    /**
     * Версия карты состояний
     * @type {number}
     * @memberof VariantRecordAllOf
     */
    timetableVersion: number;
    /**
     * Общее число экземпляров на момент запроса
     * @type {number}
     * @memberof VariantRecordAllOf
     */
    stockInstanceCount: number;
    /**
     * Число экземпляров на обслуживании на момент запроса
     * @type {number}
     * @memberof VariantRecordAllOf
     */
    underMaintenanceInstanceCount: number;
    /**
     * 
     * @type {WarehousePresenceCountersObj}
     * @memberof VariantRecordAllOf
     */
    warehousePresenceCounters: WarehousePresenceCountersObj;
    /**
     * Дата и время последней активности (измение сущности либо операция над связанными обязательствами)
     * @type {Date}
     * @memberof VariantRecordAllOf
     */
    lastActivityDate: Date;
}
/**
 * Список записей вариантов
 * @export
 * @interface VariantRecordList
 */
export interface VariantRecordList {
    /**
     * 
     * @type {ListAttributesInfo}
     * @memberof VariantRecordList
     */
    listAttributes: ListAttributesInfo;
    /**
     * 
     * @type {Array<VariantRecord>}
     * @memberof VariantRecordList
     */
    records: Array<VariantRecord>;
}
/**
 * 
 * @export
 * @interface VariantRecordListAllOf
 */
export interface VariantRecordListAllOf {
    /**
     * 
     * @type {Array<VariantRecord>}
     * @memberof VariantRecordListAllOf
     */
    records: Array<VariantRecord>;
}
/**
 * 
 * @export
 * @interface VehicleInfoAbstractCommons
 */
export interface VehicleInfoAbstractCommons {
    /**
     * Идентификатор
     * @type {number}
     * @memberof VehicleInfoAbstractCommons
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof VehicleInfoAbstractCommons
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof VehicleInfoAbstractCommons
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof VehicleInfoAbstractCommons
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof VehicleInfoAbstractCommons
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof VehicleInfoAbstractCommons
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof VehicleInfoAbstractCommons
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof VehicleInfoAbstractCommons
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof VehicleInfoAbstractCommons
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof VehicleInfoAbstractCommons
     */
    businessAccountId?: number;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof VehicleInfoAbstractCommons
     */
    shortName: string;
    /**
     * Госномер или аналог
     * @type {string}
     * @memberof VehicleInfoAbstractCommons
     */
    officialNumber: string;
    /**
     * 
     * @type {VehicleTypeCodeEnum}
     * @memberof VehicleInfoAbstractCommons
     */
    type: VehicleTypeCodeEnum;
    /**
     * Марка и модель
     * @type {string}
     * @memberof VehicleInfoAbstractCommons
     */
    brandAndModel?: string;
    /**
     * Грузоподъемность, г. (максимум - 1000 тонн)
     * @type {number}
     * @memberof VehicleInfoAbstractCommons
     */
    loadCapacity?: number;
    /**
     * Длина кузова, мм (максимум - 100 метров)
     * @type {number}
     * @memberof VehicleInfoAbstractCommons
     */
    length?: number;
    /**
     * Высота кузова, мм (максимум - 100 метров)
     * @type {number}
     * @memberof VehicleInfoAbstractCommons
     */
    height?: number;
    /**
     * Ширина кузова, мм (максимум - 100 метров)
     * @type {number}
     * @memberof VehicleInfoAbstractCommons
     */
    width?: number;
    /**
     * Тип погрузки
     * @type {Array<LoadingTypeCodeEnum>}
     * @memberof VehicleInfoAbstractCommons
     */
    loadingTypes?: Array<LoadingTypeCodeEnum>;
    /**
     * 
     * @type {BodyTypeCodeEnum}
     * @memberof VehicleInfoAbstractCommons
     */
    bodyType?: BodyTypeCodeEnum;
    /**
     * 
     * @type {ImageObj}
     * @memberof VehicleInfoAbstractCommons
     */
    image?: ImageObj;
}
/**
 * 
 * @export
 * @interface VehicleInfoAbstractCommonsAllOf
 */
export interface VehicleInfoAbstractCommonsAllOf {
    /**
     * Короткое наименование
     * @type {string}
     * @memberof VehicleInfoAbstractCommonsAllOf
     */
    shortName: string;
    /**
     * Госномер или аналог
     * @type {string}
     * @memberof VehicleInfoAbstractCommonsAllOf
     */
    officialNumber: string;
    /**
     * 
     * @type {VehicleTypeCodeEnum}
     * @memberof VehicleInfoAbstractCommonsAllOf
     */
    type: VehicleTypeCodeEnum;
    /**
     * Марка и модель
     * @type {string}
     * @memberof VehicleInfoAbstractCommonsAllOf
     */
    brandAndModel?: string;
    /**
     * Грузоподъемность, г. (максимум - 1000 тонн)
     * @type {number}
     * @memberof VehicleInfoAbstractCommonsAllOf
     */
    loadCapacity?: number;
    /**
     * Длина кузова, мм (максимум - 100 метров)
     * @type {number}
     * @memberof VehicleInfoAbstractCommonsAllOf
     */
    length?: number;
    /**
     * Высота кузова, мм (максимум - 100 метров)
     * @type {number}
     * @memberof VehicleInfoAbstractCommonsAllOf
     */
    height?: number;
    /**
     * Ширина кузова, мм (максимум - 100 метров)
     * @type {number}
     * @memberof VehicleInfoAbstractCommonsAllOf
     */
    width?: number;
    /**
     * Тип погрузки
     * @type {Array<LoadingTypeCodeEnum>}
     * @memberof VehicleInfoAbstractCommonsAllOf
     */
    loadingTypes?: Array<LoadingTypeCodeEnum>;
    /**
     * 
     * @type {BodyTypeCodeEnum}
     * @memberof VehicleInfoAbstractCommonsAllOf
     */
    bodyType?: BodyTypeCodeEnum;
    /**
     * 
     * @type {ImageObj}
     * @memberof VehicleInfoAbstractCommonsAllOf
     */
    image?: ImageObj;
}
/**
 * 
 * @export
 * @interface VehicleInfoAbstractWriteCommons
 */
export interface VehicleInfoAbstractWriteCommons {
    /**
     * Идентификатор
     * @type {number}
     * @memberof VehicleInfoAbstractWriteCommons
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof VehicleInfoAbstractWriteCommons
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof VehicleInfoAbstractWriteCommons
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof VehicleInfoAbstractWriteCommons
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof VehicleInfoAbstractWriteCommons
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof VehicleInfoAbstractWriteCommons
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof VehicleInfoAbstractWriteCommons
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof VehicleInfoAbstractWriteCommons
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof VehicleInfoAbstractWriteCommons
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof VehicleInfoAbstractWriteCommons
     */
    businessAccountId?: number;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof VehicleInfoAbstractWriteCommons
     */
    shortName: string;
    /**
     * Госномер или аналог
     * @type {string}
     * @memberof VehicleInfoAbstractWriteCommons
     */
    officialNumber: string;
    /**
     * 
     * @type {VehicleTypeCodeEnum}
     * @memberof VehicleInfoAbstractWriteCommons
     */
    type: VehicleTypeCodeEnum;
    /**
     * Марка и модель
     * @type {string}
     * @memberof VehicleInfoAbstractWriteCommons
     */
    brandAndModel?: string;
    /**
     * Грузоподъемность, г. (максимум - 1000 тонн)
     * @type {number}
     * @memberof VehicleInfoAbstractWriteCommons
     */
    loadCapacity?: number;
    /**
     * Длина кузова, мм (максимум - 100 метров)
     * @type {number}
     * @memberof VehicleInfoAbstractWriteCommons
     */
    length?: number;
    /**
     * Высота кузова, мм (максимум - 100 метров)
     * @type {number}
     * @memberof VehicleInfoAbstractWriteCommons
     */
    height?: number;
    /**
     * Ширина кузова, мм (максимум - 100 метров)
     * @type {number}
     * @memberof VehicleInfoAbstractWriteCommons
     */
    width?: number;
    /**
     * Тип погрузки
     * @type {Array<LoadingTypeCodeEnum>}
     * @memberof VehicleInfoAbstractWriteCommons
     */
    loadingTypes?: Array<LoadingTypeCodeEnum>;
    /**
     * 
     * @type {BodyTypeCodeEnum}
     * @memberof VehicleInfoAbstractWriteCommons
     */
    bodyType?: BodyTypeCodeEnum;
    /**
     * 
     * @type {ImageObj}
     * @memberof VehicleInfoAbstractWriteCommons
     */
    image?: ImageObj;
    /**
     * Контакты траспортного средства
     * @type {Array<ContactLinkObjWrite>}
     * @memberof VehicleInfoAbstractWriteCommons
     */
    contacts?: Array<ContactLinkObjWrite>;
}
/**
 * 
 * @export
 * @interface VehicleInfoAbstractWriteCommonsAllOf
 */
export interface VehicleInfoAbstractWriteCommonsAllOf {
    /**
     * Контакты траспортного средства
     * @type {Array<ContactLinkObjWrite>}
     * @memberof VehicleInfoAbstractWriteCommonsAllOf
     */
    contacts?: Array<ContactLinkObjWrite>;
}
/**
 * 
 * @export
 * @interface VehicleInfoCreate
 */
export interface VehicleInfoCreate extends VehicleInfoAbstractWriteCommons {
}
/**
 * 
 * @export
 * @interface VehicleInfoRead
 */
export interface VehicleInfoRead {
    /**
     * Идентификатор
     * @type {number}
     * @memberof VehicleInfoRead
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof VehicleInfoRead
     */
    businessVersion: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof VehicleInfoRead
     */
    creationAuthorId: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof VehicleInfoRead
     */
    creationAuthorFullName: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof VehicleInfoRead
     */
    creationDate: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof VehicleInfoRead
     */
    lastUpdateAuthorId: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof VehicleInfoRead
     */
    lastUpdateAuthorFullName: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof VehicleInfoRead
     */
    lastUpdateDate: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof VehicleInfoRead
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof VehicleInfoRead
     */
    businessAccountId: number;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof VehicleInfoRead
     */
    shortName: string;
    /**
     * Госномер или аналог
     * @type {string}
     * @memberof VehicleInfoRead
     */
    officialNumber: string;
    /**
     * 
     * @type {VehicleTypeCodeEnum}
     * @memberof VehicleInfoRead
     */
    type: VehicleTypeCodeEnum;
    /**
     * Марка и модель
     * @type {string}
     * @memberof VehicleInfoRead
     */
    brandAndModel?: string;
    /**
     * Грузоподъемность, г. (максимум - 1000 тонн)
     * @type {number}
     * @memberof VehicleInfoRead
     */
    loadCapacity?: number;
    /**
     * Длина кузова, мм (максимум - 100 метров)
     * @type {number}
     * @memberof VehicleInfoRead
     */
    length?: number;
    /**
     * Высота кузова, мм (максимум - 100 метров)
     * @type {number}
     * @memberof VehicleInfoRead
     */
    height?: number;
    /**
     * Ширина кузова, мм (максимум - 100 метров)
     * @type {number}
     * @memberof VehicleInfoRead
     */
    width?: number;
    /**
     * Тип погрузки
     * @type {Array<LoadingTypeCodeEnum>}
     * @memberof VehicleInfoRead
     */
    loadingTypes?: Array<LoadingTypeCodeEnum>;
    /**
     * 
     * @type {BodyTypeCodeEnum}
     * @memberof VehicleInfoRead
     */
    bodyType?: BodyTypeCodeEnum;
    /**
     * 
     * @type {ImageObj}
     * @memberof VehicleInfoRead
     */
    image?: ImageObj;
    /**
     * Признак нахождения в архиве
     * @type {boolean}
     * @memberof VehicleInfoRead
     */
    archive: boolean;
    /**
     * Может ли быть отправлена в архив (может, когда не в архиве)
     * @type {boolean}
     * @memberof VehicleInfoRead
     */
    archivable: boolean;
    /**
     * Может ли быть удалена (может, когда нет входящих ссылок)
     * @type {boolean}
     * @memberof VehicleInfoRead
     */
    deleteable: boolean;
    /**
     * Контакты траспортного средства
     * @type {Array<ContactLinkObjRead>}
     * @memberof VehicleInfoRead
     */
    contacts?: Array<ContactLinkObjRead>;
    /**
     * 
     * @type {AttachmentRecordList}
     * @memberof VehicleInfoRead
     */
    attachments?: AttachmentRecordList;
    /**
     * Число перевозок
     * @type {number}
     * @memberof VehicleInfoRead
     */
    transportationCount: number;
}
/**
 * 
 * @export
 * @interface VehicleInfoReadAllOf
 */
export interface VehicleInfoReadAllOf {
    /**
     * Признак нахождения в архиве
     * @type {boolean}
     * @memberof VehicleInfoReadAllOf
     */
    archive: boolean;
    /**
     * Может ли быть отправлена в архив (может, когда не в архиве)
     * @type {boolean}
     * @memberof VehicleInfoReadAllOf
     */
    archivable: boolean;
    /**
     * Может ли быть удалена (может, когда нет входящих ссылок)
     * @type {boolean}
     * @memberof VehicleInfoReadAllOf
     */
    deleteable: boolean;
    /**
     * Контакты траспортного средства
     * @type {Array<ContactLinkObjRead>}
     * @memberof VehicleInfoReadAllOf
     */
    contacts?: Array<ContactLinkObjRead>;
    /**
     * 
     * @type {AttachmentRecordList}
     * @memberof VehicleInfoReadAllOf
     */
    attachments?: AttachmentRecordList;
    /**
     * Число перевозок
     * @type {number}
     * @memberof VehicleInfoReadAllOf
     */
    transportationCount: number;
}
/**
 * 
 * @export
 * @interface VehicleInfoUpdate
 */
export interface VehicleInfoUpdate extends VehicleInfoAbstractWriteCommons {
}
/**
 * Все поля сущности VehicleRecord, которые могут быть выгружены в список Excel. Финансовых полей нет
 * @export
 * @enum {string}
 */
export enum VehicleNotFinancialFieldsTypeCode {
    ShortName = 'shortName',
    OfficialNumber = 'officialNumber',
    Type = 'type',
    BrandAndModel = 'brandAndModel',
    LoadCapacity = 'loadCapacity',
    Volume = 'volume',
    LoadingTypes = 'loadingTypes',
    BodyType = 'bodyType',
    TransportationCount = 'transportationCount',
    AttachmentCount = 'attachmentCount',
    LastUpdateAuthorShortName = 'lastUpdateAuthorShortName',
    LastUpdateDate = 'lastUpdateDate'
}

/**
 * 
 * @export
 * @interface VehicleRecord
 */
export interface VehicleRecord {
    /**
     * Идентификатор
     * @type {number}
     * @memberof VehicleRecord
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof VehicleRecord
     */
    businessVersion: number;
    /**
     * Ссылка: Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof VehicleRecord
     */
    businessAccountId: number;
    /**
     * Короткое наименование
     * @type {string}
     * @memberof VehicleRecord
     */
    shortName: string;
    /**
     * Официальный номер
     * @type {string}
     * @memberof VehicleRecord
     */
    officialNumber: string;
    /**
     * Официальный номер подготовленный формат (для проверок уникальности и поиска)
     * @type {string}
     * @memberof VehicleRecord
     */
    officialNumberPrepared: string;
    /**
     * 
     * @type {VehicleTypeCodeEnum}
     * @memberof VehicleRecord
     */
    type: VehicleTypeCodeEnum;
    /**
     * Марка и модель
     * @type {string}
     * @memberof VehicleRecord
     */
    brandAndModel?: string;
    /**
     * Грузоподъемность, г. (максимум - 1000 тонн)
     * @type {number}
     * @memberof VehicleRecord
     */
    loadCapacity?: number;
    /**
     * Длина кузова, мм (максимум - 100 метров)
     * @type {number}
     * @memberof VehicleRecord
     */
    length?: number;
    /**
     * Высота кузова, мм (максимум - 100 метров)
     * @type {number}
     * @memberof VehicleRecord
     */
    height?: number;
    /**
     * Ширина кузова, мм (максимум - 100 метров)
     * @type {number}
     * @memberof VehicleRecord
     */
    width?: number;
    /**
     * Объем кузова
     * @type {number}
     * @memberof VehicleRecord
     */
    volume?: number;
    /**
     * 
     * @type {Array<LoadingTypeCodeEnum>}
     * @memberof VehicleRecord
     */
    loadingTypes?: Array<LoadingTypeCodeEnum>;
    /**
     * 
     * @type {BodyTypeCodeEnum}
     * @memberof VehicleRecord
     */
    bodyType?: BodyTypeCodeEnum;
    /**
     * 
     * @type {ImageObj}
     * @memberof VehicleRecord
     */
    image?: ImageObj;
    /**
     * Признак нахождения в архиве
     * @type {boolean}
     * @memberof VehicleRecord
     */
    archive: boolean;
    /**
     * Может ли быть отправлена в архив (может, когда не в архиве)
     * @type {boolean}
     * @memberof VehicleRecord
     */
    archivable: boolean;
    /**
     * Может ли быть удалена (может, когда нет входящих ссылок)
     * @type {boolean}
     * @memberof VehicleRecord
     */
    deleteable: boolean;
    /**
     * Число перевозок
     * @type {number}
     * @memberof VehicleRecord
     */
    transportationCount: number;
    /**
     * Ссылка: идентификатор автора посл. изменения
     * @type {number}
     * @memberof VehicleRecord
     */
    lastUpdateAuthorId?: number;
    /**
     * Выч. по ссылке: короткое ФИО автора посл. изменения
     * @type {string}
     * @memberof VehicleRecord
     */
    lastUpdateAuthorShortName?: string;
    /**
     * Дата и время посл. изменения
     * @type {Date}
     * @memberof VehicleRecord
     */
    lastUpdateDate?: Date;
    /**
     * Число вложений
     * @type {number}
     * @memberof VehicleRecord
     */
    attachmentCount: number;
}
/**
 * 
 * @export
 * @interface VehicleRecordAllOf
 */
export interface VehicleRecordAllOf {
    /**
     * Короткое наименование
     * @type {string}
     * @memberof VehicleRecordAllOf
     */
    shortName: string;
    /**
     * Официальный номер
     * @type {string}
     * @memberof VehicleRecordAllOf
     */
    officialNumber: string;
    /**
     * Официальный номер подготовленный формат (для проверок уникальности и поиска)
     * @type {string}
     * @memberof VehicleRecordAllOf
     */
    officialNumberPrepared: string;
    /**
     * 
     * @type {VehicleTypeCodeEnum}
     * @memberof VehicleRecordAllOf
     */
    type: VehicleTypeCodeEnum;
    /**
     * Марка и модель
     * @type {string}
     * @memberof VehicleRecordAllOf
     */
    brandAndModel?: string;
    /**
     * Грузоподъемность, г. (максимум - 1000 тонн)
     * @type {number}
     * @memberof VehicleRecordAllOf
     */
    loadCapacity?: number;
    /**
     * Длина кузова, мм (максимум - 100 метров)
     * @type {number}
     * @memberof VehicleRecordAllOf
     */
    length?: number;
    /**
     * Высота кузова, мм (максимум - 100 метров)
     * @type {number}
     * @memberof VehicleRecordAllOf
     */
    height?: number;
    /**
     * Ширина кузова, мм (максимум - 100 метров)
     * @type {number}
     * @memberof VehicleRecordAllOf
     */
    width?: number;
    /**
     * Объем кузова
     * @type {number}
     * @memberof VehicleRecordAllOf
     */
    volume?: number;
    /**
     * 
     * @type {Array<LoadingTypeCodeEnum>}
     * @memberof VehicleRecordAllOf
     */
    loadingTypes?: Array<LoadingTypeCodeEnum>;
    /**
     * 
     * @type {BodyTypeCodeEnum}
     * @memberof VehicleRecordAllOf
     */
    bodyType?: BodyTypeCodeEnum;
    /**
     * 
     * @type {ImageObj}
     * @memberof VehicleRecordAllOf
     */
    image?: ImageObj;
    /**
     * Признак нахождения в архиве
     * @type {boolean}
     * @memberof VehicleRecordAllOf
     */
    archive: boolean;
    /**
     * Может ли быть отправлена в архив (может, когда не в архиве)
     * @type {boolean}
     * @memberof VehicleRecordAllOf
     */
    archivable: boolean;
    /**
     * Может ли быть удалена (может, когда нет входящих ссылок)
     * @type {boolean}
     * @memberof VehicleRecordAllOf
     */
    deleteable: boolean;
    /**
     * Число перевозок
     * @type {number}
     * @memberof VehicleRecordAllOf
     */
    transportationCount: number;
    /**
     * Ссылка: идентификатор автора посл. изменения
     * @type {number}
     * @memberof VehicleRecordAllOf
     */
    lastUpdateAuthorId?: number;
    /**
     * Выч. по ссылке: короткое ФИО автора посл. изменения
     * @type {string}
     * @memberof VehicleRecordAllOf
     */
    lastUpdateAuthorShortName?: string;
    /**
     * Дата и время посл. изменения
     * @type {Date}
     * @memberof VehicleRecordAllOf
     */
    lastUpdateDate?: Date;
    /**
     * Число вложений
     * @type {number}
     * @memberof VehicleRecordAllOf
     */
    attachmentCount: number;
}
/**
 * 
 * @export
 * @interface VehicleRecordList
 */
export interface VehicleRecordList {
    /**
     * 
     * @type {ListAttributesInfo}
     * @memberof VehicleRecordList
     */
    listAttributes: ListAttributesInfo;
    /**
     * 
     * @type {Array<VehicleRecord>}
     * @memberof VehicleRecordList
     */
    records: Array<VehicleRecord>;
}
/**
 * 
 * @export
 * @interface VehicleRecordListAllOf
 */
export interface VehicleRecordListAllOf {
    /**
     * 
     * @type {Array<VehicleRecord>}
     * @memberof VehicleRecordListAllOf
     */
    records: Array<VehicleRecord>;
}
/**
 * Тип транспорта - тягач, одиночка, прицеп или полуприцеп
 * @export
 * @enum {string}
 */
export enum VehicleTypeCodeEnum {
    TRACTORTRUCK = 'TRACTOR_TRUCK',
    STRAIGHTTRUCK = 'STRAIGHT_TRUCK',
    SEMITRAILER = 'SEMI_TRAILER',
    TRAILER = 'TRAILER',
    MINIBUS = 'MINIBUS'
}

/**
 * Версия и идентификатор сущности
 * @export
 * @interface VersionedEntityObj
 */
export interface VersionedEntityObj {
    /**
     * Идентификатор
     * @type {number}
     * @memberof VersionedEntityObj
     */
    id: number;
    /**
     * Версия
     * @type {number}
     * @memberof VersionedEntityObj
     */
    businessVersion: number;
}
/**
 * Список пар версий и идентификаторов сущностей
 * @export
 * @interface VersionedEntityObjList
 */
export interface VersionedEntityObjList {
    /**
     * 
     * @type {Array<VersionedEntityObj>}
     * @memberof VersionedEntityObjList
     */
    entities: Array<VersionedEntityObj>;
}
/**
 * Response for view! Contains all needed for correct visualization.
 * @export
 * @interface ViewInfo
 */
export interface ViewInfo {
    /**
     * 
     * @type {VisualizationTypeEnum}
     * @memberof ViewInfo
     */
    visualizationType: VisualizationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ViewInfo
     */
    settings: string;
    /**
     * 
     * @type {string}
     * @memberof ViewInfo
     */
    handlers?: string;
    /**
     * 
     * @type {string}
     * @memberof ViewInfo
     */
    data: string;
    /**
     * 
     * @type {string}
     * @memberof ViewInfo
     */
    preprocessor?: string;
}
/**
 * Type of visualization
 * @export
 * @enum {string}
 */
export enum VisualizationTypeEnum {
    COLUMNCHART = 'COLUMN_CHART',
    CLUSTEREDCOLUMNCHART = 'CLUSTERED_COLUMN_CHART',
    HIGHLIGHTINGCHARTSERIES = 'HIGHLIGHTING_CHART_SERIES',
    PIECHART = 'PIE_CHART',
    MULTILEVELTREEMAP = 'MULTILEVEL_TREE_MAP'
}

/**
 * 
 * @export
 * @interface WarehousePresenceCountersObj
 */
export interface WarehousePresenceCountersObj {
    /**
     * Число экз. на складе. Считается инкрементно при приеме и возврате поставщику на складе
     * @type {number}
     * @memberof WarehousePresenceCountersObj
     */
    stockInstanceCount: number;
    /**
     * Число экз. на обслуживании. Считается инкрементно при приеме и возврате поставщику на складе
     * @type {number}
     * @memberof WarehousePresenceCountersObj
     */
    underMaintenanceInstanceCount: number;
    /**
     * Число экз. в субаренде. Считается инкрементно при приеме и возврате поставщику субаренды, может отличаться от календарного планового числа субаренды на текущий момент при некорректных корректировках (прием или возврат в будущем)
     * @type {number}
     * @memberof WarehousePresenceCountersObj
     */
    subrentedInstanceCount: number;
    /**
     * Число экз. в аренде. Считается инкрементно при выдаче и возврате, может отличаться от календарного планового числа занятости на текущий момент планового числа при просрочке выдачи бронирования или некорректных корректировках (выдача или возврат в будущем)
     * @type {number}
     * @memberof WarehousePresenceCountersObj
     */
    rentedInstanceCount: number;
    /**
     * Число экз. на складе. Производная от общего инвентарного числа, числа на обслуживании(за вычетом), и двух полей выше. Может аналогично отличаться от плановой календарной доступности на текущий момент, если были соотв. проблемы описанные в полях выше.
     * @type {number}
     * @memberof WarehousePresenceCountersObj
     */
    warehouseInstanceCount?: number;
}
/**
 * Сгруппированные по признаку отношения к одной сущности работы
 * @export
 * @interface WorkPlanningGroupRecord
 */
export interface WorkPlanningGroupRecord {
    /**
     * 
     * @type {EntityTypeCodeEnum}
     * @memberof WorkPlanningGroupRecord
     */
    entityType?: EntityTypeCodeEnum;
    /**
     * 
     * @type {number}
     * @memberof WorkPlanningGroupRecord
     */
    entityId?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkPlanningGroupRecord
     */
    shortName?: string;
    /**
     * 
     * @type {Array<WorkPlanningsRecord>}
     * @memberof WorkPlanningGroupRecord
     */
    workPlannings?: Array<WorkPlanningsRecord>;
}
/**
 * Список групп работ
 * @export
 * @interface WorkPlanningGroupRecordList
 */
export interface WorkPlanningGroupRecordList {
    /**
     * 
     * @type {Array<WorkPlanningGroupRecord>}
     * @memberof WorkPlanningGroupRecordList
     */
    records?: Array<WorkPlanningGroupRecord>;
    /**
     * 
     * @type {ListAttributesInfo}
     * @memberof WorkPlanningGroupRecordList
     */
    listAttributes?: ListAttributesInfo;
}
/**
 * Планирование работ на проекте: базовое абстрактное состояние
 * @export
 * @interface WorkPlanningsAbstractCommons
 */
export interface WorkPlanningsAbstractCommons {
    /**
     * Идентификатор
     * @type {number}
     * @memberof WorkPlanningsAbstractCommons
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof WorkPlanningsAbstractCommons
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof WorkPlanningsAbstractCommons
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof WorkPlanningsAbstractCommons
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof WorkPlanningsAbstractCommons
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof WorkPlanningsAbstractCommons
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof WorkPlanningsAbstractCommons
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof WorkPlanningsAbstractCommons
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof WorkPlanningsAbstractCommons
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof WorkPlanningsAbstractCommons
     */
    businessAccountId?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkPlanningsAbstractCommons
     */
    name: string;
    /**
     * 
     * @type {Date}
     * @memberof WorkPlanningsAbstractCommons
     */
    startDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof WorkPlanningsAbstractCommons
     */
    endDate: Date;
    /**
     * 
     * @type {number}
     * @memberof WorkPlanningsAbstractCommons
     */
    numberOfCrew: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof WorkPlanningsAbstractCommons
     */
    crewMemberIds?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof WorkPlanningsAbstractCommons
     */
    effectivePrice: number;
    /**
     * 
     * @type {number}
     * @memberof WorkPlanningsAbstractCommons
     */
    shiftCount: number;
    /**
     * 
     * @type {WorkPlanningsStateCodeEnum}
     * @memberof WorkPlanningsAbstractCommons
     */
    stateCode: WorkPlanningsStateCodeEnum;
    /**
     * 
     * @type {number}
     * @memberof WorkPlanningsAbstractCommons
     */
    professionId: number;
    /**
     * Идентификатор ценовой схемы
     * @type {number}
     * @memberof WorkPlanningsAbstractCommons
     */
    effectivePricingSchemeId?: number;
}
/**
 * 
 * @export
 * @interface WorkPlanningsAbstractCommonsAllOf
 */
export interface WorkPlanningsAbstractCommonsAllOf {
    /**
     * 
     * @type {string}
     * @memberof WorkPlanningsAbstractCommonsAllOf
     */
    name: string;
    /**
     * 
     * @type {Date}
     * @memberof WorkPlanningsAbstractCommonsAllOf
     */
    startDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof WorkPlanningsAbstractCommonsAllOf
     */
    endDate: Date;
    /**
     * 
     * @type {number}
     * @memberof WorkPlanningsAbstractCommonsAllOf
     */
    numberOfCrew: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof WorkPlanningsAbstractCommonsAllOf
     */
    crewMemberIds?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof WorkPlanningsAbstractCommonsAllOf
     */
    effectivePrice: number;
    /**
     * 
     * @type {number}
     * @memberof WorkPlanningsAbstractCommonsAllOf
     */
    shiftCount: number;
    /**
     * 
     * @type {WorkPlanningsStateCodeEnum}
     * @memberof WorkPlanningsAbstractCommonsAllOf
     */
    stateCode: WorkPlanningsStateCodeEnum;
    /**
     * 
     * @type {number}
     * @memberof WorkPlanningsAbstractCommonsAllOf
     */
    professionId: number;
    /**
     * Идентификатор ценовой схемы
     * @type {number}
     * @memberof WorkPlanningsAbstractCommonsAllOf
     */
    effectivePricingSchemeId?: number;
}
/**
 * Финансовые поля сущности WorkPlanningsRecord, которые могут быть выгружены в список Excel
 * @export
 * @enum {string}
 */
export enum WorkPlanningsFinancialFieldsTypeCode {
    FinalTotalPrice = 'finalTotalPrice',
    EffectivePrice = 'effectivePrice'
}

/**
 * Планирование работ на проекте: состояние создания
 * @export
 * @interface WorkPlanningsInfoCreate
 */
export interface WorkPlanningsInfoCreate {
    /**
     * Идентификатор
     * @type {number}
     * @memberof WorkPlanningsInfoCreate
     */
    id?: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof WorkPlanningsInfoCreate
     */
    businessVersion?: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof WorkPlanningsInfoCreate
     */
    creationAuthorId?: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof WorkPlanningsInfoCreate
     */
    creationAuthorFullName?: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof WorkPlanningsInfoCreate
     */
    creationDate?: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof WorkPlanningsInfoCreate
     */
    lastUpdateAuthorId?: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof WorkPlanningsInfoCreate
     */
    lastUpdateAuthorFullName?: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof WorkPlanningsInfoCreate
     */
    lastUpdateDate?: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof WorkPlanningsInfoCreate
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof WorkPlanningsInfoCreate
     */
    businessAccountId?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkPlanningsInfoCreate
     */
    name: string;
    /**
     * 
     * @type {Date}
     * @memberof WorkPlanningsInfoCreate
     */
    startDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof WorkPlanningsInfoCreate
     */
    endDate: Date;
    /**
     * 
     * @type {number}
     * @memberof WorkPlanningsInfoCreate
     */
    numberOfCrew: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof WorkPlanningsInfoCreate
     */
    crewMemberIds?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof WorkPlanningsInfoCreate
     */
    effectivePrice: number;
    /**
     * 
     * @type {number}
     * @memberof WorkPlanningsInfoCreate
     */
    shiftCount: number;
    /**
     * 
     * @type {WorkPlanningsStateCodeEnum}
     * @memberof WorkPlanningsInfoCreate
     */
    stateCode: WorkPlanningsStateCodeEnum;
    /**
     * 
     * @type {number}
     * @memberof WorkPlanningsInfoCreate
     */
    professionId: number;
    /**
     * Идентификатор ценовой схемы
     * @type {number}
     * @memberof WorkPlanningsInfoCreate
     */
    effectivePricingSchemeId?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkPlanningsInfoCreate
     */
    projectId: number;
}
/**
 * 
 * @export
 * @interface WorkPlanningsInfoCreateAllOf
 */
export interface WorkPlanningsInfoCreateAllOf {
    /**
     * 
     * @type {number}
     * @memberof WorkPlanningsInfoCreateAllOf
     */
    projectId: number;
}
/**
 * Планирование работ на проекте: состояние чтения
 * @export
 * @interface WorkPlanningsInfoRead
 */
export interface WorkPlanningsInfoRead {
    /**
     * Идентификатор
     * @type {number}
     * @memberof WorkPlanningsInfoRead
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof WorkPlanningsInfoRead
     */
    businessVersion: number;
    /**
     * Ссылка: идентификатор автора создания
     * @type {number}
     * @memberof WorkPlanningsInfoRead
     */
    creationAuthorId: number;
    /**
     * ФИО автора создания
     * @type {PersonFullName}
     * @memberof WorkPlanningsInfoRead
     */
    creationAuthorFullName: PersonFullName;
    /**
     * Дата и время создания
     * @type {Date}
     * @memberof WorkPlanningsInfoRead
     */
    creationDate: Date;
    /**
     * Ссылка: идентификатор автора последнего изменения
     * @type {number}
     * @memberof WorkPlanningsInfoRead
     */
    lastUpdateAuthorId: number;
    /**
     * ФИО автора последнего изменения
     * @type {PersonFullName}
     * @memberof WorkPlanningsInfoRead
     */
    lastUpdateAuthorFullName: PersonFullName;
    /**
     * Дата и время последнего изменения
     * @type {Date}
     * @memberof WorkPlanningsInfoRead
     */
    lastUpdateDate: Date;
    /**
     * Комментарий
     * @type {string}
     * @memberof WorkPlanningsInfoRead
     */
    comment?: string;
    /**
     * Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof WorkPlanningsInfoRead
     */
    businessAccountId: number;
    /**
     * 
     * @type {string}
     * @memberof WorkPlanningsInfoRead
     */
    name: string;
    /**
     * 
     * @type {Date}
     * @memberof WorkPlanningsInfoRead
     */
    startDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof WorkPlanningsInfoRead
     */
    endDate: Date;
    /**
     * 
     * @type {number}
     * @memberof WorkPlanningsInfoRead
     */
    numberOfCrew: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof WorkPlanningsInfoRead
     */
    crewMemberIds?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof WorkPlanningsInfoRead
     */
    effectivePrice: number;
    /**
     * 
     * @type {number}
     * @memberof WorkPlanningsInfoRead
     */
    shiftCount: number;
    /**
     * 
     * @type {WorkPlanningsStateCodeEnum}
     * @memberof WorkPlanningsInfoRead
     */
    stateCode: WorkPlanningsStateCodeEnum;
    /**
     * 
     * @type {number}
     * @memberof WorkPlanningsInfoRead
     */
    professionId: number;
    /**
     * Идентификатор ценовой схемы
     * @type {number}
     * @memberof WorkPlanningsInfoRead
     */
    effectivePricingSchemeId?: number;
    /**
     * 
     * @type {Array<WorkPlanningsTransitionCodeEnum>}
     * @memberof WorkPlanningsInfoRead
     */
    availableTransitionCodes: Array<WorkPlanningsTransitionCodeEnum>;
    /**
     * 
     * @type {Array<CrewMemberSimpleObj>}
     * @memberof WorkPlanningsInfoRead
     */
    crewMembers: Array<CrewMemberSimpleObj>;
    /**
     * 
     * @type {string}
     * @memberof WorkPlanningsInfoRead
     */
    professionShortName: string;
    /**
     * 
     * @type {number}
     * @memberof WorkPlanningsInfoRead
     */
    projectId: number;
    /**
     * 
     * @type {string}
     * @memberof WorkPlanningsInfoRead
     */
    projectShortName: string;
    /**
     * Базовая цена за штуку, коп. Берется из каталожной цены на момент создания
     * @type {number}
     * @memberof WorkPlanningsInfoRead
     */
    basePriceAtTheTimeOfCreation: number;
    /**
     * 
     * @type {PricingSchemeExternalRepresentationObj}
     * @memberof WorkPlanningsInfoRead
     */
    effectivePricingScheme?: PricingSchemeExternalRepresentationObj;
    /**
     * 
     * @type {number}
     * @memberof WorkPlanningsInfoRead
     */
    finalTotalPrice: number;
    /**
     * Состояние проекта
     * @type {boolean}
     * @memberof WorkPlanningsInfoRead
     */
    projectIsOffer: boolean;
}
/**
 * 
 * @export
 * @interface WorkPlanningsInfoReadAllOf
 */
export interface WorkPlanningsInfoReadAllOf {
    /**
     * 
     * @type {Array<WorkPlanningsTransitionCodeEnum>}
     * @memberof WorkPlanningsInfoReadAllOf
     */
    availableTransitionCodes: Array<WorkPlanningsTransitionCodeEnum>;
    /**
     * 
     * @type {Array<CrewMemberSimpleObj>}
     * @memberof WorkPlanningsInfoReadAllOf
     */
    crewMembers: Array<CrewMemberSimpleObj>;
    /**
     * 
     * @type {string}
     * @memberof WorkPlanningsInfoReadAllOf
     */
    professionShortName: string;
    /**
     * 
     * @type {number}
     * @memberof WorkPlanningsInfoReadAllOf
     */
    projectId: number;
    /**
     * 
     * @type {string}
     * @memberof WorkPlanningsInfoReadAllOf
     */
    projectShortName: string;
    /**
     * Базовая цена за штуку, коп. Берется из каталожной цены на момент создания
     * @type {number}
     * @memberof WorkPlanningsInfoReadAllOf
     */
    basePriceAtTheTimeOfCreation: number;
    /**
     * 
     * @type {PricingSchemeExternalRepresentationObj}
     * @memberof WorkPlanningsInfoReadAllOf
     */
    effectivePricingScheme?: PricingSchemeExternalRepresentationObj;
    /**
     * 
     * @type {number}
     * @memberof WorkPlanningsInfoReadAllOf
     */
    finalTotalPrice: number;
    /**
     * Состояние проекта
     * @type {boolean}
     * @memberof WorkPlanningsInfoReadAllOf
     */
    projectIsOffer: boolean;
}
/**
 * Планирование работ на проекте: состояние обновления
 * @export
 * @interface WorkPlanningsInfoUpdate
 */
export interface WorkPlanningsInfoUpdate extends WorkPlanningsAbstractCommons {
}
/**
 * Не финансовые поля сущности WorkPlanningsRecord, которые могут быть выгружены в список Excel
 * @export
 * @enum {string}
 */
export enum WorkPlanningsNotFinancialFieldsTypeCode {
    Name = 'name',
    CounterpartyShortName = 'counterpartyShortName',
    ProjectShortName = 'projectShortName',
    ProfessionShortName = 'professionShortName',
    NumberOfCrew = 'numberOfCrew',
    NumberOfVacancies = 'numberOfVacancies',
    StateCode = 'stateCode',
    StartDate = 'startDate',
    EndDate = 'endDate',
    ShiftCount = 'shiftCount',
    LastUpdateDate = 'lastUpdateDate'
}

/**
 * 
 * @export
 * @interface WorkPlanningsRecord
 */
export interface WorkPlanningsRecord {
    /**
     * Идентификатор
     * @type {number}
     * @memberof WorkPlanningsRecord
     */
    id: number;
    /**
     * Бизнес версия сущности
     * @type {number}
     * @memberof WorkPlanningsRecord
     */
    businessVersion: number;
    /**
     * Ссылка: Идентификатор бизнес аккаунта
     * @type {number}
     * @memberof WorkPlanningsRecord
     */
    businessAccountId: number;
    /**
     * 
     * @type {string}
     * @memberof WorkPlanningsRecord
     */
    name: string;
    /**
     * Идентификатор контрагента
     * @type {number}
     * @memberof WorkPlanningsRecord
     */
    counterpartyId: number;
    /**
     * Короткое наименование контрагента
     * @type {string}
     * @memberof WorkPlanningsRecord
     */
    counterpartyShortName: string;
    /**
     * 
     * @type {number}
     * @memberof WorkPlanningsRecord
     */
    projectId: number;
    /**
     * 
     * @type {string}
     * @memberof WorkPlanningsRecord
     */
    projectShortName: string;
    /**
     * 
     * @type {number}
     * @memberof WorkPlanningsRecord
     */
    professionId: number;
    /**
     * 
     * @type {string}
     * @memberof WorkPlanningsRecord
     */
    professionExternalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkPlanningsRecord
     */
    professionShortName: string;
    /**
     * 
     * @type {Date}
     * @memberof WorkPlanningsRecord
     */
    startDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof WorkPlanningsRecord
     */
    endDate: Date;
    /**
     * 
     * @type {number}
     * @memberof WorkPlanningsRecord
     */
    numberOfCrew: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof WorkPlanningsRecord
     */
    crewMemberIds?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof WorkPlanningsRecord
     */
    numberOfVacancies: number;
    /**
     * 
     * @type {number}
     * @memberof WorkPlanningsRecord
     */
    effectivePrice: number;
    /**
     * Базовая цена за штуку, коп. Берется из каталожной цены на момент создания
     * @type {number}
     * @memberof WorkPlanningsRecord
     */
    basePriceAtTheTimeOfCreation: number;
    /**
     * 
     * @type {PricingSchemeExternalRepresentationObj}
     * @memberof WorkPlanningsRecord
     */
    effectivePricingScheme?: PricingSchemeExternalRepresentationObj;
    /**
     * 
     * @type {number}
     * @memberof WorkPlanningsRecord
     */
    shiftCount: number;
    /**
     * 
     * @type {number}
     * @memberof WorkPlanningsRecord
     */
    finalTotalPrice: number;
    /**
     * 
     * @type {WorkPlanningsStateCodeEnum}
     * @memberof WorkPlanningsRecord
     */
    stateCode: WorkPlanningsStateCodeEnum;
    /**
     * 
     * @type {Array<WorkPlanningsTransitionCodeEnum>}
     * @memberof WorkPlanningsRecord
     */
    availableTransitionCodes: Array<WorkPlanningsTransitionCodeEnum>;
    /**
     * Идентификатор автора последнего изменения
     * @type {number}
     * @memberof WorkPlanningsRecord
     */
    lastUpdateAuthorId: number;
    /**
     * Фио с инициалами автора последнего изменения
     * @type {string}
     * @memberof WorkPlanningsRecord
     */
    lastUpdateAuthorShortName: string;
    /**
     * Дата последнего изменения
     * @type {Date}
     * @memberof WorkPlanningsRecord
     */
    lastUpdateDate: Date;
    /**
     * Состояние проекта
     * @type {boolean}
     * @memberof WorkPlanningsRecord
     */
    projectIsOffer: boolean;
}
/**
 * 
 * @export
 * @interface WorkPlanningsRecordAllOf
 */
export interface WorkPlanningsRecordAllOf {
    /**
     * 
     * @type {string}
     * @memberof WorkPlanningsRecordAllOf
     */
    name: string;
    /**
     * Идентификатор контрагента
     * @type {number}
     * @memberof WorkPlanningsRecordAllOf
     */
    counterpartyId: number;
    /**
     * Короткое наименование контрагента
     * @type {string}
     * @memberof WorkPlanningsRecordAllOf
     */
    counterpartyShortName: string;
    /**
     * 
     * @type {number}
     * @memberof WorkPlanningsRecordAllOf
     */
    projectId: number;
    /**
     * 
     * @type {string}
     * @memberof WorkPlanningsRecordAllOf
     */
    projectShortName: string;
    /**
     * 
     * @type {number}
     * @memberof WorkPlanningsRecordAllOf
     */
    professionId: number;
    /**
     * 
     * @type {string}
     * @memberof WorkPlanningsRecordAllOf
     */
    professionExternalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkPlanningsRecordAllOf
     */
    professionShortName: string;
    /**
     * 
     * @type {Date}
     * @memberof WorkPlanningsRecordAllOf
     */
    startDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof WorkPlanningsRecordAllOf
     */
    endDate: Date;
    /**
     * 
     * @type {number}
     * @memberof WorkPlanningsRecordAllOf
     */
    numberOfCrew: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof WorkPlanningsRecordAllOf
     */
    crewMemberIds?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof WorkPlanningsRecordAllOf
     */
    numberOfVacancies: number;
    /**
     * 
     * @type {number}
     * @memberof WorkPlanningsRecordAllOf
     */
    effectivePrice: number;
    /**
     * Базовая цена за штуку, коп. Берется из каталожной цены на момент создания
     * @type {number}
     * @memberof WorkPlanningsRecordAllOf
     */
    basePriceAtTheTimeOfCreation: number;
    /**
     * 
     * @type {PricingSchemeExternalRepresentationObj}
     * @memberof WorkPlanningsRecordAllOf
     */
    effectivePricingScheme?: PricingSchemeExternalRepresentationObj;
    /**
     * 
     * @type {number}
     * @memberof WorkPlanningsRecordAllOf
     */
    shiftCount: number;
    /**
     * 
     * @type {number}
     * @memberof WorkPlanningsRecordAllOf
     */
    finalTotalPrice: number;
    /**
     * 
     * @type {WorkPlanningsStateCodeEnum}
     * @memberof WorkPlanningsRecordAllOf
     */
    stateCode: WorkPlanningsStateCodeEnum;
    /**
     * 
     * @type {Array<WorkPlanningsTransitionCodeEnum>}
     * @memberof WorkPlanningsRecordAllOf
     */
    availableTransitionCodes: Array<WorkPlanningsTransitionCodeEnum>;
    /**
     * Идентификатор автора последнего изменения
     * @type {number}
     * @memberof WorkPlanningsRecordAllOf
     */
    lastUpdateAuthorId: number;
    /**
     * Фио с инициалами автора последнего изменения
     * @type {string}
     * @memberof WorkPlanningsRecordAllOf
     */
    lastUpdateAuthorShortName: string;
    /**
     * Дата последнего изменения
     * @type {Date}
     * @memberof WorkPlanningsRecordAllOf
     */
    lastUpdateDate: Date;
    /**
     * Состояние проекта
     * @type {boolean}
     * @memberof WorkPlanningsRecordAllOf
     */
    projectIsOffer: boolean;
}
/**
 * 
 * @export
 * @interface WorkPlanningsRecordList
 */
export interface WorkPlanningsRecordList {
    /**
     * 
     * @type {ListAttributesInfo}
     * @memberof WorkPlanningsRecordList
     */
    listAttributes: ListAttributesInfo;
    /**
     * 
     * @type {Array<WorkPlanningsRecord>}
     * @memberof WorkPlanningsRecordList
     */
    records: Array<WorkPlanningsRecord>;
}
/**
 * 
 * @export
 * @interface WorkPlanningsRecordListAllOf
 */
export interface WorkPlanningsRecordListAllOf {
    /**
     * 
     * @type {Array<WorkPlanningsRecord>}
     * @memberof WorkPlanningsRecordListAllOf
     */
    records: Array<WorkPlanningsRecord>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum WorkPlanningsStateCodeEnum {
    DRAFT = 'DRAFT',
    PLANNED = 'PLANNED',
    INPROGRESS = 'IN_PROGRESS',
    FINISHED = 'FINISHED',
    CANCELED = 'CANCELED'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum WorkPlanningsTransitionCodeEnum {
    TODRAFT = 'TO_DRAFT',
    PLAN = 'PLAN',
    START = 'START',
    FINISH = 'FINISH',
    CANCEL = 'CANCEL'
}

/**
 * Директива жизненного цикла - изменение статуса и архивации сущности
 * @export
 * @interface WorkflowDirective
 */
export interface WorkflowDirective {
    /**
     * Идетификатор сущности
     * @type {number}
     * @memberof WorkflowDirective
     */
    id: number;
    /**
     * Версия
     * @type {number}
     * @memberof WorkflowDirective
     */
    businessVersion: number;
    /**
     * Новое значение маркера нахождения в архиве
     * @type {boolean}
     * @memberof WorkflowDirective
     */
    archive?: boolean;
    /**
     * Код перехода между статусами согласно ЖЦ
     * @type {string}
     * @memberof WorkflowDirective
     */
    transitionCode?: string;
}
/**
 * Множество директив жизненного цикла
 * @export
 * @interface WorkflowDirectiveBulk
 */
export interface WorkflowDirectiveBulk {
    /**
     * 
     * @type {Array<WorkflowDirective>}
     * @memberof WorkflowDirectiveBulk
     */
    directives: Array<WorkflowDirective>;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Добавление обязательств в существующий проект по шаблону
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {number} templateId Идентификатор шаблона
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRentElementsFromTemplateToExistingProject(businessAccountId: number, id: number, templateId: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling addRentElementsFromTemplateToExistingProject.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling addRentElementsFromTemplateToExistingProject.');
            }
            // verify required parameter 'templateId' is not null or undefined
            if (templateId === null || templateId === undefined) {
                throw new RequiredError('templateId','Required parameter templateId was null or undefined when calling addRentElementsFromTemplateToExistingProject.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/projects/{id}/addFromTemplate/{templateId}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Назначение ролей пользователю
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {UserBARolesUpdateObj} userBARolesUpdateObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationAssignRolesToUser(businessAccountId: number, id: number, userBARolesUpdateObj: UserBARolesUpdateObj, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling administrationAssignRolesToUser.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling administrationAssignRolesToUser.');
            }
            // verify required parameter 'userBARolesUpdateObj' is not null or undefined
            if (userBARolesUpdateObj === null || userBARolesUpdateObj === undefined) {
                throw new RequiredError('userBARolesUpdateObj','Required parameter userBARolesUpdateObj was null or undefined when calling administrationAssignRolesToUser.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/administration/users/{id}/roles`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof userBARolesUpdateObj !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(userBARolesUpdateObj !== undefined ? userBARolesUpdateObj : {}) : (userBARolesUpdateObj || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка записей пользователей бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {'INVITED' | 'ACTIVE' | 'BLOCKED'} [stateFilter] Фильтр по статусу пользователя
         * @param {'AGENT' | 'ADMIN'} [roleFilter] Фильтр по роли пользователя в бизнес аккаунте
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationListBAUsers(businessAccountId: number, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, stateFilter?: 'INVITED' | 'ACTIVE' | 'BLOCKED', roleFilter?: 'AGENT' | 'ADMIN', options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling administrationListBAUsers.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/administration/users`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (stateFilter !== undefined) {
                localVarQueryParameter['stateFilter'] = stateFilter;
            }

            if (roleFilter !== undefined) {
                localVarQueryParameter['roleFilter'] = roleFilter;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Ответ на приглашение
         * @param {string} token Код активации приглашения
         * @param {boolean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        answerToTheInvitation(token: string, body: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling answerToTheInvitation.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling answerToTheInvitation.');
            }
            const localVarPath = `/invitations/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Назначение контакта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {ContactAssignInfo} contactAssignInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignContactToEntity(businessAccountId: number, contactAssignInfo: ContactAssignInfo, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling assignContactToEntity.');
            }
            // verify required parameter 'contactAssignInfo' is not null or undefined
            if (contactAssignInfo === null || contactAssignInfo === undefined) {
                throw new RequiredError('contactAssignInfo','Required parameter contactAssignInfo was null or undefined when calling assignContactToEntity.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/contacts/assign`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof contactAssignInfo !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(contactAssignInfo !== undefined ? contactAssignInfo : {}) : (contactAssignInfo || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Массовое обновление порядка категорий
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {CategoryTypeCodeEnum} typeCode Тип изменяемых категорий
         * @param {BulkUpdateCategoriesOrder} bulkUpdateCategoriesOrder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkUpdateCategoriesOrder(businessAccountId: number, typeCode: CategoryTypeCodeEnum, bulkUpdateCategoriesOrder: BulkUpdateCategoriesOrder, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling bulkUpdateCategoriesOrder.');
            }
            // verify required parameter 'typeCode' is not null or undefined
            if (typeCode === null || typeCode === undefined) {
                throw new RequiredError('typeCode','Required parameter typeCode was null or undefined when calling bulkUpdateCategoriesOrder.');
            }
            // verify required parameter 'bulkUpdateCategoriesOrder' is not null or undefined
            if (bulkUpdateCategoriesOrder === null || bulkUpdateCategoriesOrder === undefined) {
                throw new RequiredError('bulkUpdateCategoriesOrder','Required parameter bulkUpdateCategoriesOrder was null or undefined when calling bulkUpdateCategoriesOrder.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/categories/tree`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (typeCode !== undefined) {
                localVarQueryParameter['typeCode'] = typeCode;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof bulkUpdateCategoriesOrder !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(bulkUpdateCategoriesOrder !== undefined ? bulkUpdateCategoriesOrder : {}) : (bulkUpdateCategoriesOrder || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение расчета стоимости и доступности позиций корзины
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} accessToken Обязательный токен доступа в формате UUID
         * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
         * @param {ClientCart} clientCart 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateCart(businessAccountId: number, accessToken: string, storefrontId: number, clientCart: ClientCart, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling calculateCart.');
            }
            // verify required parameter 'accessToken' is not null or undefined
            if (accessToken === null || accessToken === undefined) {
                throw new RequiredError('accessToken','Required parameter accessToken was null or undefined when calling calculateCart.');
            }
            // verify required parameter 'storefrontId' is not null or undefined
            if (storefrontId === null || storefrontId === undefined) {
                throw new RequiredError('storefrontId','Required parameter storefrontId was null or undefined when calling calculateCart.');
            }
            // verify required parameter 'clientCart' is not null or undefined
            if (clientCart === null || clientCart === undefined) {
                throw new RequiredError('clientCart','Required parameter clientCart was null or undefined when calling calculateCart.');
            }
            const localVarPath = `/public/v1/server/businessAccounts/{businessAccountId}/storefronts/{storefrontId}/calculate`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"storefrontId"}}`, encodeURIComponent(String(storefrontId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (accessToken !== undefined) {
                localVarQueryParameter['accessToken'] = accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof clientCart !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(clientCart !== undefined ? clientCart : {}) : (clientCart || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Пересчет отработанных смен по обязательствам с именованными экземплярами
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} subrentId Идентификатор поставки
         * @param {boolean} updateShifts Признак необходимости обновить смены обязательств субаренды результатом рассчета. Поставить в поле смены получившееся значение поля отработанные смены.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateWorkedShifts(businessAccountId: number, subrentId: number, updateShifts: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling calculateWorkedShifts.');
            }
            // verify required parameter 'subrentId' is not null or undefined
            if (subrentId === null || subrentId === undefined) {
                throw new RequiredError('subrentId','Required parameter subrentId was null or undefined when calling calculateWorkedShifts.');
            }
            // verify required parameter 'updateShifts' is not null or undefined
            if (updateShifts === null || updateShifts === undefined) {
                throw new RequiredError('updateShifts','Required parameter updateShifts was null or undefined when calling calculateWorkedShifts.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/subrent/{subrentId}/calculateWorkedShifts`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"subrentId"}}`, encodeURIComponent(String(subrentId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (updateShifts !== undefined) {
                localVarQueryParameter['updateShifts'] = updateShifts;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Отмена заказа. Необходим JWT токен пользователя
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
         * @param {string} orderNumber Идентификатор заказа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelOrder(businessAccountId: number, storefrontId: number, orderNumber: string, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling cancelOrder.');
            }
            // verify required parameter 'storefrontId' is not null or undefined
            if (storefrontId === null || storefrontId === undefined) {
                throw new RequiredError('storefrontId','Required parameter storefrontId was null or undefined when calling cancelOrder.');
            }
            // verify required parameter 'orderNumber' is not null or undefined
            if (orderNumber === null || orderNumber === undefined) {
                throw new RequiredError('orderNumber','Required parameter orderNumber was null or undefined when calling cancelOrder.');
            }
            const localVarPath = `/public/v1/client/businessAccounts/{businessAccountId}/storefronts/{storefrontId}/orders/{orderNumber}/cancel`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"storefrontId"}}`, encodeURIComponent(String(storefrontId)))
                .replace(`{${"orderNumber"}}`, encodeURIComponent(String(orderNumber)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Перевод проекта в новый статус с помощью корректировки
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {ProjectStateCodeEnum} targetState Целевой статус
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeOrderStateByCorrection(businessAccountId: number, id: number, targetState: ProjectStateCodeEnum, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling changeOrderStateByCorrection.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling changeOrderStateByCorrection.');
            }
            // verify required parameter 'targetState' is not null or undefined
            if (targetState === null || targetState === undefined) {
                throw new RequiredError('targetState','Required parameter targetState was null or undefined when calling changeOrderStateByCorrection.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/projects/order/{id}/correction`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (targetState !== undefined) {
                localVarQueryParameter['targetState'] = targetState;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Массовая очистка тэгов у указанных сущностей
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {TagEntityTypeCode} entityTypeCode Создать или удалить, true &#x3D; создать, false &#x3D; удалить
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearTagsBulk(businessAccountId: number, entityTypeCode: TagEntityTypeCode, versionedEntityObjList: VersionedEntityObjList, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling clearTagsBulk.');
            }
            // verify required parameter 'entityTypeCode' is not null or undefined
            if (entityTypeCode === null || entityTypeCode === undefined) {
                throw new RequiredError('entityTypeCode','Required parameter entityTypeCode was null or undefined when calling clearTagsBulk.');
            }
            // verify required parameter 'versionedEntityObjList' is not null or undefined
            if (versionedEntityObjList === null || versionedEntityObjList === undefined) {
                throw new RequiredError('versionedEntityObjList','Required parameter versionedEntityObjList was null or undefined when calling clearTagsBulk.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/tags`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (entityTypeCode !== undefined) {
                localVarQueryParameter['entityTypeCode'] = entityTypeCode;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof versionedEntityObjList !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(versionedEntityObjList !== undefined ? versionedEntityObjList : {}) : (versionedEntityObjList || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Преобразование простого заказа в проект
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertOrderToProject(businessAccountId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling convertOrderToProject.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling convertOrderToProject.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/projects/order/{id}/convert-to-project`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание нового вложения. Максимальный объем 10 МБ
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {AttachmentInfoCreate} attachmentInfoCreate 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAttachment(businessAccountId: number, attachmentInfoCreate: AttachmentInfoCreate, file: any, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling createAttachment.');
            }
            // verify required parameter 'attachmentInfoCreate' is not null or undefined
            if (attachmentInfoCreate === null || attachmentInfoCreate === undefined) {
                throw new RequiredError('attachmentInfoCreate','Required parameter attachmentInfoCreate was null or undefined when calling createAttachment.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling createAttachment.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/attachments`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


            if (attachmentInfoCreate !== undefined) { 
                localVarFormParams.append('attachmentInfoCreate', attachmentInfoCreate as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание нового бизнес аккаунта
         * @param {BusinessAccountInfoCreate} businessAccountInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBusinessAccount(businessAccountInfoCreate: BusinessAccountInfoCreate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountInfoCreate' is not null or undefined
            if (businessAccountInfoCreate === null || businessAccountInfoCreate === undefined) {
                throw new RequiredError('businessAccountInfoCreate','Required parameter businessAccountInfoCreate was null or undefined when calling createBusinessAccount.');
            }
            const localVarPath = `/businessAccounts`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof businessAccountInfoCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(businessAccountInfoCreate !== undefined ? businessAccountInfoCreate : {}) : (businessAccountInfoCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание новой категории
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {CategoryInfoCreate} categoryInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCategory(businessAccountId: number, categoryInfoCreate: CategoryInfoCreate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling createCategory.');
            }
            // verify required parameter 'categoryInfoCreate' is not null or undefined
            if (categoryInfoCreate === null || categoryInfoCreate === undefined) {
                throw new RequiredError('categoryInfoCreate','Required parameter categoryInfoCreate was null or undefined when calling createCategory.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/categories`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof categoryInfoCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(categoryInfoCreate !== undefined ? categoryInfoCreate : {}) : (categoryInfoCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Метод создаёт сущность в базе и записывает файл на диск
         * @summary Создание стандартного шаблона документа
         * @param {any} file 
         * @param {DocumentTemplateInfoCreate} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommonDocumentTemplate(file: any, data: DocumentTemplateInfoCreate, options: any = {}): RequestArgs {
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling createCommonDocumentTemplate.');
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling createCommonDocumentTemplate.');
            }
            const localVarPath = `/global/documentTemplates`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (data !== undefined) { 
                localVarFormParams.append('data', data as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание контакта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {ContactInfoCreate} contactInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContact(businessAccountId: number, contactInfoCreate: ContactInfoCreate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling createContact.');
            }
            // verify required parameter 'contactInfoCreate' is not null or undefined
            if (contactInfoCreate === null || contactInfoCreate === undefined) {
                throw new RequiredError('contactInfoCreate','Required parameter contactInfoCreate was null or undefined when calling createContact.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/contacts`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof contactInfoCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(contactInfoCreate !== undefined ? contactInfoCreate : {}) : (contactInfoCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание контакта с вложениями
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {ContactInfoCreate} [contactInfoCreate] 
         * @param {Array<any>} [attachmentInfoCreates] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContactWithAttachments(businessAccountId: number, contactInfoCreate?: ContactInfoCreate, attachmentInfoCreates?: Array<any>, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling createContactWithAttachments.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/contacts/with-attachments`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


            if (contactInfoCreate !== undefined) { 
                localVarFormParams.append('contactInfoCreate', contactInfoCreate as any);
            }
                if (attachmentInfoCreates) {
            
                localVarFormParams.append('attachmentInfoCreates', attachmentInfoCreates.join(COLLECTION_FORMATS.csv));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание новой операции корректировки
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {OperationInfoCreate} operationInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCorrectionOperation(businessAccountId: number, operationInfoCreate: OperationInfoCreate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling createCorrectionOperation.');
            }
            // verify required parameter 'operationInfoCreate' is not null or undefined
            if (operationInfoCreate === null || operationInfoCreate === undefined) {
                throw new RequiredError('operationInfoCreate','Required parameter operationInfoCreate was null or undefined when calling createCorrectionOperation.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/operations/correction`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof operationInfoCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(operationInfoCreate !== undefined ? operationInfoCreate : {}) : (operationInfoCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание нового кадра
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {CrewMemberInfoCreate} crewMemberInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCrewMember(businessAccountId: number, crewMemberInfoCreate: CrewMemberInfoCreate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling createCrewMember.');
            }
            // verify required parameter 'crewMemberInfoCreate' is not null or undefined
            if (crewMemberInfoCreate === null || crewMemberInfoCreate === undefined) {
                throw new RequiredError('crewMemberInfoCreate','Required parameter crewMemberInfoCreate was null or undefined when calling createCrewMember.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/crew-members`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof crewMemberInfoCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(crewMemberInfoCreate !== undefined ? crewMemberInfoCreate : {}) : (crewMemberInfoCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание контакта с вложениями
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {CrewMemberInfoCreate} [contactInfoCreate] 
         * @param {Array<any>} [attachmentInfoCreates] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCrewMemberWithAttachments(businessAccountId: number, contactInfoCreate?: CrewMemberInfoCreate, attachmentInfoCreates?: Array<any>, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling createCrewMemberWithAttachments.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/crew-members/with-attachments`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


            if (contactInfoCreate !== undefined) { 
                localVarFormParams.append('contactInfoCreate', contactInfoCreate as any);
            }
                if (attachmentInfoCreates) {
            
                localVarFormParams.append('attachmentInfoCreates', attachmentInfoCreates.join(COLLECTION_FORMATS.csv));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание нового пользовательского поля
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {CustomFieldInfoCreate} customFieldInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomField(businessAccountId: number, customFieldInfoCreate: CustomFieldInfoCreate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling createCustomField.');
            }
            // verify required parameter 'customFieldInfoCreate' is not null or undefined
            if (customFieldInfoCreate === null || customFieldInfoCreate === undefined) {
                throw new RequiredError('customFieldInfoCreate','Required parameter customFieldInfoCreate was null or undefined when calling createCustomField.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/customFields`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof customFieldInfoCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(customFieldInfoCreate !== undefined ? customFieldInfoCreate : {}) : (customFieldInfoCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Привязка настраиваемых полей к вариантам продукта (создание связей)
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {CustomFieldValueObjWriteList} customFieldValueObjWriteList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomFieldLinksToProductVariants(businessAccountId: number, id: number, customFieldValueObjWriteList: CustomFieldValueObjWriteList, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling createCustomFieldLinksToProductVariants.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling createCustomFieldLinksToProductVariants.');
            }
            // verify required parameter 'customFieldValueObjWriteList' is not null or undefined
            if (customFieldValueObjWriteList === null || customFieldValueObjWriteList === undefined) {
                throw new RequiredError('customFieldValueObjWriteList','Required parameter customFieldValueObjWriteList was null or undefined when calling createCustomFieldLinksToProductVariants.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/products/{id}/variants/customFields`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof customFieldValueObjWriteList !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(customFieldValueObjWriteList !== undefined ? customFieldValueObjWriteList : {}) : (customFieldValueObjWriteList || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание нового шаблона документа
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {any} file 
         * @param {DocumentTemplateInfoCreate} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocumentTemplate(businessAccountId: number, file: any, data: DocumentTemplateInfoCreate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling createDocumentTemplate.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling createDocumentTemplate.');
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling createDocumentTemplate.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/documentTemplates`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (data !== undefined) { 
                localVarFormParams.append('data', data as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Создание сущности затраты по проекту
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {ExpenseInfoCreate} expenseInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExpense(businessAccountId: number, expenseInfoCreate: ExpenseInfoCreate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling createExpense.');
            }
            // verify required parameter 'expenseInfoCreate' is not null or undefined
            if (expenseInfoCreate === null || expenseInfoCreate === undefined) {
                throw new RequiredError('expenseInfoCreate','Required parameter expenseInfoCreate was null or undefined when calling createExpense.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/expense`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof expenseInfoCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(expenseInfoCreate !== undefined ? expenseInfoCreate : {}) : (expenseInfoCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание нового шаблона для экспорта
         * @param {ExportTemplateInfoCreate} model 
         * @param {any} content 
         * @param {any} jasper 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExportTemplate(model: ExportTemplateInfoCreate, content: any, jasper: any, options: any = {}): RequestArgs {
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling createExportTemplate.');
            }
            // verify required parameter 'content' is not null or undefined
            if (content === null || content === undefined) {
                throw new RequiredError('content','Required parameter content was null or undefined when calling createExportTemplate.');
            }
            // verify required parameter 'jasper' is not null or undefined
            if (jasper === null || jasper === undefined) {
                throw new RequiredError('jasper','Required parameter jasper was null or undefined when calling createExportTemplate.');
            }
            const localVarPath = `/global/analytics/exportTemplate`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


            if (model !== undefined) { 
                localVarFormParams.append('model', model as any);
            }
    
            if (content !== undefined) { 
                localVarFormParams.append('content', content as any);
            }
    
            if (jasper !== undefined) { 
                localVarFormParams.append('jasper', jasper as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание нового изображения. Минимальная ширина и высота 200px, максимальный объем ограничен
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createImage(businessAccountId: number, file: any, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling createImage.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling createImage.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/images`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Массовое создание экземпляров продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {boolean} setInventoryNumber Устанавливать дополнительно инвентарный номер
         * @param {boolean} setBarcode Устанавливать дополнительно штриховой код
         * @param {InstanceInfoCreateBulk} instanceInfoCreateBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInstancesBulk(businessAccountId: number, id: number, setInventoryNumber: boolean, setBarcode: boolean, instanceInfoCreateBulk: InstanceInfoCreateBulk, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling createInstancesBulk.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling createInstancesBulk.');
            }
            // verify required parameter 'setInventoryNumber' is not null or undefined
            if (setInventoryNumber === null || setInventoryNumber === undefined) {
                throw new RequiredError('setInventoryNumber','Required parameter setInventoryNumber was null or undefined when calling createInstancesBulk.');
            }
            // verify required parameter 'setBarcode' is not null or undefined
            if (setBarcode === null || setBarcode === undefined) {
                throw new RequiredError('setBarcode','Required parameter setBarcode was null or undefined when calling createInstancesBulk.');
            }
            // verify required parameter 'instanceInfoCreateBulk' is not null or undefined
            if (instanceInfoCreateBulk === null || instanceInfoCreateBulk === undefined) {
                throw new RequiredError('instanceInfoCreateBulk','Required parameter instanceInfoCreateBulk was null or undefined when calling createInstancesBulk.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/products/{id}/instances/bulk`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (setInventoryNumber !== undefined) {
                localVarQueryParameter['setInventoryNumber'] = setInventoryNumber;
            }

            if (setBarcode !== undefined) {
                localVarQueryParameter['setBarcode'] = setBarcode;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof instanceInfoCreateBulk !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(instanceInfoCreateBulk !== undefined ? instanceInfoCreateBulk : {}) : (instanceInfoCreateBulk || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание события движения инвентаря
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {InventoryMovementInfoCreate} inventoryMovementInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInventoryMovement(businessAccountId: number, inventoryMovementInfoCreate: InventoryMovementInfoCreate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling createInventoryMovement.');
            }
            // verify required parameter 'inventoryMovementInfoCreate' is not null or undefined
            if (inventoryMovementInfoCreate === null || inventoryMovementInfoCreate === undefined) {
                throw new RequiredError('inventoryMovementInfoCreate','Required parameter inventoryMovementInfoCreate was null or undefined when calling createInventoryMovement.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/inventory-movements`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof inventoryMovementInfoCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(inventoryMovementInfoCreate !== undefined ? inventoryMovementInfoCreate : {}) : (inventoryMovementInfoCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание события движения инвентаря с файлами
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {InventoryMovementInfoCreate} [inventoryMovementInfoCreate] 
         * @param {Array<any>} [attachmentInfoCreates] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInventoryMovementWithAttachments(businessAccountId: number, inventoryMovementInfoCreate?: InventoryMovementInfoCreate, attachmentInfoCreates?: Array<any>, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling createInventoryMovementWithAttachments.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/inventory-movements/with-attachments`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


            if (inventoryMovementInfoCreate !== undefined) { 
                localVarFormParams.append('inventoryMovementInfoCreate', inventoryMovementInfoCreate as any);
            }
                if (attachmentInfoCreates) {
            
                for (let item of attachmentInfoCreates) { localVarFormParams.append('attachmentInfoCreates', item); }
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание нового набора
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {KitInfoCreate} kitInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createKit(businessAccountId: number, kitInfoCreate: KitInfoCreate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling createKit.');
            }
            // verify required parameter 'kitInfoCreate' is not null or undefined
            if (kitInfoCreate === null || kitInfoCreate === undefined) {
                throw new RequiredError('kitInfoCreate','Required parameter kitInfoCreate was null or undefined when calling createKit.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/kits`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof kitInfoCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(kitInfoCreate !== undefined ? kitInfoCreate : {}) : (kitInfoCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание нового объекта субъектов и реквизитов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {LegalDetailsInfoWrite} legalDetailsInfoWrite 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLegalDetails(businessAccountId: number, legalDetailsInfoWrite: LegalDetailsInfoWrite, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling createLegalDetails.');
            }
            // verify required parameter 'legalDetailsInfoWrite' is not null or undefined
            if (legalDetailsInfoWrite === null || legalDetailsInfoWrite === undefined) {
                throw new RequiredError('legalDetailsInfoWrite','Required parameter legalDetailsInfoWrite was null or undefined when calling createLegalDetails.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/legal-details`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof legalDetailsInfoWrite !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(legalDetailsInfoWrite !== undefined ? legalDetailsInfoWrite : {}) : (legalDetailsInfoWrite || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание новой локации
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {LocationInfoCreate} locationInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLocation(businessAccountId: number, locationInfoCreate: LocationInfoCreate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling createLocation.');
            }
            // verify required parameter 'locationInfoCreate' is not null or undefined
            if (locationInfoCreate === null || locationInfoCreate === undefined) {
                throw new RequiredError('locationInfoCreate','Required parameter locationInfoCreate was null or undefined when calling createLocation.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/locations`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof locationInfoCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(locationInfoCreate !== undefined ? locationInfoCreate : {}) : (locationInfoCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {LocationInfoCreate} [contactInfoCreate] 
         * @param {Array<any>} [attachmentInfoCreates] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLocationWithAttachments(businessAccountId: number, contactInfoCreate?: LocationInfoCreate, attachmentInfoCreates?: Array<any>, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling createLocationWithAttachments.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/locations/with-attachments`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


            if (contactInfoCreate !== undefined) { 
                localVarFormParams.append('contactInfoCreate', contactInfoCreate as any);
            }
                if (attachmentInfoCreates) {
            
                localVarFormParams.append('attachmentInfoCreates', attachmentInfoCreates.join(COLLECTION_FORMATS.csv));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание новой операции
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {OperationInfoCreate} operationInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOperation(businessAccountId: number, operationInfoCreate: OperationInfoCreate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling createOperation.');
            }
            // verify required parameter 'operationInfoCreate' is not null or undefined
            if (operationInfoCreate === null || operationInfoCreate === undefined) {
                throw new RequiredError('operationInfoCreate','Required parameter operationInfoCreate was null or undefined when calling createOperation.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/operations`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof operationInfoCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(operationInfoCreate !== undefined ? operationInfoCreate : {}) : (operationInfoCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание заказа через витрину. Необходим JWT токен пользователя
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
         * @param {OrderInfoCreate} orderInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderByClient(businessAccountId: number, storefrontId: number, orderInfoCreate: OrderInfoCreate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling createOrderByClient.');
            }
            // verify required parameter 'storefrontId' is not null or undefined
            if (storefrontId === null || storefrontId === undefined) {
                throw new RequiredError('storefrontId','Required parameter storefrontId was null or undefined when calling createOrderByClient.');
            }
            // verify required parameter 'orderInfoCreate' is not null or undefined
            if (orderInfoCreate === null || orderInfoCreate === undefined) {
                throw new RequiredError('orderInfoCreate','Required parameter orderInfoCreate was null or undefined when calling createOrderByClient.');
            }
            const localVarPath = `/public/v1/client/businessAccounts/{businessAccountId}/storefronts/{storefrontId}/orders`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"storefrontId"}}`, encodeURIComponent(String(storefrontId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof orderInfoCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(orderInfoCreate !== undefined ? orderInfoCreate : {}) : (orderInfoCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание заказа через витрину
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} accessToken Обязательный токен доступа в формате UUID
         * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
         * @param {OrderInfoCreate} orderInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderByServer(businessAccountId: number, accessToken: string, storefrontId: number, orderInfoCreate: OrderInfoCreate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling createOrderByServer.');
            }
            // verify required parameter 'accessToken' is not null or undefined
            if (accessToken === null || accessToken === undefined) {
                throw new RequiredError('accessToken','Required parameter accessToken was null or undefined when calling createOrderByServer.');
            }
            // verify required parameter 'storefrontId' is not null or undefined
            if (storefrontId === null || storefrontId === undefined) {
                throw new RequiredError('storefrontId','Required parameter storefrontId was null or undefined when calling createOrderByServer.');
            }
            // verify required parameter 'orderInfoCreate' is not null or undefined
            if (orderInfoCreate === null || orderInfoCreate === undefined) {
                throw new RequiredError('orderInfoCreate','Required parameter orderInfoCreate was null or undefined when calling createOrderByServer.');
            }
            const localVarPath = `/public/v1/server/businessAccounts/{businessAccountId}/storefronts/{storefrontId}/orders`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"storefrontId"}}`, encodeURIComponent(String(storefrontId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (accessToken !== undefined) {
                localVarQueryParameter['accessToken'] = accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof orderInfoCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(orderInfoCreate !== undefined ? orderInfoCreate : {}) : (orderInfoCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание простого заказа
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {SimpleOrderInfoCreate} simpleOrderInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderWithRentElements(businessAccountId: number, simpleOrderInfoCreate: SimpleOrderInfoCreate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling createOrderWithRentElements.');
            }
            // verify required parameter 'simpleOrderInfoCreate' is not null or undefined
            if (simpleOrderInfoCreate === null || simpleOrderInfoCreate === undefined) {
                throw new RequiredError('simpleOrderInfoCreate','Required parameter simpleOrderInfoCreate was null or undefined when calling createOrderWithRentElements.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/projects/order`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof simpleOrderInfoCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(simpleOrderInfoCreate !== undefined ? simpleOrderInfoCreate : {}) : (simpleOrderInfoCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание платежа
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {PaymentInfoCreate} paymentInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPayment(businessAccountId: number, paymentInfoCreate: PaymentInfoCreate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling createPayment.');
            }
            // verify required parameter 'paymentInfoCreate' is not null or undefined
            if (paymentInfoCreate === null || paymentInfoCreate === undefined) {
                throw new RequiredError('paymentInfoCreate','Required parameter paymentInfoCreate was null or undefined when calling createPayment.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/payments`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof paymentInfoCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(paymentInfoCreate !== undefined ? paymentInfoCreate : {}) : (paymentInfoCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание нового способа оплаты
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {PaymentMethodInfoCreate} paymentMethodInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPaymentMethod(businessAccountId: number, paymentMethodInfoCreate: PaymentMethodInfoCreate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling createPaymentMethod.');
            }
            // verify required parameter 'paymentMethodInfoCreate' is not null or undefined
            if (paymentMethodInfoCreate === null || paymentMethodInfoCreate === undefined) {
                throw new RequiredError('paymentMethodInfoCreate','Required parameter paymentMethodInfoCreate was null or undefined when calling createPaymentMethod.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/payment-methods`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof paymentMethodInfoCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(paymentMethodInfoCreate !== undefined ? paymentMethodInfoCreate : {}) : (paymentMethodInfoCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание платежа
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} accessToken Обязательный токен доступа в формате UUID
         * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
         * @param {PublicPaymentInfoCreate} publicPaymentInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPayment_1(businessAccountId: number, accessToken: string, storefrontId: number, publicPaymentInfoCreate: PublicPaymentInfoCreate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling createPayment_1.');
            }
            // verify required parameter 'accessToken' is not null or undefined
            if (accessToken === null || accessToken === undefined) {
                throw new RequiredError('accessToken','Required parameter accessToken was null or undefined when calling createPayment_1.');
            }
            // verify required parameter 'storefrontId' is not null or undefined
            if (storefrontId === null || storefrontId === undefined) {
                throw new RequiredError('storefrontId','Required parameter storefrontId was null or undefined when calling createPayment_1.');
            }
            // verify required parameter 'publicPaymentInfoCreate' is not null or undefined
            if (publicPaymentInfoCreate === null || publicPaymentInfoCreate === undefined) {
                throw new RequiredError('publicPaymentInfoCreate','Required parameter publicPaymentInfoCreate was null or undefined when calling createPayment_1.');
            }
            const localVarPath = `/public/v1/server/businessAccounts/{businessAccountId}/storefronts/{storefrontId}/payments`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"storefrontId"}}`, encodeURIComponent(String(storefrontId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (accessToken !== undefined) {
                localVarQueryParameter['accessToken'] = accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof publicPaymentInfoCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(publicPaymentInfoCreate !== undefined ? publicPaymentInfoCreate : {}) : (publicPaymentInfoCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание новой ценовой схемы
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {PricingSchemeInfoCreate} pricingSchemeInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPricingScheme(businessAccountId: number, pricingSchemeInfoCreate: PricingSchemeInfoCreate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling createPricingScheme.');
            }
            // verify required parameter 'pricingSchemeInfoCreate' is not null or undefined
            if (pricingSchemeInfoCreate === null || pricingSchemeInfoCreate === undefined) {
                throw new RequiredError('pricingSchemeInfoCreate','Required parameter pricingSchemeInfoCreate was null or undefined when calling createPricingScheme.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/pricingSchemes`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof pricingSchemeInfoCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(pricingSchemeInfoCreate !== undefined ? pricingSchemeInfoCreate : {}) : (pricingSchemeInfoCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание нового продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {ProductInfoCreate} productInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProduct(businessAccountId: number, productInfoCreate: ProductInfoCreate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling createProduct.');
            }
            // verify required parameter 'productInfoCreate' is not null or undefined
            if (productInfoCreate === null || productInfoCreate === undefined) {
                throw new RequiredError('productInfoCreate','Required parameter productInfoCreate was null or undefined when calling createProduct.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/products`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof productInfoCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(productInfoCreate !== undefined ? productInfoCreate : {}) : (productInfoCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание нового экземпляра продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {InstanceInfoCreate} instanceInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProductInstance(businessAccountId: number, id: number, instanceInfoCreate: InstanceInfoCreate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling createProductInstance.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling createProductInstance.');
            }
            // verify required parameter 'instanceInfoCreate' is not null or undefined
            if (instanceInfoCreate === null || instanceInfoCreate === undefined) {
                throw new RequiredError('instanceInfoCreate','Required parameter instanceInfoCreate was null or undefined when calling createProductInstance.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/products/{id}/instances`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof instanceInfoCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(instanceInfoCreate !== undefined ? instanceInfoCreate : {}) : (instanceInfoCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание нового варианта продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {VariantInfoCreate} variantInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProductVariant(businessAccountId: number, id: number, variantInfoCreate: VariantInfoCreate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling createProductVariant.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling createProductVariant.');
            }
            // verify required parameter 'variantInfoCreate' is not null or undefined
            if (variantInfoCreate === null || variantInfoCreate === undefined) {
                throw new RequiredError('variantInfoCreate','Required parameter variantInfoCreate was null or undefined when calling createProductVariant.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/products/{id}/variants`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof variantInfoCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(variantInfoCreate !== undefined ? variantInfoCreate : {}) : (variantInfoCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание нового экземпляра варианта продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} productId Идентификатор продукта
         * @param {number} id Идентификатор сущности
         * @param {InstanceInfoCreate} instanceInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProductVariantInstance(businessAccountId: number, productId: number, id: number, instanceInfoCreate: InstanceInfoCreate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling createProductVariantInstance.');
            }
            // verify required parameter 'productId' is not null or undefined
            if (productId === null || productId === undefined) {
                throw new RequiredError('productId','Required parameter productId was null or undefined when calling createProductVariantInstance.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling createProductVariantInstance.');
            }
            // verify required parameter 'instanceInfoCreate' is not null or undefined
            if (instanceInfoCreate === null || instanceInfoCreate === undefined) {
                throw new RequiredError('instanceInfoCreate','Required parameter instanceInfoCreate was null or undefined when calling createProductVariantInstance.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/products/{productId}/variants/{id}/instances`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof instanceInfoCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(instanceInfoCreate !== undefined ? instanceInfoCreate : {}) : (instanceInfoCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание профессии
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {ProfessionInfoCreate} professionInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProfession(businessAccountId: number, professionInfoCreate: ProfessionInfoCreate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling createProfession.');
            }
            // verify required parameter 'professionInfoCreate' is not null or undefined
            if (professionInfoCreate === null || professionInfoCreate === undefined) {
                throw new RequiredError('professionInfoCreate','Required parameter professionInfoCreate was null or undefined when calling createProfession.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/professions`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof professionInfoCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(professionInfoCreate !== undefined ? professionInfoCreate : {}) : (professionInfoCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание нового проекта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {ProjectInfoCreate} projectInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject(businessAccountId: number, projectInfoCreate: ProjectInfoCreate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling createProject.');
            }
            // verify required parameter 'projectInfoCreate' is not null or undefined
            if (projectInfoCreate === null || projectInfoCreate === undefined) {
                throw new RequiredError('projectInfoCreate','Required parameter projectInfoCreate was null or undefined when calling createProject.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/projects`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof projectInfoCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(projectInfoCreate !== undefined ? projectInfoCreate : {}) : (projectInfoCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание нового арендатора
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {RenterInfoCreate} renterInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRenter(businessAccountId: number, renterInfoCreate: RenterInfoCreate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling createRenter.');
            }
            // verify required parameter 'renterInfoCreate' is not null or undefined
            if (renterInfoCreate === null || renterInfoCreate === undefined) {
                throw new RequiredError('renterInfoCreate','Required parameter renterInfoCreate was null or undefined when calling createRenter.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/renters`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof renterInfoCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(renterInfoCreate !== undefined ? renterInfoCreate : {}) : (renterInfoCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание графической визуализации отчета
         * @param {number} reportId 
         * @param {ReportChartVisualizationInfoCreate} [reportChartVisualizationInfoCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReportChartVisualization(reportId: number, reportChartVisualizationInfoCreate?: ReportChartVisualizationInfoCreate, options: any = {}): RequestArgs {
            // verify required parameter 'reportId' is not null or undefined
            if (reportId === null || reportId === undefined) {
                throw new RequiredError('reportId','Required parameter reportId was null or undefined when calling createReportChartVisualization.');
            }
            const localVarPath = `/global/analytics/reportConfigurations/{reportId}/reportChartVisualization`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof reportChartVisualizationInfoCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(reportChartVisualizationInfoCreate !== undefined ? reportChartVisualizationInfoCreate : {}) : (reportChartVisualizationInfoCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание новой конфигурации отчёта
         * @param {ReportConfigurationInfoCreate} model 
         * @param {any} content 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReportConfiguration(model: ReportConfigurationInfoCreate, content: any, options: any = {}): RequestArgs {
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling createReportConfiguration.');
            }
            // verify required parameter 'content' is not null or undefined
            if (content === null || content === undefined) {
                throw new RequiredError('content','Required parameter content was null or undefined when calling createReportConfiguration.');
            }
            const localVarPath = `/global/analytics/reportConfigurations`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


            if (model !== undefined) { 
                localVarFormParams.append('model', model as any);
            }
    
            if (content !== undefined) { 
                localVarFormParams.append('content', content as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание новой Эксель визуализации отчета
         * @param {number} reportId 
         * @param {ReportExcelVisualizationInfoCreate} model 
         * @param {any} content 
         * @param {any} jasper 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReportExcelVisualization(reportId: number, model: ReportExcelVisualizationInfoCreate, content: any, jasper: any, options: any = {}): RequestArgs {
            // verify required parameter 'reportId' is not null or undefined
            if (reportId === null || reportId === undefined) {
                throw new RequiredError('reportId','Required parameter reportId was null or undefined when calling createReportExcelVisualization.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling createReportExcelVisualization.');
            }
            // verify required parameter 'content' is not null or undefined
            if (content === null || content === undefined) {
                throw new RequiredError('content','Required parameter content was null or undefined when calling createReportExcelVisualization.');
            }
            // verify required parameter 'jasper' is not null or undefined
            if (jasper === null || jasper === undefined) {
                throw new RequiredError('jasper','Required parameter jasper was null or undefined when calling createReportExcelVisualization.');
            }
            const localVarPath = `/global/analytics/reportConfigurations/{reportId}/reportExcelVisualizations`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


            if (model !== undefined) { 
                localVarFormParams.append('model', model as any);
            }
    
            if (content !== undefined) { 
                localVarFormParams.append('content', content as any);
            }
    
            if (jasper !== undefined) { 
                localVarFormParams.append('jasper', jasper as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание новой субаренды
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {SubrentInfoCreate} subrentInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubrent(businessAccountId: number, subrentInfoCreate: SubrentInfoCreate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling createSubrent.');
            }
            // verify required parameter 'subrentInfoCreate' is not null or undefined
            if (subrentInfoCreate === null || subrentInfoCreate === undefined) {
                throw new RequiredError('subrentInfoCreate','Required parameter subrentInfoCreate was null or undefined when calling createSubrent.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/subrents`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof subrentInfoCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(subrentInfoCreate !== undefined ? subrentInfoCreate : {}) : (subrentInfoCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание нового шаблона
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {TemplateInfoCreate} templateInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTemplate(businessAccountId: number, templateInfoCreate: TemplateInfoCreate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling createTemplate.');
            }
            // verify required parameter 'templateInfoCreate' is not null or undefined
            if (templateInfoCreate === null || templateInfoCreate === undefined) {
                throw new RequiredError('templateInfoCreate','Required parameter templateInfoCreate was null or undefined when calling createTemplate.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/templates`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof templateInfoCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(templateInfoCreate !== undefined ? templateInfoCreate : {}) : (templateInfoCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание новой перевозки
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {TransportationInfoCreate} transportationInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTransportation(businessAccountId: number, transportationInfoCreate: TransportationInfoCreate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling createTransportation.');
            }
            // verify required parameter 'transportationInfoCreate' is not null or undefined
            if (transportationInfoCreate === null || transportationInfoCreate === undefined) {
                throw new RequiredError('transportationInfoCreate','Required parameter transportationInfoCreate was null or undefined when calling createTransportation.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/transportations`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof transportationInfoCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(transportationInfoCreate !== undefined ? transportationInfoCreate : {}) : (transportationInfoCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {TransportationInfoCreate} [contactInfoCreate] 
         * @param {Array<any>} [attachmentInfoCreates] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTransportationWithAttachments(businessAccountId: number, contactInfoCreate?: TransportationInfoCreate, attachmentInfoCreates?: Array<any>, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling createTransportationWithAttachments.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/transportations/with-attachments`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


            if (contactInfoCreate !== undefined) { 
                localVarFormParams.append('contactInfoCreate', contactInfoCreate as any);
            }
                if (attachmentInfoCreates) {
            
                localVarFormParams.append('attachmentInfoCreates', attachmentInfoCreates.join(COLLECTION_FORMATS.csv));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание новой транспортного средства
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {VehicleInfoCreate} vehicleInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVehicle(businessAccountId: number, vehicleInfoCreate: VehicleInfoCreate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling createVehicle.');
            }
            // verify required parameter 'vehicleInfoCreate' is not null or undefined
            if (vehicleInfoCreate === null || vehicleInfoCreate === undefined) {
                throw new RequiredError('vehicleInfoCreate','Required parameter vehicleInfoCreate was null or undefined when calling createVehicle.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/vehicles`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof vehicleInfoCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(vehicleInfoCreate !== undefined ? vehicleInfoCreate : {}) : (vehicleInfoCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {VehicleInfoCreate} [contactInfoCreate] 
         * @param {Array<any>} [attachmentInfoCreates] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVehicleWithAttachments(businessAccountId: number, contactInfoCreate?: VehicleInfoCreate, attachmentInfoCreates?: Array<any>, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling createVehicleWithAttachments.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/vehicles/with-attachments`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


            if (contactInfoCreate !== undefined) { 
                localVarFormParams.append('contactInfoCreate', contactInfoCreate as any);
            }
                if (attachmentInfoCreates) {
            
                localVarFormParams.append('attachmentInfoCreates', attachmentInfoCreates.join(COLLECTION_FORMATS.csv));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Запланировать/Создать описание работы
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkPlanningsInfoCreate} workPlanningsInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkPlanning(businessAccountId: number, workPlanningsInfoCreate: WorkPlanningsInfoCreate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling createWorkPlanning.');
            }
            // verify required parameter 'workPlanningsInfoCreate' is not null or undefined
            if (workPlanningsInfoCreate === null || workPlanningsInfoCreate === undefined) {
                throw new RequiredError('workPlanningsInfoCreate','Required parameter workPlanningsInfoCreate was null or undefined when calling createWorkPlanning.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/work-plannings`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof workPlanningsInfoCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(workPlanningsInfoCreate !== undefined ? workPlanningsInfoCreate : {}) : (workPlanningsInfoCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление вложения
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {BusinessVersionObj} businessVersionObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAttachmentById(businessAccountId: number, id: number, businessVersionObj: BusinessVersionObj, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling deleteAttachmentById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteAttachmentById.');
            }
            // verify required parameter 'businessVersionObj' is not null or undefined
            if (businessVersionObj === null || businessVersionObj === undefined) {
                throw new RequiredError('businessVersionObj','Required parameter businessVersionObj was null or undefined when calling deleteAttachmentById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/attachments/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof businessVersionObj !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(businessVersionObj !== undefined ? businessVersionObj : {}) : (businessVersionObj || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление бизнес аккаунта (только для тех, которые на триальном тарифе)
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} checkString Проверочная строка, краткое наименование бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBusinessAccountById(businessAccountId: number, checkString: string, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling deleteBusinessAccountById.');
            }
            // verify required parameter 'checkString' is not null or undefined
            if (checkString === null || checkString === undefined) {
                throw new RequiredError('checkString','Required parameter checkString was null or undefined when calling deleteBusinessAccountById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (checkString !== undefined) {
                localVarQueryParameter['checkString'] = checkString;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление категории
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {boolean} deleteSubcategories Удалить подкатегории
         * @param {boolean} deleteEntityLinks Удалить связи с продуктами (если false - перенести связи на родительскую категорию, связи подкатегорий c продуктами обрабатываются аналогично связям текущей)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCategoryById(businessAccountId: number, id: number, deleteSubcategories: boolean, deleteEntityLinks: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling deleteCategoryById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteCategoryById.');
            }
            // verify required parameter 'deleteSubcategories' is not null or undefined
            if (deleteSubcategories === null || deleteSubcategories === undefined) {
                throw new RequiredError('deleteSubcategories','Required parameter deleteSubcategories was null or undefined when calling deleteCategoryById.');
            }
            // verify required parameter 'deleteEntityLinks' is not null or undefined
            if (deleteEntityLinks === null || deleteEntityLinks === undefined) {
                throw new RequiredError('deleteEntityLinks','Required parameter deleteEntityLinks was null or undefined when calling deleteCategoryById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/categories/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (deleteSubcategories !== undefined) {
                localVarQueryParameter['deleteSubcategories'] = deleteSubcategories;
            }

            if (deleteEntityLinks !== undefined) {
                localVarQueryParameter['deleteEntityLinks'] = deleteEntityLinks;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление стандартного шаблона документа по id
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCommonDocumentTemplate(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteCommonDocumentTemplate.');
            }
            const localVarPath = `/global/documentTemplates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление контакта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {BusinessVersionObj} businessVersionObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContactById(businessAccountId: number, id: number, businessVersionObj: BusinessVersionObj, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling deleteContactById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteContactById.');
            }
            // verify required parameter 'businessVersionObj' is not null or undefined
            if (businessVersionObj === null || businessVersionObj === undefined) {
                throw new RequiredError('businessVersionObj','Required parameter businessVersionObj was null or undefined when calling deleteContactById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/contacts/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof businessVersionObj !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(businessVersionObj !== undefined ? businessVersionObj : {}) : (businessVersionObj || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление контактов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContacts(businessAccountId: number, versionedEntityObjList: VersionedEntityObjList, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling deleteContacts.');
            }
            // verify required parameter 'versionedEntityObjList' is not null or undefined
            if (versionedEntityObjList === null || versionedEntityObjList === undefined) {
                throw new RequiredError('versionedEntityObjList','Required parameter versionedEntityObjList was null or undefined when calling deleteContacts.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/contacts`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof versionedEntityObjList !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(versionedEntityObjList !== undefined ? versionedEntityObjList : {}) : (versionedEntityObjList || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление кадра
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {BusinessVersionObj} businessVersionObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCrewMember(businessAccountId: number, id: number, businessVersionObj: BusinessVersionObj, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling deleteCrewMember.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteCrewMember.');
            }
            // verify required parameter 'businessVersionObj' is not null or undefined
            if (businessVersionObj === null || businessVersionObj === undefined) {
                throw new RequiredError('businessVersionObj','Required parameter businessVersionObj was null or undefined when calling deleteCrewMember.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/crew-members/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof businessVersionObj !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(businessVersionObj !== undefined ? businessVersionObj : {}) : (businessVersionObj || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление ставок кадра
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCrewMemberRates(businessAccountId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling deleteCrewMemberRates.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteCrewMemberRates.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/crew-members/{id}/rates`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Массовое удаление сотрудников
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCrewMembersBulk(businessAccountId: number, versionedEntityObjList: VersionedEntityObjList, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling deleteCrewMembersBulk.');
            }
            // verify required parameter 'versionedEntityObjList' is not null or undefined
            if (versionedEntityObjList === null || versionedEntityObjList === undefined) {
                throw new RequiredError('versionedEntityObjList','Required parameter versionedEntityObjList was null or undefined when calling deleteCrewMembersBulk.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/crew-members`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof versionedEntityObjList !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(versionedEntityObjList !== undefined ? versionedEntityObjList : {}) : (versionedEntityObjList || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление пользовательского поля
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomFieldById(businessAccountId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling deleteCustomFieldById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteCustomFieldById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/customFields/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Отвязка настраиваемого поля от вариантов продукта (удаление связей)
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} productId Идентификатор продукта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomFieldLinksToProductVariants(businessAccountId: number, productId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling deleteCustomFieldLinksToProductVariants.');
            }
            // verify required parameter 'productId' is not null or undefined
            if (productId === null || productId === undefined) {
                throw new RequiredError('productId','Required parameter productId was null or undefined when calling deleteCustomFieldLinksToProductVariants.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteCustomFieldLinksToProductVariants.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/products/{productId}/variants/customFields/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление шаблона документа по id
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocumentTemplate(businessAccountId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling deleteDocumentTemplate.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteDocumentTemplate.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/documentTemplates/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Удаление сущности затраты по проекту
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExpense(businessAccountId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling deleteExpense.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteExpense.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/expense/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление шаблона для экспорта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExportTemplate(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteExportTemplate.');
            }
            const localVarPath = `/global/analytics/exportTemplate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление изображения
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImageById(businessAccountId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling deleteImageById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteImageById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/images/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление набора
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {BusinessVersionObj} businessVersionObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteKitById(businessAccountId: number, id: number, businessVersionObj: BusinessVersionObj, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling deleteKitById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteKitById.');
            }
            // verify required parameter 'businessVersionObj' is not null or undefined
            if (businessVersionObj === null || businessVersionObj === undefined) {
                throw new RequiredError('businessVersionObj','Required parameter businessVersionObj was null or undefined when calling deleteKitById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/kits/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof businessVersionObj !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(businessVersionObj !== undefined ? businessVersionObj : {}) : (businessVersionObj || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Массовое удаление наборов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteKitsBulk(businessAccountId: number, versionedEntityObjList: VersionedEntityObjList, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling deleteKitsBulk.');
            }
            // verify required parameter 'versionedEntityObjList' is not null or undefined
            if (versionedEntityObjList === null || versionedEntityObjList === undefined) {
                throw new RequiredError('versionedEntityObjList','Required parameter versionedEntityObjList was null or undefined when calling deleteKitsBulk.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/kits`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof versionedEntityObjList !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(versionedEntityObjList !== undefined ? versionedEntityObjList : {}) : (versionedEntityObjList || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление объекта субъектов и реквизитов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {BusinessVersionObj} businessVersionObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLegalDetailById(businessAccountId: number, id: number, businessVersionObj: BusinessVersionObj, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling deleteLegalDetailById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteLegalDetailById.');
            }
            // verify required parameter 'businessVersionObj' is not null or undefined
            if (businessVersionObj === null || businessVersionObj === undefined) {
                throw new RequiredError('businessVersionObj','Required parameter businessVersionObj was null or undefined when calling deleteLegalDetailById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/legal-details/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof businessVersionObj !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(businessVersionObj !== undefined ? businessVersionObj : {}) : (businessVersionObj || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление локации
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {BusinessVersionObj} businessVersionObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLocationById(businessAccountId: number, id: number, businessVersionObj: BusinessVersionObj, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling deleteLocationById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteLocationById.');
            }
            // verify required parameter 'businessVersionObj' is not null or undefined
            if (businessVersionObj === null || businessVersionObj === undefined) {
                throw new RequiredError('businessVersionObj','Required parameter businessVersionObj was null or undefined when calling deleteLocationById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/locations/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof businessVersionObj !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(businessVersionObj !== undefined ? businessVersionObj : {}) : (businessVersionObj || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление локаций
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLocations(businessAccountId: number, versionedEntityObjList: VersionedEntityObjList, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling deleteLocations.');
            }
            // verify required parameter 'versionedEntityObjList' is not null or undefined
            if (versionedEntityObjList === null || versionedEntityObjList === undefined) {
                throw new RequiredError('versionedEntityObjList','Required parameter versionedEntityObjList was null or undefined when calling deleteLocations.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/locations`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof versionedEntityObjList !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(versionedEntityObjList !== undefined ? versionedEntityObjList : {}) : (versionedEntityObjList || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Массовое удаление номенклатуры
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {NomenclatureEntityList} nomenclatureEntityList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNomenclatureBulk(businessAccountId: number, nomenclatureEntityList: NomenclatureEntityList, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling deleteNomenclatureBulk.');
            }
            // verify required parameter 'nomenclatureEntityList' is not null or undefined
            if (nomenclatureEntityList === null || nomenclatureEntityList === undefined) {
                throw new RequiredError('nomenclatureEntityList','Required parameter nomenclatureEntityList was null or undefined when calling deleteNomenclatureBulk.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/nomenclature`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof nomenclatureEntityList !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(nomenclatureEntityList !== undefined ? nomenclatureEntityList : {}) : (nomenclatureEntityList || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление способа оплаты
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {BusinessVersionObj} businessVersionObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePaymentMethodById(businessAccountId: number, id: number, businessVersionObj: BusinessVersionObj, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling deletePaymentMethodById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deletePaymentMethodById.');
            }
            // verify required parameter 'businessVersionObj' is not null or undefined
            if (businessVersionObj === null || businessVersionObj === undefined) {
                throw new RequiredError('businessVersionObj','Required parameter businessVersionObj was null or undefined when calling deletePaymentMethodById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/payment-methods/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof businessVersionObj !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(businessVersionObj !== undefined ? businessVersionObj : {}) : (businessVersionObj || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Массовое удаление способов оплаты
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePaymentMethodsBulk(businessAccountId: number, versionedEntityObjList: VersionedEntityObjList, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling deletePaymentMethodsBulk.');
            }
            // verify required parameter 'versionedEntityObjList' is not null or undefined
            if (versionedEntityObjList === null || versionedEntityObjList === undefined) {
                throw new RequiredError('versionedEntityObjList','Required parameter versionedEntityObjList was null or undefined when calling deletePaymentMethodsBulk.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/payment-methods`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof versionedEntityObjList !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(versionedEntityObjList !== undefined ? versionedEntityObjList : {}) : (versionedEntityObjList || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление ценовой схемы
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {BusinessVersionObj} businessVersionObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePricingSchemeById(businessAccountId: number, id: number, businessVersionObj: BusinessVersionObj, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling deletePricingSchemeById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deletePricingSchemeById.');
            }
            // verify required parameter 'businessVersionObj' is not null or undefined
            if (businessVersionObj === null || businessVersionObj === undefined) {
                throw new RequiredError('businessVersionObj','Required parameter businessVersionObj was null or undefined when calling deletePricingSchemeById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/pricingSchemes/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof businessVersionObj !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(businessVersionObj !== undefined ? businessVersionObj : {}) : (businessVersionObj || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {BusinessVersionObj} businessVersionObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductById(businessAccountId: number, id: number, businessVersionObj: BusinessVersionObj, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling deleteProductById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteProductById.');
            }
            // verify required parameter 'businessVersionObj' is not null or undefined
            if (businessVersionObj === null || businessVersionObj === undefined) {
                throw new RequiredError('businessVersionObj','Required parameter businessVersionObj was null or undefined when calling deleteProductById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/products/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof businessVersionObj !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(businessVersionObj !== undefined ? businessVersionObj : {}) : (businessVersionObj || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление экземпляра
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} productId Идентификатор продукта
         * @param {number} id Идентификатор сущности
         * @param {BusinessVersionObj} businessVersionObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductInstanceById(businessAccountId: number, productId: number, id: number, businessVersionObj: BusinessVersionObj, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling deleteProductInstanceById.');
            }
            // verify required parameter 'productId' is not null or undefined
            if (productId === null || productId === undefined) {
                throw new RequiredError('productId','Required parameter productId was null or undefined when calling deleteProductInstanceById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteProductInstanceById.');
            }
            // verify required parameter 'businessVersionObj' is not null or undefined
            if (businessVersionObj === null || businessVersionObj === undefined) {
                throw new RequiredError('businessVersionObj','Required parameter businessVersionObj was null or undefined when calling deleteProductInstanceById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/products/{productId}/instances/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof businessVersionObj !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(businessVersionObj !== undefined ? businessVersionObj : {}) : (businessVersionObj || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Массовое удаление экземпляров продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductInstancesBulk(businessAccountId: number, id: number, versionedEntityObjList: VersionedEntityObjList, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling deleteProductInstancesBulk.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteProductInstancesBulk.');
            }
            // verify required parameter 'versionedEntityObjList' is not null or undefined
            if (versionedEntityObjList === null || versionedEntityObjList === undefined) {
                throw new RequiredError('versionedEntityObjList','Required parameter versionedEntityObjList was null or undefined when calling deleteProductInstancesBulk.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/products/{id}/instances`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof versionedEntityObjList !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(versionedEntityObjList !== undefined ? versionedEntityObjList : {}) : (versionedEntityObjList || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление варианта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} productId Идентификатор продукта
         * @param {number} id Идентификатор сущности
         * @param {BusinessVersionObj} businessVersionObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductVariantById(businessAccountId: number, productId: number, id: number, businessVersionObj: BusinessVersionObj, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling deleteProductVariantById.');
            }
            // verify required parameter 'productId' is not null or undefined
            if (productId === null || productId === undefined) {
                throw new RequiredError('productId','Required parameter productId was null or undefined when calling deleteProductVariantById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteProductVariantById.');
            }
            // verify required parameter 'businessVersionObj' is not null or undefined
            if (businessVersionObj === null || businessVersionObj === undefined) {
                throw new RequiredError('businessVersionObj','Required parameter businessVersionObj was null or undefined when calling deleteProductVariantById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/products/{productId}/variants/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof businessVersionObj !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(businessVersionObj !== undefined ? businessVersionObj : {}) : (businessVersionObj || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Массовое удаление вариантов продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductVariantsBulk(businessAccountId: number, id: number, versionedEntityObjList: VersionedEntityObjList, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling deleteProductVariantsBulk.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteProductVariantsBulk.');
            }
            // verify required parameter 'versionedEntityObjList' is not null or undefined
            if (versionedEntityObjList === null || versionedEntityObjList === undefined) {
                throw new RequiredError('versionedEntityObjList','Required parameter versionedEntityObjList was null or undefined when calling deleteProductVariantsBulk.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/products/{id}/variants`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof versionedEntityObjList !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(versionedEntityObjList !== undefined ? versionedEntityObjList : {}) : (versionedEntityObjList || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Массовое удаление продуктов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductsBulk(businessAccountId: number, versionedEntityObjList: VersionedEntityObjList, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling deleteProductsBulk.');
            }
            // verify required parameter 'versionedEntityObjList' is not null or undefined
            if (versionedEntityObjList === null || versionedEntityObjList === undefined) {
                throw new RequiredError('versionedEntityObjList','Required parameter versionedEntityObjList was null or undefined when calling deleteProductsBulk.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/products`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof versionedEntityObjList !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(versionedEntityObjList !== undefined ? versionedEntityObjList : {}) : (versionedEntityObjList || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление профессии
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {BusinessVersionObj} businessVersionObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProfessionById(businessAccountId: number, id: number, businessVersionObj: BusinessVersionObj, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling deleteProfessionById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteProfessionById.');
            }
            // verify required parameter 'businessVersionObj' is not null or undefined
            if (businessVersionObj === null || businessVersionObj === undefined) {
                throw new RequiredError('businessVersionObj','Required parameter businessVersionObj was null or undefined when calling deleteProfessionById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/professions/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof businessVersionObj !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(businessVersionObj !== undefined ? businessVersionObj : {}) : (businessVersionObj || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Массовое удаление профессий
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProfessionsBulk(businessAccountId: number, versionedEntityObjList: VersionedEntityObjList, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling deleteProfessionsBulk.');
            }
            // verify required parameter 'versionedEntityObjList' is not null or undefined
            if (versionedEntityObjList === null || versionedEntityObjList === undefined) {
                throw new RequiredError('versionedEntityObjList','Required parameter versionedEntityObjList was null or undefined when calling deleteProfessionsBulk.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/professions`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof versionedEntityObjList !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(versionedEntityObjList !== undefined ? versionedEntityObjList : {}) : (versionedEntityObjList || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление графической визуализации отчета
         * @param {number} reportId 
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReportChartVisualization(reportId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'reportId' is not null or undefined
            if (reportId === null || reportId === undefined) {
                throw new RequiredError('reportId','Required parameter reportId was null or undefined when calling deleteReportChartVisualization.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteReportChartVisualization.');
            }
            const localVarPath = `/global/analytics/reportConfigurations/{reportId}/reportChartVisualization/{id}`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление конфигурации отчёта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReportConfiguration(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteReportConfiguration.');
            }
            const localVarPath = `/global/analytics/reportConfigurations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление Эксель визуализации отчета
         * @param {number} reportId 
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReportExcelVisualization(reportId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'reportId' is not null or undefined
            if (reportId === null || reportId === undefined) {
                throw new RequiredError('reportId','Required parameter reportId was null or undefined when calling deleteReportExcelVisualization.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteReportExcelVisualization.');
            }
            const localVarPath = `/global/analytics/reportConfigurations/{reportId}/reportExcelVisualizations/{id}`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление организации в системе SSO авторизации и отвязка от бизнес аккаунта
         * @param {string} accessToken Обязательный токен доступа в формате UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSsoClientOrganization(accessToken: string, options: any = {}): RequestArgs {
            // verify required parameter 'accessToken' is not null or undefined
            if (accessToken === null || accessToken === undefined) {
                throw new RequiredError('accessToken','Required parameter accessToken was null or undefined when calling deleteSsoClientOrganization.');
            }
            const localVarPath = `/public/v1/server/ssoClientOrganizations`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (accessToken !== undefined) {
                localVarQueryParameter['accessToken'] = accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление перевозки
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {BusinessVersionObj} businessVersionObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTransportationById(businessAccountId: number, id: number, businessVersionObj: BusinessVersionObj, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling deleteTransportationById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteTransportationById.');
            }
            // verify required parameter 'businessVersionObj' is not null or undefined
            if (businessVersionObj === null || businessVersionObj === undefined) {
                throw new RequiredError('businessVersionObj','Required parameter businessVersionObj was null or undefined when calling deleteTransportationById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/transportations/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof businessVersionObj !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(businessVersionObj !== undefined ? businessVersionObj : {}) : (businessVersionObj || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление перевозок
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTransportations(businessAccountId: number, versionedEntityObjList: VersionedEntityObjList, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling deleteTransportations.');
            }
            // verify required parameter 'versionedEntityObjList' is not null or undefined
            if (versionedEntityObjList === null || versionedEntityObjList === undefined) {
                throw new RequiredError('versionedEntityObjList','Required parameter versionedEntityObjList was null or undefined when calling deleteTransportations.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/transportations`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof versionedEntityObjList !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(versionedEntityObjList !== undefined ? versionedEntityObjList : {}) : (versionedEntityObjList || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление транспортного средства
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {BusinessVersionObj} businessVersionObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVehicleById(businessAccountId: number, id: number, businessVersionObj: BusinessVersionObj, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling deleteVehicleById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteVehicleById.');
            }
            // verify required parameter 'businessVersionObj' is not null or undefined
            if (businessVersionObj === null || businessVersionObj === undefined) {
                throw new RequiredError('businessVersionObj','Required parameter businessVersionObj was null or undefined when calling deleteVehicleById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/vehicles/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof businessVersionObj !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(businessVersionObj !== undefined ? businessVersionObj : {}) : (businessVersionObj || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление локаций
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVehicles(businessAccountId: number, versionedEntityObjList: VersionedEntityObjList, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling deleteVehicles.');
            }
            // verify required parameter 'versionedEntityObjList' is not null or undefined
            if (versionedEntityObjList === null || versionedEntityObjList === undefined) {
                throw new RequiredError('versionedEntityObjList','Required parameter versionedEntityObjList was null or undefined when calling deleteVehicles.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/vehicles`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof versionedEntityObjList !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(versionedEntityObjList !== undefined ? versionedEntityObjList : {}) : (versionedEntityObjList || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Копирование бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateBusinessAccountById(businessAccountId: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling duplicateBusinessAccountById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/duplicate`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Запрос на подключение или отключение модуля в бизнес аккаунте
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {BusinessAccountModuleEnableOrDisableObj} businessAccountModuleEnableOrDisableObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableOrDisableBusinessAccountModule(businessAccountId: number, businessAccountModuleEnableOrDisableObj: BusinessAccountModuleEnableOrDisableObj, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling enableOrDisableBusinessAccountModule.');
            }
            // verify required parameter 'businessAccountModuleEnableOrDisableObj' is not null or undefined
            if (businessAccountModuleEnableOrDisableObj === null || businessAccountModuleEnableOrDisableObj === undefined) {
                throw new RequiredError('businessAccountModuleEnableOrDisableObj','Required parameter businessAccountModuleEnableOrDisableObj was null or undefined when calling enableOrDisableBusinessAccountModule.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/preferences/modules`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof businessAccountModuleEnableOrDisableObj !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(businessAccountModuleEnableOrDisableObj !== undefined ? businessAccountModuleEnableOrDisableObj : {}) : (businessAccountModuleEnableOrDisableObj || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Запрос на подключение или отключение модуля в бизнес аккаунте
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {BusinessAccountModuleEnableOrDisableObjList} businessAccountModuleEnableOrDisableObjList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableOrDisableBusinessAccountModuleBulk(businessAccountId: number, businessAccountModuleEnableOrDisableObjList: BusinessAccountModuleEnableOrDisableObjList, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling enableOrDisableBusinessAccountModuleBulk.');
            }
            // verify required parameter 'businessAccountModuleEnableOrDisableObjList' is not null or undefined
            if (businessAccountModuleEnableOrDisableObjList === null || businessAccountModuleEnableOrDisableObjList === undefined) {
                throw new RequiredError('businessAccountModuleEnableOrDisableObjList','Required parameter businessAccountModuleEnableOrDisableObjList was null or undefined when calling enableOrDisableBusinessAccountModuleBulk.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/preferences/modules/bulk`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof businessAccountModuleEnableOrDisableObjList !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(businessAccountModuleEnableOrDisableObjList !== undefined ? businessAccountModuleEnableOrDisableObjList : {}) : (businessAccountModuleEnableOrDisableObjList || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Экспорт списка обязательств бизнес аккаунта. При limit = null ограничение до 1000
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {Array<string>} columns Колонки учавстующие в экспорте. Передавать [\&#39;ALL\&#39;] чтобы выгрузить все доступные
         * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {Date} [specialFromStartDateIncludingDelays] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportBusinessAccountElementsList(businessAccountId: number, columns: Array<string>, expandKits: boolean, timezoneOffsetUTC: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, specialFromStartDateIncludingDelays?: Date, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling exportBusinessAccountElementsList.');
            }
            // verify required parameter 'columns' is not null or undefined
            if (columns === null || columns === undefined) {
                throw new RequiredError('columns','Required parameter columns was null or undefined when calling exportBusinessAccountElementsList.');
            }
            // verify required parameter 'expandKits' is not null or undefined
            if (expandKits === null || expandKits === undefined) {
                throw new RequiredError('expandKits','Required parameter expandKits was null or undefined when calling exportBusinessAccountElementsList.');
            }
            // verify required parameter 'timezoneOffsetUTC' is not null or undefined
            if (timezoneOffsetUTC === null || timezoneOffsetUTC === undefined) {
                throw new RequiredError('timezoneOffsetUTC','Required parameter timezoneOffsetUTC was null or undefined when calling exportBusinessAccountElementsList.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/elements/export`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (columns) {
                localVarQueryParameter['columns'] = columns;
            }

            if (expandKits !== undefined) {
                localVarQueryParameter['expandKits'] = expandKits;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (timezoneOffsetUTC !== undefined) {
                localVarQueryParameter['timezoneOffsetUTC'] = timezoneOffsetUTC;
            }

            if (specialFromStartDateIncludingDelays !== undefined) {
                localVarQueryParameter['specialFromStartDateIncludingDelays'] = (specialFromStartDateIncludingDelays as any instanceof Date) ?
                    (specialFromStartDateIncludingDelays as any).toISOString() :
                    specialFromStartDateIncludingDelays;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение календаря событий бизнес аккаунта
         * @param {string} accessToken Access token. UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportCalendar(accessToken: string, options: any = {}): RequestArgs {
            // verify required parameter 'accessToken' is not null or undefined
            if (accessToken === null || accessToken === undefined) {
                throw new RequiredError('accessToken','Required parameter accessToken was null or undefined when calling exportCalendar.');
            }
            const localVarPath = `/calendar/{accessToken}`
                .replace(`{${"accessToken"}}`, encodeURIComponent(String(accessToken)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Экспорт списка обязательств операции. При limit = null ограничение до 1000
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {Array<string>} columns Колонки учавстующие в экспорте. Передавать [\&#39;ALL\&#39;] чтобы выгрузить все доступные
         * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportOperationElementsList(businessAccountId: number, id: number, columns: Array<string>, expandKits: boolean, timezoneOffsetUTC: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling exportOperationElementsList.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling exportOperationElementsList.');
            }
            // verify required parameter 'columns' is not null or undefined
            if (columns === null || columns === undefined) {
                throw new RequiredError('columns','Required parameter columns was null or undefined when calling exportOperationElementsList.');
            }
            // verify required parameter 'expandKits' is not null or undefined
            if (expandKits === null || expandKits === undefined) {
                throw new RequiredError('expandKits','Required parameter expandKits was null or undefined when calling exportOperationElementsList.');
            }
            // verify required parameter 'timezoneOffsetUTC' is not null or undefined
            if (timezoneOffsetUTC === null || timezoneOffsetUTC === undefined) {
                throw new RequiredError('timezoneOffsetUTC','Required parameter timezoneOffsetUTC was null or undefined when calling exportOperationElementsList.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/operations/{id}/elements/export`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (columns) {
                localVarQueryParameter['columns'] = columns;
            }

            if (expandKits !== undefined) {
                localVarQueryParameter['expandKits'] = expandKits;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (timezoneOffsetUTC !== undefined) {
                localVarQueryParameter['timezoneOffsetUTC'] = timezoneOffsetUTC;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Экспорт списка обязательств проекта. При limit = null ограничение до 1000
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} projectId Идентификатор проекта
         * @param {Array<string>} columns Колонки учавстующие в экспорте. Передавать [\&#39;ALL\&#39;] чтобы выгрузить все доступные
         * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportProjectElementsList(businessAccountId: number, projectId: number, columns: Array<string>, expandKits: boolean, timezoneOffsetUTC: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling exportProjectElementsList.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling exportProjectElementsList.');
            }
            // verify required parameter 'columns' is not null or undefined
            if (columns === null || columns === undefined) {
                throw new RequiredError('columns','Required parameter columns was null or undefined when calling exportProjectElementsList.');
            }
            // verify required parameter 'expandKits' is not null or undefined
            if (expandKits === null || expandKits === undefined) {
                throw new RequiredError('expandKits','Required parameter expandKits was null or undefined when calling exportProjectElementsList.');
            }
            // verify required parameter 'timezoneOffsetUTC' is not null or undefined
            if (timezoneOffsetUTC === null || timezoneOffsetUTC === undefined) {
                throw new RequiredError('timezoneOffsetUTC','Required parameter timezoneOffsetUTC was null or undefined when calling exportProjectElementsList.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/projects/{projectId}/elements/export`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (columns) {
                localVarQueryParameter['columns'] = columns;
            }

            if (expandKits !== undefined) {
                localVarQueryParameter['expandKits'] = expandKits;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (timezoneOffsetUTC !== undefined) {
                localVarQueryParameter['timezoneOffsetUTC'] = timezoneOffsetUTC;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Экспорт списка обязательств поставки. При limit = null ограничение до 1000
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} subrentId Идентификатор поставки
         * @param {Array<string>} columns Колонки учавстующие в экспорте. Передавать [\&#39;ALL\&#39;] чтобы выгрузить все доступные
         * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportSubrentElementsList(businessAccountId: number, subrentId: number, columns: Array<string>, expandKits: boolean, timezoneOffsetUTC: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling exportSubrentElementsList.');
            }
            // verify required parameter 'subrentId' is not null or undefined
            if (subrentId === null || subrentId === undefined) {
                throw new RequiredError('subrentId','Required parameter subrentId was null or undefined when calling exportSubrentElementsList.');
            }
            // verify required parameter 'columns' is not null or undefined
            if (columns === null || columns === undefined) {
                throw new RequiredError('columns','Required parameter columns was null or undefined when calling exportSubrentElementsList.');
            }
            // verify required parameter 'expandKits' is not null or undefined
            if (expandKits === null || expandKits === undefined) {
                throw new RequiredError('expandKits','Required parameter expandKits was null or undefined when calling exportSubrentElementsList.');
            }
            // verify required parameter 'timezoneOffsetUTC' is not null or undefined
            if (timezoneOffsetUTC === null || timezoneOffsetUTC === undefined) {
                throw new RequiredError('timezoneOffsetUTC','Required parameter timezoneOffsetUTC was null or undefined when calling exportSubrentElementsList.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/subrent/{subrentId}/elements/export`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"subrentId"}}`, encodeURIComponent(String(subrentId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (columns) {
                localVarQueryParameter['columns'] = columns;
            }

            if (expandKits !== undefined) {
                localVarQueryParameter['expandKits'] = expandKits;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (timezoneOffsetUTC !== undefined) {
                localVarQueryParameter['timezoneOffsetUTC'] = timezoneOffsetUTC;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Экспорт списка обязательств шаблона. При limit = null ограничение до 1000
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} templateId Идентификатор шаблона
         * @param {Array<string>} columns Колонки учавстующие в экспорте. Передавать [\&#39;ALL\&#39;] чтобы выгрузить все доступные
         * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportTemplateElementsList(businessAccountId: number, templateId: number, columns: Array<string>, expandKits: boolean, timezoneOffsetUTC: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling exportTemplateElementsList.');
            }
            // verify required parameter 'templateId' is not null or undefined
            if (templateId === null || templateId === undefined) {
                throw new RequiredError('templateId','Required parameter templateId was null or undefined when calling exportTemplateElementsList.');
            }
            // verify required parameter 'columns' is not null or undefined
            if (columns === null || columns === undefined) {
                throw new RequiredError('columns','Required parameter columns was null or undefined when calling exportTemplateElementsList.');
            }
            // verify required parameter 'expandKits' is not null or undefined
            if (expandKits === null || expandKits === undefined) {
                throw new RequiredError('expandKits','Required parameter expandKits was null or undefined when calling exportTemplateElementsList.');
            }
            // verify required parameter 'timezoneOffsetUTC' is not null or undefined
            if (timezoneOffsetUTC === null || timezoneOffsetUTC === undefined) {
                throw new RequiredError('timezoneOffsetUTC','Required parameter timezoneOffsetUTC was null or undefined when calling exportTemplateElementsList.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/templates/{templateId}/elements/export`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (columns) {
                localVarQueryParameter['columns'] = columns;
            }

            if (expandKits !== undefined) {
                localVarQueryParameter['expandKits'] = expandKits;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (timezoneOffsetUTC !== undefined) {
                localVarQueryParameter['timezoneOffsetUTC'] = timezoneOffsetUTC;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Переиндексировать все сущности компании с удалением лишних
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fullReindexBusinessAccount(businessAccountId: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling fullReindexBusinessAccount.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/fullReindex`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение файла бейджа
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} nomenclatureType Тип номенклатуры для печати
         * @param {number} nomenclatureId Id сущности
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {boolean} [common] Флаг типа шаблона
         * @param {number} [documentTemplateId] Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateBadgePdf(businessAccountId: number, nomenclatureType: string, nomenclatureId: number, timezoneOffsetUTC: number, common?: boolean, documentTemplateId?: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling generateBadgePdf.');
            }
            // verify required parameter 'nomenclatureType' is not null or undefined
            if (nomenclatureType === null || nomenclatureType === undefined) {
                throw new RequiredError('nomenclatureType','Required parameter nomenclatureType was null or undefined when calling generateBadgePdf.');
            }
            // verify required parameter 'nomenclatureId' is not null or undefined
            if (nomenclatureId === null || nomenclatureId === undefined) {
                throw new RequiredError('nomenclatureId','Required parameter nomenclatureId was null or undefined when calling generateBadgePdf.');
            }
            // verify required parameter 'timezoneOffsetUTC' is not null or undefined
            if (timezoneOffsetUTC === null || timezoneOffsetUTC === undefined) {
                throw new RequiredError('timezoneOffsetUTC','Required parameter timezoneOffsetUTC was null or undefined when calling generateBadgePdf.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/nomenclature/badge/pdf`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (common !== undefined) {
                localVarQueryParameter['common'] = common;
            }

            if (documentTemplateId !== undefined) {
                localVarQueryParameter['documentTemplateId'] = documentTemplateId;
            }

            if (nomenclatureType !== undefined) {
                localVarQueryParameter['nomenclatureType'] = nomenclatureType;
            }

            if (nomenclatureId !== undefined) {
                localVarQueryParameter['nomenclatureId'] = nomenclatureId;
            }

            if (timezoneOffsetUTC !== undefined) {
                localVarQueryParameter['timezoneOffsetUTC'] = timezoneOffsetUTC;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение изображения штрихового кода
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} barcodeString Штриховой код
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateBarcodeImage(businessAccountId: number, barcodeString: string, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling generateBarcodeImage.');
            }
            // verify required parameter 'barcodeString' is not null or undefined
            if (barcodeString === null || barcodeString === undefined) {
                throw new RequiredError('barcodeString','Required parameter barcodeString was null or undefined when calling generateBarcodeImage.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/barcode/{barcodeString}/jpeg`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"barcodeString"}}`, encodeURIComponent(String(barcodeString)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Генерация документа транспортной накладной в формат DOCX
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {boolean} common Флаг типа шаблона
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateBillOfLadingDocx(businessAccountId: number, id: number, common: boolean, documentTemplateId: number, businessAccountLegalDetailsId?: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling generateBillOfLadingDocx.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling generateBillOfLadingDocx.');
            }
            // verify required parameter 'common' is not null or undefined
            if (common === null || common === undefined) {
                throw new RequiredError('common','Required parameter common was null or undefined when calling generateBillOfLadingDocx.');
            }
            // verify required parameter 'documentTemplateId' is not null or undefined
            if (documentTemplateId === null || documentTemplateId === undefined) {
                throw new RequiredError('documentTemplateId','Required parameter documentTemplateId was null or undefined when calling generateBillOfLadingDocx.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/transportations/{id}/bill-of-lading/docx`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (common !== undefined) {
                localVarQueryParameter['common'] = common;
            }

            if (documentTemplateId !== undefined) {
                localVarQueryParameter['documentTemplateId'] = documentTemplateId;
            }

            if (businessAccountLegalDetailsId !== undefined) {
                localVarQueryParameter['businessAccountLegalDetailsId'] = businessAccountLegalDetailsId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Генерация документа транспортной накладной в формат PDF
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {boolean} common Флаг типа шаблона
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateBillOfLadingPdf(businessAccountId: number, id: number, common: boolean, documentTemplateId: number, businessAccountLegalDetailsId?: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling generateBillOfLadingPdf.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling generateBillOfLadingPdf.');
            }
            // verify required parameter 'common' is not null or undefined
            if (common === null || common === undefined) {
                throw new RequiredError('common','Required parameter common was null or undefined when calling generateBillOfLadingPdf.');
            }
            // verify required parameter 'documentTemplateId' is not null or undefined
            if (documentTemplateId === null || documentTemplateId === undefined) {
                throw new RequiredError('documentTemplateId','Required parameter documentTemplateId was null or undefined when calling generateBillOfLadingPdf.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/transportations/{id}/bill-of-lading/pdf`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (common !== undefined) {
                localVarQueryParameter['common'] = common;
            }

            if (documentTemplateId !== undefined) {
                localVarQueryParameter['documentTemplateId'] = documentTemplateId;
            }

            if (businessAccountLegalDetailsId !== undefined) {
                localVarQueryParameter['businessAccountLegalDetailsId'] = businessAccountLegalDetailsId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Генерация документа счета в формат PDF
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {boolean} common Флаг типа шаблона
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateBillPdf(businessAccountId: number, id: number, common: boolean, documentTemplateId: number, timezoneOffsetUTC: number, businessAccountLegalDetailsId?: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling generateBillPdf.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling generateBillPdf.');
            }
            // verify required parameter 'common' is not null or undefined
            if (common === null || common === undefined) {
                throw new RequiredError('common','Required parameter common was null or undefined when calling generateBillPdf.');
            }
            // verify required parameter 'documentTemplateId' is not null or undefined
            if (documentTemplateId === null || documentTemplateId === undefined) {
                throw new RequiredError('documentTemplateId','Required parameter documentTemplateId was null or undefined when calling generateBillPdf.');
            }
            // verify required parameter 'timezoneOffsetUTC' is not null or undefined
            if (timezoneOffsetUTC === null || timezoneOffsetUTC === undefined) {
                throw new RequiredError('timezoneOffsetUTC','Required parameter timezoneOffsetUTC was null or undefined when calling generateBillPdf.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/payments/{id}/documents/bill/pdf`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (common !== undefined) {
                localVarQueryParameter['common'] = common;
            }

            if (documentTemplateId !== undefined) {
                localVarQueryParameter['documentTemplateId'] = documentTemplateId;
            }

            if (businessAccountLegalDetailsId !== undefined) {
                localVarQueryParameter['businessAccountLegalDetailsId'] = businessAccountLegalDetailsId;
            }

            if (timezoneOffsetUTC !== undefined) {
                localVarQueryParameter['timezoneOffsetUTC'] = timezoneOffsetUTC;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Запрос на генерацию нового токена доступа к апи календаря
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateCalendarAccessToken(businessAccountId: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling generateCalendarAccessToken.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/preferences/generateCalendarAccessToken`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Генерация документа договора по проекту в формат DOCX
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} projectId Идентификатор проекта
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {boolean} common Флаг типа шаблона
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateContractDocumentDocx(businessAccountId: number, projectId: number, documentTemplateId: number, common: boolean, timezoneOffsetUTC: number, businessAccountLegalDetailsId?: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling generateContractDocumentDocx.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling generateContractDocumentDocx.');
            }
            // verify required parameter 'documentTemplateId' is not null or undefined
            if (documentTemplateId === null || documentTemplateId === undefined) {
                throw new RequiredError('documentTemplateId','Required parameter documentTemplateId was null or undefined when calling generateContractDocumentDocx.');
            }
            // verify required parameter 'common' is not null or undefined
            if (common === null || common === undefined) {
                throw new RequiredError('common','Required parameter common was null or undefined when calling generateContractDocumentDocx.');
            }
            // verify required parameter 'timezoneOffsetUTC' is not null or undefined
            if (timezoneOffsetUTC === null || timezoneOffsetUTC === undefined) {
                throw new RequiredError('timezoneOffsetUTC','Required parameter timezoneOffsetUTC was null or undefined when calling generateContractDocumentDocx.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/projects/{projectId}/documents/contract/docx`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (documentTemplateId !== undefined) {
                localVarQueryParameter['documentTemplateId'] = documentTemplateId;
            }

            if (businessAccountLegalDetailsId !== undefined) {
                localVarQueryParameter['businessAccountLegalDetailsId'] = businessAccountLegalDetailsId;
            }

            if (common !== undefined) {
                localVarQueryParameter['common'] = common;
            }

            if (timezoneOffsetUTC !== undefined) {
                localVarQueryParameter['timezoneOffsetUTC'] = timezoneOffsetUTC;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Генерация документа договора по проекту в формат PDF
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} projectId Идентификатор проекта
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {boolean} common Флаг типа шаблона
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateContractDocumentPdf(businessAccountId: number, projectId: number, documentTemplateId: number, common: boolean, timezoneOffsetUTC: number, businessAccountLegalDetailsId?: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling generateContractDocumentPdf.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling generateContractDocumentPdf.');
            }
            // verify required parameter 'documentTemplateId' is not null or undefined
            if (documentTemplateId === null || documentTemplateId === undefined) {
                throw new RequiredError('documentTemplateId','Required parameter documentTemplateId was null or undefined when calling generateContractDocumentPdf.');
            }
            // verify required parameter 'common' is not null or undefined
            if (common === null || common === undefined) {
                throw new RequiredError('common','Required parameter common was null or undefined when calling generateContractDocumentPdf.');
            }
            // verify required parameter 'timezoneOffsetUTC' is not null or undefined
            if (timezoneOffsetUTC === null || timezoneOffsetUTC === undefined) {
                throw new RequiredError('timezoneOffsetUTC','Required parameter timezoneOffsetUTC was null or undefined when calling generateContractDocumentPdf.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/projects/{projectId}/documents/contract/pdf`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (documentTemplateId !== undefined) {
                localVarQueryParameter['documentTemplateId'] = documentTemplateId;
            }

            if (businessAccountLegalDetailsId !== undefined) {
                localVarQueryParameter['businessAccountLegalDetailsId'] = businessAccountLegalDetailsId;
            }

            if (common !== undefined) {
                localVarQueryParameter['common'] = common;
            }

            if (timezoneOffsetUTC !== undefined) {
                localVarQueryParameter['timezoneOffsetUTC'] = timezoneOffsetUTC;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Генерация представления отчета в формат PDF
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {ExcelExportGenerationInfo} excelExportGenerationInfo 
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateExportPdf(businessAccountId: number, excelExportGenerationInfo: ExcelExportGenerationInfo, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling generateExportPdf.');
            }
            // verify required parameter 'excelExportGenerationInfo' is not null or undefined
            if (excelExportGenerationInfo === null || excelExportGenerationInfo === undefined) {
                throw new RequiredError('excelExportGenerationInfo','Required parameter excelExportGenerationInfo was null or undefined when calling generateExportPdf.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/excelExport/pdf`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof excelExportGenerationInfo !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(excelExportGenerationInfo !== undefined ? excelExportGenerationInfo : {}) : (excelExportGenerationInfo || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Генерация представления отчета в формат XLSX
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {ExcelExportGenerationInfo} excelExportGenerationInfo 
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateExportXlsx(businessAccountId: number, excelExportGenerationInfo: ExcelExportGenerationInfo, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling generateExportXlsx.');
            }
            // verify required parameter 'excelExportGenerationInfo' is not null or undefined
            if (excelExportGenerationInfo === null || excelExportGenerationInfo === undefined) {
                throw new RequiredError('excelExportGenerationInfo','Required parameter excelExportGenerationInfo was null or undefined when calling generateExportXlsx.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/excelExport/xlsx`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof excelExportGenerationInfo !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(excelExportGenerationInfo !== undefined ? excelExportGenerationInfo : {}) : (excelExportGenerationInfo || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Генерация документа прайс листа в формат PDF
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {boolean} common Флаг типа шаблона
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {number} appliedDiscount Скидка
         * @param {boolean} showDiscount Показывать ли скидку явно
         * @param {boolean} showPricingSchemes Показывать ли ценовые схемы
         * @param {boolean} showDescription Показывать ли описание
         * @param {boolean} showCount Показывать ли общее количество
         * @param {boolean} groupByCategories Группировать по категориям
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateNomenclaturePricelistPdf(businessAccountId: number, common: boolean, documentTemplateId: number, appliedDiscount: number, showDiscount: boolean, showPricingSchemes: boolean, showDescription: boolean, showCount: boolean, groupByCategories: boolean, timezoneOffsetUTC: number, businessAccountLegalDetailsId?: number, filters?: Array<string>, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling generateNomenclaturePricelistPdf.');
            }
            // verify required parameter 'common' is not null or undefined
            if (common === null || common === undefined) {
                throw new RequiredError('common','Required parameter common was null or undefined when calling generateNomenclaturePricelistPdf.');
            }
            // verify required parameter 'documentTemplateId' is not null or undefined
            if (documentTemplateId === null || documentTemplateId === undefined) {
                throw new RequiredError('documentTemplateId','Required parameter documentTemplateId was null or undefined when calling generateNomenclaturePricelistPdf.');
            }
            // verify required parameter 'appliedDiscount' is not null or undefined
            if (appliedDiscount === null || appliedDiscount === undefined) {
                throw new RequiredError('appliedDiscount','Required parameter appliedDiscount was null or undefined when calling generateNomenclaturePricelistPdf.');
            }
            // verify required parameter 'showDiscount' is not null or undefined
            if (showDiscount === null || showDiscount === undefined) {
                throw new RequiredError('showDiscount','Required parameter showDiscount was null or undefined when calling generateNomenclaturePricelistPdf.');
            }
            // verify required parameter 'showPricingSchemes' is not null or undefined
            if (showPricingSchemes === null || showPricingSchemes === undefined) {
                throw new RequiredError('showPricingSchemes','Required parameter showPricingSchemes was null or undefined when calling generateNomenclaturePricelistPdf.');
            }
            // verify required parameter 'showDescription' is not null or undefined
            if (showDescription === null || showDescription === undefined) {
                throw new RequiredError('showDescription','Required parameter showDescription was null or undefined when calling generateNomenclaturePricelistPdf.');
            }
            // verify required parameter 'showCount' is not null or undefined
            if (showCount === null || showCount === undefined) {
                throw new RequiredError('showCount','Required parameter showCount was null or undefined when calling generateNomenclaturePricelistPdf.');
            }
            // verify required parameter 'groupByCategories' is not null or undefined
            if (groupByCategories === null || groupByCategories === undefined) {
                throw new RequiredError('groupByCategories','Required parameter groupByCategories was null or undefined when calling generateNomenclaturePricelistPdf.');
            }
            // verify required parameter 'timezoneOffsetUTC' is not null or undefined
            if (timezoneOffsetUTC === null || timezoneOffsetUTC === undefined) {
                throw new RequiredError('timezoneOffsetUTC','Required parameter timezoneOffsetUTC was null or undefined when calling generateNomenclaturePricelistPdf.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/nomenclature/pricelist/pdf`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (common !== undefined) {
                localVarQueryParameter['common'] = common;
            }

            if (documentTemplateId !== undefined) {
                localVarQueryParameter['documentTemplateId'] = documentTemplateId;
            }

            if (businessAccountLegalDetailsId !== undefined) {
                localVarQueryParameter['businessAccountLegalDetailsId'] = businessAccountLegalDetailsId;
            }

            if (appliedDiscount !== undefined) {
                localVarQueryParameter['appliedDiscount'] = appliedDiscount;
            }

            if (showDiscount !== undefined) {
                localVarQueryParameter['showDiscount'] = showDiscount;
            }

            if (showPricingSchemes !== undefined) {
                localVarQueryParameter['showPricingSchemes'] = showPricingSchemes;
            }

            if (showDescription !== undefined) {
                localVarQueryParameter['showDescription'] = showDescription;
            }

            if (showCount !== undefined) {
                localVarQueryParameter['showCount'] = showCount;
            }

            if (groupByCategories !== undefined) {
                localVarQueryParameter['groupByCategories'] = groupByCategories;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (timezoneOffsetUTC !== undefined) {
                localVarQueryParameter['timezoneOffsetUTC'] = timezoneOffsetUTC;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Генерация документа прайс листа в формат XLSX
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {boolean} common Флаг типа шаблона
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {number} appliedDiscount Скидка
         * @param {boolean} showDiscount Показывать ли скидку явно
         * @param {boolean} showPricingSchemes Показывать ли ценовые схемы
         * @param {boolean} showDescription Показывать ли описание
         * @param {boolean} showCount Показывать ли общее количество
         * @param {boolean} groupByCategories Группировать по категориям
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateNomenclaturePricelistXlsx(businessAccountId: number, common: boolean, documentTemplateId: number, appliedDiscount: number, showDiscount: boolean, showPricingSchemes: boolean, showDescription: boolean, showCount: boolean, groupByCategories: boolean, timezoneOffsetUTC: number, businessAccountLegalDetailsId?: number, filters?: Array<string>, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling generateNomenclaturePricelistXlsx.');
            }
            // verify required parameter 'common' is not null or undefined
            if (common === null || common === undefined) {
                throw new RequiredError('common','Required parameter common was null or undefined when calling generateNomenclaturePricelistXlsx.');
            }
            // verify required parameter 'documentTemplateId' is not null or undefined
            if (documentTemplateId === null || documentTemplateId === undefined) {
                throw new RequiredError('documentTemplateId','Required parameter documentTemplateId was null or undefined when calling generateNomenclaturePricelistXlsx.');
            }
            // verify required parameter 'appliedDiscount' is not null or undefined
            if (appliedDiscount === null || appliedDiscount === undefined) {
                throw new RequiredError('appliedDiscount','Required parameter appliedDiscount was null or undefined when calling generateNomenclaturePricelistXlsx.');
            }
            // verify required parameter 'showDiscount' is not null or undefined
            if (showDiscount === null || showDiscount === undefined) {
                throw new RequiredError('showDiscount','Required parameter showDiscount was null or undefined when calling generateNomenclaturePricelistXlsx.');
            }
            // verify required parameter 'showPricingSchemes' is not null or undefined
            if (showPricingSchemes === null || showPricingSchemes === undefined) {
                throw new RequiredError('showPricingSchemes','Required parameter showPricingSchemes was null or undefined when calling generateNomenclaturePricelistXlsx.');
            }
            // verify required parameter 'showDescription' is not null or undefined
            if (showDescription === null || showDescription === undefined) {
                throw new RequiredError('showDescription','Required parameter showDescription was null or undefined when calling generateNomenclaturePricelistXlsx.');
            }
            // verify required parameter 'showCount' is not null or undefined
            if (showCount === null || showCount === undefined) {
                throw new RequiredError('showCount','Required parameter showCount was null or undefined when calling generateNomenclaturePricelistXlsx.');
            }
            // verify required parameter 'groupByCategories' is not null or undefined
            if (groupByCategories === null || groupByCategories === undefined) {
                throw new RequiredError('groupByCategories','Required parameter groupByCategories was null or undefined when calling generateNomenclaturePricelistXlsx.');
            }
            // verify required parameter 'timezoneOffsetUTC' is not null or undefined
            if (timezoneOffsetUTC === null || timezoneOffsetUTC === undefined) {
                throw new RequiredError('timezoneOffsetUTC','Required parameter timezoneOffsetUTC was null or undefined when calling generateNomenclaturePricelistXlsx.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/nomenclature/pricelist/xlsx`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (common !== undefined) {
                localVarQueryParameter['common'] = common;
            }

            if (documentTemplateId !== undefined) {
                localVarQueryParameter['documentTemplateId'] = documentTemplateId;
            }

            if (businessAccountLegalDetailsId !== undefined) {
                localVarQueryParameter['businessAccountLegalDetailsId'] = businessAccountLegalDetailsId;
            }

            if (appliedDiscount !== undefined) {
                localVarQueryParameter['appliedDiscount'] = appliedDiscount;
            }

            if (showDiscount !== undefined) {
                localVarQueryParameter['showDiscount'] = showDiscount;
            }

            if (showPricingSchemes !== undefined) {
                localVarQueryParameter['showPricingSchemes'] = showPricingSchemes;
            }

            if (showDescription !== undefined) {
                localVarQueryParameter['showDescription'] = showDescription;
            }

            if (showCount !== undefined) {
                localVarQueryParameter['showCount'] = showCount;
            }

            if (groupByCategories !== undefined) {
                localVarQueryParameter['groupByCategories'] = groupByCategories;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (timezoneOffsetUTC !== undefined) {
                localVarQueryParameter['timezoneOffsetUTC'] = timezoneOffsetUTC;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Генерация акта по операции в формат DOCX
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} operationId Идентификатор операции
         * @param {boolean} common Флаг типа шаблона
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
         * @param {boolean} joinSimilarRows Объединять ли одинаковые строки при экспорте (для развернутых наборов не работает)
         * @param {boolean} groupByCategories Группировать по категориям
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {CategorySortOrderEnum} [categorySortBy] 
         * @param {'ASC' | 'DESC'} [categorySortOrder] 
         * @param {Date} [t0] 
         * @param {Date} [tN] 
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateOperationActDocumentDocx(businessAccountId: number, operationId: number, common: boolean, documentTemplateId: number, expandKits: boolean, joinSimilarRows: boolean, groupByCategories: boolean, timezoneOffsetUTC: number, categorySortBy?: CategorySortOrderEnum, categorySortOrder?: 'ASC' | 'DESC', t0?: Date, tN?: Date, businessAccountLegalDetailsId?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling generateOperationActDocumentDocx.');
            }
            // verify required parameter 'operationId' is not null or undefined
            if (operationId === null || operationId === undefined) {
                throw new RequiredError('operationId','Required parameter operationId was null or undefined when calling generateOperationActDocumentDocx.');
            }
            // verify required parameter 'common' is not null or undefined
            if (common === null || common === undefined) {
                throw new RequiredError('common','Required parameter common was null or undefined when calling generateOperationActDocumentDocx.');
            }
            // verify required parameter 'documentTemplateId' is not null or undefined
            if (documentTemplateId === null || documentTemplateId === undefined) {
                throw new RequiredError('documentTemplateId','Required parameter documentTemplateId was null or undefined when calling generateOperationActDocumentDocx.');
            }
            // verify required parameter 'expandKits' is not null or undefined
            if (expandKits === null || expandKits === undefined) {
                throw new RequiredError('expandKits','Required parameter expandKits was null or undefined when calling generateOperationActDocumentDocx.');
            }
            // verify required parameter 'joinSimilarRows' is not null or undefined
            if (joinSimilarRows === null || joinSimilarRows === undefined) {
                throw new RequiredError('joinSimilarRows','Required parameter joinSimilarRows was null or undefined when calling generateOperationActDocumentDocx.');
            }
            // verify required parameter 'groupByCategories' is not null or undefined
            if (groupByCategories === null || groupByCategories === undefined) {
                throw new RequiredError('groupByCategories','Required parameter groupByCategories was null or undefined when calling generateOperationActDocumentDocx.');
            }
            // verify required parameter 'timezoneOffsetUTC' is not null or undefined
            if (timezoneOffsetUTC === null || timezoneOffsetUTC === undefined) {
                throw new RequiredError('timezoneOffsetUTC','Required parameter timezoneOffsetUTC was null or undefined when calling generateOperationActDocumentDocx.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/operations/{operationId}/documents/act/docx`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"operationId"}}`, encodeURIComponent(String(operationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (categorySortBy !== undefined) {
                localVarQueryParameter['categorySortBy'] = categorySortBy;
            }

            if (categorySortOrder !== undefined) {
                localVarQueryParameter['categorySortOrder'] = categorySortOrder;
            }

            if (t0 !== undefined) {
                localVarQueryParameter['t0'] = (t0 as any instanceof Date) ?
                    (t0 as any).toISOString() :
                    t0;
            }

            if (tN !== undefined) {
                localVarQueryParameter['tN'] = (tN as any instanceof Date) ?
                    (tN as any).toISOString() :
                    tN;
            }

            if (common !== undefined) {
                localVarQueryParameter['common'] = common;
            }

            if (documentTemplateId !== undefined) {
                localVarQueryParameter['documentTemplateId'] = documentTemplateId;
            }

            if (businessAccountLegalDetailsId !== undefined) {
                localVarQueryParameter['businessAccountLegalDetailsId'] = businessAccountLegalDetailsId;
            }

            if (expandKits !== undefined) {
                localVarQueryParameter['expandKits'] = expandKits;
            }

            if (joinSimilarRows !== undefined) {
                localVarQueryParameter['joinSimilarRows'] = joinSimilarRows;
            }

            if (groupByCategories !== undefined) {
                localVarQueryParameter['groupByCategories'] = groupByCategories;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (timezoneOffsetUTC !== undefined) {
                localVarQueryParameter['timezoneOffsetUTC'] = timezoneOffsetUTC;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Генерация акта по операции в формат PDF
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} operationId Идентификатор операции
         * @param {boolean} common Флаг типа шаблона
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
         * @param {boolean} joinSimilarRows Объединять ли одинаковые строки при экспорте (для развернутых наборов не работает)
         * @param {boolean} groupByCategories Группировать по категориям
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {CategorySortOrderEnum} [categorySortBy] 
         * @param {'ASC' | 'DESC'} [categorySortOrder] 
         * @param {Date} [t0] 
         * @param {Date} [tN] 
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateOperationActDocumentPdf(businessAccountId: number, operationId: number, common: boolean, documentTemplateId: number, expandKits: boolean, joinSimilarRows: boolean, groupByCategories: boolean, timezoneOffsetUTC: number, categorySortBy?: CategorySortOrderEnum, categorySortOrder?: 'ASC' | 'DESC', t0?: Date, tN?: Date, businessAccountLegalDetailsId?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling generateOperationActDocumentPdf.');
            }
            // verify required parameter 'operationId' is not null or undefined
            if (operationId === null || operationId === undefined) {
                throw new RequiredError('operationId','Required parameter operationId was null or undefined when calling generateOperationActDocumentPdf.');
            }
            // verify required parameter 'common' is not null or undefined
            if (common === null || common === undefined) {
                throw new RequiredError('common','Required parameter common was null or undefined when calling generateOperationActDocumentPdf.');
            }
            // verify required parameter 'documentTemplateId' is not null or undefined
            if (documentTemplateId === null || documentTemplateId === undefined) {
                throw new RequiredError('documentTemplateId','Required parameter documentTemplateId was null or undefined when calling generateOperationActDocumentPdf.');
            }
            // verify required parameter 'expandKits' is not null or undefined
            if (expandKits === null || expandKits === undefined) {
                throw new RequiredError('expandKits','Required parameter expandKits was null or undefined when calling generateOperationActDocumentPdf.');
            }
            // verify required parameter 'joinSimilarRows' is not null or undefined
            if (joinSimilarRows === null || joinSimilarRows === undefined) {
                throw new RequiredError('joinSimilarRows','Required parameter joinSimilarRows was null or undefined when calling generateOperationActDocumentPdf.');
            }
            // verify required parameter 'groupByCategories' is not null or undefined
            if (groupByCategories === null || groupByCategories === undefined) {
                throw new RequiredError('groupByCategories','Required parameter groupByCategories was null or undefined when calling generateOperationActDocumentPdf.');
            }
            // verify required parameter 'timezoneOffsetUTC' is not null or undefined
            if (timezoneOffsetUTC === null || timezoneOffsetUTC === undefined) {
                throw new RequiredError('timezoneOffsetUTC','Required parameter timezoneOffsetUTC was null or undefined when calling generateOperationActDocumentPdf.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/operations/{operationId}/documents/act/pdf`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"operationId"}}`, encodeURIComponent(String(operationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (categorySortBy !== undefined) {
                localVarQueryParameter['categorySortBy'] = categorySortBy;
            }

            if (categorySortOrder !== undefined) {
                localVarQueryParameter['categorySortOrder'] = categorySortOrder;
            }

            if (t0 !== undefined) {
                localVarQueryParameter['t0'] = (t0 as any instanceof Date) ?
                    (t0 as any).toISOString() :
                    t0;
            }

            if (tN !== undefined) {
                localVarQueryParameter['tN'] = (tN as any instanceof Date) ?
                    (tN as any).toISOString() :
                    tN;
            }

            if (common !== undefined) {
                localVarQueryParameter['common'] = common;
            }

            if (documentTemplateId !== undefined) {
                localVarQueryParameter['documentTemplateId'] = documentTemplateId;
            }

            if (businessAccountLegalDetailsId !== undefined) {
                localVarQueryParameter['businessAccountLegalDetailsId'] = businessAccountLegalDetailsId;
            }

            if (expandKits !== undefined) {
                localVarQueryParameter['expandKits'] = expandKits;
            }

            if (joinSimilarRows !== undefined) {
                localVarQueryParameter['joinSimilarRows'] = joinSimilarRows;
            }

            if (groupByCategories !== undefined) {
                localVarQueryParameter['groupByCategories'] = groupByCategories;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (timezoneOffsetUTC !== undefined) {
                localVarQueryParameter['timezoneOffsetUTC'] = timezoneOffsetUTC;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Генерация документа сметы операции в формат PDF
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} operationId Идентификатор операции
         * @param {boolean} common Флаг типа шаблона
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
         * @param {boolean} joinSimilarRows Объединять ли одинаковые строки при экспорте (для развернутых наборов не работает)
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {CategorySortOrderEnum} [categorySortBy] 
         * @param {'ASC' | 'DESC'} [categorySortOrder] 
         * @param {Date} [t0] 
         * @param {Date} [tN] 
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateOperationEstimateDocumentPdf(businessAccountId: number, operationId: number, common: boolean, documentTemplateId: number, expandKits: boolean, joinSimilarRows: boolean, timezoneOffsetUTC: number, categorySortBy?: CategorySortOrderEnum, categorySortOrder?: 'ASC' | 'DESC', t0?: Date, tN?: Date, businessAccountLegalDetailsId?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling generateOperationEstimateDocumentPdf.');
            }
            // verify required parameter 'operationId' is not null or undefined
            if (operationId === null || operationId === undefined) {
                throw new RequiredError('operationId','Required parameter operationId was null or undefined when calling generateOperationEstimateDocumentPdf.');
            }
            // verify required parameter 'common' is not null or undefined
            if (common === null || common === undefined) {
                throw new RequiredError('common','Required parameter common was null or undefined when calling generateOperationEstimateDocumentPdf.');
            }
            // verify required parameter 'documentTemplateId' is not null or undefined
            if (documentTemplateId === null || documentTemplateId === undefined) {
                throw new RequiredError('documentTemplateId','Required parameter documentTemplateId was null or undefined when calling generateOperationEstimateDocumentPdf.');
            }
            // verify required parameter 'expandKits' is not null or undefined
            if (expandKits === null || expandKits === undefined) {
                throw new RequiredError('expandKits','Required parameter expandKits was null or undefined when calling generateOperationEstimateDocumentPdf.');
            }
            // verify required parameter 'joinSimilarRows' is not null or undefined
            if (joinSimilarRows === null || joinSimilarRows === undefined) {
                throw new RequiredError('joinSimilarRows','Required parameter joinSimilarRows was null or undefined when calling generateOperationEstimateDocumentPdf.');
            }
            // verify required parameter 'timezoneOffsetUTC' is not null or undefined
            if (timezoneOffsetUTC === null || timezoneOffsetUTC === undefined) {
                throw new RequiredError('timezoneOffsetUTC','Required parameter timezoneOffsetUTC was null or undefined when calling generateOperationEstimateDocumentPdf.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/operations/{operationId}/documents/estimate/pdf`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"operationId"}}`, encodeURIComponent(String(operationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (categorySortBy !== undefined) {
                localVarQueryParameter['categorySortBy'] = categorySortBy;
            }

            if (categorySortOrder !== undefined) {
                localVarQueryParameter['categorySortOrder'] = categorySortOrder;
            }

            if (t0 !== undefined) {
                localVarQueryParameter['t0'] = (t0 as any instanceof Date) ?
                    (t0 as any).toISOString() :
                    t0;
            }

            if (tN !== undefined) {
                localVarQueryParameter['tN'] = (tN as any instanceof Date) ?
                    (tN as any).toISOString() :
                    tN;
            }

            if (common !== undefined) {
                localVarQueryParameter['common'] = common;
            }

            if (documentTemplateId !== undefined) {
                localVarQueryParameter['documentTemplateId'] = documentTemplateId;
            }

            if (businessAccountLegalDetailsId !== undefined) {
                localVarQueryParameter['businessAccountLegalDetailsId'] = businessAccountLegalDetailsId;
            }

            if (expandKits !== undefined) {
                localVarQueryParameter['expandKits'] = expandKits;
            }

            if (joinSimilarRows !== undefined) {
                localVarQueryParameter['joinSimilarRows'] = joinSimilarRows;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (timezoneOffsetUTC !== undefined) {
                localVarQueryParameter['timezoneOffsetUTC'] = timezoneOffsetUTC;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Генерация документа сметы операции в формат XLSX
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} operationId Идентификатор операции
         * @param {boolean} common Флаг типа шаблона
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
         * @param {boolean} joinSimilarRows Объединять ли одинаковые строки при экспорте (для развернутых наборов не работает)
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {CategorySortOrderEnum} [categorySortBy] 
         * @param {'ASC' | 'DESC'} [categorySortOrder] 
         * @param {Date} [t0] 
         * @param {Date} [tN] 
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateOperationEstimateDocumentXlsx(businessAccountId: number, operationId: number, common: boolean, documentTemplateId: number, expandKits: boolean, joinSimilarRows: boolean, timezoneOffsetUTC: number, categorySortBy?: CategorySortOrderEnum, categorySortOrder?: 'ASC' | 'DESC', t0?: Date, tN?: Date, businessAccountLegalDetailsId?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling generateOperationEstimateDocumentXlsx.');
            }
            // verify required parameter 'operationId' is not null or undefined
            if (operationId === null || operationId === undefined) {
                throw new RequiredError('operationId','Required parameter operationId was null or undefined when calling generateOperationEstimateDocumentXlsx.');
            }
            // verify required parameter 'common' is not null or undefined
            if (common === null || common === undefined) {
                throw new RequiredError('common','Required parameter common was null or undefined when calling generateOperationEstimateDocumentXlsx.');
            }
            // verify required parameter 'documentTemplateId' is not null or undefined
            if (documentTemplateId === null || documentTemplateId === undefined) {
                throw new RequiredError('documentTemplateId','Required parameter documentTemplateId was null or undefined when calling generateOperationEstimateDocumentXlsx.');
            }
            // verify required parameter 'expandKits' is not null or undefined
            if (expandKits === null || expandKits === undefined) {
                throw new RequiredError('expandKits','Required parameter expandKits was null or undefined when calling generateOperationEstimateDocumentXlsx.');
            }
            // verify required parameter 'joinSimilarRows' is not null or undefined
            if (joinSimilarRows === null || joinSimilarRows === undefined) {
                throw new RequiredError('joinSimilarRows','Required parameter joinSimilarRows was null or undefined when calling generateOperationEstimateDocumentXlsx.');
            }
            // verify required parameter 'timezoneOffsetUTC' is not null or undefined
            if (timezoneOffsetUTC === null || timezoneOffsetUTC === undefined) {
                throw new RequiredError('timezoneOffsetUTC','Required parameter timezoneOffsetUTC was null or undefined when calling generateOperationEstimateDocumentXlsx.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/operations/{operationId}/documents/estimate/xlsx`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"operationId"}}`, encodeURIComponent(String(operationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (categorySortBy !== undefined) {
                localVarQueryParameter['categorySortBy'] = categorySortBy;
            }

            if (categorySortOrder !== undefined) {
                localVarQueryParameter['categorySortOrder'] = categorySortOrder;
            }

            if (t0 !== undefined) {
                localVarQueryParameter['t0'] = (t0 as any instanceof Date) ?
                    (t0 as any).toISOString() :
                    t0;
            }

            if (tN !== undefined) {
                localVarQueryParameter['tN'] = (tN as any instanceof Date) ?
                    (tN as any).toISOString() :
                    tN;
            }

            if (common !== undefined) {
                localVarQueryParameter['common'] = common;
            }

            if (documentTemplateId !== undefined) {
                localVarQueryParameter['documentTemplateId'] = documentTemplateId;
            }

            if (businessAccountLegalDetailsId !== undefined) {
                localVarQueryParameter['businessAccountLegalDetailsId'] = businessAccountLegalDetailsId;
            }

            if (expandKits !== undefined) {
                localVarQueryParameter['expandKits'] = expandKits;
            }

            if (joinSimilarRows !== undefined) {
                localVarQueryParameter['joinSimilarRows'] = joinSimilarRows;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (timezoneOffsetUTC !== undefined) {
                localVarQueryParameter['timezoneOffsetUTC'] = timezoneOffsetUTC;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Генерация документа списка инвентаря в формат PDF
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {boolean} common Флаг типа шаблона
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {boolean} showBarcode Показывать ли штриховой код
         * @param {boolean} expandOwnNamedInstances Показывать ли свои именованные экземпляры при экспорте
         * @param {boolean} expandSubrentedNamedInstances Включать ли чужие экземпляры при экспорте
         * @param {boolean} showPurchaseCost Показывать ли закупочную стоимость при экспорте
         * @param {boolean} showCarryingValue Показывать ли балансовую стоимость при экспорте
         * @param {boolean} expandInstanceStateDescription Показывать ли описания состояний экземпляров при экспорте
         * @param {boolean} showColumnActualAvailability Показывать ли колонку \&#39;Фактическое наличие\&#39; при экспорте
         * @param {boolean} showColumnConvergence Показывать ли колонку \&#39;Схождение\&#39; при экспорте
         * @param {boolean} showColumnNotes Показывать ли колонку \&#39;Для пометок\&#39; при экспорте
         * @param {boolean} showSupplier Показывать ли колонку \&#39;Поставщик\&#39; при экспорте
         * @param {boolean} groupByCategories Группировать по категориям
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {CategorySortOrderEnum} [categorySortBy] 
         * @param {'ASC' | 'DESC'} [categorySortOrder] 
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateProductsInventoryListDocumentPdf(businessAccountId: number, common: boolean, documentTemplateId: number, showBarcode: boolean, expandOwnNamedInstances: boolean, expandSubrentedNamedInstances: boolean, showPurchaseCost: boolean, showCarryingValue: boolean, expandInstanceStateDescription: boolean, showColumnActualAvailability: boolean, showColumnConvergence: boolean, showColumnNotes: boolean, showSupplier: boolean, groupByCategories: boolean, timezoneOffsetUTC: number, categorySortBy?: CategorySortOrderEnum, categorySortOrder?: 'ASC' | 'DESC', businessAccountLegalDetailsId?: number, filters?: Array<string>, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling generateProductsInventoryListDocumentPdf.');
            }
            // verify required parameter 'common' is not null or undefined
            if (common === null || common === undefined) {
                throw new RequiredError('common','Required parameter common was null or undefined when calling generateProductsInventoryListDocumentPdf.');
            }
            // verify required parameter 'documentTemplateId' is not null or undefined
            if (documentTemplateId === null || documentTemplateId === undefined) {
                throw new RequiredError('documentTemplateId','Required parameter documentTemplateId was null or undefined when calling generateProductsInventoryListDocumentPdf.');
            }
            // verify required parameter 'showBarcode' is not null or undefined
            if (showBarcode === null || showBarcode === undefined) {
                throw new RequiredError('showBarcode','Required parameter showBarcode was null or undefined when calling generateProductsInventoryListDocumentPdf.');
            }
            // verify required parameter 'expandOwnNamedInstances' is not null or undefined
            if (expandOwnNamedInstances === null || expandOwnNamedInstances === undefined) {
                throw new RequiredError('expandOwnNamedInstances','Required parameter expandOwnNamedInstances was null or undefined when calling generateProductsInventoryListDocumentPdf.');
            }
            // verify required parameter 'expandSubrentedNamedInstances' is not null or undefined
            if (expandSubrentedNamedInstances === null || expandSubrentedNamedInstances === undefined) {
                throw new RequiredError('expandSubrentedNamedInstances','Required parameter expandSubrentedNamedInstances was null or undefined when calling generateProductsInventoryListDocumentPdf.');
            }
            // verify required parameter 'showPurchaseCost' is not null or undefined
            if (showPurchaseCost === null || showPurchaseCost === undefined) {
                throw new RequiredError('showPurchaseCost','Required parameter showPurchaseCost was null or undefined when calling generateProductsInventoryListDocumentPdf.');
            }
            // verify required parameter 'showCarryingValue' is not null or undefined
            if (showCarryingValue === null || showCarryingValue === undefined) {
                throw new RequiredError('showCarryingValue','Required parameter showCarryingValue was null or undefined when calling generateProductsInventoryListDocumentPdf.');
            }
            // verify required parameter 'expandInstanceStateDescription' is not null or undefined
            if (expandInstanceStateDescription === null || expandInstanceStateDescription === undefined) {
                throw new RequiredError('expandInstanceStateDescription','Required parameter expandInstanceStateDescription was null or undefined when calling generateProductsInventoryListDocumentPdf.');
            }
            // verify required parameter 'showColumnActualAvailability' is not null or undefined
            if (showColumnActualAvailability === null || showColumnActualAvailability === undefined) {
                throw new RequiredError('showColumnActualAvailability','Required parameter showColumnActualAvailability was null or undefined when calling generateProductsInventoryListDocumentPdf.');
            }
            // verify required parameter 'showColumnConvergence' is not null or undefined
            if (showColumnConvergence === null || showColumnConvergence === undefined) {
                throw new RequiredError('showColumnConvergence','Required parameter showColumnConvergence was null or undefined when calling generateProductsInventoryListDocumentPdf.');
            }
            // verify required parameter 'showColumnNotes' is not null or undefined
            if (showColumnNotes === null || showColumnNotes === undefined) {
                throw new RequiredError('showColumnNotes','Required parameter showColumnNotes was null or undefined when calling generateProductsInventoryListDocumentPdf.');
            }
            // verify required parameter 'showSupplier' is not null or undefined
            if (showSupplier === null || showSupplier === undefined) {
                throw new RequiredError('showSupplier','Required parameter showSupplier was null or undefined when calling generateProductsInventoryListDocumentPdf.');
            }
            // verify required parameter 'groupByCategories' is not null or undefined
            if (groupByCategories === null || groupByCategories === undefined) {
                throw new RequiredError('groupByCategories','Required parameter groupByCategories was null or undefined when calling generateProductsInventoryListDocumentPdf.');
            }
            // verify required parameter 'timezoneOffsetUTC' is not null or undefined
            if (timezoneOffsetUTC === null || timezoneOffsetUTC === undefined) {
                throw new RequiredError('timezoneOffsetUTC','Required parameter timezoneOffsetUTC was null or undefined when calling generateProductsInventoryListDocumentPdf.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/products/documents/inventory-list/pdf`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (categorySortBy !== undefined) {
                localVarQueryParameter['categorySortBy'] = categorySortBy;
            }

            if (categorySortOrder !== undefined) {
                localVarQueryParameter['categorySortOrder'] = categorySortOrder;
            }

            if (common !== undefined) {
                localVarQueryParameter['common'] = common;
            }

            if (documentTemplateId !== undefined) {
                localVarQueryParameter['documentTemplateId'] = documentTemplateId;
            }

            if (businessAccountLegalDetailsId !== undefined) {
                localVarQueryParameter['businessAccountLegalDetailsId'] = businessAccountLegalDetailsId;
            }

            if (showBarcode !== undefined) {
                localVarQueryParameter['showBarcode'] = showBarcode;
            }

            if (expandOwnNamedInstances !== undefined) {
                localVarQueryParameter['expandOwnNamedInstances'] = expandOwnNamedInstances;
            }

            if (expandSubrentedNamedInstances !== undefined) {
                localVarQueryParameter['expandSubrentedNamedInstances'] = expandSubrentedNamedInstances;
            }

            if (showPurchaseCost !== undefined) {
                localVarQueryParameter['showPurchaseCost'] = showPurchaseCost;
            }

            if (showCarryingValue !== undefined) {
                localVarQueryParameter['showCarryingValue'] = showCarryingValue;
            }

            if (expandInstanceStateDescription !== undefined) {
                localVarQueryParameter['expandInstanceStateDescription'] = expandInstanceStateDescription;
            }

            if (showColumnActualAvailability !== undefined) {
                localVarQueryParameter['showColumnActualAvailability'] = showColumnActualAvailability;
            }

            if (showColumnConvergence !== undefined) {
                localVarQueryParameter['showColumnConvergence'] = showColumnConvergence;
            }

            if (showColumnNotes !== undefined) {
                localVarQueryParameter['showColumnNotes'] = showColumnNotes;
            }

            if (showSupplier !== undefined) {
                localVarQueryParameter['showSupplier'] = showSupplier;
            }

            if (groupByCategories !== undefined) {
                localVarQueryParameter['groupByCategories'] = groupByCategories;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (timezoneOffsetUTC !== undefined) {
                localVarQueryParameter['timezoneOffsetUTC'] = timezoneOffsetUTC;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Генерация документа списка инвентаря в формат XLSX
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {boolean} common Флаг типа шаблона
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {boolean} showBarcode Показывать ли штриховой код
         * @param {boolean} expandOwnNamedInstances Показывать ли свои именованные экземпляры при экспорте
         * @param {boolean} expandSubrentedNamedInstances Включать ли чужие экземпляры при экспорте
         * @param {boolean} showPurchaseCost Показывать ли закупочную стоимость при экспорте
         * @param {boolean} showCarryingValue Показывать ли балансовую стоимость при экспорте
         * @param {boolean} expandInstanceStateDescription Показывать ли описания состояний экземпляров при экспорте
         * @param {boolean} showColumnActualAvailability Показывать ли колонку \&#39;Фактическое наличие\&#39; при экспорте
         * @param {boolean} showColumnConvergence Показывать ли колонку \&#39;Схождение\&#39; при экспорте
         * @param {boolean} showColumnNotes Показывать ли колонку \&#39;Для пометок\&#39; при экспорте
         * @param {boolean} showSupplier Показывать ли колонку \&#39;Поставщик\&#39; при экспорте
         * @param {boolean} groupByCategories Группировать по категориям
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {CategorySortOrderEnum} [categorySortBy] 
         * @param {'ASC' | 'DESC'} [categorySortOrder] 
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateProductsInventoryListDocumentXlsx(businessAccountId: number, common: boolean, documentTemplateId: number, showBarcode: boolean, expandOwnNamedInstances: boolean, expandSubrentedNamedInstances: boolean, showPurchaseCost: boolean, showCarryingValue: boolean, expandInstanceStateDescription: boolean, showColumnActualAvailability: boolean, showColumnConvergence: boolean, showColumnNotes: boolean, showSupplier: boolean, groupByCategories: boolean, timezoneOffsetUTC: number, categorySortBy?: CategorySortOrderEnum, categorySortOrder?: 'ASC' | 'DESC', businessAccountLegalDetailsId?: number, filters?: Array<string>, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling generateProductsInventoryListDocumentXlsx.');
            }
            // verify required parameter 'common' is not null or undefined
            if (common === null || common === undefined) {
                throw new RequiredError('common','Required parameter common was null or undefined when calling generateProductsInventoryListDocumentXlsx.');
            }
            // verify required parameter 'documentTemplateId' is not null or undefined
            if (documentTemplateId === null || documentTemplateId === undefined) {
                throw new RequiredError('documentTemplateId','Required parameter documentTemplateId was null or undefined when calling generateProductsInventoryListDocumentXlsx.');
            }
            // verify required parameter 'showBarcode' is not null or undefined
            if (showBarcode === null || showBarcode === undefined) {
                throw new RequiredError('showBarcode','Required parameter showBarcode was null or undefined when calling generateProductsInventoryListDocumentXlsx.');
            }
            // verify required parameter 'expandOwnNamedInstances' is not null or undefined
            if (expandOwnNamedInstances === null || expandOwnNamedInstances === undefined) {
                throw new RequiredError('expandOwnNamedInstances','Required parameter expandOwnNamedInstances was null or undefined when calling generateProductsInventoryListDocumentXlsx.');
            }
            // verify required parameter 'expandSubrentedNamedInstances' is not null or undefined
            if (expandSubrentedNamedInstances === null || expandSubrentedNamedInstances === undefined) {
                throw new RequiredError('expandSubrentedNamedInstances','Required parameter expandSubrentedNamedInstances was null or undefined when calling generateProductsInventoryListDocumentXlsx.');
            }
            // verify required parameter 'showPurchaseCost' is not null or undefined
            if (showPurchaseCost === null || showPurchaseCost === undefined) {
                throw new RequiredError('showPurchaseCost','Required parameter showPurchaseCost was null or undefined when calling generateProductsInventoryListDocumentXlsx.');
            }
            // verify required parameter 'showCarryingValue' is not null or undefined
            if (showCarryingValue === null || showCarryingValue === undefined) {
                throw new RequiredError('showCarryingValue','Required parameter showCarryingValue was null or undefined when calling generateProductsInventoryListDocumentXlsx.');
            }
            // verify required parameter 'expandInstanceStateDescription' is not null or undefined
            if (expandInstanceStateDescription === null || expandInstanceStateDescription === undefined) {
                throw new RequiredError('expandInstanceStateDescription','Required parameter expandInstanceStateDescription was null or undefined when calling generateProductsInventoryListDocumentXlsx.');
            }
            // verify required parameter 'showColumnActualAvailability' is not null or undefined
            if (showColumnActualAvailability === null || showColumnActualAvailability === undefined) {
                throw new RequiredError('showColumnActualAvailability','Required parameter showColumnActualAvailability was null or undefined when calling generateProductsInventoryListDocumentXlsx.');
            }
            // verify required parameter 'showColumnConvergence' is not null or undefined
            if (showColumnConvergence === null || showColumnConvergence === undefined) {
                throw new RequiredError('showColumnConvergence','Required parameter showColumnConvergence was null or undefined when calling generateProductsInventoryListDocumentXlsx.');
            }
            // verify required parameter 'showColumnNotes' is not null or undefined
            if (showColumnNotes === null || showColumnNotes === undefined) {
                throw new RequiredError('showColumnNotes','Required parameter showColumnNotes was null or undefined when calling generateProductsInventoryListDocumentXlsx.');
            }
            // verify required parameter 'showSupplier' is not null or undefined
            if (showSupplier === null || showSupplier === undefined) {
                throw new RequiredError('showSupplier','Required parameter showSupplier was null or undefined when calling generateProductsInventoryListDocumentXlsx.');
            }
            // verify required parameter 'groupByCategories' is not null or undefined
            if (groupByCategories === null || groupByCategories === undefined) {
                throw new RequiredError('groupByCategories','Required parameter groupByCategories was null or undefined when calling generateProductsInventoryListDocumentXlsx.');
            }
            // verify required parameter 'timezoneOffsetUTC' is not null or undefined
            if (timezoneOffsetUTC === null || timezoneOffsetUTC === undefined) {
                throw new RequiredError('timezoneOffsetUTC','Required parameter timezoneOffsetUTC was null or undefined when calling generateProductsInventoryListDocumentXlsx.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/products/documents/inventory-list/xlsx`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (categorySortBy !== undefined) {
                localVarQueryParameter['categorySortBy'] = categorySortBy;
            }

            if (categorySortOrder !== undefined) {
                localVarQueryParameter['categorySortOrder'] = categorySortOrder;
            }

            if (common !== undefined) {
                localVarQueryParameter['common'] = common;
            }

            if (documentTemplateId !== undefined) {
                localVarQueryParameter['documentTemplateId'] = documentTemplateId;
            }

            if (businessAccountLegalDetailsId !== undefined) {
                localVarQueryParameter['businessAccountLegalDetailsId'] = businessAccountLegalDetailsId;
            }

            if (showBarcode !== undefined) {
                localVarQueryParameter['showBarcode'] = showBarcode;
            }

            if (expandOwnNamedInstances !== undefined) {
                localVarQueryParameter['expandOwnNamedInstances'] = expandOwnNamedInstances;
            }

            if (expandSubrentedNamedInstances !== undefined) {
                localVarQueryParameter['expandSubrentedNamedInstances'] = expandSubrentedNamedInstances;
            }

            if (showPurchaseCost !== undefined) {
                localVarQueryParameter['showPurchaseCost'] = showPurchaseCost;
            }

            if (showCarryingValue !== undefined) {
                localVarQueryParameter['showCarryingValue'] = showCarryingValue;
            }

            if (expandInstanceStateDescription !== undefined) {
                localVarQueryParameter['expandInstanceStateDescription'] = expandInstanceStateDescription;
            }

            if (showColumnActualAvailability !== undefined) {
                localVarQueryParameter['showColumnActualAvailability'] = showColumnActualAvailability;
            }

            if (showColumnConvergence !== undefined) {
                localVarQueryParameter['showColumnConvergence'] = showColumnConvergence;
            }

            if (showColumnNotes !== undefined) {
                localVarQueryParameter['showColumnNotes'] = showColumnNotes;
            }

            if (showSupplier !== undefined) {
                localVarQueryParameter['showSupplier'] = showSupplier;
            }

            if (groupByCategories !== undefined) {
                localVarQueryParameter['groupByCategories'] = groupByCategories;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (timezoneOffsetUTC !== undefined) {
                localVarQueryParameter['timezoneOffsetUTC'] = timezoneOffsetUTC;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Генерация акта по проекту в формат DOCX
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} projectId Идентификатор проекта
         * @param {boolean} includeWorkPlannings 
         * @param {boolean} includeExpenses 
         * @param {boolean} common Флаг типа шаблона
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
         * @param {boolean} joinSimilarRows Объединять ли одинаковые строки при экспорте (для развернутых наборов не работает)
         * @param {boolean} groupByCategories Группировать по категориям
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {CategorySortOrderEnum} [categorySortBy] 
         * @param {'ASC' | 'DESC'} [categorySortOrder] 
         * @param {Date} [t0] 
         * @param {Date} [tN] 
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateProjectActDocumentDocx(businessAccountId: number, projectId: number, includeWorkPlannings: boolean, includeExpenses: boolean, common: boolean, documentTemplateId: number, expandKits: boolean, joinSimilarRows: boolean, groupByCategories: boolean, timezoneOffsetUTC: number, categorySortBy?: CategorySortOrderEnum, categorySortOrder?: 'ASC' | 'DESC', t0?: Date, tN?: Date, businessAccountLegalDetailsId?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling generateProjectActDocumentDocx.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling generateProjectActDocumentDocx.');
            }
            // verify required parameter 'includeWorkPlannings' is not null or undefined
            if (includeWorkPlannings === null || includeWorkPlannings === undefined) {
                throw new RequiredError('includeWorkPlannings','Required parameter includeWorkPlannings was null or undefined when calling generateProjectActDocumentDocx.');
            }
            // verify required parameter 'includeExpenses' is not null or undefined
            if (includeExpenses === null || includeExpenses === undefined) {
                throw new RequiredError('includeExpenses','Required parameter includeExpenses was null or undefined when calling generateProjectActDocumentDocx.');
            }
            // verify required parameter 'common' is not null or undefined
            if (common === null || common === undefined) {
                throw new RequiredError('common','Required parameter common was null or undefined when calling generateProjectActDocumentDocx.');
            }
            // verify required parameter 'documentTemplateId' is not null or undefined
            if (documentTemplateId === null || documentTemplateId === undefined) {
                throw new RequiredError('documentTemplateId','Required parameter documentTemplateId was null or undefined when calling generateProjectActDocumentDocx.');
            }
            // verify required parameter 'expandKits' is not null or undefined
            if (expandKits === null || expandKits === undefined) {
                throw new RequiredError('expandKits','Required parameter expandKits was null or undefined when calling generateProjectActDocumentDocx.');
            }
            // verify required parameter 'joinSimilarRows' is not null or undefined
            if (joinSimilarRows === null || joinSimilarRows === undefined) {
                throw new RequiredError('joinSimilarRows','Required parameter joinSimilarRows was null or undefined when calling generateProjectActDocumentDocx.');
            }
            // verify required parameter 'groupByCategories' is not null or undefined
            if (groupByCategories === null || groupByCategories === undefined) {
                throw new RequiredError('groupByCategories','Required parameter groupByCategories was null or undefined when calling generateProjectActDocumentDocx.');
            }
            // verify required parameter 'timezoneOffsetUTC' is not null or undefined
            if (timezoneOffsetUTC === null || timezoneOffsetUTC === undefined) {
                throw new RequiredError('timezoneOffsetUTC','Required parameter timezoneOffsetUTC was null or undefined when calling generateProjectActDocumentDocx.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/projects/{projectId}/documents/act/docx`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (categorySortBy !== undefined) {
                localVarQueryParameter['categorySortBy'] = categorySortBy;
            }

            if (categorySortOrder !== undefined) {
                localVarQueryParameter['categorySortOrder'] = categorySortOrder;
            }

            if (includeWorkPlannings !== undefined) {
                localVarQueryParameter['includeWorkPlannings'] = includeWorkPlannings;
            }

            if (includeExpenses !== undefined) {
                localVarQueryParameter['includeExpenses'] = includeExpenses;
            }

            if (t0 !== undefined) {
                localVarQueryParameter['t0'] = (t0 as any instanceof Date) ?
                    (t0 as any).toISOString() :
                    t0;
            }

            if (tN !== undefined) {
                localVarQueryParameter['tN'] = (tN as any instanceof Date) ?
                    (tN as any).toISOString() :
                    tN;
            }

            if (common !== undefined) {
                localVarQueryParameter['common'] = common;
            }

            if (documentTemplateId !== undefined) {
                localVarQueryParameter['documentTemplateId'] = documentTemplateId;
            }

            if (businessAccountLegalDetailsId !== undefined) {
                localVarQueryParameter['businessAccountLegalDetailsId'] = businessAccountLegalDetailsId;
            }

            if (expandKits !== undefined) {
                localVarQueryParameter['expandKits'] = expandKits;
            }

            if (joinSimilarRows !== undefined) {
                localVarQueryParameter['joinSimilarRows'] = joinSimilarRows;
            }

            if (groupByCategories !== undefined) {
                localVarQueryParameter['groupByCategories'] = groupByCategories;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (timezoneOffsetUTC !== undefined) {
                localVarQueryParameter['timezoneOffsetUTC'] = timezoneOffsetUTC;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Генерация акта по проекту в формат PDF
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} projectId Идентификатор проекта
         * @param {boolean} includeWorkPlannings 
         * @param {boolean} includeExpenses 
         * @param {boolean} common Флаг типа шаблона
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
         * @param {boolean} joinSimilarRows Объединять ли одинаковые строки при экспорте (для развернутых наборов не работает)
         * @param {boolean} groupByCategories Группировать по категориям
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {CategorySortOrderEnum} [categorySortBy] 
         * @param {'ASC' | 'DESC'} [categorySortOrder] 
         * @param {Date} [t0] 
         * @param {Date} [tN] 
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateProjectActDocumentPdf(businessAccountId: number, projectId: number, includeWorkPlannings: boolean, includeExpenses: boolean, common: boolean, documentTemplateId: number, expandKits: boolean, joinSimilarRows: boolean, groupByCategories: boolean, timezoneOffsetUTC: number, categorySortBy?: CategorySortOrderEnum, categorySortOrder?: 'ASC' | 'DESC', t0?: Date, tN?: Date, businessAccountLegalDetailsId?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling generateProjectActDocumentPdf.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling generateProjectActDocumentPdf.');
            }
            // verify required parameter 'includeWorkPlannings' is not null or undefined
            if (includeWorkPlannings === null || includeWorkPlannings === undefined) {
                throw new RequiredError('includeWorkPlannings','Required parameter includeWorkPlannings was null or undefined when calling generateProjectActDocumentPdf.');
            }
            // verify required parameter 'includeExpenses' is not null or undefined
            if (includeExpenses === null || includeExpenses === undefined) {
                throw new RequiredError('includeExpenses','Required parameter includeExpenses was null or undefined when calling generateProjectActDocumentPdf.');
            }
            // verify required parameter 'common' is not null or undefined
            if (common === null || common === undefined) {
                throw new RequiredError('common','Required parameter common was null or undefined when calling generateProjectActDocumentPdf.');
            }
            // verify required parameter 'documentTemplateId' is not null or undefined
            if (documentTemplateId === null || documentTemplateId === undefined) {
                throw new RequiredError('documentTemplateId','Required parameter documentTemplateId was null or undefined when calling generateProjectActDocumentPdf.');
            }
            // verify required parameter 'expandKits' is not null or undefined
            if (expandKits === null || expandKits === undefined) {
                throw new RequiredError('expandKits','Required parameter expandKits was null or undefined when calling generateProjectActDocumentPdf.');
            }
            // verify required parameter 'joinSimilarRows' is not null or undefined
            if (joinSimilarRows === null || joinSimilarRows === undefined) {
                throw new RequiredError('joinSimilarRows','Required parameter joinSimilarRows was null or undefined when calling generateProjectActDocumentPdf.');
            }
            // verify required parameter 'groupByCategories' is not null or undefined
            if (groupByCategories === null || groupByCategories === undefined) {
                throw new RequiredError('groupByCategories','Required parameter groupByCategories was null or undefined when calling generateProjectActDocumentPdf.');
            }
            // verify required parameter 'timezoneOffsetUTC' is not null or undefined
            if (timezoneOffsetUTC === null || timezoneOffsetUTC === undefined) {
                throw new RequiredError('timezoneOffsetUTC','Required parameter timezoneOffsetUTC was null or undefined when calling generateProjectActDocumentPdf.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/projects/{projectId}/documents/act/pdf`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (categorySortBy !== undefined) {
                localVarQueryParameter['categorySortBy'] = categorySortBy;
            }

            if (categorySortOrder !== undefined) {
                localVarQueryParameter['categorySortOrder'] = categorySortOrder;
            }

            if (includeWorkPlannings !== undefined) {
                localVarQueryParameter['includeWorkPlannings'] = includeWorkPlannings;
            }

            if (includeExpenses !== undefined) {
                localVarQueryParameter['includeExpenses'] = includeExpenses;
            }

            if (t0 !== undefined) {
                localVarQueryParameter['t0'] = (t0 as any instanceof Date) ?
                    (t0 as any).toISOString() :
                    t0;
            }

            if (tN !== undefined) {
                localVarQueryParameter['tN'] = (tN as any instanceof Date) ?
                    (tN as any).toISOString() :
                    tN;
            }

            if (common !== undefined) {
                localVarQueryParameter['common'] = common;
            }

            if (documentTemplateId !== undefined) {
                localVarQueryParameter['documentTemplateId'] = documentTemplateId;
            }

            if (businessAccountLegalDetailsId !== undefined) {
                localVarQueryParameter['businessAccountLegalDetailsId'] = businessAccountLegalDetailsId;
            }

            if (expandKits !== undefined) {
                localVarQueryParameter['expandKits'] = expandKits;
            }

            if (joinSimilarRows !== undefined) {
                localVarQueryParameter['joinSimilarRows'] = joinSimilarRows;
            }

            if (groupByCategories !== undefined) {
                localVarQueryParameter['groupByCategories'] = groupByCategories;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (timezoneOffsetUTC !== undefined) {
                localVarQueryParameter['timezoneOffsetUTC'] = timezoneOffsetUTC;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Генерация документа сметы проекта в формат PDF
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} projectId Идентификатор проекта
         * @param {boolean} includeWorkPlannings 
         * @param {boolean} includeExpenses 
         * @param {boolean} common Флаг типа шаблона
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
         * @param {boolean} joinSimilarRows Объединять ли одинаковые строки при экспорте (для развернутых наборов не работает)
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {CategorySortOrderEnum} [categorySortBy] 
         * @param {'ASC' | 'DESC'} [categorySortOrder] 
         * @param {Date} [t0] 
         * @param {Date} [tN] 
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateProjectEstimateDocumentPdf(businessAccountId: number, projectId: number, includeWorkPlannings: boolean, includeExpenses: boolean, common: boolean, documentTemplateId: number, expandKits: boolean, joinSimilarRows: boolean, timezoneOffsetUTC: number, categorySortBy?: CategorySortOrderEnum, categorySortOrder?: 'ASC' | 'DESC', t0?: Date, tN?: Date, businessAccountLegalDetailsId?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling generateProjectEstimateDocumentPdf.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling generateProjectEstimateDocumentPdf.');
            }
            // verify required parameter 'includeWorkPlannings' is not null or undefined
            if (includeWorkPlannings === null || includeWorkPlannings === undefined) {
                throw new RequiredError('includeWorkPlannings','Required parameter includeWorkPlannings was null or undefined when calling generateProjectEstimateDocumentPdf.');
            }
            // verify required parameter 'includeExpenses' is not null or undefined
            if (includeExpenses === null || includeExpenses === undefined) {
                throw new RequiredError('includeExpenses','Required parameter includeExpenses was null or undefined when calling generateProjectEstimateDocumentPdf.');
            }
            // verify required parameter 'common' is not null or undefined
            if (common === null || common === undefined) {
                throw new RequiredError('common','Required parameter common was null or undefined when calling generateProjectEstimateDocumentPdf.');
            }
            // verify required parameter 'documentTemplateId' is not null or undefined
            if (documentTemplateId === null || documentTemplateId === undefined) {
                throw new RequiredError('documentTemplateId','Required parameter documentTemplateId was null or undefined when calling generateProjectEstimateDocumentPdf.');
            }
            // verify required parameter 'expandKits' is not null or undefined
            if (expandKits === null || expandKits === undefined) {
                throw new RequiredError('expandKits','Required parameter expandKits was null or undefined when calling generateProjectEstimateDocumentPdf.');
            }
            // verify required parameter 'joinSimilarRows' is not null or undefined
            if (joinSimilarRows === null || joinSimilarRows === undefined) {
                throw new RequiredError('joinSimilarRows','Required parameter joinSimilarRows was null or undefined when calling generateProjectEstimateDocumentPdf.');
            }
            // verify required parameter 'timezoneOffsetUTC' is not null or undefined
            if (timezoneOffsetUTC === null || timezoneOffsetUTC === undefined) {
                throw new RequiredError('timezoneOffsetUTC','Required parameter timezoneOffsetUTC was null or undefined when calling generateProjectEstimateDocumentPdf.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/projects/{projectId}/documents/estimate/pdf`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (categorySortBy !== undefined) {
                localVarQueryParameter['categorySortBy'] = categorySortBy;
            }

            if (categorySortOrder !== undefined) {
                localVarQueryParameter['categorySortOrder'] = categorySortOrder;
            }

            if (includeWorkPlannings !== undefined) {
                localVarQueryParameter['includeWorkPlannings'] = includeWorkPlannings;
            }

            if (includeExpenses !== undefined) {
                localVarQueryParameter['includeExpenses'] = includeExpenses;
            }

            if (t0 !== undefined) {
                localVarQueryParameter['t0'] = (t0 as any instanceof Date) ?
                    (t0 as any).toISOString() :
                    t0;
            }

            if (tN !== undefined) {
                localVarQueryParameter['tN'] = (tN as any instanceof Date) ?
                    (tN as any).toISOString() :
                    tN;
            }

            if (common !== undefined) {
                localVarQueryParameter['common'] = common;
            }

            if (documentTemplateId !== undefined) {
                localVarQueryParameter['documentTemplateId'] = documentTemplateId;
            }

            if (businessAccountLegalDetailsId !== undefined) {
                localVarQueryParameter['businessAccountLegalDetailsId'] = businessAccountLegalDetailsId;
            }

            if (expandKits !== undefined) {
                localVarQueryParameter['expandKits'] = expandKits;
            }

            if (joinSimilarRows !== undefined) {
                localVarQueryParameter['joinSimilarRows'] = joinSimilarRows;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (timezoneOffsetUTC !== undefined) {
                localVarQueryParameter['timezoneOffsetUTC'] = timezoneOffsetUTC;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Генерация документа сметы проекта в формат XLSX
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} projectId Идентификатор проекта
         * @param {boolean} includeWorkPlannings 
         * @param {boolean} includeExpenses 
         * @param {boolean} common Флаг типа шаблона
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
         * @param {boolean} joinSimilarRows Объединять ли одинаковые строки при экспорте (для развернутых наборов не работает)
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {CategorySortOrderEnum} [categorySortBy] 
         * @param {'ASC' | 'DESC'} [categorySortOrder] 
         * @param {Date} [t0] 
         * @param {Date} [tN] 
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateProjectEstimateDocumentXlsx(businessAccountId: number, projectId: number, includeWorkPlannings: boolean, includeExpenses: boolean, common: boolean, documentTemplateId: number, expandKits: boolean, joinSimilarRows: boolean, timezoneOffsetUTC: number, categorySortBy?: CategorySortOrderEnum, categorySortOrder?: 'ASC' | 'DESC', t0?: Date, tN?: Date, businessAccountLegalDetailsId?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling generateProjectEstimateDocumentXlsx.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling generateProjectEstimateDocumentXlsx.');
            }
            // verify required parameter 'includeWorkPlannings' is not null or undefined
            if (includeWorkPlannings === null || includeWorkPlannings === undefined) {
                throw new RequiredError('includeWorkPlannings','Required parameter includeWorkPlannings was null or undefined when calling generateProjectEstimateDocumentXlsx.');
            }
            // verify required parameter 'includeExpenses' is not null or undefined
            if (includeExpenses === null || includeExpenses === undefined) {
                throw new RequiredError('includeExpenses','Required parameter includeExpenses was null or undefined when calling generateProjectEstimateDocumentXlsx.');
            }
            // verify required parameter 'common' is not null or undefined
            if (common === null || common === undefined) {
                throw new RequiredError('common','Required parameter common was null or undefined when calling generateProjectEstimateDocumentXlsx.');
            }
            // verify required parameter 'documentTemplateId' is not null or undefined
            if (documentTemplateId === null || documentTemplateId === undefined) {
                throw new RequiredError('documentTemplateId','Required parameter documentTemplateId was null or undefined when calling generateProjectEstimateDocumentXlsx.');
            }
            // verify required parameter 'expandKits' is not null or undefined
            if (expandKits === null || expandKits === undefined) {
                throw new RequiredError('expandKits','Required parameter expandKits was null or undefined when calling generateProjectEstimateDocumentXlsx.');
            }
            // verify required parameter 'joinSimilarRows' is not null or undefined
            if (joinSimilarRows === null || joinSimilarRows === undefined) {
                throw new RequiredError('joinSimilarRows','Required parameter joinSimilarRows was null or undefined when calling generateProjectEstimateDocumentXlsx.');
            }
            // verify required parameter 'timezoneOffsetUTC' is not null or undefined
            if (timezoneOffsetUTC === null || timezoneOffsetUTC === undefined) {
                throw new RequiredError('timezoneOffsetUTC','Required parameter timezoneOffsetUTC was null or undefined when calling generateProjectEstimateDocumentXlsx.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/projects/{projectId}/documents/estimate/xlsx`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (categorySortBy !== undefined) {
                localVarQueryParameter['categorySortBy'] = categorySortBy;
            }

            if (categorySortOrder !== undefined) {
                localVarQueryParameter['categorySortOrder'] = categorySortOrder;
            }

            if (includeWorkPlannings !== undefined) {
                localVarQueryParameter['includeWorkPlannings'] = includeWorkPlannings;
            }

            if (includeExpenses !== undefined) {
                localVarQueryParameter['includeExpenses'] = includeExpenses;
            }

            if (t0 !== undefined) {
                localVarQueryParameter['t0'] = (t0 as any instanceof Date) ?
                    (t0 as any).toISOString() :
                    t0;
            }

            if (tN !== undefined) {
                localVarQueryParameter['tN'] = (tN as any instanceof Date) ?
                    (tN as any).toISOString() :
                    tN;
            }

            if (common !== undefined) {
                localVarQueryParameter['common'] = common;
            }

            if (documentTemplateId !== undefined) {
                localVarQueryParameter['documentTemplateId'] = documentTemplateId;
            }

            if (businessAccountLegalDetailsId !== undefined) {
                localVarQueryParameter['businessAccountLegalDetailsId'] = businessAccountLegalDetailsId;
            }

            if (expandKits !== undefined) {
                localVarQueryParameter['expandKits'] = expandKits;
            }

            if (joinSimilarRows !== undefined) {
                localVarQueryParameter['joinSimilarRows'] = joinSimilarRows;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (timezoneOffsetUTC !== undefined) {
                localVarQueryParameter['timezoneOffsetUTC'] = timezoneOffsetUTC;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Генерация отчета
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {ReportInputParameters} reportInputParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateReport(businessAccountId: number, id: number, reportInputParameters: ReportInputParameters, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling generateReport.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling generateReport.');
            }
            // verify required parameter 'reportInputParameters' is not null or undefined
            if (reportInputParameters === null || reportInputParameters === undefined) {
                throw new RequiredError('reportInputParameters','Required parameter reportInputParameters was null or undefined when calling generateReport.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/reportConfigurations/{id}/json`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof reportInputParameters !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(reportInputParameters !== undefined ? reportInputParameters : {}) : (reportInputParameters || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Генерация представления отчета в формат PDF
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {ExcelReportGenerationInfo} excelReportGenerationInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateReportPdf(businessAccountId: number, id: number, timezoneOffsetUTC: number, excelReportGenerationInfo: ExcelReportGenerationInfo, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling generateReportPdf.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling generateReportPdf.');
            }
            // verify required parameter 'timezoneOffsetUTC' is not null or undefined
            if (timezoneOffsetUTC === null || timezoneOffsetUTC === undefined) {
                throw new RequiredError('timezoneOffsetUTC','Required parameter timezoneOffsetUTC was null or undefined when calling generateReportPdf.');
            }
            // verify required parameter 'excelReportGenerationInfo' is not null or undefined
            if (excelReportGenerationInfo === null || excelReportGenerationInfo === undefined) {
                throw new RequiredError('excelReportGenerationInfo','Required parameter excelReportGenerationInfo was null or undefined when calling generateReportPdf.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/reportExcelVisualizations/{id}/pdf`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (timezoneOffsetUTC !== undefined) {
                localVarQueryParameter['timezoneOffsetUTC'] = timezoneOffsetUTC;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof excelReportGenerationInfo !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(excelReportGenerationInfo !== undefined ? excelReportGenerationInfo : {}) : (excelReportGenerationInfo || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Генерация представления отчета в формат XLSX
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {ExcelReportGenerationInfo} excelReportGenerationInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateReportXlsx(businessAccountId: number, id: number, timezoneOffsetUTC: number, excelReportGenerationInfo: ExcelReportGenerationInfo, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling generateReportXlsx.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling generateReportXlsx.');
            }
            // verify required parameter 'timezoneOffsetUTC' is not null or undefined
            if (timezoneOffsetUTC === null || timezoneOffsetUTC === undefined) {
                throw new RequiredError('timezoneOffsetUTC','Required parameter timezoneOffsetUTC was null or undefined when calling generateReportXlsx.');
            }
            // verify required parameter 'excelReportGenerationInfo' is not null or undefined
            if (excelReportGenerationInfo === null || excelReportGenerationInfo === undefined) {
                throw new RequiredError('excelReportGenerationInfo','Required parameter excelReportGenerationInfo was null or undefined when calling generateReportXlsx.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/reportExcelVisualizations/{id}/xlsx`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (timezoneOffsetUTC !== undefined) {
                localVarQueryParameter['timezoneOffsetUTC'] = timezoneOffsetUTC;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof excelReportGenerationInfo !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(excelReportGenerationInfo !== undefined ? excelReportGenerationInfo : {}) : (excelReportGenerationInfo || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Запрос на генерацию нового токена доступа к апи витрины
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateStorefrontAccessToken(businessAccountId: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling generateStorefrontAccessToken.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/preferences/generateStorefrontAccessToken`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение информации о вложении
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttachmentById(businessAccountId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getAttachmentById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getAttachmentById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/attachments/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка реквизитов по стране
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {LegalCountryCodeEnum} legalCountryTypeCode Код страны в юридическом контексте
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBALegalDetailsFieldsListByCountry(businessAccountId: number, legalCountryTypeCode: LegalCountryCodeEnum, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getBALegalDetailsFieldsListByCountry.');
            }
            // verify required parameter 'legalCountryTypeCode' is not null or undefined
            if (legalCountryTypeCode === null || legalCountryTypeCode === undefined) {
                throw new RequiredError('legalCountryTypeCode','Required parameter legalCountryTypeCode was null or undefined when calling getBALegalDetailsFieldsListByCountry.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/legalDetailsFields`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (legalCountryTypeCode !== undefined) {
                localVarQueryParameter['legalCountryTypeCode'] = legalCountryTypeCode;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение информации о компании
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} accessToken Обязательный токен доступа в формате UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessAccount(businessAccountId: number, accessToken: string, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getBusinessAccount.');
            }
            // verify required parameter 'accessToken' is not null or undefined
            if (accessToken === null || accessToken === undefined) {
                throw new RequiredError('accessToken','Required parameter accessToken was null or undefined when calling getBusinessAccount.');
            }
            const localVarPath = `/public/v1/server/businessAccounts/{businessAccountId}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (accessToken !== undefined) {
                localVarQueryParameter['accessToken'] = accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение состояния бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessAccountById(businessAccountId: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getBusinessAccountById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение контактных данных бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessAccountContactsById(businessAccountId: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getBusinessAccountContactsById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/contacts-information`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Данные дешборда бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessAccountDashboardData(businessAccountId: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getBusinessAccountDashboardData.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/dashboard`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение индикаторов бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessAccountIndicators(businessAccountId: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getBusinessAccountIndicators.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/indicators`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список ссылок на статьи базы знаний с названиями в текущей локали
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} interfaceBlockCode Код блока интерфейся для которого требуется выгрузить статьи
         * @param {LocaleCodeEnum} locale Локаль для которой требуется выгрузить статьи
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessAccountKnowledgeBaseArticleLinkList(businessAccountId: number, interfaceBlockCode: string, locale: LocaleCodeEnum, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getBusinessAccountKnowledgeBaseArticleLinkList.');
            }
            // verify required parameter 'interfaceBlockCode' is not null or undefined
            if (interfaceBlockCode === null || interfaceBlockCode === undefined) {
                throw new RequiredError('interfaceBlockCode','Required parameter interfaceBlockCode was null or undefined when calling getBusinessAccountKnowledgeBaseArticleLinkList.');
            }
            // verify required parameter 'locale' is not null or undefined
            if (locale === null || locale === undefined) {
                throw new RequiredError('locale','Required parameter locale was null or undefined when calling getBusinessAccountKnowledgeBaseArticleLinkList.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/knowledge-base/articles`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (interfaceBlockCode !== undefined) {
                localVarQueryParameter['interfaceBlockCode'] = interfaceBlockCode;
            }

            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение основных настроек бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessAccountPreferences(businessAccountId: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getBusinessAccountPreferences.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/preferences`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение состояния категории
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoryById(businessAccountId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getCategoryById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getCategoryById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/categories/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение состояния стандартного шаблона документа
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommonDocumentTemplateById(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getCommonDocumentTemplateById.');
            }
            const localVarPath = `/global/documentTemplates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение контента стандартного шаблона документа
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommonDocumentTemplateContentById(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getCommonDocumentTemplateContentById.');
            }
            const localVarPath = `/global/documentTemplates/{id}/content`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение контента стандатного шаблона документа
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommonDocumentTemplateContentFromBusinessAccountById(businessAccountId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getCommonDocumentTemplateContentFromBusinessAccountById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getCommonDocumentTemplateContentFromBusinessAccountById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/commonDocumentTemplates/{id}/content`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение состояния контакта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactById(businessAccountId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getContactById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getContactById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/contacts/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение кадра
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCrewMember(businessAccountId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getCrewMember.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getCrewMember.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/crew-members/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение состояния настроек уведомлений текущего пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentNotificationSchema(options: any = {}): RequestArgs {
            const localVarPath = `/currentProfile/notificationSchema`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение состояния текущего пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentProfile(options: any = {}): RequestArgs {
            const localVarPath = `/currentProfile`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Информация о текущем клиенте в бизнес аккаунте. Необходим JWT токен пользователя
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentRenterInfo(businessAccountId: number, storefrontId: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getCurrentRenterInfo.');
            }
            // verify required parameter 'storefrontId' is not null or undefined
            if (storefrontId === null || storefrontId === undefined) {
                throw new RequiredError('storefrontId','Required parameter storefrontId was null or undefined when calling getCurrentRenterInfo.');
            }
            const localVarPath = `/public/v1/client/businessAccounts/{businessAccountId}/storefronts/{storefrontId}/renters/current`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"storefrontId"}}`, encodeURIComponent(String(storefrontId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение прав текущего пользователя. Права в бизнес аккаунте передаются при передаче параметра businessAccountId
         * @param {number} [businessAccountId] Идентификатор бизнес аккаунта, необязательный
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentRolesAndPermissions(businessAccountId?: number, options: any = {}): RequestArgs {
            const localVarPath = `/currentProfile/rolesAndPermissions`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (businessAccountId !== undefined) {
                localVarQueryParameter['businessAccountId'] = businessAccountId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение состояния пользовательского поля
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomFieldById(businessAccountId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getCustomFieldById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getCustomFieldById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/customFields/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение всех пользовательских полей по типу сущности
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {string} nomenclatureType Тип номенклатуры для печати
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomFieldsByEntityType(businessAccountId: number, id: number, nomenclatureType: string, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getCustomFieldsByEntityType.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getCustomFieldsByEntityType.');
            }
            // verify required parameter 'nomenclatureType' is not null or undefined
            if (nomenclatureType === null || nomenclatureType === undefined) {
                throw new RequiredError('nomenclatureType','Required parameter nomenclatureType was null or undefined when calling getCustomFieldsByEntityType.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/customFieldsGlobal/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (nomenclatureType !== undefined) {
                localVarQueryParameter['nomenclatureType'] = nomenclatureType;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение состояния шаблона документа
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentTemplateById(businessAccountId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getDocumentTemplateById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getDocumentTemplateById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/documentTemplates/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение контента пользовательского шаблона документа
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentTemplateContentById(businessAccountId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getDocumentTemplateContentById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getDocumentTemplateContentById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/documentTemplates/{id}/content`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение данных сущности по штриховому коду
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} barcodeString Строка прочитанного штрихового кода
         * @param {boolean} [includeEntityInfoRead] Флаг для включения полной информации по сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityByBarcode(businessAccountId: number, barcodeString: string, includeEntityInfoRead?: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getEntityByBarcode.');
            }
            // verify required parameter 'barcodeString' is not null or undefined
            if (barcodeString === null || barcodeString === undefined) {
                throw new RequiredError('barcodeString','Required parameter barcodeString was null or undefined when calling getEntityByBarcode.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/barcode/{barcodeString}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"barcodeString"}}`, encodeURIComponent(String(barcodeString)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (includeEntityInfoRead !== undefined) {
                localVarQueryParameter['includeEntityInfoRead'] = includeEntityInfoRead;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Получение сущности затраты по проекту
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExpense(businessAccountId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getExpense.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getExpense.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/expense/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение сгенерированного мнемонического ключа
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {'renter' | 'variant' | 'instance' | 'project' | 'subrent' | 'project_operation' | 'subrent_operation' | 'template_operation'} type Тип сущности
         * @param {'mnemoKey'} fieldName Наименование поля
         * @param {number} [parentId] Идентификатор родительской сущности
         * @param {string} [baseString] Короткое наименование/тип сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneratedField(businessAccountId: number, type: 'renter' | 'variant' | 'instance' | 'project' | 'subrent' | 'project_operation' | 'subrent_operation' | 'template_operation', fieldName: 'mnemoKey', parentId?: number, baseString?: string, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getGeneratedField.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getGeneratedField.');
            }
            // verify required parameter 'fieldName' is not null or undefined
            if (fieldName === null || fieldName === undefined) {
                throw new RequiredError('fieldName','Required parameter fieldName was null or undefined when calling getGeneratedField.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/fields/generate/{type}/{fieldName}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"fieldName"}}`, encodeURIComponent(String(fieldName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (parentId !== undefined) {
                localVarQueryParameter['parentId'] = parentId;
            }

            if (baseString !== undefined) {
                localVarQueryParameter['baseString'] = baseString;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get information to visualize
         * @param {number} gridCellIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGridCellData(gridCellIdentifier: number, options: any = {}): RequestArgs {
            // verify required parameter 'gridCellIdentifier' is not null or undefined
            if (gridCellIdentifier === null || gridCellIdentifier === undefined) {
                throw new RequiredError('gridCellIdentifier','Required parameter gridCellIdentifier was null or undefined when calling getGridCellData.');
            }
            const localVarPath = `/uncharted/info/{gridCellIdentifier}`
                .replace(`{${"gridCellIdentifier"}}`, encodeURIComponent(String(gridCellIdentifier)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Operation to get info about grid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInformationAboutGrid(options: any = {}): RequestArgs {
            const localVarPath = `/uncharted/info`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение состояния экземпляра
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstanceById(businessAccountId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getInstanceById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getInstanceById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/instances/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение информации о событии движения инвентаря
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInventoryMovementById(businessAccountId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getInventoryMovementById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getInventoryMovementById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/inventory-movements/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение описания приглашения
         * @param {string} token Код активации приглашения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvitationByToken(token: string, options: any = {}): RequestArgs {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getInvitationByToken.');
            }
            const localVarPath = `/invitations/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение состояния набора
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKitById(businessAccountId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getKitById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getKitById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/kits/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение карты состояния набора (только доступность и инвентарное количество)
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {boolean} captureAll Маркер обозначающий, что нужно показать весь возможный период
         * @param {TimetableTypeCodeEnum} typeCode Тип искомого стека состояния (только доступность и инвентарное количество)
         * @param {number} [from] Начало целевого интервала времени, epoch millis
         * @param {number} [until] Конец целевого интервала времени, epoch millis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKitTimetableById(businessAccountId: number, id: number, captureAll: boolean, typeCode: TimetableTypeCodeEnum, from?: number, until?: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getKitTimetableById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getKitTimetableById.');
            }
            // verify required parameter 'captureAll' is not null or undefined
            if (captureAll === null || captureAll === undefined) {
                throw new RequiredError('captureAll','Required parameter captureAll was null or undefined when calling getKitTimetableById.');
            }
            // verify required parameter 'typeCode' is not null or undefined
            if (typeCode === null || typeCode === undefined) {
                throw new RequiredError('typeCode','Required parameter typeCode was null or undefined when calling getKitTimetableById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/kits/{id}/map`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = until;
            }

            if (captureAll !== undefined) {
                localVarQueryParameter['captureAll'] = captureAll;
            }

            if (typeCode !== undefined) {
                localVarQueryParameter['typeCode'] = typeCode;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Массовое получение состояний набора
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {Array<number>} ids Идентификаторы набора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKitsByIdsBulk(businessAccountId: number, ids: Array<number>, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getKitsByIdsBulk.');
            }
            // verify required parameter 'ids' is not null or undefined
            if (ids === null || ids === undefined) {
                throw new RequiredError('ids','Required parameter ids was null or undefined when calling getKitsByIdsBulk.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/kits/bulk`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение состояния объекта субъектов и реквизитов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLegalDetailById(businessAccountId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getLegalDetailById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getLegalDetailById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/legal-details/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение состояния объектов субъектов и реквизитов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLegalDetails(businessAccountId: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getLegalDetails.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/legal-details`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationById(businessAccountId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getLocationById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getLocationById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/locations/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение данных сущности номенклатуры для формы операции по штриховому коду
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} barcodeString Строка прочитанного штрихового кода
         * @param {boolean} [includeEntityInfoRead] Флаг для включения полной информации по сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNomenclatureInOperationFormByBarcode(businessAccountId: number, barcodeString: string, includeEntityInfoRead?: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getNomenclatureInOperationFormByBarcode.');
            }
            // verify required parameter 'barcodeString' is not null or undefined
            if (barcodeString === null || barcodeString === undefined) {
                throw new RequiredError('barcodeString','Required parameter barcodeString was null or undefined when calling getNomenclatureInOperationFormByBarcode.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/barcode/{barcodeString}/nomenclatureInOperationForm`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"barcodeString"}}`, encodeURIComponent(String(barcodeString)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (includeEntityInfoRead !== undefined) {
                localVarQueryParameter['includeEntityInfoRead'] = includeEntityInfoRead;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение состояния операции
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperationById(businessAccountId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getOperationById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOperationById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/operations/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка аудитов аренды состава операции
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperationComposition(businessAccountId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getOperationComposition.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOperationComposition.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/operations/{id}/composition`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение платежа
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentById(businessAccountId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getPaymentById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPaymentById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/payments/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение состояния способа оплаты
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentMethodById(businessAccountId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getPaymentMethodById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPaymentMethodById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/payment-methods/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение состояния ценовой схемы
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPricingSchemeById(businessAccountId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getPricingSchemeById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPricingSchemeById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/pricingSchemes/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение состояния продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductById(businessAccountId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getProductById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getProductById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/products/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение состояния экземпляра продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} productId Идентификатор продукта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductInstanceById(businessAccountId: number, productId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getProductInstanceById.');
            }
            // verify required parameter 'productId' is not null or undefined
            if (productId === null || productId === undefined) {
                throw new RequiredError('productId','Required parameter productId was null or undefined when calling getProductInstanceById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getProductInstanceById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/products/{productId}/instances/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение карты состояния экземпляра продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} productId Идентификатор продукта
         * @param {number} id Идентификатор сущности
         * @param {boolean} captureAll Маркер обозначающий, что нужно показать весь возможный период
         * @param {TimetableTypeCodeEnum} typeCode Тип искомого стека состояния (только доступность и инвентарное количество)
         * @param {number} [from] Начало целевого интервала времени, epoch millis
         * @param {number} [until] Конец целевого интервала времени, epoch millis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductInstanceTimetableById(businessAccountId: number, productId: number, id: number, captureAll: boolean, typeCode: TimetableTypeCodeEnum, from?: number, until?: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getProductInstanceTimetableById.');
            }
            // verify required parameter 'productId' is not null or undefined
            if (productId === null || productId === undefined) {
                throw new RequiredError('productId','Required parameter productId was null or undefined when calling getProductInstanceTimetableById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getProductInstanceTimetableById.');
            }
            // verify required parameter 'captureAll' is not null or undefined
            if (captureAll === null || captureAll === undefined) {
                throw new RequiredError('captureAll','Required parameter captureAll was null or undefined when calling getProductInstanceTimetableById.');
            }
            // verify required parameter 'typeCode' is not null or undefined
            if (typeCode === null || typeCode === undefined) {
                throw new RequiredError('typeCode','Required parameter typeCode was null or undefined when calling getProductInstanceTimetableById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/products/{productId}/instances/{id}/map`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = until;
            }

            if (captureAll !== undefined) {
                localVarQueryParameter['captureAll'] = captureAll;
            }

            if (typeCode !== undefined) {
                localVarQueryParameter['typeCode'] = typeCode;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение карты состояния продукта (доступность, заявка и прочее)
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {boolean} captureAll Маркер обозначающий, что нужно показать весь возможный период
         * @param {TimetableTypeCodeEnum} typeCode Тип искомого стека состояния (только доступность и инвентарное количество)
         * @param {number} [from] Начало целевого интервала времени, epoch millis
         * @param {number} [until] Конец целевого интервала времени, epoch millis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductTimetableById(businessAccountId: number, id: number, captureAll: boolean, typeCode: TimetableTypeCodeEnum, from?: number, until?: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getProductTimetableById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getProductTimetableById.');
            }
            // verify required parameter 'captureAll' is not null or undefined
            if (captureAll === null || captureAll === undefined) {
                throw new RequiredError('captureAll','Required parameter captureAll was null or undefined when calling getProductTimetableById.');
            }
            // verify required parameter 'typeCode' is not null or undefined
            if (typeCode === null || typeCode === undefined) {
                throw new RequiredError('typeCode','Required parameter typeCode was null or undefined when calling getProductTimetableById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/products/{id}/map`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = until;
            }

            if (captureAll !== undefined) {
                localVarQueryParameter['captureAll'] = captureAll;
            }

            if (typeCode !== undefined) {
                localVarQueryParameter['typeCode'] = typeCode;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение состояния варианта продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} productId Идентификатор продукта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductVariantById(businessAccountId: number, productId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getProductVariantById.');
            }
            // verify required parameter 'productId' is not null or undefined
            if (productId === null || productId === undefined) {
                throw new RequiredError('productId','Required parameter productId was null or undefined when calling getProductVariantById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getProductVariantById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/products/{productId}/variants/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение карты состояния экземпляра варианта продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} productId Идентификатор продукта
         * @param {number} variantId Идентификатор варианта
         * @param {number} id Идентификатор сущности
         * @param {boolean} captureAll Маркер обозначающий, что нужно показать весь возможный период
         * @param {TimetableTypeCodeEnum} typeCode Тип искомого стека состояния (только доступность и инвентарное количество)
         * @param {number} [from] Начало целевого интервала времени, epoch millis
         * @param {number} [until] Конец целевого интервала времени, epoch millis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductVariantInstanceTimetableById(businessAccountId: number, productId: number, variantId: number, id: number, captureAll: boolean, typeCode: TimetableTypeCodeEnum, from?: number, until?: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getProductVariantInstanceTimetableById.');
            }
            // verify required parameter 'productId' is not null or undefined
            if (productId === null || productId === undefined) {
                throw new RequiredError('productId','Required parameter productId was null or undefined when calling getProductVariantInstanceTimetableById.');
            }
            // verify required parameter 'variantId' is not null or undefined
            if (variantId === null || variantId === undefined) {
                throw new RequiredError('variantId','Required parameter variantId was null or undefined when calling getProductVariantInstanceTimetableById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getProductVariantInstanceTimetableById.');
            }
            // verify required parameter 'captureAll' is not null or undefined
            if (captureAll === null || captureAll === undefined) {
                throw new RequiredError('captureAll','Required parameter captureAll was null or undefined when calling getProductVariantInstanceTimetableById.');
            }
            // verify required parameter 'typeCode' is not null or undefined
            if (typeCode === null || typeCode === undefined) {
                throw new RequiredError('typeCode','Required parameter typeCode was null or undefined when calling getProductVariantInstanceTimetableById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/products/{productId}/variants/{variantId}/instances/{id}/map`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)))
                .replace(`{${"variantId"}}`, encodeURIComponent(String(variantId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = until;
            }

            if (captureAll !== undefined) {
                localVarQueryParameter['captureAll'] = captureAll;
            }

            if (typeCode !== undefined) {
                localVarQueryParameter['typeCode'] = typeCode;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение профессии
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfessionById(businessAccountId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getProfessionById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getProfessionById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/professions/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение состояния проекта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectById(businessAccountId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getProjectById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getProjectById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/projects/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Запрос информации элемента аренды
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRentElementById(businessAccountId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getRentElementById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getRentElementById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/elements/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Запрос истории элемента аренды
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRentElementHistory(businessAccountId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getRentElementHistory.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getRentElementHistory.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/elements/{id}/history`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение состояния арендатора
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRenterById(businessAccountId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getRenterById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getRenterById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/renters/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение данных о состоянии отдельного графического представления отчёта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportChartVisualizationById(businessAccountId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getReportChartVisualizationById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getReportChartVisualizationById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/reportChartVisualization/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение состояния конфигурации отчета
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportConfigurationById(businessAccountId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getReportConfigurationById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getReportConfigurationById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/reportConfigurations/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение изображения логотипа компании клиента при SSO авторизации
         * @param {string} ssoClientOrganizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSsoAuthorizationClientLogo(ssoClientOrganizationId: string, options: any = {}): RequestArgs {
            // verify required parameter 'ssoClientOrganizationId' is not null or undefined
            if (ssoClientOrganizationId === null || ssoClientOrganizationId === undefined) {
                throw new RequiredError('ssoClientOrganizationId','Required parameter ssoClientOrganizationId was null or undefined when calling getSsoAuthorizationClientLogo.');
            }
            const localVarPath = `/images/ssoAuthorizationClientLogo/{ssoClientOrganizationId}`
                .replace(`{${"ssoClientOrganizationId"}}`, encodeURIComponent(String(ssoClientOrganizationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение информации об организации в системе SSO авторизации для бизнес аккаунта
         * @param {string} accessToken Обязательный токен доступа в формате UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSsoClientOrganization(accessToken: string, options: any = {}): RequestArgs {
            // verify required parameter 'accessToken' is not null or undefined
            if (accessToken === null || accessToken === undefined) {
                throw new RequiredError('accessToken','Required parameter accessToken was null or undefined when calling getSsoClientOrganization.');
            }
            const localVarPath = `/public/v1/server/ssoClientOrganizations`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (accessToken !== undefined) {
                localVarQueryParameter['accessToken'] = accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение информации о витрине
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} accessToken Обязательный токен доступа в формате UUID
         * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
         * @param {Array<number>} [baseCategoryIds] Идентификаторы базовых категорий, только они и их подкатегории попадут в результат
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStorefront(businessAccountId: number, accessToken: string, storefrontId: number, baseCategoryIds?: Array<number>, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getStorefront.');
            }
            // verify required parameter 'accessToken' is not null or undefined
            if (accessToken === null || accessToken === undefined) {
                throw new RequiredError('accessToken','Required parameter accessToken was null or undefined when calling getStorefront.');
            }
            // verify required parameter 'storefrontId' is not null or undefined
            if (storefrontId === null || storefrontId === undefined) {
                throw new RequiredError('storefrontId','Required parameter storefrontId was null or undefined when calling getStorefront.');
            }
            const localVarPath = `/public/v1/server/businessAccounts/{businessAccountId}/storefronts/{storefrontId}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"storefrontId"}}`, encodeURIComponent(String(storefrontId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (accessToken !== undefined) {
                localVarQueryParameter['accessToken'] = accessToken;
            }

            if (baseCategoryIds) {
                localVarQueryParameter['baseCategoryIds'] = baseCategoryIds;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение доступности торг. предложения
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} accessToken Обязательный токен доступа в формате UUID
         * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
         * @param {number} offerId Идентификатор торг. предложения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStorefrontOfferAvailability(businessAccountId: number, accessToken: string, storefrontId: number, offerId: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getStorefrontOfferAvailability.');
            }
            // verify required parameter 'accessToken' is not null or undefined
            if (accessToken === null || accessToken === undefined) {
                throw new RequiredError('accessToken','Required parameter accessToken was null or undefined when calling getStorefrontOfferAvailability.');
            }
            // verify required parameter 'storefrontId' is not null or undefined
            if (storefrontId === null || storefrontId === undefined) {
                throw new RequiredError('storefrontId','Required parameter storefrontId was null or undefined when calling getStorefrontOfferAvailability.');
            }
            // verify required parameter 'offerId' is not null or undefined
            if (offerId === null || offerId === undefined) {
                throw new RequiredError('offerId','Required parameter offerId was null or undefined when calling getStorefrontOfferAvailability.');
            }
            const localVarPath = `/public/v1/server/businessAccounts/{businessAccountId}/storefronts/{storefrontId}/offers/{offerId}/availability`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"storefrontId"}}`, encodeURIComponent(String(storefrontId)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (accessToken !== undefined) {
                localVarQueryParameter['accessToken'] = accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение состояния субаренды
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubrentById(businessAccountId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getSubrentById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getSubrentById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/subrents/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение состояния системной настройки
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemOptionById(businessAccountId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getSystemOptionById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getSystemOptionById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/systemOptions/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список групп системных настроек с вложенными системными настройками
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemOptionsSectionById(businessAccountId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getSystemOptionsSectionById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getSystemOptionsSectionById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/systemOptionsSections/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение иерархии системных настроек бизнес аккаунта в виде дерева
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemOptionsTree(businessAccountId: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getSystemOptionsTree.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/systemOptionsTree`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение состояния шаблона
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplateById(businessAccountId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getTemplateById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getTemplateById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/templates/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение серверного времени
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTime(options: any = {}): RequestArgs {
            const localVarPath = `/time`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение серверного времени
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTime_2(options: any = {}): RequestArgs {
            const localVarPath = `/public/v1/server/time`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransportationById(businessAccountId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getTransportationById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getTransportationById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/transportations/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение состояния пользователя
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById(businessAccountId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getUserById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getUserById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/users/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Валидация значения поля
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {'kit' | 'product' | 'variant' | 'instance' | 'profession' | 'renter' | 'project' | 'subrent' | 'template' | 'customField' | 'user' | 'location' | 'contact' | 'vehicle' | 'transportation' | 'paymentMethod'} type Тип сущности
         * @param {string} fieldName Наименование поля
         * @param {string} value Значение поля
         * @param {number} [ignoreCurrentId] Идентификатор текущей сущности, которую нужно игнорировать, например, при проверке уникальности
         * @param {number} [parentId] Идентификатор родительской сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValidationForField(businessAccountId: number, type: 'kit' | 'product' | 'variant' | 'instance' | 'profession' | 'renter' | 'project' | 'subrent' | 'template' | 'customField' | 'user' | 'location' | 'contact' | 'vehicle' | 'transportation' | 'paymentMethod', fieldName: string, value: string, ignoreCurrentId?: number, parentId?: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getValidationForField.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getValidationForField.');
            }
            // verify required parameter 'fieldName' is not null or undefined
            if (fieldName === null || fieldName === undefined) {
                throw new RequiredError('fieldName','Required parameter fieldName was null or undefined when calling getValidationForField.');
            }
            // verify required parameter 'value' is not null or undefined
            if (value === null || value === undefined) {
                throw new RequiredError('value','Required parameter value was null or undefined when calling getValidationForField.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/fields/validate/{type}/{fieldName}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"fieldName"}}`, encodeURIComponent(String(fieldName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (ignoreCurrentId !== undefined) {
                localVarQueryParameter['ignoreCurrentId'] = ignoreCurrentId;
            }

            if (parentId !== undefined) {
                localVarQueryParameter['parentId'] = parentId;
            }

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Карты состояния варианта (доступность, заявка и прочее)
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} productId Идентификатор продукта
         * @param {number} id Идентификатор сущности
         * @param {boolean} captureAll Маркер обозначающий, что нужно показать весь возможный период
         * @param {TimetableTypeCodeEnum} typeCode Тип искомого стека состояния (только доступность и инвентарное количество)
         * @param {number} [from] Начало целевого интервала времени, epoch millis
         * @param {number} [until] Конец целевого интервала времени, epoch millis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVariantTimetableById(businessAccountId: number, productId: number, id: number, captureAll: boolean, typeCode: TimetableTypeCodeEnum, from?: number, until?: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getVariantTimetableById.');
            }
            // verify required parameter 'productId' is not null or undefined
            if (productId === null || productId === undefined) {
                throw new RequiredError('productId','Required parameter productId was null or undefined when calling getVariantTimetableById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getVariantTimetableById.');
            }
            // verify required parameter 'captureAll' is not null or undefined
            if (captureAll === null || captureAll === undefined) {
                throw new RequiredError('captureAll','Required parameter captureAll was null or undefined when calling getVariantTimetableById.');
            }
            // verify required parameter 'typeCode' is not null or undefined
            if (typeCode === null || typeCode === undefined) {
                throw new RequiredError('typeCode','Required parameter typeCode was null or undefined when calling getVariantTimetableById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/products/{productId}/variants/{id}/map`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = until;
            }

            if (captureAll !== undefined) {
                localVarQueryParameter['captureAll'] = captureAll;
            }

            if (typeCode !== undefined) {
                localVarQueryParameter['typeCode'] = typeCode;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVehicleById(businessAccountId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getVehicleById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getVehicleById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/vehicles/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение данных для графика
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {ReportInputParameters} [reportInputParameters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViewInfoOfReportChartVisualization(businessAccountId: number, id: number, reportInputParameters?: ReportInputParameters, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getViewInfoOfReportChartVisualization.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getViewInfoOfReportChartVisualization.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/reportChartVisualization/{id}/view/info`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof reportInputParameters !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(reportInputParameters !== undefined ? reportInputParameters : {}) : (reportInputParameters || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить описание работы
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkPlanning(businessAccountId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling getWorkPlanning.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getWorkPlanning.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/work-plannings/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Глобальный запрос списка бизнес аккаунтов
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalListBusinessAccounts(limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options: any = {}): RequestArgs {
            const localVarPath = `/global/businessAccounts`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Берёт из эластика
         * @summary Возвращает список шаблонов. Всех
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalListDocumentTemplates(limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options: any = {}): RequestArgs {
            const localVarPath = `/global/documentTemplates`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Массовое изменение КП - включение в стоимость и исключение из стоимости
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {boolean} include Включить или исключить true &#x3D; включить, false &#x3D; исключить
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        includeOrExcludeOfferInProjectPriceBulk(businessAccountId: number, include: boolean, versionedEntityObjList: VersionedEntityObjList, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling includeOrExcludeOfferInProjectPriceBulk.');
            }
            // verify required parameter 'include' is not null or undefined
            if (include === null || include === undefined) {
                throw new RequiredError('include','Required parameter include was null or undefined when calling includeOrExcludeOfferInProjectPriceBulk.');
            }
            // verify required parameter 'versionedEntityObjList' is not null or undefined
            if (versionedEntityObjList === null || versionedEntityObjList === undefined) {
                throw new RequiredError('versionedEntityObjList','Required parameter versionedEntityObjList was null or undefined when calling includeOrExcludeOfferInProjectPriceBulk.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/projects/includeOrExcludeInPriceBulk`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (include !== undefined) {
                localVarQueryParameter['include'] = include;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof versionedEntityObjList !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(versionedEntityObjList !== undefined ? versionedEntityObjList : {}) : (versionedEntityObjList || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Добавить пользователя в бизнес аккаунт
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {UserInviteToBaObj} userInviteToBaObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteUserToBusinessAccount(businessAccountId: number, userInviteToBaObj: UserInviteToBaObj, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling inviteUserToBusinessAccount.');
            }
            // verify required parameter 'userInviteToBaObj' is not null or undefined
            if (userInviteToBaObj === null || userInviteToBaObj === undefined) {
                throw new RequiredError('userInviteToBaObj','Required parameter userInviteToBaObj was null or undefined when calling inviteUserToBusinessAccount.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/administration/users`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof userInviteToBaObj !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(userInviteToBaObj !== undefined ? userInviteToBaObj : {}) : (userInviteToBaObj || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Массовое изменение привязки категорий
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {boolean} createLinks Создать или удалить, true &#x3D; создать, false &#x3D; удалить
         * @param {CategoryBulkLinkObj} categoryBulkLinkObj 
         * @param {boolean} [makeMainCategory] Назначить ли привязываемую категорию основной (передается только при привязке)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkCategoriesBulk(businessAccountId: number, createLinks: boolean, categoryBulkLinkObj: CategoryBulkLinkObj, makeMainCategory?: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling linkCategoriesBulk.');
            }
            // verify required parameter 'createLinks' is not null or undefined
            if (createLinks === null || createLinks === undefined) {
                throw new RequiredError('createLinks','Required parameter createLinks was null or undefined when calling linkCategoriesBulk.');
            }
            // verify required parameter 'categoryBulkLinkObj' is not null or undefined
            if (categoryBulkLinkObj === null || categoryBulkLinkObj === undefined) {
                throw new RequiredError('categoryBulkLinkObj','Required parameter categoryBulkLinkObj was null or undefined when calling linkCategoriesBulk.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/categories/link-bulk`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (createLinks !== undefined) {
                localVarQueryParameter['createLinks'] = createLinks;
            }

            if (makeMainCategory !== undefined) {
                localVarQueryParameter['makeMainCategory'] = makeMainCategory;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof categoryBulkLinkObj !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(categoryBulkLinkObj !== undefined ? categoryBulkLinkObj : {}) : (categoryBulkLinkObj || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Массовое изменение привязки пользовательских полей
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {boolean} createLinks Создать или удалить, true &#x3D; создать, false &#x3D; удалить
         * @param {boolean} assignFieldsToVariants Присвоить пользовательские поля вариантам или только продукту
         * @param {CustomFieldBulkLinkObj} customFieldBulkLinkObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkCustomFieldsBulk(businessAccountId: number, createLinks: boolean, assignFieldsToVariants: boolean, customFieldBulkLinkObj: CustomFieldBulkLinkObj, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling linkCustomFieldsBulk.');
            }
            // verify required parameter 'createLinks' is not null or undefined
            if (createLinks === null || createLinks === undefined) {
                throw new RequiredError('createLinks','Required parameter createLinks was null or undefined when calling linkCustomFieldsBulk.');
            }
            // verify required parameter 'assignFieldsToVariants' is not null or undefined
            if (assignFieldsToVariants === null || assignFieldsToVariants === undefined) {
                throw new RequiredError('assignFieldsToVariants','Required parameter assignFieldsToVariants was null or undefined when calling linkCustomFieldsBulk.');
            }
            // verify required parameter 'customFieldBulkLinkObj' is not null or undefined
            if (customFieldBulkLinkObj === null || customFieldBulkLinkObj === undefined) {
                throw new RequiredError('customFieldBulkLinkObj','Required parameter customFieldBulkLinkObj was null or undefined when calling linkCustomFieldsBulk.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/products/customFields/link-bulk`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (createLinks !== undefined) {
                localVarQueryParameter['createLinks'] = createLinks;
            }

            if (assignFieldsToVariants !== undefined) {
                localVarQueryParameter['assignFieldsToVariants'] = assignFieldsToVariants;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof customFieldBulkLinkObj !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(customFieldBulkLinkObj !== undefined ? customFieldBulkLinkObj : {}) : (customFieldBulkLinkObj || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Массовое назначение тэгов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {boolean} createLinks Создать или удалить, true &#x3D; создать, false &#x3D; удалить
         * @param {TagsBulkLinkObj} tagsBulkLinkObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkTagsBulk(businessAccountId: number, createLinks: boolean, tagsBulkLinkObj: TagsBulkLinkObj, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling linkTagsBulk.');
            }
            // verify required parameter 'createLinks' is not null or undefined
            if (createLinks === null || createLinks === undefined) {
                throw new RequiredError('createLinks','Required parameter createLinks was null or undefined when calling linkTagsBulk.');
            }
            // verify required parameter 'tagsBulkLinkObj' is not null or undefined
            if (tagsBulkLinkObj === null || tagsBulkLinkObj === undefined) {
                throw new RequiredError('tagsBulkLinkObj','Required parameter tagsBulkLinkObj was null or undefined when calling linkTagsBulk.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/tags`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (createLinks !== undefined) {
                localVarQueryParameter['createLinks'] = createLinks;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof tagsBulkLinkObj !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(tagsBulkLinkObj !== undefined ? tagsBulkLinkObj : {}) : (tagsBulkLinkObj || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка идентификаторов всех активных предложений витрины бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} accessToken Обязательный токен доступа в формате UUID
         * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
         * @param {Array<number>} [baseCategoryIds] Идентификаторы базовых категорий, только они и их подкатегории попадут в результат
         * @param {boolean} [includeProfessions] Если флаг передан и равен true, будут добавлены торг. предложения с типом профессия. В старых версиях витрины не передается, обеспечивает обратную совместимость
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActiveStorefrontOffersIds(businessAccountId: number, accessToken: string, storefrontId: number, baseCategoryIds?: Array<number>, includeProfessions?: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listActiveStorefrontOffersIds.');
            }
            // verify required parameter 'accessToken' is not null or undefined
            if (accessToken === null || accessToken === undefined) {
                throw new RequiredError('accessToken','Required parameter accessToken was null or undefined when calling listActiveStorefrontOffersIds.');
            }
            // verify required parameter 'storefrontId' is not null or undefined
            if (storefrontId === null || storefrontId === undefined) {
                throw new RequiredError('storefrontId','Required parameter storefrontId was null or undefined when calling listActiveStorefrontOffersIds.');
            }
            const localVarPath = `/public/v1/server/businessAccounts/{businessAccountId}/storefronts/{storefrontId}/activeOfferIds`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"storefrontId"}}`, encodeURIComponent(String(storefrontId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (accessToken !== undefined) {
                localVarQueryParameter['accessToken'] = accessToken;
            }

            if (baseCategoryIds) {
                localVarQueryParameter['baseCategoryIds'] = baseCategoryIds;
            }

            if (includeProfessions !== undefined) {
                localVarQueryParameter['includeProfessions'] = includeProfessions;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение доступных типов операций
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {OperationAvailableStatesRequestObj} operationAvailableStatesRequestObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAvailableOperationTypes(businessAccountId: number, operationAvailableStatesRequestObj: OperationAvailableStatesRequestObj, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listAvailableOperationTypes.');
            }
            // verify required parameter 'operationAvailableStatesRequestObj' is not null or undefined
            if (operationAvailableStatesRequestObj === null || operationAvailableStatesRequestObj === undefined) {
                throw new RequiredError('operationAvailableStatesRequestObj','Required parameter operationAvailableStatesRequestObj was null or undefined when calling listAvailableOperationTypes.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/operations/available`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof operationAvailableStatesRequestObj !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(operationAvailableStatesRequestObj !== undefined ? operationAvailableStatesRequestObj : {}) : (operationAvailableStatesRequestObj || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список элементов бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {boolean} [excludeAggregations] Исключить аггрегации
         * @param {boolean} [excludeRecords] Исключить записи списка
         * @param {Date} [specialFromStartDateIncludingDelays] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBusinessAccountElements(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, excludeAggregations?: boolean, excludeRecords?: boolean, specialFromStartDateIncludingDelays?: Date, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listBusinessAccountElements.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/elements`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (excludeAggregations !== undefined) {
                localVarQueryParameter['excludeAggregations'] = excludeAggregations;
            }

            if (excludeRecords !== undefined) {
                localVarQueryParameter['excludeRecords'] = excludeRecords;
            }

            if (specialFromStartDateIncludingDelays !== undefined) {
                localVarQueryParameter['specialFromStartDateIncludingDelays'] = (specialFromStartDateIncludingDelays as any instanceof Date) ?
                    (specialFromStartDateIncludingDelays as any).toISOString() :
                    specialFromStartDateIncludingDelays;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение ограничения фильтров по обязательствам бизнес аккаунта на основе присутствующих данных
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBusinessAccountElementsAvailableFiltersValues(businessAccountId: number, filters?: Array<string>, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listBusinessAccountElementsAvailableFiltersValues.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/elements/availableFilters`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение состояния объекта субъектов и реквизитов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBusinessAccountElementsGroupedByNomenclature(businessAccountId: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listBusinessAccountElementsGroupedByNomenclature.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/rentElements/grouped/nomenclature`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список сгруппированных элементов бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {boolean} subrent Запрос по субарендным обязательствам - если true то субаренда, если false то аренда
         * @param {boolean} periodStartDate Запрос по началу периода - если true, то начало считается датой группы, если false то окончание
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBusinessAccountElementsGroupedByPeriod(businessAccountId: number, subrent: boolean, periodStartDate: boolean, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listBusinessAccountElementsGroupedByPeriod.');
            }
            // verify required parameter 'subrent' is not null or undefined
            if (subrent === null || subrent === undefined) {
                throw new RequiredError('subrent','Required parameter subrent was null or undefined when calling listBusinessAccountElementsGroupedByPeriod.');
            }
            // verify required parameter 'periodStartDate' is not null or undefined
            if (periodStartDate === null || periodStartDate === undefined) {
                throw new RequiredError('periodStartDate','Required parameter periodStartDate was null or undefined when calling listBusinessAccountElementsGroupedByPeriod.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/elements/grouped/period`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (subrent !== undefined) {
                localVarQueryParameter['subrent'] = subrent;
            }

            if (periodStartDate !== undefined) {
                localVarQueryParameter['periodStartDate'] = periodStartDate;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка графических визуализаций отчетов, доступных для бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} [search] Поисковая строка
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBusinessAccountReportChartVisualizations(businessAccountId: number, search?: string, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', filters?: Array<string>, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listBusinessAccountReportChartVisualizations.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/reportChartVisualization`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка конфигураций отчетов доступных для бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBusinessAccountReportConfigurations(businessAccountId: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listBusinessAccountReportConfigurations.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/reportConfigurations`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список бизнес аккаунтов
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBusinessAccounts(limit?: number, startFrom?: number, options: any = {}): RequestArgs {
            const localVarPath = `/businessAccounts`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список категорий
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {CategoryTypeCodeEnum} typeCode Тип изменяемых категорий
         * @param {string} [search] Прямое вхождение последовательности символов в наименование
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCategories(businessAccountId: number, typeCode: CategoryTypeCodeEnum, search?: string, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listCategories.');
            }
            // verify required parameter 'typeCode' is not null or undefined
            if (typeCode === null || typeCode === undefined) {
                throw new RequiredError('typeCode','Required parameter typeCode was null or undefined when calling listCategories.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/categories`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (typeCode !== undefined) {
                localVarQueryParameter['typeCode'] = typeCode;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список деревьев категорий
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {CategoryTypeCodeEnum} typeCode Тип изменяемых категорий
         * @param {string} [search] Прямое вхождение последовательности символов в наименование
         * @param {CategorySortOrderEnum} [sortBy] По чему сортировать
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCategoryTrees(businessAccountId: number, typeCode: CategoryTypeCodeEnum, search?: string, sortBy?: CategorySortOrderEnum, sortOrder?: 'ASC' | 'DESC', options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listCategoryTrees.');
            }
            // verify required parameter 'typeCode' is not null or undefined
            if (typeCode === null || typeCode === undefined) {
                throw new RequiredError('typeCode','Required parameter typeCode was null or undefined when calling listCategoryTrees.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/categories/tree`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (typeCode !== undefined) {
                localVarQueryParameter['typeCode'] = typeCode;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение текущих заполняемых в интерфейсе операций по рамке активности
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} activityFrameId Идентификатор рамки активности
         * @param {RentActivityFrameTypeCodeEnum} activityFrameType Тип рамки активности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConcurrentOperations(businessAccountId: number, activityFrameId: number, activityFrameType: RentActivityFrameTypeCodeEnum, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listConcurrentOperations.');
            }
            // verify required parameter 'activityFrameId' is not null or undefined
            if (activityFrameId === null || activityFrameId === undefined) {
                throw new RequiredError('activityFrameId','Required parameter activityFrameId was null or undefined when calling listConcurrentOperations.');
            }
            // verify required parameter 'activityFrameType' is not null or undefined
            if (activityFrameType === null || activityFrameType === undefined) {
                throw new RequiredError('activityFrameType','Required parameter activityFrameType was null or undefined when calling listConcurrentOperations.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/operations/concurrent`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (activityFrameId !== undefined) {
                localVarQueryParameter['activityFrameId'] = activityFrameId;
            }

            if (activityFrameType !== undefined) {
                localVarQueryParameter['activityFrameType'] = activityFrameType;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка контактов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} [search] Поисковая строка
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listContacts(businessAccountId: number, search?: string, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', filters?: Array<string>, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listContacts.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/contacts`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список кадров
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCrewMembers(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listCrewMembers.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/crew-members`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение ограничений фильтров сотрудников бизнес аккаунта на основе присутствующих данных
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCrewMembersAvailableFiltersValues(businessAccountId: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listCrewMembersAvailableFiltersValues.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/crew-members/availableFilters`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список пользовательских полей
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} [search] Поисковая строка
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {'BOOLEAN' | 'LONG' | 'DOUBLE' | 'STRING' | 'DATE' | 'ENTITY_LINK'} [typeCodeFilter] Фильтр по типу поля
         * @param {boolean} [standardFilter] Фильтр по признаку является ли поле стандартным
         * @param {'USER_LINK' | 'CONTACT_LINK' | 'SUBRENT_LINK' | 'PROJECT_LINK' | 'RENTER_LINK' | 'LONG_SUM' | 'MONEY_SUM'} [processingTypeFilter] Фильтр по типу обработки поля
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCustomFields(businessAccountId: number, search?: string, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', typeCodeFilter?: 'BOOLEAN' | 'LONG' | 'DOUBLE' | 'STRING' | 'DATE' | 'ENTITY_LINK', standardFilter?: boolean, processingTypeFilter?: 'USER_LINK' | 'CONTACT_LINK' | 'SUBRENT_LINK' | 'PROJECT_LINK' | 'RENTER_LINK' | 'LONG_SUM' | 'MONEY_SUM', options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listCustomFields.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/customFields`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (typeCodeFilter !== undefined) {
                localVarQueryParameter['typeCodeFilter'] = typeCodeFilter;
            }

            if (standardFilter !== undefined) {
                localVarQueryParameter['standardFilter'] = standardFilter;
            }

            if (processingTypeFilter !== undefined) {
                localVarQueryParameter['processingTypeFilter'] = processingTypeFilter;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список ограничений по типам пользовательских полей
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCustomFieldsLimits(businessAccountId: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listCustomFieldsLimits.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/customFields/limits`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список шаблонов документов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {boolean} [showHidden] Если передан и true, то будут показаны даже скрытые шаблоны
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDocumentTemplates(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, showHidden?: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listDocumentTemplates.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/documentTemplates`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (showHidden !== undefined) {
                localVarQueryParameter['showHidden'] = showHidden;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка эксель визуализаций отчета, к которому относится графическая визуализация
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {string} [search] Поисковая строка
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDocumentTemplatesForReportChartVisualization(businessAccountId: number, id: number, search?: string, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listDocumentTemplatesForReportChartVisualization.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling listDocumentTemplatesForReportChartVisualization.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/reportChartVisualization/{id}/export/list`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение информации о измененных файлах
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEntityLifecycleEvents(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listEntityLifecycleEvents.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/entity-lifecycle-events`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение ограничений фильтров по тратам
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listExpenseAvailableFilters(businessAccountId: number, filters?: Array<string>, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listExpenseAvailableFilters.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/expense/availableFilters`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Получение списка затрат по проекту
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {boolean} [excludeAggregations] Исключить аггрегации
         * @param {boolean} [excludeRecords] Исключить записи списка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listExpenses(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, excludeAggregations?: boolean, excludeRecords?: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listExpenses.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/expense`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (excludeAggregations !== undefined) {
                localVarQueryParameter['excludeAggregations'] = excludeAggregations;
            }

            if (excludeRecords !== undefined) {
                localVarQueryParameter['excludeRecords'] = excludeRecords;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка шаблонов для экспорта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listExportTemplate(options: any = {}): RequestArgs {
            const localVarPath = `/global/analytics/exportTemplate`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка экземпляров
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInstances(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listInstances.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/instances`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка экземпляров с картой доступности и аггрегациями стеков на интервале
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} from Начало целевого интервала времени, epoch millis
         * @param {number} until Конец целевого интервала времени, epoch millis
         * @param {ListOnIntervalRequestObject} listOnIntervalRequestObject 
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {Array<TimetableTypeCodeEnum>} [targetStackTypeCodes] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInstancesOnInterval(businessAccountId: number, from: number, until: number, listOnIntervalRequestObject: ListOnIntervalRequestObject, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, targetStackTypeCodes?: Array<TimetableTypeCodeEnum>, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listInstancesOnInterval.');
            }
            // verify required parameter 'from' is not null or undefined
            if (from === null || from === undefined) {
                throw new RequiredError('from','Required parameter from was null or undefined when calling listInstancesOnInterval.');
            }
            // verify required parameter 'until' is not null or undefined
            if (until === null || until === undefined) {
                throw new RequiredError('until','Required parameter until was null or undefined when calling listInstancesOnInterval.');
            }
            // verify required parameter 'listOnIntervalRequestObject' is not null or undefined
            if (listOnIntervalRequestObject === null || listOnIntervalRequestObject === undefined) {
                throw new RequiredError('listOnIntervalRequestObject','Required parameter listOnIntervalRequestObject was null or undefined when calling listInstancesOnInterval.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/instances/interval`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (targetStackTypeCodes) {
                localVarQueryParameter['targetStackTypeCodes'] = targetStackTypeCodes;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = until;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof listOnIntervalRequestObject !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(listOnIntervalRequestObject !== undefined ? listOnIntervalRequestObject : {}) : (listOnIntervalRequestObject || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка событий движения инвентаря
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInventoryMovements(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listInventoryMovements.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/inventory-movements`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение ограничений фильтров бизнес аккаунта на основе присутствующих данных
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInventoryMovementsAvailableFiltersValues(businessAccountId: number, filters?: Array<string>, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listInventoryMovementsAvailableFiltersValues.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/inventory-movements/available-filters`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка наборов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listKits(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listKits.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/kits`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение ограничений фильтров наборов бизнес аккаунта на основе присутствующих данных
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listKitsAvailableFiltersValues(businessAccountId: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listKitsAvailableFiltersValues.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/kits/availableFilters`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} [search] Поисковая строка
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLocations(businessAccountId: number, search?: string, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', filters?: Array<string>, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listLocations.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/locations`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список наборов, продуктов и вариантов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNomenclature(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listNomenclature.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/nomenclature`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение ограничений фильтров номенклатуры бизнес аккаунта на основе присутствующих данных
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNomenclatureAvailableFiltersValues(businessAccountId: number, filters?: Array<string>, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listNomenclatureAvailableFiltersValues.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/nomenclature/availableFilters`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список наборов, продуктов и вариантов на интервале времени
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} from Начало целевого интервала времени, epoch millis
         * @param {number} until Конец целевого интервала времени, epoch millis
         * @param {ListOnIntervalRequestObject} listOnIntervalRequestObject 
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {Array<TimetableTypeCodeEnum>} [targetStackTypeCodes] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNomenclatureOnInterval(businessAccountId: number, from: number, until: number, listOnIntervalRequestObject: ListOnIntervalRequestObject, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, targetStackTypeCodes?: Array<TimetableTypeCodeEnum>, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listNomenclatureOnInterval.');
            }
            // verify required parameter 'from' is not null or undefined
            if (from === null || from === undefined) {
                throw new RequiredError('from','Required parameter from was null or undefined when calling listNomenclatureOnInterval.');
            }
            // verify required parameter 'until' is not null or undefined
            if (until === null || until === undefined) {
                throw new RequiredError('until','Required parameter until was null or undefined when calling listNomenclatureOnInterval.');
            }
            // verify required parameter 'listOnIntervalRequestObject' is not null or undefined
            if (listOnIntervalRequestObject === null || listOnIntervalRequestObject === undefined) {
                throw new RequiredError('listOnIntervalRequestObject','Required parameter listOnIntervalRequestObject was null or undefined when calling listNomenclatureOnInterval.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/nomenclature/interval`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (targetStackTypeCodes) {
                localVarQueryParameter['targetStackTypeCodes'] = targetStackTypeCodes;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = until;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof listOnIntervalRequestObject !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(listOnIntervalRequestObject !== undefined ? listOnIntervalRequestObject : {}) : (listOnIntervalRequestObject || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список элементов операции
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {boolean} [excludeAggregations] Исключить аггрегации
         * @param {boolean} [excludeRecords] Исключить записи списка
         * @param {Date} [specialFromStartDateIncludingDelays] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOperationElements(businessAccountId: number, id: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, excludeAggregations?: boolean, excludeRecords?: boolean, specialFromStartDateIncludingDelays?: Date, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listOperationElements.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling listOperationElements.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/operations/{id}/elements`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (excludeAggregations !== undefined) {
                localVarQueryParameter['excludeAggregations'] = excludeAggregations;
            }

            if (excludeRecords !== undefined) {
                localVarQueryParameter['excludeRecords'] = excludeRecords;
            }

            if (specialFromStartDateIncludingDelays !== undefined) {
                localVarQueryParameter['specialFromStartDateIncludingDelays'] = (specialFromStartDateIncludingDelays as any instanceof Date) ?
                    (specialFromStartDateIncludingDelays as any).toISOString() :
                    specialFromStartDateIncludingDelays;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список операций
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOperations(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listOperations.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/operations`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение ограничений фильтров операциям на основе присутствующих данных
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOperationsAvailableFiltersValues(businessAccountId: number, filters?: Array<string>, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listOperationsAvailableFiltersValues.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/operations/availableFilters`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список заказов текущего пользователя в бизнес аккаунте, созданных через витрину. Необходим JWT токен пользователя
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
         * @param {number} [minimumOrderChangeDate] Фильтр для получения только заказов, созданных и измененных не ранее указанной даты, epoch millis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrdersByClient(businessAccountId: number, storefrontId: number, minimumOrderChangeDate?: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listOrdersByClient.');
            }
            // verify required parameter 'storefrontId' is not null or undefined
            if (storefrontId === null || storefrontId === undefined) {
                throw new RequiredError('storefrontId','Required parameter storefrontId was null or undefined when calling listOrdersByClient.');
            }
            const localVarPath = `/public/v1/client/businessAccounts/{businessAccountId}/storefronts/{storefrontId}/orders`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"storefrontId"}}`, encodeURIComponent(String(storefrontId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (minimumOrderChangeDate !== undefined) {
                localVarQueryParameter['minimumOrderChangeDate'] = minimumOrderChangeDate;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список заказов текущего пользователя в бизнес аккаунте, созданных через витрину. Необходим JWT токен пользователя
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} accessToken Обязательный токен доступа в формате UUID
         * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
         * @param {number} [minimumOrderChangeDate] Фильтр для получения только заказов, созданных и измененных не ранее указанной даты, epoch millis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrdersByServer(businessAccountId: number, accessToken: string, storefrontId: number, minimumOrderChangeDate?: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listOrdersByServer.');
            }
            // verify required parameter 'accessToken' is not null or undefined
            if (accessToken === null || accessToken === undefined) {
                throw new RequiredError('accessToken','Required parameter accessToken was null or undefined when calling listOrdersByServer.');
            }
            // verify required parameter 'storefrontId' is not null or undefined
            if (storefrontId === null || storefrontId === undefined) {
                throw new RequiredError('storefrontId','Required parameter storefrontId was null or undefined when calling listOrdersByServer.');
            }
            const localVarPath = `/public/v1/server/businessAccounts/{businessAccountId}/storefronts/{storefrontId}/orders`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"storefrontId"}}`, encodeURIComponent(String(storefrontId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (accessToken !== undefined) {
                localVarQueryParameter['accessToken'] = accessToken;
            }

            if (minimumOrderChangeDate !== undefined) {
                localVarQueryParameter['minimumOrderChangeDate'] = minimumOrderChangeDate;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка способов оплаты
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPaymentMethods(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listPaymentMethods.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/payment-methods`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение ограничений фильтров по тратам
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPaymentMethodsAvailableFilters(businessAccountId: number, filters?: Array<string>, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listPaymentMethodsAvailableFilters.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/payment-methods/availableFilters`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список платежей
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPayments(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listPayments.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/payments`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение ограничения фильтров для списка платежей на основе присутствующих данных
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPaymentsAvailableFiltersValues(businessAccountId: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listPaymentsAvailableFiltersValues.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/payments/availableFilters`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список ценовых схем компании
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} [search] Поисковая строка
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {PricingSchemeTypeCodeEnum} [typeCodeFilter] Фильтр по типу поля
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPricingSchemes(businessAccountId: number, search?: string, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', typeCodeFilter?: PricingSchemeTypeCodeEnum, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listPricingSchemes.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/pricingSchemes`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (typeCodeFilter !== undefined) {
                localVarQueryParameter['typeCodeFilter'] = typeCodeFilter;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка экземпляров продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProductInstances(businessAccountId: number, id: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listProductInstances.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling listProductInstances.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/products/{id}/instances`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка экземпляров продукта с картой доступности и аггрегациями стеков на интервале
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {number} from Начало целевого интервала времени, epoch millis
         * @param {number} until Конец целевого интервала времени, epoch millis
         * @param {ListOnIntervalRequestObject} listOnIntervalRequestObject 
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {Array<TimetableTypeCodeEnum>} [targetStackTypeCodes] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProductInstancesOnInterval(businessAccountId: number, id: number, from: number, until: number, listOnIntervalRequestObject: ListOnIntervalRequestObject, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, targetStackTypeCodes?: Array<TimetableTypeCodeEnum>, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listProductInstancesOnInterval.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling listProductInstancesOnInterval.');
            }
            // verify required parameter 'from' is not null or undefined
            if (from === null || from === undefined) {
                throw new RequiredError('from','Required parameter from was null or undefined when calling listProductInstancesOnInterval.');
            }
            // verify required parameter 'until' is not null or undefined
            if (until === null || until === undefined) {
                throw new RequiredError('until','Required parameter until was null or undefined when calling listProductInstancesOnInterval.');
            }
            // verify required parameter 'listOnIntervalRequestObject' is not null or undefined
            if (listOnIntervalRequestObject === null || listOnIntervalRequestObject === undefined) {
                throw new RequiredError('listOnIntervalRequestObject','Required parameter listOnIntervalRequestObject was null or undefined when calling listProductInstancesOnInterval.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/products/{id}/instances/interval`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (targetStackTypeCodes) {
                localVarQueryParameter['targetStackTypeCodes'] = targetStackTypeCodes;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = until;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof listOnIntervalRequestObject !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(listOnIntervalRequestObject !== undefined ? listOnIntervalRequestObject : {}) : (listOnIntervalRequestObject || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка экземпляров варианта продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} productId Идентификатор продукта
         * @param {number} id Идентификатор сущности
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProductVariantInstances(businessAccountId: number, productId: number, id: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listProductVariantInstances.');
            }
            // verify required parameter 'productId' is not null or undefined
            if (productId === null || productId === undefined) {
                throw new RequiredError('productId','Required parameter productId was null or undefined when calling listProductVariantInstances.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling listProductVariantInstances.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/products/{productId}/variants/{id}/instances`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка экземпляров варианта продукта с картой доступности и аггрегациями стеков на интервале
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} productId Идентификатор продукта
         * @param {number} id Идентификатор сущности
         * @param {number} from Начало целевого интервала времени, epoch millis
         * @param {number} until Конец целевого интервала времени, epoch millis
         * @param {ListOnIntervalRequestObject} listOnIntervalRequestObject 
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {Array<TimetableTypeCodeEnum>} [targetStackTypeCodes] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProductVariantInstancesOnInterval(businessAccountId: number, productId: number, id: number, from: number, until: number, listOnIntervalRequestObject: ListOnIntervalRequestObject, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, targetStackTypeCodes?: Array<TimetableTypeCodeEnum>, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listProductVariantInstancesOnInterval.');
            }
            // verify required parameter 'productId' is not null or undefined
            if (productId === null || productId === undefined) {
                throw new RequiredError('productId','Required parameter productId was null or undefined when calling listProductVariantInstancesOnInterval.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling listProductVariantInstancesOnInterval.');
            }
            // verify required parameter 'from' is not null or undefined
            if (from === null || from === undefined) {
                throw new RequiredError('from','Required parameter from was null or undefined when calling listProductVariantInstancesOnInterval.');
            }
            // verify required parameter 'until' is not null or undefined
            if (until === null || until === undefined) {
                throw new RequiredError('until','Required parameter until was null or undefined when calling listProductVariantInstancesOnInterval.');
            }
            // verify required parameter 'listOnIntervalRequestObject' is not null or undefined
            if (listOnIntervalRequestObject === null || listOnIntervalRequestObject === undefined) {
                throw new RequiredError('listOnIntervalRequestObject','Required parameter listOnIntervalRequestObject was null or undefined when calling listProductVariantInstancesOnInterval.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/products/{productId}/variants/{id}/instances/interval`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (targetStackTypeCodes) {
                localVarQueryParameter['targetStackTypeCodes'] = targetStackTypeCodes;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = until;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof listOnIntervalRequestObject !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(listOnIntervalRequestObject !== undefined ? listOnIntervalRequestObject : {}) : (listOnIntervalRequestObject || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка вариантов продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProductVariants(businessAccountId: number, id: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listProductVariants.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling listProductVariants.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/products/{id}/variants`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение ограничений фильтров экземпляров продукта бизнес аккаунта на основе присутствующих данных
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProductVariantsAvailableFiltersValues(businessAccountId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listProductVariantsAvailableFiltersValues.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling listProductVariantsAvailableFiltersValues.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/products/{id}/variants/availableFilters`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список продуктов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProducts(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listProducts.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/products`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение ограничений фильтров продуктов бизнес аккаунта на основе присутствующих данных
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProductsAvailableFiltersValues(businessAccountId: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listProductsAvailableFiltersValues.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/products/availableFilters`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список профессий
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProfessions(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listProfessions.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/professions`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение ограничений фильтров профессий бизнес аккаунта на основе присутствующих данных
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProfessionsAvailableFiltersValues(businessAccountId: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listProfessionsAvailableFiltersValues.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/professions/availableFilters`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список элементов проекта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} projectId Идентификатор проекта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {boolean} [excludeAggregations] Исключить аггрегации
         * @param {boolean} [excludeRecords] Исключить записи списка
         * @param {Date} [specialFromStartDateIncludingDelays] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProjectElements(businessAccountId: number, projectId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, excludeAggregations?: boolean, excludeRecords?: boolean, specialFromStartDateIncludingDelays?: Date, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listProjectElements.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling listProjectElements.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/projects/{projectId}/elements`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (excludeAggregations !== undefined) {
                localVarQueryParameter['excludeAggregations'] = excludeAggregations;
            }

            if (excludeRecords !== undefined) {
                localVarQueryParameter['excludeRecords'] = excludeRecords;
            }

            if (specialFromStartDateIncludingDelays !== undefined) {
                localVarQueryParameter['specialFromStartDateIncludingDelays'] = (specialFromStartDateIncludingDelays as any instanceof Date) ?
                    (specialFromStartDateIncludingDelays as any).toISOString() :
                    specialFromStartDateIncludingDelays;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список проектов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProjects(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listProjects.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/projects`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение ограничений фильтров проектов на основе присутствующих данных
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProjectsAvailableFiltersValues(businessAccountId: number, filters?: Array<string>, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listProjectsAvailableFiltersValues.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/projects/availableFilters`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список рамок арендной активности
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRentActivityFrames(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listRentActivityFrames.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/rent-activity-frames`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список арендаторов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRenters(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listRenters.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/renters`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение ограничений фильтров контрагентов на основе присутствующих данных
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRentersAvailableFiltersValues(businessAccountId: number, filters?: Array<string>, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listRentersAvailableFiltersValues.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/renters/availableFilters`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка предложений витрины бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} accessToken Обязательный токен доступа в формате UUID
         * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
         * @param {number} minimumOfferChangeDate Фильтр для получения только торговых предложений, измененных не ранее указанной даты, epoch millis
         * @param {number} [pageNumber] Номер страницы
         * @param {number} [itemsPerPage] Число элементов на странице
         * @param {Array<number>} [baseCategoryIds] Идентификаторы базовых категорий, только они и их подкатегории попадут в результат
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStorefrontOffers(businessAccountId: number, accessToken: string, storefrontId: number, minimumOfferChangeDate: number, pageNumber?: number, itemsPerPage?: number, baseCategoryIds?: Array<number>, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listStorefrontOffers.');
            }
            // verify required parameter 'accessToken' is not null or undefined
            if (accessToken === null || accessToken === undefined) {
                throw new RequiredError('accessToken','Required parameter accessToken was null or undefined when calling listStorefrontOffers.');
            }
            // verify required parameter 'storefrontId' is not null or undefined
            if (storefrontId === null || storefrontId === undefined) {
                throw new RequiredError('storefrontId','Required parameter storefrontId was null or undefined when calling listStorefrontOffers.');
            }
            // verify required parameter 'minimumOfferChangeDate' is not null or undefined
            if (minimumOfferChangeDate === null || minimumOfferChangeDate === undefined) {
                throw new RequiredError('minimumOfferChangeDate','Required parameter minimumOfferChangeDate was null or undefined when calling listStorefrontOffers.');
            }
            const localVarPath = `/public/v1/server/businessAccounts/{businessAccountId}/storefronts/{storefrontId}/offers`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"storefrontId"}}`, encodeURIComponent(String(storefrontId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (accessToken !== undefined) {
                localVarQueryParameter['accessToken'] = accessToken;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (baseCategoryIds) {
                localVarQueryParameter['baseCategoryIds'] = baseCategoryIds;
            }

            if (minimumOfferChangeDate !== undefined) {
                localVarQueryParameter['minimumOfferChangeDate'] = minimumOfferChangeDate;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка предложений витрины бизнес аккаунта типа специальность
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} accessToken Обязательный токен доступа в формате UUID
         * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
         * @param {number} minimumOfferChangeDate Фильтр для получения только торговых предложений, измененных не ранее указанной даты, epoch millis
         * @param {number} [pageNumber] Номер страницы
         * @param {number} [itemsPerPage] Число элементов на странице
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStorefrontProfessionOffers(businessAccountId: number, accessToken: string, storefrontId: number, minimumOfferChangeDate: number, pageNumber?: number, itemsPerPage?: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listStorefrontProfessionOffers.');
            }
            // verify required parameter 'accessToken' is not null or undefined
            if (accessToken === null || accessToken === undefined) {
                throw new RequiredError('accessToken','Required parameter accessToken was null or undefined when calling listStorefrontProfessionOffers.');
            }
            // verify required parameter 'storefrontId' is not null or undefined
            if (storefrontId === null || storefrontId === undefined) {
                throw new RequiredError('storefrontId','Required parameter storefrontId was null or undefined when calling listStorefrontProfessionOffers.');
            }
            // verify required parameter 'minimumOfferChangeDate' is not null or undefined
            if (minimumOfferChangeDate === null || minimumOfferChangeDate === undefined) {
                throw new RequiredError('minimumOfferChangeDate','Required parameter minimumOfferChangeDate was null or undefined when calling listStorefrontProfessionOffers.');
            }
            const localVarPath = `/public/v1/server/businessAccounts/{businessAccountId}/storefronts/{storefrontId}/profession-offers`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"storefrontId"}}`, encodeURIComponent(String(storefrontId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (accessToken !== undefined) {
                localVarQueryParameter['accessToken'] = accessToken;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (minimumOfferChangeDate !== undefined) {
                localVarQueryParameter['minimumOfferChangeDate'] = minimumOfferChangeDate;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список элементов поставки
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} subrentId Идентификатор поставки
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {boolean} [excludeAggregations] Исключить аггрегации
         * @param {boolean} [excludeRecords] Исключить записи списка
         * @param {Date} [specialFromStartDateIncludingDelays] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSubrentElements(businessAccountId: number, subrentId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, excludeAggregations?: boolean, excludeRecords?: boolean, specialFromStartDateIncludingDelays?: Date, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listSubrentElements.');
            }
            // verify required parameter 'subrentId' is not null or undefined
            if (subrentId === null || subrentId === undefined) {
                throw new RequiredError('subrentId','Required parameter subrentId was null or undefined when calling listSubrentElements.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/subrent/{subrentId}/elements`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"subrentId"}}`, encodeURIComponent(String(subrentId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (excludeAggregations !== undefined) {
                localVarQueryParameter['excludeAggregations'] = excludeAggregations;
            }

            if (excludeRecords !== undefined) {
                localVarQueryParameter['excludeRecords'] = excludeRecords;
            }

            if (specialFromStartDateIncludingDelays !== undefined) {
                localVarQueryParameter['specialFromStartDateIncludingDelays'] = (specialFromStartDateIncludingDelays as any instanceof Date) ?
                    (specialFromStartDateIncludingDelays as any).toISOString() :
                    specialFromStartDateIncludingDelays;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список субаренды
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSubrents(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listSubrents.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/subrents`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение ограничений фильтров поставок на основе присутствующих данных
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSubrentsAvailableFiltersValues(businessAccountId: number, filters?: Array<string>, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listSubrentsAvailableFiltersValues.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/subrents/availableFilters`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список наборов, продуктов и вариантов на интервале времени
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} from Начало целевого интервала времени, epoch millis
         * @param {number} until Начало целевого интервала времени, epoch millis
         * @param {boolean} includeRentElements Включать ли обязательства. В случае, если флаг true - добавляем их, если нет - выводим экземпляры со стеком доступности
         * @param {SummaryScheduleListRequestObject} summaryScheduleListRequestObject 
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {boolean} [includeInstanceSchedule] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSummarySchedule(businessAccountId: number, from: number, until: number, includeRentElements: boolean, summaryScheduleListRequestObject: SummaryScheduleListRequestObject, limit?: number, startFrom?: number, includeInstanceSchedule?: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listSummarySchedule.');
            }
            // verify required parameter 'from' is not null or undefined
            if (from === null || from === undefined) {
                throw new RequiredError('from','Required parameter from was null or undefined when calling listSummarySchedule.');
            }
            // verify required parameter 'until' is not null or undefined
            if (until === null || until === undefined) {
                throw new RequiredError('until','Required parameter until was null or undefined when calling listSummarySchedule.');
            }
            // verify required parameter 'includeRentElements' is not null or undefined
            if (includeRentElements === null || includeRentElements === undefined) {
                throw new RequiredError('includeRentElements','Required parameter includeRentElements was null or undefined when calling listSummarySchedule.');
            }
            // verify required parameter 'summaryScheduleListRequestObject' is not null or undefined
            if (summaryScheduleListRequestObject === null || summaryScheduleListRequestObject === undefined) {
                throw new RequiredError('summaryScheduleListRequestObject','Required parameter summaryScheduleListRequestObject was null or undefined when calling listSummarySchedule.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/nomenclature/summary-schedule`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = until;
            }

            if (includeRentElements !== undefined) {
                localVarQueryParameter['includeRentElements'] = includeRentElements;
            }

            if (includeInstanceSchedule !== undefined) {
                localVarQueryParameter['includeInstanceSchedule'] = includeInstanceSchedule;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof summaryScheduleListRequestObject !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(summaryScheduleListRequestObject !== undefined ? summaryScheduleListRequestObject : {}) : (summaryScheduleListRequestObject || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список элементов шаблона
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} templateId Идентификатор шаблона
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {boolean} [excludeAggregations] Исключить аггрегации
         * @param {boolean} [excludeRecords] Исключить записи списка
         * @param {Date} [specialFromStartDateIncludingDelays] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTemplateElements(businessAccountId: number, templateId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, excludeAggregations?: boolean, excludeRecords?: boolean, specialFromStartDateIncludingDelays?: Date, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listTemplateElements.');
            }
            // verify required parameter 'templateId' is not null or undefined
            if (templateId === null || templateId === undefined) {
                throw new RequiredError('templateId','Required parameter templateId was null or undefined when calling listTemplateElements.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/templates/{templateId}/elements`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (excludeAggregations !== undefined) {
                localVarQueryParameter['excludeAggregations'] = excludeAggregations;
            }

            if (excludeRecords !== undefined) {
                localVarQueryParameter['excludeRecords'] = excludeRecords;
            }

            if (specialFromStartDateIncludingDelays !== undefined) {
                localVarQueryParameter['specialFromStartDateIncludingDelays'] = (specialFromStartDateIncludingDelays as any instanceof Date) ?
                    (specialFromStartDateIncludingDelays as any).toISOString() :
                    specialFromStartDateIncludingDelays;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список шаблонов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTemplates(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listTemplates.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/templates`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение ограничения фильтров шаблона на основе присутствующих данных
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTemplatesAvailableFiltersValues(businessAccountId: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listTemplatesAvailableFiltersValues.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/templates/availableFilters`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} [search] Поисковая строка
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTransportations(businessAccountId: number, search?: string, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', filters?: Array<string>, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listTransportations.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/transportations`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка простых записей пользователей бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsersSimple(businessAccountId: number, limit?: number, startFrom?: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listUsersSimple.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/users`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} [search] Поисковая строка
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVehicles(businessAccountId: number, search?: string, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', filters?: Array<string>, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listVehicles.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/vehicles`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение ограничений фильтров по тс
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVehiclesAvailableFilters(businessAccountId: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listVehiclesAvailableFilters.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/vehicles/availableFilters`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список работ
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkPlannings(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listWorkPlannings.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/work-plannings`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение ограничений фильтров работ на основе присутствующих данных
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkPlanningsAvailableFiltersValues(businessAccountId: number, filters?: Array<string>, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listWorkPlanningsAvailableFiltersValues.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/work-plannings/available-filters`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Сгруппированные обязательства успешно получены
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkPlanningsGroupedByCrewMember(businessAccountId: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling listWorkPlanningsGroupedByCrewMember.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/workPlannings/grouped`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить миграцию данных
         * @param {number} businessAccountId 
         * @param {number} targetCreationAuthorId 
         * @param {any} jxlsXMLConfiguration 
         * @param {any} excelFile 
         * @param {any} massImportXMLConfiguration 
         * @param {number} [startFrom] 
         * @param {number} [count] 
         * @param {OnExistingEntityFound} [onExistingEntityFound] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrate(businessAccountId: number, targetCreationAuthorId: number, jxlsXMLConfiguration: any, excelFile: any, massImportXMLConfiguration: any, startFrom?: number, count?: number, onExistingEntityFound?: OnExistingEntityFound, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling migrate.');
            }
            // verify required parameter 'targetCreationAuthorId' is not null or undefined
            if (targetCreationAuthorId === null || targetCreationAuthorId === undefined) {
                throw new RequiredError('targetCreationAuthorId','Required parameter targetCreationAuthorId was null or undefined when calling migrate.');
            }
            // verify required parameter 'jxlsXMLConfiguration' is not null or undefined
            if (jxlsXMLConfiguration === null || jxlsXMLConfiguration === undefined) {
                throw new RequiredError('jxlsXMLConfiguration','Required parameter jxlsXMLConfiguration was null or undefined when calling migrate.');
            }
            // verify required parameter 'excelFile' is not null or undefined
            if (excelFile === null || excelFile === undefined) {
                throw new RequiredError('excelFile','Required parameter excelFile was null or undefined when calling migrate.');
            }
            // verify required parameter 'massImportXMLConfiguration' is not null or undefined
            if (massImportXMLConfiguration === null || massImportXMLConfiguration === undefined) {
                throw new RequiredError('massImportXMLConfiguration','Required parameter massImportXMLConfiguration was null or undefined when calling migrate.');
            }
            const localVarPath = `/global/migration`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (businessAccountId !== undefined) {
                localVarQueryParameter['businessAccountId'] = businessAccountId;
            }

            if (targetCreationAuthorId !== undefined) {
                localVarQueryParameter['targetCreationAuthorId'] = targetCreationAuthorId;
            }


            if (jxlsXMLConfiguration !== undefined) { 
                localVarFormParams.append('jxlsXMLConfiguration', jxlsXMLConfiguration as any);
            }
    
            if (excelFile !== undefined) { 
                localVarFormParams.append('excelFile', excelFile as any);
            }
    
            if (massImportXMLConfiguration !== undefined) { 
                localVarFormParams.append('massImportXMLConfiguration', massImportXMLConfiguration as any);
            }
    
            if (startFrom !== undefined) { 
                localVarFormParams.append('startFrom', startFrom as any);
            }
    
            if (count !== undefined) { 
                localVarFormParams.append('count', count as any);
            }
    
            if (onExistingEntityFound !== undefined) { 
                localVarFormParams.append('onExistingEntityFound', onExistingEntityFound as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Перенос затрат проекта в другой проект
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} projectId Идентификатор проекта
         * @param {number} targetProjectId Идентификатор целевого проекта (в который переносятся затраты)
         * @param {boolean} copy Признак копирования. Если да, то в целевой переносятся как черновики, в исходном не изменяются. Если нет, то в целевой переносятся как есть, в исходном отменяются
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveExpensesToOtherProject(businessAccountId: number, projectId: number, targetProjectId: number, copy: boolean, versionedEntityObjList: VersionedEntityObjList, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling moveExpensesToOtherProject.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling moveExpensesToOtherProject.');
            }
            // verify required parameter 'targetProjectId' is not null or undefined
            if (targetProjectId === null || targetProjectId === undefined) {
                throw new RequiredError('targetProjectId','Required parameter targetProjectId was null or undefined when calling moveExpensesToOtherProject.');
            }
            // verify required parameter 'copy' is not null or undefined
            if (copy === null || copy === undefined) {
                throw new RequiredError('copy','Required parameter copy was null or undefined when calling moveExpensesToOtherProject.');
            }
            // verify required parameter 'versionedEntityObjList' is not null or undefined
            if (versionedEntityObjList === null || versionedEntityObjList === undefined) {
                throw new RequiredError('versionedEntityObjList','Required parameter versionedEntityObjList was null or undefined when calling moveExpensesToOtherProject.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/projects/{projectId}/expense/move`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (targetProjectId !== undefined) {
                localVarQueryParameter['targetProjectId'] = targetProjectId;
            }

            if (copy !== undefined) {
                localVarQueryParameter['copy'] = copy;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof versionedEntityObjList !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(versionedEntityObjList !== undefined ? versionedEntityObjList : {}) : (versionedEntityObjList || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Перенос обязательств проекта в другой проект
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} projectId Идентификатор проекта
         * @param {number} targetProjectId Идентификатор целевого проекта (в который переносятся обязательства). Должен быть в работае, если исходный в работе; не должен быть новым, если исходный завершен
         * @param {boolean} copy Признак копирования. Если да, то в целевой переносятся как черновики, в исходном не изменяются. Если нет, то в целевой переносятся как есть, в исходном отменяются
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {boolean} [replaceInstancesWithAnonymous] Признак копирования с заменой именованных на анонимные. Используется только при копировании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveProjectElementsToOtherProject(businessAccountId: number, projectId: number, targetProjectId: number, copy: boolean, versionedEntityObjList: VersionedEntityObjList, replaceInstancesWithAnonymous?: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling moveProjectElementsToOtherProject.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling moveProjectElementsToOtherProject.');
            }
            // verify required parameter 'targetProjectId' is not null or undefined
            if (targetProjectId === null || targetProjectId === undefined) {
                throw new RequiredError('targetProjectId','Required parameter targetProjectId was null or undefined when calling moveProjectElementsToOtherProject.');
            }
            // verify required parameter 'copy' is not null or undefined
            if (copy === null || copy === undefined) {
                throw new RequiredError('copy','Required parameter copy was null or undefined when calling moveProjectElementsToOtherProject.');
            }
            // verify required parameter 'versionedEntityObjList' is not null or undefined
            if (versionedEntityObjList === null || versionedEntityObjList === undefined) {
                throw new RequiredError('versionedEntityObjList','Required parameter versionedEntityObjList was null or undefined when calling moveProjectElementsToOtherProject.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/projects/{projectId}/elements/move`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (targetProjectId !== undefined) {
                localVarQueryParameter['targetProjectId'] = targetProjectId;
            }

            if (copy !== undefined) {
                localVarQueryParameter['copy'] = copy;
            }

            if (replaceInstancesWithAnonymous !== undefined) {
                localVarQueryParameter['replaceInstancesWithAnonymous'] = replaceInstancesWithAnonymous;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof versionedEntityObjList !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(versionedEntityObjList !== undefined ? versionedEntityObjList : {}) : (versionedEntityObjList || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Перенос обязательств поставки в другую поставку
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} subrentId Идентификатор поставки
         * @param {number} targetSubrentId Идентификатор целевой поставки (в которую переносятся обязательства). Должна быть в работае, если исходная в работе; не должна быть новой, если исходная завершена
         * @param {boolean} copy Признак копирования. Если да, то в целевую переносятся как черновики, в исходном не изменяются. Если нет, то в целевую переносятся как есть, в исходном отменяются.
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {boolean} [replaceInstancesWithAnonymous] Признак копирования с заменой именованных на анонимные. Используется только при копировании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveSubrentElementsToOtherSubrent(businessAccountId: number, subrentId: number, targetSubrentId: number, copy: boolean, versionedEntityObjList: VersionedEntityObjList, replaceInstancesWithAnonymous?: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling moveSubrentElementsToOtherSubrent.');
            }
            // verify required parameter 'subrentId' is not null or undefined
            if (subrentId === null || subrentId === undefined) {
                throw new RequiredError('subrentId','Required parameter subrentId was null or undefined when calling moveSubrentElementsToOtherSubrent.');
            }
            // verify required parameter 'targetSubrentId' is not null or undefined
            if (targetSubrentId === null || targetSubrentId === undefined) {
                throw new RequiredError('targetSubrentId','Required parameter targetSubrentId was null or undefined when calling moveSubrentElementsToOtherSubrent.');
            }
            // verify required parameter 'copy' is not null or undefined
            if (copy === null || copy === undefined) {
                throw new RequiredError('copy','Required parameter copy was null or undefined when calling moveSubrentElementsToOtherSubrent.');
            }
            // verify required parameter 'versionedEntityObjList' is not null or undefined
            if (versionedEntityObjList === null || versionedEntityObjList === undefined) {
                throw new RequiredError('versionedEntityObjList','Required parameter versionedEntityObjList was null or undefined when calling moveSubrentElementsToOtherSubrent.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/subrent/{subrentId}/elements/move`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"subrentId"}}`, encodeURIComponent(String(subrentId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (targetSubrentId !== undefined) {
                localVarQueryParameter['targetSubrentId'] = targetSubrentId;
            }

            if (copy !== undefined) {
                localVarQueryParameter['copy'] = copy;
            }

            if (replaceInstancesWithAnonymous !== undefined) {
                localVarQueryParameter['replaceInstancesWithAnonymous'] = replaceInstancesWithAnonymous;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof versionedEntityObjList !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(versionedEntityObjList !== undefined ? versionedEntityObjList : {}) : (versionedEntityObjList || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Перенос работ проекта в другой проект
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} projectId Идентификатор проекта
         * @param {number} targetProjectId Идентификатор целевого проекта (в который переносятся работы)
         * @param {boolean} copy Признак копирования. Если да, то в целевой переносятся как черновики, в исходном не изменяются. Если нет, то в целевой переносятся как есть, в исходном отменяются
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {boolean} [saveCrewMembers] Признак копирования с сохранением работников. Используется только при копировании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveWorkPlanningsToOtherProject(businessAccountId: number, projectId: number, targetProjectId: number, copy: boolean, versionedEntityObjList: VersionedEntityObjList, saveCrewMembers?: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling moveWorkPlanningsToOtherProject.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling moveWorkPlanningsToOtherProject.');
            }
            // verify required parameter 'targetProjectId' is not null or undefined
            if (targetProjectId === null || targetProjectId === undefined) {
                throw new RequiredError('targetProjectId','Required parameter targetProjectId was null or undefined when calling moveWorkPlanningsToOtherProject.');
            }
            // verify required parameter 'copy' is not null or undefined
            if (copy === null || copy === undefined) {
                throw new RequiredError('copy','Required parameter copy was null or undefined when calling moveWorkPlanningsToOtherProject.');
            }
            // verify required parameter 'versionedEntityObjList' is not null or undefined
            if (versionedEntityObjList === null || versionedEntityObjList === undefined) {
                throw new RequiredError('versionedEntityObjList','Required parameter versionedEntityObjList was null or undefined when calling moveWorkPlanningsToOtherProject.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/projects/{projectId}/work-plannings/move`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (targetProjectId !== undefined) {
                localVarQueryParameter['targetProjectId'] = targetProjectId;
            }

            if (copy !== undefined) {
                localVarQueryParameter['copy'] = copy;
            }

            if (saveCrewMembers !== undefined) {
                localVarQueryParameter['saveCrewMembers'] = saveCrewMembers;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof versionedEntityObjList !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(versionedEntityObjList !== undefined ? versionedEntityObjList : {}) : (versionedEntityObjList || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список элементов бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} activityFrameId Идентификатор рамки активности - проекта, КП, поставки или шаблона
         * @param {RentActivityFrameTypeCodeEnum} activityFrameTypeCode 
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recalculateBusinessAccountElementsPrice(businessAccountId: number, activityFrameId: number, activityFrameTypeCode: RentActivityFrameTypeCodeEnum, versionedEntityObjList: VersionedEntityObjList, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling recalculateBusinessAccountElementsPrice.');
            }
            // verify required parameter 'activityFrameId' is not null or undefined
            if (activityFrameId === null || activityFrameId === undefined) {
                throw new RequiredError('activityFrameId','Required parameter activityFrameId was null or undefined when calling recalculateBusinessAccountElementsPrice.');
            }
            // verify required parameter 'activityFrameTypeCode' is not null or undefined
            if (activityFrameTypeCode === null || activityFrameTypeCode === undefined) {
                throw new RequiredError('activityFrameTypeCode','Required parameter activityFrameTypeCode was null or undefined when calling recalculateBusinessAccountElementsPrice.');
            }
            // verify required parameter 'versionedEntityObjList' is not null or undefined
            if (versionedEntityObjList === null || versionedEntityObjList === undefined) {
                throw new RequiredError('versionedEntityObjList','Required parameter versionedEntityObjList was null or undefined when calling recalculateBusinessAccountElementsPrice.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/elements/recalculate-price`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (activityFrameId !== undefined) {
                localVarQueryParameter['activityFrameId'] = activityFrameId;
            }

            if (activityFrameTypeCode !== undefined) {
                localVarQueryParameter['activityFrameTypeCode'] = activityFrameTypeCode;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof versionedEntityObjList !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(versionedEntityObjList !== undefined ? versionedEntityObjList : {}) : (versionedEntityObjList || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Пересчет флага удаляемости у контактов внутри БА или у всех (если null)
         * @param {number} [businessAccountId] Id бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recalculateContactsDeleteable(businessAccountId?: number, options: any = {}): RequestArgs {
            const localVarPath = `/global/contacts/recalculate-deleteable`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (businessAccountId !== undefined) {
                localVarQueryParameter['businessAccountId'] = businessAccountId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Пересчитать стоимость к оплате и долги по проектам
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recalculateProjectsSumsToBePaidAndDebts(businessAccountId: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling recalculateProjectsSumsToBePaidAndDebts.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/recalculateProjectsDebt`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Пересчитать все показатели компании и нижестроящих сущностей (реиндекс только для измененных в процессе)
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {boolean} [fullRecountWarehousePresence] Пересчитывать ли всю номенклатуру инвентаря или только ту у которой есть что-то в аренде / субаренде
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshBusinessAccount(businessAccountId: number, fullRecountWarehousePresence?: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling refreshBusinessAccount.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/refresh`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (fullRecountWarehousePresence !== undefined) {
                localVarQueryParameter['fullRecountWarehousePresence'] = fullRecountWarehousePresence;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Пересчитать все показатели бизнес аккаунта (без пересчета нижестоящих сущностей и без реиндекса)
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshBusinessAccountIndicators(businessAccountId: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling refreshBusinessAccountIndicators.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/refreshIndicators`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Пересчитать все показатели операции и нижестроящих сущностей, а также переиндексировать его
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshOperation(businessAccountId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling refreshOperation.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling refreshOperation.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/operations/{id}/refresh`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Пересчитать все показатели продукта и нижестроящих сущностей, а также переиндексировать его
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshProduct(businessAccountId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling refreshProduct.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling refreshProduct.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/products/{id}/refresh`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Пересчитать все показатели проекта и нижестроящих сущностей, а также переиндексировать его
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshProject(businessAccountId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling refreshProject.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling refreshProject.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/projects/{id}/refresh`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Пересчитать все показатели элемента, а также переиндексировать его
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshRentElement(businessAccountId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling refreshRentElement.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling refreshRentElement.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/elements/{id}/refresh`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Пересчитать и переиндексировать все показатели арендатора и нижестроящих сущностей
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshRenter(businessAccountId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling refreshRenter.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling refreshRenter.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/renters/{id}/refresh`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Пересчитать все показатели субаренды и нижестроящих сущностей, а также переиндексировать его
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshSubrent(businessAccountId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling refreshSubrent.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling refreshSubrent.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/subrents/{id}/refresh`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Пересоздание контактов пользователей
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {boolean} removeExistingContactUserLinks Удалить существующие пользовательские контакты перед пересозданием
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regenerateBusinessAccountUserContacts(businessAccountId: number, removeExistingContactUserLinks: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling regenerateBusinessAccountUserContacts.');
            }
            // verify required parameter 'removeExistingContactUserLinks' is not null or undefined
            if (removeExistingContactUserLinks === null || removeExistingContactUserLinks === undefined) {
                throw new RequiredError('removeExistingContactUserLinks','Required parameter removeExistingContactUserLinks was null or undefined when calling regenerateBusinessAccountUserContacts.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/contacts/regenerate-for-users`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (removeExistingContactUserLinks !== undefined) {
                localVarQueryParameter['removeExistingContactUserLinks'] = removeExistingContactUserLinks;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Пересоздание контактов пользователей
         * @param {boolean} removeExistingContactUserLinks Удалить существующие пользовательские контакты перед пересозданием
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regenerateUserContactsGlobally(removeExistingContactUserLinks: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'removeExistingContactUserLinks' is not null or undefined
            if (removeExistingContactUserLinks === null || removeExistingContactUserLinks === undefined) {
                throw new RequiredError('removeExistingContactUserLinks','Required parameter removeExistingContactUserLinks was null or undefined when calling regenerateUserContactsGlobally.');
            }
            const localVarPath = `/global/contacts/regenerate-for-users`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (removeExistingContactUserLinks !== undefined) {
                localVarQueryParameter['removeExistingContactUserLinks'] = removeExistingContactUserLinks;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Регистрация операции и получения параллельных с ней
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {ConcurrentOperationObj} concurrentOperationObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerAndListConcurrentOperations(businessAccountId: number, concurrentOperationObj: ConcurrentOperationObj, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling registerAndListConcurrentOperations.');
            }
            // verify required parameter 'concurrentOperationObj' is not null or undefined
            if (concurrentOperationObj === null || concurrentOperationObj === undefined) {
                throw new RequiredError('concurrentOperationObj','Required parameter concurrentOperationObj was null or undefined when calling registerAndListConcurrentOperations.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/operations/concurrent`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof concurrentOperationObj !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(concurrentOperationObj !== undefined ? concurrentOperationObj : {}) : (concurrentOperationObj || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Регистрация нового бизнес аккаунта
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {BusinessAccountRegistrationObj} businessAccountRegistrationObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerBusinessAccount(timezoneOffsetUTC: number, businessAccountRegistrationObj: BusinessAccountRegistrationObj, options: any = {}): RequestArgs {
            // verify required parameter 'timezoneOffsetUTC' is not null or undefined
            if (timezoneOffsetUTC === null || timezoneOffsetUTC === undefined) {
                throw new RequiredError('timezoneOffsetUTC','Required parameter timezoneOffsetUTC was null or undefined when calling registerBusinessAccount.');
            }
            // verify required parameter 'businessAccountRegistrationObj' is not null or undefined
            if (businessAccountRegistrationObj === null || businessAccountRegistrationObj === undefined) {
                throw new RequiredError('businessAccountRegistrationObj','Required parameter businessAccountRegistrationObj was null or undefined when calling registerBusinessAccount.');
            }
            const localVarPath = `/businessAccounts/register`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (timezoneOffsetUTC !== undefined) {
                localVarQueryParameter['timezoneOffsetUTC'] = timezoneOffsetUTC;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof businessAccountRegistrationObj !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(businessAccountRegistrationObj !== undefined ? businessAccountRegistrationObj : {}) : (businessAccountRegistrationObj || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание организации в системе SSO авторизации и привязка к бизнес аккаунту
         * @param {string} accessToken Обязательный токен доступа в формате UUID
         * @param {SsoClientOrganizationInfoWrite} ssoClientOrganizationInfoWrite 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerSsoClientOrganization(accessToken: string, ssoClientOrganizationInfoWrite: SsoClientOrganizationInfoWrite, options: any = {}): RequestArgs {
            // verify required parameter 'accessToken' is not null or undefined
            if (accessToken === null || accessToken === undefined) {
                throw new RequiredError('accessToken','Required parameter accessToken was null or undefined when calling registerSsoClientOrganization.');
            }
            // verify required parameter 'ssoClientOrganizationInfoWrite' is not null or undefined
            if (ssoClientOrganizationInfoWrite === null || ssoClientOrganizationInfoWrite === undefined) {
                throw new RequiredError('ssoClientOrganizationInfoWrite','Required parameter ssoClientOrganizationInfoWrite was null or undefined when calling registerSsoClientOrganization.');
            }
            const localVarPath = `/public/v1/server/ssoClientOrganizations`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (accessToken !== undefined) {
                localVarQueryParameter['accessToken'] = accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof ssoClientOrganizationInfoWrite !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(ssoClientOrganizationInfoWrite !== undefined ? ssoClientOrganizationInfoWrite : {}) : (ssoClientOrganizationInfoWrite || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление из списка закрытой операции
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} uuid Временный UUID идентификатор операции
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeConcurrentOperation(businessAccountId: number, uuid: string, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling removeConcurrentOperation.');
            }
            // verify required parameter 'uuid' is not null or undefined
            if (uuid === null || uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling removeConcurrentOperation.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/operations/concurrent`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (uuid !== undefined) {
                localVarQueryParameter['uuid'] = uuid;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка релевантных адресов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} addressQuery Строка с частью адреса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestAddresses(businessAccountId: number, addressQuery: string, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling suggestAddresses.');
            }
            // verify required parameter 'addressQuery' is not null or undefined
            if (addressQuery === null || addressQuery === undefined) {
                throw new RequiredError('addressQuery','Required parameter addressQuery was null or undefined when calling suggestAddresses.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/addresses/suggest`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (addressQuery !== undefined) {
                localVarQueryParameter['addressQuery'] = addressQuery;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение статуса и архивация бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirective} workflowDirective 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitBusinessAccountWorkflow(businessAccountId: number, workflowDirective: WorkflowDirective, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling transitBusinessAccountWorkflow.');
            }
            // verify required parameter 'workflowDirective' is not null or undefined
            if (workflowDirective === null || workflowDirective === undefined) {
                throw new RequiredError('workflowDirective','Required parameter workflowDirective was null or undefined when calling transitBusinessAccountWorkflow.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/transit`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof workflowDirective !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(workflowDirective !== undefined ? workflowDirective : {}) : (workflowDirective || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Массовое изменение бизнес аккаунтов - статусы и архивирование
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitBusinessAccountWorkflowBulk(workflowDirectiveBulk: WorkflowDirectiveBulk, options: any = {}): RequestArgs {
            // verify required parameter 'workflowDirectiveBulk' is not null or undefined
            if (workflowDirectiveBulk === null || workflowDirectiveBulk === undefined) {
                throw new RequiredError('workflowDirectiveBulk','Required parameter workflowDirectiveBulk was null or undefined when calling transitBusinessAccountWorkflowBulk.');
            }
            const localVarPath = `/businessAccounts`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof workflowDirectiveBulk !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(workflowDirectiveBulk !== undefined ? workflowDirectiveBulk : {}) : (workflowDirectiveBulk || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Массовое изменение контактов - архивирование и разархивирование
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitContactWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling transitContactWorkflowBulk.');
            }
            // verify required parameter 'workflowDirectiveBulk' is not null or undefined
            if (workflowDirectiveBulk === null || workflowDirectiveBulk === undefined) {
                throw new RequiredError('workflowDirectiveBulk','Required parameter workflowDirectiveBulk was null or undefined when calling transitContactWorkflowBulk.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/contacts`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof workflowDirectiveBulk !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(workflowDirectiveBulk !== undefined ? workflowDirectiveBulk : {}) : (workflowDirectiveBulk || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Массовое изменение кадров - статусы и архивирование
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitCrewMemberWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling transitCrewMemberWorkflowBulk.');
            }
            // verify required parameter 'workflowDirectiveBulk' is not null or undefined
            if (workflowDirectiveBulk === null || workflowDirectiveBulk === undefined) {
                throw new RequiredError('workflowDirectiveBulk','Required parameter workflowDirectiveBulk was null or undefined when calling transitCrewMemberWorkflowBulk.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/crew-members`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof workflowDirectiveBulk !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(workflowDirectiveBulk !== undefined ? workflowDirectiveBulk : {}) : (workflowDirectiveBulk || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Массовое изменение затрат - статусы
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitExpenseWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling transitExpenseWorkflowBulk.');
            }
            // verify required parameter 'workflowDirectiveBulk' is not null or undefined
            if (workflowDirectiveBulk === null || workflowDirectiveBulk === undefined) {
                throw new RequiredError('workflowDirectiveBulk','Required parameter workflowDirectiveBulk was null or undefined when calling transitExpenseWorkflowBulk.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/expense`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof workflowDirectiveBulk !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(workflowDirectiveBulk !== undefined ? workflowDirectiveBulk : {}) : (workflowDirectiveBulk || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Массовое изменение наборы - статусы и архивирование
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitKitWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling transitKitWorkflowBulk.');
            }
            // verify required parameter 'workflowDirectiveBulk' is not null or undefined
            if (workflowDirectiveBulk === null || workflowDirectiveBulk === undefined) {
                throw new RequiredError('workflowDirectiveBulk','Required parameter workflowDirectiveBulk was null or undefined when calling transitKitWorkflowBulk.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/kits`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof workflowDirectiveBulk !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(workflowDirectiveBulk !== undefined ? workflowDirectiveBulk : {}) : (workflowDirectiveBulk || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Массовое изменение локаций - архивирование и разархивирование
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitLocationWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling transitLocationWorkflowBulk.');
            }
            // verify required parameter 'workflowDirectiveBulk' is not null or undefined
            if (workflowDirectiveBulk === null || workflowDirectiveBulk === undefined) {
                throw new RequiredError('workflowDirectiveBulk','Required parameter workflowDirectiveBulk was null or undefined when calling transitLocationWorkflowBulk.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/locations`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof workflowDirectiveBulk !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(workflowDirectiveBulk !== undefined ? workflowDirectiveBulk : {}) : (workflowDirectiveBulk || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Массовое изменение номенклатуры - статусы и архивирование
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {NomenclatureWorkflowDirectiveBulk} nomenclatureWorkflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitNomenclatureWorkflowBulk(businessAccountId: number, nomenclatureWorkflowDirectiveBulk: NomenclatureWorkflowDirectiveBulk, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling transitNomenclatureWorkflowBulk.');
            }
            // verify required parameter 'nomenclatureWorkflowDirectiveBulk' is not null or undefined
            if (nomenclatureWorkflowDirectiveBulk === null || nomenclatureWorkflowDirectiveBulk === undefined) {
                throw new RequiredError('nomenclatureWorkflowDirectiveBulk','Required parameter nomenclatureWorkflowDirectiveBulk was null or undefined when calling transitNomenclatureWorkflowBulk.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/nomenclature`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof nomenclatureWorkflowDirectiveBulk !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(nomenclatureWorkflowDirectiveBulk !== undefined ? nomenclatureWorkflowDirectiveBulk : {}) : (nomenclatureWorkflowDirectiveBulk || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Массовое изменение способов оплаты - только архивирование
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitPaymentMethodsWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling transitPaymentMethodsWorkflowBulk.');
            }
            // verify required parameter 'workflowDirectiveBulk' is not null or undefined
            if (workflowDirectiveBulk === null || workflowDirectiveBulk === undefined) {
                throw new RequiredError('workflowDirectiveBulk','Required parameter workflowDirectiveBulk was null or undefined when calling transitPaymentMethodsWorkflowBulk.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/payment-methods`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof workflowDirectiveBulk !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(workflowDirectiveBulk !== undefined ? workflowDirectiveBulk : {}) : (workflowDirectiveBulk || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Массовое изменение статуса платежа в жизненном цикле
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitPaymentsWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling transitPaymentsWorkflowBulk.');
            }
            // verify required parameter 'workflowDirectiveBulk' is not null or undefined
            if (workflowDirectiveBulk === null || workflowDirectiveBulk === undefined) {
                throw new RequiredError('workflowDirectiveBulk','Required parameter workflowDirectiveBulk was null or undefined when calling transitPaymentsWorkflowBulk.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/payments`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof workflowDirectiveBulk !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(workflowDirectiveBulk !== undefined ? workflowDirectiveBulk : {}) : (workflowDirectiveBulk || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Массовое изменение экземпляров продукта - только статусы
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitProductInstanceWorkflowBulk(businessAccountId: number, id: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling transitProductInstanceWorkflowBulk.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling transitProductInstanceWorkflowBulk.');
            }
            // verify required parameter 'workflowDirectiveBulk' is not null or undefined
            if (workflowDirectiveBulk === null || workflowDirectiveBulk === undefined) {
                throw new RequiredError('workflowDirectiveBulk','Required parameter workflowDirectiveBulk was null or undefined when calling transitProductInstanceWorkflowBulk.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/products/{id}/instances`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof workflowDirectiveBulk !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(workflowDirectiveBulk !== undefined ? workflowDirectiveBulk : {}) : (workflowDirectiveBulk || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Массовое изменение вариантов - только архивирование
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitProductVariantWorkflowBulk(businessAccountId: number, id: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling transitProductVariantWorkflowBulk.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling transitProductVariantWorkflowBulk.');
            }
            // verify required parameter 'workflowDirectiveBulk' is not null or undefined
            if (workflowDirectiveBulk === null || workflowDirectiveBulk === undefined) {
                throw new RequiredError('workflowDirectiveBulk','Required parameter workflowDirectiveBulk was null or undefined when calling transitProductVariantWorkflowBulk.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/products/{id}/variants`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof workflowDirectiveBulk !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(workflowDirectiveBulk !== undefined ? workflowDirectiveBulk : {}) : (workflowDirectiveBulk || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Массовое изменение продуктов - статусы и архивирование
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitProductWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling transitProductWorkflowBulk.');
            }
            // verify required parameter 'workflowDirectiveBulk' is not null or undefined
            if (workflowDirectiveBulk === null || workflowDirectiveBulk === undefined) {
                throw new RequiredError('workflowDirectiveBulk','Required parameter workflowDirectiveBulk was null or undefined when calling transitProductWorkflowBulk.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/products`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof workflowDirectiveBulk !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(workflowDirectiveBulk !== undefined ? workflowDirectiveBulk : {}) : (workflowDirectiveBulk || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Массовое изменение профессий - статусы и архивирование
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitProfessionWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling transitProfessionWorkflowBulk.');
            }
            // verify required parameter 'workflowDirectiveBulk' is not null or undefined
            if (workflowDirectiveBulk === null || workflowDirectiveBulk === undefined) {
                throw new RequiredError('workflowDirectiveBulk','Required parameter workflowDirectiveBulk was null or undefined when calling transitProfessionWorkflowBulk.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/professions`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof workflowDirectiveBulk !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(workflowDirectiveBulk !== undefined ? workflowDirectiveBulk : {}) : (workflowDirectiveBulk || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Массовое изменение проектов - статусы и архивирование
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitProjectWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling transitProjectWorkflowBulk.');
            }
            // verify required parameter 'workflowDirectiveBulk' is not null or undefined
            if (workflowDirectiveBulk === null || workflowDirectiveBulk === undefined) {
                throw new RequiredError('workflowDirectiveBulk','Required parameter workflowDirectiveBulk was null or undefined when calling transitProjectWorkflowBulk.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/projects`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof workflowDirectiveBulk !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(workflowDirectiveBulk !== undefined ? workflowDirectiveBulk : {}) : (workflowDirectiveBulk || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Массовое изменение аредаторов - статусы и архивирование
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitRenterWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling transitRenterWorkflowBulk.');
            }
            // verify required parameter 'workflowDirectiveBulk' is not null or undefined
            if (workflowDirectiveBulk === null || workflowDirectiveBulk === undefined) {
                throw new RequiredError('workflowDirectiveBulk','Required parameter workflowDirectiveBulk was null or undefined when calling transitRenterWorkflowBulk.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/renters`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof workflowDirectiveBulk !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(workflowDirectiveBulk !== undefined ? workflowDirectiveBulk : {}) : (workflowDirectiveBulk || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Массовое изменение субаренд - статусы и архивирование
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitSubrentWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling transitSubrentWorkflowBulk.');
            }
            // verify required parameter 'workflowDirectiveBulk' is not null or undefined
            if (workflowDirectiveBulk === null || workflowDirectiveBulk === undefined) {
                throw new RequiredError('workflowDirectiveBulk','Required parameter workflowDirectiveBulk was null or undefined when calling transitSubrentWorkflowBulk.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/subrents`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof workflowDirectiveBulk !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(workflowDirectiveBulk !== undefined ? workflowDirectiveBulk : {}) : (workflowDirectiveBulk || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Массовое изменение шаблонов - статусы и архивирование
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitTemplateWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling transitTemplateWorkflowBulk.');
            }
            // verify required parameter 'workflowDirectiveBulk' is not null or undefined
            if (workflowDirectiveBulk === null || workflowDirectiveBulk === undefined) {
                throw new RequiredError('workflowDirectiveBulk','Required parameter workflowDirectiveBulk was null or undefined when calling transitTemplateWorkflowBulk.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/templates`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof workflowDirectiveBulk !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(workflowDirectiveBulk !== undefined ? workflowDirectiveBulk : {}) : (workflowDirectiveBulk || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Массовое изменение локаций - архивирование и разархивирование
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitTransportationWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling transitTransportationWorkflowBulk.');
            }
            // verify required parameter 'workflowDirectiveBulk' is not null or undefined
            if (workflowDirectiveBulk === null || workflowDirectiveBulk === undefined) {
                throw new RequiredError('workflowDirectiveBulk','Required parameter workflowDirectiveBulk was null or undefined when calling transitTransportationWorkflowBulk.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/transportations`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof workflowDirectiveBulk !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(workflowDirectiveBulk !== undefined ? workflowDirectiveBulk : {}) : (workflowDirectiveBulk || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Массовое изменение локаций - архивирование и разархивирование
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitVehicleWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling transitVehicleWorkflowBulk.');
            }
            // verify required parameter 'workflowDirectiveBulk' is not null or undefined
            if (workflowDirectiveBulk === null || workflowDirectiveBulk === undefined) {
                throw new RequiredError('workflowDirectiveBulk','Required parameter workflowDirectiveBulk was null or undefined when calling transitVehicleWorkflowBulk.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/vehicles`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof workflowDirectiveBulk !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(workflowDirectiveBulk !== undefined ? workflowDirectiveBulk : {}) : (workflowDirectiveBulk || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Массовое изменение событий работ
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitWorkPlanningWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling transitWorkPlanningWorkflowBulk.');
            }
            // verify required parameter 'workflowDirectiveBulk' is not null or undefined
            if (workflowDirectiveBulk === null || workflowDirectiveBulk === undefined) {
                throw new RequiredError('workflowDirectiveBulk','Required parameter workflowDirectiveBulk was null or undefined when calling transitWorkPlanningWorkflowBulk.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/work-plannings`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof workflowDirectiveBulk !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(workflowDirectiveBulk !== undefined ? workflowDirectiveBulk : {}) : (workflowDirectiveBulk || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение информации о вложении
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {AttachmentInfoUpdate} attachmentInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAttachmentById(businessAccountId: number, id: number, attachmentInfoUpdate: AttachmentInfoUpdate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling updateAttachmentById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateAttachmentById.');
            }
            // verify required parameter 'attachmentInfoUpdate' is not null or undefined
            if (attachmentInfoUpdate === null || attachmentInfoUpdate === undefined) {
                throw new RequiredError('attachmentInfoUpdate','Required parameter attachmentInfoUpdate was null or undefined when calling updateAttachmentById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/attachments/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof attachmentInfoUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(attachmentInfoUpdate !== undefined ? attachmentInfoUpdate : {}) : (attachmentInfoUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение состояния бизнес аккаунта (полный доступ к данным)
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {BusinessAccountInfoUpdate} businessAccountInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBusinessAccountById(businessAccountId: number, businessAccountInfoUpdate: BusinessAccountInfoUpdate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling updateBusinessAccountById.');
            }
            // verify required parameter 'businessAccountInfoUpdate' is not null or undefined
            if (businessAccountInfoUpdate === null || businessAccountInfoUpdate === undefined) {
                throw new RequiredError('businessAccountInfoUpdate','Required parameter businessAccountInfoUpdate was null or undefined when calling updateBusinessAccountById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof businessAccountInfoUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(businessAccountInfoUpdate !== undefined ? businessAccountInfoUpdate : {}) : (businessAccountInfoUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение контактных данных бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {BusinessAccountContactsInfoUpdate} businessAccountContactsInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBusinessAccountContactsById(businessAccountId: number, businessAccountContactsInfoUpdate: BusinessAccountContactsInfoUpdate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling updateBusinessAccountContactsById.');
            }
            // verify required parameter 'businessAccountContactsInfoUpdate' is not null or undefined
            if (businessAccountContactsInfoUpdate === null || businessAccountContactsInfoUpdate === undefined) {
                throw new RequiredError('businessAccountContactsInfoUpdate','Required parameter businessAccountContactsInfoUpdate was null or undefined when calling updateBusinessAccountContactsById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/contacts-information`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof businessAccountContactsInfoUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(businessAccountContactsInfoUpdate !== undefined ? businessAccountContactsInfoUpdate : {}) : (businessAccountContactsInfoUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение основных настроек бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {BusinessAccountPreferencesInfoUpdate} businessAccountPreferencesInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBusinessAccountPreferences(businessAccountId: number, businessAccountPreferencesInfoUpdate: BusinessAccountPreferencesInfoUpdate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling updateBusinessAccountPreferences.');
            }
            // verify required parameter 'businessAccountPreferencesInfoUpdate' is not null or undefined
            if (businessAccountPreferencesInfoUpdate === null || businessAccountPreferencesInfoUpdate === undefined) {
                throw new RequiredError('businessAccountPreferencesInfoUpdate','Required parameter businessAccountPreferencesInfoUpdate was null or undefined when calling updateBusinessAccountPreferences.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/preferences`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof businessAccountPreferencesInfoUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(businessAccountPreferencesInfoUpdate !== undefined ? businessAccountPreferencesInfoUpdate : {}) : (businessAccountPreferencesInfoUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение состояния категории
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {CategoryInfoUpdate} categoryInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCategoryById(businessAccountId: number, id: number, categoryInfoUpdate: CategoryInfoUpdate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling updateCategoryById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateCategoryById.');
            }
            // verify required parameter 'categoryInfoUpdate' is not null or undefined
            if (categoryInfoUpdate === null || categoryInfoUpdate === undefined) {
                throw new RequiredError('categoryInfoUpdate','Required parameter categoryInfoUpdate was null or undefined when calling updateCategoryById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/categories/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof categoryInfoUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(categoryInfoUpdate !== undefined ? categoryInfoUpdate : {}) : (categoryInfoUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение состояния категории с явным указанием полей
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {Array<object>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCategoryExplicit(businessAccountId: number, requestBody: Array<object>, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling updateCategoryExplicit.');
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling updateCategoryExplicit.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/categories/explicit`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof requestBody !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestBody !== undefined ? requestBody : {}) : (requestBody || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение профиля клиентского пользователя
         * @param {ClientUserInfoUpdate} clientUserInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClientUser(clientUserInfoUpdate: ClientUserInfoUpdate, options: any = {}): RequestArgs {
            // verify required parameter 'clientUserInfoUpdate' is not null or undefined
            if (clientUserInfoUpdate === null || clientUserInfoUpdate === undefined) {
                throw new RequiredError('clientUserInfoUpdate','Required parameter clientUserInfoUpdate was null or undefined when calling updateClientUser.');
            }
            const localVarPath = `/public/v1/client/users`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof clientUserInfoUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(clientUserInfoUpdate !== undefined ? clientUserInfoUpdate : {}) : (clientUserInfoUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Обновляет стандартный шаблон документа и сущность в бд
         * @summary Обновляет стандартный шаблон документа
         * @param {number} id Идентификатор сущности
         * @param {any} file 
         * @param {boolean} ignoreFile Если true, изменение контента шаблона игнорируется
         * @param {DocumentTemplateInfoUpdate} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCommonDocumentTemplate(id: number, file: any, ignoreFile: boolean, data: DocumentTemplateInfoUpdate, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateCommonDocumentTemplate.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling updateCommonDocumentTemplate.');
            }
            // verify required parameter 'ignoreFile' is not null or undefined
            if (ignoreFile === null || ignoreFile === undefined) {
                throw new RequiredError('ignoreFile','Required parameter ignoreFile was null or undefined when calling updateCommonDocumentTemplate.');
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling updateCommonDocumentTemplate.');
            }
            const localVarPath = `/global/documentTemplates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (ignoreFile !== undefined) { 
                localVarFormParams.append('ignoreFile', ignoreFile as any);
            }
    
            if (data !== undefined) { 
                localVarFormParams.append('data', data as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновление контента стандартного шаблона документа
         * @param {number} id Идентификатор сущности
         * @param {any} file 
         * @param {number} businessVersion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCommonDocumentTemplateContent(id: number, file: any, businessVersion: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateCommonDocumentTemplateContent.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling updateCommonDocumentTemplateContent.');
            }
            // verify required parameter 'businessVersion' is not null or undefined
            if (businessVersion === null || businessVersion === undefined) {
                throw new RequiredError('businessVersion','Required parameter businessVersion was null or undefined when calling updateCommonDocumentTemplateContent.');
            }
            const localVarPath = `/global/documentTemplates/{id}/content`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (businessVersion !== undefined) { 
                localVarFormParams.append('businessVersion', businessVersion as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение контакта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {ContactInfoUpdate} contactInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContactById(businessAccountId: number, id: number, contactInfoUpdate: ContactInfoUpdate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling updateContactById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateContactById.');
            }
            // verify required parameter 'contactInfoUpdate' is not null or undefined
            if (contactInfoUpdate === null || contactInfoUpdate === undefined) {
                throw new RequiredError('contactInfoUpdate','Required parameter contactInfoUpdate was null or undefined when calling updateContactById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/contacts/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof contactInfoUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(contactInfoUpdate !== undefined ? contactInfoUpdate : {}) : (contactInfoUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение кадра
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {CrewMemberInfoUpdate} crewMemberInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCrewMember(businessAccountId: number, id: number, crewMemberInfoUpdate: CrewMemberInfoUpdate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling updateCrewMember.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateCrewMember.');
            }
            // verify required parameter 'crewMemberInfoUpdate' is not null or undefined
            if (crewMemberInfoUpdate === null || crewMemberInfoUpdate === undefined) {
                throw new RequiredError('crewMemberInfoUpdate','Required parameter crewMemberInfoUpdate was null or undefined when calling updateCrewMember.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/crew-members/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof crewMemberInfoUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(crewMemberInfoUpdate !== undefined ? crewMemberInfoUpdate : {}) : (crewMemberInfoUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение ставки кадра
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {Array<ProfessionAssignmentObjWrite>} professionAssignmentObjWrite 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCrewMemberRates(businessAccountId: number, id: number, professionAssignmentObjWrite: Array<ProfessionAssignmentObjWrite>, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling updateCrewMemberRates.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateCrewMemberRates.');
            }
            // verify required parameter 'professionAssignmentObjWrite' is not null or undefined
            if (professionAssignmentObjWrite === null || professionAssignmentObjWrite === undefined) {
                throw new RequiredError('professionAssignmentObjWrite','Required parameter professionAssignmentObjWrite was null or undefined when calling updateCrewMemberRates.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/crew-members/{id}/rates`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof professionAssignmentObjWrite !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(professionAssignmentObjWrite !== undefined ? professionAssignmentObjWrite : {}) : (professionAssignmentObjWrite || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение состояния настроек уведомлений текущего пользователя
         * @param {NotificationSchemaInfoUpdate} notificationSchemaInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCurrentNotificationSchema(notificationSchemaInfoUpdate: NotificationSchemaInfoUpdate, options: any = {}): RequestArgs {
            // verify required parameter 'notificationSchemaInfoUpdate' is not null or undefined
            if (notificationSchemaInfoUpdate === null || notificationSchemaInfoUpdate === undefined) {
                throw new RequiredError('notificationSchemaInfoUpdate','Required parameter notificationSchemaInfoUpdate was null or undefined when calling updateCurrentNotificationSchema.');
            }
            const localVarPath = `/currentProfile/notificationSchema`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof notificationSchemaInfoUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(notificationSchemaInfoUpdate !== undefined ? notificationSchemaInfoUpdate : {}) : (notificationSchemaInfoUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение пользовательского поля
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {CustomFieldInfoUpdate} customFieldInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomFieldById(businessAccountId: number, id: number, customFieldInfoUpdate: CustomFieldInfoUpdate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling updateCustomFieldById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateCustomFieldById.');
            }
            // verify required parameter 'customFieldInfoUpdate' is not null or undefined
            if (customFieldInfoUpdate === null || customFieldInfoUpdate === undefined) {
                throw new RequiredError('customFieldInfoUpdate','Required parameter customFieldInfoUpdate was null or undefined when calling updateCustomFieldById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/customFields/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof customFieldInfoUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(customFieldInfoUpdate !== undefined ? customFieldInfoUpdate : {}) : (customFieldInfoUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение всех пользовательских полей по типу сущности
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {string} nomenclatureType Тип номенклатуры для печати
         * @param {CustomFieldsGlobalObjWrite} customFieldsGlobalObjWrite 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomFieldsByEntityType(businessAccountId: number, id: number, nomenclatureType: string, customFieldsGlobalObjWrite: CustomFieldsGlobalObjWrite, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling updateCustomFieldsByEntityType.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateCustomFieldsByEntityType.');
            }
            // verify required parameter 'nomenclatureType' is not null or undefined
            if (nomenclatureType === null || nomenclatureType === undefined) {
                throw new RequiredError('nomenclatureType','Required parameter nomenclatureType was null or undefined when calling updateCustomFieldsByEntityType.');
            }
            // verify required parameter 'customFieldsGlobalObjWrite' is not null or undefined
            if (customFieldsGlobalObjWrite === null || customFieldsGlobalObjWrite === undefined) {
                throw new RequiredError('customFieldsGlobalObjWrite','Required parameter customFieldsGlobalObjWrite was null or undefined when calling updateCustomFieldsByEntityType.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/customFieldsGlobal/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (nomenclatureType !== undefined) {
                localVarQueryParameter['nomenclatureType'] = nomenclatureType;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof customFieldsGlobalObjWrite !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(customFieldsGlobalObjWrite !== undefined ? customFieldsGlobalObjWrite : {}) : (customFieldsGlobalObjWrite || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение привязки кастомных полей к продуктам
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {boolean} heckOnly Просто проверка, если true - изменений не производится, только подсчет сущностей, которые будут заторнуты
         * @param {UpdateLinksByCategoryObj} updateLinksByCategoryObj 
         * @param {boolean} [linkVariants] Привязать поля к вариантам продукта, если он поддерживает варианты. Используется только при привязке
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomFieldsLinks(businessAccountId: number, heckOnly: boolean, updateLinksByCategoryObj: UpdateLinksByCategoryObj, linkVariants?: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling updateCustomFieldsLinks.');
            }
            // verify required parameter 'heckOnly' is not null or undefined
            if (heckOnly === null || heckOnly === undefined) {
                throw new RequiredError('heckOnly','Required parameter heckOnly was null or undefined when calling updateCustomFieldsLinks.');
            }
            // verify required parameter 'updateLinksByCategoryObj' is not null or undefined
            if (updateLinksByCategoryObj === null || updateLinksByCategoryObj === undefined) {
                throw new RequiredError('updateLinksByCategoryObj','Required parameter updateLinksByCategoryObj was null or undefined when calling updateCustomFieldsLinks.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/customFields/updateLinks`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (linkVariants !== undefined) {
                localVarQueryParameter['linkVariants'] = linkVariants;
            }

            if (heckOnly !== undefined) {
                localVarQueryParameter['сheckOnly'] = heckOnly;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof updateLinksByCategoryObj !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateLinksByCategoryObj !== undefined ? updateLinksByCategoryObj : {}) : (updateLinksByCategoryObj || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновление существующего шаблона документа
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {any} file 
         * @param {boolean} ignoreFile Если true, изменение контента шаблона игнорируется
         * @param {DocumentTemplateInfoUpdate} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocumentTemplate(businessAccountId: number, id: number, file: any, ignoreFile: boolean, data: DocumentTemplateInfoUpdate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling updateDocumentTemplate.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateDocumentTemplate.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling updateDocumentTemplate.');
            }
            // verify required parameter 'ignoreFile' is not null or undefined
            if (ignoreFile === null || ignoreFile === undefined) {
                throw new RequiredError('ignoreFile','Required parameter ignoreFile was null or undefined when calling updateDocumentTemplate.');
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling updateDocumentTemplate.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/documentTemplates/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (ignoreFile !== undefined) { 
                localVarFormParams.append('ignoreFile', ignoreFile as any);
            }
    
            if (data !== undefined) { 
                localVarFormParams.append('data', data as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Обновление настроек для шаблона
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {boolean} common Флаг типа шаблона
         * @param {number} businessVersion Бизнес версия
         * @param {boolean} hidden Флаг является ли шаблон скрытым в БА
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocumentTemplateBusinessAccountPreferences(businessAccountId: number, id: number, common: boolean, businessVersion: number, hidden: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling updateDocumentTemplateBusinessAccountPreferences.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateDocumentTemplateBusinessAccountPreferences.');
            }
            // verify required parameter 'common' is not null or undefined
            if (common === null || common === undefined) {
                throw new RequiredError('common','Required parameter common was null or undefined when calling updateDocumentTemplateBusinessAccountPreferences.');
            }
            // verify required parameter 'businessVersion' is not null or undefined
            if (businessVersion === null || businessVersion === undefined) {
                throw new RequiredError('businessVersion','Required parameter businessVersion was null or undefined when calling updateDocumentTemplateBusinessAccountPreferences.');
            }
            // verify required parameter 'hidden' is not null or undefined
            if (hidden === null || hidden === undefined) {
                throw new RequiredError('hidden','Required parameter hidden was null or undefined when calling updateDocumentTemplateBusinessAccountPreferences.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/documentTemplates/{id}/preferences`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (common !== undefined) {
                localVarQueryParameter['common'] = common;
            }

            if (businessVersion !== undefined) {
                localVarQueryParameter['businessVersion'] = businessVersion;
            }

            if (hidden !== undefined) {
                localVarQueryParameter['hidden'] = hidden;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновление контента пользовательского шаблона документа
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {any} file 
         * @param {number} businessVersion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocumentTemplateContent(businessAccountId: number, id: number, file: any, businessVersion: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling updateDocumentTemplateContent.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateDocumentTemplateContent.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling updateDocumentTemplateContent.');
            }
            // verify required parameter 'businessVersion' is not null or undefined
            if (businessVersion === null || businessVersion === undefined) {
                throw new RequiredError('businessVersion','Required parameter businessVersion was null or undefined when calling updateDocumentTemplateContent.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/documentTemplates/{id}/content`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (businessVersion !== undefined) { 
                localVarFormParams.append('businessVersion', businessVersion as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Изменение сущности затраты по проекту
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {ExpenseInfoUpdate} expenseInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExpense(businessAccountId: number, id: number, expenseInfoUpdate: ExpenseInfoUpdate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling updateExpense.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateExpense.');
            }
            // verify required parameter 'expenseInfoUpdate' is not null or undefined
            if (expenseInfoUpdate === null || expenseInfoUpdate === undefined) {
                throw new RequiredError('expenseInfoUpdate','Required parameter expenseInfoUpdate was null or undefined when calling updateExpense.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/expense/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof expenseInfoUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(expenseInfoUpdate !== undefined ? expenseInfoUpdate : {}) : (expenseInfoUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение шаблона для экспорта
         * @param {number} id Идентификатор сущности
         * @param {ExportTemplateInfoUpdate} model 
         * @param {any} [content] 
         * @param {any} [jasper] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExportTemplate(id: number, model: ExportTemplateInfoUpdate, content?: any, jasper?: any, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateExportTemplate.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling updateExportTemplate.');
            }
            const localVarPath = `/global/analytics/exportTemplate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


            if (model !== undefined) { 
                localVarFormParams.append('model', model as any);
            }
    
            if (content !== undefined) { 
                localVarFormParams.append('content', content as any);
            }
    
            if (jasper !== undefined) { 
                localVarFormParams.append('jasper', jasper as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение события движения инвентаря
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {InventoryMovementInfoUpdate} inventoryMovementInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInventoryMovementById(businessAccountId: number, id: number, inventoryMovementInfoUpdate: InventoryMovementInfoUpdate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling updateInventoryMovementById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateInventoryMovementById.');
            }
            // verify required parameter 'inventoryMovementInfoUpdate' is not null or undefined
            if (inventoryMovementInfoUpdate === null || inventoryMovementInfoUpdate === undefined) {
                throw new RequiredError('inventoryMovementInfoUpdate','Required parameter inventoryMovementInfoUpdate was null or undefined when calling updateInventoryMovementById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/inventory-movements/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof inventoryMovementInfoUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(inventoryMovementInfoUpdate !== undefined ? inventoryMovementInfoUpdate : {}) : (inventoryMovementInfoUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение состояния набора
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {KitInfoUpdate} kitInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKitById(businessAccountId: number, id: number, kitInfoUpdate: KitInfoUpdate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling updateKitById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateKitById.');
            }
            // verify required parameter 'kitInfoUpdate' is not null or undefined
            if (kitInfoUpdate === null || kitInfoUpdate === undefined) {
                throw new RequiredError('kitInfoUpdate','Required parameter kitInfoUpdate was null or undefined when calling updateKitById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/kits/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof kitInfoUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(kitInfoUpdate !== undefined ? kitInfoUpdate : {}) : (kitInfoUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение состояния объекта субъектов и реквизитов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {LegalDetailsInfoWrite} legalDetailsInfoWrite 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLegalDetailById(businessAccountId: number, id: number, legalDetailsInfoWrite: LegalDetailsInfoWrite, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling updateLegalDetailById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateLegalDetailById.');
            }
            // verify required parameter 'legalDetailsInfoWrite' is not null or undefined
            if (legalDetailsInfoWrite === null || legalDetailsInfoWrite === undefined) {
                throw new RequiredError('legalDetailsInfoWrite','Required parameter legalDetailsInfoWrite was null or undefined when calling updateLegalDetailById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/legal-details/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof legalDetailsInfoWrite !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(legalDetailsInfoWrite !== undefined ? legalDetailsInfoWrite : {}) : (legalDetailsInfoWrite || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение локации
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {LocationInfoUpdate} locationInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLocationById(businessAccountId: number, id: number, locationInfoUpdate: LocationInfoUpdate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling updateLocationById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateLocationById.');
            }
            // verify required parameter 'locationInfoUpdate' is not null or undefined
            if (locationInfoUpdate === null || locationInfoUpdate === undefined) {
                throw new RequiredError('locationInfoUpdate','Required parameter locationInfoUpdate was null or undefined when calling updateLocationById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/locations/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof locationInfoUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(locationInfoUpdate !== undefined ? locationInfoUpdate : {}) : (locationInfoUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение состояния операции
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {OperationInfoUpdate} operationInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperationById(businessAccountId: number, id: number, operationInfoUpdate: OperationInfoUpdate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling updateOperationById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateOperationById.');
            }
            // verify required parameter 'operationInfoUpdate' is not null or undefined
            if (operationInfoUpdate === null || operationInfoUpdate === undefined) {
                throw new RequiredError('operationInfoUpdate','Required parameter operationInfoUpdate was null or undefined when calling updateOperationById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/operations/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof operationInfoUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(operationInfoUpdate !== undefined ? operationInfoUpdate : {}) : (operationInfoUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Редактирование простого заказа
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {SimpleOrderInfoUpdate} simpleOrderInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderWithRentElements(businessAccountId: number, id: number, simpleOrderInfoUpdate: SimpleOrderInfoUpdate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling updateOrderWithRentElements.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateOrderWithRentElements.');
            }
            // verify required parameter 'simpleOrderInfoUpdate' is not null or undefined
            if (simpleOrderInfoUpdate === null || simpleOrderInfoUpdate === undefined) {
                throw new RequiredError('simpleOrderInfoUpdate','Required parameter simpleOrderInfoUpdate was null or undefined when calling updateOrderWithRentElements.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/projects/order/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof simpleOrderInfoUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(simpleOrderInfoUpdate !== undefined ? simpleOrderInfoUpdate : {}) : (simpleOrderInfoUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение данных о платеже
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {PaymentInfoUpdate} paymentInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePaymentById(businessAccountId: number, id: number, paymentInfoUpdate: PaymentInfoUpdate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling updatePaymentById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updatePaymentById.');
            }
            // verify required parameter 'paymentInfoUpdate' is not null or undefined
            if (paymentInfoUpdate === null || paymentInfoUpdate === undefined) {
                throw new RequiredError('paymentInfoUpdate','Required parameter paymentInfoUpdate was null or undefined when calling updatePaymentById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/payments/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof paymentInfoUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(paymentInfoUpdate !== undefined ? paymentInfoUpdate : {}) : (paymentInfoUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение состояния способа оплаты
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {PaymentMethodInfoUpdate} paymentMethodInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePaymentMethodById(businessAccountId: number, id: number, paymentMethodInfoUpdate: PaymentMethodInfoUpdate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling updatePaymentMethodById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updatePaymentMethodById.');
            }
            // verify required parameter 'paymentMethodInfoUpdate' is not null or undefined
            if (paymentMethodInfoUpdate === null || paymentMethodInfoUpdate === undefined) {
                throw new RequiredError('paymentMethodInfoUpdate','Required parameter paymentMethodInfoUpdate was null or undefined when calling updatePaymentMethodById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/payment-methods/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof paymentMethodInfoUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(paymentMethodInfoUpdate !== undefined ? paymentMethodInfoUpdate : {}) : (paymentMethodInfoUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение ценовой схемы
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {PricingSchemeInfoUpdate} pricingSchemeInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePricingSchemeById(businessAccountId: number, id: number, pricingSchemeInfoUpdate: PricingSchemeInfoUpdate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling updatePricingSchemeById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updatePricingSchemeById.');
            }
            // verify required parameter 'pricingSchemeInfoUpdate' is not null or undefined
            if (pricingSchemeInfoUpdate === null || pricingSchemeInfoUpdate === undefined) {
                throw new RequiredError('pricingSchemeInfoUpdate','Required parameter pricingSchemeInfoUpdate was null or undefined when calling updatePricingSchemeById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/pricingSchemes/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof pricingSchemeInfoUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(pricingSchemeInfoUpdate !== undefined ? pricingSchemeInfoUpdate : {}) : (pricingSchemeInfoUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение привязки ценовых схем к продуктам
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {UpdateLinksByCategoryObj} updateLinksByCategoryObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePricingSchemesLinks(businessAccountId: number, updateLinksByCategoryObj: UpdateLinksByCategoryObj, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling updatePricingSchemesLinks.');
            }
            // verify required parameter 'updateLinksByCategoryObj' is not null or undefined
            if (updateLinksByCategoryObj === null || updateLinksByCategoryObj === undefined) {
                throw new RequiredError('updateLinksByCategoryObj','Required parameter updateLinksByCategoryObj was null or undefined when calling updatePricingSchemesLinks.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/pricingSchemes/updateLinks`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof updateLinksByCategoryObj !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateLinksByCategoryObj !== undefined ? updateLinksByCategoryObj : {}) : (updateLinksByCategoryObj || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение состояния продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {ProductInfoUpdate} productInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductById(businessAccountId: number, id: number, productInfoUpdate: ProductInfoUpdate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling updateProductById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateProductById.');
            }
            // verify required parameter 'productInfoUpdate' is not null or undefined
            if (productInfoUpdate === null || productInfoUpdate === undefined) {
                throw new RequiredError('productInfoUpdate','Required parameter productInfoUpdate was null or undefined when calling updateProductById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/products/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof productInfoUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(productInfoUpdate !== undefined ? productInfoUpdate : {}) : (productInfoUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение состояния экземпляра продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} productId Идентификатор продукта
         * @param {number} id Идентификатор сущности
         * @param {InstanceInfoUpdate} instanceInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductInstanceById(businessAccountId: number, productId: number, id: number, instanceInfoUpdate: InstanceInfoUpdate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling updateProductInstanceById.');
            }
            // verify required parameter 'productId' is not null or undefined
            if (productId === null || productId === undefined) {
                throw new RequiredError('productId','Required parameter productId was null or undefined when calling updateProductInstanceById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateProductInstanceById.');
            }
            // verify required parameter 'instanceInfoUpdate' is not null or undefined
            if (instanceInfoUpdate === null || instanceInfoUpdate === undefined) {
                throw new RequiredError('instanceInfoUpdate','Required parameter instanceInfoUpdate was null or undefined when calling updateProductInstanceById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/products/{productId}/instances/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof instanceInfoUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(instanceInfoUpdate !== undefined ? instanceInfoUpdate : {}) : (instanceInfoUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение состояния варианта продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} productId Идентификатор продукта
         * @param {number} id Идентификатор сущности
         * @param {VariantInfoUpdate} variantInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductVariantById(businessAccountId: number, productId: number, id: number, variantInfoUpdate: VariantInfoUpdate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling updateProductVariantById.');
            }
            // verify required parameter 'productId' is not null or undefined
            if (productId === null || productId === undefined) {
                throw new RequiredError('productId','Required parameter productId was null or undefined when calling updateProductVariantById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateProductVariantById.');
            }
            // verify required parameter 'variantInfoUpdate' is not null or undefined
            if (variantInfoUpdate === null || variantInfoUpdate === undefined) {
                throw new RequiredError('variantInfoUpdate','Required parameter variantInfoUpdate was null or undefined when calling updateProductVariantById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/products/{productId}/variants/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof variantInfoUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(variantInfoUpdate !== undefined ? variantInfoUpdate : {}) : (variantInfoUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение состояния экземпляра варианта продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} productId Идентификатор продукта
         * @param {number} variantId Идентификатор варианта
         * @param {number} id Идентификатор сущности
         * @param {InstanceInfoUpdate} instanceInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductVariantInstanceById(businessAccountId: number, productId: number, variantId: number, id: number, instanceInfoUpdate: InstanceInfoUpdate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling updateProductVariantInstanceById.');
            }
            // verify required parameter 'productId' is not null or undefined
            if (productId === null || productId === undefined) {
                throw new RequiredError('productId','Required parameter productId was null or undefined when calling updateProductVariantInstanceById.');
            }
            // verify required parameter 'variantId' is not null or undefined
            if (variantId === null || variantId === undefined) {
                throw new RequiredError('variantId','Required parameter variantId was null or undefined when calling updateProductVariantInstanceById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateProductVariantInstanceById.');
            }
            // verify required parameter 'instanceInfoUpdate' is not null or undefined
            if (instanceInfoUpdate === null || instanceInfoUpdate === undefined) {
                throw new RequiredError('instanceInfoUpdate','Required parameter instanceInfoUpdate was null or undefined when calling updateProductVariantInstanceById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/products/{productId}/variants/{variantId}/instances/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)))
                .replace(`{${"variantId"}}`, encodeURIComponent(String(variantId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof instanceInfoUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(instanceInfoUpdate !== undefined ? instanceInfoUpdate : {}) : (instanceInfoUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение профессии
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {ProfessionInfoUpdate} professionInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfessionById(businessAccountId: number, id: number, professionInfoUpdate: ProfessionInfoUpdate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling updateProfessionById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateProfessionById.');
            }
            // verify required parameter 'professionInfoUpdate' is not null or undefined
            if (professionInfoUpdate === null || professionInfoUpdate === undefined) {
                throw new RequiredError('professionInfoUpdate','Required parameter professionInfoUpdate was null or undefined when calling updateProfessionById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/professions/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof professionInfoUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(professionInfoUpdate !== undefined ? professionInfoUpdate : {}) : (professionInfoUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновление профиля пользователя
         * @param {UserInfoUpdate} userInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfileData(userInfoUpdate: UserInfoUpdate, options: any = {}): RequestArgs {
            // verify required parameter 'userInfoUpdate' is not null or undefined
            if (userInfoUpdate === null || userInfoUpdate === undefined) {
                throw new RequiredError('userInfoUpdate','Required parameter userInfoUpdate was null or undefined when calling updateProfileData.');
            }
            const localVarPath = `/currentProfile`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof userInfoUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(userInfoUpdate !== undefined ? userInfoUpdate : {}) : (userInfoUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновление пароля профиля
         * @param {UpdatePassword} updatePassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfilePassword(updatePassword: UpdatePassword, options: any = {}): RequestArgs {
            // verify required parameter 'updatePassword' is not null or undefined
            if (updatePassword === null || updatePassword === undefined) {
                throw new RequiredError('updatePassword','Required parameter updatePassword was null or undefined when calling updateProfilePassword.');
            }
            const localVarPath = `/currentProfile/credentials`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof updatePassword !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updatePassword !== undefined ? updatePassword : {}) : (updatePassword || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение состояния проекта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {ProjectInfoUpdate} projectInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectById(businessAccountId: number, id: number, projectInfoUpdate: ProjectInfoUpdate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling updateProjectById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateProjectById.');
            }
            // verify required parameter 'projectInfoUpdate' is not null or undefined
            if (projectInfoUpdate === null || projectInfoUpdate === undefined) {
                throw new RequiredError('projectInfoUpdate','Required parameter projectInfoUpdate was null or undefined when calling updateProjectById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/projects/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof projectInfoUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(projectInfoUpdate !== undefined ? projectInfoUpdate : {}) : (projectInfoUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение состояния проекта с явным указанием полей
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {Array<object>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectByIdExplicit(businessAccountId: number, id: number, requestBody: Array<object>, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling updateProjectByIdExplicit.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateProjectByIdExplicit.');
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling updateProjectByIdExplicit.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/projects/{id}/explicit`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof requestBody !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestBody !== undefined ? requestBody : {}) : (requestBody || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение состояния арендатора
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {RenterInfoUpdate} renterInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRenterById(businessAccountId: number, id: number, renterInfoUpdate: RenterInfoUpdate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling updateRenterById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateRenterById.');
            }
            // verify required parameter 'renterInfoUpdate' is not null or undefined
            if (renterInfoUpdate === null || renterInfoUpdate === undefined) {
                throw new RequiredError('renterInfoUpdate','Required parameter renterInfoUpdate was null or undefined when calling updateRenterById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/renters/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof renterInfoUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(renterInfoUpdate !== undefined ? renterInfoUpdate : {}) : (renterInfoUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение состояния арендатора с явным указанием полей
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {Array<object>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRenterByIdExplicit(businessAccountId: number, id: number, requestBody: Array<object>, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling updateRenterByIdExplicit.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateRenterByIdExplicit.');
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling updateRenterByIdExplicit.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/renters/{id}/explicit`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof requestBody !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestBody !== undefined ? requestBody : {}) : (requestBody || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновление графической визуализации отчета
         * @param {number} reportId 
         * @param {number} id Идентификатор сущности
         * @param {ReportChartVisualizationInfoUpdate} [reportChartVisualizationInfoUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReportChartVisualizationById(reportId: number, id: number, reportChartVisualizationInfoUpdate?: ReportChartVisualizationInfoUpdate, options: any = {}): RequestArgs {
            // verify required parameter 'reportId' is not null or undefined
            if (reportId === null || reportId === undefined) {
                throw new RequiredError('reportId','Required parameter reportId was null or undefined when calling updateReportChartVisualizationById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateReportChartVisualizationById.');
            }
            const localVarPath = `/global/analytics/reportConfigurations/{reportId}/reportChartVisualization/{id}`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof reportChartVisualizationInfoUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(reportChartVisualizationInfoUpdate !== undefined ? reportChartVisualizationInfoUpdate : {}) : (reportChartVisualizationInfoUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение конфигурации отчета
         * @param {number} id Идентификатор сущности
         * @param {ReportConfigurationInfoUpdate} model 
         * @param {any} [content] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReportConfiguration(id: number, model: ReportConfigurationInfoUpdate, content?: any, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateReportConfiguration.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling updateReportConfiguration.');
            }
            const localVarPath = `/global/analytics/reportConfigurations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


            if (model !== undefined) { 
                localVarFormParams.append('model', model as any);
            }
    
            if (content !== undefined) { 
                localVarFormParams.append('content', content as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновление Эксель визуализации отчета
         * @param {number} reportId 
         * @param {number} id Идентификатор сущности
         * @param {ReportExcelVisualizationInfoUpdate} model 
         * @param {any} [content] 
         * @param {any} [jasper] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReportExcelVisualizationById(reportId: number, id: number, model: ReportExcelVisualizationInfoUpdate, content?: any, jasper?: any, options: any = {}): RequestArgs {
            // verify required parameter 'reportId' is not null or undefined
            if (reportId === null || reportId === undefined) {
                throw new RequiredError('reportId','Required parameter reportId was null or undefined when calling updateReportExcelVisualizationById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateReportExcelVisualizationById.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling updateReportExcelVisualizationById.');
            }
            const localVarPath = `/global/analytics/reportConfigurations/{reportId}/reportExcelVisualizations/{id}`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


            if (model !== undefined) { 
                localVarFormParams.append('model', model as any);
            }
    
            if (content !== undefined) { 
                localVarFormParams.append('content', content as any);
            }
    
            if (jasper !== undefined) { 
                localVarFormParams.append('jasper', jasper as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновление организации в системе SSO авторизации для бизнес аккаунта
         * @param {string} accessToken Обязательный токен доступа в формате UUID
         * @param {SsoClientOrganizationInfoWrite} ssoClientOrganizationInfoWrite 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSsoClientOrganization(accessToken: string, ssoClientOrganizationInfoWrite: SsoClientOrganizationInfoWrite, options: any = {}): RequestArgs {
            // verify required parameter 'accessToken' is not null or undefined
            if (accessToken === null || accessToken === undefined) {
                throw new RequiredError('accessToken','Required parameter accessToken was null or undefined when calling updateSsoClientOrganization.');
            }
            // verify required parameter 'ssoClientOrganizationInfoWrite' is not null or undefined
            if (ssoClientOrganizationInfoWrite === null || ssoClientOrganizationInfoWrite === undefined) {
                throw new RequiredError('ssoClientOrganizationInfoWrite','Required parameter ssoClientOrganizationInfoWrite was null or undefined when calling updateSsoClientOrganization.');
            }
            const localVarPath = `/public/v1/server/ssoClientOrganizations`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (accessToken !== undefined) {
                localVarQueryParameter['accessToken'] = accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof ssoClientOrganizationInfoWrite !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(ssoClientOrganizationInfoWrite !== undefined ? ssoClientOrganizationInfoWrite : {}) : (ssoClientOrganizationInfoWrite || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение состояния субаренды
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {SubrentInfoUpdate} subrentInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubrentById(businessAccountId: number, id: number, subrentInfoUpdate: SubrentInfoUpdate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling updateSubrentById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateSubrentById.');
            }
            // verify required parameter 'subrentInfoUpdate' is not null or undefined
            if (subrentInfoUpdate === null || subrentInfoUpdate === undefined) {
                throw new RequiredError('subrentInfoUpdate','Required parameter subrentInfoUpdate was null or undefined when calling updateSubrentById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/subrents/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof subrentInfoUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(subrentInfoUpdate !== undefined ? subrentInfoUpdate : {}) : (subrentInfoUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение состояния системной настройки
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {SystemOptionInfoUpdate} systemOptionInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSystemOptionById(businessAccountId: number, id: number, systemOptionInfoUpdate: SystemOptionInfoUpdate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling updateSystemOptionById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateSystemOptionById.');
            }
            // verify required parameter 'systemOptionInfoUpdate' is not null or undefined
            if (systemOptionInfoUpdate === null || systemOptionInfoUpdate === undefined) {
                throw new RequiredError('systemOptionInfoUpdate','Required parameter systemOptionInfoUpdate was null or undefined when calling updateSystemOptionById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/systemOptions/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof systemOptionInfoUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(systemOptionInfoUpdate !== undefined ? systemOptionInfoUpdate : {}) : (systemOptionInfoUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение состояния системной настройки
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {SystemOptionInfoUpdateBulk} systemOptionInfoUpdateBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSystemOptionsBulk(businessAccountId: number, id: number, systemOptionInfoUpdateBulk: SystemOptionInfoUpdateBulk, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling updateSystemOptionsBulk.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateSystemOptionsBulk.');
            }
            // verify required parameter 'systemOptionInfoUpdateBulk' is not null or undefined
            if (systemOptionInfoUpdateBulk === null || systemOptionInfoUpdateBulk === undefined) {
                throw new RequiredError('systemOptionInfoUpdateBulk','Required parameter systemOptionInfoUpdateBulk was null or undefined when calling updateSystemOptionsBulk.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/systemOptionsSections/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof systemOptionInfoUpdateBulk !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(systemOptionInfoUpdateBulk !== undefined ? systemOptionInfoUpdateBulk : {}) : (systemOptionInfoUpdateBulk || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение состояния шаблона
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {TemplateInfoUpdate} templateInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTemplateById(businessAccountId: number, id: number, templateInfoUpdate: TemplateInfoUpdate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling updateTemplateById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateTemplateById.');
            }
            // verify required parameter 'templateInfoUpdate' is not null or undefined
            if (templateInfoUpdate === null || templateInfoUpdate === undefined) {
                throw new RequiredError('templateInfoUpdate','Required parameter templateInfoUpdate was null or undefined when calling updateTemplateById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/templates/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof templateInfoUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(templateInfoUpdate !== undefined ? templateInfoUpdate : {}) : (templateInfoUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение перевозки
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {TransportationInfoUpdate} transportationInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTransportationById(businessAccountId: number, id: number, transportationInfoUpdate: TransportationInfoUpdate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling updateTransportationById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateTransportationById.');
            }
            // verify required parameter 'transportationInfoUpdate' is not null or undefined
            if (transportationInfoUpdate === null || transportationInfoUpdate === undefined) {
                throw new RequiredError('transportationInfoUpdate','Required parameter transportationInfoUpdate was null or undefined when calling updateTransportationById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/transportations/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof transportationInfoUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(transportationInfoUpdate !== undefined ? transportationInfoUpdate : {}) : (transportationInfoUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение транспортного средства
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {VehicleInfoUpdate} vehicleInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVehicleById(businessAccountId: number, id: number, vehicleInfoUpdate: VehicleInfoUpdate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling updateVehicleById.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateVehicleById.');
            }
            // verify required parameter 'vehicleInfoUpdate' is not null or undefined
            if (vehicleInfoUpdate === null || vehicleInfoUpdate === undefined) {
                throw new RequiredError('vehicleInfoUpdate','Required parameter vehicleInfoUpdate was null or undefined when calling updateVehicleById.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/vehicles/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof vehicleInfoUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(vehicleInfoUpdate !== undefined ? vehicleInfoUpdate : {}) : (vehicleInfoUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменить описание работы
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {WorkPlanningsInfoUpdate} workPlanningsInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkPlanning(businessAccountId: number, id: number, workPlanningsInfoUpdate: WorkPlanningsInfoUpdate, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling updateWorkPlanning.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateWorkPlanning.');
            }
            // verify required parameter 'workPlanningsInfoUpdate' is not null or undefined
            if (workPlanningsInfoUpdate === null || workPlanningsInfoUpdate === undefined) {
                throw new RequiredError('workPlanningsInfoUpdate','Required parameter workPlanningsInfoUpdate was null or undefined when calling updateWorkPlanning.');
            }
            const localVarPath = `/businessAccounts/{businessAccountId}/work-plannings/{id}`
                .replace(`{${"businessAccountId"}}`, encodeURIComponent(String(businessAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof workPlanningsInfoUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(workPlanningsInfoUpdate !== undefined ? workPlanningsInfoUpdate : {}) : (workPlanningsInfoUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Загрузка kb-json
         * @param {KnowledgeBaseJson} [knowledgeBaseJson] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadKbJson(knowledgeBaseJson?: KnowledgeBaseJson, options: any = {}): RequestArgs {
            const localVarPath = `/global/kb-json`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof knowledgeBaseJson !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(knowledgeBaseJson !== undefined ? knowledgeBaseJson : {}) : (knowledgeBaseJson || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Загрузка переводов
         * @param {any} content 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadSystemWideTranslations(content: any, options: any = {}): RequestArgs {
            // verify required parameter 'content' is not null or undefined
            if (content === null || content === undefined) {
                throw new RequiredError('content','Required parameter content was null or undefined when calling uploadSystemWideTranslations.');
            }
            const localVarPath = `/global/translations`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


            if (content !== undefined) { 
                localVarFormParams.append('content', content as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Валидация данных к миграции
         * @param {number} businessAccountId 
         * @param {number} targetCreationAuthorId 
         * @param {any} jxlsXMLConfiguration 
         * @param {any} excelFile 
         * @param {any} massImportXMLConfiguration 
         * @param {OnExistingEntityFound} onExistingEntityFound 
         * @param {number} [startFrom] 
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateMigrationPolicy(businessAccountId: number, targetCreationAuthorId: number, jxlsXMLConfiguration: any, excelFile: any, massImportXMLConfiguration: any, onExistingEntityFound: OnExistingEntityFound, startFrom?: number, count?: number, options: any = {}): RequestArgs {
            // verify required parameter 'businessAccountId' is not null or undefined
            if (businessAccountId === null || businessAccountId === undefined) {
                throw new RequiredError('businessAccountId','Required parameter businessAccountId was null or undefined when calling validateMigrationPolicy.');
            }
            // verify required parameter 'targetCreationAuthorId' is not null or undefined
            if (targetCreationAuthorId === null || targetCreationAuthorId === undefined) {
                throw new RequiredError('targetCreationAuthorId','Required parameter targetCreationAuthorId was null or undefined when calling validateMigrationPolicy.');
            }
            // verify required parameter 'jxlsXMLConfiguration' is not null or undefined
            if (jxlsXMLConfiguration === null || jxlsXMLConfiguration === undefined) {
                throw new RequiredError('jxlsXMLConfiguration','Required parameter jxlsXMLConfiguration was null or undefined when calling validateMigrationPolicy.');
            }
            // verify required parameter 'excelFile' is not null or undefined
            if (excelFile === null || excelFile === undefined) {
                throw new RequiredError('excelFile','Required parameter excelFile was null or undefined when calling validateMigrationPolicy.');
            }
            // verify required parameter 'massImportXMLConfiguration' is not null or undefined
            if (massImportXMLConfiguration === null || massImportXMLConfiguration === undefined) {
                throw new RequiredError('massImportXMLConfiguration','Required parameter massImportXMLConfiguration was null or undefined when calling validateMigrationPolicy.');
            }
            // verify required parameter 'onExistingEntityFound' is not null or undefined
            if (onExistingEntityFound === null || onExistingEntityFound === undefined) {
                throw new RequiredError('onExistingEntityFound','Required parameter onExistingEntityFound was null or undefined when calling validateMigrationPolicy.');
            }
            const localVarPath = `/global/migration/validate`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (businessAccountId !== undefined) {
                localVarQueryParameter['businessAccountId'] = businessAccountId;
            }

            if (targetCreationAuthorId !== undefined) {
                localVarQueryParameter['targetCreationAuthorId'] = targetCreationAuthorId;
            }


            if (jxlsXMLConfiguration !== undefined) { 
                localVarFormParams.append('jxlsXMLConfiguration', jxlsXMLConfiguration as any);
            }
    
            if (excelFile !== undefined) { 
                localVarFormParams.append('excelFile', excelFile as any);
            }
    
            if (massImportXMLConfiguration !== undefined) { 
                localVarFormParams.append('massImportXMLConfiguration', massImportXMLConfiguration as any);
            }
    
            if (startFrom !== undefined) { 
                localVarFormParams.append('startFrom', startFrom as any);
            }
    
            if (count !== undefined) { 
                localVarFormParams.append('count', count as any);
            }
    
            if (onExistingEntityFound !== undefined) { 
                localVarFormParams.append('onExistingEntityFound', onExistingEntityFound as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Проверка допустимости email в профиле (не используется другим пользователем, принадлежность к текущему пользователю допустима). Необходим JWT токен пользователя
         * @param {string} email Email пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUniqueEmail(email: string, options: any = {}): RequestArgs {
            // verify required parameter 'email' is not null or undefined
            if (email === null || email === undefined) {
                throw new RequiredError('email','Required parameter email was null or undefined when calling validateUniqueEmail.');
            }
            const localVarPath = `/public/v1/client/users`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Добавление обязательств в существующий проект по шаблону
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {number} templateId Идентификатор шаблона
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRentElementsFromTemplateToExistingProject(businessAccountId: number, id: number, templateId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).addRentElementsFromTemplateToExistingProject(businessAccountId, id, templateId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Назначение ролей пользователю
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {UserBARolesUpdateObj} userBARolesUpdateObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationAssignRolesToUser(businessAccountId: number, id: number, userBARolesUpdateObj: UserBARolesUpdateObj, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).administrationAssignRolesToUser(businessAccountId, id, userBARolesUpdateObj, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение списка записей пользователей бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {'INVITED' | 'ACTIVE' | 'BLOCKED'} [stateFilter] Фильтр по статусу пользователя
         * @param {'AGENT' | 'ADMIN'} [roleFilter] Фильтр по роли пользователя в бизнес аккаунте
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationListBAUsers(businessAccountId: number, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, stateFilter?: 'INVITED' | 'ACTIVE' | 'BLOCKED', roleFilter?: 'AGENT' | 'ADMIN', options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BAUserRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).administrationListBAUsers(businessAccountId, limit, startFrom, sortBy, sortOrder, search, stateFilter, roleFilter, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Ответ на приглашение
         * @param {string} token Код активации приглашения
         * @param {boolean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        answerToTheInvitation(token: string, body: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserBaInvitationInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).answerToTheInvitation(token, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Назначение контакта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {ContactAssignInfo} contactAssignInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignContactToEntity(businessAccountId: number, contactAssignInfo: ContactAssignInfo, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).assignContactToEntity(businessAccountId, contactAssignInfo, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Массовое обновление порядка категорий
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {CategoryTypeCodeEnum} typeCode Тип изменяемых категорий
         * @param {BulkUpdateCategoriesOrder} bulkUpdateCategoriesOrder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkUpdateCategoriesOrder(businessAccountId: number, typeCode: CategoryTypeCodeEnum, bulkUpdateCategoriesOrder: BulkUpdateCategoriesOrder, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).bulkUpdateCategoriesOrder(businessAccountId, typeCode, bulkUpdateCategoriesOrder, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение расчета стоимости и доступности позиций корзины
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} accessToken Обязательный токен доступа в формате UUID
         * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
         * @param {ClientCart} clientCart 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateCart(businessAccountId: number, accessToken: string, storefrontId: number, clientCart: ClientCart, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalculatedCart> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).calculateCart(businessAccountId, accessToken, storefrontId, clientCart, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Пересчет отработанных смен по обязательствам с именованными экземплярами
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} subrentId Идентификатор поставки
         * @param {boolean} updateShifts Признак необходимости обновить смены обязательств субаренды результатом рассчета. Поставить в поле смены получившееся значение поля отработанные смены.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateWorkedShifts(businessAccountId: number, subrentId: number, updateShifts: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).calculateWorkedShifts(businessAccountId, subrentId, updateShifts, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Отмена заказа. Необходим JWT токен пользователя
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
         * @param {string} orderNumber Идентификатор заказа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelOrder(businessAccountId: number, storefrontId: number, orderNumber: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).cancelOrder(businessAccountId, storefrontId, orderNumber, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Перевод проекта в новый статус с помощью корректировки
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {ProjectStateCodeEnum} targetState Целевой статус
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeOrderStateByCorrection(businessAccountId: number, id: number, targetState: ProjectStateCodeEnum, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).changeOrderStateByCorrection(businessAccountId, id, targetState, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Массовая очистка тэгов у указанных сущностей
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {TagEntityTypeCode} entityTypeCode Создать или удалить, true &#x3D; создать, false &#x3D; удалить
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearTagsBulk(businessAccountId: number, entityTypeCode: TagEntityTypeCode, versionedEntityObjList: VersionedEntityObjList, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).clearTagsBulk(businessAccountId, entityTypeCode, versionedEntityObjList, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Преобразование простого заказа в проект
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertOrderToProject(businessAccountId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).convertOrderToProject(businessAccountId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Создание нового вложения. Максимальный объем 10 МБ
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {AttachmentInfoCreate} attachmentInfoCreate 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAttachment(businessAccountId: number, attachmentInfoCreate: AttachmentInfoCreate, file: any, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AttachmentInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createAttachment(businessAccountId, attachmentInfoCreate, file, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Создание нового бизнес аккаунта
         * @param {BusinessAccountInfoCreate} businessAccountInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBusinessAccount(businessAccountInfoCreate: BusinessAccountInfoCreate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessAccountInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createBusinessAccount(businessAccountInfoCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Создание новой категории
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {CategoryInfoCreate} categoryInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCategory(businessAccountId: number, categoryInfoCreate: CategoryInfoCreate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createCategory(businessAccountId, categoryInfoCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Метод создаёт сущность в базе и записывает файл на диск
         * @summary Создание стандартного шаблона документа
         * @param {any} file 
         * @param {DocumentTemplateInfoCreate} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommonDocumentTemplate(file: any, data: DocumentTemplateInfoCreate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentTemplateInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createCommonDocumentTemplate(file, data, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Создание контакта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {ContactInfoCreate} contactInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContact(businessAccountId: number, contactInfoCreate: ContactInfoCreate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createContact(businessAccountId, contactInfoCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Создание контакта с вложениями
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {ContactInfoCreate} [contactInfoCreate] 
         * @param {Array<any>} [attachmentInfoCreates] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContactWithAttachments(businessAccountId: number, contactInfoCreate?: ContactInfoCreate, attachmentInfoCreates?: Array<any>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createContactWithAttachments(businessAccountId, contactInfoCreate, attachmentInfoCreates, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Создание новой операции корректировки
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {OperationInfoCreate} operationInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCorrectionOperation(businessAccountId: number, operationInfoCreate: OperationInfoCreate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperationInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createCorrectionOperation(businessAccountId, operationInfoCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Создание нового кадра
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {CrewMemberInfoCreate} crewMemberInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCrewMember(businessAccountId: number, crewMemberInfoCreate: CrewMemberInfoCreate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CrewMemberInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createCrewMember(businessAccountId, crewMemberInfoCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Создание контакта с вложениями
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {CrewMemberInfoCreate} [contactInfoCreate] 
         * @param {Array<any>} [attachmentInfoCreates] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCrewMemberWithAttachments(businessAccountId: number, contactInfoCreate?: CrewMemberInfoCreate, attachmentInfoCreates?: Array<any>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CrewMemberInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createCrewMemberWithAttachments(businessAccountId, contactInfoCreate, attachmentInfoCreates, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Создание нового пользовательского поля
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {CustomFieldInfoCreate} customFieldInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomField(businessAccountId: number, customFieldInfoCreate: CustomFieldInfoCreate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomFieldInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createCustomField(businessAccountId, customFieldInfoCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Привязка настраиваемых полей к вариантам продукта (создание связей)
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {CustomFieldValueObjWriteList} customFieldValueObjWriteList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomFieldLinksToProductVariants(businessAccountId: number, id: number, customFieldValueObjWriteList: CustomFieldValueObjWriteList, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createCustomFieldLinksToProductVariants(businessAccountId, id, customFieldValueObjWriteList, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Создание нового шаблона документа
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {any} file 
         * @param {DocumentTemplateInfoCreate} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocumentTemplate(businessAccountId: number, file: any, data: DocumentTemplateInfoCreate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentTemplateInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createDocumentTemplate(businessAccountId, file, data, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Создание сущности затраты по проекту
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {ExpenseInfoCreate} expenseInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExpense(businessAccountId: number, expenseInfoCreate: ExpenseInfoCreate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpenseInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createExpense(businessAccountId, expenseInfoCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Создание нового шаблона для экспорта
         * @param {ExportTemplateInfoCreate} model 
         * @param {any} content 
         * @param {any} jasper 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExportTemplate(model: ExportTemplateInfoCreate, content: any, jasper: any, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExportTemplateInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createExportTemplate(model, content, jasper, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Создание нового изображения. Минимальная ширина и высота 200px, максимальный объем ограничен
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createImage(businessAccountId: number, file: any, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatedImageInfo> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createImage(businessAccountId, file, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Массовое создание экземпляров продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {boolean} setInventoryNumber Устанавливать дополнительно инвентарный номер
         * @param {boolean} setBarcode Устанавливать дополнительно штриховой код
         * @param {InstanceInfoCreateBulk} instanceInfoCreateBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInstancesBulk(businessAccountId: number, id: number, setInventoryNumber: boolean, setBarcode: boolean, instanceInfoCreateBulk: InstanceInfoCreateBulk, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createInstancesBulk(businessAccountId, id, setInventoryNumber, setBarcode, instanceInfoCreateBulk, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Создание события движения инвентаря
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {InventoryMovementInfoCreate} inventoryMovementInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInventoryMovement(businessAccountId: number, inventoryMovementInfoCreate: InventoryMovementInfoCreate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InventoryMovementInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createInventoryMovement(businessAccountId, inventoryMovementInfoCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Создание события движения инвентаря с файлами
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {InventoryMovementInfoCreate} [inventoryMovementInfoCreate] 
         * @param {Array<any>} [attachmentInfoCreates] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInventoryMovementWithAttachments(businessAccountId: number, inventoryMovementInfoCreate?: InventoryMovementInfoCreate, attachmentInfoCreates?: Array<any>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InventoryMovementInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createInventoryMovementWithAttachments(businessAccountId, inventoryMovementInfoCreate, attachmentInfoCreates, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Создание нового набора
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {KitInfoCreate} kitInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createKit(businessAccountId: number, kitInfoCreate: KitInfoCreate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<KitInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createKit(businessAccountId, kitInfoCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Создание нового объекта субъектов и реквизитов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {LegalDetailsInfoWrite} legalDetailsInfoWrite 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLegalDetails(businessAccountId: number, legalDetailsInfoWrite: LegalDetailsInfoWrite, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LegalDetailsInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createLegalDetails(businessAccountId, legalDetailsInfoWrite, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Создание новой локации
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {LocationInfoCreate} locationInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLocation(businessAccountId: number, locationInfoCreate: LocationInfoCreate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createLocation(businessAccountId, locationInfoCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {LocationInfoCreate} [contactInfoCreate] 
         * @param {Array<any>} [attachmentInfoCreates] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLocationWithAttachments(businessAccountId: number, contactInfoCreate?: LocationInfoCreate, attachmentInfoCreates?: Array<any>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createLocationWithAttachments(businessAccountId, contactInfoCreate, attachmentInfoCreates, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Создание новой операции
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {OperationInfoCreate} operationInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOperation(businessAccountId: number, operationInfoCreate: OperationInfoCreate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperationInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createOperation(businessAccountId, operationInfoCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Создание заказа через витрину. Необходим JWT токен пользователя
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
         * @param {OrderInfoCreate} orderInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderByClient(businessAccountId: number, storefrontId: number, orderInfoCreate: OrderInfoCreate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createOrderByClient(businessAccountId, storefrontId, orderInfoCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Создание заказа через витрину
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} accessToken Обязательный токен доступа в формате UUID
         * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
         * @param {OrderInfoCreate} orderInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderByServer(businessAccountId: number, accessToken: string, storefrontId: number, orderInfoCreate: OrderInfoCreate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createOrderByServer(businessAccountId, accessToken, storefrontId, orderInfoCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Создание простого заказа
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {SimpleOrderInfoCreate} simpleOrderInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderWithRentElements(businessAccountId: number, simpleOrderInfoCreate: SimpleOrderInfoCreate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createOrderWithRentElements(businessAccountId, simpleOrderInfoCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Создание платежа
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {PaymentInfoCreate} paymentInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPayment(businessAccountId: number, paymentInfoCreate: PaymentInfoCreate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createPayment(businessAccountId, paymentInfoCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Создание нового способа оплаты
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {PaymentMethodInfoCreate} paymentMethodInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPaymentMethod(businessAccountId: number, paymentMethodInfoCreate: PaymentMethodInfoCreate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentMethodInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createPaymentMethod(businessAccountId, paymentMethodInfoCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Создание платежа
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} accessToken Обязательный токен доступа в формате UUID
         * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
         * @param {PublicPaymentInfoCreate} publicPaymentInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPayment_1(businessAccountId: number, accessToken: string, storefrontId: number, publicPaymentInfoCreate: PublicPaymentInfoCreate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createPayment_1(businessAccountId, accessToken, storefrontId, publicPaymentInfoCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Создание новой ценовой схемы
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {PricingSchemeInfoCreate} pricingSchemeInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPricingScheme(businessAccountId: number, pricingSchemeInfoCreate: PricingSchemeInfoCreate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PricingSchemeInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createPricingScheme(businessAccountId, pricingSchemeInfoCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Создание нового продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {ProductInfoCreate} productInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProduct(businessAccountId: number, productInfoCreate: ProductInfoCreate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createProduct(businessAccountId, productInfoCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Создание нового экземпляра продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {InstanceInfoCreate} instanceInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProductInstance(businessAccountId: number, id: number, instanceInfoCreate: InstanceInfoCreate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstanceInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createProductInstance(businessAccountId, id, instanceInfoCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Создание нового варианта продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {VariantInfoCreate} variantInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProductVariant(businessAccountId: number, id: number, variantInfoCreate: VariantInfoCreate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VariantInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createProductVariant(businessAccountId, id, variantInfoCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Создание нового экземпляра варианта продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} productId Идентификатор продукта
         * @param {number} id Идентификатор сущности
         * @param {InstanceInfoCreate} instanceInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProductVariantInstance(businessAccountId: number, productId: number, id: number, instanceInfoCreate: InstanceInfoCreate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstanceInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createProductVariantInstance(businessAccountId, productId, id, instanceInfoCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Создание профессии
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {ProfessionInfoCreate} professionInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProfession(businessAccountId: number, professionInfoCreate: ProfessionInfoCreate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfessionInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createProfession(businessAccountId, professionInfoCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Создание нового проекта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {ProjectInfoCreate} projectInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject(businessAccountId: number, projectInfoCreate: ProjectInfoCreate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createProject(businessAccountId, projectInfoCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Создание нового арендатора
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {RenterInfoCreate} renterInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRenter(businessAccountId: number, renterInfoCreate: RenterInfoCreate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RenterInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createRenter(businessAccountId, renterInfoCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Создание графической визуализации отчета
         * @param {number} reportId 
         * @param {ReportChartVisualizationInfoCreate} [reportChartVisualizationInfoCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReportChartVisualization(reportId: number, reportChartVisualizationInfoCreate?: ReportChartVisualizationInfoCreate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportChartVisualizationInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createReportChartVisualization(reportId, reportChartVisualizationInfoCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Создание новой конфигурации отчёта
         * @param {ReportConfigurationInfoCreate} model 
         * @param {any} content 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReportConfiguration(model: ReportConfigurationInfoCreate, content: any, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportConfigurationInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createReportConfiguration(model, content, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Создание новой Эксель визуализации отчета
         * @param {number} reportId 
         * @param {ReportExcelVisualizationInfoCreate} model 
         * @param {any} content 
         * @param {any} jasper 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReportExcelVisualization(reportId: number, model: ReportExcelVisualizationInfoCreate, content: any, jasper: any, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportExcelVisualizationInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createReportExcelVisualization(reportId, model, content, jasper, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Создание новой субаренды
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {SubrentInfoCreate} subrentInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubrent(businessAccountId: number, subrentInfoCreate: SubrentInfoCreate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubrentInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createSubrent(businessAccountId, subrentInfoCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Создание нового шаблона
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {TemplateInfoCreate} templateInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTemplate(businessAccountId: number, templateInfoCreate: TemplateInfoCreate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplateInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createTemplate(businessAccountId, templateInfoCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Создание новой перевозки
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {TransportationInfoCreate} transportationInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTransportation(businessAccountId: number, transportationInfoCreate: TransportationInfoCreate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransportationInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createTransportation(businessAccountId, transportationInfoCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {TransportationInfoCreate} [contactInfoCreate] 
         * @param {Array<any>} [attachmentInfoCreates] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTransportationWithAttachments(businessAccountId: number, contactInfoCreate?: TransportationInfoCreate, attachmentInfoCreates?: Array<any>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransportationInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createTransportationWithAttachments(businessAccountId, contactInfoCreate, attachmentInfoCreates, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Создание новой транспортного средства
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {VehicleInfoCreate} vehicleInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVehicle(businessAccountId: number, vehicleInfoCreate: VehicleInfoCreate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createVehicle(businessAccountId, vehicleInfoCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {VehicleInfoCreate} [contactInfoCreate] 
         * @param {Array<any>} [attachmentInfoCreates] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVehicleWithAttachments(businessAccountId: number, contactInfoCreate?: VehicleInfoCreate, attachmentInfoCreates?: Array<any>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createVehicleWithAttachments(businessAccountId, contactInfoCreate, attachmentInfoCreates, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Запланировать/Создать описание работы
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkPlanningsInfoCreate} workPlanningsInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkPlanning(businessAccountId: number, workPlanningsInfoCreate: WorkPlanningsInfoCreate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkPlanningsInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createWorkPlanning(businessAccountId, workPlanningsInfoCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Удаление вложения
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {BusinessVersionObj} businessVersionObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAttachmentById(businessAccountId: number, id: number, businessVersionObj: BusinessVersionObj, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteAttachmentById(businessAccountId, id, businessVersionObj, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Удаление бизнес аккаунта (только для тех, которые на триальном тарифе)
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} checkString Проверочная строка, краткое наименование бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBusinessAccountById(businessAccountId: number, checkString: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteBusinessAccountById(businessAccountId, checkString, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Удаление категории
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {boolean} deleteSubcategories Удалить подкатегории
         * @param {boolean} deleteEntityLinks Удалить связи с продуктами (если false - перенести связи на родительскую категорию, связи подкатегорий c продуктами обрабатываются аналогично связям текущей)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCategoryById(businessAccountId: number, id: number, deleteSubcategories: boolean, deleteEntityLinks: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteCategoryById(businessAccountId, id, deleteSubcategories, deleteEntityLinks, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Удаление стандартного шаблона документа по id
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCommonDocumentTemplate(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteCommonDocumentTemplate(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Удаление контакта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {BusinessVersionObj} businessVersionObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContactById(businessAccountId: number, id: number, businessVersionObj: BusinessVersionObj, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteContactById(businessAccountId, id, businessVersionObj, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Удаление контактов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContacts(businessAccountId: number, versionedEntityObjList: VersionedEntityObjList, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteContacts(businessAccountId, versionedEntityObjList, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Удаление кадра
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {BusinessVersionObj} businessVersionObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCrewMember(businessAccountId: number, id: number, businessVersionObj: BusinessVersionObj, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteCrewMember(businessAccountId, id, businessVersionObj, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Удаление ставок кадра
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCrewMemberRates(businessAccountId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteCrewMemberRates(businessAccountId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Массовое удаление сотрудников
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCrewMembersBulk(businessAccountId: number, versionedEntityObjList: VersionedEntityObjList, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteCrewMembersBulk(businessAccountId, versionedEntityObjList, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Удаление пользовательского поля
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomFieldById(businessAccountId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteCustomFieldById(businessAccountId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Отвязка настраиваемого поля от вариантов продукта (удаление связей)
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} productId Идентификатор продукта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomFieldLinksToProductVariants(businessAccountId: number, productId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteCustomFieldLinksToProductVariants(businessAccountId, productId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Удаление шаблона документа по id
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocumentTemplate(businessAccountId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteDocumentTemplate(businessAccountId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Удаление сущности затраты по проекту
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExpense(businessAccountId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteExpense(businessAccountId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Удаление шаблона для экспорта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExportTemplate(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteExportTemplate(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Удаление изображения
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImageById(businessAccountId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteImageById(businessAccountId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Удаление набора
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {BusinessVersionObj} businessVersionObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteKitById(businessAccountId: number, id: number, businessVersionObj: BusinessVersionObj, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteKitById(businessAccountId, id, businessVersionObj, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Массовое удаление наборов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteKitsBulk(businessAccountId: number, versionedEntityObjList: VersionedEntityObjList, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteKitsBulk(businessAccountId, versionedEntityObjList, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Удаление объекта субъектов и реквизитов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {BusinessVersionObj} businessVersionObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLegalDetailById(businessAccountId: number, id: number, businessVersionObj: BusinessVersionObj, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteLegalDetailById(businessAccountId, id, businessVersionObj, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Удаление локации
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {BusinessVersionObj} businessVersionObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLocationById(businessAccountId: number, id: number, businessVersionObj: BusinessVersionObj, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteLocationById(businessAccountId, id, businessVersionObj, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Удаление локаций
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLocations(businessAccountId: number, versionedEntityObjList: VersionedEntityObjList, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteLocations(businessAccountId, versionedEntityObjList, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Массовое удаление номенклатуры
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {NomenclatureEntityList} nomenclatureEntityList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNomenclatureBulk(businessAccountId: number, nomenclatureEntityList: NomenclatureEntityList, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteNomenclatureBulk(businessAccountId, nomenclatureEntityList, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Удаление способа оплаты
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {BusinessVersionObj} businessVersionObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePaymentMethodById(businessAccountId: number, id: number, businessVersionObj: BusinessVersionObj, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deletePaymentMethodById(businessAccountId, id, businessVersionObj, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Массовое удаление способов оплаты
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePaymentMethodsBulk(businessAccountId: number, versionedEntityObjList: VersionedEntityObjList, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deletePaymentMethodsBulk(businessAccountId, versionedEntityObjList, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Удаление ценовой схемы
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {BusinessVersionObj} businessVersionObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePricingSchemeById(businessAccountId: number, id: number, businessVersionObj: BusinessVersionObj, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deletePricingSchemeById(businessAccountId, id, businessVersionObj, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Удаление продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {BusinessVersionObj} businessVersionObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductById(businessAccountId: number, id: number, businessVersionObj: BusinessVersionObj, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteProductById(businessAccountId, id, businessVersionObj, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Удаление экземпляра
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} productId Идентификатор продукта
         * @param {number} id Идентификатор сущности
         * @param {BusinessVersionObj} businessVersionObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductInstanceById(businessAccountId: number, productId: number, id: number, businessVersionObj: BusinessVersionObj, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteProductInstanceById(businessAccountId, productId, id, businessVersionObj, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Массовое удаление экземпляров продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductInstancesBulk(businessAccountId: number, id: number, versionedEntityObjList: VersionedEntityObjList, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteProductInstancesBulk(businessAccountId, id, versionedEntityObjList, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Удаление варианта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} productId Идентификатор продукта
         * @param {number} id Идентификатор сущности
         * @param {BusinessVersionObj} businessVersionObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductVariantById(businessAccountId: number, productId: number, id: number, businessVersionObj: BusinessVersionObj, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteProductVariantById(businessAccountId, productId, id, businessVersionObj, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Массовое удаление вариантов продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductVariantsBulk(businessAccountId: number, id: number, versionedEntityObjList: VersionedEntityObjList, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteProductVariantsBulk(businessAccountId, id, versionedEntityObjList, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Массовое удаление продуктов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductsBulk(businessAccountId: number, versionedEntityObjList: VersionedEntityObjList, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteProductsBulk(businessAccountId, versionedEntityObjList, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Удаление профессии
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {BusinessVersionObj} businessVersionObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProfessionById(businessAccountId: number, id: number, businessVersionObj: BusinessVersionObj, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteProfessionById(businessAccountId, id, businessVersionObj, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Массовое удаление профессий
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProfessionsBulk(businessAccountId: number, versionedEntityObjList: VersionedEntityObjList, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteProfessionsBulk(businessAccountId, versionedEntityObjList, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Удаление графической визуализации отчета
         * @param {number} reportId 
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReportChartVisualization(reportId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteReportChartVisualization(reportId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Удаление конфигурации отчёта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReportConfiguration(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteReportConfiguration(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Удаление Эксель визуализации отчета
         * @param {number} reportId 
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReportExcelVisualization(reportId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteReportExcelVisualization(reportId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Удаление организации в системе SSO авторизации и отвязка от бизнес аккаунта
         * @param {string} accessToken Обязательный токен доступа в формате UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSsoClientOrganization(accessToken: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteSsoClientOrganization(accessToken, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Удаление перевозки
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {BusinessVersionObj} businessVersionObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTransportationById(businessAccountId: number, id: number, businessVersionObj: BusinessVersionObj, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteTransportationById(businessAccountId, id, businessVersionObj, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Удаление перевозок
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTransportations(businessAccountId: number, versionedEntityObjList: VersionedEntityObjList, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteTransportations(businessAccountId, versionedEntityObjList, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Удаление транспортного средства
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {BusinessVersionObj} businessVersionObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVehicleById(businessAccountId: number, id: number, businessVersionObj: BusinessVersionObj, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteVehicleById(businessAccountId, id, businessVersionObj, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Удаление локаций
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVehicles(businessAccountId: number, versionedEntityObjList: VersionedEntityObjList, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteVehicles(businessAccountId, versionedEntityObjList, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Копирование бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateBusinessAccountById(businessAccountId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessAccountInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).duplicateBusinessAccountById(businessAccountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Запрос на подключение или отключение модуля в бизнес аккаунте
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {BusinessAccountModuleEnableOrDisableObj} businessAccountModuleEnableOrDisableObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableOrDisableBusinessAccountModule(businessAccountId: number, businessAccountModuleEnableOrDisableObj: BusinessAccountModuleEnableOrDisableObj, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).enableOrDisableBusinessAccountModule(businessAccountId, businessAccountModuleEnableOrDisableObj, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Запрос на подключение или отключение модуля в бизнес аккаунте
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {BusinessAccountModuleEnableOrDisableObjList} businessAccountModuleEnableOrDisableObjList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableOrDisableBusinessAccountModuleBulk(businessAccountId: number, businessAccountModuleEnableOrDisableObjList: BusinessAccountModuleEnableOrDisableObjList, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).enableOrDisableBusinessAccountModuleBulk(businessAccountId, businessAccountModuleEnableOrDisableObjList, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Экспорт списка обязательств бизнес аккаунта. При limit = null ограничение до 1000
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {Array<string>} columns Колонки учавстующие в экспорте. Передавать [\&#39;ALL\&#39;] чтобы выгрузить все доступные
         * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {Date} [specialFromStartDateIncludingDelays] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportBusinessAccountElementsList(businessAccountId: number, columns: Array<string>, expandKits: boolean, timezoneOffsetUTC: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, specialFromStartDateIncludingDelays?: Date, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).exportBusinessAccountElementsList(businessAccountId, columns, expandKits, timezoneOffsetUTC, limit, startFrom, filters, sortBy, sortOrder, search, specialFromStartDateIncludingDelays, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение календаря событий бизнес аккаунта
         * @param {string} accessToken Access token. UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportCalendar(accessToken: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).exportCalendar(accessToken, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Экспорт списка обязательств операции. При limit = null ограничение до 1000
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {Array<string>} columns Колонки учавстующие в экспорте. Передавать [\&#39;ALL\&#39;] чтобы выгрузить все доступные
         * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportOperationElementsList(businessAccountId: number, id: number, columns: Array<string>, expandKits: boolean, timezoneOffsetUTC: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).exportOperationElementsList(businessAccountId, id, columns, expandKits, timezoneOffsetUTC, limit, startFrom, filters, sortBy, sortOrder, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Экспорт списка обязательств проекта. При limit = null ограничение до 1000
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} projectId Идентификатор проекта
         * @param {Array<string>} columns Колонки учавстующие в экспорте. Передавать [\&#39;ALL\&#39;] чтобы выгрузить все доступные
         * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportProjectElementsList(businessAccountId: number, projectId: number, columns: Array<string>, expandKits: boolean, timezoneOffsetUTC: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).exportProjectElementsList(businessAccountId, projectId, columns, expandKits, timezoneOffsetUTC, limit, startFrom, filters, sortBy, sortOrder, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Экспорт списка обязательств поставки. При limit = null ограничение до 1000
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} subrentId Идентификатор поставки
         * @param {Array<string>} columns Колонки учавстующие в экспорте. Передавать [\&#39;ALL\&#39;] чтобы выгрузить все доступные
         * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportSubrentElementsList(businessAccountId: number, subrentId: number, columns: Array<string>, expandKits: boolean, timezoneOffsetUTC: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).exportSubrentElementsList(businessAccountId, subrentId, columns, expandKits, timezoneOffsetUTC, limit, startFrom, filters, sortBy, sortOrder, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Экспорт списка обязательств шаблона. При limit = null ограничение до 1000
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} templateId Идентификатор шаблона
         * @param {Array<string>} columns Колонки учавстующие в экспорте. Передавать [\&#39;ALL\&#39;] чтобы выгрузить все доступные
         * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportTemplateElementsList(businessAccountId: number, templateId: number, columns: Array<string>, expandKits: boolean, timezoneOffsetUTC: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).exportTemplateElementsList(businessAccountId, templateId, columns, expandKits, timezoneOffsetUTC, limit, startFrom, filters, sortBy, sortOrder, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Переиндексировать все сущности компании с удалением лишних
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fullReindexBusinessAccount(businessAccountId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).fullReindexBusinessAccount(businessAccountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение файла бейджа
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} nomenclatureType Тип номенклатуры для печати
         * @param {number} nomenclatureId Id сущности
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {boolean} [common] Флаг типа шаблона
         * @param {number} [documentTemplateId] Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateBadgePdf(businessAccountId: number, nomenclatureType: string, nomenclatureId: number, timezoneOffsetUTC: number, common?: boolean, documentTemplateId?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).generateBadgePdf(businessAccountId, nomenclatureType, nomenclatureId, timezoneOffsetUTC, common, documentTemplateId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение изображения штрихового кода
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} barcodeString Штриховой код
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateBarcodeImage(businessAccountId: number, barcodeString: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).generateBarcodeImage(businessAccountId, barcodeString, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Генерация документа транспортной накладной в формат DOCX
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {boolean} common Флаг типа шаблона
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateBillOfLadingDocx(businessAccountId: number, id: number, common: boolean, documentTemplateId: number, businessAccountLegalDetailsId?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).generateBillOfLadingDocx(businessAccountId, id, common, documentTemplateId, businessAccountLegalDetailsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Генерация документа транспортной накладной в формат PDF
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {boolean} common Флаг типа шаблона
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateBillOfLadingPdf(businessAccountId: number, id: number, common: boolean, documentTemplateId: number, businessAccountLegalDetailsId?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).generateBillOfLadingPdf(businessAccountId, id, common, documentTemplateId, businessAccountLegalDetailsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Генерация документа счета в формат PDF
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {boolean} common Флаг типа шаблона
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateBillPdf(businessAccountId: number, id: number, common: boolean, documentTemplateId: number, timezoneOffsetUTC: number, businessAccountLegalDetailsId?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).generateBillPdf(businessAccountId, id, common, documentTemplateId, timezoneOffsetUTC, businessAccountLegalDetailsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Запрос на генерацию нового токена доступа к апи календаря
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateCalendarAccessToken(businessAccountId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).generateCalendarAccessToken(businessAccountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Генерация документа договора по проекту в формат DOCX
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} projectId Идентификатор проекта
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {boolean} common Флаг типа шаблона
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateContractDocumentDocx(businessAccountId: number, projectId: number, documentTemplateId: number, common: boolean, timezoneOffsetUTC: number, businessAccountLegalDetailsId?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).generateContractDocumentDocx(businessAccountId, projectId, documentTemplateId, common, timezoneOffsetUTC, businessAccountLegalDetailsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Генерация документа договора по проекту в формат PDF
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} projectId Идентификатор проекта
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {boolean} common Флаг типа шаблона
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateContractDocumentPdf(businessAccountId: number, projectId: number, documentTemplateId: number, common: boolean, timezoneOffsetUTC: number, businessAccountLegalDetailsId?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).generateContractDocumentPdf(businessAccountId, projectId, documentTemplateId, common, timezoneOffsetUTC, businessAccountLegalDetailsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Генерация представления отчета в формат PDF
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {ExcelExportGenerationInfo} excelExportGenerationInfo 
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateExportPdf(businessAccountId: number, excelExportGenerationInfo: ExcelExportGenerationInfo, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).generateExportPdf(businessAccountId, excelExportGenerationInfo, filters, sortBy, sortOrder, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Генерация представления отчета в формат XLSX
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {ExcelExportGenerationInfo} excelExportGenerationInfo 
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateExportXlsx(businessAccountId: number, excelExportGenerationInfo: ExcelExportGenerationInfo, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).generateExportXlsx(businessAccountId, excelExportGenerationInfo, filters, sortBy, sortOrder, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Генерация документа прайс листа в формат PDF
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {boolean} common Флаг типа шаблона
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {number} appliedDiscount Скидка
         * @param {boolean} showDiscount Показывать ли скидку явно
         * @param {boolean} showPricingSchemes Показывать ли ценовые схемы
         * @param {boolean} showDescription Показывать ли описание
         * @param {boolean} showCount Показывать ли общее количество
         * @param {boolean} groupByCategories Группировать по категориям
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateNomenclaturePricelistPdf(businessAccountId: number, common: boolean, documentTemplateId: number, appliedDiscount: number, showDiscount: boolean, showPricingSchemes: boolean, showDescription: boolean, showCount: boolean, groupByCategories: boolean, timezoneOffsetUTC: number, businessAccountLegalDetailsId?: number, filters?: Array<string>, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).generateNomenclaturePricelistPdf(businessAccountId, common, documentTemplateId, appliedDiscount, showDiscount, showPricingSchemes, showDescription, showCount, groupByCategories, timezoneOffsetUTC, businessAccountLegalDetailsId, filters, limit, startFrom, sortBy, sortOrder, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Генерация документа прайс листа в формат XLSX
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {boolean} common Флаг типа шаблона
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {number} appliedDiscount Скидка
         * @param {boolean} showDiscount Показывать ли скидку явно
         * @param {boolean} showPricingSchemes Показывать ли ценовые схемы
         * @param {boolean} showDescription Показывать ли описание
         * @param {boolean} showCount Показывать ли общее количество
         * @param {boolean} groupByCategories Группировать по категориям
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateNomenclaturePricelistXlsx(businessAccountId: number, common: boolean, documentTemplateId: number, appliedDiscount: number, showDiscount: boolean, showPricingSchemes: boolean, showDescription: boolean, showCount: boolean, groupByCategories: boolean, timezoneOffsetUTC: number, businessAccountLegalDetailsId?: number, filters?: Array<string>, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).generateNomenclaturePricelistXlsx(businessAccountId, common, documentTemplateId, appliedDiscount, showDiscount, showPricingSchemes, showDescription, showCount, groupByCategories, timezoneOffsetUTC, businessAccountLegalDetailsId, filters, limit, startFrom, sortBy, sortOrder, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Генерация акта по операции в формат DOCX
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} operationId Идентификатор операции
         * @param {boolean} common Флаг типа шаблона
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
         * @param {boolean} joinSimilarRows Объединять ли одинаковые строки при экспорте (для развернутых наборов не работает)
         * @param {boolean} groupByCategories Группировать по категориям
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {CategorySortOrderEnum} [categorySortBy] 
         * @param {'ASC' | 'DESC'} [categorySortOrder] 
         * @param {Date} [t0] 
         * @param {Date} [tN] 
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateOperationActDocumentDocx(businessAccountId: number, operationId: number, common: boolean, documentTemplateId: number, expandKits: boolean, joinSimilarRows: boolean, groupByCategories: boolean, timezoneOffsetUTC: number, categorySortBy?: CategorySortOrderEnum, categorySortOrder?: 'ASC' | 'DESC', t0?: Date, tN?: Date, businessAccountLegalDetailsId?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).generateOperationActDocumentDocx(businessAccountId, operationId, common, documentTemplateId, expandKits, joinSimilarRows, groupByCategories, timezoneOffsetUTC, categorySortBy, categorySortOrder, t0, tN, businessAccountLegalDetailsId, filters, sortBy, sortOrder, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Генерация акта по операции в формат PDF
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} operationId Идентификатор операции
         * @param {boolean} common Флаг типа шаблона
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
         * @param {boolean} joinSimilarRows Объединять ли одинаковые строки при экспорте (для развернутых наборов не работает)
         * @param {boolean} groupByCategories Группировать по категориям
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {CategorySortOrderEnum} [categorySortBy] 
         * @param {'ASC' | 'DESC'} [categorySortOrder] 
         * @param {Date} [t0] 
         * @param {Date} [tN] 
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateOperationActDocumentPdf(businessAccountId: number, operationId: number, common: boolean, documentTemplateId: number, expandKits: boolean, joinSimilarRows: boolean, groupByCategories: boolean, timezoneOffsetUTC: number, categorySortBy?: CategorySortOrderEnum, categorySortOrder?: 'ASC' | 'DESC', t0?: Date, tN?: Date, businessAccountLegalDetailsId?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).generateOperationActDocumentPdf(businessAccountId, operationId, common, documentTemplateId, expandKits, joinSimilarRows, groupByCategories, timezoneOffsetUTC, categorySortBy, categorySortOrder, t0, tN, businessAccountLegalDetailsId, filters, sortBy, sortOrder, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Генерация документа сметы операции в формат PDF
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} operationId Идентификатор операции
         * @param {boolean} common Флаг типа шаблона
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
         * @param {boolean} joinSimilarRows Объединять ли одинаковые строки при экспорте (для развернутых наборов не работает)
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {CategorySortOrderEnum} [categorySortBy] 
         * @param {'ASC' | 'DESC'} [categorySortOrder] 
         * @param {Date} [t0] 
         * @param {Date} [tN] 
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateOperationEstimateDocumentPdf(businessAccountId: number, operationId: number, common: boolean, documentTemplateId: number, expandKits: boolean, joinSimilarRows: boolean, timezoneOffsetUTC: number, categorySortBy?: CategorySortOrderEnum, categorySortOrder?: 'ASC' | 'DESC', t0?: Date, tN?: Date, businessAccountLegalDetailsId?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).generateOperationEstimateDocumentPdf(businessAccountId, operationId, common, documentTemplateId, expandKits, joinSimilarRows, timezoneOffsetUTC, categorySortBy, categorySortOrder, t0, tN, businessAccountLegalDetailsId, filters, sortBy, sortOrder, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Генерация документа сметы операции в формат XLSX
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} operationId Идентификатор операции
         * @param {boolean} common Флаг типа шаблона
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
         * @param {boolean} joinSimilarRows Объединять ли одинаковые строки при экспорте (для развернутых наборов не работает)
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {CategorySortOrderEnum} [categorySortBy] 
         * @param {'ASC' | 'DESC'} [categorySortOrder] 
         * @param {Date} [t0] 
         * @param {Date} [tN] 
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateOperationEstimateDocumentXlsx(businessAccountId: number, operationId: number, common: boolean, documentTemplateId: number, expandKits: boolean, joinSimilarRows: boolean, timezoneOffsetUTC: number, categorySortBy?: CategorySortOrderEnum, categorySortOrder?: 'ASC' | 'DESC', t0?: Date, tN?: Date, businessAccountLegalDetailsId?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).generateOperationEstimateDocumentXlsx(businessAccountId, operationId, common, documentTemplateId, expandKits, joinSimilarRows, timezoneOffsetUTC, categorySortBy, categorySortOrder, t0, tN, businessAccountLegalDetailsId, filters, sortBy, sortOrder, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Генерация документа списка инвентаря в формат PDF
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {boolean} common Флаг типа шаблона
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {boolean} showBarcode Показывать ли штриховой код
         * @param {boolean} expandOwnNamedInstances Показывать ли свои именованные экземпляры при экспорте
         * @param {boolean} expandSubrentedNamedInstances Включать ли чужие экземпляры при экспорте
         * @param {boolean} showPurchaseCost Показывать ли закупочную стоимость при экспорте
         * @param {boolean} showCarryingValue Показывать ли балансовую стоимость при экспорте
         * @param {boolean} expandInstanceStateDescription Показывать ли описания состояний экземпляров при экспорте
         * @param {boolean} showColumnActualAvailability Показывать ли колонку \&#39;Фактическое наличие\&#39; при экспорте
         * @param {boolean} showColumnConvergence Показывать ли колонку \&#39;Схождение\&#39; при экспорте
         * @param {boolean} showColumnNotes Показывать ли колонку \&#39;Для пометок\&#39; при экспорте
         * @param {boolean} showSupplier Показывать ли колонку \&#39;Поставщик\&#39; при экспорте
         * @param {boolean} groupByCategories Группировать по категориям
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {CategorySortOrderEnum} [categorySortBy] 
         * @param {'ASC' | 'DESC'} [categorySortOrder] 
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateProductsInventoryListDocumentPdf(businessAccountId: number, common: boolean, documentTemplateId: number, showBarcode: boolean, expandOwnNamedInstances: boolean, expandSubrentedNamedInstances: boolean, showPurchaseCost: boolean, showCarryingValue: boolean, expandInstanceStateDescription: boolean, showColumnActualAvailability: boolean, showColumnConvergence: boolean, showColumnNotes: boolean, showSupplier: boolean, groupByCategories: boolean, timezoneOffsetUTC: number, categorySortBy?: CategorySortOrderEnum, categorySortOrder?: 'ASC' | 'DESC', businessAccountLegalDetailsId?: number, filters?: Array<string>, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).generateProductsInventoryListDocumentPdf(businessAccountId, common, documentTemplateId, showBarcode, expandOwnNamedInstances, expandSubrentedNamedInstances, showPurchaseCost, showCarryingValue, expandInstanceStateDescription, showColumnActualAvailability, showColumnConvergence, showColumnNotes, showSupplier, groupByCategories, timezoneOffsetUTC, categorySortBy, categorySortOrder, businessAccountLegalDetailsId, filters, limit, startFrom, sortBy, sortOrder, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Генерация документа списка инвентаря в формат XLSX
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {boolean} common Флаг типа шаблона
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {boolean} showBarcode Показывать ли штриховой код
         * @param {boolean} expandOwnNamedInstances Показывать ли свои именованные экземпляры при экспорте
         * @param {boolean} expandSubrentedNamedInstances Включать ли чужие экземпляры при экспорте
         * @param {boolean} showPurchaseCost Показывать ли закупочную стоимость при экспорте
         * @param {boolean} showCarryingValue Показывать ли балансовую стоимость при экспорте
         * @param {boolean} expandInstanceStateDescription Показывать ли описания состояний экземпляров при экспорте
         * @param {boolean} showColumnActualAvailability Показывать ли колонку \&#39;Фактическое наличие\&#39; при экспорте
         * @param {boolean} showColumnConvergence Показывать ли колонку \&#39;Схождение\&#39; при экспорте
         * @param {boolean} showColumnNotes Показывать ли колонку \&#39;Для пометок\&#39; при экспорте
         * @param {boolean} showSupplier Показывать ли колонку \&#39;Поставщик\&#39; при экспорте
         * @param {boolean} groupByCategories Группировать по категориям
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {CategorySortOrderEnum} [categorySortBy] 
         * @param {'ASC' | 'DESC'} [categorySortOrder] 
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateProductsInventoryListDocumentXlsx(businessAccountId: number, common: boolean, documentTemplateId: number, showBarcode: boolean, expandOwnNamedInstances: boolean, expandSubrentedNamedInstances: boolean, showPurchaseCost: boolean, showCarryingValue: boolean, expandInstanceStateDescription: boolean, showColumnActualAvailability: boolean, showColumnConvergence: boolean, showColumnNotes: boolean, showSupplier: boolean, groupByCategories: boolean, timezoneOffsetUTC: number, categorySortBy?: CategorySortOrderEnum, categorySortOrder?: 'ASC' | 'DESC', businessAccountLegalDetailsId?: number, filters?: Array<string>, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).generateProductsInventoryListDocumentXlsx(businessAccountId, common, documentTemplateId, showBarcode, expandOwnNamedInstances, expandSubrentedNamedInstances, showPurchaseCost, showCarryingValue, expandInstanceStateDescription, showColumnActualAvailability, showColumnConvergence, showColumnNotes, showSupplier, groupByCategories, timezoneOffsetUTC, categorySortBy, categorySortOrder, businessAccountLegalDetailsId, filters, limit, startFrom, sortBy, sortOrder, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Генерация акта по проекту в формат DOCX
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} projectId Идентификатор проекта
         * @param {boolean} includeWorkPlannings 
         * @param {boolean} includeExpenses 
         * @param {boolean} common Флаг типа шаблона
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
         * @param {boolean} joinSimilarRows Объединять ли одинаковые строки при экспорте (для развернутых наборов не работает)
         * @param {boolean} groupByCategories Группировать по категориям
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {CategorySortOrderEnum} [categorySortBy] 
         * @param {'ASC' | 'DESC'} [categorySortOrder] 
         * @param {Date} [t0] 
         * @param {Date} [tN] 
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateProjectActDocumentDocx(businessAccountId: number, projectId: number, includeWorkPlannings: boolean, includeExpenses: boolean, common: boolean, documentTemplateId: number, expandKits: boolean, joinSimilarRows: boolean, groupByCategories: boolean, timezoneOffsetUTC: number, categorySortBy?: CategorySortOrderEnum, categorySortOrder?: 'ASC' | 'DESC', t0?: Date, tN?: Date, businessAccountLegalDetailsId?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).generateProjectActDocumentDocx(businessAccountId, projectId, includeWorkPlannings, includeExpenses, common, documentTemplateId, expandKits, joinSimilarRows, groupByCategories, timezoneOffsetUTC, categorySortBy, categorySortOrder, t0, tN, businessAccountLegalDetailsId, filters, sortBy, sortOrder, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Генерация акта по проекту в формат PDF
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} projectId Идентификатор проекта
         * @param {boolean} includeWorkPlannings 
         * @param {boolean} includeExpenses 
         * @param {boolean} common Флаг типа шаблона
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
         * @param {boolean} joinSimilarRows Объединять ли одинаковые строки при экспорте (для развернутых наборов не работает)
         * @param {boolean} groupByCategories Группировать по категориям
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {CategorySortOrderEnum} [categorySortBy] 
         * @param {'ASC' | 'DESC'} [categorySortOrder] 
         * @param {Date} [t0] 
         * @param {Date} [tN] 
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateProjectActDocumentPdf(businessAccountId: number, projectId: number, includeWorkPlannings: boolean, includeExpenses: boolean, common: boolean, documentTemplateId: number, expandKits: boolean, joinSimilarRows: boolean, groupByCategories: boolean, timezoneOffsetUTC: number, categorySortBy?: CategorySortOrderEnum, categorySortOrder?: 'ASC' | 'DESC', t0?: Date, tN?: Date, businessAccountLegalDetailsId?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).generateProjectActDocumentPdf(businessAccountId, projectId, includeWorkPlannings, includeExpenses, common, documentTemplateId, expandKits, joinSimilarRows, groupByCategories, timezoneOffsetUTC, categorySortBy, categorySortOrder, t0, tN, businessAccountLegalDetailsId, filters, sortBy, sortOrder, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Генерация документа сметы проекта в формат PDF
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} projectId Идентификатор проекта
         * @param {boolean} includeWorkPlannings 
         * @param {boolean} includeExpenses 
         * @param {boolean} common Флаг типа шаблона
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
         * @param {boolean} joinSimilarRows Объединять ли одинаковые строки при экспорте (для развернутых наборов не работает)
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {CategorySortOrderEnum} [categorySortBy] 
         * @param {'ASC' | 'DESC'} [categorySortOrder] 
         * @param {Date} [t0] 
         * @param {Date} [tN] 
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateProjectEstimateDocumentPdf(businessAccountId: number, projectId: number, includeWorkPlannings: boolean, includeExpenses: boolean, common: boolean, documentTemplateId: number, expandKits: boolean, joinSimilarRows: boolean, timezoneOffsetUTC: number, categorySortBy?: CategorySortOrderEnum, categorySortOrder?: 'ASC' | 'DESC', t0?: Date, tN?: Date, businessAccountLegalDetailsId?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).generateProjectEstimateDocumentPdf(businessAccountId, projectId, includeWorkPlannings, includeExpenses, common, documentTemplateId, expandKits, joinSimilarRows, timezoneOffsetUTC, categorySortBy, categorySortOrder, t0, tN, businessAccountLegalDetailsId, filters, sortBy, sortOrder, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Генерация документа сметы проекта в формат XLSX
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} projectId Идентификатор проекта
         * @param {boolean} includeWorkPlannings 
         * @param {boolean} includeExpenses 
         * @param {boolean} common Флаг типа шаблона
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
         * @param {boolean} joinSimilarRows Объединять ли одинаковые строки при экспорте (для развернутых наборов не работает)
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {CategorySortOrderEnum} [categorySortBy] 
         * @param {'ASC' | 'DESC'} [categorySortOrder] 
         * @param {Date} [t0] 
         * @param {Date} [tN] 
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateProjectEstimateDocumentXlsx(businessAccountId: number, projectId: number, includeWorkPlannings: boolean, includeExpenses: boolean, common: boolean, documentTemplateId: number, expandKits: boolean, joinSimilarRows: boolean, timezoneOffsetUTC: number, categorySortBy?: CategorySortOrderEnum, categorySortOrder?: 'ASC' | 'DESC', t0?: Date, tN?: Date, businessAccountLegalDetailsId?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).generateProjectEstimateDocumentXlsx(businessAccountId, projectId, includeWorkPlannings, includeExpenses, common, documentTemplateId, expandKits, joinSimilarRows, timezoneOffsetUTC, categorySortBy, categorySortOrder, t0, tN, businessAccountLegalDetailsId, filters, sortBy, sortOrder, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Генерация отчета
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {ReportInputParameters} reportInputParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateReport(businessAccountId: number, id: number, reportInputParameters: ReportInputParameters, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).generateReport(businessAccountId, id, reportInputParameters, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Генерация представления отчета в формат PDF
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {ExcelReportGenerationInfo} excelReportGenerationInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateReportPdf(businessAccountId: number, id: number, timezoneOffsetUTC: number, excelReportGenerationInfo: ExcelReportGenerationInfo, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).generateReportPdf(businessAccountId, id, timezoneOffsetUTC, excelReportGenerationInfo, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Генерация представления отчета в формат XLSX
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {ExcelReportGenerationInfo} excelReportGenerationInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateReportXlsx(businessAccountId: number, id: number, timezoneOffsetUTC: number, excelReportGenerationInfo: ExcelReportGenerationInfo, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).generateReportXlsx(businessAccountId, id, timezoneOffsetUTC, excelReportGenerationInfo, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Запрос на генерацию нового токена доступа к апи витрины
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateStorefrontAccessToken(businessAccountId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).generateStorefrontAccessToken(businessAccountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение информации о вложении
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttachmentById(businessAccountId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AttachmentInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getAttachmentById(businessAccountId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение списка реквизитов по стране
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {LegalCountryCodeEnum} legalCountryTypeCode Код страны в юридическом контексте
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBALegalDetailsFieldsListByCountry(businessAccountId: number, legalCountryTypeCode: LegalCountryCodeEnum, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CountryLegalDetailsFieldsObjRead>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getBALegalDetailsFieldsListByCountry(businessAccountId, legalCountryTypeCode, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение информации о компании
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} accessToken Обязательный токен доступа в формате UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessAccount(businessAccountId: number, accessToken: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicBusinessAccountInfo> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getBusinessAccount(businessAccountId, accessToken, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение состояния бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessAccountById(businessAccountId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessAccountInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getBusinessAccountById(businessAccountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Изменение контактных данных бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessAccountContactsById(businessAccountId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessAccountContactsInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getBusinessAccountContactsById(businessAccountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Данные дешборда бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessAccountDashboardData(businessAccountId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessAccountDashboardDataObj> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getBusinessAccountDashboardData(businessAccountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение индикаторов бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessAccountIndicators(businessAccountId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessAccountIndicatorsObj> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getBusinessAccountIndicators(businessAccountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Список ссылок на статьи базы знаний с названиями в текущей локали
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} interfaceBlockCode Код блока интерфейся для которого требуется выгрузить статьи
         * @param {LocaleCodeEnum} locale Локаль для которой требуется выгрузить статьи
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessAccountKnowledgeBaseArticleLinkList(businessAccountId: number, interfaceBlockCode: string, locale: LocaleCodeEnum, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnowledgeBaseArticleLinkObjList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getBusinessAccountKnowledgeBaseArticleLinkList(businessAccountId, interfaceBlockCode, locale, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение основных настроек бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessAccountPreferences(businessAccountId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessAccountPreferencesInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getBusinessAccountPreferences(businessAccountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение состояния категории
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoryById(businessAccountId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getCategoryById(businessAccountId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение состояния стандартного шаблона документа
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommonDocumentTemplateById(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentTemplateInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getCommonDocumentTemplateById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение контента стандартного шаблона документа
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommonDocumentTemplateContentById(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getCommonDocumentTemplateContentById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение контента стандатного шаблона документа
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommonDocumentTemplateContentFromBusinessAccountById(businessAccountId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getCommonDocumentTemplateContentFromBusinessAccountById(businessAccountId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение состояния контакта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactById(businessAccountId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getContactById(businessAccountId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение кадра
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCrewMember(businessAccountId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CrewMemberInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getCrewMember(businessAccountId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение состояния настроек уведомлений текущего пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentNotificationSchema(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationSchemaInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getCurrentNotificationSchema(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение состояния текущего пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentProfile(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getCurrentProfile(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Информация о текущем клиенте в бизнес аккаунте. Необходим JWT токен пользователя
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentRenterInfo(businessAccountId: number, storefrontId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorefrontRenterInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getCurrentRenterInfo(businessAccountId, storefrontId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение прав текущего пользователя. Права в бизнес аккаунте передаются при передаче параметра businessAccountId
         * @param {number} [businessAccountId] Идентификатор бизнес аккаунта, необязательный
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentRolesAndPermissions(businessAccountId?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RolesAndPermissionsObj> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getCurrentRolesAndPermissions(businessAccountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение состояния пользовательского поля
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomFieldById(businessAccountId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomFieldInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getCustomFieldById(businessAccountId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение всех пользовательских полей по типу сущности
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {string} nomenclatureType Тип номенклатуры для печати
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomFieldsByEntityType(businessAccountId: number, id: number, nomenclatureType: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomFieldsGlobalObjRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getCustomFieldsByEntityType(businessAccountId, id, nomenclatureType, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение состояния шаблона документа
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentTemplateById(businessAccountId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentTemplateInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getDocumentTemplateById(businessAccountId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение контента пользовательского шаблона документа
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentTemplateContentById(businessAccountId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getDocumentTemplateContentById(businessAccountId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение данных сущности по штриховому коду
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} barcodeString Строка прочитанного штрихового кода
         * @param {boolean} [includeEntityInfoRead] Флаг для включения полной информации по сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityByBarcode(businessAccountId: number, barcodeString: string, includeEntityInfoRead?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BarcodeEntityObj> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getEntityByBarcode(businessAccountId, barcodeString, includeEntityInfoRead, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Получение сущности затраты по проекту
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExpense(businessAccountId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpenseInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getExpense(businessAccountId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение сгенерированного мнемонического ключа
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {'renter' | 'variant' | 'instance' | 'project' | 'subrent' | 'project_operation' | 'subrent_operation' | 'template_operation'} type Тип сущности
         * @param {'mnemoKey'} fieldName Наименование поля
         * @param {number} [parentId] Идентификатор родительской сущности
         * @param {string} [baseString] Короткое наименование/тип сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneratedField(businessAccountId: number, type: 'renter' | 'variant' | 'instance' | 'project' | 'subrent' | 'project_operation' | 'subrent_operation' | 'template_operation', fieldName: 'mnemoKey', parentId?: number, baseString?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getGeneratedField(businessAccountId, type, fieldName, parentId, baseString, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get information to visualize
         * @param {number} gridCellIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGridCellData(gridCellIdentifier: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViewInfo> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getGridCellData(gridCellIdentifier, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Operation to get info about grid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInformationAboutGrid(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Grid> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getInformationAboutGrid(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение состояния экземпляра
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstanceById(businessAccountId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstanceInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getInstanceById(businessAccountId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение информации о событии движения инвентаря
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInventoryMovementById(businessAccountId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InventoryMovementInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getInventoryMovementById(businessAccountId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение описания приглашения
         * @param {string} token Код активации приглашения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvitationByToken(token: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserBaInvitationInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getInvitationByToken(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение состояния набора
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKitById(businessAccountId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<KitInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getKitById(businessAccountId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение карты состояния набора (только доступность и инвентарное количество)
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {boolean} captureAll Маркер обозначающий, что нужно показать весь возможный период
         * @param {TimetableTypeCodeEnum} typeCode Тип искомого стека состояния (только доступность и инвентарное количество)
         * @param {number} [from] Начало целевого интервала времени, epoch millis
         * @param {number} [until] Конец целевого интервала времени, epoch millis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKitTimetableById(businessAccountId: number, id: number, captureAll: boolean, typeCode: TimetableTypeCodeEnum, from?: number, until?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimetableObj> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getKitTimetableById(businessAccountId, id, captureAll, typeCode, from, until, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Массовое получение состояний набора
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {Array<number>} ids Идентификаторы набора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKitsByIdsBulk(businessAccountId: number, ids: Array<number>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KitInfoRead>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getKitsByIdsBulk(businessAccountId, ids, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение состояния объекта субъектов и реквизитов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLegalDetailById(businessAccountId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LegalDetailsInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getLegalDetailById(businessAccountId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение состояния объектов субъектов и реквизитов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLegalDetails(businessAccountId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LegalDetailsRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getLegalDetails(businessAccountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationById(businessAccountId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getLocationById(businessAccountId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение данных сущности номенклатуры для формы операции по штриховому коду
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} barcodeString Строка прочитанного штрихового кода
         * @param {boolean} [includeEntityInfoRead] Флаг для включения полной информации по сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNomenclatureInOperationFormByBarcode(businessAccountId: number, barcodeString: string, includeEntityInfoRead?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BarcodeNomenclatureInOperationFormObj> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getNomenclatureInOperationFormByBarcode(businessAccountId, barcodeString, includeEntityInfoRead, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение состояния операции
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperationById(businessAccountId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperationInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getOperationById(businessAccountId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение списка аудитов аренды состава операции
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperationComposition(businessAccountId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperationCompositionObj> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getOperationComposition(businessAccountId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение платежа
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentById(businessAccountId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getPaymentById(businessAccountId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение состояния способа оплаты
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentMethodById(businessAccountId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentMethodInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getPaymentMethodById(businessAccountId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение состояния ценовой схемы
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPricingSchemeById(businessAccountId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PricingSchemeInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getPricingSchemeById(businessAccountId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение состояния продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductById(businessAccountId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getProductById(businessAccountId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение состояния экземпляра продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} productId Идентификатор продукта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductInstanceById(businessAccountId: number, productId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstanceInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getProductInstanceById(businessAccountId, productId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение карты состояния экземпляра продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} productId Идентификатор продукта
         * @param {number} id Идентификатор сущности
         * @param {boolean} captureAll Маркер обозначающий, что нужно показать весь возможный период
         * @param {TimetableTypeCodeEnum} typeCode Тип искомого стека состояния (только доступность и инвентарное количество)
         * @param {number} [from] Начало целевого интервала времени, epoch millis
         * @param {number} [until] Конец целевого интервала времени, epoch millis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductInstanceTimetableById(businessAccountId: number, productId: number, id: number, captureAll: boolean, typeCode: TimetableTypeCodeEnum, from?: number, until?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimetableObj> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getProductInstanceTimetableById(businessAccountId, productId, id, captureAll, typeCode, from, until, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение карты состояния продукта (доступность, заявка и прочее)
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {boolean} captureAll Маркер обозначающий, что нужно показать весь возможный период
         * @param {TimetableTypeCodeEnum} typeCode Тип искомого стека состояния (только доступность и инвентарное количество)
         * @param {number} [from] Начало целевого интервала времени, epoch millis
         * @param {number} [until] Конец целевого интервала времени, epoch millis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductTimetableById(businessAccountId: number, id: number, captureAll: boolean, typeCode: TimetableTypeCodeEnum, from?: number, until?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimetableObj> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getProductTimetableById(businessAccountId, id, captureAll, typeCode, from, until, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение состояния варианта продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} productId Идентификатор продукта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductVariantById(businessAccountId: number, productId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VariantInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getProductVariantById(businessAccountId, productId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение карты состояния экземпляра варианта продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} productId Идентификатор продукта
         * @param {number} variantId Идентификатор варианта
         * @param {number} id Идентификатор сущности
         * @param {boolean} captureAll Маркер обозначающий, что нужно показать весь возможный период
         * @param {TimetableTypeCodeEnum} typeCode Тип искомого стека состояния (только доступность и инвентарное количество)
         * @param {number} [from] Начало целевого интервала времени, epoch millis
         * @param {number} [until] Конец целевого интервала времени, epoch millis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductVariantInstanceTimetableById(businessAccountId: number, productId: number, variantId: number, id: number, captureAll: boolean, typeCode: TimetableTypeCodeEnum, from?: number, until?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimetableObj> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getProductVariantInstanceTimetableById(businessAccountId, productId, variantId, id, captureAll, typeCode, from, until, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение профессии
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfessionById(businessAccountId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfessionInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getProfessionById(businessAccountId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение состояния проекта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectById(businessAccountId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getProjectById(businessAccountId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Запрос информации элемента аренды
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRentElementById(businessAccountId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentElementInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getRentElementById(businessAccountId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Запрос истории элемента аренды
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRentElementHistory(businessAccountId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentAuditRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getRentElementHistory(businessAccountId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение состояния арендатора
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRenterById(businessAccountId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RenterInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getRenterById(businessAccountId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение данных о состоянии отдельного графического представления отчёта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportChartVisualizationById(businessAccountId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportChartVisualizationInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getReportChartVisualizationById(businessAccountId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение состояния конфигурации отчета
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportConfigurationById(businessAccountId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedReportConfigurationInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getReportConfigurationById(businessAccountId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение изображения логотипа компании клиента при SSO авторизации
         * @param {string} ssoClientOrganizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSsoAuthorizationClientLogo(ssoClientOrganizationId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getSsoAuthorizationClientLogo(ssoClientOrganizationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение информации об организации в системе SSO авторизации для бизнес аккаунта
         * @param {string} accessToken Обязательный токен доступа в формате UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSsoClientOrganization(accessToken: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SsoClientOrganizationInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getSsoClientOrganization(accessToken, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение информации о витрине
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} accessToken Обязательный токен доступа в формате UUID
         * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
         * @param {Array<number>} [baseCategoryIds] Идентификаторы базовых категорий, только они и их подкатегории попадут в результат
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStorefront(businessAccountId: number, accessToken: string, storefrontId: number, baseCategoryIds?: Array<number>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorefrontInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getStorefront(businessAccountId, accessToken, storefrontId, baseCategoryIds, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение доступности торг. предложения
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} accessToken Обязательный токен доступа в формате UUID
         * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
         * @param {number} offerId Идентификатор торг. предложения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStorefrontOfferAvailability(businessAccountId: number, accessToken: string, storefrontId: number, offerId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getStorefrontOfferAvailability(businessAccountId, accessToken, storefrontId, offerId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение состояния субаренды
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubrentById(businessAccountId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubrentInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getSubrentById(businessAccountId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение состояния системной настройки
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemOptionById(businessAccountId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemOptionInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getSystemOptionById(businessAccountId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Список групп системных настроек с вложенными системными настройками
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemOptionsSectionById(businessAccountId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemOptionsSectionReadInfo> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getSystemOptionsSectionById(businessAccountId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение иерархии системных настроек бизнес аккаунта в виде дерева
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemOptionsTree(businessAccountId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimpleTreeEntityRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getSystemOptionsTree(businessAccountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение состояния шаблона
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplateById(businessAccountId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplateInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getTemplateById(businessAccountId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение серверного времени
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTime(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Date> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getTime(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение серверного времени
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTime_2(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Date> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getTime_2(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransportationById(businessAccountId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransportationInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getTransportationById(businessAccountId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение состояния пользователя
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById(businessAccountId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getUserById(businessAccountId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Валидация значения поля
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {'kit' | 'product' | 'variant' | 'instance' | 'profession' | 'renter' | 'project' | 'subrent' | 'template' | 'customField' | 'user' | 'location' | 'contact' | 'vehicle' | 'transportation' | 'paymentMethod'} type Тип сущности
         * @param {string} fieldName Наименование поля
         * @param {string} value Значение поля
         * @param {number} [ignoreCurrentId] Идентификатор текущей сущности, которую нужно игнорировать, например, при проверке уникальности
         * @param {number} [parentId] Идентификатор родительской сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValidationForField(businessAccountId: number, type: 'kit' | 'product' | 'variant' | 'instance' | 'profession' | 'renter' | 'project' | 'subrent' | 'template' | 'customField' | 'user' | 'location' | 'contact' | 'vehicle' | 'transportation' | 'paymentMethod', fieldName: string, value: string, ignoreCurrentId?: number, parentId?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getValidationForField(businessAccountId, type, fieldName, value, ignoreCurrentId, parentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Карты состояния варианта (доступность, заявка и прочее)
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} productId Идентификатор продукта
         * @param {number} id Идентификатор сущности
         * @param {boolean} captureAll Маркер обозначающий, что нужно показать весь возможный период
         * @param {TimetableTypeCodeEnum} typeCode Тип искомого стека состояния (только доступность и инвентарное количество)
         * @param {number} [from] Начало целевого интервала времени, epoch millis
         * @param {number} [until] Конец целевого интервала времени, epoch millis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVariantTimetableById(businessAccountId: number, productId: number, id: number, captureAll: boolean, typeCode: TimetableTypeCodeEnum, from?: number, until?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimetableObj> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getVariantTimetableById(businessAccountId, productId, id, captureAll, typeCode, from, until, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVehicleById(businessAccountId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getVehicleById(businessAccountId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение данных для графика
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {ReportInputParameters} [reportInputParameters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViewInfoOfReportChartVisualization(businessAccountId: number, id: number, reportInputParameters?: ReportInputParameters, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViewInfo> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getViewInfoOfReportChartVisualization(businessAccountId, id, reportInputParameters, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получить описание работы
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkPlanning(businessAccountId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkPlanningsInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getWorkPlanning(businessAccountId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Глобальный запрос списка бизнес аккаунтов
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalListBusinessAccounts(limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessAccountRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).globalListBusinessAccounts(limit, startFrom, filters, sortBy, sortOrder, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Берёт из эластика
         * @summary Возвращает список шаблонов. Всех
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalListDocumentTemplates(limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentTemplateRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).globalListDocumentTemplates(limit, startFrom, filters, sortBy, sortOrder, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Массовое изменение КП - включение в стоимость и исключение из стоимости
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {boolean} include Включить или исключить true &#x3D; включить, false &#x3D; исключить
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        includeOrExcludeOfferInProjectPriceBulk(businessAccountId: number, include: boolean, versionedEntityObjList: VersionedEntityObjList, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).includeOrExcludeOfferInProjectPriceBulk(businessAccountId, include, versionedEntityObjList, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Добавить пользователя в бизнес аккаунт
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {UserInviteToBaObj} userInviteToBaObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteUserToBusinessAccount(businessAccountId: number, userInviteToBaObj: UserInviteToBaObj, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).inviteUserToBusinessAccount(businessAccountId, userInviteToBaObj, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Массовое изменение привязки категорий
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {boolean} createLinks Создать или удалить, true &#x3D; создать, false &#x3D; удалить
         * @param {CategoryBulkLinkObj} categoryBulkLinkObj 
         * @param {boolean} [makeMainCategory] Назначить ли привязываемую категорию основной (передается только при привязке)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkCategoriesBulk(businessAccountId: number, createLinks: boolean, categoryBulkLinkObj: CategoryBulkLinkObj, makeMainCategory?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).linkCategoriesBulk(businessAccountId, createLinks, categoryBulkLinkObj, makeMainCategory, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Массовое изменение привязки пользовательских полей
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {boolean} createLinks Создать или удалить, true &#x3D; создать, false &#x3D; удалить
         * @param {boolean} assignFieldsToVariants Присвоить пользовательские поля вариантам или только продукту
         * @param {CustomFieldBulkLinkObj} customFieldBulkLinkObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkCustomFieldsBulk(businessAccountId: number, createLinks: boolean, assignFieldsToVariants: boolean, customFieldBulkLinkObj: CustomFieldBulkLinkObj, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).linkCustomFieldsBulk(businessAccountId, createLinks, assignFieldsToVariants, customFieldBulkLinkObj, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Массовое назначение тэгов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {boolean} createLinks Создать или удалить, true &#x3D; создать, false &#x3D; удалить
         * @param {TagsBulkLinkObj} tagsBulkLinkObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkTagsBulk(businessAccountId: number, createLinks: boolean, tagsBulkLinkObj: TagsBulkLinkObj, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).linkTagsBulk(businessAccountId, createLinks, tagsBulkLinkObj, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение списка идентификаторов всех активных предложений витрины бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} accessToken Обязательный токен доступа в формате UUID
         * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
         * @param {Array<number>} [baseCategoryIds] Идентификаторы базовых категорий, только они и их подкатегории попадут в результат
         * @param {boolean} [includeProfessions] Если флаг передан и равен true, будут добавлены торг. предложения с типом профессия. В старых версиях витрины не передается, обеспечивает обратную совместимость
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActiveStorefrontOffersIds(businessAccountId: number, accessToken: string, storefrontId: number, baseCategoryIds?: Array<number>, includeProfessions?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listActiveStorefrontOffersIds(businessAccountId, accessToken, storefrontId, baseCategoryIds, includeProfessions, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение доступных типов операций
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {OperationAvailableStatesRequestObj} operationAvailableStatesRequestObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAvailableOperationTypes(businessAccountId: number, operationAvailableStatesRequestObj: OperationAvailableStatesRequestObj, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperationAvailableStatesResponseObj> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listAvailableOperationTypes(businessAccountId, operationAvailableStatesRequestObj, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Список элементов бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {boolean} [excludeAggregations] Исключить аггрегации
         * @param {boolean} [excludeRecords] Исключить записи списка
         * @param {Date} [specialFromStartDateIncludingDelays] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBusinessAccountElements(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, excludeAggregations?: boolean, excludeRecords?: boolean, specialFromStartDateIncludingDelays?: Date, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentElementRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listBusinessAccountElements(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, excludeAggregations, excludeRecords, specialFromStartDateIncludingDelays, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение ограничения фильтров по обязательствам бизнес аккаунта на основе присутствующих данных
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBusinessAccountElementsAvailableFiltersValues(businessAccountId: number, filters?: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvailableFiltersValuesListObj> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listBusinessAccountElementsAvailableFiltersValues(businessAccountId, filters, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение состояния объекта субъектов и реквизитов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBusinessAccountElementsGroupedByNomenclature(businessAccountId: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GroupedRentElement>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listBusinessAccountElementsGroupedByNomenclature(businessAccountId, filters, sortBy, sortOrder, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Список сгруппированных элементов бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {boolean} subrent Запрос по субарендным обязательствам - если true то субаренда, если false то аренда
         * @param {boolean} periodStartDate Запрос по началу периода - если true, то начало считается датой группы, если false то окончание
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBusinessAccountElementsGroupedByPeriod(businessAccountId: number, subrent: boolean, periodStartDate: boolean, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupedRentElementRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listBusinessAccountElementsGroupedByPeriod(businessAccountId, subrent, periodStartDate, limit, startFrom, filters, sortBy, sortOrder, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение списка графических визуализаций отчетов, доступных для бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} [search] Поисковая строка
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBusinessAccountReportChartVisualizations(businessAccountId: number, search?: string, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', filters?: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportChartVisualizationRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listBusinessAccountReportChartVisualizations(businessAccountId, search, limit, startFrom, sortBy, sortOrder, filters, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение списка конфигураций отчетов доступных для бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBusinessAccountReportConfigurations(businessAccountId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportConfigurationRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listBusinessAccountReportConfigurations(businessAccountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Список бизнес аккаунтов
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBusinessAccounts(limit?: number, startFrom?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessAccountRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listBusinessAccounts(limit, startFrom, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Список категорий
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {CategoryTypeCodeEnum} typeCode Тип изменяемых категорий
         * @param {string} [search] Прямое вхождение последовательности символов в наименование
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCategories(businessAccountId: number, typeCode: CategoryTypeCodeEnum, search?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listCategories(businessAccountId, typeCode, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Список деревьев категорий
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {CategoryTypeCodeEnum} typeCode Тип изменяемых категорий
         * @param {string} [search] Прямое вхождение последовательности символов в наименование
         * @param {CategorySortOrderEnum} [sortBy] По чему сортировать
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCategoryTrees(businessAccountId: number, typeCode: CategoryTypeCodeEnum, search?: string, sortBy?: CategorySortOrderEnum, sortOrder?: 'ASC' | 'DESC', options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryForest> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listCategoryTrees(businessAccountId, typeCode, search, sortBy, sortOrder, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение текущих заполняемых в интерфейсе операций по рамке активности
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} activityFrameId Идентификатор рамки активности
         * @param {RentActivityFrameTypeCodeEnum} activityFrameType Тип рамки активности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConcurrentOperations(businessAccountId: number, activityFrameId: number, activityFrameType: RentActivityFrameTypeCodeEnum, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConcurrentOperationObjList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listConcurrentOperations(businessAccountId, activityFrameId, activityFrameType, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение списка контактов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} [search] Поисковая строка
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listContacts(businessAccountId: number, search?: string, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', filters?: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listContacts(businessAccountId, search, limit, startFrom, sortBy, sortOrder, filters, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Список кадров
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCrewMembers(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CrewMemberRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listCrewMembers(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение ограничений фильтров сотрудников бизнес аккаунта на основе присутствующих данных
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCrewMembersAvailableFiltersValues(businessAccountId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvailableFiltersValuesListObj> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listCrewMembersAvailableFiltersValues(businessAccountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Список пользовательских полей
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} [search] Поисковая строка
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {'BOOLEAN' | 'LONG' | 'DOUBLE' | 'STRING' | 'DATE' | 'ENTITY_LINK'} [typeCodeFilter] Фильтр по типу поля
         * @param {boolean} [standardFilter] Фильтр по признаку является ли поле стандартным
         * @param {'USER_LINK' | 'CONTACT_LINK' | 'SUBRENT_LINK' | 'PROJECT_LINK' | 'RENTER_LINK' | 'LONG_SUM' | 'MONEY_SUM'} [processingTypeFilter] Фильтр по типу обработки поля
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCustomFields(businessAccountId: number, search?: string, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', typeCodeFilter?: 'BOOLEAN' | 'LONG' | 'DOUBLE' | 'STRING' | 'DATE' | 'ENTITY_LINK', standardFilter?: boolean, processingTypeFilter?: 'USER_LINK' | 'CONTACT_LINK' | 'SUBRENT_LINK' | 'PROJECT_LINK' | 'RENTER_LINK' | 'LONG_SUM' | 'MONEY_SUM', options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomFieldRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listCustomFields(businessAccountId, search, limit, startFrom, sortBy, sortOrder, typeCodeFilter, standardFilter, processingTypeFilter, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Список ограничений по типам пользовательских полей
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCustomFieldsLimits(businessAccountId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomFieldsLimitObjList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listCustomFieldsLimits(businessAccountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Список шаблонов документов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {boolean} [showHidden] Если передан и true, то будут показаны даже скрытые шаблоны
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDocumentTemplates(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, showHidden?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentTemplateRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listDocumentTemplates(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, showHidden, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение списка эксель визуализаций отчета, к которому относится графическая визуализация
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {string} [search] Поисковая строка
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDocumentTemplatesForReportChartVisualization(businessAccountId: number, id: number, search?: string, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportExcelVisualizationRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listDocumentTemplatesForReportChartVisualization(businessAccountId, id, search, limit, startFrom, filters, sortBy, sortOrder, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение информации о измененных файлах
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEntityLifecycleEvents(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityLifecycleEventRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listEntityLifecycleEvents(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение ограничений фильтров по тратам
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listExpenseAvailableFilters(businessAccountId: number, filters?: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvailableFiltersValuesListObj> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listExpenseAvailableFilters(businessAccountId, filters, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Получение списка затрат по проекту
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {boolean} [excludeAggregations] Исключить аггрегации
         * @param {boolean} [excludeRecords] Исключить записи списка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listExpenses(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, excludeAggregations?: boolean, excludeRecords?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpenseRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listExpenses(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, excludeAggregations, excludeRecords, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение списка шаблонов для экспорта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listExportTemplate(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExportTemplateRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listExportTemplate(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение списка экземпляров
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInstances(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstanceRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listInstances(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение списка экземпляров с картой доступности и аггрегациями стеков на интервале
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} from Начало целевого интервала времени, epoch millis
         * @param {number} until Конец целевого интервала времени, epoch millis
         * @param {ListOnIntervalRequestObject} listOnIntervalRequestObject 
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {Array<TimetableTypeCodeEnum>} [targetStackTypeCodes] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInstancesOnInterval(businessAccountId: number, from: number, until: number, listOnIntervalRequestObject: ListOnIntervalRequestObject, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, targetStackTypeCodes?: Array<TimetableTypeCodeEnum>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstanceRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listInstancesOnInterval(businessAccountId, from, until, listOnIntervalRequestObject, limit, startFrom, sortBy, sortOrder, search, targetStackTypeCodes, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение списка событий движения инвентаря
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInventoryMovements(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InventoryMovementRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listInventoryMovements(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение ограничений фильтров бизнес аккаунта на основе присутствующих данных
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInventoryMovementsAvailableFiltersValues(businessAccountId: number, filters?: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvailableFiltersValuesListObj> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listInventoryMovementsAvailableFiltersValues(businessAccountId, filters, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение списка наборов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listKits(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<KitRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listKits(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение ограничений фильтров наборов бизнес аккаунта на основе присутствующих данных
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listKitsAvailableFiltersValues(businessAccountId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvailableFiltersValuesWithCustomFieldsListObj> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listKitsAvailableFiltersValues(businessAccountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} [search] Поисковая строка
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLocations(businessAccountId: number, search?: string, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', filters?: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listLocations(businessAccountId, search, limit, startFrom, sortBy, sortOrder, filters, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Список наборов, продуктов и вариантов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNomenclature(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NomenclatureRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listNomenclature(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение ограничений фильтров номенклатуры бизнес аккаунта на основе присутствующих данных
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNomenclatureAvailableFiltersValues(businessAccountId: number, filters?: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvailableFiltersValuesWithCustomFieldsListObj> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listNomenclatureAvailableFiltersValues(businessAccountId, filters, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Список наборов, продуктов и вариантов на интервале времени
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} from Начало целевого интервала времени, epoch millis
         * @param {number} until Конец целевого интервала времени, epoch millis
         * @param {ListOnIntervalRequestObject} listOnIntervalRequestObject 
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {Array<TimetableTypeCodeEnum>} [targetStackTypeCodes] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNomenclatureOnInterval(businessAccountId: number, from: number, until: number, listOnIntervalRequestObject: ListOnIntervalRequestObject, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, targetStackTypeCodes?: Array<TimetableTypeCodeEnum>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NomenclatureRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listNomenclatureOnInterval(businessAccountId, from, until, listOnIntervalRequestObject, limit, startFrom, sortBy, sortOrder, search, targetStackTypeCodes, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Список элементов операции
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {boolean} [excludeAggregations] Исключить аггрегации
         * @param {boolean} [excludeRecords] Исключить записи списка
         * @param {Date} [specialFromStartDateIncludingDelays] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOperationElements(businessAccountId: number, id: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, excludeAggregations?: boolean, excludeRecords?: boolean, specialFromStartDateIncludingDelays?: Date, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentElementRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listOperationElements(businessAccountId, id, limit, startFrom, filters, sortBy, sortOrder, search, excludeAggregations, excludeRecords, specialFromStartDateIncludingDelays, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Список операций
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOperations(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperationRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listOperations(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение ограничений фильтров операциям на основе присутствующих данных
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOperationsAvailableFiltersValues(businessAccountId: number, filters?: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvailableFiltersValuesListObj> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listOperationsAvailableFiltersValues(businessAccountId, filters, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Список заказов текущего пользователя в бизнес аккаунте, созданных через витрину. Необходим JWT токен пользователя
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
         * @param {number} [minimumOrderChangeDate] Фильтр для получения только заказов, созданных и измененных не ранее указанной даты, epoch millis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrdersByClient(businessAccountId: number, storefrontId: number, minimumOrderChangeDate?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listOrdersByClient(businessAccountId, storefrontId, minimumOrderChangeDate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Список заказов текущего пользователя в бизнес аккаунте, созданных через витрину. Необходим JWT токен пользователя
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} accessToken Обязательный токен доступа в формате UUID
         * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
         * @param {number} [minimumOrderChangeDate] Фильтр для получения только заказов, созданных и измененных не ранее указанной даты, epoch millis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrdersByServer(businessAccountId: number, accessToken: string, storefrontId: number, minimumOrderChangeDate?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listOrdersByServer(businessAccountId, accessToken, storefrontId, minimumOrderChangeDate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение списка способов оплаты
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPaymentMethods(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentMethodRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listPaymentMethods(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение ограничений фильтров по тратам
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPaymentMethodsAvailableFilters(businessAccountId: number, filters?: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvailableFiltersValuesListObj> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listPaymentMethodsAvailableFilters(businessAccountId, filters, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Список платежей
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPayments(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listPayments(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение ограничения фильтров для списка платежей на основе присутствующих данных
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPaymentsAvailableFiltersValues(businessAccountId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvailableFiltersValuesListObj> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listPaymentsAvailableFiltersValues(businessAccountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Список ценовых схем компании
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} [search] Поисковая строка
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {PricingSchemeTypeCodeEnum} [typeCodeFilter] Фильтр по типу поля
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPricingSchemes(businessAccountId: number, search?: string, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', typeCodeFilter?: PricingSchemeTypeCodeEnum, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PricingSchemeRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listPricingSchemes(businessAccountId, search, limit, startFrom, sortBy, sortOrder, typeCodeFilter, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение списка экземпляров продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProductInstances(businessAccountId: number, id: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstanceRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listProductInstances(businessAccountId, id, limit, startFrom, filters, sortBy, sortOrder, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение списка экземпляров продукта с картой доступности и аггрегациями стеков на интервале
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {number} from Начало целевого интервала времени, epoch millis
         * @param {number} until Конец целевого интервала времени, epoch millis
         * @param {ListOnIntervalRequestObject} listOnIntervalRequestObject 
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {Array<TimetableTypeCodeEnum>} [targetStackTypeCodes] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProductInstancesOnInterval(businessAccountId: number, id: number, from: number, until: number, listOnIntervalRequestObject: ListOnIntervalRequestObject, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, targetStackTypeCodes?: Array<TimetableTypeCodeEnum>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstanceRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listProductInstancesOnInterval(businessAccountId, id, from, until, listOnIntervalRequestObject, limit, startFrom, sortBy, sortOrder, search, targetStackTypeCodes, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение списка экземпляров варианта продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} productId Идентификатор продукта
         * @param {number} id Идентификатор сущности
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProductVariantInstances(businessAccountId: number, productId: number, id: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstanceRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listProductVariantInstances(businessAccountId, productId, id, limit, startFrom, filters, sortBy, sortOrder, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение списка экземпляров варианта продукта с картой доступности и аггрегациями стеков на интервале
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} productId Идентификатор продукта
         * @param {number} id Идентификатор сущности
         * @param {number} from Начало целевого интервала времени, epoch millis
         * @param {number} until Конец целевого интервала времени, epoch millis
         * @param {ListOnIntervalRequestObject} listOnIntervalRequestObject 
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {Array<TimetableTypeCodeEnum>} [targetStackTypeCodes] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProductVariantInstancesOnInterval(businessAccountId: number, productId: number, id: number, from: number, until: number, listOnIntervalRequestObject: ListOnIntervalRequestObject, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, targetStackTypeCodes?: Array<TimetableTypeCodeEnum>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstanceRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listProductVariantInstancesOnInterval(businessAccountId, productId, id, from, until, listOnIntervalRequestObject, limit, startFrom, sortBy, sortOrder, search, targetStackTypeCodes, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение списка вариантов продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProductVariants(businessAccountId: number, id: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VariantRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listProductVariants(businessAccountId, id, limit, startFrom, filters, sortBy, sortOrder, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение ограничений фильтров экземпляров продукта бизнес аккаунта на основе присутствующих данных
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProductVariantsAvailableFiltersValues(businessAccountId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvailableFiltersValuesListObj> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listProductVariantsAvailableFiltersValues(businessAccountId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Список продуктов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProducts(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listProducts(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение ограничений фильтров продуктов бизнес аккаунта на основе присутствующих данных
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProductsAvailableFiltersValues(businessAccountId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvailableFiltersValuesListObj> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listProductsAvailableFiltersValues(businessAccountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Список профессий
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProfessions(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfessionRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listProfessions(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение ограничений фильтров профессий бизнес аккаунта на основе присутствующих данных
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProfessionsAvailableFiltersValues(businessAccountId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvailableFiltersValuesListObj> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listProfessionsAvailableFiltersValues(businessAccountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Список элементов проекта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} projectId Идентификатор проекта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {boolean} [excludeAggregations] Исключить аггрегации
         * @param {boolean} [excludeRecords] Исключить записи списка
         * @param {Date} [specialFromStartDateIncludingDelays] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProjectElements(businessAccountId: number, projectId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, excludeAggregations?: boolean, excludeRecords?: boolean, specialFromStartDateIncludingDelays?: Date, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentElementRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listProjectElements(businessAccountId, projectId, limit, startFrom, filters, sortBy, sortOrder, search, excludeAggregations, excludeRecords, specialFromStartDateIncludingDelays, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Список проектов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProjects(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listProjects(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение ограничений фильтров проектов на основе присутствующих данных
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProjectsAvailableFiltersValues(businessAccountId: number, filters?: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvailableFiltersValuesListObj> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listProjectsAvailableFiltersValues(businessAccountId, filters, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Список рамок арендной активности
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRentActivityFrames(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentActivityFrameRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listRentActivityFrames(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Список арендаторов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRenters(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RenterRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listRenters(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение ограничений фильтров контрагентов на основе присутствующих данных
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRentersAvailableFiltersValues(businessAccountId: number, filters?: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvailableFiltersValuesListObj> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listRentersAvailableFiltersValues(businessAccountId, filters, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение списка предложений витрины бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} accessToken Обязательный токен доступа в формате UUID
         * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
         * @param {number} minimumOfferChangeDate Фильтр для получения только торговых предложений, измененных не ранее указанной даты, epoch millis
         * @param {number} [pageNumber] Номер страницы
         * @param {number} [itemsPerPage] Число элементов на странице
         * @param {Array<number>} [baseCategoryIds] Идентификаторы базовых категорий, только они и их подкатегории попадут в результат
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStorefrontOffers(businessAccountId: number, accessToken: string, storefrontId: number, minimumOfferChangeDate: number, pageNumber?: number, itemsPerPage?: number, baseCategoryIds?: Array<number>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listStorefrontOffers(businessAccountId, accessToken, storefrontId, minimumOfferChangeDate, pageNumber, itemsPerPage, baseCategoryIds, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение списка предложений витрины бизнес аккаунта типа специальность
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} accessToken Обязательный токен доступа в формате UUID
         * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
         * @param {number} minimumOfferChangeDate Фильтр для получения только торговых предложений, измененных не ранее указанной даты, epoch millis
         * @param {number} [pageNumber] Номер страницы
         * @param {number} [itemsPerPage] Число элементов на странице
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStorefrontProfessionOffers(businessAccountId: number, accessToken: string, storefrontId: number, minimumOfferChangeDate: number, pageNumber?: number, itemsPerPage?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listStorefrontProfessionOffers(businessAccountId, accessToken, storefrontId, minimumOfferChangeDate, pageNumber, itemsPerPage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Список элементов поставки
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} subrentId Идентификатор поставки
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {boolean} [excludeAggregations] Исключить аггрегации
         * @param {boolean} [excludeRecords] Исключить записи списка
         * @param {Date} [specialFromStartDateIncludingDelays] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSubrentElements(businessAccountId: number, subrentId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, excludeAggregations?: boolean, excludeRecords?: boolean, specialFromStartDateIncludingDelays?: Date, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentElementRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listSubrentElements(businessAccountId, subrentId, limit, startFrom, filters, sortBy, sortOrder, search, excludeAggregations, excludeRecords, specialFromStartDateIncludingDelays, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Список субаренды
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSubrents(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubrentRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listSubrents(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение ограничений фильтров поставок на основе присутствующих данных
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSubrentsAvailableFiltersValues(businessAccountId: number, filters?: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvailableFiltersValuesListObj> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listSubrentsAvailableFiltersValues(businessAccountId, filters, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Список наборов, продуктов и вариантов на интервале времени
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} from Начало целевого интервала времени, epoch millis
         * @param {number} until Начало целевого интервала времени, epoch millis
         * @param {boolean} includeRentElements Включать ли обязательства. В случае, если флаг true - добавляем их, если нет - выводим экземпляры со стеком доступности
         * @param {SummaryScheduleListRequestObject} summaryScheduleListRequestObject 
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {boolean} [includeInstanceSchedule] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSummarySchedule(businessAccountId: number, from: number, until: number, includeRentElements: boolean, summaryScheduleListRequestObject: SummaryScheduleListRequestObject, limit?: number, startFrom?: number, includeInstanceSchedule?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NomenclatureWithInstancesAndRentElementsList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listSummarySchedule(businessAccountId, from, until, includeRentElements, summaryScheduleListRequestObject, limit, startFrom, includeInstanceSchedule, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Список элементов шаблона
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} templateId Идентификатор шаблона
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {boolean} [excludeAggregations] Исключить аггрегации
         * @param {boolean} [excludeRecords] Исключить записи списка
         * @param {Date} [specialFromStartDateIncludingDelays] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTemplateElements(businessAccountId: number, templateId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, excludeAggregations?: boolean, excludeRecords?: boolean, specialFromStartDateIncludingDelays?: Date, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentElementRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listTemplateElements(businessAccountId, templateId, limit, startFrom, filters, sortBy, sortOrder, search, excludeAggregations, excludeRecords, specialFromStartDateIncludingDelays, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Список шаблонов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTemplates(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplateRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listTemplates(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение ограничения фильтров шаблона на основе присутствующих данных
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTemplatesAvailableFiltersValues(businessAccountId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvailableFiltersValuesListObj> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listTemplatesAvailableFiltersValues(businessAccountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} [search] Поисковая строка
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTransportations(businessAccountId: number, search?: string, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', filters?: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransportationRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listTransportations(businessAccountId, search, limit, startFrom, sortBy, sortOrder, filters, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение списка простых записей пользователей бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsersSimple(businessAccountId: number, limit?: number, startFrom?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listUsersSimple(businessAccountId, limit, startFrom, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} [search] Поисковая строка
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVehicles(businessAccountId: number, search?: string, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', filters?: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listVehicles(businessAccountId, search, limit, startFrom, sortBy, sortOrder, filters, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение ограничений фильтров по тс
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVehiclesAvailableFilters(businessAccountId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvailableFiltersValuesListObj> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listVehiclesAvailableFilters(businessAccountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Список работ
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkPlannings(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkPlanningsRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listWorkPlannings(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение ограничений фильтров работ на основе присутствующих данных
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkPlanningsAvailableFiltersValues(businessAccountId: number, filters?: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvailableFiltersValuesListObj> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listWorkPlanningsAvailableFiltersValues(businessAccountId, filters, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Сгруппированные обязательства успешно получены
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkPlanningsGroupedByCrewMember(businessAccountId: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkPlanningGroupRecordList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listWorkPlanningsGroupedByCrewMember(businessAccountId, filters, sortBy, sortOrder, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Выполнить миграцию данных
         * @param {number} businessAccountId 
         * @param {number} targetCreationAuthorId 
         * @param {any} jxlsXMLConfiguration 
         * @param {any} excelFile 
         * @param {any} massImportXMLConfiguration 
         * @param {number} [startFrom] 
         * @param {number} [count] 
         * @param {OnExistingEntityFound} [onExistingEntityFound] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrate(businessAccountId: number, targetCreationAuthorId: number, jxlsXMLConfiguration: any, excelFile: any, massImportXMLConfiguration: any, startFrom?: number, count?: number, onExistingEntityFound?: OnExistingEntityFound, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MigrationStats> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).migrate(businessAccountId, targetCreationAuthorId, jxlsXMLConfiguration, excelFile, massImportXMLConfiguration, startFrom, count, onExistingEntityFound, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Перенос затрат проекта в другой проект
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} projectId Идентификатор проекта
         * @param {number} targetProjectId Идентификатор целевого проекта (в который переносятся затраты)
         * @param {boolean} copy Признак копирования. Если да, то в целевой переносятся как черновики, в исходном не изменяются. Если нет, то в целевой переносятся как есть, в исходном отменяются
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveExpensesToOtherProject(businessAccountId: number, projectId: number, targetProjectId: number, copy: boolean, versionedEntityObjList: VersionedEntityObjList, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).moveExpensesToOtherProject(businessAccountId, projectId, targetProjectId, copy, versionedEntityObjList, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Перенос обязательств проекта в другой проект
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} projectId Идентификатор проекта
         * @param {number} targetProjectId Идентификатор целевого проекта (в который переносятся обязательства). Должен быть в работае, если исходный в работе; не должен быть новым, если исходный завершен
         * @param {boolean} copy Признак копирования. Если да, то в целевой переносятся как черновики, в исходном не изменяются. Если нет, то в целевой переносятся как есть, в исходном отменяются
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {boolean} [replaceInstancesWithAnonymous] Признак копирования с заменой именованных на анонимные. Используется только при копировании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveProjectElementsToOtherProject(businessAccountId: number, projectId: number, targetProjectId: number, copy: boolean, versionedEntityObjList: VersionedEntityObjList, replaceInstancesWithAnonymous?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).moveProjectElementsToOtherProject(businessAccountId, projectId, targetProjectId, copy, versionedEntityObjList, replaceInstancesWithAnonymous, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Перенос обязательств поставки в другую поставку
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} subrentId Идентификатор поставки
         * @param {number} targetSubrentId Идентификатор целевой поставки (в которую переносятся обязательства). Должна быть в работае, если исходная в работе; не должна быть новой, если исходная завершена
         * @param {boolean} copy Признак копирования. Если да, то в целевую переносятся как черновики, в исходном не изменяются. Если нет, то в целевую переносятся как есть, в исходном отменяются.
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {boolean} [replaceInstancesWithAnonymous] Признак копирования с заменой именованных на анонимные. Используется только при копировании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveSubrentElementsToOtherSubrent(businessAccountId: number, subrentId: number, targetSubrentId: number, copy: boolean, versionedEntityObjList: VersionedEntityObjList, replaceInstancesWithAnonymous?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).moveSubrentElementsToOtherSubrent(businessAccountId, subrentId, targetSubrentId, copy, versionedEntityObjList, replaceInstancesWithAnonymous, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Перенос работ проекта в другой проект
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} projectId Идентификатор проекта
         * @param {number} targetProjectId Идентификатор целевого проекта (в который переносятся работы)
         * @param {boolean} copy Признак копирования. Если да, то в целевой переносятся как черновики, в исходном не изменяются. Если нет, то в целевой переносятся как есть, в исходном отменяются
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {boolean} [saveCrewMembers] Признак копирования с сохранением работников. Используется только при копировании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveWorkPlanningsToOtherProject(businessAccountId: number, projectId: number, targetProjectId: number, copy: boolean, versionedEntityObjList: VersionedEntityObjList, saveCrewMembers?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).moveWorkPlanningsToOtherProject(businessAccountId, projectId, targetProjectId, copy, versionedEntityObjList, saveCrewMembers, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Список элементов бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} activityFrameId Идентификатор рамки активности - проекта, КП, поставки или шаблона
         * @param {RentActivityFrameTypeCodeEnum} activityFrameTypeCode 
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recalculateBusinessAccountElementsPrice(businessAccountId: number, activityFrameId: number, activityFrameTypeCode: RentActivityFrameTypeCodeEnum, versionedEntityObjList: VersionedEntityObjList, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProblematicRentElementInRecalculateOrNumberOfChanges> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).recalculateBusinessAccountElementsPrice(businessAccountId, activityFrameId, activityFrameTypeCode, versionedEntityObjList, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Пересчет флага удаляемости у контактов внутри БА или у всех (если null)
         * @param {number} [businessAccountId] Id бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recalculateContactsDeleteable(businessAccountId?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).recalculateContactsDeleteable(businessAccountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Пересчитать стоимость к оплате и долги по проектам
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recalculateProjectsSumsToBePaidAndDebts(businessAccountId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).recalculateProjectsSumsToBePaidAndDebts(businessAccountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Пересчитать все показатели компании и нижестроящих сущностей (реиндекс только для измененных в процессе)
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {boolean} [fullRecountWarehousePresence] Пересчитывать ли всю номенклатуру инвентаря или только ту у которой есть что-то в аренде / субаренде
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshBusinessAccount(businessAccountId: number, fullRecountWarehousePresence?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessAccountIndicatorsObj> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).refreshBusinessAccount(businessAccountId, fullRecountWarehousePresence, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Пересчитать все показатели бизнес аккаунта (без пересчета нижестоящих сущностей и без реиндекса)
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshBusinessAccountIndicators(businessAccountId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessAccountIndicatorsObj> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).refreshBusinessAccountIndicators(businessAccountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Пересчитать все показатели операции и нижестроящих сущностей, а также переиндексировать его
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshOperation(businessAccountId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperationInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).refreshOperation(businessAccountId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Пересчитать все показатели продукта и нижестроящих сущностей, а также переиндексировать его
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshProduct(businessAccountId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).refreshProduct(businessAccountId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Пересчитать все показатели проекта и нижестроящих сущностей, а также переиндексировать его
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshProject(businessAccountId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentIndicatorsObj> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).refreshProject(businessAccountId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Пересчитать все показатели элемента, а также переиндексировать его
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshRentElement(businessAccountId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentElementInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).refreshRentElement(businessAccountId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Пересчитать и переиндексировать все показатели арендатора и нижестроящих сущностей
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshRenter(businessAccountId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentIndicatorsObj> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).refreshRenter(businessAccountId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Пересчитать все показатели субаренды и нижестроящих сущностей, а также переиндексировать его
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshSubrent(businessAccountId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentIndicatorsObj> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).refreshSubrent(businessAccountId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Пересоздание контактов пользователей
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {boolean} removeExistingContactUserLinks Удалить существующие пользовательские контакты перед пересозданием
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regenerateBusinessAccountUserContacts(businessAccountId: number, removeExistingContactUserLinks: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).regenerateBusinessAccountUserContacts(businessAccountId, removeExistingContactUserLinks, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Пересоздание контактов пользователей
         * @param {boolean} removeExistingContactUserLinks Удалить существующие пользовательские контакты перед пересозданием
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regenerateUserContactsGlobally(removeExistingContactUserLinks: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).regenerateUserContactsGlobally(removeExistingContactUserLinks, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Регистрация операции и получения параллельных с ней
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {ConcurrentOperationObj} concurrentOperationObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerAndListConcurrentOperations(businessAccountId: number, concurrentOperationObj: ConcurrentOperationObj, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConcurrentOperationObjList> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).registerAndListConcurrentOperations(businessAccountId, concurrentOperationObj, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Регистрация нового бизнес аккаунта
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {BusinessAccountRegistrationObj} businessAccountRegistrationObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerBusinessAccount(timezoneOffsetUTC: number, businessAccountRegistrationObj: BusinessAccountRegistrationObj, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessAccountInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).registerBusinessAccount(timezoneOffsetUTC, businessAccountRegistrationObj, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Создание организации в системе SSO авторизации и привязка к бизнес аккаунту
         * @param {string} accessToken Обязательный токен доступа в формате UUID
         * @param {SsoClientOrganizationInfoWrite} ssoClientOrganizationInfoWrite 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerSsoClientOrganization(accessToken: string, ssoClientOrganizationInfoWrite: SsoClientOrganizationInfoWrite, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SsoClientOrganizationInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).registerSsoClientOrganization(accessToken, ssoClientOrganizationInfoWrite, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Удаление из списка закрытой операции
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} uuid Временный UUID идентификатор операции
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeConcurrentOperation(businessAccountId: number, uuid: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).removeConcurrentOperation(businessAccountId, uuid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получение списка релевантных адресов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} addressQuery Строка с частью адреса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestAddresses(businessAccountId: number, addressQuery: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AddressSuggestion>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).suggestAddresses(businessAccountId, addressQuery, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Изменение статуса и архивация бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirective} workflowDirective 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitBusinessAccountWorkflow(businessAccountId: number, workflowDirective: WorkflowDirective, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).transitBusinessAccountWorkflow(businessAccountId, workflowDirective, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Массовое изменение бизнес аккаунтов - статусы и архивирование
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitBusinessAccountWorkflowBulk(workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).transitBusinessAccountWorkflowBulk(workflowDirectiveBulk, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Массовое изменение контактов - архивирование и разархивирование
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitContactWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).transitContactWorkflowBulk(businessAccountId, workflowDirectiveBulk, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Массовое изменение кадров - статусы и архивирование
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitCrewMemberWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).transitCrewMemberWorkflowBulk(businessAccountId, workflowDirectiveBulk, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Массовое изменение затрат - статусы
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitExpenseWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).transitExpenseWorkflowBulk(businessAccountId, workflowDirectiveBulk, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Массовое изменение наборы - статусы и архивирование
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitKitWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).transitKitWorkflowBulk(businessAccountId, workflowDirectiveBulk, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Массовое изменение локаций - архивирование и разархивирование
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitLocationWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).transitLocationWorkflowBulk(businessAccountId, workflowDirectiveBulk, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Массовое изменение номенклатуры - статусы и архивирование
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {NomenclatureWorkflowDirectiveBulk} nomenclatureWorkflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitNomenclatureWorkflowBulk(businessAccountId: number, nomenclatureWorkflowDirectiveBulk: NomenclatureWorkflowDirectiveBulk, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).transitNomenclatureWorkflowBulk(businessAccountId, nomenclatureWorkflowDirectiveBulk, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Массовое изменение способов оплаты - только архивирование
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitPaymentMethodsWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).transitPaymentMethodsWorkflowBulk(businessAccountId, workflowDirectiveBulk, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Массовое изменение статуса платежа в жизненном цикле
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitPaymentsWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).transitPaymentsWorkflowBulk(businessAccountId, workflowDirectiveBulk, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Массовое изменение экземпляров продукта - только статусы
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitProductInstanceWorkflowBulk(businessAccountId: number, id: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).transitProductInstanceWorkflowBulk(businessAccountId, id, workflowDirectiveBulk, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Массовое изменение вариантов - только архивирование
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitProductVariantWorkflowBulk(businessAccountId: number, id: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).transitProductVariantWorkflowBulk(businessAccountId, id, workflowDirectiveBulk, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Массовое изменение продуктов - статусы и архивирование
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitProductWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).transitProductWorkflowBulk(businessAccountId, workflowDirectiveBulk, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Массовое изменение профессий - статусы и архивирование
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitProfessionWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).transitProfessionWorkflowBulk(businessAccountId, workflowDirectiveBulk, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Массовое изменение проектов - статусы и архивирование
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitProjectWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).transitProjectWorkflowBulk(businessAccountId, workflowDirectiveBulk, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Массовое изменение аредаторов - статусы и архивирование
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitRenterWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).transitRenterWorkflowBulk(businessAccountId, workflowDirectiveBulk, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Массовое изменение субаренд - статусы и архивирование
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitSubrentWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).transitSubrentWorkflowBulk(businessAccountId, workflowDirectiveBulk, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Массовое изменение шаблонов - статусы и архивирование
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitTemplateWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).transitTemplateWorkflowBulk(businessAccountId, workflowDirectiveBulk, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Массовое изменение локаций - архивирование и разархивирование
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitTransportationWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).transitTransportationWorkflowBulk(businessAccountId, workflowDirectiveBulk, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Массовое изменение локаций - архивирование и разархивирование
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitVehicleWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).transitVehicleWorkflowBulk(businessAccountId, workflowDirectiveBulk, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Массовое изменение событий работ
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitWorkPlanningWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).transitWorkPlanningWorkflowBulk(businessAccountId, workflowDirectiveBulk, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Изменение информации о вложении
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {AttachmentInfoUpdate} attachmentInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAttachmentById(businessAccountId: number, id: number, attachmentInfoUpdate: AttachmentInfoUpdate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AttachmentInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateAttachmentById(businessAccountId, id, attachmentInfoUpdate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Изменение состояния бизнес аккаунта (полный доступ к данным)
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {BusinessAccountInfoUpdate} businessAccountInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBusinessAccountById(businessAccountId: number, businessAccountInfoUpdate: BusinessAccountInfoUpdate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessAccountInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateBusinessAccountById(businessAccountId, businessAccountInfoUpdate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Изменение контактных данных бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {BusinessAccountContactsInfoUpdate} businessAccountContactsInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBusinessAccountContactsById(businessAccountId: number, businessAccountContactsInfoUpdate: BusinessAccountContactsInfoUpdate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessAccountContactsInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateBusinessAccountContactsById(businessAccountId, businessAccountContactsInfoUpdate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Изменение основных настроек бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {BusinessAccountPreferencesInfoUpdate} businessAccountPreferencesInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBusinessAccountPreferences(businessAccountId: number, businessAccountPreferencesInfoUpdate: BusinessAccountPreferencesInfoUpdate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessAccountPreferencesInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateBusinessAccountPreferences(businessAccountId, businessAccountPreferencesInfoUpdate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Изменение состояния категории
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {CategoryInfoUpdate} categoryInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCategoryById(businessAccountId: number, id: number, categoryInfoUpdate: CategoryInfoUpdate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateCategoryById(businessAccountId, id, categoryInfoUpdate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Изменение состояния категории с явным указанием полей
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {Array<object>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCategoryExplicit(businessAccountId: number, requestBody: Array<object>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RenterInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateCategoryExplicit(businessAccountId, requestBody, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Изменение профиля клиентского пользователя
         * @param {ClientUserInfoUpdate} clientUserInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClientUser(clientUserInfoUpdate: ClientUserInfoUpdate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientUserInfoUpdate> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateClientUser(clientUserInfoUpdate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Обновляет стандартный шаблон документа и сущность в бд
         * @summary Обновляет стандартный шаблон документа
         * @param {number} id Идентификатор сущности
         * @param {any} file 
         * @param {boolean} ignoreFile Если true, изменение контента шаблона игнорируется
         * @param {DocumentTemplateInfoUpdate} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCommonDocumentTemplate(id: number, file: any, ignoreFile: boolean, data: DocumentTemplateInfoUpdate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentTemplateInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateCommonDocumentTemplate(id, file, ignoreFile, data, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Обновление контента стандартного шаблона документа
         * @param {number} id Идентификатор сущности
         * @param {any} file 
         * @param {number} businessVersion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCommonDocumentTemplateContent(id: number, file: any, businessVersion: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentTemplateInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateCommonDocumentTemplateContent(id, file, businessVersion, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Изменение контакта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {ContactInfoUpdate} contactInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContactById(businessAccountId: number, id: number, contactInfoUpdate: ContactInfoUpdate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateContactById(businessAccountId, id, contactInfoUpdate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Изменение кадра
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {CrewMemberInfoUpdate} crewMemberInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCrewMember(businessAccountId: number, id: number, crewMemberInfoUpdate: CrewMemberInfoUpdate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CrewMemberInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateCrewMember(businessAccountId, id, crewMemberInfoUpdate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Изменение ставки кадра
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {Array<ProfessionAssignmentObjWrite>} professionAssignmentObjWrite 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCrewMemberRates(businessAccountId: number, id: number, professionAssignmentObjWrite: Array<ProfessionAssignmentObjWrite>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateCrewMemberRates(businessAccountId, id, professionAssignmentObjWrite, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Изменение состояния настроек уведомлений текущего пользователя
         * @param {NotificationSchemaInfoUpdate} notificationSchemaInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCurrentNotificationSchema(notificationSchemaInfoUpdate: NotificationSchemaInfoUpdate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationSchemaInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateCurrentNotificationSchema(notificationSchemaInfoUpdate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Изменение пользовательского поля
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {CustomFieldInfoUpdate} customFieldInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomFieldById(businessAccountId: number, id: number, customFieldInfoUpdate: CustomFieldInfoUpdate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomFieldInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateCustomFieldById(businessAccountId, id, customFieldInfoUpdate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Изменение всех пользовательских полей по типу сущности
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {string} nomenclatureType Тип номенклатуры для печати
         * @param {CustomFieldsGlobalObjWrite} customFieldsGlobalObjWrite 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomFieldsByEntityType(businessAccountId: number, id: number, nomenclatureType: string, customFieldsGlobalObjWrite: CustomFieldsGlobalObjWrite, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomFieldsGlobalObjRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateCustomFieldsByEntityType(businessAccountId, id, nomenclatureType, customFieldsGlobalObjWrite, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Изменение привязки кастомных полей к продуктам
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {boolean} heckOnly Просто проверка, если true - изменений не производится, только подсчет сущностей, которые будут заторнуты
         * @param {UpdateLinksByCategoryObj} updateLinksByCategoryObj 
         * @param {boolean} [linkVariants] Привязать поля к вариантам продукта, если он поддерживает варианты. Используется только при привязке
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomFieldsLinks(businessAccountId: number, heckOnly: boolean, updateLinksByCategoryObj: UpdateLinksByCategoryObj, linkVariants?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateCustomFieldsLinks(businessAccountId, heckOnly, updateLinksByCategoryObj, linkVariants, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Обновление существующего шаблона документа
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {any} file 
         * @param {boolean} ignoreFile Если true, изменение контента шаблона игнорируется
         * @param {DocumentTemplateInfoUpdate} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocumentTemplate(businessAccountId: number, id: number, file: any, ignoreFile: boolean, data: DocumentTemplateInfoUpdate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentTemplateInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateDocumentTemplate(businessAccountId, id, file, ignoreFile, data, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Обновление настроек для шаблона
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {boolean} common Флаг типа шаблона
         * @param {number} businessVersion Бизнес версия
         * @param {boolean} hidden Флаг является ли шаблон скрытым в БА
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocumentTemplateBusinessAccountPreferences(businessAccountId: number, id: number, common: boolean, businessVersion: number, hidden: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateDocumentTemplateBusinessAccountPreferences(businessAccountId, id, common, businessVersion, hidden, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Обновление контента пользовательского шаблона документа
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {any} file 
         * @param {number} businessVersion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocumentTemplateContent(businessAccountId: number, id: number, file: any, businessVersion: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentTemplateInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateDocumentTemplateContent(businessAccountId, id, file, businessVersion, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Изменение сущности затраты по проекту
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {ExpenseInfoUpdate} expenseInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExpense(businessAccountId: number, id: number, expenseInfoUpdate: ExpenseInfoUpdate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpenseInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateExpense(businessAccountId, id, expenseInfoUpdate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Изменение шаблона для экспорта
         * @param {number} id Идентификатор сущности
         * @param {ExportTemplateInfoUpdate} model 
         * @param {any} [content] 
         * @param {any} [jasper] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExportTemplate(id: number, model: ExportTemplateInfoUpdate, content?: any, jasper?: any, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExportTemplateInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateExportTemplate(id, model, content, jasper, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Изменение события движения инвентаря
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {InventoryMovementInfoUpdate} inventoryMovementInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInventoryMovementById(businessAccountId: number, id: number, inventoryMovementInfoUpdate: InventoryMovementInfoUpdate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InventoryMovementInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateInventoryMovementById(businessAccountId, id, inventoryMovementInfoUpdate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Изменение состояния набора
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {KitInfoUpdate} kitInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKitById(businessAccountId: number, id: number, kitInfoUpdate: KitInfoUpdate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<KitInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateKitById(businessAccountId, id, kitInfoUpdate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Изменение состояния объекта субъектов и реквизитов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {LegalDetailsInfoWrite} legalDetailsInfoWrite 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLegalDetailById(businessAccountId: number, id: number, legalDetailsInfoWrite: LegalDetailsInfoWrite, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LegalDetailsInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateLegalDetailById(businessAccountId, id, legalDetailsInfoWrite, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Изменение локации
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {LocationInfoUpdate} locationInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLocationById(businessAccountId: number, id: number, locationInfoUpdate: LocationInfoUpdate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateLocationById(businessAccountId, id, locationInfoUpdate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Изменение состояния операции
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {OperationInfoUpdate} operationInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperationById(businessAccountId: number, id: number, operationInfoUpdate: OperationInfoUpdate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperationInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateOperationById(businessAccountId, id, operationInfoUpdate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Редактирование простого заказа
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {SimpleOrderInfoUpdate} simpleOrderInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderWithRentElements(businessAccountId: number, id: number, simpleOrderInfoUpdate: SimpleOrderInfoUpdate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateOrderWithRentElements(businessAccountId, id, simpleOrderInfoUpdate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Изменение данных о платеже
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {PaymentInfoUpdate} paymentInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePaymentById(businessAccountId: number, id: number, paymentInfoUpdate: PaymentInfoUpdate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updatePaymentById(businessAccountId, id, paymentInfoUpdate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Изменение состояния способа оплаты
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {PaymentMethodInfoUpdate} paymentMethodInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePaymentMethodById(businessAccountId: number, id: number, paymentMethodInfoUpdate: PaymentMethodInfoUpdate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentMethodInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updatePaymentMethodById(businessAccountId, id, paymentMethodInfoUpdate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Изменение ценовой схемы
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {PricingSchemeInfoUpdate} pricingSchemeInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePricingSchemeById(businessAccountId: number, id: number, pricingSchemeInfoUpdate: PricingSchemeInfoUpdate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PricingSchemeInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updatePricingSchemeById(businessAccountId, id, pricingSchemeInfoUpdate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Изменение привязки ценовых схем к продуктам
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {UpdateLinksByCategoryObj} updateLinksByCategoryObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePricingSchemesLinks(businessAccountId: number, updateLinksByCategoryObj: UpdateLinksByCategoryObj, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updatePricingSchemesLinks(businessAccountId, updateLinksByCategoryObj, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Изменение состояния продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {ProductInfoUpdate} productInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductById(businessAccountId: number, id: number, productInfoUpdate: ProductInfoUpdate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateProductById(businessAccountId, id, productInfoUpdate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Изменение состояния экземпляра продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} productId Идентификатор продукта
         * @param {number} id Идентификатор сущности
         * @param {InstanceInfoUpdate} instanceInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductInstanceById(businessAccountId: number, productId: number, id: number, instanceInfoUpdate: InstanceInfoUpdate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstanceInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateProductInstanceById(businessAccountId, productId, id, instanceInfoUpdate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Изменение состояния варианта продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} productId Идентификатор продукта
         * @param {number} id Идентификатор сущности
         * @param {VariantInfoUpdate} variantInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductVariantById(businessAccountId: number, productId: number, id: number, variantInfoUpdate: VariantInfoUpdate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VariantInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateProductVariantById(businessAccountId, productId, id, variantInfoUpdate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Изменение состояния экземпляра варианта продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} productId Идентификатор продукта
         * @param {number} variantId Идентификатор варианта
         * @param {number} id Идентификатор сущности
         * @param {InstanceInfoUpdate} instanceInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductVariantInstanceById(businessAccountId: number, productId: number, variantId: number, id: number, instanceInfoUpdate: InstanceInfoUpdate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstanceInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateProductVariantInstanceById(businessAccountId, productId, variantId, id, instanceInfoUpdate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Изменение профессии
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {ProfessionInfoUpdate} professionInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfessionById(businessAccountId: number, id: number, professionInfoUpdate: ProfessionInfoUpdate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfessionInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateProfessionById(businessAccountId, id, professionInfoUpdate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Обновление профиля пользователя
         * @param {UserInfoUpdate} userInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfileData(userInfoUpdate: UserInfoUpdate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateProfileData(userInfoUpdate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Обновление пароля профиля
         * @param {UpdatePassword} updatePassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfilePassword(updatePassword: UpdatePassword, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateProfilePassword(updatePassword, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Изменение состояния проекта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {ProjectInfoUpdate} projectInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectById(businessAccountId: number, id: number, projectInfoUpdate: ProjectInfoUpdate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateProjectById(businessAccountId, id, projectInfoUpdate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Изменение состояния проекта с явным указанием полей
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {Array<object>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectByIdExplicit(businessAccountId: number, id: number, requestBody: Array<object>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateProjectByIdExplicit(businessAccountId, id, requestBody, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Изменение состояния арендатора
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {RenterInfoUpdate} renterInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRenterById(businessAccountId: number, id: number, renterInfoUpdate: RenterInfoUpdate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RenterInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateRenterById(businessAccountId, id, renterInfoUpdate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Изменение состояния арендатора с явным указанием полей
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {Array<object>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRenterByIdExplicit(businessAccountId: number, id: number, requestBody: Array<object>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateRenterByIdExplicit(businessAccountId, id, requestBody, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Обновление графической визуализации отчета
         * @param {number} reportId 
         * @param {number} id Идентификатор сущности
         * @param {ReportChartVisualizationInfoUpdate} [reportChartVisualizationInfoUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReportChartVisualizationById(reportId: number, id: number, reportChartVisualizationInfoUpdate?: ReportChartVisualizationInfoUpdate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportChartVisualizationInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateReportChartVisualizationById(reportId, id, reportChartVisualizationInfoUpdate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Изменение конфигурации отчета
         * @param {number} id Идентификатор сущности
         * @param {ReportConfigurationInfoUpdate} model 
         * @param {any} [content] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReportConfiguration(id: number, model: ReportConfigurationInfoUpdate, content?: any, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateReportConfiguration(id, model, content, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Обновление Эксель визуализации отчета
         * @param {number} reportId 
         * @param {number} id Идентификатор сущности
         * @param {ReportExcelVisualizationInfoUpdate} model 
         * @param {any} [content] 
         * @param {any} [jasper] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReportExcelVisualizationById(reportId: number, id: number, model: ReportExcelVisualizationInfoUpdate, content?: any, jasper?: any, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportExcelVisualizationInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateReportExcelVisualizationById(reportId, id, model, content, jasper, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Обновление организации в системе SSO авторизации для бизнес аккаунта
         * @param {string} accessToken Обязательный токен доступа в формате UUID
         * @param {SsoClientOrganizationInfoWrite} ssoClientOrganizationInfoWrite 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSsoClientOrganization(accessToken: string, ssoClientOrganizationInfoWrite: SsoClientOrganizationInfoWrite, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateSsoClientOrganization(accessToken, ssoClientOrganizationInfoWrite, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Изменение состояния субаренды
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {SubrentInfoUpdate} subrentInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubrentById(businessAccountId: number, id: number, subrentInfoUpdate: SubrentInfoUpdate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubrentInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateSubrentById(businessAccountId, id, subrentInfoUpdate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Изменение состояния системной настройки
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {SystemOptionInfoUpdate} systemOptionInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSystemOptionById(businessAccountId: number, id: number, systemOptionInfoUpdate: SystemOptionInfoUpdate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemOptionInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateSystemOptionById(businessAccountId, id, systemOptionInfoUpdate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Изменение состояния системной настройки
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {SystemOptionInfoUpdateBulk} systemOptionInfoUpdateBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSystemOptionsBulk(businessAccountId: number, id: number, systemOptionInfoUpdateBulk: SystemOptionInfoUpdateBulk, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemOptionsSectionReadInfo> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateSystemOptionsBulk(businessAccountId, id, systemOptionInfoUpdateBulk, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Изменение состояния шаблона
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {TemplateInfoUpdate} templateInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTemplateById(businessAccountId: number, id: number, templateInfoUpdate: TemplateInfoUpdate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplateInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateTemplateById(businessAccountId, id, templateInfoUpdate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Изменение перевозки
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {TransportationInfoUpdate} transportationInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTransportationById(businessAccountId: number, id: number, transportationInfoUpdate: TransportationInfoUpdate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransportationInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateTransportationById(businessAccountId, id, transportationInfoUpdate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Изменение транспортного средства
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {VehicleInfoUpdate} vehicleInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVehicleById(businessAccountId: number, id: number, vehicleInfoUpdate: VehicleInfoUpdate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateVehicleById(businessAccountId, id, vehicleInfoUpdate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Изменить описание работы
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {WorkPlanningsInfoUpdate} workPlanningsInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkPlanning(businessAccountId: number, id: number, workPlanningsInfoUpdate: WorkPlanningsInfoUpdate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkPlanningsInfoRead> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateWorkPlanning(businessAccountId, id, workPlanningsInfoUpdate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Загрузка kb-json
         * @param {KnowledgeBaseJson} [knowledgeBaseJson] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadKbJson(knowledgeBaseJson?: KnowledgeBaseJson, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).uploadKbJson(knowledgeBaseJson, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Загрузка переводов
         * @param {any} content 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadSystemWideTranslations(content: any, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).uploadSystemWideTranslations(content, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Валидация данных к миграции
         * @param {number} businessAccountId 
         * @param {number} targetCreationAuthorId 
         * @param {any} jxlsXMLConfiguration 
         * @param {any} excelFile 
         * @param {any} massImportXMLConfiguration 
         * @param {OnExistingEntityFound} onExistingEntityFound 
         * @param {number} [startFrom] 
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateMigrationPolicy(businessAccountId: number, targetCreationAuthorId: number, jxlsXMLConfiguration: any, excelFile: any, massImportXMLConfiguration: any, onExistingEntityFound: OnExistingEntityFound, startFrom?: number, count?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).validateMigrationPolicy(businessAccountId, targetCreationAuthorId, jxlsXMLConfiguration, excelFile, massImportXMLConfiguration, onExistingEntityFound, startFrom, count, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Проверка допустимости email в профиле (не используется другим пользователем, принадлежность к текущему пользователю допустима). Необходим JWT токен пользователя
         * @param {string} email Email пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUniqueEmail(email: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).validateUniqueEmail(email, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Добавление обязательств в существующий проект по шаблону
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {number} templateId Идентификатор шаблона
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRentElementsFromTemplateToExistingProject(businessAccountId: number, id: number, templateId: number, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).addRentElementsFromTemplateToExistingProject(businessAccountId, id, templateId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Назначение ролей пользователю
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {UserBARolesUpdateObj} userBARolesUpdateObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationAssignRolesToUser(businessAccountId: number, id: number, userBARolesUpdateObj: UserBARolesUpdateObj, options?: any): AxiosPromise<UserInfoRead> {
            return DefaultApiFp(configuration).administrationAssignRolesToUser(businessAccountId, id, userBARolesUpdateObj, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение списка записей пользователей бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {'INVITED' | 'ACTIVE' | 'BLOCKED'} [stateFilter] Фильтр по статусу пользователя
         * @param {'AGENT' | 'ADMIN'} [roleFilter] Фильтр по роли пользователя в бизнес аккаунте
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationListBAUsers(businessAccountId: number, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, stateFilter?: 'INVITED' | 'ACTIVE' | 'BLOCKED', roleFilter?: 'AGENT' | 'ADMIN', options?: any): AxiosPromise<BAUserRecordList> {
            return DefaultApiFp(configuration).administrationListBAUsers(businessAccountId, limit, startFrom, sortBy, sortOrder, search, stateFilter, roleFilter, options)(axios, basePath);
        },
        /**
         * 
         * @summary Ответ на приглашение
         * @param {string} token Код активации приглашения
         * @param {boolean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        answerToTheInvitation(token: string, body: boolean, options?: any): AxiosPromise<UserBaInvitationInfoRead> {
            return DefaultApiFp(configuration).answerToTheInvitation(token, body, options)(axios, basePath);
        },
        /**
         * 
         * @summary Назначение контакта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {ContactAssignInfo} contactAssignInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignContactToEntity(businessAccountId: number, contactAssignInfo: ContactAssignInfo, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).assignContactToEntity(businessAccountId, contactAssignInfo, options)(axios, basePath);
        },
        /**
         * 
         * @summary Массовое обновление порядка категорий
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {CategoryTypeCodeEnum} typeCode Тип изменяемых категорий
         * @param {BulkUpdateCategoriesOrder} bulkUpdateCategoriesOrder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkUpdateCategoriesOrder(businessAccountId: number, typeCode: CategoryTypeCodeEnum, bulkUpdateCategoriesOrder: BulkUpdateCategoriesOrder, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).bulkUpdateCategoriesOrder(businessAccountId, typeCode, bulkUpdateCategoriesOrder, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение расчета стоимости и доступности позиций корзины
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} accessToken Обязательный токен доступа в формате UUID
         * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
         * @param {ClientCart} clientCart 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateCart(businessAccountId: number, accessToken: string, storefrontId: number, clientCart: ClientCart, options?: any): AxiosPromise<CalculatedCart> {
            return DefaultApiFp(configuration).calculateCart(businessAccountId, accessToken, storefrontId, clientCart, options)(axios, basePath);
        },
        /**
         * 
         * @summary Пересчет отработанных смен по обязательствам с именованными экземплярами
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} subrentId Идентификатор поставки
         * @param {boolean} updateShifts Признак необходимости обновить смены обязательств субаренды результатом рассчета. Поставить в поле смены получившееся значение поля отработанные смены.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateWorkedShifts(businessAccountId: number, subrentId: number, updateShifts: boolean, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).calculateWorkedShifts(businessAccountId, subrentId, updateShifts, options)(axios, basePath);
        },
        /**
         * 
         * @summary Отмена заказа. Необходим JWT токен пользователя
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
         * @param {string} orderNumber Идентификатор заказа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelOrder(businessAccountId: number, storefrontId: number, orderNumber: string, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).cancelOrder(businessAccountId, storefrontId, orderNumber, options)(axios, basePath);
        },
        /**
         * 
         * @summary Перевод проекта в новый статус с помощью корректировки
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {ProjectStateCodeEnum} targetState Целевой статус
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeOrderStateByCorrection(businessAccountId: number, id: number, targetState: ProjectStateCodeEnum, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).changeOrderStateByCorrection(businessAccountId, id, targetState, options)(axios, basePath);
        },
        /**
         * 
         * @summary Массовая очистка тэгов у указанных сущностей
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {TagEntityTypeCode} entityTypeCode Создать или удалить, true &#x3D; создать, false &#x3D; удалить
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearTagsBulk(businessAccountId: number, entityTypeCode: TagEntityTypeCode, versionedEntityObjList: VersionedEntityObjList, options?: any): AxiosPromise<number> {
            return DefaultApiFp(configuration).clearTagsBulk(businessAccountId, entityTypeCode, versionedEntityObjList, options)(axios, basePath);
        },
        /**
         * 
         * @summary Преобразование простого заказа в проект
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertOrderToProject(businessAccountId: number, id: number, options?: any): AxiosPromise<ProjectInfoRead> {
            return DefaultApiFp(configuration).convertOrderToProject(businessAccountId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Создание нового вложения. Максимальный объем 10 МБ
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {AttachmentInfoCreate} attachmentInfoCreate 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAttachment(businessAccountId: number, attachmentInfoCreate: AttachmentInfoCreate, file: any, options?: any): AxiosPromise<AttachmentInfoRead> {
            return DefaultApiFp(configuration).createAttachment(businessAccountId, attachmentInfoCreate, file, options)(axios, basePath);
        },
        /**
         * 
         * @summary Создание нового бизнес аккаунта
         * @param {BusinessAccountInfoCreate} businessAccountInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBusinessAccount(businessAccountInfoCreate: BusinessAccountInfoCreate, options?: any): AxiosPromise<BusinessAccountInfoRead> {
            return DefaultApiFp(configuration).createBusinessAccount(businessAccountInfoCreate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Создание новой категории
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {CategoryInfoCreate} categoryInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCategory(businessAccountId: number, categoryInfoCreate: CategoryInfoCreate, options?: any): AxiosPromise<CategoryInfoRead> {
            return DefaultApiFp(configuration).createCategory(businessAccountId, categoryInfoCreate, options)(axios, basePath);
        },
        /**
         * Метод создаёт сущность в базе и записывает файл на диск
         * @summary Создание стандартного шаблона документа
         * @param {any} file 
         * @param {DocumentTemplateInfoCreate} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommonDocumentTemplate(file: any, data: DocumentTemplateInfoCreate, options?: any): AxiosPromise<DocumentTemplateInfoRead> {
            return DefaultApiFp(configuration).createCommonDocumentTemplate(file, data, options)(axios, basePath);
        },
        /**
         * 
         * @summary Создание контакта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {ContactInfoCreate} contactInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContact(businessAccountId: number, contactInfoCreate: ContactInfoCreate, options?: any): AxiosPromise<ContactInfoRead> {
            return DefaultApiFp(configuration).createContact(businessAccountId, contactInfoCreate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Создание контакта с вложениями
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {ContactInfoCreate} [contactInfoCreate] 
         * @param {Array<any>} [attachmentInfoCreates] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContactWithAttachments(businessAccountId: number, contactInfoCreate?: ContactInfoCreate, attachmentInfoCreates?: Array<any>, options?: any): AxiosPromise<ContactInfoRead> {
            return DefaultApiFp(configuration).createContactWithAttachments(businessAccountId, contactInfoCreate, attachmentInfoCreates, options)(axios, basePath);
        },
        /**
         * 
         * @summary Создание новой операции корректировки
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {OperationInfoCreate} operationInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCorrectionOperation(businessAccountId: number, operationInfoCreate: OperationInfoCreate, options?: any): AxiosPromise<OperationInfoRead> {
            return DefaultApiFp(configuration).createCorrectionOperation(businessAccountId, operationInfoCreate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Создание нового кадра
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {CrewMemberInfoCreate} crewMemberInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCrewMember(businessAccountId: number, crewMemberInfoCreate: CrewMemberInfoCreate, options?: any): AxiosPromise<CrewMemberInfoRead> {
            return DefaultApiFp(configuration).createCrewMember(businessAccountId, crewMemberInfoCreate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Создание контакта с вложениями
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {CrewMemberInfoCreate} [contactInfoCreate] 
         * @param {Array<any>} [attachmentInfoCreates] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCrewMemberWithAttachments(businessAccountId: number, contactInfoCreate?: CrewMemberInfoCreate, attachmentInfoCreates?: Array<any>, options?: any): AxiosPromise<CrewMemberInfoRead> {
            return DefaultApiFp(configuration).createCrewMemberWithAttachments(businessAccountId, contactInfoCreate, attachmentInfoCreates, options)(axios, basePath);
        },
        /**
         * 
         * @summary Создание нового пользовательского поля
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {CustomFieldInfoCreate} customFieldInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomField(businessAccountId: number, customFieldInfoCreate: CustomFieldInfoCreate, options?: any): AxiosPromise<CustomFieldInfoRead> {
            return DefaultApiFp(configuration).createCustomField(businessAccountId, customFieldInfoCreate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Привязка настраиваемых полей к вариантам продукта (создание связей)
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {CustomFieldValueObjWriteList} customFieldValueObjWriteList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomFieldLinksToProductVariants(businessAccountId: number, id: number, customFieldValueObjWriteList: CustomFieldValueObjWriteList, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).createCustomFieldLinksToProductVariants(businessAccountId, id, customFieldValueObjWriteList, options)(axios, basePath);
        },
        /**
         * 
         * @summary Создание нового шаблона документа
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {any} file 
         * @param {DocumentTemplateInfoCreate} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocumentTemplate(businessAccountId: number, file: any, data: DocumentTemplateInfoCreate, options?: any): AxiosPromise<DocumentTemplateInfoRead> {
            return DefaultApiFp(configuration).createDocumentTemplate(businessAccountId, file, data, options)(axios, basePath);
        },
        /**
         * Создание сущности затраты по проекту
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {ExpenseInfoCreate} expenseInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExpense(businessAccountId: number, expenseInfoCreate: ExpenseInfoCreate, options?: any): AxiosPromise<ExpenseInfoRead> {
            return DefaultApiFp(configuration).createExpense(businessAccountId, expenseInfoCreate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Создание нового шаблона для экспорта
         * @param {ExportTemplateInfoCreate} model 
         * @param {any} content 
         * @param {any} jasper 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExportTemplate(model: ExportTemplateInfoCreate, content: any, jasper: any, options?: any): AxiosPromise<ExportTemplateInfoRead> {
            return DefaultApiFp(configuration).createExportTemplate(model, content, jasper, options)(axios, basePath);
        },
        /**
         * 
         * @summary Создание нового изображения. Минимальная ширина и высота 200px, максимальный объем ограничен
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createImage(businessAccountId: number, file: any, options?: any): AxiosPromise<CreatedImageInfo> {
            return DefaultApiFp(configuration).createImage(businessAccountId, file, options)(axios, basePath);
        },
        /**
         * 
         * @summary Массовое создание экземпляров продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {boolean} setInventoryNumber Устанавливать дополнительно инвентарный номер
         * @param {boolean} setBarcode Устанавливать дополнительно штриховой код
         * @param {InstanceInfoCreateBulk} instanceInfoCreateBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInstancesBulk(businessAccountId: number, id: number, setInventoryNumber: boolean, setBarcode: boolean, instanceInfoCreateBulk: InstanceInfoCreateBulk, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).createInstancesBulk(businessAccountId, id, setInventoryNumber, setBarcode, instanceInfoCreateBulk, options)(axios, basePath);
        },
        /**
         * 
         * @summary Создание события движения инвентаря
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {InventoryMovementInfoCreate} inventoryMovementInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInventoryMovement(businessAccountId: number, inventoryMovementInfoCreate: InventoryMovementInfoCreate, options?: any): AxiosPromise<InventoryMovementInfoRead> {
            return DefaultApiFp(configuration).createInventoryMovement(businessAccountId, inventoryMovementInfoCreate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Создание события движения инвентаря с файлами
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {InventoryMovementInfoCreate} [inventoryMovementInfoCreate] 
         * @param {Array<any>} [attachmentInfoCreates] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInventoryMovementWithAttachments(businessAccountId: number, inventoryMovementInfoCreate?: InventoryMovementInfoCreate, attachmentInfoCreates?: Array<any>, options?: any): AxiosPromise<InventoryMovementInfoRead> {
            return DefaultApiFp(configuration).createInventoryMovementWithAttachments(businessAccountId, inventoryMovementInfoCreate, attachmentInfoCreates, options)(axios, basePath);
        },
        /**
         * 
         * @summary Создание нового набора
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {KitInfoCreate} kitInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createKit(businessAccountId: number, kitInfoCreate: KitInfoCreate, options?: any): AxiosPromise<KitInfoRead> {
            return DefaultApiFp(configuration).createKit(businessAccountId, kitInfoCreate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Создание нового объекта субъектов и реквизитов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {LegalDetailsInfoWrite} legalDetailsInfoWrite 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLegalDetails(businessAccountId: number, legalDetailsInfoWrite: LegalDetailsInfoWrite, options?: any): AxiosPromise<LegalDetailsInfoRead> {
            return DefaultApiFp(configuration).createLegalDetails(businessAccountId, legalDetailsInfoWrite, options)(axios, basePath);
        },
        /**
         * 
         * @summary Создание новой локации
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {LocationInfoCreate} locationInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLocation(businessAccountId: number, locationInfoCreate: LocationInfoCreate, options?: any): AxiosPromise<LocationInfoRead> {
            return DefaultApiFp(configuration).createLocation(businessAccountId, locationInfoCreate, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {LocationInfoCreate} [contactInfoCreate] 
         * @param {Array<any>} [attachmentInfoCreates] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLocationWithAttachments(businessAccountId: number, contactInfoCreate?: LocationInfoCreate, attachmentInfoCreates?: Array<any>, options?: any): AxiosPromise<LocationInfoRead> {
            return DefaultApiFp(configuration).createLocationWithAttachments(businessAccountId, contactInfoCreate, attachmentInfoCreates, options)(axios, basePath);
        },
        /**
         * 
         * @summary Создание новой операции
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {OperationInfoCreate} operationInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOperation(businessAccountId: number, operationInfoCreate: OperationInfoCreate, options?: any): AxiosPromise<OperationInfoRead> {
            return DefaultApiFp(configuration).createOperation(businessAccountId, operationInfoCreate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Создание заказа через витрину. Необходим JWT токен пользователя
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
         * @param {OrderInfoCreate} orderInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderByClient(businessAccountId: number, storefrontId: number, orderInfoCreate: OrderInfoCreate, options?: any): AxiosPromise<OrderInfoRead> {
            return DefaultApiFp(configuration).createOrderByClient(businessAccountId, storefrontId, orderInfoCreate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Создание заказа через витрину
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} accessToken Обязательный токен доступа в формате UUID
         * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
         * @param {OrderInfoCreate} orderInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderByServer(businessAccountId: number, accessToken: string, storefrontId: number, orderInfoCreate: OrderInfoCreate, options?: any): AxiosPromise<OrderInfoRead> {
            return DefaultApiFp(configuration).createOrderByServer(businessAccountId, accessToken, storefrontId, orderInfoCreate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Создание простого заказа
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {SimpleOrderInfoCreate} simpleOrderInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderWithRentElements(businessAccountId: number, simpleOrderInfoCreate: SimpleOrderInfoCreate, options?: any): AxiosPromise<ProjectInfoRead> {
            return DefaultApiFp(configuration).createOrderWithRentElements(businessAccountId, simpleOrderInfoCreate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Создание платежа
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {PaymentInfoCreate} paymentInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPayment(businessAccountId: number, paymentInfoCreate: PaymentInfoCreate, options?: any): AxiosPromise<PaymentInfoRead> {
            return DefaultApiFp(configuration).createPayment(businessAccountId, paymentInfoCreate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Создание нового способа оплаты
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {PaymentMethodInfoCreate} paymentMethodInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPaymentMethod(businessAccountId: number, paymentMethodInfoCreate: PaymentMethodInfoCreate, options?: any): AxiosPromise<PaymentMethodInfoRead> {
            return DefaultApiFp(configuration).createPaymentMethod(businessAccountId, paymentMethodInfoCreate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Создание платежа
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} accessToken Обязательный токен доступа в формате UUID
         * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
         * @param {PublicPaymentInfoCreate} publicPaymentInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPayment_1(businessAccountId: number, accessToken: string, storefrontId: number, publicPaymentInfoCreate: PublicPaymentInfoCreate, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).createPayment_1(businessAccountId, accessToken, storefrontId, publicPaymentInfoCreate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Создание новой ценовой схемы
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {PricingSchemeInfoCreate} pricingSchemeInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPricingScheme(businessAccountId: number, pricingSchemeInfoCreate: PricingSchemeInfoCreate, options?: any): AxiosPromise<PricingSchemeInfoRead> {
            return DefaultApiFp(configuration).createPricingScheme(businessAccountId, pricingSchemeInfoCreate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Создание нового продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {ProductInfoCreate} productInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProduct(businessAccountId: number, productInfoCreate: ProductInfoCreate, options?: any): AxiosPromise<ProductInfoRead> {
            return DefaultApiFp(configuration).createProduct(businessAccountId, productInfoCreate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Создание нового экземпляра продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {InstanceInfoCreate} instanceInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProductInstance(businessAccountId: number, id: number, instanceInfoCreate: InstanceInfoCreate, options?: any): AxiosPromise<InstanceInfoRead> {
            return DefaultApiFp(configuration).createProductInstance(businessAccountId, id, instanceInfoCreate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Создание нового варианта продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {VariantInfoCreate} variantInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProductVariant(businessAccountId: number, id: number, variantInfoCreate: VariantInfoCreate, options?: any): AxiosPromise<VariantInfoRead> {
            return DefaultApiFp(configuration).createProductVariant(businessAccountId, id, variantInfoCreate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Создание нового экземпляра варианта продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} productId Идентификатор продукта
         * @param {number} id Идентификатор сущности
         * @param {InstanceInfoCreate} instanceInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProductVariantInstance(businessAccountId: number, productId: number, id: number, instanceInfoCreate: InstanceInfoCreate, options?: any): AxiosPromise<InstanceInfoRead> {
            return DefaultApiFp(configuration).createProductVariantInstance(businessAccountId, productId, id, instanceInfoCreate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Создание профессии
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {ProfessionInfoCreate} professionInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProfession(businessAccountId: number, professionInfoCreate: ProfessionInfoCreate, options?: any): AxiosPromise<ProfessionInfoRead> {
            return DefaultApiFp(configuration).createProfession(businessAccountId, professionInfoCreate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Создание нового проекта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {ProjectInfoCreate} projectInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject(businessAccountId: number, projectInfoCreate: ProjectInfoCreate, options?: any): AxiosPromise<ProjectInfoRead> {
            return DefaultApiFp(configuration).createProject(businessAccountId, projectInfoCreate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Создание нового арендатора
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {RenterInfoCreate} renterInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRenter(businessAccountId: number, renterInfoCreate: RenterInfoCreate, options?: any): AxiosPromise<RenterInfoRead> {
            return DefaultApiFp(configuration).createRenter(businessAccountId, renterInfoCreate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Создание графической визуализации отчета
         * @param {number} reportId 
         * @param {ReportChartVisualizationInfoCreate} [reportChartVisualizationInfoCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReportChartVisualization(reportId: number, reportChartVisualizationInfoCreate?: ReportChartVisualizationInfoCreate, options?: any): AxiosPromise<ReportChartVisualizationInfoRead> {
            return DefaultApiFp(configuration).createReportChartVisualization(reportId, reportChartVisualizationInfoCreate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Создание новой конфигурации отчёта
         * @param {ReportConfigurationInfoCreate} model 
         * @param {any} content 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReportConfiguration(model: ReportConfigurationInfoCreate, content: any, options?: any): AxiosPromise<ReportConfigurationInfoRead> {
            return DefaultApiFp(configuration).createReportConfiguration(model, content, options)(axios, basePath);
        },
        /**
         * 
         * @summary Создание новой Эксель визуализации отчета
         * @param {number} reportId 
         * @param {ReportExcelVisualizationInfoCreate} model 
         * @param {any} content 
         * @param {any} jasper 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReportExcelVisualization(reportId: number, model: ReportExcelVisualizationInfoCreate, content: any, jasper: any, options?: any): AxiosPromise<ReportExcelVisualizationInfoRead> {
            return DefaultApiFp(configuration).createReportExcelVisualization(reportId, model, content, jasper, options)(axios, basePath);
        },
        /**
         * 
         * @summary Создание новой субаренды
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {SubrentInfoCreate} subrentInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubrent(businessAccountId: number, subrentInfoCreate: SubrentInfoCreate, options?: any): AxiosPromise<SubrentInfoRead> {
            return DefaultApiFp(configuration).createSubrent(businessAccountId, subrentInfoCreate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Создание нового шаблона
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {TemplateInfoCreate} templateInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTemplate(businessAccountId: number, templateInfoCreate: TemplateInfoCreate, options?: any): AxiosPromise<TemplateInfoRead> {
            return DefaultApiFp(configuration).createTemplate(businessAccountId, templateInfoCreate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Создание новой перевозки
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {TransportationInfoCreate} transportationInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTransportation(businessAccountId: number, transportationInfoCreate: TransportationInfoCreate, options?: any): AxiosPromise<TransportationInfoRead> {
            return DefaultApiFp(configuration).createTransportation(businessAccountId, transportationInfoCreate, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {TransportationInfoCreate} [contactInfoCreate] 
         * @param {Array<any>} [attachmentInfoCreates] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTransportationWithAttachments(businessAccountId: number, contactInfoCreate?: TransportationInfoCreate, attachmentInfoCreates?: Array<any>, options?: any): AxiosPromise<TransportationInfoRead> {
            return DefaultApiFp(configuration).createTransportationWithAttachments(businessAccountId, contactInfoCreate, attachmentInfoCreates, options)(axios, basePath);
        },
        /**
         * 
         * @summary Создание новой транспортного средства
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {VehicleInfoCreate} vehicleInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVehicle(businessAccountId: number, vehicleInfoCreate: VehicleInfoCreate, options?: any): AxiosPromise<VehicleInfoRead> {
            return DefaultApiFp(configuration).createVehicle(businessAccountId, vehicleInfoCreate, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {VehicleInfoCreate} [contactInfoCreate] 
         * @param {Array<any>} [attachmentInfoCreates] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVehicleWithAttachments(businessAccountId: number, contactInfoCreate?: VehicleInfoCreate, attachmentInfoCreates?: Array<any>, options?: any): AxiosPromise<VehicleInfoRead> {
            return DefaultApiFp(configuration).createVehicleWithAttachments(businessAccountId, contactInfoCreate, attachmentInfoCreates, options)(axios, basePath);
        },
        /**
         * 
         * @summary Запланировать/Создать описание работы
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkPlanningsInfoCreate} workPlanningsInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkPlanning(businessAccountId: number, workPlanningsInfoCreate: WorkPlanningsInfoCreate, options?: any): AxiosPromise<WorkPlanningsInfoRead> {
            return DefaultApiFp(configuration).createWorkPlanning(businessAccountId, workPlanningsInfoCreate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Удаление вложения
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {BusinessVersionObj} businessVersionObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAttachmentById(businessAccountId: number, id: number, businessVersionObj: BusinessVersionObj, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deleteAttachmentById(businessAccountId, id, businessVersionObj, options)(axios, basePath);
        },
        /**
         * 
         * @summary Удаление бизнес аккаунта (только для тех, которые на триальном тарифе)
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} checkString Проверочная строка, краткое наименование бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBusinessAccountById(businessAccountId: number, checkString: string, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deleteBusinessAccountById(businessAccountId, checkString, options)(axios, basePath);
        },
        /**
         * 
         * @summary Удаление категории
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {boolean} deleteSubcategories Удалить подкатегории
         * @param {boolean} deleteEntityLinks Удалить связи с продуктами (если false - перенести связи на родительскую категорию, связи подкатегорий c продуктами обрабатываются аналогично связям текущей)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCategoryById(businessAccountId: number, id: number, deleteSubcategories: boolean, deleteEntityLinks: boolean, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deleteCategoryById(businessAccountId, id, deleteSubcategories, deleteEntityLinks, options)(axios, basePath);
        },
        /**
         * 
         * @summary Удаление стандартного шаблона документа по id
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCommonDocumentTemplate(id: number, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deleteCommonDocumentTemplate(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Удаление контакта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {BusinessVersionObj} businessVersionObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContactById(businessAccountId: number, id: number, businessVersionObj: BusinessVersionObj, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deleteContactById(businessAccountId, id, businessVersionObj, options)(axios, basePath);
        },
        /**
         * 
         * @summary Удаление контактов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContacts(businessAccountId: number, versionedEntityObjList: VersionedEntityObjList, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deleteContacts(businessAccountId, versionedEntityObjList, options)(axios, basePath);
        },
        /**
         * 
         * @summary Удаление кадра
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {BusinessVersionObj} businessVersionObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCrewMember(businessAccountId: number, id: number, businessVersionObj: BusinessVersionObj, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deleteCrewMember(businessAccountId, id, businessVersionObj, options)(axios, basePath);
        },
        /**
         * 
         * @summary Удаление ставок кадра
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCrewMemberRates(businessAccountId: number, id: number, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deleteCrewMemberRates(businessAccountId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Массовое удаление сотрудников
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCrewMembersBulk(businessAccountId: number, versionedEntityObjList: VersionedEntityObjList, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deleteCrewMembersBulk(businessAccountId, versionedEntityObjList, options)(axios, basePath);
        },
        /**
         * 
         * @summary Удаление пользовательского поля
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomFieldById(businessAccountId: number, id: number, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deleteCustomFieldById(businessAccountId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Отвязка настраиваемого поля от вариантов продукта (удаление связей)
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} productId Идентификатор продукта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomFieldLinksToProductVariants(businessAccountId: number, productId: number, id: number, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deleteCustomFieldLinksToProductVariants(businessAccountId, productId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Удаление шаблона документа по id
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocumentTemplate(businessAccountId: number, id: number, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deleteDocumentTemplate(businessAccountId, id, options)(axios, basePath);
        },
        /**
         * Удаление сущности затраты по проекту
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExpense(businessAccountId: number, id: number, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deleteExpense(businessAccountId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Удаление шаблона для экспорта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExportTemplate(id: number, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deleteExportTemplate(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Удаление изображения
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImageById(businessAccountId: number, id: number, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deleteImageById(businessAccountId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Удаление набора
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {BusinessVersionObj} businessVersionObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteKitById(businessAccountId: number, id: number, businessVersionObj: BusinessVersionObj, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deleteKitById(businessAccountId, id, businessVersionObj, options)(axios, basePath);
        },
        /**
         * 
         * @summary Массовое удаление наборов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteKitsBulk(businessAccountId: number, versionedEntityObjList: VersionedEntityObjList, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deleteKitsBulk(businessAccountId, versionedEntityObjList, options)(axios, basePath);
        },
        /**
         * 
         * @summary Удаление объекта субъектов и реквизитов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {BusinessVersionObj} businessVersionObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLegalDetailById(businessAccountId: number, id: number, businessVersionObj: BusinessVersionObj, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deleteLegalDetailById(businessAccountId, id, businessVersionObj, options)(axios, basePath);
        },
        /**
         * 
         * @summary Удаление локации
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {BusinessVersionObj} businessVersionObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLocationById(businessAccountId: number, id: number, businessVersionObj: BusinessVersionObj, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deleteLocationById(businessAccountId, id, businessVersionObj, options)(axios, basePath);
        },
        /**
         * 
         * @summary Удаление локаций
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLocations(businessAccountId: number, versionedEntityObjList: VersionedEntityObjList, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deleteLocations(businessAccountId, versionedEntityObjList, options)(axios, basePath);
        },
        /**
         * 
         * @summary Массовое удаление номенклатуры
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {NomenclatureEntityList} nomenclatureEntityList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNomenclatureBulk(businessAccountId: number, nomenclatureEntityList: NomenclatureEntityList, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deleteNomenclatureBulk(businessAccountId, nomenclatureEntityList, options)(axios, basePath);
        },
        /**
         * 
         * @summary Удаление способа оплаты
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {BusinessVersionObj} businessVersionObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePaymentMethodById(businessAccountId: number, id: number, businessVersionObj: BusinessVersionObj, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deletePaymentMethodById(businessAccountId, id, businessVersionObj, options)(axios, basePath);
        },
        /**
         * 
         * @summary Массовое удаление способов оплаты
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePaymentMethodsBulk(businessAccountId: number, versionedEntityObjList: VersionedEntityObjList, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deletePaymentMethodsBulk(businessAccountId, versionedEntityObjList, options)(axios, basePath);
        },
        /**
         * 
         * @summary Удаление ценовой схемы
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {BusinessVersionObj} businessVersionObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePricingSchemeById(businessAccountId: number, id: number, businessVersionObj: BusinessVersionObj, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deletePricingSchemeById(businessAccountId, id, businessVersionObj, options)(axios, basePath);
        },
        /**
         * 
         * @summary Удаление продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {BusinessVersionObj} businessVersionObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductById(businessAccountId: number, id: number, businessVersionObj: BusinessVersionObj, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deleteProductById(businessAccountId, id, businessVersionObj, options)(axios, basePath);
        },
        /**
         * 
         * @summary Удаление экземпляра
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} productId Идентификатор продукта
         * @param {number} id Идентификатор сущности
         * @param {BusinessVersionObj} businessVersionObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductInstanceById(businessAccountId: number, productId: number, id: number, businessVersionObj: BusinessVersionObj, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deleteProductInstanceById(businessAccountId, productId, id, businessVersionObj, options)(axios, basePath);
        },
        /**
         * 
         * @summary Массовое удаление экземпляров продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductInstancesBulk(businessAccountId: number, id: number, versionedEntityObjList: VersionedEntityObjList, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deleteProductInstancesBulk(businessAccountId, id, versionedEntityObjList, options)(axios, basePath);
        },
        /**
         * 
         * @summary Удаление варианта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} productId Идентификатор продукта
         * @param {number} id Идентификатор сущности
         * @param {BusinessVersionObj} businessVersionObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductVariantById(businessAccountId: number, productId: number, id: number, businessVersionObj: BusinessVersionObj, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deleteProductVariantById(businessAccountId, productId, id, businessVersionObj, options)(axios, basePath);
        },
        /**
         * 
         * @summary Массовое удаление вариантов продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductVariantsBulk(businessAccountId: number, id: number, versionedEntityObjList: VersionedEntityObjList, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deleteProductVariantsBulk(businessAccountId, id, versionedEntityObjList, options)(axios, basePath);
        },
        /**
         * 
         * @summary Массовое удаление продуктов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductsBulk(businessAccountId: number, versionedEntityObjList: VersionedEntityObjList, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deleteProductsBulk(businessAccountId, versionedEntityObjList, options)(axios, basePath);
        },
        /**
         * 
         * @summary Удаление профессии
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {BusinessVersionObj} businessVersionObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProfessionById(businessAccountId: number, id: number, businessVersionObj: BusinessVersionObj, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deleteProfessionById(businessAccountId, id, businessVersionObj, options)(axios, basePath);
        },
        /**
         * 
         * @summary Массовое удаление профессий
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProfessionsBulk(businessAccountId: number, versionedEntityObjList: VersionedEntityObjList, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deleteProfessionsBulk(businessAccountId, versionedEntityObjList, options)(axios, basePath);
        },
        /**
         * 
         * @summary Удаление графической визуализации отчета
         * @param {number} reportId 
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReportChartVisualization(reportId: number, id: number, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deleteReportChartVisualization(reportId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Удаление конфигурации отчёта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReportConfiguration(id: number, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deleteReportConfiguration(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Удаление Эксель визуализации отчета
         * @param {number} reportId 
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReportExcelVisualization(reportId: number, id: number, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deleteReportExcelVisualization(reportId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Удаление организации в системе SSO авторизации и отвязка от бизнес аккаунта
         * @param {string} accessToken Обязательный токен доступа в формате UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSsoClientOrganization(accessToken: string, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deleteSsoClientOrganization(accessToken, options)(axios, basePath);
        },
        /**
         * 
         * @summary Удаление перевозки
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {BusinessVersionObj} businessVersionObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTransportationById(businessAccountId: number, id: number, businessVersionObj: BusinessVersionObj, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deleteTransportationById(businessAccountId, id, businessVersionObj, options)(axios, basePath);
        },
        /**
         * 
         * @summary Удаление перевозок
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTransportations(businessAccountId: number, versionedEntityObjList: VersionedEntityObjList, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deleteTransportations(businessAccountId, versionedEntityObjList, options)(axios, basePath);
        },
        /**
         * 
         * @summary Удаление транспортного средства
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {BusinessVersionObj} businessVersionObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVehicleById(businessAccountId: number, id: number, businessVersionObj: BusinessVersionObj, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deleteVehicleById(businessAccountId, id, businessVersionObj, options)(axios, basePath);
        },
        /**
         * 
         * @summary Удаление локаций
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVehicles(businessAccountId: number, versionedEntityObjList: VersionedEntityObjList, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deleteVehicles(businessAccountId, versionedEntityObjList, options)(axios, basePath);
        },
        /**
         * 
         * @summary Копирование бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateBusinessAccountById(businessAccountId: number, options?: any): AxiosPromise<BusinessAccountInfoRead> {
            return DefaultApiFp(configuration).duplicateBusinessAccountById(businessAccountId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Запрос на подключение или отключение модуля в бизнес аккаунте
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {BusinessAccountModuleEnableOrDisableObj} businessAccountModuleEnableOrDisableObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableOrDisableBusinessAccountModule(businessAccountId: number, businessAccountModuleEnableOrDisableObj: BusinessAccountModuleEnableOrDisableObj, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).enableOrDisableBusinessAccountModule(businessAccountId, businessAccountModuleEnableOrDisableObj, options)(axios, basePath);
        },
        /**
         * 
         * @summary Запрос на подключение или отключение модуля в бизнес аккаунте
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {BusinessAccountModuleEnableOrDisableObjList} businessAccountModuleEnableOrDisableObjList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableOrDisableBusinessAccountModuleBulk(businessAccountId: number, businessAccountModuleEnableOrDisableObjList: BusinessAccountModuleEnableOrDisableObjList, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).enableOrDisableBusinessAccountModuleBulk(businessAccountId, businessAccountModuleEnableOrDisableObjList, options)(axios, basePath);
        },
        /**
         * 
         * @summary Экспорт списка обязательств бизнес аккаунта. При limit = null ограничение до 1000
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {Array<string>} columns Колонки учавстующие в экспорте. Передавать [\&#39;ALL\&#39;] чтобы выгрузить все доступные
         * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {Date} [specialFromStartDateIncludingDelays] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportBusinessAccountElementsList(businessAccountId: number, columns: Array<string>, expandKits: boolean, timezoneOffsetUTC: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, specialFromStartDateIncludingDelays?: Date, options?: any): AxiosPromise<any> {
            return DefaultApiFp(configuration).exportBusinessAccountElementsList(businessAccountId, columns, expandKits, timezoneOffsetUTC, limit, startFrom, filters, sortBy, sortOrder, search, specialFromStartDateIncludingDelays, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение календаря событий бизнес аккаунта
         * @param {string} accessToken Access token. UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportCalendar(accessToken: string, options?: any): AxiosPromise<string> {
            return DefaultApiFp(configuration).exportCalendar(accessToken, options)(axios, basePath);
        },
        /**
         * 
         * @summary Экспорт списка обязательств операции. При limit = null ограничение до 1000
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {Array<string>} columns Колонки учавстующие в экспорте. Передавать [\&#39;ALL\&#39;] чтобы выгрузить все доступные
         * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportOperationElementsList(businessAccountId: number, id: number, columns: Array<string>, expandKits: boolean, timezoneOffsetUTC: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): AxiosPromise<any> {
            return DefaultApiFp(configuration).exportOperationElementsList(businessAccountId, id, columns, expandKits, timezoneOffsetUTC, limit, startFrom, filters, sortBy, sortOrder, search, options)(axios, basePath);
        },
        /**
         * 
         * @summary Экспорт списка обязательств проекта. При limit = null ограничение до 1000
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} projectId Идентификатор проекта
         * @param {Array<string>} columns Колонки учавстующие в экспорте. Передавать [\&#39;ALL\&#39;] чтобы выгрузить все доступные
         * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportProjectElementsList(businessAccountId: number, projectId: number, columns: Array<string>, expandKits: boolean, timezoneOffsetUTC: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): AxiosPromise<any> {
            return DefaultApiFp(configuration).exportProjectElementsList(businessAccountId, projectId, columns, expandKits, timezoneOffsetUTC, limit, startFrom, filters, sortBy, sortOrder, search, options)(axios, basePath);
        },
        /**
         * 
         * @summary Экспорт списка обязательств поставки. При limit = null ограничение до 1000
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} subrentId Идентификатор поставки
         * @param {Array<string>} columns Колонки учавстующие в экспорте. Передавать [\&#39;ALL\&#39;] чтобы выгрузить все доступные
         * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportSubrentElementsList(businessAccountId: number, subrentId: number, columns: Array<string>, expandKits: boolean, timezoneOffsetUTC: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): AxiosPromise<any> {
            return DefaultApiFp(configuration).exportSubrentElementsList(businessAccountId, subrentId, columns, expandKits, timezoneOffsetUTC, limit, startFrom, filters, sortBy, sortOrder, search, options)(axios, basePath);
        },
        /**
         * 
         * @summary Экспорт списка обязательств шаблона. При limit = null ограничение до 1000
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} templateId Идентификатор шаблона
         * @param {Array<string>} columns Колонки учавстующие в экспорте. Передавать [\&#39;ALL\&#39;] чтобы выгрузить все доступные
         * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportTemplateElementsList(businessAccountId: number, templateId: number, columns: Array<string>, expandKits: boolean, timezoneOffsetUTC: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): AxiosPromise<any> {
            return DefaultApiFp(configuration).exportTemplateElementsList(businessAccountId, templateId, columns, expandKits, timezoneOffsetUTC, limit, startFrom, filters, sortBy, sortOrder, search, options)(axios, basePath);
        },
        /**
         * 
         * @summary Переиндексировать все сущности компании с удалением лишних
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fullReindexBusinessAccount(businessAccountId: number, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).fullReindexBusinessAccount(businessAccountId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение файла бейджа
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} nomenclatureType Тип номенклатуры для печати
         * @param {number} nomenclatureId Id сущности
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {boolean} [common] Флаг типа шаблона
         * @param {number} [documentTemplateId] Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateBadgePdf(businessAccountId: number, nomenclatureType: string, nomenclatureId: number, timezoneOffsetUTC: number, common?: boolean, documentTemplateId?: number, options?: any): AxiosPromise<any> {
            return DefaultApiFp(configuration).generateBadgePdf(businessAccountId, nomenclatureType, nomenclatureId, timezoneOffsetUTC, common, documentTemplateId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение изображения штрихового кода
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} barcodeString Штриховой код
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateBarcodeImage(businessAccountId: number, barcodeString: string, options?: any): AxiosPromise<any> {
            return DefaultApiFp(configuration).generateBarcodeImage(businessAccountId, barcodeString, options)(axios, basePath);
        },
        /**
         * 
         * @summary Генерация документа транспортной накладной в формат DOCX
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {boolean} common Флаг типа шаблона
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateBillOfLadingDocx(businessAccountId: number, id: number, common: boolean, documentTemplateId: number, businessAccountLegalDetailsId?: number, options?: any): AxiosPromise<any> {
            return DefaultApiFp(configuration).generateBillOfLadingDocx(businessAccountId, id, common, documentTemplateId, businessAccountLegalDetailsId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Генерация документа транспортной накладной в формат PDF
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {boolean} common Флаг типа шаблона
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateBillOfLadingPdf(businessAccountId: number, id: number, common: boolean, documentTemplateId: number, businessAccountLegalDetailsId?: number, options?: any): AxiosPromise<any> {
            return DefaultApiFp(configuration).generateBillOfLadingPdf(businessAccountId, id, common, documentTemplateId, businessAccountLegalDetailsId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Генерация документа счета в формат PDF
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {boolean} common Флаг типа шаблона
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateBillPdf(businessAccountId: number, id: number, common: boolean, documentTemplateId: number, timezoneOffsetUTC: number, businessAccountLegalDetailsId?: number, options?: any): AxiosPromise<any> {
            return DefaultApiFp(configuration).generateBillPdf(businessAccountId, id, common, documentTemplateId, timezoneOffsetUTC, businessAccountLegalDetailsId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Запрос на генерацию нового токена доступа к апи календаря
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateCalendarAccessToken(businessAccountId: number, options?: any): AxiosPromise<string> {
            return DefaultApiFp(configuration).generateCalendarAccessToken(businessAccountId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Генерация документа договора по проекту в формат DOCX
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} projectId Идентификатор проекта
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {boolean} common Флаг типа шаблона
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateContractDocumentDocx(businessAccountId: number, projectId: number, documentTemplateId: number, common: boolean, timezoneOffsetUTC: number, businessAccountLegalDetailsId?: number, options?: any): AxiosPromise<any> {
            return DefaultApiFp(configuration).generateContractDocumentDocx(businessAccountId, projectId, documentTemplateId, common, timezoneOffsetUTC, businessAccountLegalDetailsId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Генерация документа договора по проекту в формат PDF
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} projectId Идентификатор проекта
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {boolean} common Флаг типа шаблона
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateContractDocumentPdf(businessAccountId: number, projectId: number, documentTemplateId: number, common: boolean, timezoneOffsetUTC: number, businessAccountLegalDetailsId?: number, options?: any): AxiosPromise<any> {
            return DefaultApiFp(configuration).generateContractDocumentPdf(businessAccountId, projectId, documentTemplateId, common, timezoneOffsetUTC, businessAccountLegalDetailsId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Генерация представления отчета в формат PDF
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {ExcelExportGenerationInfo} excelExportGenerationInfo 
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateExportPdf(businessAccountId: number, excelExportGenerationInfo: ExcelExportGenerationInfo, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): AxiosPromise<any> {
            return DefaultApiFp(configuration).generateExportPdf(businessAccountId, excelExportGenerationInfo, filters, sortBy, sortOrder, search, options)(axios, basePath);
        },
        /**
         * 
         * @summary Генерация представления отчета в формат XLSX
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {ExcelExportGenerationInfo} excelExportGenerationInfo 
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateExportXlsx(businessAccountId: number, excelExportGenerationInfo: ExcelExportGenerationInfo, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): AxiosPromise<any> {
            return DefaultApiFp(configuration).generateExportXlsx(businessAccountId, excelExportGenerationInfo, filters, sortBy, sortOrder, search, options)(axios, basePath);
        },
        /**
         * 
         * @summary Генерация документа прайс листа в формат PDF
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {boolean} common Флаг типа шаблона
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {number} appliedDiscount Скидка
         * @param {boolean} showDiscount Показывать ли скидку явно
         * @param {boolean} showPricingSchemes Показывать ли ценовые схемы
         * @param {boolean} showDescription Показывать ли описание
         * @param {boolean} showCount Показывать ли общее количество
         * @param {boolean} groupByCategories Группировать по категориям
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateNomenclaturePricelistPdf(businessAccountId: number, common: boolean, documentTemplateId: number, appliedDiscount: number, showDiscount: boolean, showPricingSchemes: boolean, showDescription: boolean, showCount: boolean, groupByCategories: boolean, timezoneOffsetUTC: number, businessAccountLegalDetailsId?: number, filters?: Array<string>, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): AxiosPromise<any> {
            return DefaultApiFp(configuration).generateNomenclaturePricelistPdf(businessAccountId, common, documentTemplateId, appliedDiscount, showDiscount, showPricingSchemes, showDescription, showCount, groupByCategories, timezoneOffsetUTC, businessAccountLegalDetailsId, filters, limit, startFrom, sortBy, sortOrder, search, options)(axios, basePath);
        },
        /**
         * 
         * @summary Генерация документа прайс листа в формат XLSX
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {boolean} common Флаг типа шаблона
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {number} appliedDiscount Скидка
         * @param {boolean} showDiscount Показывать ли скидку явно
         * @param {boolean} showPricingSchemes Показывать ли ценовые схемы
         * @param {boolean} showDescription Показывать ли описание
         * @param {boolean} showCount Показывать ли общее количество
         * @param {boolean} groupByCategories Группировать по категориям
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateNomenclaturePricelistXlsx(businessAccountId: number, common: boolean, documentTemplateId: number, appliedDiscount: number, showDiscount: boolean, showPricingSchemes: boolean, showDescription: boolean, showCount: boolean, groupByCategories: boolean, timezoneOffsetUTC: number, businessAccountLegalDetailsId?: number, filters?: Array<string>, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): AxiosPromise<any> {
            return DefaultApiFp(configuration).generateNomenclaturePricelistXlsx(businessAccountId, common, documentTemplateId, appliedDiscount, showDiscount, showPricingSchemes, showDescription, showCount, groupByCategories, timezoneOffsetUTC, businessAccountLegalDetailsId, filters, limit, startFrom, sortBy, sortOrder, search, options)(axios, basePath);
        },
        /**
         * 
         * @summary Генерация акта по операции в формат DOCX
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} operationId Идентификатор операции
         * @param {boolean} common Флаг типа шаблона
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
         * @param {boolean} joinSimilarRows Объединять ли одинаковые строки при экспорте (для развернутых наборов не работает)
         * @param {boolean} groupByCategories Группировать по категориям
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {CategorySortOrderEnum} [categorySortBy] 
         * @param {'ASC' | 'DESC'} [categorySortOrder] 
         * @param {Date} [t0] 
         * @param {Date} [tN] 
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateOperationActDocumentDocx(businessAccountId: number, operationId: number, common: boolean, documentTemplateId: number, expandKits: boolean, joinSimilarRows: boolean, groupByCategories: boolean, timezoneOffsetUTC: number, categorySortBy?: CategorySortOrderEnum, categorySortOrder?: 'ASC' | 'DESC', t0?: Date, tN?: Date, businessAccountLegalDetailsId?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): AxiosPromise<any> {
            return DefaultApiFp(configuration).generateOperationActDocumentDocx(businessAccountId, operationId, common, documentTemplateId, expandKits, joinSimilarRows, groupByCategories, timezoneOffsetUTC, categorySortBy, categorySortOrder, t0, tN, businessAccountLegalDetailsId, filters, sortBy, sortOrder, search, options)(axios, basePath);
        },
        /**
         * 
         * @summary Генерация акта по операции в формат PDF
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} operationId Идентификатор операции
         * @param {boolean} common Флаг типа шаблона
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
         * @param {boolean} joinSimilarRows Объединять ли одинаковые строки при экспорте (для развернутых наборов не работает)
         * @param {boolean} groupByCategories Группировать по категориям
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {CategorySortOrderEnum} [categorySortBy] 
         * @param {'ASC' | 'DESC'} [categorySortOrder] 
         * @param {Date} [t0] 
         * @param {Date} [tN] 
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateOperationActDocumentPdf(businessAccountId: number, operationId: number, common: boolean, documentTemplateId: number, expandKits: boolean, joinSimilarRows: boolean, groupByCategories: boolean, timezoneOffsetUTC: number, categorySortBy?: CategorySortOrderEnum, categorySortOrder?: 'ASC' | 'DESC', t0?: Date, tN?: Date, businessAccountLegalDetailsId?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): AxiosPromise<any> {
            return DefaultApiFp(configuration).generateOperationActDocumentPdf(businessAccountId, operationId, common, documentTemplateId, expandKits, joinSimilarRows, groupByCategories, timezoneOffsetUTC, categorySortBy, categorySortOrder, t0, tN, businessAccountLegalDetailsId, filters, sortBy, sortOrder, search, options)(axios, basePath);
        },
        /**
         * 
         * @summary Генерация документа сметы операции в формат PDF
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} operationId Идентификатор операции
         * @param {boolean} common Флаг типа шаблона
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
         * @param {boolean} joinSimilarRows Объединять ли одинаковые строки при экспорте (для развернутых наборов не работает)
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {CategorySortOrderEnum} [categorySortBy] 
         * @param {'ASC' | 'DESC'} [categorySortOrder] 
         * @param {Date} [t0] 
         * @param {Date} [tN] 
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateOperationEstimateDocumentPdf(businessAccountId: number, operationId: number, common: boolean, documentTemplateId: number, expandKits: boolean, joinSimilarRows: boolean, timezoneOffsetUTC: number, categorySortBy?: CategorySortOrderEnum, categorySortOrder?: 'ASC' | 'DESC', t0?: Date, tN?: Date, businessAccountLegalDetailsId?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): AxiosPromise<any> {
            return DefaultApiFp(configuration).generateOperationEstimateDocumentPdf(businessAccountId, operationId, common, documentTemplateId, expandKits, joinSimilarRows, timezoneOffsetUTC, categorySortBy, categorySortOrder, t0, tN, businessAccountLegalDetailsId, filters, sortBy, sortOrder, search, options)(axios, basePath);
        },
        /**
         * 
         * @summary Генерация документа сметы операции в формат XLSX
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} operationId Идентификатор операции
         * @param {boolean} common Флаг типа шаблона
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
         * @param {boolean} joinSimilarRows Объединять ли одинаковые строки при экспорте (для развернутых наборов не работает)
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {CategorySortOrderEnum} [categorySortBy] 
         * @param {'ASC' | 'DESC'} [categorySortOrder] 
         * @param {Date} [t0] 
         * @param {Date} [tN] 
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateOperationEstimateDocumentXlsx(businessAccountId: number, operationId: number, common: boolean, documentTemplateId: number, expandKits: boolean, joinSimilarRows: boolean, timezoneOffsetUTC: number, categorySortBy?: CategorySortOrderEnum, categorySortOrder?: 'ASC' | 'DESC', t0?: Date, tN?: Date, businessAccountLegalDetailsId?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): AxiosPromise<any> {
            return DefaultApiFp(configuration).generateOperationEstimateDocumentXlsx(businessAccountId, operationId, common, documentTemplateId, expandKits, joinSimilarRows, timezoneOffsetUTC, categorySortBy, categorySortOrder, t0, tN, businessAccountLegalDetailsId, filters, sortBy, sortOrder, search, options)(axios, basePath);
        },
        /**
         * 
         * @summary Генерация документа списка инвентаря в формат PDF
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {boolean} common Флаг типа шаблона
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {boolean} showBarcode Показывать ли штриховой код
         * @param {boolean} expandOwnNamedInstances Показывать ли свои именованные экземпляры при экспорте
         * @param {boolean} expandSubrentedNamedInstances Включать ли чужие экземпляры при экспорте
         * @param {boolean} showPurchaseCost Показывать ли закупочную стоимость при экспорте
         * @param {boolean} showCarryingValue Показывать ли балансовую стоимость при экспорте
         * @param {boolean} expandInstanceStateDescription Показывать ли описания состояний экземпляров при экспорте
         * @param {boolean} showColumnActualAvailability Показывать ли колонку \&#39;Фактическое наличие\&#39; при экспорте
         * @param {boolean} showColumnConvergence Показывать ли колонку \&#39;Схождение\&#39; при экспорте
         * @param {boolean} showColumnNotes Показывать ли колонку \&#39;Для пометок\&#39; при экспорте
         * @param {boolean} showSupplier Показывать ли колонку \&#39;Поставщик\&#39; при экспорте
         * @param {boolean} groupByCategories Группировать по категориям
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {CategorySortOrderEnum} [categorySortBy] 
         * @param {'ASC' | 'DESC'} [categorySortOrder] 
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateProductsInventoryListDocumentPdf(businessAccountId: number, common: boolean, documentTemplateId: number, showBarcode: boolean, expandOwnNamedInstances: boolean, expandSubrentedNamedInstances: boolean, showPurchaseCost: boolean, showCarryingValue: boolean, expandInstanceStateDescription: boolean, showColumnActualAvailability: boolean, showColumnConvergence: boolean, showColumnNotes: boolean, showSupplier: boolean, groupByCategories: boolean, timezoneOffsetUTC: number, categorySortBy?: CategorySortOrderEnum, categorySortOrder?: 'ASC' | 'DESC', businessAccountLegalDetailsId?: number, filters?: Array<string>, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): AxiosPromise<any> {
            return DefaultApiFp(configuration).generateProductsInventoryListDocumentPdf(businessAccountId, common, documentTemplateId, showBarcode, expandOwnNamedInstances, expandSubrentedNamedInstances, showPurchaseCost, showCarryingValue, expandInstanceStateDescription, showColumnActualAvailability, showColumnConvergence, showColumnNotes, showSupplier, groupByCategories, timezoneOffsetUTC, categorySortBy, categorySortOrder, businessAccountLegalDetailsId, filters, limit, startFrom, sortBy, sortOrder, search, options)(axios, basePath);
        },
        /**
         * 
         * @summary Генерация документа списка инвентаря в формат XLSX
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {boolean} common Флаг типа шаблона
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {boolean} showBarcode Показывать ли штриховой код
         * @param {boolean} expandOwnNamedInstances Показывать ли свои именованные экземпляры при экспорте
         * @param {boolean} expandSubrentedNamedInstances Включать ли чужие экземпляры при экспорте
         * @param {boolean} showPurchaseCost Показывать ли закупочную стоимость при экспорте
         * @param {boolean} showCarryingValue Показывать ли балансовую стоимость при экспорте
         * @param {boolean} expandInstanceStateDescription Показывать ли описания состояний экземпляров при экспорте
         * @param {boolean} showColumnActualAvailability Показывать ли колонку \&#39;Фактическое наличие\&#39; при экспорте
         * @param {boolean} showColumnConvergence Показывать ли колонку \&#39;Схождение\&#39; при экспорте
         * @param {boolean} showColumnNotes Показывать ли колонку \&#39;Для пометок\&#39; при экспорте
         * @param {boolean} showSupplier Показывать ли колонку \&#39;Поставщик\&#39; при экспорте
         * @param {boolean} groupByCategories Группировать по категориям
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {CategorySortOrderEnum} [categorySortBy] 
         * @param {'ASC' | 'DESC'} [categorySortOrder] 
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateProductsInventoryListDocumentXlsx(businessAccountId: number, common: boolean, documentTemplateId: number, showBarcode: boolean, expandOwnNamedInstances: boolean, expandSubrentedNamedInstances: boolean, showPurchaseCost: boolean, showCarryingValue: boolean, expandInstanceStateDescription: boolean, showColumnActualAvailability: boolean, showColumnConvergence: boolean, showColumnNotes: boolean, showSupplier: boolean, groupByCategories: boolean, timezoneOffsetUTC: number, categorySortBy?: CategorySortOrderEnum, categorySortOrder?: 'ASC' | 'DESC', businessAccountLegalDetailsId?: number, filters?: Array<string>, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): AxiosPromise<any> {
            return DefaultApiFp(configuration).generateProductsInventoryListDocumentXlsx(businessAccountId, common, documentTemplateId, showBarcode, expandOwnNamedInstances, expandSubrentedNamedInstances, showPurchaseCost, showCarryingValue, expandInstanceStateDescription, showColumnActualAvailability, showColumnConvergence, showColumnNotes, showSupplier, groupByCategories, timezoneOffsetUTC, categorySortBy, categorySortOrder, businessAccountLegalDetailsId, filters, limit, startFrom, sortBy, sortOrder, search, options)(axios, basePath);
        },
        /**
         * 
         * @summary Генерация акта по проекту в формат DOCX
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} projectId Идентификатор проекта
         * @param {boolean} includeWorkPlannings 
         * @param {boolean} includeExpenses 
         * @param {boolean} common Флаг типа шаблона
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
         * @param {boolean} joinSimilarRows Объединять ли одинаковые строки при экспорте (для развернутых наборов не работает)
         * @param {boolean} groupByCategories Группировать по категориям
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {CategorySortOrderEnum} [categorySortBy] 
         * @param {'ASC' | 'DESC'} [categorySortOrder] 
         * @param {Date} [t0] 
         * @param {Date} [tN] 
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateProjectActDocumentDocx(businessAccountId: number, projectId: number, includeWorkPlannings: boolean, includeExpenses: boolean, common: boolean, documentTemplateId: number, expandKits: boolean, joinSimilarRows: boolean, groupByCategories: boolean, timezoneOffsetUTC: number, categorySortBy?: CategorySortOrderEnum, categorySortOrder?: 'ASC' | 'DESC', t0?: Date, tN?: Date, businessAccountLegalDetailsId?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): AxiosPromise<any> {
            return DefaultApiFp(configuration).generateProjectActDocumentDocx(businessAccountId, projectId, includeWorkPlannings, includeExpenses, common, documentTemplateId, expandKits, joinSimilarRows, groupByCategories, timezoneOffsetUTC, categorySortBy, categorySortOrder, t0, tN, businessAccountLegalDetailsId, filters, sortBy, sortOrder, search, options)(axios, basePath);
        },
        /**
         * 
         * @summary Генерация акта по проекту в формат PDF
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} projectId Идентификатор проекта
         * @param {boolean} includeWorkPlannings 
         * @param {boolean} includeExpenses 
         * @param {boolean} common Флаг типа шаблона
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
         * @param {boolean} joinSimilarRows Объединять ли одинаковые строки при экспорте (для развернутых наборов не работает)
         * @param {boolean} groupByCategories Группировать по категориям
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {CategorySortOrderEnum} [categorySortBy] 
         * @param {'ASC' | 'DESC'} [categorySortOrder] 
         * @param {Date} [t0] 
         * @param {Date} [tN] 
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateProjectActDocumentPdf(businessAccountId: number, projectId: number, includeWorkPlannings: boolean, includeExpenses: boolean, common: boolean, documentTemplateId: number, expandKits: boolean, joinSimilarRows: boolean, groupByCategories: boolean, timezoneOffsetUTC: number, categorySortBy?: CategorySortOrderEnum, categorySortOrder?: 'ASC' | 'DESC', t0?: Date, tN?: Date, businessAccountLegalDetailsId?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): AxiosPromise<any> {
            return DefaultApiFp(configuration).generateProjectActDocumentPdf(businessAccountId, projectId, includeWorkPlannings, includeExpenses, common, documentTemplateId, expandKits, joinSimilarRows, groupByCategories, timezoneOffsetUTC, categorySortBy, categorySortOrder, t0, tN, businessAccountLegalDetailsId, filters, sortBy, sortOrder, search, options)(axios, basePath);
        },
        /**
         * 
         * @summary Генерация документа сметы проекта в формат PDF
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} projectId Идентификатор проекта
         * @param {boolean} includeWorkPlannings 
         * @param {boolean} includeExpenses 
         * @param {boolean} common Флаг типа шаблона
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
         * @param {boolean} joinSimilarRows Объединять ли одинаковые строки при экспорте (для развернутых наборов не работает)
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {CategorySortOrderEnum} [categorySortBy] 
         * @param {'ASC' | 'DESC'} [categorySortOrder] 
         * @param {Date} [t0] 
         * @param {Date} [tN] 
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateProjectEstimateDocumentPdf(businessAccountId: number, projectId: number, includeWorkPlannings: boolean, includeExpenses: boolean, common: boolean, documentTemplateId: number, expandKits: boolean, joinSimilarRows: boolean, timezoneOffsetUTC: number, categorySortBy?: CategorySortOrderEnum, categorySortOrder?: 'ASC' | 'DESC', t0?: Date, tN?: Date, businessAccountLegalDetailsId?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): AxiosPromise<any> {
            return DefaultApiFp(configuration).generateProjectEstimateDocumentPdf(businessAccountId, projectId, includeWorkPlannings, includeExpenses, common, documentTemplateId, expandKits, joinSimilarRows, timezoneOffsetUTC, categorySortBy, categorySortOrder, t0, tN, businessAccountLegalDetailsId, filters, sortBy, sortOrder, search, options)(axios, basePath);
        },
        /**
         * 
         * @summary Генерация документа сметы проекта в формат XLSX
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} projectId Идентификатор проекта
         * @param {boolean} includeWorkPlannings 
         * @param {boolean} includeExpenses 
         * @param {boolean} common Флаг типа шаблона
         * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
         * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
         * @param {boolean} joinSimilarRows Объединять ли одинаковые строки при экспорте (для развернутых наборов не работает)
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {CategorySortOrderEnum} [categorySortBy] 
         * @param {'ASC' | 'DESC'} [categorySortOrder] 
         * @param {Date} [t0] 
         * @param {Date} [tN] 
         * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateProjectEstimateDocumentXlsx(businessAccountId: number, projectId: number, includeWorkPlannings: boolean, includeExpenses: boolean, common: boolean, documentTemplateId: number, expandKits: boolean, joinSimilarRows: boolean, timezoneOffsetUTC: number, categorySortBy?: CategorySortOrderEnum, categorySortOrder?: 'ASC' | 'DESC', t0?: Date, tN?: Date, businessAccountLegalDetailsId?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): AxiosPromise<any> {
            return DefaultApiFp(configuration).generateProjectEstimateDocumentXlsx(businessAccountId, projectId, includeWorkPlannings, includeExpenses, common, documentTemplateId, expandKits, joinSimilarRows, timezoneOffsetUTC, categorySortBy, categorySortOrder, t0, tN, businessAccountLegalDetailsId, filters, sortBy, sortOrder, search, options)(axios, basePath);
        },
        /**
         * 
         * @summary Генерация отчета
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {ReportInputParameters} reportInputParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateReport(businessAccountId: number, id: number, reportInputParameters: ReportInputParameters, options?: any): AxiosPromise<string> {
            return DefaultApiFp(configuration).generateReport(businessAccountId, id, reportInputParameters, options)(axios, basePath);
        },
        /**
         * 
         * @summary Генерация представления отчета в формат PDF
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {ExcelReportGenerationInfo} excelReportGenerationInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateReportPdf(businessAccountId: number, id: number, timezoneOffsetUTC: number, excelReportGenerationInfo: ExcelReportGenerationInfo, options?: any): AxiosPromise<any> {
            return DefaultApiFp(configuration).generateReportPdf(businessAccountId, id, timezoneOffsetUTC, excelReportGenerationInfo, options)(axios, basePath);
        },
        /**
         * 
         * @summary Генерация представления отчета в формат XLSX
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {ExcelReportGenerationInfo} excelReportGenerationInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateReportXlsx(businessAccountId: number, id: number, timezoneOffsetUTC: number, excelReportGenerationInfo: ExcelReportGenerationInfo, options?: any): AxiosPromise<any> {
            return DefaultApiFp(configuration).generateReportXlsx(businessAccountId, id, timezoneOffsetUTC, excelReportGenerationInfo, options)(axios, basePath);
        },
        /**
         * 
         * @summary Запрос на генерацию нового токена доступа к апи витрины
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateStorefrontAccessToken(businessAccountId: number, options?: any): AxiosPromise<string> {
            return DefaultApiFp(configuration).generateStorefrontAccessToken(businessAccountId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение информации о вложении
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttachmentById(businessAccountId: number, id: number, options?: any): AxiosPromise<AttachmentInfoRead> {
            return DefaultApiFp(configuration).getAttachmentById(businessAccountId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение списка реквизитов по стране
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {LegalCountryCodeEnum} legalCountryTypeCode Код страны в юридическом контексте
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBALegalDetailsFieldsListByCountry(businessAccountId: number, legalCountryTypeCode: LegalCountryCodeEnum, options?: any): AxiosPromise<Array<CountryLegalDetailsFieldsObjRead>> {
            return DefaultApiFp(configuration).getBALegalDetailsFieldsListByCountry(businessAccountId, legalCountryTypeCode, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение информации о компании
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} accessToken Обязательный токен доступа в формате UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessAccount(businessAccountId: number, accessToken: string, options?: any): AxiosPromise<PublicBusinessAccountInfo> {
            return DefaultApiFp(configuration).getBusinessAccount(businessAccountId, accessToken, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение состояния бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessAccountById(businessAccountId: number, options?: any): AxiosPromise<BusinessAccountInfoRead> {
            return DefaultApiFp(configuration).getBusinessAccountById(businessAccountId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Изменение контактных данных бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessAccountContactsById(businessAccountId: number, options?: any): AxiosPromise<BusinessAccountContactsInfoRead> {
            return DefaultApiFp(configuration).getBusinessAccountContactsById(businessAccountId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Данные дешборда бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessAccountDashboardData(businessAccountId: number, options?: any): AxiosPromise<BusinessAccountDashboardDataObj> {
            return DefaultApiFp(configuration).getBusinessAccountDashboardData(businessAccountId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение индикаторов бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessAccountIndicators(businessAccountId: number, options?: any): AxiosPromise<BusinessAccountIndicatorsObj> {
            return DefaultApiFp(configuration).getBusinessAccountIndicators(businessAccountId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Список ссылок на статьи базы знаний с названиями в текущей локали
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} interfaceBlockCode Код блока интерфейся для которого требуется выгрузить статьи
         * @param {LocaleCodeEnum} locale Локаль для которой требуется выгрузить статьи
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessAccountKnowledgeBaseArticleLinkList(businessAccountId: number, interfaceBlockCode: string, locale: LocaleCodeEnum, options?: any): AxiosPromise<KnowledgeBaseArticleLinkObjList> {
            return DefaultApiFp(configuration).getBusinessAccountKnowledgeBaseArticleLinkList(businessAccountId, interfaceBlockCode, locale, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение основных настроек бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessAccountPreferences(businessAccountId: number, options?: any): AxiosPromise<BusinessAccountPreferencesInfoRead> {
            return DefaultApiFp(configuration).getBusinessAccountPreferences(businessAccountId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение состояния категории
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoryById(businessAccountId: number, id: number, options?: any): AxiosPromise<CategoryInfoRead> {
            return DefaultApiFp(configuration).getCategoryById(businessAccountId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение состояния стандартного шаблона документа
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommonDocumentTemplateById(id: number, options?: any): AxiosPromise<DocumentTemplateInfoRead> {
            return DefaultApiFp(configuration).getCommonDocumentTemplateById(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение контента стандартного шаблона документа
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommonDocumentTemplateContentById(id: number, options?: any): AxiosPromise<any> {
            return DefaultApiFp(configuration).getCommonDocumentTemplateContentById(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение контента стандатного шаблона документа
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommonDocumentTemplateContentFromBusinessAccountById(businessAccountId: number, id: number, options?: any): AxiosPromise<any> {
            return DefaultApiFp(configuration).getCommonDocumentTemplateContentFromBusinessAccountById(businessAccountId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение состояния контакта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactById(businessAccountId: number, id: number, options?: any): AxiosPromise<ContactInfoRead> {
            return DefaultApiFp(configuration).getContactById(businessAccountId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение кадра
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCrewMember(businessAccountId: number, id: number, options?: any): AxiosPromise<CrewMemberInfoRead> {
            return DefaultApiFp(configuration).getCrewMember(businessAccountId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение состояния настроек уведомлений текущего пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentNotificationSchema(options?: any): AxiosPromise<NotificationSchemaInfoRead> {
            return DefaultApiFp(configuration).getCurrentNotificationSchema(options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение состояния текущего пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentProfile(options?: any): AxiosPromise<UserInfoRead> {
            return DefaultApiFp(configuration).getCurrentProfile(options)(axios, basePath);
        },
        /**
         * 
         * @summary Информация о текущем клиенте в бизнес аккаунте. Необходим JWT токен пользователя
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentRenterInfo(businessAccountId: number, storefrontId: number, options?: any): AxiosPromise<StorefrontRenterInfoRead> {
            return DefaultApiFp(configuration).getCurrentRenterInfo(businessAccountId, storefrontId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение прав текущего пользователя. Права в бизнес аккаунте передаются при передаче параметра businessAccountId
         * @param {number} [businessAccountId] Идентификатор бизнес аккаунта, необязательный
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentRolesAndPermissions(businessAccountId?: number, options?: any): AxiosPromise<RolesAndPermissionsObj> {
            return DefaultApiFp(configuration).getCurrentRolesAndPermissions(businessAccountId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение состояния пользовательского поля
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomFieldById(businessAccountId: number, id: number, options?: any): AxiosPromise<CustomFieldInfoRead> {
            return DefaultApiFp(configuration).getCustomFieldById(businessAccountId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение всех пользовательских полей по типу сущности
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {string} nomenclatureType Тип номенклатуры для печати
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomFieldsByEntityType(businessAccountId: number, id: number, nomenclatureType: string, options?: any): AxiosPromise<CustomFieldsGlobalObjRead> {
            return DefaultApiFp(configuration).getCustomFieldsByEntityType(businessAccountId, id, nomenclatureType, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение состояния шаблона документа
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentTemplateById(businessAccountId: number, id: number, options?: any): AxiosPromise<DocumentTemplateInfoRead> {
            return DefaultApiFp(configuration).getDocumentTemplateById(businessAccountId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение контента пользовательского шаблона документа
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentTemplateContentById(businessAccountId: number, id: number, options?: any): AxiosPromise<any> {
            return DefaultApiFp(configuration).getDocumentTemplateContentById(businessAccountId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение данных сущности по штриховому коду
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} barcodeString Строка прочитанного штрихового кода
         * @param {boolean} [includeEntityInfoRead] Флаг для включения полной информации по сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityByBarcode(businessAccountId: number, barcodeString: string, includeEntityInfoRead?: boolean, options?: any): AxiosPromise<BarcodeEntityObj> {
            return DefaultApiFp(configuration).getEntityByBarcode(businessAccountId, barcodeString, includeEntityInfoRead, options)(axios, basePath);
        },
        /**
         * Получение сущности затраты по проекту
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExpense(businessAccountId: number, id: number, options?: any): AxiosPromise<ExpenseInfoRead> {
            return DefaultApiFp(configuration).getExpense(businessAccountId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение сгенерированного мнемонического ключа
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {'renter' | 'variant' | 'instance' | 'project' | 'subrent' | 'project_operation' | 'subrent_operation' | 'template_operation'} type Тип сущности
         * @param {'mnemoKey'} fieldName Наименование поля
         * @param {number} [parentId] Идентификатор родительской сущности
         * @param {string} [baseString] Короткое наименование/тип сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneratedField(businessAccountId: number, type: 'renter' | 'variant' | 'instance' | 'project' | 'subrent' | 'project_operation' | 'subrent_operation' | 'template_operation', fieldName: 'mnemoKey', parentId?: number, baseString?: string, options?: any): AxiosPromise<string> {
            return DefaultApiFp(configuration).getGeneratedField(businessAccountId, type, fieldName, parentId, baseString, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get information to visualize
         * @param {number} gridCellIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGridCellData(gridCellIdentifier: number, options?: any): AxiosPromise<ViewInfo> {
            return DefaultApiFp(configuration).getGridCellData(gridCellIdentifier, options)(axios, basePath);
        },
        /**
         * 
         * @summary Operation to get info about grid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInformationAboutGrid(options?: any): AxiosPromise<Grid> {
            return DefaultApiFp(configuration).getInformationAboutGrid(options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение состояния экземпляра
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstanceById(businessAccountId: number, id: number, options?: any): AxiosPromise<InstanceInfoRead> {
            return DefaultApiFp(configuration).getInstanceById(businessAccountId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение информации о событии движения инвентаря
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInventoryMovementById(businessAccountId: number, id: number, options?: any): AxiosPromise<InventoryMovementInfoRead> {
            return DefaultApiFp(configuration).getInventoryMovementById(businessAccountId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение описания приглашения
         * @param {string} token Код активации приглашения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvitationByToken(token: string, options?: any): AxiosPromise<UserBaInvitationInfoRead> {
            return DefaultApiFp(configuration).getInvitationByToken(token, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение состояния набора
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKitById(businessAccountId: number, id: number, options?: any): AxiosPromise<KitInfoRead> {
            return DefaultApiFp(configuration).getKitById(businessAccountId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение карты состояния набора (только доступность и инвентарное количество)
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {boolean} captureAll Маркер обозначающий, что нужно показать весь возможный период
         * @param {TimetableTypeCodeEnum} typeCode Тип искомого стека состояния (только доступность и инвентарное количество)
         * @param {number} [from] Начало целевого интервала времени, epoch millis
         * @param {number} [until] Конец целевого интервала времени, epoch millis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKitTimetableById(businessAccountId: number, id: number, captureAll: boolean, typeCode: TimetableTypeCodeEnum, from?: number, until?: number, options?: any): AxiosPromise<TimetableObj> {
            return DefaultApiFp(configuration).getKitTimetableById(businessAccountId, id, captureAll, typeCode, from, until, options)(axios, basePath);
        },
        /**
         * 
         * @summary Массовое получение состояний набора
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {Array<number>} ids Идентификаторы набора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKitsByIdsBulk(businessAccountId: number, ids: Array<number>, options?: any): AxiosPromise<Array<KitInfoRead>> {
            return DefaultApiFp(configuration).getKitsByIdsBulk(businessAccountId, ids, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение состояния объекта субъектов и реквизитов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLegalDetailById(businessAccountId: number, id: number, options?: any): AxiosPromise<LegalDetailsInfoRead> {
            return DefaultApiFp(configuration).getLegalDetailById(businessAccountId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение состояния объектов субъектов и реквизитов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLegalDetails(businessAccountId: number, options?: any): AxiosPromise<LegalDetailsRecordList> {
            return DefaultApiFp(configuration).getLegalDetails(businessAccountId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationById(businessAccountId: number, id: number, options?: any): AxiosPromise<LocationInfoRead> {
            return DefaultApiFp(configuration).getLocationById(businessAccountId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение данных сущности номенклатуры для формы операции по штриховому коду
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} barcodeString Строка прочитанного штрихового кода
         * @param {boolean} [includeEntityInfoRead] Флаг для включения полной информации по сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNomenclatureInOperationFormByBarcode(businessAccountId: number, barcodeString: string, includeEntityInfoRead?: boolean, options?: any): AxiosPromise<BarcodeNomenclatureInOperationFormObj> {
            return DefaultApiFp(configuration).getNomenclatureInOperationFormByBarcode(businessAccountId, barcodeString, includeEntityInfoRead, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение состояния операции
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperationById(businessAccountId: number, id: number, options?: any): AxiosPromise<OperationInfoRead> {
            return DefaultApiFp(configuration).getOperationById(businessAccountId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение списка аудитов аренды состава операции
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperationComposition(businessAccountId: number, id: number, options?: any): AxiosPromise<OperationCompositionObj> {
            return DefaultApiFp(configuration).getOperationComposition(businessAccountId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение платежа
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentById(businessAccountId: number, id: number, options?: any): AxiosPromise<PaymentInfoRead> {
            return DefaultApiFp(configuration).getPaymentById(businessAccountId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение состояния способа оплаты
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentMethodById(businessAccountId: number, id: number, options?: any): AxiosPromise<PaymentMethodInfoRead> {
            return DefaultApiFp(configuration).getPaymentMethodById(businessAccountId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение состояния ценовой схемы
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPricingSchemeById(businessAccountId: number, id: number, options?: any): AxiosPromise<PricingSchemeInfoRead> {
            return DefaultApiFp(configuration).getPricingSchemeById(businessAccountId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение состояния продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductById(businessAccountId: number, id: number, options?: any): AxiosPromise<ProductInfoRead> {
            return DefaultApiFp(configuration).getProductById(businessAccountId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение состояния экземпляра продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} productId Идентификатор продукта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductInstanceById(businessAccountId: number, productId: number, id: number, options?: any): AxiosPromise<InstanceInfoRead> {
            return DefaultApiFp(configuration).getProductInstanceById(businessAccountId, productId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение карты состояния экземпляра продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} productId Идентификатор продукта
         * @param {number} id Идентификатор сущности
         * @param {boolean} captureAll Маркер обозначающий, что нужно показать весь возможный период
         * @param {TimetableTypeCodeEnum} typeCode Тип искомого стека состояния (только доступность и инвентарное количество)
         * @param {number} [from] Начало целевого интервала времени, epoch millis
         * @param {number} [until] Конец целевого интервала времени, epoch millis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductInstanceTimetableById(businessAccountId: number, productId: number, id: number, captureAll: boolean, typeCode: TimetableTypeCodeEnum, from?: number, until?: number, options?: any): AxiosPromise<TimetableObj> {
            return DefaultApiFp(configuration).getProductInstanceTimetableById(businessAccountId, productId, id, captureAll, typeCode, from, until, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение карты состояния продукта (доступность, заявка и прочее)
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {boolean} captureAll Маркер обозначающий, что нужно показать весь возможный период
         * @param {TimetableTypeCodeEnum} typeCode Тип искомого стека состояния (только доступность и инвентарное количество)
         * @param {number} [from] Начало целевого интервала времени, epoch millis
         * @param {number} [until] Конец целевого интервала времени, epoch millis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductTimetableById(businessAccountId: number, id: number, captureAll: boolean, typeCode: TimetableTypeCodeEnum, from?: number, until?: number, options?: any): AxiosPromise<TimetableObj> {
            return DefaultApiFp(configuration).getProductTimetableById(businessAccountId, id, captureAll, typeCode, from, until, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение состояния варианта продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} productId Идентификатор продукта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductVariantById(businessAccountId: number, productId: number, id: number, options?: any): AxiosPromise<VariantInfoRead> {
            return DefaultApiFp(configuration).getProductVariantById(businessAccountId, productId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение карты состояния экземпляра варианта продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} productId Идентификатор продукта
         * @param {number} variantId Идентификатор варианта
         * @param {number} id Идентификатор сущности
         * @param {boolean} captureAll Маркер обозначающий, что нужно показать весь возможный период
         * @param {TimetableTypeCodeEnum} typeCode Тип искомого стека состояния (только доступность и инвентарное количество)
         * @param {number} [from] Начало целевого интервала времени, epoch millis
         * @param {number} [until] Конец целевого интервала времени, epoch millis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductVariantInstanceTimetableById(businessAccountId: number, productId: number, variantId: number, id: number, captureAll: boolean, typeCode: TimetableTypeCodeEnum, from?: number, until?: number, options?: any): AxiosPromise<TimetableObj> {
            return DefaultApiFp(configuration).getProductVariantInstanceTimetableById(businessAccountId, productId, variantId, id, captureAll, typeCode, from, until, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение профессии
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfessionById(businessAccountId: number, id: number, options?: any): AxiosPromise<ProfessionInfoRead> {
            return DefaultApiFp(configuration).getProfessionById(businessAccountId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение состояния проекта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectById(businessAccountId: number, id: number, options?: any): AxiosPromise<ProjectInfoRead> {
            return DefaultApiFp(configuration).getProjectById(businessAccountId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Запрос информации элемента аренды
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRentElementById(businessAccountId: number, id: number, options?: any): AxiosPromise<RentElementInfoRead> {
            return DefaultApiFp(configuration).getRentElementById(businessAccountId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Запрос истории элемента аренды
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRentElementHistory(businessAccountId: number, id: number, options?: any): AxiosPromise<RentAuditRecordList> {
            return DefaultApiFp(configuration).getRentElementHistory(businessAccountId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение состояния арендатора
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRenterById(businessAccountId: number, id: number, options?: any): AxiosPromise<RenterInfoRead> {
            return DefaultApiFp(configuration).getRenterById(businessAccountId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение данных о состоянии отдельного графического представления отчёта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportChartVisualizationById(businessAccountId: number, id: number, options?: any): AxiosPromise<ReportChartVisualizationInfoRead> {
            return DefaultApiFp(configuration).getReportChartVisualizationById(businessAccountId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение состояния конфигурации отчета
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportConfigurationById(businessAccountId: number, id: number, options?: any): AxiosPromise<DetailedReportConfigurationInfoRead> {
            return DefaultApiFp(configuration).getReportConfigurationById(businessAccountId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение изображения логотипа компании клиента при SSO авторизации
         * @param {string} ssoClientOrganizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSsoAuthorizationClientLogo(ssoClientOrganizationId: string, options?: any): AxiosPromise<any> {
            return DefaultApiFp(configuration).getSsoAuthorizationClientLogo(ssoClientOrganizationId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение информации об организации в системе SSO авторизации для бизнес аккаунта
         * @param {string} accessToken Обязательный токен доступа в формате UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSsoClientOrganization(accessToken: string, options?: any): AxiosPromise<SsoClientOrganizationInfoRead> {
            return DefaultApiFp(configuration).getSsoClientOrganization(accessToken, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение информации о витрине
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} accessToken Обязательный токен доступа в формате UUID
         * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
         * @param {Array<number>} [baseCategoryIds] Идентификаторы базовых категорий, только они и их подкатегории попадут в результат
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStorefront(businessAccountId: number, accessToken: string, storefrontId: number, baseCategoryIds?: Array<number>, options?: any): AxiosPromise<StorefrontInfoRead> {
            return DefaultApiFp(configuration).getStorefront(businessAccountId, accessToken, storefrontId, baseCategoryIds, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение доступности торг. предложения
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} accessToken Обязательный токен доступа в формате UUID
         * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
         * @param {number} offerId Идентификатор торг. предложения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStorefrontOfferAvailability(businessAccountId: number, accessToken: string, storefrontId: number, offerId: number, options?: any): AxiosPromise<string> {
            return DefaultApiFp(configuration).getStorefrontOfferAvailability(businessAccountId, accessToken, storefrontId, offerId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение состояния субаренды
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubrentById(businessAccountId: number, id: number, options?: any): AxiosPromise<SubrentInfoRead> {
            return DefaultApiFp(configuration).getSubrentById(businessAccountId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение состояния системной настройки
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemOptionById(businessAccountId: number, id: number, options?: any): AxiosPromise<SystemOptionInfoRead> {
            return DefaultApiFp(configuration).getSystemOptionById(businessAccountId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Список групп системных настроек с вложенными системными настройками
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemOptionsSectionById(businessAccountId: number, id: number, options?: any): AxiosPromise<SystemOptionsSectionReadInfo> {
            return DefaultApiFp(configuration).getSystemOptionsSectionById(businessAccountId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение иерархии системных настроек бизнес аккаунта в виде дерева
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemOptionsTree(businessAccountId: number, options?: any): AxiosPromise<SimpleTreeEntityRecordList> {
            return DefaultApiFp(configuration).getSystemOptionsTree(businessAccountId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение состояния шаблона
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplateById(businessAccountId: number, id: number, options?: any): AxiosPromise<TemplateInfoRead> {
            return DefaultApiFp(configuration).getTemplateById(businessAccountId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение серверного времени
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTime(options?: any): AxiosPromise<Date> {
            return DefaultApiFp(configuration).getTime(options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение серверного времени
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTime_2(options?: any): AxiosPromise<Date> {
            return DefaultApiFp(configuration).getTime_2(options)(axios, basePath);
        },
        /**
         * 
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransportationById(businessAccountId: number, id: number, options?: any): AxiosPromise<TransportationInfoRead> {
            return DefaultApiFp(configuration).getTransportationById(businessAccountId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение состояния пользователя
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById(businessAccountId: number, id: number, options?: any): AxiosPromise<UserInfoRead> {
            return DefaultApiFp(configuration).getUserById(businessAccountId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Валидация значения поля
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {'kit' | 'product' | 'variant' | 'instance' | 'profession' | 'renter' | 'project' | 'subrent' | 'template' | 'customField' | 'user' | 'location' | 'contact' | 'vehicle' | 'transportation' | 'paymentMethod'} type Тип сущности
         * @param {string} fieldName Наименование поля
         * @param {string} value Значение поля
         * @param {number} [ignoreCurrentId] Идентификатор текущей сущности, которую нужно игнорировать, например, при проверке уникальности
         * @param {number} [parentId] Идентификатор родительской сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValidationForField(businessAccountId: number, type: 'kit' | 'product' | 'variant' | 'instance' | 'profession' | 'renter' | 'project' | 'subrent' | 'template' | 'customField' | 'user' | 'location' | 'contact' | 'vehicle' | 'transportation' | 'paymentMethod', fieldName: string, value: string, ignoreCurrentId?: number, parentId?: number, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).getValidationForField(businessAccountId, type, fieldName, value, ignoreCurrentId, parentId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Карты состояния варианта (доступность, заявка и прочее)
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} productId Идентификатор продукта
         * @param {number} id Идентификатор сущности
         * @param {boolean} captureAll Маркер обозначающий, что нужно показать весь возможный период
         * @param {TimetableTypeCodeEnum} typeCode Тип искомого стека состояния (только доступность и инвентарное количество)
         * @param {number} [from] Начало целевого интервала времени, epoch millis
         * @param {number} [until] Конец целевого интервала времени, epoch millis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVariantTimetableById(businessAccountId: number, productId: number, id: number, captureAll: boolean, typeCode: TimetableTypeCodeEnum, from?: number, until?: number, options?: any): AxiosPromise<TimetableObj> {
            return DefaultApiFp(configuration).getVariantTimetableById(businessAccountId, productId, id, captureAll, typeCode, from, until, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVehicleById(businessAccountId: number, id: number, options?: any): AxiosPromise<VehicleInfoRead> {
            return DefaultApiFp(configuration).getVehicleById(businessAccountId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение данных для графика
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {ReportInputParameters} [reportInputParameters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViewInfoOfReportChartVisualization(businessAccountId: number, id: number, reportInputParameters?: ReportInputParameters, options?: any): AxiosPromise<ViewInfo> {
            return DefaultApiFp(configuration).getViewInfoOfReportChartVisualization(businessAccountId, id, reportInputParameters, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получить описание работы
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkPlanning(businessAccountId: number, id: number, options?: any): AxiosPromise<WorkPlanningsInfoRead> {
            return DefaultApiFp(configuration).getWorkPlanning(businessAccountId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Глобальный запрос списка бизнес аккаунтов
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalListBusinessAccounts(limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): AxiosPromise<BusinessAccountRecordList> {
            return DefaultApiFp(configuration).globalListBusinessAccounts(limit, startFrom, filters, sortBy, sortOrder, search, options)(axios, basePath);
        },
        /**
         * Берёт из эластика
         * @summary Возвращает список шаблонов. Всех
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalListDocumentTemplates(limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): AxiosPromise<DocumentTemplateRecordList> {
            return DefaultApiFp(configuration).globalListDocumentTemplates(limit, startFrom, filters, sortBy, sortOrder, search, options)(axios, basePath);
        },
        /**
         * 
         * @summary Массовое изменение КП - включение в стоимость и исключение из стоимости
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {boolean} include Включить или исключить true &#x3D; включить, false &#x3D; исключить
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        includeOrExcludeOfferInProjectPriceBulk(businessAccountId: number, include: boolean, versionedEntityObjList: VersionedEntityObjList, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).includeOrExcludeOfferInProjectPriceBulk(businessAccountId, include, versionedEntityObjList, options)(axios, basePath);
        },
        /**
         * 
         * @summary Добавить пользователя в бизнес аккаунт
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {UserInviteToBaObj} userInviteToBaObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteUserToBusinessAccount(businessAccountId: number, userInviteToBaObj: UserInviteToBaObj, options?: any): AxiosPromise<number> {
            return DefaultApiFp(configuration).inviteUserToBusinessAccount(businessAccountId, userInviteToBaObj, options)(axios, basePath);
        },
        /**
         * 
         * @summary Массовое изменение привязки категорий
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {boolean} createLinks Создать или удалить, true &#x3D; создать, false &#x3D; удалить
         * @param {CategoryBulkLinkObj} categoryBulkLinkObj 
         * @param {boolean} [makeMainCategory] Назначить ли привязываемую категорию основной (передается только при привязке)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkCategoriesBulk(businessAccountId: number, createLinks: boolean, categoryBulkLinkObj: CategoryBulkLinkObj, makeMainCategory?: boolean, options?: any): AxiosPromise<number> {
            return DefaultApiFp(configuration).linkCategoriesBulk(businessAccountId, createLinks, categoryBulkLinkObj, makeMainCategory, options)(axios, basePath);
        },
        /**
         * 
         * @summary Массовое изменение привязки пользовательских полей
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {boolean} createLinks Создать или удалить, true &#x3D; создать, false &#x3D; удалить
         * @param {boolean} assignFieldsToVariants Присвоить пользовательские поля вариантам или только продукту
         * @param {CustomFieldBulkLinkObj} customFieldBulkLinkObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkCustomFieldsBulk(businessAccountId: number, createLinks: boolean, assignFieldsToVariants: boolean, customFieldBulkLinkObj: CustomFieldBulkLinkObj, options?: any): AxiosPromise<number> {
            return DefaultApiFp(configuration).linkCustomFieldsBulk(businessAccountId, createLinks, assignFieldsToVariants, customFieldBulkLinkObj, options)(axios, basePath);
        },
        /**
         * 
         * @summary Массовое назначение тэгов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {boolean} createLinks Создать или удалить, true &#x3D; создать, false &#x3D; удалить
         * @param {TagsBulkLinkObj} tagsBulkLinkObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkTagsBulk(businessAccountId: number, createLinks: boolean, tagsBulkLinkObj: TagsBulkLinkObj, options?: any): AxiosPromise<number> {
            return DefaultApiFp(configuration).linkTagsBulk(businessAccountId, createLinks, tagsBulkLinkObj, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение списка идентификаторов всех активных предложений витрины бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} accessToken Обязательный токен доступа в формате UUID
         * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
         * @param {Array<number>} [baseCategoryIds] Идентификаторы базовых категорий, только они и их подкатегории попадут в результат
         * @param {boolean} [includeProfessions] Если флаг передан и равен true, будут добавлены торг. предложения с типом профессия. В старых версиях витрины не передается, обеспечивает обратную совместимость
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActiveStorefrontOffersIds(businessAccountId: number, accessToken: string, storefrontId: number, baseCategoryIds?: Array<number>, includeProfessions?: boolean, options?: any): AxiosPromise<Array<number>> {
            return DefaultApiFp(configuration).listActiveStorefrontOffersIds(businessAccountId, accessToken, storefrontId, baseCategoryIds, includeProfessions, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение доступных типов операций
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {OperationAvailableStatesRequestObj} operationAvailableStatesRequestObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAvailableOperationTypes(businessAccountId: number, operationAvailableStatesRequestObj: OperationAvailableStatesRequestObj, options?: any): AxiosPromise<OperationAvailableStatesResponseObj> {
            return DefaultApiFp(configuration).listAvailableOperationTypes(businessAccountId, operationAvailableStatesRequestObj, options)(axios, basePath);
        },
        /**
         * 
         * @summary Список элементов бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {boolean} [excludeAggregations] Исключить аггрегации
         * @param {boolean} [excludeRecords] Исключить записи списка
         * @param {Date} [specialFromStartDateIncludingDelays] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBusinessAccountElements(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, excludeAggregations?: boolean, excludeRecords?: boolean, specialFromStartDateIncludingDelays?: Date, options?: any): AxiosPromise<RentElementRecordList> {
            return DefaultApiFp(configuration).listBusinessAccountElements(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, excludeAggregations, excludeRecords, specialFromStartDateIncludingDelays, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение ограничения фильтров по обязательствам бизнес аккаунта на основе присутствующих данных
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBusinessAccountElementsAvailableFiltersValues(businessAccountId: number, filters?: Array<string>, options?: any): AxiosPromise<AvailableFiltersValuesListObj> {
            return DefaultApiFp(configuration).listBusinessAccountElementsAvailableFiltersValues(businessAccountId, filters, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение состояния объекта субъектов и реквизитов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBusinessAccountElementsGroupedByNomenclature(businessAccountId: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', options?: any): AxiosPromise<Array<GroupedRentElement>> {
            return DefaultApiFp(configuration).listBusinessAccountElementsGroupedByNomenclature(businessAccountId, filters, sortBy, sortOrder, options)(axios, basePath);
        },
        /**
         * 
         * @summary Список сгруппированных элементов бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {boolean} subrent Запрос по субарендным обязательствам - если true то субаренда, если false то аренда
         * @param {boolean} periodStartDate Запрос по началу периода - если true, то начало считается датой группы, если false то окончание
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBusinessAccountElementsGroupedByPeriod(businessAccountId: number, subrent: boolean, periodStartDate: boolean, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): AxiosPromise<GroupedRentElementRecordList> {
            return DefaultApiFp(configuration).listBusinessAccountElementsGroupedByPeriod(businessAccountId, subrent, periodStartDate, limit, startFrom, filters, sortBy, sortOrder, search, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение списка графических визуализаций отчетов, доступных для бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} [search] Поисковая строка
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBusinessAccountReportChartVisualizations(businessAccountId: number, search?: string, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', filters?: Array<string>, options?: any): AxiosPromise<ReportChartVisualizationRecordList> {
            return DefaultApiFp(configuration).listBusinessAccountReportChartVisualizations(businessAccountId, search, limit, startFrom, sortBy, sortOrder, filters, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение списка конфигураций отчетов доступных для бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBusinessAccountReportConfigurations(businessAccountId: number, options?: any): AxiosPromise<ReportConfigurationRecordList> {
            return DefaultApiFp(configuration).listBusinessAccountReportConfigurations(businessAccountId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Список бизнес аккаунтов
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBusinessAccounts(limit?: number, startFrom?: number, options?: any): AxiosPromise<BusinessAccountRecordList> {
            return DefaultApiFp(configuration).listBusinessAccounts(limit, startFrom, options)(axios, basePath);
        },
        /**
         * 
         * @summary Список категорий
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {CategoryTypeCodeEnum} typeCode Тип изменяемых категорий
         * @param {string} [search] Прямое вхождение последовательности символов в наименование
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCategories(businessAccountId: number, typeCode: CategoryTypeCodeEnum, search?: string, options?: any): AxiosPromise<CategoryRecordList> {
            return DefaultApiFp(configuration).listCategories(businessAccountId, typeCode, search, options)(axios, basePath);
        },
        /**
         * 
         * @summary Список деревьев категорий
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {CategoryTypeCodeEnum} typeCode Тип изменяемых категорий
         * @param {string} [search] Прямое вхождение последовательности символов в наименование
         * @param {CategorySortOrderEnum} [sortBy] По чему сортировать
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCategoryTrees(businessAccountId: number, typeCode: CategoryTypeCodeEnum, search?: string, sortBy?: CategorySortOrderEnum, sortOrder?: 'ASC' | 'DESC', options?: any): AxiosPromise<CategoryForest> {
            return DefaultApiFp(configuration).listCategoryTrees(businessAccountId, typeCode, search, sortBy, sortOrder, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение текущих заполняемых в интерфейсе операций по рамке активности
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} activityFrameId Идентификатор рамки активности
         * @param {RentActivityFrameTypeCodeEnum} activityFrameType Тип рамки активности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConcurrentOperations(businessAccountId: number, activityFrameId: number, activityFrameType: RentActivityFrameTypeCodeEnum, options?: any): AxiosPromise<ConcurrentOperationObjList> {
            return DefaultApiFp(configuration).listConcurrentOperations(businessAccountId, activityFrameId, activityFrameType, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение списка контактов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} [search] Поисковая строка
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listContacts(businessAccountId: number, search?: string, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', filters?: Array<string>, options?: any): AxiosPromise<ContactRecordList> {
            return DefaultApiFp(configuration).listContacts(businessAccountId, search, limit, startFrom, sortBy, sortOrder, filters, options)(axios, basePath);
        },
        /**
         * 
         * @summary Список кадров
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCrewMembers(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): AxiosPromise<CrewMemberRecordList> {
            return DefaultApiFp(configuration).listCrewMembers(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение ограничений фильтров сотрудников бизнес аккаунта на основе присутствующих данных
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCrewMembersAvailableFiltersValues(businessAccountId: number, options?: any): AxiosPromise<AvailableFiltersValuesListObj> {
            return DefaultApiFp(configuration).listCrewMembersAvailableFiltersValues(businessAccountId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Список пользовательских полей
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} [search] Поисковая строка
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {'BOOLEAN' | 'LONG' | 'DOUBLE' | 'STRING' | 'DATE' | 'ENTITY_LINK'} [typeCodeFilter] Фильтр по типу поля
         * @param {boolean} [standardFilter] Фильтр по признаку является ли поле стандартным
         * @param {'USER_LINK' | 'CONTACT_LINK' | 'SUBRENT_LINK' | 'PROJECT_LINK' | 'RENTER_LINK' | 'LONG_SUM' | 'MONEY_SUM'} [processingTypeFilter] Фильтр по типу обработки поля
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCustomFields(businessAccountId: number, search?: string, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', typeCodeFilter?: 'BOOLEAN' | 'LONG' | 'DOUBLE' | 'STRING' | 'DATE' | 'ENTITY_LINK', standardFilter?: boolean, processingTypeFilter?: 'USER_LINK' | 'CONTACT_LINK' | 'SUBRENT_LINK' | 'PROJECT_LINK' | 'RENTER_LINK' | 'LONG_SUM' | 'MONEY_SUM', options?: any): AxiosPromise<CustomFieldRecordList> {
            return DefaultApiFp(configuration).listCustomFields(businessAccountId, search, limit, startFrom, sortBy, sortOrder, typeCodeFilter, standardFilter, processingTypeFilter, options)(axios, basePath);
        },
        /**
         * 
         * @summary Список ограничений по типам пользовательских полей
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCustomFieldsLimits(businessAccountId: number, options?: any): AxiosPromise<CustomFieldsLimitObjList> {
            return DefaultApiFp(configuration).listCustomFieldsLimits(businessAccountId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Список шаблонов документов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {boolean} [showHidden] Если передан и true, то будут показаны даже скрытые шаблоны
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDocumentTemplates(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, showHidden?: boolean, options?: any): AxiosPromise<DocumentTemplateRecordList> {
            return DefaultApiFp(configuration).listDocumentTemplates(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, showHidden, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение списка эксель визуализаций отчета, к которому относится графическая визуализация
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {string} [search] Поисковая строка
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDocumentTemplatesForReportChartVisualization(businessAccountId: number, id: number, search?: string, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', options?: any): AxiosPromise<ReportExcelVisualizationRecordList> {
            return DefaultApiFp(configuration).listDocumentTemplatesForReportChartVisualization(businessAccountId, id, search, limit, startFrom, filters, sortBy, sortOrder, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение информации о измененных файлах
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEntityLifecycleEvents(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', options?: any): AxiosPromise<EntityLifecycleEventRecordList> {
            return DefaultApiFp(configuration).listEntityLifecycleEvents(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение ограничений фильтров по тратам
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listExpenseAvailableFilters(businessAccountId: number, filters?: Array<string>, options?: any): AxiosPromise<AvailableFiltersValuesListObj> {
            return DefaultApiFp(configuration).listExpenseAvailableFilters(businessAccountId, filters, options)(axios, basePath);
        },
        /**
         * Получение списка затрат по проекту
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {boolean} [excludeAggregations] Исключить аггрегации
         * @param {boolean} [excludeRecords] Исключить записи списка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listExpenses(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, excludeAggregations?: boolean, excludeRecords?: boolean, options?: any): AxiosPromise<ExpenseRecordList> {
            return DefaultApiFp(configuration).listExpenses(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, excludeAggregations, excludeRecords, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение списка шаблонов для экспорта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listExportTemplate(options?: any): AxiosPromise<ExportTemplateRecordList> {
            return DefaultApiFp(configuration).listExportTemplate(options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение списка экземпляров
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInstances(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): AxiosPromise<InstanceRecordList> {
            return DefaultApiFp(configuration).listInstances(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение списка экземпляров с картой доступности и аггрегациями стеков на интервале
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} from Начало целевого интервала времени, epoch millis
         * @param {number} until Конец целевого интервала времени, epoch millis
         * @param {ListOnIntervalRequestObject} listOnIntervalRequestObject 
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {Array<TimetableTypeCodeEnum>} [targetStackTypeCodes] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInstancesOnInterval(businessAccountId: number, from: number, until: number, listOnIntervalRequestObject: ListOnIntervalRequestObject, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, targetStackTypeCodes?: Array<TimetableTypeCodeEnum>, options?: any): AxiosPromise<InstanceRecordList> {
            return DefaultApiFp(configuration).listInstancesOnInterval(businessAccountId, from, until, listOnIntervalRequestObject, limit, startFrom, sortBy, sortOrder, search, targetStackTypeCodes, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение списка событий движения инвентаря
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInventoryMovements(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): AxiosPromise<InventoryMovementRecordList> {
            return DefaultApiFp(configuration).listInventoryMovements(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение ограничений фильтров бизнес аккаунта на основе присутствующих данных
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInventoryMovementsAvailableFiltersValues(businessAccountId: number, filters?: Array<string>, options?: any): AxiosPromise<AvailableFiltersValuesListObj> {
            return DefaultApiFp(configuration).listInventoryMovementsAvailableFiltersValues(businessAccountId, filters, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение списка наборов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listKits(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): AxiosPromise<KitRecordList> {
            return DefaultApiFp(configuration).listKits(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение ограничений фильтров наборов бизнес аккаунта на основе присутствующих данных
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listKitsAvailableFiltersValues(businessAccountId: number, options?: any): AxiosPromise<AvailableFiltersValuesWithCustomFieldsListObj> {
            return DefaultApiFp(configuration).listKitsAvailableFiltersValues(businessAccountId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} [search] Поисковая строка
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLocations(businessAccountId: number, search?: string, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', filters?: Array<string>, options?: any): AxiosPromise<LocationRecordList> {
            return DefaultApiFp(configuration).listLocations(businessAccountId, search, limit, startFrom, sortBy, sortOrder, filters, options)(axios, basePath);
        },
        /**
         * 
         * @summary Список наборов, продуктов и вариантов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNomenclature(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): AxiosPromise<NomenclatureRecordList> {
            return DefaultApiFp(configuration).listNomenclature(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение ограничений фильтров номенклатуры бизнес аккаунта на основе присутствующих данных
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNomenclatureAvailableFiltersValues(businessAccountId: number, filters?: Array<string>, options?: any): AxiosPromise<AvailableFiltersValuesWithCustomFieldsListObj> {
            return DefaultApiFp(configuration).listNomenclatureAvailableFiltersValues(businessAccountId, filters, options)(axios, basePath);
        },
        /**
         * 
         * @summary Список наборов, продуктов и вариантов на интервале времени
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} from Начало целевого интервала времени, epoch millis
         * @param {number} until Конец целевого интервала времени, epoch millis
         * @param {ListOnIntervalRequestObject} listOnIntervalRequestObject 
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {Array<TimetableTypeCodeEnum>} [targetStackTypeCodes] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNomenclatureOnInterval(businessAccountId: number, from: number, until: number, listOnIntervalRequestObject: ListOnIntervalRequestObject, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, targetStackTypeCodes?: Array<TimetableTypeCodeEnum>, options?: any): AxiosPromise<NomenclatureRecordList> {
            return DefaultApiFp(configuration).listNomenclatureOnInterval(businessAccountId, from, until, listOnIntervalRequestObject, limit, startFrom, sortBy, sortOrder, search, targetStackTypeCodes, options)(axios, basePath);
        },
        /**
         * 
         * @summary Список элементов операции
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {boolean} [excludeAggregations] Исключить аггрегации
         * @param {boolean} [excludeRecords] Исключить записи списка
         * @param {Date} [specialFromStartDateIncludingDelays] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOperationElements(businessAccountId: number, id: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, excludeAggregations?: boolean, excludeRecords?: boolean, specialFromStartDateIncludingDelays?: Date, options?: any): AxiosPromise<RentElementRecordList> {
            return DefaultApiFp(configuration).listOperationElements(businessAccountId, id, limit, startFrom, filters, sortBy, sortOrder, search, excludeAggregations, excludeRecords, specialFromStartDateIncludingDelays, options)(axios, basePath);
        },
        /**
         * 
         * @summary Список операций
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOperations(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): AxiosPromise<OperationRecordList> {
            return DefaultApiFp(configuration).listOperations(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение ограничений фильтров операциям на основе присутствующих данных
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOperationsAvailableFiltersValues(businessAccountId: number, filters?: Array<string>, options?: any): AxiosPromise<AvailableFiltersValuesListObj> {
            return DefaultApiFp(configuration).listOperationsAvailableFiltersValues(businessAccountId, filters, options)(axios, basePath);
        },
        /**
         * 
         * @summary Список заказов текущего пользователя в бизнес аккаунте, созданных через витрину. Необходим JWT токен пользователя
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
         * @param {number} [minimumOrderChangeDate] Фильтр для получения только заказов, созданных и измененных не ранее указанной даты, epoch millis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrdersByClient(businessAccountId: number, storefrontId: number, minimumOrderChangeDate?: number, options?: any): AxiosPromise<OrderRecordList> {
            return DefaultApiFp(configuration).listOrdersByClient(businessAccountId, storefrontId, minimumOrderChangeDate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Список заказов текущего пользователя в бизнес аккаунте, созданных через витрину. Необходим JWT токен пользователя
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} accessToken Обязательный токен доступа в формате UUID
         * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
         * @param {number} [minimumOrderChangeDate] Фильтр для получения только заказов, созданных и измененных не ранее указанной даты, epoch millis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrdersByServer(businessAccountId: number, accessToken: string, storefrontId: number, minimumOrderChangeDate?: number, options?: any): AxiosPromise<OrderRecordList> {
            return DefaultApiFp(configuration).listOrdersByServer(businessAccountId, accessToken, storefrontId, minimumOrderChangeDate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение списка способов оплаты
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPaymentMethods(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', options?: any): AxiosPromise<PaymentMethodRecordList> {
            return DefaultApiFp(configuration).listPaymentMethods(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение ограничений фильтров по тратам
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPaymentMethodsAvailableFilters(businessAccountId: number, filters?: Array<string>, options?: any): AxiosPromise<AvailableFiltersValuesListObj> {
            return DefaultApiFp(configuration).listPaymentMethodsAvailableFilters(businessAccountId, filters, options)(axios, basePath);
        },
        /**
         * 
         * @summary Список платежей
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPayments(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): AxiosPromise<PaymentRecordList> {
            return DefaultApiFp(configuration).listPayments(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение ограничения фильтров для списка платежей на основе присутствующих данных
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPaymentsAvailableFiltersValues(businessAccountId: number, options?: any): AxiosPromise<AvailableFiltersValuesListObj> {
            return DefaultApiFp(configuration).listPaymentsAvailableFiltersValues(businessAccountId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Список ценовых схем компании
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} [search] Поисковая строка
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {PricingSchemeTypeCodeEnum} [typeCodeFilter] Фильтр по типу поля
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPricingSchemes(businessAccountId: number, search?: string, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', typeCodeFilter?: PricingSchemeTypeCodeEnum, options?: any): AxiosPromise<PricingSchemeRecordList> {
            return DefaultApiFp(configuration).listPricingSchemes(businessAccountId, search, limit, startFrom, sortBy, sortOrder, typeCodeFilter, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение списка экземпляров продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProductInstances(businessAccountId: number, id: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): AxiosPromise<InstanceRecordList> {
            return DefaultApiFp(configuration).listProductInstances(businessAccountId, id, limit, startFrom, filters, sortBy, sortOrder, search, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение списка экземпляров продукта с картой доступности и аггрегациями стеков на интервале
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {number} from Начало целевого интервала времени, epoch millis
         * @param {number} until Конец целевого интервала времени, epoch millis
         * @param {ListOnIntervalRequestObject} listOnIntervalRequestObject 
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {Array<TimetableTypeCodeEnum>} [targetStackTypeCodes] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProductInstancesOnInterval(businessAccountId: number, id: number, from: number, until: number, listOnIntervalRequestObject: ListOnIntervalRequestObject, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, targetStackTypeCodes?: Array<TimetableTypeCodeEnum>, options?: any): AxiosPromise<InstanceRecordList> {
            return DefaultApiFp(configuration).listProductInstancesOnInterval(businessAccountId, id, from, until, listOnIntervalRequestObject, limit, startFrom, sortBy, sortOrder, search, targetStackTypeCodes, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение списка экземпляров варианта продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} productId Идентификатор продукта
         * @param {number} id Идентификатор сущности
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProductVariantInstances(businessAccountId: number, productId: number, id: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): AxiosPromise<InstanceRecordList> {
            return DefaultApiFp(configuration).listProductVariantInstances(businessAccountId, productId, id, limit, startFrom, filters, sortBy, sortOrder, search, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение списка экземпляров варианта продукта с картой доступности и аггрегациями стеков на интервале
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} productId Идентификатор продукта
         * @param {number} id Идентификатор сущности
         * @param {number} from Начало целевого интервала времени, epoch millis
         * @param {number} until Конец целевого интервала времени, epoch millis
         * @param {ListOnIntervalRequestObject} listOnIntervalRequestObject 
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {Array<TimetableTypeCodeEnum>} [targetStackTypeCodes] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProductVariantInstancesOnInterval(businessAccountId: number, productId: number, id: number, from: number, until: number, listOnIntervalRequestObject: ListOnIntervalRequestObject, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, targetStackTypeCodes?: Array<TimetableTypeCodeEnum>, options?: any): AxiosPromise<InstanceRecordList> {
            return DefaultApiFp(configuration).listProductVariantInstancesOnInterval(businessAccountId, productId, id, from, until, listOnIntervalRequestObject, limit, startFrom, sortBy, sortOrder, search, targetStackTypeCodes, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение списка вариантов продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProductVariants(businessAccountId: number, id: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): AxiosPromise<VariantRecordList> {
            return DefaultApiFp(configuration).listProductVariants(businessAccountId, id, limit, startFrom, filters, sortBy, sortOrder, search, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение ограничений фильтров экземпляров продукта бизнес аккаунта на основе присутствующих данных
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProductVariantsAvailableFiltersValues(businessAccountId: number, id: number, options?: any): AxiosPromise<AvailableFiltersValuesListObj> {
            return DefaultApiFp(configuration).listProductVariantsAvailableFiltersValues(businessAccountId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Список продуктов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProducts(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): AxiosPromise<ProductRecordList> {
            return DefaultApiFp(configuration).listProducts(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение ограничений фильтров продуктов бизнес аккаунта на основе присутствующих данных
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProductsAvailableFiltersValues(businessAccountId: number, options?: any): AxiosPromise<AvailableFiltersValuesListObj> {
            return DefaultApiFp(configuration).listProductsAvailableFiltersValues(businessAccountId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Список профессий
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProfessions(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): AxiosPromise<ProfessionRecordList> {
            return DefaultApiFp(configuration).listProfessions(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение ограничений фильтров профессий бизнес аккаунта на основе присутствующих данных
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProfessionsAvailableFiltersValues(businessAccountId: number, options?: any): AxiosPromise<AvailableFiltersValuesListObj> {
            return DefaultApiFp(configuration).listProfessionsAvailableFiltersValues(businessAccountId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Список элементов проекта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} projectId Идентификатор проекта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {boolean} [excludeAggregations] Исключить аггрегации
         * @param {boolean} [excludeRecords] Исключить записи списка
         * @param {Date} [specialFromStartDateIncludingDelays] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProjectElements(businessAccountId: number, projectId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, excludeAggregations?: boolean, excludeRecords?: boolean, specialFromStartDateIncludingDelays?: Date, options?: any): AxiosPromise<RentElementRecordList> {
            return DefaultApiFp(configuration).listProjectElements(businessAccountId, projectId, limit, startFrom, filters, sortBy, sortOrder, search, excludeAggregations, excludeRecords, specialFromStartDateIncludingDelays, options)(axios, basePath);
        },
        /**
         * 
         * @summary Список проектов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProjects(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): AxiosPromise<ProjectRecordList> {
            return DefaultApiFp(configuration).listProjects(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение ограничений фильтров проектов на основе присутствующих данных
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProjectsAvailableFiltersValues(businessAccountId: number, filters?: Array<string>, options?: any): AxiosPromise<AvailableFiltersValuesListObj> {
            return DefaultApiFp(configuration).listProjectsAvailableFiltersValues(businessAccountId, filters, options)(axios, basePath);
        },
        /**
         * 
         * @summary Список рамок арендной активности
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRentActivityFrames(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): AxiosPromise<RentActivityFrameRecordList> {
            return DefaultApiFp(configuration).listRentActivityFrames(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, options)(axios, basePath);
        },
        /**
         * 
         * @summary Список арендаторов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRenters(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): AxiosPromise<RenterRecordList> {
            return DefaultApiFp(configuration).listRenters(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение ограничений фильтров контрагентов на основе присутствующих данных
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRentersAvailableFiltersValues(businessAccountId: number, filters?: Array<string>, options?: any): AxiosPromise<AvailableFiltersValuesListObj> {
            return DefaultApiFp(configuration).listRentersAvailableFiltersValues(businessAccountId, filters, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение списка предложений витрины бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} accessToken Обязательный токен доступа в формате UUID
         * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
         * @param {number} minimumOfferChangeDate Фильтр для получения только торговых предложений, измененных не ранее указанной даты, epoch millis
         * @param {number} [pageNumber] Номер страницы
         * @param {number} [itemsPerPage] Число элементов на странице
         * @param {Array<number>} [baseCategoryIds] Идентификаторы базовых категорий, только они и их подкатегории попадут в результат
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStorefrontOffers(businessAccountId: number, accessToken: string, storefrontId: number, minimumOfferChangeDate: number, pageNumber?: number, itemsPerPage?: number, baseCategoryIds?: Array<number>, options?: any): AxiosPromise<OfferRecordList> {
            return DefaultApiFp(configuration).listStorefrontOffers(businessAccountId, accessToken, storefrontId, minimumOfferChangeDate, pageNumber, itemsPerPage, baseCategoryIds, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение списка предложений витрины бизнес аккаунта типа специальность
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} accessToken Обязательный токен доступа в формате UUID
         * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
         * @param {number} minimumOfferChangeDate Фильтр для получения только торговых предложений, измененных не ранее указанной даты, epoch millis
         * @param {number} [pageNumber] Номер страницы
         * @param {number} [itemsPerPage] Число элементов на странице
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStorefrontProfessionOffers(businessAccountId: number, accessToken: string, storefrontId: number, minimumOfferChangeDate: number, pageNumber?: number, itemsPerPage?: number, options?: any): AxiosPromise<OfferRecordList> {
            return DefaultApiFp(configuration).listStorefrontProfessionOffers(businessAccountId, accessToken, storefrontId, minimumOfferChangeDate, pageNumber, itemsPerPage, options)(axios, basePath);
        },
        /**
         * 
         * @summary Список элементов поставки
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} subrentId Идентификатор поставки
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {boolean} [excludeAggregations] Исключить аггрегации
         * @param {boolean} [excludeRecords] Исключить записи списка
         * @param {Date} [specialFromStartDateIncludingDelays] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSubrentElements(businessAccountId: number, subrentId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, excludeAggregations?: boolean, excludeRecords?: boolean, specialFromStartDateIncludingDelays?: Date, options?: any): AxiosPromise<RentElementRecordList> {
            return DefaultApiFp(configuration).listSubrentElements(businessAccountId, subrentId, limit, startFrom, filters, sortBy, sortOrder, search, excludeAggregations, excludeRecords, specialFromStartDateIncludingDelays, options)(axios, basePath);
        },
        /**
         * 
         * @summary Список субаренды
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSubrents(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): AxiosPromise<SubrentRecordList> {
            return DefaultApiFp(configuration).listSubrents(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение ограничений фильтров поставок на основе присутствующих данных
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSubrentsAvailableFiltersValues(businessAccountId: number, filters?: Array<string>, options?: any): AxiosPromise<AvailableFiltersValuesListObj> {
            return DefaultApiFp(configuration).listSubrentsAvailableFiltersValues(businessAccountId, filters, options)(axios, basePath);
        },
        /**
         * 
         * @summary Список наборов, продуктов и вариантов на интервале времени
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} from Начало целевого интервала времени, epoch millis
         * @param {number} until Начало целевого интервала времени, epoch millis
         * @param {boolean} includeRentElements Включать ли обязательства. В случае, если флаг true - добавляем их, если нет - выводим экземпляры со стеком доступности
         * @param {SummaryScheduleListRequestObject} summaryScheduleListRequestObject 
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {boolean} [includeInstanceSchedule] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSummarySchedule(businessAccountId: number, from: number, until: number, includeRentElements: boolean, summaryScheduleListRequestObject: SummaryScheduleListRequestObject, limit?: number, startFrom?: number, includeInstanceSchedule?: boolean, options?: any): AxiosPromise<NomenclatureWithInstancesAndRentElementsList> {
            return DefaultApiFp(configuration).listSummarySchedule(businessAccountId, from, until, includeRentElements, summaryScheduleListRequestObject, limit, startFrom, includeInstanceSchedule, options)(axios, basePath);
        },
        /**
         * 
         * @summary Список элементов шаблона
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} templateId Идентификатор шаблона
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {boolean} [excludeAggregations] Исключить аггрегации
         * @param {boolean} [excludeRecords] Исключить записи списка
         * @param {Date} [specialFromStartDateIncludingDelays] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTemplateElements(businessAccountId: number, templateId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, excludeAggregations?: boolean, excludeRecords?: boolean, specialFromStartDateIncludingDelays?: Date, options?: any): AxiosPromise<RentElementRecordList> {
            return DefaultApiFp(configuration).listTemplateElements(businessAccountId, templateId, limit, startFrom, filters, sortBy, sortOrder, search, excludeAggregations, excludeRecords, specialFromStartDateIncludingDelays, options)(axios, basePath);
        },
        /**
         * 
         * @summary Список шаблонов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTemplates(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): AxiosPromise<TemplateRecordList> {
            return DefaultApiFp(configuration).listTemplates(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение ограничения фильтров шаблона на основе присутствующих данных
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTemplatesAvailableFiltersValues(businessAccountId: number, options?: any): AxiosPromise<AvailableFiltersValuesListObj> {
            return DefaultApiFp(configuration).listTemplatesAvailableFiltersValues(businessAccountId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} [search] Поисковая строка
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTransportations(businessAccountId: number, search?: string, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', filters?: Array<string>, options?: any): AxiosPromise<TransportationRecordList> {
            return DefaultApiFp(configuration).listTransportations(businessAccountId, search, limit, startFrom, sortBy, sortOrder, filters, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение списка простых записей пользователей бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsersSimple(businessAccountId: number, limit?: number, startFrom?: number, options?: any): AxiosPromise<UserRecordList> {
            return DefaultApiFp(configuration).listUsersSimple(businessAccountId, limit, startFrom, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} [search] Поисковая строка
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVehicles(businessAccountId: number, search?: string, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', filters?: Array<string>, options?: any): AxiosPromise<VehicleRecordList> {
            return DefaultApiFp(configuration).listVehicles(businessAccountId, search, limit, startFrom, sortBy, sortOrder, filters, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение ограничений фильтров по тс
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVehiclesAvailableFilters(businessAccountId: number, options?: any): AxiosPromise<AvailableFiltersValuesListObj> {
            return DefaultApiFp(configuration).listVehiclesAvailableFilters(businessAccountId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Список работ
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} [limit] Максимальное количество элементов
         * @param {number} [startFrom] Сдвиг
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {string} [search] Поисковая строка
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkPlannings(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any): AxiosPromise<WorkPlanningsRecordList> {
            return DefaultApiFp(configuration).listWorkPlannings(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение ограничений фильтров работ на основе присутствующих данных
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkPlanningsAvailableFiltersValues(businessAccountId: number, filters?: Array<string>, options?: any): AxiosPromise<AvailableFiltersValuesListObj> {
            return DefaultApiFp(configuration).listWorkPlanningsAvailableFiltersValues(businessAccountId, filters, options)(axios, basePath);
        },
        /**
         * 
         * @summary Сгруппированные обязательства успешно получены
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
         * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
         * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkPlanningsGroupedByCrewMember(businessAccountId: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', options?: any): AxiosPromise<WorkPlanningGroupRecordList> {
            return DefaultApiFp(configuration).listWorkPlanningsGroupedByCrewMember(businessAccountId, filters, sortBy, sortOrder, options)(axios, basePath);
        },
        /**
         * 
         * @summary Выполнить миграцию данных
         * @param {number} businessAccountId 
         * @param {number} targetCreationAuthorId 
         * @param {any} jxlsXMLConfiguration 
         * @param {any} excelFile 
         * @param {any} massImportXMLConfiguration 
         * @param {number} [startFrom] 
         * @param {number} [count] 
         * @param {OnExistingEntityFound} [onExistingEntityFound] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrate(businessAccountId: number, targetCreationAuthorId: number, jxlsXMLConfiguration: any, excelFile: any, massImportXMLConfiguration: any, startFrom?: number, count?: number, onExistingEntityFound?: OnExistingEntityFound, options?: any): AxiosPromise<MigrationStats> {
            return DefaultApiFp(configuration).migrate(businessAccountId, targetCreationAuthorId, jxlsXMLConfiguration, excelFile, massImportXMLConfiguration, startFrom, count, onExistingEntityFound, options)(axios, basePath);
        },
        /**
         * 
         * @summary Перенос затрат проекта в другой проект
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} projectId Идентификатор проекта
         * @param {number} targetProjectId Идентификатор целевого проекта (в который переносятся затраты)
         * @param {boolean} copy Признак копирования. Если да, то в целевой переносятся как черновики, в исходном не изменяются. Если нет, то в целевой переносятся как есть, в исходном отменяются
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveExpensesToOtherProject(businessAccountId: number, projectId: number, targetProjectId: number, copy: boolean, versionedEntityObjList: VersionedEntityObjList, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).moveExpensesToOtherProject(businessAccountId, projectId, targetProjectId, copy, versionedEntityObjList, options)(axios, basePath);
        },
        /**
         * 
         * @summary Перенос обязательств проекта в другой проект
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} projectId Идентификатор проекта
         * @param {number} targetProjectId Идентификатор целевого проекта (в который переносятся обязательства). Должен быть в работае, если исходный в работе; не должен быть новым, если исходный завершен
         * @param {boolean} copy Признак копирования. Если да, то в целевой переносятся как черновики, в исходном не изменяются. Если нет, то в целевой переносятся как есть, в исходном отменяются
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {boolean} [replaceInstancesWithAnonymous] Признак копирования с заменой именованных на анонимные. Используется только при копировании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveProjectElementsToOtherProject(businessAccountId: number, projectId: number, targetProjectId: number, copy: boolean, versionedEntityObjList: VersionedEntityObjList, replaceInstancesWithAnonymous?: boolean, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).moveProjectElementsToOtherProject(businessAccountId, projectId, targetProjectId, copy, versionedEntityObjList, replaceInstancesWithAnonymous, options)(axios, basePath);
        },
        /**
         * 
         * @summary Перенос обязательств поставки в другую поставку
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} subrentId Идентификатор поставки
         * @param {number} targetSubrentId Идентификатор целевой поставки (в которую переносятся обязательства). Должна быть в работае, если исходная в работе; не должна быть новой, если исходная завершена
         * @param {boolean} copy Признак копирования. Если да, то в целевую переносятся как черновики, в исходном не изменяются. Если нет, то в целевую переносятся как есть, в исходном отменяются.
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {boolean} [replaceInstancesWithAnonymous] Признак копирования с заменой именованных на анонимные. Используется только при копировании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveSubrentElementsToOtherSubrent(businessAccountId: number, subrentId: number, targetSubrentId: number, copy: boolean, versionedEntityObjList: VersionedEntityObjList, replaceInstancesWithAnonymous?: boolean, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).moveSubrentElementsToOtherSubrent(businessAccountId, subrentId, targetSubrentId, copy, versionedEntityObjList, replaceInstancesWithAnonymous, options)(axios, basePath);
        },
        /**
         * 
         * @summary Перенос работ проекта в другой проект
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} projectId Идентификатор проекта
         * @param {number} targetProjectId Идентификатор целевого проекта (в который переносятся работы)
         * @param {boolean} copy Признак копирования. Если да, то в целевой переносятся как черновики, в исходном не изменяются. Если нет, то в целевой переносятся как есть, в исходном отменяются
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {boolean} [saveCrewMembers] Признак копирования с сохранением работников. Используется только при копировании
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveWorkPlanningsToOtherProject(businessAccountId: number, projectId: number, targetProjectId: number, copy: boolean, versionedEntityObjList: VersionedEntityObjList, saveCrewMembers?: boolean, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).moveWorkPlanningsToOtherProject(businessAccountId, projectId, targetProjectId, copy, versionedEntityObjList, saveCrewMembers, options)(axios, basePath);
        },
        /**
         * 
         * @summary Список элементов бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} activityFrameId Идентификатор рамки активности - проекта, КП, поставки или шаблона
         * @param {RentActivityFrameTypeCodeEnum} activityFrameTypeCode 
         * @param {VersionedEntityObjList} versionedEntityObjList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recalculateBusinessAccountElementsPrice(businessAccountId: number, activityFrameId: number, activityFrameTypeCode: RentActivityFrameTypeCodeEnum, versionedEntityObjList: VersionedEntityObjList, options?: any): AxiosPromise<ProblematicRentElementInRecalculateOrNumberOfChanges> {
            return DefaultApiFp(configuration).recalculateBusinessAccountElementsPrice(businessAccountId, activityFrameId, activityFrameTypeCode, versionedEntityObjList, options)(axios, basePath);
        },
        /**
         * 
         * @summary Пересчет флага удаляемости у контактов внутри БА или у всех (если null)
         * @param {number} [businessAccountId] Id бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recalculateContactsDeleteable(businessAccountId?: number, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).recalculateContactsDeleteable(businessAccountId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Пересчитать стоимость к оплате и долги по проектам
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recalculateProjectsSumsToBePaidAndDebts(businessAccountId: number, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).recalculateProjectsSumsToBePaidAndDebts(businessAccountId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Пересчитать все показатели компании и нижестроящих сущностей (реиндекс только для измененных в процессе)
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {boolean} [fullRecountWarehousePresence] Пересчитывать ли всю номенклатуру инвентаря или только ту у которой есть что-то в аренде / субаренде
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshBusinessAccount(businessAccountId: number, fullRecountWarehousePresence?: boolean, options?: any): AxiosPromise<BusinessAccountIndicatorsObj> {
            return DefaultApiFp(configuration).refreshBusinessAccount(businessAccountId, fullRecountWarehousePresence, options)(axios, basePath);
        },
        /**
         * 
         * @summary Пересчитать все показатели бизнес аккаунта (без пересчета нижестоящих сущностей и без реиндекса)
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshBusinessAccountIndicators(businessAccountId: number, options?: any): AxiosPromise<BusinessAccountIndicatorsObj> {
            return DefaultApiFp(configuration).refreshBusinessAccountIndicators(businessAccountId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Пересчитать все показатели операции и нижестроящих сущностей, а также переиндексировать его
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshOperation(businessAccountId: number, id: number, options?: any): AxiosPromise<OperationInfoRead> {
            return DefaultApiFp(configuration).refreshOperation(businessAccountId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Пересчитать все показатели продукта и нижестроящих сущностей, а также переиндексировать его
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshProduct(businessAccountId: number, id: number, options?: any): AxiosPromise<ProductInfoRead> {
            return DefaultApiFp(configuration).refreshProduct(businessAccountId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Пересчитать все показатели проекта и нижестроящих сущностей, а также переиндексировать его
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshProject(businessAccountId: number, id: number, options?: any): AxiosPromise<RentIndicatorsObj> {
            return DefaultApiFp(configuration).refreshProject(businessAccountId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Пересчитать все показатели элемента, а также переиндексировать его
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshRentElement(businessAccountId: number, id: number, options?: any): AxiosPromise<RentElementInfoRead> {
            return DefaultApiFp(configuration).refreshRentElement(businessAccountId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Пересчитать и переиндексировать все показатели арендатора и нижестроящих сущностей
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshRenter(businessAccountId: number, id: number, options?: any): AxiosPromise<RentIndicatorsObj> {
            return DefaultApiFp(configuration).refreshRenter(businessAccountId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Пересчитать все показатели субаренды и нижестроящих сущностей, а также переиндексировать его
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshSubrent(businessAccountId: number, id: number, options?: any): AxiosPromise<RentIndicatorsObj> {
            return DefaultApiFp(configuration).refreshSubrent(businessAccountId, id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Пересоздание контактов пользователей
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {boolean} removeExistingContactUserLinks Удалить существующие пользовательские контакты перед пересозданием
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regenerateBusinessAccountUserContacts(businessAccountId: number, removeExistingContactUserLinks: boolean, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).regenerateBusinessAccountUserContacts(businessAccountId, removeExistingContactUserLinks, options)(axios, basePath);
        },
        /**
         * 
         * @summary Пересоздание контактов пользователей
         * @param {boolean} removeExistingContactUserLinks Удалить существующие пользовательские контакты перед пересозданием
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regenerateUserContactsGlobally(removeExistingContactUserLinks: boolean, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).regenerateUserContactsGlobally(removeExistingContactUserLinks, options)(axios, basePath);
        },
        /**
         * 
         * @summary Регистрация операции и получения параллельных с ней
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {ConcurrentOperationObj} concurrentOperationObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerAndListConcurrentOperations(businessAccountId: number, concurrentOperationObj: ConcurrentOperationObj, options?: any): AxiosPromise<ConcurrentOperationObjList> {
            return DefaultApiFp(configuration).registerAndListConcurrentOperations(businessAccountId, concurrentOperationObj, options)(axios, basePath);
        },
        /**
         * 
         * @summary Регистрация нового бизнес аккаунта
         * @param {number} timezoneOffsetUTC Временная зона
         * @param {BusinessAccountRegistrationObj} businessAccountRegistrationObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerBusinessAccount(timezoneOffsetUTC: number, businessAccountRegistrationObj: BusinessAccountRegistrationObj, options?: any): AxiosPromise<BusinessAccountInfoRead> {
            return DefaultApiFp(configuration).registerBusinessAccount(timezoneOffsetUTC, businessAccountRegistrationObj, options)(axios, basePath);
        },
        /**
         * 
         * @summary Создание организации в системе SSO авторизации и привязка к бизнес аккаунту
         * @param {string} accessToken Обязательный токен доступа в формате UUID
         * @param {SsoClientOrganizationInfoWrite} ssoClientOrganizationInfoWrite 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerSsoClientOrganization(accessToken: string, ssoClientOrganizationInfoWrite: SsoClientOrganizationInfoWrite, options?: any): AxiosPromise<SsoClientOrganizationInfoRead> {
            return DefaultApiFp(configuration).registerSsoClientOrganization(accessToken, ssoClientOrganizationInfoWrite, options)(axios, basePath);
        },
        /**
         * 
         * @summary Удаление из списка закрытой операции
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} uuid Временный UUID идентификатор операции
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeConcurrentOperation(businessAccountId: number, uuid: string, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).removeConcurrentOperation(businessAccountId, uuid, options)(axios, basePath);
        },
        /**
         * 
         * @summary Получение списка релевантных адресов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {string} addressQuery Строка с частью адреса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestAddresses(businessAccountId: number, addressQuery: string, options?: any): AxiosPromise<Array<AddressSuggestion>> {
            return DefaultApiFp(configuration).suggestAddresses(businessAccountId, addressQuery, options)(axios, basePath);
        },
        /**
         * 
         * @summary Изменение статуса и архивация бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirective} workflowDirective 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitBusinessAccountWorkflow(businessAccountId: number, workflowDirective: WorkflowDirective, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).transitBusinessAccountWorkflow(businessAccountId, workflowDirective, options)(axios, basePath);
        },
        /**
         * 
         * @summary Массовое изменение бизнес аккаунтов - статусы и архивирование
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitBusinessAccountWorkflowBulk(workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).transitBusinessAccountWorkflowBulk(workflowDirectiveBulk, options)(axios, basePath);
        },
        /**
         * 
         * @summary Массовое изменение контактов - архивирование и разархивирование
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitContactWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).transitContactWorkflowBulk(businessAccountId, workflowDirectiveBulk, options)(axios, basePath);
        },
        /**
         * 
         * @summary Массовое изменение кадров - статусы и архивирование
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitCrewMemberWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).transitCrewMemberWorkflowBulk(businessAccountId, workflowDirectiveBulk, options)(axios, basePath);
        },
        /**
         * 
         * @summary Массовое изменение затрат - статусы
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitExpenseWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).transitExpenseWorkflowBulk(businessAccountId, workflowDirectiveBulk, options)(axios, basePath);
        },
        /**
         * 
         * @summary Массовое изменение наборы - статусы и архивирование
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitKitWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).transitKitWorkflowBulk(businessAccountId, workflowDirectiveBulk, options)(axios, basePath);
        },
        /**
         * 
         * @summary Массовое изменение локаций - архивирование и разархивирование
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitLocationWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).transitLocationWorkflowBulk(businessAccountId, workflowDirectiveBulk, options)(axios, basePath);
        },
        /**
         * 
         * @summary Массовое изменение номенклатуры - статусы и архивирование
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {NomenclatureWorkflowDirectiveBulk} nomenclatureWorkflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitNomenclatureWorkflowBulk(businessAccountId: number, nomenclatureWorkflowDirectiveBulk: NomenclatureWorkflowDirectiveBulk, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).transitNomenclatureWorkflowBulk(businessAccountId, nomenclatureWorkflowDirectiveBulk, options)(axios, basePath);
        },
        /**
         * 
         * @summary Массовое изменение способов оплаты - только архивирование
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitPaymentMethodsWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).transitPaymentMethodsWorkflowBulk(businessAccountId, workflowDirectiveBulk, options)(axios, basePath);
        },
        /**
         * 
         * @summary Массовое изменение статуса платежа в жизненном цикле
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitPaymentsWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).transitPaymentsWorkflowBulk(businessAccountId, workflowDirectiveBulk, options)(axios, basePath);
        },
        /**
         * 
         * @summary Массовое изменение экземпляров продукта - только статусы
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitProductInstanceWorkflowBulk(businessAccountId: number, id: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).transitProductInstanceWorkflowBulk(businessAccountId, id, workflowDirectiveBulk, options)(axios, basePath);
        },
        /**
         * 
         * @summary Массовое изменение вариантов - только архивирование
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitProductVariantWorkflowBulk(businessAccountId: number, id: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).transitProductVariantWorkflowBulk(businessAccountId, id, workflowDirectiveBulk, options)(axios, basePath);
        },
        /**
         * 
         * @summary Массовое изменение продуктов - статусы и архивирование
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitProductWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).transitProductWorkflowBulk(businessAccountId, workflowDirectiveBulk, options)(axios, basePath);
        },
        /**
         * 
         * @summary Массовое изменение профессий - статусы и архивирование
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitProfessionWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).transitProfessionWorkflowBulk(businessAccountId, workflowDirectiveBulk, options)(axios, basePath);
        },
        /**
         * 
         * @summary Массовое изменение проектов - статусы и архивирование
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitProjectWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).transitProjectWorkflowBulk(businessAccountId, workflowDirectiveBulk, options)(axios, basePath);
        },
        /**
         * 
         * @summary Массовое изменение аредаторов - статусы и архивирование
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitRenterWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).transitRenterWorkflowBulk(businessAccountId, workflowDirectiveBulk, options)(axios, basePath);
        },
        /**
         * 
         * @summary Массовое изменение субаренд - статусы и архивирование
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitSubrentWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).transitSubrentWorkflowBulk(businessAccountId, workflowDirectiveBulk, options)(axios, basePath);
        },
        /**
         * 
         * @summary Массовое изменение шаблонов - статусы и архивирование
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitTemplateWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).transitTemplateWorkflowBulk(businessAccountId, workflowDirectiveBulk, options)(axios, basePath);
        },
        /**
         * 
         * @summary Массовое изменение локаций - архивирование и разархивирование
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitTransportationWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).transitTransportationWorkflowBulk(businessAccountId, workflowDirectiveBulk, options)(axios, basePath);
        },
        /**
         * 
         * @summary Массовое изменение локаций - архивирование и разархивирование
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitVehicleWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).transitVehicleWorkflowBulk(businessAccountId, workflowDirectiveBulk, options)(axios, basePath);
        },
        /**
         * 
         * @summary Массовое изменение событий работ
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transitWorkPlanningWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).transitWorkPlanningWorkflowBulk(businessAccountId, workflowDirectiveBulk, options)(axios, basePath);
        },
        /**
         * 
         * @summary Изменение информации о вложении
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {AttachmentInfoUpdate} attachmentInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAttachmentById(businessAccountId: number, id: number, attachmentInfoUpdate: AttachmentInfoUpdate, options?: any): AxiosPromise<AttachmentInfoRead> {
            return DefaultApiFp(configuration).updateAttachmentById(businessAccountId, id, attachmentInfoUpdate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Изменение состояния бизнес аккаунта (полный доступ к данным)
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {BusinessAccountInfoUpdate} businessAccountInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBusinessAccountById(businessAccountId: number, businessAccountInfoUpdate: BusinessAccountInfoUpdate, options?: any): AxiosPromise<BusinessAccountInfoRead> {
            return DefaultApiFp(configuration).updateBusinessAccountById(businessAccountId, businessAccountInfoUpdate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Изменение контактных данных бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {BusinessAccountContactsInfoUpdate} businessAccountContactsInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBusinessAccountContactsById(businessAccountId: number, businessAccountContactsInfoUpdate: BusinessAccountContactsInfoUpdate, options?: any): AxiosPromise<BusinessAccountContactsInfoRead> {
            return DefaultApiFp(configuration).updateBusinessAccountContactsById(businessAccountId, businessAccountContactsInfoUpdate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Изменение основных настроек бизнес аккаунта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {BusinessAccountPreferencesInfoUpdate} businessAccountPreferencesInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBusinessAccountPreferences(businessAccountId: number, businessAccountPreferencesInfoUpdate: BusinessAccountPreferencesInfoUpdate, options?: any): AxiosPromise<BusinessAccountPreferencesInfoRead> {
            return DefaultApiFp(configuration).updateBusinessAccountPreferences(businessAccountId, businessAccountPreferencesInfoUpdate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Изменение состояния категории
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {CategoryInfoUpdate} categoryInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCategoryById(businessAccountId: number, id: number, categoryInfoUpdate: CategoryInfoUpdate, options?: any): AxiosPromise<CategoryInfoRead> {
            return DefaultApiFp(configuration).updateCategoryById(businessAccountId, id, categoryInfoUpdate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Изменение состояния категории с явным указанием полей
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {Array<object>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCategoryExplicit(businessAccountId: number, requestBody: Array<object>, options?: any): AxiosPromise<RenterInfoRead> {
            return DefaultApiFp(configuration).updateCategoryExplicit(businessAccountId, requestBody, options)(axios, basePath);
        },
        /**
         * 
         * @summary Изменение профиля клиентского пользователя
         * @param {ClientUserInfoUpdate} clientUserInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClientUser(clientUserInfoUpdate: ClientUserInfoUpdate, options?: any): AxiosPromise<ClientUserInfoUpdate> {
            return DefaultApiFp(configuration).updateClientUser(clientUserInfoUpdate, options)(axios, basePath);
        },
        /**
         * Обновляет стандартный шаблон документа и сущность в бд
         * @summary Обновляет стандартный шаблон документа
         * @param {number} id Идентификатор сущности
         * @param {any} file 
         * @param {boolean} ignoreFile Если true, изменение контента шаблона игнорируется
         * @param {DocumentTemplateInfoUpdate} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCommonDocumentTemplate(id: number, file: any, ignoreFile: boolean, data: DocumentTemplateInfoUpdate, options?: any): AxiosPromise<DocumentTemplateInfoRead> {
            return DefaultApiFp(configuration).updateCommonDocumentTemplate(id, file, ignoreFile, data, options)(axios, basePath);
        },
        /**
         * 
         * @summary Обновление контента стандартного шаблона документа
         * @param {number} id Идентификатор сущности
         * @param {any} file 
         * @param {number} businessVersion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCommonDocumentTemplateContent(id: number, file: any, businessVersion: number, options?: any): AxiosPromise<DocumentTemplateInfoRead> {
            return DefaultApiFp(configuration).updateCommonDocumentTemplateContent(id, file, businessVersion, options)(axios, basePath);
        },
        /**
         * 
         * @summary Изменение контакта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {ContactInfoUpdate} contactInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContactById(businessAccountId: number, id: number, contactInfoUpdate: ContactInfoUpdate, options?: any): AxiosPromise<ContactInfoRead> {
            return DefaultApiFp(configuration).updateContactById(businessAccountId, id, contactInfoUpdate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Изменение кадра
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {CrewMemberInfoUpdate} crewMemberInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCrewMember(businessAccountId: number, id: number, crewMemberInfoUpdate: CrewMemberInfoUpdate, options?: any): AxiosPromise<CrewMemberInfoRead> {
            return DefaultApiFp(configuration).updateCrewMember(businessAccountId, id, crewMemberInfoUpdate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Изменение ставки кадра
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {Array<ProfessionAssignmentObjWrite>} professionAssignmentObjWrite 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCrewMemberRates(businessAccountId: number, id: number, professionAssignmentObjWrite: Array<ProfessionAssignmentObjWrite>, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).updateCrewMemberRates(businessAccountId, id, professionAssignmentObjWrite, options)(axios, basePath);
        },
        /**
         * 
         * @summary Изменение состояния настроек уведомлений текущего пользователя
         * @param {NotificationSchemaInfoUpdate} notificationSchemaInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCurrentNotificationSchema(notificationSchemaInfoUpdate: NotificationSchemaInfoUpdate, options?: any): AxiosPromise<NotificationSchemaInfoRead> {
            return DefaultApiFp(configuration).updateCurrentNotificationSchema(notificationSchemaInfoUpdate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Изменение пользовательского поля
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {CustomFieldInfoUpdate} customFieldInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomFieldById(businessAccountId: number, id: number, customFieldInfoUpdate: CustomFieldInfoUpdate, options?: any): AxiosPromise<CustomFieldInfoRead> {
            return DefaultApiFp(configuration).updateCustomFieldById(businessAccountId, id, customFieldInfoUpdate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Изменение всех пользовательских полей по типу сущности
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {string} nomenclatureType Тип номенклатуры для печати
         * @param {CustomFieldsGlobalObjWrite} customFieldsGlobalObjWrite 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomFieldsByEntityType(businessAccountId: number, id: number, nomenclatureType: string, customFieldsGlobalObjWrite: CustomFieldsGlobalObjWrite, options?: any): AxiosPromise<CustomFieldsGlobalObjRead> {
            return DefaultApiFp(configuration).updateCustomFieldsByEntityType(businessAccountId, id, nomenclatureType, customFieldsGlobalObjWrite, options)(axios, basePath);
        },
        /**
         * 
         * @summary Изменение привязки кастомных полей к продуктам
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {boolean} heckOnly Просто проверка, если true - изменений не производится, только подсчет сущностей, которые будут заторнуты
         * @param {UpdateLinksByCategoryObj} updateLinksByCategoryObj 
         * @param {boolean} [linkVariants] Привязать поля к вариантам продукта, если он поддерживает варианты. Используется только при привязке
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomFieldsLinks(businessAccountId: number, heckOnly: boolean, updateLinksByCategoryObj: UpdateLinksByCategoryObj, linkVariants?: boolean, options?: any): AxiosPromise<number> {
            return DefaultApiFp(configuration).updateCustomFieldsLinks(businessAccountId, heckOnly, updateLinksByCategoryObj, linkVariants, options)(axios, basePath);
        },
        /**
         * 
         * @summary Обновление существующего шаблона документа
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {any} file 
         * @param {boolean} ignoreFile Если true, изменение контента шаблона игнорируется
         * @param {DocumentTemplateInfoUpdate} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocumentTemplate(businessAccountId: number, id: number, file: any, ignoreFile: boolean, data: DocumentTemplateInfoUpdate, options?: any): AxiosPromise<DocumentTemplateInfoRead> {
            return DefaultApiFp(configuration).updateDocumentTemplate(businessAccountId, id, file, ignoreFile, data, options)(axios, basePath);
        },
        /**
         * Обновление настроек для шаблона
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {boolean} common Флаг типа шаблона
         * @param {number} businessVersion Бизнес версия
         * @param {boolean} hidden Флаг является ли шаблон скрытым в БА
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocumentTemplateBusinessAccountPreferences(businessAccountId: number, id: number, common: boolean, businessVersion: number, hidden: boolean, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).updateDocumentTemplateBusinessAccountPreferences(businessAccountId, id, common, businessVersion, hidden, options)(axios, basePath);
        },
        /**
         * 
         * @summary Обновление контента пользовательского шаблона документа
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {any} file 
         * @param {number} businessVersion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocumentTemplateContent(businessAccountId: number, id: number, file: any, businessVersion: number, options?: any): AxiosPromise<DocumentTemplateInfoRead> {
            return DefaultApiFp(configuration).updateDocumentTemplateContent(businessAccountId, id, file, businessVersion, options)(axios, basePath);
        },
        /**
         * Изменение сущности затраты по проекту
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {ExpenseInfoUpdate} expenseInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExpense(businessAccountId: number, id: number, expenseInfoUpdate: ExpenseInfoUpdate, options?: any): AxiosPromise<ExpenseInfoRead> {
            return DefaultApiFp(configuration).updateExpense(businessAccountId, id, expenseInfoUpdate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Изменение шаблона для экспорта
         * @param {number} id Идентификатор сущности
         * @param {ExportTemplateInfoUpdate} model 
         * @param {any} [content] 
         * @param {any} [jasper] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExportTemplate(id: number, model: ExportTemplateInfoUpdate, content?: any, jasper?: any, options?: any): AxiosPromise<ExportTemplateInfoRead> {
            return DefaultApiFp(configuration).updateExportTemplate(id, model, content, jasper, options)(axios, basePath);
        },
        /**
         * 
         * @summary Изменение события движения инвентаря
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {InventoryMovementInfoUpdate} inventoryMovementInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInventoryMovementById(businessAccountId: number, id: number, inventoryMovementInfoUpdate: InventoryMovementInfoUpdate, options?: any): AxiosPromise<InventoryMovementInfoRead> {
            return DefaultApiFp(configuration).updateInventoryMovementById(businessAccountId, id, inventoryMovementInfoUpdate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Изменение состояния набора
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {KitInfoUpdate} kitInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKitById(businessAccountId: number, id: number, kitInfoUpdate: KitInfoUpdate, options?: any): AxiosPromise<KitInfoRead> {
            return DefaultApiFp(configuration).updateKitById(businessAccountId, id, kitInfoUpdate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Изменение состояния объекта субъектов и реквизитов
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {LegalDetailsInfoWrite} legalDetailsInfoWrite 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLegalDetailById(businessAccountId: number, id: number, legalDetailsInfoWrite: LegalDetailsInfoWrite, options?: any): AxiosPromise<LegalDetailsInfoRead> {
            return DefaultApiFp(configuration).updateLegalDetailById(businessAccountId, id, legalDetailsInfoWrite, options)(axios, basePath);
        },
        /**
         * 
         * @summary Изменение локации
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {LocationInfoUpdate} locationInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLocationById(businessAccountId: number, id: number, locationInfoUpdate: LocationInfoUpdate, options?: any): AxiosPromise<LocationInfoRead> {
            return DefaultApiFp(configuration).updateLocationById(businessAccountId, id, locationInfoUpdate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Изменение состояния операции
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {OperationInfoUpdate} operationInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperationById(businessAccountId: number, id: number, operationInfoUpdate: OperationInfoUpdate, options?: any): AxiosPromise<OperationInfoRead> {
            return DefaultApiFp(configuration).updateOperationById(businessAccountId, id, operationInfoUpdate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Редактирование простого заказа
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {SimpleOrderInfoUpdate} simpleOrderInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderWithRentElements(businessAccountId: number, id: number, simpleOrderInfoUpdate: SimpleOrderInfoUpdate, options?: any): AxiosPromise<ProjectInfoRead> {
            return DefaultApiFp(configuration).updateOrderWithRentElements(businessAccountId, id, simpleOrderInfoUpdate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Изменение данных о платеже
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {PaymentInfoUpdate} paymentInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePaymentById(businessAccountId: number, id: number, paymentInfoUpdate: PaymentInfoUpdate, options?: any): AxiosPromise<PaymentInfoRead> {
            return DefaultApiFp(configuration).updatePaymentById(businessAccountId, id, paymentInfoUpdate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Изменение состояния способа оплаты
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {PaymentMethodInfoUpdate} paymentMethodInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePaymentMethodById(businessAccountId: number, id: number, paymentMethodInfoUpdate: PaymentMethodInfoUpdate, options?: any): AxiosPromise<PaymentMethodInfoRead> {
            return DefaultApiFp(configuration).updatePaymentMethodById(businessAccountId, id, paymentMethodInfoUpdate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Изменение ценовой схемы
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {PricingSchemeInfoUpdate} pricingSchemeInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePricingSchemeById(businessAccountId: number, id: number, pricingSchemeInfoUpdate: PricingSchemeInfoUpdate, options?: any): AxiosPromise<PricingSchemeInfoRead> {
            return DefaultApiFp(configuration).updatePricingSchemeById(businessAccountId, id, pricingSchemeInfoUpdate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Изменение привязки ценовых схем к продуктам
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {UpdateLinksByCategoryObj} updateLinksByCategoryObj 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePricingSchemesLinks(businessAccountId: number, updateLinksByCategoryObj: UpdateLinksByCategoryObj, options?: any): AxiosPromise<number> {
            return DefaultApiFp(configuration).updatePricingSchemesLinks(businessAccountId, updateLinksByCategoryObj, options)(axios, basePath);
        },
        /**
         * 
         * @summary Изменение состояния продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {ProductInfoUpdate} productInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductById(businessAccountId: number, id: number, productInfoUpdate: ProductInfoUpdate, options?: any): AxiosPromise<ProductInfoRead> {
            return DefaultApiFp(configuration).updateProductById(businessAccountId, id, productInfoUpdate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Изменение состояния экземпляра продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} productId Идентификатор продукта
         * @param {number} id Идентификатор сущности
         * @param {InstanceInfoUpdate} instanceInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductInstanceById(businessAccountId: number, productId: number, id: number, instanceInfoUpdate: InstanceInfoUpdate, options?: any): AxiosPromise<InstanceInfoRead> {
            return DefaultApiFp(configuration).updateProductInstanceById(businessAccountId, productId, id, instanceInfoUpdate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Изменение состояния варианта продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} productId Идентификатор продукта
         * @param {number} id Идентификатор сущности
         * @param {VariantInfoUpdate} variantInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductVariantById(businessAccountId: number, productId: number, id: number, variantInfoUpdate: VariantInfoUpdate, options?: any): AxiosPromise<VariantInfoRead> {
            return DefaultApiFp(configuration).updateProductVariantById(businessAccountId, productId, id, variantInfoUpdate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Изменение состояния экземпляра варианта продукта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} productId Идентификатор продукта
         * @param {number} variantId Идентификатор варианта
         * @param {number} id Идентификатор сущности
         * @param {InstanceInfoUpdate} instanceInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductVariantInstanceById(businessAccountId: number, productId: number, variantId: number, id: number, instanceInfoUpdate: InstanceInfoUpdate, options?: any): AxiosPromise<InstanceInfoRead> {
            return DefaultApiFp(configuration).updateProductVariantInstanceById(businessAccountId, productId, variantId, id, instanceInfoUpdate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Изменение профессии
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {ProfessionInfoUpdate} professionInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfessionById(businessAccountId: number, id: number, professionInfoUpdate: ProfessionInfoUpdate, options?: any): AxiosPromise<ProfessionInfoRead> {
            return DefaultApiFp(configuration).updateProfessionById(businessAccountId, id, professionInfoUpdate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Обновление профиля пользователя
         * @param {UserInfoUpdate} userInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfileData(userInfoUpdate: UserInfoUpdate, options?: any): AxiosPromise<UserInfoRead> {
            return DefaultApiFp(configuration).updateProfileData(userInfoUpdate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Обновление пароля профиля
         * @param {UpdatePassword} updatePassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfilePassword(updatePassword: UpdatePassword, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).updateProfilePassword(updatePassword, options)(axios, basePath);
        },
        /**
         * 
         * @summary Изменение состояния проекта
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {ProjectInfoUpdate} projectInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectById(businessAccountId: number, id: number, projectInfoUpdate: ProjectInfoUpdate, options?: any): AxiosPromise<ProjectInfoRead> {
            return DefaultApiFp(configuration).updateProjectById(businessAccountId, id, projectInfoUpdate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Изменение состояния проекта с явным указанием полей
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {Array<object>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectByIdExplicit(businessAccountId: number, id: number, requestBody: Array<object>, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).updateProjectByIdExplicit(businessAccountId, id, requestBody, options)(axios, basePath);
        },
        /**
         * 
         * @summary Изменение состояния арендатора
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {RenterInfoUpdate} renterInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRenterById(businessAccountId: number, id: number, renterInfoUpdate: RenterInfoUpdate, options?: any): AxiosPromise<RenterInfoRead> {
            return DefaultApiFp(configuration).updateRenterById(businessAccountId, id, renterInfoUpdate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Изменение состояния арендатора с явным указанием полей
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {Array<object>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRenterByIdExplicit(businessAccountId: number, id: number, requestBody: Array<object>, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).updateRenterByIdExplicit(businessAccountId, id, requestBody, options)(axios, basePath);
        },
        /**
         * 
         * @summary Обновление графической визуализации отчета
         * @param {number} reportId 
         * @param {number} id Идентификатор сущности
         * @param {ReportChartVisualizationInfoUpdate} [reportChartVisualizationInfoUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReportChartVisualizationById(reportId: number, id: number, reportChartVisualizationInfoUpdate?: ReportChartVisualizationInfoUpdate, options?: any): AxiosPromise<ReportChartVisualizationInfoRead> {
            return DefaultApiFp(configuration).updateReportChartVisualizationById(reportId, id, reportChartVisualizationInfoUpdate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Изменение конфигурации отчета
         * @param {number} id Идентификатор сущности
         * @param {ReportConfigurationInfoUpdate} model 
         * @param {any} [content] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReportConfiguration(id: number, model: ReportConfigurationInfoUpdate, content?: any, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).updateReportConfiguration(id, model, content, options)(axios, basePath);
        },
        /**
         * 
         * @summary Обновление Эксель визуализации отчета
         * @param {number} reportId 
         * @param {number} id Идентификатор сущности
         * @param {ReportExcelVisualizationInfoUpdate} model 
         * @param {any} [content] 
         * @param {any} [jasper] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReportExcelVisualizationById(reportId: number, id: number, model: ReportExcelVisualizationInfoUpdate, content?: any, jasper?: any, options?: any): AxiosPromise<ReportExcelVisualizationInfoRead> {
            return DefaultApiFp(configuration).updateReportExcelVisualizationById(reportId, id, model, content, jasper, options)(axios, basePath);
        },
        /**
         * 
         * @summary Обновление организации в системе SSO авторизации для бизнес аккаунта
         * @param {string} accessToken Обязательный токен доступа в формате UUID
         * @param {SsoClientOrganizationInfoWrite} ssoClientOrganizationInfoWrite 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSsoClientOrganization(accessToken: string, ssoClientOrganizationInfoWrite: SsoClientOrganizationInfoWrite, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).updateSsoClientOrganization(accessToken, ssoClientOrganizationInfoWrite, options)(axios, basePath);
        },
        /**
         * 
         * @summary Изменение состояния субаренды
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {SubrentInfoUpdate} subrentInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubrentById(businessAccountId: number, id: number, subrentInfoUpdate: SubrentInfoUpdate, options?: any): AxiosPromise<SubrentInfoRead> {
            return DefaultApiFp(configuration).updateSubrentById(businessAccountId, id, subrentInfoUpdate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Изменение состояния системной настройки
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {SystemOptionInfoUpdate} systemOptionInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSystemOptionById(businessAccountId: number, id: number, systemOptionInfoUpdate: SystemOptionInfoUpdate, options?: any): AxiosPromise<SystemOptionInfoRead> {
            return DefaultApiFp(configuration).updateSystemOptionById(businessAccountId, id, systemOptionInfoUpdate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Изменение состояния системной настройки
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {SystemOptionInfoUpdateBulk} systemOptionInfoUpdateBulk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSystemOptionsBulk(businessAccountId: number, id: number, systemOptionInfoUpdateBulk: SystemOptionInfoUpdateBulk, options?: any): AxiosPromise<SystemOptionsSectionReadInfo> {
            return DefaultApiFp(configuration).updateSystemOptionsBulk(businessAccountId, id, systemOptionInfoUpdateBulk, options)(axios, basePath);
        },
        /**
         * 
         * @summary Изменение состояния шаблона
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {TemplateInfoUpdate} templateInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTemplateById(businessAccountId: number, id: number, templateInfoUpdate: TemplateInfoUpdate, options?: any): AxiosPromise<TemplateInfoRead> {
            return DefaultApiFp(configuration).updateTemplateById(businessAccountId, id, templateInfoUpdate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Изменение перевозки
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {TransportationInfoUpdate} transportationInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTransportationById(businessAccountId: number, id: number, transportationInfoUpdate: TransportationInfoUpdate, options?: any): AxiosPromise<TransportationInfoRead> {
            return DefaultApiFp(configuration).updateTransportationById(businessAccountId, id, transportationInfoUpdate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Изменение транспортного средства
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {VehicleInfoUpdate} vehicleInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVehicleById(businessAccountId: number, id: number, vehicleInfoUpdate: VehicleInfoUpdate, options?: any): AxiosPromise<VehicleInfoRead> {
            return DefaultApiFp(configuration).updateVehicleById(businessAccountId, id, vehicleInfoUpdate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Изменить описание работы
         * @param {number} businessAccountId Идентификатор бизнес аккаунта
         * @param {number} id Идентификатор сущности
         * @param {WorkPlanningsInfoUpdate} workPlanningsInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkPlanning(businessAccountId: number, id: number, workPlanningsInfoUpdate: WorkPlanningsInfoUpdate, options?: any): AxiosPromise<WorkPlanningsInfoRead> {
            return DefaultApiFp(configuration).updateWorkPlanning(businessAccountId, id, workPlanningsInfoUpdate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Загрузка kb-json
         * @param {KnowledgeBaseJson} [knowledgeBaseJson] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadKbJson(knowledgeBaseJson?: KnowledgeBaseJson, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).uploadKbJson(knowledgeBaseJson, options)(axios, basePath);
        },
        /**
         * 
         * @summary Загрузка переводов
         * @param {any} content 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadSystemWideTranslations(content: any, options?: any): AxiosPromise<boolean> {
            return DefaultApiFp(configuration).uploadSystemWideTranslations(content, options)(axios, basePath);
        },
        /**
         * 
         * @summary Валидация данных к миграции
         * @param {number} businessAccountId 
         * @param {number} targetCreationAuthorId 
         * @param {any} jxlsXMLConfiguration 
         * @param {any} excelFile 
         * @param {any} massImportXMLConfiguration 
         * @param {OnExistingEntityFound} onExistingEntityFound 
         * @param {number} [startFrom] 
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateMigrationPolicy(businessAccountId: number, targetCreationAuthorId: number, jxlsXMLConfiguration: any, excelFile: any, massImportXMLConfiguration: any, onExistingEntityFound: OnExistingEntityFound, startFrom?: number, count?: number, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).validateMigrationPolicy(businessAccountId, targetCreationAuthorId, jxlsXMLConfiguration, excelFile, massImportXMLConfiguration, onExistingEntityFound, startFrom, count, options)(axios, basePath);
        },
        /**
         * 
         * @summary Проверка допустимости email в профиле (не используется другим пользователем, принадлежность к текущему пользователю допустима). Необходим JWT токен пользователя
         * @param {string} email Email пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUniqueEmail(email: string, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).validateUniqueEmail(email, options)(axios, basePath);
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Добавление обязательств в существующий проект по шаблону
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {number} templateId Идентификатор шаблона
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addRentElementsFromTemplateToExistingProject(businessAccountId: number, id: number, templateId: number, options?: any) {
        return DefaultApiFp(this.configuration).addRentElementsFromTemplateToExistingProject(businessAccountId, id, templateId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Назначение ролей пользователю
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {UserBARolesUpdateObj} userBARolesUpdateObj 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public administrationAssignRolesToUser(businessAccountId: number, id: number, userBARolesUpdateObj: UserBARolesUpdateObj, options?: any) {
        return DefaultApiFp(this.configuration).administrationAssignRolesToUser(businessAccountId, id, userBARolesUpdateObj, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение списка записей пользователей бизнес аккаунта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {'INVITED' | 'ACTIVE' | 'BLOCKED'} [stateFilter] Фильтр по статусу пользователя
     * @param {'AGENT' | 'ADMIN'} [roleFilter] Фильтр по роли пользователя в бизнес аккаунте
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public administrationListBAUsers(businessAccountId: number, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, stateFilter?: 'INVITED' | 'ACTIVE' | 'BLOCKED', roleFilter?: 'AGENT' | 'ADMIN', options?: any) {
        return DefaultApiFp(this.configuration).administrationListBAUsers(businessAccountId, limit, startFrom, sortBy, sortOrder, search, stateFilter, roleFilter, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Ответ на приглашение
     * @param {string} token Код активации приглашения
     * @param {boolean} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public answerToTheInvitation(token: string, body: boolean, options?: any) {
        return DefaultApiFp(this.configuration).answerToTheInvitation(token, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Назначение контакта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {ContactAssignInfo} contactAssignInfo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public assignContactToEntity(businessAccountId: number, contactAssignInfo: ContactAssignInfo, options?: any) {
        return DefaultApiFp(this.configuration).assignContactToEntity(businessAccountId, contactAssignInfo, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Массовое обновление порядка категорий
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {CategoryTypeCodeEnum} typeCode Тип изменяемых категорий
     * @param {BulkUpdateCategoriesOrder} bulkUpdateCategoriesOrder 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public bulkUpdateCategoriesOrder(businessAccountId: number, typeCode: CategoryTypeCodeEnum, bulkUpdateCategoriesOrder: BulkUpdateCategoriesOrder, options?: any) {
        return DefaultApiFp(this.configuration).bulkUpdateCategoriesOrder(businessAccountId, typeCode, bulkUpdateCategoriesOrder, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение расчета стоимости и доступности позиций корзины
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {string} accessToken Обязательный токен доступа в формате UUID
     * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
     * @param {ClientCart} clientCart 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public calculateCart(businessAccountId: number, accessToken: string, storefrontId: number, clientCart: ClientCart, options?: any) {
        return DefaultApiFp(this.configuration).calculateCart(businessAccountId, accessToken, storefrontId, clientCart, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Пересчет отработанных смен по обязательствам с именованными экземплярами
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} subrentId Идентификатор поставки
     * @param {boolean} updateShifts Признак необходимости обновить смены обязательств субаренды результатом рассчета. Поставить в поле смены получившееся значение поля отработанные смены.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public calculateWorkedShifts(businessAccountId: number, subrentId: number, updateShifts: boolean, options?: any) {
        return DefaultApiFp(this.configuration).calculateWorkedShifts(businessAccountId, subrentId, updateShifts, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Отмена заказа. Необходим JWT токен пользователя
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
     * @param {string} orderNumber Идентификатор заказа
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public cancelOrder(businessAccountId: number, storefrontId: number, orderNumber: string, options?: any) {
        return DefaultApiFp(this.configuration).cancelOrder(businessAccountId, storefrontId, orderNumber, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Перевод проекта в новый статус с помощью корректировки
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {ProjectStateCodeEnum} targetState Целевой статус
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public changeOrderStateByCorrection(businessAccountId: number, id: number, targetState: ProjectStateCodeEnum, options?: any) {
        return DefaultApiFp(this.configuration).changeOrderStateByCorrection(businessAccountId, id, targetState, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Массовая очистка тэгов у указанных сущностей
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {TagEntityTypeCode} entityTypeCode Создать или удалить, true &#x3D; создать, false &#x3D; удалить
     * @param {VersionedEntityObjList} versionedEntityObjList 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public clearTagsBulk(businessAccountId: number, entityTypeCode: TagEntityTypeCode, versionedEntityObjList: VersionedEntityObjList, options?: any) {
        return DefaultApiFp(this.configuration).clearTagsBulk(businessAccountId, entityTypeCode, versionedEntityObjList, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Преобразование простого заказа в проект
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public convertOrderToProject(businessAccountId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).convertOrderToProject(businessAccountId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Создание нового вложения. Максимальный объем 10 МБ
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {AttachmentInfoCreate} attachmentInfoCreate 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createAttachment(businessAccountId: number, attachmentInfoCreate: AttachmentInfoCreate, file: any, options?: any) {
        return DefaultApiFp(this.configuration).createAttachment(businessAccountId, attachmentInfoCreate, file, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Создание нового бизнес аккаунта
     * @param {BusinessAccountInfoCreate} businessAccountInfoCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createBusinessAccount(businessAccountInfoCreate: BusinessAccountInfoCreate, options?: any) {
        return DefaultApiFp(this.configuration).createBusinessAccount(businessAccountInfoCreate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Создание новой категории
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {CategoryInfoCreate} categoryInfoCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createCategory(businessAccountId: number, categoryInfoCreate: CategoryInfoCreate, options?: any) {
        return DefaultApiFp(this.configuration).createCategory(businessAccountId, categoryInfoCreate, options)(this.axios, this.basePath);
    }

    /**
     * Метод создаёт сущность в базе и записывает файл на диск
     * @summary Создание стандартного шаблона документа
     * @param {any} file 
     * @param {DocumentTemplateInfoCreate} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createCommonDocumentTemplate(file: any, data: DocumentTemplateInfoCreate, options?: any) {
        return DefaultApiFp(this.configuration).createCommonDocumentTemplate(file, data, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Создание контакта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {ContactInfoCreate} contactInfoCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createContact(businessAccountId: number, contactInfoCreate: ContactInfoCreate, options?: any) {
        return DefaultApiFp(this.configuration).createContact(businessAccountId, contactInfoCreate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Создание контакта с вложениями
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {ContactInfoCreate} [contactInfoCreate] 
     * @param {Array<any>} [attachmentInfoCreates] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createContactWithAttachments(businessAccountId: number, contactInfoCreate?: ContactInfoCreate, attachmentInfoCreates?: Array<any>, options?: any) {
        return DefaultApiFp(this.configuration).createContactWithAttachments(businessAccountId, contactInfoCreate, attachmentInfoCreates, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Создание новой операции корректировки
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {OperationInfoCreate} operationInfoCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createCorrectionOperation(businessAccountId: number, operationInfoCreate: OperationInfoCreate, options?: any) {
        return DefaultApiFp(this.configuration).createCorrectionOperation(businessAccountId, operationInfoCreate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Создание нового кадра
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {CrewMemberInfoCreate} crewMemberInfoCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createCrewMember(businessAccountId: number, crewMemberInfoCreate: CrewMemberInfoCreate, options?: any) {
        return DefaultApiFp(this.configuration).createCrewMember(businessAccountId, crewMemberInfoCreate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Создание контакта с вложениями
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {CrewMemberInfoCreate} [contactInfoCreate] 
     * @param {Array<any>} [attachmentInfoCreates] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createCrewMemberWithAttachments(businessAccountId: number, contactInfoCreate?: CrewMemberInfoCreate, attachmentInfoCreates?: Array<any>, options?: any) {
        return DefaultApiFp(this.configuration).createCrewMemberWithAttachments(businessAccountId, contactInfoCreate, attachmentInfoCreates, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Создание нового пользовательского поля
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {CustomFieldInfoCreate} customFieldInfoCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createCustomField(businessAccountId: number, customFieldInfoCreate: CustomFieldInfoCreate, options?: any) {
        return DefaultApiFp(this.configuration).createCustomField(businessAccountId, customFieldInfoCreate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Привязка настраиваемых полей к вариантам продукта (создание связей)
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {CustomFieldValueObjWriteList} customFieldValueObjWriteList 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createCustomFieldLinksToProductVariants(businessAccountId: number, id: number, customFieldValueObjWriteList: CustomFieldValueObjWriteList, options?: any) {
        return DefaultApiFp(this.configuration).createCustomFieldLinksToProductVariants(businessAccountId, id, customFieldValueObjWriteList, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Создание нового шаблона документа
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {any} file 
     * @param {DocumentTemplateInfoCreate} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createDocumentTemplate(businessAccountId: number, file: any, data: DocumentTemplateInfoCreate, options?: any) {
        return DefaultApiFp(this.configuration).createDocumentTemplate(businessAccountId, file, data, options)(this.axios, this.basePath);
    }

    /**
     * Создание сущности затраты по проекту
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {ExpenseInfoCreate} expenseInfoCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createExpense(businessAccountId: number, expenseInfoCreate: ExpenseInfoCreate, options?: any) {
        return DefaultApiFp(this.configuration).createExpense(businessAccountId, expenseInfoCreate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Создание нового шаблона для экспорта
     * @param {ExportTemplateInfoCreate} model 
     * @param {any} content 
     * @param {any} jasper 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createExportTemplate(model: ExportTemplateInfoCreate, content: any, jasper: any, options?: any) {
        return DefaultApiFp(this.configuration).createExportTemplate(model, content, jasper, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Создание нового изображения. Минимальная ширина и высота 200px, максимальный объем ограничен
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createImage(businessAccountId: number, file: any, options?: any) {
        return DefaultApiFp(this.configuration).createImage(businessAccountId, file, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Массовое создание экземпляров продукта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {boolean} setInventoryNumber Устанавливать дополнительно инвентарный номер
     * @param {boolean} setBarcode Устанавливать дополнительно штриховой код
     * @param {InstanceInfoCreateBulk} instanceInfoCreateBulk 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createInstancesBulk(businessAccountId: number, id: number, setInventoryNumber: boolean, setBarcode: boolean, instanceInfoCreateBulk: InstanceInfoCreateBulk, options?: any) {
        return DefaultApiFp(this.configuration).createInstancesBulk(businessAccountId, id, setInventoryNumber, setBarcode, instanceInfoCreateBulk, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Создание события движения инвентаря
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {InventoryMovementInfoCreate} inventoryMovementInfoCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createInventoryMovement(businessAccountId: number, inventoryMovementInfoCreate: InventoryMovementInfoCreate, options?: any) {
        return DefaultApiFp(this.configuration).createInventoryMovement(businessAccountId, inventoryMovementInfoCreate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Создание события движения инвентаря с файлами
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {InventoryMovementInfoCreate} [inventoryMovementInfoCreate] 
     * @param {Array<any>} [attachmentInfoCreates] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createInventoryMovementWithAttachments(businessAccountId: number, inventoryMovementInfoCreate?: InventoryMovementInfoCreate, attachmentInfoCreates?: Array<any>, options?: any) {
        return DefaultApiFp(this.configuration).createInventoryMovementWithAttachments(businessAccountId, inventoryMovementInfoCreate, attachmentInfoCreates, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Создание нового набора
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {KitInfoCreate} kitInfoCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createKit(businessAccountId: number, kitInfoCreate: KitInfoCreate, options?: any) {
        return DefaultApiFp(this.configuration).createKit(businessAccountId, kitInfoCreate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Создание нового объекта субъектов и реквизитов
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {LegalDetailsInfoWrite} legalDetailsInfoWrite 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createLegalDetails(businessAccountId: number, legalDetailsInfoWrite: LegalDetailsInfoWrite, options?: any) {
        return DefaultApiFp(this.configuration).createLegalDetails(businessAccountId, legalDetailsInfoWrite, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Создание новой локации
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {LocationInfoCreate} locationInfoCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createLocation(businessAccountId: number, locationInfoCreate: LocationInfoCreate, options?: any) {
        return DefaultApiFp(this.configuration).createLocation(businessAccountId, locationInfoCreate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {LocationInfoCreate} [contactInfoCreate] 
     * @param {Array<any>} [attachmentInfoCreates] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createLocationWithAttachments(businessAccountId: number, contactInfoCreate?: LocationInfoCreate, attachmentInfoCreates?: Array<any>, options?: any) {
        return DefaultApiFp(this.configuration).createLocationWithAttachments(businessAccountId, contactInfoCreate, attachmentInfoCreates, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Создание новой операции
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {OperationInfoCreate} operationInfoCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createOperation(businessAccountId: number, operationInfoCreate: OperationInfoCreate, options?: any) {
        return DefaultApiFp(this.configuration).createOperation(businessAccountId, operationInfoCreate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Создание заказа через витрину. Необходим JWT токен пользователя
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
     * @param {OrderInfoCreate} orderInfoCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createOrderByClient(businessAccountId: number, storefrontId: number, orderInfoCreate: OrderInfoCreate, options?: any) {
        return DefaultApiFp(this.configuration).createOrderByClient(businessAccountId, storefrontId, orderInfoCreate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Создание заказа через витрину
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {string} accessToken Обязательный токен доступа в формате UUID
     * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
     * @param {OrderInfoCreate} orderInfoCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createOrderByServer(businessAccountId: number, accessToken: string, storefrontId: number, orderInfoCreate: OrderInfoCreate, options?: any) {
        return DefaultApiFp(this.configuration).createOrderByServer(businessAccountId, accessToken, storefrontId, orderInfoCreate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Создание простого заказа
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {SimpleOrderInfoCreate} simpleOrderInfoCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createOrderWithRentElements(businessAccountId: number, simpleOrderInfoCreate: SimpleOrderInfoCreate, options?: any) {
        return DefaultApiFp(this.configuration).createOrderWithRentElements(businessAccountId, simpleOrderInfoCreate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Создание платежа
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {PaymentInfoCreate} paymentInfoCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createPayment(businessAccountId: number, paymentInfoCreate: PaymentInfoCreate, options?: any) {
        return DefaultApiFp(this.configuration).createPayment(businessAccountId, paymentInfoCreate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Создание нового способа оплаты
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {PaymentMethodInfoCreate} paymentMethodInfoCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createPaymentMethod(businessAccountId: number, paymentMethodInfoCreate: PaymentMethodInfoCreate, options?: any) {
        return DefaultApiFp(this.configuration).createPaymentMethod(businessAccountId, paymentMethodInfoCreate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Создание платежа
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {string} accessToken Обязательный токен доступа в формате UUID
     * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
     * @param {PublicPaymentInfoCreate} publicPaymentInfoCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createPayment_1(businessAccountId: number, accessToken: string, storefrontId: number, publicPaymentInfoCreate: PublicPaymentInfoCreate, options?: any) {
        return DefaultApiFp(this.configuration).createPayment_1(businessAccountId, accessToken, storefrontId, publicPaymentInfoCreate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Создание новой ценовой схемы
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {PricingSchemeInfoCreate} pricingSchemeInfoCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createPricingScheme(businessAccountId: number, pricingSchemeInfoCreate: PricingSchemeInfoCreate, options?: any) {
        return DefaultApiFp(this.configuration).createPricingScheme(businessAccountId, pricingSchemeInfoCreate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Создание нового продукта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {ProductInfoCreate} productInfoCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createProduct(businessAccountId: number, productInfoCreate: ProductInfoCreate, options?: any) {
        return DefaultApiFp(this.configuration).createProduct(businessAccountId, productInfoCreate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Создание нового экземпляра продукта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {InstanceInfoCreate} instanceInfoCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createProductInstance(businessAccountId: number, id: number, instanceInfoCreate: InstanceInfoCreate, options?: any) {
        return DefaultApiFp(this.configuration).createProductInstance(businessAccountId, id, instanceInfoCreate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Создание нового варианта продукта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {VariantInfoCreate} variantInfoCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createProductVariant(businessAccountId: number, id: number, variantInfoCreate: VariantInfoCreate, options?: any) {
        return DefaultApiFp(this.configuration).createProductVariant(businessAccountId, id, variantInfoCreate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Создание нового экземпляра варианта продукта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} productId Идентификатор продукта
     * @param {number} id Идентификатор сущности
     * @param {InstanceInfoCreate} instanceInfoCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createProductVariantInstance(businessAccountId: number, productId: number, id: number, instanceInfoCreate: InstanceInfoCreate, options?: any) {
        return DefaultApiFp(this.configuration).createProductVariantInstance(businessAccountId, productId, id, instanceInfoCreate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Создание профессии
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {ProfessionInfoCreate} professionInfoCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createProfession(businessAccountId: number, professionInfoCreate: ProfessionInfoCreate, options?: any) {
        return DefaultApiFp(this.configuration).createProfession(businessAccountId, professionInfoCreate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Создание нового проекта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {ProjectInfoCreate} projectInfoCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createProject(businessAccountId: number, projectInfoCreate: ProjectInfoCreate, options?: any) {
        return DefaultApiFp(this.configuration).createProject(businessAccountId, projectInfoCreate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Создание нового арендатора
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {RenterInfoCreate} renterInfoCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createRenter(businessAccountId: number, renterInfoCreate: RenterInfoCreate, options?: any) {
        return DefaultApiFp(this.configuration).createRenter(businessAccountId, renterInfoCreate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Создание графической визуализации отчета
     * @param {number} reportId 
     * @param {ReportChartVisualizationInfoCreate} [reportChartVisualizationInfoCreate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createReportChartVisualization(reportId: number, reportChartVisualizationInfoCreate?: ReportChartVisualizationInfoCreate, options?: any) {
        return DefaultApiFp(this.configuration).createReportChartVisualization(reportId, reportChartVisualizationInfoCreate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Создание новой конфигурации отчёта
     * @param {ReportConfigurationInfoCreate} model 
     * @param {any} content 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createReportConfiguration(model: ReportConfigurationInfoCreate, content: any, options?: any) {
        return DefaultApiFp(this.configuration).createReportConfiguration(model, content, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Создание новой Эксель визуализации отчета
     * @param {number} reportId 
     * @param {ReportExcelVisualizationInfoCreate} model 
     * @param {any} content 
     * @param {any} jasper 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createReportExcelVisualization(reportId: number, model: ReportExcelVisualizationInfoCreate, content: any, jasper: any, options?: any) {
        return DefaultApiFp(this.configuration).createReportExcelVisualization(reportId, model, content, jasper, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Создание новой субаренды
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {SubrentInfoCreate} subrentInfoCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createSubrent(businessAccountId: number, subrentInfoCreate: SubrentInfoCreate, options?: any) {
        return DefaultApiFp(this.configuration).createSubrent(businessAccountId, subrentInfoCreate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Создание нового шаблона
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {TemplateInfoCreate} templateInfoCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createTemplate(businessAccountId: number, templateInfoCreate: TemplateInfoCreate, options?: any) {
        return DefaultApiFp(this.configuration).createTemplate(businessAccountId, templateInfoCreate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Создание новой перевозки
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {TransportationInfoCreate} transportationInfoCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createTransportation(businessAccountId: number, transportationInfoCreate: TransportationInfoCreate, options?: any) {
        return DefaultApiFp(this.configuration).createTransportation(businessAccountId, transportationInfoCreate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {TransportationInfoCreate} [contactInfoCreate] 
     * @param {Array<any>} [attachmentInfoCreates] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createTransportationWithAttachments(businessAccountId: number, contactInfoCreate?: TransportationInfoCreate, attachmentInfoCreates?: Array<any>, options?: any) {
        return DefaultApiFp(this.configuration).createTransportationWithAttachments(businessAccountId, contactInfoCreate, attachmentInfoCreates, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Создание новой транспортного средства
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {VehicleInfoCreate} vehicleInfoCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createVehicle(businessAccountId: number, vehicleInfoCreate: VehicleInfoCreate, options?: any) {
        return DefaultApiFp(this.configuration).createVehicle(businessAccountId, vehicleInfoCreate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {VehicleInfoCreate} [contactInfoCreate] 
     * @param {Array<any>} [attachmentInfoCreates] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createVehicleWithAttachments(businessAccountId: number, contactInfoCreate?: VehicleInfoCreate, attachmentInfoCreates?: Array<any>, options?: any) {
        return DefaultApiFp(this.configuration).createVehicleWithAttachments(businessAccountId, contactInfoCreate, attachmentInfoCreates, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Запланировать/Создать описание работы
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {WorkPlanningsInfoCreate} workPlanningsInfoCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createWorkPlanning(businessAccountId: number, workPlanningsInfoCreate: WorkPlanningsInfoCreate, options?: any) {
        return DefaultApiFp(this.configuration).createWorkPlanning(businessAccountId, workPlanningsInfoCreate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Удаление вложения
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {BusinessVersionObj} businessVersionObj 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteAttachmentById(businessAccountId: number, id: number, businessVersionObj: BusinessVersionObj, options?: any) {
        return DefaultApiFp(this.configuration).deleteAttachmentById(businessAccountId, id, businessVersionObj, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Удаление бизнес аккаунта (только для тех, которые на триальном тарифе)
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {string} checkString Проверочная строка, краткое наименование бизнес аккаунта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteBusinessAccountById(businessAccountId: number, checkString: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteBusinessAccountById(businessAccountId, checkString, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Удаление категории
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {boolean} deleteSubcategories Удалить подкатегории
     * @param {boolean} deleteEntityLinks Удалить связи с продуктами (если false - перенести связи на родительскую категорию, связи подкатегорий c продуктами обрабатываются аналогично связям текущей)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteCategoryById(businessAccountId: number, id: number, deleteSubcategories: boolean, deleteEntityLinks: boolean, options?: any) {
        return DefaultApiFp(this.configuration).deleteCategoryById(businessAccountId, id, deleteSubcategories, deleteEntityLinks, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Удаление стандартного шаблона документа по id
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteCommonDocumentTemplate(id: number, options?: any) {
        return DefaultApiFp(this.configuration).deleteCommonDocumentTemplate(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Удаление контакта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {BusinessVersionObj} businessVersionObj 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteContactById(businessAccountId: number, id: number, businessVersionObj: BusinessVersionObj, options?: any) {
        return DefaultApiFp(this.configuration).deleteContactById(businessAccountId, id, businessVersionObj, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Удаление контактов
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {VersionedEntityObjList} versionedEntityObjList 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteContacts(businessAccountId: number, versionedEntityObjList: VersionedEntityObjList, options?: any) {
        return DefaultApiFp(this.configuration).deleteContacts(businessAccountId, versionedEntityObjList, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Удаление кадра
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {BusinessVersionObj} businessVersionObj 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteCrewMember(businessAccountId: number, id: number, businessVersionObj: BusinessVersionObj, options?: any) {
        return DefaultApiFp(this.configuration).deleteCrewMember(businessAccountId, id, businessVersionObj, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Удаление ставок кадра
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteCrewMemberRates(businessAccountId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).deleteCrewMemberRates(businessAccountId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Массовое удаление сотрудников
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {VersionedEntityObjList} versionedEntityObjList 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteCrewMembersBulk(businessAccountId: number, versionedEntityObjList: VersionedEntityObjList, options?: any) {
        return DefaultApiFp(this.configuration).deleteCrewMembersBulk(businessAccountId, versionedEntityObjList, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Удаление пользовательского поля
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteCustomFieldById(businessAccountId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).deleteCustomFieldById(businessAccountId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Отвязка настраиваемого поля от вариантов продукта (удаление связей)
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} productId Идентификатор продукта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteCustomFieldLinksToProductVariants(businessAccountId: number, productId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).deleteCustomFieldLinksToProductVariants(businessAccountId, productId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Удаление шаблона документа по id
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteDocumentTemplate(businessAccountId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).deleteDocumentTemplate(businessAccountId, id, options)(this.axios, this.basePath);
    }

    /**
     * Удаление сущности затраты по проекту
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteExpense(businessAccountId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).deleteExpense(businessAccountId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Удаление шаблона для экспорта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteExportTemplate(id: number, options?: any) {
        return DefaultApiFp(this.configuration).deleteExportTemplate(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Удаление изображения
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteImageById(businessAccountId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).deleteImageById(businessAccountId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Удаление набора
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {BusinessVersionObj} businessVersionObj 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteKitById(businessAccountId: number, id: number, businessVersionObj: BusinessVersionObj, options?: any) {
        return DefaultApiFp(this.configuration).deleteKitById(businessAccountId, id, businessVersionObj, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Массовое удаление наборов
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {VersionedEntityObjList} versionedEntityObjList 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteKitsBulk(businessAccountId: number, versionedEntityObjList: VersionedEntityObjList, options?: any) {
        return DefaultApiFp(this.configuration).deleteKitsBulk(businessAccountId, versionedEntityObjList, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Удаление объекта субъектов и реквизитов
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {BusinessVersionObj} businessVersionObj 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteLegalDetailById(businessAccountId: number, id: number, businessVersionObj: BusinessVersionObj, options?: any) {
        return DefaultApiFp(this.configuration).deleteLegalDetailById(businessAccountId, id, businessVersionObj, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Удаление локации
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {BusinessVersionObj} businessVersionObj 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteLocationById(businessAccountId: number, id: number, businessVersionObj: BusinessVersionObj, options?: any) {
        return DefaultApiFp(this.configuration).deleteLocationById(businessAccountId, id, businessVersionObj, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Удаление локаций
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {VersionedEntityObjList} versionedEntityObjList 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteLocations(businessAccountId: number, versionedEntityObjList: VersionedEntityObjList, options?: any) {
        return DefaultApiFp(this.configuration).deleteLocations(businessAccountId, versionedEntityObjList, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Массовое удаление номенклатуры
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {NomenclatureEntityList} nomenclatureEntityList 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteNomenclatureBulk(businessAccountId: number, nomenclatureEntityList: NomenclatureEntityList, options?: any) {
        return DefaultApiFp(this.configuration).deleteNomenclatureBulk(businessAccountId, nomenclatureEntityList, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Удаление способа оплаты
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {BusinessVersionObj} businessVersionObj 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deletePaymentMethodById(businessAccountId: number, id: number, businessVersionObj: BusinessVersionObj, options?: any) {
        return DefaultApiFp(this.configuration).deletePaymentMethodById(businessAccountId, id, businessVersionObj, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Массовое удаление способов оплаты
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {VersionedEntityObjList} versionedEntityObjList 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deletePaymentMethodsBulk(businessAccountId: number, versionedEntityObjList: VersionedEntityObjList, options?: any) {
        return DefaultApiFp(this.configuration).deletePaymentMethodsBulk(businessAccountId, versionedEntityObjList, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Удаление ценовой схемы
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {BusinessVersionObj} businessVersionObj 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deletePricingSchemeById(businessAccountId: number, id: number, businessVersionObj: BusinessVersionObj, options?: any) {
        return DefaultApiFp(this.configuration).deletePricingSchemeById(businessAccountId, id, businessVersionObj, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Удаление продукта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {BusinessVersionObj} businessVersionObj 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteProductById(businessAccountId: number, id: number, businessVersionObj: BusinessVersionObj, options?: any) {
        return DefaultApiFp(this.configuration).deleteProductById(businessAccountId, id, businessVersionObj, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Удаление экземпляра
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} productId Идентификатор продукта
     * @param {number} id Идентификатор сущности
     * @param {BusinessVersionObj} businessVersionObj 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteProductInstanceById(businessAccountId: number, productId: number, id: number, businessVersionObj: BusinessVersionObj, options?: any) {
        return DefaultApiFp(this.configuration).deleteProductInstanceById(businessAccountId, productId, id, businessVersionObj, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Массовое удаление экземпляров продукта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {VersionedEntityObjList} versionedEntityObjList 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteProductInstancesBulk(businessAccountId: number, id: number, versionedEntityObjList: VersionedEntityObjList, options?: any) {
        return DefaultApiFp(this.configuration).deleteProductInstancesBulk(businessAccountId, id, versionedEntityObjList, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Удаление варианта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} productId Идентификатор продукта
     * @param {number} id Идентификатор сущности
     * @param {BusinessVersionObj} businessVersionObj 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteProductVariantById(businessAccountId: number, productId: number, id: number, businessVersionObj: BusinessVersionObj, options?: any) {
        return DefaultApiFp(this.configuration).deleteProductVariantById(businessAccountId, productId, id, businessVersionObj, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Массовое удаление вариантов продукта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {VersionedEntityObjList} versionedEntityObjList 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteProductVariantsBulk(businessAccountId: number, id: number, versionedEntityObjList: VersionedEntityObjList, options?: any) {
        return DefaultApiFp(this.configuration).deleteProductVariantsBulk(businessAccountId, id, versionedEntityObjList, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Массовое удаление продуктов
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {VersionedEntityObjList} versionedEntityObjList 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteProductsBulk(businessAccountId: number, versionedEntityObjList: VersionedEntityObjList, options?: any) {
        return DefaultApiFp(this.configuration).deleteProductsBulk(businessAccountId, versionedEntityObjList, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Удаление профессии
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {BusinessVersionObj} businessVersionObj 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteProfessionById(businessAccountId: number, id: number, businessVersionObj: BusinessVersionObj, options?: any) {
        return DefaultApiFp(this.configuration).deleteProfessionById(businessAccountId, id, businessVersionObj, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Массовое удаление профессий
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {VersionedEntityObjList} versionedEntityObjList 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteProfessionsBulk(businessAccountId: number, versionedEntityObjList: VersionedEntityObjList, options?: any) {
        return DefaultApiFp(this.configuration).deleteProfessionsBulk(businessAccountId, versionedEntityObjList, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Удаление графической визуализации отчета
     * @param {number} reportId 
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteReportChartVisualization(reportId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).deleteReportChartVisualization(reportId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Удаление конфигурации отчёта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteReportConfiguration(id: number, options?: any) {
        return DefaultApiFp(this.configuration).deleteReportConfiguration(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Удаление Эксель визуализации отчета
     * @param {number} reportId 
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteReportExcelVisualization(reportId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).deleteReportExcelVisualization(reportId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Удаление организации в системе SSO авторизации и отвязка от бизнес аккаунта
     * @param {string} accessToken Обязательный токен доступа в формате UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteSsoClientOrganization(accessToken: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteSsoClientOrganization(accessToken, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Удаление перевозки
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {BusinessVersionObj} businessVersionObj 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteTransportationById(businessAccountId: number, id: number, businessVersionObj: BusinessVersionObj, options?: any) {
        return DefaultApiFp(this.configuration).deleteTransportationById(businessAccountId, id, businessVersionObj, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Удаление перевозок
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {VersionedEntityObjList} versionedEntityObjList 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteTransportations(businessAccountId: number, versionedEntityObjList: VersionedEntityObjList, options?: any) {
        return DefaultApiFp(this.configuration).deleteTransportations(businessAccountId, versionedEntityObjList, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Удаление транспортного средства
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {BusinessVersionObj} businessVersionObj 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteVehicleById(businessAccountId: number, id: number, businessVersionObj: BusinessVersionObj, options?: any) {
        return DefaultApiFp(this.configuration).deleteVehicleById(businessAccountId, id, businessVersionObj, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Удаление локаций
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {VersionedEntityObjList} versionedEntityObjList 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteVehicles(businessAccountId: number, versionedEntityObjList: VersionedEntityObjList, options?: any) {
        return DefaultApiFp(this.configuration).deleteVehicles(businessAccountId, versionedEntityObjList, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Копирование бизнес аккаунта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public duplicateBusinessAccountById(businessAccountId: number, options?: any) {
        return DefaultApiFp(this.configuration).duplicateBusinessAccountById(businessAccountId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Запрос на подключение или отключение модуля в бизнес аккаунте
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {BusinessAccountModuleEnableOrDisableObj} businessAccountModuleEnableOrDisableObj 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public enableOrDisableBusinessAccountModule(businessAccountId: number, businessAccountModuleEnableOrDisableObj: BusinessAccountModuleEnableOrDisableObj, options?: any) {
        return DefaultApiFp(this.configuration).enableOrDisableBusinessAccountModule(businessAccountId, businessAccountModuleEnableOrDisableObj, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Запрос на подключение или отключение модуля в бизнес аккаунте
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {BusinessAccountModuleEnableOrDisableObjList} businessAccountModuleEnableOrDisableObjList 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public enableOrDisableBusinessAccountModuleBulk(businessAccountId: number, businessAccountModuleEnableOrDisableObjList: BusinessAccountModuleEnableOrDisableObjList, options?: any) {
        return DefaultApiFp(this.configuration).enableOrDisableBusinessAccountModuleBulk(businessAccountId, businessAccountModuleEnableOrDisableObjList, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Экспорт списка обязательств бизнес аккаунта. При limit = null ограничение до 1000
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {Array<string>} columns Колонки учавстующие в экспорте. Передавать [\&#39;ALL\&#39;] чтобы выгрузить все доступные
     * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
     * @param {number} timezoneOffsetUTC Временная зона
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {Date} [specialFromStartDateIncludingDelays] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public exportBusinessAccountElementsList(businessAccountId: number, columns: Array<string>, expandKits: boolean, timezoneOffsetUTC: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, specialFromStartDateIncludingDelays?: Date, options?: any) {
        return DefaultApiFp(this.configuration).exportBusinessAccountElementsList(businessAccountId, columns, expandKits, timezoneOffsetUTC, limit, startFrom, filters, sortBy, sortOrder, search, specialFromStartDateIncludingDelays, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение календаря событий бизнес аккаунта
     * @param {string} accessToken Access token. UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public exportCalendar(accessToken: string, options?: any) {
        return DefaultApiFp(this.configuration).exportCalendar(accessToken, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Экспорт списка обязательств операции. При limit = null ограничение до 1000
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {Array<string>} columns Колонки учавстующие в экспорте. Передавать [\&#39;ALL\&#39;] чтобы выгрузить все доступные
     * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
     * @param {number} timezoneOffsetUTC Временная зона
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public exportOperationElementsList(businessAccountId: number, id: number, columns: Array<string>, expandKits: boolean, timezoneOffsetUTC: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any) {
        return DefaultApiFp(this.configuration).exportOperationElementsList(businessAccountId, id, columns, expandKits, timezoneOffsetUTC, limit, startFrom, filters, sortBy, sortOrder, search, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Экспорт списка обязательств проекта. При limit = null ограничение до 1000
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} projectId Идентификатор проекта
     * @param {Array<string>} columns Колонки учавстующие в экспорте. Передавать [\&#39;ALL\&#39;] чтобы выгрузить все доступные
     * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
     * @param {number} timezoneOffsetUTC Временная зона
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public exportProjectElementsList(businessAccountId: number, projectId: number, columns: Array<string>, expandKits: boolean, timezoneOffsetUTC: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any) {
        return DefaultApiFp(this.configuration).exportProjectElementsList(businessAccountId, projectId, columns, expandKits, timezoneOffsetUTC, limit, startFrom, filters, sortBy, sortOrder, search, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Экспорт списка обязательств поставки. При limit = null ограничение до 1000
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} subrentId Идентификатор поставки
     * @param {Array<string>} columns Колонки учавстующие в экспорте. Передавать [\&#39;ALL\&#39;] чтобы выгрузить все доступные
     * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
     * @param {number} timezoneOffsetUTC Временная зона
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public exportSubrentElementsList(businessAccountId: number, subrentId: number, columns: Array<string>, expandKits: boolean, timezoneOffsetUTC: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any) {
        return DefaultApiFp(this.configuration).exportSubrentElementsList(businessAccountId, subrentId, columns, expandKits, timezoneOffsetUTC, limit, startFrom, filters, sortBy, sortOrder, search, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Экспорт списка обязательств шаблона. При limit = null ограничение до 1000
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} templateId Идентификатор шаблона
     * @param {Array<string>} columns Колонки учавстующие в экспорте. Передавать [\&#39;ALL\&#39;] чтобы выгрузить все доступные
     * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
     * @param {number} timezoneOffsetUTC Временная зона
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public exportTemplateElementsList(businessAccountId: number, templateId: number, columns: Array<string>, expandKits: boolean, timezoneOffsetUTC: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any) {
        return DefaultApiFp(this.configuration).exportTemplateElementsList(businessAccountId, templateId, columns, expandKits, timezoneOffsetUTC, limit, startFrom, filters, sortBy, sortOrder, search, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Переиндексировать все сущности компании с удалением лишних
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fullReindexBusinessAccount(businessAccountId: number, options?: any) {
        return DefaultApiFp(this.configuration).fullReindexBusinessAccount(businessAccountId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение файла бейджа
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {string} nomenclatureType Тип номенклатуры для печати
     * @param {number} nomenclatureId Id сущности
     * @param {number} timezoneOffsetUTC Временная зона
     * @param {boolean} [common] Флаг типа шаблона
     * @param {number} [documentTemplateId] Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public generateBadgePdf(businessAccountId: number, nomenclatureType: string, nomenclatureId: number, timezoneOffsetUTC: number, common?: boolean, documentTemplateId?: number, options?: any) {
        return DefaultApiFp(this.configuration).generateBadgePdf(businessAccountId, nomenclatureType, nomenclatureId, timezoneOffsetUTC, common, documentTemplateId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение изображения штрихового кода
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {string} barcodeString Штриховой код
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public generateBarcodeImage(businessAccountId: number, barcodeString: string, options?: any) {
        return DefaultApiFp(this.configuration).generateBarcodeImage(businessAccountId, barcodeString, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Генерация документа транспортной накладной в формат DOCX
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {boolean} common Флаг типа шаблона
     * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
     * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public generateBillOfLadingDocx(businessAccountId: number, id: number, common: boolean, documentTemplateId: number, businessAccountLegalDetailsId?: number, options?: any) {
        return DefaultApiFp(this.configuration).generateBillOfLadingDocx(businessAccountId, id, common, documentTemplateId, businessAccountLegalDetailsId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Генерация документа транспортной накладной в формат PDF
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {boolean} common Флаг типа шаблона
     * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
     * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public generateBillOfLadingPdf(businessAccountId: number, id: number, common: boolean, documentTemplateId: number, businessAccountLegalDetailsId?: number, options?: any) {
        return DefaultApiFp(this.configuration).generateBillOfLadingPdf(businessAccountId, id, common, documentTemplateId, businessAccountLegalDetailsId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Генерация документа счета в формат PDF
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {boolean} common Флаг типа шаблона
     * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
     * @param {number} timezoneOffsetUTC Временная зона
     * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public generateBillPdf(businessAccountId: number, id: number, common: boolean, documentTemplateId: number, timezoneOffsetUTC: number, businessAccountLegalDetailsId?: number, options?: any) {
        return DefaultApiFp(this.configuration).generateBillPdf(businessAccountId, id, common, documentTemplateId, timezoneOffsetUTC, businessAccountLegalDetailsId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Запрос на генерацию нового токена доступа к апи календаря
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public generateCalendarAccessToken(businessAccountId: number, options?: any) {
        return DefaultApiFp(this.configuration).generateCalendarAccessToken(businessAccountId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Генерация документа договора по проекту в формат DOCX
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} projectId Идентификатор проекта
     * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
     * @param {boolean} common Флаг типа шаблона
     * @param {number} timezoneOffsetUTC Временная зона
     * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public generateContractDocumentDocx(businessAccountId: number, projectId: number, documentTemplateId: number, common: boolean, timezoneOffsetUTC: number, businessAccountLegalDetailsId?: number, options?: any) {
        return DefaultApiFp(this.configuration).generateContractDocumentDocx(businessAccountId, projectId, documentTemplateId, common, timezoneOffsetUTC, businessAccountLegalDetailsId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Генерация документа договора по проекту в формат PDF
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} projectId Идентификатор проекта
     * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
     * @param {boolean} common Флаг типа шаблона
     * @param {number} timezoneOffsetUTC Временная зона
     * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public generateContractDocumentPdf(businessAccountId: number, projectId: number, documentTemplateId: number, common: boolean, timezoneOffsetUTC: number, businessAccountLegalDetailsId?: number, options?: any) {
        return DefaultApiFp(this.configuration).generateContractDocumentPdf(businessAccountId, projectId, documentTemplateId, common, timezoneOffsetUTC, businessAccountLegalDetailsId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Генерация представления отчета в формат PDF
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {ExcelExportGenerationInfo} excelExportGenerationInfo 
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public generateExportPdf(businessAccountId: number, excelExportGenerationInfo: ExcelExportGenerationInfo, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any) {
        return DefaultApiFp(this.configuration).generateExportPdf(businessAccountId, excelExportGenerationInfo, filters, sortBy, sortOrder, search, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Генерация представления отчета в формат XLSX
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {ExcelExportGenerationInfo} excelExportGenerationInfo 
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public generateExportXlsx(businessAccountId: number, excelExportGenerationInfo: ExcelExportGenerationInfo, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any) {
        return DefaultApiFp(this.configuration).generateExportXlsx(businessAccountId, excelExportGenerationInfo, filters, sortBy, sortOrder, search, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Генерация документа прайс листа в формат PDF
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {boolean} common Флаг типа шаблона
     * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
     * @param {number} appliedDiscount Скидка
     * @param {boolean} showDiscount Показывать ли скидку явно
     * @param {boolean} showPricingSchemes Показывать ли ценовые схемы
     * @param {boolean} showDescription Показывать ли описание
     * @param {boolean} showCount Показывать ли общее количество
     * @param {boolean} groupByCategories Группировать по категориям
     * @param {number} timezoneOffsetUTC Временная зона
     * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public generateNomenclaturePricelistPdf(businessAccountId: number, common: boolean, documentTemplateId: number, appliedDiscount: number, showDiscount: boolean, showPricingSchemes: boolean, showDescription: boolean, showCount: boolean, groupByCategories: boolean, timezoneOffsetUTC: number, businessAccountLegalDetailsId?: number, filters?: Array<string>, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any) {
        return DefaultApiFp(this.configuration).generateNomenclaturePricelistPdf(businessAccountId, common, documentTemplateId, appliedDiscount, showDiscount, showPricingSchemes, showDescription, showCount, groupByCategories, timezoneOffsetUTC, businessAccountLegalDetailsId, filters, limit, startFrom, sortBy, sortOrder, search, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Генерация документа прайс листа в формат XLSX
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {boolean} common Флаг типа шаблона
     * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
     * @param {number} appliedDiscount Скидка
     * @param {boolean} showDiscount Показывать ли скидку явно
     * @param {boolean} showPricingSchemes Показывать ли ценовые схемы
     * @param {boolean} showDescription Показывать ли описание
     * @param {boolean} showCount Показывать ли общее количество
     * @param {boolean} groupByCategories Группировать по категориям
     * @param {number} timezoneOffsetUTC Временная зона
     * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public generateNomenclaturePricelistXlsx(businessAccountId: number, common: boolean, documentTemplateId: number, appliedDiscount: number, showDiscount: boolean, showPricingSchemes: boolean, showDescription: boolean, showCount: boolean, groupByCategories: boolean, timezoneOffsetUTC: number, businessAccountLegalDetailsId?: number, filters?: Array<string>, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any) {
        return DefaultApiFp(this.configuration).generateNomenclaturePricelistXlsx(businessAccountId, common, documentTemplateId, appliedDiscount, showDiscount, showPricingSchemes, showDescription, showCount, groupByCategories, timezoneOffsetUTC, businessAccountLegalDetailsId, filters, limit, startFrom, sortBy, sortOrder, search, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Генерация акта по операции в формат DOCX
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} operationId Идентификатор операции
     * @param {boolean} common Флаг типа шаблона
     * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
     * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
     * @param {boolean} joinSimilarRows Объединять ли одинаковые строки при экспорте (для развернутых наборов не работает)
     * @param {boolean} groupByCategories Группировать по категориям
     * @param {number} timezoneOffsetUTC Временная зона
     * @param {CategorySortOrderEnum} [categorySortBy] 
     * @param {'ASC' | 'DESC'} [categorySortOrder] 
     * @param {Date} [t0] 
     * @param {Date} [tN] 
     * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public generateOperationActDocumentDocx(businessAccountId: number, operationId: number, common: boolean, documentTemplateId: number, expandKits: boolean, joinSimilarRows: boolean, groupByCategories: boolean, timezoneOffsetUTC: number, categorySortBy?: CategorySortOrderEnum, categorySortOrder?: 'ASC' | 'DESC', t0?: Date, tN?: Date, businessAccountLegalDetailsId?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any) {
        return DefaultApiFp(this.configuration).generateOperationActDocumentDocx(businessAccountId, operationId, common, documentTemplateId, expandKits, joinSimilarRows, groupByCategories, timezoneOffsetUTC, categorySortBy, categorySortOrder, t0, tN, businessAccountLegalDetailsId, filters, sortBy, sortOrder, search, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Генерация акта по операции в формат PDF
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} operationId Идентификатор операции
     * @param {boolean} common Флаг типа шаблона
     * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
     * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
     * @param {boolean} joinSimilarRows Объединять ли одинаковые строки при экспорте (для развернутых наборов не работает)
     * @param {boolean} groupByCategories Группировать по категориям
     * @param {number} timezoneOffsetUTC Временная зона
     * @param {CategorySortOrderEnum} [categorySortBy] 
     * @param {'ASC' | 'DESC'} [categorySortOrder] 
     * @param {Date} [t0] 
     * @param {Date} [tN] 
     * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public generateOperationActDocumentPdf(businessAccountId: number, operationId: number, common: boolean, documentTemplateId: number, expandKits: boolean, joinSimilarRows: boolean, groupByCategories: boolean, timezoneOffsetUTC: number, categorySortBy?: CategorySortOrderEnum, categorySortOrder?: 'ASC' | 'DESC', t0?: Date, tN?: Date, businessAccountLegalDetailsId?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any) {
        return DefaultApiFp(this.configuration).generateOperationActDocumentPdf(businessAccountId, operationId, common, documentTemplateId, expandKits, joinSimilarRows, groupByCategories, timezoneOffsetUTC, categorySortBy, categorySortOrder, t0, tN, businessAccountLegalDetailsId, filters, sortBy, sortOrder, search, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Генерация документа сметы операции в формат PDF
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} operationId Идентификатор операции
     * @param {boolean} common Флаг типа шаблона
     * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
     * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
     * @param {boolean} joinSimilarRows Объединять ли одинаковые строки при экспорте (для развернутых наборов не работает)
     * @param {number} timezoneOffsetUTC Временная зона
     * @param {CategorySortOrderEnum} [categorySortBy] 
     * @param {'ASC' | 'DESC'} [categorySortOrder] 
     * @param {Date} [t0] 
     * @param {Date} [tN] 
     * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public generateOperationEstimateDocumentPdf(businessAccountId: number, operationId: number, common: boolean, documentTemplateId: number, expandKits: boolean, joinSimilarRows: boolean, timezoneOffsetUTC: number, categorySortBy?: CategorySortOrderEnum, categorySortOrder?: 'ASC' | 'DESC', t0?: Date, tN?: Date, businessAccountLegalDetailsId?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any) {
        return DefaultApiFp(this.configuration).generateOperationEstimateDocumentPdf(businessAccountId, operationId, common, documentTemplateId, expandKits, joinSimilarRows, timezoneOffsetUTC, categorySortBy, categorySortOrder, t0, tN, businessAccountLegalDetailsId, filters, sortBy, sortOrder, search, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Генерация документа сметы операции в формат XLSX
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} operationId Идентификатор операции
     * @param {boolean} common Флаг типа шаблона
     * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
     * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
     * @param {boolean} joinSimilarRows Объединять ли одинаковые строки при экспорте (для развернутых наборов не работает)
     * @param {number} timezoneOffsetUTC Временная зона
     * @param {CategorySortOrderEnum} [categorySortBy] 
     * @param {'ASC' | 'DESC'} [categorySortOrder] 
     * @param {Date} [t0] 
     * @param {Date} [tN] 
     * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public generateOperationEstimateDocumentXlsx(businessAccountId: number, operationId: number, common: boolean, documentTemplateId: number, expandKits: boolean, joinSimilarRows: boolean, timezoneOffsetUTC: number, categorySortBy?: CategorySortOrderEnum, categorySortOrder?: 'ASC' | 'DESC', t0?: Date, tN?: Date, businessAccountLegalDetailsId?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any) {
        return DefaultApiFp(this.configuration).generateOperationEstimateDocumentXlsx(businessAccountId, operationId, common, documentTemplateId, expandKits, joinSimilarRows, timezoneOffsetUTC, categorySortBy, categorySortOrder, t0, tN, businessAccountLegalDetailsId, filters, sortBy, sortOrder, search, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Генерация документа списка инвентаря в формат PDF
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {boolean} common Флаг типа шаблона
     * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
     * @param {boolean} showBarcode Показывать ли штриховой код
     * @param {boolean} expandOwnNamedInstances Показывать ли свои именованные экземпляры при экспорте
     * @param {boolean} expandSubrentedNamedInstances Включать ли чужие экземпляры при экспорте
     * @param {boolean} showPurchaseCost Показывать ли закупочную стоимость при экспорте
     * @param {boolean} showCarryingValue Показывать ли балансовую стоимость при экспорте
     * @param {boolean} expandInstanceStateDescription Показывать ли описания состояний экземпляров при экспорте
     * @param {boolean} showColumnActualAvailability Показывать ли колонку \&#39;Фактическое наличие\&#39; при экспорте
     * @param {boolean} showColumnConvergence Показывать ли колонку \&#39;Схождение\&#39; при экспорте
     * @param {boolean} showColumnNotes Показывать ли колонку \&#39;Для пометок\&#39; при экспорте
     * @param {boolean} showSupplier Показывать ли колонку \&#39;Поставщик\&#39; при экспорте
     * @param {boolean} groupByCategories Группировать по категориям
     * @param {number} timezoneOffsetUTC Временная зона
     * @param {CategorySortOrderEnum} [categorySortBy] 
     * @param {'ASC' | 'DESC'} [categorySortOrder] 
     * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public generateProductsInventoryListDocumentPdf(businessAccountId: number, common: boolean, documentTemplateId: number, showBarcode: boolean, expandOwnNamedInstances: boolean, expandSubrentedNamedInstances: boolean, showPurchaseCost: boolean, showCarryingValue: boolean, expandInstanceStateDescription: boolean, showColumnActualAvailability: boolean, showColumnConvergence: boolean, showColumnNotes: boolean, showSupplier: boolean, groupByCategories: boolean, timezoneOffsetUTC: number, categorySortBy?: CategorySortOrderEnum, categorySortOrder?: 'ASC' | 'DESC', businessAccountLegalDetailsId?: number, filters?: Array<string>, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any) {
        return DefaultApiFp(this.configuration).generateProductsInventoryListDocumentPdf(businessAccountId, common, documentTemplateId, showBarcode, expandOwnNamedInstances, expandSubrentedNamedInstances, showPurchaseCost, showCarryingValue, expandInstanceStateDescription, showColumnActualAvailability, showColumnConvergence, showColumnNotes, showSupplier, groupByCategories, timezoneOffsetUTC, categorySortBy, categorySortOrder, businessAccountLegalDetailsId, filters, limit, startFrom, sortBy, sortOrder, search, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Генерация документа списка инвентаря в формат XLSX
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {boolean} common Флаг типа шаблона
     * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
     * @param {boolean} showBarcode Показывать ли штриховой код
     * @param {boolean} expandOwnNamedInstances Показывать ли свои именованные экземпляры при экспорте
     * @param {boolean} expandSubrentedNamedInstances Включать ли чужие экземпляры при экспорте
     * @param {boolean} showPurchaseCost Показывать ли закупочную стоимость при экспорте
     * @param {boolean} showCarryingValue Показывать ли балансовую стоимость при экспорте
     * @param {boolean} expandInstanceStateDescription Показывать ли описания состояний экземпляров при экспорте
     * @param {boolean} showColumnActualAvailability Показывать ли колонку \&#39;Фактическое наличие\&#39; при экспорте
     * @param {boolean} showColumnConvergence Показывать ли колонку \&#39;Схождение\&#39; при экспорте
     * @param {boolean} showColumnNotes Показывать ли колонку \&#39;Для пометок\&#39; при экспорте
     * @param {boolean} showSupplier Показывать ли колонку \&#39;Поставщик\&#39; при экспорте
     * @param {boolean} groupByCategories Группировать по категориям
     * @param {number} timezoneOffsetUTC Временная зона
     * @param {CategorySortOrderEnum} [categorySortBy] 
     * @param {'ASC' | 'DESC'} [categorySortOrder] 
     * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public generateProductsInventoryListDocumentXlsx(businessAccountId: number, common: boolean, documentTemplateId: number, showBarcode: boolean, expandOwnNamedInstances: boolean, expandSubrentedNamedInstances: boolean, showPurchaseCost: boolean, showCarryingValue: boolean, expandInstanceStateDescription: boolean, showColumnActualAvailability: boolean, showColumnConvergence: boolean, showColumnNotes: boolean, showSupplier: boolean, groupByCategories: boolean, timezoneOffsetUTC: number, categorySortBy?: CategorySortOrderEnum, categorySortOrder?: 'ASC' | 'DESC', businessAccountLegalDetailsId?: number, filters?: Array<string>, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any) {
        return DefaultApiFp(this.configuration).generateProductsInventoryListDocumentXlsx(businessAccountId, common, documentTemplateId, showBarcode, expandOwnNamedInstances, expandSubrentedNamedInstances, showPurchaseCost, showCarryingValue, expandInstanceStateDescription, showColumnActualAvailability, showColumnConvergence, showColumnNotes, showSupplier, groupByCategories, timezoneOffsetUTC, categorySortBy, categorySortOrder, businessAccountLegalDetailsId, filters, limit, startFrom, sortBy, sortOrder, search, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Генерация акта по проекту в формат DOCX
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} projectId Идентификатор проекта
     * @param {boolean} includeWorkPlannings 
     * @param {boolean} includeExpenses 
     * @param {boolean} common Флаг типа шаблона
     * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
     * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
     * @param {boolean} joinSimilarRows Объединять ли одинаковые строки при экспорте (для развернутых наборов не работает)
     * @param {boolean} groupByCategories Группировать по категориям
     * @param {number} timezoneOffsetUTC Временная зона
     * @param {CategorySortOrderEnum} [categorySortBy] 
     * @param {'ASC' | 'DESC'} [categorySortOrder] 
     * @param {Date} [t0] 
     * @param {Date} [tN] 
     * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public generateProjectActDocumentDocx(businessAccountId: number, projectId: number, includeWorkPlannings: boolean, includeExpenses: boolean, common: boolean, documentTemplateId: number, expandKits: boolean, joinSimilarRows: boolean, groupByCategories: boolean, timezoneOffsetUTC: number, categorySortBy?: CategorySortOrderEnum, categorySortOrder?: 'ASC' | 'DESC', t0?: Date, tN?: Date, businessAccountLegalDetailsId?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any) {
        return DefaultApiFp(this.configuration).generateProjectActDocumentDocx(businessAccountId, projectId, includeWorkPlannings, includeExpenses, common, documentTemplateId, expandKits, joinSimilarRows, groupByCategories, timezoneOffsetUTC, categorySortBy, categorySortOrder, t0, tN, businessAccountLegalDetailsId, filters, sortBy, sortOrder, search, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Генерация акта по проекту в формат PDF
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} projectId Идентификатор проекта
     * @param {boolean} includeWorkPlannings 
     * @param {boolean} includeExpenses 
     * @param {boolean} common Флаг типа шаблона
     * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
     * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
     * @param {boolean} joinSimilarRows Объединять ли одинаковые строки при экспорте (для развернутых наборов не работает)
     * @param {boolean} groupByCategories Группировать по категориям
     * @param {number} timezoneOffsetUTC Временная зона
     * @param {CategorySortOrderEnum} [categorySortBy] 
     * @param {'ASC' | 'DESC'} [categorySortOrder] 
     * @param {Date} [t0] 
     * @param {Date} [tN] 
     * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public generateProjectActDocumentPdf(businessAccountId: number, projectId: number, includeWorkPlannings: boolean, includeExpenses: boolean, common: boolean, documentTemplateId: number, expandKits: boolean, joinSimilarRows: boolean, groupByCategories: boolean, timezoneOffsetUTC: number, categorySortBy?: CategorySortOrderEnum, categorySortOrder?: 'ASC' | 'DESC', t0?: Date, tN?: Date, businessAccountLegalDetailsId?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any) {
        return DefaultApiFp(this.configuration).generateProjectActDocumentPdf(businessAccountId, projectId, includeWorkPlannings, includeExpenses, common, documentTemplateId, expandKits, joinSimilarRows, groupByCategories, timezoneOffsetUTC, categorySortBy, categorySortOrder, t0, tN, businessAccountLegalDetailsId, filters, sortBy, sortOrder, search, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Генерация документа сметы проекта в формат PDF
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} projectId Идентификатор проекта
     * @param {boolean} includeWorkPlannings 
     * @param {boolean} includeExpenses 
     * @param {boolean} common Флаг типа шаблона
     * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
     * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
     * @param {boolean} joinSimilarRows Объединять ли одинаковые строки при экспорте (для развернутых наборов не работает)
     * @param {number} timezoneOffsetUTC Временная зона
     * @param {CategorySortOrderEnum} [categorySortBy] 
     * @param {'ASC' | 'DESC'} [categorySortOrder] 
     * @param {Date} [t0] 
     * @param {Date} [tN] 
     * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public generateProjectEstimateDocumentPdf(businessAccountId: number, projectId: number, includeWorkPlannings: boolean, includeExpenses: boolean, common: boolean, documentTemplateId: number, expandKits: boolean, joinSimilarRows: boolean, timezoneOffsetUTC: number, categorySortBy?: CategorySortOrderEnum, categorySortOrder?: 'ASC' | 'DESC', t0?: Date, tN?: Date, businessAccountLegalDetailsId?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any) {
        return DefaultApiFp(this.configuration).generateProjectEstimateDocumentPdf(businessAccountId, projectId, includeWorkPlannings, includeExpenses, common, documentTemplateId, expandKits, joinSimilarRows, timezoneOffsetUTC, categorySortBy, categorySortOrder, t0, tN, businessAccountLegalDetailsId, filters, sortBy, sortOrder, search, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Генерация документа сметы проекта в формат XLSX
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} projectId Идентификатор проекта
     * @param {boolean} includeWorkPlannings 
     * @param {boolean} includeExpenses 
     * @param {boolean} common Флаг типа шаблона
     * @param {number} documentTemplateId Идентификатор шаблона документа, если не указан, берется общий(common) шаблон для типа документа
     * @param {boolean} expandKits Разворачивать ли наборы (показывать компаненты) при экспорте
     * @param {boolean} joinSimilarRows Объединять ли одинаковые строки при экспорте (для развернутых наборов не работает)
     * @param {number} timezoneOffsetUTC Временная зона
     * @param {CategorySortOrderEnum} [categorySortBy] 
     * @param {'ASC' | 'DESC'} [categorySortOrder] 
     * @param {Date} [t0] 
     * @param {Date} [tN] 
     * @param {number} [businessAccountLegalDetailsId] Идентификатор реквизита, если не указан, берется первый из списка реквизитов
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public generateProjectEstimateDocumentXlsx(businessAccountId: number, projectId: number, includeWorkPlannings: boolean, includeExpenses: boolean, common: boolean, documentTemplateId: number, expandKits: boolean, joinSimilarRows: boolean, timezoneOffsetUTC: number, categorySortBy?: CategorySortOrderEnum, categorySortOrder?: 'ASC' | 'DESC', t0?: Date, tN?: Date, businessAccountLegalDetailsId?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any) {
        return DefaultApiFp(this.configuration).generateProjectEstimateDocumentXlsx(businessAccountId, projectId, includeWorkPlannings, includeExpenses, common, documentTemplateId, expandKits, joinSimilarRows, timezoneOffsetUTC, categorySortBy, categorySortOrder, t0, tN, businessAccountLegalDetailsId, filters, sortBy, sortOrder, search, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Генерация отчета
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {ReportInputParameters} reportInputParameters 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public generateReport(businessAccountId: number, id: number, reportInputParameters: ReportInputParameters, options?: any) {
        return DefaultApiFp(this.configuration).generateReport(businessAccountId, id, reportInputParameters, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Генерация представления отчета в формат PDF
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {number} timezoneOffsetUTC Временная зона
     * @param {ExcelReportGenerationInfo} excelReportGenerationInfo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public generateReportPdf(businessAccountId: number, id: number, timezoneOffsetUTC: number, excelReportGenerationInfo: ExcelReportGenerationInfo, options?: any) {
        return DefaultApiFp(this.configuration).generateReportPdf(businessAccountId, id, timezoneOffsetUTC, excelReportGenerationInfo, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Генерация представления отчета в формат XLSX
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {number} timezoneOffsetUTC Временная зона
     * @param {ExcelReportGenerationInfo} excelReportGenerationInfo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public generateReportXlsx(businessAccountId: number, id: number, timezoneOffsetUTC: number, excelReportGenerationInfo: ExcelReportGenerationInfo, options?: any) {
        return DefaultApiFp(this.configuration).generateReportXlsx(businessAccountId, id, timezoneOffsetUTC, excelReportGenerationInfo, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Запрос на генерацию нового токена доступа к апи витрины
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public generateStorefrontAccessToken(businessAccountId: number, options?: any) {
        return DefaultApiFp(this.configuration).generateStorefrontAccessToken(businessAccountId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение информации о вложении
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAttachmentById(businessAccountId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).getAttachmentById(businessAccountId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение списка реквизитов по стране
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {LegalCountryCodeEnum} legalCountryTypeCode Код страны в юридическом контексте
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getBALegalDetailsFieldsListByCountry(businessAccountId: number, legalCountryTypeCode: LegalCountryCodeEnum, options?: any) {
        return DefaultApiFp(this.configuration).getBALegalDetailsFieldsListByCountry(businessAccountId, legalCountryTypeCode, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение информации о компании
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {string} accessToken Обязательный токен доступа в формате UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getBusinessAccount(businessAccountId: number, accessToken: string, options?: any) {
        return DefaultApiFp(this.configuration).getBusinessAccount(businessAccountId, accessToken, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение состояния бизнес аккаунта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getBusinessAccountById(businessAccountId: number, options?: any) {
        return DefaultApiFp(this.configuration).getBusinessAccountById(businessAccountId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Изменение контактных данных бизнес аккаунта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getBusinessAccountContactsById(businessAccountId: number, options?: any) {
        return DefaultApiFp(this.configuration).getBusinessAccountContactsById(businessAccountId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Данные дешборда бизнес аккаунта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getBusinessAccountDashboardData(businessAccountId: number, options?: any) {
        return DefaultApiFp(this.configuration).getBusinessAccountDashboardData(businessAccountId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение индикаторов бизнес аккаунта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getBusinessAccountIndicators(businessAccountId: number, options?: any) {
        return DefaultApiFp(this.configuration).getBusinessAccountIndicators(businessAccountId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Список ссылок на статьи базы знаний с названиями в текущей локали
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {string} interfaceBlockCode Код блока интерфейся для которого требуется выгрузить статьи
     * @param {LocaleCodeEnum} locale Локаль для которой требуется выгрузить статьи
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getBusinessAccountKnowledgeBaseArticleLinkList(businessAccountId: number, interfaceBlockCode: string, locale: LocaleCodeEnum, options?: any) {
        return DefaultApiFp(this.configuration).getBusinessAccountKnowledgeBaseArticleLinkList(businessAccountId, interfaceBlockCode, locale, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение основных настроек бизнес аккаунта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getBusinessAccountPreferences(businessAccountId: number, options?: any) {
        return DefaultApiFp(this.configuration).getBusinessAccountPreferences(businessAccountId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение состояния категории
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCategoryById(businessAccountId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).getCategoryById(businessAccountId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение состояния стандартного шаблона документа
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCommonDocumentTemplateById(id: number, options?: any) {
        return DefaultApiFp(this.configuration).getCommonDocumentTemplateById(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение контента стандартного шаблона документа
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCommonDocumentTemplateContentById(id: number, options?: any) {
        return DefaultApiFp(this.configuration).getCommonDocumentTemplateContentById(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение контента стандатного шаблона документа
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCommonDocumentTemplateContentFromBusinessAccountById(businessAccountId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).getCommonDocumentTemplateContentFromBusinessAccountById(businessAccountId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение состояния контакта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getContactById(businessAccountId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).getContactById(businessAccountId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение кадра
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCrewMember(businessAccountId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).getCrewMember(businessAccountId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение состояния настроек уведомлений текущего пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCurrentNotificationSchema(options?: any) {
        return DefaultApiFp(this.configuration).getCurrentNotificationSchema(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение состояния текущего пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCurrentProfile(options?: any) {
        return DefaultApiFp(this.configuration).getCurrentProfile(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Информация о текущем клиенте в бизнес аккаунте. Необходим JWT токен пользователя
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCurrentRenterInfo(businessAccountId: number, storefrontId: number, options?: any) {
        return DefaultApiFp(this.configuration).getCurrentRenterInfo(businessAccountId, storefrontId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение прав текущего пользователя. Права в бизнес аккаунте передаются при передаче параметра businessAccountId
     * @param {number} [businessAccountId] Идентификатор бизнес аккаунта, необязательный
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCurrentRolesAndPermissions(businessAccountId?: number, options?: any) {
        return DefaultApiFp(this.configuration).getCurrentRolesAndPermissions(businessAccountId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение состояния пользовательского поля
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCustomFieldById(businessAccountId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).getCustomFieldById(businessAccountId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение всех пользовательских полей по типу сущности
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {string} nomenclatureType Тип номенклатуры для печати
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCustomFieldsByEntityType(businessAccountId: number, id: number, nomenclatureType: string, options?: any) {
        return DefaultApiFp(this.configuration).getCustomFieldsByEntityType(businessAccountId, id, nomenclatureType, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение состояния шаблона документа
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getDocumentTemplateById(businessAccountId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).getDocumentTemplateById(businessAccountId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение контента пользовательского шаблона документа
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getDocumentTemplateContentById(businessAccountId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).getDocumentTemplateContentById(businessAccountId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение данных сущности по штриховому коду
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {string} barcodeString Строка прочитанного штрихового кода
     * @param {boolean} [includeEntityInfoRead] Флаг для включения полной информации по сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getEntityByBarcode(businessAccountId: number, barcodeString: string, includeEntityInfoRead?: boolean, options?: any) {
        return DefaultApiFp(this.configuration).getEntityByBarcode(businessAccountId, barcodeString, includeEntityInfoRead, options)(this.axios, this.basePath);
    }

    /**
     * Получение сущности затраты по проекту
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getExpense(businessAccountId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).getExpense(businessAccountId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение сгенерированного мнемонического ключа
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {'renter' | 'variant' | 'instance' | 'project' | 'subrent' | 'project_operation' | 'subrent_operation' | 'template_operation'} type Тип сущности
     * @param {'mnemoKey'} fieldName Наименование поля
     * @param {number} [parentId] Идентификатор родительской сущности
     * @param {string} [baseString] Короткое наименование/тип сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getGeneratedField(businessAccountId: number, type: 'renter' | 'variant' | 'instance' | 'project' | 'subrent' | 'project_operation' | 'subrent_operation' | 'template_operation', fieldName: 'mnemoKey', parentId?: number, baseString?: string, options?: any) {
        return DefaultApiFp(this.configuration).getGeneratedField(businessAccountId, type, fieldName, parentId, baseString, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get information to visualize
     * @param {number} gridCellIdentifier 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getGridCellData(gridCellIdentifier: number, options?: any) {
        return DefaultApiFp(this.configuration).getGridCellData(gridCellIdentifier, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Operation to get info about grid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getInformationAboutGrid(options?: any) {
        return DefaultApiFp(this.configuration).getInformationAboutGrid(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение состояния экземпляра
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getInstanceById(businessAccountId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).getInstanceById(businessAccountId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение информации о событии движения инвентаря
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getInventoryMovementById(businessAccountId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).getInventoryMovementById(businessAccountId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение описания приглашения
     * @param {string} token Код активации приглашения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getInvitationByToken(token: string, options?: any) {
        return DefaultApiFp(this.configuration).getInvitationByToken(token, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение состояния набора
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getKitById(businessAccountId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).getKitById(businessAccountId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение карты состояния набора (только доступность и инвентарное количество)
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {boolean} captureAll Маркер обозначающий, что нужно показать весь возможный период
     * @param {TimetableTypeCodeEnum} typeCode Тип искомого стека состояния (только доступность и инвентарное количество)
     * @param {number} [from] Начало целевого интервала времени, epoch millis
     * @param {number} [until] Конец целевого интервала времени, epoch millis
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getKitTimetableById(businessAccountId: number, id: number, captureAll: boolean, typeCode: TimetableTypeCodeEnum, from?: number, until?: number, options?: any) {
        return DefaultApiFp(this.configuration).getKitTimetableById(businessAccountId, id, captureAll, typeCode, from, until, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Массовое получение состояний набора
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {Array<number>} ids Идентификаторы набора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getKitsByIdsBulk(businessAccountId: number, ids: Array<number>, options?: any) {
        return DefaultApiFp(this.configuration).getKitsByIdsBulk(businessAccountId, ids, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение состояния объекта субъектов и реквизитов
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getLegalDetailById(businessAccountId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).getLegalDetailById(businessAccountId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение состояния объектов субъектов и реквизитов
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getLegalDetails(businessAccountId: number, options?: any) {
        return DefaultApiFp(this.configuration).getLegalDetails(businessAccountId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getLocationById(businessAccountId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).getLocationById(businessAccountId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение данных сущности номенклатуры для формы операции по штриховому коду
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {string} barcodeString Строка прочитанного штрихового кода
     * @param {boolean} [includeEntityInfoRead] Флаг для включения полной информации по сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getNomenclatureInOperationFormByBarcode(businessAccountId: number, barcodeString: string, includeEntityInfoRead?: boolean, options?: any) {
        return DefaultApiFp(this.configuration).getNomenclatureInOperationFormByBarcode(businessAccountId, barcodeString, includeEntityInfoRead, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение состояния операции
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getOperationById(businessAccountId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).getOperationById(businessAccountId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение списка аудитов аренды состава операции
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getOperationComposition(businessAccountId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).getOperationComposition(businessAccountId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение платежа
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPaymentById(businessAccountId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).getPaymentById(businessAccountId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение состояния способа оплаты
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPaymentMethodById(businessAccountId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).getPaymentMethodById(businessAccountId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение состояния ценовой схемы
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPricingSchemeById(businessAccountId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).getPricingSchemeById(businessAccountId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение состояния продукта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProductById(businessAccountId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).getProductById(businessAccountId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение состояния экземпляра продукта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} productId Идентификатор продукта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProductInstanceById(businessAccountId: number, productId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).getProductInstanceById(businessAccountId, productId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение карты состояния экземпляра продукта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} productId Идентификатор продукта
     * @param {number} id Идентификатор сущности
     * @param {boolean} captureAll Маркер обозначающий, что нужно показать весь возможный период
     * @param {TimetableTypeCodeEnum} typeCode Тип искомого стека состояния (только доступность и инвентарное количество)
     * @param {number} [from] Начало целевого интервала времени, epoch millis
     * @param {number} [until] Конец целевого интервала времени, epoch millis
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProductInstanceTimetableById(businessAccountId: number, productId: number, id: number, captureAll: boolean, typeCode: TimetableTypeCodeEnum, from?: number, until?: number, options?: any) {
        return DefaultApiFp(this.configuration).getProductInstanceTimetableById(businessAccountId, productId, id, captureAll, typeCode, from, until, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение карты состояния продукта (доступность, заявка и прочее)
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {boolean} captureAll Маркер обозначающий, что нужно показать весь возможный период
     * @param {TimetableTypeCodeEnum} typeCode Тип искомого стека состояния (только доступность и инвентарное количество)
     * @param {number} [from] Начало целевого интервала времени, epoch millis
     * @param {number} [until] Конец целевого интервала времени, epoch millis
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProductTimetableById(businessAccountId: number, id: number, captureAll: boolean, typeCode: TimetableTypeCodeEnum, from?: number, until?: number, options?: any) {
        return DefaultApiFp(this.configuration).getProductTimetableById(businessAccountId, id, captureAll, typeCode, from, until, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение состояния варианта продукта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} productId Идентификатор продукта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProductVariantById(businessAccountId: number, productId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).getProductVariantById(businessAccountId, productId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение карты состояния экземпляра варианта продукта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} productId Идентификатор продукта
     * @param {number} variantId Идентификатор варианта
     * @param {number} id Идентификатор сущности
     * @param {boolean} captureAll Маркер обозначающий, что нужно показать весь возможный период
     * @param {TimetableTypeCodeEnum} typeCode Тип искомого стека состояния (только доступность и инвентарное количество)
     * @param {number} [from] Начало целевого интервала времени, epoch millis
     * @param {number} [until] Конец целевого интервала времени, epoch millis
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProductVariantInstanceTimetableById(businessAccountId: number, productId: number, variantId: number, id: number, captureAll: boolean, typeCode: TimetableTypeCodeEnum, from?: number, until?: number, options?: any) {
        return DefaultApiFp(this.configuration).getProductVariantInstanceTimetableById(businessAccountId, productId, variantId, id, captureAll, typeCode, from, until, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение профессии
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProfessionById(businessAccountId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).getProfessionById(businessAccountId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение состояния проекта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProjectById(businessAccountId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).getProjectById(businessAccountId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Запрос информации элемента аренды
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getRentElementById(businessAccountId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).getRentElementById(businessAccountId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Запрос истории элемента аренды
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getRentElementHistory(businessAccountId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).getRentElementHistory(businessAccountId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение состояния арендатора
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getRenterById(businessAccountId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).getRenterById(businessAccountId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение данных о состоянии отдельного графического представления отчёта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getReportChartVisualizationById(businessAccountId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).getReportChartVisualizationById(businessAccountId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение состояния конфигурации отчета
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getReportConfigurationById(businessAccountId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).getReportConfigurationById(businessAccountId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение изображения логотипа компании клиента при SSO авторизации
     * @param {string} ssoClientOrganizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSsoAuthorizationClientLogo(ssoClientOrganizationId: string, options?: any) {
        return DefaultApiFp(this.configuration).getSsoAuthorizationClientLogo(ssoClientOrganizationId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение информации об организации в системе SSO авторизации для бизнес аккаунта
     * @param {string} accessToken Обязательный токен доступа в формате UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSsoClientOrganization(accessToken: string, options?: any) {
        return DefaultApiFp(this.configuration).getSsoClientOrganization(accessToken, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение информации о витрине
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {string} accessToken Обязательный токен доступа в формате UUID
     * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
     * @param {Array<number>} [baseCategoryIds] Идентификаторы базовых категорий, только они и их подкатегории попадут в результат
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getStorefront(businessAccountId: number, accessToken: string, storefrontId: number, baseCategoryIds?: Array<number>, options?: any) {
        return DefaultApiFp(this.configuration).getStorefront(businessAccountId, accessToken, storefrontId, baseCategoryIds, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение доступности торг. предложения
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {string} accessToken Обязательный токен доступа в формате UUID
     * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
     * @param {number} offerId Идентификатор торг. предложения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getStorefrontOfferAvailability(businessAccountId: number, accessToken: string, storefrontId: number, offerId: number, options?: any) {
        return DefaultApiFp(this.configuration).getStorefrontOfferAvailability(businessAccountId, accessToken, storefrontId, offerId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение состояния субаренды
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSubrentById(businessAccountId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).getSubrentById(businessAccountId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение состояния системной настройки
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSystemOptionById(businessAccountId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).getSystemOptionById(businessAccountId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Список групп системных настроек с вложенными системными настройками
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSystemOptionsSectionById(businessAccountId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).getSystemOptionsSectionById(businessAccountId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение иерархии системных настроек бизнес аккаунта в виде дерева
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSystemOptionsTree(businessAccountId: number, options?: any) {
        return DefaultApiFp(this.configuration).getSystemOptionsTree(businessAccountId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение состояния шаблона
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTemplateById(businessAccountId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).getTemplateById(businessAccountId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение серверного времени
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTime(options?: any) {
        return DefaultApiFp(this.configuration).getTime(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение серверного времени
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTime_2(options?: any) {
        return DefaultApiFp(this.configuration).getTime_2(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTransportationById(businessAccountId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).getTransportationById(businessAccountId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение состояния пользователя
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUserById(businessAccountId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).getUserById(businessAccountId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Валидация значения поля
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {'kit' | 'product' | 'variant' | 'instance' | 'profession' | 'renter' | 'project' | 'subrent' | 'template' | 'customField' | 'user' | 'location' | 'contact' | 'vehicle' | 'transportation' | 'paymentMethod'} type Тип сущности
     * @param {string} fieldName Наименование поля
     * @param {string} value Значение поля
     * @param {number} [ignoreCurrentId] Идентификатор текущей сущности, которую нужно игнорировать, например, при проверке уникальности
     * @param {number} [parentId] Идентификатор родительской сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getValidationForField(businessAccountId: number, type: 'kit' | 'product' | 'variant' | 'instance' | 'profession' | 'renter' | 'project' | 'subrent' | 'template' | 'customField' | 'user' | 'location' | 'contact' | 'vehicle' | 'transportation' | 'paymentMethod', fieldName: string, value: string, ignoreCurrentId?: number, parentId?: number, options?: any) {
        return DefaultApiFp(this.configuration).getValidationForField(businessAccountId, type, fieldName, value, ignoreCurrentId, parentId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Карты состояния варианта (доступность, заявка и прочее)
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} productId Идентификатор продукта
     * @param {number} id Идентификатор сущности
     * @param {boolean} captureAll Маркер обозначающий, что нужно показать весь возможный период
     * @param {TimetableTypeCodeEnum} typeCode Тип искомого стека состояния (только доступность и инвентарное количество)
     * @param {number} [from] Начало целевого интервала времени, epoch millis
     * @param {number} [until] Конец целевого интервала времени, epoch millis
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getVariantTimetableById(businessAccountId: number, productId: number, id: number, captureAll: boolean, typeCode: TimetableTypeCodeEnum, from?: number, until?: number, options?: any) {
        return DefaultApiFp(this.configuration).getVariantTimetableById(businessAccountId, productId, id, captureAll, typeCode, from, until, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getVehicleById(businessAccountId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).getVehicleById(businessAccountId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение данных для графика
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {ReportInputParameters} [reportInputParameters] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getViewInfoOfReportChartVisualization(businessAccountId: number, id: number, reportInputParameters?: ReportInputParameters, options?: any) {
        return DefaultApiFp(this.configuration).getViewInfoOfReportChartVisualization(businessAccountId, id, reportInputParameters, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получить описание работы
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getWorkPlanning(businessAccountId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).getWorkPlanning(businessAccountId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Глобальный запрос списка бизнес аккаунтов
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public globalListBusinessAccounts(limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any) {
        return DefaultApiFp(this.configuration).globalListBusinessAccounts(limit, startFrom, filters, sortBy, sortOrder, search, options)(this.axios, this.basePath);
    }

    /**
     * Берёт из эластика
     * @summary Возвращает список шаблонов. Всех
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public globalListDocumentTemplates(limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any) {
        return DefaultApiFp(this.configuration).globalListDocumentTemplates(limit, startFrom, filters, sortBy, sortOrder, search, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Массовое изменение КП - включение в стоимость и исключение из стоимости
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {boolean} include Включить или исключить true &#x3D; включить, false &#x3D; исключить
     * @param {VersionedEntityObjList} versionedEntityObjList 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public includeOrExcludeOfferInProjectPriceBulk(businessAccountId: number, include: boolean, versionedEntityObjList: VersionedEntityObjList, options?: any) {
        return DefaultApiFp(this.configuration).includeOrExcludeOfferInProjectPriceBulk(businessAccountId, include, versionedEntityObjList, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Добавить пользователя в бизнес аккаунт
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {UserInviteToBaObj} userInviteToBaObj 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public inviteUserToBusinessAccount(businessAccountId: number, userInviteToBaObj: UserInviteToBaObj, options?: any) {
        return DefaultApiFp(this.configuration).inviteUserToBusinessAccount(businessAccountId, userInviteToBaObj, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Массовое изменение привязки категорий
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {boolean} createLinks Создать или удалить, true &#x3D; создать, false &#x3D; удалить
     * @param {CategoryBulkLinkObj} categoryBulkLinkObj 
     * @param {boolean} [makeMainCategory] Назначить ли привязываемую категорию основной (передается только при привязке)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public linkCategoriesBulk(businessAccountId: number, createLinks: boolean, categoryBulkLinkObj: CategoryBulkLinkObj, makeMainCategory?: boolean, options?: any) {
        return DefaultApiFp(this.configuration).linkCategoriesBulk(businessAccountId, createLinks, categoryBulkLinkObj, makeMainCategory, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Массовое изменение привязки пользовательских полей
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {boolean} createLinks Создать или удалить, true &#x3D; создать, false &#x3D; удалить
     * @param {boolean} assignFieldsToVariants Присвоить пользовательские поля вариантам или только продукту
     * @param {CustomFieldBulkLinkObj} customFieldBulkLinkObj 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public linkCustomFieldsBulk(businessAccountId: number, createLinks: boolean, assignFieldsToVariants: boolean, customFieldBulkLinkObj: CustomFieldBulkLinkObj, options?: any) {
        return DefaultApiFp(this.configuration).linkCustomFieldsBulk(businessAccountId, createLinks, assignFieldsToVariants, customFieldBulkLinkObj, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Массовое назначение тэгов
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {boolean} createLinks Создать или удалить, true &#x3D; создать, false &#x3D; удалить
     * @param {TagsBulkLinkObj} tagsBulkLinkObj 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public linkTagsBulk(businessAccountId: number, createLinks: boolean, tagsBulkLinkObj: TagsBulkLinkObj, options?: any) {
        return DefaultApiFp(this.configuration).linkTagsBulk(businessAccountId, createLinks, tagsBulkLinkObj, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение списка идентификаторов всех активных предложений витрины бизнес аккаунта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {string} accessToken Обязательный токен доступа в формате UUID
     * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
     * @param {Array<number>} [baseCategoryIds] Идентификаторы базовых категорий, только они и их подкатегории попадут в результат
     * @param {boolean} [includeProfessions] Если флаг передан и равен true, будут добавлены торг. предложения с типом профессия. В старых версиях витрины не передается, обеспечивает обратную совместимость
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listActiveStorefrontOffersIds(businessAccountId: number, accessToken: string, storefrontId: number, baseCategoryIds?: Array<number>, includeProfessions?: boolean, options?: any) {
        return DefaultApiFp(this.configuration).listActiveStorefrontOffersIds(businessAccountId, accessToken, storefrontId, baseCategoryIds, includeProfessions, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение доступных типов операций
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {OperationAvailableStatesRequestObj} operationAvailableStatesRequestObj 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listAvailableOperationTypes(businessAccountId: number, operationAvailableStatesRequestObj: OperationAvailableStatesRequestObj, options?: any) {
        return DefaultApiFp(this.configuration).listAvailableOperationTypes(businessAccountId, operationAvailableStatesRequestObj, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Список элементов бизнес аккаунта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {boolean} [excludeAggregations] Исключить аггрегации
     * @param {boolean} [excludeRecords] Исключить записи списка
     * @param {Date} [specialFromStartDateIncludingDelays] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listBusinessAccountElements(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, excludeAggregations?: boolean, excludeRecords?: boolean, specialFromStartDateIncludingDelays?: Date, options?: any) {
        return DefaultApiFp(this.configuration).listBusinessAccountElements(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, excludeAggregations, excludeRecords, specialFromStartDateIncludingDelays, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение ограничения фильтров по обязательствам бизнес аккаунта на основе присутствующих данных
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listBusinessAccountElementsAvailableFiltersValues(businessAccountId: number, filters?: Array<string>, options?: any) {
        return DefaultApiFp(this.configuration).listBusinessAccountElementsAvailableFiltersValues(businessAccountId, filters, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение состояния объекта субъектов и реквизитов
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listBusinessAccountElementsGroupedByNomenclature(businessAccountId: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', options?: any) {
        return DefaultApiFp(this.configuration).listBusinessAccountElementsGroupedByNomenclature(businessAccountId, filters, sortBy, sortOrder, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Список сгруппированных элементов бизнес аккаунта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {boolean} subrent Запрос по субарендным обязательствам - если true то субаренда, если false то аренда
     * @param {boolean} periodStartDate Запрос по началу периода - если true, то начало считается датой группы, если false то окончание
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listBusinessAccountElementsGroupedByPeriod(businessAccountId: number, subrent: boolean, periodStartDate: boolean, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any) {
        return DefaultApiFp(this.configuration).listBusinessAccountElementsGroupedByPeriod(businessAccountId, subrent, periodStartDate, limit, startFrom, filters, sortBy, sortOrder, search, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение списка графических визуализаций отчетов, доступных для бизнес аккаунта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {string} [search] Поисковая строка
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listBusinessAccountReportChartVisualizations(businessAccountId: number, search?: string, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', filters?: Array<string>, options?: any) {
        return DefaultApiFp(this.configuration).listBusinessAccountReportChartVisualizations(businessAccountId, search, limit, startFrom, sortBy, sortOrder, filters, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение списка конфигураций отчетов доступных для бизнес аккаунта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listBusinessAccountReportConfigurations(businessAccountId: number, options?: any) {
        return DefaultApiFp(this.configuration).listBusinessAccountReportConfigurations(businessAccountId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Список бизнес аккаунтов
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listBusinessAccounts(limit?: number, startFrom?: number, options?: any) {
        return DefaultApiFp(this.configuration).listBusinessAccounts(limit, startFrom, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Список категорий
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {CategoryTypeCodeEnum} typeCode Тип изменяемых категорий
     * @param {string} [search] Прямое вхождение последовательности символов в наименование
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listCategories(businessAccountId: number, typeCode: CategoryTypeCodeEnum, search?: string, options?: any) {
        return DefaultApiFp(this.configuration).listCategories(businessAccountId, typeCode, search, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Список деревьев категорий
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {CategoryTypeCodeEnum} typeCode Тип изменяемых категорий
     * @param {string} [search] Прямое вхождение последовательности символов в наименование
     * @param {CategorySortOrderEnum} [sortBy] По чему сортировать
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listCategoryTrees(businessAccountId: number, typeCode: CategoryTypeCodeEnum, search?: string, sortBy?: CategorySortOrderEnum, sortOrder?: 'ASC' | 'DESC', options?: any) {
        return DefaultApiFp(this.configuration).listCategoryTrees(businessAccountId, typeCode, search, sortBy, sortOrder, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение текущих заполняемых в интерфейсе операций по рамке активности
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} activityFrameId Идентификатор рамки активности
     * @param {RentActivityFrameTypeCodeEnum} activityFrameType Тип рамки активности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listConcurrentOperations(businessAccountId: number, activityFrameId: number, activityFrameType: RentActivityFrameTypeCodeEnum, options?: any) {
        return DefaultApiFp(this.configuration).listConcurrentOperations(businessAccountId, activityFrameId, activityFrameType, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение списка контактов
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {string} [search] Поисковая строка
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listContacts(businessAccountId: number, search?: string, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', filters?: Array<string>, options?: any) {
        return DefaultApiFp(this.configuration).listContacts(businessAccountId, search, limit, startFrom, sortBy, sortOrder, filters, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Список кадров
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listCrewMembers(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any) {
        return DefaultApiFp(this.configuration).listCrewMembers(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение ограничений фильтров сотрудников бизнес аккаунта на основе присутствующих данных
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listCrewMembersAvailableFiltersValues(businessAccountId: number, options?: any) {
        return DefaultApiFp(this.configuration).listCrewMembersAvailableFiltersValues(businessAccountId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Список пользовательских полей
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {string} [search] Поисковая строка
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {'BOOLEAN' | 'LONG' | 'DOUBLE' | 'STRING' | 'DATE' | 'ENTITY_LINK'} [typeCodeFilter] Фильтр по типу поля
     * @param {boolean} [standardFilter] Фильтр по признаку является ли поле стандартным
     * @param {'USER_LINK' | 'CONTACT_LINK' | 'SUBRENT_LINK' | 'PROJECT_LINK' | 'RENTER_LINK' | 'LONG_SUM' | 'MONEY_SUM'} [processingTypeFilter] Фильтр по типу обработки поля
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listCustomFields(businessAccountId: number, search?: string, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', typeCodeFilter?: 'BOOLEAN' | 'LONG' | 'DOUBLE' | 'STRING' | 'DATE' | 'ENTITY_LINK', standardFilter?: boolean, processingTypeFilter?: 'USER_LINK' | 'CONTACT_LINK' | 'SUBRENT_LINK' | 'PROJECT_LINK' | 'RENTER_LINK' | 'LONG_SUM' | 'MONEY_SUM', options?: any) {
        return DefaultApiFp(this.configuration).listCustomFields(businessAccountId, search, limit, startFrom, sortBy, sortOrder, typeCodeFilter, standardFilter, processingTypeFilter, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Список ограничений по типам пользовательских полей
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listCustomFieldsLimits(businessAccountId: number, options?: any) {
        return DefaultApiFp(this.configuration).listCustomFieldsLimits(businessAccountId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Список шаблонов документов
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {boolean} [showHidden] Если передан и true, то будут показаны даже скрытые шаблоны
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listDocumentTemplates(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, showHidden?: boolean, options?: any) {
        return DefaultApiFp(this.configuration).listDocumentTemplates(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, showHidden, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение списка эксель визуализаций отчета, к которому относится графическая визуализация
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {string} [search] Поисковая строка
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listDocumentTemplatesForReportChartVisualization(businessAccountId: number, id: number, search?: string, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', options?: any) {
        return DefaultApiFp(this.configuration).listDocumentTemplatesForReportChartVisualization(businessAccountId, id, search, limit, startFrom, filters, sortBy, sortOrder, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение информации о измененных файлах
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listEntityLifecycleEvents(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', options?: any) {
        return DefaultApiFp(this.configuration).listEntityLifecycleEvents(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение ограничений фильтров по тратам
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listExpenseAvailableFilters(businessAccountId: number, filters?: Array<string>, options?: any) {
        return DefaultApiFp(this.configuration).listExpenseAvailableFilters(businessAccountId, filters, options)(this.axios, this.basePath);
    }

    /**
     * Получение списка затрат по проекту
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {boolean} [excludeAggregations] Исключить аггрегации
     * @param {boolean} [excludeRecords] Исключить записи списка
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listExpenses(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, excludeAggregations?: boolean, excludeRecords?: boolean, options?: any) {
        return DefaultApiFp(this.configuration).listExpenses(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, excludeAggregations, excludeRecords, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение списка шаблонов для экспорта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listExportTemplate(options?: any) {
        return DefaultApiFp(this.configuration).listExportTemplate(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение списка экземпляров
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listInstances(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any) {
        return DefaultApiFp(this.configuration).listInstances(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение списка экземпляров с картой доступности и аггрегациями стеков на интервале
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} from Начало целевого интервала времени, epoch millis
     * @param {number} until Конец целевого интервала времени, epoch millis
     * @param {ListOnIntervalRequestObject} listOnIntervalRequestObject 
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {Array<TimetableTypeCodeEnum>} [targetStackTypeCodes] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listInstancesOnInterval(businessAccountId: number, from: number, until: number, listOnIntervalRequestObject: ListOnIntervalRequestObject, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, targetStackTypeCodes?: Array<TimetableTypeCodeEnum>, options?: any) {
        return DefaultApiFp(this.configuration).listInstancesOnInterval(businessAccountId, from, until, listOnIntervalRequestObject, limit, startFrom, sortBy, sortOrder, search, targetStackTypeCodes, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение списка событий движения инвентаря
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listInventoryMovements(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any) {
        return DefaultApiFp(this.configuration).listInventoryMovements(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение ограничений фильтров бизнес аккаунта на основе присутствующих данных
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listInventoryMovementsAvailableFiltersValues(businessAccountId: number, filters?: Array<string>, options?: any) {
        return DefaultApiFp(this.configuration).listInventoryMovementsAvailableFiltersValues(businessAccountId, filters, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение списка наборов
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listKits(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any) {
        return DefaultApiFp(this.configuration).listKits(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение ограничений фильтров наборов бизнес аккаунта на основе присутствующих данных
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listKitsAvailableFiltersValues(businessAccountId: number, options?: any) {
        return DefaultApiFp(this.configuration).listKitsAvailableFiltersValues(businessAccountId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {string} [search] Поисковая строка
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listLocations(businessAccountId: number, search?: string, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', filters?: Array<string>, options?: any) {
        return DefaultApiFp(this.configuration).listLocations(businessAccountId, search, limit, startFrom, sortBy, sortOrder, filters, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Список наборов, продуктов и вариантов
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listNomenclature(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any) {
        return DefaultApiFp(this.configuration).listNomenclature(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение ограничений фильтров номенклатуры бизнес аккаунта на основе присутствующих данных
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listNomenclatureAvailableFiltersValues(businessAccountId: number, filters?: Array<string>, options?: any) {
        return DefaultApiFp(this.configuration).listNomenclatureAvailableFiltersValues(businessAccountId, filters, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Список наборов, продуктов и вариантов на интервале времени
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} from Начало целевого интервала времени, epoch millis
     * @param {number} until Конец целевого интервала времени, epoch millis
     * @param {ListOnIntervalRequestObject} listOnIntervalRequestObject 
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {Array<TimetableTypeCodeEnum>} [targetStackTypeCodes] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listNomenclatureOnInterval(businessAccountId: number, from: number, until: number, listOnIntervalRequestObject: ListOnIntervalRequestObject, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, targetStackTypeCodes?: Array<TimetableTypeCodeEnum>, options?: any) {
        return DefaultApiFp(this.configuration).listNomenclatureOnInterval(businessAccountId, from, until, listOnIntervalRequestObject, limit, startFrom, sortBy, sortOrder, search, targetStackTypeCodes, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Список элементов операции
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {boolean} [excludeAggregations] Исключить аггрегации
     * @param {boolean} [excludeRecords] Исключить записи списка
     * @param {Date} [specialFromStartDateIncludingDelays] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listOperationElements(businessAccountId: number, id: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, excludeAggregations?: boolean, excludeRecords?: boolean, specialFromStartDateIncludingDelays?: Date, options?: any) {
        return DefaultApiFp(this.configuration).listOperationElements(businessAccountId, id, limit, startFrom, filters, sortBy, sortOrder, search, excludeAggregations, excludeRecords, specialFromStartDateIncludingDelays, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Список операций
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listOperations(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any) {
        return DefaultApiFp(this.configuration).listOperations(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение ограничений фильтров операциям на основе присутствующих данных
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listOperationsAvailableFiltersValues(businessAccountId: number, filters?: Array<string>, options?: any) {
        return DefaultApiFp(this.configuration).listOperationsAvailableFiltersValues(businessAccountId, filters, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Список заказов текущего пользователя в бизнес аккаунте, созданных через витрину. Необходим JWT токен пользователя
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
     * @param {number} [minimumOrderChangeDate] Фильтр для получения только заказов, созданных и измененных не ранее указанной даты, epoch millis
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listOrdersByClient(businessAccountId: number, storefrontId: number, minimumOrderChangeDate?: number, options?: any) {
        return DefaultApiFp(this.configuration).listOrdersByClient(businessAccountId, storefrontId, minimumOrderChangeDate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Список заказов текущего пользователя в бизнес аккаунте, созданных через витрину. Необходим JWT токен пользователя
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {string} accessToken Обязательный токен доступа в формате UUID
     * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
     * @param {number} [minimumOrderChangeDate] Фильтр для получения только заказов, созданных и измененных не ранее указанной даты, epoch millis
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listOrdersByServer(businessAccountId: number, accessToken: string, storefrontId: number, minimumOrderChangeDate?: number, options?: any) {
        return DefaultApiFp(this.configuration).listOrdersByServer(businessAccountId, accessToken, storefrontId, minimumOrderChangeDate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение списка способов оплаты
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listPaymentMethods(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', options?: any) {
        return DefaultApiFp(this.configuration).listPaymentMethods(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение ограничений фильтров по тратам
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listPaymentMethodsAvailableFilters(businessAccountId: number, filters?: Array<string>, options?: any) {
        return DefaultApiFp(this.configuration).listPaymentMethodsAvailableFilters(businessAccountId, filters, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Список платежей
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listPayments(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any) {
        return DefaultApiFp(this.configuration).listPayments(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение ограничения фильтров для списка платежей на основе присутствующих данных
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listPaymentsAvailableFiltersValues(businessAccountId: number, options?: any) {
        return DefaultApiFp(this.configuration).listPaymentsAvailableFiltersValues(businessAccountId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Список ценовых схем компании
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {string} [search] Поисковая строка
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {PricingSchemeTypeCodeEnum} [typeCodeFilter] Фильтр по типу поля
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listPricingSchemes(businessAccountId: number, search?: string, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', typeCodeFilter?: PricingSchemeTypeCodeEnum, options?: any) {
        return DefaultApiFp(this.configuration).listPricingSchemes(businessAccountId, search, limit, startFrom, sortBy, sortOrder, typeCodeFilter, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение списка экземпляров продукта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listProductInstances(businessAccountId: number, id: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any) {
        return DefaultApiFp(this.configuration).listProductInstances(businessAccountId, id, limit, startFrom, filters, sortBy, sortOrder, search, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение списка экземпляров продукта с картой доступности и аггрегациями стеков на интервале
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {number} from Начало целевого интервала времени, epoch millis
     * @param {number} until Конец целевого интервала времени, epoch millis
     * @param {ListOnIntervalRequestObject} listOnIntervalRequestObject 
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {Array<TimetableTypeCodeEnum>} [targetStackTypeCodes] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listProductInstancesOnInterval(businessAccountId: number, id: number, from: number, until: number, listOnIntervalRequestObject: ListOnIntervalRequestObject, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, targetStackTypeCodes?: Array<TimetableTypeCodeEnum>, options?: any) {
        return DefaultApiFp(this.configuration).listProductInstancesOnInterval(businessAccountId, id, from, until, listOnIntervalRequestObject, limit, startFrom, sortBy, sortOrder, search, targetStackTypeCodes, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение списка экземпляров варианта продукта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} productId Идентификатор продукта
     * @param {number} id Идентификатор сущности
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listProductVariantInstances(businessAccountId: number, productId: number, id: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any) {
        return DefaultApiFp(this.configuration).listProductVariantInstances(businessAccountId, productId, id, limit, startFrom, filters, sortBy, sortOrder, search, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение списка экземпляров варианта продукта с картой доступности и аггрегациями стеков на интервале
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} productId Идентификатор продукта
     * @param {number} id Идентификатор сущности
     * @param {number} from Начало целевого интервала времени, epoch millis
     * @param {number} until Конец целевого интервала времени, epoch millis
     * @param {ListOnIntervalRequestObject} listOnIntervalRequestObject 
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {Array<TimetableTypeCodeEnum>} [targetStackTypeCodes] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listProductVariantInstancesOnInterval(businessAccountId: number, productId: number, id: number, from: number, until: number, listOnIntervalRequestObject: ListOnIntervalRequestObject, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, targetStackTypeCodes?: Array<TimetableTypeCodeEnum>, options?: any) {
        return DefaultApiFp(this.configuration).listProductVariantInstancesOnInterval(businessAccountId, productId, id, from, until, listOnIntervalRequestObject, limit, startFrom, sortBy, sortOrder, search, targetStackTypeCodes, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение списка вариантов продукта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listProductVariants(businessAccountId: number, id: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any) {
        return DefaultApiFp(this.configuration).listProductVariants(businessAccountId, id, limit, startFrom, filters, sortBy, sortOrder, search, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение ограничений фильтров экземпляров продукта бизнес аккаунта на основе присутствующих данных
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listProductVariantsAvailableFiltersValues(businessAccountId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).listProductVariantsAvailableFiltersValues(businessAccountId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Список продуктов
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listProducts(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any) {
        return DefaultApiFp(this.configuration).listProducts(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение ограничений фильтров продуктов бизнес аккаунта на основе присутствующих данных
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listProductsAvailableFiltersValues(businessAccountId: number, options?: any) {
        return DefaultApiFp(this.configuration).listProductsAvailableFiltersValues(businessAccountId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Список профессий
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listProfessions(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any) {
        return DefaultApiFp(this.configuration).listProfessions(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение ограничений фильтров профессий бизнес аккаунта на основе присутствующих данных
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listProfessionsAvailableFiltersValues(businessAccountId: number, options?: any) {
        return DefaultApiFp(this.configuration).listProfessionsAvailableFiltersValues(businessAccountId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Список элементов проекта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} projectId Идентификатор проекта
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {boolean} [excludeAggregations] Исключить аггрегации
     * @param {boolean} [excludeRecords] Исключить записи списка
     * @param {Date} [specialFromStartDateIncludingDelays] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listProjectElements(businessAccountId: number, projectId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, excludeAggregations?: boolean, excludeRecords?: boolean, specialFromStartDateIncludingDelays?: Date, options?: any) {
        return DefaultApiFp(this.configuration).listProjectElements(businessAccountId, projectId, limit, startFrom, filters, sortBy, sortOrder, search, excludeAggregations, excludeRecords, specialFromStartDateIncludingDelays, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Список проектов
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listProjects(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any) {
        return DefaultApiFp(this.configuration).listProjects(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение ограничений фильтров проектов на основе присутствующих данных
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listProjectsAvailableFiltersValues(businessAccountId: number, filters?: Array<string>, options?: any) {
        return DefaultApiFp(this.configuration).listProjectsAvailableFiltersValues(businessAccountId, filters, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Список рамок арендной активности
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listRentActivityFrames(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any) {
        return DefaultApiFp(this.configuration).listRentActivityFrames(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Список арендаторов
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listRenters(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any) {
        return DefaultApiFp(this.configuration).listRenters(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение ограничений фильтров контрагентов на основе присутствующих данных
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listRentersAvailableFiltersValues(businessAccountId: number, filters?: Array<string>, options?: any) {
        return DefaultApiFp(this.configuration).listRentersAvailableFiltersValues(businessAccountId, filters, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение списка предложений витрины бизнес аккаунта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {string} accessToken Обязательный токен доступа в формате UUID
     * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
     * @param {number} minimumOfferChangeDate Фильтр для получения только торговых предложений, измененных не ранее указанной даты, epoch millis
     * @param {number} [pageNumber] Номер страницы
     * @param {number} [itemsPerPage] Число элементов на странице
     * @param {Array<number>} [baseCategoryIds] Идентификаторы базовых категорий, только они и их подкатегории попадут в результат
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listStorefrontOffers(businessAccountId: number, accessToken: string, storefrontId: number, minimumOfferChangeDate: number, pageNumber?: number, itemsPerPage?: number, baseCategoryIds?: Array<number>, options?: any) {
        return DefaultApiFp(this.configuration).listStorefrontOffers(businessAccountId, accessToken, storefrontId, minimumOfferChangeDate, pageNumber, itemsPerPage, baseCategoryIds, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение списка предложений витрины бизнес аккаунта типа специальность
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {string} accessToken Обязательный токен доступа в формате UUID
     * @param {number} storefrontId Идентификатор витрины, -1 для витрины бизнес аккаунта по умолчанию
     * @param {number} minimumOfferChangeDate Фильтр для получения только торговых предложений, измененных не ранее указанной даты, epoch millis
     * @param {number} [pageNumber] Номер страницы
     * @param {number} [itemsPerPage] Число элементов на странице
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listStorefrontProfessionOffers(businessAccountId: number, accessToken: string, storefrontId: number, minimumOfferChangeDate: number, pageNumber?: number, itemsPerPage?: number, options?: any) {
        return DefaultApiFp(this.configuration).listStorefrontProfessionOffers(businessAccountId, accessToken, storefrontId, minimumOfferChangeDate, pageNumber, itemsPerPage, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Список элементов поставки
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} subrentId Идентификатор поставки
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {boolean} [excludeAggregations] Исключить аггрегации
     * @param {boolean} [excludeRecords] Исключить записи списка
     * @param {Date} [specialFromStartDateIncludingDelays] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listSubrentElements(businessAccountId: number, subrentId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, excludeAggregations?: boolean, excludeRecords?: boolean, specialFromStartDateIncludingDelays?: Date, options?: any) {
        return DefaultApiFp(this.configuration).listSubrentElements(businessAccountId, subrentId, limit, startFrom, filters, sortBy, sortOrder, search, excludeAggregations, excludeRecords, specialFromStartDateIncludingDelays, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Список субаренды
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listSubrents(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any) {
        return DefaultApiFp(this.configuration).listSubrents(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение ограничений фильтров поставок на основе присутствующих данных
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listSubrentsAvailableFiltersValues(businessAccountId: number, filters?: Array<string>, options?: any) {
        return DefaultApiFp(this.configuration).listSubrentsAvailableFiltersValues(businessAccountId, filters, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Список наборов, продуктов и вариантов на интервале времени
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} from Начало целевого интервала времени, epoch millis
     * @param {number} until Начало целевого интервала времени, epoch millis
     * @param {boolean} includeRentElements Включать ли обязательства. В случае, если флаг true - добавляем их, если нет - выводим экземпляры со стеком доступности
     * @param {SummaryScheduleListRequestObject} summaryScheduleListRequestObject 
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {boolean} [includeInstanceSchedule] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listSummarySchedule(businessAccountId: number, from: number, until: number, includeRentElements: boolean, summaryScheduleListRequestObject: SummaryScheduleListRequestObject, limit?: number, startFrom?: number, includeInstanceSchedule?: boolean, options?: any) {
        return DefaultApiFp(this.configuration).listSummarySchedule(businessAccountId, from, until, includeRentElements, summaryScheduleListRequestObject, limit, startFrom, includeInstanceSchedule, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Список элементов шаблона
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} templateId Идентификатор шаблона
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {boolean} [excludeAggregations] Исключить аггрегации
     * @param {boolean} [excludeRecords] Исключить записи списка
     * @param {Date} [specialFromStartDateIncludingDelays] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listTemplateElements(businessAccountId: number, templateId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, excludeAggregations?: boolean, excludeRecords?: boolean, specialFromStartDateIncludingDelays?: Date, options?: any) {
        return DefaultApiFp(this.configuration).listTemplateElements(businessAccountId, templateId, limit, startFrom, filters, sortBy, sortOrder, search, excludeAggregations, excludeRecords, specialFromStartDateIncludingDelays, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Список шаблонов
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listTemplates(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any) {
        return DefaultApiFp(this.configuration).listTemplates(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение ограничения фильтров шаблона на основе присутствующих данных
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listTemplatesAvailableFiltersValues(businessAccountId: number, options?: any) {
        return DefaultApiFp(this.configuration).listTemplatesAvailableFiltersValues(businessAccountId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {string} [search] Поисковая строка
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listTransportations(businessAccountId: number, search?: string, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', filters?: Array<string>, options?: any) {
        return DefaultApiFp(this.configuration).listTransportations(businessAccountId, search, limit, startFrom, sortBy, sortOrder, filters, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение списка простых записей пользователей бизнес аккаунта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listUsersSimple(businessAccountId: number, limit?: number, startFrom?: number, options?: any) {
        return DefaultApiFp(this.configuration).listUsersSimple(businessAccountId, limit, startFrom, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {string} [search] Поисковая строка
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listVehicles(businessAccountId: number, search?: string, limit?: number, startFrom?: number, sortBy?: string, sortOrder?: 'ASC' | 'DESC', filters?: Array<string>, options?: any) {
        return DefaultApiFp(this.configuration).listVehicles(businessAccountId, search, limit, startFrom, sortBy, sortOrder, filters, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение ограничений фильтров по тс
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listVehiclesAvailableFilters(businessAccountId: number, options?: any) {
        return DefaultApiFp(this.configuration).listVehiclesAvailableFilters(businessAccountId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Список работ
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} [limit] Максимальное количество элементов
     * @param {number} [startFrom] Сдвиг
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {string} [search] Поисковая строка
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listWorkPlannings(businessAccountId: number, limit?: number, startFrom?: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', search?: string, options?: any) {
        return DefaultApiFp(this.configuration).listWorkPlannings(businessAccountId, limit, startFrom, filters, sortBy, sortOrder, search, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение ограничений фильтров работ на основе присутствующих данных
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listWorkPlanningsAvailableFiltersValues(businessAccountId: number, filters?: Array<string>, options?: any) {
        return DefaultApiFp(this.configuration).listWorkPlanningsAvailableFiltersValues(businessAccountId, filters, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Сгруппированные обязательства успешно получены
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {Array<string>} [filters] Фильтры. Значения: нечетные - имена фильтров, четные - значения фильтров
     * @param {string} [sortBy] Колонка сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, направление сортировки игнорируется
     * @param {'ASC' | 'DESC'} [sortOrder] Направление сортировки. Если не указан - сортировка по умолчанию для конкретного ресурса, колонка сортировки игнорируется
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listWorkPlanningsGroupedByCrewMember(businessAccountId: number, filters?: Array<string>, sortBy?: string, sortOrder?: 'ASC' | 'DESC', options?: any) {
        return DefaultApiFp(this.configuration).listWorkPlanningsGroupedByCrewMember(businessAccountId, filters, sortBy, sortOrder, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Выполнить миграцию данных
     * @param {number} businessAccountId 
     * @param {number} targetCreationAuthorId 
     * @param {any} jxlsXMLConfiguration 
     * @param {any} excelFile 
     * @param {any} massImportXMLConfiguration 
     * @param {number} [startFrom] 
     * @param {number} [count] 
     * @param {OnExistingEntityFound} [onExistingEntityFound] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public migrate(businessAccountId: number, targetCreationAuthorId: number, jxlsXMLConfiguration: any, excelFile: any, massImportXMLConfiguration: any, startFrom?: number, count?: number, onExistingEntityFound?: OnExistingEntityFound, options?: any) {
        return DefaultApiFp(this.configuration).migrate(businessAccountId, targetCreationAuthorId, jxlsXMLConfiguration, excelFile, massImportXMLConfiguration, startFrom, count, onExistingEntityFound, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Перенос затрат проекта в другой проект
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} projectId Идентификатор проекта
     * @param {number} targetProjectId Идентификатор целевого проекта (в который переносятся затраты)
     * @param {boolean} copy Признак копирования. Если да, то в целевой переносятся как черновики, в исходном не изменяются. Если нет, то в целевой переносятся как есть, в исходном отменяются
     * @param {VersionedEntityObjList} versionedEntityObjList 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public moveExpensesToOtherProject(businessAccountId: number, projectId: number, targetProjectId: number, copy: boolean, versionedEntityObjList: VersionedEntityObjList, options?: any) {
        return DefaultApiFp(this.configuration).moveExpensesToOtherProject(businessAccountId, projectId, targetProjectId, copy, versionedEntityObjList, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Перенос обязательств проекта в другой проект
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} projectId Идентификатор проекта
     * @param {number} targetProjectId Идентификатор целевого проекта (в который переносятся обязательства). Должен быть в работае, если исходный в работе; не должен быть новым, если исходный завершен
     * @param {boolean} copy Признак копирования. Если да, то в целевой переносятся как черновики, в исходном не изменяются. Если нет, то в целевой переносятся как есть, в исходном отменяются
     * @param {VersionedEntityObjList} versionedEntityObjList 
     * @param {boolean} [replaceInstancesWithAnonymous] Признак копирования с заменой именованных на анонимные. Используется только при копировании
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public moveProjectElementsToOtherProject(businessAccountId: number, projectId: number, targetProjectId: number, copy: boolean, versionedEntityObjList: VersionedEntityObjList, replaceInstancesWithAnonymous?: boolean, options?: any) {
        return DefaultApiFp(this.configuration).moveProjectElementsToOtherProject(businessAccountId, projectId, targetProjectId, copy, versionedEntityObjList, replaceInstancesWithAnonymous, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Перенос обязательств поставки в другую поставку
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} subrentId Идентификатор поставки
     * @param {number} targetSubrentId Идентификатор целевой поставки (в которую переносятся обязательства). Должна быть в работае, если исходная в работе; не должна быть новой, если исходная завершена
     * @param {boolean} copy Признак копирования. Если да, то в целевую переносятся как черновики, в исходном не изменяются. Если нет, то в целевую переносятся как есть, в исходном отменяются.
     * @param {VersionedEntityObjList} versionedEntityObjList 
     * @param {boolean} [replaceInstancesWithAnonymous] Признак копирования с заменой именованных на анонимные. Используется только при копировании
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public moveSubrentElementsToOtherSubrent(businessAccountId: number, subrentId: number, targetSubrentId: number, copy: boolean, versionedEntityObjList: VersionedEntityObjList, replaceInstancesWithAnonymous?: boolean, options?: any) {
        return DefaultApiFp(this.configuration).moveSubrentElementsToOtherSubrent(businessAccountId, subrentId, targetSubrentId, copy, versionedEntityObjList, replaceInstancesWithAnonymous, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Перенос работ проекта в другой проект
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} projectId Идентификатор проекта
     * @param {number} targetProjectId Идентификатор целевого проекта (в который переносятся работы)
     * @param {boolean} copy Признак копирования. Если да, то в целевой переносятся как черновики, в исходном не изменяются. Если нет, то в целевой переносятся как есть, в исходном отменяются
     * @param {VersionedEntityObjList} versionedEntityObjList 
     * @param {boolean} [saveCrewMembers] Признак копирования с сохранением работников. Используется только при копировании
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public moveWorkPlanningsToOtherProject(businessAccountId: number, projectId: number, targetProjectId: number, copy: boolean, versionedEntityObjList: VersionedEntityObjList, saveCrewMembers?: boolean, options?: any) {
        return DefaultApiFp(this.configuration).moveWorkPlanningsToOtherProject(businessAccountId, projectId, targetProjectId, copy, versionedEntityObjList, saveCrewMembers, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Список элементов бизнес аккаунта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} activityFrameId Идентификатор рамки активности - проекта, КП, поставки или шаблона
     * @param {RentActivityFrameTypeCodeEnum} activityFrameTypeCode 
     * @param {VersionedEntityObjList} versionedEntityObjList 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public recalculateBusinessAccountElementsPrice(businessAccountId: number, activityFrameId: number, activityFrameTypeCode: RentActivityFrameTypeCodeEnum, versionedEntityObjList: VersionedEntityObjList, options?: any) {
        return DefaultApiFp(this.configuration).recalculateBusinessAccountElementsPrice(businessAccountId, activityFrameId, activityFrameTypeCode, versionedEntityObjList, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Пересчет флага удаляемости у контактов внутри БА или у всех (если null)
     * @param {number} [businessAccountId] Id бизнес аккаунта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public recalculateContactsDeleteable(businessAccountId?: number, options?: any) {
        return DefaultApiFp(this.configuration).recalculateContactsDeleteable(businessAccountId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Пересчитать стоимость к оплате и долги по проектам
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public recalculateProjectsSumsToBePaidAndDebts(businessAccountId: number, options?: any) {
        return DefaultApiFp(this.configuration).recalculateProjectsSumsToBePaidAndDebts(businessAccountId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Пересчитать все показатели компании и нижестроящих сущностей (реиндекс только для измененных в процессе)
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {boolean} [fullRecountWarehousePresence] Пересчитывать ли всю номенклатуру инвентаря или только ту у которой есть что-то в аренде / субаренде
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public refreshBusinessAccount(businessAccountId: number, fullRecountWarehousePresence?: boolean, options?: any) {
        return DefaultApiFp(this.configuration).refreshBusinessAccount(businessAccountId, fullRecountWarehousePresence, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Пересчитать все показатели бизнес аккаунта (без пересчета нижестоящих сущностей и без реиндекса)
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public refreshBusinessAccountIndicators(businessAccountId: number, options?: any) {
        return DefaultApiFp(this.configuration).refreshBusinessAccountIndicators(businessAccountId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Пересчитать все показатели операции и нижестроящих сущностей, а также переиндексировать его
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public refreshOperation(businessAccountId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).refreshOperation(businessAccountId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Пересчитать все показатели продукта и нижестроящих сущностей, а также переиндексировать его
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public refreshProduct(businessAccountId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).refreshProduct(businessAccountId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Пересчитать все показатели проекта и нижестроящих сущностей, а также переиндексировать его
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public refreshProject(businessAccountId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).refreshProject(businessAccountId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Пересчитать все показатели элемента, а также переиндексировать его
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public refreshRentElement(businessAccountId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).refreshRentElement(businessAccountId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Пересчитать и переиндексировать все показатели арендатора и нижестроящих сущностей
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public refreshRenter(businessAccountId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).refreshRenter(businessAccountId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Пересчитать все показатели субаренды и нижестроящих сущностей, а также переиндексировать его
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public refreshSubrent(businessAccountId: number, id: number, options?: any) {
        return DefaultApiFp(this.configuration).refreshSubrent(businessAccountId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Пересоздание контактов пользователей
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {boolean} removeExistingContactUserLinks Удалить существующие пользовательские контакты перед пересозданием
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public regenerateBusinessAccountUserContacts(businessAccountId: number, removeExistingContactUserLinks: boolean, options?: any) {
        return DefaultApiFp(this.configuration).regenerateBusinessAccountUserContacts(businessAccountId, removeExistingContactUserLinks, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Пересоздание контактов пользователей
     * @param {boolean} removeExistingContactUserLinks Удалить существующие пользовательские контакты перед пересозданием
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public regenerateUserContactsGlobally(removeExistingContactUserLinks: boolean, options?: any) {
        return DefaultApiFp(this.configuration).regenerateUserContactsGlobally(removeExistingContactUserLinks, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Регистрация операции и получения параллельных с ней
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {ConcurrentOperationObj} concurrentOperationObj 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public registerAndListConcurrentOperations(businessAccountId: number, concurrentOperationObj: ConcurrentOperationObj, options?: any) {
        return DefaultApiFp(this.configuration).registerAndListConcurrentOperations(businessAccountId, concurrentOperationObj, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Регистрация нового бизнес аккаунта
     * @param {number} timezoneOffsetUTC Временная зона
     * @param {BusinessAccountRegistrationObj} businessAccountRegistrationObj 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public registerBusinessAccount(timezoneOffsetUTC: number, businessAccountRegistrationObj: BusinessAccountRegistrationObj, options?: any) {
        return DefaultApiFp(this.configuration).registerBusinessAccount(timezoneOffsetUTC, businessAccountRegistrationObj, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Создание организации в системе SSO авторизации и привязка к бизнес аккаунту
     * @param {string} accessToken Обязательный токен доступа в формате UUID
     * @param {SsoClientOrganizationInfoWrite} ssoClientOrganizationInfoWrite 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public registerSsoClientOrganization(accessToken: string, ssoClientOrganizationInfoWrite: SsoClientOrganizationInfoWrite, options?: any) {
        return DefaultApiFp(this.configuration).registerSsoClientOrganization(accessToken, ssoClientOrganizationInfoWrite, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Удаление из списка закрытой операции
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {string} uuid Временный UUID идентификатор операции
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public removeConcurrentOperation(businessAccountId: number, uuid: string, options?: any) {
        return DefaultApiFp(this.configuration).removeConcurrentOperation(businessAccountId, uuid, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Получение списка релевантных адресов
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {string} addressQuery Строка с частью адреса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public suggestAddresses(businessAccountId: number, addressQuery: string, options?: any) {
        return DefaultApiFp(this.configuration).suggestAddresses(businessAccountId, addressQuery, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Изменение статуса и архивация бизнес аккаунта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {WorkflowDirective} workflowDirective 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public transitBusinessAccountWorkflow(businessAccountId: number, workflowDirective: WorkflowDirective, options?: any) {
        return DefaultApiFp(this.configuration).transitBusinessAccountWorkflow(businessAccountId, workflowDirective, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Массовое изменение бизнес аккаунтов - статусы и архивирование
     * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public transitBusinessAccountWorkflowBulk(workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any) {
        return DefaultApiFp(this.configuration).transitBusinessAccountWorkflowBulk(workflowDirectiveBulk, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Массовое изменение контактов - архивирование и разархивирование
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public transitContactWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any) {
        return DefaultApiFp(this.configuration).transitContactWorkflowBulk(businessAccountId, workflowDirectiveBulk, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Массовое изменение кадров - статусы и архивирование
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public transitCrewMemberWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any) {
        return DefaultApiFp(this.configuration).transitCrewMemberWorkflowBulk(businessAccountId, workflowDirectiveBulk, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Массовое изменение затрат - статусы
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public transitExpenseWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any) {
        return DefaultApiFp(this.configuration).transitExpenseWorkflowBulk(businessAccountId, workflowDirectiveBulk, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Массовое изменение наборы - статусы и архивирование
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public transitKitWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any) {
        return DefaultApiFp(this.configuration).transitKitWorkflowBulk(businessAccountId, workflowDirectiveBulk, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Массовое изменение локаций - архивирование и разархивирование
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public transitLocationWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any) {
        return DefaultApiFp(this.configuration).transitLocationWorkflowBulk(businessAccountId, workflowDirectiveBulk, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Массовое изменение номенклатуры - статусы и архивирование
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {NomenclatureWorkflowDirectiveBulk} nomenclatureWorkflowDirectiveBulk 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public transitNomenclatureWorkflowBulk(businessAccountId: number, nomenclatureWorkflowDirectiveBulk: NomenclatureWorkflowDirectiveBulk, options?: any) {
        return DefaultApiFp(this.configuration).transitNomenclatureWorkflowBulk(businessAccountId, nomenclatureWorkflowDirectiveBulk, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Массовое изменение способов оплаты - только архивирование
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public transitPaymentMethodsWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any) {
        return DefaultApiFp(this.configuration).transitPaymentMethodsWorkflowBulk(businessAccountId, workflowDirectiveBulk, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Массовое изменение статуса платежа в жизненном цикле
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public transitPaymentsWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any) {
        return DefaultApiFp(this.configuration).transitPaymentsWorkflowBulk(businessAccountId, workflowDirectiveBulk, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Массовое изменение экземпляров продукта - только статусы
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public transitProductInstanceWorkflowBulk(businessAccountId: number, id: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any) {
        return DefaultApiFp(this.configuration).transitProductInstanceWorkflowBulk(businessAccountId, id, workflowDirectiveBulk, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Массовое изменение вариантов - только архивирование
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public transitProductVariantWorkflowBulk(businessAccountId: number, id: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any) {
        return DefaultApiFp(this.configuration).transitProductVariantWorkflowBulk(businessAccountId, id, workflowDirectiveBulk, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Массовое изменение продуктов - статусы и архивирование
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public transitProductWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any) {
        return DefaultApiFp(this.configuration).transitProductWorkflowBulk(businessAccountId, workflowDirectiveBulk, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Массовое изменение профессий - статусы и архивирование
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public transitProfessionWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any) {
        return DefaultApiFp(this.configuration).transitProfessionWorkflowBulk(businessAccountId, workflowDirectiveBulk, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Массовое изменение проектов - статусы и архивирование
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public transitProjectWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any) {
        return DefaultApiFp(this.configuration).transitProjectWorkflowBulk(businessAccountId, workflowDirectiveBulk, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Массовое изменение аредаторов - статусы и архивирование
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public transitRenterWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any) {
        return DefaultApiFp(this.configuration).transitRenterWorkflowBulk(businessAccountId, workflowDirectiveBulk, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Массовое изменение субаренд - статусы и архивирование
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public transitSubrentWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any) {
        return DefaultApiFp(this.configuration).transitSubrentWorkflowBulk(businessAccountId, workflowDirectiveBulk, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Массовое изменение шаблонов - статусы и архивирование
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public transitTemplateWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any) {
        return DefaultApiFp(this.configuration).transitTemplateWorkflowBulk(businessAccountId, workflowDirectiveBulk, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Массовое изменение локаций - архивирование и разархивирование
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public transitTransportationWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any) {
        return DefaultApiFp(this.configuration).transitTransportationWorkflowBulk(businessAccountId, workflowDirectiveBulk, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Массовое изменение локаций - архивирование и разархивирование
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public transitVehicleWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any) {
        return DefaultApiFp(this.configuration).transitVehicleWorkflowBulk(businessAccountId, workflowDirectiveBulk, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Массовое изменение событий работ
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {WorkflowDirectiveBulk} workflowDirectiveBulk 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public transitWorkPlanningWorkflowBulk(businessAccountId: number, workflowDirectiveBulk: WorkflowDirectiveBulk, options?: any) {
        return DefaultApiFp(this.configuration).transitWorkPlanningWorkflowBulk(businessAccountId, workflowDirectiveBulk, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Изменение информации о вложении
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {AttachmentInfoUpdate} attachmentInfoUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateAttachmentById(businessAccountId: number, id: number, attachmentInfoUpdate: AttachmentInfoUpdate, options?: any) {
        return DefaultApiFp(this.configuration).updateAttachmentById(businessAccountId, id, attachmentInfoUpdate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Изменение состояния бизнес аккаунта (полный доступ к данным)
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {BusinessAccountInfoUpdate} businessAccountInfoUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateBusinessAccountById(businessAccountId: number, businessAccountInfoUpdate: BusinessAccountInfoUpdate, options?: any) {
        return DefaultApiFp(this.configuration).updateBusinessAccountById(businessAccountId, businessAccountInfoUpdate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Изменение контактных данных бизнес аккаунта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {BusinessAccountContactsInfoUpdate} businessAccountContactsInfoUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateBusinessAccountContactsById(businessAccountId: number, businessAccountContactsInfoUpdate: BusinessAccountContactsInfoUpdate, options?: any) {
        return DefaultApiFp(this.configuration).updateBusinessAccountContactsById(businessAccountId, businessAccountContactsInfoUpdate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Изменение основных настроек бизнес аккаунта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {BusinessAccountPreferencesInfoUpdate} businessAccountPreferencesInfoUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateBusinessAccountPreferences(businessAccountId: number, businessAccountPreferencesInfoUpdate: BusinessAccountPreferencesInfoUpdate, options?: any) {
        return DefaultApiFp(this.configuration).updateBusinessAccountPreferences(businessAccountId, businessAccountPreferencesInfoUpdate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Изменение состояния категории
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {CategoryInfoUpdate} categoryInfoUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateCategoryById(businessAccountId: number, id: number, categoryInfoUpdate: CategoryInfoUpdate, options?: any) {
        return DefaultApiFp(this.configuration).updateCategoryById(businessAccountId, id, categoryInfoUpdate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Изменение состояния категории с явным указанием полей
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {Array<object>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateCategoryExplicit(businessAccountId: number, requestBody: Array<object>, options?: any) {
        return DefaultApiFp(this.configuration).updateCategoryExplicit(businessAccountId, requestBody, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Изменение профиля клиентского пользователя
     * @param {ClientUserInfoUpdate} clientUserInfoUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateClientUser(clientUserInfoUpdate: ClientUserInfoUpdate, options?: any) {
        return DefaultApiFp(this.configuration).updateClientUser(clientUserInfoUpdate, options)(this.axios, this.basePath);
    }

    /**
     * Обновляет стандартный шаблон документа и сущность в бд
     * @summary Обновляет стандартный шаблон документа
     * @param {number} id Идентификатор сущности
     * @param {any} file 
     * @param {boolean} ignoreFile Если true, изменение контента шаблона игнорируется
     * @param {DocumentTemplateInfoUpdate} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateCommonDocumentTemplate(id: number, file: any, ignoreFile: boolean, data: DocumentTemplateInfoUpdate, options?: any) {
        return DefaultApiFp(this.configuration).updateCommonDocumentTemplate(id, file, ignoreFile, data, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Обновление контента стандартного шаблона документа
     * @param {number} id Идентификатор сущности
     * @param {any} file 
     * @param {number} businessVersion 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateCommonDocumentTemplateContent(id: number, file: any, businessVersion: number, options?: any) {
        return DefaultApiFp(this.configuration).updateCommonDocumentTemplateContent(id, file, businessVersion, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Изменение контакта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {ContactInfoUpdate} contactInfoUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateContactById(businessAccountId: number, id: number, contactInfoUpdate: ContactInfoUpdate, options?: any) {
        return DefaultApiFp(this.configuration).updateContactById(businessAccountId, id, contactInfoUpdate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Изменение кадра
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {CrewMemberInfoUpdate} crewMemberInfoUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateCrewMember(businessAccountId: number, id: number, crewMemberInfoUpdate: CrewMemberInfoUpdate, options?: any) {
        return DefaultApiFp(this.configuration).updateCrewMember(businessAccountId, id, crewMemberInfoUpdate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Изменение ставки кадра
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {Array<ProfessionAssignmentObjWrite>} professionAssignmentObjWrite 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateCrewMemberRates(businessAccountId: number, id: number, professionAssignmentObjWrite: Array<ProfessionAssignmentObjWrite>, options?: any) {
        return DefaultApiFp(this.configuration).updateCrewMemberRates(businessAccountId, id, professionAssignmentObjWrite, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Изменение состояния настроек уведомлений текущего пользователя
     * @param {NotificationSchemaInfoUpdate} notificationSchemaInfoUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateCurrentNotificationSchema(notificationSchemaInfoUpdate: NotificationSchemaInfoUpdate, options?: any) {
        return DefaultApiFp(this.configuration).updateCurrentNotificationSchema(notificationSchemaInfoUpdate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Изменение пользовательского поля
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {CustomFieldInfoUpdate} customFieldInfoUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateCustomFieldById(businessAccountId: number, id: number, customFieldInfoUpdate: CustomFieldInfoUpdate, options?: any) {
        return DefaultApiFp(this.configuration).updateCustomFieldById(businessAccountId, id, customFieldInfoUpdate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Изменение всех пользовательских полей по типу сущности
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {string} nomenclatureType Тип номенклатуры для печати
     * @param {CustomFieldsGlobalObjWrite} customFieldsGlobalObjWrite 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateCustomFieldsByEntityType(businessAccountId: number, id: number, nomenclatureType: string, customFieldsGlobalObjWrite: CustomFieldsGlobalObjWrite, options?: any) {
        return DefaultApiFp(this.configuration).updateCustomFieldsByEntityType(businessAccountId, id, nomenclatureType, customFieldsGlobalObjWrite, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Изменение привязки кастомных полей к продуктам
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {boolean} heckOnly Просто проверка, если true - изменений не производится, только подсчет сущностей, которые будут заторнуты
     * @param {UpdateLinksByCategoryObj} updateLinksByCategoryObj 
     * @param {boolean} [linkVariants] Привязать поля к вариантам продукта, если он поддерживает варианты. Используется только при привязке
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateCustomFieldsLinks(businessAccountId: number, heckOnly: boolean, updateLinksByCategoryObj: UpdateLinksByCategoryObj, linkVariants?: boolean, options?: any) {
        return DefaultApiFp(this.configuration).updateCustomFieldsLinks(businessAccountId, heckOnly, updateLinksByCategoryObj, linkVariants, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Обновление существующего шаблона документа
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {any} file 
     * @param {boolean} ignoreFile Если true, изменение контента шаблона игнорируется
     * @param {DocumentTemplateInfoUpdate} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateDocumentTemplate(businessAccountId: number, id: number, file: any, ignoreFile: boolean, data: DocumentTemplateInfoUpdate, options?: any) {
        return DefaultApiFp(this.configuration).updateDocumentTemplate(businessAccountId, id, file, ignoreFile, data, options)(this.axios, this.basePath);
    }

    /**
     * Обновление настроек для шаблона
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {boolean} common Флаг типа шаблона
     * @param {number} businessVersion Бизнес версия
     * @param {boolean} hidden Флаг является ли шаблон скрытым в БА
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateDocumentTemplateBusinessAccountPreferences(businessAccountId: number, id: number, common: boolean, businessVersion: number, hidden: boolean, options?: any) {
        return DefaultApiFp(this.configuration).updateDocumentTemplateBusinessAccountPreferences(businessAccountId, id, common, businessVersion, hidden, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Обновление контента пользовательского шаблона документа
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {any} file 
     * @param {number} businessVersion 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateDocumentTemplateContent(businessAccountId: number, id: number, file: any, businessVersion: number, options?: any) {
        return DefaultApiFp(this.configuration).updateDocumentTemplateContent(businessAccountId, id, file, businessVersion, options)(this.axios, this.basePath);
    }

    /**
     * Изменение сущности затраты по проекту
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {ExpenseInfoUpdate} expenseInfoUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateExpense(businessAccountId: number, id: number, expenseInfoUpdate: ExpenseInfoUpdate, options?: any) {
        return DefaultApiFp(this.configuration).updateExpense(businessAccountId, id, expenseInfoUpdate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Изменение шаблона для экспорта
     * @param {number} id Идентификатор сущности
     * @param {ExportTemplateInfoUpdate} model 
     * @param {any} [content] 
     * @param {any} [jasper] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateExportTemplate(id: number, model: ExportTemplateInfoUpdate, content?: any, jasper?: any, options?: any) {
        return DefaultApiFp(this.configuration).updateExportTemplate(id, model, content, jasper, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Изменение события движения инвентаря
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {InventoryMovementInfoUpdate} inventoryMovementInfoUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateInventoryMovementById(businessAccountId: number, id: number, inventoryMovementInfoUpdate: InventoryMovementInfoUpdate, options?: any) {
        return DefaultApiFp(this.configuration).updateInventoryMovementById(businessAccountId, id, inventoryMovementInfoUpdate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Изменение состояния набора
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {KitInfoUpdate} kitInfoUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateKitById(businessAccountId: number, id: number, kitInfoUpdate: KitInfoUpdate, options?: any) {
        return DefaultApiFp(this.configuration).updateKitById(businessAccountId, id, kitInfoUpdate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Изменение состояния объекта субъектов и реквизитов
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {LegalDetailsInfoWrite} legalDetailsInfoWrite 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateLegalDetailById(businessAccountId: number, id: number, legalDetailsInfoWrite: LegalDetailsInfoWrite, options?: any) {
        return DefaultApiFp(this.configuration).updateLegalDetailById(businessAccountId, id, legalDetailsInfoWrite, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Изменение локации
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {LocationInfoUpdate} locationInfoUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateLocationById(businessAccountId: number, id: number, locationInfoUpdate: LocationInfoUpdate, options?: any) {
        return DefaultApiFp(this.configuration).updateLocationById(businessAccountId, id, locationInfoUpdate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Изменение состояния операции
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {OperationInfoUpdate} operationInfoUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateOperationById(businessAccountId: number, id: number, operationInfoUpdate: OperationInfoUpdate, options?: any) {
        return DefaultApiFp(this.configuration).updateOperationById(businessAccountId, id, operationInfoUpdate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Редактирование простого заказа
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {SimpleOrderInfoUpdate} simpleOrderInfoUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateOrderWithRentElements(businessAccountId: number, id: number, simpleOrderInfoUpdate: SimpleOrderInfoUpdate, options?: any) {
        return DefaultApiFp(this.configuration).updateOrderWithRentElements(businessAccountId, id, simpleOrderInfoUpdate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Изменение данных о платеже
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {PaymentInfoUpdate} paymentInfoUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updatePaymentById(businessAccountId: number, id: number, paymentInfoUpdate: PaymentInfoUpdate, options?: any) {
        return DefaultApiFp(this.configuration).updatePaymentById(businessAccountId, id, paymentInfoUpdate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Изменение состояния способа оплаты
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {PaymentMethodInfoUpdate} paymentMethodInfoUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updatePaymentMethodById(businessAccountId: number, id: number, paymentMethodInfoUpdate: PaymentMethodInfoUpdate, options?: any) {
        return DefaultApiFp(this.configuration).updatePaymentMethodById(businessAccountId, id, paymentMethodInfoUpdate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Изменение ценовой схемы
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {PricingSchemeInfoUpdate} pricingSchemeInfoUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updatePricingSchemeById(businessAccountId: number, id: number, pricingSchemeInfoUpdate: PricingSchemeInfoUpdate, options?: any) {
        return DefaultApiFp(this.configuration).updatePricingSchemeById(businessAccountId, id, pricingSchemeInfoUpdate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Изменение привязки ценовых схем к продуктам
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {UpdateLinksByCategoryObj} updateLinksByCategoryObj 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updatePricingSchemesLinks(businessAccountId: number, updateLinksByCategoryObj: UpdateLinksByCategoryObj, options?: any) {
        return DefaultApiFp(this.configuration).updatePricingSchemesLinks(businessAccountId, updateLinksByCategoryObj, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Изменение состояния продукта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {ProductInfoUpdate} productInfoUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateProductById(businessAccountId: number, id: number, productInfoUpdate: ProductInfoUpdate, options?: any) {
        return DefaultApiFp(this.configuration).updateProductById(businessAccountId, id, productInfoUpdate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Изменение состояния экземпляра продукта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} productId Идентификатор продукта
     * @param {number} id Идентификатор сущности
     * @param {InstanceInfoUpdate} instanceInfoUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateProductInstanceById(businessAccountId: number, productId: number, id: number, instanceInfoUpdate: InstanceInfoUpdate, options?: any) {
        return DefaultApiFp(this.configuration).updateProductInstanceById(businessAccountId, productId, id, instanceInfoUpdate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Изменение состояния варианта продукта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} productId Идентификатор продукта
     * @param {number} id Идентификатор сущности
     * @param {VariantInfoUpdate} variantInfoUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateProductVariantById(businessAccountId: number, productId: number, id: number, variantInfoUpdate: VariantInfoUpdate, options?: any) {
        return DefaultApiFp(this.configuration).updateProductVariantById(businessAccountId, productId, id, variantInfoUpdate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Изменение состояния экземпляра варианта продукта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} productId Идентификатор продукта
     * @param {number} variantId Идентификатор варианта
     * @param {number} id Идентификатор сущности
     * @param {InstanceInfoUpdate} instanceInfoUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateProductVariantInstanceById(businessAccountId: number, productId: number, variantId: number, id: number, instanceInfoUpdate: InstanceInfoUpdate, options?: any) {
        return DefaultApiFp(this.configuration).updateProductVariantInstanceById(businessAccountId, productId, variantId, id, instanceInfoUpdate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Изменение профессии
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {ProfessionInfoUpdate} professionInfoUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateProfessionById(businessAccountId: number, id: number, professionInfoUpdate: ProfessionInfoUpdate, options?: any) {
        return DefaultApiFp(this.configuration).updateProfessionById(businessAccountId, id, professionInfoUpdate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Обновление профиля пользователя
     * @param {UserInfoUpdate} userInfoUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateProfileData(userInfoUpdate: UserInfoUpdate, options?: any) {
        return DefaultApiFp(this.configuration).updateProfileData(userInfoUpdate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Обновление пароля профиля
     * @param {UpdatePassword} updatePassword 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateProfilePassword(updatePassword: UpdatePassword, options?: any) {
        return DefaultApiFp(this.configuration).updateProfilePassword(updatePassword, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Изменение состояния проекта
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {ProjectInfoUpdate} projectInfoUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateProjectById(businessAccountId: number, id: number, projectInfoUpdate: ProjectInfoUpdate, options?: any) {
        return DefaultApiFp(this.configuration).updateProjectById(businessAccountId, id, projectInfoUpdate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Изменение состояния проекта с явным указанием полей
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {Array<object>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateProjectByIdExplicit(businessAccountId: number, id: number, requestBody: Array<object>, options?: any) {
        return DefaultApiFp(this.configuration).updateProjectByIdExplicit(businessAccountId, id, requestBody, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Изменение состояния арендатора
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {RenterInfoUpdate} renterInfoUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateRenterById(businessAccountId: number, id: number, renterInfoUpdate: RenterInfoUpdate, options?: any) {
        return DefaultApiFp(this.configuration).updateRenterById(businessAccountId, id, renterInfoUpdate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Изменение состояния арендатора с явным указанием полей
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {Array<object>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateRenterByIdExplicit(businessAccountId: number, id: number, requestBody: Array<object>, options?: any) {
        return DefaultApiFp(this.configuration).updateRenterByIdExplicit(businessAccountId, id, requestBody, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Обновление графической визуализации отчета
     * @param {number} reportId 
     * @param {number} id Идентификатор сущности
     * @param {ReportChartVisualizationInfoUpdate} [reportChartVisualizationInfoUpdate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateReportChartVisualizationById(reportId: number, id: number, reportChartVisualizationInfoUpdate?: ReportChartVisualizationInfoUpdate, options?: any) {
        return DefaultApiFp(this.configuration).updateReportChartVisualizationById(reportId, id, reportChartVisualizationInfoUpdate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Изменение конфигурации отчета
     * @param {number} id Идентификатор сущности
     * @param {ReportConfigurationInfoUpdate} model 
     * @param {any} [content] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateReportConfiguration(id: number, model: ReportConfigurationInfoUpdate, content?: any, options?: any) {
        return DefaultApiFp(this.configuration).updateReportConfiguration(id, model, content, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Обновление Эксель визуализации отчета
     * @param {number} reportId 
     * @param {number} id Идентификатор сущности
     * @param {ReportExcelVisualizationInfoUpdate} model 
     * @param {any} [content] 
     * @param {any} [jasper] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateReportExcelVisualizationById(reportId: number, id: number, model: ReportExcelVisualizationInfoUpdate, content?: any, jasper?: any, options?: any) {
        return DefaultApiFp(this.configuration).updateReportExcelVisualizationById(reportId, id, model, content, jasper, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Обновление организации в системе SSO авторизации для бизнес аккаунта
     * @param {string} accessToken Обязательный токен доступа в формате UUID
     * @param {SsoClientOrganizationInfoWrite} ssoClientOrganizationInfoWrite 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateSsoClientOrganization(accessToken: string, ssoClientOrganizationInfoWrite: SsoClientOrganizationInfoWrite, options?: any) {
        return DefaultApiFp(this.configuration).updateSsoClientOrganization(accessToken, ssoClientOrganizationInfoWrite, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Изменение состояния субаренды
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {SubrentInfoUpdate} subrentInfoUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateSubrentById(businessAccountId: number, id: number, subrentInfoUpdate: SubrentInfoUpdate, options?: any) {
        return DefaultApiFp(this.configuration).updateSubrentById(businessAccountId, id, subrentInfoUpdate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Изменение состояния системной настройки
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {SystemOptionInfoUpdate} systemOptionInfoUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateSystemOptionById(businessAccountId: number, id: number, systemOptionInfoUpdate: SystemOptionInfoUpdate, options?: any) {
        return DefaultApiFp(this.configuration).updateSystemOptionById(businessAccountId, id, systemOptionInfoUpdate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Изменение состояния системной настройки
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {SystemOptionInfoUpdateBulk} systemOptionInfoUpdateBulk 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateSystemOptionsBulk(businessAccountId: number, id: number, systemOptionInfoUpdateBulk: SystemOptionInfoUpdateBulk, options?: any) {
        return DefaultApiFp(this.configuration).updateSystemOptionsBulk(businessAccountId, id, systemOptionInfoUpdateBulk, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Изменение состояния шаблона
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {TemplateInfoUpdate} templateInfoUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateTemplateById(businessAccountId: number, id: number, templateInfoUpdate: TemplateInfoUpdate, options?: any) {
        return DefaultApiFp(this.configuration).updateTemplateById(businessAccountId, id, templateInfoUpdate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Изменение перевозки
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {TransportationInfoUpdate} transportationInfoUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateTransportationById(businessAccountId: number, id: number, transportationInfoUpdate: TransportationInfoUpdate, options?: any) {
        return DefaultApiFp(this.configuration).updateTransportationById(businessAccountId, id, transportationInfoUpdate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Изменение транспортного средства
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {VehicleInfoUpdate} vehicleInfoUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateVehicleById(businessAccountId: number, id: number, vehicleInfoUpdate: VehicleInfoUpdate, options?: any) {
        return DefaultApiFp(this.configuration).updateVehicleById(businessAccountId, id, vehicleInfoUpdate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Изменить описание работы
     * @param {number} businessAccountId Идентификатор бизнес аккаунта
     * @param {number} id Идентификатор сущности
     * @param {WorkPlanningsInfoUpdate} workPlanningsInfoUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateWorkPlanning(businessAccountId: number, id: number, workPlanningsInfoUpdate: WorkPlanningsInfoUpdate, options?: any) {
        return DefaultApiFp(this.configuration).updateWorkPlanning(businessAccountId, id, workPlanningsInfoUpdate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Загрузка kb-json
     * @param {KnowledgeBaseJson} [knowledgeBaseJson] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public uploadKbJson(knowledgeBaseJson?: KnowledgeBaseJson, options?: any) {
        return DefaultApiFp(this.configuration).uploadKbJson(knowledgeBaseJson, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Загрузка переводов
     * @param {any} content 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public uploadSystemWideTranslations(content: any, options?: any) {
        return DefaultApiFp(this.configuration).uploadSystemWideTranslations(content, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Валидация данных к миграции
     * @param {number} businessAccountId 
     * @param {number} targetCreationAuthorId 
     * @param {any} jxlsXMLConfiguration 
     * @param {any} excelFile 
     * @param {any} massImportXMLConfiguration 
     * @param {OnExistingEntityFound} onExistingEntityFound 
     * @param {number} [startFrom] 
     * @param {number} [count] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public validateMigrationPolicy(businessAccountId: number, targetCreationAuthorId: number, jxlsXMLConfiguration: any, excelFile: any, massImportXMLConfiguration: any, onExistingEntityFound: OnExistingEntityFound, startFrom?: number, count?: number, options?: any) {
        return DefaultApiFp(this.configuration).validateMigrationPolicy(businessAccountId, targetCreationAuthorId, jxlsXMLConfiguration, excelFile, massImportXMLConfiguration, onExistingEntityFound, startFrom, count, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Проверка допустимости email в профиле (не используется другим пользователем, принадлежность к текущему пользователю допустима). Необходим JWT токен пользователя
     * @param {string} email Email пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public validateUniqueEmail(email: string, options?: any) {
        return DefaultApiFp(this.configuration).validateUniqueEmail(email, options)(this.axios, this.basePath);
    }

}




import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../shared/reducers/system.reducer';
import { declOfNum } from '../../../../../../components/link/toPayments/LinkToPayments';
import { IRootState } from '../../../../../../shared/reducers';

interface Props {
    projectsCount?: number;
    ordersCount?: number;
    renterId?: number;
    totalDebt?: number;
}

const DebtLinks = ({ projectsCount, ordersCount, renterId, totalDebt }: Props) => {
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    const includeBooked = useAppSelector(
        (state: IRootState) => state.businessAccountPreferences.preferences?.includeBookedElementsInProjectSum
    );

    if (!renterId || !totalDebt) return null;

    const projectStates = 'COORDINATION,IN_PROGRESS,FINISHED';
    const orderStates = 'RENTED,FINISHED' + (includeBooked ? ',BOOKED' : '');

    const params = encodeURI(`&debtSum=.01,${Number.MAX_SAFE_INTEGER}&sortBy=debtSum&sortOrder=DESC`);

    const projectsLink = projectsCount ? (
        <Link to={`/${businessAccountId}/crm/counterparties/${renterId}?tab=projects${params}&status=${projectStates}`}>
            {projectsCount} {declOfNum(projectsCount, ['проектов', 'проект', 'проекта'])}
        </Link>
    ) : null;

    const ordersLink = ordersCount ? (
        <Link to={`/${businessAccountId}/crm/counterparties/${renterId}?tab=simpleOrders${params}&status=${orderStates}`}>
            {ordersCount} {declOfNum(ordersCount, ['заказов', 'заказ', 'заказа'])}
        </Link>
    ) : null;

    return projectsLink || ordersLink ? (
        <div style={{ fontWeight: 400, fontSize: 14 }}>
            {ordersLink}
            {!!ordersLink && !!projectsCount && ', '}
            {projectsLink} с долгом
        </div>
    ) : null;
};
export default DebtLinks;

import React, {FC} from 'react';
import {RangePicker, RangePickerProps} from "../../../../../../components/v2/calendar/rangePicker/rangePicker";

interface Props {
    value?: RangePickerProps['value'];
    onChange?: RangePickerProps['onChange'];
    disabled?: boolean;
}

export const Picker: FC<Props> = (props) => {

    const key = 'simpleOrder';

    return (
        <RangePicker
            disabled={props.disabled}
            value={props.value}
            showTime
            onChange={props.onChange}
            style={{width: 420}}
            storeKey={key}
            recalculateShiftCountBlockProps={{
                hideRecalculateIcon: true
            }}
            pricingScheme={undefined}
        />
    );
};

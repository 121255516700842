import { GridProps } from '../../../grid/Grid';
import { MutableRefObject, useMemo } from 'react';
import { FormFieldsGroup } from '../../../dynamicForm/DynamicForm';
import { useFetchEntityErrorCallback, useGridFiltersChanges } from './useFiltersChanges';
import { AvailableIntervalFiltersData } from '../types/api';
import { ListParams, URLDrawerParams } from '../types/params';
import { EntityGridName } from '../types/grid';
import { useProcessedFiltersDataWithIntervals } from './useFiltersProcessed';
import { usePageUrlParamsContext } from '../components/context/PageUrlParamsContext';
import { CustomFieldObjRead } from '../../../../server';

type GridFiltersPropsRequiredFields =
    | 'filtered'
    | 'filtersCurrentValues'
    | 'filtersData'
    | 'filtersDefaultValues'
    | 'filtersGetFiltersFormRef'
    | 'filtersOnChange'
    | 'filtersResetFiltersCb'
    | 'fetchEntityErrorCallback'
    | 'filtersOnHideCustomValue';

export interface useEntityGridFiltersProps<EntityRecord extends object> {
    filtersData: FormFieldsGroup[];
    availableIntervalFiltersData: AvailableIntervalFiltersData<EntityRecord>;
    filteredCount?: number;
    filtersRef: MutableRefObject<any>;
    getFiltersForm: Function;
    gridName: EntityGridName;
    isFormLoadedRef: MutableRefObject<boolean>;
    customFieldMarkers: CustomFieldObjRead[] | undefined;
    clearSelection: (()=>void) | undefined;
}

export interface EntityGridFilters<PageParams extends object> extends Required<Pick<GridProps, GridFiltersPropsRequiredFields>> {
    filtersCurrentValues: PageParams & ListParams;
    filtersInitialValues: Partial<PageParams & ListParams>;
    filtersDefaultValues: Partial<PageParams & ListParams>;
}

export const useFilters = <PageParams extends URLDrawerParams & ListParams, EntityRecord extends object>(
    props: useEntityGridFiltersProps<EntityRecord>
): EntityGridFilters<PageParams> => {
    const { pageParams } = usePageUrlParamsContext<PageParams>();

    const {
        filtersData,
        availableIntervalFiltersData,
        filteredCount,
        filtersRef,
        getFiltersForm,
        gridName,
        isFormLoadedRef,
        customFieldMarkers,
        clearSelection
    } = props;

    const { processedFiltersData, processedFiltersInitialValues, processedFiltersCurrentValues, processedFiltersDefaultValues } =
        useProcessedFiltersDataWithIntervals({
            filtersData,
            pageParams,
            availableIntervalFiltersData,
            customFieldMarkers,
        });

    const { onFiltersChange, resetFilters, onHideCustomValue } = useGridFiltersChanges({
        processedFiltersInitialValues,
        availableIntervalFiltersData,
        getFiltersForm,
        isFormLoadedRef,
        clearSelection
    });

    const fetchEntityErrorCallback = useFetchEntityErrorCallback({
        pageParams: processedFiltersCurrentValues,
        gridName,
        getFiltersForm,
    });

    return useMemo(() => {
        return {
            fetchEntityErrorCallback,
            filtered: filteredCount ?? 0,
            filtersCurrentValues: processedFiltersCurrentValues,
            filtersData: processedFiltersData,
            filtersDefaultValues: processedFiltersDefaultValues,
            filtersGetFiltersFormRef: filtersRef as any,
            filtersInitialValues: processedFiltersInitialValues,
            filtersOnChange: onFiltersChange,
            filtersOnHideCustomValue: onHideCustomValue,
            filtersResetFiltersCb: resetFilters,
        };
    }, [
        filteredCount,
        processedFiltersCurrentValues,
        processedFiltersInitialValues,
        processedFiltersDefaultValues,
        processedFiltersData,
        filtersRef,
        onFiltersChange,
        resetFilters,
        fetchEntityErrorCallback,
        onHideCustomValue,
    ]);
};

import React from 'react';
import {CardProps} from 'antd/lib/card';
import {formatMoney} from '../../../../shared/util/formatMoney';
import FormValue from '../../../../components/formValue/FormValue';
import './kit-indicators-block.less';
import {LocalizationEnum, localize} from '../../../../localization';
import {FormatPercents} from '../../../../components/formatPercents/formatPercents';
import {useAppSelector} from "../../../../store/hooks";
import {canViewFinancialDataSelector} from "../../../../shared/reducers/permissions.reducer";

interface KitIndicatorsBlockProps extends CardProps {
    instanceCount: number;
    price: number;
    discount: number;
    finalTotalPrice: number;
    discountPercent: number;
}

export const KitIndicatorsBlock = (props: KitIndicatorsBlockProps) => {

    const {instanceCount, price, discountPercent, discount, finalTotalPrice} = props;
    const canViewFinancialData = useAppSelector(canViewFinancialDataSelector);

    const instanceCountBlock = (
        <div
            className={'rr-form-item rr-kit-indicators-block-item rr-kit-indicators-item1'}
            style={{margin: '0px', borderRight: '1px solid #eaeaea'}}
        >
            <div>
                <div className="rr-label">
                    {localize(LocalizationEnum.ASPECT__RENT_INDICATORS__INSTANCE_COUNT, undefined, {value: 0})}
                </div>
                <div className="rr-value">
                    <FormValue value={instanceCount} originalValue={instanceCount}/>
                </div>
            </div>
        </div>
    );

    const blockWithoutFinancialData = (
        <div style={{width: '100%', display: 'flex', justifyContent: 'end'}}>
            <div style={{backgroundColor: '#FFFFFF', marginTop: 30, padding: '12px 0px', paddingLeft: 32, width: 220}}>
                <div>
                    <div className="rr-label">
                        {localize(LocalizationEnum.ASPECT__RENT_INDICATORS__INSTANCE_COUNT, undefined, {value: 0})}
                    </div>
                    <div className="rr-value">
                        <FormValue value={instanceCount} originalValue={instanceCount}/>
                    </div>
                </div>
            </div>
        </div>
    );

    const priceBlock = (
        <div
            className={'rr-form-item rr-kit-indicators-block-item  rr-kit-indicators-item2'}
            style={{margin: '0px', borderRight: '1px solid #eaeaea'}}
        >
            <div>
                <div
                    className="rr-label">{localize(LocalizationEnum.ASPECT__RENT_INDICATORS__PRICE_BEFORE_DISCOUNT)}</div>
                <div className="rr-value">
                    <FormValue value={formatMoney(price)} originalValue={price}/>
                </div>
            </div>
        </div>
    );

    const discountPercentBlock = (
        <div
            className={'rr-form-item rr-kit-indicators-block-item  rr-kit-indicators-item3'}
            style={{margin: '0px', borderRight: '1px solid #eaeaea'}}
        >
            <div>
                <div className="rr-label">{localize(LocalizationEnum.ASPECT__FIELDS__COMMON__DISCOUNT)}</div>
                <div className="rr-value">
                    <FormValue
                        value={<FormatPercents value={discountPercent || 0} withoutDots={false} precision={2}/>}
                        originalValue={discountPercent}
                    />
                </div>
            </div>
        </div>
    );

    const discountBlock = (
        <div className={'rr-form-item rr-kit-indicators-block-item  rr-kit-indicators-item4'}
             style={{margin: '0px'}}>
            <div>
                <div
                    className="rr-label">{localize(LocalizationEnum.PAGE__OPERATIONS__CARD__TOTAL_DISCOUNT)}</div>
                <div className="rr-value">
                    <FormValue value={formatMoney(discount)} originalValue={discount}/>
                </div>
            </div>
        </div>
    );

    const finalTotalPriceBlock = (
        <div
            className={'rr-form-item rr-kit-indicators-block-item  rr-kit-indicators-item5'}
            style={{margin: '0px', backgroundColor: '#525a95'}}
        >
            <div>
                <div className="rr-label" style={{color: '#ffffff'}}>
                    {localize(LocalizationEnum.ASPECT__GLOBAL__IN_TOTAL)}
                </div>
                <div className="rr-value" style={{color: '#ffffff'}}>
                    <FormValue value={formatMoney(finalTotalPrice)} originalValue={finalTotalPrice}/>
                </div>
            </div>
        </div>
    );

    return canViewFinancialData ? (
        <div className="rr-kit-indicators-block" style={{cursor: 'default'}}>
            {instanceCountBlock}
            {priceBlock}
            {discountPercentBlock}
            {discountBlock}
            {finalTotalPriceBlock}
        </div>
    ) : (
        blockWithoutFinancialData
    );
}

import { useCallback, useEffect, useState } from 'react';
import { useVisibleOnScroll } from '../../СalendarBottomNavigation/hooks/useVisibleOnScroll';

export function useFixedTableHeader(
    headerRef: React.MutableRefObject<HTMLDivElement | undefined>,
    scrollRef: React.MutableRefObject<HTMLDivElement | undefined>
) {
    const [headerRect, setHeaderRect] = useState<DOMRect>();

    const resizeCb = useCallback(() => {
        const rect = scrollRef.current?.getBoundingClientRect();
        if (rect && headerRect && headerRef.current && rect.width !== headerRect.width) {
            headerRef.current.style.width = rect.width + 'px';
            setHeaderRect({ ...headerRect, width: rect.width });
        }
    }, [headerRect, scrollRef, headerRef]);

    useEffect(() => {
        const observer = new ResizeObserver(resizeCb);
        if (scrollRef.current) {
            observer.observe(scrollRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [resizeCb, scrollRef]);

    useEffect(() => {
        if (headerRef.current) {
            const rect = headerRef.current.getBoundingClientRect();
            setHeaderRect(rect);
            headerRef.current.style.width = rect.width + 'px';
        }
    }, [headerRef]);

    const fixed = useVisibleOnScroll(headerRect?.y ?? 400);

    return { fixed, headerRect };
}

import { CategorisedEntityCodeEnum, NomenclatureStateCodeEnum, SimpleTransitionCodeEnum } from '../../../../../../server';
import { NomenclatureRecordCustom, ProductsListFilters } from '../../api/products.api.types';
import React, { useCallback, useMemo } from 'react';
import every from 'lodash/every';
import RoundButton from '../../../../../../components/button/roundButton';
import { LocalizationEnum, localize, localizeIntl } from '../../../../../../localization';
import { Icon } from 'antd';
import { IconArchive, IconCheck, IconLevelUp, IconTrash, IconUnlock, IconUnlockAlt } from '../../../../../../components/icons';
import { AdditionalEntityActionForCategories } from '../../../../../../components/additionalEntityActions/AdditionalEntityActionForCategories/AdditionalEntityActionForCategories';
import { useIntl } from 'react-intl';
import { useSetStatusForProducts } from './useSetStatusAction';
import { useArchiveProducts } from './useArchiveAction';
import { useDeleteProducts } from './useDeleteAction';
import { useEntityGridContext } from '../../../../../../components/page/entityGrid/components/context/GridRefContext';
import { useAppSelector } from '../../../../../../store/hooks';
import { useCategoryIds } from '../useCategoryIds';
import { AdditionalEntityActionsPopoverContainer } from '../../../../../../components/additionalEntityActions/AdditionalEntityActionsPopoverContainer/AdditionalEntityActionsPopoverContainer';
import { ProductAdditionalEntityActionForCustomFields } from '../../components/AdditionalEntityActionForCustomFields/ProductAdditionalEntityActionForCustomFields';
import EditActionForCustomFields from '../../components/AdditionalEntityActionForCustomFields/EditActionForCustomFields';

export const useActionsButtons = (selectedRecords: NomenclatureRecordCustom[], pageParams: ProductsListFilters) => {
    const intl = useIntl();
    const gridRef = useEntityGridContext();
    const categories = useAppSelector((store) => store.entities.categories.products);

    const setStatusForProducts = useSetStatusForProducts(selectedRecords);

    const archiveProducts = useArchiveProducts(selectedRecords);

    const deleteProducts = useDeleteProducts(selectedRecords);

    const categoriesIds = useCategoryIds(pageParams);

    const clearSelection = useCallback(() => {
        gridRef?.current?.clearSelection();
    }, [gridRef]);

    return useMemo(() => {
        return [
            every(
                selectedRecords,
                (item: NomenclatureRecordCustom) =>
                    item.availableTransitionCodes?.includes(SimpleTransitionCodeEnum.ACTIVATE) &&
                    item.stateCode === NomenclatureStateCodeEnum.NEW
            ) ? (
                <RoundButton
                    key={0}
                    title={'' + localizeIntl(intl, LocalizationEnum.ASPECT__ACTION__ACTIVATE)}
                    colorScheme={'success'}
                    onClick={setStatusForProducts}
                >
                    <Icon component={IconCheck} />
                    {localize(LocalizationEnum.ASPECT__ACTION__ACTIVATE, 'span')}
                </RoundButton>
            ) : null,
            every(selectedRecords, (item: NomenclatureRecordCustom) =>
                item.availableTransitionCodes?.includes(SimpleTransitionCodeEnum.BLOCK)
            ) ? (
                <RoundButton
                    key={1}
                    title={'' + localizeIntl(intl, LocalizationEnum.ASPECT__ACTION__BLOCK)}
                    colorScheme={'blocked'}
                    onClick={setStatusForProducts}
                >
                    <Icon component={IconUnlock} />
                    {localize(LocalizationEnum.ASPECT__ACTION__BLOCK, 'span')}
                </RoundButton>
            ) : null,
            every(
                selectedRecords,
                (item: NomenclatureRecordCustom) =>
                    item.availableTransitionCodes?.includes(SimpleTransitionCodeEnum.ACTIVATE) &&
                    item.stateCode === NomenclatureStateCodeEnum.NOTAVAILABLE
            ) ? (
                <RoundButton
                    key={2}
                    title={'' + localizeIntl(intl, LocalizationEnum.ASPECT__ACTION__UNLOCK)}
                    colorScheme={'success'}
                    onClick={setStatusForProducts}
                >
                    <Icon component={IconUnlockAlt} />
                    {localize(LocalizationEnum.ASPECT__ACTION__UNLOCK, 'span')}
                </RoundButton>
            ) : null,
            every(selectedRecords, (item: NomenclatureRecordCustom) => !item.archive && item.archivable) ? (
                <RoundButton
                    key={3}
                    title={'' + localizeIntl(intl, LocalizationEnum.ASPECT__ACTION__TO_ARCHIVE)}
                    onClick={archiveProducts}
                    colorScheme={'TO_ARCHIVE'}
                >
                    <Icon component={IconArchive} />
                    {localize(LocalizationEnum.ASPECT__ACTION__TO_ARCHIVE, 'span')}
                </RoundButton>
            ) : null,
            every(selectedRecords, (item: NomenclatureRecordCustom) => item.archive) ? (
                <RoundButton
                    key={4}
                    title={'' + localizeIntl(intl, LocalizationEnum.ASPECT__ACTION__FROM_ARCHIVE)}
                    onClick={archiveProducts}
                    colorScheme={'FROM_ARCHIVE'}
                >
                    <Icon component={IconLevelUp} />
                    {localize(LocalizationEnum.ASPECT__ACTION__FROM_ARCHIVE, 'span')}
                </RoundButton>
            ) : null,
            every(selectedRecords, (item: NomenclatureRecordCustom) => item.deleteable) ? (
                <RoundButton key={5} title={'Удалить'} onClick={deleteProducts} colorScheme={'DELETE'}>
                    <Icon component={IconTrash} />
                    Удалить
                </RoundButton>
            ) : null,
            <AdditionalEntityActionsPopoverContainer>
                <AdditionalEntityActionForCategories
                    categories={categories ?? []}
                    selectedRecords={selectedRecords}
                    entityTypeCode={CategorisedEntityCodeEnum.PRODUCT}
                    clearSelection={clearSelection}
                    selectedCategoriesKeys={categoriesIds}
                />
                <ProductAdditionalEntityActionForCustomFields selectedRecords={selectedRecords} clearSelection={clearSelection} />
                <EditActionForCustomFields selectedRecords={selectedRecords} clearSelection={clearSelection} />
            </AdditionalEntityActionsPopoverContainer>,
        ];
    }, [archiveProducts, categories, categoriesIds, clearSelection, deleteProducts, intl, selectedRecords, setStatusForProducts]);
};

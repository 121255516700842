import React, { FC, ReactElement, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { ArchiveBadge } from '../../../archiveBadge/archiveBadge';
import { HeaderNavigationBlock } from '../../../page/headerNavigationBlock/HeaderNavigationBlock';

export interface HeaderTitleLink {
    name: string | ReactElement;
    link?: string;
    bold?: boolean;
}

interface HeaderTitleProps {
    icon: ReactElement;
    archive?: boolean;
    title: ReactNode;
    badge?: ReactElement;
    links?: HeaderTitleLink[];
    className?: string;
}

export const HeaderTitle: FC<HeaderTitleProps> = ({ title, archive, badge, links, icon, className }) => {
    return (
        <HeaderNavigationBlock className={className}>
            {(maxWidth) => (
                <>
                    <div className={'top-section'}>
                        {<span className={'icon'}>{icon}</span>}
                        <span className={'title'} style={{ maxWidth }}>
                            {title}
                        </span>
                        <div style={{ minWidth: 'min-content', height: 20 }}>{badge}</div>
                    </div>
                    <div className={'bottom-section'}>
                        {links?.map((link, index, arr) => {
                            return [
                                link.link ? (
                                    <Link to={link.link} className={classNames('link', link.bold && 'bold')}>
                                        {link.name}
                                    </Link>
                                ) : (
                                    <span className={classNames(link.bold && 'bold')}>{link.name}</span>
                                ),
                                index < arr.length - 1 && <span>/</span>,
                            ];
                        })}
                        {archive && <ArchiveBadge style={{ marginLeft: 4 }} />}
                    </div>
                </>
            )}
        </HeaderNavigationBlock>
    );
};

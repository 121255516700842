import { serverApi } from '../../../../../../../../server';
import { convertAvailableFiltersToObj, getPathFromState } from '../../../../../../../../shared/util/utils';
import { push } from 'connected-react-router';
import { rentersPageUrlRoute } from '../../../../../../renters/data/tabs';
import { subrentPageUrlRoute } from '../../../../../../subrent/subrentPage';
import { useAppDispatch, useAppSelector } from '../../../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../../../shared/reducers/system.reducer';
import { useCallback, useMemo } from 'react';
import { RentersPageTabsEnum, SubrentPageTabsEnum } from '../../../../../../../../shared/constants/tabEnums';

export const useLinksCallbacks = () => {
    const dispatch = useAppDispatch();
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    const onDebtOfRenterClick = useCallback(async () => {
        try {
            const { data: availableFiltersValues } = await serverApi.listRentersAvailableFiltersValues(businessAccountId);

            let { debtOfRenter } = convertAvailableFiltersToObj(availableFiltersValues.filters);

            if (debtOfRenter == null) return;
            debtOfRenter[0] = .01;

            return dispatch(
                push(
                    getPathFromState(`/${businessAccountId}/${rentersPageUrlRoute}/${RentersPageTabsEnum.COUNTERPARTIES}`, '', {
                        debtOfRenter,
                        sortBy: 'debtOfRenter',
                        sortOrder: 'DESC',
                    })
                )
            );
        } catch (e) {}
    }, [businessAccountId, dispatch]);

    const onDebtToSupplierClick = useCallback(async () => {
        try {
            const { data: availableFiltersValues } = await serverApi.listRentersAvailableFiltersValues(businessAccountId);

            let { debtToSupplier } = convertAvailableFiltersToObj(availableFiltersValues.filters);

            if (debtToSupplier == null) return;
            debtToSupplier[0] = .01;

            return dispatch(
                push(
                    getPathFromState(`/${businessAccountId}/${rentersPageUrlRoute}/${RentersPageTabsEnum.COUNTERPARTIES}`, '', {
                        debtToSupplier,
                        sortBy: 'debtToSupplier',
                        sortOrder: 'DESC',
                    })
                )
            );
        } catch (e) {}
    }, [businessAccountId, dispatch]);

    const onProjectDebtSumClick = useCallback(async () => {
        try {
            const { data: availableFiltersValues } = await serverApi.listProjectsAvailableFiltersValues(businessAccountId);

            let { debtSum } = convertAvailableFiltersToObj(availableFiltersValues.filters);

            if (debtSum == null) return;
            debtSum[0] = .01;

            return dispatch(
                push(
                    getPathFromState(`/${businessAccountId}/projects/production`, '', {
                        debtSum,
                        sortBy: 'debtSum',
                        sortOrder: 'DESC',
                    })
                )
            );
        } catch (e) {}
    }, [businessAccountId, dispatch]);

    const onSubrentDebtSumClick = useCallback(async () => {
        try {
            const { data: availableFiltersValues } = await serverApi.listSubrentsAvailableFiltersValues(businessAccountId);

            let { debtToSupplierSum } = convertAvailableFiltersToObj(availableFiltersValues.filters);

            if (debtToSupplierSum == null) return;
            debtToSupplierSum[0] = .01;

            return dispatch(
                push(
                    getPathFromState(`/${businessAccountId}/${subrentPageUrlRoute}/${SubrentPageTabsEnum.SHIPPINGS}`, '', {
                        debtToSupplierSum,
                        sortBy: 'debtToSupplierSum',
                        sortOrder: 'DESC',
                    })
                )
            );
        } catch (e) {}
    }, [businessAccountId, dispatch]);

    return useMemo(
        () => ({
            onDebtOfRenterClick,
            onDebtToSupplierClick,
            onProjectDebtSumClick,
            onSubrentDebtSumClick,
        }),
        [onDebtOfRenterClick, onDebtToSupplierClick, onProjectDebtSumClick, onSubrentDebtSumClick]
    );
};

import { Link } from 'react-router-dom';
import { businessAccountIdSelector } from '../../../shared/reducers/system.reducer';
import { useAppSelector } from '../../../store/hooks';
import { declOfNum } from '../toPayments/LinkToPayments';

interface Props {
    shippingsCount?: number;
    total?: number;
    supplierId?: number;
}

const LinkToShippings = ({ shippingsCount, total, supplierId }: Props) => {
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    if (!shippingsCount || !total || shippingsCount <= 0) return null;

    const link = `/${businessAccountId}/crm/counterparties/${supplierId}?tab=shippings&debtToSupplierSum=.01,${Number.MAX_SAFE_INTEGER}&status=IN_PROGRESS,FINISHED`;

    return (
        <div style={{ fontWeight: 400, fontSize: 14 }}>
            <Link to={link}>{`${shippingsCount} ${declOfNum(shippingsCount, ['поставок', 'поставка', 'поставки'])}`}</Link> с долгом
        </div>
    );
};
export default LinkToShippings;

import {connect} from 'react-redux';
import {IRootState} from '../../../../shared/reducers';
import {
    CustomFieldGroupObjRead,
    ImageObj,
    KitInfoCreate,
    KitInfoRead,
    KitInfoUpdate,
    PricingSchemeModeCodeEnum
} from '../../../../server/api';
import {createKit, loadEntity, loadEntity2, resetError, updateKit} from './reducers/kit.reducer';
import {loadCategories} from '../../../../shared/reducers/entities.reducer';
import {goBack, push} from 'connected-react-router';
import {showConfirm} from '../../../../components/confirm/showConfirm';
import {formFields} from './kit-create-modal-fields';
import {LocalizationEnum, localize, localizeIntl} from '../../../../localization';
import {injectIntl, WrappedComponentProps} from 'react-intl';
import {getImagePath} from '../../../../shared/util/utils';
import {ModalFormNEW, ModalFormNEWProps, ModalFormNEWState} from '../../../../components/modalForm/ModalFormNEW';
import {ServerError} from '../../../../server';
import {updateModalFilters} from '../../../../shared/reducers/userSettings/userSettings.reducer';
import {FormFieldsGroup, IFormField} from '../../../../components/dynamicForm/DynamicForm';
import {
    customFieldObjToFormFieldObj,
    findFieldGroupIndexById,
    findFieldInGroupsById,
    getCustomFieldIndexByFieldId,
    setCustomFieldGroupsinFormData,
} from '../../../../shared/util/customFieldsUtils';
import {Icon} from "antd";
import {IconClose, IconTags} from "../../../../components/icons";
import React from "react";
import {getStoreState} from "../../../../../index";
import {canViewFinancialData} from "../../../../shared/util/permissionUtils";
import {canViewFinancialDataSelector} from "../../../../shared/reducers/permissions.reducer";

interface IState extends ModalFormNEWState {
    formFields: FormFieldsGroup[];
}

interface IProps extends ModalFormNEWProps, WrappedComponentProps {
    initialValues?: KitInfoRead;
    businessAccountId: number;
    copyMode?: boolean;
    updating: boolean;
    updatingError: ServerError | undefined;
    customFieldGroups?: Array<CustomFieldGroupObjRead>;
}

class KitCreateModal extends ModalFormNEW<IProps & StateProps & DispatchProps, IState> {
    static defaultProps = {
        formFields: formFields,
    };

    _componentDidMount = () => {
        this.initializeFormFields();
    };

    customFieldsGroupToFormFields = (group: CustomFieldGroupObjRead): IFormField[] => {
        let fields: IFormField[] = [];

        group.fields.forEach((field) => {
            if (field.values) {
                if (field.values.length > 1) {
                    field.values.forEach((value, index) => {
                        fields.push(customFieldObjToFormFieldObj(field, value, index));
                    });
                } else {
                    fields.push(customFieldObjToFormFieldObj(field, field.values[0], 0));
                }
            }

            if (field.linkValues) {
                fields.push(customFieldObjToFormFieldObj(field, field.multipleValues ? field.linkValues : field.linkValues[0], 0));
            }
        });

        return fields;
    };

    initializeFormFields = () => {
        let newFormFields: FormFieldsGroup[] = (this.props.formFields ?? []).map((group) => {
            return {
                title: group.title,
                fields: group.fields.map((field) => field),
                helpMessage: group.helpMessage,
                visible: group.visible,
                header: group.header,
            };
        });

        const fieldGroups = this.props.customFieldGroups?.map((group)=>{
            return {
                ...group,
                fields: group.fields.filter(field=>!field.financial || this.props.canViewFinancialData)
            }
        }).filter(group=>group.fields.length > 0);

        if (fieldGroups && fieldGroups.length > 0) {
            fieldGroups
                .forEach((group, index) => {
                const fields: IFormField[] = this.customFieldsGroupToFormFields(group);

                if (fields.length > 0) {
                    newFormFields.push({
                        title:
                            group.name === 'MAIN_CUSTOM_FIELD_GROUP_NAME'
                                ? localize(LocalizationEnum.PAGE__PRODUCTS__MAIN_ONES_LABEL)
                                : group.name,
                        fields: fields,
                        header: index === 0 ? 'Характеристики набора' : undefined,
                    });
                }
            });
        }

        this.setState({
            formFields: newFormFields,
        });
    };

    getInitialValues = () => {
        let data = this.props.initialValues;
        let initialValues;

        if (data) {
            initialValues = { ...data } as KitInfoUpdate;

            initialValues['kitId'] = data.id;

            if (!this.props.copyMode) {
                initialValues['kitEdit'] = true;
            }

            if (data.categories && data.categories.length) {
                initialValues.categoryIds = data.categories.map((item) => item.id);
            }

            if (data.mainImage) {
                initialValues['mainImage'] = [
                    {
                        uid: data.mainImage.id,
                        url: getImagePath(data.mainImage),
                        response: data.mainImage,
                    },
                ];
            }

            if (data.images && data.images.length) {
                initialValues['otherImages'] = data.images.map((item) => ({
                    uid: item.id,
                    url: getImagePath(item),
                    response: item,
                }));
            }

            if (data.members && data.members.length) {
                initialValues.members = data.members.map((item) => {
                    return { ...item, id: undefined };
                });
            }

            if(data.pricingScheme){
                initialValues.pricingScheme = data?.pricingScheme.id;
            }else if(data.pricingSchemeMode === PricingSchemeModeCodeEnum.DISABLED){
                initialValues.pricingScheme = -2;
            }else if(data.pricingSchemeMode === PricingSchemeModeCodeEnum.WITHMEMBERPRICINGSCHEMES){
                initialValues.pricingScheme = -1;
            }

            if (this.props.copyMode) {
                initialValues.stateCode = undefined;
            }
        }

        if(!initialValues){
            initialValues = {pricingScheme: -1/*pricingSchemeWithMembers*/};
        }

        return initialValues;
    };

    getFormFields = () => {
        const { formFields } = this.state;

        return this.getFilteredForms(formFields, false);
    };

    addField = (fieldName: string, index: number) => {
        let formFields = [...this.state.formFields];
        let baseField = findFieldInGroupsById(formFields, `${fieldName}[${index}]`);
        let groupIndex = findFieldGroupIndexById(formFields, `${fieldName}[${index}]`);

        if (baseField !== null && groupIndex !== null) {
            baseField = { ...baseField, values: [], defaultValue: undefined };

            let maxId = Math.max(
                ...formFields[groupIndex].fields
                    .filter((f) => {
                        return f.id.includes(fieldName);
                    })
                    .map((f, index) => {
                        return getCustomFieldIndexByFieldId(f.id) || 0;
                    })
            );

            baseField.id = `${fieldName}[${maxId + 1}]`;

            let filteredFields = formFields[groupIndex].fields.filter((f) => f.id.indexOf(fieldName) === 0);
            if (filteredFields) {
                formFields[groupIndex].fields.splice(
                    formFields[groupIndex].fields.indexOf(filteredFields[filteredFields.length - 1]) + 1,
                    0,
                    baseField
                );
            }

            this.setState({
                formFields,
            });
        }
    };

    removeField = (fieldName: string, index: number) => {
        let formFields = [...this.state.formFields];
        let groupIndex = findFieldGroupIndexById(formFields, `${fieldName}[${index}]`);

        if (groupIndex !== null) {
            let removeFrom = formFields[groupIndex].fields.findIndex((f) => {
                return f.id === `${fieldName}[${index}]`;
            });
            if (removeFrom > -1) {
                formFields[groupIndex].fields.splice(removeFrom, 1);
                this.setState({
                    formFields,
                });
            }
        }
    };

    onOk = (data) => {
        let mainImageId: number =
            data.mainImage && data.mainImage[0] && data.mainImage[0].response && data.mainImage[0].response.id
                ? data.mainImage[0].response.id
                : undefined;
        let otherImages: Array<ImageObj> =
            data.otherImages && data.otherImages.length > 0
                ? data.otherImages.filter((item) => item.response && item.response.id).map((item) => ({ id: item.response.id }))
                : [];

        delete data.mainImage;
        delete data.otherImages;
        delete data.kitEdit;

        let _pricingSchemeId: number | undefined;
        let pricingSchemeId: number | undefined;
        let pricigSchemeMode: PricingSchemeModeCodeEnum | undefined;
        _pricingSchemeId = +data?.pricingScheme;

        if(_pricingSchemeId){
            if(_pricingSchemeId === -1){
                pricingSchemeId = undefined;
                pricigSchemeMode = PricingSchemeModeCodeEnum.WITHMEMBERPRICINGSCHEMES;
            }else if(_pricingSchemeId === -2){
                pricingSchemeId = undefined;
                pricigSchemeMode = PricingSchemeModeCodeEnum.DISABLED;
            }else{
                pricingSchemeId = _pricingSchemeId;
                pricigSchemeMode = PricingSchemeModeCodeEnum.KITPRICINGSCHEME;
            }
        }

        delete data.pricingScheme;
        data.pricingSchemeId = pricingSchemeId;
        data.pricingSchemeMode = pricigSchemeMode;

        if (this.props.initialValues) {
            if (!this.props.copyMode) {
                // Редактирование
                const members = data.members.map((item) => {
                    if (!data.mainMemberOptionEnabled) item.main = false;
                    return item;
                });

                let newData: KitInfoUpdate = { ...this.props.initialValues, members, ...data } as KitInfoUpdate;
                newData = setCustomFieldGroupsinFormData(this.props.customFieldGroups, newData);

                this.props.loadEntity(this.props.businessAccountId, this.props.initialValues ? this.props.initialValues.id : 1);

                newData.mainImage = mainImageId ? { id: mainImageId } : undefined;
                newData.images = otherImages;

                if (newData.fullName === '') delete newData.fullName;
                if (newData.barcode === '') delete newData.barcode;

                this.props.updateKit(this.props.intl, this.props.businessAccountId, (newData as any).kitId || 0, newData);
            } else {
                // Копирование
                let newData: KitInfoCreate = data as KitInfoCreate;
                newData = setCustomFieldGroupsinFormData(this.props.customFieldGroups, newData);

                newData.members = data.members.map((item) => {
                    if (!data.mainMemberOptionEnabled) item.main = false;
                    return item;
                });

                newData.mainImage = mainImageId ? { id: mainImageId } : undefined;
                newData.images = otherImages;

                if (newData.fullName === '') newData.fullName = undefined;

                this.props.createKit(this.props.intl, this.props.businessAccountId, newData);
            }
        } else {
            // Создание
            let newData: KitInfoCreate = data as KitInfoCreate;
            newData = setCustomFieldGroupsinFormData(this.props.customFieldGroups, newData);

            newData.mainImage = mainImageId ? { id: mainImageId } : undefined;
            newData.images = otherImages;

            if (newData.fullName === '') newData.fullName = undefined;

            this.props.createKit(this.props.intl, this.props.businessAccountId, newData);
        }
    };

    onCancel = async (isFieldsTouched: Boolean) => {
        let yes =
            !isFieldsTouched ||
            (await showConfirm(
                this.props.intl,
                localizeIntl(
                    this.props.intl,
                    this.props.initialValues ? LocalizationEnum.ASPECT__MODAL__ABORT_EDIT : LocalizationEnum.ASPECT__MODAL__ABORT_CREATION
                )
            ));
        if (yes) {
            this.props.initialValues
                ? this.props.push(`/${this.props.businessAccountId}/inventory/kits/${this.props.initialValues?.id}?tab=description`)
                : this.props.push(`/${this.props.businessAccountId}/inventory/kits/`);
            this.props.resetError();
        }
    };
}

const mapStateToProps = (storeState: IRootState) => ({
    updating: storeState.kit.updating,
    updatingError: storeState.kit.updatingError,
    modalFilters: storeState.userSettings.modalFilters,
    canViewFinancialData: canViewFinancialDataSelector(storeState)
});

const mapDispatchToProps = { createKit, updateKit, loadCategories, goBack, resetError, loadEntity2, loadEntity, push, updateModalFilters };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(KitCreateModal));

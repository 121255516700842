import React from 'react';
import { Card, Col, Icon, Row } from 'antd';
import { connect } from 'react-redux';
import { IRootState } from '../../../../../../shared/reducers';
import {
    AttachmentParentEntityTypeCodeEnum,
    CategoryTypeCodeEnum,
    ContactPersonAPIObj,
    CounterpartyActivityTypeCodeEnum,
    EntityTypeCodeEnum,
} from '../../../../../../server/api';
import { AbstractEntityInfoCard, FormValue } from '../../../../../../components';
import { IconBuilding } from '../../../../../../components/icons';
import { Status } from '../../../../../../components/status/status';
import { getImagePath } from '../../../../../../shared/util/utils';
import { Link } from 'react-router-dom';
import { LocalizationEnum, localize } from '../../../../../../localization';
import { formatMoney } from '../../../../../../shared/util/formatMoney';
import { RichTextView } from '../../../../../../components/richTextView/RichTextView';
import { loadCategories } from '../../../../../../shared/reducers/entities.reducer';
import { Avatar } from '../../../../../../components/avatar/avatar';
import { getGridStorageData, setGridStorageDataFilters } from '../../../../../../components/grid/utils';
import { ContactPersonBlock } from '../../../../../../components/contactPersonBlock/contactPersonBlock';
import { getBusinessAccountId } from '../../../../../../../index';
import { getCounterpartyActivityTypeByCode, getCounterpartySourceByCode } from '../../../../../../shared/util/utils4';
import { FormValueMoneyProblem } from '../../../../../../components/formValue/FormValueMoneyProblem';
import { canViewFinancialData } from '../../../../../../shared/util/permissionUtils';
import { LinkToPayments } from '../../../../../../components/link/toPayments/LinkToPayments';
import { AttachmentsBlock } from '../../../../../../components/attachmentsBlock/attachmentsBlock';
import { loadRenter } from '../../../reducers/renters/renter.reducer';
import { DataDisplayBlock, DataDisplayItemType } from '../../../../../../components/dataDisplay';
import { subrentModuleEnabledSelector } from '../../../../../../shared/reducers/businessAccountPreferences.reducer';
import { EntityRentIndicatorsBlock } from '../../../../../../components/currentRentIndicators/entityRentIndicatorsBlock';
import { EntitySubrentIndicatorsBlock } from '../../../../../../components/currentRentIndicators/entitySubrentIndicatorsBlock';
import { push } from 'connected-react-router';
import { LegalDetailsActionType } from '../../../../legalDetails/data/legalDetailsData';
import { LegalDetailsBlockWrapper } from '../../../../legalDetails/components/legalDetailsBlockWrapper';
import LinkToShippings from '../../../../../../components/link/toShippings/LinkToShippings';
import DebtLinks from './DebtLinks';

interface IState {
    collapsedIndicators: boolean;
    collapsed?: string[];
}

interface IProps extends StateProps, DispatchProps {}

const GRID_NAME: string = 'renter-description';

class CounterpartyPageDescriptionTabPane extends React.PureComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            collapsedIndicators: true,
            collapsed: [],
        };
    }

    componentDidMount = async () => {
        console.log('RenterPageDescriptionTabPane componentDidMount()');
        if (this.props.data) {
            await this.props.loadCategories(this.props.data.businessAccountId, CategoryTypeCodeEnum.RENTER);
        }

        let collapsed = await getGridStorageData(GRID_NAME);

        this.setState({
            collapsed: collapsed.filters?.collapsedIndicators,
        });
    };

    onCollapsedIndicators = (key) => {
        setGridStorageDataFilters(GRID_NAME, { collapsedIndicators: key });
        this.setState({
            collapsedIndicators: key.length,
            collapsed: key,
        });
    };

    componentWillUnmount = () => {
        console.log('RenterPageDescriptionTabPane componentWillUnmount()');
    };

    render() {
        console.log('RenterPageDescriptionTabPane render()');

        let { data, canViewFinancialData, subrentModuleEnabled } = this.props;

        let taxRate;
        if (data) {
            if (data.projectsTaxRateMax !== data.projectsTaxRateMin) {
                taxRate = `${data.projectsTaxRateMin}-${data.projectsTaxRateMax}%`;
            } else {
                if (data.projectsTaxRateMax === 0) {
                    taxRate = undefined;
                } else {
                    taxRate = `${data.projectsTaxRateMin}%`;
                }
            }
        }

        let rentDebt = 0;
        let subrentDebt = 0;

        if (data) {
            rentDebt = data.debtOfRenter;
            subrentDebt = data.debtToSupplier;
        }

        const typeRent =
            data?.activityTypeCode === CounterpartyActivityTypeCodeEnum.RENTERANDSUPPLIER
                ? 'all'
                : data?.activityTypeCode === CounterpartyActivityTypeCodeEnum.SUPPLIERONLY
                ? 'subrent'
                : 'rent';

        return data ? (
            <Row gutter={32}>
                {/* Левый блок */}
                <Col xxl={6} xl={8} lg={10} span={24} style={{ marginBottom: 32 }}>
                    <Card bordered={false} className={'rr-card-without-padding'}>
                        <div style={{ padding: 32, paddingTop: 30 }}>
                            <div style={{ textAlign: 'center', marginBottom: 28 }}>
                                <Avatar
                                    className={data.image ? 'rr-avatar-big' : 'rr-avatar-medium-110'}
                                    style={{ backgroundColor: '#90dcff' }}
                                    src={getImagePath(data.image, '158')}
                                    originalSrc={getImagePath(data.image)}
                                >
                                    <Icon component={IconBuilding} />
                                </Avatar>
                            </div>

                            <div style={{ marginBottom: 9 }}>
                                <Status entityType={'renter'} code={data.stateCode} />
                            </div>

                            <Row>
                                <Col className={'rr-form-item'} style={{ marginBottom: 29 }}>
                                    <div className={'rr-label'}>{localize(LocalizationEnum.ASPECT__FIELDS__COMMON__SHORT_TITLE)}</div>
                                    <h3>
                                        <FormValue
                                            value={data.shortName}
                                            emptyText={localize(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED)}
                                        />
                                    </h3>
                                    <div className={'rr-entity-page-categories'}>
                                        {data.categories
                                            ? data.categories.map((item, index, d) => (
                                                  <React.Fragment key={index}>
                                                      <Link
                                                          className={'rr-link2'}
                                                          to={`/${this.props.businessAccountId}/crm/counterparties?categoryIds=${item.id}`}
                                                          style={index === 0 ? { fontWeight: 600 } : {}}
                                                      >
                                                          {item.name}
                                                      </Link>
                                                      {index !== d.length - 1 ? ' / ' : null}
                                                  </React.Fragment>
                                              ))
                                            : null}
                                    </div>
                                </Col>
                                <DataDisplayBlock
                                    data={[
                                        subrentModuleEnabled
                                            ? [
                                                  {
                                                      label: 'Вид деятельности',
                                                      type: DataDisplayItemType.String,
                                                      value: getCounterpartyActivityTypeByCode(data?.activityTypeCode),
                                                      emptyValueText: localize(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED),
                                                  },
                                              ]
                                            : undefined,
                                        [
                                            {
                                                label: 'Источник',
                                                type: DataDisplayItemType.String,
                                                value: getCounterpartySourceByCode(data?.sourceCode),
                                                emptyValueText: 'Создан в системе',
                                            },
                                        ],
                                        [
                                            {
                                                label: localize(LocalizationEnum.ASPECT__FIELDS__COMMON__ASSIGNEE),
                                                type: DataDisplayItemType.User,
                                                value: {
                                                    baId: getBusinessAccountId(),
                                                    name: data.assigneeFullName,
                                                    id: data?.assigneeId,
                                                },
                                                emptyValueText: localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_ASSIGNED),
                                            },
                                        ],
                                    ]}
                                    paddings={false}
                                />
                            </Row>
                        </div>

                        {data?.activityTypeCode !== CounterpartyActivityTypeCodeEnum.SUPPLIERONLY && canViewFinancialData && (
                            <Row style={{ borderTop: '1px solid #eaeaea' }}>
                                <div style={{ padding: 32, paddingTop: 30 }}>
                                    <Col className={'rr-form-item'}>
                                        <div className={'rr-label'}>Скидка по умолчанию</div>
                                        <div className="rr-value">
                                            <FormValue
                                                originalValue={data.defaultDiscount}
                                                value={
                                                    data.defaultDiscount !== undefined && data.defaultDiscount !== null
                                                        ? data.defaultDiscount + '%'
                                                        : null
                                                }
                                                emptyText={localize(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED)}
                                            />
                                        </div>
                                    </Col>

                                    <Col className={'rr-form-item'}>
                                        <div className="rr-label">{localize(LocalizationEnum.PAGE__RENTERS__CARD__PROJECTS_TAXES)}</div>
                                        <div className="rr-value">
                                            <FormValue
                                                value={taxRate}
                                                emptyText={localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_CHARGED)}
                                                originalValue={140}
                                            />
                                        </div>
                                    </Col>
                                </div>
                            </Row>
                        )}

                        {(data?.activityTypeCode === CounterpartyActivityTypeCodeEnum.RENTERONLY ||
                            data?.activityTypeCode === CounterpartyActivityTypeCodeEnum.RENTERANDSUPPLIER) &&
                            canViewFinancialData && (
                                <Row style={{ borderTop: '1px solid #eaeaea' }}>
                                    <div style={{ padding: 32, paddingTop: 30 }}>
                                        <Col className={'rr-form-item'}>
                                            <div className="rr-label">Принято оплаты по проектам</div>
                                            <div className="rr-value">
                                                {formatMoney(data.allProjectsAcceptedPaymentsSum)}
                                                <LinkToPayments
                                                    paymentsCount={data.allProjectsAcceptedPaymentsCount}
                                                    params={{ counterpartyId: data.id }}
                                                />
                                            </div>
                                        </Col>
                                        <Col className="rr-form-item">
                                            <div className="rr-label">Долг арендатора</div>
                                            {rentDebt === 0 ? (
                                                <span
                                                    style={{
                                                        color: 'rgb(52, 191, 163)',
                                                        fontWeight: 400,
                                                        fontSize: '18px',
                                                    }}
                                                >
                                                    {localize(LocalizationEnum.ASPECT__GLOBAL__NO)}
                                                </span>
                                            ) : (
                                                <div className={`rr-value rr-danger rr-nounderline`}>
                                                    <FormValueMoneyProblem withOverpay={true} value={rentDebt} />
                                                    <DebtLinks
                                                        renterId={data.id}
                                                        projectsCount={data.projectsWithDebtCount}
                                                        ordersCount={data.ordersWithDebtCount}
                                                        totalDebt={rentDebt}
                                                    />
                                                </div>
                                            )}
                                        </Col>
                                    </div>
                                </Row>
                            )}

                        {canViewFinancialData &&
                            (data?.activityTypeCode === CounterpartyActivityTypeCodeEnum.SUPPLIERONLY ||
                                data?.activityTypeCode === CounterpartyActivityTypeCodeEnum.RENTERANDSUPPLIER) && (
                                <Row style={{ borderTop: '1px solid #eaeaea' }}>
                                    <div style={{ padding: 32, paddingTop: 30 }}>
                                        <Col className={'rr-form-item'}>
                                            <div className="rr-label">Произведено оплаты по поставкам</div>
                                            <div className="rr-value">
                                                {formatMoney(data?.allSubrentsPaymentsMadeSum)}
                                                <LinkToPayments
                                                    paymentsCount={data.allSubrentsPaymentsMadeCount}
                                                    params={{ counterpartyId: data.id, projectId: -1 }}
                                                />
                                            </div>
                                        </Col>
                                        <Col className="rr-form-item">
                                            <div className="rr-label">Долг перед поставщиком</div>
                                            {subrentDebt === 0 ? (
                                                <span
                                                    style={{
                                                        color: 'rgb(52, 191, 163)',
                                                        fontWeight: 400,
                                                        fontSize: '18px',
                                                    }}
                                                >
                                                    {localize(LocalizationEnum.ASPECT__GLOBAL__NO)}
                                                </span>
                                            ) : (
                                                <div className={`rr-value rr-danger rr-nounderline`}>
                                                    <FormValueMoneyProblem withOverpay={true} value={subrentDebt} />
                                                    <LinkToShippings
                                                        shippingsCount={data.subrentsWithDebtCount}
                                                        supplierId={data.id}
                                                        total={subrentDebt}
                                                    />
                                                </div>
                                            )}
                                        </Col>
                                    </div>
                                </Row>
                            )}

                        {canViewFinancialData && data?.activityTypeCode === CounterpartyActivityTypeCodeEnum.RENTERANDSUPPLIER && (
                            <Row style={{ borderTop: '1px solid #eaeaea' }}>
                                <div style={{ padding: 32, paddingTop: 30 }}>
                                    <Col className="rr-form-item">
                                        <div className="rr-label">Долг контрагента после взаимозачета</div>
                                        {rentDebt - subrentDebt === 0 ? (
                                            <span
                                                style={{
                                                    color: 'rgb(52, 191, 163)',
                                                    fontWeight: 400,
                                                    fontSize: '18px',
                                                }}
                                            >
                                                {localize(LocalizationEnum.ASPECT__GLOBAL__NO)}
                                            </span>
                                        ) : (
                                            <div className={`rr-value rr-danger`}>
                                                <FormValueMoneyProblem withOverpay={false} value={rentDebt - subrentDebt} />
                                            </div>
                                        )}
                                    </Col>
                                </div>
                            </Row>
                        )}
                    </Card>
                </Col>

                {/* Правый блок */}
                <Col xxl={18} xl={16} lg={14} span={24}>
                    <Card bordered={false} className={'rr-card-without-padding'} style={{ backgroundColor: 'unset' }}>
                        <Row style={{ marginLeft: 8, marginRight: 8 }}>
                            <Col>
                                <Row gutter={16} type={'flex'}>
                                    <Col xxl={12} xl={24} lg={24} span={24} className={'rr-page-renter-block-with-right-border'}>
                                        <Row gutter={16} style={{ padding: 0, backgroundColor: '#FFFFFF' }}>
                                            {[
                                                CounterpartyActivityTypeCodeEnum.RENTERANDSUPPLIER,
                                                CounterpartyActivityTypeCodeEnum.RENTERONLY,
                                            ].includes(data?.activityTypeCode) && (
                                                <>
                                                    <EntityRentIndicatorsBlock
                                                        entityType={'renter'}
                                                        entity={data}
                                                        storageKey={'counterparty-rent'}
                                                        className={'rr-page-renter-data-block'}
                                                    />
                                                    {canViewFinancialData && (
                                                        <DataDisplayBlock
                                                            className={'rr-page-renter-data-block'}
                                                            data={[
                                                                [
                                                                    {
                                                                        label: 'Стоимость проектов',
                                                                        type: DataDisplayItemType.Money,
                                                                        value: data.allProjectsSumAfterTaxes,
                                                                        emptyValueText: localize(
                                                                            LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED
                                                                        ),
                                                                    },
                                                                ],
                                                            ]}
                                                        />
                                                    )}
                                                </>
                                            )}
                                            {subrentModuleEnabled &&
                                                [
                                                    CounterpartyActivityTypeCodeEnum.RENTERANDSUPPLIER,
                                                    CounterpartyActivityTypeCodeEnum.SUPPLIERONLY,
                                                ].includes(data?.activityTypeCode) && (
                                                    <>
                                                        <EntitySubrentIndicatorsBlock
                                                            entityType={'renter'}
                                                            entity={data}
                                                            storageKey={'counterparty-subrent'}
                                                            className={'rr-page-renter-data-block'}
                                                        />
                                                        {canViewFinancialData && (
                                                            <DataDisplayBlock
                                                                className={'rr-page-renter-data-block'}
                                                                data={[
                                                                    [
                                                                        {
                                                                            label: 'Стоимость поставок',
                                                                            type: DataDisplayItemType.Money,
                                                                            value: data.allSubrentsSum,
                                                                            emptyValueText: localize(
                                                                                LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED
                                                                            ),
                                                                        },
                                                                    ],
                                                                ]}
                                                            />
                                                        )}
                                                    </>
                                                )}

                                            <LegalDetailsBlockWrapper
                                                storageKey={'counterparty-legalDetails'}
                                                entityType={EntityTypeCodeEnum.RENTER}
                                                data={data.legalDetails}
                                                onAction={async (action) => {
                                                    if (action === LegalDetailsActionType.create) {
                                                        this.props.push(
                                                            `/${this.props.businessAccountId}/crm/counterparties/${data?.id}/createLegalDetails?tab=description`
                                                        );
                                                    } else if (action === LegalDetailsActionType.edit) {
                                                        this.props.push(
                                                            `/${this.props.businessAccountId}/crm/counterparties/${data?.id}/editLegalDetails?tab=description`
                                                        );
                                                    }
                                                }}
                                                afterDeleteCb={() => {
                                                    this.props.loadRenter(this.props.businessAccountId, data?.id!);
                                                }}
                                            />
                                        </Row>
                                    </Col>

                                    <Col
                                        xxl={12}
                                        xl={24}
                                        lg={24}
                                        span={24}
                                        style={{ backgroundColor: '#FFFFFF' }}
                                        className={'rr-page-renter-block-with-top-border'}
                                    >
                                        {data.contacts.map((item: ContactPersonAPIObj, index: number) => (
                                            <ContactPersonBlock data={item} hasOwnContacts={true} key={index} />
                                        ))}
                                        <Row>
                                            <Col style={{ marginLeft: -8, marginRight: -8, borderBottom: '1px solid #eaeaea' }}>
                                                <AttachmentsBlock
                                                    parentEntityId={data.id}
                                                    parentEntityTypeCode={AttachmentParentEntityTypeCodeEnum.RENTER}
                                                    attachments={data.attachments?.records || []}
                                                    updateParentEntity={() => {
                                                        this.props.loadRenter(this.props.businessAccountId, data!.id);
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row style={{ padding: '32px 24px' }}>
                                            <Col span={24} className={'rr-form-item'}>
                                                <div className={'rr-label'}>
                                                    {localize(LocalizationEnum.ASPECT__FIELDS__COMMON__DESCRIPTION)}
                                                </div>
                                                <div className={'rr-text'}>
                                                    <FormValue
                                                        value={data.description && <RichTextView value={data.description} />}
                                                        emptyText={localize(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED)}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                    <AbstractEntityInfoCard leftBlockSpanLG={24} data={data} style={{ marginTop: 32 }} />
                </Col>
            </Row>
        ) : null;
    }
}

const mapStateToProps = (storeState: IRootState) => ({
    data: storeState.renter.entity,
    businessAccountId: storeState.system.businessAccountId,
    canViewFinancialData: canViewFinancialData(storeState.permissions.permissions),
    subrentModuleEnabled: subrentModuleEnabledSelector(storeState),
});

const mapDispatchToProps = {
    loadCategories,
    loadRenter,
    push,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CounterpartyPageDescriptionTabPane);

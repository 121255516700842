import { ReactNode } from 'react';
import { IDetailedSelectColumn } from './DetailedSelect';
import classNames from 'classnames';
import { Button } from 'antd';

export interface DetailedOptionProps<T extends {}> {
    item: T;
    getOptionPrefix?: (record: T) => ReactNode | null;
    columns: IDetailedSelectColumn[];
    getKey: (item: T) => string | number;
    details?: {
        clickHandler: (id: number | undefined) => void;
        text: string;
        getKey?: (item: T) => number | undefined;
    };
}

const DetailedOption = <T extends {}>({ item, columns, getOptionPrefix, getKey, details }: DetailedOptionProps<T>) => {
    return (
        <div className="rr-select-detailed-line">
            {getOptionPrefix?.(item)}
            {columns.map((c) => (
                <div
                    key={c.key.toString()}
                    className={classNames(c.className, 'rr-select-detailed-cell', c.secondary && 'rr-select-detailed-cell-secondary')}
                    style={{ width: c.width }}
                >
                    {c.render ? c.render(item[c.key]) : item[c.key]}
                </div>
            ))}
            {details && (
                <Button
                    type="link"
                    onClick={(e) => {
                        e.stopPropagation();
                        details.clickHandler(details.getKey ? details.getKey(item) : +getKey(item));
                    }}
                    className="rr-select-detailed-detail-button"
                >
                    {details.text}
                </Button>
            )}
        </div>
    );
};
export default DetailedOption;
